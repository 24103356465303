import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { DrawerBase } from '@demiplane-dev/demiplane-components';
import { Grid } from '@material-ui/core';
import { IGameType, ISelectOption } from '../../../types';
import { SelectPanel } from 'react-multi-select-component';
import './styles.css';
import { useGetGameTypesQuery } from '../../../types/graphql';
import { parseISO } from 'date-fns';

interface IGameSystemsDrawerProps {
  open: boolean;
  onClose: (modified: boolean) => void;
  showClose: boolean;
  title: string;
  selectedGameSystems: ISelectOption[];
  setSelectedGameSystems: Dispatch<SetStateAction<ISelectOption[]>>;
}

type TGameSystemsDrawerProps = IGameSystemsDrawerProps & WithSnackbarProps;

const GameSystemsDrawerComp = withSnackbar(
  ({
    open,
    onClose,
    showClose,
    title,
    selectedGameSystems,
    setSelectedGameSystems,
  }: TGameSystemsDrawerProps) => {
    const [gameTypes, setGameTypes] = useState<IGameType[]>([]);
    const [updated, setUpdated] = useState(false);
    const [gameTypesResult] = useGetGameTypesQuery();
    const { data: gameTypesData } = gameTypesResult;

    useEffect(() => {
      if (!!gameTypesData && !!gameTypesData.demiplane_game_type) {
        setGameTypes(
          gameTypesData.demiplane_game_type.map(
            (gt): IGameType => {
              return {
                id: gt.id,
                code: gt.code,
                name: gt.name,
                active: gt.active,
                created: parseISO(gt.created),
                updated: parseISO(gt.updated),
              };
            }
          )
        );
      }
    }, [gameTypesData]);

    useEffect(() => {
      const isUpdated =
        gameTypes.length !== selectedGameSystems.length &&
        selectedGameSystems.length !== 0;
      setUpdated(isUpdated);
    }, [gameTypes.length, selectedGameSystems.length]);
    const handleDrawerClose = async () => {
      onClose(updated);
    };

    const handleSelected = (selectedSystems: ISelectOption[]): void => {
      setSelectedGameSystems(selectedSystems);
      if (gameTypes.length !== selectedSystems.length) {
        setUpdated(true);
      } else {
        setUpdated(false);
      }
    };

    const gameTypeOptions: ISelectOption[] = useMemo(() => {
      return gameTypes.map((p) => {
        return { label: p.name, value: p.id, key: p.id, disabled: false };
      });
    }, [gameTypes]);

    return (
      <DrawerBase
        open={open}
        onClose={handleDrawerClose}
        showClose={showClose}
        title={title}
        includePadding={true}
      >
        <Grid container justify='center'>
          <Grid item xs={12}>
            <SelectPanel
              options={gameTypeOptions}
              onChange={handleSelected}
              focusSearchOnOpen={true}
              hasSelectAll
              value={selectedGameSystems}
            />
          </Grid>
        </Grid>
      </DrawerBase>
    );
  }
);

// @ts-ignore
GameSystemsDrawerComp.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'GameSystemsDrawer',
};

export const GameSystemsDrawer = GameSystemsDrawerComp;
