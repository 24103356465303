import React from 'react';

interface DaytimeIconProps {
  height: string;
  width: string;
}

export const DaytimeIcon = ({ height, width }: DaytimeIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.492 33.9876C17.9063 33.9876 13.7757 31.2279 12.0205 26.9954C10.2652 22.7596 11.2362 17.8884 14.4778 14.6482C17.7194 11.4082 22.5925 10.4378 26.8309 12.1921C31.0655 13.9465 33.8264 18.0747 33.8264 22.6586C33.8264 25.6647 32.6309 28.5444 30.5057 30.6686C28.3805 32.7928 25.4994 33.9877 22.4918 33.9877L22.492 33.9876ZM22.492 14.5662C19.2187 14.5662 16.2649 16.5387 15.0125 19.5606C13.7602 22.5857 14.4528 26.0661 16.7678 28.3795C19.0828 30.6934 22.5648 31.3857 25.5909 30.1339C28.6143 28.8822 30.5878 25.9298 30.5878 22.6581C30.5878 20.5118 29.7339 18.4539 28.2159 16.9366C26.6978 15.4193 24.639 14.5658 22.4917 14.5658L22.492 14.5662Z'
        fill='black'
      />
      <path
        d='M22.492 45C21.597 45 20.8728 44.2761 20.8728 43.3815V40.4607C20.8728 39.5661 21.597 38.8423 22.492 38.8423C23.387 38.8423 24.1112 39.5662 24.1112 40.4607V43.3562C24.1176 43.7924 23.95 44.2097 23.6464 44.5163C23.3428 44.8261 22.9253 45 22.492 45L22.492 45Z'
        fill='black'
      />
      <path
        d='M22.492 6.47379C21.597 6.47379 20.8728 5.74992 20.8728 4.85535V1.61845C20.8728 0.723851 21.597 0 22.492 0C23.387 0 24.1112 0.723875 24.1112 1.61845V4.85535C24.1112 5.28525 23.9405 5.69621 23.6369 5.99967C23.3333 6.30313 22.9221 6.47383 22.492 6.47383L22.492 6.47379Z'
        fill='black'
      />
      <path
        d='M43.3808 24.2769H40.3037C39.4086 24.2769 38.6844 23.5531 38.6844 22.6585C38.6844 21.7639 39.4087 21.04 40.3037 21.04H43.3808C44.2759 21.04 45.0001 21.7639 45.0001 22.6585C45.0001 23.5531 44.2758 24.2769 43.3808 24.2769Z'
        fill='black'
      />
      <path
        d='M4.68053 24.2769H1.61922C0.727355 24.2769 0 23.5531 0 22.6585C0 21.7639 0.727387 21.04 1.61922 21.04H4.68053C5.57555 21.04 6.29975 21.7639 6.29975 22.6585C6.29975 23.5531 5.57553 24.2769 4.68053 24.2769Z'
        fill='black'
      />
      <path
        d='M7.50443 39.093C6.85295 39.0899 6.26476 38.6979 6.01491 38.0941C5.76823 37.4904 5.90422 36.7981 6.36279 36.3334L8.5987 34.0986V34.1017C9.23753 33.4695 10.2685 33.479 10.8978 34.1175C11.5272 34.7561 11.5209 35.7866 10.882 36.4156L8.64612 38.6157C8.34568 38.9191 7.93454 39.093 7.5044 39.093L7.50443 39.093Z'
        fill='black'
      />
      <path
        d='M34.9017 11.8218C34.2502 11.8187 33.662 11.4267 33.4121 10.8229C33.1655 10.2192 33.3014 9.52688 33.7632 9.06222L36.0054 6.82108C36.6379 6.18887 37.6626 6.18887 38.2951 6.82108C38.9275 7.45328 38.9276 8.48063 38.2951 9.1128L36.0434 11.3287C35.7461 11.6385 35.335 11.8155 34.9016 11.8218L34.9017 11.8218Z'
        fill='black'
      />
      <path
        d='M10.0822 11.8478C9.65204 11.8447 9.24088 11.674 8.94043 11.3705L6.75513 9.18627H6.75197C6.11946 8.55407 6.11946 7.52672 6.75197 6.89455C7.38448 6.26238 8.41232 6.26234 9.04479 6.89455L11.2301 9.07881C11.6981 9.54348 11.8373 10.2421 11.5843 10.849C11.3345 11.4559 10.7399 11.851 10.0821 11.8479L10.0822 11.8478Z'
        fill='black'
      />
      <path
        d='M37.1475 38.9725C36.7174 38.9693 36.3062 38.7986 36.0058 38.4952L33.8995 36.4152C33.267 35.783 33.267 34.7588 33.8995 34.1267C34.532 33.4913 35.5567 33.4913 36.1923 34.1267L38.2986 36.232L38.2954 36.2288C38.7445 36.6935 38.8742 37.3826 38.6243 37.98C38.3745 38.5775 37.7926 38.9663 37.1474 38.9726L37.1475 38.9725Z'
        fill='black'
      />
    </svg>
  );
};
