import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    position: 'fixed',
    bottom: '0',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    zIndex: 500,
    width: '100%',
  },
  children: {
    position: 'relative',
    top: '4rem',
  },
}));
