import React from 'react';
import { Link as RouterLink } from '@demiplane-dev/react-router-dom';
import { Link as MuiLink } from '@material-ui/core';
import { LinkProps } from '@material-ui/core/Link';

interface ILinkProps extends LinkProps {
  to: string;
  newWindow?: boolean;
}

const LinkComp = React.memo(
  ({ to, children, className, newWindow }: ILinkProps) => {
    return (
      <>
        {newWindow ? (
          <MuiLink
            href={to}
            className={className}
            target='_blank'
            rel='noopener'
          >
            {children}
          </MuiLink>
        ) : (
          <MuiLink component={RouterLink} to={to} className={className}>
            {children}
          </MuiLink>
        )}
      </>
    );
  }
);

// @ts-ignore
LinkComp.whyDidYouRender = {
  customName: 'Link',
};

export const Link = LinkComp;
