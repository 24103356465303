import React from 'react';

interface ProfileIconProps {
  height: string;
  width: string;
}

export const ProfileIcon = ({ height, width }: ProfileIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z'
      stroke='#3276B5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.0001 20.6472C20.4367 20.6472 22.4119 18.8695 22.4119 16.6766C22.4119 14.4837 20.4367 12.7061 18.0001 12.7061C15.5636 12.7061 13.5884 14.4837 13.5884 16.6766C13.5884 18.8695 15.5636 20.6472 18.0001 20.6472Z'
      stroke='#3276B5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.17627 29.4709L9.3964 27.8572C9.53859 26.8378 10.0596 25.9028 10.8625 25.2259C11.6655 24.5491 12.6959 24.1763 13.7623 24.1769H15.0464L17.9815 26.3002L20.9165 24.1769H22.2006C23.2733 24.1677 24.3126 24.5366 25.1233 25.2142C25.934 25.8919 26.4602 26.8317 26.6032 27.8572L26.8233 29.4709'
      stroke='#3276B5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
