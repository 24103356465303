import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: '0.75rem',
    color: theme.palette.warning.main,
  },
  container: {
    height: '100%',
    width: '100%',
    background: colors.mediumLightGrey,
    borderRadius: '0.25rem',
    cursor: 'pointer',
  },
  containerSelected: {
    height: '100%',
    width: '100%',
    background: colors.deepBlue,
    borderRadius: '0.25rem',
    cursor: 'pointer',
  },
}));
