import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from 'urql';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  bigint: string;
  date: any;
  json: { [key: string]: any };
  jsonb: any;
  numeric: number;
  time: any;
  timestamp: string;
  timestamptz: string;
  uuid: any;
};

export type AboutUserOutput = {
  __typename?: 'AboutUserOutput';
  message: Scalars['String'];
  result?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type AdDetailOutput = {
  __typename?: 'AdDetailOutput';
  message: Scalars['String'];
  result?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type AdLinkType = {
  __typename?: 'AdLinkType';
  ad: AdType;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
};

export type AdMatchCriteriaTimeType = {
  __typename?: 'AdMatchCriteriaTimeType';
  adMatchCriteria: AdMatchCriteriaType;
  created: Scalars['DateTime'];
  dow: Scalars['Float'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  startTime: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type AdMatchCriteriaType = {
  __typename?: 'AdMatchCriteriaType';
  adventuringPlatforms: Array<AdventuringPlatformType>;
  combat: Scalars['Float'];
  created: Scalars['DateTime'];
  creator: UserType;
  frequency: Scalars['String'];
  gameTypes: Array<GameTypeType>;
  id: Scalars['ID'];
  imminent: Scalars['Boolean'];
  isGM: Scalars['Boolean'];
  newPlayer: Scalars['Boolean'];
  noCost: Scalars['Boolean'];
  paidOnly: Scalars['Boolean'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  resultCount: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
  times: Array<AdMatchCriteriaTimeType>;
  updated: Scalars['DateTime'];
};

export type AdRequestScoredType = {
  __typename?: 'AdRequestScoredType';
  acceptedDate?: Maybe<Scalars['DateTime']>;
  ad: AdType;
  closed: Scalars['Boolean'];
  created: Scalars['DateTime'];
  declinedDate?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invitedDate?: Maybe<Scalars['DateTime']>;
  matchCriteria?: Maybe<AdMatchCriteriaType>;
  outcome: Scalars['String'];
  read: Scalars['Boolean'];
  requestedDate: Scalars['DateTime'];
  score: Scalars['Float'];
  updated: Scalars['DateTime'];
  user: UserType;
};

export type AdRequestType = {
  __typename?: 'AdRequestType';
  acceptedDate?: Maybe<Scalars['DateTime']>;
  ad: AdType;
  closed: Scalars['Boolean'];
  created: Scalars['DateTime'];
  declinedDate?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invitedDate?: Maybe<Scalars['DateTime']>;
  matchCriteria?: Maybe<AdMatchCriteriaType>;
  outcome: Scalars['String'];
  read: Scalars['Boolean'];
  requestedDate: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  user: UserType;
};

export type AdResultType = {
  __typename?: 'AdResultType';
  adRequests: Array<AdRequestScoredType>;
  adventurerCount: Scalars['Float'];
  adventuringPlatform: AdventuringPlatformType;
  avatar: Scalars['String'];
  closed: Scalars['Boolean'];
  combat: Scalars['Float'];
  cost: Scalars['Float'];
  created: Scalars['DateTime'];
  creator?: Maybe<UserType>;
  deleted?: Maybe<Scalars['DateTime']>;
  demicard?: Maybe<AttachmentType>;
  description: Scalars['String'];
  frequency: Scalars['String'];
  gameType: GameTypeType;
  gameTypeId?: Maybe<Scalars['String']>;
  gm: Scalars['Boolean'];
  id: Scalars['ID'];
  imminent: Scalars['Boolean'];
  imminentLaunchDate?: Maybe<Scalars['DateTime']>;
  invitations: Array<InvitationType>;
  invites: Scalars['String'];
  link?: Maybe<AdLinkType>;
  matchCriteriaId: Scalars['String'];
  name: Scalars['String'];
  newPlayer: Scalars['Boolean'];
  notificationHistory?: Maybe<Array<NotificationHistoryType>>;
  outcome: Scalars['String'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  score: Scalars['Float'];
  searchEnabled: Scalars['Boolean'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
  times?: Maybe<Array<AdTimeType>>;
  updated: Scalars['DateTime'];
};

export type AdTimeHasuraType = {
  __typename?: 'AdTimeHasuraType';
  ad?: Maybe<AdType>;
  adventure?: Maybe<AdventureType>;
  created: Scalars['DateTime'];
  dow: Scalars['Float'];
  end_time: Scalars['DateTime'];
  id: Scalars['ID'];
  start_time: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type AdTimeInput = {
  dow: Scalars['Float'];
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type AdTimeType = {
  __typename?: 'AdTimeType';
  ad?: Maybe<AdType>;
  adventure?: Maybe<AdventureType>;
  created: Scalars['DateTime'];
  dow: Scalars['Float'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  startTime: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type AdType = {
  __typename?: 'AdType';
  adRequests: Array<AdRequestType>;
  adventurerCount: Scalars['Float'];
  adventuringPlatform: AdventuringPlatformType;
  avatar: Scalars['String'];
  closed: Scalars['Boolean'];
  combat: Scalars['Float'];
  cost: Scalars['Float'];
  created: Scalars['DateTime'];
  creator?: Maybe<UserType>;
  deleted?: Maybe<Scalars['DateTime']>;
  demicard?: Maybe<AttachmentType>;
  description: Scalars['String'];
  frequency: Scalars['String'];
  gameType: GameTypeType;
  gameTypeId?: Maybe<Scalars['String']>;
  gm: Scalars['Boolean'];
  id: Scalars['ID'];
  imminent: Scalars['Boolean'];
  imminentLaunchDate?: Maybe<Scalars['DateTime']>;
  invitations: Array<InvitationType>;
  invites: Scalars['String'];
  link?: Maybe<AdLinkType>;
  name: Scalars['String'];
  newPlayer: Scalars['Boolean'];
  notificationHistory?: Maybe<Array<NotificationHistoryType>>;
  outcome: Scalars['String'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  searchEnabled: Scalars['Boolean'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
  times?: Maybe<Array<AdTimeType>>;
  updated: Scalars['DateTime'];
};

export type AddFavoriteGameOutput = {
  __typename?: 'AddFavoriteGameOutput';
  favoriteGameId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AddFavoritePlatformOutput = {
  __typename?: 'AddFavoritePlatformOutput';
  favoritePlatformId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AddNewPortalV2Output = {
  __typename?: 'AddNewPortalV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
  updatedUser?: Maybe<DemiplaneUser>;
  userId: Scalars['String'];
};

export type AdventureConnectionType = {
  __typename?: 'AdventureConnectionType';
  adventure: AdventureType;
  connectionStatus?: Maybe<ConnectionStatusType>;
  connectionStatusDate?: Maybe<Scalars['DateTime']>;
  connectionType?: Maybe<ConnectionTypeType>;
  connectionTypeDate?: Maybe<Scalars['DateTime']>;
  contactType: ContactTypeType;
  contactTypeDate: Scalars['DateTime'];
  contactor?: Maybe<UserType>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  requestee?: Maybe<UserType>;
  requesteeApproval: Scalars['Boolean'];
  requesteeEmail?: Maybe<Scalars['String']>;
  requesteeRead: Scalars['Boolean'];
  requestor?: Maybe<UserType>;
  requestorApproval: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type AdventureLinkType = {
  __typename?: 'AdventureLinkType';
  adventure: AdventureType;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
};

export type AdventureMutationType = {
  __typename?: 'AdventureMutationType';
  adventure: AdventureType;
  adventurePlayer: AdventurePlayerType;
};

export type AdventureNotificationType = {
  __typename?: 'AdventureNotificationType';
  adventure: AdventureType;
  adventurePlayer: AdventurePlayerType;
  date: Scalars['DateTime'];
};

export type AdventurePlayerMutationType = {
  __typename?: 'AdventurePlayerMutationType';
  adventurePlayerId: Scalars['String'];
};

export type AdventurePlayerNotificationType = {
  __typename?: 'AdventurePlayerNotificationType';
  adventurePlayerId: Scalars['String'];
  date: Scalars['DateTime'];
};

export type AdventurePlayerType = {
  __typename?: 'AdventurePlayerType';
  adventure: AdventureType;
  episodeAttendances: Array<Maybe<EpisoderAttendanceType>>;
  id: Scalars['ID'];
  inVideo: Scalars['Boolean'];
  inVoice: Scalars['Boolean'];
  inWhisper: Scalars['Boolean'];
  isGm: Scalars['Boolean'];
  playerName?: Maybe<Scalars['String']>;
  upload?: Maybe<UploadType>;
  user: UserType;
};

export type AdventureTodoType = {
  __typename?: 'AdventureTodoType';
  adventure: AdventureType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  taskMessage: Scalars['String'];
  taskOpen: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type AdventureType = {
  __typename?: 'AdventureType';
  acceptedIncremenetalAdventurerCount: Scalars['Float'];
  adventurePlayers: Array<AdventurePlayerType>;
  adventureTodos: Array<AdventureTodoType>;
  adventuringPlatform?: Maybe<AdventuringPlatformType>;
  autoJoin: Scalars['Boolean'];
  autoJoinThreshold?: Maybe<Scalars['Float']>;
  availability: Array<Maybe<AdTimeType>>;
  avatar?: Maybe<Scalars['String']>;
  combat: Scalars['Float'];
  connections: Array<Maybe<AdventureConnectionType>>;
  created: Scalars['DateTime'];
  creator?: Maybe<UserType>;
  currentAd?: Maybe<AdType>;
  currentSession?: Maybe<SessionType>;
  defaultAdventure: Scalars['Boolean'];
  deleted?: Maybe<Scalars['DateTime']>;
  demicard?: Maybe<AttachmentType>;
  description?: Maybe<Scalars['String']>;
  episodeFrequency?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['String']>;
  gameCost: Scalars['Float'];
  gameLinkUrl?: Maybe<Scalars['String']>;
  gameMasterDetails?: Maybe<SearchAdResultDetailLeaderType>;
  gameType?: Maybe<GameTypeType>;
  hasGm: Scalars['Boolean'];
  hasLeaderViewed: Scalars['Boolean'];
  id: Scalars['ID'];
  invitations: Array<InvitationType>;
  isActive: Scalars['Boolean'];
  isAdventurePlayer: Scalars['Boolean'];
  isAdventurer: Scalars['Boolean'];
  isConnected: Scalars['Boolean'];
  isGm: Scalars['Boolean'];
  isInvited: Scalars['Boolean'];
  isLeader: Scalars['Boolean'];
  isRallying: Scalars['Boolean'];
  isRecruited: Scalars['Boolean'];
  isUserActive: Scalars['Boolean'];
  journals: Array<Maybe<AllJournalsJournalType>>;
  leaderDetails?: Maybe<SearchAdResultDetailLeaderType>;
  link?: Maybe<AdventureLinkType>;
  matchMakingEnabled: Scalars['Boolean'];
  matchMakingStatusAdvDetails: Scalars['Boolean'];
  matchMakingStatusAdvImage: Scalars['Boolean'];
  matchMakingStatusAdvType: Scalars['Boolean'];
  matchMakingStatusGameAvail: Scalars['Boolean'];
  matchMakingStatusPartyMgmt: Scalars['Boolean'];
  matchMakingStatusThemeScores: Scalars['Boolean'];
  name: Scalars['String'];
  newPlayerFriendly: Scalars['Boolean'];
  openIncremenetalAdventurerCount: Scalars['Float'];
  paidFlag: Scalars['Boolean'];
  playerStory: Scalars['Float'];
  previousSession?: Maybe<SessionType>;
  puzzlesLogic: Scalars['Float'];
  ratingSession?: Maybe<SessionType>;
  requestedIncremenetalAdventurerCount: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  sessions: Array<SessionType>;
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
  tasks: Array<Maybe<TaskType>>;
  totalActiveAdventurerCount: Scalars['Float'];
  unreadChatCount: Scalars['Float'];
  unreadConnectionCount: Scalars['Float'];
  unreadJournalCount: Scalars['Float'];
  unreadTaskCount: Scalars['Float'];
  updated: Scalars['DateTime'];
  userAdventureRoles: Array<Maybe<UserAdventureRoleType>>;
};

export type AdventuringPlatformType = {
  __typename?: 'AdventuringPlatformType';
  active: Scalars['Boolean'];
  ads: Array<AdType>;
  adventures: Array<AdventureType>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type AllJournalsItemType = {
  __typename?: 'AllJournalsItemType';
  created: Scalars['DateTime'];
  isNote: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  isSuperEvent: Scalars['Boolean'];
  item: ItemUnionType;
};

export type AllJournalsJournalType = {
  __typename?: 'AllJournalsJournalType';
  created: Scalars['DateTime'];
  items: Array<AllJournalsItemType>;
  journal: JournalType;
  unread: Scalars['Float'];
};

export type AppVersionType = {
  __typename?: 'AppVersionType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
  version: Scalars['String'];
};

export type AttachmentType = {
  __typename?: 'AttachmentType';
  ads?: Maybe<Array<AdType>>;
  adventures?: Maybe<Array<AdventureType>>;
  created: Scalars['DateTime'];
  file: Scalars['String'];
  id: Scalars['ID'];
  kind: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type AvailabilityInput = {
  dow: Scalars['Float'];
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type AvailabilityInputMm = {
  dow: Scalars['Int'];
  endTime: Scalars['timestamptz'];
  startTime: Scalars['timestamptz'];
};

export type AwardypeType = {
  __typename?: 'AwardypeType';
  awardName: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  updated: Scalars['DateTime'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ChatTokenType = {
  __typename?: 'ChatTokenType';
  token: Scalars['String'];
};

export type ConnectionStatusType = {
  __typename?: 'ConnectionStatusType';
  connections?: Maybe<Array<AdventureConnectionType>>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ConnectionTypeType = {
  __typename?: 'ConnectionTypeType';
  connections?: Maybe<Array<AdventureConnectionType>>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ContactTypeType = {
  __typename?: 'ContactTypeType';
  connections?: Maybe<Array<AdventureConnectionType>>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ConversationParticipantsType = {
  __typename?: 'ConversationParticipantsType';
  fullParty: Scalars['Boolean'];
  participants: Array<Maybe<Scalars['String']>>;
};

export type CountryType = {
  __typename?: 'CountryType';
  active: Scalars['Boolean'];
  code2: Scalars['String'];
  code3: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regions: Array<RegionType>;
  updated: Scalars['DateTime'];
};

export type CreditCardInput = {
  cardType?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
  lastDigits?: Maybe<Scalars['String']>;
  stripeCardId?: Maybe<Scalars['String']>;
  stripeToken?: Maybe<Scalars['String']>;
};

export type CreditCardType = {
  __typename?: 'CreditCardType';
  cardName?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  lastDigits?: Maybe<Scalars['String']>;
  stripeCardId?: Maybe<Scalars['String']>;
  stripeToken?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  user: UserType;
};


export type EarningsType = {
  __typename?: 'EarningsType';
  currentMonthEarnings: Scalars['Float'];
  currentMonthEpisodeCount: Scalars['Float'];
  lifetimeEarnings: Scalars['Float'];
  lifetimeEpisodeCount: Scalars['Float'];
};

export type EjectGameMasterV2Output = {
  __typename?: 'EjectGameMasterV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type EndEpisodeOutput = {
  __typename?: 'EndEpisodeOutput';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type EndEpisodeV2Output = {
  __typename?: 'EndEpisodeV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type EpisoderAttendanceType = {
  __typename?: 'EpisoderAttendanceType';
  adventurePlayer: AdventurePlayerType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  joining: Scalars['Boolean'];
  missing: Scalars['Boolean'];
  session: SessionType;
  updated: Scalars['DateTime'];
};

export type FavoriteDiceRollType = {
  __typename?: 'FavoriteDiceRollType';
  adventurePlayer: AdventurePlayerType;
  created: Scalars['DateTime'];
  diceRoll: Scalars['String'];
  id: Scalars['ID'];
  rollName: Scalars['String'];
  sortOrder: Scalars['Float'];
  updated: Scalars['DateTime'];
};

export type FeaturedGmRecommendationReviewsOutput = {
  __typename?: 'FeaturedGmRecommendationReviewsOutput';
  message: Scalars['String'];
  result?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type GameTypeType = {
  __typename?: 'GameTypeType';
  active: Scalars['Boolean'];
  adventures: Array<AdventureType>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type GmRatingType = {
  __typename?: 'GmRatingType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  rated: Scalars['Boolean'];
  ratedPlayer: SessionPlayerType;
  rating: Scalars['Float'];
  ratingPlayer: SessionPlayerType;
  session: SessionType;
  updated: Scalars['DateTime'];
};

export type HandleParticipantLeavesWhisperOutput = {
  __typename?: 'HandleParticipantLeavesWhisperOutput';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type HowUserPlaysOutput = {
  __typename?: 'HowUserPlaysOutput';
  message: Scalars['String'];
  result: Scalars['json'];
  success: Scalars['Boolean'];
};

export type ImmediatelyOpenEpisodeV2Output = {
  __typename?: 'ImmediatelyOpenEpisodeV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type InvitationType = {
  __typename?: 'InvitationType';
  acceptedDate?: Maybe<Scalars['DateTime']>;
  acceptedFlag: Scalars['Boolean'];
  ad?: Maybe<AdType>;
  adventure?: Maybe<AdventureType>;
  created: Scalars['DateTime'];
  declinedDate?: Maybe<Scalars['DateTime']>;
  declinedFlag: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['ID'];
  recruitedDate?: Maybe<Scalars['DateTime']>;
  recruitedFlag: Scalars['Boolean'];
  user?: Maybe<UserType>;
};

/** Note or SuperEvent */
export type ItemUnionType = JournalNoteType | JournalSuperEventType;

export type JoinAdventuresAdsOutput = {
  __typename?: 'JoinAdventuresAdsOutput';
  message: Scalars['String'];
  result: Scalars['json'];
  success: Scalars['Boolean'];
};

export type JoinAdventuresAdsResult = {
  __typename?: 'JoinAdventuresAdsResult';
  acceptedAdventurerCount: Scalars['Int'];
  adventureFeatured?: Maybe<Scalars['Boolean']>;
  adventureId: Scalars['String'];
  adventureRanking?: Maybe<Scalars['Int']>;
  adventureShareLink: Scalars['String'];
  adventuringPlatformName: Scalars['String'];
  cost: Scalars['Float'];
  created: Scalars['timestamptz'];
  dowFriday: Scalars['timestamptz'];
  dowMonday: Scalars['timestamptz'];
  dowSaturday: Scalars['timestamptz'];
  dowSunday: Scalars['timestamptz'];
  dowThursday: Scalars['timestamptz'];
  dowTuesday: Scalars['timestamptz'];
  dowWednesday: Scalars['timestamptz'];
  frequency: Scalars['String'];
  gameTypeName: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  matchScore?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  openAdventurerCount: Scalars['Int'];
  quickMatch: Scalars['Boolean'];
  quickMatchDate: Scalars['timestamptz'];
  requestedAdventurerCount: Scalars['Int'];
};

export type JoinSessionInput = {
  id: Scalars['String'];
};

export type JournalNoteShareInput = {
  id: Scalars['String'];
  playerIds: Array<Maybe<Scalars['String']>>;
};

export type JournalNoteShareReturnType = {
  __typename?: 'JournalNoteShareReturnType';
  journalId: Scalars['ID'];
  noteId: Scalars['ID'];
  shares: Array<Maybe<JournalNoteShareType>>;
};

export type JournalNoteShareType = {
  __typename?: 'JournalNoteShareType';
  adventurePlayer: AdventurePlayerType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  note: JournalNoteType;
  read: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type JournalNoteType = {
  __typename?: 'JournalNoteType';
  adventurePlayer: AdventurePlayerType;
  contentDelta: Scalars['jsonb'];
  contentHtml: Scalars['String'];
  contentPlain: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  journal: JournalType;
  shares: Array<JournalNoteShareType>;
  title: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type JournalNoteUpdateInput = {
  contentDelta: Scalars['jsonb'];
  contentHtml: Scalars['String'];
  contentPlain: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
};

export type JournalSuperEventDeleteInput = {
  adventureId: Scalars['String'];
  id: Scalars['String'];
};

export type JournalSuperEventDeleteType = {
  __typename?: 'JournalSuperEventDeleteType';
  id: Scalars['ID'];
  journalId: Scalars['ID'];
};

export type JournalSuperEventInput = {
  adventureId: Scalars['String'];
  journalId: Scalars['String'];
  title: Scalars['String'];
};

export type JournalSuperEventReadStatusType = {
  __typename?: 'JournalSuperEventReadStatusType';
  adventurePlayer: AdventurePlayerType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  journalSuperEvent: JournalSuperEventType;
  read: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type JournalSuperEventType = {
  __typename?: 'JournalSuperEventType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  journal: JournalType;
  readStatuses: Array<Maybe<JournalSuperEventReadStatusType>>;
  title: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type JournalSuperEventUpdateInput = {
  adventureId: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
};

export type JournalTitleType = {
  __typename?: 'JournalTitleType';
  adventurePlayer: AdventurePlayerType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  journal: JournalType;
  title: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type JournalType = {
  __typename?: 'JournalType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  notes: Array<JournalNoteType>;
  session: SessionType;
  superEvents: Array<JournalSuperEventType>;
  titles: Array<JournalTitleType>;
  updated: Scalars['DateTime'];
};

export type JournalUpdateInput = {
  id: Scalars['String'];
  title: Scalars['String'];
};

export type LeaveEpisodeV2Output = {
  __typename?: 'LeaveEpisodeV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type LeaveSessionInput = {
  id: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: AdventureConnectionType;
  acceptRecruit: AdventureConnectionType;
  acceptRequest: AdventureConnectionType;
  addAdventureSlim: AdventureType;
  addCreditCard: CreditCardType;
  addJournalSuperEvent: JournalSuperEventType;
  addUser: UserType;
  attendEpisode: Scalars['Boolean'];
  calculateFeesWithTip: SessionType;
  cancelPayment: Scalars['Boolean'];
  cancelSession: SessionMutationType;
  cantMakeEpisode: Scalars['Boolean'];
  capturePayment: PaymentType;
  captureTipPayment: PaymentType;
  completeRatingFlow: SessionPlayerType;
  completeTask: TaskType;
  contactGameMaster: AdventureType;
  createFavoriteDiceRoll: FavoriteDiceRollType;
  createTask: TaskType;
  createUpload: UploadType;
  declineInvite: AdventureConnectionType;
  declineRecruit: AdventureConnectionType;
  declineRequest: AdventureConnectionType;
  declineTask: TaskType;
  deleteAdventure: Scalars['Boolean'];
  deleteFavoriteDiceRoll: Scalars['Boolean'];
  deleteJournalSuperEvent: JournalSuperEventDeleteType;
  deleteUpload: Scalars['Boolean'];
  disableAdventureMatchmaking: AdventureType;
  ejectAdventureGameMaster: AdventureType;
  enableAdventureMatchmaking: AdventureType;
  gameplayNotification: SessionMutationType;
  immediatelyOpenSession: SessionMutationType;
  insertPlayerAward: PlayerAwardType;
  inviteAdventurers: AdventureType;
  joinAdventureRecruit: AdventureConnectionType;
  joinAdventureRequest: AdventureConnectionType;
  joinSession: SessionMutationType;
  leaveAdventure: AdventureType;
  leaveRecruit: AdventureConnectionType;
  leaveRequest: AdventureConnectionType;
  leaveSession: SessionMutationType;
  openSession: SessionMutationType;
  preauthorizePayment: PaymentType;
  precalculateFees: SessionType;
  promoteAdventureGameMaster: AdventureType;
  readAdventureConnections: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  readNotificationByUrl: Scalars['Boolean'];
  readSharedNote: Scalars['Boolean'];
  readSuperEvents: Scalars['Boolean'];
  readTasks: Scalars['Boolean'];
  readUnreadNotifications: Scalars['Boolean'];
  recruitAdventure: AdventureConnectionType;
  removeAdventurer: AdventureType;
  reorderFavoriteDiceRolls: Scalars['Boolean'];
  reorderTasks: Scalars['Boolean'];
  rescheduleSession: SessionMutationType;
  scheduleSession: SessionMutationType;
  sendAccountCreateEmail: UserType;
  sendFeedbackEmail: Scalars['Boolean'];
  sendTipEmail: Scalars['Boolean'];
  setEpisodeFrequency: Scalars['Boolean'];
  shareJournalNote: JournalNoteShareReturnType;
  specialRequest: AdventureType;
  startSession: SessionMutationType;
  submitFile: Scalars['Boolean'];
  submitGiphy: Scalars['Boolean'];
  submitMessage: Scalars['Boolean'];
  submitSystemMessage: Scalars['Boolean'];
  toggleVoiceVideoStatus: Scalars['Boolean'];
  toggleWhisperStatus: Scalars['Boolean'];
  triggerDisableWhisper: Scalars['Boolean'];
  triggerEnableWhisper: Scalars['Boolean'];
  updateAdventureCoverImage: AdventureType;
  updateAdventureDetails: AdventureType;
  updateAdventureFrequency: AdventureType;
  updateAdventureGameAvailability: AdventureType;
  updateAdventureMatchMakingStatus: AdventureType;
  updateAdventurePlayerName: AdventurePlayerType;
  updateAdventureThemeScores: AdventureType;
  updateFavoriteDiceRoll: FavoriteDiceRollType;
  updateFirstLogin: UserType;
  updateGameMasterBio: UserType;
  updateGmRating: GmRatingType;
  updateJournal: JournalTitleType;
  updateJournalNote: JournalNoteType;
  updateJournalSuperEvent: JournalSuperEventType;
  updateLeaderHasViewed: AdventureType;
  updatePlayerRatings: Array<PlayerRatingType>;
  updatePreferences: UserType;
  updateTask: TaskType;
  updateUpload: UploadType;
  updateUserInformation: UserType;
  updateUserMetadata: UserMetadataType;
  updateUserRecruitment: UserRecruitmentType;
};


export type MutationAcceptInviteArgs = {
  connectionId: Scalars['String'];
};


export type MutationAcceptRecruitArgs = {
  connectionId: Scalars['String'];
};


export type MutationAcceptRequestArgs = {
  connectionId: Scalars['String'];
};


export type MutationAddAdventureSlimArgs = {
  adventureCost: Scalars['Float'];
  adventureName: Scalars['String'];
  gameTypeCode: Scalars['String'];
  invites: Scalars['String'];
  isPaid: Scalars['Boolean'];
};


export type MutationAddCreditCardArgs = {
  creditCard: CreditCardInput;
};


export type MutationAddJournalSuperEventArgs = {
  journalSuperEvent: JournalSuperEventInput;
};


export type MutationAddUserArgs = {
  user: UserInput;
};


export type MutationAttendEpisodeArgs = {
  sessionId: Scalars['String'];
};


export type MutationCalculateFeesWithTipArgs = {
  tip: TipInput;
};


export type MutationCancelPaymentArgs = {
  sessionId: Scalars['String'];
};


export type MutationCancelSessionArgs = {
  session: SessionCancelInput;
};


export type MutationCantMakeEpisodeArgs = {
  sessionId: Scalars['String'];
};


export type MutationCapturePaymentArgs = {
  sessionId: Scalars['String'];
};


export type MutationCaptureTipPaymentArgs = {
  tip: TipInput;
};


export type MutationCompleteRatingFlowArgs = {
  sessionId: Scalars['String'];
};


export type MutationCompleteTaskArgs = {
  id: Scalars['String'];
};


export type MutationContactGameMasterArgs = {
  adventureId: Scalars['String'];
};


export type MutationCreateFavoriteDiceRollArgs = {
  adventureId: Scalars['String'];
  diceRoll: Scalars['String'];
  rollName: Scalars['String'];
};


export type MutationCreateTaskArgs = {
  task: TaskCreateInput;
};


export type MutationCreateUploadArgs = {
  adventurePlayerId?: Maybe<Scalars['String']>;
  file: Scalars['String'];
  kind: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationDeclineInviteArgs = {
  connectionId: Scalars['String'];
};


export type MutationDeclineRecruitArgs = {
  connectionId: Scalars['String'];
};


export type MutationDeclineRequestArgs = {
  connectionId: Scalars['String'];
};


export type MutationDeclineTaskArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAdventureArgs = {
  adventureId: Scalars['String'];
};


export type MutationDeleteFavoriteDiceRollArgs = {
  adventureId: Scalars['String'];
  favoriteDiceRollId: Scalars['String'];
};


export type MutationDeleteJournalSuperEventArgs = {
  journalSuperEvent: JournalSuperEventDeleteInput;
};


export type MutationDeleteUploadArgs = {
  adventurePlayerId?: Maybe<Scalars['String']>;
};


export type MutationDisableAdventureMatchmakingArgs = {
  adventureId: Scalars['String'];
};


export type MutationEjectAdventureGameMasterArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationEnableAdventureMatchmakingArgs = {
  adventureId: Scalars['String'];
  adventurerCount: Scalars['Float'];
  autoJoin: Scalars['Boolean'];
  threshold: Scalars['Float'];
};


export type MutationGameplayNotificationArgs = {
  session: SessionGameplayNotificationInput;
};


export type MutationImmediatelyOpenSessionArgs = {
  session: SessionImmediateOpenInput;
};


export type MutationInsertPlayerAwardArgs = {
  awardType: Scalars['String'];
  sessionId: Scalars['String'];
  sessionPlayerId: Scalars['String'];
};


export type MutationInviteAdventurersArgs = {
  adventureId: Scalars['String'];
  invites: Scalars['String'];
};


export type MutationJoinAdventureRecruitArgs = {
  connectionId: Scalars['String'];
};


export type MutationJoinAdventureRequestArgs = {
  connectionId: Scalars['String'];
};


export type MutationJoinSessionArgs = {
  session: JoinSessionInput;
};


export type MutationLeaveAdventureArgs = {
  adventureId: Scalars['String'];
};


export type MutationLeaveRecruitArgs = {
  connectionId: Scalars['String'];
};


export type MutationLeaveRequestArgs = {
  connectionId: Scalars['String'];
};


export type MutationLeaveSessionArgs = {
  session: LeaveSessionInput;
};


export type MutationOpenSessionArgs = {
  adventureId: Scalars['String'];
};


export type MutationPreauthorizePaymentArgs = {
  sessionId: Scalars['String'];
};


export type MutationPrecalculateFeesArgs = {
  sessionId: Scalars['String'];
};


export type MutationPromoteAdventureGameMasterArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationReadAdventureConnectionsArgs = {
  adventureConnnectionIds: Array<Maybe<Scalars['String']>>;
  adventureId: Scalars['String'];
};


export type MutationReadNotificationArgs = {
  notificationId: Scalars['String'];
};


export type MutationReadNotificationByUrlArgs = {
  url: Scalars['String'];
};


export type MutationReadSharedNoteArgs = {
  adventureId: Scalars['String'];
  noteId: Scalars['String'];
};


export type MutationReadSuperEventsArgs = {
  adventureId: Scalars['String'];
  superEventIds: Array<Maybe<Scalars['String']>>;
};


export type MutationReadTasksArgs = {
  adventureId: Scalars['String'];
  taskIds: Array<Maybe<Scalars['String']>>;
};


export type MutationRecruitAdventureArgs = {
  adventureId: Scalars['String'];
  forGm: Scalars['Boolean'];
  recruitId: Scalars['String'];
};


export type MutationRemoveAdventurerArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationReorderFavoriteDiceRollsArgs = {
  adventureId: Scalars['String'];
  rollIds: Array<Maybe<Scalars['String']>>;
};


export type MutationReorderTasksArgs = {
  taskIds: Array<Maybe<Scalars['String']>>;
};


export type MutationRescheduleSessionArgs = {
  session: SessionRescheduleInput;
};


export type MutationScheduleSessionArgs = {
  session: SessionScheduleInput;
};


export type MutationSendFeedbackEmailArgs = {
  adventureId: Scalars['String'];
  feedback: Scalars['String'];
  gmRating: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSendTipEmailArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSetEpisodeFrequencyArgs = {
  adventureId: Scalars['String'];
  episodeFrequency: Scalars['Float'];
};


export type MutationShareJournalNoteArgs = {
  journalNote: JournalNoteShareInput;
};


export type MutationSpecialRequestArgs = {
  adventureId: Scalars['String'];
};


export type MutationStartSessionArgs = {
  session: SessionStartInput;
};


export type MutationSubmitFileArgs = {
  chatHash: Scalars['String'];
  file: Scalars['Upload'];
};


export type MutationSubmitGiphyArgs = {
  chatHash: Scalars['String'];
  url: Scalars['String'];
};


export type MutationSubmitMessageArgs = {
  chatHash: Scalars['String'];
  message: Scalars['String'];
};


export type MutationSubmitSystemMessageArgs = {
  chatHash: Scalars['String'];
  message: Scalars['String'];
};


export type MutationToggleVoiceVideoStatusArgs = {
  adventurePlayerId: Scalars['String'];
  inVideo: Scalars['Boolean'];
  inVoice: Scalars['Boolean'];
};


export type MutationToggleWhisperStatusArgs = {
  adventurePlayerId: Scalars['String'];
};


export type MutationTriggerDisableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


export type MutationTriggerEnableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


export type MutationUpdateAdventureCoverImageArgs = {
  adventureId: Scalars['String'];
  demicardId: Scalars['String'];
};


export type MutationUpdateAdventureDetailsArgs = {
  adventureId: Scalars['String'];
  adventuringPlatformId: Scalars['String'];
  description: Scalars['String'];
  gameLinkUrl: Scalars['String'];
  newPlayerFriendly: Scalars['Boolean'];
};


export type MutationUpdateAdventureFrequencyArgs = {
  adventureId: Scalars['String'];
  frequency: Scalars['String'];
};


export type MutationUpdateAdventureGameAvailabilityArgs = {
  adventureId: Scalars['String'];
  availabilities?: Maybe<Array<AdTimeInput>>;
  scheduledDate?: Maybe<Scalars['String']>;
};


export type MutationUpdateAdventureMatchMakingStatusArgs = {
  adventureId: Scalars['String'];
  matchMakingStatusAdvDetails?: Maybe<Scalars['Boolean']>;
  matchMakingStatusAdvImage?: Maybe<Scalars['Boolean']>;
  matchMakingStatusAdvType?: Maybe<Scalars['Boolean']>;
  matchMakingStatusGameAvail?: Maybe<Scalars['Boolean']>;
  matchMakingStatusPartyMgmt?: Maybe<Scalars['Boolean']>;
  matchMakingStatusThemeScores?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateAdventurePlayerNameArgs = {
  adventurePlayerId: Scalars['String'];
  playerName: Scalars['String'];
};


export type MutationUpdateAdventureThemeScoresArgs = {
  adventureId: Scalars['String'];
  combat: Scalars['Float'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
};


export type MutationUpdateFavoriteDiceRollArgs = {
  adventureId: Scalars['String'];
  favoriteDiceRollId: Scalars['String'];
  newDiceRoll: Scalars['String'];
  newRollName: Scalars['String'];
};


export type MutationUpdateFirstLoginArgs = {
  firstLogin: Scalars['Boolean'];
};


export type MutationUpdateGameMasterBioArgs = {
  gameMasterBio: Scalars['String'];
};


export type MutationUpdateGmRatingArgs = {
  rating: Scalars['Float'];
  sessionId: Scalars['String'];
  sessionPlayerId: Scalars['String'];
};


export type MutationUpdateJournalArgs = {
  journal: JournalUpdateInput;
};


export type MutationUpdateJournalNoteArgs = {
  journalNote: JournalNoteUpdateInput;
};


export type MutationUpdateJournalSuperEventArgs = {
  journalSuperEvent: JournalSuperEventUpdateInput;
};


export type MutationUpdateLeaderHasViewedArgs = {
  adventureId: Scalars['String'];
  isAdventurer: Scalars['Boolean'];
  isGm: Scalars['Boolean'];
};


export type MutationUpdatePlayerRatingsArgs = {
  ratings: Array<Maybe<PlayerRatingInput>>;
  sessionId: Scalars['String'];
};


export type MutationUpdatePreferencesArgs = {
  userPreferences: UserPreferenceInput;
};


export type MutationUpdateTaskArgs = {
  id: Scalars['String'];
  title: Scalars['String'];
};


export type MutationUpdateUploadArgs = {
  file: Scalars['String'];
  kind: Scalars['String'];
  mimeType: Scalars['String'];
  uploadId: Scalars['String'];
};


export type MutationUpdateUserInformationArgs = {
  userInformation: UserInformationInput;
};


export type MutationUpdateUserMetadataArgs = {
  userMetadata: UserMetadataInput;
};


export type MutationUpdateUserRecruitmentArgs = {
  userRecruitment: UserRecruitmentInput;
};

export type MyAdventuresType = {
  __typename?: 'MyAdventuresType';
  actives: Array<AdventureType>;
  defaults: Array<AdventureType>;
};

export type NotificationHistoryType = {
  __typename?: 'NotificationHistoryType';
  content: Scalars['String'];
  created: Scalars['DateTime'];
  heading: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  osId?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  submitor?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  url: Scalars['String'];
  user: Array<UserType>;
};

export type OpenEpisodeV2Output = {
  __typename?: 'OpenEpisodeV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type OtherAdventuresAdsOutput = {
  __typename?: 'OtherAdventuresAdsOutput';
  message: Scalars['String'];
  result: Scalars['json'];
  success: Scalars['Boolean'];
};

export type OtherAdventuresAdsResult = {
  __typename?: 'OtherAdventuresAdsResult';
  acceptedAdventurerCount: Scalars['Int'];
  adventureId: Scalars['String'];
  adventureShareLink: Scalars['String'];
  adventuringPlatformName: Scalars['String'];
  cost: Scalars['Float'];
  created: Scalars['timestamptz'];
  dowFriday: Scalars['timestamptz'];
  dowMonday: Scalars['timestamptz'];
  dowSaturday: Scalars['timestamptz'];
  dowSunday: Scalars['timestamptz'];
  dowThursday: Scalars['timestamptz'];
  dowTuesday: Scalars['timestamptz'];
  dowWednesday: Scalars['timestamptz'];
  frequency: Scalars['String'];
  gameTypeName: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  matchScore?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  openAdventurerCount: Scalars['Int'];
  quickMatch: Scalars['Boolean'];
  quickMatchDate: Scalars['timestamptz'];
  requestedAdventurerCount: Scalars['Int'];
};

export type ParticipantLeavesWhisperOutput = {
  __typename?: 'ParticipantLeavesWhisperOutput';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type PaymentType = {
  __typename?: 'PaymentType';
  authorized: Scalars['Boolean'];
  captured: Scalars['Boolean'];
  chargeAmount?: Maybe<Scalars['Float']>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  refunded: Scalars['Boolean'];
  session: SessionType;
  shouldCapture: Scalars['Boolean'];
  stripeChargeId: Scalars['String'];
  tipCharge: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  user: UserType;
};

export type PlayerAwardType = {
  __typename?: 'PlayerAwardType';
  awardType: AwardypeType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  votedPlayer: SessionPlayerType;
  votingPlayer: SessionPlayerType;
};

export type PlayerRatingInput = {
  id: Scalars['String'];
  rating: Scalars['Float'];
};

export type PlayerRatingType = {
  __typename?: 'PlayerRatingType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  rated: Scalars['Boolean'];
  ratedPlayer: SessionPlayerType;
  rating: Scalars['Float'];
  ratingPlayer: SessionPlayerType;
  updated: Scalars['DateTime'];
};

export type PromoteGameMasterV2Output = {
  __typename?: 'PromoteGameMasterV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type PronounType = {
  __typename?: 'PronounType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sortOrder: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  adventure: AdventureType;
  adventureActives: Array<AdventureType>;
  adventureDetails: AdventureType;
  adventureHosts: Array<AdventureType>;
  adventuringPlatforms: Array<AdventuringPlatformType>;
  almostFullAds: Array<SearchAdResultType>;
  appVersion: AppVersionType;
  chatToken: ChatTokenType;
  conversationParticipants: ConversationParticipantsType;
  creditCards: Array<CreditCardType>;
  currentUser: UserType;
  demicards: Array<AttachmentType>;
  earnings: EarningsType;
  emailExists: Scalars['Boolean'];
  favoriteDiceRolls: Array<FavoriteDiceRollType>;
  freeAds: Array<SearchAdResultType>;
  gameTypes: Array<GameTypeType>;
  getAdventureByLinkCode: AdventureType;
  getAdventureRecruits: Array<RecruitType>;
  getQuestRecruitSummary: RecruitSummaryType;
  journalNote: JournalNoteType;
  myAdventures: MyAdventuresType;
  newestAds: Array<SearchAdResultType>;
  notificationHistory: Array<NotificationHistoryType>;
  paymentAdventureHosts: Array<AdventureType>;
  paymentsByUserSession: Array<PaymentType>;
  playingSoonAds: Array<SearchAdResultType>;
  regionsByCountryAbbreviation: Array<RegionType>;
  searchAdDetail: SearchAdResultDetailType;
  searchAds: Array<SearchAdResultType>;
  session: SessionType;
  sessionHistories: Array<SessionHistoryType>;
  snippet: SnippetType;
  stripeConnectUrl: StripeLoginLinkType;
  stripeUserBalance: StripeUserBalanceType;
  stripeUserBalanceTransactions: Array<StripeBalanceTransactionType>;
  suggestedAds: Array<SearchAdResultType>;
  systemStatus: StatusType;
  upload?: Maybe<UploadType>;
  userAdventurePlayers: Array<AdventurePlayerType>;
  userConversations: UserConversationsType;
  userEmailNotificationPref: UserEmailNotificationPrefType;
  userPlayTime: UserPlayTimeType;
  userProfile: UserProfileType;
  userPushNotificationPref: UserPushNotificationPrefType;
  userRatingsAndAwards: UserRatingsAndAwardsType;
  userRecruitmentByUserId?: Maybe<UserRecruitmentType>;
  usernameExists: Scalars['Boolean'];
  validateUserAddress: UserAddressType;
};


export type QueryAdventureArgs = {
  id: Scalars['String'];
};


export type QueryAdventureDetailsArgs = {
  adventureId: Scalars['String'];
};


export type QueryConversationParticipantsArgs = {
  chatHash: Scalars['String'];
};


export type QueryEmailExistsArgs = {
  email: Scalars['String'];
};


export type QueryFavoriteDiceRollsArgs = {
  adventureId: Scalars['String'];
};


export type QueryGetAdventureByLinkCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetAdventureRecruitsArgs = {
  adventureId: Scalars['String'];
  displayCount: Scalars['Float'];
  excludeRecruited: Scalars['Boolean'];
  recruitForGameMaster: Scalars['Boolean'];
};


export type QueryGetQuestRecruitSummaryArgs = {
  questId: Scalars['String'];
};


export type QueryJournalNoteArgs = {
  adventureId: Scalars['String'];
  id: Scalars['String'];
  isShared: Scalars['Boolean'];
};


export type QueryNotificationHistoryArgs = {
  limit: Scalars['Float'];
};


export type QueryPaymentsByUserSessionArgs = {
  sessionId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryRegionsByCountryAbbreviationArgs = {
  countryCode: Scalars['String'];
};


export type QuerySearchAdDetailArgs = {
  adId: Scalars['String'];
};


export type QuerySearchAdsArgs = {
  adventuringPlatformIds: Array<Maybe<Scalars['String']>>;
  availability: Array<Maybe<AvailabilityInput>>;
  frequencyOneTime: Scalars['Boolean'];
  frequencyRecurring: Scalars['Boolean'];
  gameTypeIds: Array<Maybe<Scalars['String']>>;
  newPlayer: Scalars['Boolean'];
  noCost: Scalars['Boolean'];
  quickMatch: Scalars['Boolean'];
  recordCount: Scalars['Float'];
};


export type QuerySessionArgs = {
  id: Scalars['String'];
};


export type QuerySnippetArgs = {
  name: Scalars['String'];
};


export type QueryStripeUserBalanceTransactionsArgs = {
  createdAfter?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};


export type QueryUploadArgs = {
  adventurePlayerId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type QueryUserPlayTimeArgs = {
  userId: Scalars['String'];
};


export type QueryUserProfileArgs = {
  userId: Scalars['String'];
};


export type QueryUserRatingsAndAwardsArgs = {
  userId: Scalars['String'];
};


export type QueryUserRecruitmentByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryUsernameExistsArgs = {
  username: Scalars['String'];
};


export type QueryValidateUserAddressArgs = {
  userAddress: UserAddressInput;
};

export type RecruitAdventurerOutput = {
  __typename?: 'RecruitAdventurerOutput';
  hours: Scalars['Int'];
  id: Scalars['String'];
  score: Scalars['Float'];
  selected: Scalars['Boolean'];
  uploadUrl?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  votes: Scalars['Int'];
};

export type RecruitAdventurersOutput = {
  __typename?: 'RecruitAdventurersOutput';
  message: Scalars['String'];
  recruitAdventurers?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type RecruitGameMasterOutput = {
  __typename?: 'RecruitGameMasterOutput';
  hours: Scalars['Int'];
  id: Scalars['String'];
  score: Scalars['Float'];
  selected: Scalars['Boolean'];
  uploadUrl?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  votes: Scalars['Int'];
};

export type RecruitGameMastersOutput = {
  __typename?: 'RecruitGameMastersOutput';
  message: Scalars['String'];
  recruitGameMasters?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type RecruitSummaryType = {
  __typename?: 'RecruitSummaryType';
  playerAdventuringPlatformCount: Scalars['Float'];
  playerGameTypeCount: Scalars['Float'];
  questId: Scalars['ID'];
};

export type RecruitType = {
  __typename?: 'RecruitType';
  hours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  selected?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<UploadType>;
  username?: Maybe<Scalars['String']>;
  votes?: Maybe<Scalars['Float']>;
};

export type RegionType = {
  __typename?: 'RegionType';
  active: Scalars['Boolean'];
  code2: Scalars['String'];
  country?: Maybe<CountryType>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
  users: Array<UserType>;
};

export type RemoveAdventurerV2Output = {
  __typename?: 'RemoveAdventurerV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type ReorderFavoriteGamesOutput = {
  __typename?: 'ReorderFavoriteGamesOutput';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ReorderFavoritePlatformsOutput = {
  __typename?: 'ReorderFavoritePlatformsOutput';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RoleType = {
  __typename?: 'RoleType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  roleName: Scalars['String'];
  updated: Scalars['DateTime'];
  userAdventureRoles?: Maybe<Array<UserAdventureRoleType>>;
};

export type SearchAdDetailMmOutput = {
  __typename?: 'SearchAdDetailMMOutput';
  message: Scalars['String'];
  result: Scalars['json'];
  success: Scalars['Boolean'];
};

export type SearchAdResultDetailLeaderType = {
  __typename?: 'SearchAdResultDetailLeaderType';
  adventuringHours: Scalars['Float'];
  adventuringThumbsUp: Scalars['Float'];
  bio: Scalars['String'];
  hostingHours: Scalars['Float'];
  hostingThumbsUp: Scalars['Float'];
  id: Scalars['String'];
  mvpCount: Scalars['Float'];
  name: Scalars['String'];
  role: Scalars['String'];
  upload?: Maybe<UploadType>;
};

export type SearchAdResultDetailType = {
  __typename?: 'SearchAdResultDetailType';
  acceptedAdventurerCount: Scalars['Float'];
  adventureId?: Maybe<Scalars['String']>;
  adventureShareLink: Scalars['String'];
  adventuringPlatformName: Scalars['String'];
  combat: Scalars['Float'];
  cost: Scalars['Float'];
  description: Scalars['String'];
  dowFriday?: Maybe<Scalars['DateTime']>;
  dowMonday?: Maybe<Scalars['DateTime']>;
  dowSaturday?: Maybe<Scalars['DateTime']>;
  dowSunday?: Maybe<Scalars['DateTime']>;
  dowThursday?: Maybe<Scalars['DateTime']>;
  dowTuesday?: Maybe<Scalars['DateTime']>;
  dowWednesday?: Maybe<Scalars['DateTime']>;
  gameTypeName: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  leader: SearchAdResultDetailLeaderType;
  matchScore: Scalars['Float'];
  name: Scalars['String'];
  openAdventurerCount: Scalars['Float'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  quickMatch: Scalars['Boolean'];
  quickMatchDate?: Maybe<Scalars['DateTime']>;
  requestedAdventurerCount: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
  times?: Maybe<Array<AdTimeHasuraType>>;
};

export type SearchAdResultType = {
  __typename?: 'SearchAdResultType';
  acceptedAdventurerCount: Scalars['Float'];
  adventuringPlatformName: Scalars['String'];
  cost: Scalars['Float'];
  created: Scalars['DateTime'];
  dowFriday?: Maybe<Scalars['DateTime']>;
  dowMonday?: Maybe<Scalars['DateTime']>;
  dowSaturday?: Maybe<Scalars['DateTime']>;
  dowSunday?: Maybe<Scalars['DateTime']>;
  dowThursday?: Maybe<Scalars['DateTime']>;
  dowTuesday?: Maybe<Scalars['DateTime']>;
  dowWednesday?: Maybe<Scalars['DateTime']>;
  frequency: Scalars['String'];
  gameTypeName: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  matchScore: Scalars['Float'];
  name: Scalars['String'];
  openAdventurerCount: Scalars['Float'];
  quickMatch: Scalars['Boolean'];
  quickMatchDate?: Maybe<Scalars['DateTime']>;
  requestedAdventurerCount: Scalars['Float'];
};

export type SearchAdsMmOutput = {
  __typename?: 'SearchAdsMMOutput';
  message: Scalars['String'];
  result: Scalars['json'];
  success: Scalars['Boolean'];
};

export type SearchAdsMmResult = {
  __typename?: 'SearchAdsMMResult';
  acceptedAdventurerCount: Scalars['Int'];
  adventureId: Scalars['String'];
  adventureShareLink: Scalars['String'];
  adventuringPlatformName: Scalars['String'];
  cost: Scalars['Float'];
  created: Scalars['timestamptz'];
  dowFriday: Scalars['timestamptz'];
  dowMonday: Scalars['timestamptz'];
  dowSaturday: Scalars['timestamptz'];
  dowSunday: Scalars['timestamptz'];
  dowThursday: Scalars['timestamptz'];
  dowTuesday: Scalars['timestamptz'];
  dowWednesday: Scalars['timestamptz'];
  frequency: Scalars['String'];
  gameMaster: Scalars['String'];
  gameTypeName: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  matchScore: Scalars['Float'];
  name: Scalars['String'];
  openAdventurerCount: Scalars['Int'];
  quickMatch: Scalars['Boolean'];
  quickMatchDate: Scalars['timestamptz'];
  requestedAdventurerCount: Scalars['Int'];
};

export type SessionCancelInput = {
  id: Scalars['String'];
};

export type SessionGameplayNotificationInput = {
  id: Scalars['String'];
};

export type SessionHistoryTipType = {
  __typename?: 'SessionHistoryTipType';
  id: Scalars['ID'];
  tipAmount?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
};

export type SessionHistoryType = {
  __typename?: 'SessionHistoryType';
  adventureName?: Maybe<Scalars['String']>;
  demicardImage?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  gmRatings?: Maybe<Array<GmRatingType>>;
  id: Scalars['ID'];
  playerRatings?: Maybe<Array<PlayerRatingType>>;
  sessionAdventurerCount?: Maybe<Scalars['Float']>;
  sessionPaymentFeesCharged?: Maybe<Scalars['Float']>;
  sessionPaymentTips?: Maybe<Array<SessionHistoryTipType>>;
  sessionPaymentTipsCharged?: Maybe<Scalars['Float']>;
  sessionPaymentTotalCharged?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  wasGm: Scalars['Boolean'];
};

export type SessionImmediateOpenInput = {
  adventureId: Scalars['String'];
};

export type SessionMutationType = {
  __typename?: 'SessionMutationType';
  adventure: AdventureType;
  session: SessionType;
};

export type SessionNotificationType = {
  __typename?: 'SessionNotificationType';
  adventure: AdventureType;
  date: Scalars['DateTime'];
  session: SessionType;
};

export type SessionPlayerActiveType = {
  __typename?: 'SessionPlayerActiveType';
  adventurePlayerId: Scalars['String'];
  isActive: Scalars['Boolean'];
  joinDate?: Maybe<Scalars['DateTime']>;
  playerName?: Maybe<Scalars['String']>;
  realName: Scalars['String'];
  sessionPlayerId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type SessionPlayerMutationType = {
  __typename?: 'SessionPlayerMutationType';
  adventure: AdventureType;
  session: SessionType;
  sessionPlayer: SessionPlayerType;
};

export type SessionPlayerNotificationType = {
  __typename?: 'SessionPlayerNotificationType';
  adventure: AdventureType;
  date: Scalars['DateTime'];
  session: SessionType;
  sessionPlayer: SessionPlayerType;
};

export type SessionPlayerType = {
  __typename?: 'SessionPlayerType';
  combinedTaxRate?: Maybe<Scalars['Float']>;
  completedRatingFlow: Scalars['Boolean'];
  gmFee?: Maybe<Scalars['Float']>;
  gmRatings: Array<Maybe<GmRatingType>>;
  gmsRated: Array<Maybe<GmRatingType>>;
  id: Scalars['ID'];
  isGm: Scalars['Boolean'];
  joinDate?: Maybe<Scalars['DateTime']>;
  joinedSession: Scalars['Boolean'];
  leftDate?: Maybe<Scalars['DateTime']>;
  leftSession: Scalars['Boolean'];
  playerAwards: Array<Maybe<PlayerAwardType>>;
  playerRatings: Array<Maybe<PlayerRatingType>>;
  playersAwarded: Array<Maybe<PlayerAwardType>>;
  playersRated: Array<Maybe<PlayerRatingType>>;
  session: SessionType;
  taxAmount?: Maybe<Scalars['Float']>;
  tipAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  transactionFee?: Maybe<Scalars['Float']>;
  user: UserType;
};

export type SessionRescheduleInput = {
  scheduledDate: Scalars['DateTime'];
  sessionId: Scalars['String'];
};

export type SessionScheduleInput = {
  adventureId: Scalars['String'];
  scheduledDate: Scalars['DateTime'];
};

export type SessionStartInput = {
  id: Scalars['String'];
};

export type SessionType = {
  __typename?: 'SessionType';
  adventure: AdventureType;
  adventurerJoinedCount?: Maybe<Scalars['Float']>;
  adventurerTotalCount?: Maybe<Scalars['Float']>;
  cancelled: Scalars['Boolean'];
  cancelledDate?: Maybe<Scalars['DateTime']>;
  completed: Scalars['Boolean'];
  cost: Scalars['Float'];
  created: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  episodeAttendances: Array<Maybe<EpisoderAttendanceType>>;
  gmJoinedCount?: Maybe<Scalars['Float']>;
  gmLookup?: Maybe<Scalars['Boolean']>;
  gmTotalCount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  live: Scalars['Boolean'];
  open: Scalars['Boolean'];
  openDate?: Maybe<Scalars['DateTime']>;
  payments?: Maybe<Array<PaymentType>>;
  scheduledDate: Scalars['DateTime'];
  sessionPlayers: Array<SessionPlayerType>;
  sessionPlayersActive?: Maybe<Array<SessionPlayerActiveType>>;
  startDate?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
};

export type SnippetType = {
  __typename?: 'SnippetType';
  content: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type StartEpisodeV2Output = {
  __typename?: 'StartEpisodeV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type StatusType = {
  __typename?: 'StatusType';
  id: Scalars['ID'];
  status: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type StripeBalanceTransactionType = {
  __typename?: 'StripeBalanceTransactionType';
  amount?: Maybe<Scalars['Float']>;
  available_on?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  net?: Maybe<Scalars['Float']>;
  object?: Maybe<Scalars['String']>;
  reporting_category?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type StripeBalanceType = {
  __typename?: 'StripeBalanceType';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  source_types?: Maybe<StripeSourceTypesType>;
};

export type StripeLoginLinkType = {
  __typename?: 'StripeLoginLinkType';
  url?: Maybe<Scalars['String']>;
};

export type StripeSourceTypesType = {
  __typename?: 'StripeSourceTypesType';
  card?: Maybe<Scalars['Float']>;
};

export type StripeUserBalanceType = {
  __typename?: 'StripeUserBalanceType';
  available?: Maybe<Array<StripeBalanceType>>;
  pending?: Maybe<Array<StripeBalanceType>>;
};

export type SubmitFileV2Output = {
  __typename?: 'SubmitFileV2Output';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  adventurePlayerRemoved: AdventureNotificationType;
  disableWhisper: AdventurePlayerNotificationType;
  enableWhisper: AdventurePlayerNotificationType;
  sessionCancelled: SessionNotificationType;
  sessionEnded: SessionNotificationType;
  sessionGameplayNotification: SessionNotificationType;
  sessionJoined: SessionNotificationType;
  sessionLeft: SessionPlayerNotificationType;
  sessionOpened: SessionNotificationType;
  sessionRescheduled: SessionNotificationType;
  sessionScheduled: SessionNotificationType;
  sessionStarted: SessionNotificationType;
};


export type SubscriptionAdventurePlayerRemovedArgs = {
  adventurePlayerIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionDisableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


export type SubscriptionEnableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


export type SubscriptionSessionCancelledArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionEndedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionGameplayNotificationArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionJoinedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionLeftArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionOpenedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionRescheduledArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionScheduledArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


export type SubscriptionSessionStartedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};

export type TConversation = {
  __typename?: 'TConversation';
  createdAt: Scalars['Float'];
  custom?: Maybe<Scalars['json']>;
  id: Scalars['String'];
  participants: Scalars['json'];
  photoUrl?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  welcomeMessages?: Maybe<Array<Scalars['String']>>;
};

export type TaskCreateInput = {
  adventureId: Scalars['String'];
  title: Scalars['String'];
};

export type TaskReadStatusType = {
  __typename?: 'TaskReadStatusType';
  adventurePlayer: AdventurePlayerType;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  task: TaskType;
  updated: Scalars['DateTime'];
};

export type TaskType = {
  __typename?: 'TaskType';
  adventure: AdventureType;
  completed: Scalars['Boolean'];
  completedBy?: Maybe<AdventurePlayerType>;
  completedDate?: Maybe<Scalars['DateTime']>;
  created: Scalars['DateTime'];
  createdBy: AdventurePlayerType;
  declined: Scalars['Boolean'];
  declinedBy?: Maybe<AdventurePlayerType>;
  declinedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  readStatuses: Array<Maybe<TaskReadStatusType>>;
  sortOrder: Scalars['Float'];
  super: Scalars['Boolean'];
  title: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type TipInput = {
  sessionId: Scalars['String'];
  tipAmountInCents: Scalars['Float'];
};

export type TransactionDetailOutput = {
  __typename?: 'TransactionDetailOutput';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  transactionDetail?: Maybe<Scalars['json']>;
};

export type TransactionHistoryOutput = {
  __typename?: 'TransactionHistoryOutput';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  transactionHistory?: Maybe<Scalars['json']>;
};

export type TriggerDisableWhisperV2Output = {
  __typename?: 'TriggerDisableWhisperV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type TriggerEnableWhisperV2Output = {
  __typename?: 'TriggerEnableWhisperV2Output';
  adventureId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAdventure?: Maybe<DemiplaneAdventure>;
};

export type UpdateBillingAddressOutput = {
  __typename?: 'UpdateBillingAddressOutput';
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
  state: Scalars['String'];
  success: Scalars['Boolean'];
  userId: Scalars['String'];
  zipcode: Scalars['String'];
  zipcodePlus4Code: Scalars['String'];
};

export type UpdateUploadV2Output = {
  __typename?: 'UpdateUploadV2Output';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedUpload?: Maybe<DemiplaneUpload>;
  uploadId: Scalars['String'];
};

export type UpdateUserInformationV2Output = {
  __typename?: 'UpdateUserInformationV2Output';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  updatedUser?: Maybe<DemiplaneUser>;
  userId: Scalars['String'];
};


export type UploadType = {
  __typename?: 'UploadType';
  adventurePlayer?: Maybe<AdventurePlayerType>;
  created: Scalars['DateTime'];
  file: Scalars['String'];
  id: Scalars['ID'];
  kind: Scalars['String'];
  mimeType: Scalars['String'];
  updated: Scalars['DateTime'];
  user?: Maybe<UserType>;
};

export type UserAddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  region: Scalars['String'];
  zipcode: Scalars['String'];
};

export type UserAddressType = {
  __typename?: 'UserAddressType';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcodePlus4Code?: Maybe<Scalars['String']>;
};

export type UserAdventureRatingsOutput = {
  __typename?: 'UserAdventureRatingsOutput';
  message: Scalars['String'];
  result?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type UserAdventureRoleType = {
  __typename?: 'UserAdventureRoleType';
  adventure: AdventureType;
  deleted?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  role: RoleType;
  user: UserType;
};

export type UserConversationsType = {
  __typename?: 'UserConversationsType';
  conversations: Array<Maybe<TConversation>>;
};

export type UserEmailNotificationPrefType = {
  __typename?: 'UserEmailNotificationPrefType';
  adventureRallying: Scalars['Boolean'];
  adventureRescheduled: Scalars['Boolean'];
  adventureScheduled: Scalars['Boolean'];
  adventurerLeavesAdventure: Scalars['Boolean'];
  apLaunchesAdventure: Scalars['Boolean'];
  created: Scalars['DateTime'];
  createdFirstQuest: Scalars['Boolean'];
  id: Scalars['ID'];
  invitedToQuestOrParty: Scalars['Boolean'];
  postAdventureReminder: Scalars['Boolean'];
  questApCreatorLaunchesAdventure: Scalars['Boolean'];
  questLaunchesAdventure: Scalars['Boolean'];
  requestToJoinAcceptDecline: Scalars['Boolean'];
  requestToJoinReceived: Scalars['Boolean'];
  unreadNotifications: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  user: UserType;
  weeklyJournalAlert: Scalars['Boolean'];
  weeklyTaskAlert: Scalars['Boolean'];
};

export type UserInformationInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  dob: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  region: Scalars['String'];
  zipcode: Scalars['String'];
};

export type UserInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cognitoId: Scalars['String'];
  combat: Scalars['Float'];
  country: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  dob: Scalars['DateTime'];
  email: Scalars['String'];
  experience: Scalars['Float'];
  firstName: Scalars['String'];
  gameMasterBio: Scalars['String'];
  lastName: Scalars['String'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  region: Scalars['String'];
  rolePlaying: Scalars['Float'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
  stripeConnectId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  utmCampaign?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcodePlus4Code?: Maybe<Scalars['String']>;
};

export type UserInvitationType = {
  __typename?: 'UserInvitationType';
  id: Scalars['ID'];
  invitation: InvitationType;
  user: UserType;
};

export type UserMetadataInput = {
  acceptedTerms?: Maybe<Scalars['Boolean']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  viewedActiveGmMessage?: Maybe<Scalars['Boolean']>;
  viewedActivePlayerMessage?: Maybe<Scalars['Boolean']>;
  viewedAddToHomeScreen?: Maybe<Scalars['Boolean']>;
  viewedMatchedGmMessage?: Maybe<Scalars['Boolean']>;
  viewedMatchedPlayerMessage?: Maybe<Scalars['Boolean']>;
};

export type UserMetadataType = {
  __typename?: 'UserMetadataType';
  acceptedTerms: Scalars['Boolean'];
  id: Scalars['ID'];
  user?: Maybe<UserType>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  viewedActiveGmMessage: Scalars['Boolean'];
  viewedActivePlayerMessage: Scalars['Boolean'];
  viewedAddToHomeScreen: Scalars['Boolean'];
  viewedMatchedGmMessage: Scalars['Boolean'];
  viewedMatchedPlayerMessage: Scalars['Boolean'];
};

export type UserPlayTimeType = {
  __typename?: 'UserPlayTimeType';
  gmPlayTime: Scalars['Float'];
  playerPlayTime: Scalars['Float'];
  totalPlayTime: Scalars['Float'];
};

export type UserPreferenceInput = {
  combat: Scalars['Float'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
};

export type UserProfileType = {
  __typename?: 'UserProfileType';
  adventuringHours: Scalars['Float'];
  adventuringThumbsUp: Scalars['Float'];
  email: Scalars['String'];
  gameMasterBio: Scalars['String'];
  hostingHours: Scalars['Float'];
  hostingThumbsUp: Scalars['Float'];
  id: Scalars['ID'];
  mvpCount: Scalars['Float'];
  upload?: Maybe<UploadType>;
  username: Scalars['String'];
};

export type UserPushNotificationPrefType = {
  __typename?: 'UserPushNotificationPrefType';
  adventureIsRallying: Scalars['Boolean'];
  adventureReminders: Scalars['Boolean'];
  adventurerRemovedFromAdventure: Scalars['Boolean'];
  adventurerRequestsToJoin: Scalars['Boolean'];
  created: Scalars['DateTime'];
  gmAcceptsAdventurer: Scalars['Boolean'];
  gmDeniesAdventurer: Scalars['Boolean'];
  gmRecruitsAdventurer: Scalars['Boolean'];
  gmRequestsToHost: Scalars['Boolean'];
  id: Scalars['ID'];
  invitedToAdventure: Scalars['Boolean'];
  invitedToAdventuringParty: Scalars['Boolean'];
  invitedToQuest: Scalars['Boolean'];
  newChatAlert: Scalars['Boolean'];
  newJournalAlert: Scalars['Boolean'];
  partyLeaderAcceptsGM: Scalars['Boolean'];
  partyLeaderDeniesGM: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  user: UserType;
  userConnected: Scalars['Boolean'];
  userJoinsPortal: Scalars['Boolean'];
};

export type UserRatingsAndAwardsType = {
  __typename?: 'UserRatingsAndAwardsType';
  gmRating: Scalars['Float'];
  mvpAwards: Scalars['Float'];
  playerRating: Scalars['Float'];
};

export type UserRecruitmentAvailabilityType = {
  __typename?: 'UserRecruitmentAvailabilityType';
  created: Scalars['DateTime'];
  dow: Scalars['Float'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  startTime: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  userRecruitment: UserRecruitmentType;
};

export type UserRecruitmentInput = {
  adventurer: Scalars['Boolean'];
  adventuringPlatformIds: Array<Maybe<Scalars['String']>>;
  availabilities: Array<Maybe<Scalars['String']>>;
  availabilityStartOfWeek?: Maybe<Scalars['String']>;
  gameMaster: Scalars['Boolean'];
  gameTypeIds: Array<Maybe<Scalars['String']>>;
  maxMatchScore: Scalars['Float'];
  minMatchScore: Scalars['Float'];
  noCost: Scalars['Boolean'];
  status: Scalars['Boolean'];
};

export type UserRecruitmentType = {
  __typename?: 'UserRecruitmentType';
  adventurer: Scalars['Boolean'];
  adventuringPlatforms: Array<AdventuringPlatformType>;
  availabilities: Array<UserRecruitmentAvailabilityType>;
  availabilityStartOfWeek?: Maybe<Scalars['DateTime']>;
  created: Scalars['DateTime'];
  gameMaster: Scalars['Boolean'];
  gameTypes: Array<GameTypeType>;
  id: Scalars['ID'];
  maxMatchScore: Scalars['Float'];
  minMatchScore: Scalars['Float'];
  noCost: Scalars['Boolean'];
  status: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  user: UserType;
};

export type UserSocialProfilesOutput = {
  __typename?: 'UserSocialProfilesOutput';
  message: Scalars['String'];
  result: Scalars['json'];
  success: Scalars['Boolean'];
};

export type UserSocialProfilesResult = {
  __typename?: 'UserSocialProfilesResult';
  facebook_profile?: Maybe<Scalars['String']>;
  instagram_profile?: Maybe<Scalars['String']>;
  twitch_profile?: Maybe<Scalars['String']>;
  twitter_profile?: Maybe<Scalars['String']>;
};

export type UserTreasureTroveOutput = {
  __typename?: 'UserTreasureTroveOutput';
  message: Scalars['String'];
  result: Scalars['json'];
  success: Scalars['Boolean'];
};

export type UserType = {
  __typename?: 'UserType';
  accessLevel?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  adventurePlayers?: Maybe<Array<AdventurePlayerType>>;
  city?: Maybe<Scalars['String']>;
  cognitoId: Scalars['String'];
  combat: Scalars['Float'];
  contactorConnections?: Maybe<Array<AdventureConnectionType>>;
  country: CountryType;
  created: Scalars['DateTime'];
  creditCards?: Maybe<Array<CreditCardType>>;
  dob: Scalars['DateTime'];
  email: Scalars['String'];
  experience: Scalars['Float'];
  featureLevel?: Maybe<Scalars['String']>;
  firstLogin: Scalars['Boolean'];
  firstName: Scalars['String'];
  gameMasterBio: Scalars['String'];
  id: Scalars['ID'];
  inactiveMonthAlertedEmail: Scalars['Boolean'];
  inactiveTwoMonthAlertedEmail: Scalars['Boolean'];
  inactiveWeekAlertedEmail: Scalars['Boolean'];
  lastAccess: Scalars['DateTime'];
  lastName: Scalars['String'];
  newUserSecondEmail: Scalars['Boolean'];
  newUserThirdEmail: Scalars['Boolean'];
  notificationHistory?: Maybe<Array<NotificationHistoryType>>;
  notificationSubmissionHistory?: Maybe<Array<NotificationHistoryType>>;
  payments?: Maybe<Array<PaymentType>>;
  playerStory: Scalars['Float'];
  pronoun?: Maybe<PronounType>;
  puzzlesLogic: Scalars['Float'];
  recruitmentSetOrAlerted: Scalars['Boolean'];
  region: RegionType;
  requesteeConnections?: Maybe<Array<AdventureConnectionType>>;
  requestorConnections?: Maybe<Array<AdventureConnectionType>>;
  rolePlaying: Scalars['Float'];
  roles?: Maybe<Array<RoleType>>;
  sessionPlayers?: Maybe<Array<SessionPlayerType>>;
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
  stripeConnectId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  tutorialSeen: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  upload?: Maybe<UploadType>;
  userAdventureRoles?: Maybe<Array<UserAdventureRoleType>>;
  userEmailNotificationPref?: Maybe<UserEmailNotificationPrefType>;
  userInvitations?: Maybe<Array<UserInvitationType>>;
  userMetadata: UserMetadataType;
  userPushNotificationPref?: Maybe<UserPushNotificationPrefType>;
  userRecruitment?: Maybe<UserRecruitmentType>;
  username: Scalars['String'];
  zipcode?: Maybe<Scalars['String']>;
  zipcodePlus4Code?: Maybe<Scalars['String']>;
};

export type AuthMmOutput = {
  __typename?: 'authMMOutput';
  message: Scalars['String'];
  session?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type AuthMmTokenOutput = {
  __typename?: 'authMMTokenOutput';
  jwt: Scalars['json'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "demiplane.ad" */
export type DemiplaneAd = {
  __typename?: 'demiplane_ad';
  /** An object relationship */
  ad_link?: Maybe<DemiplaneAdLink>;
  ad_link_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  ad_requests: Array<DemiplaneAdRequest>;
  /** An aggregated array relationship */
  ad_requests_aggregate: DemiplaneAdRequestAggregate;
  /** An array relationship */
  ad_times: Array<DemiplaneAdTime>;
  /** An aggregated array relationship */
  ad_times_aggregate: DemiplaneAdTimeAggregate;
  /** An object relationship */
  adventure?: Maybe<DemiplaneAdventure>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventurer_count: Scalars['Int'];
  /** An object relationship */
  adventuring_platform: DemiplaneAdventuringPlatform;
  adventuring_platform_id: Scalars['bigint'];
  /** An object relationship */
  attachment?: Maybe<DemiplaneAttachment>;
  avatar: Scalars['String'];
  closed: Scalars['Boolean'];
  combat: Scalars['Int'];
  cost: Scalars['Int'];
  created: Scalars['timestamptz'];
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description: Scalars['String'];
  end_time?: Maybe<Scalars['time']>;
  frequency: Scalars['String'];
  friday?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  game_type: DemiplaneGameType;
  game_type_id: Scalars['bigint'];
  gm: Scalars['Boolean'];
  id: Scalars['bigint'];
  imminent: Scalars['Boolean'];
  imminent_launch_date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  invitations: Array<DemiplaneInvitation>;
  /** An aggregated array relationship */
  invitations_aggregate: DemiplaneInvitationAggregate;
  invites: Scalars['String'];
  monday?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  new_player: Scalars['Boolean'];
  /** An array relationship */
  notification_histories: Array<DemiplaneNotificationHistory>;
  /** An aggregated array relationship */
  notification_histories_aggregate: DemiplaneNotificationHistoryAggregate;
  outcome: Scalars['String'];
  player_story: Scalars['Int'];
  puzzles_logic: Scalars['Int'];
  role_playing: Scalars['Int'];
  saturday?: Maybe<Scalars['Boolean']>;
  search_enabled: Scalars['Boolean'];
  social_intrigue: Scalars['Int'];
  start_time?: Maybe<Scalars['time']>;
  strict_rules: Scalars['Int'];
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_id?: Maybe<Scalars['bigint']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdAdRequestsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdAdRequestsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdAdTimesArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdAdTimesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdInvitationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdInvitationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdNotificationHistoriesArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** columns and relationships of "demiplane.ad" */
export type DemiplaneAdNotificationHistoriesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};

/** aggregated selection of "demiplane.ad" */
export type DemiplaneAdAggregate = {
  __typename?: 'demiplane_ad_aggregate';
  aggregate?: Maybe<DemiplaneAdAggregateFields>;
  nodes: Array<DemiplaneAd>;
};

/** aggregate fields of "demiplane.ad" */
export type DemiplaneAdAggregateFields = {
  __typename?: 'demiplane_ad_aggregate_fields';
  avg?: Maybe<DemiplaneAdAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdMaxFields>;
  min?: Maybe<DemiplaneAdMinFields>;
  stddev?: Maybe<DemiplaneAdStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdStddevSampFields>;
  sum?: Maybe<DemiplaneAdSumFields>;
  var_pop?: Maybe<DemiplaneAdVarPopFields>;
  var_samp?: Maybe<DemiplaneAdVarSampFields>;
  variance?: Maybe<DemiplaneAdVarianceFields>;
};


/** aggregate fields of "demiplane.ad" */
export type DemiplaneAdAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.ad" */
export type DemiplaneAdAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdMaxOrderBy>;
  min?: Maybe<DemiplaneAdMinOrderBy>;
  stddev?: Maybe<DemiplaneAdStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.ad" */
export type DemiplaneAdArrRelInsertInput = {
  data: Array<DemiplaneAdInsertInput>;
  on_conflict?: Maybe<DemiplaneAdOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdAvgFields = {
  __typename?: 'demiplane_ad_avg_fields';
  ad_link_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  adventurer_count?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.ad" */
export type DemiplaneAdAvgOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.ad". All fields are combined with a logical 'AND'. */
export type DemiplaneAdBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdBoolExp>>>;
  _not?: Maybe<DemiplaneAdBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdBoolExp>>>;
  ad_link?: Maybe<DemiplaneAdLinkBoolExp>;
  ad_link_id?: Maybe<BigintComparisonExp>;
  ad_requests?: Maybe<DemiplaneAdRequestBoolExp>;
  ad_times?: Maybe<DemiplaneAdTimeBoolExp>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  adventurer_count?: Maybe<IntComparisonExp>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
  adventuring_platform_id?: Maybe<BigintComparisonExp>;
  attachment?: Maybe<DemiplaneAttachmentBoolExp>;
  avatar?: Maybe<StringComparisonExp>;
  closed?: Maybe<BooleanComparisonExp>;
  combat?: Maybe<IntComparisonExp>;
  cost?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  deleted?: Maybe<TimestampComparisonExp>;
  demicard_id?: Maybe<BigintComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  end_time?: Maybe<TimeComparisonExp>;
  frequency?: Maybe<StringComparisonExp>;
  friday?: Maybe<BooleanComparisonExp>;
  game_type?: Maybe<DemiplaneGameTypeBoolExp>;
  game_type_id?: Maybe<BigintComparisonExp>;
  gm?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  imminent?: Maybe<BooleanComparisonExp>;
  imminent_launch_date?: Maybe<TimestamptzComparisonExp>;
  invitations?: Maybe<DemiplaneInvitationBoolExp>;
  invites?: Maybe<StringComparisonExp>;
  monday?: Maybe<BooleanComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  new_player?: Maybe<BooleanComparisonExp>;
  notification_histories?: Maybe<DemiplaneNotificationHistoryBoolExp>;
  outcome?: Maybe<StringComparisonExp>;
  player_story?: Maybe<IntComparisonExp>;
  puzzles_logic?: Maybe<IntComparisonExp>;
  role_playing?: Maybe<IntComparisonExp>;
  saturday?: Maybe<BooleanComparisonExp>;
  search_enabled?: Maybe<BooleanComparisonExp>;
  social_intrigue?: Maybe<IntComparisonExp>;
  start_time?: Maybe<TimeComparisonExp>;
  strict_rules?: Maybe<IntComparisonExp>;
  sunday?: Maybe<BooleanComparisonExp>;
  thursday?: Maybe<BooleanComparisonExp>;
  tuesday?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  wednesday?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.ad" */
export enum DemiplaneAdConstraint {
  /** unique or primary key constraint */
  PkAdId = 'pk_ad_id',
  /** unique or primary key constraint */
  UqAdAdLinkId = 'uq_ad_ad_link_id'
}

/** input type for incrementing integer column in table "demiplane.ad" */
export type DemiplaneAdIncInput = {
  ad_link_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventurer_count?: Maybe<Scalars['Int']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  combat?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.ad" */
export type DemiplaneAdInsertInput = {
  ad_link?: Maybe<DemiplaneAdLinkObjRelInsertInput>;
  ad_link_id?: Maybe<Scalars['bigint']>;
  ad_requests?: Maybe<DemiplaneAdRequestArrRelInsertInput>;
  ad_times?: Maybe<DemiplaneAdTimeArrRelInsertInput>;
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventurer_count?: Maybe<Scalars['Int']>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformObjRelInsertInput>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  attachment?: Maybe<DemiplaneAttachmentObjRelInsertInput>;
  avatar?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  combat?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['time']>;
  frequency?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['Boolean']>;
  game_type?: Maybe<DemiplaneGameTypeObjRelInsertInput>;
  game_type_id?: Maybe<Scalars['bigint']>;
  gm?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  imminent?: Maybe<Scalars['Boolean']>;
  imminent_launch_date?: Maybe<Scalars['timestamptz']>;
  invitations?: Maybe<DemiplaneInvitationArrRelInsertInput>;
  invites?: Maybe<Scalars['String']>;
  monday?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  new_player?: Maybe<Scalars['Boolean']>;
  notification_histories?: Maybe<DemiplaneNotificationHistoryArrRelInsertInput>;
  outcome?: Maybe<Scalars['String']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  saturday?: Maybe<Scalars['Boolean']>;
  search_enabled?: Maybe<Scalars['Boolean']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['time']>;
  strict_rules?: Maybe<Scalars['Int']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "demiplane.ad_link" */
export type DemiplaneAdLink = {
  __typename?: 'demiplane_ad_link';
  /** An object relationship */
  ad?: Maybe<DemiplaneAd>;
  code: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.ad_link" */
export type DemiplaneAdLinkAggregate = {
  __typename?: 'demiplane_ad_link_aggregate';
  aggregate?: Maybe<DemiplaneAdLinkAggregateFields>;
  nodes: Array<DemiplaneAdLink>;
};

/** aggregate fields of "demiplane.ad_link" */
export type DemiplaneAdLinkAggregateFields = {
  __typename?: 'demiplane_ad_link_aggregate_fields';
  avg?: Maybe<DemiplaneAdLinkAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdLinkMaxFields>;
  min?: Maybe<DemiplaneAdLinkMinFields>;
  stddev?: Maybe<DemiplaneAdLinkStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdLinkStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdLinkStddevSampFields>;
  sum?: Maybe<DemiplaneAdLinkSumFields>;
  var_pop?: Maybe<DemiplaneAdLinkVarPopFields>;
  var_samp?: Maybe<DemiplaneAdLinkVarSampFields>;
  variance?: Maybe<DemiplaneAdLinkVarianceFields>;
};


/** aggregate fields of "demiplane.ad_link" */
export type DemiplaneAdLinkAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdLinkSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.ad_link" */
export type DemiplaneAdLinkAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdLinkAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdLinkMaxOrderBy>;
  min?: Maybe<DemiplaneAdLinkMinOrderBy>;
  stddev?: Maybe<DemiplaneAdLinkStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdLinkStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdLinkStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdLinkSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdLinkVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdLinkVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdLinkVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.ad_link" */
export type DemiplaneAdLinkArrRelInsertInput = {
  data: Array<DemiplaneAdLinkInsertInput>;
  on_conflict?: Maybe<DemiplaneAdLinkOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdLinkAvgFields = {
  __typename?: 'demiplane_ad_link_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.ad_link". All fields are combined with a logical 'AND'. */
export type DemiplaneAdLinkBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdLinkBoolExp>>>;
  _not?: Maybe<DemiplaneAdLinkBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdLinkBoolExp>>>;
  ad?: Maybe<DemiplaneAdBoolExp>;
  code?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.ad_link" */
export enum DemiplaneAdLinkConstraint {
  /** unique or primary key constraint */
  PkAdLinkId = 'pk_ad_link_id',
  /** unique or primary key constraint */
  UqAdLinkCode = 'uq_ad_link_code'
}

/** input type for incrementing integer column in table "demiplane.ad_link" */
export type DemiplaneAdLinkIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.ad_link" */
export type DemiplaneAdLinkInsertInput = {
  ad?: Maybe<DemiplaneAdObjRelInsertInput>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAdLinkMaxFields = {
  __typename?: 'demiplane_ad_link_max_fields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkMaxOrderBy = {
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdLinkMinFields = {
  __typename?: 'demiplane_ad_link_min_fields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkMinOrderBy = {
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.ad_link" */
export type DemiplaneAdLinkMutationResponse = {
  __typename?: 'demiplane_ad_link_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdLink>;
};

/** input type for inserting object relation for remote table "demiplane.ad_link" */
export type DemiplaneAdLinkObjRelInsertInput = {
  data: DemiplaneAdLinkInsertInput;
  on_conflict?: Maybe<DemiplaneAdLinkOnConflict>;
};

/** on conflict condition type for table "demiplane.ad_link" */
export type DemiplaneAdLinkOnConflict = {
  constraint: DemiplaneAdLinkConstraint;
  update_columns: Array<DemiplaneAdLinkUpdateColumn>;
  where?: Maybe<DemiplaneAdLinkBoolExp>;
};

/** ordering options when selecting data from "demiplane.ad_link" */
export type DemiplaneAdLinkOrderBy = {
  ad?: Maybe<DemiplaneAdOrderBy>;
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.ad_link" */
export type DemiplaneAdLinkPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.ad_link" */
export enum DemiplaneAdLinkSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.ad_link" */
export type DemiplaneAdLinkSetInput = {
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdLinkStddevFields = {
  __typename?: 'demiplane_ad_link_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdLinkStddevPopFields = {
  __typename?: 'demiplane_ad_link_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdLinkStddevSampFields = {
  __typename?: 'demiplane_ad_link_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdLinkSumFields = {
  __typename?: 'demiplane_ad_link_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.ad_link" */
export enum DemiplaneAdLinkUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdLinkVarPopFields = {
  __typename?: 'demiplane_ad_link_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdLinkVarSampFields = {
  __typename?: 'demiplane_ad_link_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdLinkVarianceFields = {
  __typename?: 'demiplane_ad_link_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.ad_link" */
export type DemiplaneAdLinkVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteria = {
  __typename?: 'demiplane_ad_match_criteria';
  /** An array relationship */
  ad_match_criteria_times: Array<DemiplaneAdMatchCriteriaTime>;
  /** An aggregated array relationship */
  ad_match_criteria_times_aggregate: DemiplaneAdMatchCriteriaTimeAggregate;
  /** An array relationship */
  ad_requests: Array<DemiplaneAdRequest>;
  /** An aggregated array relationship */
  ad_requests_aggregate: DemiplaneAdRequestAggregate;
  /** An array relationship */
  amc_adventuring_platforms: Array<DemiplaneAmcAdventuringPlatforms>;
  /** An aggregated array relationship */
  amc_adventuring_platforms_aggregate: DemiplaneAmcAdventuringPlatformsAggregate;
  /** An array relationship */
  amc_game_types: Array<DemiplaneAmcGameTypes>;
  /** An aggregated array relationship */
  amc_game_types_aggregate: DemiplaneAmcGameTypesAggregate;
  combat?: Maybe<Scalars['Int']>;
  created: Scalars['timestamptz'];
  end_time?: Maybe<Scalars['time']>;
  frequency?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['Boolean']>;
  gm?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  imminent?: Maybe<Scalars['Boolean']>;
  monday?: Maybe<Scalars['Boolean']>;
  new_player?: Maybe<Scalars['Boolean']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  paid_only?: Maybe<Scalars['Boolean']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  saturday?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['time']>;
  strict_rules?: Maybe<Scalars['Int']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  updated: Scalars['timestamptz'];
  wednesday?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAdMatchCriteriaTimesArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaTimeOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAdMatchCriteriaTimesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaTimeOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAdRequestsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAdRequestsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAmcAdventuringPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAmcAdventuringPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAmcGameTypesArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};


/** columns and relationships of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAmcGameTypesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};

/** aggregated selection of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAggregate = {
  __typename?: 'demiplane_ad_match_criteria_aggregate';
  aggregate?: Maybe<DemiplaneAdMatchCriteriaAggregateFields>;
  nodes: Array<DemiplaneAdMatchCriteria>;
};

/** aggregate fields of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAggregateFields = {
  __typename?: 'demiplane_ad_match_criteria_aggregate_fields';
  avg?: Maybe<DemiplaneAdMatchCriteriaAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdMatchCriteriaMaxFields>;
  min?: Maybe<DemiplaneAdMatchCriteriaMinFields>;
  stddev?: Maybe<DemiplaneAdMatchCriteriaStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdMatchCriteriaStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdMatchCriteriaStddevSampFields>;
  sum?: Maybe<DemiplaneAdMatchCriteriaSumFields>;
  var_pop?: Maybe<DemiplaneAdMatchCriteriaVarPopFields>;
  var_samp?: Maybe<DemiplaneAdMatchCriteriaVarSampFields>;
  variance?: Maybe<DemiplaneAdMatchCriteriaVarianceFields>;
};


/** aggregate fields of "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdMatchCriteriaSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdMatchCriteriaAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdMatchCriteriaMaxOrderBy>;
  min?: Maybe<DemiplaneAdMatchCriteriaMinOrderBy>;
  stddev?: Maybe<DemiplaneAdMatchCriteriaStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdMatchCriteriaStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdMatchCriteriaStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdMatchCriteriaSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdMatchCriteriaVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdMatchCriteriaVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdMatchCriteriaVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaArrRelInsertInput = {
  data: Array<DemiplaneAdMatchCriteriaInsertInput>;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdMatchCriteriaAvgFields = {
  __typename?: 'demiplane_ad_match_criteria_avg_fields';
  combat?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  result_count?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaAvgOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.ad_match_criteria". All fields are combined with a logical 'AND'. */
export type DemiplaneAdMatchCriteriaBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdMatchCriteriaBoolExp>>>;
  _not?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdMatchCriteriaBoolExp>>>;
  ad_match_criteria_times?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
  ad_requests?: Maybe<DemiplaneAdRequestBoolExp>;
  amc_adventuring_platforms?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
  amc_game_types?: Maybe<DemiplaneAmcGameTypesBoolExp>;
  combat?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  end_time?: Maybe<TimeComparisonExp>;
  frequency?: Maybe<StringComparisonExp>;
  friday?: Maybe<BooleanComparisonExp>;
  gm?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  imminent?: Maybe<BooleanComparisonExp>;
  monday?: Maybe<BooleanComparisonExp>;
  new_player?: Maybe<BooleanComparisonExp>;
  no_cost?: Maybe<BooleanComparisonExp>;
  paid_only?: Maybe<BooleanComparisonExp>;
  player_story?: Maybe<IntComparisonExp>;
  puzzles_logic?: Maybe<IntComparisonExp>;
  result_count?: Maybe<IntComparisonExp>;
  role_playing?: Maybe<IntComparisonExp>;
  saturday?: Maybe<BooleanComparisonExp>;
  skip?: Maybe<IntComparisonExp>;
  social_intrigue?: Maybe<IntComparisonExp>;
  start_time?: Maybe<TimeComparisonExp>;
  strict_rules?: Maybe<IntComparisonExp>;
  sunday?: Maybe<BooleanComparisonExp>;
  thursday?: Maybe<BooleanComparisonExp>;
  tuesday?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  wednesday?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.ad_match_criteria" */
export enum DemiplaneAdMatchCriteriaConstraint {
  /** unique or primary key constraint */
  PkAdMatchCriteriaId = 'pk_ad_match_criteria_id'
}

/** input type for incrementing integer column in table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaIncInput = {
  combat?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaInsertInput = {
  ad_match_criteria_times?: Maybe<DemiplaneAdMatchCriteriaTimeArrRelInsertInput>;
  ad_requests?: Maybe<DemiplaneAdRequestArrRelInsertInput>;
  amc_adventuring_platforms?: Maybe<DemiplaneAmcAdventuringPlatformsArrRelInsertInput>;
  amc_game_types?: Maybe<DemiplaneAmcGameTypesArrRelInsertInput>;
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['time']>;
  frequency?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['Boolean']>;
  gm?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  imminent?: Maybe<Scalars['Boolean']>;
  monday?: Maybe<Scalars['Boolean']>;
  new_player?: Maybe<Scalars['Boolean']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  paid_only?: Maybe<Scalars['Boolean']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  saturday?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['time']>;
  strict_rules?: Maybe<Scalars['Int']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type DemiplaneAdMatchCriteriaMaxFields = {
  __typename?: 'demiplane_ad_match_criteria_max_fields';
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaMaxOrderBy = {
  combat?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdMatchCriteriaMinFields = {
  __typename?: 'demiplane_ad_match_criteria_min_fields';
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaMinOrderBy = {
  combat?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaMutationResponse = {
  __typename?: 'demiplane_ad_match_criteria_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdMatchCriteria>;
};

/** input type for inserting object relation for remote table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaObjRelInsertInput = {
  data: DemiplaneAdMatchCriteriaInsertInput;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaOnConflict>;
};

/** on conflict condition type for table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaOnConflict = {
  constraint: DemiplaneAdMatchCriteriaConstraint;
  update_columns: Array<DemiplaneAdMatchCriteriaUpdateColumn>;
  where?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
};

/** ordering options when selecting data from "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaOrderBy = {
  ad_match_criteria_times_aggregate?: Maybe<DemiplaneAdMatchCriteriaTimeAggregateOrderBy>;
  ad_requests_aggregate?: Maybe<DemiplaneAdRequestAggregateOrderBy>;
  amc_adventuring_platforms_aggregate?: Maybe<DemiplaneAmcAdventuringPlatformsAggregateOrderBy>;
  amc_game_types_aggregate?: Maybe<DemiplaneAmcGameTypesAggregateOrderBy>;
  combat?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  friday?: Maybe<OrderBy>;
  gm?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imminent?: Maybe<OrderBy>;
  monday?: Maybe<OrderBy>;
  new_player?: Maybe<OrderBy>;
  no_cost?: Maybe<OrderBy>;
  paid_only?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  saturday?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  sunday?: Maybe<OrderBy>;
  thursday?: Maybe<OrderBy>;
  tuesday?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  wednesday?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.ad_match_criteria" */
export enum DemiplaneAdMatchCriteriaSelectColumn {
  /** column name */
  Combat = 'combat',
  /** column name */
  Created = 'created',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Friday = 'friday',
  /** column name */
  Gm = 'gm',
  /** column name */
  Id = 'id',
  /** column name */
  Imminent = 'imminent',
  /** column name */
  Monday = 'monday',
  /** column name */
  NewPlayer = 'new_player',
  /** column name */
  NoCost = 'no_cost',
  /** column name */
  PaidOnly = 'paid_only',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  ResultCount = 'result_count',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  Saturday = 'saturday',
  /** column name */
  Skip = 'skip',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  Sunday = 'sunday',
  /** column name */
  Thursday = 'thursday',
  /** column name */
  Tuesday = 'tuesday',
  /** column name */
  Updated = 'updated',
  /** column name */
  Wednesday = 'wednesday'
}

/** input type for updating data in table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaSetInput = {
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['time']>;
  frequency?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['Boolean']>;
  gm?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  imminent?: Maybe<Scalars['Boolean']>;
  monday?: Maybe<Scalars['Boolean']>;
  new_player?: Maybe<Scalars['Boolean']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  paid_only?: Maybe<Scalars['Boolean']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  saturday?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['time']>;
  strict_rules?: Maybe<Scalars['Int']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdMatchCriteriaStddevFields = {
  __typename?: 'demiplane_ad_match_criteria_stddev_fields';
  combat?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  result_count?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaStddevOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdMatchCriteriaStddevPopFields = {
  __typename?: 'demiplane_ad_match_criteria_stddev_pop_fields';
  combat?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  result_count?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaStddevPopOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdMatchCriteriaStddevSampFields = {
  __typename?: 'demiplane_ad_match_criteria_stddev_samp_fields';
  combat?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  result_count?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaStddevSampOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdMatchCriteriaSumFields = {
  __typename?: 'demiplane_ad_match_criteria_sum_fields';
  combat?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  result_count?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaSumOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTime = {
  __typename?: 'demiplane_ad_match_criteria_time';
  ad_match_criteria_id: Scalars['bigint'];
  /** An object relationship */
  ad_match_criterium: DemiplaneAdMatchCriteria;
  created: Scalars['timestamptz'];
  dow: Scalars['Int'];
  end_time: Scalars['timestamptz'];
  id: Scalars['bigint'];
  start_time: Scalars['timestamptz'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeAggregate = {
  __typename?: 'demiplane_ad_match_criteria_time_aggregate';
  aggregate?: Maybe<DemiplaneAdMatchCriteriaTimeAggregateFields>;
  nodes: Array<DemiplaneAdMatchCriteriaTime>;
};

/** aggregate fields of "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeAggregateFields = {
  __typename?: 'demiplane_ad_match_criteria_time_aggregate_fields';
  avg?: Maybe<DemiplaneAdMatchCriteriaTimeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdMatchCriteriaTimeMaxFields>;
  min?: Maybe<DemiplaneAdMatchCriteriaTimeMinFields>;
  stddev?: Maybe<DemiplaneAdMatchCriteriaTimeStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdMatchCriteriaTimeStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdMatchCriteriaTimeStddevSampFields>;
  sum?: Maybe<DemiplaneAdMatchCriteriaTimeSumFields>;
  var_pop?: Maybe<DemiplaneAdMatchCriteriaTimeVarPopFields>;
  var_samp?: Maybe<DemiplaneAdMatchCriteriaTimeVarSampFields>;
  variance?: Maybe<DemiplaneAdMatchCriteriaTimeVarianceFields>;
};


/** aggregate fields of "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdMatchCriteriaTimeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdMatchCriteriaTimeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdMatchCriteriaTimeMaxOrderBy>;
  min?: Maybe<DemiplaneAdMatchCriteriaTimeMinOrderBy>;
  stddev?: Maybe<DemiplaneAdMatchCriteriaTimeStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdMatchCriteriaTimeStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdMatchCriteriaTimeStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdMatchCriteriaTimeSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdMatchCriteriaTimeVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdMatchCriteriaTimeVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdMatchCriteriaTimeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeArrRelInsertInput = {
  data: Array<DemiplaneAdMatchCriteriaTimeInsertInput>;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaTimeOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdMatchCriteriaTimeAvgFields = {
  __typename?: 'demiplane_ad_match_criteria_time_avg_fields';
  ad_match_criteria_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeAvgOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.ad_match_criteria_time". All fields are combined with a logical 'AND'. */
export type DemiplaneAdMatchCriteriaTimeBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>>>;
  _not?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>>>;
  ad_match_criteria_id?: Maybe<BigintComparisonExp>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  dow?: Maybe<IntComparisonExp>;
  end_time?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  start_time?: Maybe<TimestamptzComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.ad_match_criteria_time" */
export enum DemiplaneAdMatchCriteriaTimeConstraint {
  /** unique or primary key constraint */
  PkAdMatchCriteriaTimeId = 'pk_ad_match_criteria_time_id'
}

/** input type for incrementing integer column in table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeIncInput = {
  ad_match_criteria_id?: Maybe<Scalars['bigint']>;
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeInsertInput = {
  ad_match_criteria_id?: Maybe<Scalars['bigint']>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaObjRelInsertInput>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAdMatchCriteriaTimeMaxFields = {
  __typename?: 'demiplane_ad_match_criteria_time_max_fields';
  ad_match_criteria_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeMaxOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdMatchCriteriaTimeMinFields = {
  __typename?: 'demiplane_ad_match_criteria_time_min_fields';
  ad_match_criteria_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeMinOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeMutationResponse = {
  __typename?: 'demiplane_ad_match_criteria_time_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdMatchCriteriaTime>;
};

/** input type for inserting object relation for remote table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeObjRelInsertInput = {
  data: DemiplaneAdMatchCriteriaTimeInsertInput;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaTimeOnConflict>;
};

/** on conflict condition type for table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeOnConflict = {
  constraint: DemiplaneAdMatchCriteriaTimeConstraint;
  update_columns: Array<DemiplaneAdMatchCriteriaTimeUpdateColumn>;
  where?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
};

/** ordering options when selecting data from "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaOrderBy>;
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.ad_match_criteria_time" */
export enum DemiplaneAdMatchCriteriaTimeSelectColumn {
  /** column name */
  AdMatchCriteriaId = 'ad_match_criteria_id',
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeSetInput = {
  ad_match_criteria_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdMatchCriteriaTimeStddevFields = {
  __typename?: 'demiplane_ad_match_criteria_time_stddev_fields';
  ad_match_criteria_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeStddevOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdMatchCriteriaTimeStddevPopFields = {
  __typename?: 'demiplane_ad_match_criteria_time_stddev_pop_fields';
  ad_match_criteria_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeStddevPopOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdMatchCriteriaTimeStddevSampFields = {
  __typename?: 'demiplane_ad_match_criteria_time_stddev_samp_fields';
  ad_match_criteria_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeStddevSampOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdMatchCriteriaTimeSumFields = {
  __typename?: 'demiplane_ad_match_criteria_time_sum_fields';
  ad_match_criteria_id?: Maybe<Scalars['bigint']>;
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeSumOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.ad_match_criteria_time" */
export enum DemiplaneAdMatchCriteriaTimeUpdateColumn {
  /** column name */
  AdMatchCriteriaId = 'ad_match_criteria_id',
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdMatchCriteriaTimeVarPopFields = {
  __typename?: 'demiplane_ad_match_criteria_time_var_pop_fields';
  ad_match_criteria_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeVarPopOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdMatchCriteriaTimeVarSampFields = {
  __typename?: 'demiplane_ad_match_criteria_time_var_samp_fields';
  ad_match_criteria_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeVarSampOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdMatchCriteriaTimeVarianceFields = {
  __typename?: 'demiplane_ad_match_criteria_time_variance_fields';
  ad_match_criteria_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.ad_match_criteria_time" */
export type DemiplaneAdMatchCriteriaTimeVarianceOrderBy = {
  ad_match_criteria_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.ad_match_criteria" */
export enum DemiplaneAdMatchCriteriaUpdateColumn {
  /** column name */
  Combat = 'combat',
  /** column name */
  Created = 'created',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Friday = 'friday',
  /** column name */
  Gm = 'gm',
  /** column name */
  Id = 'id',
  /** column name */
  Imminent = 'imminent',
  /** column name */
  Monday = 'monday',
  /** column name */
  NewPlayer = 'new_player',
  /** column name */
  NoCost = 'no_cost',
  /** column name */
  PaidOnly = 'paid_only',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  ResultCount = 'result_count',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  Saturday = 'saturday',
  /** column name */
  Skip = 'skip',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  Sunday = 'sunday',
  /** column name */
  Thursday = 'thursday',
  /** column name */
  Tuesday = 'tuesday',
  /** column name */
  Updated = 'updated',
  /** column name */
  Wednesday = 'wednesday'
}

/** aggregate var_pop on columns */
export type DemiplaneAdMatchCriteriaVarPopFields = {
  __typename?: 'demiplane_ad_match_criteria_var_pop_fields';
  combat?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  result_count?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaVarPopOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdMatchCriteriaVarSampFields = {
  __typename?: 'demiplane_ad_match_criteria_var_samp_fields';
  combat?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  result_count?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaVarSampOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdMatchCriteriaVarianceFields = {
  __typename?: 'demiplane_ad_match_criteria_variance_fields';
  combat?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  result_count?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.ad_match_criteria" */
export type DemiplaneAdMatchCriteriaVarianceOrderBy = {
  combat?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  result_count?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  skip?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type DemiplaneAdMaxFields = {
  __typename?: 'demiplane_ad_max_fields';
  ad_link_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventurer_count?: Maybe<Scalars['Int']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  imminent_launch_date?: Maybe<Scalars['timestamptz']>;
  invites?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.ad" */
export type DemiplaneAdMaxOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  avatar?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imminent_launch_date?: Maybe<OrderBy>;
  invites?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  outcome?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdMinFields = {
  __typename?: 'demiplane_ad_min_fields';
  ad_link_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventurer_count?: Maybe<Scalars['Int']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  imminent_launch_date?: Maybe<Scalars['timestamptz']>;
  invites?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outcome?: Maybe<Scalars['String']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.ad" */
export type DemiplaneAdMinOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  avatar?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imminent_launch_date?: Maybe<OrderBy>;
  invites?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  outcome?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.ad" */
export type DemiplaneAdMutationResponse = {
  __typename?: 'demiplane_ad_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAd>;
};

/** input type for inserting object relation for remote table "demiplane.ad" */
export type DemiplaneAdObjRelInsertInput = {
  data: DemiplaneAdInsertInput;
  on_conflict?: Maybe<DemiplaneAdOnConflict>;
};

/** on conflict condition type for table "demiplane.ad" */
export type DemiplaneAdOnConflict = {
  constraint: DemiplaneAdConstraint;
  update_columns: Array<DemiplaneAdUpdateColumn>;
  where?: Maybe<DemiplaneAdBoolExp>;
};

/** ordering options when selecting data from "demiplane.ad" */
export type DemiplaneAdOrderBy = {
  ad_link?: Maybe<DemiplaneAdLinkOrderBy>;
  ad_link_id?: Maybe<OrderBy>;
  ad_requests_aggregate?: Maybe<DemiplaneAdRequestAggregateOrderBy>;
  ad_times_aggregate?: Maybe<DemiplaneAdTimeAggregateOrderBy>;
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformOrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  attachment?: Maybe<DemiplaneAttachmentOrderBy>;
  avatar?: Maybe<OrderBy>;
  closed?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  friday?: Maybe<OrderBy>;
  game_type?: Maybe<DemiplaneGameTypeOrderBy>;
  game_type_id?: Maybe<OrderBy>;
  gm?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  imminent?: Maybe<OrderBy>;
  imminent_launch_date?: Maybe<OrderBy>;
  invitations_aggregate?: Maybe<DemiplaneInvitationAggregateOrderBy>;
  invites?: Maybe<OrderBy>;
  monday?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  new_player?: Maybe<OrderBy>;
  notification_histories_aggregate?: Maybe<DemiplaneNotificationHistoryAggregateOrderBy>;
  outcome?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  saturday?: Maybe<OrderBy>;
  search_enabled?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  sunday?: Maybe<OrderBy>;
  thursday?: Maybe<OrderBy>;
  tuesday?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  wednesday?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.ad" */
export type DemiplaneAdPkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.ad_request" */
export type DemiplaneAdRequest = {
  __typename?: 'demiplane_ad_request';
  accepted_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  ad: DemiplaneAd;
  ad_id: Scalars['bigint'];
  /** An object relationship */
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteria>;
  closed: Scalars['Boolean'];
  created: Scalars['timestamptz'];
  declined_date?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id: Scalars['bigint'];
  invited_date?: Maybe<Scalars['timestamptz']>;
  match_criteria_id?: Maybe<Scalars['bigint']>;
  outcome: Scalars['String'];
  read: Scalars['Boolean'];
  requested_date: Scalars['timestamptz'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.ad_request" */
export type DemiplaneAdRequestAggregate = {
  __typename?: 'demiplane_ad_request_aggregate';
  aggregate?: Maybe<DemiplaneAdRequestAggregateFields>;
  nodes: Array<DemiplaneAdRequest>;
};

/** aggregate fields of "demiplane.ad_request" */
export type DemiplaneAdRequestAggregateFields = {
  __typename?: 'demiplane_ad_request_aggregate_fields';
  avg?: Maybe<DemiplaneAdRequestAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdRequestMaxFields>;
  min?: Maybe<DemiplaneAdRequestMinFields>;
  stddev?: Maybe<DemiplaneAdRequestStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdRequestStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdRequestStddevSampFields>;
  sum?: Maybe<DemiplaneAdRequestSumFields>;
  var_pop?: Maybe<DemiplaneAdRequestVarPopFields>;
  var_samp?: Maybe<DemiplaneAdRequestVarSampFields>;
  variance?: Maybe<DemiplaneAdRequestVarianceFields>;
};


/** aggregate fields of "demiplane.ad_request" */
export type DemiplaneAdRequestAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.ad_request" */
export type DemiplaneAdRequestAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdRequestAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdRequestMaxOrderBy>;
  min?: Maybe<DemiplaneAdRequestMinOrderBy>;
  stddev?: Maybe<DemiplaneAdRequestStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdRequestStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdRequestStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdRequestSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdRequestVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdRequestVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdRequestVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.ad_request" */
export type DemiplaneAdRequestArrRelInsertInput = {
  data: Array<DemiplaneAdRequestInsertInput>;
  on_conflict?: Maybe<DemiplaneAdRequestOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdRequestAvgFields = {
  __typename?: 'demiplane_ad_request_avg_fields';
  ad_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  match_criteria_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestAvgOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.ad_request". All fields are combined with a logical 'AND'. */
export type DemiplaneAdRequestBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdRequestBoolExp>>>;
  _not?: Maybe<DemiplaneAdRequestBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdRequestBoolExp>>>;
  accepted_date?: Maybe<TimestamptzComparisonExp>;
  ad?: Maybe<DemiplaneAdBoolExp>;
  ad_id?: Maybe<BigintComparisonExp>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
  closed?: Maybe<BooleanComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  declined_date?: Maybe<TimestamptzComparisonExp>;
  deleted?: Maybe<TimestampComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  invited_date?: Maybe<TimestamptzComparisonExp>;
  match_criteria_id?: Maybe<BigintComparisonExp>;
  outcome?: Maybe<StringComparisonExp>;
  read?: Maybe<BooleanComparisonExp>;
  requested_date?: Maybe<TimestamptzComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.ad_request" */
export enum DemiplaneAdRequestConstraint {
  /** unique or primary key constraint */
  PkAdRequestId = 'pk_ad_request_id'
}

/** input type for incrementing integer column in table "demiplane.ad_request" */
export type DemiplaneAdRequestIncInput = {
  ad_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  match_criteria_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.ad_request" */
export type DemiplaneAdRequestInsertInput = {
  accepted_date?: Maybe<Scalars['timestamptz']>;
  ad?: Maybe<DemiplaneAdObjRelInsertInput>;
  ad_id?: Maybe<Scalars['bigint']>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaObjRelInsertInput>;
  closed?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  invited_date?: Maybe<Scalars['timestamptz']>;
  match_criteria_id?: Maybe<Scalars['bigint']>;
  outcome?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  requested_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneAdRequestMaxFields = {
  __typename?: 'demiplane_ad_request_max_fields';
  accepted_date?: Maybe<Scalars['timestamptz']>;
  ad_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  invited_date?: Maybe<Scalars['timestamptz']>;
  match_criteria_id?: Maybe<Scalars['bigint']>;
  outcome?: Maybe<Scalars['String']>;
  requested_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestMaxOrderBy = {
  accepted_date?: Maybe<OrderBy>;
  ad_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invited_date?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  outcome?: Maybe<OrderBy>;
  requested_date?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdRequestMinFields = {
  __typename?: 'demiplane_ad_request_min_fields';
  accepted_date?: Maybe<Scalars['timestamptz']>;
  ad_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  invited_date?: Maybe<Scalars['timestamptz']>;
  match_criteria_id?: Maybe<Scalars['bigint']>;
  outcome?: Maybe<Scalars['String']>;
  requested_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestMinOrderBy = {
  accepted_date?: Maybe<OrderBy>;
  ad_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invited_date?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  outcome?: Maybe<OrderBy>;
  requested_date?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.ad_request" */
export type DemiplaneAdRequestMutationResponse = {
  __typename?: 'demiplane_ad_request_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdRequest>;
};

/** input type for inserting object relation for remote table "demiplane.ad_request" */
export type DemiplaneAdRequestObjRelInsertInput = {
  data: DemiplaneAdRequestInsertInput;
  on_conflict?: Maybe<DemiplaneAdRequestOnConflict>;
};

/** on conflict condition type for table "demiplane.ad_request" */
export type DemiplaneAdRequestOnConflict = {
  constraint: DemiplaneAdRequestConstraint;
  update_columns: Array<DemiplaneAdRequestUpdateColumn>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};

/** ordering options when selecting data from "demiplane.ad_request" */
export type DemiplaneAdRequestOrderBy = {
  accepted_date?: Maybe<OrderBy>;
  ad?: Maybe<DemiplaneAdOrderBy>;
  ad_id?: Maybe<OrderBy>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaOrderBy>;
  closed?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invited_date?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  outcome?: Maybe<OrderBy>;
  read?: Maybe<OrderBy>;
  requested_date?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.ad_request" */
export type DemiplaneAdRequestPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.ad_request" */
export enum DemiplaneAdRequestSelectColumn {
  /** column name */
  AcceptedDate = 'accepted_date',
  /** column name */
  AdId = 'ad_id',
  /** column name */
  Closed = 'closed',
  /** column name */
  Created = 'created',
  /** column name */
  DeclinedDate = 'declined_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedDate = 'invited_date',
  /** column name */
  MatchCriteriaId = 'match_criteria_id',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  Read = 'read',
  /** column name */
  RequestedDate = 'requested_date',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.ad_request" */
export type DemiplaneAdRequestSetInput = {
  accepted_date?: Maybe<Scalars['timestamptz']>;
  ad_id?: Maybe<Scalars['bigint']>;
  closed?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  invited_date?: Maybe<Scalars['timestamptz']>;
  match_criteria_id?: Maybe<Scalars['bigint']>;
  outcome?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  requested_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdRequestStddevFields = {
  __typename?: 'demiplane_ad_request_stddev_fields';
  ad_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  match_criteria_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestStddevOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdRequestStddevPopFields = {
  __typename?: 'demiplane_ad_request_stddev_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  match_criteria_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestStddevPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdRequestStddevSampFields = {
  __typename?: 'demiplane_ad_request_stddev_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  match_criteria_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestStddevSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdRequestSumFields = {
  __typename?: 'demiplane_ad_request_sum_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  match_criteria_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestSumOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.ad_request" */
export enum DemiplaneAdRequestUpdateColumn {
  /** column name */
  AcceptedDate = 'accepted_date',
  /** column name */
  AdId = 'ad_id',
  /** column name */
  Closed = 'closed',
  /** column name */
  Created = 'created',
  /** column name */
  DeclinedDate = 'declined_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedDate = 'invited_date',
  /** column name */
  MatchCriteriaId = 'match_criteria_id',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  Read = 'read',
  /** column name */
  RequestedDate = 'requested_date',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneAdRequestVarPopFields = {
  __typename?: 'demiplane_ad_request_var_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  match_criteria_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestVarPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdRequestVarSampFields = {
  __typename?: 'demiplane_ad_request_var_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  match_criteria_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestVarSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdRequestVarianceFields = {
  __typename?: 'demiplane_ad_request_variance_fields';
  ad_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  match_criteria_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.ad_request" */
export type DemiplaneAdRequestVarianceOrderBy = {
  ad_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  match_criteria_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.ad" */
export enum DemiplaneAdSelectColumn {
  /** column name */
  AdLinkId = 'ad_link_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  AdventurerCount = 'adventurer_count',
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Closed = 'closed',
  /** column name */
  Combat = 'combat',
  /** column name */
  Cost = 'cost',
  /** column name */
  Created = 'created',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DemicardId = 'demicard_id',
  /** column name */
  Description = 'description',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Friday = 'friday',
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  Gm = 'gm',
  /** column name */
  Id = 'id',
  /** column name */
  Imminent = 'imminent',
  /** column name */
  ImminentLaunchDate = 'imminent_launch_date',
  /** column name */
  Invites = 'invites',
  /** column name */
  Monday = 'monday',
  /** column name */
  Name = 'name',
  /** column name */
  NewPlayer = 'new_player',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  Saturday = 'saturday',
  /** column name */
  SearchEnabled = 'search_enabled',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  Sunday = 'sunday',
  /** column name */
  Thursday = 'thursday',
  /** column name */
  Tuesday = 'tuesday',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Wednesday = 'wednesday'
}

/** input type for updating data in table "demiplane.ad" */
export type DemiplaneAdSetInput = {
  ad_link_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventurer_count?: Maybe<Scalars['Int']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  combat?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['time']>;
  frequency?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['Boolean']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  gm?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  imminent?: Maybe<Scalars['Boolean']>;
  imminent_launch_date?: Maybe<Scalars['timestamptz']>;
  invites?: Maybe<Scalars['String']>;
  monday?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  new_player?: Maybe<Scalars['Boolean']>;
  outcome?: Maybe<Scalars['String']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  saturday?: Maybe<Scalars['Boolean']>;
  search_enabled?: Maybe<Scalars['Boolean']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['time']>;
  strict_rules?: Maybe<Scalars['Int']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdStddevFields = {
  __typename?: 'demiplane_ad_stddev_fields';
  ad_link_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  adventurer_count?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.ad" */
export type DemiplaneAdStddevOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdStddevPopFields = {
  __typename?: 'demiplane_ad_stddev_pop_fields';
  ad_link_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  adventurer_count?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.ad" */
export type DemiplaneAdStddevPopOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdStddevSampFields = {
  __typename?: 'demiplane_ad_stddev_samp_fields';
  ad_link_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  adventurer_count?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.ad" */
export type DemiplaneAdStddevSampOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdSumFields = {
  __typename?: 'demiplane_ad_sum_fields';
  ad_link_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventurer_count?: Maybe<Scalars['Int']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  combat?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.ad" */
export type DemiplaneAdSumOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.ad_time" */
export type DemiplaneAdTime = {
  __typename?: 'demiplane_ad_time';
  /** An object relationship */
  ad?: Maybe<DemiplaneAd>;
  ad_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  adventure?: Maybe<DemiplaneAdventure>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created: Scalars['timestamptz'];
  dow: Scalars['Int'];
  end_time: Scalars['timestamptz'];
  id: Scalars['bigint'];
  start_time: Scalars['timestamptz'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.ad_time" */
export type DemiplaneAdTimeAggregate = {
  __typename?: 'demiplane_ad_time_aggregate';
  aggregate?: Maybe<DemiplaneAdTimeAggregateFields>;
  nodes: Array<DemiplaneAdTime>;
};

/** aggregate fields of "demiplane.ad_time" */
export type DemiplaneAdTimeAggregateFields = {
  __typename?: 'demiplane_ad_time_aggregate_fields';
  avg?: Maybe<DemiplaneAdTimeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdTimeMaxFields>;
  min?: Maybe<DemiplaneAdTimeMinFields>;
  stddev?: Maybe<DemiplaneAdTimeStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdTimeStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdTimeStddevSampFields>;
  sum?: Maybe<DemiplaneAdTimeSumFields>;
  var_pop?: Maybe<DemiplaneAdTimeVarPopFields>;
  var_samp?: Maybe<DemiplaneAdTimeVarSampFields>;
  variance?: Maybe<DemiplaneAdTimeVarianceFields>;
};


/** aggregate fields of "demiplane.ad_time" */
export type DemiplaneAdTimeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.ad_time" */
export type DemiplaneAdTimeAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdTimeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdTimeMaxOrderBy>;
  min?: Maybe<DemiplaneAdTimeMinOrderBy>;
  stddev?: Maybe<DemiplaneAdTimeStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdTimeStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdTimeStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdTimeSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdTimeVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdTimeVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdTimeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.ad_time" */
export type DemiplaneAdTimeArrRelInsertInput = {
  data: Array<DemiplaneAdTimeInsertInput>;
  on_conflict?: Maybe<DemiplaneAdTimeOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdTimeAvgFields = {
  __typename?: 'demiplane_ad_time_avg_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeAvgOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.ad_time". All fields are combined with a logical 'AND'. */
export type DemiplaneAdTimeBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdTimeBoolExp>>>;
  _not?: Maybe<DemiplaneAdTimeBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdTimeBoolExp>>>;
  ad?: Maybe<DemiplaneAdBoolExp>;
  ad_id?: Maybe<BigintComparisonExp>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  dow?: Maybe<IntComparisonExp>;
  end_time?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  start_time?: Maybe<TimestamptzComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.ad_time" */
export enum DemiplaneAdTimeConstraint {
  /** unique or primary key constraint */
  PkAdTimeId = 'pk_ad_time_id'
}

/** input type for incrementing integer column in table "demiplane.ad_time" */
export type DemiplaneAdTimeIncInput = {
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.ad_time" */
export type DemiplaneAdTimeInsertInput = {
  ad?: Maybe<DemiplaneAdObjRelInsertInput>;
  ad_id?: Maybe<Scalars['bigint']>;
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAdTimeMaxFields = {
  __typename?: 'demiplane_ad_time_max_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeMaxOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdTimeMinFields = {
  __typename?: 'demiplane_ad_time_min_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeMinOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.ad_time" */
export type DemiplaneAdTimeMutationResponse = {
  __typename?: 'demiplane_ad_time_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdTime>;
};

/** input type for inserting object relation for remote table "demiplane.ad_time" */
export type DemiplaneAdTimeObjRelInsertInput = {
  data: DemiplaneAdTimeInsertInput;
  on_conflict?: Maybe<DemiplaneAdTimeOnConflict>;
};

/** on conflict condition type for table "demiplane.ad_time" */
export type DemiplaneAdTimeOnConflict = {
  constraint: DemiplaneAdTimeConstraint;
  update_columns: Array<DemiplaneAdTimeUpdateColumn>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};

/** ordering options when selecting data from "demiplane.ad_time" */
export type DemiplaneAdTimeOrderBy = {
  ad?: Maybe<DemiplaneAdOrderBy>;
  ad_id?: Maybe<OrderBy>;
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.ad_time" */
export type DemiplaneAdTimePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.ad_time" */
export enum DemiplaneAdTimeSelectColumn {
  /** column name */
  AdId = 'ad_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.ad_time" */
export type DemiplaneAdTimeSetInput = {
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdTimeStddevFields = {
  __typename?: 'demiplane_ad_time_stddev_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeStddevOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdTimeStddevPopFields = {
  __typename?: 'demiplane_ad_time_stddev_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeStddevPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdTimeStddevSampFields = {
  __typename?: 'demiplane_ad_time_stddev_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeStddevSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdTimeSumFields = {
  __typename?: 'demiplane_ad_time_sum_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeSumOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.ad_time" */
export enum DemiplaneAdTimeUpdateColumn {
  /** column name */
  AdId = 'ad_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdTimeVarPopFields = {
  __typename?: 'demiplane_ad_time_var_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeVarPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdTimeVarSampFields = {
  __typename?: 'demiplane_ad_time_var_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeVarSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdTimeVarianceFields = {
  __typename?: 'demiplane_ad_time_variance_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.ad_time" */
export type DemiplaneAdTimeVarianceOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.ad" */
export enum DemiplaneAdUpdateColumn {
  /** column name */
  AdLinkId = 'ad_link_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  AdventurerCount = 'adventurer_count',
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Closed = 'closed',
  /** column name */
  Combat = 'combat',
  /** column name */
  Cost = 'cost',
  /** column name */
  Created = 'created',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DemicardId = 'demicard_id',
  /** column name */
  Description = 'description',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Friday = 'friday',
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  Gm = 'gm',
  /** column name */
  Id = 'id',
  /** column name */
  Imminent = 'imminent',
  /** column name */
  ImminentLaunchDate = 'imminent_launch_date',
  /** column name */
  Invites = 'invites',
  /** column name */
  Monday = 'monday',
  /** column name */
  Name = 'name',
  /** column name */
  NewPlayer = 'new_player',
  /** column name */
  Outcome = 'outcome',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  Saturday = 'saturday',
  /** column name */
  SearchEnabled = 'search_enabled',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  Sunday = 'sunday',
  /** column name */
  Thursday = 'thursday',
  /** column name */
  Tuesday = 'tuesday',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Wednesday = 'wednesday'
}

/** aggregate var_pop on columns */
export type DemiplaneAdVarPopFields = {
  __typename?: 'demiplane_ad_var_pop_fields';
  ad_link_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  adventurer_count?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.ad" */
export type DemiplaneAdVarPopOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdVarSampFields = {
  __typename?: 'demiplane_ad_var_samp_fields';
  ad_link_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  adventurer_count?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.ad" */
export type DemiplaneAdVarSampOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdVarianceFields = {
  __typename?: 'demiplane_ad_variance_fields';
  ad_link_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  adventurer_count?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.ad" */
export type DemiplaneAdVarianceOrderBy = {
  ad_link_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventurer_count?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventure = {
  __typename?: 'demiplane_adventure';
  accepted_inc_adv_cnt: Scalars['Int'];
  /** An array relationship */
  ad_times: Array<DemiplaneAdTime>;
  /** An aggregated array relationship */
  ad_times_aggregate: DemiplaneAdTimeAggregate;
  /** An array relationship */
  ads: Array<DemiplaneAd>;
  /** An aggregated array relationship */
  ads_aggregate: DemiplaneAdAggregate;
  /** An array relationship */
  adventure_connections: Array<DemiplaneAdventureConnection>;
  /** An aggregated array relationship */
  adventure_connections_aggregate: DemiplaneAdventureConnectionAggregate;
  /** An object relationship */
  adventure_link?: Maybe<DemiplaneAdventureLink>;
  adventure_link_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  adventure_players: Array<DemiplaneAdventurePlayer>;
  /** An aggregated array relationship */
  adventure_players_aggregate: DemiplaneAdventurePlayerAggregate;
  /** An array relationship */
  adventure_todos: Array<DemiplaneAdventureTodo>;
  /** An aggregated array relationship */
  adventure_todos_aggregate: DemiplaneAdventureTodoAggregate;
  /** An object relationship */
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatform>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  attachment?: Maybe<DemiplaneAttachment>;
  auto_join: Scalars['Boolean'];
  auto_join_threshold?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  combat: Scalars['Int'];
  created: Scalars['timestamptz'];
  default_adventure: Scalars['Boolean'];
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  episode_frequency?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  game_cost: Scalars['Int'];
  game_link_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  game_type?: Maybe<DemiplaneGameType>;
  game_type_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  gm_recommendations: Array<DemiplaneGmRecommendation>;
  /** An aggregated array relationship */
  gm_recommendations_aggregate: DemiplaneGmRecommendationAggregate;
  has_leader_viewed: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  invitations: Array<DemiplaneInvitation>;
  /** An aggregated array relationship */
  invitations_aggregate: DemiplaneInvitationAggregate;
  match_making_enabled: Scalars['Boolean'];
  match_making_status_adv_details: Scalars['Boolean'];
  match_making_status_adv_image: Scalars['Boolean'];
  match_making_status_adv_type: Scalars['Boolean'];
  match_making_status_game_avail: Scalars['Boolean'];
  match_making_status_party_mgmt: Scalars['Boolean'];
  match_making_status_theme_scores: Scalars['Boolean'];
  name: Scalars['String'];
  new_player_friendly: Scalars['Boolean'];
  /** An array relationship */
  notification_histories: Array<DemiplaneNotificationHistory>;
  /** An aggregated array relationship */
  notification_histories_aggregate: DemiplaneNotificationHistoryAggregate;
  open_inc_adv_cnt: Scalars['Int'];
  paid_flag: Scalars['Boolean'];
  player_story: Scalars['Int'];
  puzzles_logic: Scalars['Int'];
  requested_inc_adv_cnt: Scalars['Int'];
  role_playing: Scalars['Int'];
  /** An array relationship */
  sessions: Array<DemiplaneSession>;
  /** An aggregated array relationship */
  sessions_aggregate: DemiplaneSessionAggregate;
  social_intrigue: Scalars['Int'];
  strict_rules: Scalars['Int'];
  /** An array relationship */
  tasks: Array<DemiplaneTask>;
  /** An aggregated array relationship */
  tasks_aggregate: DemiplaneTaskAggregate;
  total_act_adv_cnt: Scalars['Int'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  /** An array relationship */
  user_adventure_rankings: Array<DemiplaneUserAdventureRanking>;
  /** An aggregated array relationship */
  user_adventure_rankings_aggregate: DemiplaneUserAdventureRankingAggregate;
  /** An array relationship */
  user_adventure_roles: Array<DemiplaneUserAdventureRole>;
  /** An aggregated array relationship */
  user_adventure_roles_aggregate: DemiplaneUserAdventureRoleAggregate;
  /** An array relationship */
  user_adventures: Array<DemiplaneUserAdventure>;
  /** An aggregated array relationship */
  user_adventures_aggregate: DemiplaneUserAdventureAggregate;
  user_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdTimesArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdTimesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdventureConnectionsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdventureConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdventurePlayersArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdventurePlayersAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdventureTodosArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureTodoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureTodoOrderBy>>;
  where?: Maybe<DemiplaneAdventureTodoBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureAdventureTodosAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureTodoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureTodoOrderBy>>;
  where?: Maybe<DemiplaneAdventureTodoBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureGmRecommendationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureGmRecommendationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureInvitationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureInvitationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureNotificationHistoriesArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureNotificationHistoriesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureSessionsArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionOrderBy>>;
  where?: Maybe<DemiplaneSessionBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureSessionsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionOrderBy>>;
  where?: Maybe<DemiplaneSessionBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureTasksArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureTasksAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureUserAdventureRankingsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureUserAdventureRankingsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureUserAdventureRolesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureUserAdventureRolesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureUserAdventuresArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};


/** columns and relationships of "demiplane.adventure" */
export type DemiplaneAdventureUserAdventuresAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};

/** aggregated selection of "demiplane.adventure" */
export type DemiplaneAdventureAggregate = {
  __typename?: 'demiplane_adventure_aggregate';
  aggregate?: Maybe<DemiplaneAdventureAggregateFields>;
  nodes: Array<DemiplaneAdventure>;
};

/** aggregate fields of "demiplane.adventure" */
export type DemiplaneAdventureAggregateFields = {
  __typename?: 'demiplane_adventure_aggregate_fields';
  avg?: Maybe<DemiplaneAdventureAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdventureMaxFields>;
  min?: Maybe<DemiplaneAdventureMinFields>;
  stddev?: Maybe<DemiplaneAdventureStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdventureStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdventureStddevSampFields>;
  sum?: Maybe<DemiplaneAdventureSumFields>;
  var_pop?: Maybe<DemiplaneAdventureVarPopFields>;
  var_samp?: Maybe<DemiplaneAdventureVarSampFields>;
  variance?: Maybe<DemiplaneAdventureVarianceFields>;
};


/** aggregate fields of "demiplane.adventure" */
export type DemiplaneAdventureAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.adventure" */
export type DemiplaneAdventureAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdventureAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdventureMaxOrderBy>;
  min?: Maybe<DemiplaneAdventureMinOrderBy>;
  stddev?: Maybe<DemiplaneAdventureStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdventureStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdventureStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdventureSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdventureVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdventureVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdventureVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.adventure" */
export type DemiplaneAdventureArrRelInsertInput = {
  data: Array<DemiplaneAdventureInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdventureAvgFields = {
  __typename?: 'demiplane_adventure_avg_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Float']>;
  adventure_link_id?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  auto_join_threshold?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  episode_frequency?: Maybe<Scalars['Float']>;
  game_cost?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  open_inc_adv_cnt?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  total_act_adv_cnt?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureAvgOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.adventure". All fields are combined with a logical 'AND'. */
export type DemiplaneAdventureBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdventureBoolExp>>>;
  _not?: Maybe<DemiplaneAdventureBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdventureBoolExp>>>;
  accepted_inc_adv_cnt?: Maybe<IntComparisonExp>;
  ad_times?: Maybe<DemiplaneAdTimeBoolExp>;
  ads?: Maybe<DemiplaneAdBoolExp>;
  adventure_connections?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  adventure_link?: Maybe<DemiplaneAdventureLinkBoolExp>;
  adventure_link_id?: Maybe<BigintComparisonExp>;
  adventure_players?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_todos?: Maybe<DemiplaneAdventureTodoBoolExp>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
  adventuring_platform_id?: Maybe<BigintComparisonExp>;
  attachment?: Maybe<DemiplaneAttachmentBoolExp>;
  auto_join?: Maybe<BooleanComparisonExp>;
  auto_join_threshold?: Maybe<IntComparisonExp>;
  avatar?: Maybe<StringComparisonExp>;
  combat?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  default_adventure?: Maybe<BooleanComparisonExp>;
  deleted?: Maybe<TimestampComparisonExp>;
  demicard_id?: Maybe<BigintComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  episode_frequency?: Maybe<IntComparisonExp>;
  frequency?: Maybe<StringComparisonExp>;
  game_cost?: Maybe<IntComparisonExp>;
  game_link_url?: Maybe<StringComparisonExp>;
  game_type?: Maybe<DemiplaneGameTypeBoolExp>;
  game_type_id?: Maybe<BigintComparisonExp>;
  gm_recommendations?: Maybe<DemiplaneGmRecommendationBoolExp>;
  has_leader_viewed?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  invitations?: Maybe<DemiplaneInvitationBoolExp>;
  match_making_enabled?: Maybe<BooleanComparisonExp>;
  match_making_status_adv_details?: Maybe<BooleanComparisonExp>;
  match_making_status_adv_image?: Maybe<BooleanComparisonExp>;
  match_making_status_adv_type?: Maybe<BooleanComparisonExp>;
  match_making_status_game_avail?: Maybe<BooleanComparisonExp>;
  match_making_status_party_mgmt?: Maybe<BooleanComparisonExp>;
  match_making_status_theme_scores?: Maybe<BooleanComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  new_player_friendly?: Maybe<BooleanComparisonExp>;
  notification_histories?: Maybe<DemiplaneNotificationHistoryBoolExp>;
  open_inc_adv_cnt?: Maybe<IntComparisonExp>;
  paid_flag?: Maybe<BooleanComparisonExp>;
  player_story?: Maybe<IntComparisonExp>;
  puzzles_logic?: Maybe<IntComparisonExp>;
  requested_inc_adv_cnt?: Maybe<IntComparisonExp>;
  role_playing?: Maybe<IntComparisonExp>;
  sessions?: Maybe<DemiplaneSessionBoolExp>;
  social_intrigue?: Maybe<IntComparisonExp>;
  strict_rules?: Maybe<IntComparisonExp>;
  tasks?: Maybe<DemiplaneTaskBoolExp>;
  total_act_adv_cnt?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_adventure_rankings?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
  user_adventure_roles?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
  user_adventures?: Maybe<DemiplaneUserAdventureBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** columns and relationships of "demiplane.adventure_connection" */
export type DemiplaneAdventureConnection = {
  __typename?: 'demiplane_adventure_connection';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  /** An object relationship */
  connection_status?: Maybe<DemiplaneConnectionStatus>;
  connection_status_date?: Maybe<Scalars['timestamptz']>;
  connection_status_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  connection_type?: Maybe<DemiplaneConnectionType>;
  connection_type_date?: Maybe<Scalars['timestamptz']>;
  connection_type_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  contact_type: DemiplaneContactType;
  contact_type_date: Scalars['timestamptz'];
  contact_type_id: Scalars['bigint'];
  /** An object relationship */
  contactor?: Maybe<DemiplaneUser>;
  contactor_id?: Maybe<Scalars['bigint']>;
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  requestee?: Maybe<DemiplaneUser>;
  requestee_approval: Scalars['Boolean'];
  requestee_email: Scalars['String'];
  requestee_id?: Maybe<Scalars['bigint']>;
  requestee_read: Scalars['Boolean'];
  /** An object relationship */
  requestor?: Maybe<DemiplaneUser>;
  requestor_approval: Scalars['Boolean'];
  requestor_id?: Maybe<Scalars['bigint']>;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionAggregate = {
  __typename?: 'demiplane_adventure_connection_aggregate';
  aggregate?: Maybe<DemiplaneAdventureConnectionAggregateFields>;
  nodes: Array<DemiplaneAdventureConnection>;
};

/** aggregate fields of "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionAggregateFields = {
  __typename?: 'demiplane_adventure_connection_aggregate_fields';
  avg?: Maybe<DemiplaneAdventureConnectionAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdventureConnectionMaxFields>;
  min?: Maybe<DemiplaneAdventureConnectionMinFields>;
  stddev?: Maybe<DemiplaneAdventureConnectionStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdventureConnectionStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdventureConnectionStddevSampFields>;
  sum?: Maybe<DemiplaneAdventureConnectionSumFields>;
  var_pop?: Maybe<DemiplaneAdventureConnectionVarPopFields>;
  var_samp?: Maybe<DemiplaneAdventureConnectionVarSampFields>;
  variance?: Maybe<DemiplaneAdventureConnectionVarianceFields>;
};


/** aggregate fields of "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdventureConnectionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdventureConnectionMaxOrderBy>;
  min?: Maybe<DemiplaneAdventureConnectionMinOrderBy>;
  stddev?: Maybe<DemiplaneAdventureConnectionStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdventureConnectionStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdventureConnectionStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdventureConnectionSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdventureConnectionVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdventureConnectionVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdventureConnectionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionArrRelInsertInput = {
  data: Array<DemiplaneAdventureConnectionInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureConnectionOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdventureConnectionAvgFields = {
  __typename?: 'demiplane_adventure_connection_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  connection_status_id?: Maybe<Scalars['Float']>;
  connection_type_id?: Maybe<Scalars['Float']>;
  contact_type_id?: Maybe<Scalars['Float']>;
  contactor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestee_id?: Maybe<Scalars['Float']>;
  requestor_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.adventure_connection". All fields are combined with a logical 'AND'. */
export type DemiplaneAdventureConnectionBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdventureConnectionBoolExp>>>;
  _not?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdventureConnectionBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  connection_status?: Maybe<DemiplaneConnectionStatusBoolExp>;
  connection_status_date?: Maybe<TimestamptzComparisonExp>;
  connection_status_id?: Maybe<BigintComparisonExp>;
  connection_type?: Maybe<DemiplaneConnectionTypeBoolExp>;
  connection_type_date?: Maybe<TimestamptzComparisonExp>;
  connection_type_id?: Maybe<BigintComparisonExp>;
  contact_type?: Maybe<DemiplaneContactTypeBoolExp>;
  contact_type_date?: Maybe<TimestamptzComparisonExp>;
  contact_type_id?: Maybe<BigintComparisonExp>;
  contactor?: Maybe<DemiplaneUserBoolExp>;
  contactor_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  requestee?: Maybe<DemiplaneUserBoolExp>;
  requestee_approval?: Maybe<BooleanComparisonExp>;
  requestee_email?: Maybe<StringComparisonExp>;
  requestee_id?: Maybe<BigintComparisonExp>;
  requestee_read?: Maybe<BooleanComparisonExp>;
  requestor?: Maybe<DemiplaneUserBoolExp>;
  requestor_approval?: Maybe<BooleanComparisonExp>;
  requestor_id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.adventure_connection" */
export enum DemiplaneAdventureConnectionConstraint {
  /** unique or primary key constraint */
  PkAdventureConnectionId = 'pk_adventure_connection_id'
}

/** input type for incrementing integer column in table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  connection_status_id?: Maybe<Scalars['bigint']>;
  connection_type_id?: Maybe<Scalars['bigint']>;
  contact_type_id?: Maybe<Scalars['bigint']>;
  contactor_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  requestee_id?: Maybe<Scalars['bigint']>;
  requestor_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  connection_status?: Maybe<DemiplaneConnectionStatusObjRelInsertInput>;
  connection_status_date?: Maybe<Scalars['timestamptz']>;
  connection_status_id?: Maybe<Scalars['bigint']>;
  connection_type?: Maybe<DemiplaneConnectionTypeObjRelInsertInput>;
  connection_type_date?: Maybe<Scalars['timestamptz']>;
  connection_type_id?: Maybe<Scalars['bigint']>;
  contact_type?: Maybe<DemiplaneContactTypeObjRelInsertInput>;
  contact_type_date?: Maybe<Scalars['timestamptz']>;
  contact_type_id?: Maybe<Scalars['bigint']>;
  contactor?: Maybe<DemiplaneUserObjRelInsertInput>;
  contactor_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  requestee?: Maybe<DemiplaneUserObjRelInsertInput>;
  requestee_approval?: Maybe<Scalars['Boolean']>;
  requestee_email?: Maybe<Scalars['String']>;
  requestee_id?: Maybe<Scalars['bigint']>;
  requestee_read?: Maybe<Scalars['Boolean']>;
  requestor?: Maybe<DemiplaneUserObjRelInsertInput>;
  requestor_approval?: Maybe<Scalars['Boolean']>;
  requestor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAdventureConnectionMaxFields = {
  __typename?: 'demiplane_adventure_connection_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  connection_status_date?: Maybe<Scalars['timestamptz']>;
  connection_status_id?: Maybe<Scalars['bigint']>;
  connection_type_date?: Maybe<Scalars['timestamptz']>;
  connection_type_id?: Maybe<Scalars['bigint']>;
  contact_type_date?: Maybe<Scalars['timestamptz']>;
  contact_type_id?: Maybe<Scalars['bigint']>;
  contactor_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  requestee_email?: Maybe<Scalars['String']>;
  requestee_id?: Maybe<Scalars['bigint']>;
  requestor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_date?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_date?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_date?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_email?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdventureConnectionMinFields = {
  __typename?: 'demiplane_adventure_connection_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  connection_status_date?: Maybe<Scalars['timestamptz']>;
  connection_status_id?: Maybe<Scalars['bigint']>;
  connection_type_date?: Maybe<Scalars['timestamptz']>;
  connection_type_id?: Maybe<Scalars['bigint']>;
  contact_type_date?: Maybe<Scalars['timestamptz']>;
  contact_type_id?: Maybe<Scalars['bigint']>;
  contactor_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  requestee_email?: Maybe<Scalars['String']>;
  requestee_id?: Maybe<Scalars['bigint']>;
  requestor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_date?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_date?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_date?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_email?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionMutationResponse = {
  __typename?: 'demiplane_adventure_connection_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdventureConnection>;
};

/** input type for inserting object relation for remote table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionObjRelInsertInput = {
  data: DemiplaneAdventureConnectionInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureConnectionOnConflict>;
};

/** on conflict condition type for table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionOnConflict = {
  constraint: DemiplaneAdventureConnectionConstraint;
  update_columns: Array<DemiplaneAdventureConnectionUpdateColumn>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};

/** ordering options when selecting data from "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  connection_status?: Maybe<DemiplaneConnectionStatusOrderBy>;
  connection_status_date?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type?: Maybe<DemiplaneConnectionTypeOrderBy>;
  connection_type_date?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type?: Maybe<DemiplaneContactTypeOrderBy>;
  contact_type_date?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor?: Maybe<DemiplaneUserOrderBy>;
  contactor_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee?: Maybe<DemiplaneUserOrderBy>;
  requestee_approval?: Maybe<OrderBy>;
  requestee_email?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestee_read?: Maybe<OrderBy>;
  requestor?: Maybe<DemiplaneUserOrderBy>;
  requestor_approval?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.adventure_connection" */
export enum DemiplaneAdventureConnectionSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  ConnectionStatusDate = 'connection_status_date',
  /** column name */
  ConnectionStatusId = 'connection_status_id',
  /** column name */
  ConnectionTypeDate = 'connection_type_date',
  /** column name */
  ConnectionTypeId = 'connection_type_id',
  /** column name */
  ContactTypeDate = 'contact_type_date',
  /** column name */
  ContactTypeId = 'contact_type_id',
  /** column name */
  ContactorId = 'contactor_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  RequesteeApproval = 'requestee_approval',
  /** column name */
  RequesteeEmail = 'requestee_email',
  /** column name */
  RequesteeId = 'requestee_id',
  /** column name */
  RequesteeRead = 'requestee_read',
  /** column name */
  RequestorApproval = 'requestor_approval',
  /** column name */
  RequestorId = 'requestor_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  connection_status_date?: Maybe<Scalars['timestamptz']>;
  connection_status_id?: Maybe<Scalars['bigint']>;
  connection_type_date?: Maybe<Scalars['timestamptz']>;
  connection_type_id?: Maybe<Scalars['bigint']>;
  contact_type_date?: Maybe<Scalars['timestamptz']>;
  contact_type_id?: Maybe<Scalars['bigint']>;
  contactor_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  requestee_approval?: Maybe<Scalars['Boolean']>;
  requestee_email?: Maybe<Scalars['String']>;
  requestee_id?: Maybe<Scalars['bigint']>;
  requestee_read?: Maybe<Scalars['Boolean']>;
  requestor_approval?: Maybe<Scalars['Boolean']>;
  requestor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdventureConnectionStddevFields = {
  __typename?: 'demiplane_adventure_connection_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  connection_status_id?: Maybe<Scalars['Float']>;
  connection_type_id?: Maybe<Scalars['Float']>;
  contact_type_id?: Maybe<Scalars['Float']>;
  contactor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestee_id?: Maybe<Scalars['Float']>;
  requestor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdventureConnectionStddevPopFields = {
  __typename?: 'demiplane_adventure_connection_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  connection_status_id?: Maybe<Scalars['Float']>;
  connection_type_id?: Maybe<Scalars['Float']>;
  contact_type_id?: Maybe<Scalars['Float']>;
  contactor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestee_id?: Maybe<Scalars['Float']>;
  requestor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdventureConnectionStddevSampFields = {
  __typename?: 'demiplane_adventure_connection_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  connection_status_id?: Maybe<Scalars['Float']>;
  connection_type_id?: Maybe<Scalars['Float']>;
  contact_type_id?: Maybe<Scalars['Float']>;
  contactor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestee_id?: Maybe<Scalars['Float']>;
  requestor_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdventureConnectionSumFields = {
  __typename?: 'demiplane_adventure_connection_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  connection_status_id?: Maybe<Scalars['bigint']>;
  connection_type_id?: Maybe<Scalars['bigint']>;
  contact_type_id?: Maybe<Scalars['bigint']>;
  contactor_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  requestee_id?: Maybe<Scalars['bigint']>;
  requestor_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.adventure_connection" */
export enum DemiplaneAdventureConnectionUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  ConnectionStatusDate = 'connection_status_date',
  /** column name */
  ConnectionStatusId = 'connection_status_id',
  /** column name */
  ConnectionTypeDate = 'connection_type_date',
  /** column name */
  ConnectionTypeId = 'connection_type_id',
  /** column name */
  ContactTypeDate = 'contact_type_date',
  /** column name */
  ContactTypeId = 'contact_type_id',
  /** column name */
  ContactorId = 'contactor_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  RequesteeApproval = 'requestee_approval',
  /** column name */
  RequesteeEmail = 'requestee_email',
  /** column name */
  RequesteeId = 'requestee_id',
  /** column name */
  RequesteeRead = 'requestee_read',
  /** column name */
  RequestorApproval = 'requestor_approval',
  /** column name */
  RequestorId = 'requestor_id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdventureConnectionVarPopFields = {
  __typename?: 'demiplane_adventure_connection_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  connection_status_id?: Maybe<Scalars['Float']>;
  connection_type_id?: Maybe<Scalars['Float']>;
  contact_type_id?: Maybe<Scalars['Float']>;
  contactor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestee_id?: Maybe<Scalars['Float']>;
  requestor_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdventureConnectionVarSampFields = {
  __typename?: 'demiplane_adventure_connection_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  connection_status_id?: Maybe<Scalars['Float']>;
  connection_type_id?: Maybe<Scalars['Float']>;
  contact_type_id?: Maybe<Scalars['Float']>;
  contactor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestee_id?: Maybe<Scalars['Float']>;
  requestor_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdventureConnectionVarianceFields = {
  __typename?: 'demiplane_adventure_connection_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  connection_status_id?: Maybe<Scalars['Float']>;
  connection_type_id?: Maybe<Scalars['Float']>;
  contact_type_id?: Maybe<Scalars['Float']>;
  contactor_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestee_id?: Maybe<Scalars['Float']>;
  requestor_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.adventure_connection" */
export type DemiplaneAdventureConnectionVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  connection_status_id?: Maybe<OrderBy>;
  connection_type_id?: Maybe<OrderBy>;
  contact_type_id?: Maybe<OrderBy>;
  contactor_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  requestee_id?: Maybe<OrderBy>;
  requestor_id?: Maybe<OrderBy>;
};

/** unique or primary key constraints on table "demiplane.adventure" */
export enum DemiplaneAdventureConstraint {
  /** unique or primary key constraint */
  PkAdventureId = 'pk_adventure_id',
  /** unique or primary key constraint */
  UqAdventureAdventureLinkId = 'uq_adventure_adventure_link_id'
}

/** columns and relationships of "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneTool = {
  __typename?: 'demiplane_adventure_demiplane_tool';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  created?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  demiplane_tool: DemiplaneDemiplaneTool;
  demiplane_tool_id: Scalars['Int'];
  id: Scalars['bigint'];
  sort_order: Scalars['Int'];
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolAggregate = {
  __typename?: 'demiplane_adventure_demiplane_tool_aggregate';
  aggregate?: Maybe<DemiplaneAdventureDemiplaneToolAggregateFields>;
  nodes: Array<DemiplaneAdventureDemiplaneTool>;
};

/** aggregate fields of "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolAggregateFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_aggregate_fields';
  avg?: Maybe<DemiplaneAdventureDemiplaneToolAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdventureDemiplaneToolMaxFields>;
  min?: Maybe<DemiplaneAdventureDemiplaneToolMinFields>;
  stddev?: Maybe<DemiplaneAdventureDemiplaneToolStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdventureDemiplaneToolStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdventureDemiplaneToolStddevSampFields>;
  sum?: Maybe<DemiplaneAdventureDemiplaneToolSumFields>;
  var_pop?: Maybe<DemiplaneAdventureDemiplaneToolVarPopFields>;
  var_samp?: Maybe<DemiplaneAdventureDemiplaneToolVarSampFields>;
  variance?: Maybe<DemiplaneAdventureDemiplaneToolVarianceFields>;
};


/** aggregate fields of "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdventureDemiplaneToolSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdventureDemiplaneToolAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdventureDemiplaneToolMaxOrderBy>;
  min?: Maybe<DemiplaneAdventureDemiplaneToolMinOrderBy>;
  stddev?: Maybe<DemiplaneAdventureDemiplaneToolStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdventureDemiplaneToolStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdventureDemiplaneToolStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdventureDemiplaneToolSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdventureDemiplaneToolVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdventureDemiplaneToolVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdventureDemiplaneToolVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolArrRelInsertInput = {
  data: Array<DemiplaneAdventureDemiplaneToolInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureDemiplaneToolOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdventureDemiplaneToolAvgFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  demiplane_tool_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.adventure_demiplane_tool". All fields are combined with a logical 'AND'. */
export type DemiplaneAdventureDemiplaneToolBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdventureDemiplaneToolBoolExp>>>;
  _not?: Maybe<DemiplaneAdventureDemiplaneToolBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdventureDemiplaneToolBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  demiplane_tool?: Maybe<DemiplaneDemiplaneToolBoolExp>;
  demiplane_tool_id?: Maybe<IntComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  sort_order?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.adventure_demiplane_tool" */
export enum DemiplaneAdventureDemiplaneToolConstraint {
  /** unique or primary key constraint */
  AdventureDemiplaneToolAdventureIdDemiplaneToolIdKey = 'adventure_demiplane_tool_adventure_id_demiplane_tool_id_key',
  /** unique or primary key constraint */
  AdventureDemiplaneToolPkey = 'adventure_demiplane_tool_pkey'
}

/** input type for incrementing integer column in table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  demiplane_tool_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  demiplane_tool?: Maybe<DemiplaneDemiplaneToolObjRelInsertInput>;
  demiplane_tool_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAdventureDemiplaneToolMaxFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  demiplane_tool_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdventureDemiplaneToolMinFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  demiplane_tool_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolMutationResponse = {
  __typename?: 'demiplane_adventure_demiplane_tool_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdventureDemiplaneTool>;
};

/** input type for inserting object relation for remote table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolObjRelInsertInput = {
  data: DemiplaneAdventureDemiplaneToolInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureDemiplaneToolOnConflict>;
};

/** on conflict condition type for table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolOnConflict = {
  constraint: DemiplaneAdventureDemiplaneToolConstraint;
  update_columns: Array<DemiplaneAdventureDemiplaneToolUpdateColumn>;
  where?: Maybe<DemiplaneAdventureDemiplaneToolBoolExp>;
};

/** ordering options when selecting data from "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  demiplane_tool?: Maybe<DemiplaneDemiplaneToolOrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.adventure_demiplane_tool" */
export enum DemiplaneAdventureDemiplaneToolSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  DemiplaneToolId = 'demiplane_tool_id',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  demiplane_tool_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdventureDemiplaneToolStddevFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  demiplane_tool_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdventureDemiplaneToolStddevPopFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  demiplane_tool_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdventureDemiplaneToolStddevSampFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  demiplane_tool_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdventureDemiplaneToolSumFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  demiplane_tool_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.adventure_demiplane_tool" */
export enum DemiplaneAdventureDemiplaneToolUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  DemiplaneToolId = 'demiplane_tool_id',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdventureDemiplaneToolVarPopFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  demiplane_tool_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdventureDemiplaneToolVarSampFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  demiplane_tool_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdventureDemiplaneToolVarianceFields = {
  __typename?: 'demiplane_adventure_demiplane_tool_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  demiplane_tool_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.adventure_demiplane_tool" */
export type DemiplaneAdventureDemiplaneToolVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  demiplane_tool_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** input type for incrementing integer column in table "demiplane.adventure" */
export type DemiplaneAdventureIncInput = {
  accepted_inc_adv_cnt?: Maybe<Scalars['Int']>;
  adventure_link_id?: Maybe<Scalars['bigint']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  auto_join_threshold?: Maybe<Scalars['Int']>;
  combat?: Maybe<Scalars['Int']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  episode_frequency?: Maybe<Scalars['Int']>;
  game_cost?: Maybe<Scalars['Int']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  open_inc_adv_cnt?: Maybe<Scalars['Int']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  total_act_adv_cnt?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.adventure" */
export type DemiplaneAdventureInsertInput = {
  accepted_inc_adv_cnt?: Maybe<Scalars['Int']>;
  ad_times?: Maybe<DemiplaneAdTimeArrRelInsertInput>;
  ads?: Maybe<DemiplaneAdArrRelInsertInput>;
  adventure_connections?: Maybe<DemiplaneAdventureConnectionArrRelInsertInput>;
  adventure_link?: Maybe<DemiplaneAdventureLinkObjRelInsertInput>;
  adventure_link_id?: Maybe<Scalars['bigint']>;
  adventure_players?: Maybe<DemiplaneAdventurePlayerArrRelInsertInput>;
  adventure_todos?: Maybe<DemiplaneAdventureTodoArrRelInsertInput>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformObjRelInsertInput>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  attachment?: Maybe<DemiplaneAttachmentObjRelInsertInput>;
  auto_join?: Maybe<Scalars['Boolean']>;
  auto_join_threshold?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  default_adventure?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  episode_frequency?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  game_cost?: Maybe<Scalars['Int']>;
  game_link_url?: Maybe<Scalars['String']>;
  game_type?: Maybe<DemiplaneGameTypeObjRelInsertInput>;
  game_type_id?: Maybe<Scalars['bigint']>;
  gm_recommendations?: Maybe<DemiplaneGmRecommendationArrRelInsertInput>;
  has_leader_viewed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  invitations?: Maybe<DemiplaneInvitationArrRelInsertInput>;
  match_making_enabled?: Maybe<Scalars['Boolean']>;
  match_making_status_adv_details?: Maybe<Scalars['Boolean']>;
  match_making_status_adv_image?: Maybe<Scalars['Boolean']>;
  match_making_status_adv_type?: Maybe<Scalars['Boolean']>;
  match_making_status_game_avail?: Maybe<Scalars['Boolean']>;
  match_making_status_party_mgmt?: Maybe<Scalars['Boolean']>;
  match_making_status_theme_scores?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  new_player_friendly?: Maybe<Scalars['Boolean']>;
  notification_histories?: Maybe<DemiplaneNotificationHistoryArrRelInsertInput>;
  open_inc_adv_cnt?: Maybe<Scalars['Int']>;
  paid_flag?: Maybe<Scalars['Boolean']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  sessions?: Maybe<DemiplaneSessionArrRelInsertInput>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  tasks?: Maybe<DemiplaneTaskArrRelInsertInput>;
  total_act_adv_cnt?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_adventure_rankings?: Maybe<DemiplaneUserAdventureRankingArrRelInsertInput>;
  user_adventure_roles?: Maybe<DemiplaneUserAdventureRoleArrRelInsertInput>;
  user_adventures?: Maybe<DemiplaneUserAdventureArrRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "demiplane.adventure_link" */
export type DemiplaneAdventureLink = {
  __typename?: 'demiplane_adventure_link';
  /** An object relationship */
  adventure?: Maybe<DemiplaneAdventure>;
  code: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.adventure_link" */
export type DemiplaneAdventureLinkAggregate = {
  __typename?: 'demiplane_adventure_link_aggregate';
  aggregate?: Maybe<DemiplaneAdventureLinkAggregateFields>;
  nodes: Array<DemiplaneAdventureLink>;
};

/** aggregate fields of "demiplane.adventure_link" */
export type DemiplaneAdventureLinkAggregateFields = {
  __typename?: 'demiplane_adventure_link_aggregate_fields';
  avg?: Maybe<DemiplaneAdventureLinkAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdventureLinkMaxFields>;
  min?: Maybe<DemiplaneAdventureLinkMinFields>;
  stddev?: Maybe<DemiplaneAdventureLinkStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdventureLinkStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdventureLinkStddevSampFields>;
  sum?: Maybe<DemiplaneAdventureLinkSumFields>;
  var_pop?: Maybe<DemiplaneAdventureLinkVarPopFields>;
  var_samp?: Maybe<DemiplaneAdventureLinkVarSampFields>;
  variance?: Maybe<DemiplaneAdventureLinkVarianceFields>;
};


/** aggregate fields of "demiplane.adventure_link" */
export type DemiplaneAdventureLinkAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdventureLinkSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdventureLinkAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdventureLinkMaxOrderBy>;
  min?: Maybe<DemiplaneAdventureLinkMinOrderBy>;
  stddev?: Maybe<DemiplaneAdventureLinkStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdventureLinkStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdventureLinkStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdventureLinkSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdventureLinkVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdventureLinkVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdventureLinkVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkArrRelInsertInput = {
  data: Array<DemiplaneAdventureLinkInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureLinkOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdventureLinkAvgFields = {
  __typename?: 'demiplane_adventure_link_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.adventure_link". All fields are combined with a logical 'AND'. */
export type DemiplaneAdventureLinkBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdventureLinkBoolExp>>>;
  _not?: Maybe<DemiplaneAdventureLinkBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdventureLinkBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  code?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.adventure_link" */
export enum DemiplaneAdventureLinkConstraint {
  /** unique or primary key constraint */
  PkAdventureLinkId = 'pk_adventure_link_id',
  /** unique or primary key constraint */
  UqAdventureLinkCode = 'uq_adventure_link_code'
}

/** input type for incrementing integer column in table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAdventureLinkMaxFields = {
  __typename?: 'demiplane_adventure_link_max_fields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkMaxOrderBy = {
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdventureLinkMinFields = {
  __typename?: 'demiplane_adventure_link_min_fields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkMinOrderBy = {
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkMutationResponse = {
  __typename?: 'demiplane_adventure_link_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdventureLink>;
};

/** input type for inserting object relation for remote table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkObjRelInsertInput = {
  data: DemiplaneAdventureLinkInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureLinkOnConflict>;
};

/** on conflict condition type for table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkOnConflict = {
  constraint: DemiplaneAdventureLinkConstraint;
  update_columns: Array<DemiplaneAdventureLinkUpdateColumn>;
  where?: Maybe<DemiplaneAdventureLinkBoolExp>;
};

/** ordering options when selecting data from "demiplane.adventure_link" */
export type DemiplaneAdventureLinkOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.adventure_link" */
export type DemiplaneAdventureLinkPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.adventure_link" */
export enum DemiplaneAdventureLinkSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkSetInput = {
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdventureLinkStddevFields = {
  __typename?: 'demiplane_adventure_link_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdventureLinkStddevPopFields = {
  __typename?: 'demiplane_adventure_link_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdventureLinkStddevSampFields = {
  __typename?: 'demiplane_adventure_link_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdventureLinkSumFields = {
  __typename?: 'demiplane_adventure_link_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.adventure_link" */
export enum DemiplaneAdventureLinkUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdventureLinkVarPopFields = {
  __typename?: 'demiplane_adventure_link_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdventureLinkVarSampFields = {
  __typename?: 'demiplane_adventure_link_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdventureLinkVarianceFields = {
  __typename?: 'demiplane_adventure_link_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.adventure_link" */
export type DemiplaneAdventureLinkVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type DemiplaneAdventureMaxFields = {
  __typename?: 'demiplane_adventure_max_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Int']>;
  adventure_link_id?: Maybe<Scalars['bigint']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  auto_join_threshold?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  episode_frequency?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  game_cost?: Maybe<Scalars['Int']>;
  game_link_url?: Maybe<Scalars['String']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  open_inc_adv_cnt?: Maybe<Scalars['Int']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  total_act_adv_cnt?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureMaxOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  avatar?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_link_url?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdventureMinFields = {
  __typename?: 'demiplane_adventure_min_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Int']>;
  adventure_link_id?: Maybe<Scalars['bigint']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  auto_join_threshold?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  episode_frequency?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  game_cost?: Maybe<Scalars['Int']>;
  game_link_url?: Maybe<Scalars['String']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  open_inc_adv_cnt?: Maybe<Scalars['Int']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  total_act_adv_cnt?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureMinOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  avatar?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_link_url?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.adventure" */
export type DemiplaneAdventureMutationResponse = {
  __typename?: 'demiplane_adventure_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdventure>;
};

/** input type for inserting object relation for remote table "demiplane.adventure" */
export type DemiplaneAdventureObjRelInsertInput = {
  data: DemiplaneAdventureInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureOnConflict>;
};

/** on conflict condition type for table "demiplane.adventure" */
export type DemiplaneAdventureOnConflict = {
  constraint: DemiplaneAdventureConstraint;
  update_columns: Array<DemiplaneAdventureUpdateColumn>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};

/** ordering options when selecting data from "demiplane.adventure" */
export type DemiplaneAdventureOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  ad_times_aggregate?: Maybe<DemiplaneAdTimeAggregateOrderBy>;
  ads_aggregate?: Maybe<DemiplaneAdAggregateOrderBy>;
  adventure_connections_aggregate?: Maybe<DemiplaneAdventureConnectionAggregateOrderBy>;
  adventure_link?: Maybe<DemiplaneAdventureLinkOrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventure_players_aggregate?: Maybe<DemiplaneAdventurePlayerAggregateOrderBy>;
  adventure_todos_aggregate?: Maybe<DemiplaneAdventureTodoAggregateOrderBy>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformOrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  attachment?: Maybe<DemiplaneAttachmentOrderBy>;
  auto_join?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  avatar?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  default_adventure?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_link_url?: Maybe<OrderBy>;
  game_type?: Maybe<DemiplaneGameTypeOrderBy>;
  game_type_id?: Maybe<OrderBy>;
  gm_recommendations_aggregate?: Maybe<DemiplaneGmRecommendationAggregateOrderBy>;
  has_leader_viewed?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invitations_aggregate?: Maybe<DemiplaneInvitationAggregateOrderBy>;
  match_making_enabled?: Maybe<OrderBy>;
  match_making_status_adv_details?: Maybe<OrderBy>;
  match_making_status_adv_image?: Maybe<OrderBy>;
  match_making_status_adv_type?: Maybe<OrderBy>;
  match_making_status_game_avail?: Maybe<OrderBy>;
  match_making_status_party_mgmt?: Maybe<OrderBy>;
  match_making_status_theme_scores?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  new_player_friendly?: Maybe<OrderBy>;
  notification_histories_aggregate?: Maybe<DemiplaneNotificationHistoryAggregateOrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  paid_flag?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  sessions_aggregate?: Maybe<DemiplaneSessionAggregateOrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  tasks_aggregate?: Maybe<DemiplaneTaskAggregateOrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_adventure_rankings_aggregate?: Maybe<DemiplaneUserAdventureRankingAggregateOrderBy>;
  user_adventure_roles_aggregate?: Maybe<DemiplaneUserAdventureRoleAggregateOrderBy>;
  user_adventures_aggregate?: Maybe<DemiplaneUserAdventureAggregateOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.adventure" */
export type DemiplaneAdventurePkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayer = {
  __typename?: 'demiplane_adventure_player';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  /** An array relationship */
  episode_attendances: Array<DemiplaneEpisodeAttendance>;
  /** An aggregated array relationship */
  episode_attendances_aggregate: DemiplaneEpisodeAttendanceAggregate;
  /** An array relationship */
  favorite_dice_rolls: Array<DemiplaneFavoriteDiceRoll>;
  /** An aggregated array relationship */
  favorite_dice_rolls_aggregate: DemiplaneFavoriteDiceRollAggregate;
  id: Scalars['bigint'];
  in_video: Scalars['Boolean'];
  in_voice: Scalars['Boolean'];
  in_whisper: Scalars['Boolean'];
  is_gm: Scalars['Boolean'];
  /** An array relationship */
  journal_note_shares: Array<DemiplaneJournalNoteShare>;
  /** An aggregated array relationship */
  journal_note_shares_aggregate: DemiplaneJournalNoteShareAggregate;
  /** An array relationship */
  journal_notes: Array<DemiplaneJournalNote>;
  /** An aggregated array relationship */
  journal_notes_aggregate: DemiplaneJournalNoteAggregate;
  /** An array relationship */
  journal_super_event_read_statuses: Array<DemiplaneJournalSuperEventReadStatus>;
  /** An aggregated array relationship */
  journal_super_event_read_statuses_aggregate: DemiplaneJournalSuperEventReadStatusAggregate;
  /** An array relationship */
  journal_titles: Array<DemiplaneJournalTitle>;
  /** An aggregated array relationship */
  journal_titles_aggregate: DemiplaneJournalTitleAggregate;
  player_name?: Maybe<Scalars['String']>;
  portal_participant_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  task_read_statuses: Array<DemiplaneTaskReadStatus>;
  /** An aggregated array relationship */
  task_read_statuses_aggregate: DemiplaneTaskReadStatusAggregate;
  /** An array relationship */
  tasks: Array<DemiplaneTask>;
  /** An array relationship */
  tasksByCompletedBy: Array<DemiplaneTask>;
  /** An aggregated array relationship */
  tasksByCompletedBy_aggregate: DemiplaneTaskAggregate;
  /** An array relationship */
  tasksByDeclinedBy: Array<DemiplaneTask>;
  /** An aggregated array relationship */
  tasksByDeclinedBy_aggregate: DemiplaneTaskAggregate;
  /** An aggregated array relationship */
  tasks_aggregate: DemiplaneTaskAggregate;
  triggered_disable_whisper: Scalars['Boolean'];
  /** An object relationship */
  upload?: Maybe<DemiplaneUpload>;
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
  whisper_participant_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerEpisodeAttendancesArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerEpisodeAttendancesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerFavoriteDiceRollsArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteDiceRollSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteDiceRollOrderBy>>;
  where?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerFavoriteDiceRollsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteDiceRollSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteDiceRollOrderBy>>;
  where?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalNoteSharesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalNoteSharesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalNotesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalNotesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalSuperEventReadStatusesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalSuperEventReadStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalTitlesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerJournalTitlesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTaskReadStatusesArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTaskReadStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTasksArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTasksByCompletedByArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTasksByCompletedByAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTasksByDeclinedByArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTasksByDeclinedByAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** columns and relationships of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerTasksAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};

/** aggregated selection of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerAggregate = {
  __typename?: 'demiplane_adventure_player_aggregate';
  aggregate?: Maybe<DemiplaneAdventurePlayerAggregateFields>;
  nodes: Array<DemiplaneAdventurePlayer>;
};

/** aggregate fields of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerAggregateFields = {
  __typename?: 'demiplane_adventure_player_aggregate_fields';
  avg?: Maybe<DemiplaneAdventurePlayerAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdventurePlayerMaxFields>;
  min?: Maybe<DemiplaneAdventurePlayerMinFields>;
  stddev?: Maybe<DemiplaneAdventurePlayerStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdventurePlayerStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdventurePlayerStddevSampFields>;
  sum?: Maybe<DemiplaneAdventurePlayerSumFields>;
  var_pop?: Maybe<DemiplaneAdventurePlayerVarPopFields>;
  var_samp?: Maybe<DemiplaneAdventurePlayerVarSampFields>;
  variance?: Maybe<DemiplaneAdventurePlayerVarianceFields>;
};


/** aggregate fields of "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdventurePlayerAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdventurePlayerMaxOrderBy>;
  min?: Maybe<DemiplaneAdventurePlayerMinOrderBy>;
  stddev?: Maybe<DemiplaneAdventurePlayerStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdventurePlayerStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdventurePlayerStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdventurePlayerSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdventurePlayerVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdventurePlayerVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdventurePlayerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerArrRelInsertInput = {
  data: Array<DemiplaneAdventurePlayerInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventurePlayerOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdventurePlayerAvgFields = {
  __typename?: 'demiplane_adventure_player_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.adventure_player". All fields are combined with a logical 'AND'. */
export type DemiplaneAdventurePlayerBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdventurePlayerBoolExp>>>;
  _not?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdventurePlayerBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  episode_attendances?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
  favorite_dice_rolls?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
  id?: Maybe<BigintComparisonExp>;
  in_video?: Maybe<BooleanComparisonExp>;
  in_voice?: Maybe<BooleanComparisonExp>;
  in_whisper?: Maybe<BooleanComparisonExp>;
  is_gm?: Maybe<BooleanComparisonExp>;
  journal_note_shares?: Maybe<DemiplaneJournalNoteShareBoolExp>;
  journal_notes?: Maybe<DemiplaneJournalNoteBoolExp>;
  journal_super_event_read_statuses?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
  journal_titles?: Maybe<DemiplaneJournalTitleBoolExp>;
  player_name?: Maybe<StringComparisonExp>;
  portal_participant_id?: Maybe<StringComparisonExp>;
  task_read_statuses?: Maybe<DemiplaneTaskReadStatusBoolExp>;
  tasks?: Maybe<DemiplaneTaskBoolExp>;
  tasksByCompletedBy?: Maybe<DemiplaneTaskBoolExp>;
  tasksByDeclinedBy?: Maybe<DemiplaneTaskBoolExp>;
  triggered_disable_whisper?: Maybe<BooleanComparisonExp>;
  upload?: Maybe<DemiplaneUploadBoolExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  whisper_participant_id?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.adventure_player" */
export enum DemiplaneAdventurePlayerConstraint {
  /** unique or primary key constraint */
  PkAdventurePlayerId = 'pk_adventure_player_id'
}

/** input type for incrementing integer column in table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  episode_attendances?: Maybe<DemiplaneEpisodeAttendanceArrRelInsertInput>;
  favorite_dice_rolls?: Maybe<DemiplaneFavoriteDiceRollArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  in_video?: Maybe<Scalars['Boolean']>;
  in_voice?: Maybe<Scalars['Boolean']>;
  in_whisper?: Maybe<Scalars['Boolean']>;
  is_gm?: Maybe<Scalars['Boolean']>;
  journal_note_shares?: Maybe<DemiplaneJournalNoteShareArrRelInsertInput>;
  journal_notes?: Maybe<DemiplaneJournalNoteArrRelInsertInput>;
  journal_super_event_read_statuses?: Maybe<DemiplaneJournalSuperEventReadStatusArrRelInsertInput>;
  journal_titles?: Maybe<DemiplaneJournalTitleArrRelInsertInput>;
  player_name?: Maybe<Scalars['String']>;
  portal_participant_id?: Maybe<Scalars['String']>;
  task_read_statuses?: Maybe<DemiplaneTaskReadStatusArrRelInsertInput>;
  tasks?: Maybe<DemiplaneTaskArrRelInsertInput>;
  tasksByCompletedBy?: Maybe<DemiplaneTaskArrRelInsertInput>;
  tasksByDeclinedBy?: Maybe<DemiplaneTaskArrRelInsertInput>;
  triggered_disable_whisper?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<DemiplaneUploadObjRelInsertInput>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  whisper_participant_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DemiplaneAdventurePlayerMaxFields = {
  __typename?: 'demiplane_adventure_player_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  player_name?: Maybe<Scalars['String']>;
  portal_participant_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  whisper_participant_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_name?: Maybe<OrderBy>;
  portal_participant_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  whisper_participant_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdventurePlayerMinFields = {
  __typename?: 'demiplane_adventure_player_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  player_name?: Maybe<Scalars['String']>;
  portal_participant_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
  whisper_participant_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_name?: Maybe<OrderBy>;
  portal_participant_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  whisper_participant_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerMutationResponse = {
  __typename?: 'demiplane_adventure_player_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdventurePlayer>;
};

/** input type for inserting object relation for remote table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerObjRelInsertInput = {
  data: DemiplaneAdventurePlayerInsertInput;
  on_conflict?: Maybe<DemiplaneAdventurePlayerOnConflict>;
};

/** on conflict condition type for table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerOnConflict = {
  constraint: DemiplaneAdventurePlayerConstraint;
  update_columns: Array<DemiplaneAdventurePlayerUpdateColumn>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};

/** ordering options when selecting data from "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  episode_attendances_aggregate?: Maybe<DemiplaneEpisodeAttendanceAggregateOrderBy>;
  favorite_dice_rolls_aggregate?: Maybe<DemiplaneFavoriteDiceRollAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  in_video?: Maybe<OrderBy>;
  in_voice?: Maybe<OrderBy>;
  in_whisper?: Maybe<OrderBy>;
  is_gm?: Maybe<OrderBy>;
  journal_note_shares_aggregate?: Maybe<DemiplaneJournalNoteShareAggregateOrderBy>;
  journal_notes_aggregate?: Maybe<DemiplaneJournalNoteAggregateOrderBy>;
  journal_super_event_read_statuses_aggregate?: Maybe<DemiplaneJournalSuperEventReadStatusAggregateOrderBy>;
  journal_titles_aggregate?: Maybe<DemiplaneJournalTitleAggregateOrderBy>;
  player_name?: Maybe<OrderBy>;
  portal_participant_id?: Maybe<OrderBy>;
  task_read_statuses_aggregate?: Maybe<DemiplaneTaskReadStatusAggregateOrderBy>;
  tasksByCompletedBy_aggregate?: Maybe<DemiplaneTaskAggregateOrderBy>;
  tasksByDeclinedBy_aggregate?: Maybe<DemiplaneTaskAggregateOrderBy>;
  tasks_aggregate?: Maybe<DemiplaneTaskAggregateOrderBy>;
  triggered_disable_whisper?: Maybe<OrderBy>;
  upload?: Maybe<DemiplaneUploadOrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  whisper_participant_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.adventure_player" */
export enum DemiplaneAdventurePlayerSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Id = 'id',
  /** column name */
  InVideo = 'in_video',
  /** column name */
  InVoice = 'in_voice',
  /** column name */
  InWhisper = 'in_whisper',
  /** column name */
  IsGm = 'is_gm',
  /** column name */
  PlayerName = 'player_name',
  /** column name */
  PortalParticipantId = 'portal_participant_id',
  /** column name */
  TriggeredDisableWhisper = 'triggered_disable_whisper',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WhisperParticipantId = 'whisper_participant_id'
}

/** input type for updating data in table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  in_video?: Maybe<Scalars['Boolean']>;
  in_voice?: Maybe<Scalars['Boolean']>;
  in_whisper?: Maybe<Scalars['Boolean']>;
  is_gm?: Maybe<Scalars['Boolean']>;
  player_name?: Maybe<Scalars['String']>;
  portal_participant_id?: Maybe<Scalars['String']>;
  triggered_disable_whisper?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['bigint']>;
  whisper_participant_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdventurePlayerStddevFields = {
  __typename?: 'demiplane_adventure_player_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdventurePlayerStddevPopFields = {
  __typename?: 'demiplane_adventure_player_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdventurePlayerStddevSampFields = {
  __typename?: 'demiplane_adventure_player_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdventurePlayerSumFields = {
  __typename?: 'demiplane_adventure_player_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.adventure_player" */
export enum DemiplaneAdventurePlayerUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Id = 'id',
  /** column name */
  InVideo = 'in_video',
  /** column name */
  InVoice = 'in_voice',
  /** column name */
  InWhisper = 'in_whisper',
  /** column name */
  IsGm = 'is_gm',
  /** column name */
  PlayerName = 'player_name',
  /** column name */
  PortalParticipantId = 'portal_participant_id',
  /** column name */
  TriggeredDisableWhisper = 'triggered_disable_whisper',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WhisperParticipantId = 'whisper_participant_id'
}

/** aggregate var_pop on columns */
export type DemiplaneAdventurePlayerVarPopFields = {
  __typename?: 'demiplane_adventure_player_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdventurePlayerVarSampFields = {
  __typename?: 'demiplane_adventure_player_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdventurePlayerVarianceFields = {
  __typename?: 'demiplane_adventure_player_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.adventure_player" */
export type DemiplaneAdventurePlayerVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.adventure" */
export enum DemiplaneAdventureSelectColumn {
  /** column name */
  AcceptedIncAdvCnt = 'accepted_inc_adv_cnt',
  /** column name */
  AdventureLinkId = 'adventure_link_id',
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  AutoJoin = 'auto_join',
  /** column name */
  AutoJoinThreshold = 'auto_join_threshold',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Combat = 'combat',
  /** column name */
  Created = 'created',
  /** column name */
  DefaultAdventure = 'default_adventure',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DemicardId = 'demicard_id',
  /** column name */
  Description = 'description',
  /** column name */
  EpisodeFrequency = 'episode_frequency',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  GameCost = 'game_cost',
  /** column name */
  GameLinkUrl = 'game_link_url',
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  HasLeaderViewed = 'has_leader_viewed',
  /** column name */
  Id = 'id',
  /** column name */
  MatchMakingEnabled = 'match_making_enabled',
  /** column name */
  MatchMakingStatusAdvDetails = 'match_making_status_adv_details',
  /** column name */
  MatchMakingStatusAdvImage = 'match_making_status_adv_image',
  /** column name */
  MatchMakingStatusAdvType = 'match_making_status_adv_type',
  /** column name */
  MatchMakingStatusGameAvail = 'match_making_status_game_avail',
  /** column name */
  MatchMakingStatusPartyMgmt = 'match_making_status_party_mgmt',
  /** column name */
  MatchMakingStatusThemeScores = 'match_making_status_theme_scores',
  /** column name */
  Name = 'name',
  /** column name */
  NewPlayerFriendly = 'new_player_friendly',
  /** column name */
  OpenIncAdvCnt = 'open_inc_adv_cnt',
  /** column name */
  PaidFlag = 'paid_flag',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  RequestedIncAdvCnt = 'requested_inc_adv_cnt',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  TotalActAdvCnt = 'total_act_adv_cnt',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.adventure" */
export type DemiplaneAdventureSetInput = {
  accepted_inc_adv_cnt?: Maybe<Scalars['Int']>;
  adventure_link_id?: Maybe<Scalars['bigint']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  auto_join?: Maybe<Scalars['Boolean']>;
  auto_join_threshold?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  default_adventure?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['timestamp']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  episode_frequency?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  game_cost?: Maybe<Scalars['Int']>;
  game_link_url?: Maybe<Scalars['String']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  has_leader_viewed?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  match_making_enabled?: Maybe<Scalars['Boolean']>;
  match_making_status_adv_details?: Maybe<Scalars['Boolean']>;
  match_making_status_adv_image?: Maybe<Scalars['Boolean']>;
  match_making_status_adv_type?: Maybe<Scalars['Boolean']>;
  match_making_status_game_avail?: Maybe<Scalars['Boolean']>;
  match_making_status_party_mgmt?: Maybe<Scalars['Boolean']>;
  match_making_status_theme_scores?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  new_player_friendly?: Maybe<Scalars['Boolean']>;
  open_inc_adv_cnt?: Maybe<Scalars['Int']>;
  paid_flag?: Maybe<Scalars['Boolean']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  total_act_adv_cnt?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdventureStddevFields = {
  __typename?: 'demiplane_adventure_stddev_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Float']>;
  adventure_link_id?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  auto_join_threshold?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  episode_frequency?: Maybe<Scalars['Float']>;
  game_cost?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  open_inc_adv_cnt?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  total_act_adv_cnt?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureStddevOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdventureStddevPopFields = {
  __typename?: 'demiplane_adventure_stddev_pop_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Float']>;
  adventure_link_id?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  auto_join_threshold?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  episode_frequency?: Maybe<Scalars['Float']>;
  game_cost?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  open_inc_adv_cnt?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  total_act_adv_cnt?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureStddevPopOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdventureStddevSampFields = {
  __typename?: 'demiplane_adventure_stddev_samp_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Float']>;
  adventure_link_id?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  auto_join_threshold?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  episode_frequency?: Maybe<Scalars['Float']>;
  game_cost?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  open_inc_adv_cnt?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  total_act_adv_cnt?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureStddevSampOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdventureSumFields = {
  __typename?: 'demiplane_adventure_sum_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Int']>;
  adventure_link_id?: Maybe<Scalars['bigint']>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  auto_join_threshold?: Maybe<Scalars['Int']>;
  combat?: Maybe<Scalars['Int']>;
  demicard_id?: Maybe<Scalars['bigint']>;
  episode_frequency?: Maybe<Scalars['Int']>;
  game_cost?: Maybe<Scalars['Int']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  open_inc_adv_cnt?: Maybe<Scalars['Int']>;
  player_story?: Maybe<Scalars['Int']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Int']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  total_act_adv_cnt?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureSumOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.adventure_todo" */
export type DemiplaneAdventureTodo = {
  __typename?: 'demiplane_adventure_todo';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  task_message: Scalars['String'];
  task_open: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoAggregate = {
  __typename?: 'demiplane_adventure_todo_aggregate';
  aggregate?: Maybe<DemiplaneAdventureTodoAggregateFields>;
  nodes: Array<DemiplaneAdventureTodo>;
};

/** aggregate fields of "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoAggregateFields = {
  __typename?: 'demiplane_adventure_todo_aggregate_fields';
  avg?: Maybe<DemiplaneAdventureTodoAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdventureTodoMaxFields>;
  min?: Maybe<DemiplaneAdventureTodoMinFields>;
  stddev?: Maybe<DemiplaneAdventureTodoStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdventureTodoStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdventureTodoStddevSampFields>;
  sum?: Maybe<DemiplaneAdventureTodoSumFields>;
  var_pop?: Maybe<DemiplaneAdventureTodoVarPopFields>;
  var_samp?: Maybe<DemiplaneAdventureTodoVarSampFields>;
  variance?: Maybe<DemiplaneAdventureTodoVarianceFields>;
};


/** aggregate fields of "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdventureTodoSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdventureTodoAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdventureTodoMaxOrderBy>;
  min?: Maybe<DemiplaneAdventureTodoMinOrderBy>;
  stddev?: Maybe<DemiplaneAdventureTodoStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdventureTodoStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdventureTodoStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdventureTodoSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdventureTodoVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdventureTodoVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdventureTodoVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoArrRelInsertInput = {
  data: Array<DemiplaneAdventureTodoInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureTodoOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdventureTodoAvgFields = {
  __typename?: 'demiplane_adventure_todo_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.adventure_todo". All fields are combined with a logical 'AND'. */
export type DemiplaneAdventureTodoBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdventureTodoBoolExp>>>;
  _not?: Maybe<DemiplaneAdventureTodoBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdventureTodoBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  task_message?: Maybe<StringComparisonExp>;
  task_open?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.adventure_todo" */
export enum DemiplaneAdventureTodoConstraint {
  /** unique or primary key constraint */
  PkAdventureTodoId = 'pk_adventure_todo_id'
}

/** input type for incrementing integer column in table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  task_message?: Maybe<Scalars['String']>;
  task_open?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAdventureTodoMaxFields = {
  __typename?: 'demiplane_adventure_todo_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  task_message?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_message?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdventureTodoMinFields = {
  __typename?: 'demiplane_adventure_todo_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  task_message?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_message?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoMutationResponse = {
  __typename?: 'demiplane_adventure_todo_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdventureTodo>;
};

/** input type for inserting object relation for remote table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoObjRelInsertInput = {
  data: DemiplaneAdventureTodoInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureTodoOnConflict>;
};

/** on conflict condition type for table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoOnConflict = {
  constraint: DemiplaneAdventureTodoConstraint;
  update_columns: Array<DemiplaneAdventureTodoUpdateColumn>;
  where?: Maybe<DemiplaneAdventureTodoBoolExp>;
};

/** ordering options when selecting data from "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_message?: Maybe<OrderBy>;
  task_open?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.adventure_todo" */
export enum DemiplaneAdventureTodoSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  TaskMessage = 'task_message',
  /** column name */
  TaskOpen = 'task_open',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  task_message?: Maybe<Scalars['String']>;
  task_open?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdventureTodoStddevFields = {
  __typename?: 'demiplane_adventure_todo_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdventureTodoStddevPopFields = {
  __typename?: 'demiplane_adventure_todo_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdventureTodoStddevSampFields = {
  __typename?: 'demiplane_adventure_todo_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdventureTodoSumFields = {
  __typename?: 'demiplane_adventure_todo_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.adventure_todo" */
export enum DemiplaneAdventureTodoUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  TaskMessage = 'task_message',
  /** column name */
  TaskOpen = 'task_open',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdventureTodoVarPopFields = {
  __typename?: 'demiplane_adventure_todo_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdventureTodoVarSampFields = {
  __typename?: 'demiplane_adventure_todo_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdventureTodoVarianceFields = {
  __typename?: 'demiplane_adventure_todo_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.adventure_todo" */
export type DemiplaneAdventureTodoVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.adventure" */
export enum DemiplaneAdventureUpdateColumn {
  /** column name */
  AcceptedIncAdvCnt = 'accepted_inc_adv_cnt',
  /** column name */
  AdventureLinkId = 'adventure_link_id',
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  AutoJoin = 'auto_join',
  /** column name */
  AutoJoinThreshold = 'auto_join_threshold',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Combat = 'combat',
  /** column name */
  Created = 'created',
  /** column name */
  DefaultAdventure = 'default_adventure',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DemicardId = 'demicard_id',
  /** column name */
  Description = 'description',
  /** column name */
  EpisodeFrequency = 'episode_frequency',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  GameCost = 'game_cost',
  /** column name */
  GameLinkUrl = 'game_link_url',
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  HasLeaderViewed = 'has_leader_viewed',
  /** column name */
  Id = 'id',
  /** column name */
  MatchMakingEnabled = 'match_making_enabled',
  /** column name */
  MatchMakingStatusAdvDetails = 'match_making_status_adv_details',
  /** column name */
  MatchMakingStatusAdvImage = 'match_making_status_adv_image',
  /** column name */
  MatchMakingStatusAdvType = 'match_making_status_adv_type',
  /** column name */
  MatchMakingStatusGameAvail = 'match_making_status_game_avail',
  /** column name */
  MatchMakingStatusPartyMgmt = 'match_making_status_party_mgmt',
  /** column name */
  MatchMakingStatusThemeScores = 'match_making_status_theme_scores',
  /** column name */
  Name = 'name',
  /** column name */
  NewPlayerFriendly = 'new_player_friendly',
  /** column name */
  OpenIncAdvCnt = 'open_inc_adv_cnt',
  /** column name */
  PaidFlag = 'paid_flag',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  RequestedIncAdvCnt = 'requested_inc_adv_cnt',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  TotalActAdvCnt = 'total_act_adv_cnt',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneAdventureVarPopFields = {
  __typename?: 'demiplane_adventure_var_pop_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Float']>;
  adventure_link_id?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  auto_join_threshold?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  episode_frequency?: Maybe<Scalars['Float']>;
  game_cost?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  open_inc_adv_cnt?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  total_act_adv_cnt?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureVarPopOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdventureVarSampFields = {
  __typename?: 'demiplane_adventure_var_samp_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Float']>;
  adventure_link_id?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  auto_join_threshold?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  episode_frequency?: Maybe<Scalars['Float']>;
  game_cost?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  open_inc_adv_cnt?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  total_act_adv_cnt?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureVarSampOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdventureVarianceFields = {
  __typename?: 'demiplane_adventure_variance_fields';
  accepted_inc_adv_cnt?: Maybe<Scalars['Float']>;
  adventure_link_id?: Maybe<Scalars['Float']>;
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  auto_join_threshold?: Maybe<Scalars['Float']>;
  combat?: Maybe<Scalars['Float']>;
  demicard_id?: Maybe<Scalars['Float']>;
  episode_frequency?: Maybe<Scalars['Float']>;
  game_cost?: Maybe<Scalars['Float']>;
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  open_inc_adv_cnt?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  requested_inc_adv_cnt?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
  total_act_adv_cnt?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.adventure" */
export type DemiplaneAdventureVarianceOrderBy = {
  accepted_inc_adv_cnt?: Maybe<OrderBy>;
  adventure_link_id?: Maybe<OrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  auto_join_threshold?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  demicard_id?: Maybe<OrderBy>;
  episode_frequency?: Maybe<OrderBy>;
  game_cost?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_inc_adv_cnt?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  requested_inc_adv_cnt?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  total_act_adv_cnt?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatform = {
  __typename?: 'demiplane_adventuring_platform';
  active: Scalars['Boolean'];
  /** An array relationship */
  ads: Array<DemiplaneAd>;
  /** An aggregated array relationship */
  ads_aggregate: DemiplaneAdAggregate;
  /** An array relationship */
  adventures: Array<DemiplaneAdventure>;
  /** An aggregated array relationship */
  adventures_aggregate: DemiplaneAdventureAggregate;
  /** An array relationship */
  amc_adventuring_platforms: Array<DemiplaneAmcAdventuringPlatforms>;
  /** An aggregated array relationship */
  amc_adventuring_platforms_aggregate: DemiplaneAmcAdventuringPlatformsAggregate;
  created: Scalars['timestamptz'];
  /** An array relationship */
  favorite_adventuring_platforms: Array<DemiplaneFavoriteAdventuringPlatform>;
  /** An aggregated array relationship */
  favorite_adventuring_platforms_aggregate: DemiplaneFavoriteAdventuringPlatformAggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated: Scalars['timestamptz'];
  /** An array relationship */
  uraps: Array<DemiplaneUrap>;
  /** An aggregated array relationship */
  uraps_aggregate: DemiplaneUrapAggregate;
  /** An array relationship */
  user_recruitment_adventurer_platforms: Array<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** An aggregated array relationship */
  user_recruitment_adventurer_platforms_aggregate: DemiplaneUserRecruitmentAdventurerPlatformsAggregate;
  /** An array relationship */
  user_recruitment_game_master_platforms: Array<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** An aggregated array relationship */
  user_recruitment_game_master_platforms_aggregate: DemiplaneUserRecruitmentGameMasterPlatformsAggregate;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAdsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAdsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAdventuresArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAdventuresAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAmcAdventuringPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAmcAdventuringPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformFavoriteAdventuringPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformFavoriteAdventuringPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformUrapsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformUrapsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformUserRecruitmentAdventurerPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformUserRecruitmentAdventurerPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformUserRecruitmentGameMasterPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformUserRecruitmentGameMasterPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};

/** aggregated selection of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAggregate = {
  __typename?: 'demiplane_adventuring_platform_aggregate';
  aggregate?: Maybe<DemiplaneAdventuringPlatformAggregateFields>;
  nodes: Array<DemiplaneAdventuringPlatform>;
};

/** aggregate fields of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAggregateFields = {
  __typename?: 'demiplane_adventuring_platform_aggregate_fields';
  avg?: Maybe<DemiplaneAdventuringPlatformAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAdventuringPlatformMaxFields>;
  min?: Maybe<DemiplaneAdventuringPlatformMinFields>;
  stddev?: Maybe<DemiplaneAdventuringPlatformStddevFields>;
  stddev_pop?: Maybe<DemiplaneAdventuringPlatformStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAdventuringPlatformStddevSampFields>;
  sum?: Maybe<DemiplaneAdventuringPlatformSumFields>;
  var_pop?: Maybe<DemiplaneAdventuringPlatformVarPopFields>;
  var_samp?: Maybe<DemiplaneAdventuringPlatformVarSampFields>;
  variance?: Maybe<DemiplaneAdventuringPlatformVarianceFields>;
};


/** aggregate fields of "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAdventuringPlatformSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAggregateOrderBy = {
  avg?: Maybe<DemiplaneAdventuringPlatformAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAdventuringPlatformMaxOrderBy>;
  min?: Maybe<DemiplaneAdventuringPlatformMinOrderBy>;
  stddev?: Maybe<DemiplaneAdventuringPlatformStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAdventuringPlatformStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAdventuringPlatformStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAdventuringPlatformSumOrderBy>;
  var_pop?: Maybe<DemiplaneAdventuringPlatformVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAdventuringPlatformVarSampOrderBy>;
  variance?: Maybe<DemiplaneAdventuringPlatformVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformArrRelInsertInput = {
  data: Array<DemiplaneAdventuringPlatformInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventuringPlatformOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAdventuringPlatformAvgFields = {
  __typename?: 'demiplane_adventuring_platform_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.adventuring_platform". All fields are combined with a logical 'AND'. */
export type DemiplaneAdventuringPlatformBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAdventuringPlatformBoolExp>>>;
  _not?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAdventuringPlatformBoolExp>>>;
  active?: Maybe<BooleanComparisonExp>;
  ads?: Maybe<DemiplaneAdBoolExp>;
  adventures?: Maybe<DemiplaneAdventureBoolExp>;
  amc_adventuring_platforms?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  favorite_adventuring_platforms?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  uraps?: Maybe<DemiplaneUrapBoolExp>;
  user_recruitment_adventurer_platforms?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
  user_recruitment_game_master_platforms?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};

/** unique or primary key constraints on table "demiplane.adventuring_platform" */
export enum DemiplaneAdventuringPlatformConstraint {
  /** unique or primary key constraint */
  PkAdventuringPlatformId = 'pk_adventuring_platform_id'
}

/** input type for incrementing integer column in table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  ads?: Maybe<DemiplaneAdArrRelInsertInput>;
  adventures?: Maybe<DemiplaneAdventureArrRelInsertInput>;
  amc_adventuring_platforms?: Maybe<DemiplaneAmcAdventuringPlatformsArrRelInsertInput>;
  created?: Maybe<Scalars['timestamptz']>;
  favorite_adventuring_platforms?: Maybe<DemiplaneFavoriteAdventuringPlatformArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  uraps?: Maybe<DemiplaneUrapArrRelInsertInput>;
  user_recruitment_adventurer_platforms?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsArrRelInsertInput>;
  user_recruitment_game_master_platforms?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneAdventuringPlatformMaxFields = {
  __typename?: 'demiplane_adventuring_platform_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAdventuringPlatformMinFields = {
  __typename?: 'demiplane_adventuring_platform_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformMutationResponse = {
  __typename?: 'demiplane_adventuring_platform_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAdventuringPlatform>;
};

/** input type for inserting object relation for remote table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformObjRelInsertInput = {
  data: DemiplaneAdventuringPlatformInsertInput;
  on_conflict?: Maybe<DemiplaneAdventuringPlatformOnConflict>;
};

/** on conflict condition type for table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformOnConflict = {
  constraint: DemiplaneAdventuringPlatformConstraint;
  update_columns: Array<DemiplaneAdventuringPlatformUpdateColumn>;
  where?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
};

/** ordering options when selecting data from "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformOrderBy = {
  active?: Maybe<OrderBy>;
  ads_aggregate?: Maybe<DemiplaneAdAggregateOrderBy>;
  adventures_aggregate?: Maybe<DemiplaneAdventureAggregateOrderBy>;
  amc_adventuring_platforms_aggregate?: Maybe<DemiplaneAmcAdventuringPlatformsAggregateOrderBy>;
  created?: Maybe<OrderBy>;
  favorite_adventuring_platforms_aggregate?: Maybe<DemiplaneFavoriteAdventuringPlatformAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  uraps_aggregate?: Maybe<DemiplaneUrapAggregateOrderBy>;
  user_recruitment_adventurer_platforms_aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsAggregateOrderBy>;
  user_recruitment_game_master_platforms_aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.adventuring_platform" */
export enum DemiplaneAdventuringPlatformSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAdventuringPlatformStddevFields = {
  __typename?: 'demiplane_adventuring_platform_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAdventuringPlatformStddevPopFields = {
  __typename?: 'demiplane_adventuring_platform_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAdventuringPlatformStddevSampFields = {
  __typename?: 'demiplane_adventuring_platform_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAdventuringPlatformSumFields = {
  __typename?: 'demiplane_adventuring_platform_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.adventuring_platform" */
export enum DemiplaneAdventuringPlatformUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAdventuringPlatformVarPopFields = {
  __typename?: 'demiplane_adventuring_platform_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAdventuringPlatformVarSampFields = {
  __typename?: 'demiplane_adventuring_platform_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAdventuringPlatformVarianceFields = {
  __typename?: 'demiplane_adventuring_platform_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.adventuring_platform" */
export type DemiplaneAdventuringPlatformVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatforms = {
  __typename?: 'demiplane_amc_adventuring_platforms';
  adMatchCriteriaId: Scalars['bigint'];
  /** An object relationship */
  ad_match_criterium: DemiplaneAdMatchCriteria;
  adventuringPlatformId: Scalars['bigint'];
  /** An object relationship */
  adventuring_platform: DemiplaneAdventuringPlatform;
};

/** aggregated selection of "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsAggregate = {
  __typename?: 'demiplane_amc_adventuring_platforms_aggregate';
  aggregate?: Maybe<DemiplaneAmcAdventuringPlatformsAggregateFields>;
  nodes: Array<DemiplaneAmcAdventuringPlatforms>;
};

/** aggregate fields of "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsAggregateFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_aggregate_fields';
  avg?: Maybe<DemiplaneAmcAdventuringPlatformsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAmcAdventuringPlatformsMaxFields>;
  min?: Maybe<DemiplaneAmcAdventuringPlatformsMinFields>;
  stddev?: Maybe<DemiplaneAmcAdventuringPlatformsStddevFields>;
  stddev_pop?: Maybe<DemiplaneAmcAdventuringPlatformsStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAmcAdventuringPlatformsStddevSampFields>;
  sum?: Maybe<DemiplaneAmcAdventuringPlatformsSumFields>;
  var_pop?: Maybe<DemiplaneAmcAdventuringPlatformsVarPopFields>;
  var_samp?: Maybe<DemiplaneAmcAdventuringPlatformsVarSampFields>;
  variance?: Maybe<DemiplaneAmcAdventuringPlatformsVarianceFields>;
};


/** aggregate fields of "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsAggregateOrderBy = {
  avg?: Maybe<DemiplaneAmcAdventuringPlatformsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAmcAdventuringPlatformsMaxOrderBy>;
  min?: Maybe<DemiplaneAmcAdventuringPlatformsMinOrderBy>;
  stddev?: Maybe<DemiplaneAmcAdventuringPlatformsStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAmcAdventuringPlatformsStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAmcAdventuringPlatformsStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAmcAdventuringPlatformsSumOrderBy>;
  var_pop?: Maybe<DemiplaneAmcAdventuringPlatformsVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAmcAdventuringPlatformsVarSampOrderBy>;
  variance?: Maybe<DemiplaneAmcAdventuringPlatformsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsArrRelInsertInput = {
  data: Array<DemiplaneAmcAdventuringPlatformsInsertInput>;
  on_conflict?: Maybe<DemiplaneAmcAdventuringPlatformsOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAmcAdventuringPlatformsAvgFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_avg_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  adventuringPlatformId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsAvgOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.amc_adventuring_platforms". All fields are combined with a logical 'AND'. */
export type DemiplaneAmcAdventuringPlatformsBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>>>;
  _not?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>>>;
  adMatchCriteriaId?: Maybe<BigintComparisonExp>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
  adventuringPlatformId?: Maybe<BigintComparisonExp>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
};

/** unique or primary key constraints on table "demiplane.amc_adventuring_platforms" */
export enum DemiplaneAmcAdventuringPlatformsConstraint {
  /** unique or primary key constraint */
  PkAmcAdventuringPlatformsAdMatchCriteriaIdAdventuringPlatf = 'pk_amc_adventuring_platforms_adMatchCriteriaId_adventuringPlatf'
}

/** input type for incrementing integer column in table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsIncInput = {
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsInsertInput = {
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaObjRelInsertInput>;
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformObjRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneAmcAdventuringPlatformsMaxFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_max_fields';
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsMaxOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAmcAdventuringPlatformsMinFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_min_fields';
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsMinOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsMutationResponse = {
  __typename?: 'demiplane_amc_adventuring_platforms_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAmcAdventuringPlatforms>;
};

/** input type for inserting object relation for remote table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsObjRelInsertInput = {
  data: DemiplaneAmcAdventuringPlatformsInsertInput;
  on_conflict?: Maybe<DemiplaneAmcAdventuringPlatformsOnConflict>;
};

/** on conflict condition type for table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsOnConflict = {
  constraint: DemiplaneAmcAdventuringPlatformsConstraint;
  update_columns: Array<DemiplaneAmcAdventuringPlatformsUpdateColumn>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};

/** ordering options when selecting data from "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaOrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformOrderBy>;
};

/** primary key columns input for table: "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsPkColumnsInput = {
  adMatchCriteriaId: Scalars['bigint'];
  adventuringPlatformId: Scalars['bigint'];
};

/** select columns of table "demiplane.amc_adventuring_platforms" */
export enum DemiplaneAmcAdventuringPlatformsSelectColumn {
  /** column name */
  AdMatchCriteriaId = 'adMatchCriteriaId',
  /** column name */
  AdventuringPlatformId = 'adventuringPlatformId'
}

/** input type for updating data in table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsSetInput = {
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneAmcAdventuringPlatformsStddevFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_stddev_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  adventuringPlatformId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsStddevOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAmcAdventuringPlatformsStddevPopFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_stddev_pop_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  adventuringPlatformId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsStddevPopOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAmcAdventuringPlatformsStddevSampFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_stddev_samp_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  adventuringPlatformId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsStddevSampOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAmcAdventuringPlatformsSumFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_sum_fields';
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsSumOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.amc_adventuring_platforms" */
export enum DemiplaneAmcAdventuringPlatformsUpdateColumn {
  /** column name */
  AdMatchCriteriaId = 'adMatchCriteriaId',
  /** column name */
  AdventuringPlatformId = 'adventuringPlatformId'
}

/** aggregate var_pop on columns */
export type DemiplaneAmcAdventuringPlatformsVarPopFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_var_pop_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  adventuringPlatformId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsVarPopOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAmcAdventuringPlatformsVarSampFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_var_samp_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  adventuringPlatformId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsVarSampOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAmcAdventuringPlatformsVarianceFields = {
  __typename?: 'demiplane_amc_adventuring_platforms_variance_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  adventuringPlatformId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.amc_adventuring_platforms" */
export type DemiplaneAmcAdventuringPlatformsVarianceOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  adventuringPlatformId?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypes = {
  __typename?: 'demiplane_amc_game_types';
  adMatchCriteriaId: Scalars['bigint'];
  /** An object relationship */
  ad_match_criterium: DemiplaneAdMatchCriteria;
  gameTypeId: Scalars['bigint'];
  /** An object relationship */
  game_type: DemiplaneGameType;
};

/** aggregated selection of "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesAggregate = {
  __typename?: 'demiplane_amc_game_types_aggregate';
  aggregate?: Maybe<DemiplaneAmcGameTypesAggregateFields>;
  nodes: Array<DemiplaneAmcGameTypes>;
};

/** aggregate fields of "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesAggregateFields = {
  __typename?: 'demiplane_amc_game_types_aggregate_fields';
  avg?: Maybe<DemiplaneAmcGameTypesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAmcGameTypesMaxFields>;
  min?: Maybe<DemiplaneAmcGameTypesMinFields>;
  stddev?: Maybe<DemiplaneAmcGameTypesStddevFields>;
  stddev_pop?: Maybe<DemiplaneAmcGameTypesStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAmcGameTypesStddevSampFields>;
  sum?: Maybe<DemiplaneAmcGameTypesSumFields>;
  var_pop?: Maybe<DemiplaneAmcGameTypesVarPopFields>;
  var_samp?: Maybe<DemiplaneAmcGameTypesVarSampFields>;
  variance?: Maybe<DemiplaneAmcGameTypesVarianceFields>;
};


/** aggregate fields of "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesAggregateOrderBy = {
  avg?: Maybe<DemiplaneAmcGameTypesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAmcGameTypesMaxOrderBy>;
  min?: Maybe<DemiplaneAmcGameTypesMinOrderBy>;
  stddev?: Maybe<DemiplaneAmcGameTypesStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAmcGameTypesStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAmcGameTypesStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAmcGameTypesSumOrderBy>;
  var_pop?: Maybe<DemiplaneAmcGameTypesVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAmcGameTypesVarSampOrderBy>;
  variance?: Maybe<DemiplaneAmcGameTypesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesArrRelInsertInput = {
  data: Array<DemiplaneAmcGameTypesInsertInput>;
  on_conflict?: Maybe<DemiplaneAmcGameTypesOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAmcGameTypesAvgFields = {
  __typename?: 'demiplane_amc_game_types_avg_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  gameTypeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesAvgOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.amc_game_types". All fields are combined with a logical 'AND'. */
export type DemiplaneAmcGameTypesBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAmcGameTypesBoolExp>>>;
  _not?: Maybe<DemiplaneAmcGameTypesBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAmcGameTypesBoolExp>>>;
  adMatchCriteriaId?: Maybe<BigintComparisonExp>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
  gameTypeId?: Maybe<BigintComparisonExp>;
  game_type?: Maybe<DemiplaneGameTypeBoolExp>;
};

/** unique or primary key constraints on table "demiplane.amc_game_types" */
export enum DemiplaneAmcGameTypesConstraint {
  /** unique or primary key constraint */
  PkAmcGameTypesAdMatchCriteriaIdGameTypeId = 'pk_amc_game_types_adMatchCriteriaId_gameTypeId'
}

/** input type for incrementing integer column in table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesIncInput = {
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  gameTypeId?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesInsertInput = {
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaObjRelInsertInput>;
  gameTypeId?: Maybe<Scalars['bigint']>;
  game_type?: Maybe<DemiplaneGameTypeObjRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneAmcGameTypesMaxFields = {
  __typename?: 'demiplane_amc_game_types_max_fields';
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  gameTypeId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesMaxOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAmcGameTypesMinFields = {
  __typename?: 'demiplane_amc_game_types_min_fields';
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  gameTypeId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesMinOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesMutationResponse = {
  __typename?: 'demiplane_amc_game_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAmcGameTypes>;
};

/** input type for inserting object relation for remote table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesObjRelInsertInput = {
  data: DemiplaneAmcGameTypesInsertInput;
  on_conflict?: Maybe<DemiplaneAmcGameTypesOnConflict>;
};

/** on conflict condition type for table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesOnConflict = {
  constraint: DemiplaneAmcGameTypesConstraint;
  update_columns: Array<DemiplaneAmcGameTypesUpdateColumn>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};

/** ordering options when selecting data from "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  ad_match_criterium?: Maybe<DemiplaneAdMatchCriteriaOrderBy>;
  gameTypeId?: Maybe<OrderBy>;
  game_type?: Maybe<DemiplaneGameTypeOrderBy>;
};

/** primary key columns input for table: "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesPkColumnsInput = {
  adMatchCriteriaId: Scalars['bigint'];
  gameTypeId: Scalars['bigint'];
};

/** select columns of table "demiplane.amc_game_types" */
export enum DemiplaneAmcGameTypesSelectColumn {
  /** column name */
  AdMatchCriteriaId = 'adMatchCriteriaId',
  /** column name */
  GameTypeId = 'gameTypeId'
}

/** input type for updating data in table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesSetInput = {
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  gameTypeId?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneAmcGameTypesStddevFields = {
  __typename?: 'demiplane_amc_game_types_stddev_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  gameTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesStddevOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAmcGameTypesStddevPopFields = {
  __typename?: 'demiplane_amc_game_types_stddev_pop_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  gameTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesStddevPopOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAmcGameTypesStddevSampFields = {
  __typename?: 'demiplane_amc_game_types_stddev_samp_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  gameTypeId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesStddevSampOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAmcGameTypesSumFields = {
  __typename?: 'demiplane_amc_game_types_sum_fields';
  adMatchCriteriaId?: Maybe<Scalars['bigint']>;
  gameTypeId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesSumOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.amc_game_types" */
export enum DemiplaneAmcGameTypesUpdateColumn {
  /** column name */
  AdMatchCriteriaId = 'adMatchCriteriaId',
  /** column name */
  GameTypeId = 'gameTypeId'
}

/** aggregate var_pop on columns */
export type DemiplaneAmcGameTypesVarPopFields = {
  __typename?: 'demiplane_amc_game_types_var_pop_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  gameTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesVarPopOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAmcGameTypesVarSampFields = {
  __typename?: 'demiplane_amc_game_types_var_samp_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  gameTypeId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesVarSampOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAmcGameTypesVarianceFields = {
  __typename?: 'demiplane_amc_game_types_variance_fields';
  adMatchCriteriaId?: Maybe<Scalars['Float']>;
  gameTypeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.amc_game_types" */
export type DemiplaneAmcGameTypesVarianceOrderBy = {
  adMatchCriteriaId?: Maybe<OrderBy>;
  gameTypeId?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.app_version" */
export type DemiplaneAppVersion = {
  __typename?: 'demiplane_app_version';
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  updated: Scalars['timestamptz'];
  version: Scalars['String'];
};

/** aggregated selection of "demiplane.app_version" */
export type DemiplaneAppVersionAggregate = {
  __typename?: 'demiplane_app_version_aggregate';
  aggregate?: Maybe<DemiplaneAppVersionAggregateFields>;
  nodes: Array<DemiplaneAppVersion>;
};

/** aggregate fields of "demiplane.app_version" */
export type DemiplaneAppVersionAggregateFields = {
  __typename?: 'demiplane_app_version_aggregate_fields';
  avg?: Maybe<DemiplaneAppVersionAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAppVersionMaxFields>;
  min?: Maybe<DemiplaneAppVersionMinFields>;
  stddev?: Maybe<DemiplaneAppVersionStddevFields>;
  stddev_pop?: Maybe<DemiplaneAppVersionStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAppVersionStddevSampFields>;
  sum?: Maybe<DemiplaneAppVersionSumFields>;
  var_pop?: Maybe<DemiplaneAppVersionVarPopFields>;
  var_samp?: Maybe<DemiplaneAppVersionVarSampFields>;
  variance?: Maybe<DemiplaneAppVersionVarianceFields>;
};


/** aggregate fields of "demiplane.app_version" */
export type DemiplaneAppVersionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAppVersionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.app_version" */
export type DemiplaneAppVersionAggregateOrderBy = {
  avg?: Maybe<DemiplaneAppVersionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAppVersionMaxOrderBy>;
  min?: Maybe<DemiplaneAppVersionMinOrderBy>;
  stddev?: Maybe<DemiplaneAppVersionStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAppVersionStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAppVersionStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAppVersionSumOrderBy>;
  var_pop?: Maybe<DemiplaneAppVersionVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAppVersionVarSampOrderBy>;
  variance?: Maybe<DemiplaneAppVersionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.app_version" */
export type DemiplaneAppVersionArrRelInsertInput = {
  data: Array<DemiplaneAppVersionInsertInput>;
  on_conflict?: Maybe<DemiplaneAppVersionOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAppVersionAvgFields = {
  __typename?: 'demiplane_app_version_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.app_version". All fields are combined with a logical 'AND'. */
export type DemiplaneAppVersionBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAppVersionBoolExp>>>;
  _not?: Maybe<DemiplaneAppVersionBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAppVersionBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  version?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.app_version" */
export enum DemiplaneAppVersionConstraint {
  /** unique or primary key constraint */
  PkAppVersionId = 'pk_app_version_id'
}

/** input type for incrementing integer column in table "demiplane.app_version" */
export type DemiplaneAppVersionIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.app_version" */
export type DemiplaneAppVersionInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DemiplaneAppVersionMaxFields = {
  __typename?: 'demiplane_app_version_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAppVersionMinFields = {
  __typename?: 'demiplane_app_version_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.app_version" */
export type DemiplaneAppVersionMutationResponse = {
  __typename?: 'demiplane_app_version_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAppVersion>;
};

/** input type for inserting object relation for remote table "demiplane.app_version" */
export type DemiplaneAppVersionObjRelInsertInput = {
  data: DemiplaneAppVersionInsertInput;
  on_conflict?: Maybe<DemiplaneAppVersionOnConflict>;
};

/** on conflict condition type for table "demiplane.app_version" */
export type DemiplaneAppVersionOnConflict = {
  constraint: DemiplaneAppVersionConstraint;
  update_columns: Array<DemiplaneAppVersionUpdateColumn>;
  where?: Maybe<DemiplaneAppVersionBoolExp>;
};

/** ordering options when selecting data from "demiplane.app_version" */
export type DemiplaneAppVersionOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  version?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.app_version" */
export type DemiplaneAppVersionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.app_version" */
export enum DemiplaneAppVersionSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "demiplane.app_version" */
export type DemiplaneAppVersionSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneAppVersionStddevFields = {
  __typename?: 'demiplane_app_version_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAppVersionStddevPopFields = {
  __typename?: 'demiplane_app_version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAppVersionStddevSampFields = {
  __typename?: 'demiplane_app_version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAppVersionSumFields = {
  __typename?: 'demiplane_app_version_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.app_version" */
export enum DemiplaneAppVersionUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated',
  /** column name */
  Version = 'version'
}

/** aggregate var_pop on columns */
export type DemiplaneAppVersionVarPopFields = {
  __typename?: 'demiplane_app_version_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAppVersionVarSampFields = {
  __typename?: 'demiplane_app_version_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAppVersionVarianceFields = {
  __typename?: 'demiplane_app_version_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.app_version" */
export type DemiplaneAppVersionVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.attachment" */
export type DemiplaneAttachment = {
  __typename?: 'demiplane_attachment';
  /** An array relationship */
  ads: Array<DemiplaneAd>;
  /** An aggregated array relationship */
  ads_aggregate: DemiplaneAdAggregate;
  /** An array relationship */
  adventures: Array<DemiplaneAdventure>;
  /** An aggregated array relationship */
  adventures_aggregate: DemiplaneAdventureAggregate;
  created: Scalars['timestamptz'];
  file: Scalars['String'];
  id: Scalars['bigint'];
  kind: Scalars['String'];
  mime_type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.attachment" */
export type DemiplaneAttachmentAdsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.attachment" */
export type DemiplaneAttachmentAdsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.attachment" */
export type DemiplaneAttachmentAdventuresArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** columns and relationships of "demiplane.attachment" */
export type DemiplaneAttachmentAdventuresAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};

/** aggregated selection of "demiplane.attachment" */
export type DemiplaneAttachmentAggregate = {
  __typename?: 'demiplane_attachment_aggregate';
  aggregate?: Maybe<DemiplaneAttachmentAggregateFields>;
  nodes: Array<DemiplaneAttachment>;
};

/** aggregate fields of "demiplane.attachment" */
export type DemiplaneAttachmentAggregateFields = {
  __typename?: 'demiplane_attachment_aggregate_fields';
  avg?: Maybe<DemiplaneAttachmentAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAttachmentMaxFields>;
  min?: Maybe<DemiplaneAttachmentMinFields>;
  stddev?: Maybe<DemiplaneAttachmentStddevFields>;
  stddev_pop?: Maybe<DemiplaneAttachmentStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAttachmentStddevSampFields>;
  sum?: Maybe<DemiplaneAttachmentSumFields>;
  var_pop?: Maybe<DemiplaneAttachmentVarPopFields>;
  var_samp?: Maybe<DemiplaneAttachmentVarSampFields>;
  variance?: Maybe<DemiplaneAttachmentVarianceFields>;
};


/** aggregate fields of "demiplane.attachment" */
export type DemiplaneAttachmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAttachmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.attachment" */
export type DemiplaneAttachmentAggregateOrderBy = {
  avg?: Maybe<DemiplaneAttachmentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAttachmentMaxOrderBy>;
  min?: Maybe<DemiplaneAttachmentMinOrderBy>;
  stddev?: Maybe<DemiplaneAttachmentStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAttachmentStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAttachmentStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAttachmentSumOrderBy>;
  var_pop?: Maybe<DemiplaneAttachmentVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAttachmentVarSampOrderBy>;
  variance?: Maybe<DemiplaneAttachmentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.attachment" */
export type DemiplaneAttachmentArrRelInsertInput = {
  data: Array<DemiplaneAttachmentInsertInput>;
  on_conflict?: Maybe<DemiplaneAttachmentOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAttachmentAvgFields = {
  __typename?: 'demiplane_attachment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.attachment". All fields are combined with a logical 'AND'. */
export type DemiplaneAttachmentBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAttachmentBoolExp>>>;
  _not?: Maybe<DemiplaneAttachmentBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAttachmentBoolExp>>>;
  ads?: Maybe<DemiplaneAdBoolExp>;
  adventures?: Maybe<DemiplaneAdventureBoolExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  file?: Maybe<StringComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  kind?: Maybe<StringComparisonExp>;
  mime_type?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.attachment" */
export enum DemiplaneAttachmentConstraint {
  /** unique or primary key constraint */
  PkAttachmentId = 'pk_attachment_id'
}

/** input type for incrementing integer column in table "demiplane.attachment" */
export type DemiplaneAttachmentIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.attachment" */
export type DemiplaneAttachmentInsertInput = {
  ads?: Maybe<DemiplaneAdArrRelInsertInput>;
  adventures?: Maybe<DemiplaneAdventureArrRelInsertInput>;
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAttachmentMaxFields = {
  __typename?: 'demiplane_attachment_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentMaxOrderBy = {
  created?: Maybe<OrderBy>;
  file?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAttachmentMinFields = {
  __typename?: 'demiplane_attachment_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentMinOrderBy = {
  created?: Maybe<OrderBy>;
  file?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.attachment" */
export type DemiplaneAttachmentMutationResponse = {
  __typename?: 'demiplane_attachment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAttachment>;
};

/** input type for inserting object relation for remote table "demiplane.attachment" */
export type DemiplaneAttachmentObjRelInsertInput = {
  data: DemiplaneAttachmentInsertInput;
  on_conflict?: Maybe<DemiplaneAttachmentOnConflict>;
};

/** on conflict condition type for table "demiplane.attachment" */
export type DemiplaneAttachmentOnConflict = {
  constraint: DemiplaneAttachmentConstraint;
  update_columns: Array<DemiplaneAttachmentUpdateColumn>;
  where?: Maybe<DemiplaneAttachmentBoolExp>;
};

/** ordering options when selecting data from "demiplane.attachment" */
export type DemiplaneAttachmentOrderBy = {
  ads_aggregate?: Maybe<DemiplaneAdAggregateOrderBy>;
  adventures_aggregate?: Maybe<DemiplaneAdventureAggregateOrderBy>;
  created?: Maybe<OrderBy>;
  file?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.attachment" */
export type DemiplaneAttachmentPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.attachment" */
export enum DemiplaneAttachmentSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.attachment" */
export type DemiplaneAttachmentSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAttachmentStddevFields = {
  __typename?: 'demiplane_attachment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAttachmentStddevPopFields = {
  __typename?: 'demiplane_attachment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAttachmentStddevSampFields = {
  __typename?: 'demiplane_attachment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAttachmentSumFields = {
  __typename?: 'demiplane_attachment_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.attachment" */
export enum DemiplaneAttachmentUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAttachmentVarPopFields = {
  __typename?: 'demiplane_attachment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAttachmentVarSampFields = {
  __typename?: 'demiplane_attachment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAttachmentVarianceFields = {
  __typename?: 'demiplane_attachment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.attachment" */
export type DemiplaneAttachmentVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.award_type" */
export type DemiplaneAwardType = {
  __typename?: 'demiplane_award_type';
  award_name: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An array relationship */
  player_awards: Array<DemiplanePlayerAward>;
  /** An aggregated array relationship */
  player_awards_aggregate: DemiplanePlayerAwardAggregate;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.award_type" */
export type DemiplaneAwardTypePlayerAwardsArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** columns and relationships of "demiplane.award_type" */
export type DemiplaneAwardTypePlayerAwardsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};

/** aggregated selection of "demiplane.award_type" */
export type DemiplaneAwardTypeAggregate = {
  __typename?: 'demiplane_award_type_aggregate';
  aggregate?: Maybe<DemiplaneAwardTypeAggregateFields>;
  nodes: Array<DemiplaneAwardType>;
};

/** aggregate fields of "demiplane.award_type" */
export type DemiplaneAwardTypeAggregateFields = {
  __typename?: 'demiplane_award_type_aggregate_fields';
  avg?: Maybe<DemiplaneAwardTypeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneAwardTypeMaxFields>;
  min?: Maybe<DemiplaneAwardTypeMinFields>;
  stddev?: Maybe<DemiplaneAwardTypeStddevFields>;
  stddev_pop?: Maybe<DemiplaneAwardTypeStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneAwardTypeStddevSampFields>;
  sum?: Maybe<DemiplaneAwardTypeSumFields>;
  var_pop?: Maybe<DemiplaneAwardTypeVarPopFields>;
  var_samp?: Maybe<DemiplaneAwardTypeVarSampFields>;
  variance?: Maybe<DemiplaneAwardTypeVarianceFields>;
};


/** aggregate fields of "demiplane.award_type" */
export type DemiplaneAwardTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneAwardTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.award_type" */
export type DemiplaneAwardTypeAggregateOrderBy = {
  avg?: Maybe<DemiplaneAwardTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneAwardTypeMaxOrderBy>;
  min?: Maybe<DemiplaneAwardTypeMinOrderBy>;
  stddev?: Maybe<DemiplaneAwardTypeStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneAwardTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneAwardTypeStddevSampOrderBy>;
  sum?: Maybe<DemiplaneAwardTypeSumOrderBy>;
  var_pop?: Maybe<DemiplaneAwardTypeVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneAwardTypeVarSampOrderBy>;
  variance?: Maybe<DemiplaneAwardTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.award_type" */
export type DemiplaneAwardTypeArrRelInsertInput = {
  data: Array<DemiplaneAwardTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneAwardTypeOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneAwardTypeAvgFields = {
  __typename?: 'demiplane_award_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.award_type". All fields are combined with a logical 'AND'. */
export type DemiplaneAwardTypeBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneAwardTypeBoolExp>>>;
  _not?: Maybe<DemiplaneAwardTypeBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneAwardTypeBoolExp>>>;
  award_name?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  player_awards?: Maybe<DemiplanePlayerAwardBoolExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.award_type" */
export enum DemiplaneAwardTypeConstraint {
  /** unique or primary key constraint */
  PkAwardTypeId = 'pk_award_type_id',
  /** unique or primary key constraint */
  UqAwardName = 'uq_award_name'
}

/** input type for incrementing integer column in table "demiplane.award_type" */
export type DemiplaneAwardTypeIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.award_type" */
export type DemiplaneAwardTypeInsertInput = {
  award_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  player_awards?: Maybe<DemiplanePlayerAwardArrRelInsertInput>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneAwardTypeMaxFields = {
  __typename?: 'demiplane_award_type_max_fields';
  award_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeMaxOrderBy = {
  award_name?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneAwardTypeMinFields = {
  __typename?: 'demiplane_award_type_min_fields';
  award_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeMinOrderBy = {
  award_name?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.award_type" */
export type DemiplaneAwardTypeMutationResponse = {
  __typename?: 'demiplane_award_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneAwardType>;
};

/** input type for inserting object relation for remote table "demiplane.award_type" */
export type DemiplaneAwardTypeObjRelInsertInput = {
  data: DemiplaneAwardTypeInsertInput;
  on_conflict?: Maybe<DemiplaneAwardTypeOnConflict>;
};

/** on conflict condition type for table "demiplane.award_type" */
export type DemiplaneAwardTypeOnConflict = {
  constraint: DemiplaneAwardTypeConstraint;
  update_columns: Array<DemiplaneAwardTypeUpdateColumn>;
  where?: Maybe<DemiplaneAwardTypeBoolExp>;
};

/** ordering options when selecting data from "demiplane.award_type" */
export type DemiplaneAwardTypeOrderBy = {
  award_name?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_awards_aggregate?: Maybe<DemiplanePlayerAwardAggregateOrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.award_type" */
export type DemiplaneAwardTypePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.award_type" */
export enum DemiplaneAwardTypeSelectColumn {
  /** column name */
  AwardName = 'award_name',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.award_type" */
export type DemiplaneAwardTypeSetInput = {
  award_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneAwardTypeStddevFields = {
  __typename?: 'demiplane_award_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneAwardTypeStddevPopFields = {
  __typename?: 'demiplane_award_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneAwardTypeStddevSampFields = {
  __typename?: 'demiplane_award_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneAwardTypeSumFields = {
  __typename?: 'demiplane_award_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.award_type" */
export enum DemiplaneAwardTypeUpdateColumn {
  /** column name */
  AwardName = 'award_name',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneAwardTypeVarPopFields = {
  __typename?: 'demiplane_award_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneAwardTypeVarSampFields = {
  __typename?: 'demiplane_award_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneAwardTypeVarianceFields = {
  __typename?: 'demiplane_award_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.award_type" */
export type DemiplaneAwardTypeVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.connection_status" */
export type DemiplaneConnectionStatus = {
  __typename?: 'demiplane_connection_status';
  /** An array relationship */
  adventure_connections: Array<DemiplaneAdventureConnection>;
  /** An aggregated array relationship */
  adventure_connections_aggregate: DemiplaneAdventureConnectionAggregate;
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
};


/** columns and relationships of "demiplane.connection_status" */
export type DemiplaneConnectionStatusAdventureConnectionsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.connection_status" */
export type DemiplaneConnectionStatusAdventureConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};

/** aggregated selection of "demiplane.connection_status" */
export type DemiplaneConnectionStatusAggregate = {
  __typename?: 'demiplane_connection_status_aggregate';
  aggregate?: Maybe<DemiplaneConnectionStatusAggregateFields>;
  nodes: Array<DemiplaneConnectionStatus>;
};

/** aggregate fields of "demiplane.connection_status" */
export type DemiplaneConnectionStatusAggregateFields = {
  __typename?: 'demiplane_connection_status_aggregate_fields';
  avg?: Maybe<DemiplaneConnectionStatusAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneConnectionStatusMaxFields>;
  min?: Maybe<DemiplaneConnectionStatusMinFields>;
  stddev?: Maybe<DemiplaneConnectionStatusStddevFields>;
  stddev_pop?: Maybe<DemiplaneConnectionStatusStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneConnectionStatusStddevSampFields>;
  sum?: Maybe<DemiplaneConnectionStatusSumFields>;
  var_pop?: Maybe<DemiplaneConnectionStatusVarPopFields>;
  var_samp?: Maybe<DemiplaneConnectionStatusVarSampFields>;
  variance?: Maybe<DemiplaneConnectionStatusVarianceFields>;
};


/** aggregate fields of "demiplane.connection_status" */
export type DemiplaneConnectionStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneConnectionStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusAggregateOrderBy = {
  avg?: Maybe<DemiplaneConnectionStatusAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneConnectionStatusMaxOrderBy>;
  min?: Maybe<DemiplaneConnectionStatusMinOrderBy>;
  stddev?: Maybe<DemiplaneConnectionStatusStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneConnectionStatusStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneConnectionStatusStddevSampOrderBy>;
  sum?: Maybe<DemiplaneConnectionStatusSumOrderBy>;
  var_pop?: Maybe<DemiplaneConnectionStatusVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneConnectionStatusVarSampOrderBy>;
  variance?: Maybe<DemiplaneConnectionStatusVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.connection_status" */
export type DemiplaneConnectionStatusArrRelInsertInput = {
  data: Array<DemiplaneConnectionStatusInsertInput>;
  on_conflict?: Maybe<DemiplaneConnectionStatusOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneConnectionStatusAvgFields = {
  __typename?: 'demiplane_connection_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.connection_status". All fields are combined with a logical 'AND'. */
export type DemiplaneConnectionStatusBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneConnectionStatusBoolExp>>>;
  _not?: Maybe<DemiplaneConnectionStatusBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneConnectionStatusBoolExp>>>;
  adventure_connections?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.connection_status" */
export enum DemiplaneConnectionStatusConstraint {
  /** unique or primary key constraint */
  PkConnectionStatusId = 'pk_connection_status_id'
}

/** input type for incrementing integer column in table "demiplane.connection_status" */
export type DemiplaneConnectionStatusIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.connection_status" */
export type DemiplaneConnectionStatusInsertInput = {
  adventure_connections?: Maybe<DemiplaneAdventureConnectionArrRelInsertInput>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DemiplaneConnectionStatusMaxFields = {
  __typename?: 'demiplane_connection_status_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneConnectionStatusMinFields = {
  __typename?: 'demiplane_connection_status_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.connection_status" */
export type DemiplaneConnectionStatusMutationResponse = {
  __typename?: 'demiplane_connection_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneConnectionStatus>;
};

/** input type for inserting object relation for remote table "demiplane.connection_status" */
export type DemiplaneConnectionStatusObjRelInsertInput = {
  data: DemiplaneConnectionStatusInsertInput;
  on_conflict?: Maybe<DemiplaneConnectionStatusOnConflict>;
};

/** on conflict condition type for table "demiplane.connection_status" */
export type DemiplaneConnectionStatusOnConflict = {
  constraint: DemiplaneConnectionStatusConstraint;
  update_columns: Array<DemiplaneConnectionStatusUpdateColumn>;
  where?: Maybe<DemiplaneConnectionStatusBoolExp>;
};

/** ordering options when selecting data from "demiplane.connection_status" */
export type DemiplaneConnectionStatusOrderBy = {
  adventure_connections_aggregate?: Maybe<DemiplaneAdventureConnectionAggregateOrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.connection_status" */
export type DemiplaneConnectionStatusPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.connection_status" */
export enum DemiplaneConnectionStatusSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "demiplane.connection_status" */
export type DemiplaneConnectionStatusSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneConnectionStatusStddevFields = {
  __typename?: 'demiplane_connection_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneConnectionStatusStddevPopFields = {
  __typename?: 'demiplane_connection_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneConnectionStatusStddevSampFields = {
  __typename?: 'demiplane_connection_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneConnectionStatusSumFields = {
  __typename?: 'demiplane_connection_status_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.connection_status" */
export enum DemiplaneConnectionStatusUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type DemiplaneConnectionStatusVarPopFields = {
  __typename?: 'demiplane_connection_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneConnectionStatusVarSampFields = {
  __typename?: 'demiplane_connection_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneConnectionStatusVarianceFields = {
  __typename?: 'demiplane_connection_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.connection_status" */
export type DemiplaneConnectionStatusVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.connection_type" */
export type DemiplaneConnectionType = {
  __typename?: 'demiplane_connection_type';
  /** An array relationship */
  adventure_connections: Array<DemiplaneAdventureConnection>;
  /** An aggregated array relationship */
  adventure_connections_aggregate: DemiplaneAdventureConnectionAggregate;
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
};


/** columns and relationships of "demiplane.connection_type" */
export type DemiplaneConnectionTypeAdventureConnectionsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.connection_type" */
export type DemiplaneConnectionTypeAdventureConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};

/** aggregated selection of "demiplane.connection_type" */
export type DemiplaneConnectionTypeAggregate = {
  __typename?: 'demiplane_connection_type_aggregate';
  aggregate?: Maybe<DemiplaneConnectionTypeAggregateFields>;
  nodes: Array<DemiplaneConnectionType>;
};

/** aggregate fields of "demiplane.connection_type" */
export type DemiplaneConnectionTypeAggregateFields = {
  __typename?: 'demiplane_connection_type_aggregate_fields';
  avg?: Maybe<DemiplaneConnectionTypeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneConnectionTypeMaxFields>;
  min?: Maybe<DemiplaneConnectionTypeMinFields>;
  stddev?: Maybe<DemiplaneConnectionTypeStddevFields>;
  stddev_pop?: Maybe<DemiplaneConnectionTypeStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneConnectionTypeStddevSampFields>;
  sum?: Maybe<DemiplaneConnectionTypeSumFields>;
  var_pop?: Maybe<DemiplaneConnectionTypeVarPopFields>;
  var_samp?: Maybe<DemiplaneConnectionTypeVarSampFields>;
  variance?: Maybe<DemiplaneConnectionTypeVarianceFields>;
};


/** aggregate fields of "demiplane.connection_type" */
export type DemiplaneConnectionTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneConnectionTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeAggregateOrderBy = {
  avg?: Maybe<DemiplaneConnectionTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneConnectionTypeMaxOrderBy>;
  min?: Maybe<DemiplaneConnectionTypeMinOrderBy>;
  stddev?: Maybe<DemiplaneConnectionTypeStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneConnectionTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneConnectionTypeStddevSampOrderBy>;
  sum?: Maybe<DemiplaneConnectionTypeSumOrderBy>;
  var_pop?: Maybe<DemiplaneConnectionTypeVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneConnectionTypeVarSampOrderBy>;
  variance?: Maybe<DemiplaneConnectionTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.connection_type" */
export type DemiplaneConnectionTypeArrRelInsertInput = {
  data: Array<DemiplaneConnectionTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneConnectionTypeOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneConnectionTypeAvgFields = {
  __typename?: 'demiplane_connection_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.connection_type". All fields are combined with a logical 'AND'. */
export type DemiplaneConnectionTypeBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneConnectionTypeBoolExp>>>;
  _not?: Maybe<DemiplaneConnectionTypeBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneConnectionTypeBoolExp>>>;
  adventure_connections?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.connection_type" */
export enum DemiplaneConnectionTypeConstraint {
  /** unique or primary key constraint */
  PkConnectionTypeId = 'pk_connection_type_id'
}

/** input type for incrementing integer column in table "demiplane.connection_type" */
export type DemiplaneConnectionTypeIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.connection_type" */
export type DemiplaneConnectionTypeInsertInput = {
  adventure_connections?: Maybe<DemiplaneAdventureConnectionArrRelInsertInput>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DemiplaneConnectionTypeMaxFields = {
  __typename?: 'demiplane_connection_type_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneConnectionTypeMinFields = {
  __typename?: 'demiplane_connection_type_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.connection_type" */
export type DemiplaneConnectionTypeMutationResponse = {
  __typename?: 'demiplane_connection_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneConnectionType>;
};

/** input type for inserting object relation for remote table "demiplane.connection_type" */
export type DemiplaneConnectionTypeObjRelInsertInput = {
  data: DemiplaneConnectionTypeInsertInput;
  on_conflict?: Maybe<DemiplaneConnectionTypeOnConflict>;
};

/** on conflict condition type for table "demiplane.connection_type" */
export type DemiplaneConnectionTypeOnConflict = {
  constraint: DemiplaneConnectionTypeConstraint;
  update_columns: Array<DemiplaneConnectionTypeUpdateColumn>;
  where?: Maybe<DemiplaneConnectionTypeBoolExp>;
};

/** ordering options when selecting data from "demiplane.connection_type" */
export type DemiplaneConnectionTypeOrderBy = {
  adventure_connections_aggregate?: Maybe<DemiplaneAdventureConnectionAggregateOrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.connection_type" */
export type DemiplaneConnectionTypePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.connection_type" */
export enum DemiplaneConnectionTypeSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "demiplane.connection_type" */
export type DemiplaneConnectionTypeSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneConnectionTypeStddevFields = {
  __typename?: 'demiplane_connection_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneConnectionTypeStddevPopFields = {
  __typename?: 'demiplane_connection_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneConnectionTypeStddevSampFields = {
  __typename?: 'demiplane_connection_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneConnectionTypeSumFields = {
  __typename?: 'demiplane_connection_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.connection_type" */
export enum DemiplaneConnectionTypeUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type DemiplaneConnectionTypeVarPopFields = {
  __typename?: 'demiplane_connection_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneConnectionTypeVarSampFields = {
  __typename?: 'demiplane_connection_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneConnectionTypeVarianceFields = {
  __typename?: 'demiplane_connection_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.connection_type" */
export type DemiplaneConnectionTypeVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.contact_type" */
export type DemiplaneContactType = {
  __typename?: 'demiplane_contact_type';
  /** An array relationship */
  adventure_connections: Array<DemiplaneAdventureConnection>;
  /** An aggregated array relationship */
  adventure_connections_aggregate: DemiplaneAdventureConnectionAggregate;
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
};


/** columns and relationships of "demiplane.contact_type" */
export type DemiplaneContactTypeAdventureConnectionsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.contact_type" */
export type DemiplaneContactTypeAdventureConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};

/** aggregated selection of "demiplane.contact_type" */
export type DemiplaneContactTypeAggregate = {
  __typename?: 'demiplane_contact_type_aggregate';
  aggregate?: Maybe<DemiplaneContactTypeAggregateFields>;
  nodes: Array<DemiplaneContactType>;
};

/** aggregate fields of "demiplane.contact_type" */
export type DemiplaneContactTypeAggregateFields = {
  __typename?: 'demiplane_contact_type_aggregate_fields';
  avg?: Maybe<DemiplaneContactTypeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneContactTypeMaxFields>;
  min?: Maybe<DemiplaneContactTypeMinFields>;
  stddev?: Maybe<DemiplaneContactTypeStddevFields>;
  stddev_pop?: Maybe<DemiplaneContactTypeStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneContactTypeStddevSampFields>;
  sum?: Maybe<DemiplaneContactTypeSumFields>;
  var_pop?: Maybe<DemiplaneContactTypeVarPopFields>;
  var_samp?: Maybe<DemiplaneContactTypeVarSampFields>;
  variance?: Maybe<DemiplaneContactTypeVarianceFields>;
};


/** aggregate fields of "demiplane.contact_type" */
export type DemiplaneContactTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneContactTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.contact_type" */
export type DemiplaneContactTypeAggregateOrderBy = {
  avg?: Maybe<DemiplaneContactTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneContactTypeMaxOrderBy>;
  min?: Maybe<DemiplaneContactTypeMinOrderBy>;
  stddev?: Maybe<DemiplaneContactTypeStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneContactTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneContactTypeStddevSampOrderBy>;
  sum?: Maybe<DemiplaneContactTypeSumOrderBy>;
  var_pop?: Maybe<DemiplaneContactTypeVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneContactTypeVarSampOrderBy>;
  variance?: Maybe<DemiplaneContactTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.contact_type" */
export type DemiplaneContactTypeArrRelInsertInput = {
  data: Array<DemiplaneContactTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneContactTypeOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneContactTypeAvgFields = {
  __typename?: 'demiplane_contact_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.contact_type". All fields are combined with a logical 'AND'. */
export type DemiplaneContactTypeBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneContactTypeBoolExp>>>;
  _not?: Maybe<DemiplaneContactTypeBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneContactTypeBoolExp>>>;
  adventure_connections?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.contact_type" */
export enum DemiplaneContactTypeConstraint {
  /** unique or primary key constraint */
  PkContactTypeId = 'pk_contact_type_id'
}

/** input type for incrementing integer column in table "demiplane.contact_type" */
export type DemiplaneContactTypeIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.contact_type" */
export type DemiplaneContactTypeInsertInput = {
  adventure_connections?: Maybe<DemiplaneAdventureConnectionArrRelInsertInput>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DemiplaneContactTypeMaxFields = {
  __typename?: 'demiplane_contact_type_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneContactTypeMinFields = {
  __typename?: 'demiplane_contact_type_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.contact_type" */
export type DemiplaneContactTypeMutationResponse = {
  __typename?: 'demiplane_contact_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneContactType>;
};

/** input type for inserting object relation for remote table "demiplane.contact_type" */
export type DemiplaneContactTypeObjRelInsertInput = {
  data: DemiplaneContactTypeInsertInput;
  on_conflict?: Maybe<DemiplaneContactTypeOnConflict>;
};

/** on conflict condition type for table "demiplane.contact_type" */
export type DemiplaneContactTypeOnConflict = {
  constraint: DemiplaneContactTypeConstraint;
  update_columns: Array<DemiplaneContactTypeUpdateColumn>;
  where?: Maybe<DemiplaneContactTypeBoolExp>;
};

/** ordering options when selecting data from "demiplane.contact_type" */
export type DemiplaneContactTypeOrderBy = {
  adventure_connections_aggregate?: Maybe<DemiplaneAdventureConnectionAggregateOrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.contact_type" */
export type DemiplaneContactTypePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.contact_type" */
export enum DemiplaneContactTypeSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "demiplane.contact_type" */
export type DemiplaneContactTypeSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneContactTypeStddevFields = {
  __typename?: 'demiplane_contact_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneContactTypeStddevPopFields = {
  __typename?: 'demiplane_contact_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneContactTypeStddevSampFields = {
  __typename?: 'demiplane_contact_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneContactTypeSumFields = {
  __typename?: 'demiplane_contact_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.contact_type" */
export enum DemiplaneContactTypeUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type DemiplaneContactTypeVarPopFields = {
  __typename?: 'demiplane_contact_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneContactTypeVarSampFields = {
  __typename?: 'demiplane_contact_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneContactTypeVarianceFields = {
  __typename?: 'demiplane_contact_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.contact_type" */
export type DemiplaneContactTypeVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.country" */
export type DemiplaneCountry = {
  __typename?: 'demiplane_country';
  active: Scalars['Boolean'];
  code2: Scalars['String'];
  code3: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  regions: Array<DemiplaneRegion>;
  /** An aggregated array relationship */
  regions_aggregate: DemiplaneRegionAggregate;
  /** An array relationship */
  transaction_receipts: Array<DemiplaneTransactionReceipt>;
  /** An aggregated array relationship */
  transaction_receipts_aggregate: DemiplaneTransactionReceiptAggregate;
  updated: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<DemiplaneUser>;
  /** An aggregated array relationship */
  users_aggregate: DemiplaneUserAggregate;
};


/** columns and relationships of "demiplane.country" */
export type DemiplaneCountryRegionsArgs = {
  distinct_on?: Maybe<Array<DemiplaneRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRegionOrderBy>>;
  where?: Maybe<DemiplaneRegionBoolExp>;
};


/** columns and relationships of "demiplane.country" */
export type DemiplaneCountryRegionsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRegionOrderBy>>;
  where?: Maybe<DemiplaneRegionBoolExp>;
};


/** columns and relationships of "demiplane.country" */
export type DemiplaneCountryTransactionReceiptsArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** columns and relationships of "demiplane.country" */
export type DemiplaneCountryTransactionReceiptsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** columns and relationships of "demiplane.country" */
export type DemiplaneCountryUsersArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};


/** columns and relationships of "demiplane.country" */
export type DemiplaneCountryUsersAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};

/** aggregated selection of "demiplane.country" */
export type DemiplaneCountryAggregate = {
  __typename?: 'demiplane_country_aggregate';
  aggregate?: Maybe<DemiplaneCountryAggregateFields>;
  nodes: Array<DemiplaneCountry>;
};

/** aggregate fields of "demiplane.country" */
export type DemiplaneCountryAggregateFields = {
  __typename?: 'demiplane_country_aggregate_fields';
  avg?: Maybe<DemiplaneCountryAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneCountryMaxFields>;
  min?: Maybe<DemiplaneCountryMinFields>;
  stddev?: Maybe<DemiplaneCountryStddevFields>;
  stddev_pop?: Maybe<DemiplaneCountryStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneCountryStddevSampFields>;
  sum?: Maybe<DemiplaneCountrySumFields>;
  var_pop?: Maybe<DemiplaneCountryVarPopFields>;
  var_samp?: Maybe<DemiplaneCountryVarSampFields>;
  variance?: Maybe<DemiplaneCountryVarianceFields>;
};


/** aggregate fields of "demiplane.country" */
export type DemiplaneCountryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneCountrySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.country" */
export type DemiplaneCountryAggregateOrderBy = {
  avg?: Maybe<DemiplaneCountryAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneCountryMaxOrderBy>;
  min?: Maybe<DemiplaneCountryMinOrderBy>;
  stddev?: Maybe<DemiplaneCountryStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneCountryStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneCountryStddevSampOrderBy>;
  sum?: Maybe<DemiplaneCountrySumOrderBy>;
  var_pop?: Maybe<DemiplaneCountryVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneCountryVarSampOrderBy>;
  variance?: Maybe<DemiplaneCountryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.country" */
export type DemiplaneCountryArrRelInsertInput = {
  data: Array<DemiplaneCountryInsertInput>;
  on_conflict?: Maybe<DemiplaneCountryOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneCountryAvgFields = {
  __typename?: 'demiplane_country_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.country" */
export type DemiplaneCountryAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.country". All fields are combined with a logical 'AND'. */
export type DemiplaneCountryBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneCountryBoolExp>>>;
  _not?: Maybe<DemiplaneCountryBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneCountryBoolExp>>>;
  active?: Maybe<BooleanComparisonExp>;
  code2?: Maybe<StringComparisonExp>;
  code3?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  regions?: Maybe<DemiplaneRegionBoolExp>;
  transaction_receipts?: Maybe<DemiplaneTransactionReceiptBoolExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<DemiplaneUserBoolExp>;
};

/** unique or primary key constraints on table "demiplane.country" */
export enum DemiplaneCountryConstraint {
  /** unique or primary key constraint */
  PkCountryId = 'pk_country_id'
}

/** input type for incrementing integer column in table "demiplane.country" */
export type DemiplaneCountryIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.country" */
export type DemiplaneCountryInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  code2?: Maybe<Scalars['String']>;
  code3?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regions?: Maybe<DemiplaneRegionArrRelInsertInput>;
  transaction_receipts?: Maybe<DemiplaneTransactionReceiptArrRelInsertInput>;
  updated?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<DemiplaneUserArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneCountryMaxFields = {
  __typename?: 'demiplane_country_max_fields';
  code2?: Maybe<Scalars['String']>;
  code3?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.country" */
export type DemiplaneCountryMaxOrderBy = {
  code2?: Maybe<OrderBy>;
  code3?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneCountryMinFields = {
  __typename?: 'demiplane_country_min_fields';
  code2?: Maybe<Scalars['String']>;
  code3?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.country" */
export type DemiplaneCountryMinOrderBy = {
  code2?: Maybe<OrderBy>;
  code3?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.country" */
export type DemiplaneCountryMutationResponse = {
  __typename?: 'demiplane_country_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneCountry>;
};

/** input type for inserting object relation for remote table "demiplane.country" */
export type DemiplaneCountryObjRelInsertInput = {
  data: DemiplaneCountryInsertInput;
  on_conflict?: Maybe<DemiplaneCountryOnConflict>;
};

/** on conflict condition type for table "demiplane.country" */
export type DemiplaneCountryOnConflict = {
  constraint: DemiplaneCountryConstraint;
  update_columns: Array<DemiplaneCountryUpdateColumn>;
  where?: Maybe<DemiplaneCountryBoolExp>;
};

/** ordering options when selecting data from "demiplane.country" */
export type DemiplaneCountryOrderBy = {
  active?: Maybe<OrderBy>;
  code2?: Maybe<OrderBy>;
  code3?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  regions_aggregate?: Maybe<DemiplaneRegionAggregateOrderBy>;
  transaction_receipts_aggregate?: Maybe<DemiplaneTransactionReceiptAggregateOrderBy>;
  updated?: Maybe<OrderBy>;
  users_aggregate?: Maybe<DemiplaneUserAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.country" */
export type DemiplaneCountryPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "demiplane.country" */
export enum DemiplaneCountrySelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code2 = 'code2',
  /** column name */
  Code3 = 'code3',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.country" */
export type DemiplaneCountrySetInput = {
  active?: Maybe<Scalars['Boolean']>;
  code2?: Maybe<Scalars['String']>;
  code3?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneCountryStddevFields = {
  __typename?: 'demiplane_country_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.country" */
export type DemiplaneCountryStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneCountryStddevPopFields = {
  __typename?: 'demiplane_country_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.country" */
export type DemiplaneCountryStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneCountryStddevSampFields = {
  __typename?: 'demiplane_country_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.country" */
export type DemiplaneCountryStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneCountrySumFields = {
  __typename?: 'demiplane_country_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.country" */
export type DemiplaneCountrySumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.country" */
export enum DemiplaneCountryUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code2 = 'code2',
  /** column name */
  Code3 = 'code3',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneCountryVarPopFields = {
  __typename?: 'demiplane_country_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.country" */
export type DemiplaneCountryVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneCountryVarSampFields = {
  __typename?: 'demiplane_country_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.country" */
export type DemiplaneCountryVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneCountryVarianceFields = {
  __typename?: 'demiplane_country_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.country" */
export type DemiplaneCountryVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.credit_card" */
export type DemiplaneCreditCard = {
  __typename?: 'demiplane_credit_card';
  card_name?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  created: Scalars['timestamptz'];
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  last_digits?: Maybe<Scalars['String']>;
  stripe_card_id?: Maybe<Scalars['String']>;
  stripe_token?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.credit_card" */
export type DemiplaneCreditCardAggregate = {
  __typename?: 'demiplane_credit_card_aggregate';
  aggregate?: Maybe<DemiplaneCreditCardAggregateFields>;
  nodes: Array<DemiplaneCreditCard>;
};

/** aggregate fields of "demiplane.credit_card" */
export type DemiplaneCreditCardAggregateFields = {
  __typename?: 'demiplane_credit_card_aggregate_fields';
  avg?: Maybe<DemiplaneCreditCardAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneCreditCardMaxFields>;
  min?: Maybe<DemiplaneCreditCardMinFields>;
  stddev?: Maybe<DemiplaneCreditCardStddevFields>;
  stddev_pop?: Maybe<DemiplaneCreditCardStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneCreditCardStddevSampFields>;
  sum?: Maybe<DemiplaneCreditCardSumFields>;
  var_pop?: Maybe<DemiplaneCreditCardVarPopFields>;
  var_samp?: Maybe<DemiplaneCreditCardVarSampFields>;
  variance?: Maybe<DemiplaneCreditCardVarianceFields>;
};


/** aggregate fields of "demiplane.credit_card" */
export type DemiplaneCreditCardAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneCreditCardSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.credit_card" */
export type DemiplaneCreditCardAggregateOrderBy = {
  avg?: Maybe<DemiplaneCreditCardAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneCreditCardMaxOrderBy>;
  min?: Maybe<DemiplaneCreditCardMinOrderBy>;
  stddev?: Maybe<DemiplaneCreditCardStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneCreditCardStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneCreditCardStddevSampOrderBy>;
  sum?: Maybe<DemiplaneCreditCardSumOrderBy>;
  var_pop?: Maybe<DemiplaneCreditCardVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneCreditCardVarSampOrderBy>;
  variance?: Maybe<DemiplaneCreditCardVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.credit_card" */
export type DemiplaneCreditCardArrRelInsertInput = {
  data: Array<DemiplaneCreditCardInsertInput>;
  on_conflict?: Maybe<DemiplaneCreditCardOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneCreditCardAvgFields = {
  __typename?: 'demiplane_credit_card_avg_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardAvgOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.credit_card". All fields are combined with a logical 'AND'. */
export type DemiplaneCreditCardBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneCreditCardBoolExp>>>;
  _not?: Maybe<DemiplaneCreditCardBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneCreditCardBoolExp>>>;
  card_name?: Maybe<StringComparisonExp>;
  card_type?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  exp_month?: Maybe<IntComparisonExp>;
  exp_year?: Maybe<IntComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  last_digits?: Maybe<StringComparisonExp>;
  stripe_card_id?: Maybe<StringComparisonExp>;
  stripe_token?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.credit_card" */
export enum DemiplaneCreditCardConstraint {
  /** unique or primary key constraint */
  PkCreditCardId = 'pk_credit_card_id'
}

/** input type for incrementing integer column in table "demiplane.credit_card" */
export type DemiplaneCreditCardIncInput = {
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.credit_card" */
export type DemiplaneCreditCardInsertInput = {
  card_name?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_digits?: Maybe<Scalars['String']>;
  stripe_card_id?: Maybe<Scalars['String']>;
  stripe_token?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneCreditCardMaxFields = {
  __typename?: 'demiplane_credit_card_max_fields';
  card_name?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_digits?: Maybe<Scalars['String']>;
  stripe_card_id?: Maybe<Scalars['String']>;
  stripe_token?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardMaxOrderBy = {
  card_name?: Maybe<OrderBy>;
  card_type?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_digits?: Maybe<OrderBy>;
  stripe_card_id?: Maybe<OrderBy>;
  stripe_token?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneCreditCardMinFields = {
  __typename?: 'demiplane_credit_card_min_fields';
  card_name?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_digits?: Maybe<Scalars['String']>;
  stripe_card_id?: Maybe<Scalars['String']>;
  stripe_token?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardMinOrderBy = {
  card_name?: Maybe<OrderBy>;
  card_type?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_digits?: Maybe<OrderBy>;
  stripe_card_id?: Maybe<OrderBy>;
  stripe_token?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.credit_card" */
export type DemiplaneCreditCardMutationResponse = {
  __typename?: 'demiplane_credit_card_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneCreditCard>;
};

/** input type for inserting object relation for remote table "demiplane.credit_card" */
export type DemiplaneCreditCardObjRelInsertInput = {
  data: DemiplaneCreditCardInsertInput;
  on_conflict?: Maybe<DemiplaneCreditCardOnConflict>;
};

/** on conflict condition type for table "demiplane.credit_card" */
export type DemiplaneCreditCardOnConflict = {
  constraint: DemiplaneCreditCardConstraint;
  update_columns: Array<DemiplaneCreditCardUpdateColumn>;
  where?: Maybe<DemiplaneCreditCardBoolExp>;
};

/** ordering options when selecting data from "demiplane.credit_card" */
export type DemiplaneCreditCardOrderBy = {
  card_name?: Maybe<OrderBy>;
  card_type?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  last_digits?: Maybe<OrderBy>;
  stripe_card_id?: Maybe<OrderBy>;
  stripe_token?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.credit_card" */
export type DemiplaneCreditCardPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.credit_card" */
export enum DemiplaneCreditCardSelectColumn {
  /** column name */
  CardName = 'card_name',
  /** column name */
  CardType = 'card_type',
  /** column name */
  Created = 'created',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  Id = 'id',
  /** column name */
  LastDigits = 'last_digits',
  /** column name */
  StripeCardId = 'stripe_card_id',
  /** column name */
  StripeToken = 'stripe_token',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.credit_card" */
export type DemiplaneCreditCardSetInput = {
  card_name?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  last_digits?: Maybe<Scalars['String']>;
  stripe_card_id?: Maybe<Scalars['String']>;
  stripe_token?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneCreditCardStddevFields = {
  __typename?: 'demiplane_credit_card_stddev_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardStddevOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneCreditCardStddevPopFields = {
  __typename?: 'demiplane_credit_card_stddev_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardStddevPopOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneCreditCardStddevSampFields = {
  __typename?: 'demiplane_credit_card_stddev_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardStddevSampOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneCreditCardSumFields = {
  __typename?: 'demiplane_credit_card_sum_fields';
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardSumOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.credit_card" */
export enum DemiplaneCreditCardUpdateColumn {
  /** column name */
  CardName = 'card_name',
  /** column name */
  CardType = 'card_type',
  /** column name */
  Created = 'created',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  Id = 'id',
  /** column name */
  LastDigits = 'last_digits',
  /** column name */
  StripeCardId = 'stripe_card_id',
  /** column name */
  StripeToken = 'stripe_token',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneCreditCardVarPopFields = {
  __typename?: 'demiplane_credit_card_var_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardVarPopOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneCreditCardVarSampFields = {
  __typename?: 'demiplane_credit_card_var_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardVarSampOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneCreditCardVarianceFields = {
  __typename?: 'demiplane_credit_card_variance_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.credit_card" */
export type DemiplaneCreditCardVarianceOrderBy = {
  exp_month?: Maybe<OrderBy>;
  exp_year?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneTool = {
  __typename?: 'demiplane_demiplane_tool';
  created?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  title: Scalars['String'];
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolAggregate = {
  __typename?: 'demiplane_demiplane_tool_aggregate';
  aggregate?: Maybe<DemiplaneDemiplaneToolAggregateFields>;
  nodes: Array<DemiplaneDemiplaneTool>;
};

/** aggregate fields of "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolAggregateFields = {
  __typename?: 'demiplane_demiplane_tool_aggregate_fields';
  avg?: Maybe<DemiplaneDemiplaneToolAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneDemiplaneToolMaxFields>;
  min?: Maybe<DemiplaneDemiplaneToolMinFields>;
  stddev?: Maybe<DemiplaneDemiplaneToolStddevFields>;
  stddev_pop?: Maybe<DemiplaneDemiplaneToolStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneDemiplaneToolStddevSampFields>;
  sum?: Maybe<DemiplaneDemiplaneToolSumFields>;
  var_pop?: Maybe<DemiplaneDemiplaneToolVarPopFields>;
  var_samp?: Maybe<DemiplaneDemiplaneToolVarSampFields>;
  variance?: Maybe<DemiplaneDemiplaneToolVarianceFields>;
};


/** aggregate fields of "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneDemiplaneToolSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolAggregateOrderBy = {
  avg?: Maybe<DemiplaneDemiplaneToolAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneDemiplaneToolMaxOrderBy>;
  min?: Maybe<DemiplaneDemiplaneToolMinOrderBy>;
  stddev?: Maybe<DemiplaneDemiplaneToolStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneDemiplaneToolStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneDemiplaneToolStddevSampOrderBy>;
  sum?: Maybe<DemiplaneDemiplaneToolSumOrderBy>;
  var_pop?: Maybe<DemiplaneDemiplaneToolVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneDemiplaneToolVarSampOrderBy>;
  variance?: Maybe<DemiplaneDemiplaneToolVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolArrRelInsertInput = {
  data: Array<DemiplaneDemiplaneToolInsertInput>;
  on_conflict?: Maybe<DemiplaneDemiplaneToolOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneDemiplaneToolAvgFields = {
  __typename?: 'demiplane_demiplane_tool_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.demiplane_tool". All fields are combined with a logical 'AND'. */
export type DemiplaneDemiplaneToolBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneDemiplaneToolBoolExp>>>;
  _not?: Maybe<DemiplaneDemiplaneToolBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneDemiplaneToolBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.demiplane_tool" */
export enum DemiplaneDemiplaneToolConstraint {
  /** unique or primary key constraint */
  DemiplaneToolPkey = 'demiplane_tool_pkey',
  /** unique or primary key constraint */
  DemiplaneToolTitleKey = 'demiplane_tool_title_key'
}

/** input type for incrementing integer column in table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneDemiplaneToolMaxFields = {
  __typename?: 'demiplane_demiplane_tool_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneDemiplaneToolMinFields = {
  __typename?: 'demiplane_demiplane_tool_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolMutationResponse = {
  __typename?: 'demiplane_demiplane_tool_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneDemiplaneTool>;
};

/** input type for inserting object relation for remote table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolObjRelInsertInput = {
  data: DemiplaneDemiplaneToolInsertInput;
  on_conflict?: Maybe<DemiplaneDemiplaneToolOnConflict>;
};

/** on conflict condition type for table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolOnConflict = {
  constraint: DemiplaneDemiplaneToolConstraint;
  update_columns: Array<DemiplaneDemiplaneToolUpdateColumn>;
  where?: Maybe<DemiplaneDemiplaneToolBoolExp>;
};

/** ordering options when selecting data from "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "demiplane.demiplane_tool" */
export enum DemiplaneDemiplaneToolSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneDemiplaneToolStddevFields = {
  __typename?: 'demiplane_demiplane_tool_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneDemiplaneToolStddevPopFields = {
  __typename?: 'demiplane_demiplane_tool_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneDemiplaneToolStddevSampFields = {
  __typename?: 'demiplane_demiplane_tool_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneDemiplaneToolSumFields = {
  __typename?: 'demiplane_demiplane_tool_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.demiplane_tool" */
export enum DemiplaneDemiplaneToolUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneDemiplaneToolVarPopFields = {
  __typename?: 'demiplane_demiplane_tool_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneDemiplaneToolVarSampFields = {
  __typename?: 'demiplane_demiplane_tool_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneDemiplaneToolVarianceFields = {
  __typename?: 'demiplane_demiplane_tool_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.demiplane_tool" */
export type DemiplaneDemiplaneToolVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendance = {
  __typename?: 'demiplane_episode_attendance';
  /** An object relationship */
  adventure_player: DemiplaneAdventurePlayer;
  adventure_player_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  joining: Scalars['Boolean'];
  missing: Scalars['Boolean'];
  /** An object relationship */
  session: DemiplaneSession;
  session_id: Scalars['bigint'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceAggregate = {
  __typename?: 'demiplane_episode_attendance_aggregate';
  aggregate?: Maybe<DemiplaneEpisodeAttendanceAggregateFields>;
  nodes: Array<DemiplaneEpisodeAttendance>;
};

/** aggregate fields of "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceAggregateFields = {
  __typename?: 'demiplane_episode_attendance_aggregate_fields';
  avg?: Maybe<DemiplaneEpisodeAttendanceAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneEpisodeAttendanceMaxFields>;
  min?: Maybe<DemiplaneEpisodeAttendanceMinFields>;
  stddev?: Maybe<DemiplaneEpisodeAttendanceStddevFields>;
  stddev_pop?: Maybe<DemiplaneEpisodeAttendanceStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneEpisodeAttendanceStddevSampFields>;
  sum?: Maybe<DemiplaneEpisodeAttendanceSumFields>;
  var_pop?: Maybe<DemiplaneEpisodeAttendanceVarPopFields>;
  var_samp?: Maybe<DemiplaneEpisodeAttendanceVarSampFields>;
  variance?: Maybe<DemiplaneEpisodeAttendanceVarianceFields>;
};


/** aggregate fields of "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceAggregateOrderBy = {
  avg?: Maybe<DemiplaneEpisodeAttendanceAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneEpisodeAttendanceMaxOrderBy>;
  min?: Maybe<DemiplaneEpisodeAttendanceMinOrderBy>;
  stddev?: Maybe<DemiplaneEpisodeAttendanceStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneEpisodeAttendanceStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneEpisodeAttendanceStddevSampOrderBy>;
  sum?: Maybe<DemiplaneEpisodeAttendanceSumOrderBy>;
  var_pop?: Maybe<DemiplaneEpisodeAttendanceVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneEpisodeAttendanceVarSampOrderBy>;
  variance?: Maybe<DemiplaneEpisodeAttendanceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceArrRelInsertInput = {
  data: Array<DemiplaneEpisodeAttendanceInsertInput>;
  on_conflict?: Maybe<DemiplaneEpisodeAttendanceOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneEpisodeAttendanceAvgFields = {
  __typename?: 'demiplane_episode_attendance_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.episode_attendance". All fields are combined with a logical 'AND'. */
export type DemiplaneEpisodeAttendanceBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneEpisodeAttendanceBoolExp>>>;
  _not?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneEpisodeAttendanceBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  joining?: Maybe<BooleanComparisonExp>;
  missing?: Maybe<BooleanComparisonExp>;
  session?: Maybe<DemiplaneSessionBoolExp>;
  session_id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.episode_attendance" */
export enum DemiplaneEpisodeAttendanceConstraint {
  /** unique or primary key constraint */
  PkEpisodeAttendanceId = 'pk_episode_attendance_id',
  /** unique or primary key constraint */
  UqEpisodeAttendanceAdventurePlayerIdSessionId = 'uq_episode_attendance_adventure_player_id_session_id'
}

/** input type for incrementing integer column in table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  joining?: Maybe<Scalars['Boolean']>;
  missing?: Maybe<Scalars['Boolean']>;
  session?: Maybe<DemiplaneSessionObjRelInsertInput>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneEpisodeAttendanceMaxFields = {
  __typename?: 'demiplane_episode_attendance_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneEpisodeAttendanceMinFields = {
  __typename?: 'demiplane_episode_attendance_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceMutationResponse = {
  __typename?: 'demiplane_episode_attendance_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneEpisodeAttendance>;
};

/** input type for inserting object relation for remote table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceObjRelInsertInput = {
  data: DemiplaneEpisodeAttendanceInsertInput;
  on_conflict?: Maybe<DemiplaneEpisodeAttendanceOnConflict>;
};

/** on conflict condition type for table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceOnConflict = {
  constraint: DemiplaneEpisodeAttendanceConstraint;
  update_columns: Array<DemiplaneEpisodeAttendanceUpdateColumn>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};

/** ordering options when selecting data from "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  joining?: Maybe<OrderBy>;
  missing?: Maybe<OrderBy>;
  session?: Maybe<DemiplaneSessionOrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendancePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.episode_attendance" */
export enum DemiplaneEpisodeAttendanceSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Joining = 'joining',
  /** column name */
  Missing = 'missing',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  joining?: Maybe<Scalars['Boolean']>;
  missing?: Maybe<Scalars['Boolean']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneEpisodeAttendanceStddevFields = {
  __typename?: 'demiplane_episode_attendance_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneEpisodeAttendanceStddevPopFields = {
  __typename?: 'demiplane_episode_attendance_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneEpisodeAttendanceStddevSampFields = {
  __typename?: 'demiplane_episode_attendance_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneEpisodeAttendanceSumFields = {
  __typename?: 'demiplane_episode_attendance_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.episode_attendance" */
export enum DemiplaneEpisodeAttendanceUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Joining = 'joining',
  /** column name */
  Missing = 'missing',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneEpisodeAttendanceVarPopFields = {
  __typename?: 'demiplane_episode_attendance_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneEpisodeAttendanceVarSampFields = {
  __typename?: 'demiplane_episode_attendance_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneEpisodeAttendanceVarianceFields = {
  __typename?: 'demiplane_episode_attendance_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.episode_attendance" */
export type DemiplaneEpisodeAttendanceVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.event" */
export type DemiplaneEvent = {
  __typename?: 'demiplane_event';
  ack: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['json']>;
  /** An object relationship */
  event_type: DemiplaneEventType;
  event_type_id: Scalars['bigint'];
  id: Scalars['bigint'];
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "demiplane.event" */
export type DemiplaneEventDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "demiplane.event" */
export type DemiplaneEventAggregate = {
  __typename?: 'demiplane_event_aggregate';
  aggregate?: Maybe<DemiplaneEventAggregateFields>;
  nodes: Array<DemiplaneEvent>;
};

/** aggregate fields of "demiplane.event" */
export type DemiplaneEventAggregateFields = {
  __typename?: 'demiplane_event_aggregate_fields';
  avg?: Maybe<DemiplaneEventAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneEventMaxFields>;
  min?: Maybe<DemiplaneEventMinFields>;
  stddev?: Maybe<DemiplaneEventStddevFields>;
  stddev_pop?: Maybe<DemiplaneEventStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneEventStddevSampFields>;
  sum?: Maybe<DemiplaneEventSumFields>;
  var_pop?: Maybe<DemiplaneEventVarPopFields>;
  var_samp?: Maybe<DemiplaneEventVarSampFields>;
  variance?: Maybe<DemiplaneEventVarianceFields>;
};


/** aggregate fields of "demiplane.event" */
export type DemiplaneEventAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneEventSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.event" */
export type DemiplaneEventAggregateOrderBy = {
  avg?: Maybe<DemiplaneEventAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneEventMaxOrderBy>;
  min?: Maybe<DemiplaneEventMinOrderBy>;
  stddev?: Maybe<DemiplaneEventStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneEventStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneEventStddevSampOrderBy>;
  sum?: Maybe<DemiplaneEventSumOrderBy>;
  var_pop?: Maybe<DemiplaneEventVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneEventVarSampOrderBy>;
  variance?: Maybe<DemiplaneEventVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.event" */
export type DemiplaneEventArrRelInsertInput = {
  data: Array<DemiplaneEventInsertInput>;
  on_conflict?: Maybe<DemiplaneEventOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneEventAvgFields = {
  __typename?: 'demiplane_event_avg_fields';
  event_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.event" */
export type DemiplaneEventAvgOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.event". All fields are combined with a logical 'AND'. */
export type DemiplaneEventBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneEventBoolExp>>>;
  _not?: Maybe<DemiplaneEventBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneEventBoolExp>>>;
  ack?: Maybe<BooleanComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  data?: Maybe<JsonComparisonExp>;
  event_type?: Maybe<DemiplaneEventTypeBoolExp>;
  event_type_id?: Maybe<BigintComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  message?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  url?: Maybe<StringComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.event" */
export enum DemiplaneEventConstraint {
  /** unique or primary key constraint */
  EventPkey = 'event_pkey'
}

/** input type for incrementing integer column in table "demiplane.event" */
export type DemiplaneEventIncInput = {
  event_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.event" */
export type DemiplaneEventInsertInput = {
  ack?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['json']>;
  event_type?: Maybe<DemiplaneEventTypeObjRelInsertInput>;
  event_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneEventMaxFields = {
  __typename?: 'demiplane_event_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.event" */
export type DemiplaneEventMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneEventMinFields = {
  __typename?: 'demiplane_event_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.event" */
export type DemiplaneEventMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.event" */
export type DemiplaneEventMutationResponse = {
  __typename?: 'demiplane_event_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneEvent>;
};

/** input type for inserting object relation for remote table "demiplane.event" */
export type DemiplaneEventObjRelInsertInput = {
  data: DemiplaneEventInsertInput;
  on_conflict?: Maybe<DemiplaneEventOnConflict>;
};

/** on conflict condition type for table "demiplane.event" */
export type DemiplaneEventOnConflict = {
  constraint: DemiplaneEventConstraint;
  update_columns: Array<DemiplaneEventUpdateColumn>;
  where?: Maybe<DemiplaneEventBoolExp>;
};

/** ordering options when selecting data from "demiplane.event" */
export type DemiplaneEventOrderBy = {
  ack?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  data?: Maybe<OrderBy>;
  event_type?: Maybe<DemiplaneEventTypeOrderBy>;
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.event" */
export type DemiplaneEventPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.event" */
export enum DemiplaneEventSelectColumn {
  /** column name */
  Ack = 'ack',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.event" */
export type DemiplaneEventSetInput = {
  ack?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['json']>;
  event_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneEventStddevFields = {
  __typename?: 'demiplane_event_stddev_fields';
  event_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.event" */
export type DemiplaneEventStddevOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneEventStddevPopFields = {
  __typename?: 'demiplane_event_stddev_pop_fields';
  event_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.event" */
export type DemiplaneEventStddevPopOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneEventStddevSampFields = {
  __typename?: 'demiplane_event_stddev_samp_fields';
  event_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.event" */
export type DemiplaneEventStddevSampOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneEventSumFields = {
  __typename?: 'demiplane_event_sum_fields';
  event_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.event" */
export type DemiplaneEventSumOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.event_type" */
export type DemiplaneEventType = {
  __typename?: 'demiplane_event_type';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  events: Array<DemiplaneEvent>;
  /** An aggregated array relationship */
  events_aggregate: DemiplaneEventAggregate;
  id: Scalars['bigint'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.event_type" */
export type DemiplaneEventTypeEventsArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};


/** columns and relationships of "demiplane.event_type" */
export type DemiplaneEventTypeEventsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};

/** aggregated selection of "demiplane.event_type" */
export type DemiplaneEventTypeAggregate = {
  __typename?: 'demiplane_event_type_aggregate';
  aggregate?: Maybe<DemiplaneEventTypeAggregateFields>;
  nodes: Array<DemiplaneEventType>;
};

/** aggregate fields of "demiplane.event_type" */
export type DemiplaneEventTypeAggregateFields = {
  __typename?: 'demiplane_event_type_aggregate_fields';
  avg?: Maybe<DemiplaneEventTypeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneEventTypeMaxFields>;
  min?: Maybe<DemiplaneEventTypeMinFields>;
  stddev?: Maybe<DemiplaneEventTypeStddevFields>;
  stddev_pop?: Maybe<DemiplaneEventTypeStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneEventTypeStddevSampFields>;
  sum?: Maybe<DemiplaneEventTypeSumFields>;
  var_pop?: Maybe<DemiplaneEventTypeVarPopFields>;
  var_samp?: Maybe<DemiplaneEventTypeVarSampFields>;
  variance?: Maybe<DemiplaneEventTypeVarianceFields>;
};


/** aggregate fields of "demiplane.event_type" */
export type DemiplaneEventTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneEventTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.event_type" */
export type DemiplaneEventTypeAggregateOrderBy = {
  avg?: Maybe<DemiplaneEventTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneEventTypeMaxOrderBy>;
  min?: Maybe<DemiplaneEventTypeMinOrderBy>;
  stddev?: Maybe<DemiplaneEventTypeStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneEventTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneEventTypeStddevSampOrderBy>;
  sum?: Maybe<DemiplaneEventTypeSumOrderBy>;
  var_pop?: Maybe<DemiplaneEventTypeVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneEventTypeVarSampOrderBy>;
  variance?: Maybe<DemiplaneEventTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.event_type" */
export type DemiplaneEventTypeArrRelInsertInput = {
  data: Array<DemiplaneEventTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneEventTypeOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneEventTypeAvgFields = {
  __typename?: 'demiplane_event_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.event_type". All fields are combined with a logical 'AND'. */
export type DemiplaneEventTypeBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneEventTypeBoolExp>>>;
  _not?: Maybe<DemiplaneEventTypeBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneEventTypeBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  events?: Maybe<DemiplaneEventBoolExp>;
  id?: Maybe<BigintComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.event_type" */
export enum DemiplaneEventTypeConstraint {
  /** unique or primary key constraint */
  EventTypePkey = 'event_type_pkey',
  /** unique or primary key constraint */
  EventTypeTypeKey = 'event_type_type_key'
}

/** input type for incrementing integer column in table "demiplane.event_type" */
export type DemiplaneEventTypeIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.event_type" */
export type DemiplaneEventTypeInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<DemiplaneEventArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneEventTypeMaxFields = {
  __typename?: 'demiplane_event_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneEventTypeMinFields = {
  __typename?: 'demiplane_event_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.event_type" */
export type DemiplaneEventTypeMutationResponse = {
  __typename?: 'demiplane_event_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneEventType>;
};

/** input type for inserting object relation for remote table "demiplane.event_type" */
export type DemiplaneEventTypeObjRelInsertInput = {
  data: DemiplaneEventTypeInsertInput;
  on_conflict?: Maybe<DemiplaneEventTypeOnConflict>;
};

/** on conflict condition type for table "demiplane.event_type" */
export type DemiplaneEventTypeOnConflict = {
  constraint: DemiplaneEventTypeConstraint;
  update_columns: Array<DemiplaneEventTypeUpdateColumn>;
  where?: Maybe<DemiplaneEventTypeBoolExp>;
};

/** ordering options when selecting data from "demiplane.event_type" */
export type DemiplaneEventTypeOrderBy = {
  created_at?: Maybe<OrderBy>;
  events_aggregate?: Maybe<DemiplaneEventAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.event_type" */
export type DemiplaneEventTypePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.event_type" */
export enum DemiplaneEventTypeSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "demiplane.event_type" */
export type DemiplaneEventTypeSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneEventTypeStddevFields = {
  __typename?: 'demiplane_event_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneEventTypeStddevPopFields = {
  __typename?: 'demiplane_event_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneEventTypeStddevSampFields = {
  __typename?: 'demiplane_event_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneEventTypeSumFields = {
  __typename?: 'demiplane_event_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.event_type" */
export enum DemiplaneEventTypeUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type DemiplaneEventTypeVarPopFields = {
  __typename?: 'demiplane_event_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneEventTypeVarSampFields = {
  __typename?: 'demiplane_event_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneEventTypeVarianceFields = {
  __typename?: 'demiplane_event_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.event_type" */
export type DemiplaneEventTypeVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.event" */
export enum DemiplaneEventUpdateColumn {
  /** column name */
  Ack = 'ack',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneEventVarPopFields = {
  __typename?: 'demiplane_event_var_pop_fields';
  event_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.event" */
export type DemiplaneEventVarPopOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneEventVarSampFields = {
  __typename?: 'demiplane_event_var_samp_fields';
  event_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.event" */
export type DemiplaneEventVarSampOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneEventVarianceFields = {
  __typename?: 'demiplane_event_variance_fields';
  event_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.event" */
export type DemiplaneEventVarianceOrderBy = {
  event_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatform = {
  __typename?: 'demiplane_favorite_adventuring_platform';
  /** An object relationship */
  adventuring_platform: DemiplaneAdventuringPlatform;
  adventuring_platform_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  sort_order: Scalars['Int'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformAggregate = {
  __typename?: 'demiplane_favorite_adventuring_platform_aggregate';
  aggregate?: Maybe<DemiplaneFavoriteAdventuringPlatformAggregateFields>;
  nodes: Array<DemiplaneFavoriteAdventuringPlatform>;
};

/** aggregate fields of "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformAggregateFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_aggregate_fields';
  avg?: Maybe<DemiplaneFavoriteAdventuringPlatformAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneFavoriteAdventuringPlatformMaxFields>;
  min?: Maybe<DemiplaneFavoriteAdventuringPlatformMinFields>;
  stddev?: Maybe<DemiplaneFavoriteAdventuringPlatformStddevFields>;
  stddev_pop?: Maybe<DemiplaneFavoriteAdventuringPlatformStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneFavoriteAdventuringPlatformStddevSampFields>;
  sum?: Maybe<DemiplaneFavoriteAdventuringPlatformSumFields>;
  var_pop?: Maybe<DemiplaneFavoriteAdventuringPlatformVarPopFields>;
  var_samp?: Maybe<DemiplaneFavoriteAdventuringPlatformVarSampFields>;
  variance?: Maybe<DemiplaneFavoriteAdventuringPlatformVarianceFields>;
};


/** aggregate fields of "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformAggregateOrderBy = {
  avg?: Maybe<DemiplaneFavoriteAdventuringPlatformAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneFavoriteAdventuringPlatformMaxOrderBy>;
  min?: Maybe<DemiplaneFavoriteAdventuringPlatformMinOrderBy>;
  stddev?: Maybe<DemiplaneFavoriteAdventuringPlatformStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneFavoriteAdventuringPlatformStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneFavoriteAdventuringPlatformStddevSampOrderBy>;
  sum?: Maybe<DemiplaneFavoriteAdventuringPlatformSumOrderBy>;
  var_pop?: Maybe<DemiplaneFavoriteAdventuringPlatformVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneFavoriteAdventuringPlatformVarSampOrderBy>;
  variance?: Maybe<DemiplaneFavoriteAdventuringPlatformVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformArrRelInsertInput = {
  data: Array<DemiplaneFavoriteAdventuringPlatformInsertInput>;
  on_conflict?: Maybe<DemiplaneFavoriteAdventuringPlatformOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneFavoriteAdventuringPlatformAvgFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_avg_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformAvgOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.favorite_adventuring_platform". All fields are combined with a logical 'AND'. */
export type DemiplaneFavoriteAdventuringPlatformBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>>>;
  _not?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>>>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
  adventuring_platform_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  sort_order?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.favorite_adventuring_platform" */
export enum DemiplaneFavoriteAdventuringPlatformConstraint {
  /** unique or primary key constraint */
  FavoriteAdventuringPlatformPkey = 'favorite_adventuring_platform_pkey',
  /** unique or primary key constraint */
  FavoriteAdventuringPlatformUserIdAdventuringPlatformKey = 'favorite_adventuring_platform_user_id_adventuring_platform__key'
}

/** input type for incrementing integer column in table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformIncInput = {
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformInsertInput = {
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformObjRelInsertInput>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneFavoriteAdventuringPlatformMaxFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_max_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformMaxOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneFavoriteAdventuringPlatformMinFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_min_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformMinOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformMutationResponse = {
  __typename?: 'demiplane_favorite_adventuring_platform_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneFavoriteAdventuringPlatform>;
};

/** input type for inserting object relation for remote table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformObjRelInsertInput = {
  data: DemiplaneFavoriteAdventuringPlatformInsertInput;
  on_conflict?: Maybe<DemiplaneFavoriteAdventuringPlatformOnConflict>;
};

/** on conflict condition type for table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformOnConflict = {
  constraint: DemiplaneFavoriteAdventuringPlatformConstraint;
  update_columns: Array<DemiplaneFavoriteAdventuringPlatformUpdateColumn>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};

/** ordering options when selecting data from "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformOrderBy = {
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformOrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.favorite_adventuring_platform" */
export enum DemiplaneFavoriteAdventuringPlatformSelectColumn {
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformSetInput = {
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneFavoriteAdventuringPlatformStddevFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_stddev_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformStddevOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneFavoriteAdventuringPlatformStddevPopFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_stddev_pop_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformStddevPopOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneFavoriteAdventuringPlatformStddevSampFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_stddev_samp_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformStddevSampOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneFavoriteAdventuringPlatformSumFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_sum_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformSumOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.favorite_adventuring_platform" */
export enum DemiplaneFavoriteAdventuringPlatformUpdateColumn {
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneFavoriteAdventuringPlatformVarPopFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_var_pop_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformVarPopOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneFavoriteAdventuringPlatformVarSampFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_var_samp_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformVarSampOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneFavoriteAdventuringPlatformVarianceFields = {
  __typename?: 'demiplane_favorite_adventuring_platform_variance_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.favorite_adventuring_platform" */
export type DemiplaneFavoriteAdventuringPlatformVarianceOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRoll = {
  __typename?: 'demiplane_favorite_dice_roll';
  /** An object relationship */
  adventure_player: DemiplaneAdventurePlayer;
  adventure_player_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  dice_roll: Scalars['String'];
  id: Scalars['bigint'];
  roll_name: Scalars['String'];
  sort_order: Scalars['Int'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollAggregate = {
  __typename?: 'demiplane_favorite_dice_roll_aggregate';
  aggregate?: Maybe<DemiplaneFavoriteDiceRollAggregateFields>;
  nodes: Array<DemiplaneFavoriteDiceRoll>;
};

/** aggregate fields of "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollAggregateFields = {
  __typename?: 'demiplane_favorite_dice_roll_aggregate_fields';
  avg?: Maybe<DemiplaneFavoriteDiceRollAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneFavoriteDiceRollMaxFields>;
  min?: Maybe<DemiplaneFavoriteDiceRollMinFields>;
  stddev?: Maybe<DemiplaneFavoriteDiceRollStddevFields>;
  stddev_pop?: Maybe<DemiplaneFavoriteDiceRollStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneFavoriteDiceRollStddevSampFields>;
  sum?: Maybe<DemiplaneFavoriteDiceRollSumFields>;
  var_pop?: Maybe<DemiplaneFavoriteDiceRollVarPopFields>;
  var_samp?: Maybe<DemiplaneFavoriteDiceRollVarSampFields>;
  variance?: Maybe<DemiplaneFavoriteDiceRollVarianceFields>;
};


/** aggregate fields of "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneFavoriteDiceRollSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollAggregateOrderBy = {
  avg?: Maybe<DemiplaneFavoriteDiceRollAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneFavoriteDiceRollMaxOrderBy>;
  min?: Maybe<DemiplaneFavoriteDiceRollMinOrderBy>;
  stddev?: Maybe<DemiplaneFavoriteDiceRollStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneFavoriteDiceRollStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneFavoriteDiceRollStddevSampOrderBy>;
  sum?: Maybe<DemiplaneFavoriteDiceRollSumOrderBy>;
  var_pop?: Maybe<DemiplaneFavoriteDiceRollVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneFavoriteDiceRollVarSampOrderBy>;
  variance?: Maybe<DemiplaneFavoriteDiceRollVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollArrRelInsertInput = {
  data: Array<DemiplaneFavoriteDiceRollInsertInput>;
  on_conflict?: Maybe<DemiplaneFavoriteDiceRollOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneFavoriteDiceRollAvgFields = {
  __typename?: 'demiplane_favorite_dice_roll_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.favorite_dice_roll". All fields are combined with a logical 'AND'. */
export type DemiplaneFavoriteDiceRollBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneFavoriteDiceRollBoolExp>>>;
  _not?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneFavoriteDiceRollBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  dice_roll?: Maybe<StringComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  roll_name?: Maybe<StringComparisonExp>;
  sort_order?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.favorite_dice_roll" */
export enum DemiplaneFavoriteDiceRollConstraint {
  /** unique or primary key constraint */
  PkFavoriteDiceRollId = 'pk_favorite_dice_roll_id'
}

/** input type for incrementing integer column in table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dice_roll?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  roll_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneFavoriteDiceRollMaxFields = {
  __typename?: 'demiplane_favorite_dice_roll_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dice_roll?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  roll_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dice_roll?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  roll_name?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneFavoriteDiceRollMinFields = {
  __typename?: 'demiplane_favorite_dice_roll_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dice_roll?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  roll_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dice_roll?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  roll_name?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollMutationResponse = {
  __typename?: 'demiplane_favorite_dice_roll_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneFavoriteDiceRoll>;
};

/** input type for inserting object relation for remote table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollObjRelInsertInput = {
  data: DemiplaneFavoriteDiceRollInsertInput;
  on_conflict?: Maybe<DemiplaneFavoriteDiceRollOnConflict>;
};

/** on conflict condition type for table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollOnConflict = {
  constraint: DemiplaneFavoriteDiceRollConstraint;
  update_columns: Array<DemiplaneFavoriteDiceRollUpdateColumn>;
  where?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
};

/** ordering options when selecting data from "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dice_roll?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  roll_name?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.favorite_dice_roll" */
export enum DemiplaneFavoriteDiceRollSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  DiceRoll = 'dice_roll',
  /** column name */
  Id = 'id',
  /** column name */
  RollName = 'roll_name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  dice_roll?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  roll_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneFavoriteDiceRollStddevFields = {
  __typename?: 'demiplane_favorite_dice_roll_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneFavoriteDiceRollStddevPopFields = {
  __typename?: 'demiplane_favorite_dice_roll_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneFavoriteDiceRollStddevSampFields = {
  __typename?: 'demiplane_favorite_dice_roll_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneFavoriteDiceRollSumFields = {
  __typename?: 'demiplane_favorite_dice_roll_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.favorite_dice_roll" */
export enum DemiplaneFavoriteDiceRollUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  DiceRoll = 'dice_roll',
  /** column name */
  Id = 'id',
  /** column name */
  RollName = 'roll_name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneFavoriteDiceRollVarPopFields = {
  __typename?: 'demiplane_favorite_dice_roll_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneFavoriteDiceRollVarSampFields = {
  __typename?: 'demiplane_favorite_dice_roll_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneFavoriteDiceRollVarianceFields = {
  __typename?: 'demiplane_favorite_dice_roll_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.favorite_dice_roll" */
export type DemiplaneFavoriteDiceRollVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.favorite_game" */
export type DemiplaneFavoriteGame = {
  __typename?: 'demiplane_favorite_game';
  created: Scalars['timestamptz'];
  /** An object relationship */
  game_type: DemiplaneGameType;
  game_type_id: Scalars['bigint'];
  id: Scalars['bigint'];
  sort_order: Scalars['Int'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.favorite_game" */
export type DemiplaneFavoriteGameAggregate = {
  __typename?: 'demiplane_favorite_game_aggregate';
  aggregate?: Maybe<DemiplaneFavoriteGameAggregateFields>;
  nodes: Array<DemiplaneFavoriteGame>;
};

/** aggregate fields of "demiplane.favorite_game" */
export type DemiplaneFavoriteGameAggregateFields = {
  __typename?: 'demiplane_favorite_game_aggregate_fields';
  avg?: Maybe<DemiplaneFavoriteGameAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneFavoriteGameMaxFields>;
  min?: Maybe<DemiplaneFavoriteGameMinFields>;
  stddev?: Maybe<DemiplaneFavoriteGameStddevFields>;
  stddev_pop?: Maybe<DemiplaneFavoriteGameStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneFavoriteGameStddevSampFields>;
  sum?: Maybe<DemiplaneFavoriteGameSumFields>;
  var_pop?: Maybe<DemiplaneFavoriteGameVarPopFields>;
  var_samp?: Maybe<DemiplaneFavoriteGameVarSampFields>;
  variance?: Maybe<DemiplaneFavoriteGameVarianceFields>;
};


/** aggregate fields of "demiplane.favorite_game" */
export type DemiplaneFavoriteGameAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameAggregateOrderBy = {
  avg?: Maybe<DemiplaneFavoriteGameAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneFavoriteGameMaxOrderBy>;
  min?: Maybe<DemiplaneFavoriteGameMinOrderBy>;
  stddev?: Maybe<DemiplaneFavoriteGameStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneFavoriteGameStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneFavoriteGameStddevSampOrderBy>;
  sum?: Maybe<DemiplaneFavoriteGameSumOrderBy>;
  var_pop?: Maybe<DemiplaneFavoriteGameVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneFavoriteGameVarSampOrderBy>;
  variance?: Maybe<DemiplaneFavoriteGameVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameArrRelInsertInput = {
  data: Array<DemiplaneFavoriteGameInsertInput>;
  on_conflict?: Maybe<DemiplaneFavoriteGameOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneFavoriteGameAvgFields = {
  __typename?: 'demiplane_favorite_game_avg_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameAvgOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.favorite_game". All fields are combined with a logical 'AND'. */
export type DemiplaneFavoriteGameBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneFavoriteGameBoolExp>>>;
  _not?: Maybe<DemiplaneFavoriteGameBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneFavoriteGameBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  game_type?: Maybe<DemiplaneGameTypeBoolExp>;
  game_type_id?: Maybe<BigintComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  sort_order?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.favorite_game" */
export enum DemiplaneFavoriteGameConstraint {
  /** unique or primary key constraint */
  FavoriteGamePkey = 'favorite_game_pkey',
  /** unique or primary key constraint */
  FavoriteGameUserIdGameTypeIdKey = 'favorite_game_user_id_game_type_id_key'
}

/** input type for incrementing integer column in table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameIncInput = {
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  game_type?: Maybe<DemiplaneGameTypeObjRelInsertInput>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneFavoriteGameMaxFields = {
  __typename?: 'demiplane_favorite_game_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameMaxOrderBy = {
  created?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneFavoriteGameMinFields = {
  __typename?: 'demiplane_favorite_game_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameMinOrderBy = {
  created?: Maybe<OrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameMutationResponse = {
  __typename?: 'demiplane_favorite_game_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneFavoriteGame>;
};

/** input type for inserting object relation for remote table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameObjRelInsertInput = {
  data: DemiplaneFavoriteGameInsertInput;
  on_conflict?: Maybe<DemiplaneFavoriteGameOnConflict>;
};

/** on conflict condition type for table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameOnConflict = {
  constraint: DemiplaneFavoriteGameConstraint;
  update_columns: Array<DemiplaneFavoriteGameUpdateColumn>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};

/** ordering options when selecting data from "demiplane.favorite_game" */
export type DemiplaneFavoriteGameOrderBy = {
  created?: Maybe<OrderBy>;
  game_type?: Maybe<DemiplaneGameTypeOrderBy>;
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.favorite_game" */
export type DemiplaneFavoriteGamePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.favorite_game" */
export enum DemiplaneFavoriteGameSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneFavoriteGameStddevFields = {
  __typename?: 'demiplane_favorite_game_stddev_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameStddevOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneFavoriteGameStddevPopFields = {
  __typename?: 'demiplane_favorite_game_stddev_pop_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameStddevPopOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneFavoriteGameStddevSampFields = {
  __typename?: 'demiplane_favorite_game_stddev_samp_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameStddevSampOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneFavoriteGameSumFields = {
  __typename?: 'demiplane_favorite_game_sum_fields';
  game_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameSumOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.favorite_game" */
export enum DemiplaneFavoriteGameUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneFavoriteGameVarPopFields = {
  __typename?: 'demiplane_favorite_game_var_pop_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameVarPopOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneFavoriteGameVarSampFields = {
  __typename?: 'demiplane_favorite_game_var_samp_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameVarSampOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneFavoriteGameVarianceFields = {
  __typename?: 'demiplane_favorite_game_variance_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.favorite_game" */
export type DemiplaneFavoriteGameVarianceOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameType = {
  __typename?: 'demiplane_game_type';
  active: Scalars['Boolean'];
  /** An array relationship */
  ads: Array<DemiplaneAd>;
  /** An aggregated array relationship */
  ads_aggregate: DemiplaneAdAggregate;
  /** An array relationship */
  adventures: Array<DemiplaneAdventure>;
  /** An aggregated array relationship */
  adventures_aggregate: DemiplaneAdventureAggregate;
  /** An array relationship */
  amc_game_types: Array<DemiplaneAmcGameTypes>;
  /** An aggregated array relationship */
  amc_game_types_aggregate: DemiplaneAmcGameTypesAggregate;
  code: Scalars['String'];
  created: Scalars['timestamptz'];
  /** An array relationship */
  favorite_games: Array<DemiplaneFavoriteGame>;
  /** An aggregated array relationship */
  favorite_games_aggregate: DemiplaneFavoriteGameAggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated: Scalars['timestamptz'];
  /** An array relationship */
  urgts: Array<DemiplaneUrgt>;
  /** An aggregated array relationship */
  urgts_aggregate: DemiplaneUrgtAggregate;
  /** An array relationship */
  user_recruitment_adventurer_games: Array<DemiplaneUserRecruitmentAdventurerGames>;
  /** An aggregated array relationship */
  user_recruitment_adventurer_games_aggregate: DemiplaneUserRecruitmentAdventurerGamesAggregate;
  /** An array relationship */
  user_recruitment_game_master_games: Array<DemiplaneUserRecruitmentGameMasterGames>;
  /** An aggregated array relationship */
  user_recruitment_game_master_games_aggregate: DemiplaneUserRecruitmentGameMasterGamesAggregate;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeAdsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeAdsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeAdventuresArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeAdventuresAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeAmcGameTypesArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeAmcGameTypesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeFavoriteGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeFavoriteGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeUrgtsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeUrgtsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeUserRecruitmentAdventurerGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeUserRecruitmentAdventurerGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeUserRecruitmentGameMasterGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};


/** columns and relationships of "demiplane.game_type" */
export type DemiplaneGameTypeUserRecruitmentGameMasterGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};

/** aggregated selection of "demiplane.game_type" */
export type DemiplaneGameTypeAggregate = {
  __typename?: 'demiplane_game_type_aggregate';
  aggregate?: Maybe<DemiplaneGameTypeAggregateFields>;
  nodes: Array<DemiplaneGameType>;
};

/** aggregate fields of "demiplane.game_type" */
export type DemiplaneGameTypeAggregateFields = {
  __typename?: 'demiplane_game_type_aggregate_fields';
  avg?: Maybe<DemiplaneGameTypeAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneGameTypeMaxFields>;
  min?: Maybe<DemiplaneGameTypeMinFields>;
  stddev?: Maybe<DemiplaneGameTypeStddevFields>;
  stddev_pop?: Maybe<DemiplaneGameTypeStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneGameTypeStddevSampFields>;
  sum?: Maybe<DemiplaneGameTypeSumFields>;
  var_pop?: Maybe<DemiplaneGameTypeVarPopFields>;
  var_samp?: Maybe<DemiplaneGameTypeVarSampFields>;
  variance?: Maybe<DemiplaneGameTypeVarianceFields>;
};


/** aggregate fields of "demiplane.game_type" */
export type DemiplaneGameTypeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneGameTypeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.game_type" */
export type DemiplaneGameTypeAggregateOrderBy = {
  avg?: Maybe<DemiplaneGameTypeAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneGameTypeMaxOrderBy>;
  min?: Maybe<DemiplaneGameTypeMinOrderBy>;
  stddev?: Maybe<DemiplaneGameTypeStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneGameTypeStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneGameTypeStddevSampOrderBy>;
  sum?: Maybe<DemiplaneGameTypeSumOrderBy>;
  var_pop?: Maybe<DemiplaneGameTypeVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneGameTypeVarSampOrderBy>;
  variance?: Maybe<DemiplaneGameTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.game_type" */
export type DemiplaneGameTypeArrRelInsertInput = {
  data: Array<DemiplaneGameTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneGameTypeOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneGameTypeAvgFields = {
  __typename?: 'demiplane_game_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.game_type". All fields are combined with a logical 'AND'. */
export type DemiplaneGameTypeBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneGameTypeBoolExp>>>;
  _not?: Maybe<DemiplaneGameTypeBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneGameTypeBoolExp>>>;
  active?: Maybe<BooleanComparisonExp>;
  ads?: Maybe<DemiplaneAdBoolExp>;
  adventures?: Maybe<DemiplaneAdventureBoolExp>;
  amc_game_types?: Maybe<DemiplaneAmcGameTypesBoolExp>;
  code?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  favorite_games?: Maybe<DemiplaneFavoriteGameBoolExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  urgts?: Maybe<DemiplaneUrgtBoolExp>;
  user_recruitment_adventurer_games?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
  user_recruitment_game_master_games?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};

/** unique or primary key constraints on table "demiplane.game_type" */
export enum DemiplaneGameTypeConstraint {
  /** unique or primary key constraint */
  PkGameTypeId = 'pk_game_type_id',
  /** unique or primary key constraint */
  UqCode = 'uq_code'
}

/** input type for incrementing integer column in table "demiplane.game_type" */
export type DemiplaneGameTypeIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.game_type" */
export type DemiplaneGameTypeInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  ads?: Maybe<DemiplaneAdArrRelInsertInput>;
  adventures?: Maybe<DemiplaneAdventureArrRelInsertInput>;
  amc_game_types?: Maybe<DemiplaneAmcGameTypesArrRelInsertInput>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  favorite_games?: Maybe<DemiplaneFavoriteGameArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  urgts?: Maybe<DemiplaneUrgtArrRelInsertInput>;
  user_recruitment_adventurer_games?: Maybe<DemiplaneUserRecruitmentAdventurerGamesArrRelInsertInput>;
  user_recruitment_game_master_games?: Maybe<DemiplaneUserRecruitmentGameMasterGamesArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneGameTypeMaxFields = {
  __typename?: 'demiplane_game_type_max_fields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeMaxOrderBy = {
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneGameTypeMinFields = {
  __typename?: 'demiplane_game_type_min_fields';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeMinOrderBy = {
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.game_type" */
export type DemiplaneGameTypeMutationResponse = {
  __typename?: 'demiplane_game_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneGameType>;
};

/** input type for inserting object relation for remote table "demiplane.game_type" */
export type DemiplaneGameTypeObjRelInsertInput = {
  data: DemiplaneGameTypeInsertInput;
  on_conflict?: Maybe<DemiplaneGameTypeOnConflict>;
};

/** on conflict condition type for table "demiplane.game_type" */
export type DemiplaneGameTypeOnConflict = {
  constraint: DemiplaneGameTypeConstraint;
  update_columns: Array<DemiplaneGameTypeUpdateColumn>;
  where?: Maybe<DemiplaneGameTypeBoolExp>;
};

/** ordering options when selecting data from "demiplane.game_type" */
export type DemiplaneGameTypeOrderBy = {
  active?: Maybe<OrderBy>;
  ads_aggregate?: Maybe<DemiplaneAdAggregateOrderBy>;
  adventures_aggregate?: Maybe<DemiplaneAdventureAggregateOrderBy>;
  amc_game_types_aggregate?: Maybe<DemiplaneAmcGameTypesAggregateOrderBy>;
  code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  favorite_games_aggregate?: Maybe<DemiplaneFavoriteGameAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  urgts_aggregate?: Maybe<DemiplaneUrgtAggregateOrderBy>;
  user_recruitment_adventurer_games_aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerGamesAggregateOrderBy>;
  user_recruitment_game_master_games_aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterGamesAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.game_type" */
export type DemiplaneGameTypePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.game_type" */
export enum DemiplaneGameTypeSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.game_type" */
export type DemiplaneGameTypeSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneGameTypeStddevFields = {
  __typename?: 'demiplane_game_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneGameTypeStddevPopFields = {
  __typename?: 'demiplane_game_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneGameTypeStddevSampFields = {
  __typename?: 'demiplane_game_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneGameTypeSumFields = {
  __typename?: 'demiplane_game_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.game_type" */
export enum DemiplaneGameTypeUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneGameTypeVarPopFields = {
  __typename?: 'demiplane_game_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneGameTypeVarSampFields = {
  __typename?: 'demiplane_game_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneGameTypeVarianceFields = {
  __typename?: 'demiplane_game_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.game_type" */
export type DemiplaneGameTypeVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.gm_rating" */
export type DemiplaneGmRating = {
  __typename?: 'demiplane_gm_rating';
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  rated: Scalars['Boolean'];
  rated_player_id: Scalars['bigint'];
  rating: Scalars['numeric'];
  rating_player_id: Scalars['bigint'];
  /** An object relationship */
  session: DemiplaneSession;
  /** An object relationship */
  sessionPlayerByRatedPlayerId: DemiplaneSessionPlayer;
  session_id: Scalars['bigint'];
  /** An object relationship */
  session_player: DemiplaneSessionPlayer;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.gm_rating" */
export type DemiplaneGmRatingAggregate = {
  __typename?: 'demiplane_gm_rating_aggregate';
  aggregate?: Maybe<DemiplaneGmRatingAggregateFields>;
  nodes: Array<DemiplaneGmRating>;
};

/** aggregate fields of "demiplane.gm_rating" */
export type DemiplaneGmRatingAggregateFields = {
  __typename?: 'demiplane_gm_rating_aggregate_fields';
  avg?: Maybe<DemiplaneGmRatingAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneGmRatingMaxFields>;
  min?: Maybe<DemiplaneGmRatingMinFields>;
  stddev?: Maybe<DemiplaneGmRatingStddevFields>;
  stddev_pop?: Maybe<DemiplaneGmRatingStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneGmRatingStddevSampFields>;
  sum?: Maybe<DemiplaneGmRatingSumFields>;
  var_pop?: Maybe<DemiplaneGmRatingVarPopFields>;
  var_samp?: Maybe<DemiplaneGmRatingVarSampFields>;
  variance?: Maybe<DemiplaneGmRatingVarianceFields>;
};


/** aggregate fields of "demiplane.gm_rating" */
export type DemiplaneGmRatingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.gm_rating" */
export type DemiplaneGmRatingAggregateOrderBy = {
  avg?: Maybe<DemiplaneGmRatingAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneGmRatingMaxOrderBy>;
  min?: Maybe<DemiplaneGmRatingMinOrderBy>;
  stddev?: Maybe<DemiplaneGmRatingStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneGmRatingStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneGmRatingStddevSampOrderBy>;
  sum?: Maybe<DemiplaneGmRatingSumOrderBy>;
  var_pop?: Maybe<DemiplaneGmRatingVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneGmRatingVarSampOrderBy>;
  variance?: Maybe<DemiplaneGmRatingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.gm_rating" */
export type DemiplaneGmRatingArrRelInsertInput = {
  data: Array<DemiplaneGmRatingInsertInput>;
  on_conflict?: Maybe<DemiplaneGmRatingOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneGmRatingAvgFields = {
  __typename?: 'demiplane_gm_rating_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingAvgOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.gm_rating". All fields are combined with a logical 'AND'. */
export type DemiplaneGmRatingBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneGmRatingBoolExp>>>;
  _not?: Maybe<DemiplaneGmRatingBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneGmRatingBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  rated?: Maybe<BooleanComparisonExp>;
  rated_player_id?: Maybe<BigintComparisonExp>;
  rating?: Maybe<NumericComparisonExp>;
  rating_player_id?: Maybe<BigintComparisonExp>;
  session?: Maybe<DemiplaneSessionBoolExp>;
  sessionPlayerByRatedPlayerId?: Maybe<DemiplaneSessionPlayerBoolExp>;
  session_id?: Maybe<BigintComparisonExp>;
  session_player?: Maybe<DemiplaneSessionPlayerBoolExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.gm_rating" */
export enum DemiplaneGmRatingConstraint {
  /** unique or primary key constraint */
  PkGmRatingId = 'pk_gm_rating_id'
}

/** input type for incrementing integer column in table "demiplane.gm_rating" */
export type DemiplaneGmRatingIncInput = {
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.gm_rating" */
export type DemiplaneGmRatingInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated?: Maybe<Scalars['Boolean']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session?: Maybe<DemiplaneSessionObjRelInsertInput>;
  sessionPlayerByRatedPlayerId?: Maybe<DemiplaneSessionPlayerObjRelInsertInput>;
  session_id?: Maybe<Scalars['bigint']>;
  session_player?: Maybe<DemiplaneSessionPlayerObjRelInsertInput>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneGmRatingMaxFields = {
  __typename?: 'demiplane_gm_rating_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneGmRatingMinFields = {
  __typename?: 'demiplane_gm_rating_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.gm_rating" */
export type DemiplaneGmRatingMutationResponse = {
  __typename?: 'demiplane_gm_rating_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneGmRating>;
};

/** input type for inserting object relation for remote table "demiplane.gm_rating" */
export type DemiplaneGmRatingObjRelInsertInput = {
  data: DemiplaneGmRatingInsertInput;
  on_conflict?: Maybe<DemiplaneGmRatingOnConflict>;
};

/** on conflict condition type for table "demiplane.gm_rating" */
export type DemiplaneGmRatingOnConflict = {
  constraint: DemiplaneGmRatingConstraint;
  update_columns: Array<DemiplaneGmRatingUpdateColumn>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};

/** ordering options when selecting data from "demiplane.gm_rating" */
export type DemiplaneGmRatingOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  rated?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session?: Maybe<DemiplaneSessionOrderBy>;
  sessionPlayerByRatedPlayerId?: Maybe<DemiplaneSessionPlayerOrderBy>;
  session_id?: Maybe<OrderBy>;
  session_player?: Maybe<DemiplaneSessionPlayerOrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.gm_rating" */
export type DemiplaneGmRatingPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.gm_rating" */
export enum DemiplaneGmRatingSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Rated = 'rated',
  /** column name */
  RatedPlayerId = 'rated_player_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingPlayerId = 'rating_player_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.gm_rating" */
export type DemiplaneGmRatingSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated?: Maybe<Scalars['Boolean']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneGmRatingStddevFields = {
  __typename?: 'demiplane_gm_rating_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingStddevOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneGmRatingStddevPopFields = {
  __typename?: 'demiplane_gm_rating_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneGmRatingStddevSampFields = {
  __typename?: 'demiplane_gm_rating_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneGmRatingSumFields = {
  __typename?: 'demiplane_gm_rating_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingSumOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.gm_rating" */
export enum DemiplaneGmRatingUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Rated = 'rated',
  /** column name */
  RatedPlayerId = 'rated_player_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingPlayerId = 'rating_player_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneGmRatingVarPopFields = {
  __typename?: 'demiplane_gm_rating_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneGmRatingVarSampFields = {
  __typename?: 'demiplane_gm_rating_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneGmRatingVarianceFields = {
  __typename?: 'demiplane_gm_rating_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.gm_rating" */
export type DemiplaneGmRatingVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendation = {
  __typename?: 'demiplane_gm_recommendation';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  deleted?: Maybe<Scalars['timestamp']>;
  displayed: Scalars['Boolean'];
  /** An object relationship */
  gm: DemiplaneUser;
  gm_id: Scalars['bigint'];
  id: Scalars['bigint'];
  intro: Scalars['String'];
  recommendation: Scalars['String'];
  /** An object relationship */
  recommending_user: DemiplaneUser;
  recommending_user_id: Scalars['bigint'];
  tipped: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationAggregate = {
  __typename?: 'demiplane_gm_recommendation_aggregate';
  aggregate?: Maybe<DemiplaneGmRecommendationAggregateFields>;
  nodes: Array<DemiplaneGmRecommendation>;
};

/** aggregate fields of "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationAggregateFields = {
  __typename?: 'demiplane_gm_recommendation_aggregate_fields';
  avg?: Maybe<DemiplaneGmRecommendationAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneGmRecommendationMaxFields>;
  min?: Maybe<DemiplaneGmRecommendationMinFields>;
  stddev?: Maybe<DemiplaneGmRecommendationStddevFields>;
  stddev_pop?: Maybe<DemiplaneGmRecommendationStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneGmRecommendationStddevSampFields>;
  sum?: Maybe<DemiplaneGmRecommendationSumFields>;
  var_pop?: Maybe<DemiplaneGmRecommendationVarPopFields>;
  var_samp?: Maybe<DemiplaneGmRecommendationVarSampFields>;
  variance?: Maybe<DemiplaneGmRecommendationVarianceFields>;
};


/** aggregate fields of "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationAggregateOrderBy = {
  avg?: Maybe<DemiplaneGmRecommendationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneGmRecommendationMaxOrderBy>;
  min?: Maybe<DemiplaneGmRecommendationMinOrderBy>;
  stddev?: Maybe<DemiplaneGmRecommendationStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneGmRecommendationStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneGmRecommendationStddevSampOrderBy>;
  sum?: Maybe<DemiplaneGmRecommendationSumOrderBy>;
  var_pop?: Maybe<DemiplaneGmRecommendationVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneGmRecommendationVarSampOrderBy>;
  variance?: Maybe<DemiplaneGmRecommendationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationArrRelInsertInput = {
  data: Array<DemiplaneGmRecommendationInsertInput>;
  on_conflict?: Maybe<DemiplaneGmRecommendationOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneGmRecommendationAvgFields = {
  __typename?: 'demiplane_gm_recommendation_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  gm_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recommending_user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.gm_recommendation". All fields are combined with a logical 'AND'. */
export type DemiplaneGmRecommendationBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneGmRecommendationBoolExp>>>;
  _not?: Maybe<DemiplaneGmRecommendationBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneGmRecommendationBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  deleted?: Maybe<TimestampComparisonExp>;
  displayed?: Maybe<BooleanComparisonExp>;
  gm?: Maybe<DemiplaneUserBoolExp>;
  gm_id?: Maybe<BigintComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  intro?: Maybe<StringComparisonExp>;
  recommendation?: Maybe<StringComparisonExp>;
  recommending_user?: Maybe<DemiplaneUserBoolExp>;
  recommending_user_id?: Maybe<BigintComparisonExp>;
  tipped?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.gm_recommendation" */
export enum DemiplaneGmRecommendationConstraint {
  /** unique or primary key constraint */
  GmRecommendationAdventureIdRecommendingPlayerIdKey = 'gm_recommendation_adventure_id_recommending_player_id_key',
  /** unique or primary key constraint */
  GmRecommendationPkey = 'gm_recommendation_pkey'
}

/** input type for incrementing integer column in table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  gm_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  recommending_user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  displayed?: Maybe<Scalars['Boolean']>;
  gm?: Maybe<DemiplaneUserObjRelInsertInput>;
  gm_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  intro?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
  recommending_user?: Maybe<DemiplaneUserObjRelInsertInput>;
  recommending_user_id?: Maybe<Scalars['bigint']>;
  tipped?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneGmRecommendationMaxFields = {
  __typename?: 'demiplane_gm_recommendation_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  gm_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  intro?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
  recommending_user_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intro?: Maybe<OrderBy>;
  recommendation?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneGmRecommendationMinFields = {
  __typename?: 'demiplane_gm_recommendation_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  gm_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  intro?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
  recommending_user_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intro?: Maybe<OrderBy>;
  recommendation?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationMutationResponse = {
  __typename?: 'demiplane_gm_recommendation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneGmRecommendation>;
};

/** input type for inserting object relation for remote table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationObjRelInsertInput = {
  data: DemiplaneGmRecommendationInsertInput;
  on_conflict?: Maybe<DemiplaneGmRecommendationOnConflict>;
};

/** on conflict condition type for table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationOnConflict = {
  constraint: DemiplaneGmRecommendationConstraint;
  update_columns: Array<DemiplaneGmRecommendationUpdateColumn>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};

/** ordering options when selecting data from "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  displayed?: Maybe<OrderBy>;
  gm?: Maybe<DemiplaneUserOrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intro?: Maybe<OrderBy>;
  recommendation?: Maybe<OrderBy>;
  recommending_user?: Maybe<DemiplaneUserOrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
  tipped?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.gm_recommendation" */
export enum DemiplaneGmRecommendationSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Displayed = 'displayed',
  /** column name */
  GmId = 'gm_id',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Recommendation = 'recommendation',
  /** column name */
  RecommendingUserId = 'recommending_user_id',
  /** column name */
  Tipped = 'tipped',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  displayed?: Maybe<Scalars['Boolean']>;
  gm_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  intro?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
  recommending_user_id?: Maybe<Scalars['bigint']>;
  tipped?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneGmRecommendationStddevFields = {
  __typename?: 'demiplane_gm_recommendation_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  gm_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recommending_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneGmRecommendationStddevPopFields = {
  __typename?: 'demiplane_gm_recommendation_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  gm_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recommending_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneGmRecommendationStddevSampFields = {
  __typename?: 'demiplane_gm_recommendation_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  gm_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recommending_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneGmRecommendationSumFields = {
  __typename?: 'demiplane_gm_recommendation_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  gm_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  recommending_user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.gm_recommendation" */
export enum DemiplaneGmRecommendationUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Displayed = 'displayed',
  /** column name */
  GmId = 'gm_id',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Recommendation = 'recommendation',
  /** column name */
  RecommendingUserId = 'recommending_user_id',
  /** column name */
  Tipped = 'tipped',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneGmRecommendationVarPopFields = {
  __typename?: 'demiplane_gm_recommendation_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  gm_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recommending_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneGmRecommendationVarSampFields = {
  __typename?: 'demiplane_gm_recommendation_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  gm_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recommending_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneGmRecommendationVarianceFields = {
  __typename?: 'demiplane_gm_recommendation_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  gm_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  recommending_user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.gm_recommendation" */
export type DemiplaneGmRecommendationVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  gm_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recommending_user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.invitation" */
export type DemiplaneInvitation = {
  __typename?: 'demiplane_invitation';
  accepted_date?: Maybe<Scalars['timestamptz']>;
  accepted_flag: Scalars['Boolean'];
  /** An object relationship */
  ad?: Maybe<DemiplaneAd>;
  ad_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  adventure?: Maybe<DemiplaneAdventure>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created: Scalars['timestamptz'];
  declined_date?: Maybe<Scalars['timestamptz']>;
  declined_flag: Scalars['Boolean'];
  deleted?: Maybe<Scalars['timestamp']>;
  email: Scalars['String'];
  id: Scalars['bigint'];
  recruited_date?: Maybe<Scalars['timestamptz']>;
  recruited_flag: Scalars['Boolean'];
  /** An array relationship */
  user_invitations: Array<DemiplaneUserInvitation>;
  /** An aggregated array relationship */
  user_invitations_aggregate: DemiplaneUserInvitationAggregate;
};


/** columns and relationships of "demiplane.invitation" */
export type DemiplaneInvitationUserInvitationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};


/** columns and relationships of "demiplane.invitation" */
export type DemiplaneInvitationUserInvitationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};

/** aggregated selection of "demiplane.invitation" */
export type DemiplaneInvitationAggregate = {
  __typename?: 'demiplane_invitation_aggregate';
  aggregate?: Maybe<DemiplaneInvitationAggregateFields>;
  nodes: Array<DemiplaneInvitation>;
};

/** aggregate fields of "demiplane.invitation" */
export type DemiplaneInvitationAggregateFields = {
  __typename?: 'demiplane_invitation_aggregate_fields';
  avg?: Maybe<DemiplaneInvitationAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneInvitationMaxFields>;
  min?: Maybe<DemiplaneInvitationMinFields>;
  stddev?: Maybe<DemiplaneInvitationStddevFields>;
  stddev_pop?: Maybe<DemiplaneInvitationStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneInvitationStddevSampFields>;
  sum?: Maybe<DemiplaneInvitationSumFields>;
  var_pop?: Maybe<DemiplaneInvitationVarPopFields>;
  var_samp?: Maybe<DemiplaneInvitationVarSampFields>;
  variance?: Maybe<DemiplaneInvitationVarianceFields>;
};


/** aggregate fields of "demiplane.invitation" */
export type DemiplaneInvitationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.invitation" */
export type DemiplaneInvitationAggregateOrderBy = {
  avg?: Maybe<DemiplaneInvitationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneInvitationMaxOrderBy>;
  min?: Maybe<DemiplaneInvitationMinOrderBy>;
  stddev?: Maybe<DemiplaneInvitationStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneInvitationStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneInvitationStddevSampOrderBy>;
  sum?: Maybe<DemiplaneInvitationSumOrderBy>;
  var_pop?: Maybe<DemiplaneInvitationVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneInvitationVarSampOrderBy>;
  variance?: Maybe<DemiplaneInvitationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.invitation" */
export type DemiplaneInvitationArrRelInsertInput = {
  data: Array<DemiplaneInvitationInsertInput>;
  on_conflict?: Maybe<DemiplaneInvitationOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneInvitationAvgFields = {
  __typename?: 'demiplane_invitation_avg_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationAvgOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.invitation". All fields are combined with a logical 'AND'. */
export type DemiplaneInvitationBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneInvitationBoolExp>>>;
  _not?: Maybe<DemiplaneInvitationBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneInvitationBoolExp>>>;
  accepted_date?: Maybe<TimestamptzComparisonExp>;
  accepted_flag?: Maybe<BooleanComparisonExp>;
  ad?: Maybe<DemiplaneAdBoolExp>;
  ad_id?: Maybe<BigintComparisonExp>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  declined_date?: Maybe<TimestamptzComparisonExp>;
  declined_flag?: Maybe<BooleanComparisonExp>;
  deleted?: Maybe<TimestampComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  recruited_date?: Maybe<TimestamptzComparisonExp>;
  recruited_flag?: Maybe<BooleanComparisonExp>;
  user_invitations?: Maybe<DemiplaneUserInvitationBoolExp>;
};

/** unique or primary key constraints on table "demiplane.invitation" */
export enum DemiplaneInvitationConstraint {
  /** unique or primary key constraint */
  PkInvitationId = 'pk_invitation_id'
}

/** input type for incrementing integer column in table "demiplane.invitation" */
export type DemiplaneInvitationIncInput = {
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.invitation" */
export type DemiplaneInvitationInsertInput = {
  accepted_date?: Maybe<Scalars['timestamptz']>;
  accepted_flag?: Maybe<Scalars['Boolean']>;
  ad?: Maybe<DemiplaneAdObjRelInsertInput>;
  ad_id?: Maybe<Scalars['bigint']>;
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  declined_flag?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  recruited_date?: Maybe<Scalars['timestamptz']>;
  recruited_flag?: Maybe<Scalars['Boolean']>;
  user_invitations?: Maybe<DemiplaneUserInvitationArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneInvitationMaxFields = {
  __typename?: 'demiplane_invitation_max_fields';
  accepted_date?: Maybe<Scalars['timestamptz']>;
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  recruited_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationMaxOrderBy = {
  accepted_date?: Maybe<OrderBy>;
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recruited_date?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneInvitationMinFields = {
  __typename?: 'demiplane_invitation_min_fields';
  accepted_date?: Maybe<Scalars['timestamptz']>;
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  recruited_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationMinOrderBy = {
  accepted_date?: Maybe<OrderBy>;
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recruited_date?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.invitation" */
export type DemiplaneInvitationMutationResponse = {
  __typename?: 'demiplane_invitation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneInvitation>;
};

/** input type for inserting object relation for remote table "demiplane.invitation" */
export type DemiplaneInvitationObjRelInsertInput = {
  data: DemiplaneInvitationInsertInput;
  on_conflict?: Maybe<DemiplaneInvitationOnConflict>;
};

/** on conflict condition type for table "demiplane.invitation" */
export type DemiplaneInvitationOnConflict = {
  constraint: DemiplaneInvitationConstraint;
  update_columns: Array<DemiplaneInvitationUpdateColumn>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};

/** ordering options when selecting data from "demiplane.invitation" */
export type DemiplaneInvitationOrderBy = {
  accepted_date?: Maybe<OrderBy>;
  accepted_flag?: Maybe<OrderBy>;
  ad?: Maybe<DemiplaneAdOrderBy>;
  ad_id?: Maybe<OrderBy>;
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  declined_flag?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  recruited_date?: Maybe<OrderBy>;
  recruited_flag?: Maybe<OrderBy>;
  user_invitations_aggregate?: Maybe<DemiplaneUserInvitationAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.invitation" */
export type DemiplaneInvitationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.invitation" */
export enum DemiplaneInvitationSelectColumn {
  /** column name */
  AcceptedDate = 'accepted_date',
  /** column name */
  AcceptedFlag = 'accepted_flag',
  /** column name */
  AdId = 'ad_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  DeclinedDate = 'declined_date',
  /** column name */
  DeclinedFlag = 'declined_flag',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  RecruitedDate = 'recruited_date',
  /** column name */
  RecruitedFlag = 'recruited_flag'
}

/** input type for updating data in table "demiplane.invitation" */
export type DemiplaneInvitationSetInput = {
  accepted_date?: Maybe<Scalars['timestamptz']>;
  accepted_flag?: Maybe<Scalars['Boolean']>;
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  declined_flag?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  recruited_date?: Maybe<Scalars['timestamptz']>;
  recruited_flag?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type DemiplaneInvitationStddevFields = {
  __typename?: 'demiplane_invitation_stddev_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationStddevOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneInvitationStddevPopFields = {
  __typename?: 'demiplane_invitation_stddev_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationStddevPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneInvitationStddevSampFields = {
  __typename?: 'demiplane_invitation_stddev_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationStddevSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneInvitationSumFields = {
  __typename?: 'demiplane_invitation_sum_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationSumOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.invitation" */
export enum DemiplaneInvitationUpdateColumn {
  /** column name */
  AcceptedDate = 'accepted_date',
  /** column name */
  AcceptedFlag = 'accepted_flag',
  /** column name */
  AdId = 'ad_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  DeclinedDate = 'declined_date',
  /** column name */
  DeclinedFlag = 'declined_flag',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  RecruitedDate = 'recruited_date',
  /** column name */
  RecruitedFlag = 'recruited_flag'
}

/** aggregate var_pop on columns */
export type DemiplaneInvitationVarPopFields = {
  __typename?: 'demiplane_invitation_var_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationVarPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneInvitationVarSampFields = {
  __typename?: 'demiplane_invitation_var_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationVarSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneInvitationVarianceFields = {
  __typename?: 'demiplane_invitation_variance_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.invitation" */
export type DemiplaneInvitationVarianceOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.journal" */
export type DemiplaneJournal = {
  __typename?: 'demiplane_journal';
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An array relationship */
  journal_notes: Array<DemiplaneJournalNote>;
  /** An aggregated array relationship */
  journal_notes_aggregate: DemiplaneJournalNoteAggregate;
  /** An array relationship */
  journal_super_events: Array<DemiplaneJournalSuperEvent>;
  /** An aggregated array relationship */
  journal_super_events_aggregate: DemiplaneJournalSuperEventAggregate;
  /** An array relationship */
  journal_titles: Array<DemiplaneJournalTitle>;
  /** An aggregated array relationship */
  journal_titles_aggregate: DemiplaneJournalTitleAggregate;
  /** An object relationship */
  session: DemiplaneSession;
  session_id: Scalars['bigint'];
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.journal" */
export type DemiplaneJournalJournalNotesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** columns and relationships of "demiplane.journal" */
export type DemiplaneJournalJournalNotesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** columns and relationships of "demiplane.journal" */
export type DemiplaneJournalJournalSuperEventsArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventBoolExp>;
};


/** columns and relationships of "demiplane.journal" */
export type DemiplaneJournalJournalSuperEventsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventBoolExp>;
};


/** columns and relationships of "demiplane.journal" */
export type DemiplaneJournalJournalTitlesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};


/** columns and relationships of "demiplane.journal" */
export type DemiplaneJournalJournalTitlesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};

/** aggregated selection of "demiplane.journal" */
export type DemiplaneJournalAggregate = {
  __typename?: 'demiplane_journal_aggregate';
  aggregate?: Maybe<DemiplaneJournalAggregateFields>;
  nodes: Array<DemiplaneJournal>;
};

/** aggregate fields of "demiplane.journal" */
export type DemiplaneJournalAggregateFields = {
  __typename?: 'demiplane_journal_aggregate_fields';
  avg?: Maybe<DemiplaneJournalAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneJournalMaxFields>;
  min?: Maybe<DemiplaneJournalMinFields>;
  stddev?: Maybe<DemiplaneJournalStddevFields>;
  stddev_pop?: Maybe<DemiplaneJournalStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneJournalStddevSampFields>;
  sum?: Maybe<DemiplaneJournalSumFields>;
  var_pop?: Maybe<DemiplaneJournalVarPopFields>;
  var_samp?: Maybe<DemiplaneJournalVarSampFields>;
  variance?: Maybe<DemiplaneJournalVarianceFields>;
};


/** aggregate fields of "demiplane.journal" */
export type DemiplaneJournalAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneJournalSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.journal" */
export type DemiplaneJournalAggregateOrderBy = {
  avg?: Maybe<DemiplaneJournalAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneJournalMaxOrderBy>;
  min?: Maybe<DemiplaneJournalMinOrderBy>;
  stddev?: Maybe<DemiplaneJournalStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneJournalStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneJournalStddevSampOrderBy>;
  sum?: Maybe<DemiplaneJournalSumOrderBy>;
  var_pop?: Maybe<DemiplaneJournalVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneJournalVarSampOrderBy>;
  variance?: Maybe<DemiplaneJournalVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.journal" */
export type DemiplaneJournalArrRelInsertInput = {
  data: Array<DemiplaneJournalInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneJournalAvgFields = {
  __typename?: 'demiplane_journal_avg_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.journal" */
export type DemiplaneJournalAvgOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.journal". All fields are combined with a logical 'AND'. */
export type DemiplaneJournalBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneJournalBoolExp>>>;
  _not?: Maybe<DemiplaneJournalBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneJournalBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  journal_notes?: Maybe<DemiplaneJournalNoteBoolExp>;
  journal_super_events?: Maybe<DemiplaneJournalSuperEventBoolExp>;
  journal_titles?: Maybe<DemiplaneJournalTitleBoolExp>;
  session?: Maybe<DemiplaneSessionBoolExp>;
  session_id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.journal" */
export enum DemiplaneJournalConstraint {
  /** unique or primary key constraint */
  PkJournalId = 'pk_journal_id'
}

/** input type for incrementing integer column in table "demiplane.journal" */
export type DemiplaneJournalIncInput = {
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.journal" */
export type DemiplaneJournalInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_notes?: Maybe<DemiplaneJournalNoteArrRelInsertInput>;
  journal_super_events?: Maybe<DemiplaneJournalSuperEventArrRelInsertInput>;
  journal_titles?: Maybe<DemiplaneJournalTitleArrRelInsertInput>;
  session?: Maybe<DemiplaneSessionObjRelInsertInput>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneJournalMaxFields = {
  __typename?: 'demiplane_journal_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.journal" */
export type DemiplaneJournalMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneJournalMinFields = {
  __typename?: 'demiplane_journal_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.journal" */
export type DemiplaneJournalMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.journal" */
export type DemiplaneJournalMutationResponse = {
  __typename?: 'demiplane_journal_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneJournal>;
};

/** columns and relationships of "demiplane.journal_note" */
export type DemiplaneJournalNote = {
  __typename?: 'demiplane_journal_note';
  /** An object relationship */
  adventure_player: DemiplaneAdventurePlayer;
  adventure_player_id: Scalars['bigint'];
  content_delta: Scalars['jsonb'];
  content_html: Scalars['String'];
  content_plain: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journal: DemiplaneJournal;
  journal_id: Scalars['bigint'];
  /** An array relationship */
  journal_note_shares: Array<DemiplaneJournalNoteShare>;
  /** An aggregated array relationship */
  journal_note_shares_aggregate: DemiplaneJournalNoteShareAggregate;
  title: Scalars['String'];
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.journal_note" */
export type DemiplaneJournalNoteContentDeltaArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "demiplane.journal_note" */
export type DemiplaneJournalNoteJournalNoteSharesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};


/** columns and relationships of "demiplane.journal_note" */
export type DemiplaneJournalNoteJournalNoteSharesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};

/** aggregated selection of "demiplane.journal_note" */
export type DemiplaneJournalNoteAggregate = {
  __typename?: 'demiplane_journal_note_aggregate';
  aggregate?: Maybe<DemiplaneJournalNoteAggregateFields>;
  nodes: Array<DemiplaneJournalNote>;
};

/** aggregate fields of "demiplane.journal_note" */
export type DemiplaneJournalNoteAggregateFields = {
  __typename?: 'demiplane_journal_note_aggregate_fields';
  avg?: Maybe<DemiplaneJournalNoteAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneJournalNoteMaxFields>;
  min?: Maybe<DemiplaneJournalNoteMinFields>;
  stddev?: Maybe<DemiplaneJournalNoteStddevFields>;
  stddev_pop?: Maybe<DemiplaneJournalNoteStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneJournalNoteStddevSampFields>;
  sum?: Maybe<DemiplaneJournalNoteSumFields>;
  var_pop?: Maybe<DemiplaneJournalNoteVarPopFields>;
  var_samp?: Maybe<DemiplaneJournalNoteVarSampFields>;
  variance?: Maybe<DemiplaneJournalNoteVarianceFields>;
};


/** aggregate fields of "demiplane.journal_note" */
export type DemiplaneJournalNoteAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.journal_note" */
export type DemiplaneJournalNoteAggregateOrderBy = {
  avg?: Maybe<DemiplaneJournalNoteAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneJournalNoteMaxOrderBy>;
  min?: Maybe<DemiplaneJournalNoteMinOrderBy>;
  stddev?: Maybe<DemiplaneJournalNoteStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneJournalNoteStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneJournalNoteStddevSampOrderBy>;
  sum?: Maybe<DemiplaneJournalNoteSumOrderBy>;
  var_pop?: Maybe<DemiplaneJournalNoteVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneJournalNoteVarSampOrderBy>;
  variance?: Maybe<DemiplaneJournalNoteVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DemiplaneJournalNoteAppendInput = {
  content_delta?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "demiplane.journal_note" */
export type DemiplaneJournalNoteArrRelInsertInput = {
  data: Array<DemiplaneJournalNoteInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalNoteOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneJournalNoteAvgFields = {
  __typename?: 'demiplane_journal_note_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.journal_note". All fields are combined with a logical 'AND'. */
export type DemiplaneJournalNoteBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneJournalNoteBoolExp>>>;
  _not?: Maybe<DemiplaneJournalNoteBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneJournalNoteBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  content_delta?: Maybe<JsonbComparisonExp>;
  content_html?: Maybe<StringComparisonExp>;
  content_plain?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  journal?: Maybe<DemiplaneJournalBoolExp>;
  journal_id?: Maybe<BigintComparisonExp>;
  journal_note_shares?: Maybe<DemiplaneJournalNoteShareBoolExp>;
  title?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.journal_note" */
export enum DemiplaneJournalNoteConstraint {
  /** unique or primary key constraint */
  PkJournalNoteId = 'pk_journal_note_id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DemiplaneJournalNoteDeleteAtPathInput = {
  content_delta?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DemiplaneJournalNoteDeleteElemInput = {
  content_delta?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DemiplaneJournalNoteDeleteKeyInput = {
  content_delta?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "demiplane.journal_note" */
export type DemiplaneJournalNoteIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.journal_note" */
export type DemiplaneJournalNoteInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  content_delta?: Maybe<Scalars['jsonb']>;
  content_html?: Maybe<Scalars['String']>;
  content_plain?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal?: Maybe<DemiplaneJournalObjRelInsertInput>;
  journal_id?: Maybe<Scalars['bigint']>;
  journal_note_shares?: Maybe<DemiplaneJournalNoteShareArrRelInsertInput>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneJournalNoteMaxFields = {
  __typename?: 'demiplane_journal_note_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  content_html?: Maybe<Scalars['String']>;
  content_plain?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  content_html?: Maybe<OrderBy>;
  content_plain?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneJournalNoteMinFields = {
  __typename?: 'demiplane_journal_note_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  content_html?: Maybe<Scalars['String']>;
  content_plain?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  content_html?: Maybe<OrderBy>;
  content_plain?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.journal_note" */
export type DemiplaneJournalNoteMutationResponse = {
  __typename?: 'demiplane_journal_note_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneJournalNote>;
};

/** input type for inserting object relation for remote table "demiplane.journal_note" */
export type DemiplaneJournalNoteObjRelInsertInput = {
  data: DemiplaneJournalNoteInsertInput;
  on_conflict?: Maybe<DemiplaneJournalNoteOnConflict>;
};

/** on conflict condition type for table "demiplane.journal_note" */
export type DemiplaneJournalNoteOnConflict = {
  constraint: DemiplaneJournalNoteConstraint;
  update_columns: Array<DemiplaneJournalNoteUpdateColumn>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};

/** ordering options when selecting data from "demiplane.journal_note" */
export type DemiplaneJournalNoteOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  content_delta?: Maybe<OrderBy>;
  content_html?: Maybe<OrderBy>;
  content_plain?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal?: Maybe<DemiplaneJournalOrderBy>;
  journal_id?: Maybe<OrderBy>;
  journal_note_shares_aggregate?: Maybe<DemiplaneJournalNoteShareAggregateOrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.journal_note" */
export type DemiplaneJournalNotePkColumnsInput = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DemiplaneJournalNotePrependInput = {
  content_delta?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "demiplane.journal_note" */
export enum DemiplaneJournalNoteSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  ContentDelta = 'content_delta',
  /** column name */
  ContentHtml = 'content_html',
  /** column name */
  ContentPlain = 'content_plain',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.journal_note" */
export type DemiplaneJournalNoteSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  content_delta?: Maybe<Scalars['jsonb']>;
  content_html?: Maybe<Scalars['String']>;
  content_plain?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShare = {
  __typename?: 'demiplane_journal_note_share';
  /** An object relationship */
  adventure_player: DemiplaneAdventurePlayer;
  adventure_player_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journal_note: DemiplaneJournalNote;
  journal_note_id: Scalars['bigint'];
  read: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareAggregate = {
  __typename?: 'demiplane_journal_note_share_aggregate';
  aggregate?: Maybe<DemiplaneJournalNoteShareAggregateFields>;
  nodes: Array<DemiplaneJournalNoteShare>;
};

/** aggregate fields of "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareAggregateFields = {
  __typename?: 'demiplane_journal_note_share_aggregate_fields';
  avg?: Maybe<DemiplaneJournalNoteShareAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneJournalNoteShareMaxFields>;
  min?: Maybe<DemiplaneJournalNoteShareMinFields>;
  stddev?: Maybe<DemiplaneJournalNoteShareStddevFields>;
  stddev_pop?: Maybe<DemiplaneJournalNoteShareStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneJournalNoteShareStddevSampFields>;
  sum?: Maybe<DemiplaneJournalNoteShareSumFields>;
  var_pop?: Maybe<DemiplaneJournalNoteShareVarPopFields>;
  var_samp?: Maybe<DemiplaneJournalNoteShareVarSampFields>;
  variance?: Maybe<DemiplaneJournalNoteShareVarianceFields>;
};


/** aggregate fields of "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareAggregateOrderBy = {
  avg?: Maybe<DemiplaneJournalNoteShareAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneJournalNoteShareMaxOrderBy>;
  min?: Maybe<DemiplaneJournalNoteShareMinOrderBy>;
  stddev?: Maybe<DemiplaneJournalNoteShareStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneJournalNoteShareStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneJournalNoteShareStddevSampOrderBy>;
  sum?: Maybe<DemiplaneJournalNoteShareSumOrderBy>;
  var_pop?: Maybe<DemiplaneJournalNoteShareVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneJournalNoteShareVarSampOrderBy>;
  variance?: Maybe<DemiplaneJournalNoteShareVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareArrRelInsertInput = {
  data: Array<DemiplaneJournalNoteShareInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalNoteShareOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneJournalNoteShareAvgFields = {
  __typename?: 'demiplane_journal_note_share_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_note_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.journal_note_share". All fields are combined with a logical 'AND'. */
export type DemiplaneJournalNoteShareBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneJournalNoteShareBoolExp>>>;
  _not?: Maybe<DemiplaneJournalNoteShareBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneJournalNoteShareBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  journal_note?: Maybe<DemiplaneJournalNoteBoolExp>;
  journal_note_id?: Maybe<BigintComparisonExp>;
  read?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.journal_note_share" */
export enum DemiplaneJournalNoteShareConstraint {
  /** unique or primary key constraint */
  PkJournalNoteShareId = 'pk_journal_note_share_id'
}

/** input type for incrementing integer column in table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_note_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_note?: Maybe<DemiplaneJournalNoteObjRelInsertInput>;
  journal_note_id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneJournalNoteShareMaxFields = {
  __typename?: 'demiplane_journal_note_share_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_note_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneJournalNoteShareMinFields = {
  __typename?: 'demiplane_journal_note_share_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_note_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareMutationResponse = {
  __typename?: 'demiplane_journal_note_share_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneJournalNoteShare>;
};

/** input type for inserting object relation for remote table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareObjRelInsertInput = {
  data: DemiplaneJournalNoteShareInsertInput;
  on_conflict?: Maybe<DemiplaneJournalNoteShareOnConflict>;
};

/** on conflict condition type for table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareOnConflict = {
  constraint: DemiplaneJournalNoteShareConstraint;
  update_columns: Array<DemiplaneJournalNoteShareUpdateColumn>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};

/** ordering options when selecting data from "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note?: Maybe<DemiplaneJournalNoteOrderBy>;
  journal_note_id?: Maybe<OrderBy>;
  read?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.journal_note_share" */
export type DemiplaneJournalNoteSharePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.journal_note_share" */
export enum DemiplaneJournalNoteShareSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalNoteId = 'journal_note_id',
  /** column name */
  Read = 'read',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_note_id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneJournalNoteShareStddevFields = {
  __typename?: 'demiplane_journal_note_share_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneJournalNoteShareStddevPopFields = {
  __typename?: 'demiplane_journal_note_share_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneJournalNoteShareStddevSampFields = {
  __typename?: 'demiplane_journal_note_share_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneJournalNoteShareSumFields = {
  __typename?: 'demiplane_journal_note_share_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_note_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.journal_note_share" */
export enum DemiplaneJournalNoteShareUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalNoteId = 'journal_note_id',
  /** column name */
  Read = 'read',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneJournalNoteShareVarPopFields = {
  __typename?: 'demiplane_journal_note_share_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneJournalNoteShareVarSampFields = {
  __typename?: 'demiplane_journal_note_share_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneJournalNoteShareVarianceFields = {
  __typename?: 'demiplane_journal_note_share_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_note_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.journal_note_share" */
export type DemiplaneJournalNoteShareVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_note_id?: Maybe<OrderBy>;
};

/** aggregate stddev on columns */
export type DemiplaneJournalNoteStddevFields = {
  __typename?: 'demiplane_journal_note_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneJournalNoteStddevPopFields = {
  __typename?: 'demiplane_journal_note_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneJournalNoteStddevSampFields = {
  __typename?: 'demiplane_journal_note_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneJournalNoteSumFields = {
  __typename?: 'demiplane_journal_note_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.journal_note" */
export enum DemiplaneJournalNoteUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  ContentDelta = 'content_delta',
  /** column name */
  ContentHtml = 'content_html',
  /** column name */
  ContentPlain = 'content_plain',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneJournalNoteVarPopFields = {
  __typename?: 'demiplane_journal_note_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneJournalNoteVarSampFields = {
  __typename?: 'demiplane_journal_note_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneJournalNoteVarianceFields = {
  __typename?: 'demiplane_journal_note_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.journal_note" */
export type DemiplaneJournalNoteVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** input type for inserting object relation for remote table "demiplane.journal" */
export type DemiplaneJournalObjRelInsertInput = {
  data: DemiplaneJournalInsertInput;
  on_conflict?: Maybe<DemiplaneJournalOnConflict>;
};

/** on conflict condition type for table "demiplane.journal" */
export type DemiplaneJournalOnConflict = {
  constraint: DemiplaneJournalConstraint;
  update_columns: Array<DemiplaneJournalUpdateColumn>;
  where?: Maybe<DemiplaneJournalBoolExp>;
};

/** ordering options when selecting data from "demiplane.journal" */
export type DemiplaneJournalOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_notes_aggregate?: Maybe<DemiplaneJournalNoteAggregateOrderBy>;
  journal_super_events_aggregate?: Maybe<DemiplaneJournalSuperEventAggregateOrderBy>;
  journal_titles_aggregate?: Maybe<DemiplaneJournalTitleAggregateOrderBy>;
  session?: Maybe<DemiplaneSessionOrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.journal" */
export type DemiplaneJournalPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.journal" */
export enum DemiplaneJournalSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.journal" */
export type DemiplaneJournalSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneJournalStddevFields = {
  __typename?: 'demiplane_journal_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.journal" */
export type DemiplaneJournalStddevOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneJournalStddevPopFields = {
  __typename?: 'demiplane_journal_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.journal" */
export type DemiplaneJournalStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneJournalStddevSampFields = {
  __typename?: 'demiplane_journal_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.journal" */
export type DemiplaneJournalStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneJournalSumFields = {
  __typename?: 'demiplane_journal_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.journal" */
export type DemiplaneJournalSumOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEvent = {
  __typename?: 'demiplane_journal_super_event';
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journal: DemiplaneJournal;
  journal_id: Scalars['bigint'];
  /** An array relationship */
  journal_super_event_read_statuses: Array<DemiplaneJournalSuperEventReadStatus>;
  /** An aggregated array relationship */
  journal_super_event_read_statuses_aggregate: DemiplaneJournalSuperEventReadStatusAggregate;
  title: Scalars['String'];
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventJournalSuperEventReadStatusesArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};


/** columns and relationships of "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventJournalSuperEventReadStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};

/** aggregated selection of "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventAggregate = {
  __typename?: 'demiplane_journal_super_event_aggregate';
  aggregate?: Maybe<DemiplaneJournalSuperEventAggregateFields>;
  nodes: Array<DemiplaneJournalSuperEvent>;
};

/** aggregate fields of "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventAggregateFields = {
  __typename?: 'demiplane_journal_super_event_aggregate_fields';
  avg?: Maybe<DemiplaneJournalSuperEventAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneJournalSuperEventMaxFields>;
  min?: Maybe<DemiplaneJournalSuperEventMinFields>;
  stddev?: Maybe<DemiplaneJournalSuperEventStddevFields>;
  stddev_pop?: Maybe<DemiplaneJournalSuperEventStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneJournalSuperEventStddevSampFields>;
  sum?: Maybe<DemiplaneJournalSuperEventSumFields>;
  var_pop?: Maybe<DemiplaneJournalSuperEventVarPopFields>;
  var_samp?: Maybe<DemiplaneJournalSuperEventVarSampFields>;
  variance?: Maybe<DemiplaneJournalSuperEventVarianceFields>;
};


/** aggregate fields of "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneJournalSuperEventSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventAggregateOrderBy = {
  avg?: Maybe<DemiplaneJournalSuperEventAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneJournalSuperEventMaxOrderBy>;
  min?: Maybe<DemiplaneJournalSuperEventMinOrderBy>;
  stddev?: Maybe<DemiplaneJournalSuperEventStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneJournalSuperEventStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneJournalSuperEventStddevSampOrderBy>;
  sum?: Maybe<DemiplaneJournalSuperEventSumOrderBy>;
  var_pop?: Maybe<DemiplaneJournalSuperEventVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneJournalSuperEventVarSampOrderBy>;
  variance?: Maybe<DemiplaneJournalSuperEventVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventArrRelInsertInput = {
  data: Array<DemiplaneJournalSuperEventInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalSuperEventOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneJournalSuperEventAvgFields = {
  __typename?: 'demiplane_journal_super_event_avg_fields';
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventAvgOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.journal_super_event". All fields are combined with a logical 'AND'. */
export type DemiplaneJournalSuperEventBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneJournalSuperEventBoolExp>>>;
  _not?: Maybe<DemiplaneJournalSuperEventBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneJournalSuperEventBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  journal?: Maybe<DemiplaneJournalBoolExp>;
  journal_id?: Maybe<BigintComparisonExp>;
  journal_super_event_read_statuses?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
  title?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.journal_super_event" */
export enum DemiplaneJournalSuperEventConstraint {
  /** unique or primary key constraint */
  PkJournalSuperEventId = 'pk_journal_super_event_id'
}

/** input type for incrementing integer column in table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventIncInput = {
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal?: Maybe<DemiplaneJournalObjRelInsertInput>;
  journal_id?: Maybe<Scalars['bigint']>;
  journal_super_event_read_statuses?: Maybe<DemiplaneJournalSuperEventReadStatusArrRelInsertInput>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneJournalSuperEventMaxFields = {
  __typename?: 'demiplane_journal_super_event_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneJournalSuperEventMinFields = {
  __typename?: 'demiplane_journal_super_event_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventMutationResponse = {
  __typename?: 'demiplane_journal_super_event_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneJournalSuperEvent>;
};

/** input type for inserting object relation for remote table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventObjRelInsertInput = {
  data: DemiplaneJournalSuperEventInsertInput;
  on_conflict?: Maybe<DemiplaneJournalSuperEventOnConflict>;
};

/** on conflict condition type for table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventOnConflict = {
  constraint: DemiplaneJournalSuperEventConstraint;
  update_columns: Array<DemiplaneJournalSuperEventUpdateColumn>;
  where?: Maybe<DemiplaneJournalSuperEventBoolExp>;
};

/** ordering options when selecting data from "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal?: Maybe<DemiplaneJournalOrderBy>;
  journal_id?: Maybe<OrderBy>;
  journal_super_event_read_statuses_aggregate?: Maybe<DemiplaneJournalSuperEventReadStatusAggregateOrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventPkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatus = {
  __typename?: 'demiplane_journal_super_event_read_status';
  /** An object relationship */
  adventure_player: DemiplaneAdventurePlayer;
  adventure_player_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journal_super_event: DemiplaneJournalSuperEvent;
  journal_super_event_id: Scalars['bigint'];
  read: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusAggregate = {
  __typename?: 'demiplane_journal_super_event_read_status_aggregate';
  aggregate?: Maybe<DemiplaneJournalSuperEventReadStatusAggregateFields>;
  nodes: Array<DemiplaneJournalSuperEventReadStatus>;
};

/** aggregate fields of "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusAggregateFields = {
  __typename?: 'demiplane_journal_super_event_read_status_aggregate_fields';
  avg?: Maybe<DemiplaneJournalSuperEventReadStatusAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneJournalSuperEventReadStatusMaxFields>;
  min?: Maybe<DemiplaneJournalSuperEventReadStatusMinFields>;
  stddev?: Maybe<DemiplaneJournalSuperEventReadStatusStddevFields>;
  stddev_pop?: Maybe<DemiplaneJournalSuperEventReadStatusStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneJournalSuperEventReadStatusStddevSampFields>;
  sum?: Maybe<DemiplaneJournalSuperEventReadStatusSumFields>;
  var_pop?: Maybe<DemiplaneJournalSuperEventReadStatusVarPopFields>;
  var_samp?: Maybe<DemiplaneJournalSuperEventReadStatusVarSampFields>;
  variance?: Maybe<DemiplaneJournalSuperEventReadStatusVarianceFields>;
};


/** aggregate fields of "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusAggregateOrderBy = {
  avg?: Maybe<DemiplaneJournalSuperEventReadStatusAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneJournalSuperEventReadStatusMaxOrderBy>;
  min?: Maybe<DemiplaneJournalSuperEventReadStatusMinOrderBy>;
  stddev?: Maybe<DemiplaneJournalSuperEventReadStatusStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneJournalSuperEventReadStatusStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneJournalSuperEventReadStatusStddevSampOrderBy>;
  sum?: Maybe<DemiplaneJournalSuperEventReadStatusSumOrderBy>;
  var_pop?: Maybe<DemiplaneJournalSuperEventReadStatusVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneJournalSuperEventReadStatusVarSampOrderBy>;
  variance?: Maybe<DemiplaneJournalSuperEventReadStatusVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusArrRelInsertInput = {
  data: Array<DemiplaneJournalSuperEventReadStatusInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalSuperEventReadStatusOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneJournalSuperEventReadStatusAvgFields = {
  __typename?: 'demiplane_journal_super_event_read_status_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_super_event_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.journal_super_event_read_status". All fields are combined with a logical 'AND'. */
export type DemiplaneJournalSuperEventReadStatusBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>>>;
  _not?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  journal_super_event?: Maybe<DemiplaneJournalSuperEventBoolExp>;
  journal_super_event_id?: Maybe<BigintComparisonExp>;
  read?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.journal_super_event_read_status" */
export enum DemiplaneJournalSuperEventReadStatusConstraint {
  /** unique or primary key constraint */
  PkJournalSuperEventReadStatusId = 'pk_journal_super_event_read_status_id'
}

/** input type for incrementing integer column in table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_super_event_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_super_event?: Maybe<DemiplaneJournalSuperEventObjRelInsertInput>;
  journal_super_event_id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneJournalSuperEventReadStatusMaxFields = {
  __typename?: 'demiplane_journal_super_event_read_status_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_super_event_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneJournalSuperEventReadStatusMinFields = {
  __typename?: 'demiplane_journal_super_event_read_status_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_super_event_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusMutationResponse = {
  __typename?: 'demiplane_journal_super_event_read_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneJournalSuperEventReadStatus>;
};

/** input type for inserting object relation for remote table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusObjRelInsertInput = {
  data: DemiplaneJournalSuperEventReadStatusInsertInput;
  on_conflict?: Maybe<DemiplaneJournalSuperEventReadStatusOnConflict>;
};

/** on conflict condition type for table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusOnConflict = {
  constraint: DemiplaneJournalSuperEventReadStatusConstraint;
  update_columns: Array<DemiplaneJournalSuperEventReadStatusUpdateColumn>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};

/** ordering options when selecting data from "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event?: Maybe<DemiplaneJournalSuperEventOrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
  read?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.journal_super_event_read_status" */
export enum DemiplaneJournalSuperEventReadStatusSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalSuperEventId = 'journal_super_event_id',
  /** column name */
  Read = 'read',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_super_event_id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneJournalSuperEventReadStatusStddevFields = {
  __typename?: 'demiplane_journal_super_event_read_status_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_super_event_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneJournalSuperEventReadStatusStddevPopFields = {
  __typename?: 'demiplane_journal_super_event_read_status_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_super_event_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneJournalSuperEventReadStatusStddevSampFields = {
  __typename?: 'demiplane_journal_super_event_read_status_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_super_event_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneJournalSuperEventReadStatusSumFields = {
  __typename?: 'demiplane_journal_super_event_read_status_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_super_event_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.journal_super_event_read_status" */
export enum DemiplaneJournalSuperEventReadStatusUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalSuperEventId = 'journal_super_event_id',
  /** column name */
  Read = 'read',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneJournalSuperEventReadStatusVarPopFields = {
  __typename?: 'demiplane_journal_super_event_read_status_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_super_event_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneJournalSuperEventReadStatusVarSampFields = {
  __typename?: 'demiplane_journal_super_event_read_status_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_super_event_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneJournalSuperEventReadStatusVarianceFields = {
  __typename?: 'demiplane_journal_super_event_read_status_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_super_event_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.journal_super_event_read_status" */
export type DemiplaneJournalSuperEventReadStatusVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_super_event_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.journal_super_event" */
export enum DemiplaneJournalSuperEventSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneJournalSuperEventStddevFields = {
  __typename?: 'demiplane_journal_super_event_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventStddevOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneJournalSuperEventStddevPopFields = {
  __typename?: 'demiplane_journal_super_event_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneJournalSuperEventStddevSampFields = {
  __typename?: 'demiplane_journal_super_event_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneJournalSuperEventSumFields = {
  __typename?: 'demiplane_journal_super_event_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventSumOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.journal_super_event" */
export enum DemiplaneJournalSuperEventUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneJournalSuperEventVarPopFields = {
  __typename?: 'demiplane_journal_super_event_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneJournalSuperEventVarSampFields = {
  __typename?: 'demiplane_journal_super_event_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneJournalSuperEventVarianceFields = {
  __typename?: 'demiplane_journal_super_event_variance_fields';
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.journal_super_event" */
export type DemiplaneJournalSuperEventVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.journal_title" */
export type DemiplaneJournalTitle = {
  __typename?: 'demiplane_journal_title';
  /** An object relationship */
  adventure_player: DemiplaneAdventurePlayer;
  adventure_player_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  journal: DemiplaneJournal;
  journal_id: Scalars['bigint'];
  title: Scalars['String'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.journal_title" */
export type DemiplaneJournalTitleAggregate = {
  __typename?: 'demiplane_journal_title_aggregate';
  aggregate?: Maybe<DemiplaneJournalTitleAggregateFields>;
  nodes: Array<DemiplaneJournalTitle>;
};

/** aggregate fields of "demiplane.journal_title" */
export type DemiplaneJournalTitleAggregateFields = {
  __typename?: 'demiplane_journal_title_aggregate_fields';
  avg?: Maybe<DemiplaneJournalTitleAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneJournalTitleMaxFields>;
  min?: Maybe<DemiplaneJournalTitleMinFields>;
  stddev?: Maybe<DemiplaneJournalTitleStddevFields>;
  stddev_pop?: Maybe<DemiplaneJournalTitleStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneJournalTitleStddevSampFields>;
  sum?: Maybe<DemiplaneJournalTitleSumFields>;
  var_pop?: Maybe<DemiplaneJournalTitleVarPopFields>;
  var_samp?: Maybe<DemiplaneJournalTitleVarSampFields>;
  variance?: Maybe<DemiplaneJournalTitleVarianceFields>;
};


/** aggregate fields of "demiplane.journal_title" */
export type DemiplaneJournalTitleAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.journal_title" */
export type DemiplaneJournalTitleAggregateOrderBy = {
  avg?: Maybe<DemiplaneJournalTitleAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneJournalTitleMaxOrderBy>;
  min?: Maybe<DemiplaneJournalTitleMinOrderBy>;
  stddev?: Maybe<DemiplaneJournalTitleStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneJournalTitleStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneJournalTitleStddevSampOrderBy>;
  sum?: Maybe<DemiplaneJournalTitleSumOrderBy>;
  var_pop?: Maybe<DemiplaneJournalTitleVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneJournalTitleVarSampOrderBy>;
  variance?: Maybe<DemiplaneJournalTitleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.journal_title" */
export type DemiplaneJournalTitleArrRelInsertInput = {
  data: Array<DemiplaneJournalTitleInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalTitleOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneJournalTitleAvgFields = {
  __typename?: 'demiplane_journal_title_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.journal_title". All fields are combined with a logical 'AND'. */
export type DemiplaneJournalTitleBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneJournalTitleBoolExp>>>;
  _not?: Maybe<DemiplaneJournalTitleBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneJournalTitleBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  journal?: Maybe<DemiplaneJournalBoolExp>;
  journal_id?: Maybe<BigintComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.journal_title" */
export enum DemiplaneJournalTitleConstraint {
  /** unique or primary key constraint */
  PkJournalTitleId = 'pk_journal_title_id'
}

/** input type for incrementing integer column in table "demiplane.journal_title" */
export type DemiplaneJournalTitleIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.journal_title" */
export type DemiplaneJournalTitleInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal?: Maybe<DemiplaneJournalObjRelInsertInput>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneJournalTitleMaxFields = {
  __typename?: 'demiplane_journal_title_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneJournalTitleMinFields = {
  __typename?: 'demiplane_journal_title_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.journal_title" */
export type DemiplaneJournalTitleMutationResponse = {
  __typename?: 'demiplane_journal_title_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneJournalTitle>;
};

/** input type for inserting object relation for remote table "demiplane.journal_title" */
export type DemiplaneJournalTitleObjRelInsertInput = {
  data: DemiplaneJournalTitleInsertInput;
  on_conflict?: Maybe<DemiplaneJournalTitleOnConflict>;
};

/** on conflict condition type for table "demiplane.journal_title" */
export type DemiplaneJournalTitleOnConflict = {
  constraint: DemiplaneJournalTitleConstraint;
  update_columns: Array<DemiplaneJournalTitleUpdateColumn>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};

/** ordering options when selecting data from "demiplane.journal_title" */
export type DemiplaneJournalTitleOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal?: Maybe<DemiplaneJournalOrderBy>;
  journal_id?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.journal_title" */
export type DemiplaneJournalTitlePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.journal_title" */
export enum DemiplaneJournalTitleSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.journal_title" */
export type DemiplaneJournalTitleSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneJournalTitleStddevFields = {
  __typename?: 'demiplane_journal_title_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneJournalTitleStddevPopFields = {
  __typename?: 'demiplane_journal_title_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneJournalTitleStddevSampFields = {
  __typename?: 'demiplane_journal_title_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneJournalTitleSumFields = {
  __typename?: 'demiplane_journal_title_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journal_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.journal_title" */
export enum DemiplaneJournalTitleUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneJournalTitleVarPopFields = {
  __typename?: 'demiplane_journal_title_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneJournalTitleVarSampFields = {
  __typename?: 'demiplane_journal_title_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneJournalTitleVarianceFields = {
  __typename?: 'demiplane_journal_title_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journal_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.journal_title" */
export type DemiplaneJournalTitleVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  journal_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.journal" */
export enum DemiplaneJournalUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneJournalVarPopFields = {
  __typename?: 'demiplane_journal_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.journal" */
export type DemiplaneJournalVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneJournalVarSampFields = {
  __typename?: 'demiplane_journal_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.journal" */
export type DemiplaneJournalVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneJournalVarianceFields = {
  __typename?: 'demiplane_journal_variance_fields';
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.journal" */
export type DemiplaneJournalVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingToken = {
  __typename?: 'demiplane_matchmaking_token';
  created_at: Scalars['timestamptz'];
  customer_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  origin: Scalars['String'];
  token: Scalars['String'];
};

/** aggregated selection of "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenAggregate = {
  __typename?: 'demiplane_matchmaking_token_aggregate';
  aggregate?: Maybe<DemiplaneMatchmakingTokenAggregateFields>;
  nodes: Array<DemiplaneMatchmakingToken>;
};

/** aggregate fields of "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenAggregateFields = {
  __typename?: 'demiplane_matchmaking_token_aggregate_fields';
  avg?: Maybe<DemiplaneMatchmakingTokenAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneMatchmakingTokenMaxFields>;
  min?: Maybe<DemiplaneMatchmakingTokenMinFields>;
  stddev?: Maybe<DemiplaneMatchmakingTokenStddevFields>;
  stddev_pop?: Maybe<DemiplaneMatchmakingTokenStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneMatchmakingTokenStddevSampFields>;
  sum?: Maybe<DemiplaneMatchmakingTokenSumFields>;
  var_pop?: Maybe<DemiplaneMatchmakingTokenVarPopFields>;
  var_samp?: Maybe<DemiplaneMatchmakingTokenVarSampFields>;
  variance?: Maybe<DemiplaneMatchmakingTokenVarianceFields>;
};


/** aggregate fields of "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneMatchmakingTokenSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenAggregateOrderBy = {
  avg?: Maybe<DemiplaneMatchmakingTokenAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneMatchmakingTokenMaxOrderBy>;
  min?: Maybe<DemiplaneMatchmakingTokenMinOrderBy>;
  stddev?: Maybe<DemiplaneMatchmakingTokenStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneMatchmakingTokenStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneMatchmakingTokenStddevSampOrderBy>;
  sum?: Maybe<DemiplaneMatchmakingTokenSumOrderBy>;
  var_pop?: Maybe<DemiplaneMatchmakingTokenVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneMatchmakingTokenVarSampOrderBy>;
  variance?: Maybe<DemiplaneMatchmakingTokenVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenArrRelInsertInput = {
  data: Array<DemiplaneMatchmakingTokenInsertInput>;
  on_conflict?: Maybe<DemiplaneMatchmakingTokenOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneMatchmakingTokenAvgFields = {
  __typename?: 'demiplane_matchmaking_token_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.matchmaking_token". All fields are combined with a logical 'AND'. */
export type DemiplaneMatchmakingTokenBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneMatchmakingTokenBoolExp>>>;
  _not?: Maybe<DemiplaneMatchmakingTokenBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneMatchmakingTokenBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  customer_name?: Maybe<StringComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  origin?: Maybe<StringComparisonExp>;
  token?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.matchmaking_token" */
export enum DemiplaneMatchmakingTokenConstraint {
  /** unique or primary key constraint */
  MatchmakingTokenPkey = 'matchmaking_token_pkey',
  /** unique or primary key constraint */
  MatchmakingTokenTokenOriginKey = 'matchmaking_token_token_origin_key'
}

/** input type for incrementing integer column in table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DemiplaneMatchmakingTokenMaxFields = {
  __typename?: 'demiplane_matchmaking_token_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  customer_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  origin?: Maybe<OrderBy>;
  token?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneMatchmakingTokenMinFields = {
  __typename?: 'demiplane_matchmaking_token_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  customer_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  origin?: Maybe<OrderBy>;
  token?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenMutationResponse = {
  __typename?: 'demiplane_matchmaking_token_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneMatchmakingToken>;
};

/** input type for inserting object relation for remote table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenObjRelInsertInput = {
  data: DemiplaneMatchmakingTokenInsertInput;
  on_conflict?: Maybe<DemiplaneMatchmakingTokenOnConflict>;
};

/** on conflict condition type for table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenOnConflict = {
  constraint: DemiplaneMatchmakingTokenConstraint;
  update_columns: Array<DemiplaneMatchmakingTokenUpdateColumn>;
  where?: Maybe<DemiplaneMatchmakingTokenBoolExp>;
};

/** ordering options when selecting data from "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenOrderBy = {
  created_at?: Maybe<OrderBy>;
  customer_name?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  origin?: Maybe<OrderBy>;
  token?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.matchmaking_token" */
export enum DemiplaneMatchmakingTokenSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneMatchmakingTokenStddevFields = {
  __typename?: 'demiplane_matchmaking_token_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneMatchmakingTokenStddevPopFields = {
  __typename?: 'demiplane_matchmaking_token_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneMatchmakingTokenStddevSampFields = {
  __typename?: 'demiplane_matchmaking_token_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneMatchmakingTokenSumFields = {
  __typename?: 'demiplane_matchmaking_token_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.matchmaking_token" */
export enum DemiplaneMatchmakingTokenUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  Token = 'token'
}

/** aggregate var_pop on columns */
export type DemiplaneMatchmakingTokenVarPopFields = {
  __typename?: 'demiplane_matchmaking_token_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneMatchmakingTokenVarSampFields = {
  __typename?: 'demiplane_matchmaking_token_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneMatchmakingTokenVarianceFields = {
  __typename?: 'demiplane_matchmaking_token_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.matchmaking_token" */
export type DemiplaneMatchmakingTokenVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.migrations" */
export type DemiplaneMigrations = {
  __typename?: 'demiplane_migrations';
  id: Scalars['Int'];
  name: Scalars['String'];
  timestamp: Scalars['bigint'];
};

/** aggregated selection of "demiplane.migrations" */
export type DemiplaneMigrationsAggregate = {
  __typename?: 'demiplane_migrations_aggregate';
  aggregate?: Maybe<DemiplaneMigrationsAggregateFields>;
  nodes: Array<DemiplaneMigrations>;
};

/** aggregate fields of "demiplane.migrations" */
export type DemiplaneMigrationsAggregateFields = {
  __typename?: 'demiplane_migrations_aggregate_fields';
  avg?: Maybe<DemiplaneMigrationsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneMigrationsMaxFields>;
  min?: Maybe<DemiplaneMigrationsMinFields>;
  stddev?: Maybe<DemiplaneMigrationsStddevFields>;
  stddev_pop?: Maybe<DemiplaneMigrationsStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneMigrationsStddevSampFields>;
  sum?: Maybe<DemiplaneMigrationsSumFields>;
  var_pop?: Maybe<DemiplaneMigrationsVarPopFields>;
  var_samp?: Maybe<DemiplaneMigrationsVarSampFields>;
  variance?: Maybe<DemiplaneMigrationsVarianceFields>;
};


/** aggregate fields of "demiplane.migrations" */
export type DemiplaneMigrationsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneMigrationsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.migrations" */
export type DemiplaneMigrationsAggregateOrderBy = {
  avg?: Maybe<DemiplaneMigrationsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneMigrationsMaxOrderBy>;
  min?: Maybe<DemiplaneMigrationsMinOrderBy>;
  stddev?: Maybe<DemiplaneMigrationsStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneMigrationsStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneMigrationsStddevSampOrderBy>;
  sum?: Maybe<DemiplaneMigrationsSumOrderBy>;
  var_pop?: Maybe<DemiplaneMigrationsVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneMigrationsVarSampOrderBy>;
  variance?: Maybe<DemiplaneMigrationsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.migrations" */
export type DemiplaneMigrationsArrRelInsertInput = {
  data: Array<DemiplaneMigrationsInsertInput>;
  on_conflict?: Maybe<DemiplaneMigrationsOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneMigrationsAvgFields = {
  __typename?: 'demiplane_migrations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsAvgOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.migrations". All fields are combined with a logical 'AND'. */
export type DemiplaneMigrationsBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneMigrationsBoolExp>>>;
  _not?: Maybe<DemiplaneMigrationsBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneMigrationsBoolExp>>>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  timestamp?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.migrations" */
export enum DemiplaneMigrationsConstraint {
  /** unique or primary key constraint */
  PkMigrationsId = 'pk_migrations_id'
}

/** input type for incrementing integer column in table "demiplane.migrations" */
export type DemiplaneMigrationsIncInput = {
  id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.migrations" */
export type DemiplaneMigrationsInsertInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneMigrationsMaxFields = {
  __typename?: 'demiplane_migrations_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsMaxOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneMigrationsMinFields = {
  __typename?: 'demiplane_migrations_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsMinOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.migrations" */
export type DemiplaneMigrationsMutationResponse = {
  __typename?: 'demiplane_migrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneMigrations>;
};

/** input type for inserting object relation for remote table "demiplane.migrations" */
export type DemiplaneMigrationsObjRelInsertInput = {
  data: DemiplaneMigrationsInsertInput;
  on_conflict?: Maybe<DemiplaneMigrationsOnConflict>;
};

/** on conflict condition type for table "demiplane.migrations" */
export type DemiplaneMigrationsOnConflict = {
  constraint: DemiplaneMigrationsConstraint;
  update_columns: Array<DemiplaneMigrationsUpdateColumn>;
  where?: Maybe<DemiplaneMigrationsBoolExp>;
};

/** ordering options when selecting data from "demiplane.migrations" */
export type DemiplaneMigrationsOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.migrations" */
export type DemiplaneMigrationsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "demiplane.migrations" */
export enum DemiplaneMigrationsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

/** input type for updating data in table "demiplane.migrations" */
export type DemiplaneMigrationsSetInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneMigrationsStddevFields = {
  __typename?: 'demiplane_migrations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsStddevOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneMigrationsStddevPopFields = {
  __typename?: 'demiplane_migrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneMigrationsStddevSampFields = {
  __typename?: 'demiplane_migrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneMigrationsSumFields = {
  __typename?: 'demiplane_migrations_sum_fields';
  id?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsSumOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.migrations" */
export enum DemiplaneMigrationsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

/** aggregate var_pop on columns */
export type DemiplaneMigrationsVarPopFields = {
  __typename?: 'demiplane_migrations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneMigrationsVarSampFields = {
  __typename?: 'demiplane_migrations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneMigrationsVarianceFields = {
  __typename?: 'demiplane_migrations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.migrations" */
export type DemiplaneMigrationsVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.notification_history" */
export type DemiplaneNotificationHistory = {
  __typename?: 'demiplane_notification_history';
  /** An object relationship */
  ad?: Maybe<DemiplaneAd>;
  ad_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  adventure?: Maybe<DemiplaneAdventure>;
  adventure_id?: Maybe<Scalars['bigint']>;
  content: Scalars['String'];
  created: Scalars['timestamptz'];
  heading: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['bigint'];
  os_id?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  submitor_id?: Maybe<Scalars['bigint']>;
  updated: Scalars['timestamptz'];
  url: Scalars['String'];
  /** An object relationship */
  user: DemiplaneUser;
  /** An object relationship */
  userBySubmitorId?: Maybe<DemiplaneUser>;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.notification_history" */
export type DemiplaneNotificationHistoryAggregate = {
  __typename?: 'demiplane_notification_history_aggregate';
  aggregate?: Maybe<DemiplaneNotificationHistoryAggregateFields>;
  nodes: Array<DemiplaneNotificationHistory>;
};

/** aggregate fields of "demiplane.notification_history" */
export type DemiplaneNotificationHistoryAggregateFields = {
  __typename?: 'demiplane_notification_history_aggregate_fields';
  avg?: Maybe<DemiplaneNotificationHistoryAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneNotificationHistoryMaxFields>;
  min?: Maybe<DemiplaneNotificationHistoryMinFields>;
  stddev?: Maybe<DemiplaneNotificationHistoryStddevFields>;
  stddev_pop?: Maybe<DemiplaneNotificationHistoryStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneNotificationHistoryStddevSampFields>;
  sum?: Maybe<DemiplaneNotificationHistorySumFields>;
  var_pop?: Maybe<DemiplaneNotificationHistoryVarPopFields>;
  var_samp?: Maybe<DemiplaneNotificationHistoryVarSampFields>;
  variance?: Maybe<DemiplaneNotificationHistoryVarianceFields>;
};


/** aggregate fields of "demiplane.notification_history" */
export type DemiplaneNotificationHistoryAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryAggregateOrderBy = {
  avg?: Maybe<DemiplaneNotificationHistoryAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneNotificationHistoryMaxOrderBy>;
  min?: Maybe<DemiplaneNotificationHistoryMinOrderBy>;
  stddev?: Maybe<DemiplaneNotificationHistoryStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneNotificationHistoryStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneNotificationHistoryStddevSampOrderBy>;
  sum?: Maybe<DemiplaneNotificationHistorySumOrderBy>;
  var_pop?: Maybe<DemiplaneNotificationHistoryVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneNotificationHistoryVarSampOrderBy>;
  variance?: Maybe<DemiplaneNotificationHistoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryArrRelInsertInput = {
  data: Array<DemiplaneNotificationHistoryInsertInput>;
  on_conflict?: Maybe<DemiplaneNotificationHistoryOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneNotificationHistoryAvgFields = {
  __typename?: 'demiplane_notification_history_avg_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  submitor_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryAvgOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.notification_history". All fields are combined with a logical 'AND'. */
export type DemiplaneNotificationHistoryBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneNotificationHistoryBoolExp>>>;
  _not?: Maybe<DemiplaneNotificationHistoryBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneNotificationHistoryBoolExp>>>;
  ad?: Maybe<DemiplaneAdBoolExp>;
  ad_id?: Maybe<BigintComparisonExp>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  content?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  heading?: Maybe<StringComparisonExp>;
  icon?: Maybe<StringComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  os_id?: Maybe<StringComparisonExp>;
  read?: Maybe<BooleanComparisonExp>;
  submitor_id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  url?: Maybe<StringComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  userBySubmitorId?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.notification_history" */
export enum DemiplaneNotificationHistoryConstraint {
  /** unique or primary key constraint */
  PkNotificationHistoryId = 'pk_notification_history_id'
}

/** input type for incrementing integer column in table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryIncInput = {
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  submitor_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryInsertInput = {
  ad?: Maybe<DemiplaneAdObjRelInsertInput>;
  ad_id?: Maybe<Scalars['bigint']>;
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  heading?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  os_id?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  submitor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  userBySubmitorId?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneNotificationHistoryMaxFields = {
  __typename?: 'demiplane_notification_history_max_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  heading?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  os_id?: Maybe<Scalars['String']>;
  submitor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryMaxOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  content?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  heading?: Maybe<OrderBy>;
  icon?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  os_id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneNotificationHistoryMinFields = {
  __typename?: 'demiplane_notification_history_min_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  heading?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  os_id?: Maybe<Scalars['String']>;
  submitor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryMinOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  content?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  heading?: Maybe<OrderBy>;
  icon?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  os_id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryMutationResponse = {
  __typename?: 'demiplane_notification_history_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneNotificationHistory>;
};

/** input type for inserting object relation for remote table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryObjRelInsertInput = {
  data: DemiplaneNotificationHistoryInsertInput;
  on_conflict?: Maybe<DemiplaneNotificationHistoryOnConflict>;
};

/** on conflict condition type for table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryOnConflict = {
  constraint: DemiplaneNotificationHistoryConstraint;
  update_columns: Array<DemiplaneNotificationHistoryUpdateColumn>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};

/** ordering options when selecting data from "demiplane.notification_history" */
export type DemiplaneNotificationHistoryOrderBy = {
  ad?: Maybe<DemiplaneAdOrderBy>;
  ad_id?: Maybe<OrderBy>;
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  content?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  heading?: Maybe<OrderBy>;
  icon?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  os_id?: Maybe<OrderBy>;
  read?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  userBySubmitorId?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.notification_history" */
export type DemiplaneNotificationHistoryPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.notification_history" */
export enum DemiplaneNotificationHistorySelectColumn {
  /** column name */
  AdId = 'ad_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Heading = 'heading',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  OsId = 'os_id',
  /** column name */
  Read = 'read',
  /** column name */
  SubmitorId = 'submitor_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.notification_history" */
export type DemiplaneNotificationHistorySetInput = {
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  heading?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  os_id?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  submitor_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneNotificationHistoryStddevFields = {
  __typename?: 'demiplane_notification_history_stddev_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  submitor_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryStddevOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneNotificationHistoryStddevPopFields = {
  __typename?: 'demiplane_notification_history_stddev_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  submitor_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryStddevPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneNotificationHistoryStddevSampFields = {
  __typename?: 'demiplane_notification_history_stddev_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  submitor_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryStddevSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneNotificationHistorySumFields = {
  __typename?: 'demiplane_notification_history_sum_fields';
  ad_id?: Maybe<Scalars['bigint']>;
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  submitor_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistorySumOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.notification_history" */
export enum DemiplaneNotificationHistoryUpdateColumn {
  /** column name */
  AdId = 'ad_id',
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Heading = 'heading',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  OsId = 'os_id',
  /** column name */
  Read = 'read',
  /** column name */
  SubmitorId = 'submitor_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneNotificationHistoryVarPopFields = {
  __typename?: 'demiplane_notification_history_var_pop_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  submitor_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryVarPopOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneNotificationHistoryVarSampFields = {
  __typename?: 'demiplane_notification_history_var_samp_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  submitor_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryVarSampOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneNotificationHistoryVarianceFields = {
  __typename?: 'demiplane_notification_history_variance_fields';
  ad_id?: Maybe<Scalars['Float']>;
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  submitor_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.notification_history" */
export type DemiplaneNotificationHistoryVarianceOrderBy = {
  ad_id?: Maybe<OrderBy>;
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  submitor_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.payment" */
export type DemiplanePayment = {
  __typename?: 'demiplane_payment';
  authorized: Scalars['Boolean'];
  captured: Scalars['Boolean'];
  charge_amount?: Maybe<Scalars['Int']>;
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  refunded: Scalars['Boolean'];
  /** An object relationship */
  session: DemiplaneSession;
  session_id: Scalars['bigint'];
  should_capture: Scalars['Boolean'];
  stripe_charge_id: Scalars['String'];
  tip_charge: Scalars['Boolean'];
  /** An object relationship */
  transaction_receipt?: Maybe<DemiplaneTransactionReceipt>;
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.payment" */
export type DemiplanePaymentAggregate = {
  __typename?: 'demiplane_payment_aggregate';
  aggregate?: Maybe<DemiplanePaymentAggregateFields>;
  nodes: Array<DemiplanePayment>;
};

/** aggregate fields of "demiplane.payment" */
export type DemiplanePaymentAggregateFields = {
  __typename?: 'demiplane_payment_aggregate_fields';
  avg?: Maybe<DemiplanePaymentAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplanePaymentMaxFields>;
  min?: Maybe<DemiplanePaymentMinFields>;
  stddev?: Maybe<DemiplanePaymentStddevFields>;
  stddev_pop?: Maybe<DemiplanePaymentStddevPopFields>;
  stddev_samp?: Maybe<DemiplanePaymentStddevSampFields>;
  sum?: Maybe<DemiplanePaymentSumFields>;
  var_pop?: Maybe<DemiplanePaymentVarPopFields>;
  var_samp?: Maybe<DemiplanePaymentVarSampFields>;
  variance?: Maybe<DemiplanePaymentVarianceFields>;
};


/** aggregate fields of "demiplane.payment" */
export type DemiplanePaymentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.payment" */
export type DemiplanePaymentAggregateOrderBy = {
  avg?: Maybe<DemiplanePaymentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplanePaymentMaxOrderBy>;
  min?: Maybe<DemiplanePaymentMinOrderBy>;
  stddev?: Maybe<DemiplanePaymentStddevOrderBy>;
  stddev_pop?: Maybe<DemiplanePaymentStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplanePaymentStddevSampOrderBy>;
  sum?: Maybe<DemiplanePaymentSumOrderBy>;
  var_pop?: Maybe<DemiplanePaymentVarPopOrderBy>;
  var_samp?: Maybe<DemiplanePaymentVarSampOrderBy>;
  variance?: Maybe<DemiplanePaymentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.payment" */
export type DemiplanePaymentArrRelInsertInput = {
  data: Array<DemiplanePaymentInsertInput>;
  on_conflict?: Maybe<DemiplanePaymentOnConflict>;
};

/** aggregate avg on columns */
export type DemiplanePaymentAvgFields = {
  __typename?: 'demiplane_payment_avg_fields';
  charge_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.payment" */
export type DemiplanePaymentAvgOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.payment". All fields are combined with a logical 'AND'. */
export type DemiplanePaymentBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplanePaymentBoolExp>>>;
  _not?: Maybe<DemiplanePaymentBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplanePaymentBoolExp>>>;
  authorized?: Maybe<BooleanComparisonExp>;
  captured?: Maybe<BooleanComparisonExp>;
  charge_amount?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  refunded?: Maybe<BooleanComparisonExp>;
  session?: Maybe<DemiplaneSessionBoolExp>;
  session_id?: Maybe<BigintComparisonExp>;
  should_capture?: Maybe<BooleanComparisonExp>;
  stripe_charge_id?: Maybe<StringComparisonExp>;
  tip_charge?: Maybe<BooleanComparisonExp>;
  transaction_receipt?: Maybe<DemiplaneTransactionReceiptBoolExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.payment" */
export enum DemiplanePaymentConstraint {
  /** unique or primary key constraint */
  PkPaymentId = 'pk_payment_id'
}

/** input type for incrementing integer column in table "demiplane.payment" */
export type DemiplanePaymentIncInput = {
  charge_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.payment" */
export type DemiplanePaymentInsertInput = {
  authorized?: Maybe<Scalars['Boolean']>;
  captured?: Maybe<Scalars['Boolean']>;
  charge_amount?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  refunded?: Maybe<Scalars['Boolean']>;
  session?: Maybe<DemiplaneSessionObjRelInsertInput>;
  session_id?: Maybe<Scalars['bigint']>;
  should_capture?: Maybe<Scalars['Boolean']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  tip_charge?: Maybe<Scalars['Boolean']>;
  transaction_receipt?: Maybe<DemiplaneTransactionReceiptObjRelInsertInput>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplanePaymentMaxFields = {
  __typename?: 'demiplane_payment_max_fields';
  charge_amount?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.payment" */
export type DemiplanePaymentMaxOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  stripe_charge_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplanePaymentMinFields = {
  __typename?: 'demiplane_payment_min_fields';
  charge_amount?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.payment" */
export type DemiplanePaymentMinOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  stripe_charge_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.payment" */
export type DemiplanePaymentMutationResponse = {
  __typename?: 'demiplane_payment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplanePayment>;
};

/** input type for inserting object relation for remote table "demiplane.payment" */
export type DemiplanePaymentObjRelInsertInput = {
  data: DemiplanePaymentInsertInput;
  on_conflict?: Maybe<DemiplanePaymentOnConflict>;
};

/** on conflict condition type for table "demiplane.payment" */
export type DemiplanePaymentOnConflict = {
  constraint: DemiplanePaymentConstraint;
  update_columns: Array<DemiplanePaymentUpdateColumn>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};

/** ordering options when selecting data from "demiplane.payment" */
export type DemiplanePaymentOrderBy = {
  authorized?: Maybe<OrderBy>;
  captured?: Maybe<OrderBy>;
  charge_amount?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  refunded?: Maybe<OrderBy>;
  session?: Maybe<DemiplaneSessionOrderBy>;
  session_id?: Maybe<OrderBy>;
  should_capture?: Maybe<OrderBy>;
  stripe_charge_id?: Maybe<OrderBy>;
  tip_charge?: Maybe<OrderBy>;
  transaction_receipt?: Maybe<DemiplaneTransactionReceiptOrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.payment" */
export type DemiplanePaymentPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.payment" */
export enum DemiplanePaymentSelectColumn {
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Captured = 'captured',
  /** column name */
  ChargeAmount = 'charge_amount',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Refunded = 'refunded',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  ShouldCapture = 'should_capture',
  /** column name */
  StripeChargeId = 'stripe_charge_id',
  /** column name */
  TipCharge = 'tip_charge',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.payment" */
export type DemiplanePaymentSetInput = {
  authorized?: Maybe<Scalars['Boolean']>;
  captured?: Maybe<Scalars['Boolean']>;
  charge_amount?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  refunded?: Maybe<Scalars['Boolean']>;
  session_id?: Maybe<Scalars['bigint']>;
  should_capture?: Maybe<Scalars['Boolean']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  tip_charge?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplanePaymentStddevFields = {
  __typename?: 'demiplane_payment_stddev_fields';
  charge_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.payment" */
export type DemiplanePaymentStddevOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplanePaymentStddevPopFields = {
  __typename?: 'demiplane_payment_stddev_pop_fields';
  charge_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.payment" */
export type DemiplanePaymentStddevPopOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplanePaymentStddevSampFields = {
  __typename?: 'demiplane_payment_stddev_samp_fields';
  charge_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.payment" */
export type DemiplanePaymentStddevSampOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplanePaymentSumFields = {
  __typename?: 'demiplane_payment_sum_fields';
  charge_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.payment" */
export type DemiplanePaymentSumOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.payment" */
export enum DemiplanePaymentUpdateColumn {
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Captured = 'captured',
  /** column name */
  ChargeAmount = 'charge_amount',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Refunded = 'refunded',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  ShouldCapture = 'should_capture',
  /** column name */
  StripeChargeId = 'stripe_charge_id',
  /** column name */
  TipCharge = 'tip_charge',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplanePaymentVarPopFields = {
  __typename?: 'demiplane_payment_var_pop_fields';
  charge_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.payment" */
export type DemiplanePaymentVarPopOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplanePaymentVarSampFields = {
  __typename?: 'demiplane_payment_var_samp_fields';
  charge_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.payment" */
export type DemiplanePaymentVarSampOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplanePaymentVarianceFields = {
  __typename?: 'demiplane_payment_variance_fields';
  charge_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.payment" */
export type DemiplanePaymentVarianceOrderBy = {
  charge_amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.player_award" */
export type DemiplanePlayerAward = {
  __typename?: 'demiplane_player_award';
  /** An object relationship */
  award_type: DemiplaneAwardType;
  award_type_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  read: Scalars['Boolean'];
  /** An object relationship */
  session: DemiplaneSession;
  /** An object relationship */
  sessionPlayerByVotedPlayerId: DemiplaneSessionPlayer;
  session_id: Scalars['bigint'];
  /** An object relationship */
  session_player: DemiplaneSessionPlayer;
  updated: Scalars['timestamptz'];
  voted_player_id: Scalars['bigint'];
  voting_player_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.player_award" */
export type DemiplanePlayerAwardAggregate = {
  __typename?: 'demiplane_player_award_aggregate';
  aggregate?: Maybe<DemiplanePlayerAwardAggregateFields>;
  nodes: Array<DemiplanePlayerAward>;
};

/** aggregate fields of "demiplane.player_award" */
export type DemiplanePlayerAwardAggregateFields = {
  __typename?: 'demiplane_player_award_aggregate_fields';
  avg?: Maybe<DemiplanePlayerAwardAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplanePlayerAwardMaxFields>;
  min?: Maybe<DemiplanePlayerAwardMinFields>;
  stddev?: Maybe<DemiplanePlayerAwardStddevFields>;
  stddev_pop?: Maybe<DemiplanePlayerAwardStddevPopFields>;
  stddev_samp?: Maybe<DemiplanePlayerAwardStddevSampFields>;
  sum?: Maybe<DemiplanePlayerAwardSumFields>;
  var_pop?: Maybe<DemiplanePlayerAwardVarPopFields>;
  var_samp?: Maybe<DemiplanePlayerAwardVarSampFields>;
  variance?: Maybe<DemiplanePlayerAwardVarianceFields>;
};


/** aggregate fields of "demiplane.player_award" */
export type DemiplanePlayerAwardAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.player_award" */
export type DemiplanePlayerAwardAggregateOrderBy = {
  avg?: Maybe<DemiplanePlayerAwardAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplanePlayerAwardMaxOrderBy>;
  min?: Maybe<DemiplanePlayerAwardMinOrderBy>;
  stddev?: Maybe<DemiplanePlayerAwardStddevOrderBy>;
  stddev_pop?: Maybe<DemiplanePlayerAwardStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplanePlayerAwardStddevSampOrderBy>;
  sum?: Maybe<DemiplanePlayerAwardSumOrderBy>;
  var_pop?: Maybe<DemiplanePlayerAwardVarPopOrderBy>;
  var_samp?: Maybe<DemiplanePlayerAwardVarSampOrderBy>;
  variance?: Maybe<DemiplanePlayerAwardVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.player_award" */
export type DemiplanePlayerAwardArrRelInsertInput = {
  data: Array<DemiplanePlayerAwardInsertInput>;
  on_conflict?: Maybe<DemiplanePlayerAwardOnConflict>;
};

/** aggregate avg on columns */
export type DemiplanePlayerAwardAvgFields = {
  __typename?: 'demiplane_player_award_avg_fields';
  award_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  voted_player_id?: Maybe<Scalars['Float']>;
  voting_player_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardAvgOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.player_award". All fields are combined with a logical 'AND'. */
export type DemiplanePlayerAwardBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplanePlayerAwardBoolExp>>>;
  _not?: Maybe<DemiplanePlayerAwardBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplanePlayerAwardBoolExp>>>;
  award_type?: Maybe<DemiplaneAwardTypeBoolExp>;
  award_type_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  read?: Maybe<BooleanComparisonExp>;
  session?: Maybe<DemiplaneSessionBoolExp>;
  sessionPlayerByVotedPlayerId?: Maybe<DemiplaneSessionPlayerBoolExp>;
  session_id?: Maybe<BigintComparisonExp>;
  session_player?: Maybe<DemiplaneSessionPlayerBoolExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  voted_player_id?: Maybe<BigintComparisonExp>;
  voting_player_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.player_award" */
export enum DemiplanePlayerAwardConstraint {
  /** unique or primary key constraint */
  PkPlayerAwardId = 'pk_player_award_id',
  /** unique or primary key constraint */
  UqPlayerAwardAwardTypeIdSessionIdVotingPlayerId = 'uq_player_award_award_type_id_session_id_voting_player_id'
}

/** input type for incrementing integer column in table "demiplane.player_award" */
export type DemiplanePlayerAwardIncInput = {
  award_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  voted_player_id?: Maybe<Scalars['bigint']>;
  voting_player_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.player_award" */
export type DemiplanePlayerAwardInsertInput = {
  award_type?: Maybe<DemiplaneAwardTypeObjRelInsertInput>;
  award_type_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  session?: Maybe<DemiplaneSessionObjRelInsertInput>;
  sessionPlayerByVotedPlayerId?: Maybe<DemiplaneSessionPlayerObjRelInsertInput>;
  session_id?: Maybe<Scalars['bigint']>;
  session_player?: Maybe<DemiplaneSessionPlayerObjRelInsertInput>;
  updated?: Maybe<Scalars['timestamptz']>;
  voted_player_id?: Maybe<Scalars['bigint']>;
  voting_player_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplanePlayerAwardMaxFields = {
  __typename?: 'demiplane_player_award_max_fields';
  award_type_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  voted_player_id?: Maybe<Scalars['bigint']>;
  voting_player_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardMaxOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplanePlayerAwardMinFields = {
  __typename?: 'demiplane_player_award_min_fields';
  award_type_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  voted_player_id?: Maybe<Scalars['bigint']>;
  voting_player_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardMinOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.player_award" */
export type DemiplanePlayerAwardMutationResponse = {
  __typename?: 'demiplane_player_award_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplanePlayerAward>;
};

/** input type for inserting object relation for remote table "demiplane.player_award" */
export type DemiplanePlayerAwardObjRelInsertInput = {
  data: DemiplanePlayerAwardInsertInput;
  on_conflict?: Maybe<DemiplanePlayerAwardOnConflict>;
};

/** on conflict condition type for table "demiplane.player_award" */
export type DemiplanePlayerAwardOnConflict = {
  constraint: DemiplanePlayerAwardConstraint;
  update_columns: Array<DemiplanePlayerAwardUpdateColumn>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};

/** ordering options when selecting data from "demiplane.player_award" */
export type DemiplanePlayerAwardOrderBy = {
  award_type?: Maybe<DemiplaneAwardTypeOrderBy>;
  award_type_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  read?: Maybe<OrderBy>;
  session?: Maybe<DemiplaneSessionOrderBy>;
  sessionPlayerByVotedPlayerId?: Maybe<DemiplaneSessionPlayerOrderBy>;
  session_id?: Maybe<OrderBy>;
  session_player?: Maybe<DemiplaneSessionPlayerOrderBy>;
  updated?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.player_award" */
export type DemiplanePlayerAwardPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.player_award" */
export enum DemiplanePlayerAwardSelectColumn {
  /** column name */
  AwardTypeId = 'award_type_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  VotedPlayerId = 'voted_player_id',
  /** column name */
  VotingPlayerId = 'voting_player_id'
}

/** input type for updating data in table "demiplane.player_award" */
export type DemiplanePlayerAwardSetInput = {
  award_type_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  voted_player_id?: Maybe<Scalars['bigint']>;
  voting_player_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplanePlayerAwardStddevFields = {
  __typename?: 'demiplane_player_award_stddev_fields';
  award_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  voted_player_id?: Maybe<Scalars['Float']>;
  voting_player_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardStddevOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplanePlayerAwardStddevPopFields = {
  __typename?: 'demiplane_player_award_stddev_pop_fields';
  award_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  voted_player_id?: Maybe<Scalars['Float']>;
  voting_player_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardStddevPopOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplanePlayerAwardStddevSampFields = {
  __typename?: 'demiplane_player_award_stddev_samp_fields';
  award_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  voted_player_id?: Maybe<Scalars['Float']>;
  voting_player_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardStddevSampOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplanePlayerAwardSumFields = {
  __typename?: 'demiplane_player_award_sum_fields';
  award_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  voted_player_id?: Maybe<Scalars['bigint']>;
  voting_player_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardSumOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.player_award" */
export enum DemiplanePlayerAwardUpdateColumn {
  /** column name */
  AwardTypeId = 'award_type_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  VotedPlayerId = 'voted_player_id',
  /** column name */
  VotingPlayerId = 'voting_player_id'
}

/** aggregate var_pop on columns */
export type DemiplanePlayerAwardVarPopFields = {
  __typename?: 'demiplane_player_award_var_pop_fields';
  award_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  voted_player_id?: Maybe<Scalars['Float']>;
  voting_player_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardVarPopOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplanePlayerAwardVarSampFields = {
  __typename?: 'demiplane_player_award_var_samp_fields';
  award_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  voted_player_id?: Maybe<Scalars['Float']>;
  voting_player_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardVarSampOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplanePlayerAwardVarianceFields = {
  __typename?: 'demiplane_player_award_variance_fields';
  award_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  voted_player_id?: Maybe<Scalars['Float']>;
  voting_player_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.player_award" */
export type DemiplanePlayerAwardVarianceOrderBy = {
  award_type_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  voted_player_id?: Maybe<OrderBy>;
  voting_player_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.player_rating" */
export type DemiplanePlayerRating = {
  __typename?: 'demiplane_player_rating';
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  rated: Scalars['Boolean'];
  rated_player_id: Scalars['bigint'];
  rating: Scalars['numeric'];
  rating_player_id: Scalars['bigint'];
  /** An object relationship */
  session: DemiplaneSession;
  /** An object relationship */
  sessionPlayerByRatedPlayerId: DemiplaneSessionPlayer;
  session_id: Scalars['bigint'];
  /** An object relationship */
  session_player: DemiplaneSessionPlayer;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.player_rating" */
export type DemiplanePlayerRatingAggregate = {
  __typename?: 'demiplane_player_rating_aggregate';
  aggregate?: Maybe<DemiplanePlayerRatingAggregateFields>;
  nodes: Array<DemiplanePlayerRating>;
};

/** aggregate fields of "demiplane.player_rating" */
export type DemiplanePlayerRatingAggregateFields = {
  __typename?: 'demiplane_player_rating_aggregate_fields';
  avg?: Maybe<DemiplanePlayerRatingAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplanePlayerRatingMaxFields>;
  min?: Maybe<DemiplanePlayerRatingMinFields>;
  stddev?: Maybe<DemiplanePlayerRatingStddevFields>;
  stddev_pop?: Maybe<DemiplanePlayerRatingStddevPopFields>;
  stddev_samp?: Maybe<DemiplanePlayerRatingStddevSampFields>;
  sum?: Maybe<DemiplanePlayerRatingSumFields>;
  var_pop?: Maybe<DemiplanePlayerRatingVarPopFields>;
  var_samp?: Maybe<DemiplanePlayerRatingVarSampFields>;
  variance?: Maybe<DemiplanePlayerRatingVarianceFields>;
};


/** aggregate fields of "demiplane.player_rating" */
export type DemiplanePlayerRatingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.player_rating" */
export type DemiplanePlayerRatingAggregateOrderBy = {
  avg?: Maybe<DemiplanePlayerRatingAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplanePlayerRatingMaxOrderBy>;
  min?: Maybe<DemiplanePlayerRatingMinOrderBy>;
  stddev?: Maybe<DemiplanePlayerRatingStddevOrderBy>;
  stddev_pop?: Maybe<DemiplanePlayerRatingStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplanePlayerRatingStddevSampOrderBy>;
  sum?: Maybe<DemiplanePlayerRatingSumOrderBy>;
  var_pop?: Maybe<DemiplanePlayerRatingVarPopOrderBy>;
  var_samp?: Maybe<DemiplanePlayerRatingVarSampOrderBy>;
  variance?: Maybe<DemiplanePlayerRatingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.player_rating" */
export type DemiplanePlayerRatingArrRelInsertInput = {
  data: Array<DemiplanePlayerRatingInsertInput>;
  on_conflict?: Maybe<DemiplanePlayerRatingOnConflict>;
};

/** aggregate avg on columns */
export type DemiplanePlayerRatingAvgFields = {
  __typename?: 'demiplane_player_rating_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingAvgOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.player_rating". All fields are combined with a logical 'AND'. */
export type DemiplanePlayerRatingBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplanePlayerRatingBoolExp>>>;
  _not?: Maybe<DemiplanePlayerRatingBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplanePlayerRatingBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  rated?: Maybe<BooleanComparisonExp>;
  rated_player_id?: Maybe<BigintComparisonExp>;
  rating?: Maybe<NumericComparisonExp>;
  rating_player_id?: Maybe<BigintComparisonExp>;
  session?: Maybe<DemiplaneSessionBoolExp>;
  sessionPlayerByRatedPlayerId?: Maybe<DemiplaneSessionPlayerBoolExp>;
  session_id?: Maybe<BigintComparisonExp>;
  session_player?: Maybe<DemiplaneSessionPlayerBoolExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.player_rating" */
export enum DemiplanePlayerRatingConstraint {
  /** unique or primary key constraint */
  PkPlayerRatingId = 'pk_player_rating_id'
}

/** input type for incrementing integer column in table "demiplane.player_rating" */
export type DemiplanePlayerRatingIncInput = {
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.player_rating" */
export type DemiplanePlayerRatingInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated?: Maybe<Scalars['Boolean']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session?: Maybe<DemiplaneSessionObjRelInsertInput>;
  sessionPlayerByRatedPlayerId?: Maybe<DemiplaneSessionPlayerObjRelInsertInput>;
  session_id?: Maybe<Scalars['bigint']>;
  session_player?: Maybe<DemiplaneSessionPlayerObjRelInsertInput>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplanePlayerRatingMaxFields = {
  __typename?: 'demiplane_player_rating_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplanePlayerRatingMinFields = {
  __typename?: 'demiplane_player_rating_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.player_rating" */
export type DemiplanePlayerRatingMutationResponse = {
  __typename?: 'demiplane_player_rating_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplanePlayerRating>;
};

/** input type for inserting object relation for remote table "demiplane.player_rating" */
export type DemiplanePlayerRatingObjRelInsertInput = {
  data: DemiplanePlayerRatingInsertInput;
  on_conflict?: Maybe<DemiplanePlayerRatingOnConflict>;
};

/** on conflict condition type for table "demiplane.player_rating" */
export type DemiplanePlayerRatingOnConflict = {
  constraint: DemiplanePlayerRatingConstraint;
  update_columns: Array<DemiplanePlayerRatingUpdateColumn>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};

/** ordering options when selecting data from "demiplane.player_rating" */
export type DemiplanePlayerRatingOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  rated?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session?: Maybe<DemiplaneSessionOrderBy>;
  sessionPlayerByRatedPlayerId?: Maybe<DemiplaneSessionPlayerOrderBy>;
  session_id?: Maybe<OrderBy>;
  session_player?: Maybe<DemiplaneSessionPlayerOrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.player_rating" */
export type DemiplanePlayerRatingPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.player_rating" */
export enum DemiplanePlayerRatingSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Rated = 'rated',
  /** column name */
  RatedPlayerId = 'rated_player_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingPlayerId = 'rating_player_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.player_rating" */
export type DemiplanePlayerRatingSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  rated?: Maybe<Scalars['Boolean']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplanePlayerRatingStddevFields = {
  __typename?: 'demiplane_player_rating_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingStddevOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplanePlayerRatingStddevPopFields = {
  __typename?: 'demiplane_player_rating_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplanePlayerRatingStddevSampFields = {
  __typename?: 'demiplane_player_rating_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplanePlayerRatingSumFields = {
  __typename?: 'demiplane_player_rating_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rated_player_id?: Maybe<Scalars['bigint']>;
  rating?: Maybe<Scalars['numeric']>;
  rating_player_id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingSumOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.player_rating" */
export enum DemiplanePlayerRatingUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Rated = 'rated',
  /** column name */
  RatedPlayerId = 'rated_player_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingPlayerId = 'rating_player_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplanePlayerRatingVarPopFields = {
  __typename?: 'demiplane_player_rating_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplanePlayerRatingVarSampFields = {
  __typename?: 'demiplane_player_rating_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplanePlayerRatingVarianceFields = {
  __typename?: 'demiplane_player_rating_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rated_player_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  rating_player_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.player_rating" */
export type DemiplanePlayerRatingVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  rated_player_id?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  rating_player_id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.pronoun" */
export type DemiplanePronoun = {
  __typename?: 'demiplane_pronoun';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  sort_order: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<DemiplaneUser>;
  /** An aggregated array relationship */
  users_aggregate: DemiplaneUserAggregate;
};


/** columns and relationships of "demiplane.pronoun" */
export type DemiplanePronounUsersArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};


/** columns and relationships of "demiplane.pronoun" */
export type DemiplanePronounUsersAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};

/** aggregated selection of "demiplane.pronoun" */
export type DemiplanePronounAggregate = {
  __typename?: 'demiplane_pronoun_aggregate';
  aggregate?: Maybe<DemiplanePronounAggregateFields>;
  nodes: Array<DemiplanePronoun>;
};

/** aggregate fields of "demiplane.pronoun" */
export type DemiplanePronounAggregateFields = {
  __typename?: 'demiplane_pronoun_aggregate_fields';
  avg?: Maybe<DemiplanePronounAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplanePronounMaxFields>;
  min?: Maybe<DemiplanePronounMinFields>;
  stddev?: Maybe<DemiplanePronounStddevFields>;
  stddev_pop?: Maybe<DemiplanePronounStddevPopFields>;
  stddev_samp?: Maybe<DemiplanePronounStddevSampFields>;
  sum?: Maybe<DemiplanePronounSumFields>;
  var_pop?: Maybe<DemiplanePronounVarPopFields>;
  var_samp?: Maybe<DemiplanePronounVarSampFields>;
  variance?: Maybe<DemiplanePronounVarianceFields>;
};


/** aggregate fields of "demiplane.pronoun" */
export type DemiplanePronounAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplanePronounSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.pronoun" */
export type DemiplanePronounAggregateOrderBy = {
  avg?: Maybe<DemiplanePronounAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplanePronounMaxOrderBy>;
  min?: Maybe<DemiplanePronounMinOrderBy>;
  stddev?: Maybe<DemiplanePronounStddevOrderBy>;
  stddev_pop?: Maybe<DemiplanePronounStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplanePronounStddevSampOrderBy>;
  sum?: Maybe<DemiplanePronounSumOrderBy>;
  var_pop?: Maybe<DemiplanePronounVarPopOrderBy>;
  var_samp?: Maybe<DemiplanePronounVarSampOrderBy>;
  variance?: Maybe<DemiplanePronounVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.pronoun" */
export type DemiplanePronounArrRelInsertInput = {
  data: Array<DemiplanePronounInsertInput>;
  on_conflict?: Maybe<DemiplanePronounOnConflict>;
};

/** aggregate avg on columns */
export type DemiplanePronounAvgFields = {
  __typename?: 'demiplane_pronoun_avg_fields';
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.pronoun" */
export type DemiplanePronounAvgOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.pronoun". All fields are combined with a logical 'AND'. */
export type DemiplanePronounBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplanePronounBoolExp>>>;
  _not?: Maybe<DemiplanePronounBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplanePronounBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  sort_order?: Maybe<IntComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<DemiplaneUserBoolExp>;
};

/** unique or primary key constraints on table "demiplane.pronoun" */
export enum DemiplanePronounConstraint {
  /** unique or primary key constraint */
  PronounNameKey = 'pronoun_name_key',
  /** unique or primary key constraint */
  PronounPkey = 'pronoun_pkey',
  /** unique or primary key constraint */
  PronounSortOrderKey = 'pronoun_sort_order_key'
}

/** input type for incrementing integer column in table "demiplane.pronoun" */
export type DemiplanePronounIncInput = {
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.pronoun" */
export type DemiplanePronounInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<DemiplaneUserArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplanePronounMaxFields = {
  __typename?: 'demiplane_pronoun_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.pronoun" */
export type DemiplanePronounMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplanePronounMinFields = {
  __typename?: 'demiplane_pronoun_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.pronoun" */
export type DemiplanePronounMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.pronoun" */
export type DemiplanePronounMutationResponse = {
  __typename?: 'demiplane_pronoun_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplanePronoun>;
};

/** input type for inserting object relation for remote table "demiplane.pronoun" */
export type DemiplanePronounObjRelInsertInput = {
  data: DemiplanePronounInsertInput;
  on_conflict?: Maybe<DemiplanePronounOnConflict>;
};

/** on conflict condition type for table "demiplane.pronoun" */
export type DemiplanePronounOnConflict = {
  constraint: DemiplanePronounConstraint;
  update_columns: Array<DemiplanePronounUpdateColumn>;
  where?: Maybe<DemiplanePronounBoolExp>;
};

/** ordering options when selecting data from "demiplane.pronoun" */
export type DemiplanePronounOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  users_aggregate?: Maybe<DemiplaneUserAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.pronoun" */
export type DemiplanePronounPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.pronoun" */
export enum DemiplanePronounSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "demiplane.pronoun" */
export type DemiplanePronounSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplanePronounStddevFields = {
  __typename?: 'demiplane_pronoun_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.pronoun" */
export type DemiplanePronounStddevOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplanePronounStddevPopFields = {
  __typename?: 'demiplane_pronoun_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.pronoun" */
export type DemiplanePronounStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplanePronounStddevSampFields = {
  __typename?: 'demiplane_pronoun_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.pronoun" */
export type DemiplanePronounStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplanePronounSumFields = {
  __typename?: 'demiplane_pronoun_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.pronoun" */
export type DemiplanePronounSumOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.pronoun" */
export enum DemiplanePronounUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type DemiplanePronounVarPopFields = {
  __typename?: 'demiplane_pronoun_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.pronoun" */
export type DemiplanePronounVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplanePronounVarSampFields = {
  __typename?: 'demiplane_pronoun_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.pronoun" */
export type DemiplanePronounVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplanePronounVarianceFields = {
  __typename?: 'demiplane_pronoun_variance_fields';
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.pronoun" */
export type DemiplanePronounVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.region" */
export type DemiplaneRegion = {
  __typename?: 'demiplane_region';
  active: Scalars['Boolean'];
  code2: Scalars['String'];
  /** An object relationship */
  country?: Maybe<DemiplaneCountry>;
  country_id?: Maybe<Scalars['Int']>;
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An array relationship */
  transaction_receipts: Array<DemiplaneTransactionReceipt>;
  /** An aggregated array relationship */
  transaction_receipts_aggregate: DemiplaneTransactionReceiptAggregate;
  updated: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<DemiplaneUser>;
  /** An aggregated array relationship */
  users_aggregate: DemiplaneUserAggregate;
};


/** columns and relationships of "demiplane.region" */
export type DemiplaneRegionTransactionReceiptsArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** columns and relationships of "demiplane.region" */
export type DemiplaneRegionTransactionReceiptsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** columns and relationships of "demiplane.region" */
export type DemiplaneRegionUsersArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};


/** columns and relationships of "demiplane.region" */
export type DemiplaneRegionUsersAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};

/** aggregated selection of "demiplane.region" */
export type DemiplaneRegionAggregate = {
  __typename?: 'demiplane_region_aggregate';
  aggregate?: Maybe<DemiplaneRegionAggregateFields>;
  nodes: Array<DemiplaneRegion>;
};

/** aggregate fields of "demiplane.region" */
export type DemiplaneRegionAggregateFields = {
  __typename?: 'demiplane_region_aggregate_fields';
  avg?: Maybe<DemiplaneRegionAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneRegionMaxFields>;
  min?: Maybe<DemiplaneRegionMinFields>;
  stddev?: Maybe<DemiplaneRegionStddevFields>;
  stddev_pop?: Maybe<DemiplaneRegionStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneRegionStddevSampFields>;
  sum?: Maybe<DemiplaneRegionSumFields>;
  var_pop?: Maybe<DemiplaneRegionVarPopFields>;
  var_samp?: Maybe<DemiplaneRegionVarSampFields>;
  variance?: Maybe<DemiplaneRegionVarianceFields>;
};


/** aggregate fields of "demiplane.region" */
export type DemiplaneRegionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneRegionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.region" */
export type DemiplaneRegionAggregateOrderBy = {
  avg?: Maybe<DemiplaneRegionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneRegionMaxOrderBy>;
  min?: Maybe<DemiplaneRegionMinOrderBy>;
  stddev?: Maybe<DemiplaneRegionStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneRegionStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneRegionStddevSampOrderBy>;
  sum?: Maybe<DemiplaneRegionSumOrderBy>;
  var_pop?: Maybe<DemiplaneRegionVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneRegionVarSampOrderBy>;
  variance?: Maybe<DemiplaneRegionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.region" */
export type DemiplaneRegionArrRelInsertInput = {
  data: Array<DemiplaneRegionInsertInput>;
  on_conflict?: Maybe<DemiplaneRegionOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneRegionAvgFields = {
  __typename?: 'demiplane_region_avg_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.region" */
export type DemiplaneRegionAvgOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.region". All fields are combined with a logical 'AND'. */
export type DemiplaneRegionBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneRegionBoolExp>>>;
  _not?: Maybe<DemiplaneRegionBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneRegionBoolExp>>>;
  active?: Maybe<BooleanComparisonExp>;
  code2?: Maybe<StringComparisonExp>;
  country?: Maybe<DemiplaneCountryBoolExp>;
  country_id?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  transaction_receipts?: Maybe<DemiplaneTransactionReceiptBoolExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  users?: Maybe<DemiplaneUserBoolExp>;
};

/** unique or primary key constraints on table "demiplane.region" */
export enum DemiplaneRegionConstraint {
  /** unique or primary key constraint */
  PkRegionId = 'pk_region_id'
}

/** input type for incrementing integer column in table "demiplane.region" */
export type DemiplaneRegionIncInput = {
  country_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.region" */
export type DemiplaneRegionInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  code2?: Maybe<Scalars['String']>;
  country?: Maybe<DemiplaneCountryObjRelInsertInput>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  transaction_receipts?: Maybe<DemiplaneTransactionReceiptArrRelInsertInput>;
  updated?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<DemiplaneUserArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneRegionMaxFields = {
  __typename?: 'demiplane_region_max_fields';
  code2?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.region" */
export type DemiplaneRegionMaxOrderBy = {
  code2?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneRegionMinFields = {
  __typename?: 'demiplane_region_min_fields';
  code2?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.region" */
export type DemiplaneRegionMinOrderBy = {
  code2?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.region" */
export type DemiplaneRegionMutationResponse = {
  __typename?: 'demiplane_region_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneRegion>;
};

/** input type for inserting object relation for remote table "demiplane.region" */
export type DemiplaneRegionObjRelInsertInput = {
  data: DemiplaneRegionInsertInput;
  on_conflict?: Maybe<DemiplaneRegionOnConflict>;
};

/** on conflict condition type for table "demiplane.region" */
export type DemiplaneRegionOnConflict = {
  constraint: DemiplaneRegionConstraint;
  update_columns: Array<DemiplaneRegionUpdateColumn>;
  where?: Maybe<DemiplaneRegionBoolExp>;
};

/** ordering options when selecting data from "demiplane.region" */
export type DemiplaneRegionOrderBy = {
  active?: Maybe<OrderBy>;
  code2?: Maybe<OrderBy>;
  country?: Maybe<DemiplaneCountryOrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  transaction_receipts_aggregate?: Maybe<DemiplaneTransactionReceiptAggregateOrderBy>;
  updated?: Maybe<OrderBy>;
  users_aggregate?: Maybe<DemiplaneUserAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.region" */
export type DemiplaneRegionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.region" */
export enum DemiplaneRegionSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code2 = 'code2',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.region" */
export type DemiplaneRegionSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  code2?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneRegionStddevFields = {
  __typename?: 'demiplane_region_stddev_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.region" */
export type DemiplaneRegionStddevOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneRegionStddevPopFields = {
  __typename?: 'demiplane_region_stddev_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.region" */
export type DemiplaneRegionStddevPopOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneRegionStddevSampFields = {
  __typename?: 'demiplane_region_stddev_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.region" */
export type DemiplaneRegionStddevSampOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneRegionSumFields = {
  __typename?: 'demiplane_region_sum_fields';
  country_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.region" */
export type DemiplaneRegionSumOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.region" */
export enum DemiplaneRegionUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Code2 = 'code2',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneRegionVarPopFields = {
  __typename?: 'demiplane_region_var_pop_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.region" */
export type DemiplaneRegionVarPopOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneRegionVarSampFields = {
  __typename?: 'demiplane_region_var_samp_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.region" */
export type DemiplaneRegionVarSampOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneRegionVarianceFields = {
  __typename?: 'demiplane_region_variance_fields';
  country_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.region" */
export type DemiplaneRegionVarianceOrderBy = {
  country_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.role" */
export type DemiplaneRole = {
  __typename?: 'demiplane_role';
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  role_name: Scalars['String'];
  updated: Scalars['timestamptz'];
  /** An array relationship */
  user_adventure_roles: Array<DemiplaneUserAdventureRole>;
  /** An aggregated array relationship */
  user_adventure_roles_aggregate: DemiplaneUserAdventureRoleAggregate;
  /** An array relationship */
  user_roles: Array<DemiplaneUserRole>;
  /** An aggregated array relationship */
  user_roles_aggregate: DemiplaneUserRoleAggregate;
};


/** columns and relationships of "demiplane.role" */
export type DemiplaneRoleUserAdventureRolesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** columns and relationships of "demiplane.role" */
export type DemiplaneRoleUserAdventureRolesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** columns and relationships of "demiplane.role" */
export type DemiplaneRoleUserRolesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};


/** columns and relationships of "demiplane.role" */
export type DemiplaneRoleUserRolesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};

/** aggregated selection of "demiplane.role" */
export type DemiplaneRoleAggregate = {
  __typename?: 'demiplane_role_aggregate';
  aggregate?: Maybe<DemiplaneRoleAggregateFields>;
  nodes: Array<DemiplaneRole>;
};

/** aggregate fields of "demiplane.role" */
export type DemiplaneRoleAggregateFields = {
  __typename?: 'demiplane_role_aggregate_fields';
  avg?: Maybe<DemiplaneRoleAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneRoleMaxFields>;
  min?: Maybe<DemiplaneRoleMinFields>;
  stddev?: Maybe<DemiplaneRoleStddevFields>;
  stddev_pop?: Maybe<DemiplaneRoleStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneRoleStddevSampFields>;
  sum?: Maybe<DemiplaneRoleSumFields>;
  var_pop?: Maybe<DemiplaneRoleVarPopFields>;
  var_samp?: Maybe<DemiplaneRoleVarSampFields>;
  variance?: Maybe<DemiplaneRoleVarianceFields>;
};


/** aggregate fields of "demiplane.role" */
export type DemiplaneRoleAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneRoleSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.role" */
export type DemiplaneRoleAggregateOrderBy = {
  avg?: Maybe<DemiplaneRoleAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneRoleMaxOrderBy>;
  min?: Maybe<DemiplaneRoleMinOrderBy>;
  stddev?: Maybe<DemiplaneRoleStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneRoleStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneRoleStddevSampOrderBy>;
  sum?: Maybe<DemiplaneRoleSumOrderBy>;
  var_pop?: Maybe<DemiplaneRoleVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneRoleVarSampOrderBy>;
  variance?: Maybe<DemiplaneRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.role" */
export type DemiplaneRoleArrRelInsertInput = {
  data: Array<DemiplaneRoleInsertInput>;
  on_conflict?: Maybe<DemiplaneRoleOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneRoleAvgFields = {
  __typename?: 'demiplane_role_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.role" */
export type DemiplaneRoleAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.role". All fields are combined with a logical 'AND'. */
export type DemiplaneRoleBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneRoleBoolExp>>>;
  _not?: Maybe<DemiplaneRoleBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneRoleBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  role_name?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user_adventure_roles?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
  user_roles?: Maybe<DemiplaneUserRoleBoolExp>;
};

/** unique or primary key constraints on table "demiplane.role" */
export enum DemiplaneRoleConstraint {
  /** unique or primary key constraint */
  PkRoleId = 'pk_role_id'
}

/** input type for incrementing integer column in table "demiplane.role" */
export type DemiplaneRoleIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.role" */
export type DemiplaneRoleInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role_name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_adventure_roles?: Maybe<DemiplaneUserAdventureRoleArrRelInsertInput>;
  user_roles?: Maybe<DemiplaneUserRoleArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneRoleMaxFields = {
  __typename?: 'demiplane_role_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role_name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.role" */
export type DemiplaneRoleMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneRoleMinFields = {
  __typename?: 'demiplane_role_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role_name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.role" */
export type DemiplaneRoleMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.role" */
export type DemiplaneRoleMutationResponse = {
  __typename?: 'demiplane_role_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneRole>;
};

/** input type for inserting object relation for remote table "demiplane.role" */
export type DemiplaneRoleObjRelInsertInput = {
  data: DemiplaneRoleInsertInput;
  on_conflict?: Maybe<DemiplaneRoleOnConflict>;
};

/** on conflict condition type for table "demiplane.role" */
export type DemiplaneRoleOnConflict = {
  constraint: DemiplaneRoleConstraint;
  update_columns: Array<DemiplaneRoleUpdateColumn>;
  where?: Maybe<DemiplaneRoleBoolExp>;
};

/** ordering options when selecting data from "demiplane.role" */
export type DemiplaneRoleOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_adventure_roles_aggregate?: Maybe<DemiplaneUserAdventureRoleAggregateOrderBy>;
  user_roles_aggregate?: Maybe<DemiplaneUserRoleAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.role" */
export type DemiplaneRolePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.role" */
export enum DemiplaneRoleSelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.role" */
export type DemiplaneRoleSetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role_name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneRoleStddevFields = {
  __typename?: 'demiplane_role_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.role" */
export type DemiplaneRoleStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneRoleStddevPopFields = {
  __typename?: 'demiplane_role_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.role" */
export type DemiplaneRoleStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneRoleStddevSampFields = {
  __typename?: 'demiplane_role_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.role" */
export type DemiplaneRoleStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneRoleSumFields = {
  __typename?: 'demiplane_role_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.role" */
export type DemiplaneRoleSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.role" */
export enum DemiplaneRoleUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneRoleVarPopFields = {
  __typename?: 'demiplane_role_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.role" */
export type DemiplaneRoleVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneRoleVarSampFields = {
  __typename?: 'demiplane_role_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.role" */
export type DemiplaneRoleVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneRoleVarianceFields = {
  __typename?: 'demiplane_role_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.role" */
export type DemiplaneRoleVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.session" */
export type DemiplaneSession = {
  __typename?: 'demiplane_session';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  cancelled: Scalars['Boolean'];
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  completed: Scalars['Boolean'];
  cost: Scalars['Int'];
  created: Scalars['timestamptz'];
  end_date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  episode_attendances: Array<DemiplaneEpisodeAttendance>;
  /** An aggregated array relationship */
  episode_attendances_aggregate: DemiplaneEpisodeAttendanceAggregate;
  /** An array relationship */
  gm_ratings: Array<DemiplaneGmRating>;
  /** An aggregated array relationship */
  gm_ratings_aggregate: DemiplaneGmRatingAggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  journals: Array<DemiplaneJournal>;
  /** An aggregated array relationship */
  journals_aggregate: DemiplaneJournalAggregate;
  live: Scalars['Boolean'];
  open: Scalars['Boolean'];
  open_date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  payments: Array<DemiplanePayment>;
  /** An aggregated array relationship */
  payments_aggregate: DemiplanePaymentAggregate;
  /** An array relationship */
  player_awards: Array<DemiplanePlayerAward>;
  /** An aggregated array relationship */
  player_awards_aggregate: DemiplanePlayerAwardAggregate;
  /** An array relationship */
  player_ratings: Array<DemiplanePlayerRating>;
  /** An aggregated array relationship */
  player_ratings_aggregate: DemiplanePlayerRatingAggregate;
  scheduled_date: Scalars['timestamptz'];
  /** An array relationship */
  session_players: Array<DemiplaneSessionPlayer>;
  /** An aggregated array relationship */
  session_players_aggregate: DemiplaneSessionPlayerAggregate;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionEpisodeAttendancesArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionEpisodeAttendancesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionGmRatingsArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionGmRatingsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionJournalsArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalOrderBy>>;
  where?: Maybe<DemiplaneJournalBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionJournalsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalOrderBy>>;
  where?: Maybe<DemiplaneJournalBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionPaymentsArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionPaymentsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionPlayerAwardsArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionPlayerAwardsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionPlayerRatingsArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionPlayerRatingsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionSessionPlayersArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};


/** columns and relationships of "demiplane.session" */
export type DemiplaneSessionSessionPlayersAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};

/** aggregated selection of "demiplane.session" */
export type DemiplaneSessionAggregate = {
  __typename?: 'demiplane_session_aggregate';
  aggregate?: Maybe<DemiplaneSessionAggregateFields>;
  nodes: Array<DemiplaneSession>;
};

/** aggregate fields of "demiplane.session" */
export type DemiplaneSessionAggregateFields = {
  __typename?: 'demiplane_session_aggregate_fields';
  avg?: Maybe<DemiplaneSessionAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneSessionMaxFields>;
  min?: Maybe<DemiplaneSessionMinFields>;
  stddev?: Maybe<DemiplaneSessionStddevFields>;
  stddev_pop?: Maybe<DemiplaneSessionStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneSessionStddevSampFields>;
  sum?: Maybe<DemiplaneSessionSumFields>;
  var_pop?: Maybe<DemiplaneSessionVarPopFields>;
  var_samp?: Maybe<DemiplaneSessionVarSampFields>;
  variance?: Maybe<DemiplaneSessionVarianceFields>;
};


/** aggregate fields of "demiplane.session" */
export type DemiplaneSessionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneSessionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.session" */
export type DemiplaneSessionAggregateOrderBy = {
  avg?: Maybe<DemiplaneSessionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneSessionMaxOrderBy>;
  min?: Maybe<DemiplaneSessionMinOrderBy>;
  stddev?: Maybe<DemiplaneSessionStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneSessionStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneSessionStddevSampOrderBy>;
  sum?: Maybe<DemiplaneSessionSumOrderBy>;
  var_pop?: Maybe<DemiplaneSessionVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneSessionVarSampOrderBy>;
  variance?: Maybe<DemiplaneSessionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.session" */
export type DemiplaneSessionArrRelInsertInput = {
  data: Array<DemiplaneSessionInsertInput>;
  on_conflict?: Maybe<DemiplaneSessionOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneSessionAvgFields = {
  __typename?: 'demiplane_session_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.session" */
export type DemiplaneSessionAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.session". All fields are combined with a logical 'AND'. */
export type DemiplaneSessionBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneSessionBoolExp>>>;
  _not?: Maybe<DemiplaneSessionBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneSessionBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  cancelled?: Maybe<BooleanComparisonExp>;
  cancelled_date?: Maybe<TimestamptzComparisonExp>;
  completed?: Maybe<BooleanComparisonExp>;
  cost?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  end_date?: Maybe<TimestamptzComparisonExp>;
  episode_attendances?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
  gm_ratings?: Maybe<DemiplaneGmRatingBoolExp>;
  id?: Maybe<BigintComparisonExp>;
  journals?: Maybe<DemiplaneJournalBoolExp>;
  live?: Maybe<BooleanComparisonExp>;
  open?: Maybe<BooleanComparisonExp>;
  open_date?: Maybe<TimestamptzComparisonExp>;
  payments?: Maybe<DemiplanePaymentBoolExp>;
  player_awards?: Maybe<DemiplanePlayerAwardBoolExp>;
  player_ratings?: Maybe<DemiplanePlayerRatingBoolExp>;
  scheduled_date?: Maybe<TimestamptzComparisonExp>;
  session_players?: Maybe<DemiplaneSessionPlayerBoolExp>;
  start_date?: Maybe<TimestamptzComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.session" */
export enum DemiplaneSessionConstraint {
  /** unique or primary key constraint */
  PkSessionId = 'pk_session_id'
}

/** input type for incrementing integer column in table "demiplane.session" */
export type DemiplaneSessionIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.session" */
export type DemiplaneSessionInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  completed?: Maybe<Scalars['Boolean']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  episode_attendances?: Maybe<DemiplaneEpisodeAttendanceArrRelInsertInput>;
  gm_ratings?: Maybe<DemiplaneGmRatingArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  journals?: Maybe<DemiplaneJournalArrRelInsertInput>;
  live?: Maybe<Scalars['Boolean']>;
  open?: Maybe<Scalars['Boolean']>;
  open_date?: Maybe<Scalars['timestamptz']>;
  payments?: Maybe<DemiplanePaymentArrRelInsertInput>;
  player_awards?: Maybe<DemiplanePlayerAwardArrRelInsertInput>;
  player_ratings?: Maybe<DemiplanePlayerRatingArrRelInsertInput>;
  scheduled_date?: Maybe<Scalars['timestamptz']>;
  session_players?: Maybe<DemiplaneSessionPlayerArrRelInsertInput>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneSessionMaxFields = {
  __typename?: 'demiplane_session_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  open_date?: Maybe<Scalars['timestamptz']>;
  scheduled_date?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.session" */
export type DemiplaneSessionMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cancelled_date?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  end_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_date?: Maybe<OrderBy>;
  scheduled_date?: Maybe<OrderBy>;
  start_date?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneSessionMinFields = {
  __typename?: 'demiplane_session_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  open_date?: Maybe<Scalars['timestamptz']>;
  scheduled_date?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.session" */
export type DemiplaneSessionMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cancelled_date?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  end_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  open_date?: Maybe<OrderBy>;
  scheduled_date?: Maybe<OrderBy>;
  start_date?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.session" */
export type DemiplaneSessionMutationResponse = {
  __typename?: 'demiplane_session_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneSession>;
};

/** input type for inserting object relation for remote table "demiplane.session" */
export type DemiplaneSessionObjRelInsertInput = {
  data: DemiplaneSessionInsertInput;
  on_conflict?: Maybe<DemiplaneSessionOnConflict>;
};

/** on conflict condition type for table "demiplane.session" */
export type DemiplaneSessionOnConflict = {
  constraint: DemiplaneSessionConstraint;
  update_columns: Array<DemiplaneSessionUpdateColumn>;
  where?: Maybe<DemiplaneSessionBoolExp>;
};

/** ordering options when selecting data from "demiplane.session" */
export type DemiplaneSessionOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  cancelled?: Maybe<OrderBy>;
  cancelled_date?: Maybe<OrderBy>;
  completed?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  end_date?: Maybe<OrderBy>;
  episode_attendances_aggregate?: Maybe<DemiplaneEpisodeAttendanceAggregateOrderBy>;
  gm_ratings_aggregate?: Maybe<DemiplaneGmRatingAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  journals_aggregate?: Maybe<DemiplaneJournalAggregateOrderBy>;
  live?: Maybe<OrderBy>;
  open?: Maybe<OrderBy>;
  open_date?: Maybe<OrderBy>;
  payments_aggregate?: Maybe<DemiplanePaymentAggregateOrderBy>;
  player_awards_aggregate?: Maybe<DemiplanePlayerAwardAggregateOrderBy>;
  player_ratings_aggregate?: Maybe<DemiplanePlayerRatingAggregateOrderBy>;
  scheduled_date?: Maybe<OrderBy>;
  session_players_aggregate?: Maybe<DemiplaneSessionPlayerAggregateOrderBy>;
  start_date?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.session" */
export type DemiplaneSessionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayer = {
  __typename?: 'demiplane_session_player';
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  completed_rating_flow: Scalars['Boolean'];
  created: Scalars['timestamptz'];
  /** An array relationship */
  gmRatingsByRatedPlayerId: Array<DemiplaneGmRating>;
  /** An aggregated array relationship */
  gmRatingsByRatedPlayerId_aggregate: DemiplaneGmRatingAggregate;
  gm_fee?: Maybe<Scalars['Int']>;
  /** An array relationship */
  gm_ratings: Array<DemiplaneGmRating>;
  /** An aggregated array relationship */
  gm_ratings_aggregate: DemiplaneGmRatingAggregate;
  id: Scalars['bigint'];
  is_gm: Scalars['Boolean'];
  join_date?: Maybe<Scalars['timestamptz']>;
  joined_session: Scalars['Boolean'];
  left_date?: Maybe<Scalars['timestamptz']>;
  left_session: Scalars['Boolean'];
  /** An object relationship */
  payment?: Maybe<DemiplanePayment>;
  /** An array relationship */
  playerAwardsByVotedPlayerId: Array<DemiplanePlayerAward>;
  /** An aggregated array relationship */
  playerAwardsByVotedPlayerId_aggregate: DemiplanePlayerAwardAggregate;
  /** An array relationship */
  playerRatingsByRatedPlayerId: Array<DemiplanePlayerRating>;
  /** An aggregated array relationship */
  playerRatingsByRatedPlayerId_aggregate: DemiplanePlayerRatingAggregate;
  /** An array relationship */
  player_awards: Array<DemiplanePlayerAward>;
  /** An aggregated array relationship */
  player_awards_aggregate: DemiplanePlayerAwardAggregate;
  /** An array relationship */
  player_ratings: Array<DemiplanePlayerRating>;
  /** An aggregated array relationship */
  player_ratings_aggregate: DemiplanePlayerRatingAggregate;
  /** An object relationship */
  session: DemiplaneSession;
  session_id: Scalars['bigint'];
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerGmRatingsByRatedPlayerIdArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerGmRatingsByRatedPlayerIdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerGmRatingsArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerGmRatingsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerAwardsByVotedPlayerIdArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerAwardsByVotedPlayerIdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerRatingsByRatedPlayerIdArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerRatingsByRatedPlayerIdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerAwardsArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerAwardsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerRatingsArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** columns and relationships of "demiplane.session_player" */
export type DemiplaneSessionPlayerPlayerRatingsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};

/** aggregated selection of "demiplane.session_player" */
export type DemiplaneSessionPlayerAggregate = {
  __typename?: 'demiplane_session_player_aggregate';
  aggregate?: Maybe<DemiplaneSessionPlayerAggregateFields>;
  nodes: Array<DemiplaneSessionPlayer>;
};

/** aggregate fields of "demiplane.session_player" */
export type DemiplaneSessionPlayerAggregateFields = {
  __typename?: 'demiplane_session_player_aggregate_fields';
  avg?: Maybe<DemiplaneSessionPlayerAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneSessionPlayerMaxFields>;
  min?: Maybe<DemiplaneSessionPlayerMinFields>;
  stddev?: Maybe<DemiplaneSessionPlayerStddevFields>;
  stddev_pop?: Maybe<DemiplaneSessionPlayerStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneSessionPlayerStddevSampFields>;
  sum?: Maybe<DemiplaneSessionPlayerSumFields>;
  var_pop?: Maybe<DemiplaneSessionPlayerVarPopFields>;
  var_samp?: Maybe<DemiplaneSessionPlayerVarSampFields>;
  variance?: Maybe<DemiplaneSessionPlayerVarianceFields>;
};


/** aggregate fields of "demiplane.session_player" */
export type DemiplaneSessionPlayerAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.session_player" */
export type DemiplaneSessionPlayerAggregateOrderBy = {
  avg?: Maybe<DemiplaneSessionPlayerAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneSessionPlayerMaxOrderBy>;
  min?: Maybe<DemiplaneSessionPlayerMinOrderBy>;
  stddev?: Maybe<DemiplaneSessionPlayerStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneSessionPlayerStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneSessionPlayerStddevSampOrderBy>;
  sum?: Maybe<DemiplaneSessionPlayerSumOrderBy>;
  var_pop?: Maybe<DemiplaneSessionPlayerVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneSessionPlayerVarSampOrderBy>;
  variance?: Maybe<DemiplaneSessionPlayerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.session_player" */
export type DemiplaneSessionPlayerArrRelInsertInput = {
  data: Array<DemiplaneSessionPlayerInsertInput>;
  on_conflict?: Maybe<DemiplaneSessionPlayerOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneSessionPlayerAvgFields = {
  __typename?: 'demiplane_session_player_avg_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerAvgOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.session_player". All fields are combined with a logical 'AND'. */
export type DemiplaneSessionPlayerBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneSessionPlayerBoolExp>>>;
  _not?: Maybe<DemiplaneSessionPlayerBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneSessionPlayerBoolExp>>>;
  combined_tax_rate?: Maybe<NumericComparisonExp>;
  completed_rating_flow?: Maybe<BooleanComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  gmRatingsByRatedPlayerId?: Maybe<DemiplaneGmRatingBoolExp>;
  gm_fee?: Maybe<IntComparisonExp>;
  gm_ratings?: Maybe<DemiplaneGmRatingBoolExp>;
  id?: Maybe<BigintComparisonExp>;
  is_gm?: Maybe<BooleanComparisonExp>;
  join_date?: Maybe<TimestamptzComparisonExp>;
  joined_session?: Maybe<BooleanComparisonExp>;
  left_date?: Maybe<TimestamptzComparisonExp>;
  left_session?: Maybe<BooleanComparisonExp>;
  payment?: Maybe<DemiplanePaymentBoolExp>;
  playerAwardsByVotedPlayerId?: Maybe<DemiplanePlayerAwardBoolExp>;
  playerRatingsByRatedPlayerId?: Maybe<DemiplanePlayerRatingBoolExp>;
  player_awards?: Maybe<DemiplanePlayerAwardBoolExp>;
  player_ratings?: Maybe<DemiplanePlayerRatingBoolExp>;
  session?: Maybe<DemiplaneSessionBoolExp>;
  session_id?: Maybe<BigintComparisonExp>;
  tax_amount?: Maybe<IntComparisonExp>;
  tip_amount?: Maybe<IntComparisonExp>;
  total_amount?: Maybe<IntComparisonExp>;
  transaction_fee?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.session_player" */
export enum DemiplaneSessionPlayerConstraint {
  /** unique or primary key constraint */
  PkSessionPlayerId = 'pk_session_player_id',
  /** unique or primary key constraint */
  UqSessionPlayerSessionIdUserId = 'uq_session_player_session_id_user_id'
}

/** input type for incrementing integer column in table "demiplane.session_player" */
export type DemiplaneSessionPlayerIncInput = {
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.session_player" */
export type DemiplaneSessionPlayerInsertInput = {
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  completed_rating_flow?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  gmRatingsByRatedPlayerId?: Maybe<DemiplaneGmRatingArrRelInsertInput>;
  gm_fee?: Maybe<Scalars['Int']>;
  gm_ratings?: Maybe<DemiplaneGmRatingArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  is_gm?: Maybe<Scalars['Boolean']>;
  join_date?: Maybe<Scalars['timestamptz']>;
  joined_session?: Maybe<Scalars['Boolean']>;
  left_date?: Maybe<Scalars['timestamptz']>;
  left_session?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<DemiplanePaymentObjRelInsertInput>;
  playerAwardsByVotedPlayerId?: Maybe<DemiplanePlayerAwardArrRelInsertInput>;
  playerRatingsByRatedPlayerId?: Maybe<DemiplanePlayerRatingArrRelInsertInput>;
  player_awards?: Maybe<DemiplanePlayerAwardArrRelInsertInput>;
  player_ratings?: Maybe<DemiplanePlayerRatingArrRelInsertInput>;
  session?: Maybe<DemiplaneSessionObjRelInsertInput>;
  session_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneSessionPlayerMaxFields = {
  __typename?: 'demiplane_session_player_max_fields';
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  created?: Maybe<Scalars['timestamptz']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  join_date?: Maybe<Scalars['timestamptz']>;
  left_date?: Maybe<Scalars['timestamptz']>;
  session_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerMaxOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  join_date?: Maybe<OrderBy>;
  left_date?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneSessionPlayerMinFields = {
  __typename?: 'demiplane_session_player_min_fields';
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  created?: Maybe<Scalars['timestamptz']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  join_date?: Maybe<Scalars['timestamptz']>;
  left_date?: Maybe<Scalars['timestamptz']>;
  session_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerMinOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  join_date?: Maybe<OrderBy>;
  left_date?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.session_player" */
export type DemiplaneSessionPlayerMutationResponse = {
  __typename?: 'demiplane_session_player_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneSessionPlayer>;
};

/** input type for inserting object relation for remote table "demiplane.session_player" */
export type DemiplaneSessionPlayerObjRelInsertInput = {
  data: DemiplaneSessionPlayerInsertInput;
  on_conflict?: Maybe<DemiplaneSessionPlayerOnConflict>;
};

/** on conflict condition type for table "demiplane.session_player" */
export type DemiplaneSessionPlayerOnConflict = {
  constraint: DemiplaneSessionPlayerConstraint;
  update_columns: Array<DemiplaneSessionPlayerUpdateColumn>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};

/** ordering options when selecting data from "demiplane.session_player" */
export type DemiplaneSessionPlayerOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  completed_rating_flow?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  gmRatingsByRatedPlayerId_aggregate?: Maybe<DemiplaneGmRatingAggregateOrderBy>;
  gm_fee?: Maybe<OrderBy>;
  gm_ratings_aggregate?: Maybe<DemiplaneGmRatingAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  is_gm?: Maybe<OrderBy>;
  join_date?: Maybe<OrderBy>;
  joined_session?: Maybe<OrderBy>;
  left_date?: Maybe<OrderBy>;
  left_session?: Maybe<OrderBy>;
  payment?: Maybe<DemiplanePaymentOrderBy>;
  playerAwardsByVotedPlayerId_aggregate?: Maybe<DemiplanePlayerAwardAggregateOrderBy>;
  playerRatingsByRatedPlayerId_aggregate?: Maybe<DemiplanePlayerRatingAggregateOrderBy>;
  player_awards_aggregate?: Maybe<DemiplanePlayerAwardAggregateOrderBy>;
  player_ratings_aggregate?: Maybe<DemiplanePlayerRatingAggregateOrderBy>;
  session?: Maybe<DemiplaneSessionOrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.session_player" */
export type DemiplaneSessionPlayerPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.session_player" */
export enum DemiplaneSessionPlayerSelectColumn {
  /** column name */
  CombinedTaxRate = 'combined_tax_rate',
  /** column name */
  CompletedRatingFlow = 'completed_rating_flow',
  /** column name */
  Created = 'created',
  /** column name */
  GmFee = 'gm_fee',
  /** column name */
  Id = 'id',
  /** column name */
  IsGm = 'is_gm',
  /** column name */
  JoinDate = 'join_date',
  /** column name */
  JoinedSession = 'joined_session',
  /** column name */
  LeftDate = 'left_date',
  /** column name */
  LeftSession = 'left_session',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  TaxAmount = 'tax_amount',
  /** column name */
  TipAmount = 'tip_amount',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TransactionFee = 'transaction_fee',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.session_player" */
export type DemiplaneSessionPlayerSetInput = {
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  completed_rating_flow?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  is_gm?: Maybe<Scalars['Boolean']>;
  join_date?: Maybe<Scalars['timestamptz']>;
  joined_session?: Maybe<Scalars['Boolean']>;
  left_date?: Maybe<Scalars['timestamptz']>;
  left_session?: Maybe<Scalars['Boolean']>;
  session_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneSessionPlayerStddevFields = {
  __typename?: 'demiplane_session_player_stddev_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerStddevOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneSessionPlayerStddevPopFields = {
  __typename?: 'demiplane_session_player_stddev_pop_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerStddevPopOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneSessionPlayerStddevSampFields = {
  __typename?: 'demiplane_session_player_stddev_samp_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerStddevSampOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneSessionPlayerSumFields = {
  __typename?: 'demiplane_session_player_sum_fields';
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  session_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerSumOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.session_player" */
export enum DemiplaneSessionPlayerUpdateColumn {
  /** column name */
  CombinedTaxRate = 'combined_tax_rate',
  /** column name */
  CompletedRatingFlow = 'completed_rating_flow',
  /** column name */
  Created = 'created',
  /** column name */
  GmFee = 'gm_fee',
  /** column name */
  Id = 'id',
  /** column name */
  IsGm = 'is_gm',
  /** column name */
  JoinDate = 'join_date',
  /** column name */
  JoinedSession = 'joined_session',
  /** column name */
  LeftDate = 'left_date',
  /** column name */
  LeftSession = 'left_session',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  TaxAmount = 'tax_amount',
  /** column name */
  TipAmount = 'tip_amount',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TransactionFee = 'transaction_fee',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneSessionPlayerVarPopFields = {
  __typename?: 'demiplane_session_player_var_pop_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerVarPopOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneSessionPlayerVarSampFields = {
  __typename?: 'demiplane_session_player_var_samp_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerVarSampOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneSessionPlayerVarianceFields = {
  __typename?: 'demiplane_session_player_variance_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.session_player" */
export type DemiplaneSessionPlayerVarianceOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  session_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.session" */
export enum DemiplaneSessionSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CancelledDate = 'cancelled_date',
  /** column name */
  Completed = 'completed',
  /** column name */
  Cost = 'cost',
  /** column name */
  Created = 'created',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Live = 'live',
  /** column name */
  Open = 'open',
  /** column name */
  OpenDate = 'open_date',
  /** column name */
  ScheduledDate = 'scheduled_date',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.session" */
export type DemiplaneSessionSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelled_date?: Maybe<Scalars['timestamptz']>;
  completed?: Maybe<Scalars['Boolean']>;
  cost?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  live?: Maybe<Scalars['Boolean']>;
  open?: Maybe<Scalars['Boolean']>;
  open_date?: Maybe<Scalars['timestamptz']>;
  scheduled_date?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneSessionStddevFields = {
  __typename?: 'demiplane_session_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.session" */
export type DemiplaneSessionStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneSessionStddevPopFields = {
  __typename?: 'demiplane_session_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.session" */
export type DemiplaneSessionStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneSessionStddevSampFields = {
  __typename?: 'demiplane_session_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.session" */
export type DemiplaneSessionStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneSessionSumFields = {
  __typename?: 'demiplane_session_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.session" */
export type DemiplaneSessionSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.session" */
export enum DemiplaneSessionUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CancelledDate = 'cancelled_date',
  /** column name */
  Completed = 'completed',
  /** column name */
  Cost = 'cost',
  /** column name */
  Created = 'created',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Live = 'live',
  /** column name */
  Open = 'open',
  /** column name */
  OpenDate = 'open_date',
  /** column name */
  ScheduledDate = 'scheduled_date',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneSessionVarPopFields = {
  __typename?: 'demiplane_session_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.session" */
export type DemiplaneSessionVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneSessionVarSampFields = {
  __typename?: 'demiplane_session_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.session" */
export type DemiplaneSessionVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneSessionVarianceFields = {
  __typename?: 'demiplane_session_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.session" */
export type DemiplaneSessionVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  cost?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.snippet" */
export type DemiplaneSnippet = {
  __typename?: 'demiplane_snippet';
  content: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.snippet" */
export type DemiplaneSnippetAggregate = {
  __typename?: 'demiplane_snippet_aggregate';
  aggregate?: Maybe<DemiplaneSnippetAggregateFields>;
  nodes: Array<DemiplaneSnippet>;
};

/** aggregate fields of "demiplane.snippet" */
export type DemiplaneSnippetAggregateFields = {
  __typename?: 'demiplane_snippet_aggregate_fields';
  avg?: Maybe<DemiplaneSnippetAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneSnippetMaxFields>;
  min?: Maybe<DemiplaneSnippetMinFields>;
  stddev?: Maybe<DemiplaneSnippetStddevFields>;
  stddev_pop?: Maybe<DemiplaneSnippetStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneSnippetStddevSampFields>;
  sum?: Maybe<DemiplaneSnippetSumFields>;
  var_pop?: Maybe<DemiplaneSnippetVarPopFields>;
  var_samp?: Maybe<DemiplaneSnippetVarSampFields>;
  variance?: Maybe<DemiplaneSnippetVarianceFields>;
};


/** aggregate fields of "demiplane.snippet" */
export type DemiplaneSnippetAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneSnippetSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.snippet" */
export type DemiplaneSnippetAggregateOrderBy = {
  avg?: Maybe<DemiplaneSnippetAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneSnippetMaxOrderBy>;
  min?: Maybe<DemiplaneSnippetMinOrderBy>;
  stddev?: Maybe<DemiplaneSnippetStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneSnippetStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneSnippetStddevSampOrderBy>;
  sum?: Maybe<DemiplaneSnippetSumOrderBy>;
  var_pop?: Maybe<DemiplaneSnippetVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneSnippetVarSampOrderBy>;
  variance?: Maybe<DemiplaneSnippetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.snippet" */
export type DemiplaneSnippetArrRelInsertInput = {
  data: Array<DemiplaneSnippetInsertInput>;
  on_conflict?: Maybe<DemiplaneSnippetOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneSnippetAvgFields = {
  __typename?: 'demiplane_snippet_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.snippet". All fields are combined with a logical 'AND'. */
export type DemiplaneSnippetBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneSnippetBoolExp>>>;
  _not?: Maybe<DemiplaneSnippetBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneSnippetBoolExp>>>;
  content?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.snippet" */
export enum DemiplaneSnippetConstraint {
  /** unique or primary key constraint */
  PkSnippetId = 'pk_snippet_id'
}

/** input type for incrementing integer column in table "demiplane.snippet" */
export type DemiplaneSnippetIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.snippet" */
export type DemiplaneSnippetInsertInput = {
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneSnippetMaxFields = {
  __typename?: 'demiplane_snippet_max_fields';
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetMaxOrderBy = {
  content?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneSnippetMinFields = {
  __typename?: 'demiplane_snippet_min_fields';
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetMinOrderBy = {
  content?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.snippet" */
export type DemiplaneSnippetMutationResponse = {
  __typename?: 'demiplane_snippet_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneSnippet>;
};

/** input type for inserting object relation for remote table "demiplane.snippet" */
export type DemiplaneSnippetObjRelInsertInput = {
  data: DemiplaneSnippetInsertInput;
  on_conflict?: Maybe<DemiplaneSnippetOnConflict>;
};

/** on conflict condition type for table "demiplane.snippet" */
export type DemiplaneSnippetOnConflict = {
  constraint: DemiplaneSnippetConstraint;
  update_columns: Array<DemiplaneSnippetUpdateColumn>;
  where?: Maybe<DemiplaneSnippetBoolExp>;
};

/** ordering options when selecting data from "demiplane.snippet" */
export type DemiplaneSnippetOrderBy = {
  content?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.snippet" */
export type DemiplaneSnippetPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.snippet" */
export enum DemiplaneSnippetSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.snippet" */
export type DemiplaneSnippetSetInput = {
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneSnippetStddevFields = {
  __typename?: 'demiplane_snippet_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneSnippetStddevPopFields = {
  __typename?: 'demiplane_snippet_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneSnippetStddevSampFields = {
  __typename?: 'demiplane_snippet_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneSnippetSumFields = {
  __typename?: 'demiplane_snippet_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.snippet" */
export enum DemiplaneSnippetUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneSnippetVarPopFields = {
  __typename?: 'demiplane_snippet_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneSnippetVarSampFields = {
  __typename?: 'demiplane_snippet_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneSnippetVarianceFields = {
  __typename?: 'demiplane_snippet_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.snippet" */
export type DemiplaneSnippetVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.task" */
export type DemiplaneTask = {
  __typename?: 'demiplane_task';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  /** An object relationship */
  adventurePlayerByCompletedBy?: Maybe<DemiplaneAdventurePlayer>;
  /** An object relationship */
  adventurePlayerByCreatedBy: DemiplaneAdventurePlayer;
  adventure_id: Scalars['bigint'];
  /** An object relationship */
  adventure_player?: Maybe<DemiplaneAdventurePlayer>;
  completed: Scalars['Boolean'];
  completed_by?: Maybe<Scalars['bigint']>;
  completed_date?: Maybe<Scalars['timestamptz']>;
  created: Scalars['timestamptz'];
  created_by: Scalars['bigint'];
  declined: Scalars['Boolean'];
  declined_by?: Maybe<Scalars['bigint']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  sort_order: Scalars['Int'];
  super: Scalars['Boolean'];
  /** An array relationship */
  task_read_statuses: Array<DemiplaneTaskReadStatus>;
  /** An aggregated array relationship */
  task_read_statuses_aggregate: DemiplaneTaskReadStatusAggregate;
  title: Scalars['String'];
  updated: Scalars['timestamptz'];
};


/** columns and relationships of "demiplane.task" */
export type DemiplaneTaskTaskReadStatusesArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};


/** columns and relationships of "demiplane.task" */
export type DemiplaneTaskTaskReadStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};

/** aggregated selection of "demiplane.task" */
export type DemiplaneTaskAggregate = {
  __typename?: 'demiplane_task_aggregate';
  aggregate?: Maybe<DemiplaneTaskAggregateFields>;
  nodes: Array<DemiplaneTask>;
};

/** aggregate fields of "demiplane.task" */
export type DemiplaneTaskAggregateFields = {
  __typename?: 'demiplane_task_aggregate_fields';
  avg?: Maybe<DemiplaneTaskAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneTaskMaxFields>;
  min?: Maybe<DemiplaneTaskMinFields>;
  stddev?: Maybe<DemiplaneTaskStddevFields>;
  stddev_pop?: Maybe<DemiplaneTaskStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneTaskStddevSampFields>;
  sum?: Maybe<DemiplaneTaskSumFields>;
  var_pop?: Maybe<DemiplaneTaskVarPopFields>;
  var_samp?: Maybe<DemiplaneTaskVarSampFields>;
  variance?: Maybe<DemiplaneTaskVarianceFields>;
};


/** aggregate fields of "demiplane.task" */
export type DemiplaneTaskAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.task" */
export type DemiplaneTaskAggregateOrderBy = {
  avg?: Maybe<DemiplaneTaskAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneTaskMaxOrderBy>;
  min?: Maybe<DemiplaneTaskMinOrderBy>;
  stddev?: Maybe<DemiplaneTaskStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneTaskStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneTaskStddevSampOrderBy>;
  sum?: Maybe<DemiplaneTaskSumOrderBy>;
  var_pop?: Maybe<DemiplaneTaskVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneTaskVarSampOrderBy>;
  variance?: Maybe<DemiplaneTaskVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.task" */
export type DemiplaneTaskArrRelInsertInput = {
  data: Array<DemiplaneTaskInsertInput>;
  on_conflict?: Maybe<DemiplaneTaskOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneTaskAvgFields = {
  __typename?: 'demiplane_task_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  declined_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.task" */
export type DemiplaneTaskAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.task". All fields are combined with a logical 'AND'. */
export type DemiplaneTaskBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneTaskBoolExp>>>;
  _not?: Maybe<DemiplaneTaskBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneTaskBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventurePlayerByCompletedBy?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventurePlayerByCreatedBy?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  completed?: Maybe<BooleanComparisonExp>;
  completed_by?: Maybe<BigintComparisonExp>;
  completed_date?: Maybe<TimestamptzComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  created_by?: Maybe<BigintComparisonExp>;
  declined?: Maybe<BooleanComparisonExp>;
  declined_by?: Maybe<BigintComparisonExp>;
  declined_date?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  sort_order?: Maybe<IntComparisonExp>;
  super?: Maybe<BooleanComparisonExp>;
  task_read_statuses?: Maybe<DemiplaneTaskReadStatusBoolExp>;
  title?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.task" */
export enum DemiplaneTaskConstraint {
  /** unique or primary key constraint */
  PkTaskId = 'pk_task_id'
}

/** input type for incrementing integer column in table "demiplane.task" */
export type DemiplaneTaskIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  completed_by?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  declined_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.task" */
export type DemiplaneTaskInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventurePlayerByCompletedBy?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventurePlayerByCreatedBy?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completed_by?: Maybe<Scalars['bigint']>;
  completed_date?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  declined?: Maybe<Scalars['Boolean']>;
  declined_by?: Maybe<Scalars['bigint']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  super?: Maybe<Scalars['Boolean']>;
  task_read_statuses?: Maybe<DemiplaneTaskReadStatusArrRelInsertInput>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneTaskMaxFields = {
  __typename?: 'demiplane_task_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  completed_by?: Maybe<Scalars['bigint']>;
  completed_date?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  declined_by?: Maybe<Scalars['bigint']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.task" */
export type DemiplaneTaskMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  completed_date?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneTaskMinFields = {
  __typename?: 'demiplane_task_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  completed_by?: Maybe<Scalars['bigint']>;
  completed_date?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  declined_by?: Maybe<Scalars['bigint']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.task" */
export type DemiplaneTaskMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  completed_date?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.task" */
export type DemiplaneTaskMutationResponse = {
  __typename?: 'demiplane_task_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneTask>;
};

/** input type for inserting object relation for remote table "demiplane.task" */
export type DemiplaneTaskObjRelInsertInput = {
  data: DemiplaneTaskInsertInput;
  on_conflict?: Maybe<DemiplaneTaskOnConflict>;
};

/** on conflict condition type for table "demiplane.task" */
export type DemiplaneTaskOnConflict = {
  constraint: DemiplaneTaskConstraint;
  update_columns: Array<DemiplaneTaskUpdateColumn>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};

/** ordering options when selecting data from "demiplane.task" */
export type DemiplaneTaskOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventurePlayerByCompletedBy?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventurePlayerByCreatedBy?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  completed?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  completed_date?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  declined_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  super?: Maybe<OrderBy>;
  task_read_statuses_aggregate?: Maybe<DemiplaneTaskReadStatusAggregateOrderBy>;
  title?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.task" */
export type DemiplaneTaskPkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.task_read_status" */
export type DemiplaneTaskReadStatus = {
  __typename?: 'demiplane_task_read_status';
  /** An object relationship */
  adventure_player: DemiplaneAdventurePlayer;
  adventure_player_id: Scalars['bigint'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  read: Scalars['Boolean'];
  /** An object relationship */
  task: DemiplaneTask;
  task_id: Scalars['bigint'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusAggregate = {
  __typename?: 'demiplane_task_read_status_aggregate';
  aggregate?: Maybe<DemiplaneTaskReadStatusAggregateFields>;
  nodes: Array<DemiplaneTaskReadStatus>;
};

/** aggregate fields of "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusAggregateFields = {
  __typename?: 'demiplane_task_read_status_aggregate_fields';
  avg?: Maybe<DemiplaneTaskReadStatusAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneTaskReadStatusMaxFields>;
  min?: Maybe<DemiplaneTaskReadStatusMinFields>;
  stddev?: Maybe<DemiplaneTaskReadStatusStddevFields>;
  stddev_pop?: Maybe<DemiplaneTaskReadStatusStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneTaskReadStatusStddevSampFields>;
  sum?: Maybe<DemiplaneTaskReadStatusSumFields>;
  var_pop?: Maybe<DemiplaneTaskReadStatusVarPopFields>;
  var_samp?: Maybe<DemiplaneTaskReadStatusVarSampFields>;
  variance?: Maybe<DemiplaneTaskReadStatusVarianceFields>;
};


/** aggregate fields of "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusAggregateOrderBy = {
  avg?: Maybe<DemiplaneTaskReadStatusAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneTaskReadStatusMaxOrderBy>;
  min?: Maybe<DemiplaneTaskReadStatusMinOrderBy>;
  stddev?: Maybe<DemiplaneTaskReadStatusStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneTaskReadStatusStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneTaskReadStatusStddevSampOrderBy>;
  sum?: Maybe<DemiplaneTaskReadStatusSumOrderBy>;
  var_pop?: Maybe<DemiplaneTaskReadStatusVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneTaskReadStatusVarSampOrderBy>;
  variance?: Maybe<DemiplaneTaskReadStatusVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusArrRelInsertInput = {
  data: Array<DemiplaneTaskReadStatusInsertInput>;
  on_conflict?: Maybe<DemiplaneTaskReadStatusOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneTaskReadStatusAvgFields = {
  __typename?: 'demiplane_task_read_status_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  task_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.task_read_status". All fields are combined with a logical 'AND'. */
export type DemiplaneTaskReadStatusBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneTaskReadStatusBoolExp>>>;
  _not?: Maybe<DemiplaneTaskReadStatusBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneTaskReadStatusBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  read?: Maybe<BooleanComparisonExp>;
  task?: Maybe<DemiplaneTaskBoolExp>;
  task_id?: Maybe<BigintComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.task_read_status" */
export enum DemiplaneTaskReadStatusConstraint {
  /** unique or primary key constraint */
  PkTaskReadStatusId = 'pk_task_read_status_id'
}

/** input type for incrementing integer column in table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  task_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  task?: Maybe<DemiplaneTaskObjRelInsertInput>;
  task_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneTaskReadStatusMaxFields = {
  __typename?: 'demiplane_task_read_status_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  task_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneTaskReadStatusMinFields = {
  __typename?: 'demiplane_task_read_status_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  task_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusMutationResponse = {
  __typename?: 'demiplane_task_read_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneTaskReadStatus>;
};

/** input type for inserting object relation for remote table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusObjRelInsertInput = {
  data: DemiplaneTaskReadStatusInsertInput;
  on_conflict?: Maybe<DemiplaneTaskReadStatusOnConflict>;
};

/** on conflict condition type for table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusOnConflict = {
  constraint: DemiplaneTaskReadStatusConstraint;
  update_columns: Array<DemiplaneTaskReadStatusUpdateColumn>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};

/** ordering options when selecting data from "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  read?: Maybe<OrderBy>;
  task?: Maybe<DemiplaneTaskOrderBy>;
  task_id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.task_read_status" */
export enum DemiplaneTaskReadStatusSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read?: Maybe<Scalars['Boolean']>;
  task_id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneTaskReadStatusStddevFields = {
  __typename?: 'demiplane_task_read_status_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  task_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneTaskReadStatusStddevPopFields = {
  __typename?: 'demiplane_task_read_status_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  task_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneTaskReadStatusStddevSampFields = {
  __typename?: 'demiplane_task_read_status_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  task_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneTaskReadStatusSumFields = {
  __typename?: 'demiplane_task_read_status_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  task_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.task_read_status" */
export enum DemiplaneTaskReadStatusUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneTaskReadStatusVarPopFields = {
  __typename?: 'demiplane_task_read_status_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  task_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneTaskReadStatusVarSampFields = {
  __typename?: 'demiplane_task_read_status_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  task_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneTaskReadStatusVarianceFields = {
  __typename?: 'demiplane_task_read_status_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  task_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.task_read_status" */
export type DemiplaneTaskReadStatusVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  task_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.task" */
export enum DemiplaneTaskSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedBy = 'completed_by',
  /** column name */
  CompletedDate = 'completed_date',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Declined = 'declined',
  /** column name */
  DeclinedBy = 'declined_by',
  /** column name */
  DeclinedDate = 'declined_date',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Super = 'super',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.task" */
export type DemiplaneTaskSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  completed?: Maybe<Scalars['Boolean']>;
  completed_by?: Maybe<Scalars['bigint']>;
  completed_date?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  declined?: Maybe<Scalars['Boolean']>;
  declined_by?: Maybe<Scalars['bigint']>;
  declined_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  super?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneTaskStddevFields = {
  __typename?: 'demiplane_task_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  declined_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.task" */
export type DemiplaneTaskStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneTaskStddevPopFields = {
  __typename?: 'demiplane_task_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  declined_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.task" */
export type DemiplaneTaskStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneTaskStddevSampFields = {
  __typename?: 'demiplane_task_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  declined_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.task" */
export type DemiplaneTaskStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneTaskSumFields = {
  __typename?: 'demiplane_task_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  completed_by?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  declined_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.task" */
export type DemiplaneTaskSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.task" */
export enum DemiplaneTaskUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedBy = 'completed_by',
  /** column name */
  CompletedDate = 'completed_date',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Declined = 'declined',
  /** column name */
  DeclinedBy = 'declined_by',
  /** column name */
  DeclinedDate = 'declined_date',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Super = 'super',
  /** column name */
  Title = 'title',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneTaskVarPopFields = {
  __typename?: 'demiplane_task_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  declined_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.task" */
export type DemiplaneTaskVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneTaskVarSampFields = {
  __typename?: 'demiplane_task_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  declined_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.task" */
export type DemiplaneTaskVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneTaskVarianceFields = {
  __typename?: 'demiplane_task_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  completed_by?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  declined_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.task" */
export type DemiplaneTaskVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  completed_by?: Maybe<OrderBy>;
  created_by?: Maybe<OrderBy>;
  declined_by?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegion = {
  __typename?: 'demiplane_tax_nexus_region';
  country: Scalars['String'];
  country_code: Scalars['String'];
  created: Scalars['timestamptz'];
  id: Scalars['bigint'];
  region: Scalars['String'];
  region_code: Scalars['String'];
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionAggregate = {
  __typename?: 'demiplane_tax_nexus_region_aggregate';
  aggregate?: Maybe<DemiplaneTaxNexusRegionAggregateFields>;
  nodes: Array<DemiplaneTaxNexusRegion>;
};

/** aggregate fields of "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionAggregateFields = {
  __typename?: 'demiplane_tax_nexus_region_aggregate_fields';
  avg?: Maybe<DemiplaneTaxNexusRegionAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneTaxNexusRegionMaxFields>;
  min?: Maybe<DemiplaneTaxNexusRegionMinFields>;
  stddev?: Maybe<DemiplaneTaxNexusRegionStddevFields>;
  stddev_pop?: Maybe<DemiplaneTaxNexusRegionStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneTaxNexusRegionStddevSampFields>;
  sum?: Maybe<DemiplaneTaxNexusRegionSumFields>;
  var_pop?: Maybe<DemiplaneTaxNexusRegionVarPopFields>;
  var_samp?: Maybe<DemiplaneTaxNexusRegionVarSampFields>;
  variance?: Maybe<DemiplaneTaxNexusRegionVarianceFields>;
};


/** aggregate fields of "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneTaxNexusRegionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionAggregateOrderBy = {
  avg?: Maybe<DemiplaneTaxNexusRegionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneTaxNexusRegionMaxOrderBy>;
  min?: Maybe<DemiplaneTaxNexusRegionMinOrderBy>;
  stddev?: Maybe<DemiplaneTaxNexusRegionStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneTaxNexusRegionStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneTaxNexusRegionStddevSampOrderBy>;
  sum?: Maybe<DemiplaneTaxNexusRegionSumOrderBy>;
  var_pop?: Maybe<DemiplaneTaxNexusRegionVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneTaxNexusRegionVarSampOrderBy>;
  variance?: Maybe<DemiplaneTaxNexusRegionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionArrRelInsertInput = {
  data: Array<DemiplaneTaxNexusRegionInsertInput>;
  on_conflict?: Maybe<DemiplaneTaxNexusRegionOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneTaxNexusRegionAvgFields = {
  __typename?: 'demiplane_tax_nexus_region_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionAvgOrderBy = {
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.tax_nexus_region". All fields are combined with a logical 'AND'. */
export type DemiplaneTaxNexusRegionBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneTaxNexusRegionBoolExp>>>;
  _not?: Maybe<DemiplaneTaxNexusRegionBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneTaxNexusRegionBoolExp>>>;
  country?: Maybe<StringComparisonExp>;
  country_code?: Maybe<StringComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  region?: Maybe<StringComparisonExp>;
  region_code?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.tax_nexus_region" */
export enum DemiplaneTaxNexusRegionConstraint {
  /** unique or primary key constraint */
  PkTaxNexusRegionId = 'pk_tax_nexus_region_id'
}

/** input type for incrementing integer column in table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionIncInput = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionInsertInput = {
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DemiplaneTaxNexusRegionMaxFields = {
  __typename?: 'demiplane_tax_nexus_region_max_fields';
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionMaxOrderBy = {
  country?: Maybe<OrderBy>;
  country_code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  region?: Maybe<OrderBy>;
  region_code?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneTaxNexusRegionMinFields = {
  __typename?: 'demiplane_tax_nexus_region_min_fields';
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionMinOrderBy = {
  country?: Maybe<OrderBy>;
  country_code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  region?: Maybe<OrderBy>;
  region_code?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionMutationResponse = {
  __typename?: 'demiplane_tax_nexus_region_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneTaxNexusRegion>;
};

/** input type for inserting object relation for remote table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionObjRelInsertInput = {
  data: DemiplaneTaxNexusRegionInsertInput;
  on_conflict?: Maybe<DemiplaneTaxNexusRegionOnConflict>;
};

/** on conflict condition type for table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionOnConflict = {
  constraint: DemiplaneTaxNexusRegionConstraint;
  update_columns: Array<DemiplaneTaxNexusRegionUpdateColumn>;
  where?: Maybe<DemiplaneTaxNexusRegionBoolExp>;
};

/** ordering options when selecting data from "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionOrderBy = {
  country?: Maybe<OrderBy>;
  country_code?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  region?: Maybe<OrderBy>;
  region_code?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.tax_nexus_region" */
export enum DemiplaneTaxNexusRegionSelectColumn {
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  RegionCode = 'region_code',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionSetInput = {
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DemiplaneTaxNexusRegionStddevFields = {
  __typename?: 'demiplane_tax_nexus_region_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionStddevOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneTaxNexusRegionStddevPopFields = {
  __typename?: 'demiplane_tax_nexus_region_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneTaxNexusRegionStddevSampFields = {
  __typename?: 'demiplane_tax_nexus_region_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneTaxNexusRegionSumFields = {
  __typename?: 'demiplane_tax_nexus_region_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionSumOrderBy = {
  id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.tax_nexus_region" */
export enum DemiplaneTaxNexusRegionUpdateColumn {
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  RegionCode = 'region_code',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type DemiplaneTaxNexusRegionVarPopFields = {
  __typename?: 'demiplane_tax_nexus_region_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionVarPopOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneTaxNexusRegionVarSampFields = {
  __typename?: 'demiplane_tax_nexus_region_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionVarSampOrderBy = {
  id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneTaxNexusRegionVarianceFields = {
  __typename?: 'demiplane_tax_nexus_region_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.tax_nexus_region" */
export type DemiplaneTaxNexusRegionVarianceOrderBy = {
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceipt = {
  __typename?: 'demiplane_transaction_receipt';
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  combined_tax_rate: Scalars['numeric'];
  /** An object relationship */
  country: DemiplaneCountry;
  country_id: Scalars['Int'];
  created: Scalars['timestamptz'];
  customer_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gm_fee: Scalars['Int'];
  id: Scalars['bigint'];
  /** An object relationship */
  payment: DemiplanePayment;
  payment_id: Scalars['bigint'];
  /** An object relationship */
  region: DemiplaneRegion;
  region_id: Scalars['bigint'];
  session_date: Scalars['timestamptz'];
  tax_amount: Scalars['Int'];
  tip_amount: Scalars['Int'];
  total_amount: Scalars['Int'];
  transaction_date: Scalars['timestamptz'];
  transaction_fee: Scalars['Int'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_id?: Maybe<Scalars['bigint']>;
  zipcode: Scalars['String'];
  zipcode_plus_4_code: Scalars['String'];
};

/** aggregated selection of "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptAggregate = {
  __typename?: 'demiplane_transaction_receipt_aggregate';
  aggregate?: Maybe<DemiplaneTransactionReceiptAggregateFields>;
  nodes: Array<DemiplaneTransactionReceipt>;
};

/** aggregate fields of "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptAggregateFields = {
  __typename?: 'demiplane_transaction_receipt_aggregate_fields';
  avg?: Maybe<DemiplaneTransactionReceiptAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneTransactionReceiptMaxFields>;
  min?: Maybe<DemiplaneTransactionReceiptMinFields>;
  stddev?: Maybe<DemiplaneTransactionReceiptStddevFields>;
  stddev_pop?: Maybe<DemiplaneTransactionReceiptStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneTransactionReceiptStddevSampFields>;
  sum?: Maybe<DemiplaneTransactionReceiptSumFields>;
  var_pop?: Maybe<DemiplaneTransactionReceiptVarPopFields>;
  var_samp?: Maybe<DemiplaneTransactionReceiptVarSampFields>;
  variance?: Maybe<DemiplaneTransactionReceiptVarianceFields>;
};


/** aggregate fields of "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptAggregateOrderBy = {
  avg?: Maybe<DemiplaneTransactionReceiptAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneTransactionReceiptMaxOrderBy>;
  min?: Maybe<DemiplaneTransactionReceiptMinOrderBy>;
  stddev?: Maybe<DemiplaneTransactionReceiptStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneTransactionReceiptStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneTransactionReceiptStddevSampOrderBy>;
  sum?: Maybe<DemiplaneTransactionReceiptSumOrderBy>;
  var_pop?: Maybe<DemiplaneTransactionReceiptVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneTransactionReceiptVarSampOrderBy>;
  variance?: Maybe<DemiplaneTransactionReceiptVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptArrRelInsertInput = {
  data: Array<DemiplaneTransactionReceiptInsertInput>;
  on_conflict?: Maybe<DemiplaneTransactionReceiptOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneTransactionReceiptAvgFields = {
  __typename?: 'demiplane_transaction_receipt_avg_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptAvgOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.transaction_receipt". All fields are combined with a logical 'AND'. */
export type DemiplaneTransactionReceiptBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneTransactionReceiptBoolExp>>>;
  _not?: Maybe<DemiplaneTransactionReceiptBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneTransactionReceiptBoolExp>>>;
  address1?: Maybe<StringComparisonExp>;
  address2?: Maybe<StringComparisonExp>;
  city?: Maybe<StringComparisonExp>;
  combined_tax_rate?: Maybe<NumericComparisonExp>;
  country?: Maybe<DemiplaneCountryBoolExp>;
  country_id?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  customer_name?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  gm_fee?: Maybe<IntComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  payment?: Maybe<DemiplanePaymentBoolExp>;
  payment_id?: Maybe<BigintComparisonExp>;
  region?: Maybe<DemiplaneRegionBoolExp>;
  region_id?: Maybe<BigintComparisonExp>;
  session_date?: Maybe<TimestamptzComparisonExp>;
  tax_amount?: Maybe<IntComparisonExp>;
  tip_amount?: Maybe<IntComparisonExp>;
  total_amount?: Maybe<IntComparisonExp>;
  transaction_date?: Maybe<TimestamptzComparisonExp>;
  transaction_fee?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  zipcode?: Maybe<StringComparisonExp>;
  zipcode_plus_4_code?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.transaction_receipt" */
export enum DemiplaneTransactionReceiptConstraint {
  /** unique or primary key constraint */
  PkTransactionReceiptId = 'pk_transaction_receipt_id',
  /** unique or primary key constraint */
  UqTransactionReceiptPaymentId = 'uq_transaction_receipt_payment_id'
}

/** input type for incrementing integer column in table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptIncInput = {
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  country_id?: Maybe<Scalars['Int']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptInsertInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  country?: Maybe<DemiplaneCountryObjRelInsertInput>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment?: Maybe<DemiplanePaymentObjRelInsertInput>;
  payment_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<DemiplaneRegionObjRelInsertInput>;
  region_id?: Maybe<Scalars['bigint']>;
  session_date?: Maybe<Scalars['timestamptz']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_date?: Maybe<Scalars['timestamptz']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DemiplaneTransactionReceiptMaxFields = {
  __typename?: 'demiplane_transaction_receipt_max_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  session_date?: Maybe<Scalars['timestamptz']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_date?: Maybe<Scalars['timestamptz']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptMaxOrderBy = {
  address1?: Maybe<OrderBy>;
  address2?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  customer_name?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  session_date?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_date?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  zipcode?: Maybe<OrderBy>;
  zipcode_plus_4_code?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneTransactionReceiptMinFields = {
  __typename?: 'demiplane_transaction_receipt_min_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  session_date?: Maybe<Scalars['timestamptz']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_date?: Maybe<Scalars['timestamptz']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptMinOrderBy = {
  address1?: Maybe<OrderBy>;
  address2?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  customer_name?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  session_date?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_date?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  zipcode?: Maybe<OrderBy>;
  zipcode_plus_4_code?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptMutationResponse = {
  __typename?: 'demiplane_transaction_receipt_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneTransactionReceipt>;
};

/** input type for inserting object relation for remote table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptObjRelInsertInput = {
  data: DemiplaneTransactionReceiptInsertInput;
  on_conflict?: Maybe<DemiplaneTransactionReceiptOnConflict>;
};

/** on conflict condition type for table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptOnConflict = {
  constraint: DemiplaneTransactionReceiptConstraint;
  update_columns: Array<DemiplaneTransactionReceiptUpdateColumn>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};

/** ordering options when selecting data from "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptOrderBy = {
  address1?: Maybe<OrderBy>;
  address2?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  combined_tax_rate?: Maybe<OrderBy>;
  country?: Maybe<DemiplaneCountryOrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  customer_name?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment?: Maybe<DemiplanePaymentOrderBy>;
  payment_id?: Maybe<OrderBy>;
  region?: Maybe<DemiplaneRegionOrderBy>;
  region_id?: Maybe<OrderBy>;
  session_date?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_date?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  zipcode?: Maybe<OrderBy>;
  zipcode_plus_4_code?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.transaction_receipt" */
export enum DemiplaneTransactionReceiptSelectColumn {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  CombinedTaxRate = 'combined_tax_rate',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Email = 'email',
  /** column name */
  GmFee = 'gm_fee',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  SessionDate = 'session_date',
  /** column name */
  TaxAmount = 'tax_amount',
  /** column name */
  TipAmount = 'tip_amount',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  TransactionFee = 'transaction_fee',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Zipcode = 'zipcode',
  /** column name */
  ZipcodePlus_4Code = 'zipcode_plus_4_code'
}

/** input type for updating data in table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptSetInput = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  customer_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  session_date?: Maybe<Scalars['timestamptz']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_date?: Maybe<Scalars['timestamptz']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneTransactionReceiptStddevFields = {
  __typename?: 'demiplane_transaction_receipt_stddev_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptStddevOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneTransactionReceiptStddevPopFields = {
  __typename?: 'demiplane_transaction_receipt_stddev_pop_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptStddevPopOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneTransactionReceiptStddevSampFields = {
  __typename?: 'demiplane_transaction_receipt_stddev_samp_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptStddevSampOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneTransactionReceiptSumFields = {
  __typename?: 'demiplane_transaction_receipt_sum_fields';
  combined_tax_rate?: Maybe<Scalars['numeric']>;
  country_id?: Maybe<Scalars['Int']>;
  gm_fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  tax_amount?: Maybe<Scalars['Int']>;
  tip_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  transaction_fee?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptSumOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.transaction_receipt" */
export enum DemiplaneTransactionReceiptUpdateColumn {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  CombinedTaxRate = 'combined_tax_rate',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Email = 'email',
  /** column name */
  GmFee = 'gm_fee',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  SessionDate = 'session_date',
  /** column name */
  TaxAmount = 'tax_amount',
  /** column name */
  TipAmount = 'tip_amount',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  TransactionFee = 'transaction_fee',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Zipcode = 'zipcode',
  /** column name */
  ZipcodePlus_4Code = 'zipcode_plus_4_code'
}

/** aggregate var_pop on columns */
export type DemiplaneTransactionReceiptVarPopFields = {
  __typename?: 'demiplane_transaction_receipt_var_pop_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptVarPopOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneTransactionReceiptVarSampFields = {
  __typename?: 'demiplane_transaction_receipt_var_samp_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptVarSampOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneTransactionReceiptVarianceFields = {
  __typename?: 'demiplane_transaction_receipt_variance_fields';
  combined_tax_rate?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  gm_fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  tax_amount?: Maybe<Scalars['Float']>;
  tip_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  transaction_fee?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.transaction_receipt" */
export type DemiplaneTransactionReceiptVarianceOrderBy = {
  combined_tax_rate?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  gm_fee?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  payment_id?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  tax_amount?: Maybe<OrderBy>;
  tip_amount?: Maybe<OrderBy>;
  total_amount?: Maybe<OrderBy>;
  transaction_fee?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.upload" */
export type DemiplaneUpload = {
  __typename?: 'demiplane_upload';
  /** An object relationship */
  adventure_player?: Maybe<DemiplaneAdventurePlayer>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created: Scalars['timestamptz'];
  file: Scalars['String'];
  id: Scalars['bigint'];
  kind: Scalars['String'];
  mime_type: Scalars['String'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "demiplane.upload" */
export type DemiplaneUploadAggregate = {
  __typename?: 'demiplane_upload_aggregate';
  aggregate?: Maybe<DemiplaneUploadAggregateFields>;
  nodes: Array<DemiplaneUpload>;
};

/** aggregate fields of "demiplane.upload" */
export type DemiplaneUploadAggregateFields = {
  __typename?: 'demiplane_upload_aggregate_fields';
  avg?: Maybe<DemiplaneUploadAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUploadMaxFields>;
  min?: Maybe<DemiplaneUploadMinFields>;
  stddev?: Maybe<DemiplaneUploadStddevFields>;
  stddev_pop?: Maybe<DemiplaneUploadStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUploadStddevSampFields>;
  sum?: Maybe<DemiplaneUploadSumFields>;
  var_pop?: Maybe<DemiplaneUploadVarPopFields>;
  var_samp?: Maybe<DemiplaneUploadVarSampFields>;
  variance?: Maybe<DemiplaneUploadVarianceFields>;
};


/** aggregate fields of "demiplane.upload" */
export type DemiplaneUploadAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUploadSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.upload" */
export type DemiplaneUploadAggregateOrderBy = {
  avg?: Maybe<DemiplaneUploadAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUploadMaxOrderBy>;
  min?: Maybe<DemiplaneUploadMinOrderBy>;
  stddev?: Maybe<DemiplaneUploadStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUploadStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUploadStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUploadSumOrderBy>;
  var_pop?: Maybe<DemiplaneUploadVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUploadVarSampOrderBy>;
  variance?: Maybe<DemiplaneUploadVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.upload" */
export type DemiplaneUploadArrRelInsertInput = {
  data: Array<DemiplaneUploadInsertInput>;
  on_conflict?: Maybe<DemiplaneUploadOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUploadAvgFields = {
  __typename?: 'demiplane_upload_avg_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.upload" */
export type DemiplaneUploadAvgOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.upload". All fields are combined with a logical 'AND'. */
export type DemiplaneUploadBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUploadBoolExp>>>;
  _not?: Maybe<DemiplaneUploadBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUploadBoolExp>>>;
  adventure_player?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventure_player_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  file?: Maybe<StringComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  kind?: Maybe<StringComparisonExp>;
  mime_type?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.upload" */
export enum DemiplaneUploadConstraint {
  /** unique or primary key constraint */
  Rel_025395edd09ef065341d83a27c = 'REL_025395edd09ef065341d83a27c',
  /** unique or primary key constraint */
  Rel_8b8fd353e845a9b1e512f9120a = 'REL_8b8fd353e845a9b1e512f9120a',
  /** unique or primary key constraint */
  PkUploadId = 'pk_upload_id'
}

/** input type for incrementing integer column in table "demiplane.upload" */
export type DemiplaneUploadIncInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.upload" */
export type DemiplaneUploadInsertInput = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerObjRelInsertInput>;
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUploadMaxFields = {
  __typename?: 'demiplane_upload_max_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.upload" */
export type DemiplaneUploadMaxOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  file?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUploadMinFields = {
  __typename?: 'demiplane_upload_min_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.upload" */
export type DemiplaneUploadMinOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  file?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.upload" */
export type DemiplaneUploadMutationResponse = {
  __typename?: 'demiplane_upload_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUpload>;
};

/** input type for inserting object relation for remote table "demiplane.upload" */
export type DemiplaneUploadObjRelInsertInput = {
  data: DemiplaneUploadInsertInput;
  on_conflict?: Maybe<DemiplaneUploadOnConflict>;
};

/** on conflict condition type for table "demiplane.upload" */
export type DemiplaneUploadOnConflict = {
  constraint: DemiplaneUploadConstraint;
  update_columns: Array<DemiplaneUploadUpdateColumn>;
  where?: Maybe<DemiplaneUploadBoolExp>;
};

/** ordering options when selecting data from "demiplane.upload" */
export type DemiplaneUploadOrderBy = {
  adventure_player?: Maybe<DemiplaneAdventurePlayerOrderBy>;
  adventure_player_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  file?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  mime_type?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.upload" */
export type DemiplaneUploadPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.upload" */
export enum DemiplaneUploadSelectColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.upload" */
export type DemiplaneUploadSetInput = {
  adventure_player_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUploadStddevFields = {
  __typename?: 'demiplane_upload_stddev_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.upload" */
export type DemiplaneUploadStddevOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUploadStddevPopFields = {
  __typename?: 'demiplane_upload_stddev_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.upload" */
export type DemiplaneUploadStddevPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUploadStddevSampFields = {
  __typename?: 'demiplane_upload_stddev_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.upload" */
export type DemiplaneUploadStddevSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUploadSumFields = {
  __typename?: 'demiplane_upload_sum_fields';
  adventure_player_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.upload" */
export type DemiplaneUploadSumOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.upload" */
export enum DemiplaneUploadUpdateColumn {
  /** column name */
  AdventurePlayerId = 'adventure_player_id',
  /** column name */
  Created = 'created',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUploadVarPopFields = {
  __typename?: 'demiplane_upload_var_pop_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.upload" */
export type DemiplaneUploadVarPopOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUploadVarSampFields = {
  __typename?: 'demiplane_upload_var_samp_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.upload" */
export type DemiplaneUploadVarSampOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUploadVarianceFields = {
  __typename?: 'demiplane_upload_variance_fields';
  adventure_player_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.upload" */
export type DemiplaneUploadVarianceOrderBy = {
  adventure_player_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.urap" */
export type DemiplaneUrap = {
  __typename?: 'demiplane_urap';
  adventuringPlatformId: Scalars['bigint'];
  /** An object relationship */
  adventuring_platform: DemiplaneAdventuringPlatform;
  userRecruitmentId: Scalars['bigint'];
  /** An object relationship */
  user_recruitment: DemiplaneUserRecruitment;
};

/** aggregated selection of "demiplane.urap" */
export type DemiplaneUrapAggregate = {
  __typename?: 'demiplane_urap_aggregate';
  aggregate?: Maybe<DemiplaneUrapAggregateFields>;
  nodes: Array<DemiplaneUrap>;
};

/** aggregate fields of "demiplane.urap" */
export type DemiplaneUrapAggregateFields = {
  __typename?: 'demiplane_urap_aggregate_fields';
  avg?: Maybe<DemiplaneUrapAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUrapMaxFields>;
  min?: Maybe<DemiplaneUrapMinFields>;
  stddev?: Maybe<DemiplaneUrapStddevFields>;
  stddev_pop?: Maybe<DemiplaneUrapStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUrapStddevSampFields>;
  sum?: Maybe<DemiplaneUrapSumFields>;
  var_pop?: Maybe<DemiplaneUrapVarPopFields>;
  var_samp?: Maybe<DemiplaneUrapVarSampFields>;
  variance?: Maybe<DemiplaneUrapVarianceFields>;
};


/** aggregate fields of "demiplane.urap" */
export type DemiplaneUrapAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.urap" */
export type DemiplaneUrapAggregateOrderBy = {
  avg?: Maybe<DemiplaneUrapAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUrapMaxOrderBy>;
  min?: Maybe<DemiplaneUrapMinOrderBy>;
  stddev?: Maybe<DemiplaneUrapStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUrapStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUrapStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUrapSumOrderBy>;
  var_pop?: Maybe<DemiplaneUrapVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUrapVarSampOrderBy>;
  variance?: Maybe<DemiplaneUrapVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.urap" */
export type DemiplaneUrapArrRelInsertInput = {
  data: Array<DemiplaneUrapInsertInput>;
  on_conflict?: Maybe<DemiplaneUrapOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUrapAvgFields = {
  __typename?: 'demiplane_urap_avg_fields';
  adventuringPlatformId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.urap" */
export type DemiplaneUrapAvgOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.urap". All fields are combined with a logical 'AND'. */
export type DemiplaneUrapBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUrapBoolExp>>>;
  _not?: Maybe<DemiplaneUrapBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUrapBoolExp>>>;
  adventuringPlatformId?: Maybe<BigintComparisonExp>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
  userRecruitmentId?: Maybe<BigintComparisonExp>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentBoolExp>;
};

/** unique or primary key constraints on table "demiplane.urap" */
export enum DemiplaneUrapConstraint {
  /** unique or primary key constraint */
  PkUrapAdventuringPlatformIdUserRecruitmentId = 'pk_urap_adventuringPlatformId_userRecruitmentId'
}

/** input type for incrementing integer column in table "demiplane.urap" */
export type DemiplaneUrapIncInput = {
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.urap" */
export type DemiplaneUrapInsertInput = {
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformObjRelInsertInput>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentObjRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneUrapMaxFields = {
  __typename?: 'demiplane_urap_max_fields';
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.urap" */
export type DemiplaneUrapMaxOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUrapMinFields = {
  __typename?: 'demiplane_urap_min_fields';
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.urap" */
export type DemiplaneUrapMinOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.urap" */
export type DemiplaneUrapMutationResponse = {
  __typename?: 'demiplane_urap_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUrap>;
};

/** input type for inserting object relation for remote table "demiplane.urap" */
export type DemiplaneUrapObjRelInsertInput = {
  data: DemiplaneUrapInsertInput;
  on_conflict?: Maybe<DemiplaneUrapOnConflict>;
};

/** on conflict condition type for table "demiplane.urap" */
export type DemiplaneUrapOnConflict = {
  constraint: DemiplaneUrapConstraint;
  update_columns: Array<DemiplaneUrapUpdateColumn>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};

/** ordering options when selecting data from "demiplane.urap" */
export type DemiplaneUrapOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformOrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentOrderBy>;
};

/** primary key columns input for table: "demiplane.urap" */
export type DemiplaneUrapPkColumnsInput = {
  adventuringPlatformId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};

/** select columns of table "demiplane.urap" */
export enum DemiplaneUrapSelectColumn {
  /** column name */
  AdventuringPlatformId = 'adventuringPlatformId',
  /** column name */
  UserRecruitmentId = 'userRecruitmentId'
}

/** input type for updating data in table "demiplane.urap" */
export type DemiplaneUrapSetInput = {
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUrapStddevFields = {
  __typename?: 'demiplane_urap_stddev_fields';
  adventuringPlatformId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.urap" */
export type DemiplaneUrapStddevOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUrapStddevPopFields = {
  __typename?: 'demiplane_urap_stddev_pop_fields';
  adventuringPlatformId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.urap" */
export type DemiplaneUrapStddevPopOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUrapStddevSampFields = {
  __typename?: 'demiplane_urap_stddev_samp_fields';
  adventuringPlatformId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.urap" */
export type DemiplaneUrapStddevSampOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUrapSumFields = {
  __typename?: 'demiplane_urap_sum_fields';
  adventuringPlatformId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.urap" */
export type DemiplaneUrapSumOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.urap" */
export enum DemiplaneUrapUpdateColumn {
  /** column name */
  AdventuringPlatformId = 'adventuringPlatformId',
  /** column name */
  UserRecruitmentId = 'userRecruitmentId'
}

/** aggregate var_pop on columns */
export type DemiplaneUrapVarPopFields = {
  __typename?: 'demiplane_urap_var_pop_fields';
  adventuringPlatformId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.urap" */
export type DemiplaneUrapVarPopOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUrapVarSampFields = {
  __typename?: 'demiplane_urap_var_samp_fields';
  adventuringPlatformId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.urap" */
export type DemiplaneUrapVarSampOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUrapVarianceFields = {
  __typename?: 'demiplane_urap_variance_fields';
  adventuringPlatformId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.urap" */
export type DemiplaneUrapVarianceOrderBy = {
  adventuringPlatformId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.urgt" */
export type DemiplaneUrgt = {
  __typename?: 'demiplane_urgt';
  gameTypeId: Scalars['bigint'];
  /** An object relationship */
  game_type: DemiplaneGameType;
  userRecruitmentId: Scalars['bigint'];
  /** An object relationship */
  user_recruitment: DemiplaneUserRecruitment;
};

/** aggregated selection of "demiplane.urgt" */
export type DemiplaneUrgtAggregate = {
  __typename?: 'demiplane_urgt_aggregate';
  aggregate?: Maybe<DemiplaneUrgtAggregateFields>;
  nodes: Array<DemiplaneUrgt>;
};

/** aggregate fields of "demiplane.urgt" */
export type DemiplaneUrgtAggregateFields = {
  __typename?: 'demiplane_urgt_aggregate_fields';
  avg?: Maybe<DemiplaneUrgtAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUrgtMaxFields>;
  min?: Maybe<DemiplaneUrgtMinFields>;
  stddev?: Maybe<DemiplaneUrgtStddevFields>;
  stddev_pop?: Maybe<DemiplaneUrgtStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUrgtStddevSampFields>;
  sum?: Maybe<DemiplaneUrgtSumFields>;
  var_pop?: Maybe<DemiplaneUrgtVarPopFields>;
  var_samp?: Maybe<DemiplaneUrgtVarSampFields>;
  variance?: Maybe<DemiplaneUrgtVarianceFields>;
};


/** aggregate fields of "demiplane.urgt" */
export type DemiplaneUrgtAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.urgt" */
export type DemiplaneUrgtAggregateOrderBy = {
  avg?: Maybe<DemiplaneUrgtAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUrgtMaxOrderBy>;
  min?: Maybe<DemiplaneUrgtMinOrderBy>;
  stddev?: Maybe<DemiplaneUrgtStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUrgtStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUrgtStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUrgtSumOrderBy>;
  var_pop?: Maybe<DemiplaneUrgtVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUrgtVarSampOrderBy>;
  variance?: Maybe<DemiplaneUrgtVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.urgt" */
export type DemiplaneUrgtArrRelInsertInput = {
  data: Array<DemiplaneUrgtInsertInput>;
  on_conflict?: Maybe<DemiplaneUrgtOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUrgtAvgFields = {
  __typename?: 'demiplane_urgt_avg_fields';
  gameTypeId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtAvgOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.urgt". All fields are combined with a logical 'AND'. */
export type DemiplaneUrgtBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUrgtBoolExp>>>;
  _not?: Maybe<DemiplaneUrgtBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUrgtBoolExp>>>;
  gameTypeId?: Maybe<BigintComparisonExp>;
  game_type?: Maybe<DemiplaneGameTypeBoolExp>;
  userRecruitmentId?: Maybe<BigintComparisonExp>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentBoolExp>;
};

/** unique or primary key constraints on table "demiplane.urgt" */
export enum DemiplaneUrgtConstraint {
  /** unique or primary key constraint */
  PkUrgtGameTypeIdUserRecruitmentId = 'pk_urgt_gameTypeId_userRecruitmentId'
}

/** input type for incrementing integer column in table "demiplane.urgt" */
export type DemiplaneUrgtIncInput = {
  gameTypeId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.urgt" */
export type DemiplaneUrgtInsertInput = {
  gameTypeId?: Maybe<Scalars['bigint']>;
  game_type?: Maybe<DemiplaneGameTypeObjRelInsertInput>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentObjRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneUrgtMaxFields = {
  __typename?: 'demiplane_urgt_max_fields';
  gameTypeId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtMaxOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUrgtMinFields = {
  __typename?: 'demiplane_urgt_min_fields';
  gameTypeId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtMinOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.urgt" */
export type DemiplaneUrgtMutationResponse = {
  __typename?: 'demiplane_urgt_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUrgt>;
};

/** input type for inserting object relation for remote table "demiplane.urgt" */
export type DemiplaneUrgtObjRelInsertInput = {
  data: DemiplaneUrgtInsertInput;
  on_conflict?: Maybe<DemiplaneUrgtOnConflict>;
};

/** on conflict condition type for table "demiplane.urgt" */
export type DemiplaneUrgtOnConflict = {
  constraint: DemiplaneUrgtConstraint;
  update_columns: Array<DemiplaneUrgtUpdateColumn>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};

/** ordering options when selecting data from "demiplane.urgt" */
export type DemiplaneUrgtOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  game_type?: Maybe<DemiplaneGameTypeOrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentOrderBy>;
};

/** primary key columns input for table: "demiplane.urgt" */
export type DemiplaneUrgtPkColumnsInput = {
  gameTypeId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};

/** select columns of table "demiplane.urgt" */
export enum DemiplaneUrgtSelectColumn {
  /** column name */
  GameTypeId = 'gameTypeId',
  /** column name */
  UserRecruitmentId = 'userRecruitmentId'
}

/** input type for updating data in table "demiplane.urgt" */
export type DemiplaneUrgtSetInput = {
  gameTypeId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUrgtStddevFields = {
  __typename?: 'demiplane_urgt_stddev_fields';
  gameTypeId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtStddevOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUrgtStddevPopFields = {
  __typename?: 'demiplane_urgt_stddev_pop_fields';
  gameTypeId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtStddevPopOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUrgtStddevSampFields = {
  __typename?: 'demiplane_urgt_stddev_samp_fields';
  gameTypeId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtStddevSampOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUrgtSumFields = {
  __typename?: 'demiplane_urgt_sum_fields';
  gameTypeId?: Maybe<Scalars['bigint']>;
  userRecruitmentId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtSumOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.urgt" */
export enum DemiplaneUrgtUpdateColumn {
  /** column name */
  GameTypeId = 'gameTypeId',
  /** column name */
  UserRecruitmentId = 'userRecruitmentId'
}

/** aggregate var_pop on columns */
export type DemiplaneUrgtVarPopFields = {
  __typename?: 'demiplane_urgt_var_pop_fields';
  gameTypeId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtVarPopOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUrgtVarSampFields = {
  __typename?: 'demiplane_urgt_var_samp_fields';
  gameTypeId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtVarSampOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUrgtVarianceFields = {
  __typename?: 'demiplane_urgt_variance_fields';
  gameTypeId?: Maybe<Scalars['Float']>;
  userRecruitmentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.urgt" */
export type DemiplaneUrgtVarianceOrderBy = {
  gameTypeId?: Maybe<OrderBy>;
  userRecruitmentId?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.user" */
export type DemiplaneUser = {
  __typename?: 'demiplane_user';
  access_level: Scalars['String'];
  accessibility_friendly: Scalars['Boolean'];
  /** An array relationship */
  ad_requests: Array<DemiplaneAdRequest>;
  /** An aggregated array relationship */
  ad_requests_aggregate: DemiplaneAdRequestAggregate;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  /** An array relationship */
  ads: Array<DemiplaneAd>;
  /** An aggregated array relationship */
  ads_aggregate: DemiplaneAdAggregate;
  /** An array relationship */
  adventureConnectionsByContactorId: Array<DemiplaneAdventureConnection>;
  /** An aggregated array relationship */
  adventureConnectionsByContactorId_aggregate: DemiplaneAdventureConnectionAggregate;
  /** An array relationship */
  adventureConnectionsByRequestorId: Array<DemiplaneAdventureConnection>;
  /** An aggregated array relationship */
  adventureConnectionsByRequestorId_aggregate: DemiplaneAdventureConnectionAggregate;
  /** An array relationship */
  adventure_connections: Array<DemiplaneAdventureConnection>;
  /** An aggregated array relationship */
  adventure_connections_aggregate: DemiplaneAdventureConnectionAggregate;
  /** An array relationship */
  adventure_players: Array<DemiplaneAdventurePlayer>;
  /** An aggregated array relationship */
  adventure_players_aggregate: DemiplaneAdventurePlayerAggregate;
  /** An array relationship */
  adventures: Array<DemiplaneAdventure>;
  /** An aggregated array relationship */
  adventures_aggregate: DemiplaneAdventureAggregate;
  city?: Maybe<Scalars['String']>;
  cognito_id: Scalars['String'];
  combat: Scalars['Int'];
  /** An object relationship */
  country: DemiplaneCountry;
  country_id: Scalars['Int'];
  created: Scalars['timestamptz'];
  /** An array relationship */
  credit_cards: Array<DemiplaneCreditCard>;
  /** An aggregated array relationship */
  credit_cards_aggregate: DemiplaneCreditCardAggregate;
  dob: Scalars['timestamptz'];
  email: Scalars['String'];
  /** An array relationship */
  events: Array<DemiplaneEvent>;
  /** An aggregated array relationship */
  events_aggregate: DemiplaneEventAggregate;
  experience: Scalars['Int'];
  facebook_profile?: Maybe<Scalars['String']>;
  /** An array relationship */
  favorite_adventuring_platforms: Array<DemiplaneFavoriteAdventuringPlatform>;
  /** An aggregated array relationship */
  favorite_adventuring_platforms_aggregate: DemiplaneFavoriteAdventuringPlatformAggregate;
  /** An array relationship */
  favorite_games: Array<DemiplaneFavoriteGame>;
  /** An aggregated array relationship */
  favorite_games_aggregate: DemiplaneFavoriteGameAggregate;
  feature_level: Scalars['String'];
  first_login: Scalars['Boolean'];
  first_name: Scalars['String'];
  game_master_bio: Scalars['String'];
  /** An array relationship */
  gmRecommendationsByGmId: Array<DemiplaneGmRecommendation>;
  /** An aggregated array relationship */
  gmRecommendationsByGmId_aggregate: DemiplaneGmRecommendationAggregate;
  /** An array relationship */
  gm_recommendations: Array<DemiplaneGmRecommendation>;
  /** An aggregated array relationship */
  gm_recommendations_aggregate: DemiplaneGmRecommendationAggregate;
  id: Scalars['bigint'];
  inactive_month_alerted_email: Scalars['Boolean'];
  inactive_two_month_alerted_email: Scalars['Boolean'];
  inactive_week_alerted_email: Scalars['Boolean'];
  instagram_profile?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  last_access: Scalars['timestamptz'];
  last_name: Scalars['String'];
  lgbtqia_friendly: Scalars['Boolean'];
  mind_theater_combat: Scalars['Boolean'];
  new_user_second_email: Scalars['Boolean'];
  new_user_third_email: Scalars['Boolean'];
  /** An array relationship */
  notificationHistoriesBySubmitorId: Array<DemiplaneNotificationHistory>;
  /** An aggregated array relationship */
  notificationHistoriesBySubmitorId_aggregate: DemiplaneNotificationHistoryAggregate;
  /** An array relationship */
  notification_histories: Array<DemiplaneNotificationHistory>;
  /** An aggregated array relationship */
  notification_histories_aggregate: DemiplaneNotificationHistoryAggregate;
  /** An array relationship */
  payments: Array<DemiplanePayment>;
  /** An aggregated array relationship */
  payments_aggregate: DemiplanePaymentAggregate;
  player_story: Scalars['Int'];
  /** An object relationship */
  pronoun?: Maybe<DemiplanePronoun>;
  pronoun_id?: Maybe<Scalars['bigint']>;
  puzzles_logic: Scalars['Int'];
  recruitment_set_or_alerted: Scalars['Boolean'];
  /** An object relationship */
  region: DemiplaneRegion;
  region_id: Scalars['bigint'];
  role_playing: Scalars['Int'];
  /** An array relationship */
  session_players: Array<DemiplaneSessionPlayer>;
  /** An aggregated array relationship */
  session_players_aggregate: DemiplaneSessionPlayerAggregate;
  social_intrigue: Scalars['Int'];
  strict_rules: Scalars['Int'];
  stripe_connect_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  tactical_grid_combat: Scalars['Boolean'];
  time_zone: Scalars['String'];
  /** An array relationship */
  transaction_receipts: Array<DemiplaneTransactionReceipt>;
  /** An aggregated array relationship */
  transaction_receipts_aggregate: DemiplaneTransactionReceiptAggregate;
  tutorial_seen: Scalars['Boolean'];
  twitch_profile?: Maybe<Scalars['String']>;
  twitter_profile?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
  /** An object relationship */
  upload?: Maybe<DemiplaneUpload>;
  /** An array relationship */
  user_activities: Array<DemiplaneUserActivity>;
  /** An aggregated array relationship */
  user_activities_aggregate: DemiplaneUserActivityAggregate;
  /** An array relationship */
  user_adventure_rankings: Array<DemiplaneUserAdventureRanking>;
  /** An aggregated array relationship */
  user_adventure_rankings_aggregate: DemiplaneUserAdventureRankingAggregate;
  /** An array relationship */
  user_adventure_roles: Array<DemiplaneUserAdventureRole>;
  /** An aggregated array relationship */
  user_adventure_roles_aggregate: DemiplaneUserAdventureRoleAggregate;
  /** An array relationship */
  user_adventures: Array<DemiplaneUserAdventure>;
  /** An aggregated array relationship */
  user_adventures_aggregate: DemiplaneUserAdventureAggregate;
  /** An object relationship */
  user_email_notification_pref?: Maybe<DemiplaneUserEmailNotificationPref>;
  /** An array relationship */
  user_invitations: Array<DemiplaneUserInvitation>;
  /** An aggregated array relationship */
  user_invitations_aggregate: DemiplaneUserInvitationAggregate;
  /** An object relationship */
  user_metadatum?: Maybe<DemiplaneUserMetadata>;
  /** An object relationship */
  user_push_notification_pref?: Maybe<DemiplaneUserPushNotificationPref>;
  /** An object relationship */
  user_recruitment?: Maybe<DemiplaneUserRecruitment>;
  /** An object relationship */
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurer>;
  /** An object relationship */
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMaster>;
  /** An array relationship */
  user_roles: Array<DemiplaneUserRole>;
  /** An aggregated array relationship */
  user_roles_aggregate: DemiplaneUserRoleAggregate;
  username: Scalars['String'];
  uses_video_webcam: Scalars['Boolean'];
  uses_voice_mic: Scalars['Boolean'];
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdRequestsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdRequestsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventureConnectionsByContactorIdArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventureConnectionsByContactorIdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventureConnectionsByRequestorIdArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventureConnectionsByRequestorIdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventureConnectionsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventureConnectionsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventurePlayersArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventurePlayersAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventuresArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserAdventuresAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserCreditCardsArgs = {
  distinct_on?: Maybe<Array<DemiplaneCreditCardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCreditCardOrderBy>>;
  where?: Maybe<DemiplaneCreditCardBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserCreditCardsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneCreditCardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCreditCardOrderBy>>;
  where?: Maybe<DemiplaneCreditCardBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserEventsArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserEventsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserFavoriteAdventuringPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserFavoriteAdventuringPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserFavoriteGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserFavoriteGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserGmRecommendationsByGmIdArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserGmRecommendationsByGmIdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserGmRecommendationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserGmRecommendationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserNotificationHistoriesBySubmitorIdArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserNotificationHistoriesBySubmitorIdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserNotificationHistoriesArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserNotificationHistoriesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserPaymentsArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserPaymentsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserSessionPlayersArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserSessionPlayersAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserTransactionReceiptsArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserTransactionReceiptsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserActivitiesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserActivityOrderBy>>;
  where?: Maybe<DemiplaneUserActivityBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserActivitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserActivityOrderBy>>;
  where?: Maybe<DemiplaneUserActivityBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserAdventureRankingsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserAdventureRankingsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserAdventureRolesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserAdventureRolesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserAdventuresArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserAdventuresAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserInvitationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserInvitationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserRolesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};


/** columns and relationships of "demiplane.user" */
export type DemiplaneUserUserRolesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};

/** columns and relationships of "demiplane.user_activity" */
export type DemiplaneUserActivity = {
  __typename?: 'demiplane_user_activity';
  access_date: Scalars['date'];
  id: Scalars['bigint'];
  ip?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: DemiplaneUser;
  user_agent?: Maybe<Scalars['String']>;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_activity" */
export type DemiplaneUserActivityAggregate = {
  __typename?: 'demiplane_user_activity_aggregate';
  aggregate?: Maybe<DemiplaneUserActivityAggregateFields>;
  nodes: Array<DemiplaneUserActivity>;
};

/** aggregate fields of "demiplane.user_activity" */
export type DemiplaneUserActivityAggregateFields = {
  __typename?: 'demiplane_user_activity_aggregate_fields';
  avg?: Maybe<DemiplaneUserActivityAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserActivityMaxFields>;
  min?: Maybe<DemiplaneUserActivityMinFields>;
  stddev?: Maybe<DemiplaneUserActivityStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserActivityStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserActivityStddevSampFields>;
  sum?: Maybe<DemiplaneUserActivitySumFields>;
  var_pop?: Maybe<DemiplaneUserActivityVarPopFields>;
  var_samp?: Maybe<DemiplaneUserActivityVarSampFields>;
  variance?: Maybe<DemiplaneUserActivityVarianceFields>;
};


/** aggregate fields of "demiplane.user_activity" */
export type DemiplaneUserActivityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserActivitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_activity" */
export type DemiplaneUserActivityAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserActivityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserActivityMaxOrderBy>;
  min?: Maybe<DemiplaneUserActivityMinOrderBy>;
  stddev?: Maybe<DemiplaneUserActivityStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserActivityStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserActivityStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserActivitySumOrderBy>;
  var_pop?: Maybe<DemiplaneUserActivityVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserActivityVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserActivityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_activity" */
export type DemiplaneUserActivityArrRelInsertInput = {
  data: Array<DemiplaneUserActivityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserActivityOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserActivityAvgFields = {
  __typename?: 'demiplane_user_activity_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityAvgOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_activity". All fields are combined with a logical 'AND'. */
export type DemiplaneUserActivityBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserActivityBoolExp>>>;
  _not?: Maybe<DemiplaneUserActivityBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserActivityBoolExp>>>;
  access_date?: Maybe<DateComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  ip?: Maybe<StringComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_agent?: Maybe<StringComparisonExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_activity" */
export enum DemiplaneUserActivityConstraint {
  /** unique or primary key constraint */
  PkUserActivityId = 'pk_user_activity_id'
}

/** input type for incrementing integer column in table "demiplane.user_activity" */
export type DemiplaneUserActivityIncInput = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_activity" */
export type DemiplaneUserActivityInsertInput = {
  access_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  ip?: Maybe<Scalars['String']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserActivityMaxFields = {
  __typename?: 'demiplane_user_activity_max_fields';
  access_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  ip?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityMaxOrderBy = {
  access_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  user_agent?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserActivityMinFields = {
  __typename?: 'demiplane_user_activity_min_fields';
  access_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  ip?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityMinOrderBy = {
  access_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  user_agent?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_activity" */
export type DemiplaneUserActivityMutationResponse = {
  __typename?: 'demiplane_user_activity_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserActivity>;
};

/** input type for inserting object relation for remote table "demiplane.user_activity" */
export type DemiplaneUserActivityObjRelInsertInput = {
  data: DemiplaneUserActivityInsertInput;
  on_conflict?: Maybe<DemiplaneUserActivityOnConflict>;
};

/** on conflict condition type for table "demiplane.user_activity" */
export type DemiplaneUserActivityOnConflict = {
  constraint: DemiplaneUserActivityConstraint;
  update_columns: Array<DemiplaneUserActivityUpdateColumn>;
  where?: Maybe<DemiplaneUserActivityBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_activity" */
export type DemiplaneUserActivityOrderBy = {
  access_date?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_agent?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_activity" */
export type DemiplaneUserActivityPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_activity" */
export enum DemiplaneUserActivitySelectColumn {
  /** column name */
  AccessDate = 'access_date',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_activity" */
export type DemiplaneUserActivitySetInput = {
  access_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  ip?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserActivityStddevFields = {
  __typename?: 'demiplane_user_activity_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityStddevOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserActivityStddevPopFields = {
  __typename?: 'demiplane_user_activity_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserActivityStddevSampFields = {
  __typename?: 'demiplane_user_activity_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserActivitySumFields = {
  __typename?: 'demiplane_user_activity_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivitySumOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_activity" */
export enum DemiplaneUserActivityUpdateColumn {
  /** column name */
  AccessDate = 'access_date',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserActivityVarPopFields = {
  __typename?: 'demiplane_user_activity_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserActivityVarSampFields = {
  __typename?: 'demiplane_user_activity_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserActivityVarianceFields = {
  __typename?: 'demiplane_user_activity_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_activity" */
export type DemiplaneUserActivityVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.user_adventure" */
export type DemiplaneUserAdventure = {
  __typename?: 'demiplane_user_adventure';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_adventure" */
export type DemiplaneUserAdventureAggregate = {
  __typename?: 'demiplane_user_adventure_aggregate';
  aggregate?: Maybe<DemiplaneUserAdventureAggregateFields>;
  nodes: Array<DemiplaneUserAdventure>;
};

/** aggregate fields of "demiplane.user_adventure" */
export type DemiplaneUserAdventureAggregateFields = {
  __typename?: 'demiplane_user_adventure_aggregate_fields';
  avg?: Maybe<DemiplaneUserAdventureAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserAdventureMaxFields>;
  min?: Maybe<DemiplaneUserAdventureMinFields>;
  stddev?: Maybe<DemiplaneUserAdventureStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserAdventureStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserAdventureStddevSampFields>;
  sum?: Maybe<DemiplaneUserAdventureSumFields>;
  var_pop?: Maybe<DemiplaneUserAdventureVarPopFields>;
  var_samp?: Maybe<DemiplaneUserAdventureVarSampFields>;
  variance?: Maybe<DemiplaneUserAdventureVarianceFields>;
};


/** aggregate fields of "demiplane.user_adventure" */
export type DemiplaneUserAdventureAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserAdventureAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserAdventureMaxOrderBy>;
  min?: Maybe<DemiplaneUserAdventureMinOrderBy>;
  stddev?: Maybe<DemiplaneUserAdventureStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserAdventureStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserAdventureStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserAdventureSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserAdventureVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserAdventureVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserAdventureVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_adventure" */
export type DemiplaneUserAdventureArrRelInsertInput = {
  data: Array<DemiplaneUserAdventureInsertInput>;
  on_conflict?: Maybe<DemiplaneUserAdventureOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserAdventureAvgFields = {
  __typename?: 'demiplane_user_adventure_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_adventure". All fields are combined with a logical 'AND'. */
export type DemiplaneUserAdventureBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserAdventureBoolExp>>>;
  _not?: Maybe<DemiplaneUserAdventureBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserAdventureBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_adventure" */
export enum DemiplaneUserAdventureConstraint {
  /** unique or primary key constraint */
  UserAdventureIdUserIdAdventureIdKey = 'user_adventure_id_user_id_adventure_id_key',
  /** unique or primary key constraint */
  UserAdventurePkey = 'user_adventure_pkey'
}

/** input type for incrementing integer column in table "demiplane.user_adventure" */
export type DemiplaneUserAdventureIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_adventure" */
export type DemiplaneUserAdventureInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserAdventureMaxFields = {
  __typename?: 'demiplane_user_adventure_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserAdventureMinFields = {
  __typename?: 'demiplane_user_adventure_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_adventure" */
export type DemiplaneUserAdventureMutationResponse = {
  __typename?: 'demiplane_user_adventure_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserAdventure>;
};

/** input type for inserting object relation for remote table "demiplane.user_adventure" */
export type DemiplaneUserAdventureObjRelInsertInput = {
  data: DemiplaneUserAdventureInsertInput;
  on_conflict?: Maybe<DemiplaneUserAdventureOnConflict>;
};

/** on conflict condition type for table "demiplane.user_adventure" */
export type DemiplaneUserAdventureOnConflict = {
  constraint: DemiplaneUserAdventureConstraint;
  update_columns: Array<DemiplaneUserAdventureUpdateColumn>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_adventure" */
export type DemiplaneUserAdventureOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_adventure" */
export type DemiplaneUserAdventurePkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRanking = {
  __typename?: 'demiplane_user_adventure_ranking';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  created?: Maybe<Scalars['timestamptz']>;
  featured: Scalars['Boolean'];
  id: Scalars['bigint'];
  sort_order: Scalars['Int'];
  updated?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingAggregate = {
  __typename?: 'demiplane_user_adventure_ranking_aggregate';
  aggregate?: Maybe<DemiplaneUserAdventureRankingAggregateFields>;
  nodes: Array<DemiplaneUserAdventureRanking>;
};

/** aggregate fields of "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingAggregateFields = {
  __typename?: 'demiplane_user_adventure_ranking_aggregate_fields';
  avg?: Maybe<DemiplaneUserAdventureRankingAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserAdventureRankingMaxFields>;
  min?: Maybe<DemiplaneUserAdventureRankingMinFields>;
  stddev?: Maybe<DemiplaneUserAdventureRankingStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserAdventureRankingStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserAdventureRankingStddevSampFields>;
  sum?: Maybe<DemiplaneUserAdventureRankingSumFields>;
  var_pop?: Maybe<DemiplaneUserAdventureRankingVarPopFields>;
  var_samp?: Maybe<DemiplaneUserAdventureRankingVarSampFields>;
  variance?: Maybe<DemiplaneUserAdventureRankingVarianceFields>;
};


/** aggregate fields of "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserAdventureRankingAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserAdventureRankingMaxOrderBy>;
  min?: Maybe<DemiplaneUserAdventureRankingMinOrderBy>;
  stddev?: Maybe<DemiplaneUserAdventureRankingStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserAdventureRankingStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserAdventureRankingStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserAdventureRankingSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserAdventureRankingVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserAdventureRankingVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserAdventureRankingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingArrRelInsertInput = {
  data: Array<DemiplaneUserAdventureRankingInsertInput>;
  on_conflict?: Maybe<DemiplaneUserAdventureRankingOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserAdventureRankingAvgFields = {
  __typename?: 'demiplane_user_adventure_ranking_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_adventure_ranking". All fields are combined with a logical 'AND'. */
export type DemiplaneUserAdventureRankingBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserAdventureRankingBoolExp>>>;
  _not?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserAdventureRankingBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  featured?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  sort_order?: Maybe<IntComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_adventure_ranking" */
export enum DemiplaneUserAdventureRankingConstraint {
  /** unique or primary key constraint */
  UserAdventureRankingPkey = 'user_adventure_ranking_pkey',
  /** unique or primary key constraint */
  UserAdventureRankingUserIdAdventureIdKey = 'user_adventure_ranking_user_id_adventure_id_key'
}

/** input type for incrementing integer column in table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  featured?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserAdventureRankingMaxFields = {
  __typename?: 'demiplane_user_adventure_ranking_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserAdventureRankingMinFields = {
  __typename?: 'demiplane_user_adventure_ranking_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingMutationResponse = {
  __typename?: 'demiplane_user_adventure_ranking_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserAdventureRanking>;
};

/** input type for inserting object relation for remote table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingObjRelInsertInput = {
  data: DemiplaneUserAdventureRankingInsertInput;
  on_conflict?: Maybe<DemiplaneUserAdventureRankingOnConflict>;
};

/** on conflict condition type for table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingOnConflict = {
  constraint: DemiplaneUserAdventureRankingConstraint;
  update_columns: Array<DemiplaneUserAdventureRankingUpdateColumn>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  featured?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_adventure_ranking" */
export enum DemiplaneUserAdventureRankingSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Featured = 'featured',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  created?: Maybe<Scalars['timestamptz']>;
  featured?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserAdventureRankingStddevFields = {
  __typename?: 'demiplane_user_adventure_ranking_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserAdventureRankingStddevPopFields = {
  __typename?: 'demiplane_user_adventure_ranking_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserAdventureRankingStddevSampFields = {
  __typename?: 'demiplane_user_adventure_ranking_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserAdventureRankingSumFields = {
  __typename?: 'demiplane_user_adventure_ranking_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_adventure_ranking" */
export enum DemiplaneUserAdventureRankingUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Created = 'created',
  /** column name */
  Featured = 'featured',
  /** column name */
  Id = 'id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserAdventureRankingVarPopFields = {
  __typename?: 'demiplane_user_adventure_ranking_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserAdventureRankingVarSampFields = {
  __typename?: 'demiplane_user_adventure_ranking_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserAdventureRankingVarianceFields = {
  __typename?: 'demiplane_user_adventure_ranking_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_adventure_ranking" */
export type DemiplaneUserAdventureRankingVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sort_order?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRole = {
  __typename?: 'demiplane_user_adventure_role';
  /** An object relationship */
  adventure: DemiplaneAdventure;
  adventure_id: Scalars['bigint'];
  deleted?: Maybe<Scalars['timestamp']>;
  id: Scalars['bigint'];
  /** An object relationship */
  role: DemiplaneRole;
  role_id: Scalars['bigint'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleAggregate = {
  __typename?: 'demiplane_user_adventure_role_aggregate';
  aggregate?: Maybe<DemiplaneUserAdventureRoleAggregateFields>;
  nodes: Array<DemiplaneUserAdventureRole>;
};

/** aggregate fields of "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleAggregateFields = {
  __typename?: 'demiplane_user_adventure_role_aggregate_fields';
  avg?: Maybe<DemiplaneUserAdventureRoleAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserAdventureRoleMaxFields>;
  min?: Maybe<DemiplaneUserAdventureRoleMinFields>;
  stddev?: Maybe<DemiplaneUserAdventureRoleStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserAdventureRoleStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserAdventureRoleStddevSampFields>;
  sum?: Maybe<DemiplaneUserAdventureRoleSumFields>;
  var_pop?: Maybe<DemiplaneUserAdventureRoleVarPopFields>;
  var_samp?: Maybe<DemiplaneUserAdventureRoleVarSampFields>;
  variance?: Maybe<DemiplaneUserAdventureRoleVarianceFields>;
};


/** aggregate fields of "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserAdventureRoleAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserAdventureRoleMaxOrderBy>;
  min?: Maybe<DemiplaneUserAdventureRoleMinOrderBy>;
  stddev?: Maybe<DemiplaneUserAdventureRoleStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserAdventureRoleStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserAdventureRoleStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserAdventureRoleSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserAdventureRoleVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserAdventureRoleVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserAdventureRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleArrRelInsertInput = {
  data: Array<DemiplaneUserAdventureRoleInsertInput>;
  on_conflict?: Maybe<DemiplaneUserAdventureRoleOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserAdventureRoleAvgFields = {
  __typename?: 'demiplane_user_adventure_role_avg_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleAvgOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_adventure_role". All fields are combined with a logical 'AND'. */
export type DemiplaneUserAdventureRoleBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserAdventureRoleBoolExp>>>;
  _not?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserAdventureRoleBoolExp>>>;
  adventure?: Maybe<DemiplaneAdventureBoolExp>;
  adventure_id?: Maybe<BigintComparisonExp>;
  deleted?: Maybe<TimestampComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  role?: Maybe<DemiplaneRoleBoolExp>;
  role_id?: Maybe<BigintComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_adventure_role" */
export enum DemiplaneUserAdventureRoleConstraint {
  /** unique or primary key constraint */
  PkUserAdventureRoleId = 'pk_user_adventure_role_id'
}

/** input type for incrementing integer column in table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleIncInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleInsertInput = {
  adventure?: Maybe<DemiplaneAdventureObjRelInsertInput>;
  adventure_id?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role?: Maybe<DemiplaneRoleObjRelInsertInput>;
  role_id?: Maybe<Scalars['bigint']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserAdventureRoleMaxFields = {
  __typename?: 'demiplane_user_adventure_role_max_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleMaxOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserAdventureRoleMinFields = {
  __typename?: 'demiplane_user_adventure_role_min_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleMinOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleMutationResponse = {
  __typename?: 'demiplane_user_adventure_role_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserAdventureRole>;
};

/** input type for inserting object relation for remote table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleObjRelInsertInput = {
  data: DemiplaneUserAdventureRoleInsertInput;
  on_conflict?: Maybe<DemiplaneUserAdventureRoleOnConflict>;
};

/** on conflict condition type for table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleOnConflict = {
  constraint: DemiplaneUserAdventureRoleConstraint;
  update_columns: Array<DemiplaneUserAdventureRoleUpdateColumn>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleOrderBy = {
  adventure?: Maybe<DemiplaneAdventureOrderBy>;
  adventure_id?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role?: Maybe<DemiplaneRoleOrderBy>;
  role_id?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRolePkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_adventure_role" */
export enum DemiplaneUserAdventureRoleSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserAdventureRoleStddevFields = {
  __typename?: 'demiplane_user_adventure_role_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserAdventureRoleStddevPopFields = {
  __typename?: 'demiplane_user_adventure_role_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserAdventureRoleStddevSampFields = {
  __typename?: 'demiplane_user_adventure_role_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserAdventureRoleSumFields = {
  __typename?: 'demiplane_user_adventure_role_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_adventure_role" */
export enum DemiplaneUserAdventureRoleUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserAdventureRoleVarPopFields = {
  __typename?: 'demiplane_user_adventure_role_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserAdventureRoleVarSampFields = {
  __typename?: 'demiplane_user_adventure_role_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserAdventureRoleVarianceFields = {
  __typename?: 'demiplane_user_adventure_role_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_adventure_role" */
export type DemiplaneUserAdventureRoleVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  role_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.user_adventure" */
export enum DemiplaneUserAdventureSelectColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_adventure" */
export type DemiplaneUserAdventureSetInput = {
  adventure_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserAdventureStddevFields = {
  __typename?: 'demiplane_user_adventure_stddev_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureStddevOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserAdventureStddevPopFields = {
  __typename?: 'demiplane_user_adventure_stddev_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureStddevPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserAdventureStddevSampFields = {
  __typename?: 'demiplane_user_adventure_stddev_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureStddevSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserAdventureSumFields = {
  __typename?: 'demiplane_user_adventure_sum_fields';
  adventure_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureSumOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_adventure" */
export enum DemiplaneUserAdventureUpdateColumn {
  /** column name */
  AdventureId = 'adventure_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserAdventureVarPopFields = {
  __typename?: 'demiplane_user_adventure_var_pop_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureVarPopOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserAdventureVarSampFields = {
  __typename?: 'demiplane_user_adventure_var_samp_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureVarSampOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserAdventureVarianceFields = {
  __typename?: 'demiplane_user_adventure_variance_fields';
  adventure_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_adventure" */
export type DemiplaneUserAdventureVarianceOrderBy = {
  adventure_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregated selection of "demiplane.user" */
export type DemiplaneUserAggregate = {
  __typename?: 'demiplane_user_aggregate';
  aggregate?: Maybe<DemiplaneUserAggregateFields>;
  nodes: Array<DemiplaneUser>;
};

/** aggregate fields of "demiplane.user" */
export type DemiplaneUserAggregateFields = {
  __typename?: 'demiplane_user_aggregate_fields';
  avg?: Maybe<DemiplaneUserAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserMaxFields>;
  min?: Maybe<DemiplaneUserMinFields>;
  stddev?: Maybe<DemiplaneUserStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserStddevSampFields>;
  sum?: Maybe<DemiplaneUserSumFields>;
  var_pop?: Maybe<DemiplaneUserVarPopFields>;
  var_samp?: Maybe<DemiplaneUserVarSampFields>;
  variance?: Maybe<DemiplaneUserVarianceFields>;
};


/** aggregate fields of "demiplane.user" */
export type DemiplaneUserAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user" */
export type DemiplaneUserAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserMaxOrderBy>;
  min?: Maybe<DemiplaneUserMinOrderBy>;
  stddev?: Maybe<DemiplaneUserStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user" */
export type DemiplaneUserArrRelInsertInput = {
  data: Array<DemiplaneUserInsertInput>;
  on_conflict?: Maybe<DemiplaneUserOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserAvgFields = {
  __typename?: 'demiplane_user_avg_fields';
  combat?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  experience?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  pronoun_id?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user" */
export type DemiplaneUserAvgOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user". All fields are combined with a logical 'AND'. */
export type DemiplaneUserBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserBoolExp>>>;
  _not?: Maybe<DemiplaneUserBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserBoolExp>>>;
  access_level?: Maybe<StringComparisonExp>;
  accessibility_friendly?: Maybe<BooleanComparisonExp>;
  ad_requests?: Maybe<DemiplaneAdRequestBoolExp>;
  address1?: Maybe<StringComparisonExp>;
  address2?: Maybe<StringComparisonExp>;
  ads?: Maybe<DemiplaneAdBoolExp>;
  adventureConnectionsByContactorId?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  adventureConnectionsByRequestorId?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  adventure_connections?: Maybe<DemiplaneAdventureConnectionBoolExp>;
  adventure_players?: Maybe<DemiplaneAdventurePlayerBoolExp>;
  adventures?: Maybe<DemiplaneAdventureBoolExp>;
  city?: Maybe<StringComparisonExp>;
  cognito_id?: Maybe<StringComparisonExp>;
  combat?: Maybe<IntComparisonExp>;
  country?: Maybe<DemiplaneCountryBoolExp>;
  country_id?: Maybe<IntComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  credit_cards?: Maybe<DemiplaneCreditCardBoolExp>;
  dob?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  events?: Maybe<DemiplaneEventBoolExp>;
  experience?: Maybe<IntComparisonExp>;
  facebook_profile?: Maybe<StringComparisonExp>;
  favorite_adventuring_platforms?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
  favorite_games?: Maybe<DemiplaneFavoriteGameBoolExp>;
  feature_level?: Maybe<StringComparisonExp>;
  first_login?: Maybe<BooleanComparisonExp>;
  first_name?: Maybe<StringComparisonExp>;
  game_master_bio?: Maybe<StringComparisonExp>;
  gmRecommendationsByGmId?: Maybe<DemiplaneGmRecommendationBoolExp>;
  gm_recommendations?: Maybe<DemiplaneGmRecommendationBoolExp>;
  id?: Maybe<BigintComparisonExp>;
  inactive_month_alerted_email?: Maybe<BooleanComparisonExp>;
  inactive_two_month_alerted_email?: Maybe<BooleanComparisonExp>;
  inactive_week_alerted_email?: Maybe<BooleanComparisonExp>;
  instagram_profile?: Maybe<StringComparisonExp>;
  languages?: Maybe<StringComparisonExp>;
  last_access?: Maybe<TimestamptzComparisonExp>;
  last_name?: Maybe<StringComparisonExp>;
  lgbtqia_friendly?: Maybe<BooleanComparisonExp>;
  mind_theater_combat?: Maybe<BooleanComparisonExp>;
  new_user_second_email?: Maybe<BooleanComparisonExp>;
  new_user_third_email?: Maybe<BooleanComparisonExp>;
  notificationHistoriesBySubmitorId?: Maybe<DemiplaneNotificationHistoryBoolExp>;
  notification_histories?: Maybe<DemiplaneNotificationHistoryBoolExp>;
  payments?: Maybe<DemiplanePaymentBoolExp>;
  player_story?: Maybe<IntComparisonExp>;
  pronoun?: Maybe<DemiplanePronounBoolExp>;
  pronoun_id?: Maybe<BigintComparisonExp>;
  puzzles_logic?: Maybe<IntComparisonExp>;
  recruitment_set_or_alerted?: Maybe<BooleanComparisonExp>;
  region?: Maybe<DemiplaneRegionBoolExp>;
  region_id?: Maybe<BigintComparisonExp>;
  role_playing?: Maybe<IntComparisonExp>;
  session_players?: Maybe<DemiplaneSessionPlayerBoolExp>;
  social_intrigue?: Maybe<IntComparisonExp>;
  strict_rules?: Maybe<IntComparisonExp>;
  stripe_connect_id?: Maybe<StringComparisonExp>;
  stripe_customer_id?: Maybe<StringComparisonExp>;
  tactical_grid_combat?: Maybe<BooleanComparisonExp>;
  time_zone?: Maybe<StringComparisonExp>;
  transaction_receipts?: Maybe<DemiplaneTransactionReceiptBoolExp>;
  tutorial_seen?: Maybe<BooleanComparisonExp>;
  twitch_profile?: Maybe<StringComparisonExp>;
  twitter_profile?: Maybe<StringComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  upload?: Maybe<DemiplaneUploadBoolExp>;
  user_activities?: Maybe<DemiplaneUserActivityBoolExp>;
  user_adventure_rankings?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
  user_adventure_roles?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
  user_adventures?: Maybe<DemiplaneUserAdventureBoolExp>;
  user_email_notification_pref?: Maybe<DemiplaneUserEmailNotificationPrefBoolExp>;
  user_invitations?: Maybe<DemiplaneUserInvitationBoolExp>;
  user_metadatum?: Maybe<DemiplaneUserMetadataBoolExp>;
  user_push_notification_pref?: Maybe<DemiplaneUserPushNotificationPrefBoolExp>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentBoolExp>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
  user_roles?: Maybe<DemiplaneUserRoleBoolExp>;
  username?: Maybe<StringComparisonExp>;
  uses_video_webcam?: Maybe<BooleanComparisonExp>;
  uses_voice_mic?: Maybe<BooleanComparisonExp>;
  zipcode?: Maybe<StringComparisonExp>;
  zipcode_plus_4_code?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user" */
export enum DemiplaneUserConstraint {
  /** unique or primary key constraint */
  PkUserId = 'pk_user_id',
  /** unique or primary key constraint */
  UserCognitoIdKey = 'user_cognito_id_key',
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserUsernameKey = 'user_username_key'
}

/** columns and relationships of "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPref = {
  __typename?: 'demiplane_user_email_notification_pref';
  adventure_rallying: Scalars['Boolean'];
  adventure_rescheduled: Scalars['Boolean'];
  adventure_scheduled: Scalars['Boolean'];
  adventurer_leaves_adventure: Scalars['Boolean'];
  ap_launches_adventure: Scalars['Boolean'];
  created: Scalars['timestamptz'];
  created_first_quest: Scalars['Boolean'];
  id: Scalars['bigint'];
  invited_to_quest_or_party: Scalars['Boolean'];
  post_adventure_reminder: Scalars['Boolean'];
  quest_ap_creator_launches_adventure: Scalars['Boolean'];
  quest_launches_adventure: Scalars['Boolean'];
  request_to_join_accept_decline: Scalars['Boolean'];
  request_to_join_received: Scalars['Boolean'];
  unread_notifications: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_id?: Maybe<Scalars['bigint']>;
  weekly_journal_alert: Scalars['Boolean'];
  weekly_task_alert: Scalars['Boolean'];
};

/** aggregated selection of "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefAggregate = {
  __typename?: 'demiplane_user_email_notification_pref_aggregate';
  aggregate?: Maybe<DemiplaneUserEmailNotificationPrefAggregateFields>;
  nodes: Array<DemiplaneUserEmailNotificationPref>;
};

/** aggregate fields of "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefAggregateFields = {
  __typename?: 'demiplane_user_email_notification_pref_aggregate_fields';
  avg?: Maybe<DemiplaneUserEmailNotificationPrefAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserEmailNotificationPrefMaxFields>;
  min?: Maybe<DemiplaneUserEmailNotificationPrefMinFields>;
  stddev?: Maybe<DemiplaneUserEmailNotificationPrefStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserEmailNotificationPrefStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserEmailNotificationPrefStddevSampFields>;
  sum?: Maybe<DemiplaneUserEmailNotificationPrefSumFields>;
  var_pop?: Maybe<DemiplaneUserEmailNotificationPrefVarPopFields>;
  var_samp?: Maybe<DemiplaneUserEmailNotificationPrefVarSampFields>;
  variance?: Maybe<DemiplaneUserEmailNotificationPrefVarianceFields>;
};


/** aggregate fields of "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserEmailNotificationPrefSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserEmailNotificationPrefAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserEmailNotificationPrefMaxOrderBy>;
  min?: Maybe<DemiplaneUserEmailNotificationPrefMinOrderBy>;
  stddev?: Maybe<DemiplaneUserEmailNotificationPrefStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserEmailNotificationPrefStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserEmailNotificationPrefStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserEmailNotificationPrefSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserEmailNotificationPrefVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserEmailNotificationPrefVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserEmailNotificationPrefVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefArrRelInsertInput = {
  data: Array<DemiplaneUserEmailNotificationPrefInsertInput>;
  on_conflict?: Maybe<DemiplaneUserEmailNotificationPrefOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserEmailNotificationPrefAvgFields = {
  __typename?: 'demiplane_user_email_notification_pref_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefAvgOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_email_notification_pref". All fields are combined with a logical 'AND'. */
export type DemiplaneUserEmailNotificationPrefBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserEmailNotificationPrefBoolExp>>>;
  _not?: Maybe<DemiplaneUserEmailNotificationPrefBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserEmailNotificationPrefBoolExp>>>;
  adventure_rallying?: Maybe<BooleanComparisonExp>;
  adventure_rescheduled?: Maybe<BooleanComparisonExp>;
  adventure_scheduled?: Maybe<BooleanComparisonExp>;
  adventurer_leaves_adventure?: Maybe<BooleanComparisonExp>;
  ap_launches_adventure?: Maybe<BooleanComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  created_first_quest?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  invited_to_quest_or_party?: Maybe<BooleanComparisonExp>;
  post_adventure_reminder?: Maybe<BooleanComparisonExp>;
  quest_ap_creator_launches_adventure?: Maybe<BooleanComparisonExp>;
  quest_launches_adventure?: Maybe<BooleanComparisonExp>;
  request_to_join_accept_decline?: Maybe<BooleanComparisonExp>;
  request_to_join_received?: Maybe<BooleanComparisonExp>;
  unread_notifications?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  weekly_journal_alert?: Maybe<BooleanComparisonExp>;
  weekly_task_alert?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_email_notification_pref" */
export enum DemiplaneUserEmailNotificationPrefConstraint {
  /** unique or primary key constraint */
  RelC5e4623b311d4a0180d98351a8 = 'REL_c5e4623b311d4a0180d98351a8',
  /** unique or primary key constraint */
  PkUserEmailNotificationPrefId = 'pk_user_email_notification_pref_id'
}

/** input type for incrementing integer column in table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefIncInput = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefInsertInput = {
  adventure_rallying?: Maybe<Scalars['Boolean']>;
  adventure_rescheduled?: Maybe<Scalars['Boolean']>;
  adventure_scheduled?: Maybe<Scalars['Boolean']>;
  adventurer_leaves_adventure?: Maybe<Scalars['Boolean']>;
  ap_launches_adventure?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_first_quest?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  invited_to_quest_or_party?: Maybe<Scalars['Boolean']>;
  post_adventure_reminder?: Maybe<Scalars['Boolean']>;
  quest_ap_creator_launches_adventure?: Maybe<Scalars['Boolean']>;
  quest_launches_adventure?: Maybe<Scalars['Boolean']>;
  request_to_join_accept_decline?: Maybe<Scalars['Boolean']>;
  request_to_join_received?: Maybe<Scalars['Boolean']>;
  unread_notifications?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  weekly_journal_alert?: Maybe<Scalars['Boolean']>;
  weekly_task_alert?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type DemiplaneUserEmailNotificationPrefMaxFields = {
  __typename?: 'demiplane_user_email_notification_pref_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserEmailNotificationPrefMinFields = {
  __typename?: 'demiplane_user_email_notification_pref_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefMutationResponse = {
  __typename?: 'demiplane_user_email_notification_pref_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserEmailNotificationPref>;
};

/** input type for inserting object relation for remote table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefObjRelInsertInput = {
  data: DemiplaneUserEmailNotificationPrefInsertInput;
  on_conflict?: Maybe<DemiplaneUserEmailNotificationPrefOnConflict>;
};

/** on conflict condition type for table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefOnConflict = {
  constraint: DemiplaneUserEmailNotificationPrefConstraint;
  update_columns: Array<DemiplaneUserEmailNotificationPrefUpdateColumn>;
  where?: Maybe<DemiplaneUserEmailNotificationPrefBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefOrderBy = {
  adventure_rallying?: Maybe<OrderBy>;
  adventure_rescheduled?: Maybe<OrderBy>;
  adventure_scheduled?: Maybe<OrderBy>;
  adventurer_leaves_adventure?: Maybe<OrderBy>;
  ap_launches_adventure?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  created_first_quest?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invited_to_quest_or_party?: Maybe<OrderBy>;
  post_adventure_reminder?: Maybe<OrderBy>;
  quest_ap_creator_launches_adventure?: Maybe<OrderBy>;
  quest_launches_adventure?: Maybe<OrderBy>;
  request_to_join_accept_decline?: Maybe<OrderBy>;
  request_to_join_received?: Maybe<OrderBy>;
  unread_notifications?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  weekly_journal_alert?: Maybe<OrderBy>;
  weekly_task_alert?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_email_notification_pref" */
export enum DemiplaneUserEmailNotificationPrefSelectColumn {
  /** column name */
  AdventureRallying = 'adventure_rallying',
  /** column name */
  AdventureRescheduled = 'adventure_rescheduled',
  /** column name */
  AdventureScheduled = 'adventure_scheduled',
  /** column name */
  AdventurerLeavesAdventure = 'adventurer_leaves_adventure',
  /** column name */
  ApLaunchesAdventure = 'ap_launches_adventure',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedFirstQuest = 'created_first_quest',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedToQuestOrParty = 'invited_to_quest_or_party',
  /** column name */
  PostAdventureReminder = 'post_adventure_reminder',
  /** column name */
  QuestApCreatorLaunchesAdventure = 'quest_ap_creator_launches_adventure',
  /** column name */
  QuestLaunchesAdventure = 'quest_launches_adventure',
  /** column name */
  RequestToJoinAcceptDecline = 'request_to_join_accept_decline',
  /** column name */
  RequestToJoinReceived = 'request_to_join_received',
  /** column name */
  UnreadNotifications = 'unread_notifications',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WeeklyJournalAlert = 'weekly_journal_alert',
  /** column name */
  WeeklyTaskAlert = 'weekly_task_alert'
}

/** input type for updating data in table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefSetInput = {
  adventure_rallying?: Maybe<Scalars['Boolean']>;
  adventure_rescheduled?: Maybe<Scalars['Boolean']>;
  adventure_scheduled?: Maybe<Scalars['Boolean']>;
  adventurer_leaves_adventure?: Maybe<Scalars['Boolean']>;
  ap_launches_adventure?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_first_quest?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  invited_to_quest_or_party?: Maybe<Scalars['Boolean']>;
  post_adventure_reminder?: Maybe<Scalars['Boolean']>;
  quest_ap_creator_launches_adventure?: Maybe<Scalars['Boolean']>;
  quest_launches_adventure?: Maybe<Scalars['Boolean']>;
  request_to_join_accept_decline?: Maybe<Scalars['Boolean']>;
  request_to_join_received?: Maybe<Scalars['Boolean']>;
  unread_notifications?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
  weekly_journal_alert?: Maybe<Scalars['Boolean']>;
  weekly_task_alert?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserEmailNotificationPrefStddevFields = {
  __typename?: 'demiplane_user_email_notification_pref_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefStddevOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserEmailNotificationPrefStddevPopFields = {
  __typename?: 'demiplane_user_email_notification_pref_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserEmailNotificationPrefStddevSampFields = {
  __typename?: 'demiplane_user_email_notification_pref_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserEmailNotificationPrefSumFields = {
  __typename?: 'demiplane_user_email_notification_pref_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefSumOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_email_notification_pref" */
export enum DemiplaneUserEmailNotificationPrefUpdateColumn {
  /** column name */
  AdventureRallying = 'adventure_rallying',
  /** column name */
  AdventureRescheduled = 'adventure_rescheduled',
  /** column name */
  AdventureScheduled = 'adventure_scheduled',
  /** column name */
  AdventurerLeavesAdventure = 'adventurer_leaves_adventure',
  /** column name */
  ApLaunchesAdventure = 'ap_launches_adventure',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedFirstQuest = 'created_first_quest',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedToQuestOrParty = 'invited_to_quest_or_party',
  /** column name */
  PostAdventureReminder = 'post_adventure_reminder',
  /** column name */
  QuestApCreatorLaunchesAdventure = 'quest_ap_creator_launches_adventure',
  /** column name */
  QuestLaunchesAdventure = 'quest_launches_adventure',
  /** column name */
  RequestToJoinAcceptDecline = 'request_to_join_accept_decline',
  /** column name */
  RequestToJoinReceived = 'request_to_join_received',
  /** column name */
  UnreadNotifications = 'unread_notifications',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WeeklyJournalAlert = 'weekly_journal_alert',
  /** column name */
  WeeklyTaskAlert = 'weekly_task_alert'
}

/** aggregate var_pop on columns */
export type DemiplaneUserEmailNotificationPrefVarPopFields = {
  __typename?: 'demiplane_user_email_notification_pref_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserEmailNotificationPrefVarSampFields = {
  __typename?: 'demiplane_user_email_notification_pref_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserEmailNotificationPrefVarianceFields = {
  __typename?: 'demiplane_user_email_notification_pref_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_email_notification_pref" */
export type DemiplaneUserEmailNotificationPrefVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** input type for incrementing integer column in table "demiplane.user" */
export type DemiplaneUserIncInput = {
  combat?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  experience?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  pronoun_id?: Maybe<Scalars['bigint']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['bigint']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "demiplane.user" */
export type DemiplaneUserInsertInput = {
  access_level?: Maybe<Scalars['String']>;
  accessibility_friendly?: Maybe<Scalars['Boolean']>;
  ad_requests?: Maybe<DemiplaneAdRequestArrRelInsertInput>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  ads?: Maybe<DemiplaneAdArrRelInsertInput>;
  adventureConnectionsByContactorId?: Maybe<DemiplaneAdventureConnectionArrRelInsertInput>;
  adventureConnectionsByRequestorId?: Maybe<DemiplaneAdventureConnectionArrRelInsertInput>;
  adventure_connections?: Maybe<DemiplaneAdventureConnectionArrRelInsertInput>;
  adventure_players?: Maybe<DemiplaneAdventurePlayerArrRelInsertInput>;
  adventures?: Maybe<DemiplaneAdventureArrRelInsertInput>;
  city?: Maybe<Scalars['String']>;
  cognito_id?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  country?: Maybe<DemiplaneCountryObjRelInsertInput>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  credit_cards?: Maybe<DemiplaneCreditCardArrRelInsertInput>;
  dob?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  events?: Maybe<DemiplaneEventArrRelInsertInput>;
  experience?: Maybe<Scalars['Int']>;
  facebook_profile?: Maybe<Scalars['String']>;
  favorite_adventuring_platforms?: Maybe<DemiplaneFavoriteAdventuringPlatformArrRelInsertInput>;
  favorite_games?: Maybe<DemiplaneFavoriteGameArrRelInsertInput>;
  feature_level?: Maybe<Scalars['String']>;
  first_login?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  game_master_bio?: Maybe<Scalars['String']>;
  gmRecommendationsByGmId?: Maybe<DemiplaneGmRecommendationArrRelInsertInput>;
  gm_recommendations?: Maybe<DemiplaneGmRecommendationArrRelInsertInput>;
  id?: Maybe<Scalars['bigint']>;
  inactive_month_alerted_email?: Maybe<Scalars['Boolean']>;
  inactive_two_month_alerted_email?: Maybe<Scalars['Boolean']>;
  inactive_week_alerted_email?: Maybe<Scalars['Boolean']>;
  instagram_profile?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  last_access?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  lgbtqia_friendly?: Maybe<Scalars['Boolean']>;
  mind_theater_combat?: Maybe<Scalars['Boolean']>;
  new_user_second_email?: Maybe<Scalars['Boolean']>;
  new_user_third_email?: Maybe<Scalars['Boolean']>;
  notificationHistoriesBySubmitorId?: Maybe<DemiplaneNotificationHistoryArrRelInsertInput>;
  notification_histories?: Maybe<DemiplaneNotificationHistoryArrRelInsertInput>;
  payments?: Maybe<DemiplanePaymentArrRelInsertInput>;
  player_story?: Maybe<Scalars['Int']>;
  pronoun?: Maybe<DemiplanePronounObjRelInsertInput>;
  pronoun_id?: Maybe<Scalars['bigint']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  recruitment_set_or_alerted?: Maybe<Scalars['Boolean']>;
  region?: Maybe<DemiplaneRegionObjRelInsertInput>;
  region_id?: Maybe<Scalars['bigint']>;
  role_playing?: Maybe<Scalars['Int']>;
  session_players?: Maybe<DemiplaneSessionPlayerArrRelInsertInput>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  stripe_connect_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  tactical_grid_combat?: Maybe<Scalars['Boolean']>;
  time_zone?: Maybe<Scalars['String']>;
  transaction_receipts?: Maybe<DemiplaneTransactionReceiptArrRelInsertInput>;
  tutorial_seen?: Maybe<Scalars['Boolean']>;
  twitch_profile?: Maybe<Scalars['String']>;
  twitter_profile?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  upload?: Maybe<DemiplaneUploadObjRelInsertInput>;
  user_activities?: Maybe<DemiplaneUserActivityArrRelInsertInput>;
  user_adventure_rankings?: Maybe<DemiplaneUserAdventureRankingArrRelInsertInput>;
  user_adventure_roles?: Maybe<DemiplaneUserAdventureRoleArrRelInsertInput>;
  user_adventures?: Maybe<DemiplaneUserAdventureArrRelInsertInput>;
  user_email_notification_pref?: Maybe<DemiplaneUserEmailNotificationPrefObjRelInsertInput>;
  user_invitations?: Maybe<DemiplaneUserInvitationArrRelInsertInput>;
  user_metadatum?: Maybe<DemiplaneUserMetadataObjRelInsertInput>;
  user_push_notification_pref?: Maybe<DemiplaneUserPushNotificationPrefObjRelInsertInput>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentObjRelInsertInput>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerObjRelInsertInput>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterObjRelInsertInput>;
  user_roles?: Maybe<DemiplaneUserRoleArrRelInsertInput>;
  username?: Maybe<Scalars['String']>;
  uses_video_webcam?: Maybe<Scalars['Boolean']>;
  uses_voice_mic?: Maybe<Scalars['Boolean']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** columns and relationships of "demiplane.user_invitation" */
export type DemiplaneUserInvitation = {
  __typename?: 'demiplane_user_invitation';
  id: Scalars['bigint'];
  /** An object relationship */
  invitation: DemiplaneInvitation;
  invitation_id: Scalars['bigint'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_invitation" */
export type DemiplaneUserInvitationAggregate = {
  __typename?: 'demiplane_user_invitation_aggregate';
  aggregate?: Maybe<DemiplaneUserInvitationAggregateFields>;
  nodes: Array<DemiplaneUserInvitation>;
};

/** aggregate fields of "demiplane.user_invitation" */
export type DemiplaneUserInvitationAggregateFields = {
  __typename?: 'demiplane_user_invitation_aggregate_fields';
  avg?: Maybe<DemiplaneUserInvitationAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserInvitationMaxFields>;
  min?: Maybe<DemiplaneUserInvitationMinFields>;
  stddev?: Maybe<DemiplaneUserInvitationStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserInvitationStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserInvitationStddevSampFields>;
  sum?: Maybe<DemiplaneUserInvitationSumFields>;
  var_pop?: Maybe<DemiplaneUserInvitationVarPopFields>;
  var_samp?: Maybe<DemiplaneUserInvitationVarSampFields>;
  variance?: Maybe<DemiplaneUserInvitationVarianceFields>;
};


/** aggregate fields of "demiplane.user_invitation" */
export type DemiplaneUserInvitationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserInvitationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserInvitationMaxOrderBy>;
  min?: Maybe<DemiplaneUserInvitationMinOrderBy>;
  stddev?: Maybe<DemiplaneUserInvitationStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserInvitationStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserInvitationStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserInvitationSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserInvitationVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserInvitationVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserInvitationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_invitation" */
export type DemiplaneUserInvitationArrRelInsertInput = {
  data: Array<DemiplaneUserInvitationInsertInput>;
  on_conflict?: Maybe<DemiplaneUserInvitationOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserInvitationAvgFields = {
  __typename?: 'demiplane_user_invitation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationAvgOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_invitation". All fields are combined with a logical 'AND'. */
export type DemiplaneUserInvitationBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserInvitationBoolExp>>>;
  _not?: Maybe<DemiplaneUserInvitationBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserInvitationBoolExp>>>;
  id?: Maybe<BigintComparisonExp>;
  invitation?: Maybe<DemiplaneInvitationBoolExp>;
  invitation_id?: Maybe<BigintComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_invitation" */
export enum DemiplaneUserInvitationConstraint {
  /** unique or primary key constraint */
  PkUserInvitationId = 'pk_user_invitation_id'
}

/** input type for incrementing integer column in table "demiplane.user_invitation" */
export type DemiplaneUserInvitationIncInput = {
  id?: Maybe<Scalars['bigint']>;
  invitation_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_invitation" */
export type DemiplaneUserInvitationInsertInput = {
  id?: Maybe<Scalars['bigint']>;
  invitation?: Maybe<DemiplaneInvitationObjRelInsertInput>;
  invitation_id?: Maybe<Scalars['bigint']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserInvitationMaxFields = {
  __typename?: 'demiplane_user_invitation_max_fields';
  id?: Maybe<Scalars['bigint']>;
  invitation_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationMaxOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserInvitationMinFields = {
  __typename?: 'demiplane_user_invitation_min_fields';
  id?: Maybe<Scalars['bigint']>;
  invitation_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationMinOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_invitation" */
export type DemiplaneUserInvitationMutationResponse = {
  __typename?: 'demiplane_user_invitation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserInvitation>;
};

/** input type for inserting object relation for remote table "demiplane.user_invitation" */
export type DemiplaneUserInvitationObjRelInsertInput = {
  data: DemiplaneUserInvitationInsertInput;
  on_conflict?: Maybe<DemiplaneUserInvitationOnConflict>;
};

/** on conflict condition type for table "demiplane.user_invitation" */
export type DemiplaneUserInvitationOnConflict = {
  constraint: DemiplaneUserInvitationConstraint;
  update_columns: Array<DemiplaneUserInvitationUpdateColumn>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_invitation" */
export type DemiplaneUserInvitationOrderBy = {
  id?: Maybe<OrderBy>;
  invitation?: Maybe<DemiplaneInvitationOrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_invitation" */
export type DemiplaneUserInvitationPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_invitation" */
export enum DemiplaneUserInvitationSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_invitation" */
export type DemiplaneUserInvitationSetInput = {
  id?: Maybe<Scalars['bigint']>;
  invitation_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserInvitationStddevFields = {
  __typename?: 'demiplane_user_invitation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationStddevOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserInvitationStddevPopFields = {
  __typename?: 'demiplane_user_invitation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserInvitationStddevSampFields = {
  __typename?: 'demiplane_user_invitation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserInvitationSumFields = {
  __typename?: 'demiplane_user_invitation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  invitation_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationSumOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_invitation" */
export enum DemiplaneUserInvitationUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InvitationId = 'invitation_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserInvitationVarPopFields = {
  __typename?: 'demiplane_user_invitation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserInvitationVarSampFields = {
  __typename?: 'demiplane_user_invitation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserInvitationVarianceFields = {
  __typename?: 'demiplane_user_invitation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_invitation" */
export type DemiplaneUserInvitationVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  invitation_id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type DemiplaneUserMaxFields = {
  __typename?: 'demiplane_user_max_fields';
  access_level?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cognito_id?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['Int']>;
  facebook_profile?: Maybe<Scalars['String']>;
  feature_level?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  game_master_bio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_profile?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  last_access?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  player_story?: Maybe<Scalars['Int']>;
  pronoun_id?: Maybe<Scalars['bigint']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['bigint']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  stripe_connect_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  twitch_profile?: Maybe<Scalars['String']>;
  twitter_profile?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.user" */
export type DemiplaneUserMaxOrderBy = {
  access_level?: Maybe<OrderBy>;
  address1?: Maybe<OrderBy>;
  address2?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  cognito_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dob?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  facebook_profile?: Maybe<OrderBy>;
  feature_level?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  game_master_bio?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  instagram_profile?: Maybe<OrderBy>;
  languages?: Maybe<OrderBy>;
  last_access?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  stripe_connect_id?: Maybe<OrderBy>;
  stripe_customer_id?: Maybe<OrderBy>;
  time_zone?: Maybe<OrderBy>;
  twitch_profile?: Maybe<OrderBy>;
  twitter_profile?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  username?: Maybe<OrderBy>;
  zipcode?: Maybe<OrderBy>;
  zipcode_plus_4_code?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.user_metadata" */
export type DemiplaneUserMetadata = {
  __typename?: 'demiplane_user_metadata';
  accepted_terms: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_id?: Maybe<Scalars['bigint']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  viewed_active_gm_message: Scalars['Boolean'];
  viewed_active_player_message: Scalars['Boolean'];
  viewed_add_to_home_screen: Scalars['Boolean'];
  viewed_matched_gm_message: Scalars['Boolean'];
  viewed_matched_player_message: Scalars['Boolean'];
};

/** aggregated selection of "demiplane.user_metadata" */
export type DemiplaneUserMetadataAggregate = {
  __typename?: 'demiplane_user_metadata_aggregate';
  aggregate?: Maybe<DemiplaneUserMetadataAggregateFields>;
  nodes: Array<DemiplaneUserMetadata>;
};

/** aggregate fields of "demiplane.user_metadata" */
export type DemiplaneUserMetadataAggregateFields = {
  __typename?: 'demiplane_user_metadata_aggregate_fields';
  avg?: Maybe<DemiplaneUserMetadataAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserMetadataMaxFields>;
  min?: Maybe<DemiplaneUserMetadataMinFields>;
  stddev?: Maybe<DemiplaneUserMetadataStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserMetadataStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserMetadataStddevSampFields>;
  sum?: Maybe<DemiplaneUserMetadataSumFields>;
  var_pop?: Maybe<DemiplaneUserMetadataVarPopFields>;
  var_samp?: Maybe<DemiplaneUserMetadataVarSampFields>;
  variance?: Maybe<DemiplaneUserMetadataVarianceFields>;
};


/** aggregate fields of "demiplane.user_metadata" */
export type DemiplaneUserMetadataAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserMetadataSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserMetadataAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserMetadataMaxOrderBy>;
  min?: Maybe<DemiplaneUserMetadataMinOrderBy>;
  stddev?: Maybe<DemiplaneUserMetadataStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserMetadataStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserMetadataStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserMetadataSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserMetadataVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserMetadataVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserMetadataVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_metadata" */
export type DemiplaneUserMetadataArrRelInsertInput = {
  data: Array<DemiplaneUserMetadataInsertInput>;
  on_conflict?: Maybe<DemiplaneUserMetadataOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserMetadataAvgFields = {
  __typename?: 'demiplane_user_metadata_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataAvgOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_metadata". All fields are combined with a logical 'AND'. */
export type DemiplaneUserMetadataBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserMetadataBoolExp>>>;
  _not?: Maybe<DemiplaneUserMetadataBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserMetadataBoolExp>>>;
  accepted_terms?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  utm_campaign?: Maybe<StringComparisonExp>;
  utm_medium?: Maybe<StringComparisonExp>;
  utm_source?: Maybe<StringComparisonExp>;
  viewed_active_gm_message?: Maybe<BooleanComparisonExp>;
  viewed_active_player_message?: Maybe<BooleanComparisonExp>;
  viewed_add_to_home_screen?: Maybe<BooleanComparisonExp>;
  viewed_matched_gm_message?: Maybe<BooleanComparisonExp>;
  viewed_matched_player_message?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_metadata" */
export enum DemiplaneUserMetadataConstraint {
  /** unique or primary key constraint */
  PkUserMetadataId = 'pk_user_metadata_id',
  /** unique or primary key constraint */
  UqUserId = 'uq_user_id'
}

/** input type for incrementing integer column in table "demiplane.user_metadata" */
export type DemiplaneUserMetadataIncInput = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_metadata" */
export type DemiplaneUserMetadataInsertInput = {
  accepted_terms?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  viewed_active_gm_message?: Maybe<Scalars['Boolean']>;
  viewed_active_player_message?: Maybe<Scalars['Boolean']>;
  viewed_add_to_home_screen?: Maybe<Scalars['Boolean']>;
  viewed_matched_gm_message?: Maybe<Scalars['Boolean']>;
  viewed_matched_player_message?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type DemiplaneUserMetadataMaxFields = {
  __typename?: 'demiplane_user_metadata_max_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataMaxOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  utm_campaign?: Maybe<OrderBy>;
  utm_medium?: Maybe<OrderBy>;
  utm_source?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserMetadataMinFields = {
  __typename?: 'demiplane_user_metadata_min_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataMinOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  utm_campaign?: Maybe<OrderBy>;
  utm_medium?: Maybe<OrderBy>;
  utm_source?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_metadata" */
export type DemiplaneUserMetadataMutationResponse = {
  __typename?: 'demiplane_user_metadata_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserMetadata>;
};

/** input type for inserting object relation for remote table "demiplane.user_metadata" */
export type DemiplaneUserMetadataObjRelInsertInput = {
  data: DemiplaneUserMetadataInsertInput;
  on_conflict?: Maybe<DemiplaneUserMetadataOnConflict>;
};

/** on conflict condition type for table "demiplane.user_metadata" */
export type DemiplaneUserMetadataOnConflict = {
  constraint: DemiplaneUserMetadataConstraint;
  update_columns: Array<DemiplaneUserMetadataUpdateColumn>;
  where?: Maybe<DemiplaneUserMetadataBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_metadata" */
export type DemiplaneUserMetadataOrderBy = {
  accepted_terms?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  utm_campaign?: Maybe<OrderBy>;
  utm_medium?: Maybe<OrderBy>;
  utm_source?: Maybe<OrderBy>;
  viewed_active_gm_message?: Maybe<OrderBy>;
  viewed_active_player_message?: Maybe<OrderBy>;
  viewed_add_to_home_screen?: Maybe<OrderBy>;
  viewed_matched_gm_message?: Maybe<OrderBy>;
  viewed_matched_player_message?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_metadata" */
export type DemiplaneUserMetadataPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_metadata" */
export enum DemiplaneUserMetadataSelectColumn {
  /** column name */
  AcceptedTerms = 'accepted_terms',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  ViewedActiveGmMessage = 'viewed_active_gm_message',
  /** column name */
  ViewedActivePlayerMessage = 'viewed_active_player_message',
  /** column name */
  ViewedAddToHomeScreen = 'viewed_add_to_home_screen',
  /** column name */
  ViewedMatchedGmMessage = 'viewed_matched_gm_message',
  /** column name */
  ViewedMatchedPlayerMessage = 'viewed_matched_player_message'
}

/** input type for updating data in table "demiplane.user_metadata" */
export type DemiplaneUserMetadataSetInput = {
  accepted_terms?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['String']>;
  viewed_active_gm_message?: Maybe<Scalars['Boolean']>;
  viewed_active_player_message?: Maybe<Scalars['Boolean']>;
  viewed_add_to_home_screen?: Maybe<Scalars['Boolean']>;
  viewed_matched_gm_message?: Maybe<Scalars['Boolean']>;
  viewed_matched_player_message?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserMetadataStddevFields = {
  __typename?: 'demiplane_user_metadata_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataStddevOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserMetadataStddevPopFields = {
  __typename?: 'demiplane_user_metadata_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserMetadataStddevSampFields = {
  __typename?: 'demiplane_user_metadata_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserMetadataSumFields = {
  __typename?: 'demiplane_user_metadata_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataSumOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_metadata" */
export enum DemiplaneUserMetadataUpdateColumn {
  /** column name */
  AcceptedTerms = 'accepted_terms',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  ViewedActiveGmMessage = 'viewed_active_gm_message',
  /** column name */
  ViewedActivePlayerMessage = 'viewed_active_player_message',
  /** column name */
  ViewedAddToHomeScreen = 'viewed_add_to_home_screen',
  /** column name */
  ViewedMatchedGmMessage = 'viewed_matched_gm_message',
  /** column name */
  ViewedMatchedPlayerMessage = 'viewed_matched_player_message'
}

/** aggregate var_pop on columns */
export type DemiplaneUserMetadataVarPopFields = {
  __typename?: 'demiplane_user_metadata_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserMetadataVarSampFields = {
  __typename?: 'demiplane_user_metadata_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserMetadataVarianceFields = {
  __typename?: 'demiplane_user_metadata_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_metadata" */
export type DemiplaneUserMetadataVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserMinFields = {
  __typename?: 'demiplane_user_min_fields';
  access_level?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cognito_id?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['Int']>;
  facebook_profile?: Maybe<Scalars['String']>;
  feature_level?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  game_master_bio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_profile?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  last_access?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  player_story?: Maybe<Scalars['Int']>;
  pronoun_id?: Maybe<Scalars['bigint']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['bigint']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  stripe_connect_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  twitch_profile?: Maybe<Scalars['String']>;
  twitter_profile?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "demiplane.user" */
export type DemiplaneUserMinOrderBy = {
  access_level?: Maybe<OrderBy>;
  address1?: Maybe<OrderBy>;
  address2?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  cognito_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  dob?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  facebook_profile?: Maybe<OrderBy>;
  feature_level?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  game_master_bio?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  instagram_profile?: Maybe<OrderBy>;
  languages?: Maybe<OrderBy>;
  last_access?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  stripe_connect_id?: Maybe<OrderBy>;
  stripe_customer_id?: Maybe<OrderBy>;
  time_zone?: Maybe<OrderBy>;
  twitch_profile?: Maybe<OrderBy>;
  twitter_profile?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  username?: Maybe<OrderBy>;
  zipcode?: Maybe<OrderBy>;
  zipcode_plus_4_code?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user" */
export type DemiplaneUserMutationResponse = {
  __typename?: 'demiplane_user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUser>;
};

/** input type for inserting object relation for remote table "demiplane.user" */
export type DemiplaneUserObjRelInsertInput = {
  data: DemiplaneUserInsertInput;
  on_conflict?: Maybe<DemiplaneUserOnConflict>;
};

/** on conflict condition type for table "demiplane.user" */
export type DemiplaneUserOnConflict = {
  constraint: DemiplaneUserConstraint;
  update_columns: Array<DemiplaneUserUpdateColumn>;
  where?: Maybe<DemiplaneUserBoolExp>;
};

/** ordering options when selecting data from "demiplane.user" */
export type DemiplaneUserOrderBy = {
  access_level?: Maybe<OrderBy>;
  accessibility_friendly?: Maybe<OrderBy>;
  ad_requests_aggregate?: Maybe<DemiplaneAdRequestAggregateOrderBy>;
  address1?: Maybe<OrderBy>;
  address2?: Maybe<OrderBy>;
  ads_aggregate?: Maybe<DemiplaneAdAggregateOrderBy>;
  adventureConnectionsByContactorId_aggregate?: Maybe<DemiplaneAdventureConnectionAggregateOrderBy>;
  adventureConnectionsByRequestorId_aggregate?: Maybe<DemiplaneAdventureConnectionAggregateOrderBy>;
  adventure_connections_aggregate?: Maybe<DemiplaneAdventureConnectionAggregateOrderBy>;
  adventure_players_aggregate?: Maybe<DemiplaneAdventurePlayerAggregateOrderBy>;
  adventures_aggregate?: Maybe<DemiplaneAdventureAggregateOrderBy>;
  city?: Maybe<OrderBy>;
  cognito_id?: Maybe<OrderBy>;
  combat?: Maybe<OrderBy>;
  country?: Maybe<DemiplaneCountryOrderBy>;
  country_id?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  credit_cards_aggregate?: Maybe<DemiplaneCreditCardAggregateOrderBy>;
  dob?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  events_aggregate?: Maybe<DemiplaneEventAggregateOrderBy>;
  experience?: Maybe<OrderBy>;
  facebook_profile?: Maybe<OrderBy>;
  favorite_adventuring_platforms_aggregate?: Maybe<DemiplaneFavoriteAdventuringPlatformAggregateOrderBy>;
  favorite_games_aggregate?: Maybe<DemiplaneFavoriteGameAggregateOrderBy>;
  feature_level?: Maybe<OrderBy>;
  first_login?: Maybe<OrderBy>;
  first_name?: Maybe<OrderBy>;
  game_master_bio?: Maybe<OrderBy>;
  gmRecommendationsByGmId_aggregate?: Maybe<DemiplaneGmRecommendationAggregateOrderBy>;
  gm_recommendations_aggregate?: Maybe<DemiplaneGmRecommendationAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  inactive_month_alerted_email?: Maybe<OrderBy>;
  inactive_two_month_alerted_email?: Maybe<OrderBy>;
  inactive_week_alerted_email?: Maybe<OrderBy>;
  instagram_profile?: Maybe<OrderBy>;
  languages?: Maybe<OrderBy>;
  last_access?: Maybe<OrderBy>;
  last_name?: Maybe<OrderBy>;
  lgbtqia_friendly?: Maybe<OrderBy>;
  mind_theater_combat?: Maybe<OrderBy>;
  new_user_second_email?: Maybe<OrderBy>;
  new_user_third_email?: Maybe<OrderBy>;
  notificationHistoriesBySubmitorId_aggregate?: Maybe<DemiplaneNotificationHistoryAggregateOrderBy>;
  notification_histories_aggregate?: Maybe<DemiplaneNotificationHistoryAggregateOrderBy>;
  payments_aggregate?: Maybe<DemiplanePaymentAggregateOrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun?: Maybe<DemiplanePronounOrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  recruitment_set_or_alerted?: Maybe<OrderBy>;
  region?: Maybe<DemiplaneRegionOrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  session_players_aggregate?: Maybe<DemiplaneSessionPlayerAggregateOrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
  stripe_connect_id?: Maybe<OrderBy>;
  stripe_customer_id?: Maybe<OrderBy>;
  tactical_grid_combat?: Maybe<OrderBy>;
  time_zone?: Maybe<OrderBy>;
  transaction_receipts_aggregate?: Maybe<DemiplaneTransactionReceiptAggregateOrderBy>;
  tutorial_seen?: Maybe<OrderBy>;
  twitch_profile?: Maybe<OrderBy>;
  twitter_profile?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  upload?: Maybe<DemiplaneUploadOrderBy>;
  user_activities_aggregate?: Maybe<DemiplaneUserActivityAggregateOrderBy>;
  user_adventure_rankings_aggregate?: Maybe<DemiplaneUserAdventureRankingAggregateOrderBy>;
  user_adventure_roles_aggregate?: Maybe<DemiplaneUserAdventureRoleAggregateOrderBy>;
  user_adventures_aggregate?: Maybe<DemiplaneUserAdventureAggregateOrderBy>;
  user_email_notification_pref?: Maybe<DemiplaneUserEmailNotificationPrefOrderBy>;
  user_invitations_aggregate?: Maybe<DemiplaneUserInvitationAggregateOrderBy>;
  user_metadatum?: Maybe<DemiplaneUserMetadataOrderBy>;
  user_push_notification_pref?: Maybe<DemiplaneUserPushNotificationPrefOrderBy>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentOrderBy>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerOrderBy>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterOrderBy>;
  user_roles_aggregate?: Maybe<DemiplaneUserRoleAggregateOrderBy>;
  username?: Maybe<OrderBy>;
  uses_video_webcam?: Maybe<OrderBy>;
  uses_voice_mic?: Maybe<OrderBy>;
  zipcode?: Maybe<OrderBy>;
  zipcode_plus_4_code?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user" */
export type DemiplaneUserPkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPref = {
  __typename?: 'demiplane_user_push_notification_pref';
  adventure_is_rallying: Scalars['Boolean'];
  adventure_reminders: Scalars['Boolean'];
  adventurer_removed_from_adventure: Scalars['Boolean'];
  adventurer_requests_to_join: Scalars['Boolean'];
  created: Scalars['timestamptz'];
  gm_accepts_adventurer: Scalars['Boolean'];
  gm_denies_adventurer: Scalars['Boolean'];
  gm_recruits_adventurer: Scalars['Boolean'];
  gm_requests_to_host: Scalars['Boolean'];
  id: Scalars['bigint'];
  invited_to_adventure: Scalars['Boolean'];
  invited_to_adventuring_party: Scalars['Boolean'];
  invited_to_quest: Scalars['Boolean'];
  new_chat_alert: Scalars['Boolean'];
  new_journal_alert: Scalars['Boolean'];
  party_leader_accepts_gm: Scalars['Boolean'];
  party_leader_denies_gm: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_connected: Scalars['Boolean'];
  user_id?: Maybe<Scalars['bigint']>;
  user_joins_portal: Scalars['Boolean'];
};

/** aggregated selection of "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefAggregate = {
  __typename?: 'demiplane_user_push_notification_pref_aggregate';
  aggregate?: Maybe<DemiplaneUserPushNotificationPrefAggregateFields>;
  nodes: Array<DemiplaneUserPushNotificationPref>;
};

/** aggregate fields of "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefAggregateFields = {
  __typename?: 'demiplane_user_push_notification_pref_aggregate_fields';
  avg?: Maybe<DemiplaneUserPushNotificationPrefAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserPushNotificationPrefMaxFields>;
  min?: Maybe<DemiplaneUserPushNotificationPrefMinFields>;
  stddev?: Maybe<DemiplaneUserPushNotificationPrefStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserPushNotificationPrefStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserPushNotificationPrefStddevSampFields>;
  sum?: Maybe<DemiplaneUserPushNotificationPrefSumFields>;
  var_pop?: Maybe<DemiplaneUserPushNotificationPrefVarPopFields>;
  var_samp?: Maybe<DemiplaneUserPushNotificationPrefVarSampFields>;
  variance?: Maybe<DemiplaneUserPushNotificationPrefVarianceFields>;
};


/** aggregate fields of "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserPushNotificationPrefSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserPushNotificationPrefAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserPushNotificationPrefMaxOrderBy>;
  min?: Maybe<DemiplaneUserPushNotificationPrefMinOrderBy>;
  stddev?: Maybe<DemiplaneUserPushNotificationPrefStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserPushNotificationPrefStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserPushNotificationPrefStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserPushNotificationPrefSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserPushNotificationPrefVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserPushNotificationPrefVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserPushNotificationPrefVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefArrRelInsertInput = {
  data: Array<DemiplaneUserPushNotificationPrefInsertInput>;
  on_conflict?: Maybe<DemiplaneUserPushNotificationPrefOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserPushNotificationPrefAvgFields = {
  __typename?: 'demiplane_user_push_notification_pref_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefAvgOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_push_notification_pref". All fields are combined with a logical 'AND'. */
export type DemiplaneUserPushNotificationPrefBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserPushNotificationPrefBoolExp>>>;
  _not?: Maybe<DemiplaneUserPushNotificationPrefBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserPushNotificationPrefBoolExp>>>;
  adventure_is_rallying?: Maybe<BooleanComparisonExp>;
  adventure_reminders?: Maybe<BooleanComparisonExp>;
  adventurer_removed_from_adventure?: Maybe<BooleanComparisonExp>;
  adventurer_requests_to_join?: Maybe<BooleanComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  gm_accepts_adventurer?: Maybe<BooleanComparisonExp>;
  gm_denies_adventurer?: Maybe<BooleanComparisonExp>;
  gm_recruits_adventurer?: Maybe<BooleanComparisonExp>;
  gm_requests_to_host?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  invited_to_adventure?: Maybe<BooleanComparisonExp>;
  invited_to_adventuring_party?: Maybe<BooleanComparisonExp>;
  invited_to_quest?: Maybe<BooleanComparisonExp>;
  new_chat_alert?: Maybe<BooleanComparisonExp>;
  new_journal_alert?: Maybe<BooleanComparisonExp>;
  party_leader_accepts_gm?: Maybe<BooleanComparisonExp>;
  party_leader_denies_gm?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_connected?: Maybe<BooleanComparisonExp>;
  user_id?: Maybe<BigintComparisonExp>;
  user_joins_portal?: Maybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_push_notification_pref" */
export enum DemiplaneUserPushNotificationPrefConstraint {
  /** unique or primary key constraint */
  Rel_222f59e56676fd97d2a69cbbd9 = 'REL_222f59e56676fd97d2a69cbbd9',
  /** unique or primary key constraint */
  PkUserPushNotificationPrefId = 'pk_user_push_notification_pref_id'
}

/** input type for incrementing integer column in table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefIncInput = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefInsertInput = {
  adventure_is_rallying?: Maybe<Scalars['Boolean']>;
  adventure_reminders?: Maybe<Scalars['Boolean']>;
  adventurer_removed_from_adventure?: Maybe<Scalars['Boolean']>;
  adventurer_requests_to_join?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  gm_accepts_adventurer?: Maybe<Scalars['Boolean']>;
  gm_denies_adventurer?: Maybe<Scalars['Boolean']>;
  gm_recruits_adventurer?: Maybe<Scalars['Boolean']>;
  gm_requests_to_host?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  invited_to_adventure?: Maybe<Scalars['Boolean']>;
  invited_to_adventuring_party?: Maybe<Scalars['Boolean']>;
  invited_to_quest?: Maybe<Scalars['Boolean']>;
  new_chat_alert?: Maybe<Scalars['Boolean']>;
  new_journal_alert?: Maybe<Scalars['Boolean']>;
  party_leader_accepts_gm?: Maybe<Scalars['Boolean']>;
  party_leader_denies_gm?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_connected?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['bigint']>;
  user_joins_portal?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type DemiplaneUserPushNotificationPrefMaxFields = {
  __typename?: 'demiplane_user_push_notification_pref_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserPushNotificationPrefMinFields = {
  __typename?: 'demiplane_user_push_notification_pref_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefMutationResponse = {
  __typename?: 'demiplane_user_push_notification_pref_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserPushNotificationPref>;
};

/** input type for inserting object relation for remote table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefObjRelInsertInput = {
  data: DemiplaneUserPushNotificationPrefInsertInput;
  on_conflict?: Maybe<DemiplaneUserPushNotificationPrefOnConflict>;
};

/** on conflict condition type for table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefOnConflict = {
  constraint: DemiplaneUserPushNotificationPrefConstraint;
  update_columns: Array<DemiplaneUserPushNotificationPrefUpdateColumn>;
  where?: Maybe<DemiplaneUserPushNotificationPrefBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefOrderBy = {
  adventure_is_rallying?: Maybe<OrderBy>;
  adventure_reminders?: Maybe<OrderBy>;
  adventurer_removed_from_adventure?: Maybe<OrderBy>;
  adventurer_requests_to_join?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  gm_accepts_adventurer?: Maybe<OrderBy>;
  gm_denies_adventurer?: Maybe<OrderBy>;
  gm_recruits_adventurer?: Maybe<OrderBy>;
  gm_requests_to_host?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  invited_to_adventure?: Maybe<OrderBy>;
  invited_to_adventuring_party?: Maybe<OrderBy>;
  invited_to_quest?: Maybe<OrderBy>;
  new_chat_alert?: Maybe<OrderBy>;
  new_journal_alert?: Maybe<OrderBy>;
  party_leader_accepts_gm?: Maybe<OrderBy>;
  party_leader_denies_gm?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_connected?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  user_joins_portal?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_push_notification_pref" */
export enum DemiplaneUserPushNotificationPrefSelectColumn {
  /** column name */
  AdventureIsRallying = 'adventure_is_rallying',
  /** column name */
  AdventureReminders = 'adventure_reminders',
  /** column name */
  AdventurerRemovedFromAdventure = 'adventurer_removed_from_adventure',
  /** column name */
  AdventurerRequestsToJoin = 'adventurer_requests_to_join',
  /** column name */
  Created = 'created',
  /** column name */
  GmAcceptsAdventurer = 'gm_accepts_adventurer',
  /** column name */
  GmDeniesAdventurer = 'gm_denies_adventurer',
  /** column name */
  GmRecruitsAdventurer = 'gm_recruits_adventurer',
  /** column name */
  GmRequestsToHost = 'gm_requests_to_host',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedToAdventure = 'invited_to_adventure',
  /** column name */
  InvitedToAdventuringParty = 'invited_to_adventuring_party',
  /** column name */
  InvitedToQuest = 'invited_to_quest',
  /** column name */
  NewChatAlert = 'new_chat_alert',
  /** column name */
  NewJournalAlert = 'new_journal_alert',
  /** column name */
  PartyLeaderAcceptsGm = 'party_leader_accepts_gm',
  /** column name */
  PartyLeaderDeniesGm = 'party_leader_denies_gm',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserConnected = 'user_connected',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserJoinsPortal = 'user_joins_portal'
}

/** input type for updating data in table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefSetInput = {
  adventure_is_rallying?: Maybe<Scalars['Boolean']>;
  adventure_reminders?: Maybe<Scalars['Boolean']>;
  adventurer_removed_from_adventure?: Maybe<Scalars['Boolean']>;
  adventurer_requests_to_join?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  gm_accepts_adventurer?: Maybe<Scalars['Boolean']>;
  gm_denies_adventurer?: Maybe<Scalars['Boolean']>;
  gm_recruits_adventurer?: Maybe<Scalars['Boolean']>;
  gm_requests_to_host?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  invited_to_adventure?: Maybe<Scalars['Boolean']>;
  invited_to_adventuring_party?: Maybe<Scalars['Boolean']>;
  invited_to_quest?: Maybe<Scalars['Boolean']>;
  new_chat_alert?: Maybe<Scalars['Boolean']>;
  new_journal_alert?: Maybe<Scalars['Boolean']>;
  party_leader_accepts_gm?: Maybe<Scalars['Boolean']>;
  party_leader_denies_gm?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_connected?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['bigint']>;
  user_joins_portal?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserPushNotificationPrefStddevFields = {
  __typename?: 'demiplane_user_push_notification_pref_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefStddevOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserPushNotificationPrefStddevPopFields = {
  __typename?: 'demiplane_user_push_notification_pref_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserPushNotificationPrefStddevSampFields = {
  __typename?: 'demiplane_user_push_notification_pref_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserPushNotificationPrefSumFields = {
  __typename?: 'demiplane_user_push_notification_pref_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefSumOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_push_notification_pref" */
export enum DemiplaneUserPushNotificationPrefUpdateColumn {
  /** column name */
  AdventureIsRallying = 'adventure_is_rallying',
  /** column name */
  AdventureReminders = 'adventure_reminders',
  /** column name */
  AdventurerRemovedFromAdventure = 'adventurer_removed_from_adventure',
  /** column name */
  AdventurerRequestsToJoin = 'adventurer_requests_to_join',
  /** column name */
  Created = 'created',
  /** column name */
  GmAcceptsAdventurer = 'gm_accepts_adventurer',
  /** column name */
  GmDeniesAdventurer = 'gm_denies_adventurer',
  /** column name */
  GmRecruitsAdventurer = 'gm_recruits_adventurer',
  /** column name */
  GmRequestsToHost = 'gm_requests_to_host',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedToAdventure = 'invited_to_adventure',
  /** column name */
  InvitedToAdventuringParty = 'invited_to_adventuring_party',
  /** column name */
  InvitedToQuest = 'invited_to_quest',
  /** column name */
  NewChatAlert = 'new_chat_alert',
  /** column name */
  NewJournalAlert = 'new_journal_alert',
  /** column name */
  PartyLeaderAcceptsGm = 'party_leader_accepts_gm',
  /** column name */
  PartyLeaderDeniesGm = 'party_leader_denies_gm',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserConnected = 'user_connected',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserJoinsPortal = 'user_joins_portal'
}

/** aggregate var_pop on columns */
export type DemiplaneUserPushNotificationPrefVarPopFields = {
  __typename?: 'demiplane_user_push_notification_pref_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserPushNotificationPrefVarSampFields = {
  __typename?: 'demiplane_user_push_notification_pref_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserPushNotificationPrefVarianceFields = {
  __typename?: 'demiplane_user_push_notification_pref_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_push_notification_pref" */
export type DemiplaneUserPushNotificationPrefVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitment = {
  __typename?: 'demiplane_user_recruitment';
  adventurer: Scalars['Boolean'];
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created: Scalars['timestamptz'];
  game_master: Scalars['Boolean'];
  id: Scalars['bigint'];
  max_match_score: Scalars['Int'];
  min_match_score: Scalars['Int'];
  no_cost: Scalars['Boolean'];
  status: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
  /** An array relationship */
  uraps: Array<DemiplaneUrap>;
  /** An aggregated array relationship */
  uraps_aggregate: DemiplaneUrapAggregate;
  /** An array relationship */
  urgts: Array<DemiplaneUrgt>;
  /** An aggregated array relationship */
  urgts_aggregate: DemiplaneUrgtAggregate;
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  user_recruitment_availabilities: Array<DemiplaneUserRecruitmentAvailability>;
  /** An aggregated array relationship */
  user_recruitment_availabilities_aggregate: DemiplaneUserRecruitmentAvailabilityAggregate;
};


/** columns and relationships of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentUrapsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentUrapsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentUrgtsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentUrgtsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentUserRecruitmentAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentUserRecruitmentAvailabilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};

/** columns and relationships of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurer = {
  __typename?: 'demiplane_user_recruitment_adventurer';
  active: Scalars['Boolean'];
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
  /** An array relationship */
  user_recruitment_adventurer_availabilities: Array<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** An aggregated array relationship */
  user_recruitment_adventurer_availabilities_aggregate: DemiplaneUserRecruitmentAdventurerAvailabilityAggregate;
  /** An array relationship */
  user_recruitment_adventurer_games: Array<DemiplaneUserRecruitmentAdventurerGames>;
  /** An aggregated array relationship */
  user_recruitment_adventurer_games_aggregate: DemiplaneUserRecruitmentAdventurerGamesAggregate;
  /** An array relationship */
  user_recruitment_adventurer_platforms: Array<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** An aggregated array relationship */
  user_recruitment_adventurer_platforms_aggregate: DemiplaneUserRecruitmentAdventurerPlatformsAggregate;
};


/** columns and relationships of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerAvailabilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};

/** aggregated selection of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerAggregate = {
  __typename?: 'demiplane_user_recruitment_adventurer_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentAdventurer>;
};

/** aggregate fields of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerAggregateFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerSumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentAdventurerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentAdventurerInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerOnConflict>;
};

/** columns and relationships of "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailability = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability';
  created?: Maybe<Scalars['timestamptz']>;
  dow: Scalars['Int'];
  end_time: Scalars['timestamptz'];
  id: Scalars['bigint'];
  start_time: Scalars['timestamptz'];
  updated?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user_recruitment_adventurer: DemiplaneUserRecruitmentAdventurer;
  user_recruitment_adventurer_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityAggregate = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentAdventurerAvailability>;
};

/** aggregate fields of "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilitySumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilitySumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentAdventurerAvailabilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityAvgFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_avg_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityAvgOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_adventurer_availability". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  dow?: Maybe<IntComparisonExp>;
  end_time?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  start_time?: Maybe<TimestamptzComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
  user_recruitment_adventurer_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_adventurer_availability" */
export enum DemiplaneUserRecruitmentAdventurerAvailabilityConstraint {
  /** unique or primary key constraint */
  UserRecruitmentAdventurerAvailabilityPkey = 'user_recruitment_adventurer_availability_pkey'
}

/** input type for incrementing integer column in table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityIncInput = {
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerObjRelInsertInput>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityMaxFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityMaxOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityMinFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityMinOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponse = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentAdventurerAvailability>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityObjRelInsertInput = {
  data: DemiplaneUserRecruitmentAdventurerAvailabilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityOnConflict = {
  constraint: DemiplaneUserRecruitmentAdventurerAvailabilityConstraint;
  update_columns: Array<DemiplaneUserRecruitmentAdventurerAvailabilityUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerOrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment_adventurer_availability" */
export enum DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserRecruitmentAdventurerId = 'user_recruitment_adventurer_id'
}

/** input type for updating data in table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilitySetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_stddev_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_stddev_pop_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_stddev_samp_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilitySumFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_sum_fields';
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilitySumOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_adventurer_availability" */
export enum DemiplaneUserRecruitmentAdventurerAvailabilityUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserRecruitmentAdventurerId = 'user_recruitment_adventurer_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityVarPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_var_pop_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityVarPopOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityVarSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_var_samp_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityVarSampOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentAdventurerAvailabilityVarianceFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_availability_variance_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_adventurer_availability" */
export type DemiplaneUserRecruitmentAdventurerAvailabilityVarianceOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentAdventurerAvgFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_avg_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerAvgOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_adventurer". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentAdventurerBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>>>;
  active?: Maybe<BooleanComparisonExp>;
  availability_start_of_week?: Maybe<TimestamptzComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  min_match_score_threshold?: Maybe<IntComparisonExp>;
  no_cost?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  user_recruitment_adventurer_availabilities?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
  user_recruitment_adventurer_games?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
  user_recruitment_adventurer_platforms?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_adventurer" */
export enum DemiplaneUserRecruitmentAdventurerConstraint {
  /** unique or primary key constraint */
  UserRecruitmentAdventurerPkey = 'user_recruitment_adventurer_pkey',
  /** unique or primary key constraint */
  UserRecruitmentAdventurerUserIdKey = 'user_recruitment_adventurer_user_id_key'
}

/** columns and relationships of "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGames = {
  __typename?: 'demiplane_user_recruitment_adventurer_games';
  /** An object relationship */
  game_type: DemiplaneGameType;
  game_type_id: Scalars['bigint'];
  /** An object relationship */
  user_recruitment_adventurer: DemiplaneUserRecruitmentAdventurer;
  user_recruitment_adventurer_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesAggregate = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerGamesAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentAdventurerGames>;
};

/** aggregate fields of "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesAggregateFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerGamesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerGamesMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerGamesMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerGamesStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerGamesStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerGamesStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerGamesSumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerGamesVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerGamesVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerGamesVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerGamesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerGamesMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerGamesMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerGamesStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerGamesStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerGamesStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerGamesSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerGamesVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerGamesVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerGamesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentAdventurerGamesInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerGamesOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentAdventurerGamesAvgFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_avg_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesAvgOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_adventurer_games". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentAdventurerGamesBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>>>;
  game_type?: Maybe<DemiplaneGameTypeBoolExp>;
  game_type_id?: Maybe<BigintComparisonExp>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
  user_recruitment_adventurer_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_adventurer_games" */
export enum DemiplaneUserRecruitmentAdventurerGamesConstraint {
  /** unique or primary key constraint */
  UserRecruitmentAdventurerGamesPkey = 'user_recruitment_adventurer_games_pkey'
}

/** input type for incrementing integer column in table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesIncInput = {
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesInsertInput = {
  game_type?: Maybe<DemiplaneGameTypeObjRelInsertInput>;
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerObjRelInsertInput>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentAdventurerGamesMaxFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_max_fields';
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesMaxOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentAdventurerGamesMinFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_min_fields';
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesMinOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesMutationResponse = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentAdventurerGames>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesObjRelInsertInput = {
  data: DemiplaneUserRecruitmentAdventurerGamesInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerGamesOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesOnConflict = {
  constraint: DemiplaneUserRecruitmentAdventurerGamesConstraint;
  update_columns: Array<DemiplaneUserRecruitmentAdventurerGamesUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesOrderBy = {
  game_type?: Maybe<DemiplaneGameTypeOrderBy>;
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerOrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesPkColumnsInput = {
  game_type_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment_adventurer_games" */
export enum DemiplaneUserRecruitmentAdventurerGamesSelectColumn {
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  UserRecruitmentAdventurerId = 'user_recruitment_adventurer_id'
}

/** input type for updating data in table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesSetInput = {
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentAdventurerGamesStddevFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_stddev_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesStddevOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentAdventurerGamesStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_stddev_pop_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesStddevPopOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentAdventurerGamesStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_stddev_samp_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesStddevSampOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentAdventurerGamesSumFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_sum_fields';
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesSumOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_adventurer_games" */
export enum DemiplaneUserRecruitmentAdventurerGamesUpdateColumn {
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  UserRecruitmentAdventurerId = 'user_recruitment_adventurer_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentAdventurerGamesVarPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_var_pop_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesVarPopOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentAdventurerGamesVarSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_var_samp_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesVarSampOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentAdventurerGamesVarianceFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_games_variance_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_adventurer_games" */
export type DemiplaneUserRecruitmentAdventurerGamesVarianceOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** input type for incrementing integer column in table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerIncInput = {
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_availabilities?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityArrRelInsertInput>;
  user_recruitment_adventurer_games?: Maybe<DemiplaneUserRecruitmentAdventurerGamesArrRelInsertInput>;
  user_recruitment_adventurer_platforms?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentAdventurerMaxFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_max_fields';
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerMaxOrderBy = {
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentAdventurerMinFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_min_fields';
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerMinOrderBy = {
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerMutationResponse = {
  __typename?: 'demiplane_user_recruitment_adventurer_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentAdventurer>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerObjRelInsertInput = {
  data: DemiplaneUserRecruitmentAdventurerInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerOnConflict = {
  constraint: DemiplaneUserRecruitmentAdventurerConstraint;
  update_columns: Array<DemiplaneUserRecruitmentAdventurerUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerOrderBy = {
  active?: Maybe<OrderBy>;
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  no_cost?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_availabilities_aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityAggregateOrderBy>;
  user_recruitment_adventurer_games_aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerGamesAggregateOrderBy>;
  user_recruitment_adventurer_platforms_aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerPkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatforms = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms';
  /** An object relationship */
  adventuring_platform: DemiplaneAdventuringPlatform;
  adventuring_platform_id: Scalars['bigint'];
  /** An object relationship */
  user_recruitment_adventurer: DemiplaneUserRecruitmentAdventurer;
  user_recruitment_adventurer_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsAggregate = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentAdventurerPlatforms>;
};

/** aggregate fields of "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsAggregateFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsSumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentAdventurerPlatformsInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsAvgFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_avg_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsAvgOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_adventurer_platforms". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentAdventurerPlatformsBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>>>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
  adventuring_platform_id?: Maybe<BigintComparisonExp>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
  user_recruitment_adventurer_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_adventurer_platforms" */
export enum DemiplaneUserRecruitmentAdventurerPlatformsConstraint {
  /** unique or primary key constraint */
  UserRecruitmentAdventurerPlatformsPkey = 'user_recruitment_adventurer_platforms_pkey'
}

/** input type for incrementing integer column in table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsIncInput = {
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsInsertInput = {
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformObjRelInsertInput>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerObjRelInsertInput>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsMaxFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_max_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsMaxOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsMinFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_min_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsMinOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsMutationResponse = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentAdventurerPlatforms>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsObjRelInsertInput = {
  data: DemiplaneUserRecruitmentAdventurerPlatformsInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsOnConflict = {
  constraint: DemiplaneUserRecruitmentAdventurerPlatformsConstraint;
  update_columns: Array<DemiplaneUserRecruitmentAdventurerPlatformsUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsOrderBy = {
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformOrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerOrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsPkColumnsInput = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment_adventurer_platforms" */
export enum DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn {
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  UserRecruitmentAdventurerId = 'user_recruitment_adventurer_id'
}

/** input type for updating data in table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsSetInput = {
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsStddevFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_stddev_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsStddevOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_stddev_pop_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsStddevPopOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_stddev_samp_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsStddevSampOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsSumFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_sum_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsSumOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_adventurer_platforms" */
export enum DemiplaneUserRecruitmentAdventurerPlatformsUpdateColumn {
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  UserRecruitmentAdventurerId = 'user_recruitment_adventurer_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsVarPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_var_pop_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsVarPopOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsVarSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_var_samp_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsVarSampOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentAdventurerPlatformsVarianceFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_platforms_variance_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_adventurer_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_adventurer_platforms" */
export type DemiplaneUserRecruitmentAdventurerPlatformsVarianceOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_adventurer_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.user_recruitment_adventurer" */
export enum DemiplaneUserRecruitmentAdventurerSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AvailabilityStartOfWeek = 'availability_start_of_week',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MinMatchScoreThreshold = 'min_match_score_threshold',
  /** column name */
  NoCost = 'no_cost',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentAdventurerStddevFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerStddevOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentAdventurerStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentAdventurerStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentAdventurerSumFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerSumOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_adventurer" */
export enum DemiplaneUserRecruitmentAdventurerUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AvailabilityStartOfWeek = 'availability_start_of_week',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MinMatchScoreThreshold = 'min_match_score_threshold',
  /** column name */
  NoCost = 'no_cost',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentAdventurerVarPopFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentAdventurerVarSampFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentAdventurerVarianceFields = {
  __typename?: 'demiplane_user_recruitment_adventurer_variance_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_adventurer" */
export type DemiplaneUserRecruitmentAdventurerVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregated selection of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentAggregate = {
  __typename?: 'demiplane_user_recruitment_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentAggregateFields>;
  nodes: Array<DemiplaneUserRecruitment>;
};

/** aggregate fields of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentAggregateFields = {
  __typename?: 'demiplane_user_recruitment_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentSumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentOnConflict>;
};

/** columns and relationships of "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailability = {
  __typename?: 'demiplane_user_recruitment_availability';
  created: Scalars['timestamptz'];
  dow: Scalars['Int'];
  end_time: Scalars['timestamptz'];
  id: Scalars['bigint'];
  start_time: Scalars['timestamptz'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user_recruitment: DemiplaneUserRecruitment;
  user_recruitment_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityAggregate = {
  __typename?: 'demiplane_user_recruitment_availability_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentAvailabilityAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentAvailability>;
};

/** aggregate fields of "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityAggregateFields = {
  __typename?: 'demiplane_user_recruitment_availability_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentAvailabilityAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentAvailabilityMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentAvailabilityMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentAvailabilityStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAvailabilityStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAvailabilityStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentAvailabilitySumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAvailabilityVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAvailabilityVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentAvailabilityVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentAvailabilitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentAvailabilityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentAvailabilityMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentAvailabilityMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentAvailabilityStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentAvailabilityStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentAvailabilityStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentAvailabilitySumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentAvailabilityVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentAvailabilityVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentAvailabilityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentAvailabilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAvailabilityOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentAvailabilityAvgFields = {
  __typename?: 'demiplane_user_recruitment_availability_avg_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityAvgOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_availability". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentAvailabilityBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  dow?: Maybe<IntComparisonExp>;
  end_time?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  start_time?: Maybe<TimestamptzComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentBoolExp>;
  user_recruitment_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_availability" */
export enum DemiplaneUserRecruitmentAvailabilityConstraint {
  /** unique or primary key constraint */
  PkUserRecruitmentAvailabilityId = 'pk_user_recruitment_availability_id'
}

/** input type for incrementing integer column in table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityIncInput = {
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_recruitment_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentObjRelInsertInput>;
  user_recruitment_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentAvailabilityMaxFields = {
  __typename?: 'demiplane_user_recruitment_availability_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityMaxOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentAvailabilityMinFields = {
  __typename?: 'demiplane_user_recruitment_availability_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityMinOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityMutationResponse = {
  __typename?: 'demiplane_user_recruitment_availability_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentAvailability>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityObjRelInsertInput = {
  data: DemiplaneUserRecruitmentAvailabilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAvailabilityOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityOnConflict = {
  constraint: DemiplaneUserRecruitmentAvailabilityConstraint;
  update_columns: Array<DemiplaneUserRecruitmentAvailabilityUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment?: Maybe<DemiplaneUserRecruitmentOrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment_availability" */
export enum DemiplaneUserRecruitmentAvailabilitySelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserRecruitmentId = 'user_recruitment_id'
}

/** input type for updating data in table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilitySetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentAvailabilityStddevFields = {
  __typename?: 'demiplane_user_recruitment_availability_stddev_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityStddevOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentAvailabilityStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_availability_stddev_pop_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityStddevPopOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentAvailabilityStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_availability_stddev_samp_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityStddevSampOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentAvailabilitySumFields = {
  __typename?: 'demiplane_user_recruitment_availability_sum_fields';
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_recruitment_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilitySumOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_availability" */
export enum DemiplaneUserRecruitmentAvailabilityUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserRecruitmentId = 'user_recruitment_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentAvailabilityVarPopFields = {
  __typename?: 'demiplane_user_recruitment_availability_var_pop_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityVarPopOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentAvailabilityVarSampFields = {
  __typename?: 'demiplane_user_recruitment_availability_var_samp_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityVarSampOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentAvailabilityVarianceFields = {
  __typename?: 'demiplane_user_recruitment_availability_variance_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_availability" */
export type DemiplaneUserRecruitmentAvailabilityVarianceOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_id?: Maybe<OrderBy>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentAvgFields = {
  __typename?: 'demiplane_user_recruitment_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max_match_score?: Maybe<Scalars['Float']>;
  min_match_score?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentAvgOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentBoolExp>>>;
  adventurer?: Maybe<BooleanComparisonExp>;
  availability_start_of_week?: Maybe<TimestamptzComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  game_master?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  max_match_score?: Maybe<IntComparisonExp>;
  min_match_score?: Maybe<IntComparisonExp>;
  no_cost?: Maybe<BooleanComparisonExp>;
  status?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  uraps?: Maybe<DemiplaneUrapBoolExp>;
  urgts?: Maybe<DemiplaneUrgtBoolExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  user_recruitment_availabilities?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment" */
export enum DemiplaneUserRecruitmentConstraint {
  /** unique or primary key constraint */
  Rel_20a7e13943a8482ed3824c564a = 'REL_20a7e13943a8482ed3824c564a',
  /** unique or primary key constraint */
  PkUserRecruitmentId = 'pk_user_recruitment_id'
}

/** columns and relationships of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMaster = {
  __typename?: 'demiplane_user_recruitment_game_master';
  active: Scalars['Boolean'];
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  paid_only?: Maybe<Scalars['Boolean']>;
  public_profile?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<DemiplaneUser>;
  user_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  user_recruitment_game_master_availabilities: Array<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** An aggregated array relationship */
  user_recruitment_game_master_availabilities_aggregate: DemiplaneUserRecruitmentGameMasterAvailabilityAggregate;
  /** An array relationship */
  user_recruitment_game_master_games: Array<DemiplaneUserRecruitmentGameMasterGames>;
  /** An aggregated array relationship */
  user_recruitment_game_master_games_aggregate: DemiplaneUserRecruitmentGameMasterGamesAggregate;
  /** An array relationship */
  user_recruitment_game_master_platforms: Array<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** An aggregated array relationship */
  user_recruitment_game_master_platforms_aggregate: DemiplaneUserRecruitmentGameMasterPlatformsAggregate;
};


/** columns and relationships of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterAvailabilitiesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterAvailabilitiesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};


/** columns and relationships of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};

/** aggregated selection of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterAggregate = {
  __typename?: 'demiplane_user_recruitment_game_master_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentGameMaster>;
};

/** aggregate fields of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterAggregateFields = {
  __typename?: 'demiplane_user_recruitment_game_master_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterSumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentGameMasterSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentGameMasterInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterOnConflict>;
};

/** columns and relationships of "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailability = {
  __typename?: 'demiplane_user_recruitment_game_master_availability';
  created?: Maybe<Scalars['timestamptz']>;
  dow: Scalars['Int'];
  end_time: Scalars['timestamptz'];
  id: Scalars['bigint'];
  start_time: Scalars['timestamptz'];
  updated?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user_recruitment_game_master: DemiplaneUserRecruitmentGameMaster;
  user_recruitment_game_master_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityAggregate = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentGameMasterAvailability>;
};

/** aggregate fields of "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilitySumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilitySumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentGameMasterAvailabilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityAvgFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_avg_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityAvgOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_game_master_availability". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>>>;
  created?: Maybe<TimestamptzComparisonExp>;
  dow?: Maybe<IntComparisonExp>;
  end_time?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  start_time?: Maybe<TimestamptzComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
  user_recruitment_game_master_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_game_master_availability" */
export enum DemiplaneUserRecruitmentGameMasterAvailabilityConstraint {
  /** unique or primary key constraint */
  UserRecruitmentGameMasterAvailabilityPkey = 'user_recruitment_game_master_availability_pkey'
}

/** input type for incrementing integer column in table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityIncInput = {
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityInsertInput = {
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterObjRelInsertInput>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityMaxFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityMaxOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityMinFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityMinOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponse = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentGameMasterAvailability>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityObjRelInsertInput = {
  data: DemiplaneUserRecruitmentGameMasterAvailabilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityOnConflict = {
  constraint: DemiplaneUserRecruitmentGameMasterAvailabilityConstraint;
  update_columns: Array<DemiplaneUserRecruitmentGameMasterAvailabilityUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy = {
  created?: Maybe<OrderBy>;
  dow?: Maybe<OrderBy>;
  end_time?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  start_time?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterOrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment_game_master_availability" */
export enum DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserRecruitmentGameMasterId = 'user_recruitment_game_master_id'
}

/** input type for updating data in table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilitySetInput = {
  created?: Maybe<Scalars['timestamptz']>;
  dow?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_stddev_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_stddev_pop_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_stddev_samp_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilitySumFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_sum_fields';
  dow?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilitySumOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_game_master_availability" */
export enum DemiplaneUserRecruitmentGameMasterAvailabilityUpdateColumn {
  /** column name */
  Created = 'created',
  /** column name */
  Dow = 'dow',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserRecruitmentGameMasterId = 'user_recruitment_game_master_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityVarPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_var_pop_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityVarPopOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityVarSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_var_samp_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityVarSampOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentGameMasterAvailabilityVarianceFields = {
  __typename?: 'demiplane_user_recruitment_game_master_availability_variance_fields';
  dow?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_game_master_availability" */
export type DemiplaneUserRecruitmentGameMasterAvailabilityVarianceOrderBy = {
  dow?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentGameMasterAvgFields = {
  __typename?: 'demiplane_user_recruitment_game_master_avg_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterAvgOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_game_master". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentGameMasterBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>>>;
  active?: Maybe<BooleanComparisonExp>;
  availability_start_of_week?: Maybe<TimestamptzComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  min_match_score_threshold?: Maybe<IntComparisonExp>;
  paid_only?: Maybe<BooleanComparisonExp>;
  public_profile?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
  user_recruitment_game_master_availabilities?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
  user_recruitment_game_master_games?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
  user_recruitment_game_master_platforms?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_game_master" */
export enum DemiplaneUserRecruitmentGameMasterConstraint {
  /** unique or primary key constraint */
  UserRecruitmentGameMasterPkey = 'user_recruitment_game_master_pkey',
  /** unique or primary key constraint */
  UserRecruitmentGameMasterUserIdKey = 'user_recruitment_game_master_user_id_key'
}

/** columns and relationships of "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGames = {
  __typename?: 'demiplane_user_recruitment_game_master_games';
  /** An object relationship */
  game_type: DemiplaneGameType;
  game_type_id: Scalars['bigint'];
  /** An object relationship */
  user_recruitment_game_master: DemiplaneUserRecruitmentGameMaster;
  user_recruitment_game_master_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesAggregate = {
  __typename?: 'demiplane_user_recruitment_game_master_games_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterGamesAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentGameMasterGames>;
};

/** aggregate fields of "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesAggregateFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterGamesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterGamesMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterGamesMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterGamesStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterGamesStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterGamesStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterGamesSumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterGamesVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterGamesVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterGamesVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterGamesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterGamesMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterGamesMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterGamesStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterGamesStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterGamesStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterGamesSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterGamesVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterGamesVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterGamesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentGameMasterGamesInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterGamesOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentGameMasterGamesAvgFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_avg_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesAvgOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_game_master_games". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentGameMasterGamesBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>>>;
  game_type?: Maybe<DemiplaneGameTypeBoolExp>;
  game_type_id?: Maybe<BigintComparisonExp>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
  user_recruitment_game_master_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_game_master_games" */
export enum DemiplaneUserRecruitmentGameMasterGamesConstraint {
  /** unique or primary key constraint */
  UserRecruitmentGameMasterGamesPkey = 'user_recruitment_game_master_games_pkey'
}

/** input type for incrementing integer column in table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesIncInput = {
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesInsertInput = {
  game_type?: Maybe<DemiplaneGameTypeObjRelInsertInput>;
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterObjRelInsertInput>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentGameMasterGamesMaxFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_max_fields';
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesMaxOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentGameMasterGamesMinFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_min_fields';
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesMinOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesMutationResponse = {
  __typename?: 'demiplane_user_recruitment_game_master_games_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentGameMasterGames>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesObjRelInsertInput = {
  data: DemiplaneUserRecruitmentGameMasterGamesInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterGamesOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesOnConflict = {
  constraint: DemiplaneUserRecruitmentGameMasterGamesConstraint;
  update_columns: Array<DemiplaneUserRecruitmentGameMasterGamesUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesOrderBy = {
  game_type?: Maybe<DemiplaneGameTypeOrderBy>;
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterOrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesPkColumnsInput = {
  game_type_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment_game_master_games" */
export enum DemiplaneUserRecruitmentGameMasterGamesSelectColumn {
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  UserRecruitmentGameMasterId = 'user_recruitment_game_master_id'
}

/** input type for updating data in table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesSetInput = {
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentGameMasterGamesStddevFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_stddev_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesStddevOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentGameMasterGamesStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_stddev_pop_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesStddevPopOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentGameMasterGamesStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_stddev_samp_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesStddevSampOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentGameMasterGamesSumFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_sum_fields';
  game_type_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesSumOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_game_master_games" */
export enum DemiplaneUserRecruitmentGameMasterGamesUpdateColumn {
  /** column name */
  GameTypeId = 'game_type_id',
  /** column name */
  UserRecruitmentGameMasterId = 'user_recruitment_game_master_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentGameMasterGamesVarPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_var_pop_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesVarPopOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentGameMasterGamesVarSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_var_samp_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesVarSampOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentGameMasterGamesVarianceFields = {
  __typename?: 'demiplane_user_recruitment_game_master_games_variance_fields';
  game_type_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_game_master_games" */
export type DemiplaneUserRecruitmentGameMasterGamesVarianceOrderBy = {
  game_type_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** input type for incrementing integer column in table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterIncInput = {
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterInsertInput = {
  active?: Maybe<Scalars['Boolean']>;
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  paid_only?: Maybe<Scalars['Boolean']>;
  public_profile?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_availabilities?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityArrRelInsertInput>;
  user_recruitment_game_master_games?: Maybe<DemiplaneUserRecruitmentGameMasterGamesArrRelInsertInput>;
  user_recruitment_game_master_platforms?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentGameMasterMaxFields = {
  __typename?: 'demiplane_user_recruitment_game_master_max_fields';
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterMaxOrderBy = {
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentGameMasterMinFields = {
  __typename?: 'demiplane_user_recruitment_game_master_min_fields';
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterMinOrderBy = {
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterMutationResponse = {
  __typename?: 'demiplane_user_recruitment_game_master_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentGameMaster>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterObjRelInsertInput = {
  data: DemiplaneUserRecruitmentGameMasterInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterOnConflict = {
  constraint: DemiplaneUserRecruitmentGameMasterConstraint;
  update_columns: Array<DemiplaneUserRecruitmentGameMasterUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterOrderBy = {
  active?: Maybe<OrderBy>;
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  paid_only?: Maybe<OrderBy>;
  public_profile?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  user_recruitment_game_master_availabilities_aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityAggregateOrderBy>;
  user_recruitment_game_master_games_aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterGamesAggregateOrderBy>;
  user_recruitment_game_master_platforms_aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterPkColumnsInput = {
  id: Scalars['bigint'];
};

/** columns and relationships of "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatforms = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms';
  /** An object relationship */
  adventuring_platform: DemiplaneAdventuringPlatform;
  adventuring_platform_id: Scalars['bigint'];
  /** An object relationship */
  user_recruitment_game_master: DemiplaneUserRecruitmentGameMaster;
  user_recruitment_game_master_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsAggregate = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_aggregate';
  aggregate?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsAggregateFields>;
  nodes: Array<DemiplaneUserRecruitmentGameMasterPlatforms>;
};

/** aggregate fields of "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsAggregateFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_aggregate_fields';
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsMaxFields>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsMinFields>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsStddevSampFields>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsSumFields>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsVarSampFields>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsVarianceFields>;
};


/** aggregate fields of "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsMaxOrderBy>;
  min?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsArrRelInsertInput = {
  data: Array<DemiplaneUserRecruitmentGameMasterPlatformsInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsAvgFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_avg_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsAvgOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_recruitment_game_master_platforms". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRecruitmentGameMasterPlatformsBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>>>;
  _not?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>>>;
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
  adventuring_platform_id?: Maybe<BigintComparisonExp>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
  user_recruitment_game_master_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_recruitment_game_master_platforms" */
export enum DemiplaneUserRecruitmentGameMasterPlatformsConstraint {
  /** unique or primary key constraint */
  UserRecruitmentGameMasterPlatformsPkey = 'user_recruitment_game_master_platforms_pkey'
}

/** input type for incrementing integer column in table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsIncInput = {
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsInsertInput = {
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformObjRelInsertInput>;
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterObjRelInsertInput>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsMaxFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_max_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsMaxOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsMinFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_min_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsMinOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsMutationResponse = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitmentGameMasterPlatforms>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsObjRelInsertInput = {
  data: DemiplaneUserRecruitmentGameMasterPlatformsInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsOnConflict = {
  constraint: DemiplaneUserRecruitmentGameMasterPlatformsConstraint;
  update_columns: Array<DemiplaneUserRecruitmentGameMasterPlatformsUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsOrderBy = {
  adventuring_platform?: Maybe<DemiplaneAdventuringPlatformOrderBy>;
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterOrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsPkColumnsInput = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment_game_master_platforms" */
export enum DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn {
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  UserRecruitmentGameMasterId = 'user_recruitment_game_master_id'
}

/** input type for updating data in table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsSetInput = {
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsStddevFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_stddev_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsStddevOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_stddev_pop_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsStddevPopOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_stddev_samp_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsStddevSampOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsSumFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_sum_fields';
  adventuring_platform_id?: Maybe<Scalars['bigint']>;
  user_recruitment_game_master_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsSumOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_game_master_platforms" */
export enum DemiplaneUserRecruitmentGameMasterPlatformsUpdateColumn {
  /** column name */
  AdventuringPlatformId = 'adventuring_platform_id',
  /** column name */
  UserRecruitmentGameMasterId = 'user_recruitment_game_master_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsVarPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_var_pop_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsVarPopOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsVarSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_var_samp_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsVarSampOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentGameMasterPlatformsVarianceFields = {
  __typename?: 'demiplane_user_recruitment_game_master_platforms_variance_fields';
  adventuring_platform_id?: Maybe<Scalars['Float']>;
  user_recruitment_game_master_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_game_master_platforms" */
export type DemiplaneUserRecruitmentGameMasterPlatformsVarianceOrderBy = {
  adventuring_platform_id?: Maybe<OrderBy>;
  user_recruitment_game_master_id?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.user_recruitment_game_master" */
export enum DemiplaneUserRecruitmentGameMasterSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AvailabilityStartOfWeek = 'availability_start_of_week',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MinMatchScoreThreshold = 'min_match_score_threshold',
  /** column name */
  PaidOnly = 'paid_only',
  /** column name */
  PublicProfile = 'public_profile',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterSetInput = {
  active?: Maybe<Scalars['Boolean']>;
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  paid_only?: Maybe<Scalars['Boolean']>;
  public_profile?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentGameMasterStddevFields = {
  __typename?: 'demiplane_user_recruitment_game_master_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterStddevOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentGameMasterStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentGameMasterStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentGameMasterSumFields = {
  __typename?: 'demiplane_user_recruitment_game_master_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  min_match_score_threshold?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterSumOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment_game_master" */
export enum DemiplaneUserRecruitmentGameMasterUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  AvailabilityStartOfWeek = 'availability_start_of_week',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  MinMatchScoreThreshold = 'min_match_score_threshold',
  /** column name */
  PaidOnly = 'paid_only',
  /** column name */
  PublicProfile = 'public_profile',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentGameMasterVarPopFields = {
  __typename?: 'demiplane_user_recruitment_game_master_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentGameMasterVarSampFields = {
  __typename?: 'demiplane_user_recruitment_game_master_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentGameMasterVarianceFields = {
  __typename?: 'demiplane_user_recruitment_game_master_variance_fields';
  id?: Maybe<Scalars['Float']>;
  min_match_score_threshold?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment_game_master" */
export type DemiplaneUserRecruitmentGameMasterVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  min_match_score_threshold?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** input type for incrementing integer column in table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentIncInput = {
  id?: Maybe<Scalars['bigint']>;
  max_match_score?: Maybe<Scalars['Int']>;
  min_match_score?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentInsertInput = {
  adventurer?: Maybe<Scalars['Boolean']>;
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  game_master?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  max_match_score?: Maybe<Scalars['Int']>;
  min_match_score?: Maybe<Scalars['Int']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  uraps?: Maybe<DemiplaneUrapArrRelInsertInput>;
  urgts?: Maybe<DemiplaneUrgtArrRelInsertInput>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
  user_recruitment_availabilities?: Maybe<DemiplaneUserRecruitmentAvailabilityArrRelInsertInput>;
};

/** aggregate max on columns */
export type DemiplaneUserRecruitmentMaxFields = {
  __typename?: 'demiplane_user_recruitment_max_fields';
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  max_match_score?: Maybe<Scalars['Int']>;
  min_match_score?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentMaxOrderBy = {
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRecruitmentMinFields = {
  __typename?: 'demiplane_user_recruitment_min_fields';
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  max_match_score?: Maybe<Scalars['Int']>;
  min_match_score?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentMinOrderBy = {
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentMutationResponse = {
  __typename?: 'demiplane_user_recruitment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRecruitment>;
};

/** input type for inserting object relation for remote table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentObjRelInsertInput = {
  data: DemiplaneUserRecruitmentInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentOnConflict>;
};

/** on conflict condition type for table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentOnConflict = {
  constraint: DemiplaneUserRecruitmentConstraint;
  update_columns: Array<DemiplaneUserRecruitmentUpdateColumn>;
  where?: Maybe<DemiplaneUserRecruitmentBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentOrderBy = {
  adventurer?: Maybe<OrderBy>;
  availability_start_of_week?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  game_master?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  no_cost?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  uraps_aggregate?: Maybe<DemiplaneUrapAggregateOrderBy>;
  urgts_aggregate?: Maybe<DemiplaneUrgtAggregateOrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
  user_recruitment_availabilities_aggregate?: Maybe<DemiplaneUserRecruitmentAvailabilityAggregateOrderBy>;
};

/** primary key columns input for table: "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_recruitment" */
export enum DemiplaneUserRecruitmentSelectColumn {
  /** column name */
  Adventurer = 'adventurer',
  /** column name */
  AvailabilityStartOfWeek = 'availability_start_of_week',
  /** column name */
  Created = 'created',
  /** column name */
  GameMaster = 'game_master',
  /** column name */
  Id = 'id',
  /** column name */
  MaxMatchScore = 'max_match_score',
  /** column name */
  MinMatchScore = 'min_match_score',
  /** column name */
  NoCost = 'no_cost',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentSetInput = {
  adventurer?: Maybe<Scalars['Boolean']>;
  availability_start_of_week?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamptz']>;
  game_master?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  max_match_score?: Maybe<Scalars['Int']>;
  min_match_score?: Maybe<Scalars['Int']>;
  no_cost?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRecruitmentStddevFields = {
  __typename?: 'demiplane_user_recruitment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max_match_score?: Maybe<Scalars['Float']>;
  min_match_score?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentStddevOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRecruitmentStddevPopFields = {
  __typename?: 'demiplane_user_recruitment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_match_score?: Maybe<Scalars['Float']>;
  min_match_score?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRecruitmentStddevSampFields = {
  __typename?: 'demiplane_user_recruitment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_match_score?: Maybe<Scalars['Float']>;
  min_match_score?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRecruitmentSumFields = {
  __typename?: 'demiplane_user_recruitment_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  max_match_score?: Maybe<Scalars['Int']>;
  min_match_score?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentSumOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_recruitment" */
export enum DemiplaneUserRecruitmentUpdateColumn {
  /** column name */
  Adventurer = 'adventurer',
  /** column name */
  AvailabilityStartOfWeek = 'availability_start_of_week',
  /** column name */
  Created = 'created',
  /** column name */
  GameMaster = 'game_master',
  /** column name */
  Id = 'id',
  /** column name */
  MaxMatchScore = 'max_match_score',
  /** column name */
  MinMatchScore = 'min_match_score',
  /** column name */
  NoCost = 'no_cost',
  /** column name */
  Status = 'status',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRecruitmentVarPopFields = {
  __typename?: 'demiplane_user_recruitment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_match_score?: Maybe<Scalars['Float']>;
  min_match_score?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRecruitmentVarSampFields = {
  __typename?: 'demiplane_user_recruitment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_match_score?: Maybe<Scalars['Float']>;
  min_match_score?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRecruitmentVarianceFields = {
  __typename?: 'demiplane_user_recruitment_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max_match_score?: Maybe<Scalars['Float']>;
  min_match_score?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_recruitment" */
export type DemiplaneUserRecruitmentVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  max_match_score?: Maybe<OrderBy>;
  min_match_score?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.user_role" */
export type DemiplaneUserRole = {
  __typename?: 'demiplane_user_role';
  /** An object relationship */
  role: DemiplaneRole;
  roleId: Scalars['bigint'];
  /** An object relationship */
  user: DemiplaneUser;
  userId: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_role" */
export type DemiplaneUserRoleAggregate = {
  __typename?: 'demiplane_user_role_aggregate';
  aggregate?: Maybe<DemiplaneUserRoleAggregateFields>;
  nodes: Array<DemiplaneUserRole>;
};

/** aggregate fields of "demiplane.user_role" */
export type DemiplaneUserRoleAggregateFields = {
  __typename?: 'demiplane_user_role_aggregate_fields';
  avg?: Maybe<DemiplaneUserRoleAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserRoleMaxFields>;
  min?: Maybe<DemiplaneUserRoleMinFields>;
  stddev?: Maybe<DemiplaneUserRoleStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserRoleStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserRoleStddevSampFields>;
  sum?: Maybe<DemiplaneUserRoleSumFields>;
  var_pop?: Maybe<DemiplaneUserRoleVarPopFields>;
  var_samp?: Maybe<DemiplaneUserRoleVarSampFields>;
  variance?: Maybe<DemiplaneUserRoleVarianceFields>;
};


/** aggregate fields of "demiplane.user_role" */
export type DemiplaneUserRoleAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_role" */
export type DemiplaneUserRoleAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserRoleAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserRoleMaxOrderBy>;
  min?: Maybe<DemiplaneUserRoleMinOrderBy>;
  stddev?: Maybe<DemiplaneUserRoleStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserRoleStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserRoleStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserRoleSumOrderBy>;
  var_pop?: Maybe<DemiplaneUserRoleVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserRoleVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_role" */
export type DemiplaneUserRoleArrRelInsertInput = {
  data: Array<DemiplaneUserRoleInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRoleOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserRoleAvgFields = {
  __typename?: 'demiplane_user_role_avg_fields';
  roleId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleAvgOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_role". All fields are combined with a logical 'AND'. */
export type DemiplaneUserRoleBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserRoleBoolExp>>>;
  _not?: Maybe<DemiplaneUserRoleBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserRoleBoolExp>>>;
  role?: Maybe<DemiplaneRoleBoolExp>;
  roleId?: Maybe<BigintComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  userId?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_role" */
export enum DemiplaneUserRoleConstraint {
  /** unique or primary key constraint */
  PkUserRoleRoleIdUserId = 'pk_user_role_roleId_userId'
}

/** input type for incrementing integer column in table "demiplane.user_role" */
export type DemiplaneUserRoleIncInput = {
  roleId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_role" */
export type DemiplaneUserRoleInsertInput = {
  role?: Maybe<DemiplaneRoleObjRelInsertInput>;
  roleId?: Maybe<Scalars['bigint']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserRoleMaxFields = {
  __typename?: 'demiplane_user_role_max_fields';
  roleId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleMaxOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserRoleMinFields = {
  __typename?: 'demiplane_user_role_min_fields';
  roleId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleMinOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_role" */
export type DemiplaneUserRoleMutationResponse = {
  __typename?: 'demiplane_user_role_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserRole>;
};

/** input type for inserting object relation for remote table "demiplane.user_role" */
export type DemiplaneUserRoleObjRelInsertInput = {
  data: DemiplaneUserRoleInsertInput;
  on_conflict?: Maybe<DemiplaneUserRoleOnConflict>;
};

/** on conflict condition type for table "demiplane.user_role" */
export type DemiplaneUserRoleOnConflict = {
  constraint: DemiplaneUserRoleConstraint;
  update_columns: Array<DemiplaneUserRoleUpdateColumn>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_role" */
export type DemiplaneUserRoleOrderBy = {
  role?: Maybe<DemiplaneRoleOrderBy>;
  roleId?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  userId?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_role" */
export type DemiplaneUserRolePkColumnsInput = {
  roleId: Scalars['bigint'];
  userId: Scalars['bigint'];
};

/** select columns of table "demiplane.user_role" */
export enum DemiplaneUserRoleSelectColumn {
  /** column name */
  RoleId = 'roleId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "demiplane.user_role" */
export type DemiplaneUserRoleSetInput = {
  roleId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserRoleStddevFields = {
  __typename?: 'demiplane_user_role_stddev_fields';
  roleId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleStddevOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserRoleStddevPopFields = {
  __typename?: 'demiplane_user_role_stddev_pop_fields';
  roleId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleStddevPopOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserRoleStddevSampFields = {
  __typename?: 'demiplane_user_role_stddev_samp_fields';
  roleId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleStddevSampOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserRoleSumFields = {
  __typename?: 'demiplane_user_role_sum_fields';
  roleId?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleSumOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_role" */
export enum DemiplaneUserRoleUpdateColumn {
  /** column name */
  RoleId = 'roleId',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type DemiplaneUserRoleVarPopFields = {
  __typename?: 'demiplane_user_role_var_pop_fields';
  roleId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleVarPopOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserRoleVarSampFields = {
  __typename?: 'demiplane_user_role_var_samp_fields';
  roleId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleVarSampOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserRoleVarianceFields = {
  __typename?: 'demiplane_user_role_variance_fields';
  roleId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_role" */
export type DemiplaneUserRoleVarianceOrderBy = {
  roleId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
};

/** select columns of table "demiplane.user" */
export enum DemiplaneUserSelectColumn {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  AccessibilityFriendly = 'accessibility_friendly',
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  CognitoId = 'cognito_id',
  /** column name */
  Combat = 'combat',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  Experience = 'experience',
  /** column name */
  FacebookProfile = 'facebook_profile',
  /** column name */
  FeatureLevel = 'feature_level',
  /** column name */
  FirstLogin = 'first_login',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GameMasterBio = 'game_master_bio',
  /** column name */
  Id = 'id',
  /** column name */
  InactiveMonthAlertedEmail = 'inactive_month_alerted_email',
  /** column name */
  InactiveTwoMonthAlertedEmail = 'inactive_two_month_alerted_email',
  /** column name */
  InactiveWeekAlertedEmail = 'inactive_week_alerted_email',
  /** column name */
  InstagramProfile = 'instagram_profile',
  /** column name */
  Languages = 'languages',
  /** column name */
  LastAccess = 'last_access',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LgbtqiaFriendly = 'lgbtqia_friendly',
  /** column name */
  MindTheaterCombat = 'mind_theater_combat',
  /** column name */
  NewUserSecondEmail = 'new_user_second_email',
  /** column name */
  NewUserThirdEmail = 'new_user_third_email',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PronounId = 'pronoun_id',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  RecruitmentSetOrAlerted = 'recruitment_set_or_alerted',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  StripeConnectId = 'stripe_connect_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  TacticalGridCombat = 'tactical_grid_combat',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  TutorialSeen = 'tutorial_seen',
  /** column name */
  TwitchProfile = 'twitch_profile',
  /** column name */
  TwitterProfile = 'twitter_profile',
  /** column name */
  Updated = 'updated',
  /** column name */
  Username = 'username',
  /** column name */
  UsesVideoWebcam = 'uses_video_webcam',
  /** column name */
  UsesVoiceMic = 'uses_voice_mic',
  /** column name */
  Zipcode = 'zipcode',
  /** column name */
  ZipcodePlus_4Code = 'zipcode_plus_4_code'
}

/** input type for updating data in table "demiplane.user" */
export type DemiplaneUserSetInput = {
  access_level?: Maybe<Scalars['String']>;
  accessibility_friendly?: Maybe<Scalars['Boolean']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cognito_id?: Maybe<Scalars['String']>;
  combat?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['Int']>;
  facebook_profile?: Maybe<Scalars['String']>;
  feature_level?: Maybe<Scalars['String']>;
  first_login?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  game_master_bio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inactive_month_alerted_email?: Maybe<Scalars['Boolean']>;
  inactive_two_month_alerted_email?: Maybe<Scalars['Boolean']>;
  inactive_week_alerted_email?: Maybe<Scalars['Boolean']>;
  instagram_profile?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  last_access?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  lgbtqia_friendly?: Maybe<Scalars['Boolean']>;
  mind_theater_combat?: Maybe<Scalars['Boolean']>;
  new_user_second_email?: Maybe<Scalars['Boolean']>;
  new_user_third_email?: Maybe<Scalars['Boolean']>;
  player_story?: Maybe<Scalars['Int']>;
  pronoun_id?: Maybe<Scalars['bigint']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  recruitment_set_or_alerted?: Maybe<Scalars['Boolean']>;
  region_id?: Maybe<Scalars['bigint']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
  stripe_connect_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  tactical_grid_combat?: Maybe<Scalars['Boolean']>;
  time_zone?: Maybe<Scalars['String']>;
  tutorial_seen?: Maybe<Scalars['Boolean']>;
  twitch_profile?: Maybe<Scalars['String']>;
  twitter_profile?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  uses_video_webcam?: Maybe<Scalars['Boolean']>;
  uses_voice_mic?: Maybe<Scalars['Boolean']>;
  zipcode?: Maybe<Scalars['String']>;
  zipcode_plus_4_code?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserStddevFields = {
  __typename?: 'demiplane_user_stddev_fields';
  combat?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  experience?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  pronoun_id?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user" */
export type DemiplaneUserStddevOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserStddevPopFields = {
  __typename?: 'demiplane_user_stddev_pop_fields';
  combat?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  experience?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  pronoun_id?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user" */
export type DemiplaneUserStddevPopOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserStddevSampFields = {
  __typename?: 'demiplane_user_stddev_samp_fields';
  combat?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  experience?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  pronoun_id?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user" */
export type DemiplaneUserStddevSampOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserSumFields = {
  __typename?: 'demiplane_user_sum_fields';
  combat?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  experience?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  player_story?: Maybe<Scalars['Int']>;
  pronoun_id?: Maybe<Scalars['bigint']>;
  puzzles_logic?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['bigint']>;
  role_playing?: Maybe<Scalars['Int']>;
  social_intrigue?: Maybe<Scalars['Int']>;
  strict_rules?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "demiplane.user" */
export type DemiplaneUserSumOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user" */
export enum DemiplaneUserUpdateColumn {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  AccessibilityFriendly = 'accessibility_friendly',
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  City = 'city',
  /** column name */
  CognitoId = 'cognito_id',
  /** column name */
  Combat = 'combat',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  Experience = 'experience',
  /** column name */
  FacebookProfile = 'facebook_profile',
  /** column name */
  FeatureLevel = 'feature_level',
  /** column name */
  FirstLogin = 'first_login',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GameMasterBio = 'game_master_bio',
  /** column name */
  Id = 'id',
  /** column name */
  InactiveMonthAlertedEmail = 'inactive_month_alerted_email',
  /** column name */
  InactiveTwoMonthAlertedEmail = 'inactive_two_month_alerted_email',
  /** column name */
  InactiveWeekAlertedEmail = 'inactive_week_alerted_email',
  /** column name */
  InstagramProfile = 'instagram_profile',
  /** column name */
  Languages = 'languages',
  /** column name */
  LastAccess = 'last_access',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LgbtqiaFriendly = 'lgbtqia_friendly',
  /** column name */
  MindTheaterCombat = 'mind_theater_combat',
  /** column name */
  NewUserSecondEmail = 'new_user_second_email',
  /** column name */
  NewUserThirdEmail = 'new_user_third_email',
  /** column name */
  PlayerStory = 'player_story',
  /** column name */
  PronounId = 'pronoun_id',
  /** column name */
  PuzzlesLogic = 'puzzles_logic',
  /** column name */
  RecruitmentSetOrAlerted = 'recruitment_set_or_alerted',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RolePlaying = 'role_playing',
  /** column name */
  SocialIntrigue = 'social_intrigue',
  /** column name */
  StrictRules = 'strict_rules',
  /** column name */
  StripeConnectId = 'stripe_connect_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  TacticalGridCombat = 'tactical_grid_combat',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  TutorialSeen = 'tutorial_seen',
  /** column name */
  TwitchProfile = 'twitch_profile',
  /** column name */
  TwitterProfile = 'twitter_profile',
  /** column name */
  Updated = 'updated',
  /** column name */
  Username = 'username',
  /** column name */
  UsesVideoWebcam = 'uses_video_webcam',
  /** column name */
  UsesVoiceMic = 'uses_voice_mic',
  /** column name */
  Zipcode = 'zipcode',
  /** column name */
  ZipcodePlus_4Code = 'zipcode_plus_4_code'
}

/** aggregate var_pop on columns */
export type DemiplaneUserVarPopFields = {
  __typename?: 'demiplane_user_var_pop_fields';
  combat?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  experience?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  pronoun_id?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user" */
export type DemiplaneUserVarPopOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserVarSampFields = {
  __typename?: 'demiplane_user_var_samp_fields';
  combat?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  experience?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  pronoun_id?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user" */
export type DemiplaneUserVarSampOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserVarianceFields = {
  __typename?: 'demiplane_user_variance_fields';
  combat?: Maybe<Scalars['Float']>;
  country_id?: Maybe<Scalars['Float']>;
  experience?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  player_story?: Maybe<Scalars['Float']>;
  pronoun_id?: Maybe<Scalars['Float']>;
  puzzles_logic?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  role_playing?: Maybe<Scalars['Float']>;
  social_intrigue?: Maybe<Scalars['Float']>;
  strict_rules?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user" */
export type DemiplaneUserVarianceOrderBy = {
  combat?: Maybe<OrderBy>;
  country_id?: Maybe<OrderBy>;
  experience?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  player_story?: Maybe<OrderBy>;
  pronoun_id?: Maybe<OrderBy>;
  puzzles_logic?: Maybe<OrderBy>;
  region_id?: Maybe<OrderBy>;
  role_playing?: Maybe<OrderBy>;
  social_intrigue?: Maybe<OrderBy>;
  strict_rules?: Maybe<OrderBy>;
};

/** columns and relationships of "demiplane.user_visibility" */
export type DemiplaneUserVisibility = {
  __typename?: 'demiplane_user_visibility';
  adventure_ratings: Scalars['Boolean'];
  created: Scalars['timestamptz'];
  facebook_profile: Scalars['Boolean'];
  how_i_play: Scalars['Boolean'];
  id: Scalars['bigint'];
  instagram_profile: Scalars['Boolean'];
  my_favorite_games: Scalars['Boolean'];
  my_favorite_platforms: Scalars['Boolean'];
  my_pronouns: Scalars['Boolean'];
  my_recommendations: Scalars['Boolean'];
  my_treasure_trove: Scalars['Boolean'];
  twitch_profile: Scalars['Boolean'];
  twitter_profile: Scalars['Boolean'];
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: DemiplaneUser;
  user_id: Scalars['bigint'];
};

/** aggregated selection of "demiplane.user_visibility" */
export type DemiplaneUserVisibilityAggregate = {
  __typename?: 'demiplane_user_visibility_aggregate';
  aggregate?: Maybe<DemiplaneUserVisibilityAggregateFields>;
  nodes: Array<DemiplaneUserVisibility>;
};

/** aggregate fields of "demiplane.user_visibility" */
export type DemiplaneUserVisibilityAggregateFields = {
  __typename?: 'demiplane_user_visibility_aggregate_fields';
  avg?: Maybe<DemiplaneUserVisibilityAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<DemiplaneUserVisibilityMaxFields>;
  min?: Maybe<DemiplaneUserVisibilityMinFields>;
  stddev?: Maybe<DemiplaneUserVisibilityStddevFields>;
  stddev_pop?: Maybe<DemiplaneUserVisibilityStddevPopFields>;
  stddev_samp?: Maybe<DemiplaneUserVisibilityStddevSampFields>;
  sum?: Maybe<DemiplaneUserVisibilitySumFields>;
  var_pop?: Maybe<DemiplaneUserVisibilityVarPopFields>;
  var_samp?: Maybe<DemiplaneUserVisibilityVarSampFields>;
  variance?: Maybe<DemiplaneUserVisibilityVarianceFields>;
};


/** aggregate fields of "demiplane.user_visibility" */
export type DemiplaneUserVisibilityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DemiplaneUserVisibilitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityAggregateOrderBy = {
  avg?: Maybe<DemiplaneUserVisibilityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<DemiplaneUserVisibilityMaxOrderBy>;
  min?: Maybe<DemiplaneUserVisibilityMinOrderBy>;
  stddev?: Maybe<DemiplaneUserVisibilityStddevOrderBy>;
  stddev_pop?: Maybe<DemiplaneUserVisibilityStddevPopOrderBy>;
  stddev_samp?: Maybe<DemiplaneUserVisibilityStddevSampOrderBy>;
  sum?: Maybe<DemiplaneUserVisibilitySumOrderBy>;
  var_pop?: Maybe<DemiplaneUserVisibilityVarPopOrderBy>;
  var_samp?: Maybe<DemiplaneUserVisibilityVarSampOrderBy>;
  variance?: Maybe<DemiplaneUserVisibilityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityArrRelInsertInput = {
  data: Array<DemiplaneUserVisibilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserVisibilityOnConflict>;
};

/** aggregate avg on columns */
export type DemiplaneUserVisibilityAvgFields = {
  __typename?: 'demiplane_user_visibility_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityAvgOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "demiplane.user_visibility". All fields are combined with a logical 'AND'. */
export type DemiplaneUserVisibilityBoolExp = {
  _and?: Maybe<Array<Maybe<DemiplaneUserVisibilityBoolExp>>>;
  _not?: Maybe<DemiplaneUserVisibilityBoolExp>;
  _or?: Maybe<Array<Maybe<DemiplaneUserVisibilityBoolExp>>>;
  adventure_ratings?: Maybe<BooleanComparisonExp>;
  created?: Maybe<TimestamptzComparisonExp>;
  facebook_profile?: Maybe<BooleanComparisonExp>;
  how_i_play?: Maybe<BooleanComparisonExp>;
  id?: Maybe<BigintComparisonExp>;
  instagram_profile?: Maybe<BooleanComparisonExp>;
  my_favorite_games?: Maybe<BooleanComparisonExp>;
  my_favorite_platforms?: Maybe<BooleanComparisonExp>;
  my_pronouns?: Maybe<BooleanComparisonExp>;
  my_recommendations?: Maybe<BooleanComparisonExp>;
  my_treasure_trove?: Maybe<BooleanComparisonExp>;
  twitch_profile?: Maybe<BooleanComparisonExp>;
  twitter_profile?: Maybe<BooleanComparisonExp>;
  updated?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<DemiplaneUserBoolExp>;
  user_id?: Maybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "demiplane.user_visibility" */
export enum DemiplaneUserVisibilityConstraint {
  /** unique or primary key constraint */
  UserVisibilityPkey = 'user_visibility_pkey',
  /** unique or primary key constraint */
  UserVisibilityUserIdKey = 'user_visibility_user_id_key'
}

/** input type for incrementing integer column in table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityIncInput = {
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityInsertInput = {
  adventure_ratings?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  facebook_profile?: Maybe<Scalars['Boolean']>;
  how_i_play?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_profile?: Maybe<Scalars['Boolean']>;
  my_favorite_games?: Maybe<Scalars['Boolean']>;
  my_favorite_platforms?: Maybe<Scalars['Boolean']>;
  my_pronouns?: Maybe<Scalars['Boolean']>;
  my_recommendations?: Maybe<Scalars['Boolean']>;
  my_treasure_trove?: Maybe<Scalars['Boolean']>;
  twitch_profile?: Maybe<Scalars['Boolean']>;
  twitter_profile?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<DemiplaneUserObjRelInsertInput>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type DemiplaneUserVisibilityMaxFields = {
  __typename?: 'demiplane_user_visibility_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityMaxOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type DemiplaneUserVisibilityMinFields = {
  __typename?: 'demiplane_user_visibility_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityMinOrderBy = {
  created?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityMutationResponse = {
  __typename?: 'demiplane_user_visibility_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<DemiplaneUserVisibility>;
};

/** input type for inserting object relation for remote table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityObjRelInsertInput = {
  data: DemiplaneUserVisibilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserVisibilityOnConflict>;
};

/** on conflict condition type for table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityOnConflict = {
  constraint: DemiplaneUserVisibilityConstraint;
  update_columns: Array<DemiplaneUserVisibilityUpdateColumn>;
  where?: Maybe<DemiplaneUserVisibilityBoolExp>;
};

/** ordering options when selecting data from "demiplane.user_visibility" */
export type DemiplaneUserVisibilityOrderBy = {
  adventure_ratings?: Maybe<OrderBy>;
  created?: Maybe<OrderBy>;
  facebook_profile?: Maybe<OrderBy>;
  how_i_play?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  instagram_profile?: Maybe<OrderBy>;
  my_favorite_games?: Maybe<OrderBy>;
  my_favorite_platforms?: Maybe<OrderBy>;
  my_pronouns?: Maybe<OrderBy>;
  my_recommendations?: Maybe<OrderBy>;
  my_treasure_trove?: Maybe<OrderBy>;
  twitch_profile?: Maybe<OrderBy>;
  twitter_profile?: Maybe<OrderBy>;
  updated?: Maybe<OrderBy>;
  user?: Maybe<DemiplaneUserOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "demiplane.user_visibility" */
export type DemiplaneUserVisibilityPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "demiplane.user_visibility" */
export enum DemiplaneUserVisibilitySelectColumn {
  /** column name */
  AdventureRatings = 'adventure_ratings',
  /** column name */
  Created = 'created',
  /** column name */
  FacebookProfile = 'facebook_profile',
  /** column name */
  HowIPlay = 'how_i_play',
  /** column name */
  Id = 'id',
  /** column name */
  InstagramProfile = 'instagram_profile',
  /** column name */
  MyFavoriteGames = 'my_favorite_games',
  /** column name */
  MyFavoritePlatforms = 'my_favorite_platforms',
  /** column name */
  MyPronouns = 'my_pronouns',
  /** column name */
  MyRecommendations = 'my_recommendations',
  /** column name */
  MyTreasureTrove = 'my_treasure_trove',
  /** column name */
  TwitchProfile = 'twitch_profile',
  /** column name */
  TwitterProfile = 'twitter_profile',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "demiplane.user_visibility" */
export type DemiplaneUserVisibilitySetInput = {
  adventure_ratings?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  facebook_profile?: Maybe<Scalars['Boolean']>;
  how_i_play?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_profile?: Maybe<Scalars['Boolean']>;
  my_favorite_games?: Maybe<Scalars['Boolean']>;
  my_favorite_platforms?: Maybe<Scalars['Boolean']>;
  my_pronouns?: Maybe<Scalars['Boolean']>;
  my_recommendations?: Maybe<Scalars['Boolean']>;
  my_treasure_trove?: Maybe<Scalars['Boolean']>;
  twitch_profile?: Maybe<Scalars['Boolean']>;
  twitter_profile?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type DemiplaneUserVisibilityStddevFields = {
  __typename?: 'demiplane_user_visibility_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityStddevOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DemiplaneUserVisibilityStddevPopFields = {
  __typename?: 'demiplane_user_visibility_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DemiplaneUserVisibilityStddevSampFields = {
  __typename?: 'demiplane_user_visibility_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type DemiplaneUserVisibilitySumFields = {
  __typename?: 'demiplane_user_visibility_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilitySumOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** update columns of table "demiplane.user_visibility" */
export enum DemiplaneUserVisibilityUpdateColumn {
  /** column name */
  AdventureRatings = 'adventure_ratings',
  /** column name */
  Created = 'created',
  /** column name */
  FacebookProfile = 'facebook_profile',
  /** column name */
  HowIPlay = 'how_i_play',
  /** column name */
  Id = 'id',
  /** column name */
  InstagramProfile = 'instagram_profile',
  /** column name */
  MyFavoriteGames = 'my_favorite_games',
  /** column name */
  MyFavoritePlatforms = 'my_favorite_platforms',
  /** column name */
  MyPronouns = 'my_pronouns',
  /** column name */
  MyRecommendations = 'my_recommendations',
  /** column name */
  MyTreasureTrove = 'my_treasure_trove',
  /** column name */
  TwitchProfile = 'twitch_profile',
  /** column name */
  TwitterProfile = 'twitter_profile',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type DemiplaneUserVisibilityVarPopFields = {
  __typename?: 'demiplane_user_visibility_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DemiplaneUserVisibilityVarSampFields = {
  __typename?: 'demiplane_user_visibility_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type DemiplaneUserVisibilityVarianceFields = {
  __typename?: 'demiplane_user_visibility_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "demiplane.user_visibility" */
export type DemiplaneUserVisibilityVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type JsonComparisonExp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  acceptInvite: AdventureConnectionType;
  acceptRecruit: AdventureConnectionType;
  acceptRequest: AdventureConnectionType;
  addAdventureSlim: AdventureType;
  addCreditCard: CreditCardType;
  /** perform the action: "addFavoriteGame" */
  addFavoriteGame?: Maybe<AddFavoriteGameOutput>;
  /** perform the action: "addFavoritePlatform" */
  addFavoritePlatform?: Maybe<AddFavoritePlatformOutput>;
  addJournalSuperEvent: JournalSuperEventType;
  /** perform the action: "addNewPortalV2" */
  addNewPortalV2?: Maybe<AddNewPortalV2Output>;
  addUser: UserType;
  attendEpisode: Scalars['Boolean'];
  /** perform the action: "authMM" */
  authMM?: Maybe<AuthMmOutput>;
  calculateFeesWithTip: SessionType;
  cancelPayment: Scalars['Boolean'];
  cancelSession: SessionMutationType;
  cantMakeEpisode: Scalars['Boolean'];
  capturePayment: PaymentType;
  captureTipPayment: PaymentType;
  completeRatingFlow: SessionPlayerType;
  completeTask: TaskType;
  contactGameMaster: AdventureType;
  createFavoriteDiceRoll: FavoriteDiceRollType;
  createTask: TaskType;
  createUpload: UploadType;
  declineInvite: AdventureConnectionType;
  declineRecruit: AdventureConnectionType;
  declineRequest: AdventureConnectionType;
  declineTask: TaskType;
  deleteAdventure: Scalars['Boolean'];
  deleteFavoriteDiceRoll: Scalars['Boolean'];
  deleteJournalSuperEvent: JournalSuperEventDeleteType;
  deleteUpload: Scalars['Boolean'];
  /** delete data from the table: "demiplane.ad" */
  delete_demiplane_ad?: Maybe<DemiplaneAdMutationResponse>;
  /** delete single row from the table: "demiplane.ad" */
  delete_demiplane_ad_by_pk?: Maybe<DemiplaneAd>;
  /** delete data from the table: "demiplane.ad_link" */
  delete_demiplane_ad_link?: Maybe<DemiplaneAdLinkMutationResponse>;
  /** delete single row from the table: "demiplane.ad_link" */
  delete_demiplane_ad_link_by_pk?: Maybe<DemiplaneAdLink>;
  /** delete data from the table: "demiplane.ad_match_criteria" */
  delete_demiplane_ad_match_criteria?: Maybe<DemiplaneAdMatchCriteriaMutationResponse>;
  /** delete single row from the table: "demiplane.ad_match_criteria" */
  delete_demiplane_ad_match_criteria_by_pk?: Maybe<DemiplaneAdMatchCriteria>;
  /** delete data from the table: "demiplane.ad_match_criteria_time" */
  delete_demiplane_ad_match_criteria_time?: Maybe<DemiplaneAdMatchCriteriaTimeMutationResponse>;
  /** delete single row from the table: "demiplane.ad_match_criteria_time" */
  delete_demiplane_ad_match_criteria_time_by_pk?: Maybe<DemiplaneAdMatchCriteriaTime>;
  /** delete data from the table: "demiplane.ad_request" */
  delete_demiplane_ad_request?: Maybe<DemiplaneAdRequestMutationResponse>;
  /** delete single row from the table: "demiplane.ad_request" */
  delete_demiplane_ad_request_by_pk?: Maybe<DemiplaneAdRequest>;
  /** delete data from the table: "demiplane.ad_time" */
  delete_demiplane_ad_time?: Maybe<DemiplaneAdTimeMutationResponse>;
  /** delete single row from the table: "demiplane.ad_time" */
  delete_demiplane_ad_time_by_pk?: Maybe<DemiplaneAdTime>;
  /** delete data from the table: "demiplane.adventure" */
  delete_demiplane_adventure?: Maybe<DemiplaneAdventureMutationResponse>;
  /** delete single row from the table: "demiplane.adventure" */
  delete_demiplane_adventure_by_pk?: Maybe<DemiplaneAdventure>;
  /** delete data from the table: "demiplane.adventure_connection" */
  delete_demiplane_adventure_connection?: Maybe<DemiplaneAdventureConnectionMutationResponse>;
  /** delete single row from the table: "demiplane.adventure_connection" */
  delete_demiplane_adventure_connection_by_pk?: Maybe<DemiplaneAdventureConnection>;
  /** delete data from the table: "demiplane.adventure_demiplane_tool" */
  delete_demiplane_adventure_demiplane_tool?: Maybe<DemiplaneAdventureDemiplaneToolMutationResponse>;
  /** delete single row from the table: "demiplane.adventure_demiplane_tool" */
  delete_demiplane_adventure_demiplane_tool_by_pk?: Maybe<DemiplaneAdventureDemiplaneTool>;
  /** delete data from the table: "demiplane.adventure_link" */
  delete_demiplane_adventure_link?: Maybe<DemiplaneAdventureLinkMutationResponse>;
  /** delete single row from the table: "demiplane.adventure_link" */
  delete_demiplane_adventure_link_by_pk?: Maybe<DemiplaneAdventureLink>;
  /** delete data from the table: "demiplane.adventure_player" */
  delete_demiplane_adventure_player?: Maybe<DemiplaneAdventurePlayerMutationResponse>;
  /** delete single row from the table: "demiplane.adventure_player" */
  delete_demiplane_adventure_player_by_pk?: Maybe<DemiplaneAdventurePlayer>;
  /** delete data from the table: "demiplane.adventure_todo" */
  delete_demiplane_adventure_todo?: Maybe<DemiplaneAdventureTodoMutationResponse>;
  /** delete single row from the table: "demiplane.adventure_todo" */
  delete_demiplane_adventure_todo_by_pk?: Maybe<DemiplaneAdventureTodo>;
  /** delete data from the table: "demiplane.adventuring_platform" */
  delete_demiplane_adventuring_platform?: Maybe<DemiplaneAdventuringPlatformMutationResponse>;
  /** delete single row from the table: "demiplane.adventuring_platform" */
  delete_demiplane_adventuring_platform_by_pk?: Maybe<DemiplaneAdventuringPlatform>;
  /** delete data from the table: "demiplane.amc_adventuring_platforms" */
  delete_demiplane_amc_adventuring_platforms?: Maybe<DemiplaneAmcAdventuringPlatformsMutationResponse>;
  /** delete single row from the table: "demiplane.amc_adventuring_platforms" */
  delete_demiplane_amc_adventuring_platforms_by_pk?: Maybe<DemiplaneAmcAdventuringPlatforms>;
  /** delete data from the table: "demiplane.amc_game_types" */
  delete_demiplane_amc_game_types?: Maybe<DemiplaneAmcGameTypesMutationResponse>;
  /** delete single row from the table: "demiplane.amc_game_types" */
  delete_demiplane_amc_game_types_by_pk?: Maybe<DemiplaneAmcGameTypes>;
  /** delete data from the table: "demiplane.app_version" */
  delete_demiplane_app_version?: Maybe<DemiplaneAppVersionMutationResponse>;
  /** delete single row from the table: "demiplane.app_version" */
  delete_demiplane_app_version_by_pk?: Maybe<DemiplaneAppVersion>;
  /** delete data from the table: "demiplane.attachment" */
  delete_demiplane_attachment?: Maybe<DemiplaneAttachmentMutationResponse>;
  /** delete single row from the table: "demiplane.attachment" */
  delete_demiplane_attachment_by_pk?: Maybe<DemiplaneAttachment>;
  /** delete data from the table: "demiplane.award_type" */
  delete_demiplane_award_type?: Maybe<DemiplaneAwardTypeMutationResponse>;
  /** delete single row from the table: "demiplane.award_type" */
  delete_demiplane_award_type_by_pk?: Maybe<DemiplaneAwardType>;
  /** delete data from the table: "demiplane.connection_status" */
  delete_demiplane_connection_status?: Maybe<DemiplaneConnectionStatusMutationResponse>;
  /** delete single row from the table: "demiplane.connection_status" */
  delete_demiplane_connection_status_by_pk?: Maybe<DemiplaneConnectionStatus>;
  /** delete data from the table: "demiplane.connection_type" */
  delete_demiplane_connection_type?: Maybe<DemiplaneConnectionTypeMutationResponse>;
  /** delete single row from the table: "demiplane.connection_type" */
  delete_demiplane_connection_type_by_pk?: Maybe<DemiplaneConnectionType>;
  /** delete data from the table: "demiplane.contact_type" */
  delete_demiplane_contact_type?: Maybe<DemiplaneContactTypeMutationResponse>;
  /** delete single row from the table: "demiplane.contact_type" */
  delete_demiplane_contact_type_by_pk?: Maybe<DemiplaneContactType>;
  /** delete data from the table: "demiplane.country" */
  delete_demiplane_country?: Maybe<DemiplaneCountryMutationResponse>;
  /** delete single row from the table: "demiplane.country" */
  delete_demiplane_country_by_pk?: Maybe<DemiplaneCountry>;
  /** delete data from the table: "demiplane.credit_card" */
  delete_demiplane_credit_card?: Maybe<DemiplaneCreditCardMutationResponse>;
  /** delete single row from the table: "demiplane.credit_card" */
  delete_demiplane_credit_card_by_pk?: Maybe<DemiplaneCreditCard>;
  /** delete data from the table: "demiplane.demiplane_tool" */
  delete_demiplane_demiplane_tool?: Maybe<DemiplaneDemiplaneToolMutationResponse>;
  /** delete single row from the table: "demiplane.demiplane_tool" */
  delete_demiplane_demiplane_tool_by_pk?: Maybe<DemiplaneDemiplaneTool>;
  /** delete data from the table: "demiplane.episode_attendance" */
  delete_demiplane_episode_attendance?: Maybe<DemiplaneEpisodeAttendanceMutationResponse>;
  /** delete single row from the table: "demiplane.episode_attendance" */
  delete_demiplane_episode_attendance_by_pk?: Maybe<DemiplaneEpisodeAttendance>;
  /** delete data from the table: "demiplane.event" */
  delete_demiplane_event?: Maybe<DemiplaneEventMutationResponse>;
  /** delete single row from the table: "demiplane.event" */
  delete_demiplane_event_by_pk?: Maybe<DemiplaneEvent>;
  /** delete data from the table: "demiplane.event_type" */
  delete_demiplane_event_type?: Maybe<DemiplaneEventTypeMutationResponse>;
  /** delete single row from the table: "demiplane.event_type" */
  delete_demiplane_event_type_by_pk?: Maybe<DemiplaneEventType>;
  /** delete data from the table: "demiplane.favorite_adventuring_platform" */
  delete_demiplane_favorite_adventuring_platform?: Maybe<DemiplaneFavoriteAdventuringPlatformMutationResponse>;
  /** delete single row from the table: "demiplane.favorite_adventuring_platform" */
  delete_demiplane_favorite_adventuring_platform_by_pk?: Maybe<DemiplaneFavoriteAdventuringPlatform>;
  /** delete data from the table: "demiplane.favorite_dice_roll" */
  delete_demiplane_favorite_dice_roll?: Maybe<DemiplaneFavoriteDiceRollMutationResponse>;
  /** delete single row from the table: "demiplane.favorite_dice_roll" */
  delete_demiplane_favorite_dice_roll_by_pk?: Maybe<DemiplaneFavoriteDiceRoll>;
  /** delete data from the table: "demiplane.favorite_game" */
  delete_demiplane_favorite_game?: Maybe<DemiplaneFavoriteGameMutationResponse>;
  /** delete single row from the table: "demiplane.favorite_game" */
  delete_demiplane_favorite_game_by_pk?: Maybe<DemiplaneFavoriteGame>;
  /** delete data from the table: "demiplane.game_type" */
  delete_demiplane_game_type?: Maybe<DemiplaneGameTypeMutationResponse>;
  /** delete single row from the table: "demiplane.game_type" */
  delete_demiplane_game_type_by_pk?: Maybe<DemiplaneGameType>;
  /** delete data from the table: "demiplane.gm_rating" */
  delete_demiplane_gm_rating?: Maybe<DemiplaneGmRatingMutationResponse>;
  /** delete single row from the table: "demiplane.gm_rating" */
  delete_demiplane_gm_rating_by_pk?: Maybe<DemiplaneGmRating>;
  /** delete data from the table: "demiplane.gm_recommendation" */
  delete_demiplane_gm_recommendation?: Maybe<DemiplaneGmRecommendationMutationResponse>;
  /** delete single row from the table: "demiplane.gm_recommendation" */
  delete_demiplane_gm_recommendation_by_pk?: Maybe<DemiplaneGmRecommendation>;
  /** delete data from the table: "demiplane.invitation" */
  delete_demiplane_invitation?: Maybe<DemiplaneInvitationMutationResponse>;
  /** delete single row from the table: "demiplane.invitation" */
  delete_demiplane_invitation_by_pk?: Maybe<DemiplaneInvitation>;
  /** delete data from the table: "demiplane.journal" */
  delete_demiplane_journal?: Maybe<DemiplaneJournalMutationResponse>;
  /** delete single row from the table: "demiplane.journal" */
  delete_demiplane_journal_by_pk?: Maybe<DemiplaneJournal>;
  /** delete data from the table: "demiplane.journal_note" */
  delete_demiplane_journal_note?: Maybe<DemiplaneJournalNoteMutationResponse>;
  /** delete single row from the table: "demiplane.journal_note" */
  delete_demiplane_journal_note_by_pk?: Maybe<DemiplaneJournalNote>;
  /** delete data from the table: "demiplane.journal_note_share" */
  delete_demiplane_journal_note_share?: Maybe<DemiplaneJournalNoteShareMutationResponse>;
  /** delete single row from the table: "demiplane.journal_note_share" */
  delete_demiplane_journal_note_share_by_pk?: Maybe<DemiplaneJournalNoteShare>;
  /** delete data from the table: "demiplane.journal_super_event" */
  delete_demiplane_journal_super_event?: Maybe<DemiplaneJournalSuperEventMutationResponse>;
  /** delete single row from the table: "demiplane.journal_super_event" */
  delete_demiplane_journal_super_event_by_pk?: Maybe<DemiplaneJournalSuperEvent>;
  /** delete data from the table: "demiplane.journal_super_event_read_status" */
  delete_demiplane_journal_super_event_read_status?: Maybe<DemiplaneJournalSuperEventReadStatusMutationResponse>;
  /** delete single row from the table: "demiplane.journal_super_event_read_status" */
  delete_demiplane_journal_super_event_read_status_by_pk?: Maybe<DemiplaneJournalSuperEventReadStatus>;
  /** delete data from the table: "demiplane.journal_title" */
  delete_demiplane_journal_title?: Maybe<DemiplaneJournalTitleMutationResponse>;
  /** delete single row from the table: "demiplane.journal_title" */
  delete_demiplane_journal_title_by_pk?: Maybe<DemiplaneJournalTitle>;
  /** delete data from the table: "demiplane.matchmaking_token" */
  delete_demiplane_matchmaking_token?: Maybe<DemiplaneMatchmakingTokenMutationResponse>;
  /** delete single row from the table: "demiplane.matchmaking_token" */
  delete_demiplane_matchmaking_token_by_pk?: Maybe<DemiplaneMatchmakingToken>;
  /** delete data from the table: "demiplane.migrations" */
  delete_demiplane_migrations?: Maybe<DemiplaneMigrationsMutationResponse>;
  /** delete single row from the table: "demiplane.migrations" */
  delete_demiplane_migrations_by_pk?: Maybe<DemiplaneMigrations>;
  /** delete data from the table: "demiplane.notification_history" */
  delete_demiplane_notification_history?: Maybe<DemiplaneNotificationHistoryMutationResponse>;
  /** delete single row from the table: "demiplane.notification_history" */
  delete_demiplane_notification_history_by_pk?: Maybe<DemiplaneNotificationHistory>;
  /** delete data from the table: "demiplane.payment" */
  delete_demiplane_payment?: Maybe<DemiplanePaymentMutationResponse>;
  /** delete single row from the table: "demiplane.payment" */
  delete_demiplane_payment_by_pk?: Maybe<DemiplanePayment>;
  /** delete data from the table: "demiplane.player_award" */
  delete_demiplane_player_award?: Maybe<DemiplanePlayerAwardMutationResponse>;
  /** delete single row from the table: "demiplane.player_award" */
  delete_demiplane_player_award_by_pk?: Maybe<DemiplanePlayerAward>;
  /** delete data from the table: "demiplane.player_rating" */
  delete_demiplane_player_rating?: Maybe<DemiplanePlayerRatingMutationResponse>;
  /** delete single row from the table: "demiplane.player_rating" */
  delete_demiplane_player_rating_by_pk?: Maybe<DemiplanePlayerRating>;
  /** delete data from the table: "demiplane.pronoun" */
  delete_demiplane_pronoun?: Maybe<DemiplanePronounMutationResponse>;
  /** delete single row from the table: "demiplane.pronoun" */
  delete_demiplane_pronoun_by_pk?: Maybe<DemiplanePronoun>;
  /** delete data from the table: "demiplane.region" */
  delete_demiplane_region?: Maybe<DemiplaneRegionMutationResponse>;
  /** delete single row from the table: "demiplane.region" */
  delete_demiplane_region_by_pk?: Maybe<DemiplaneRegion>;
  /** delete data from the table: "demiplane.role" */
  delete_demiplane_role?: Maybe<DemiplaneRoleMutationResponse>;
  /** delete single row from the table: "demiplane.role" */
  delete_demiplane_role_by_pk?: Maybe<DemiplaneRole>;
  /** delete data from the table: "demiplane.session" */
  delete_demiplane_session?: Maybe<DemiplaneSessionMutationResponse>;
  /** delete single row from the table: "demiplane.session" */
  delete_demiplane_session_by_pk?: Maybe<DemiplaneSession>;
  /** delete data from the table: "demiplane.session_player" */
  delete_demiplane_session_player?: Maybe<DemiplaneSessionPlayerMutationResponse>;
  /** delete single row from the table: "demiplane.session_player" */
  delete_demiplane_session_player_by_pk?: Maybe<DemiplaneSessionPlayer>;
  /** delete data from the table: "demiplane.snippet" */
  delete_demiplane_snippet?: Maybe<DemiplaneSnippetMutationResponse>;
  /** delete single row from the table: "demiplane.snippet" */
  delete_demiplane_snippet_by_pk?: Maybe<DemiplaneSnippet>;
  /** delete data from the table: "demiplane.task" */
  delete_demiplane_task?: Maybe<DemiplaneTaskMutationResponse>;
  /** delete single row from the table: "demiplane.task" */
  delete_demiplane_task_by_pk?: Maybe<DemiplaneTask>;
  /** delete data from the table: "demiplane.task_read_status" */
  delete_demiplane_task_read_status?: Maybe<DemiplaneTaskReadStatusMutationResponse>;
  /** delete single row from the table: "demiplane.task_read_status" */
  delete_demiplane_task_read_status_by_pk?: Maybe<DemiplaneTaskReadStatus>;
  /** delete data from the table: "demiplane.tax_nexus_region" */
  delete_demiplane_tax_nexus_region?: Maybe<DemiplaneTaxNexusRegionMutationResponse>;
  /** delete single row from the table: "demiplane.tax_nexus_region" */
  delete_demiplane_tax_nexus_region_by_pk?: Maybe<DemiplaneTaxNexusRegion>;
  /** delete data from the table: "demiplane.transaction_receipt" */
  delete_demiplane_transaction_receipt?: Maybe<DemiplaneTransactionReceiptMutationResponse>;
  /** delete single row from the table: "demiplane.transaction_receipt" */
  delete_demiplane_transaction_receipt_by_pk?: Maybe<DemiplaneTransactionReceipt>;
  /** delete data from the table: "demiplane.upload" */
  delete_demiplane_upload?: Maybe<DemiplaneUploadMutationResponse>;
  /** delete single row from the table: "demiplane.upload" */
  delete_demiplane_upload_by_pk?: Maybe<DemiplaneUpload>;
  /** delete data from the table: "demiplane.urap" */
  delete_demiplane_urap?: Maybe<DemiplaneUrapMutationResponse>;
  /** delete single row from the table: "demiplane.urap" */
  delete_demiplane_urap_by_pk?: Maybe<DemiplaneUrap>;
  /** delete data from the table: "demiplane.urgt" */
  delete_demiplane_urgt?: Maybe<DemiplaneUrgtMutationResponse>;
  /** delete single row from the table: "demiplane.urgt" */
  delete_demiplane_urgt_by_pk?: Maybe<DemiplaneUrgt>;
  /** delete data from the table: "demiplane.user" */
  delete_demiplane_user?: Maybe<DemiplaneUserMutationResponse>;
  /** delete data from the table: "demiplane.user_activity" */
  delete_demiplane_user_activity?: Maybe<DemiplaneUserActivityMutationResponse>;
  /** delete single row from the table: "demiplane.user_activity" */
  delete_demiplane_user_activity_by_pk?: Maybe<DemiplaneUserActivity>;
  /** delete data from the table: "demiplane.user_adventure" */
  delete_demiplane_user_adventure?: Maybe<DemiplaneUserAdventureMutationResponse>;
  /** delete single row from the table: "demiplane.user_adventure" */
  delete_demiplane_user_adventure_by_pk?: Maybe<DemiplaneUserAdventure>;
  /** delete data from the table: "demiplane.user_adventure_ranking" */
  delete_demiplane_user_adventure_ranking?: Maybe<DemiplaneUserAdventureRankingMutationResponse>;
  /** delete single row from the table: "demiplane.user_adventure_ranking" */
  delete_demiplane_user_adventure_ranking_by_pk?: Maybe<DemiplaneUserAdventureRanking>;
  /** delete data from the table: "demiplane.user_adventure_role" */
  delete_demiplane_user_adventure_role?: Maybe<DemiplaneUserAdventureRoleMutationResponse>;
  /** delete single row from the table: "demiplane.user_adventure_role" */
  delete_demiplane_user_adventure_role_by_pk?: Maybe<DemiplaneUserAdventureRole>;
  /** delete single row from the table: "demiplane.user" */
  delete_demiplane_user_by_pk?: Maybe<DemiplaneUser>;
  /** delete data from the table: "demiplane.user_email_notification_pref" */
  delete_demiplane_user_email_notification_pref?: Maybe<DemiplaneUserEmailNotificationPrefMutationResponse>;
  /** delete single row from the table: "demiplane.user_email_notification_pref" */
  delete_demiplane_user_email_notification_pref_by_pk?: Maybe<DemiplaneUserEmailNotificationPref>;
  /** delete data from the table: "demiplane.user_invitation" */
  delete_demiplane_user_invitation?: Maybe<DemiplaneUserInvitationMutationResponse>;
  /** delete single row from the table: "demiplane.user_invitation" */
  delete_demiplane_user_invitation_by_pk?: Maybe<DemiplaneUserInvitation>;
  /** delete data from the table: "demiplane.user_metadata" */
  delete_demiplane_user_metadata?: Maybe<DemiplaneUserMetadataMutationResponse>;
  /** delete single row from the table: "demiplane.user_metadata" */
  delete_demiplane_user_metadata_by_pk?: Maybe<DemiplaneUserMetadata>;
  /** delete data from the table: "demiplane.user_push_notification_pref" */
  delete_demiplane_user_push_notification_pref?: Maybe<DemiplaneUserPushNotificationPrefMutationResponse>;
  /** delete single row from the table: "demiplane.user_push_notification_pref" */
  delete_demiplane_user_push_notification_pref_by_pk?: Maybe<DemiplaneUserPushNotificationPref>;
  /** delete data from the table: "demiplane.user_recruitment" */
  delete_demiplane_user_recruitment?: Maybe<DemiplaneUserRecruitmentMutationResponse>;
  /** delete data from the table: "demiplane.user_recruitment_adventurer" */
  delete_demiplane_user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerMutationResponse>;
  /** delete data from the table: "demiplane.user_recruitment_adventurer_availability" */
  delete_demiplane_user_recruitment_adventurer_availability?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponse>;
  /** delete single row from the table: "demiplane.user_recruitment_adventurer_availability" */
  delete_demiplane_user_recruitment_adventurer_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** delete single row from the table: "demiplane.user_recruitment_adventurer" */
  delete_demiplane_user_recruitment_adventurer_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurer>;
  /** delete data from the table: "demiplane.user_recruitment_adventurer_games" */
  delete_demiplane_user_recruitment_adventurer_games?: Maybe<DemiplaneUserRecruitmentAdventurerGamesMutationResponse>;
  /** delete single row from the table: "demiplane.user_recruitment_adventurer_games" */
  delete_demiplane_user_recruitment_adventurer_games_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerGames>;
  /** delete data from the table: "demiplane.user_recruitment_adventurer_platforms" */
  delete_demiplane_user_recruitment_adventurer_platforms?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsMutationResponse>;
  /** delete single row from the table: "demiplane.user_recruitment_adventurer_platforms" */
  delete_demiplane_user_recruitment_adventurer_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** delete data from the table: "demiplane.user_recruitment_availability" */
  delete_demiplane_user_recruitment_availability?: Maybe<DemiplaneUserRecruitmentAvailabilityMutationResponse>;
  /** delete single row from the table: "demiplane.user_recruitment_availability" */
  delete_demiplane_user_recruitment_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAvailability>;
  /** delete single row from the table: "demiplane.user_recruitment" */
  delete_demiplane_user_recruitment_by_pk?: Maybe<DemiplaneUserRecruitment>;
  /** delete data from the table: "demiplane.user_recruitment_game_master" */
  delete_demiplane_user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterMutationResponse>;
  /** delete data from the table: "demiplane.user_recruitment_game_master_availability" */
  delete_demiplane_user_recruitment_game_master_availability?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponse>;
  /** delete single row from the table: "demiplane.user_recruitment_game_master_availability" */
  delete_demiplane_user_recruitment_game_master_availability_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** delete single row from the table: "demiplane.user_recruitment_game_master" */
  delete_demiplane_user_recruitment_game_master_by_pk?: Maybe<DemiplaneUserRecruitmentGameMaster>;
  /** delete data from the table: "demiplane.user_recruitment_game_master_games" */
  delete_demiplane_user_recruitment_game_master_games?: Maybe<DemiplaneUserRecruitmentGameMasterGamesMutationResponse>;
  /** delete single row from the table: "demiplane.user_recruitment_game_master_games" */
  delete_demiplane_user_recruitment_game_master_games_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterGames>;
  /** delete data from the table: "demiplane.user_recruitment_game_master_platforms" */
  delete_demiplane_user_recruitment_game_master_platforms?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsMutationResponse>;
  /** delete single row from the table: "demiplane.user_recruitment_game_master_platforms" */
  delete_demiplane_user_recruitment_game_master_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** delete data from the table: "demiplane.user_role" */
  delete_demiplane_user_role?: Maybe<DemiplaneUserRoleMutationResponse>;
  /** delete single row from the table: "demiplane.user_role" */
  delete_demiplane_user_role_by_pk?: Maybe<DemiplaneUserRole>;
  /** delete data from the table: "demiplane.user_visibility" */
  delete_demiplane_user_visibility?: Maybe<DemiplaneUserVisibilityMutationResponse>;
  /** delete single row from the table: "demiplane.user_visibility" */
  delete_demiplane_user_visibility_by_pk?: Maybe<DemiplaneUserVisibility>;
  disableAdventureMatchmaking: AdventureType;
  ejectAdventureGameMaster: AdventureType;
  /** perform the action: "ejectGameMasterV2" */
  ejectGameMasterV2?: Maybe<EjectGameMasterV2Output>;
  enableAdventureMatchmaking: AdventureType;
  /** perform the action: "endEpisode" */
  endEpisode?: Maybe<EndEpisodeOutput>;
  gameplayNotification: SessionMutationType;
  /** perform the action: "immediatelyOpenEpisodeV2" */
  immediatelyOpenEpisodeV2?: Maybe<ImmediatelyOpenEpisodeV2Output>;
  immediatelyOpenSession: SessionMutationType;
  insertPlayerAward: PlayerAwardType;
  /** insert data into the table: "demiplane.ad" */
  insert_demiplane_ad?: Maybe<DemiplaneAdMutationResponse>;
  /** insert data into the table: "demiplane.ad_link" */
  insert_demiplane_ad_link?: Maybe<DemiplaneAdLinkMutationResponse>;
  /** insert a single row into the table: "demiplane.ad_link" */
  insert_demiplane_ad_link_one?: Maybe<DemiplaneAdLink>;
  /** insert data into the table: "demiplane.ad_match_criteria" */
  insert_demiplane_ad_match_criteria?: Maybe<DemiplaneAdMatchCriteriaMutationResponse>;
  /** insert a single row into the table: "demiplane.ad_match_criteria" */
  insert_demiplane_ad_match_criteria_one?: Maybe<DemiplaneAdMatchCriteria>;
  /** insert data into the table: "demiplane.ad_match_criteria_time" */
  insert_demiplane_ad_match_criteria_time?: Maybe<DemiplaneAdMatchCriteriaTimeMutationResponse>;
  /** insert a single row into the table: "demiplane.ad_match_criteria_time" */
  insert_demiplane_ad_match_criteria_time_one?: Maybe<DemiplaneAdMatchCriteriaTime>;
  /** insert a single row into the table: "demiplane.ad" */
  insert_demiplane_ad_one?: Maybe<DemiplaneAd>;
  /** insert data into the table: "demiplane.ad_request" */
  insert_demiplane_ad_request?: Maybe<DemiplaneAdRequestMutationResponse>;
  /** insert a single row into the table: "demiplane.ad_request" */
  insert_demiplane_ad_request_one?: Maybe<DemiplaneAdRequest>;
  /** insert data into the table: "demiplane.ad_time" */
  insert_demiplane_ad_time?: Maybe<DemiplaneAdTimeMutationResponse>;
  /** insert a single row into the table: "demiplane.ad_time" */
  insert_demiplane_ad_time_one?: Maybe<DemiplaneAdTime>;
  /** insert data into the table: "demiplane.adventure" */
  insert_demiplane_adventure?: Maybe<DemiplaneAdventureMutationResponse>;
  /** insert data into the table: "demiplane.adventure_connection" */
  insert_demiplane_adventure_connection?: Maybe<DemiplaneAdventureConnectionMutationResponse>;
  /** insert a single row into the table: "demiplane.adventure_connection" */
  insert_demiplane_adventure_connection_one?: Maybe<DemiplaneAdventureConnection>;
  /** insert data into the table: "demiplane.adventure_demiplane_tool" */
  insert_demiplane_adventure_demiplane_tool?: Maybe<DemiplaneAdventureDemiplaneToolMutationResponse>;
  /** insert a single row into the table: "demiplane.adventure_demiplane_tool" */
  insert_demiplane_adventure_demiplane_tool_one?: Maybe<DemiplaneAdventureDemiplaneTool>;
  /** insert data into the table: "demiplane.adventure_link" */
  insert_demiplane_adventure_link?: Maybe<DemiplaneAdventureLinkMutationResponse>;
  /** insert a single row into the table: "demiplane.adventure_link" */
  insert_demiplane_adventure_link_one?: Maybe<DemiplaneAdventureLink>;
  /** insert a single row into the table: "demiplane.adventure" */
  insert_demiplane_adventure_one?: Maybe<DemiplaneAdventure>;
  /** insert data into the table: "demiplane.adventure_player" */
  insert_demiplane_adventure_player?: Maybe<DemiplaneAdventurePlayerMutationResponse>;
  /** insert a single row into the table: "demiplane.adventure_player" */
  insert_demiplane_adventure_player_one?: Maybe<DemiplaneAdventurePlayer>;
  /** insert data into the table: "demiplane.adventure_todo" */
  insert_demiplane_adventure_todo?: Maybe<DemiplaneAdventureTodoMutationResponse>;
  /** insert a single row into the table: "demiplane.adventure_todo" */
  insert_demiplane_adventure_todo_one?: Maybe<DemiplaneAdventureTodo>;
  /** insert data into the table: "demiplane.adventuring_platform" */
  insert_demiplane_adventuring_platform?: Maybe<DemiplaneAdventuringPlatformMutationResponse>;
  /** insert a single row into the table: "demiplane.adventuring_platform" */
  insert_demiplane_adventuring_platform_one?: Maybe<DemiplaneAdventuringPlatform>;
  /** insert data into the table: "demiplane.amc_adventuring_platforms" */
  insert_demiplane_amc_adventuring_platforms?: Maybe<DemiplaneAmcAdventuringPlatformsMutationResponse>;
  /** insert a single row into the table: "demiplane.amc_adventuring_platforms" */
  insert_demiplane_amc_adventuring_platforms_one?: Maybe<DemiplaneAmcAdventuringPlatforms>;
  /** insert data into the table: "demiplane.amc_game_types" */
  insert_demiplane_amc_game_types?: Maybe<DemiplaneAmcGameTypesMutationResponse>;
  /** insert a single row into the table: "demiplane.amc_game_types" */
  insert_demiplane_amc_game_types_one?: Maybe<DemiplaneAmcGameTypes>;
  /** insert data into the table: "demiplane.app_version" */
  insert_demiplane_app_version?: Maybe<DemiplaneAppVersionMutationResponse>;
  /** insert a single row into the table: "demiplane.app_version" */
  insert_demiplane_app_version_one?: Maybe<DemiplaneAppVersion>;
  /** insert data into the table: "demiplane.attachment" */
  insert_demiplane_attachment?: Maybe<DemiplaneAttachmentMutationResponse>;
  /** insert a single row into the table: "demiplane.attachment" */
  insert_demiplane_attachment_one?: Maybe<DemiplaneAttachment>;
  /** insert data into the table: "demiplane.award_type" */
  insert_demiplane_award_type?: Maybe<DemiplaneAwardTypeMutationResponse>;
  /** insert a single row into the table: "demiplane.award_type" */
  insert_demiplane_award_type_one?: Maybe<DemiplaneAwardType>;
  /** insert data into the table: "demiplane.connection_status" */
  insert_demiplane_connection_status?: Maybe<DemiplaneConnectionStatusMutationResponse>;
  /** insert a single row into the table: "demiplane.connection_status" */
  insert_demiplane_connection_status_one?: Maybe<DemiplaneConnectionStatus>;
  /** insert data into the table: "demiplane.connection_type" */
  insert_demiplane_connection_type?: Maybe<DemiplaneConnectionTypeMutationResponse>;
  /** insert a single row into the table: "demiplane.connection_type" */
  insert_demiplane_connection_type_one?: Maybe<DemiplaneConnectionType>;
  /** insert data into the table: "demiplane.contact_type" */
  insert_demiplane_contact_type?: Maybe<DemiplaneContactTypeMutationResponse>;
  /** insert a single row into the table: "demiplane.contact_type" */
  insert_demiplane_contact_type_one?: Maybe<DemiplaneContactType>;
  /** insert data into the table: "demiplane.country" */
  insert_demiplane_country?: Maybe<DemiplaneCountryMutationResponse>;
  /** insert a single row into the table: "demiplane.country" */
  insert_demiplane_country_one?: Maybe<DemiplaneCountry>;
  /** insert data into the table: "demiplane.credit_card" */
  insert_demiplane_credit_card?: Maybe<DemiplaneCreditCardMutationResponse>;
  /** insert a single row into the table: "demiplane.credit_card" */
  insert_demiplane_credit_card_one?: Maybe<DemiplaneCreditCard>;
  /** insert data into the table: "demiplane.demiplane_tool" */
  insert_demiplane_demiplane_tool?: Maybe<DemiplaneDemiplaneToolMutationResponse>;
  /** insert a single row into the table: "demiplane.demiplane_tool" */
  insert_demiplane_demiplane_tool_one?: Maybe<DemiplaneDemiplaneTool>;
  /** insert data into the table: "demiplane.episode_attendance" */
  insert_demiplane_episode_attendance?: Maybe<DemiplaneEpisodeAttendanceMutationResponse>;
  /** insert a single row into the table: "demiplane.episode_attendance" */
  insert_demiplane_episode_attendance_one?: Maybe<DemiplaneEpisodeAttendance>;
  /** insert data into the table: "demiplane.event" */
  insert_demiplane_event?: Maybe<DemiplaneEventMutationResponse>;
  /** insert a single row into the table: "demiplane.event" */
  insert_demiplane_event_one?: Maybe<DemiplaneEvent>;
  /** insert data into the table: "demiplane.event_type" */
  insert_demiplane_event_type?: Maybe<DemiplaneEventTypeMutationResponse>;
  /** insert a single row into the table: "demiplane.event_type" */
  insert_demiplane_event_type_one?: Maybe<DemiplaneEventType>;
  /** insert data into the table: "demiplane.favorite_adventuring_platform" */
  insert_demiplane_favorite_adventuring_platform?: Maybe<DemiplaneFavoriteAdventuringPlatformMutationResponse>;
  /** insert a single row into the table: "demiplane.favorite_adventuring_platform" */
  insert_demiplane_favorite_adventuring_platform_one?: Maybe<DemiplaneFavoriteAdventuringPlatform>;
  /** insert data into the table: "demiplane.favorite_dice_roll" */
  insert_demiplane_favorite_dice_roll?: Maybe<DemiplaneFavoriteDiceRollMutationResponse>;
  /** insert a single row into the table: "demiplane.favorite_dice_roll" */
  insert_demiplane_favorite_dice_roll_one?: Maybe<DemiplaneFavoriteDiceRoll>;
  /** insert data into the table: "demiplane.favorite_game" */
  insert_demiplane_favorite_game?: Maybe<DemiplaneFavoriteGameMutationResponse>;
  /** insert a single row into the table: "demiplane.favorite_game" */
  insert_demiplane_favorite_game_one?: Maybe<DemiplaneFavoriteGame>;
  /** insert data into the table: "demiplane.game_type" */
  insert_demiplane_game_type?: Maybe<DemiplaneGameTypeMutationResponse>;
  /** insert a single row into the table: "demiplane.game_type" */
  insert_demiplane_game_type_one?: Maybe<DemiplaneGameType>;
  /** insert data into the table: "demiplane.gm_rating" */
  insert_demiplane_gm_rating?: Maybe<DemiplaneGmRatingMutationResponse>;
  /** insert a single row into the table: "demiplane.gm_rating" */
  insert_demiplane_gm_rating_one?: Maybe<DemiplaneGmRating>;
  /** insert data into the table: "demiplane.gm_recommendation" */
  insert_demiplane_gm_recommendation?: Maybe<DemiplaneGmRecommendationMutationResponse>;
  /** insert a single row into the table: "demiplane.gm_recommendation" */
  insert_demiplane_gm_recommendation_one?: Maybe<DemiplaneGmRecommendation>;
  /** insert data into the table: "demiplane.invitation" */
  insert_demiplane_invitation?: Maybe<DemiplaneInvitationMutationResponse>;
  /** insert a single row into the table: "demiplane.invitation" */
  insert_demiplane_invitation_one?: Maybe<DemiplaneInvitation>;
  /** insert data into the table: "demiplane.journal" */
  insert_demiplane_journal?: Maybe<DemiplaneJournalMutationResponse>;
  /** insert data into the table: "demiplane.journal_note" */
  insert_demiplane_journal_note?: Maybe<DemiplaneJournalNoteMutationResponse>;
  /** insert a single row into the table: "demiplane.journal_note" */
  insert_demiplane_journal_note_one?: Maybe<DemiplaneJournalNote>;
  /** insert data into the table: "demiplane.journal_note_share" */
  insert_demiplane_journal_note_share?: Maybe<DemiplaneJournalNoteShareMutationResponse>;
  /** insert a single row into the table: "demiplane.journal_note_share" */
  insert_demiplane_journal_note_share_one?: Maybe<DemiplaneJournalNoteShare>;
  /** insert a single row into the table: "demiplane.journal" */
  insert_demiplane_journal_one?: Maybe<DemiplaneJournal>;
  /** insert data into the table: "demiplane.journal_super_event" */
  insert_demiplane_journal_super_event?: Maybe<DemiplaneJournalSuperEventMutationResponse>;
  /** insert a single row into the table: "demiplane.journal_super_event" */
  insert_demiplane_journal_super_event_one?: Maybe<DemiplaneJournalSuperEvent>;
  /** insert data into the table: "demiplane.journal_super_event_read_status" */
  insert_demiplane_journal_super_event_read_status?: Maybe<DemiplaneJournalSuperEventReadStatusMutationResponse>;
  /** insert a single row into the table: "demiplane.journal_super_event_read_status" */
  insert_demiplane_journal_super_event_read_status_one?: Maybe<DemiplaneJournalSuperEventReadStatus>;
  /** insert data into the table: "demiplane.journal_title" */
  insert_demiplane_journal_title?: Maybe<DemiplaneJournalTitleMutationResponse>;
  /** insert a single row into the table: "demiplane.journal_title" */
  insert_demiplane_journal_title_one?: Maybe<DemiplaneJournalTitle>;
  /** insert data into the table: "demiplane.matchmaking_token" */
  insert_demiplane_matchmaking_token?: Maybe<DemiplaneMatchmakingTokenMutationResponse>;
  /** insert a single row into the table: "demiplane.matchmaking_token" */
  insert_demiplane_matchmaking_token_one?: Maybe<DemiplaneMatchmakingToken>;
  /** insert data into the table: "demiplane.migrations" */
  insert_demiplane_migrations?: Maybe<DemiplaneMigrationsMutationResponse>;
  /** insert a single row into the table: "demiplane.migrations" */
  insert_demiplane_migrations_one?: Maybe<DemiplaneMigrations>;
  /** insert data into the table: "demiplane.notification_history" */
  insert_demiplane_notification_history?: Maybe<DemiplaneNotificationHistoryMutationResponse>;
  /** insert a single row into the table: "demiplane.notification_history" */
  insert_demiplane_notification_history_one?: Maybe<DemiplaneNotificationHistory>;
  /** insert data into the table: "demiplane.payment" */
  insert_demiplane_payment?: Maybe<DemiplanePaymentMutationResponse>;
  /** insert a single row into the table: "demiplane.payment" */
  insert_demiplane_payment_one?: Maybe<DemiplanePayment>;
  /** insert data into the table: "demiplane.player_award" */
  insert_demiplane_player_award?: Maybe<DemiplanePlayerAwardMutationResponse>;
  /** insert a single row into the table: "demiplane.player_award" */
  insert_demiplane_player_award_one?: Maybe<DemiplanePlayerAward>;
  /** insert data into the table: "demiplane.player_rating" */
  insert_demiplane_player_rating?: Maybe<DemiplanePlayerRatingMutationResponse>;
  /** insert a single row into the table: "demiplane.player_rating" */
  insert_demiplane_player_rating_one?: Maybe<DemiplanePlayerRating>;
  /** insert data into the table: "demiplane.pronoun" */
  insert_demiplane_pronoun?: Maybe<DemiplanePronounMutationResponse>;
  /** insert a single row into the table: "demiplane.pronoun" */
  insert_demiplane_pronoun_one?: Maybe<DemiplanePronoun>;
  /** insert data into the table: "demiplane.region" */
  insert_demiplane_region?: Maybe<DemiplaneRegionMutationResponse>;
  /** insert a single row into the table: "demiplane.region" */
  insert_demiplane_region_one?: Maybe<DemiplaneRegion>;
  /** insert data into the table: "demiplane.role" */
  insert_demiplane_role?: Maybe<DemiplaneRoleMutationResponse>;
  /** insert a single row into the table: "demiplane.role" */
  insert_demiplane_role_one?: Maybe<DemiplaneRole>;
  /** insert data into the table: "demiplane.session" */
  insert_demiplane_session?: Maybe<DemiplaneSessionMutationResponse>;
  /** insert a single row into the table: "demiplane.session" */
  insert_demiplane_session_one?: Maybe<DemiplaneSession>;
  /** insert data into the table: "demiplane.session_player" */
  insert_demiplane_session_player?: Maybe<DemiplaneSessionPlayerMutationResponse>;
  /** insert a single row into the table: "demiplane.session_player" */
  insert_demiplane_session_player_one?: Maybe<DemiplaneSessionPlayer>;
  /** insert data into the table: "demiplane.snippet" */
  insert_demiplane_snippet?: Maybe<DemiplaneSnippetMutationResponse>;
  /** insert a single row into the table: "demiplane.snippet" */
  insert_demiplane_snippet_one?: Maybe<DemiplaneSnippet>;
  /** insert data into the table: "demiplane.task" */
  insert_demiplane_task?: Maybe<DemiplaneTaskMutationResponse>;
  /** insert a single row into the table: "demiplane.task" */
  insert_demiplane_task_one?: Maybe<DemiplaneTask>;
  /** insert data into the table: "demiplane.task_read_status" */
  insert_demiplane_task_read_status?: Maybe<DemiplaneTaskReadStatusMutationResponse>;
  /** insert a single row into the table: "demiplane.task_read_status" */
  insert_demiplane_task_read_status_one?: Maybe<DemiplaneTaskReadStatus>;
  /** insert data into the table: "demiplane.tax_nexus_region" */
  insert_demiplane_tax_nexus_region?: Maybe<DemiplaneTaxNexusRegionMutationResponse>;
  /** insert a single row into the table: "demiplane.tax_nexus_region" */
  insert_demiplane_tax_nexus_region_one?: Maybe<DemiplaneTaxNexusRegion>;
  /** insert data into the table: "demiplane.transaction_receipt" */
  insert_demiplane_transaction_receipt?: Maybe<DemiplaneTransactionReceiptMutationResponse>;
  /** insert a single row into the table: "demiplane.transaction_receipt" */
  insert_demiplane_transaction_receipt_one?: Maybe<DemiplaneTransactionReceipt>;
  /** insert data into the table: "demiplane.upload" */
  insert_demiplane_upload?: Maybe<DemiplaneUploadMutationResponse>;
  /** insert a single row into the table: "demiplane.upload" */
  insert_demiplane_upload_one?: Maybe<DemiplaneUpload>;
  /** insert data into the table: "demiplane.urap" */
  insert_demiplane_urap?: Maybe<DemiplaneUrapMutationResponse>;
  /** insert a single row into the table: "demiplane.urap" */
  insert_demiplane_urap_one?: Maybe<DemiplaneUrap>;
  /** insert data into the table: "demiplane.urgt" */
  insert_demiplane_urgt?: Maybe<DemiplaneUrgtMutationResponse>;
  /** insert a single row into the table: "demiplane.urgt" */
  insert_demiplane_urgt_one?: Maybe<DemiplaneUrgt>;
  /** insert data into the table: "demiplane.user" */
  insert_demiplane_user?: Maybe<DemiplaneUserMutationResponse>;
  /** insert data into the table: "demiplane.user_activity" */
  insert_demiplane_user_activity?: Maybe<DemiplaneUserActivityMutationResponse>;
  /** insert a single row into the table: "demiplane.user_activity" */
  insert_demiplane_user_activity_one?: Maybe<DemiplaneUserActivity>;
  /** insert data into the table: "demiplane.user_adventure" */
  insert_demiplane_user_adventure?: Maybe<DemiplaneUserAdventureMutationResponse>;
  /** insert a single row into the table: "demiplane.user_adventure" */
  insert_demiplane_user_adventure_one?: Maybe<DemiplaneUserAdventure>;
  /** insert data into the table: "demiplane.user_adventure_ranking" */
  insert_demiplane_user_adventure_ranking?: Maybe<DemiplaneUserAdventureRankingMutationResponse>;
  /** insert a single row into the table: "demiplane.user_adventure_ranking" */
  insert_demiplane_user_adventure_ranking_one?: Maybe<DemiplaneUserAdventureRanking>;
  /** insert data into the table: "demiplane.user_adventure_role" */
  insert_demiplane_user_adventure_role?: Maybe<DemiplaneUserAdventureRoleMutationResponse>;
  /** insert a single row into the table: "demiplane.user_adventure_role" */
  insert_demiplane_user_adventure_role_one?: Maybe<DemiplaneUserAdventureRole>;
  /** insert data into the table: "demiplane.user_email_notification_pref" */
  insert_demiplane_user_email_notification_pref?: Maybe<DemiplaneUserEmailNotificationPrefMutationResponse>;
  /** insert a single row into the table: "demiplane.user_email_notification_pref" */
  insert_demiplane_user_email_notification_pref_one?: Maybe<DemiplaneUserEmailNotificationPref>;
  /** insert data into the table: "demiplane.user_invitation" */
  insert_demiplane_user_invitation?: Maybe<DemiplaneUserInvitationMutationResponse>;
  /** insert a single row into the table: "demiplane.user_invitation" */
  insert_demiplane_user_invitation_one?: Maybe<DemiplaneUserInvitation>;
  /** insert data into the table: "demiplane.user_metadata" */
  insert_demiplane_user_metadata?: Maybe<DemiplaneUserMetadataMutationResponse>;
  /** insert a single row into the table: "demiplane.user_metadata" */
  insert_demiplane_user_metadata_one?: Maybe<DemiplaneUserMetadata>;
  /** insert a single row into the table: "demiplane.user" */
  insert_demiplane_user_one?: Maybe<DemiplaneUser>;
  /** insert data into the table: "demiplane.user_push_notification_pref" */
  insert_demiplane_user_push_notification_pref?: Maybe<DemiplaneUserPushNotificationPrefMutationResponse>;
  /** insert a single row into the table: "demiplane.user_push_notification_pref" */
  insert_demiplane_user_push_notification_pref_one?: Maybe<DemiplaneUserPushNotificationPref>;
  /** insert data into the table: "demiplane.user_recruitment" */
  insert_demiplane_user_recruitment?: Maybe<DemiplaneUserRecruitmentMutationResponse>;
  /** insert data into the table: "demiplane.user_recruitment_adventurer" */
  insert_demiplane_user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerMutationResponse>;
  /** insert data into the table: "demiplane.user_recruitment_adventurer_availability" */
  insert_demiplane_user_recruitment_adventurer_availability?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponse>;
  /** insert a single row into the table: "demiplane.user_recruitment_adventurer_availability" */
  insert_demiplane_user_recruitment_adventurer_availability_one?: Maybe<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** insert data into the table: "demiplane.user_recruitment_adventurer_games" */
  insert_demiplane_user_recruitment_adventurer_games?: Maybe<DemiplaneUserRecruitmentAdventurerGamesMutationResponse>;
  /** insert a single row into the table: "demiplane.user_recruitment_adventurer_games" */
  insert_demiplane_user_recruitment_adventurer_games_one?: Maybe<DemiplaneUserRecruitmentAdventurerGames>;
  /** insert a single row into the table: "demiplane.user_recruitment_adventurer" */
  insert_demiplane_user_recruitment_adventurer_one?: Maybe<DemiplaneUserRecruitmentAdventurer>;
  /** insert data into the table: "demiplane.user_recruitment_adventurer_platforms" */
  insert_demiplane_user_recruitment_adventurer_platforms?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsMutationResponse>;
  /** insert a single row into the table: "demiplane.user_recruitment_adventurer_platforms" */
  insert_demiplane_user_recruitment_adventurer_platforms_one?: Maybe<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** insert data into the table: "demiplane.user_recruitment_availability" */
  insert_demiplane_user_recruitment_availability?: Maybe<DemiplaneUserRecruitmentAvailabilityMutationResponse>;
  /** insert a single row into the table: "demiplane.user_recruitment_availability" */
  insert_demiplane_user_recruitment_availability_one?: Maybe<DemiplaneUserRecruitmentAvailability>;
  /** insert data into the table: "demiplane.user_recruitment_game_master" */
  insert_demiplane_user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterMutationResponse>;
  /** insert data into the table: "demiplane.user_recruitment_game_master_availability" */
  insert_demiplane_user_recruitment_game_master_availability?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponse>;
  /** insert a single row into the table: "demiplane.user_recruitment_game_master_availability" */
  insert_demiplane_user_recruitment_game_master_availability_one?: Maybe<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** insert data into the table: "demiplane.user_recruitment_game_master_games" */
  insert_demiplane_user_recruitment_game_master_games?: Maybe<DemiplaneUserRecruitmentGameMasterGamesMutationResponse>;
  /** insert a single row into the table: "demiplane.user_recruitment_game_master_games" */
  insert_demiplane_user_recruitment_game_master_games_one?: Maybe<DemiplaneUserRecruitmentGameMasterGames>;
  /** insert a single row into the table: "demiplane.user_recruitment_game_master" */
  insert_demiplane_user_recruitment_game_master_one?: Maybe<DemiplaneUserRecruitmentGameMaster>;
  /** insert data into the table: "demiplane.user_recruitment_game_master_platforms" */
  insert_demiplane_user_recruitment_game_master_platforms?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsMutationResponse>;
  /** insert a single row into the table: "demiplane.user_recruitment_game_master_platforms" */
  insert_demiplane_user_recruitment_game_master_platforms_one?: Maybe<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** insert a single row into the table: "demiplane.user_recruitment" */
  insert_demiplane_user_recruitment_one?: Maybe<DemiplaneUserRecruitment>;
  /** insert data into the table: "demiplane.user_role" */
  insert_demiplane_user_role?: Maybe<DemiplaneUserRoleMutationResponse>;
  /** insert a single row into the table: "demiplane.user_role" */
  insert_demiplane_user_role_one?: Maybe<DemiplaneUserRole>;
  /** insert data into the table: "demiplane.user_visibility" */
  insert_demiplane_user_visibility?: Maybe<DemiplaneUserVisibilityMutationResponse>;
  /** insert a single row into the table: "demiplane.user_visibility" */
  insert_demiplane_user_visibility_one?: Maybe<DemiplaneUserVisibility>;
  inviteAdventurers: AdventureType;
  joinAdventureRecruit: AdventureConnectionType;
  joinAdventureRequest: AdventureConnectionType;
  joinSession: SessionMutationType;
  leaveAdventure: AdventureType;
  /** perform the action: "leaveEpisodeV2" */
  leaveEpisodeV2?: Maybe<LeaveEpisodeV2Output>;
  leaveRecruit: AdventureConnectionType;
  leaveRequest: AdventureConnectionType;
  leaveSession: SessionMutationType;
  /** perform the action: "openEpisodeV2" */
  openEpisodeV2?: Maybe<OpenEpisodeV2Output>;
  openSession: SessionMutationType;
  /** perform the action: "participantLeavesWhisper" */
  participantLeavesWhisper?: Maybe<ParticipantLeavesWhisperOutput>;
  preauthorizePayment: PaymentType;
  precalculateFees: SessionType;
  promoteAdventureGameMaster: AdventureType;
  /** perform the action: "promoteGameMasterV2" */
  promoteGameMasterV2?: Maybe<PromoteGameMasterV2Output>;
  readAdventureConnections: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  readNotificationByUrl: Scalars['Boolean'];
  readSharedNote: Scalars['Boolean'];
  readSuperEvents: Scalars['Boolean'];
  readTasks: Scalars['Boolean'];
  readUnreadNotifications: Scalars['Boolean'];
  recruitAdventure: AdventureConnectionType;
  removeAdventurer: AdventureType;
  /** perform the action: "removeAdventurerV2" */
  removeAdventurerV2?: Maybe<RemoveAdventurerV2Output>;
  reorderFavoriteDiceRolls: Scalars['Boolean'];
  /** perform the action: "reorderFavoriteGames" */
  reorderFavoriteGames?: Maybe<ReorderFavoriteGamesOutput>;
  /** perform the action: "reorderFavoritePlatforms" */
  reorderFavoritePlatforms?: Maybe<ReorderFavoritePlatformsOutput>;
  reorderTasks: Scalars['Boolean'];
  rescheduleSession: SessionMutationType;
  scheduleSession: SessionMutationType;
  sendAccountCreateEmail: UserType;
  sendFeedbackEmail: Scalars['Boolean'];
  sendTipEmail: Scalars['Boolean'];
  setEpisodeFrequency: Scalars['Boolean'];
  shareJournalNote: JournalNoteShareReturnType;
  specialRequest: AdventureType;
  /** perform the action: "startEpisodeV2" */
  startEpisodeV2?: Maybe<StartEpisodeV2Output>;
  startSession: SessionMutationType;
  submitFile: Scalars['Boolean'];
  /** perform the action: "submitFileV2" */
  submitFileV2?: Maybe<SubmitFileV2Output>;
  submitGiphy: Scalars['Boolean'];
  submitMessage: Scalars['Boolean'];
  submitSystemMessage: Scalars['Boolean'];
  toggleVoiceVideoStatus: Scalars['Boolean'];
  toggleWhisperStatus: Scalars['Boolean'];
  triggerDisableWhisper: Scalars['Boolean'];
  /** perform the action: "triggerDisableWhisperV2" */
  triggerDisableWhisperV2?: Maybe<TriggerDisableWhisperV2Output>;
  triggerEnableWhisper: Scalars['Boolean'];
  /** perform the action: "triggerEnableWhisperV2" */
  triggerEnableWhisperV2?: Maybe<TriggerEnableWhisperV2Output>;
  updateAdventureCoverImage: AdventureType;
  updateAdventureDetails: AdventureType;
  updateAdventureFrequency: AdventureType;
  updateAdventureGameAvailability: AdventureType;
  updateAdventureMatchMakingStatus: AdventureType;
  updateAdventurePlayerName: AdventurePlayerType;
  updateAdventureThemeScores: AdventureType;
  /** perform the action: "updateBillingAddress" */
  updateBillingAddress?: Maybe<UpdateBillingAddressOutput>;
  updateFavoriteDiceRoll: FavoriteDiceRollType;
  updateFirstLogin: UserType;
  updateGameMasterBio: UserType;
  updateGmRating: GmRatingType;
  updateJournal: JournalTitleType;
  updateJournalNote: JournalNoteType;
  updateJournalSuperEvent: JournalSuperEventType;
  updateLeaderHasViewed: AdventureType;
  updatePlayerRatings: Array<PlayerRatingType>;
  updatePreferences: UserType;
  updateTask: TaskType;
  updateUpload: UploadType;
  /** perform the action: "updateUploadV2" */
  updateUploadV2?: Maybe<UpdateUploadV2Output>;
  updateUserInformation: UserType;
  /** perform the action: "updateUserInformationV2" */
  updateUserInformationV2?: Maybe<UpdateUserInformationV2Output>;
  updateUserMetadata: UserMetadataType;
  updateUserRecruitment: UserRecruitmentType;
  /** update data of the table: "demiplane.ad" */
  update_demiplane_ad?: Maybe<DemiplaneAdMutationResponse>;
  /** update single row of the table: "demiplane.ad" */
  update_demiplane_ad_by_pk?: Maybe<DemiplaneAd>;
  /** update data of the table: "demiplane.ad_link" */
  update_demiplane_ad_link?: Maybe<DemiplaneAdLinkMutationResponse>;
  /** update single row of the table: "demiplane.ad_link" */
  update_demiplane_ad_link_by_pk?: Maybe<DemiplaneAdLink>;
  /** update data of the table: "demiplane.ad_match_criteria" */
  update_demiplane_ad_match_criteria?: Maybe<DemiplaneAdMatchCriteriaMutationResponse>;
  /** update single row of the table: "demiplane.ad_match_criteria" */
  update_demiplane_ad_match_criteria_by_pk?: Maybe<DemiplaneAdMatchCriteria>;
  /** update data of the table: "demiplane.ad_match_criteria_time" */
  update_demiplane_ad_match_criteria_time?: Maybe<DemiplaneAdMatchCriteriaTimeMutationResponse>;
  /** update single row of the table: "demiplane.ad_match_criteria_time" */
  update_demiplane_ad_match_criteria_time_by_pk?: Maybe<DemiplaneAdMatchCriteriaTime>;
  /** update data of the table: "demiplane.ad_request" */
  update_demiplane_ad_request?: Maybe<DemiplaneAdRequestMutationResponse>;
  /** update single row of the table: "demiplane.ad_request" */
  update_demiplane_ad_request_by_pk?: Maybe<DemiplaneAdRequest>;
  /** update data of the table: "demiplane.ad_time" */
  update_demiplane_ad_time?: Maybe<DemiplaneAdTimeMutationResponse>;
  /** update single row of the table: "demiplane.ad_time" */
  update_demiplane_ad_time_by_pk?: Maybe<DemiplaneAdTime>;
  /** update data of the table: "demiplane.adventure" */
  update_demiplane_adventure?: Maybe<DemiplaneAdventureMutationResponse>;
  /** update single row of the table: "demiplane.adventure" */
  update_demiplane_adventure_by_pk?: Maybe<DemiplaneAdventure>;
  /** update data of the table: "demiplane.adventure_connection" */
  update_demiplane_adventure_connection?: Maybe<DemiplaneAdventureConnectionMutationResponse>;
  /** update single row of the table: "demiplane.adventure_connection" */
  update_demiplane_adventure_connection_by_pk?: Maybe<DemiplaneAdventureConnection>;
  /** update data of the table: "demiplane.adventure_demiplane_tool" */
  update_demiplane_adventure_demiplane_tool?: Maybe<DemiplaneAdventureDemiplaneToolMutationResponse>;
  /** update single row of the table: "demiplane.adventure_demiplane_tool" */
  update_demiplane_adventure_demiplane_tool_by_pk?: Maybe<DemiplaneAdventureDemiplaneTool>;
  /** update data of the table: "demiplane.adventure_link" */
  update_demiplane_adventure_link?: Maybe<DemiplaneAdventureLinkMutationResponse>;
  /** update single row of the table: "demiplane.adventure_link" */
  update_demiplane_adventure_link_by_pk?: Maybe<DemiplaneAdventureLink>;
  /** update data of the table: "demiplane.adventure_player" */
  update_demiplane_adventure_player?: Maybe<DemiplaneAdventurePlayerMutationResponse>;
  /** update single row of the table: "demiplane.adventure_player" */
  update_demiplane_adventure_player_by_pk?: Maybe<DemiplaneAdventurePlayer>;
  /** update data of the table: "demiplane.adventure_todo" */
  update_demiplane_adventure_todo?: Maybe<DemiplaneAdventureTodoMutationResponse>;
  /** update single row of the table: "demiplane.adventure_todo" */
  update_demiplane_adventure_todo_by_pk?: Maybe<DemiplaneAdventureTodo>;
  /** update data of the table: "demiplane.adventuring_platform" */
  update_demiplane_adventuring_platform?: Maybe<DemiplaneAdventuringPlatformMutationResponse>;
  /** update single row of the table: "demiplane.adventuring_platform" */
  update_demiplane_adventuring_platform_by_pk?: Maybe<DemiplaneAdventuringPlatform>;
  /** update data of the table: "demiplane.amc_adventuring_platforms" */
  update_demiplane_amc_adventuring_platforms?: Maybe<DemiplaneAmcAdventuringPlatformsMutationResponse>;
  /** update single row of the table: "demiplane.amc_adventuring_platforms" */
  update_demiplane_amc_adventuring_platforms_by_pk?: Maybe<DemiplaneAmcAdventuringPlatforms>;
  /** update data of the table: "demiplane.amc_game_types" */
  update_demiplane_amc_game_types?: Maybe<DemiplaneAmcGameTypesMutationResponse>;
  /** update single row of the table: "demiplane.amc_game_types" */
  update_demiplane_amc_game_types_by_pk?: Maybe<DemiplaneAmcGameTypes>;
  /** update data of the table: "demiplane.app_version" */
  update_demiplane_app_version?: Maybe<DemiplaneAppVersionMutationResponse>;
  /** update single row of the table: "demiplane.app_version" */
  update_demiplane_app_version_by_pk?: Maybe<DemiplaneAppVersion>;
  /** update data of the table: "demiplane.attachment" */
  update_demiplane_attachment?: Maybe<DemiplaneAttachmentMutationResponse>;
  /** update single row of the table: "demiplane.attachment" */
  update_demiplane_attachment_by_pk?: Maybe<DemiplaneAttachment>;
  /** update data of the table: "demiplane.award_type" */
  update_demiplane_award_type?: Maybe<DemiplaneAwardTypeMutationResponse>;
  /** update single row of the table: "demiplane.award_type" */
  update_demiplane_award_type_by_pk?: Maybe<DemiplaneAwardType>;
  /** update data of the table: "demiplane.connection_status" */
  update_demiplane_connection_status?: Maybe<DemiplaneConnectionStatusMutationResponse>;
  /** update single row of the table: "demiplane.connection_status" */
  update_demiplane_connection_status_by_pk?: Maybe<DemiplaneConnectionStatus>;
  /** update data of the table: "demiplane.connection_type" */
  update_demiplane_connection_type?: Maybe<DemiplaneConnectionTypeMutationResponse>;
  /** update single row of the table: "demiplane.connection_type" */
  update_demiplane_connection_type_by_pk?: Maybe<DemiplaneConnectionType>;
  /** update data of the table: "demiplane.contact_type" */
  update_demiplane_contact_type?: Maybe<DemiplaneContactTypeMutationResponse>;
  /** update single row of the table: "demiplane.contact_type" */
  update_demiplane_contact_type_by_pk?: Maybe<DemiplaneContactType>;
  /** update data of the table: "demiplane.country" */
  update_demiplane_country?: Maybe<DemiplaneCountryMutationResponse>;
  /** update single row of the table: "demiplane.country" */
  update_demiplane_country_by_pk?: Maybe<DemiplaneCountry>;
  /** update data of the table: "demiplane.credit_card" */
  update_demiplane_credit_card?: Maybe<DemiplaneCreditCardMutationResponse>;
  /** update single row of the table: "demiplane.credit_card" */
  update_demiplane_credit_card_by_pk?: Maybe<DemiplaneCreditCard>;
  /** update data of the table: "demiplane.demiplane_tool" */
  update_demiplane_demiplane_tool?: Maybe<DemiplaneDemiplaneToolMutationResponse>;
  /** update single row of the table: "demiplane.demiplane_tool" */
  update_demiplane_demiplane_tool_by_pk?: Maybe<DemiplaneDemiplaneTool>;
  /** update data of the table: "demiplane.episode_attendance" */
  update_demiplane_episode_attendance?: Maybe<DemiplaneEpisodeAttendanceMutationResponse>;
  /** update single row of the table: "demiplane.episode_attendance" */
  update_demiplane_episode_attendance_by_pk?: Maybe<DemiplaneEpisodeAttendance>;
  /** update data of the table: "demiplane.event" */
  update_demiplane_event?: Maybe<DemiplaneEventMutationResponse>;
  /** update single row of the table: "demiplane.event" */
  update_demiplane_event_by_pk?: Maybe<DemiplaneEvent>;
  /** update data of the table: "demiplane.event_type" */
  update_demiplane_event_type?: Maybe<DemiplaneEventTypeMutationResponse>;
  /** update single row of the table: "demiplane.event_type" */
  update_demiplane_event_type_by_pk?: Maybe<DemiplaneEventType>;
  /** update data of the table: "demiplane.favorite_adventuring_platform" */
  update_demiplane_favorite_adventuring_platform?: Maybe<DemiplaneFavoriteAdventuringPlatformMutationResponse>;
  /** update single row of the table: "demiplane.favorite_adventuring_platform" */
  update_demiplane_favorite_adventuring_platform_by_pk?: Maybe<DemiplaneFavoriteAdventuringPlatform>;
  /** update data of the table: "demiplane.favorite_dice_roll" */
  update_demiplane_favorite_dice_roll?: Maybe<DemiplaneFavoriteDiceRollMutationResponse>;
  /** update single row of the table: "demiplane.favorite_dice_roll" */
  update_demiplane_favorite_dice_roll_by_pk?: Maybe<DemiplaneFavoriteDiceRoll>;
  /** update data of the table: "demiplane.favorite_game" */
  update_demiplane_favorite_game?: Maybe<DemiplaneFavoriteGameMutationResponse>;
  /** update single row of the table: "demiplane.favorite_game" */
  update_demiplane_favorite_game_by_pk?: Maybe<DemiplaneFavoriteGame>;
  /** update data of the table: "demiplane.game_type" */
  update_demiplane_game_type?: Maybe<DemiplaneGameTypeMutationResponse>;
  /** update single row of the table: "demiplane.game_type" */
  update_demiplane_game_type_by_pk?: Maybe<DemiplaneGameType>;
  /** update data of the table: "demiplane.gm_rating" */
  update_demiplane_gm_rating?: Maybe<DemiplaneGmRatingMutationResponse>;
  /** update single row of the table: "demiplane.gm_rating" */
  update_demiplane_gm_rating_by_pk?: Maybe<DemiplaneGmRating>;
  /** update data of the table: "demiplane.gm_recommendation" */
  update_demiplane_gm_recommendation?: Maybe<DemiplaneGmRecommendationMutationResponse>;
  /** update single row of the table: "demiplane.gm_recommendation" */
  update_demiplane_gm_recommendation_by_pk?: Maybe<DemiplaneGmRecommendation>;
  /** update data of the table: "demiplane.invitation" */
  update_demiplane_invitation?: Maybe<DemiplaneInvitationMutationResponse>;
  /** update single row of the table: "demiplane.invitation" */
  update_demiplane_invitation_by_pk?: Maybe<DemiplaneInvitation>;
  /** update data of the table: "demiplane.journal" */
  update_demiplane_journal?: Maybe<DemiplaneJournalMutationResponse>;
  /** update single row of the table: "demiplane.journal" */
  update_demiplane_journal_by_pk?: Maybe<DemiplaneJournal>;
  /** update data of the table: "demiplane.journal_note" */
  update_demiplane_journal_note?: Maybe<DemiplaneJournalNoteMutationResponse>;
  /** update single row of the table: "demiplane.journal_note" */
  update_demiplane_journal_note_by_pk?: Maybe<DemiplaneJournalNote>;
  /** update data of the table: "demiplane.journal_note_share" */
  update_demiplane_journal_note_share?: Maybe<DemiplaneJournalNoteShareMutationResponse>;
  /** update single row of the table: "demiplane.journal_note_share" */
  update_demiplane_journal_note_share_by_pk?: Maybe<DemiplaneJournalNoteShare>;
  /** update data of the table: "demiplane.journal_super_event" */
  update_demiplane_journal_super_event?: Maybe<DemiplaneJournalSuperEventMutationResponse>;
  /** update single row of the table: "demiplane.journal_super_event" */
  update_demiplane_journal_super_event_by_pk?: Maybe<DemiplaneJournalSuperEvent>;
  /** update data of the table: "demiplane.journal_super_event_read_status" */
  update_demiplane_journal_super_event_read_status?: Maybe<DemiplaneJournalSuperEventReadStatusMutationResponse>;
  /** update single row of the table: "demiplane.journal_super_event_read_status" */
  update_demiplane_journal_super_event_read_status_by_pk?: Maybe<DemiplaneJournalSuperEventReadStatus>;
  /** update data of the table: "demiplane.journal_title" */
  update_demiplane_journal_title?: Maybe<DemiplaneJournalTitleMutationResponse>;
  /** update single row of the table: "demiplane.journal_title" */
  update_demiplane_journal_title_by_pk?: Maybe<DemiplaneJournalTitle>;
  /** update data of the table: "demiplane.matchmaking_token" */
  update_demiplane_matchmaking_token?: Maybe<DemiplaneMatchmakingTokenMutationResponse>;
  /** update single row of the table: "demiplane.matchmaking_token" */
  update_demiplane_matchmaking_token_by_pk?: Maybe<DemiplaneMatchmakingToken>;
  /** update data of the table: "demiplane.migrations" */
  update_demiplane_migrations?: Maybe<DemiplaneMigrationsMutationResponse>;
  /** update single row of the table: "demiplane.migrations" */
  update_demiplane_migrations_by_pk?: Maybe<DemiplaneMigrations>;
  /** update data of the table: "demiplane.notification_history" */
  update_demiplane_notification_history?: Maybe<DemiplaneNotificationHistoryMutationResponse>;
  /** update single row of the table: "demiplane.notification_history" */
  update_demiplane_notification_history_by_pk?: Maybe<DemiplaneNotificationHistory>;
  /** update data of the table: "demiplane.payment" */
  update_demiplane_payment?: Maybe<DemiplanePaymentMutationResponse>;
  /** update single row of the table: "demiplane.payment" */
  update_demiplane_payment_by_pk?: Maybe<DemiplanePayment>;
  /** update data of the table: "demiplane.player_award" */
  update_demiplane_player_award?: Maybe<DemiplanePlayerAwardMutationResponse>;
  /** update single row of the table: "demiplane.player_award" */
  update_demiplane_player_award_by_pk?: Maybe<DemiplanePlayerAward>;
  /** update data of the table: "demiplane.player_rating" */
  update_demiplane_player_rating?: Maybe<DemiplanePlayerRatingMutationResponse>;
  /** update single row of the table: "demiplane.player_rating" */
  update_demiplane_player_rating_by_pk?: Maybe<DemiplanePlayerRating>;
  /** update data of the table: "demiplane.pronoun" */
  update_demiplane_pronoun?: Maybe<DemiplanePronounMutationResponse>;
  /** update single row of the table: "demiplane.pronoun" */
  update_demiplane_pronoun_by_pk?: Maybe<DemiplanePronoun>;
  /** update data of the table: "demiplane.region" */
  update_demiplane_region?: Maybe<DemiplaneRegionMutationResponse>;
  /** update single row of the table: "demiplane.region" */
  update_demiplane_region_by_pk?: Maybe<DemiplaneRegion>;
  /** update data of the table: "demiplane.role" */
  update_demiplane_role?: Maybe<DemiplaneRoleMutationResponse>;
  /** update single row of the table: "demiplane.role" */
  update_demiplane_role_by_pk?: Maybe<DemiplaneRole>;
  /** update data of the table: "demiplane.session" */
  update_demiplane_session?: Maybe<DemiplaneSessionMutationResponse>;
  /** update single row of the table: "demiplane.session" */
  update_demiplane_session_by_pk?: Maybe<DemiplaneSession>;
  /** update data of the table: "demiplane.session_player" */
  update_demiplane_session_player?: Maybe<DemiplaneSessionPlayerMutationResponse>;
  /** update single row of the table: "demiplane.session_player" */
  update_demiplane_session_player_by_pk?: Maybe<DemiplaneSessionPlayer>;
  /** update data of the table: "demiplane.snippet" */
  update_demiplane_snippet?: Maybe<DemiplaneSnippetMutationResponse>;
  /** update single row of the table: "demiplane.snippet" */
  update_demiplane_snippet_by_pk?: Maybe<DemiplaneSnippet>;
  /** update data of the table: "demiplane.task" */
  update_demiplane_task?: Maybe<DemiplaneTaskMutationResponse>;
  /** update single row of the table: "demiplane.task" */
  update_demiplane_task_by_pk?: Maybe<DemiplaneTask>;
  /** update data of the table: "demiplane.task_read_status" */
  update_demiplane_task_read_status?: Maybe<DemiplaneTaskReadStatusMutationResponse>;
  /** update single row of the table: "demiplane.task_read_status" */
  update_demiplane_task_read_status_by_pk?: Maybe<DemiplaneTaskReadStatus>;
  /** update data of the table: "demiplane.tax_nexus_region" */
  update_demiplane_tax_nexus_region?: Maybe<DemiplaneTaxNexusRegionMutationResponse>;
  /** update single row of the table: "demiplane.tax_nexus_region" */
  update_demiplane_tax_nexus_region_by_pk?: Maybe<DemiplaneTaxNexusRegion>;
  /** update data of the table: "demiplane.transaction_receipt" */
  update_demiplane_transaction_receipt?: Maybe<DemiplaneTransactionReceiptMutationResponse>;
  /** update single row of the table: "demiplane.transaction_receipt" */
  update_demiplane_transaction_receipt_by_pk?: Maybe<DemiplaneTransactionReceipt>;
  /** update data of the table: "demiplane.upload" */
  update_demiplane_upload?: Maybe<DemiplaneUploadMutationResponse>;
  /** update single row of the table: "demiplane.upload" */
  update_demiplane_upload_by_pk?: Maybe<DemiplaneUpload>;
  /** update data of the table: "demiplane.urap" */
  update_demiplane_urap?: Maybe<DemiplaneUrapMutationResponse>;
  /** update single row of the table: "demiplane.urap" */
  update_demiplane_urap_by_pk?: Maybe<DemiplaneUrap>;
  /** update data of the table: "demiplane.urgt" */
  update_demiplane_urgt?: Maybe<DemiplaneUrgtMutationResponse>;
  /** update single row of the table: "demiplane.urgt" */
  update_demiplane_urgt_by_pk?: Maybe<DemiplaneUrgt>;
  /** update data of the table: "demiplane.user" */
  update_demiplane_user?: Maybe<DemiplaneUserMutationResponse>;
  /** update data of the table: "demiplane.user_activity" */
  update_demiplane_user_activity?: Maybe<DemiplaneUserActivityMutationResponse>;
  /** update single row of the table: "demiplane.user_activity" */
  update_demiplane_user_activity_by_pk?: Maybe<DemiplaneUserActivity>;
  /** update data of the table: "demiplane.user_adventure" */
  update_demiplane_user_adventure?: Maybe<DemiplaneUserAdventureMutationResponse>;
  /** update single row of the table: "demiplane.user_adventure" */
  update_demiplane_user_adventure_by_pk?: Maybe<DemiplaneUserAdventure>;
  /** update data of the table: "demiplane.user_adventure_ranking" */
  update_demiplane_user_adventure_ranking?: Maybe<DemiplaneUserAdventureRankingMutationResponse>;
  /** update single row of the table: "demiplane.user_adventure_ranking" */
  update_demiplane_user_adventure_ranking_by_pk?: Maybe<DemiplaneUserAdventureRanking>;
  /** update data of the table: "demiplane.user_adventure_role" */
  update_demiplane_user_adventure_role?: Maybe<DemiplaneUserAdventureRoleMutationResponse>;
  /** update single row of the table: "demiplane.user_adventure_role" */
  update_demiplane_user_adventure_role_by_pk?: Maybe<DemiplaneUserAdventureRole>;
  /** update single row of the table: "demiplane.user" */
  update_demiplane_user_by_pk?: Maybe<DemiplaneUser>;
  /** update data of the table: "demiplane.user_email_notification_pref" */
  update_demiplane_user_email_notification_pref?: Maybe<DemiplaneUserEmailNotificationPrefMutationResponse>;
  /** update single row of the table: "demiplane.user_email_notification_pref" */
  update_demiplane_user_email_notification_pref_by_pk?: Maybe<DemiplaneUserEmailNotificationPref>;
  /** update data of the table: "demiplane.user_invitation" */
  update_demiplane_user_invitation?: Maybe<DemiplaneUserInvitationMutationResponse>;
  /** update single row of the table: "demiplane.user_invitation" */
  update_demiplane_user_invitation_by_pk?: Maybe<DemiplaneUserInvitation>;
  /** update data of the table: "demiplane.user_metadata" */
  update_demiplane_user_metadata?: Maybe<DemiplaneUserMetadataMutationResponse>;
  /** update single row of the table: "demiplane.user_metadata" */
  update_demiplane_user_metadata_by_pk?: Maybe<DemiplaneUserMetadata>;
  /** update data of the table: "demiplane.user_push_notification_pref" */
  update_demiplane_user_push_notification_pref?: Maybe<DemiplaneUserPushNotificationPrefMutationResponse>;
  /** update single row of the table: "demiplane.user_push_notification_pref" */
  update_demiplane_user_push_notification_pref_by_pk?: Maybe<DemiplaneUserPushNotificationPref>;
  /** update data of the table: "demiplane.user_recruitment" */
  update_demiplane_user_recruitment?: Maybe<DemiplaneUserRecruitmentMutationResponse>;
  /** update data of the table: "demiplane.user_recruitment_adventurer" */
  update_demiplane_user_recruitment_adventurer?: Maybe<DemiplaneUserRecruitmentAdventurerMutationResponse>;
  /** update data of the table: "demiplane.user_recruitment_adventurer_availability" */
  update_demiplane_user_recruitment_adventurer_availability?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponse>;
  /** update single row of the table: "demiplane.user_recruitment_adventurer_availability" */
  update_demiplane_user_recruitment_adventurer_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** update single row of the table: "demiplane.user_recruitment_adventurer" */
  update_demiplane_user_recruitment_adventurer_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurer>;
  /** update data of the table: "demiplane.user_recruitment_adventurer_games" */
  update_demiplane_user_recruitment_adventurer_games?: Maybe<DemiplaneUserRecruitmentAdventurerGamesMutationResponse>;
  /** update single row of the table: "demiplane.user_recruitment_adventurer_games" */
  update_demiplane_user_recruitment_adventurer_games_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerGames>;
  /** update data of the table: "demiplane.user_recruitment_adventurer_platforms" */
  update_demiplane_user_recruitment_adventurer_platforms?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsMutationResponse>;
  /** update single row of the table: "demiplane.user_recruitment_adventurer_platforms" */
  update_demiplane_user_recruitment_adventurer_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** update data of the table: "demiplane.user_recruitment_availability" */
  update_demiplane_user_recruitment_availability?: Maybe<DemiplaneUserRecruitmentAvailabilityMutationResponse>;
  /** update single row of the table: "demiplane.user_recruitment_availability" */
  update_demiplane_user_recruitment_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAvailability>;
  /** update single row of the table: "demiplane.user_recruitment" */
  update_demiplane_user_recruitment_by_pk?: Maybe<DemiplaneUserRecruitment>;
  /** update data of the table: "demiplane.user_recruitment_game_master" */
  update_demiplane_user_recruitment_game_master?: Maybe<DemiplaneUserRecruitmentGameMasterMutationResponse>;
  /** update data of the table: "demiplane.user_recruitment_game_master_availability" */
  update_demiplane_user_recruitment_game_master_availability?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponse>;
  /** update single row of the table: "demiplane.user_recruitment_game_master_availability" */
  update_demiplane_user_recruitment_game_master_availability_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** update single row of the table: "demiplane.user_recruitment_game_master" */
  update_demiplane_user_recruitment_game_master_by_pk?: Maybe<DemiplaneUserRecruitmentGameMaster>;
  /** update data of the table: "demiplane.user_recruitment_game_master_games" */
  update_demiplane_user_recruitment_game_master_games?: Maybe<DemiplaneUserRecruitmentGameMasterGamesMutationResponse>;
  /** update single row of the table: "demiplane.user_recruitment_game_master_games" */
  update_demiplane_user_recruitment_game_master_games_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterGames>;
  /** update data of the table: "demiplane.user_recruitment_game_master_platforms" */
  update_demiplane_user_recruitment_game_master_platforms?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsMutationResponse>;
  /** update single row of the table: "demiplane.user_recruitment_game_master_platforms" */
  update_demiplane_user_recruitment_game_master_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** update data of the table: "demiplane.user_role" */
  update_demiplane_user_role?: Maybe<DemiplaneUserRoleMutationResponse>;
  /** update single row of the table: "demiplane.user_role" */
  update_demiplane_user_role_by_pk?: Maybe<DemiplaneUserRole>;
  /** update data of the table: "demiplane.user_visibility" */
  update_demiplane_user_visibility?: Maybe<DemiplaneUserVisibilityMutationResponse>;
  /** update single row of the table: "demiplane.user_visibility" */
  update_demiplane_user_visibility_by_pk?: Maybe<DemiplaneUserVisibility>;
};


/** mutation root */
export type MutationRootAcceptInviteArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootAcceptRecruitArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootAcceptRequestArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootAddAdventureSlimArgs = {
  adventureCost: Scalars['Float'];
  adventureName: Scalars['String'];
  gameTypeCode: Scalars['String'];
  invites: Scalars['String'];
  isPaid: Scalars['Boolean'];
};


/** mutation root */
export type MutationRootAddCreditCardArgs = {
  creditCard: CreditCardInput;
};


/** mutation root */
export type MutationRootAddFavoriteGameArgs = {
  gameTypeId: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootAddFavoritePlatformArgs = {
  adventuringPlatformId: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootAddJournalSuperEventArgs = {
  journalSuperEvent: JournalSuperEventInput;
};


/** mutation root */
export type MutationRootAddNewPortalV2Args = {
  gameTypeCode: Scalars['String'];
  invites: Scalars['String'];
  isPaid: Scalars['Boolean'];
  portalCost: Scalars['Float'];
  portalName: Scalars['String'];
};


/** mutation root */
export type MutationRootAddUserArgs = {
  user: UserInput;
};


/** mutation root */
export type MutationRootAttendEpisodeArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootAuthMmArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type MutationRootCalculateFeesWithTipArgs = {
  tip: TipInput;
};


/** mutation root */
export type MutationRootCancelPaymentArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootCancelSessionArgs = {
  session: SessionCancelInput;
};


/** mutation root */
export type MutationRootCantMakeEpisodeArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootCapturePaymentArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootCaptureTipPaymentArgs = {
  tip: TipInput;
};


/** mutation root */
export type MutationRootCompleteRatingFlowArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootCompleteTaskArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootContactGameMasterArgs = {
  adventureId: Scalars['String'];
};


/** mutation root */
export type MutationRootCreateFavoriteDiceRollArgs = {
  adventureId: Scalars['String'];
  diceRoll: Scalars['String'];
  rollName: Scalars['String'];
};


/** mutation root */
export type MutationRootCreateTaskArgs = {
  task: TaskCreateInput;
};


/** mutation root */
export type MutationRootCreateUploadArgs = {
  adventurePlayerId?: Maybe<Scalars['String']>;
  file: Scalars['String'];
  kind: Scalars['String'];
  mimeType: Scalars['String'];
};


/** mutation root */
export type MutationRootDeclineInviteArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootDeclineRecruitArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootDeclineRequestArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootDeclineTaskArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteAdventureArgs = {
  adventureId: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteFavoriteDiceRollArgs = {
  adventureId: Scalars['String'];
  favoriteDiceRollId: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteJournalSuperEventArgs = {
  journalSuperEvent: JournalSuperEventDeleteInput;
};


/** mutation root */
export type MutationRootDeleteUploadArgs = {
  adventurePlayerId?: Maybe<Scalars['String']>;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdArgs = {
  where: DemiplaneAdBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdLinkArgs = {
  where: DemiplaneAdLinkBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdLinkByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdMatchCriteriaArgs = {
  where: DemiplaneAdMatchCriteriaBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdMatchCriteriaByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdMatchCriteriaTimeArgs = {
  where: DemiplaneAdMatchCriteriaTimeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdMatchCriteriaTimeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdRequestArgs = {
  where: DemiplaneAdRequestBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdRequestByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdTimeArgs = {
  where: DemiplaneAdTimeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdTimeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureArgs = {
  where: DemiplaneAdventureBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureConnectionArgs = {
  where: DemiplaneAdventureConnectionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureConnectionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureDemiplaneToolArgs = {
  where: DemiplaneAdventureDemiplaneToolBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureDemiplaneToolByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureLinkArgs = {
  where: DemiplaneAdventureLinkBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureLinkByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventurePlayerArgs = {
  where: DemiplaneAdventurePlayerBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventurePlayerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureTodoArgs = {
  where: DemiplaneAdventureTodoBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventureTodoByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventuringPlatformArgs = {
  where: DemiplaneAdventuringPlatformBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAdventuringPlatformByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAmcAdventuringPlatformsArgs = {
  where: DemiplaneAmcAdventuringPlatformsBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAmcAdventuringPlatformsByPkArgs = {
  adMatchCriteriaId: Scalars['bigint'];
  adventuringPlatformId: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAmcGameTypesArgs = {
  where: DemiplaneAmcGameTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAmcGameTypesByPkArgs = {
  adMatchCriteriaId: Scalars['bigint'];
  gameTypeId: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAppVersionArgs = {
  where: DemiplaneAppVersionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAppVersionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAttachmentArgs = {
  where: DemiplaneAttachmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAttachmentByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneAwardTypeArgs = {
  where: DemiplaneAwardTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneAwardTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneConnectionStatusArgs = {
  where: DemiplaneConnectionStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneConnectionStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneConnectionTypeArgs = {
  where: DemiplaneConnectionTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneConnectionTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneContactTypeArgs = {
  where: DemiplaneContactTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneContactTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneCountryArgs = {
  where: DemiplaneCountryBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneCountryByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneCreditCardArgs = {
  where: DemiplaneCreditCardBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneCreditCardByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneDemiplaneToolArgs = {
  where: DemiplaneDemiplaneToolBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneDemiplaneToolByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneEpisodeAttendanceArgs = {
  where: DemiplaneEpisodeAttendanceBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneEpisodeAttendanceByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneEventArgs = {
  where: DemiplaneEventBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneEventByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneEventTypeArgs = {
  where: DemiplaneEventTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneEventTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneFavoriteAdventuringPlatformArgs = {
  where: DemiplaneFavoriteAdventuringPlatformBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneFavoriteAdventuringPlatformByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneFavoriteDiceRollArgs = {
  where: DemiplaneFavoriteDiceRollBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneFavoriteDiceRollByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneFavoriteGameArgs = {
  where: DemiplaneFavoriteGameBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneFavoriteGameByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneGameTypeArgs = {
  where: DemiplaneGameTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneGameTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneGmRatingArgs = {
  where: DemiplaneGmRatingBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneGmRatingByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneGmRecommendationArgs = {
  where: DemiplaneGmRecommendationBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneGmRecommendationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneInvitationArgs = {
  where: DemiplaneInvitationBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneInvitationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalArgs = {
  where: DemiplaneJournalBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalNoteArgs = {
  where: DemiplaneJournalNoteBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalNoteByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalNoteShareArgs = {
  where: DemiplaneJournalNoteShareBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalNoteShareByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalSuperEventArgs = {
  where: DemiplaneJournalSuperEventBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalSuperEventByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalSuperEventReadStatusArgs = {
  where: DemiplaneJournalSuperEventReadStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalSuperEventReadStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalTitleArgs = {
  where: DemiplaneJournalTitleBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneJournalTitleByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneMatchmakingTokenArgs = {
  where: DemiplaneMatchmakingTokenBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneMatchmakingTokenByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneMigrationsArgs = {
  where: DemiplaneMigrationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneMigrationsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneNotificationHistoryArgs = {
  where: DemiplaneNotificationHistoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneNotificationHistoryByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplanePaymentArgs = {
  where: DemiplanePaymentBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplanePaymentByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplanePlayerAwardArgs = {
  where: DemiplanePlayerAwardBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplanePlayerAwardByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplanePlayerRatingArgs = {
  where: DemiplanePlayerRatingBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplanePlayerRatingByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplanePronounArgs = {
  where: DemiplanePronounBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplanePronounByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneRegionArgs = {
  where: DemiplaneRegionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneRegionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneRoleArgs = {
  where: DemiplaneRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneRoleByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneSessionArgs = {
  where: DemiplaneSessionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneSessionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneSessionPlayerArgs = {
  where: DemiplaneSessionPlayerBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneSessionPlayerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneSnippetArgs = {
  where: DemiplaneSnippetBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneSnippetByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneTaskArgs = {
  where: DemiplaneTaskBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneTaskByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneTaskReadStatusArgs = {
  where: DemiplaneTaskReadStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneTaskReadStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneTaxNexusRegionArgs = {
  where: DemiplaneTaxNexusRegionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneTaxNexusRegionByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneTransactionReceiptArgs = {
  where: DemiplaneTransactionReceiptBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneTransactionReceiptByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUploadArgs = {
  where: DemiplaneUploadBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUploadByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUrapArgs = {
  where: DemiplaneUrapBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUrapByPkArgs = {
  adventuringPlatformId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUrgtArgs = {
  where: DemiplaneUrgtBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUrgtByPkArgs = {
  gameTypeId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserArgs = {
  where: DemiplaneUserBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserActivityArgs = {
  where: DemiplaneUserActivityBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserActivityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserAdventureArgs = {
  where: DemiplaneUserAdventureBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserAdventureByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserAdventureRankingArgs = {
  where: DemiplaneUserAdventureRankingBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserAdventureRankingByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserAdventureRoleArgs = {
  where: DemiplaneUserAdventureRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserAdventureRoleByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserEmailNotificationPrefArgs = {
  where: DemiplaneUserEmailNotificationPrefBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserEmailNotificationPrefByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserInvitationArgs = {
  where: DemiplaneUserInvitationBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserInvitationByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserMetadataArgs = {
  where: DemiplaneUserMetadataBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserMetadataByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserPushNotificationPrefArgs = {
  where: DemiplaneUserPushNotificationPrefBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserPushNotificationPrefByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentArgs = {
  where: DemiplaneUserRecruitmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerArgs = {
  where: DemiplaneUserRecruitmentAdventurerBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerAvailabilityArgs = {
  where: DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerGamesArgs = {
  where: DemiplaneUserRecruitmentAdventurerGamesBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerGamesByPkArgs = {
  game_type_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerPlatformsArgs = {
  where: DemiplaneUserRecruitmentAdventurerPlatformsBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAvailabilityArgs = {
  where: DemiplaneUserRecruitmentAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterArgs = {
  where: DemiplaneUserRecruitmentGameMasterBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterAvailabilityArgs = {
  where: DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterGamesArgs = {
  where: DemiplaneUserRecruitmentGameMasterGamesBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterGamesByPkArgs = {
  game_type_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterPlatformsArgs = {
  where: DemiplaneUserRecruitmentGameMasterPlatformsBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRoleArgs = {
  where: DemiplaneUserRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserRoleByPkArgs = {
  roleId: Scalars['bigint'];
  userId: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserVisibilityArgs = {
  where: DemiplaneUserVisibilityBoolExp;
};


/** mutation root */
export type MutationRootDeleteDemiplaneUserVisibilityByPkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type MutationRootDisableAdventureMatchmakingArgs = {
  adventureId: Scalars['String'];
};


/** mutation root */
export type MutationRootEjectAdventureGameMasterArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootEjectGameMasterV2Args = {
  adventureId: Scalars['String'];
  gameMasterToEjectUserId: Scalars['String'];
};


/** mutation root */
export type MutationRootEnableAdventureMatchmakingArgs = {
  adventureId: Scalars['String'];
  adventurerCount: Scalars['Float'];
  autoJoin: Scalars['Boolean'];
  threshold: Scalars['Float'];
};


/** mutation root */
export type MutationRootEndEpisodeArgs = {
  portalId: Scalars['String'];
};


/** mutation root */
export type MutationRootGameplayNotificationArgs = {
  session: SessionGameplayNotificationInput;
};


/** mutation root */
export type MutationRootImmediatelyOpenEpisodeV2Args = {
  portalId: Scalars['String'];
};


/** mutation root */
export type MutationRootImmediatelyOpenSessionArgs = {
  session: SessionImmediateOpenInput;
};


/** mutation root */
export type MutationRootInsertPlayerAwardArgs = {
  awardType: Scalars['String'];
  sessionId: Scalars['String'];
  sessionPlayerId: Scalars['String'];
};


/** mutation root */
export type MutationRootInsertDemiplaneAdArgs = {
  objects: Array<DemiplaneAdInsertInput>;
  on_conflict?: Maybe<DemiplaneAdOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdLinkArgs = {
  objects: Array<DemiplaneAdLinkInsertInput>;
  on_conflict?: Maybe<DemiplaneAdLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdLinkOneArgs = {
  object: DemiplaneAdLinkInsertInput;
  on_conflict?: Maybe<DemiplaneAdLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdMatchCriteriaArgs = {
  objects: Array<DemiplaneAdMatchCriteriaInsertInput>;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdMatchCriteriaOneArgs = {
  object: DemiplaneAdMatchCriteriaInsertInput;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdMatchCriteriaTimeArgs = {
  objects: Array<DemiplaneAdMatchCriteriaTimeInsertInput>;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaTimeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdMatchCriteriaTimeOneArgs = {
  object: DemiplaneAdMatchCriteriaTimeInsertInput;
  on_conflict?: Maybe<DemiplaneAdMatchCriteriaTimeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdOneArgs = {
  object: DemiplaneAdInsertInput;
  on_conflict?: Maybe<DemiplaneAdOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdRequestArgs = {
  objects: Array<DemiplaneAdRequestInsertInput>;
  on_conflict?: Maybe<DemiplaneAdRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdRequestOneArgs = {
  object: DemiplaneAdRequestInsertInput;
  on_conflict?: Maybe<DemiplaneAdRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdTimeArgs = {
  objects: Array<DemiplaneAdTimeInsertInput>;
  on_conflict?: Maybe<DemiplaneAdTimeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdTimeOneArgs = {
  object: DemiplaneAdTimeInsertInput;
  on_conflict?: Maybe<DemiplaneAdTimeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureArgs = {
  objects: Array<DemiplaneAdventureInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureConnectionArgs = {
  objects: Array<DemiplaneAdventureConnectionInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureConnectionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureConnectionOneArgs = {
  object: DemiplaneAdventureConnectionInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureConnectionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureDemiplaneToolArgs = {
  objects: Array<DemiplaneAdventureDemiplaneToolInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureDemiplaneToolOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureDemiplaneToolOneArgs = {
  object: DemiplaneAdventureDemiplaneToolInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureDemiplaneToolOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureLinkArgs = {
  objects: Array<DemiplaneAdventureLinkInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureLinkOneArgs = {
  object: DemiplaneAdventureLinkInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureOneArgs = {
  object: DemiplaneAdventureInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventurePlayerArgs = {
  objects: Array<DemiplaneAdventurePlayerInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventurePlayerOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventurePlayerOneArgs = {
  object: DemiplaneAdventurePlayerInsertInput;
  on_conflict?: Maybe<DemiplaneAdventurePlayerOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureTodoArgs = {
  objects: Array<DemiplaneAdventureTodoInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventureTodoOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventureTodoOneArgs = {
  object: DemiplaneAdventureTodoInsertInput;
  on_conflict?: Maybe<DemiplaneAdventureTodoOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventuringPlatformArgs = {
  objects: Array<DemiplaneAdventuringPlatformInsertInput>;
  on_conflict?: Maybe<DemiplaneAdventuringPlatformOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAdventuringPlatformOneArgs = {
  object: DemiplaneAdventuringPlatformInsertInput;
  on_conflict?: Maybe<DemiplaneAdventuringPlatformOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAmcAdventuringPlatformsArgs = {
  objects: Array<DemiplaneAmcAdventuringPlatformsInsertInput>;
  on_conflict?: Maybe<DemiplaneAmcAdventuringPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAmcAdventuringPlatformsOneArgs = {
  object: DemiplaneAmcAdventuringPlatformsInsertInput;
  on_conflict?: Maybe<DemiplaneAmcAdventuringPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAmcGameTypesArgs = {
  objects: Array<DemiplaneAmcGameTypesInsertInput>;
  on_conflict?: Maybe<DemiplaneAmcGameTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAmcGameTypesOneArgs = {
  object: DemiplaneAmcGameTypesInsertInput;
  on_conflict?: Maybe<DemiplaneAmcGameTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAppVersionArgs = {
  objects: Array<DemiplaneAppVersionInsertInput>;
  on_conflict?: Maybe<DemiplaneAppVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAppVersionOneArgs = {
  object: DemiplaneAppVersionInsertInput;
  on_conflict?: Maybe<DemiplaneAppVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAttachmentArgs = {
  objects: Array<DemiplaneAttachmentInsertInput>;
  on_conflict?: Maybe<DemiplaneAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAttachmentOneArgs = {
  object: DemiplaneAttachmentInsertInput;
  on_conflict?: Maybe<DemiplaneAttachmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAwardTypeArgs = {
  objects: Array<DemiplaneAwardTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneAwardTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneAwardTypeOneArgs = {
  object: DemiplaneAwardTypeInsertInput;
  on_conflict?: Maybe<DemiplaneAwardTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneConnectionStatusArgs = {
  objects: Array<DemiplaneConnectionStatusInsertInput>;
  on_conflict?: Maybe<DemiplaneConnectionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneConnectionStatusOneArgs = {
  object: DemiplaneConnectionStatusInsertInput;
  on_conflict?: Maybe<DemiplaneConnectionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneConnectionTypeArgs = {
  objects: Array<DemiplaneConnectionTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneConnectionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneConnectionTypeOneArgs = {
  object: DemiplaneConnectionTypeInsertInput;
  on_conflict?: Maybe<DemiplaneConnectionTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneContactTypeArgs = {
  objects: Array<DemiplaneContactTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneContactTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneContactTypeOneArgs = {
  object: DemiplaneContactTypeInsertInput;
  on_conflict?: Maybe<DemiplaneContactTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneCountryArgs = {
  objects: Array<DemiplaneCountryInsertInput>;
  on_conflict?: Maybe<DemiplaneCountryOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneCountryOneArgs = {
  object: DemiplaneCountryInsertInput;
  on_conflict?: Maybe<DemiplaneCountryOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneCreditCardArgs = {
  objects: Array<DemiplaneCreditCardInsertInput>;
  on_conflict?: Maybe<DemiplaneCreditCardOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneCreditCardOneArgs = {
  object: DemiplaneCreditCardInsertInput;
  on_conflict?: Maybe<DemiplaneCreditCardOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneDemiplaneToolArgs = {
  objects: Array<DemiplaneDemiplaneToolInsertInput>;
  on_conflict?: Maybe<DemiplaneDemiplaneToolOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneDemiplaneToolOneArgs = {
  object: DemiplaneDemiplaneToolInsertInput;
  on_conflict?: Maybe<DemiplaneDemiplaneToolOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneEpisodeAttendanceArgs = {
  objects: Array<DemiplaneEpisodeAttendanceInsertInput>;
  on_conflict?: Maybe<DemiplaneEpisodeAttendanceOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneEpisodeAttendanceOneArgs = {
  object: DemiplaneEpisodeAttendanceInsertInput;
  on_conflict?: Maybe<DemiplaneEpisodeAttendanceOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneEventArgs = {
  objects: Array<DemiplaneEventInsertInput>;
  on_conflict?: Maybe<DemiplaneEventOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneEventOneArgs = {
  object: DemiplaneEventInsertInput;
  on_conflict?: Maybe<DemiplaneEventOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneEventTypeArgs = {
  objects: Array<DemiplaneEventTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneEventTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneEventTypeOneArgs = {
  object: DemiplaneEventTypeInsertInput;
  on_conflict?: Maybe<DemiplaneEventTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneFavoriteAdventuringPlatformArgs = {
  objects: Array<DemiplaneFavoriteAdventuringPlatformInsertInput>;
  on_conflict?: Maybe<DemiplaneFavoriteAdventuringPlatformOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneFavoriteAdventuringPlatformOneArgs = {
  object: DemiplaneFavoriteAdventuringPlatformInsertInput;
  on_conflict?: Maybe<DemiplaneFavoriteAdventuringPlatformOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneFavoriteDiceRollArgs = {
  objects: Array<DemiplaneFavoriteDiceRollInsertInput>;
  on_conflict?: Maybe<DemiplaneFavoriteDiceRollOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneFavoriteDiceRollOneArgs = {
  object: DemiplaneFavoriteDiceRollInsertInput;
  on_conflict?: Maybe<DemiplaneFavoriteDiceRollOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneFavoriteGameArgs = {
  objects: Array<DemiplaneFavoriteGameInsertInput>;
  on_conflict?: Maybe<DemiplaneFavoriteGameOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneFavoriteGameOneArgs = {
  object: DemiplaneFavoriteGameInsertInput;
  on_conflict?: Maybe<DemiplaneFavoriteGameOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneGameTypeArgs = {
  objects: Array<DemiplaneGameTypeInsertInput>;
  on_conflict?: Maybe<DemiplaneGameTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneGameTypeOneArgs = {
  object: DemiplaneGameTypeInsertInput;
  on_conflict?: Maybe<DemiplaneGameTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneGmRatingArgs = {
  objects: Array<DemiplaneGmRatingInsertInput>;
  on_conflict?: Maybe<DemiplaneGmRatingOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneGmRatingOneArgs = {
  object: DemiplaneGmRatingInsertInput;
  on_conflict?: Maybe<DemiplaneGmRatingOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneGmRecommendationArgs = {
  objects: Array<DemiplaneGmRecommendationInsertInput>;
  on_conflict?: Maybe<DemiplaneGmRecommendationOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneGmRecommendationOneArgs = {
  object: DemiplaneGmRecommendationInsertInput;
  on_conflict?: Maybe<DemiplaneGmRecommendationOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneInvitationArgs = {
  objects: Array<DemiplaneInvitationInsertInput>;
  on_conflict?: Maybe<DemiplaneInvitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneInvitationOneArgs = {
  object: DemiplaneInvitationInsertInput;
  on_conflict?: Maybe<DemiplaneInvitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalArgs = {
  objects: Array<DemiplaneJournalInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalNoteArgs = {
  objects: Array<DemiplaneJournalNoteInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalNoteOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalNoteOneArgs = {
  object: DemiplaneJournalNoteInsertInput;
  on_conflict?: Maybe<DemiplaneJournalNoteOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalNoteShareArgs = {
  objects: Array<DemiplaneJournalNoteShareInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalNoteShareOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalNoteShareOneArgs = {
  object: DemiplaneJournalNoteShareInsertInput;
  on_conflict?: Maybe<DemiplaneJournalNoteShareOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalOneArgs = {
  object: DemiplaneJournalInsertInput;
  on_conflict?: Maybe<DemiplaneJournalOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalSuperEventArgs = {
  objects: Array<DemiplaneJournalSuperEventInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalSuperEventOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalSuperEventOneArgs = {
  object: DemiplaneJournalSuperEventInsertInput;
  on_conflict?: Maybe<DemiplaneJournalSuperEventOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalSuperEventReadStatusArgs = {
  objects: Array<DemiplaneJournalSuperEventReadStatusInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalSuperEventReadStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalSuperEventReadStatusOneArgs = {
  object: DemiplaneJournalSuperEventReadStatusInsertInput;
  on_conflict?: Maybe<DemiplaneJournalSuperEventReadStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalTitleArgs = {
  objects: Array<DemiplaneJournalTitleInsertInput>;
  on_conflict?: Maybe<DemiplaneJournalTitleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneJournalTitleOneArgs = {
  object: DemiplaneJournalTitleInsertInput;
  on_conflict?: Maybe<DemiplaneJournalTitleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneMatchmakingTokenArgs = {
  objects: Array<DemiplaneMatchmakingTokenInsertInput>;
  on_conflict?: Maybe<DemiplaneMatchmakingTokenOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneMatchmakingTokenOneArgs = {
  object: DemiplaneMatchmakingTokenInsertInput;
  on_conflict?: Maybe<DemiplaneMatchmakingTokenOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneMigrationsArgs = {
  objects: Array<DemiplaneMigrationsInsertInput>;
  on_conflict?: Maybe<DemiplaneMigrationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneMigrationsOneArgs = {
  object: DemiplaneMigrationsInsertInput;
  on_conflict?: Maybe<DemiplaneMigrationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneNotificationHistoryArgs = {
  objects: Array<DemiplaneNotificationHistoryInsertInput>;
  on_conflict?: Maybe<DemiplaneNotificationHistoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneNotificationHistoryOneArgs = {
  object: DemiplaneNotificationHistoryInsertInput;
  on_conflict?: Maybe<DemiplaneNotificationHistoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePaymentArgs = {
  objects: Array<DemiplanePaymentInsertInput>;
  on_conflict?: Maybe<DemiplanePaymentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePaymentOneArgs = {
  object: DemiplanePaymentInsertInput;
  on_conflict?: Maybe<DemiplanePaymentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePlayerAwardArgs = {
  objects: Array<DemiplanePlayerAwardInsertInput>;
  on_conflict?: Maybe<DemiplanePlayerAwardOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePlayerAwardOneArgs = {
  object: DemiplanePlayerAwardInsertInput;
  on_conflict?: Maybe<DemiplanePlayerAwardOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePlayerRatingArgs = {
  objects: Array<DemiplanePlayerRatingInsertInput>;
  on_conflict?: Maybe<DemiplanePlayerRatingOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePlayerRatingOneArgs = {
  object: DemiplanePlayerRatingInsertInput;
  on_conflict?: Maybe<DemiplanePlayerRatingOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePronounArgs = {
  objects: Array<DemiplanePronounInsertInput>;
  on_conflict?: Maybe<DemiplanePronounOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplanePronounOneArgs = {
  object: DemiplanePronounInsertInput;
  on_conflict?: Maybe<DemiplanePronounOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneRegionArgs = {
  objects: Array<DemiplaneRegionInsertInput>;
  on_conflict?: Maybe<DemiplaneRegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneRegionOneArgs = {
  object: DemiplaneRegionInsertInput;
  on_conflict?: Maybe<DemiplaneRegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneRoleArgs = {
  objects: Array<DemiplaneRoleInsertInput>;
  on_conflict?: Maybe<DemiplaneRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneRoleOneArgs = {
  object: DemiplaneRoleInsertInput;
  on_conflict?: Maybe<DemiplaneRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneSessionArgs = {
  objects: Array<DemiplaneSessionInsertInput>;
  on_conflict?: Maybe<DemiplaneSessionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneSessionOneArgs = {
  object: DemiplaneSessionInsertInput;
  on_conflict?: Maybe<DemiplaneSessionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneSessionPlayerArgs = {
  objects: Array<DemiplaneSessionPlayerInsertInput>;
  on_conflict?: Maybe<DemiplaneSessionPlayerOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneSessionPlayerOneArgs = {
  object: DemiplaneSessionPlayerInsertInput;
  on_conflict?: Maybe<DemiplaneSessionPlayerOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneSnippetArgs = {
  objects: Array<DemiplaneSnippetInsertInput>;
  on_conflict?: Maybe<DemiplaneSnippetOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneSnippetOneArgs = {
  object: DemiplaneSnippetInsertInput;
  on_conflict?: Maybe<DemiplaneSnippetOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTaskArgs = {
  objects: Array<DemiplaneTaskInsertInput>;
  on_conflict?: Maybe<DemiplaneTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTaskOneArgs = {
  object: DemiplaneTaskInsertInput;
  on_conflict?: Maybe<DemiplaneTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTaskReadStatusArgs = {
  objects: Array<DemiplaneTaskReadStatusInsertInput>;
  on_conflict?: Maybe<DemiplaneTaskReadStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTaskReadStatusOneArgs = {
  object: DemiplaneTaskReadStatusInsertInput;
  on_conflict?: Maybe<DemiplaneTaskReadStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTaxNexusRegionArgs = {
  objects: Array<DemiplaneTaxNexusRegionInsertInput>;
  on_conflict?: Maybe<DemiplaneTaxNexusRegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTaxNexusRegionOneArgs = {
  object: DemiplaneTaxNexusRegionInsertInput;
  on_conflict?: Maybe<DemiplaneTaxNexusRegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTransactionReceiptArgs = {
  objects: Array<DemiplaneTransactionReceiptInsertInput>;
  on_conflict?: Maybe<DemiplaneTransactionReceiptOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneTransactionReceiptOneArgs = {
  object: DemiplaneTransactionReceiptInsertInput;
  on_conflict?: Maybe<DemiplaneTransactionReceiptOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUploadArgs = {
  objects: Array<DemiplaneUploadInsertInput>;
  on_conflict?: Maybe<DemiplaneUploadOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUploadOneArgs = {
  object: DemiplaneUploadInsertInput;
  on_conflict?: Maybe<DemiplaneUploadOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUrapArgs = {
  objects: Array<DemiplaneUrapInsertInput>;
  on_conflict?: Maybe<DemiplaneUrapOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUrapOneArgs = {
  object: DemiplaneUrapInsertInput;
  on_conflict?: Maybe<DemiplaneUrapOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUrgtArgs = {
  objects: Array<DemiplaneUrgtInsertInput>;
  on_conflict?: Maybe<DemiplaneUrgtOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUrgtOneArgs = {
  object: DemiplaneUrgtInsertInput;
  on_conflict?: Maybe<DemiplaneUrgtOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserArgs = {
  objects: Array<DemiplaneUserInsertInput>;
  on_conflict?: Maybe<DemiplaneUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserActivityArgs = {
  objects: Array<DemiplaneUserActivityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserActivityOneArgs = {
  object: DemiplaneUserActivityInsertInput;
  on_conflict?: Maybe<DemiplaneUserActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserAdventureArgs = {
  objects: Array<DemiplaneUserAdventureInsertInput>;
  on_conflict?: Maybe<DemiplaneUserAdventureOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserAdventureOneArgs = {
  object: DemiplaneUserAdventureInsertInput;
  on_conflict?: Maybe<DemiplaneUserAdventureOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserAdventureRankingArgs = {
  objects: Array<DemiplaneUserAdventureRankingInsertInput>;
  on_conflict?: Maybe<DemiplaneUserAdventureRankingOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserAdventureRankingOneArgs = {
  object: DemiplaneUserAdventureRankingInsertInput;
  on_conflict?: Maybe<DemiplaneUserAdventureRankingOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserAdventureRoleArgs = {
  objects: Array<DemiplaneUserAdventureRoleInsertInput>;
  on_conflict?: Maybe<DemiplaneUserAdventureRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserAdventureRoleOneArgs = {
  object: DemiplaneUserAdventureRoleInsertInput;
  on_conflict?: Maybe<DemiplaneUserAdventureRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserEmailNotificationPrefArgs = {
  objects: Array<DemiplaneUserEmailNotificationPrefInsertInput>;
  on_conflict?: Maybe<DemiplaneUserEmailNotificationPrefOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserEmailNotificationPrefOneArgs = {
  object: DemiplaneUserEmailNotificationPrefInsertInput;
  on_conflict?: Maybe<DemiplaneUserEmailNotificationPrefOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserInvitationArgs = {
  objects: Array<DemiplaneUserInvitationInsertInput>;
  on_conflict?: Maybe<DemiplaneUserInvitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserInvitationOneArgs = {
  object: DemiplaneUserInvitationInsertInput;
  on_conflict?: Maybe<DemiplaneUserInvitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserMetadataArgs = {
  objects: Array<DemiplaneUserMetadataInsertInput>;
  on_conflict?: Maybe<DemiplaneUserMetadataOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserMetadataOneArgs = {
  object: DemiplaneUserMetadataInsertInput;
  on_conflict?: Maybe<DemiplaneUserMetadataOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserOneArgs = {
  object: DemiplaneUserInsertInput;
  on_conflict?: Maybe<DemiplaneUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserPushNotificationPrefArgs = {
  objects: Array<DemiplaneUserPushNotificationPrefInsertInput>;
  on_conflict?: Maybe<DemiplaneUserPushNotificationPrefOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserPushNotificationPrefOneArgs = {
  object: DemiplaneUserPushNotificationPrefInsertInput;
  on_conflict?: Maybe<DemiplaneUserPushNotificationPrefOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentArgs = {
  objects: Array<DemiplaneUserRecruitmentInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerArgs = {
  objects: Array<DemiplaneUserRecruitmentAdventurerInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerAvailabilityArgs = {
  objects: Array<DemiplaneUserRecruitmentAdventurerAvailabilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerAvailabilityOneArgs = {
  object: DemiplaneUserRecruitmentAdventurerAvailabilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerGamesArgs = {
  objects: Array<DemiplaneUserRecruitmentAdventurerGamesInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerGamesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerGamesOneArgs = {
  object: DemiplaneUserRecruitmentAdventurerGamesInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerGamesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerOneArgs = {
  object: DemiplaneUserRecruitmentAdventurerInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerPlatformsArgs = {
  objects: Array<DemiplaneUserRecruitmentAdventurerPlatformsInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAdventurerPlatformsOneArgs = {
  object: DemiplaneUserRecruitmentAdventurerPlatformsInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAvailabilityArgs = {
  objects: Array<DemiplaneUserRecruitmentAvailabilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentAvailabilityOneArgs = {
  object: DemiplaneUserRecruitmentAvailabilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterArgs = {
  objects: Array<DemiplaneUserRecruitmentGameMasterInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterAvailabilityArgs = {
  objects: Array<DemiplaneUserRecruitmentGameMasterAvailabilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterAvailabilityOneArgs = {
  object: DemiplaneUserRecruitmentGameMasterAvailabilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterGamesArgs = {
  objects: Array<DemiplaneUserRecruitmentGameMasterGamesInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterGamesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterGamesOneArgs = {
  object: DemiplaneUserRecruitmentGameMasterGamesInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterGamesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterOneArgs = {
  object: DemiplaneUserRecruitmentGameMasterInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterPlatformsArgs = {
  objects: Array<DemiplaneUserRecruitmentGameMasterPlatformsInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentGameMasterPlatformsOneArgs = {
  object: DemiplaneUserRecruitmentGameMasterPlatformsInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRecruitmentOneArgs = {
  object: DemiplaneUserRecruitmentInsertInput;
  on_conflict?: Maybe<DemiplaneUserRecruitmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRoleArgs = {
  objects: Array<DemiplaneUserRoleInsertInput>;
  on_conflict?: Maybe<DemiplaneUserRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserRoleOneArgs = {
  object: DemiplaneUserRoleInsertInput;
  on_conflict?: Maybe<DemiplaneUserRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserVisibilityArgs = {
  objects: Array<DemiplaneUserVisibilityInsertInput>;
  on_conflict?: Maybe<DemiplaneUserVisibilityOnConflict>;
};


/** mutation root */
export type MutationRootInsertDemiplaneUserVisibilityOneArgs = {
  object: DemiplaneUserVisibilityInsertInput;
  on_conflict?: Maybe<DemiplaneUserVisibilityOnConflict>;
};


/** mutation root */
export type MutationRootInviteAdventurersArgs = {
  adventureId: Scalars['String'];
  invites: Scalars['String'];
};


/** mutation root */
export type MutationRootJoinAdventureRecruitArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootJoinAdventureRequestArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootJoinSessionArgs = {
  session: JoinSessionInput;
};


/** mutation root */
export type MutationRootLeaveAdventureArgs = {
  adventureId: Scalars['String'];
};


/** mutation root */
export type MutationRootLeaveEpisodeV2Args = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationRootLeaveRecruitArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootLeaveRequestArgs = {
  connectionId: Scalars['String'];
};


/** mutation root */
export type MutationRootLeaveSessionArgs = {
  session: LeaveSessionInput;
};


/** mutation root */
export type MutationRootOpenEpisodeV2Args = {
  portalId: Scalars['String'];
};


/** mutation root */
export type MutationRootOpenSessionArgs = {
  adventureId: Scalars['String'];
};


/** mutation root */
export type MutationRootParticipantLeavesWhisperArgs = {
  portalId: Scalars['String'];
  whisperParticipantId: Scalars['String'];
};


/** mutation root */
export type MutationRootPreauthorizePaymentArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootPrecalculateFeesArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootPromoteAdventureGameMasterArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootPromoteGameMasterV2Args = {
  adventureId: Scalars['String'];
  adventurerToPromoteUserId: Scalars['String'];
};


/** mutation root */
export type MutationRootReadAdventureConnectionsArgs = {
  adventureConnnectionIds: Array<Maybe<Scalars['String']>>;
  adventureId: Scalars['String'];
};


/** mutation root */
export type MutationRootReadNotificationArgs = {
  notificationId: Scalars['String'];
};


/** mutation root */
export type MutationRootReadNotificationByUrlArgs = {
  url: Scalars['String'];
};


/** mutation root */
export type MutationRootReadSharedNoteArgs = {
  adventureId: Scalars['String'];
  noteId: Scalars['String'];
};


/** mutation root */
export type MutationRootReadSuperEventsArgs = {
  adventureId: Scalars['String'];
  superEventIds: Array<Maybe<Scalars['String']>>;
};


/** mutation root */
export type MutationRootReadTasksArgs = {
  adventureId: Scalars['String'];
  taskIds: Array<Maybe<Scalars['String']>>;
};


/** mutation root */
export type MutationRootRecruitAdventureArgs = {
  adventureId: Scalars['String'];
  forGm: Scalars['Boolean'];
  recruitId: Scalars['String'];
};


/** mutation root */
export type MutationRootRemoveAdventurerArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootRemoveAdventurerV2Args = {
  adventureId: Scalars['String'];
  adventurerToRemoveUserId: Scalars['String'];
};


/** mutation root */
export type MutationRootReorderFavoriteDiceRollsArgs = {
  adventureId: Scalars['String'];
  rollIds: Array<Maybe<Scalars['String']>>;
};


/** mutation root */
export type MutationRootReorderFavoriteGamesArgs = {
  gameTypeIds: Array<Scalars['Int']>;
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootReorderFavoritePlatformsArgs = {
  adventuringPlatformIds: Array<Scalars['Int']>;
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootReorderTasksArgs = {
  taskIds: Array<Maybe<Scalars['String']>>;
};


/** mutation root */
export type MutationRootRescheduleSessionArgs = {
  session: SessionRescheduleInput;
};


/** mutation root */
export type MutationRootScheduleSessionArgs = {
  session: SessionScheduleInput;
};


/** mutation root */
export type MutationRootSendFeedbackEmailArgs = {
  adventureId: Scalars['String'];
  feedback: Scalars['String'];
  gmRating: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootSendTipEmailArgs = {
  adventureId: Scalars['String'];
  userId: Scalars['String'];
};


/** mutation root */
export type MutationRootSetEpisodeFrequencyArgs = {
  adventureId: Scalars['String'];
  episodeFrequency: Scalars['Float'];
};


/** mutation root */
export type MutationRootShareJournalNoteArgs = {
  journalNote: JournalNoteShareInput;
};


/** mutation root */
export type MutationRootSpecialRequestArgs = {
  adventureId: Scalars['String'];
};


/** mutation root */
export type MutationRootStartEpisodeV2Args = {
  portalId: Scalars['String'];
};


/** mutation root */
export type MutationRootStartSessionArgs = {
  session: SessionStartInput;
};


/** mutation root */
export type MutationRootSubmitFileArgs = {
  chatHash: Scalars['String'];
  file: Scalars['Upload'];
};


/** mutation root */
export type MutationRootSubmitFileV2Args = {
  base64str: Scalars['String'];
  chatHash: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootSubmitGiphyArgs = {
  chatHash: Scalars['String'];
  url: Scalars['String'];
};


/** mutation root */
export type MutationRootSubmitMessageArgs = {
  chatHash: Scalars['String'];
  message: Scalars['String'];
};


/** mutation root */
export type MutationRootSubmitSystemMessageArgs = {
  chatHash: Scalars['String'];
  message: Scalars['String'];
};


/** mutation root */
export type MutationRootToggleVoiceVideoStatusArgs = {
  adventurePlayerId: Scalars['String'];
  inVideo: Scalars['Boolean'];
  inVoice: Scalars['Boolean'];
};


/** mutation root */
export type MutationRootToggleWhisperStatusArgs = {
  adventurePlayerId: Scalars['String'];
};


/** mutation root */
export type MutationRootTriggerDisableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


/** mutation root */
export type MutationRootTriggerDisableWhisperV2Args = {
  adventurePlayerId: Scalars['String'];
};


/** mutation root */
export type MutationRootTriggerEnableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


/** mutation root */
export type MutationRootTriggerEnableWhisperV2Args = {
  adventurePlayerId: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateAdventureCoverImageArgs = {
  adventureId: Scalars['String'];
  demicardId: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateAdventureDetailsArgs = {
  adventureId: Scalars['String'];
  adventuringPlatformId: Scalars['String'];
  description: Scalars['String'];
  gameLinkUrl: Scalars['String'];
  newPlayerFriendly: Scalars['Boolean'];
};


/** mutation root */
export type MutationRootUpdateAdventureFrequencyArgs = {
  adventureId: Scalars['String'];
  frequency: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateAdventureGameAvailabilityArgs = {
  adventureId: Scalars['String'];
  availabilities?: Maybe<Array<AdTimeInput>>;
  scheduledDate?: Maybe<Scalars['String']>;
};


/** mutation root */
export type MutationRootUpdateAdventureMatchMakingStatusArgs = {
  adventureId: Scalars['String'];
  matchMakingStatusAdvDetails?: Maybe<Scalars['Boolean']>;
  matchMakingStatusAdvImage?: Maybe<Scalars['Boolean']>;
  matchMakingStatusAdvType?: Maybe<Scalars['Boolean']>;
  matchMakingStatusGameAvail?: Maybe<Scalars['Boolean']>;
  matchMakingStatusPartyMgmt?: Maybe<Scalars['Boolean']>;
  matchMakingStatusThemeScores?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationRootUpdateAdventurePlayerNameArgs = {
  adventurePlayerId: Scalars['String'];
  playerName: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateAdventureThemeScoresArgs = {
  adventureId: Scalars['String'];
  combat: Scalars['Float'];
  playerStory: Scalars['Float'];
  puzzlesLogic: Scalars['Float'];
  rolePlaying: Scalars['Float'];
  socialIntrigue: Scalars['Float'];
  strictRules: Scalars['Float'];
};


/** mutation root */
export type MutationRootUpdateBillingAddressArgs = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  regionId: Scalars['String'];
  zipcode: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateFavoriteDiceRollArgs = {
  adventureId: Scalars['String'];
  favoriteDiceRollId: Scalars['String'];
  newDiceRoll: Scalars['String'];
  newRollName: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateFirstLoginArgs = {
  firstLogin: Scalars['Boolean'];
};


/** mutation root */
export type MutationRootUpdateGameMasterBioArgs = {
  gameMasterBio: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateGmRatingArgs = {
  rating: Scalars['Float'];
  sessionId: Scalars['String'];
  sessionPlayerId: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateJournalArgs = {
  journal: JournalUpdateInput;
};


/** mutation root */
export type MutationRootUpdateJournalNoteArgs = {
  journalNote: JournalNoteUpdateInput;
};


/** mutation root */
export type MutationRootUpdateJournalSuperEventArgs = {
  journalSuperEvent: JournalSuperEventUpdateInput;
};


/** mutation root */
export type MutationRootUpdateLeaderHasViewedArgs = {
  adventureId: Scalars['String'];
  isAdventurer: Scalars['Boolean'];
  isGm: Scalars['Boolean'];
};


/** mutation root */
export type MutationRootUpdatePlayerRatingsArgs = {
  ratings: Array<Maybe<PlayerRatingInput>>;
  sessionId: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdatePreferencesArgs = {
  userPreferences: UserPreferenceInput;
};


/** mutation root */
export type MutationRootUpdateTaskArgs = {
  id: Scalars['String'];
  title: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateUploadArgs = {
  file: Scalars['String'];
  kind: Scalars['String'];
  mimeType: Scalars['String'];
  uploadId: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateUploadV2Args = {
  file: Scalars['String'];
  kind: Scalars['String'];
  mimeType: Scalars['String'];
  uploadId: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateUserInformationArgs = {
  userInformation: UserInformationInput;
};


/** mutation root */
export type MutationRootUpdateUserInformationV2Args = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  dob: Scalars['timestamptz'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  regionId: Scalars['String'];
  zipcode: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateUserMetadataArgs = {
  userMetadata: UserMetadataInput;
};


/** mutation root */
export type MutationRootUpdateUserRecruitmentArgs = {
  userRecruitment: UserRecruitmentInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdArgs = {
  _inc?: Maybe<DemiplaneAdIncInput>;
  _set?: Maybe<DemiplaneAdSetInput>;
  where: DemiplaneAdBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdByPkArgs = {
  _inc?: Maybe<DemiplaneAdIncInput>;
  _set?: Maybe<DemiplaneAdSetInput>;
  pk_columns: DemiplaneAdPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdLinkArgs = {
  _inc?: Maybe<DemiplaneAdLinkIncInput>;
  _set?: Maybe<DemiplaneAdLinkSetInput>;
  where: DemiplaneAdLinkBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdLinkByPkArgs = {
  _inc?: Maybe<DemiplaneAdLinkIncInput>;
  _set?: Maybe<DemiplaneAdLinkSetInput>;
  pk_columns: DemiplaneAdLinkPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdMatchCriteriaArgs = {
  _inc?: Maybe<DemiplaneAdMatchCriteriaIncInput>;
  _set?: Maybe<DemiplaneAdMatchCriteriaSetInput>;
  where: DemiplaneAdMatchCriteriaBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdMatchCriteriaByPkArgs = {
  _inc?: Maybe<DemiplaneAdMatchCriteriaIncInput>;
  _set?: Maybe<DemiplaneAdMatchCriteriaSetInput>;
  pk_columns: DemiplaneAdMatchCriteriaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdMatchCriteriaTimeArgs = {
  _inc?: Maybe<DemiplaneAdMatchCriteriaTimeIncInput>;
  _set?: Maybe<DemiplaneAdMatchCriteriaTimeSetInput>;
  where: DemiplaneAdMatchCriteriaTimeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdMatchCriteriaTimeByPkArgs = {
  _inc?: Maybe<DemiplaneAdMatchCriteriaTimeIncInput>;
  _set?: Maybe<DemiplaneAdMatchCriteriaTimeSetInput>;
  pk_columns: DemiplaneAdMatchCriteriaTimePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdRequestArgs = {
  _inc?: Maybe<DemiplaneAdRequestIncInput>;
  _set?: Maybe<DemiplaneAdRequestSetInput>;
  where: DemiplaneAdRequestBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdRequestByPkArgs = {
  _inc?: Maybe<DemiplaneAdRequestIncInput>;
  _set?: Maybe<DemiplaneAdRequestSetInput>;
  pk_columns: DemiplaneAdRequestPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdTimeArgs = {
  _inc?: Maybe<DemiplaneAdTimeIncInput>;
  _set?: Maybe<DemiplaneAdTimeSetInput>;
  where: DemiplaneAdTimeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdTimeByPkArgs = {
  _inc?: Maybe<DemiplaneAdTimeIncInput>;
  _set?: Maybe<DemiplaneAdTimeSetInput>;
  pk_columns: DemiplaneAdTimePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureArgs = {
  _inc?: Maybe<DemiplaneAdventureIncInput>;
  _set?: Maybe<DemiplaneAdventureSetInput>;
  where: DemiplaneAdventureBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureByPkArgs = {
  _inc?: Maybe<DemiplaneAdventureIncInput>;
  _set?: Maybe<DemiplaneAdventureSetInput>;
  pk_columns: DemiplaneAdventurePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureConnectionArgs = {
  _inc?: Maybe<DemiplaneAdventureConnectionIncInput>;
  _set?: Maybe<DemiplaneAdventureConnectionSetInput>;
  where: DemiplaneAdventureConnectionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureConnectionByPkArgs = {
  _inc?: Maybe<DemiplaneAdventureConnectionIncInput>;
  _set?: Maybe<DemiplaneAdventureConnectionSetInput>;
  pk_columns: DemiplaneAdventureConnectionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureDemiplaneToolArgs = {
  _inc?: Maybe<DemiplaneAdventureDemiplaneToolIncInput>;
  _set?: Maybe<DemiplaneAdventureDemiplaneToolSetInput>;
  where: DemiplaneAdventureDemiplaneToolBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureDemiplaneToolByPkArgs = {
  _inc?: Maybe<DemiplaneAdventureDemiplaneToolIncInput>;
  _set?: Maybe<DemiplaneAdventureDemiplaneToolSetInput>;
  pk_columns: DemiplaneAdventureDemiplaneToolPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureLinkArgs = {
  _inc?: Maybe<DemiplaneAdventureLinkIncInput>;
  _set?: Maybe<DemiplaneAdventureLinkSetInput>;
  where: DemiplaneAdventureLinkBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureLinkByPkArgs = {
  _inc?: Maybe<DemiplaneAdventureLinkIncInput>;
  _set?: Maybe<DemiplaneAdventureLinkSetInput>;
  pk_columns: DemiplaneAdventureLinkPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventurePlayerArgs = {
  _inc?: Maybe<DemiplaneAdventurePlayerIncInput>;
  _set?: Maybe<DemiplaneAdventurePlayerSetInput>;
  where: DemiplaneAdventurePlayerBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventurePlayerByPkArgs = {
  _inc?: Maybe<DemiplaneAdventurePlayerIncInput>;
  _set?: Maybe<DemiplaneAdventurePlayerSetInput>;
  pk_columns: DemiplaneAdventurePlayerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureTodoArgs = {
  _inc?: Maybe<DemiplaneAdventureTodoIncInput>;
  _set?: Maybe<DemiplaneAdventureTodoSetInput>;
  where: DemiplaneAdventureTodoBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventureTodoByPkArgs = {
  _inc?: Maybe<DemiplaneAdventureTodoIncInput>;
  _set?: Maybe<DemiplaneAdventureTodoSetInput>;
  pk_columns: DemiplaneAdventureTodoPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventuringPlatformArgs = {
  _inc?: Maybe<DemiplaneAdventuringPlatformIncInput>;
  _set?: Maybe<DemiplaneAdventuringPlatformSetInput>;
  where: DemiplaneAdventuringPlatformBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAdventuringPlatformByPkArgs = {
  _inc?: Maybe<DemiplaneAdventuringPlatformIncInput>;
  _set?: Maybe<DemiplaneAdventuringPlatformSetInput>;
  pk_columns: DemiplaneAdventuringPlatformPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAmcAdventuringPlatformsArgs = {
  _inc?: Maybe<DemiplaneAmcAdventuringPlatformsIncInput>;
  _set?: Maybe<DemiplaneAmcAdventuringPlatformsSetInput>;
  where: DemiplaneAmcAdventuringPlatformsBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAmcAdventuringPlatformsByPkArgs = {
  _inc?: Maybe<DemiplaneAmcAdventuringPlatformsIncInput>;
  _set?: Maybe<DemiplaneAmcAdventuringPlatformsSetInput>;
  pk_columns: DemiplaneAmcAdventuringPlatformsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAmcGameTypesArgs = {
  _inc?: Maybe<DemiplaneAmcGameTypesIncInput>;
  _set?: Maybe<DemiplaneAmcGameTypesSetInput>;
  where: DemiplaneAmcGameTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAmcGameTypesByPkArgs = {
  _inc?: Maybe<DemiplaneAmcGameTypesIncInput>;
  _set?: Maybe<DemiplaneAmcGameTypesSetInput>;
  pk_columns: DemiplaneAmcGameTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAppVersionArgs = {
  _inc?: Maybe<DemiplaneAppVersionIncInput>;
  _set?: Maybe<DemiplaneAppVersionSetInput>;
  where: DemiplaneAppVersionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAppVersionByPkArgs = {
  _inc?: Maybe<DemiplaneAppVersionIncInput>;
  _set?: Maybe<DemiplaneAppVersionSetInput>;
  pk_columns: DemiplaneAppVersionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAttachmentArgs = {
  _inc?: Maybe<DemiplaneAttachmentIncInput>;
  _set?: Maybe<DemiplaneAttachmentSetInput>;
  where: DemiplaneAttachmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAttachmentByPkArgs = {
  _inc?: Maybe<DemiplaneAttachmentIncInput>;
  _set?: Maybe<DemiplaneAttachmentSetInput>;
  pk_columns: DemiplaneAttachmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAwardTypeArgs = {
  _inc?: Maybe<DemiplaneAwardTypeIncInput>;
  _set?: Maybe<DemiplaneAwardTypeSetInput>;
  where: DemiplaneAwardTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneAwardTypeByPkArgs = {
  _inc?: Maybe<DemiplaneAwardTypeIncInput>;
  _set?: Maybe<DemiplaneAwardTypeSetInput>;
  pk_columns: DemiplaneAwardTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneConnectionStatusArgs = {
  _inc?: Maybe<DemiplaneConnectionStatusIncInput>;
  _set?: Maybe<DemiplaneConnectionStatusSetInput>;
  where: DemiplaneConnectionStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneConnectionStatusByPkArgs = {
  _inc?: Maybe<DemiplaneConnectionStatusIncInput>;
  _set?: Maybe<DemiplaneConnectionStatusSetInput>;
  pk_columns: DemiplaneConnectionStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneConnectionTypeArgs = {
  _inc?: Maybe<DemiplaneConnectionTypeIncInput>;
  _set?: Maybe<DemiplaneConnectionTypeSetInput>;
  where: DemiplaneConnectionTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneConnectionTypeByPkArgs = {
  _inc?: Maybe<DemiplaneConnectionTypeIncInput>;
  _set?: Maybe<DemiplaneConnectionTypeSetInput>;
  pk_columns: DemiplaneConnectionTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneContactTypeArgs = {
  _inc?: Maybe<DemiplaneContactTypeIncInput>;
  _set?: Maybe<DemiplaneContactTypeSetInput>;
  where: DemiplaneContactTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneContactTypeByPkArgs = {
  _inc?: Maybe<DemiplaneContactTypeIncInput>;
  _set?: Maybe<DemiplaneContactTypeSetInput>;
  pk_columns: DemiplaneContactTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneCountryArgs = {
  _inc?: Maybe<DemiplaneCountryIncInput>;
  _set?: Maybe<DemiplaneCountrySetInput>;
  where: DemiplaneCountryBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneCountryByPkArgs = {
  _inc?: Maybe<DemiplaneCountryIncInput>;
  _set?: Maybe<DemiplaneCountrySetInput>;
  pk_columns: DemiplaneCountryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneCreditCardArgs = {
  _inc?: Maybe<DemiplaneCreditCardIncInput>;
  _set?: Maybe<DemiplaneCreditCardSetInput>;
  where: DemiplaneCreditCardBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneCreditCardByPkArgs = {
  _inc?: Maybe<DemiplaneCreditCardIncInput>;
  _set?: Maybe<DemiplaneCreditCardSetInput>;
  pk_columns: DemiplaneCreditCardPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneDemiplaneToolArgs = {
  _inc?: Maybe<DemiplaneDemiplaneToolIncInput>;
  _set?: Maybe<DemiplaneDemiplaneToolSetInput>;
  where: DemiplaneDemiplaneToolBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneDemiplaneToolByPkArgs = {
  _inc?: Maybe<DemiplaneDemiplaneToolIncInput>;
  _set?: Maybe<DemiplaneDemiplaneToolSetInput>;
  pk_columns: DemiplaneDemiplaneToolPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneEpisodeAttendanceArgs = {
  _inc?: Maybe<DemiplaneEpisodeAttendanceIncInput>;
  _set?: Maybe<DemiplaneEpisodeAttendanceSetInput>;
  where: DemiplaneEpisodeAttendanceBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneEpisodeAttendanceByPkArgs = {
  _inc?: Maybe<DemiplaneEpisodeAttendanceIncInput>;
  _set?: Maybe<DemiplaneEpisodeAttendanceSetInput>;
  pk_columns: DemiplaneEpisodeAttendancePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneEventArgs = {
  _inc?: Maybe<DemiplaneEventIncInput>;
  _set?: Maybe<DemiplaneEventSetInput>;
  where: DemiplaneEventBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneEventByPkArgs = {
  _inc?: Maybe<DemiplaneEventIncInput>;
  _set?: Maybe<DemiplaneEventSetInput>;
  pk_columns: DemiplaneEventPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneEventTypeArgs = {
  _inc?: Maybe<DemiplaneEventTypeIncInput>;
  _set?: Maybe<DemiplaneEventTypeSetInput>;
  where: DemiplaneEventTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneEventTypeByPkArgs = {
  _inc?: Maybe<DemiplaneEventTypeIncInput>;
  _set?: Maybe<DemiplaneEventTypeSetInput>;
  pk_columns: DemiplaneEventTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneFavoriteAdventuringPlatformArgs = {
  _inc?: Maybe<DemiplaneFavoriteAdventuringPlatformIncInput>;
  _set?: Maybe<DemiplaneFavoriteAdventuringPlatformSetInput>;
  where: DemiplaneFavoriteAdventuringPlatformBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneFavoriteAdventuringPlatformByPkArgs = {
  _inc?: Maybe<DemiplaneFavoriteAdventuringPlatformIncInput>;
  _set?: Maybe<DemiplaneFavoriteAdventuringPlatformSetInput>;
  pk_columns: DemiplaneFavoriteAdventuringPlatformPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneFavoriteDiceRollArgs = {
  _inc?: Maybe<DemiplaneFavoriteDiceRollIncInput>;
  _set?: Maybe<DemiplaneFavoriteDiceRollSetInput>;
  where: DemiplaneFavoriteDiceRollBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneFavoriteDiceRollByPkArgs = {
  _inc?: Maybe<DemiplaneFavoriteDiceRollIncInput>;
  _set?: Maybe<DemiplaneFavoriteDiceRollSetInput>;
  pk_columns: DemiplaneFavoriteDiceRollPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneFavoriteGameArgs = {
  _inc?: Maybe<DemiplaneFavoriteGameIncInput>;
  _set?: Maybe<DemiplaneFavoriteGameSetInput>;
  where: DemiplaneFavoriteGameBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneFavoriteGameByPkArgs = {
  _inc?: Maybe<DemiplaneFavoriteGameIncInput>;
  _set?: Maybe<DemiplaneFavoriteGameSetInput>;
  pk_columns: DemiplaneFavoriteGamePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneGameTypeArgs = {
  _inc?: Maybe<DemiplaneGameTypeIncInput>;
  _set?: Maybe<DemiplaneGameTypeSetInput>;
  where: DemiplaneGameTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneGameTypeByPkArgs = {
  _inc?: Maybe<DemiplaneGameTypeIncInput>;
  _set?: Maybe<DemiplaneGameTypeSetInput>;
  pk_columns: DemiplaneGameTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneGmRatingArgs = {
  _inc?: Maybe<DemiplaneGmRatingIncInput>;
  _set?: Maybe<DemiplaneGmRatingSetInput>;
  where: DemiplaneGmRatingBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneGmRatingByPkArgs = {
  _inc?: Maybe<DemiplaneGmRatingIncInput>;
  _set?: Maybe<DemiplaneGmRatingSetInput>;
  pk_columns: DemiplaneGmRatingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneGmRecommendationArgs = {
  _inc?: Maybe<DemiplaneGmRecommendationIncInput>;
  _set?: Maybe<DemiplaneGmRecommendationSetInput>;
  where: DemiplaneGmRecommendationBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneGmRecommendationByPkArgs = {
  _inc?: Maybe<DemiplaneGmRecommendationIncInput>;
  _set?: Maybe<DemiplaneGmRecommendationSetInput>;
  pk_columns: DemiplaneGmRecommendationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneInvitationArgs = {
  _inc?: Maybe<DemiplaneInvitationIncInput>;
  _set?: Maybe<DemiplaneInvitationSetInput>;
  where: DemiplaneInvitationBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneInvitationByPkArgs = {
  _inc?: Maybe<DemiplaneInvitationIncInput>;
  _set?: Maybe<DemiplaneInvitationSetInput>;
  pk_columns: DemiplaneInvitationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalArgs = {
  _inc?: Maybe<DemiplaneJournalIncInput>;
  _set?: Maybe<DemiplaneJournalSetInput>;
  where: DemiplaneJournalBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalByPkArgs = {
  _inc?: Maybe<DemiplaneJournalIncInput>;
  _set?: Maybe<DemiplaneJournalSetInput>;
  pk_columns: DemiplaneJournalPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalNoteArgs = {
  _append?: Maybe<DemiplaneJournalNoteAppendInput>;
  _delete_at_path?: Maybe<DemiplaneJournalNoteDeleteAtPathInput>;
  _delete_elem?: Maybe<DemiplaneJournalNoteDeleteElemInput>;
  _delete_key?: Maybe<DemiplaneJournalNoteDeleteKeyInput>;
  _inc?: Maybe<DemiplaneJournalNoteIncInput>;
  _prepend?: Maybe<DemiplaneJournalNotePrependInput>;
  _set?: Maybe<DemiplaneJournalNoteSetInput>;
  where: DemiplaneJournalNoteBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalNoteByPkArgs = {
  _append?: Maybe<DemiplaneJournalNoteAppendInput>;
  _delete_at_path?: Maybe<DemiplaneJournalNoteDeleteAtPathInput>;
  _delete_elem?: Maybe<DemiplaneJournalNoteDeleteElemInput>;
  _delete_key?: Maybe<DemiplaneJournalNoteDeleteKeyInput>;
  _inc?: Maybe<DemiplaneJournalNoteIncInput>;
  _prepend?: Maybe<DemiplaneJournalNotePrependInput>;
  _set?: Maybe<DemiplaneJournalNoteSetInput>;
  pk_columns: DemiplaneJournalNotePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalNoteShareArgs = {
  _inc?: Maybe<DemiplaneJournalNoteShareIncInput>;
  _set?: Maybe<DemiplaneJournalNoteShareSetInput>;
  where: DemiplaneJournalNoteShareBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalNoteShareByPkArgs = {
  _inc?: Maybe<DemiplaneJournalNoteShareIncInput>;
  _set?: Maybe<DemiplaneJournalNoteShareSetInput>;
  pk_columns: DemiplaneJournalNoteSharePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalSuperEventArgs = {
  _inc?: Maybe<DemiplaneJournalSuperEventIncInput>;
  _set?: Maybe<DemiplaneJournalSuperEventSetInput>;
  where: DemiplaneJournalSuperEventBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalSuperEventByPkArgs = {
  _inc?: Maybe<DemiplaneJournalSuperEventIncInput>;
  _set?: Maybe<DemiplaneJournalSuperEventSetInput>;
  pk_columns: DemiplaneJournalSuperEventPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalSuperEventReadStatusArgs = {
  _inc?: Maybe<DemiplaneJournalSuperEventReadStatusIncInput>;
  _set?: Maybe<DemiplaneJournalSuperEventReadStatusSetInput>;
  where: DemiplaneJournalSuperEventReadStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalSuperEventReadStatusByPkArgs = {
  _inc?: Maybe<DemiplaneJournalSuperEventReadStatusIncInput>;
  _set?: Maybe<DemiplaneJournalSuperEventReadStatusSetInput>;
  pk_columns: DemiplaneJournalSuperEventReadStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalTitleArgs = {
  _inc?: Maybe<DemiplaneJournalTitleIncInput>;
  _set?: Maybe<DemiplaneJournalTitleSetInput>;
  where: DemiplaneJournalTitleBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneJournalTitleByPkArgs = {
  _inc?: Maybe<DemiplaneJournalTitleIncInput>;
  _set?: Maybe<DemiplaneJournalTitleSetInput>;
  pk_columns: DemiplaneJournalTitlePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneMatchmakingTokenArgs = {
  _inc?: Maybe<DemiplaneMatchmakingTokenIncInput>;
  _set?: Maybe<DemiplaneMatchmakingTokenSetInput>;
  where: DemiplaneMatchmakingTokenBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneMatchmakingTokenByPkArgs = {
  _inc?: Maybe<DemiplaneMatchmakingTokenIncInput>;
  _set?: Maybe<DemiplaneMatchmakingTokenSetInput>;
  pk_columns: DemiplaneMatchmakingTokenPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneMigrationsArgs = {
  _inc?: Maybe<DemiplaneMigrationsIncInput>;
  _set?: Maybe<DemiplaneMigrationsSetInput>;
  where: DemiplaneMigrationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneMigrationsByPkArgs = {
  _inc?: Maybe<DemiplaneMigrationsIncInput>;
  _set?: Maybe<DemiplaneMigrationsSetInput>;
  pk_columns: DemiplaneMigrationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneNotificationHistoryArgs = {
  _inc?: Maybe<DemiplaneNotificationHistoryIncInput>;
  _set?: Maybe<DemiplaneNotificationHistorySetInput>;
  where: DemiplaneNotificationHistoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneNotificationHistoryByPkArgs = {
  _inc?: Maybe<DemiplaneNotificationHistoryIncInput>;
  _set?: Maybe<DemiplaneNotificationHistorySetInput>;
  pk_columns: DemiplaneNotificationHistoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplanePaymentArgs = {
  _inc?: Maybe<DemiplanePaymentIncInput>;
  _set?: Maybe<DemiplanePaymentSetInput>;
  where: DemiplanePaymentBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplanePaymentByPkArgs = {
  _inc?: Maybe<DemiplanePaymentIncInput>;
  _set?: Maybe<DemiplanePaymentSetInput>;
  pk_columns: DemiplanePaymentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplanePlayerAwardArgs = {
  _inc?: Maybe<DemiplanePlayerAwardIncInput>;
  _set?: Maybe<DemiplanePlayerAwardSetInput>;
  where: DemiplanePlayerAwardBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplanePlayerAwardByPkArgs = {
  _inc?: Maybe<DemiplanePlayerAwardIncInput>;
  _set?: Maybe<DemiplanePlayerAwardSetInput>;
  pk_columns: DemiplanePlayerAwardPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplanePlayerRatingArgs = {
  _inc?: Maybe<DemiplanePlayerRatingIncInput>;
  _set?: Maybe<DemiplanePlayerRatingSetInput>;
  where: DemiplanePlayerRatingBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplanePlayerRatingByPkArgs = {
  _inc?: Maybe<DemiplanePlayerRatingIncInput>;
  _set?: Maybe<DemiplanePlayerRatingSetInput>;
  pk_columns: DemiplanePlayerRatingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplanePronounArgs = {
  _inc?: Maybe<DemiplanePronounIncInput>;
  _set?: Maybe<DemiplanePronounSetInput>;
  where: DemiplanePronounBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplanePronounByPkArgs = {
  _inc?: Maybe<DemiplanePronounIncInput>;
  _set?: Maybe<DemiplanePronounSetInput>;
  pk_columns: DemiplanePronounPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneRegionArgs = {
  _inc?: Maybe<DemiplaneRegionIncInput>;
  _set?: Maybe<DemiplaneRegionSetInput>;
  where: DemiplaneRegionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneRegionByPkArgs = {
  _inc?: Maybe<DemiplaneRegionIncInput>;
  _set?: Maybe<DemiplaneRegionSetInput>;
  pk_columns: DemiplaneRegionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneRoleArgs = {
  _inc?: Maybe<DemiplaneRoleIncInput>;
  _set?: Maybe<DemiplaneRoleSetInput>;
  where: DemiplaneRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneRoleByPkArgs = {
  _inc?: Maybe<DemiplaneRoleIncInput>;
  _set?: Maybe<DemiplaneRoleSetInput>;
  pk_columns: DemiplaneRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneSessionArgs = {
  _inc?: Maybe<DemiplaneSessionIncInput>;
  _set?: Maybe<DemiplaneSessionSetInput>;
  where: DemiplaneSessionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneSessionByPkArgs = {
  _inc?: Maybe<DemiplaneSessionIncInput>;
  _set?: Maybe<DemiplaneSessionSetInput>;
  pk_columns: DemiplaneSessionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneSessionPlayerArgs = {
  _inc?: Maybe<DemiplaneSessionPlayerIncInput>;
  _set?: Maybe<DemiplaneSessionPlayerSetInput>;
  where: DemiplaneSessionPlayerBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneSessionPlayerByPkArgs = {
  _inc?: Maybe<DemiplaneSessionPlayerIncInput>;
  _set?: Maybe<DemiplaneSessionPlayerSetInput>;
  pk_columns: DemiplaneSessionPlayerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneSnippetArgs = {
  _inc?: Maybe<DemiplaneSnippetIncInput>;
  _set?: Maybe<DemiplaneSnippetSetInput>;
  where: DemiplaneSnippetBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneSnippetByPkArgs = {
  _inc?: Maybe<DemiplaneSnippetIncInput>;
  _set?: Maybe<DemiplaneSnippetSetInput>;
  pk_columns: DemiplaneSnippetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTaskArgs = {
  _inc?: Maybe<DemiplaneTaskIncInput>;
  _set?: Maybe<DemiplaneTaskSetInput>;
  where: DemiplaneTaskBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTaskByPkArgs = {
  _inc?: Maybe<DemiplaneTaskIncInput>;
  _set?: Maybe<DemiplaneTaskSetInput>;
  pk_columns: DemiplaneTaskPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTaskReadStatusArgs = {
  _inc?: Maybe<DemiplaneTaskReadStatusIncInput>;
  _set?: Maybe<DemiplaneTaskReadStatusSetInput>;
  where: DemiplaneTaskReadStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTaskReadStatusByPkArgs = {
  _inc?: Maybe<DemiplaneTaskReadStatusIncInput>;
  _set?: Maybe<DemiplaneTaskReadStatusSetInput>;
  pk_columns: DemiplaneTaskReadStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTaxNexusRegionArgs = {
  _inc?: Maybe<DemiplaneTaxNexusRegionIncInput>;
  _set?: Maybe<DemiplaneTaxNexusRegionSetInput>;
  where: DemiplaneTaxNexusRegionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTaxNexusRegionByPkArgs = {
  _inc?: Maybe<DemiplaneTaxNexusRegionIncInput>;
  _set?: Maybe<DemiplaneTaxNexusRegionSetInput>;
  pk_columns: DemiplaneTaxNexusRegionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTransactionReceiptArgs = {
  _inc?: Maybe<DemiplaneTransactionReceiptIncInput>;
  _set?: Maybe<DemiplaneTransactionReceiptSetInput>;
  where: DemiplaneTransactionReceiptBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneTransactionReceiptByPkArgs = {
  _inc?: Maybe<DemiplaneTransactionReceiptIncInput>;
  _set?: Maybe<DemiplaneTransactionReceiptSetInput>;
  pk_columns: DemiplaneTransactionReceiptPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUploadArgs = {
  _inc?: Maybe<DemiplaneUploadIncInput>;
  _set?: Maybe<DemiplaneUploadSetInput>;
  where: DemiplaneUploadBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUploadByPkArgs = {
  _inc?: Maybe<DemiplaneUploadIncInput>;
  _set?: Maybe<DemiplaneUploadSetInput>;
  pk_columns: DemiplaneUploadPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUrapArgs = {
  _inc?: Maybe<DemiplaneUrapIncInput>;
  _set?: Maybe<DemiplaneUrapSetInput>;
  where: DemiplaneUrapBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUrapByPkArgs = {
  _inc?: Maybe<DemiplaneUrapIncInput>;
  _set?: Maybe<DemiplaneUrapSetInput>;
  pk_columns: DemiplaneUrapPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUrgtArgs = {
  _inc?: Maybe<DemiplaneUrgtIncInput>;
  _set?: Maybe<DemiplaneUrgtSetInput>;
  where: DemiplaneUrgtBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUrgtByPkArgs = {
  _inc?: Maybe<DemiplaneUrgtIncInput>;
  _set?: Maybe<DemiplaneUrgtSetInput>;
  pk_columns: DemiplaneUrgtPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserArgs = {
  _inc?: Maybe<DemiplaneUserIncInput>;
  _set?: Maybe<DemiplaneUserSetInput>;
  where: DemiplaneUserBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserActivityArgs = {
  _inc?: Maybe<DemiplaneUserActivityIncInput>;
  _set?: Maybe<DemiplaneUserActivitySetInput>;
  where: DemiplaneUserActivityBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserActivityByPkArgs = {
  _inc?: Maybe<DemiplaneUserActivityIncInput>;
  _set?: Maybe<DemiplaneUserActivitySetInput>;
  pk_columns: DemiplaneUserActivityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserAdventureArgs = {
  _inc?: Maybe<DemiplaneUserAdventureIncInput>;
  _set?: Maybe<DemiplaneUserAdventureSetInput>;
  where: DemiplaneUserAdventureBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserAdventureByPkArgs = {
  _inc?: Maybe<DemiplaneUserAdventureIncInput>;
  _set?: Maybe<DemiplaneUserAdventureSetInput>;
  pk_columns: DemiplaneUserAdventurePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserAdventureRankingArgs = {
  _inc?: Maybe<DemiplaneUserAdventureRankingIncInput>;
  _set?: Maybe<DemiplaneUserAdventureRankingSetInput>;
  where: DemiplaneUserAdventureRankingBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserAdventureRankingByPkArgs = {
  _inc?: Maybe<DemiplaneUserAdventureRankingIncInput>;
  _set?: Maybe<DemiplaneUserAdventureRankingSetInput>;
  pk_columns: DemiplaneUserAdventureRankingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserAdventureRoleArgs = {
  _inc?: Maybe<DemiplaneUserAdventureRoleIncInput>;
  _set?: Maybe<DemiplaneUserAdventureRoleSetInput>;
  where: DemiplaneUserAdventureRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserAdventureRoleByPkArgs = {
  _inc?: Maybe<DemiplaneUserAdventureRoleIncInput>;
  _set?: Maybe<DemiplaneUserAdventureRoleSetInput>;
  pk_columns: DemiplaneUserAdventureRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserByPkArgs = {
  _inc?: Maybe<DemiplaneUserIncInput>;
  _set?: Maybe<DemiplaneUserSetInput>;
  pk_columns: DemiplaneUserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserEmailNotificationPrefArgs = {
  _inc?: Maybe<DemiplaneUserEmailNotificationPrefIncInput>;
  _set?: Maybe<DemiplaneUserEmailNotificationPrefSetInput>;
  where: DemiplaneUserEmailNotificationPrefBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserEmailNotificationPrefByPkArgs = {
  _inc?: Maybe<DemiplaneUserEmailNotificationPrefIncInput>;
  _set?: Maybe<DemiplaneUserEmailNotificationPrefSetInput>;
  pk_columns: DemiplaneUserEmailNotificationPrefPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserInvitationArgs = {
  _inc?: Maybe<DemiplaneUserInvitationIncInput>;
  _set?: Maybe<DemiplaneUserInvitationSetInput>;
  where: DemiplaneUserInvitationBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserInvitationByPkArgs = {
  _inc?: Maybe<DemiplaneUserInvitationIncInput>;
  _set?: Maybe<DemiplaneUserInvitationSetInput>;
  pk_columns: DemiplaneUserInvitationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserMetadataArgs = {
  _inc?: Maybe<DemiplaneUserMetadataIncInput>;
  _set?: Maybe<DemiplaneUserMetadataSetInput>;
  where: DemiplaneUserMetadataBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserMetadataByPkArgs = {
  _inc?: Maybe<DemiplaneUserMetadataIncInput>;
  _set?: Maybe<DemiplaneUserMetadataSetInput>;
  pk_columns: DemiplaneUserMetadataPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserPushNotificationPrefArgs = {
  _inc?: Maybe<DemiplaneUserPushNotificationPrefIncInput>;
  _set?: Maybe<DemiplaneUserPushNotificationPrefSetInput>;
  where: DemiplaneUserPushNotificationPrefBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserPushNotificationPrefByPkArgs = {
  _inc?: Maybe<DemiplaneUserPushNotificationPrefIncInput>;
  _set?: Maybe<DemiplaneUserPushNotificationPrefSetInput>;
  pk_columns: DemiplaneUserPushNotificationPrefPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentSetInput>;
  where: DemiplaneUserRecruitmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerSetInput>;
  where: DemiplaneUserRecruitmentAdventurerBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerAvailabilityArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilitySetInput>;
  where: DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilitySetInput>;
  pk_columns: DemiplaneUserRecruitmentAdventurerAvailabilityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerSetInput>;
  pk_columns: DemiplaneUserRecruitmentAdventurerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerGamesArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerGamesIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerGamesSetInput>;
  where: DemiplaneUserRecruitmentAdventurerGamesBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerGamesByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerGamesIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerGamesSetInput>;
  pk_columns: DemiplaneUserRecruitmentAdventurerGamesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerPlatformsArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsSetInput>;
  where: DemiplaneUserRecruitmentAdventurerPlatformsBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsSetInput>;
  pk_columns: DemiplaneUserRecruitmentAdventurerPlatformsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAvailabilityArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAvailabilityIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAvailabilitySetInput>;
  where: DemiplaneUserRecruitmentAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentAvailabilityByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentAvailabilityIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentAvailabilitySetInput>;
  pk_columns: DemiplaneUserRecruitmentAvailabilityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentSetInput>;
  pk_columns: DemiplaneUserRecruitmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterSetInput>;
  where: DemiplaneUserRecruitmentGameMasterBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterAvailabilityArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilitySetInput>;
  where: DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilitySetInput>;
  pk_columns: DemiplaneUserRecruitmentGameMasterAvailabilityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterSetInput>;
  pk_columns: DemiplaneUserRecruitmentGameMasterPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterGamesArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterGamesIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterGamesSetInput>;
  where: DemiplaneUserRecruitmentGameMasterGamesBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterGamesByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterGamesIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterGamesSetInput>;
  pk_columns: DemiplaneUserRecruitmentGameMasterGamesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterPlatformsArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsSetInput>;
  where: DemiplaneUserRecruitmentGameMasterPlatformsBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs = {
  _inc?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsIncInput>;
  _set?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsSetInput>;
  pk_columns: DemiplaneUserRecruitmentGameMasterPlatformsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRoleArgs = {
  _inc?: Maybe<DemiplaneUserRoleIncInput>;
  _set?: Maybe<DemiplaneUserRoleSetInput>;
  where: DemiplaneUserRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserRoleByPkArgs = {
  _inc?: Maybe<DemiplaneUserRoleIncInput>;
  _set?: Maybe<DemiplaneUserRoleSetInput>;
  pk_columns: DemiplaneUserRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserVisibilityArgs = {
  _inc?: Maybe<DemiplaneUserVisibilityIncInput>;
  _set?: Maybe<DemiplaneUserVisibilitySetInput>;
  where: DemiplaneUserVisibilityBoolExp;
};


/** mutation root */
export type MutationRootUpdateDemiplaneUserVisibilityByPkArgs = {
  _inc?: Maybe<DemiplaneUserVisibilityIncInput>;
  _set?: Maybe<DemiplaneUserVisibilitySetInput>;
  pk_columns: DemiplaneUserVisibilityPkColumnsInput;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type QueryRoot = {
  __typename?: 'query_root';
  /** perform the action: "aboutUser" */
  aboutUser?: Maybe<AboutUserOutput>;
  /** perform the action: "adDetail" */
  adDetail?: Maybe<AdDetailOutput>;
  adventure: AdventureType;
  adventureActives: Array<AdventureType>;
  adventureDetails: AdventureType;
  adventureHosts: Array<AdventureType>;
  adventuringPlatforms: Array<AdventuringPlatformType>;
  almostFullAds: Array<SearchAdResultType>;
  appVersion: AppVersionType;
  chatToken: ChatTokenType;
  conversationParticipants: ConversationParticipantsType;
  creditCards: Array<CreditCardType>;
  currentUser: UserType;
  demicards: Array<AttachmentType>;
  /** fetch data from the table: "demiplane.ad" */
  demiplane_ad: Array<DemiplaneAd>;
  /** fetch aggregated fields from the table: "demiplane.ad" */
  demiplane_ad_aggregate: DemiplaneAdAggregate;
  /** fetch data from the table: "demiplane.ad" using primary key columns */
  demiplane_ad_by_pk?: Maybe<DemiplaneAd>;
  /** fetch data from the table: "demiplane.ad_link" */
  demiplane_ad_link: Array<DemiplaneAdLink>;
  /** fetch aggregated fields from the table: "demiplane.ad_link" */
  demiplane_ad_link_aggregate: DemiplaneAdLinkAggregate;
  /** fetch data from the table: "demiplane.ad_link" using primary key columns */
  demiplane_ad_link_by_pk?: Maybe<DemiplaneAdLink>;
  /** fetch data from the table: "demiplane.ad_match_criteria" */
  demiplane_ad_match_criteria: Array<DemiplaneAdMatchCriteria>;
  /** fetch aggregated fields from the table: "demiplane.ad_match_criteria" */
  demiplane_ad_match_criteria_aggregate: DemiplaneAdMatchCriteriaAggregate;
  /** fetch data from the table: "demiplane.ad_match_criteria" using primary key columns */
  demiplane_ad_match_criteria_by_pk?: Maybe<DemiplaneAdMatchCriteria>;
  /** fetch data from the table: "demiplane.ad_match_criteria_time" */
  demiplane_ad_match_criteria_time: Array<DemiplaneAdMatchCriteriaTime>;
  /** fetch aggregated fields from the table: "demiplane.ad_match_criteria_time" */
  demiplane_ad_match_criteria_time_aggregate: DemiplaneAdMatchCriteriaTimeAggregate;
  /** fetch data from the table: "demiplane.ad_match_criteria_time" using primary key columns */
  demiplane_ad_match_criteria_time_by_pk?: Maybe<DemiplaneAdMatchCriteriaTime>;
  /** fetch data from the table: "demiplane.ad_request" */
  demiplane_ad_request: Array<DemiplaneAdRequest>;
  /** fetch aggregated fields from the table: "demiplane.ad_request" */
  demiplane_ad_request_aggregate: DemiplaneAdRequestAggregate;
  /** fetch data from the table: "demiplane.ad_request" using primary key columns */
  demiplane_ad_request_by_pk?: Maybe<DemiplaneAdRequest>;
  /** fetch data from the table: "demiplane.ad_time" */
  demiplane_ad_time: Array<DemiplaneAdTime>;
  /** fetch aggregated fields from the table: "demiplane.ad_time" */
  demiplane_ad_time_aggregate: DemiplaneAdTimeAggregate;
  /** fetch data from the table: "demiplane.ad_time" using primary key columns */
  demiplane_ad_time_by_pk?: Maybe<DemiplaneAdTime>;
  /** fetch data from the table: "demiplane.adventure" */
  demiplane_adventure: Array<DemiplaneAdventure>;
  /** fetch aggregated fields from the table: "demiplane.adventure" */
  demiplane_adventure_aggregate: DemiplaneAdventureAggregate;
  /** fetch data from the table: "demiplane.adventure" using primary key columns */
  demiplane_adventure_by_pk?: Maybe<DemiplaneAdventure>;
  /** fetch data from the table: "demiplane.adventure_connection" */
  demiplane_adventure_connection: Array<DemiplaneAdventureConnection>;
  /** fetch aggregated fields from the table: "demiplane.adventure_connection" */
  demiplane_adventure_connection_aggregate: DemiplaneAdventureConnectionAggregate;
  /** fetch data from the table: "demiplane.adventure_connection" using primary key columns */
  demiplane_adventure_connection_by_pk?: Maybe<DemiplaneAdventureConnection>;
  /** fetch data from the table: "demiplane.adventure_demiplane_tool" */
  demiplane_adventure_demiplane_tool: Array<DemiplaneAdventureDemiplaneTool>;
  /** fetch aggregated fields from the table: "demiplane.adventure_demiplane_tool" */
  demiplane_adventure_demiplane_tool_aggregate: DemiplaneAdventureDemiplaneToolAggregate;
  /** fetch data from the table: "demiplane.adventure_demiplane_tool" using primary key columns */
  demiplane_adventure_demiplane_tool_by_pk?: Maybe<DemiplaneAdventureDemiplaneTool>;
  /** fetch data from the table: "demiplane.adventure_link" */
  demiplane_adventure_link: Array<DemiplaneAdventureLink>;
  /** fetch aggregated fields from the table: "demiplane.adventure_link" */
  demiplane_adventure_link_aggregate: DemiplaneAdventureLinkAggregate;
  /** fetch data from the table: "demiplane.adventure_link" using primary key columns */
  demiplane_adventure_link_by_pk?: Maybe<DemiplaneAdventureLink>;
  /** fetch data from the table: "demiplane.adventure_player" */
  demiplane_adventure_player: Array<DemiplaneAdventurePlayer>;
  /** fetch aggregated fields from the table: "demiplane.adventure_player" */
  demiplane_adventure_player_aggregate: DemiplaneAdventurePlayerAggregate;
  /** fetch data from the table: "demiplane.adventure_player" using primary key columns */
  demiplane_adventure_player_by_pk?: Maybe<DemiplaneAdventurePlayer>;
  /** fetch data from the table: "demiplane.adventure_todo" */
  demiplane_adventure_todo: Array<DemiplaneAdventureTodo>;
  /** fetch aggregated fields from the table: "demiplane.adventure_todo" */
  demiplane_adventure_todo_aggregate: DemiplaneAdventureTodoAggregate;
  /** fetch data from the table: "demiplane.adventure_todo" using primary key columns */
  demiplane_adventure_todo_by_pk?: Maybe<DemiplaneAdventureTodo>;
  /** fetch data from the table: "demiplane.adventuring_platform" */
  demiplane_adventuring_platform: Array<DemiplaneAdventuringPlatform>;
  /** fetch aggregated fields from the table: "demiplane.adventuring_platform" */
  demiplane_adventuring_platform_aggregate: DemiplaneAdventuringPlatformAggregate;
  /** fetch data from the table: "demiplane.adventuring_platform" using primary key columns */
  demiplane_adventuring_platform_by_pk?: Maybe<DemiplaneAdventuringPlatform>;
  /** fetch data from the table: "demiplane.amc_adventuring_platforms" */
  demiplane_amc_adventuring_platforms: Array<DemiplaneAmcAdventuringPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.amc_adventuring_platforms" */
  demiplane_amc_adventuring_platforms_aggregate: DemiplaneAmcAdventuringPlatformsAggregate;
  /** fetch data from the table: "demiplane.amc_adventuring_platforms" using primary key columns */
  demiplane_amc_adventuring_platforms_by_pk?: Maybe<DemiplaneAmcAdventuringPlatforms>;
  /** fetch data from the table: "demiplane.amc_game_types" */
  demiplane_amc_game_types: Array<DemiplaneAmcGameTypes>;
  /** fetch aggregated fields from the table: "demiplane.amc_game_types" */
  demiplane_amc_game_types_aggregate: DemiplaneAmcGameTypesAggregate;
  /** fetch data from the table: "demiplane.amc_game_types" using primary key columns */
  demiplane_amc_game_types_by_pk?: Maybe<DemiplaneAmcGameTypes>;
  /** fetch data from the table: "demiplane.app_version" */
  demiplane_app_version: Array<DemiplaneAppVersion>;
  /** fetch aggregated fields from the table: "demiplane.app_version" */
  demiplane_app_version_aggregate: DemiplaneAppVersionAggregate;
  /** fetch data from the table: "demiplane.app_version" using primary key columns */
  demiplane_app_version_by_pk?: Maybe<DemiplaneAppVersion>;
  /** fetch data from the table: "demiplane.attachment" */
  demiplane_attachment: Array<DemiplaneAttachment>;
  /** fetch aggregated fields from the table: "demiplane.attachment" */
  demiplane_attachment_aggregate: DemiplaneAttachmentAggregate;
  /** fetch data from the table: "demiplane.attachment" using primary key columns */
  demiplane_attachment_by_pk?: Maybe<DemiplaneAttachment>;
  /** fetch data from the table: "demiplane.award_type" */
  demiplane_award_type: Array<DemiplaneAwardType>;
  /** fetch aggregated fields from the table: "demiplane.award_type" */
  demiplane_award_type_aggregate: DemiplaneAwardTypeAggregate;
  /** fetch data from the table: "demiplane.award_type" using primary key columns */
  demiplane_award_type_by_pk?: Maybe<DemiplaneAwardType>;
  /** fetch data from the table: "demiplane.connection_status" */
  demiplane_connection_status: Array<DemiplaneConnectionStatus>;
  /** fetch aggregated fields from the table: "demiplane.connection_status" */
  demiplane_connection_status_aggregate: DemiplaneConnectionStatusAggregate;
  /** fetch data from the table: "demiplane.connection_status" using primary key columns */
  demiplane_connection_status_by_pk?: Maybe<DemiplaneConnectionStatus>;
  /** fetch data from the table: "demiplane.connection_type" */
  demiplane_connection_type: Array<DemiplaneConnectionType>;
  /** fetch aggregated fields from the table: "demiplane.connection_type" */
  demiplane_connection_type_aggregate: DemiplaneConnectionTypeAggregate;
  /** fetch data from the table: "demiplane.connection_type" using primary key columns */
  demiplane_connection_type_by_pk?: Maybe<DemiplaneConnectionType>;
  /** fetch data from the table: "demiplane.contact_type" */
  demiplane_contact_type: Array<DemiplaneContactType>;
  /** fetch aggregated fields from the table: "demiplane.contact_type" */
  demiplane_contact_type_aggregate: DemiplaneContactTypeAggregate;
  /** fetch data from the table: "demiplane.contact_type" using primary key columns */
  demiplane_contact_type_by_pk?: Maybe<DemiplaneContactType>;
  /** fetch data from the table: "demiplane.country" */
  demiplane_country: Array<DemiplaneCountry>;
  /** fetch aggregated fields from the table: "demiplane.country" */
  demiplane_country_aggregate: DemiplaneCountryAggregate;
  /** fetch data from the table: "demiplane.country" using primary key columns */
  demiplane_country_by_pk?: Maybe<DemiplaneCountry>;
  /** fetch data from the table: "demiplane.credit_card" */
  demiplane_credit_card: Array<DemiplaneCreditCard>;
  /** fetch aggregated fields from the table: "demiplane.credit_card" */
  demiplane_credit_card_aggregate: DemiplaneCreditCardAggregate;
  /** fetch data from the table: "demiplane.credit_card" using primary key columns */
  demiplane_credit_card_by_pk?: Maybe<DemiplaneCreditCard>;
  /** fetch data from the table: "demiplane.demiplane_tool" */
  demiplane_demiplane_tool: Array<DemiplaneDemiplaneTool>;
  /** fetch aggregated fields from the table: "demiplane.demiplane_tool" */
  demiplane_demiplane_tool_aggregate: DemiplaneDemiplaneToolAggregate;
  /** fetch data from the table: "demiplane.demiplane_tool" using primary key columns */
  demiplane_demiplane_tool_by_pk?: Maybe<DemiplaneDemiplaneTool>;
  /** fetch data from the table: "demiplane.episode_attendance" */
  demiplane_episode_attendance: Array<DemiplaneEpisodeAttendance>;
  /** fetch aggregated fields from the table: "demiplane.episode_attendance" */
  demiplane_episode_attendance_aggregate: DemiplaneEpisodeAttendanceAggregate;
  /** fetch data from the table: "demiplane.episode_attendance" using primary key columns */
  demiplane_episode_attendance_by_pk?: Maybe<DemiplaneEpisodeAttendance>;
  /** fetch data from the table: "demiplane.event" */
  demiplane_event: Array<DemiplaneEvent>;
  /** fetch aggregated fields from the table: "demiplane.event" */
  demiplane_event_aggregate: DemiplaneEventAggregate;
  /** fetch data from the table: "demiplane.event" using primary key columns */
  demiplane_event_by_pk?: Maybe<DemiplaneEvent>;
  /** fetch data from the table: "demiplane.event_type" */
  demiplane_event_type: Array<DemiplaneEventType>;
  /** fetch aggregated fields from the table: "demiplane.event_type" */
  demiplane_event_type_aggregate: DemiplaneEventTypeAggregate;
  /** fetch data from the table: "demiplane.event_type" using primary key columns */
  demiplane_event_type_by_pk?: Maybe<DemiplaneEventType>;
  /** fetch data from the table: "demiplane.favorite_adventuring_platform" */
  demiplane_favorite_adventuring_platform: Array<DemiplaneFavoriteAdventuringPlatform>;
  /** fetch aggregated fields from the table: "demiplane.favorite_adventuring_platform" */
  demiplane_favorite_adventuring_platform_aggregate: DemiplaneFavoriteAdventuringPlatformAggregate;
  /** fetch data from the table: "demiplane.favorite_adventuring_platform" using primary key columns */
  demiplane_favorite_adventuring_platform_by_pk?: Maybe<DemiplaneFavoriteAdventuringPlatform>;
  /** fetch data from the table: "demiplane.favorite_dice_roll" */
  demiplane_favorite_dice_roll: Array<DemiplaneFavoriteDiceRoll>;
  /** fetch aggregated fields from the table: "demiplane.favorite_dice_roll" */
  demiplane_favorite_dice_roll_aggregate: DemiplaneFavoriteDiceRollAggregate;
  /** fetch data from the table: "demiplane.favorite_dice_roll" using primary key columns */
  demiplane_favorite_dice_roll_by_pk?: Maybe<DemiplaneFavoriteDiceRoll>;
  /** fetch data from the table: "demiplane.favorite_game" */
  demiplane_favorite_game: Array<DemiplaneFavoriteGame>;
  /** fetch aggregated fields from the table: "demiplane.favorite_game" */
  demiplane_favorite_game_aggregate: DemiplaneFavoriteGameAggregate;
  /** fetch data from the table: "demiplane.favorite_game" using primary key columns */
  demiplane_favorite_game_by_pk?: Maybe<DemiplaneFavoriteGame>;
  /** fetch data from the table: "demiplane.game_type" */
  demiplane_game_type: Array<DemiplaneGameType>;
  /** fetch aggregated fields from the table: "demiplane.game_type" */
  demiplane_game_type_aggregate: DemiplaneGameTypeAggregate;
  /** fetch data from the table: "demiplane.game_type" using primary key columns */
  demiplane_game_type_by_pk?: Maybe<DemiplaneGameType>;
  /** fetch data from the table: "demiplane.gm_rating" */
  demiplane_gm_rating: Array<DemiplaneGmRating>;
  /** fetch aggregated fields from the table: "demiplane.gm_rating" */
  demiplane_gm_rating_aggregate: DemiplaneGmRatingAggregate;
  /** fetch data from the table: "demiplane.gm_rating" using primary key columns */
  demiplane_gm_rating_by_pk?: Maybe<DemiplaneGmRating>;
  /** fetch data from the table: "demiplane.gm_recommendation" */
  demiplane_gm_recommendation: Array<DemiplaneGmRecommendation>;
  /** fetch aggregated fields from the table: "demiplane.gm_recommendation" */
  demiplane_gm_recommendation_aggregate: DemiplaneGmRecommendationAggregate;
  /** fetch data from the table: "demiplane.gm_recommendation" using primary key columns */
  demiplane_gm_recommendation_by_pk?: Maybe<DemiplaneGmRecommendation>;
  /** fetch data from the table: "demiplane.invitation" */
  demiplane_invitation: Array<DemiplaneInvitation>;
  /** fetch aggregated fields from the table: "demiplane.invitation" */
  demiplane_invitation_aggregate: DemiplaneInvitationAggregate;
  /** fetch data from the table: "demiplane.invitation" using primary key columns */
  demiplane_invitation_by_pk?: Maybe<DemiplaneInvitation>;
  /** fetch data from the table: "demiplane.journal" */
  demiplane_journal: Array<DemiplaneJournal>;
  /** fetch aggregated fields from the table: "demiplane.journal" */
  demiplane_journal_aggregate: DemiplaneJournalAggregate;
  /** fetch data from the table: "demiplane.journal" using primary key columns */
  demiplane_journal_by_pk?: Maybe<DemiplaneJournal>;
  /** fetch data from the table: "demiplane.journal_note" */
  demiplane_journal_note: Array<DemiplaneJournalNote>;
  /** fetch aggregated fields from the table: "demiplane.journal_note" */
  demiplane_journal_note_aggregate: DemiplaneJournalNoteAggregate;
  /** fetch data from the table: "demiplane.journal_note" using primary key columns */
  demiplane_journal_note_by_pk?: Maybe<DemiplaneJournalNote>;
  /** fetch data from the table: "demiplane.journal_note_share" */
  demiplane_journal_note_share: Array<DemiplaneJournalNoteShare>;
  /** fetch aggregated fields from the table: "demiplane.journal_note_share" */
  demiplane_journal_note_share_aggregate: DemiplaneJournalNoteShareAggregate;
  /** fetch data from the table: "demiplane.journal_note_share" using primary key columns */
  demiplane_journal_note_share_by_pk?: Maybe<DemiplaneJournalNoteShare>;
  /** fetch data from the table: "demiplane.journal_super_event" */
  demiplane_journal_super_event: Array<DemiplaneJournalSuperEvent>;
  /** fetch aggregated fields from the table: "demiplane.journal_super_event" */
  demiplane_journal_super_event_aggregate: DemiplaneJournalSuperEventAggregate;
  /** fetch data from the table: "demiplane.journal_super_event" using primary key columns */
  demiplane_journal_super_event_by_pk?: Maybe<DemiplaneJournalSuperEvent>;
  /** fetch data from the table: "demiplane.journal_super_event_read_status" */
  demiplane_journal_super_event_read_status: Array<DemiplaneJournalSuperEventReadStatus>;
  /** fetch aggregated fields from the table: "demiplane.journal_super_event_read_status" */
  demiplane_journal_super_event_read_status_aggregate: DemiplaneJournalSuperEventReadStatusAggregate;
  /** fetch data from the table: "demiplane.journal_super_event_read_status" using primary key columns */
  demiplane_journal_super_event_read_status_by_pk?: Maybe<DemiplaneJournalSuperEventReadStatus>;
  /** fetch data from the table: "demiplane.journal_title" */
  demiplane_journal_title: Array<DemiplaneJournalTitle>;
  /** fetch aggregated fields from the table: "demiplane.journal_title" */
  demiplane_journal_title_aggregate: DemiplaneJournalTitleAggregate;
  /** fetch data from the table: "demiplane.journal_title" using primary key columns */
  demiplane_journal_title_by_pk?: Maybe<DemiplaneJournalTitle>;
  /** fetch data from the table: "demiplane.matchmaking_token" */
  demiplane_matchmaking_token: Array<DemiplaneMatchmakingToken>;
  /** fetch aggregated fields from the table: "demiplane.matchmaking_token" */
  demiplane_matchmaking_token_aggregate: DemiplaneMatchmakingTokenAggregate;
  /** fetch data from the table: "demiplane.matchmaking_token" using primary key columns */
  demiplane_matchmaking_token_by_pk?: Maybe<DemiplaneMatchmakingToken>;
  /** fetch data from the table: "demiplane.migrations" */
  demiplane_migrations: Array<DemiplaneMigrations>;
  /** fetch aggregated fields from the table: "demiplane.migrations" */
  demiplane_migrations_aggregate: DemiplaneMigrationsAggregate;
  /** fetch data from the table: "demiplane.migrations" using primary key columns */
  demiplane_migrations_by_pk?: Maybe<DemiplaneMigrations>;
  /** fetch data from the table: "demiplane.notification_history" */
  demiplane_notification_history: Array<DemiplaneNotificationHistory>;
  /** fetch aggregated fields from the table: "demiplane.notification_history" */
  demiplane_notification_history_aggregate: DemiplaneNotificationHistoryAggregate;
  /** fetch data from the table: "demiplane.notification_history" using primary key columns */
  demiplane_notification_history_by_pk?: Maybe<DemiplaneNotificationHistory>;
  /** fetch data from the table: "demiplane.payment" */
  demiplane_payment: Array<DemiplanePayment>;
  /** fetch aggregated fields from the table: "demiplane.payment" */
  demiplane_payment_aggregate: DemiplanePaymentAggregate;
  /** fetch data from the table: "demiplane.payment" using primary key columns */
  demiplane_payment_by_pk?: Maybe<DemiplanePayment>;
  /** fetch data from the table: "demiplane.player_award" */
  demiplane_player_award: Array<DemiplanePlayerAward>;
  /** fetch aggregated fields from the table: "demiplane.player_award" */
  demiplane_player_award_aggregate: DemiplanePlayerAwardAggregate;
  /** fetch data from the table: "demiplane.player_award" using primary key columns */
  demiplane_player_award_by_pk?: Maybe<DemiplanePlayerAward>;
  /** fetch data from the table: "demiplane.player_rating" */
  demiplane_player_rating: Array<DemiplanePlayerRating>;
  /** fetch aggregated fields from the table: "demiplane.player_rating" */
  demiplane_player_rating_aggregate: DemiplanePlayerRatingAggregate;
  /** fetch data from the table: "demiplane.player_rating" using primary key columns */
  demiplane_player_rating_by_pk?: Maybe<DemiplanePlayerRating>;
  /** fetch data from the table: "demiplane.pronoun" */
  demiplane_pronoun: Array<DemiplanePronoun>;
  /** fetch aggregated fields from the table: "demiplane.pronoun" */
  demiplane_pronoun_aggregate: DemiplanePronounAggregate;
  /** fetch data from the table: "demiplane.pronoun" using primary key columns */
  demiplane_pronoun_by_pk?: Maybe<DemiplanePronoun>;
  /** fetch data from the table: "demiplane.region" */
  demiplane_region: Array<DemiplaneRegion>;
  /** fetch aggregated fields from the table: "demiplane.region" */
  demiplane_region_aggregate: DemiplaneRegionAggregate;
  /** fetch data from the table: "demiplane.region" using primary key columns */
  demiplane_region_by_pk?: Maybe<DemiplaneRegion>;
  /** fetch data from the table: "demiplane.role" */
  demiplane_role: Array<DemiplaneRole>;
  /** fetch aggregated fields from the table: "demiplane.role" */
  demiplane_role_aggregate: DemiplaneRoleAggregate;
  /** fetch data from the table: "demiplane.role" using primary key columns */
  demiplane_role_by_pk?: Maybe<DemiplaneRole>;
  /** fetch data from the table: "demiplane.session" */
  demiplane_session: Array<DemiplaneSession>;
  /** fetch aggregated fields from the table: "demiplane.session" */
  demiplane_session_aggregate: DemiplaneSessionAggregate;
  /** fetch data from the table: "demiplane.session" using primary key columns */
  demiplane_session_by_pk?: Maybe<DemiplaneSession>;
  /** fetch data from the table: "demiplane.session_player" */
  demiplane_session_player: Array<DemiplaneSessionPlayer>;
  /** fetch aggregated fields from the table: "demiplane.session_player" */
  demiplane_session_player_aggregate: DemiplaneSessionPlayerAggregate;
  /** fetch data from the table: "demiplane.session_player" using primary key columns */
  demiplane_session_player_by_pk?: Maybe<DemiplaneSessionPlayer>;
  /** fetch data from the table: "demiplane.snippet" */
  demiplane_snippet: Array<DemiplaneSnippet>;
  /** fetch aggregated fields from the table: "demiplane.snippet" */
  demiplane_snippet_aggregate: DemiplaneSnippetAggregate;
  /** fetch data from the table: "demiplane.snippet" using primary key columns */
  demiplane_snippet_by_pk?: Maybe<DemiplaneSnippet>;
  /** fetch data from the table: "demiplane.task" */
  demiplane_task: Array<DemiplaneTask>;
  /** fetch aggregated fields from the table: "demiplane.task" */
  demiplane_task_aggregate: DemiplaneTaskAggregate;
  /** fetch data from the table: "demiplane.task" using primary key columns */
  demiplane_task_by_pk?: Maybe<DemiplaneTask>;
  /** fetch data from the table: "demiplane.task_read_status" */
  demiplane_task_read_status: Array<DemiplaneTaskReadStatus>;
  /** fetch aggregated fields from the table: "demiplane.task_read_status" */
  demiplane_task_read_status_aggregate: DemiplaneTaskReadStatusAggregate;
  /** fetch data from the table: "demiplane.task_read_status" using primary key columns */
  demiplane_task_read_status_by_pk?: Maybe<DemiplaneTaskReadStatus>;
  /** fetch data from the table: "demiplane.tax_nexus_region" */
  demiplane_tax_nexus_region: Array<DemiplaneTaxNexusRegion>;
  /** fetch aggregated fields from the table: "demiplane.tax_nexus_region" */
  demiplane_tax_nexus_region_aggregate: DemiplaneTaxNexusRegionAggregate;
  /** fetch data from the table: "demiplane.tax_nexus_region" using primary key columns */
  demiplane_tax_nexus_region_by_pk?: Maybe<DemiplaneTaxNexusRegion>;
  /** fetch data from the table: "demiplane.transaction_receipt" */
  demiplane_transaction_receipt: Array<DemiplaneTransactionReceipt>;
  /** fetch aggregated fields from the table: "demiplane.transaction_receipt" */
  demiplane_transaction_receipt_aggregate: DemiplaneTransactionReceiptAggregate;
  /** fetch data from the table: "demiplane.transaction_receipt" using primary key columns */
  demiplane_transaction_receipt_by_pk?: Maybe<DemiplaneTransactionReceipt>;
  /** fetch data from the table: "demiplane.upload" */
  demiplane_upload: Array<DemiplaneUpload>;
  /** fetch aggregated fields from the table: "demiplane.upload" */
  demiplane_upload_aggregate: DemiplaneUploadAggregate;
  /** fetch data from the table: "demiplane.upload" using primary key columns */
  demiplane_upload_by_pk?: Maybe<DemiplaneUpload>;
  /** fetch data from the table: "demiplane.urap" */
  demiplane_urap: Array<DemiplaneUrap>;
  /** fetch aggregated fields from the table: "demiplane.urap" */
  demiplane_urap_aggregate: DemiplaneUrapAggregate;
  /** fetch data from the table: "demiplane.urap" using primary key columns */
  demiplane_urap_by_pk?: Maybe<DemiplaneUrap>;
  /** fetch data from the table: "demiplane.urgt" */
  demiplane_urgt: Array<DemiplaneUrgt>;
  /** fetch aggregated fields from the table: "demiplane.urgt" */
  demiplane_urgt_aggregate: DemiplaneUrgtAggregate;
  /** fetch data from the table: "demiplane.urgt" using primary key columns */
  demiplane_urgt_by_pk?: Maybe<DemiplaneUrgt>;
  /** fetch data from the table: "demiplane.user" */
  demiplane_user: Array<DemiplaneUser>;
  /** fetch data from the table: "demiplane.user_activity" */
  demiplane_user_activity: Array<DemiplaneUserActivity>;
  /** fetch aggregated fields from the table: "demiplane.user_activity" */
  demiplane_user_activity_aggregate: DemiplaneUserActivityAggregate;
  /** fetch data from the table: "demiplane.user_activity" using primary key columns */
  demiplane_user_activity_by_pk?: Maybe<DemiplaneUserActivity>;
  /** fetch data from the table: "demiplane.user_adventure" */
  demiplane_user_adventure: Array<DemiplaneUserAdventure>;
  /** fetch aggregated fields from the table: "demiplane.user_adventure" */
  demiplane_user_adventure_aggregate: DemiplaneUserAdventureAggregate;
  /** fetch data from the table: "demiplane.user_adventure" using primary key columns */
  demiplane_user_adventure_by_pk?: Maybe<DemiplaneUserAdventure>;
  /** fetch data from the table: "demiplane.user_adventure_ranking" */
  demiplane_user_adventure_ranking: Array<DemiplaneUserAdventureRanking>;
  /** fetch aggregated fields from the table: "demiplane.user_adventure_ranking" */
  demiplane_user_adventure_ranking_aggregate: DemiplaneUserAdventureRankingAggregate;
  /** fetch data from the table: "demiplane.user_adventure_ranking" using primary key columns */
  demiplane_user_adventure_ranking_by_pk?: Maybe<DemiplaneUserAdventureRanking>;
  /** fetch data from the table: "demiplane.user_adventure_role" */
  demiplane_user_adventure_role: Array<DemiplaneUserAdventureRole>;
  /** fetch aggregated fields from the table: "demiplane.user_adventure_role" */
  demiplane_user_adventure_role_aggregate: DemiplaneUserAdventureRoleAggregate;
  /** fetch data from the table: "demiplane.user_adventure_role" using primary key columns */
  demiplane_user_adventure_role_by_pk?: Maybe<DemiplaneUserAdventureRole>;
  /** fetch aggregated fields from the table: "demiplane.user" */
  demiplane_user_aggregate: DemiplaneUserAggregate;
  /** fetch data from the table: "demiplane.user" using primary key columns */
  demiplane_user_by_pk?: Maybe<DemiplaneUser>;
  /** fetch data from the table: "demiplane.user_email_notification_pref" */
  demiplane_user_email_notification_pref: Array<DemiplaneUserEmailNotificationPref>;
  /** fetch aggregated fields from the table: "demiplane.user_email_notification_pref" */
  demiplane_user_email_notification_pref_aggregate: DemiplaneUserEmailNotificationPrefAggregate;
  /** fetch data from the table: "demiplane.user_email_notification_pref" using primary key columns */
  demiplane_user_email_notification_pref_by_pk?: Maybe<DemiplaneUserEmailNotificationPref>;
  /** fetch data from the table: "demiplane.user_invitation" */
  demiplane_user_invitation: Array<DemiplaneUserInvitation>;
  /** fetch aggregated fields from the table: "demiplane.user_invitation" */
  demiplane_user_invitation_aggregate: DemiplaneUserInvitationAggregate;
  /** fetch data from the table: "demiplane.user_invitation" using primary key columns */
  demiplane_user_invitation_by_pk?: Maybe<DemiplaneUserInvitation>;
  /** fetch data from the table: "demiplane.user_metadata" */
  demiplane_user_metadata: Array<DemiplaneUserMetadata>;
  /** fetch aggregated fields from the table: "demiplane.user_metadata" */
  demiplane_user_metadata_aggregate: DemiplaneUserMetadataAggregate;
  /** fetch data from the table: "demiplane.user_metadata" using primary key columns */
  demiplane_user_metadata_by_pk?: Maybe<DemiplaneUserMetadata>;
  /** fetch data from the table: "demiplane.user_push_notification_pref" */
  demiplane_user_push_notification_pref: Array<DemiplaneUserPushNotificationPref>;
  /** fetch aggregated fields from the table: "demiplane.user_push_notification_pref" */
  demiplane_user_push_notification_pref_aggregate: DemiplaneUserPushNotificationPrefAggregate;
  /** fetch data from the table: "demiplane.user_push_notification_pref" using primary key columns */
  demiplane_user_push_notification_pref_by_pk?: Maybe<DemiplaneUserPushNotificationPref>;
  /** fetch data from the table: "demiplane.user_recruitment" */
  demiplane_user_recruitment: Array<DemiplaneUserRecruitment>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer" */
  demiplane_user_recruitment_adventurer: Array<DemiplaneUserRecruitmentAdventurer>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer" */
  demiplane_user_recruitment_adventurer_aggregate: DemiplaneUserRecruitmentAdventurerAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_availability" */
  demiplane_user_recruitment_adventurer_availability: Array<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer_availability" */
  demiplane_user_recruitment_adventurer_availability_aggregate: DemiplaneUserRecruitmentAdventurerAvailabilityAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_availability" using primary key columns */
  demiplane_user_recruitment_adventurer_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer" using primary key columns */
  demiplane_user_recruitment_adventurer_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurer>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_games" */
  demiplane_user_recruitment_adventurer_games: Array<DemiplaneUserRecruitmentAdventurerGames>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer_games" */
  demiplane_user_recruitment_adventurer_games_aggregate: DemiplaneUserRecruitmentAdventurerGamesAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_games" using primary key columns */
  demiplane_user_recruitment_adventurer_games_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerGames>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_platforms" */
  demiplane_user_recruitment_adventurer_platforms: Array<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer_platforms" */
  demiplane_user_recruitment_adventurer_platforms_aggregate: DemiplaneUserRecruitmentAdventurerPlatformsAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_platforms" using primary key columns */
  demiplane_user_recruitment_adventurer_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment" */
  demiplane_user_recruitment_aggregate: DemiplaneUserRecruitmentAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_availability" */
  demiplane_user_recruitment_availability: Array<DemiplaneUserRecruitmentAvailability>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_availability" */
  demiplane_user_recruitment_availability_aggregate: DemiplaneUserRecruitmentAvailabilityAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_availability" using primary key columns */
  demiplane_user_recruitment_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAvailability>;
  /** fetch data from the table: "demiplane.user_recruitment" using primary key columns */
  demiplane_user_recruitment_by_pk?: Maybe<DemiplaneUserRecruitment>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master" */
  demiplane_user_recruitment_game_master: Array<DemiplaneUserRecruitmentGameMaster>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master" */
  demiplane_user_recruitment_game_master_aggregate: DemiplaneUserRecruitmentGameMasterAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_availability" */
  demiplane_user_recruitment_game_master_availability: Array<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master_availability" */
  demiplane_user_recruitment_game_master_availability_aggregate: DemiplaneUserRecruitmentGameMasterAvailabilityAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_availability" using primary key columns */
  demiplane_user_recruitment_game_master_availability_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master" using primary key columns */
  demiplane_user_recruitment_game_master_by_pk?: Maybe<DemiplaneUserRecruitmentGameMaster>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_games" */
  demiplane_user_recruitment_game_master_games: Array<DemiplaneUserRecruitmentGameMasterGames>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master_games" */
  demiplane_user_recruitment_game_master_games_aggregate: DemiplaneUserRecruitmentGameMasterGamesAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_games" using primary key columns */
  demiplane_user_recruitment_game_master_games_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterGames>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_platforms" */
  demiplane_user_recruitment_game_master_platforms: Array<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master_platforms" */
  demiplane_user_recruitment_game_master_platforms_aggregate: DemiplaneUserRecruitmentGameMasterPlatformsAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_platforms" using primary key columns */
  demiplane_user_recruitment_game_master_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** fetch data from the table: "demiplane.user_role" */
  demiplane_user_role: Array<DemiplaneUserRole>;
  /** fetch aggregated fields from the table: "demiplane.user_role" */
  demiplane_user_role_aggregate: DemiplaneUserRoleAggregate;
  /** fetch data from the table: "demiplane.user_role" using primary key columns */
  demiplane_user_role_by_pk?: Maybe<DemiplaneUserRole>;
  /** fetch data from the table: "demiplane.user_visibility" */
  demiplane_user_visibility: Array<DemiplaneUserVisibility>;
  /** fetch aggregated fields from the table: "demiplane.user_visibility" */
  demiplane_user_visibility_aggregate: DemiplaneUserVisibilityAggregate;
  /** fetch data from the table: "demiplane.user_visibility" using primary key columns */
  demiplane_user_visibility_by_pk?: Maybe<DemiplaneUserVisibility>;
  earnings: EarningsType;
  emailExists: Scalars['Boolean'];
  favoriteDiceRolls: Array<FavoriteDiceRollType>;
  /** perform the action: "featuredGmRecommendationReviews" */
  featuredGmRecommendationReviews?: Maybe<FeaturedGmRecommendationReviewsOutput>;
  freeAds: Array<SearchAdResultType>;
  gameTypes: Array<GameTypeType>;
  getAdventureByLinkCode: AdventureType;
  getAdventureRecruits: Array<RecruitType>;
  getQuestRecruitSummary: RecruitSummaryType;
  /** perform the action: "howUserPlays" */
  howUserPlays?: Maybe<HowUserPlaysOutput>;
  /** perform the action: "joinAdventuresAds" */
  joinAdventuresAds?: Maybe<JoinAdventuresAdsOutput>;
  journalNote: JournalNoteType;
  myAdventures: MyAdventuresType;
  newestAds: Array<SearchAdResultType>;
  notificationHistory: Array<NotificationHistoryType>;
  /** perform the action: "otherAdventuresAds" */
  otherAdventuresAds?: Maybe<OtherAdventuresAdsOutput>;
  paymentAdventureHosts: Array<AdventureType>;
  paymentsByUserSession: Array<PaymentType>;
  playingSoonAds: Array<SearchAdResultType>;
  /** perform the action: "recruitAdventurers" */
  recruitAdventurers?: Maybe<RecruitAdventurersOutput>;
  /** perform the action: "recruitGameMasters" */
  recruitGameMasters?: Maybe<RecruitGameMastersOutput>;
  regionsByCountryAbbreviation: Array<RegionType>;
  searchAdDetail: SearchAdResultDetailType;
  /** perform the action: "searchAdDetailMM" */
  searchAdDetailMM?: Maybe<SearchAdDetailMmOutput>;
  searchAds: Array<SearchAdResultType>;
  /** perform the action: "searchAdsMM" */
  searchAdsMM?: Maybe<SearchAdsMmOutput>;
  session: SessionType;
  sessionHistories: Array<SessionHistoryType>;
  snippet: SnippetType;
  stripeConnectUrl: StripeLoginLinkType;
  stripeUserBalance: StripeUserBalanceType;
  stripeUserBalanceTransactions: Array<StripeBalanceTransactionType>;
  suggestedAds: Array<SearchAdResultType>;
  systemStatus: StatusType;
  /** perform the action: "transactionDetail" */
  transactionDetail?: Maybe<TransactionDetailOutput>;
  /** perform the action: "transactionHistory" */
  transactionHistory?: Maybe<TransactionHistoryOutput>;
  upload?: Maybe<UploadType>;
  userAdventurePlayers: Array<AdventurePlayerType>;
  /** perform the action: "userAdventureRatings" */
  userAdventureRatings?: Maybe<UserAdventureRatingsOutput>;
  userConversations: UserConversationsType;
  userEmailNotificationPref: UserEmailNotificationPrefType;
  userPlayTime: UserPlayTimeType;
  userProfile: UserProfileType;
  userPushNotificationPref: UserPushNotificationPrefType;
  userRatingsAndAwards: UserRatingsAndAwardsType;
  userRecruitmentByUserId?: Maybe<UserRecruitmentType>;
  /** perform the action: "userSocialProfiles" */
  userSocialProfiles?: Maybe<UserSocialProfilesOutput>;
  /** perform the action: "userTreasureTrove" */
  userTreasureTrove?: Maybe<UserTreasureTroveOutput>;
  usernameExists: Scalars['Boolean'];
  validateUserAddress: UserAddressType;
};


/** query root */
export type QueryRootAboutUserArgs = {
  userId: Scalars['bigint'];
};


/** query root */
export type QueryRootAdDetailArgs = {
  adId: Scalars['Int'];
};


/** query root */
export type QueryRootAdventureArgs = {
  id: Scalars['String'];
};


/** query root */
export type QueryRootAdventureDetailsArgs = {
  adventureId: Scalars['String'];
};


/** query root */
export type QueryRootConversationParticipantsArgs = {
  chatHash: Scalars['String'];
};


/** query root */
export type QueryRootDemiplaneAdArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdLinkArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdLinkOrderBy>>;
  where?: Maybe<DemiplaneAdLinkBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdLinkAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdLinkOrderBy>>;
  where?: Maybe<DemiplaneAdLinkBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdLinkByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdMatchCriteriaArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdMatchCriteriaAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdMatchCriteriaByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdMatchCriteriaTimeArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaTimeOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdMatchCriteriaTimeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaTimeOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdMatchCriteriaTimeByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdRequestArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdRequestAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdRequestByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdTimeArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdTimeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdTimeByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdventureArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdventureConnectionArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureConnectionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureConnectionByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdventureDemiplaneToolArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneAdventureDemiplaneToolBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureDemiplaneToolAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneAdventureDemiplaneToolBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureDemiplaneToolByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdventureLinkArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureLinkOrderBy>>;
  where?: Maybe<DemiplaneAdventureLinkBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureLinkAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureLinkOrderBy>>;
  where?: Maybe<DemiplaneAdventureLinkBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureLinkByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdventurePlayerArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventurePlayerAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventurePlayerByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdventureTodoArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureTodoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureTodoOrderBy>>;
  where?: Maybe<DemiplaneAdventureTodoBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureTodoAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureTodoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureTodoOrderBy>>;
  where?: Maybe<DemiplaneAdventureTodoBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventureTodoByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAdventuringPlatformArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventuringPlatformAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAdventuringPlatformByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAmcAdventuringPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAmcAdventuringPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAmcAdventuringPlatformsByPkArgs = {
  adMatchCriteriaId: Scalars['bigint'];
  adventuringPlatformId: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAmcGameTypesArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAmcGameTypesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAmcGameTypesByPkArgs = {
  adMatchCriteriaId: Scalars['bigint'];
  gameTypeId: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAppVersionArgs = {
  distinct_on?: Maybe<Array<DemiplaneAppVersionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAppVersionOrderBy>>;
  where?: Maybe<DemiplaneAppVersionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAppVersionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAppVersionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAppVersionOrderBy>>;
  where?: Maybe<DemiplaneAppVersionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAppVersionByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAttachmentArgs = {
  distinct_on?: Maybe<Array<DemiplaneAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAttachmentOrderBy>>;
  where?: Maybe<DemiplaneAttachmentBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAttachmentOrderBy>>;
  where?: Maybe<DemiplaneAttachmentBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAttachmentByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneAwardTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneAwardTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAwardTypeOrderBy>>;
  where?: Maybe<DemiplaneAwardTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAwardTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAwardTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAwardTypeOrderBy>>;
  where?: Maybe<DemiplaneAwardTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneAwardTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneConnectionStatusArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionStatusOrderBy>>;
  where?: Maybe<DemiplaneConnectionStatusBoolExp>;
};


/** query root */
export type QueryRootDemiplaneConnectionStatusAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionStatusOrderBy>>;
  where?: Maybe<DemiplaneConnectionStatusBoolExp>;
};


/** query root */
export type QueryRootDemiplaneConnectionStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneConnectionTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionTypeOrderBy>>;
  where?: Maybe<DemiplaneConnectionTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneConnectionTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionTypeOrderBy>>;
  where?: Maybe<DemiplaneConnectionTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneConnectionTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneContactTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneContactTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneContactTypeOrderBy>>;
  where?: Maybe<DemiplaneContactTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneContactTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneContactTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneContactTypeOrderBy>>;
  where?: Maybe<DemiplaneContactTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneContactTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneCountryArgs = {
  distinct_on?: Maybe<Array<DemiplaneCountrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCountryOrderBy>>;
  where?: Maybe<DemiplaneCountryBoolExp>;
};


/** query root */
export type QueryRootDemiplaneCountryAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneCountrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCountryOrderBy>>;
  where?: Maybe<DemiplaneCountryBoolExp>;
};


/** query root */
export type QueryRootDemiplaneCountryByPkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type QueryRootDemiplaneCreditCardArgs = {
  distinct_on?: Maybe<Array<DemiplaneCreditCardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCreditCardOrderBy>>;
  where?: Maybe<DemiplaneCreditCardBoolExp>;
};


/** query root */
export type QueryRootDemiplaneCreditCardAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneCreditCardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCreditCardOrderBy>>;
  where?: Maybe<DemiplaneCreditCardBoolExp>;
};


/** query root */
export type QueryRootDemiplaneCreditCardByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneDemiplaneToolArgs = {
  distinct_on?: Maybe<Array<DemiplaneDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneDemiplaneToolBoolExp>;
};


/** query root */
export type QueryRootDemiplaneDemiplaneToolAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneDemiplaneToolBoolExp>;
};


/** query root */
export type QueryRootDemiplaneDemiplaneToolByPkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type QueryRootDemiplaneEpisodeAttendanceArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** query root */
export type QueryRootDemiplaneEpisodeAttendanceAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** query root */
export type QueryRootDemiplaneEpisodeAttendanceByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneEventArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};


/** query root */
export type QueryRootDemiplaneEventAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};


/** query root */
export type QueryRootDemiplaneEventByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneEventTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventTypeOrderBy>>;
  where?: Maybe<DemiplaneEventTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneEventTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventTypeOrderBy>>;
  where?: Maybe<DemiplaneEventTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneEventTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneFavoriteAdventuringPlatformArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** query root */
export type QueryRootDemiplaneFavoriteAdventuringPlatformAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** query root */
export type QueryRootDemiplaneFavoriteAdventuringPlatformByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneFavoriteDiceRollArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteDiceRollSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteDiceRollOrderBy>>;
  where?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
};


/** query root */
export type QueryRootDemiplaneFavoriteDiceRollAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteDiceRollSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteDiceRollOrderBy>>;
  where?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
};


/** query root */
export type QueryRootDemiplaneFavoriteDiceRollByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneFavoriteGameArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** query root */
export type QueryRootDemiplaneFavoriteGameAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** query root */
export type QueryRootDemiplaneFavoriteGameByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneGameTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneGameTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGameTypeOrderBy>>;
  where?: Maybe<DemiplaneGameTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneGameTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGameTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGameTypeOrderBy>>;
  where?: Maybe<DemiplaneGameTypeBoolExp>;
};


/** query root */
export type QueryRootDemiplaneGameTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneGmRatingArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** query root */
export type QueryRootDemiplaneGmRatingAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** query root */
export type QueryRootDemiplaneGmRatingByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneGmRecommendationArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** query root */
export type QueryRootDemiplaneGmRecommendationAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** query root */
export type QueryRootDemiplaneGmRecommendationByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneInvitationArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** query root */
export type QueryRootDemiplaneInvitationAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** query root */
export type QueryRootDemiplaneInvitationByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneJournalArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalOrderBy>>;
  where?: Maybe<DemiplaneJournalBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalOrderBy>>;
  where?: Maybe<DemiplaneJournalBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneJournalNoteArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalNoteAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalNoteByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneJournalNoteShareArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalNoteShareAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalNoteShareByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneJournalSuperEventArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalSuperEventAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalSuperEventByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneJournalSuperEventReadStatusArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalSuperEventReadStatusAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalSuperEventReadStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneJournalTitleArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalTitleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneJournalTitleByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneMatchmakingTokenArgs = {
  distinct_on?: Maybe<Array<DemiplaneMatchmakingTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMatchmakingTokenOrderBy>>;
  where?: Maybe<DemiplaneMatchmakingTokenBoolExp>;
};


/** query root */
export type QueryRootDemiplaneMatchmakingTokenAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneMatchmakingTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMatchmakingTokenOrderBy>>;
  where?: Maybe<DemiplaneMatchmakingTokenBoolExp>;
};


/** query root */
export type QueryRootDemiplaneMatchmakingTokenByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneMigrationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneMigrationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMigrationsOrderBy>>;
  where?: Maybe<DemiplaneMigrationsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneMigrationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneMigrationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMigrationsOrderBy>>;
  where?: Maybe<DemiplaneMigrationsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneMigrationsByPkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type QueryRootDemiplaneNotificationHistoryArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** query root */
export type QueryRootDemiplaneNotificationHistoryAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** query root */
export type QueryRootDemiplaneNotificationHistoryByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplanePaymentArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** query root */
export type QueryRootDemiplanePaymentAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** query root */
export type QueryRootDemiplanePaymentByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplanePlayerAwardArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** query root */
export type QueryRootDemiplanePlayerAwardAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** query root */
export type QueryRootDemiplanePlayerAwardByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplanePlayerRatingArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** query root */
export type QueryRootDemiplanePlayerRatingAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** query root */
export type QueryRootDemiplanePlayerRatingByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplanePronounArgs = {
  distinct_on?: Maybe<Array<DemiplanePronounSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePronounOrderBy>>;
  where?: Maybe<DemiplanePronounBoolExp>;
};


/** query root */
export type QueryRootDemiplanePronounAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePronounSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePronounOrderBy>>;
  where?: Maybe<DemiplanePronounBoolExp>;
};


/** query root */
export type QueryRootDemiplanePronounByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneRegionArgs = {
  distinct_on?: Maybe<Array<DemiplaneRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRegionOrderBy>>;
  where?: Maybe<DemiplaneRegionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneRegionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRegionOrderBy>>;
  where?: Maybe<DemiplaneRegionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneRegionByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneRoleArgs = {
  distinct_on?: Maybe<Array<DemiplaneRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRoleOrderBy>>;
  where?: Maybe<DemiplaneRoleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneRoleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRoleOrderBy>>;
  where?: Maybe<DemiplaneRoleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneRoleByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneSessionArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionOrderBy>>;
  where?: Maybe<DemiplaneSessionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneSessionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionOrderBy>>;
  where?: Maybe<DemiplaneSessionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneSessionByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneSessionPlayerArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};


/** query root */
export type QueryRootDemiplaneSessionPlayerAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};


/** query root */
export type QueryRootDemiplaneSessionPlayerByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneSnippetArgs = {
  distinct_on?: Maybe<Array<DemiplaneSnippetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSnippetOrderBy>>;
  where?: Maybe<DemiplaneSnippetBoolExp>;
};


/** query root */
export type QueryRootDemiplaneSnippetAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSnippetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSnippetOrderBy>>;
  where?: Maybe<DemiplaneSnippetBoolExp>;
};


/** query root */
export type QueryRootDemiplaneSnippetByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneTaskArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTaskAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTaskByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneTaskReadStatusArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTaskReadStatusAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTaskReadStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneTaxNexusRegionArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaxNexusRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaxNexusRegionOrderBy>>;
  where?: Maybe<DemiplaneTaxNexusRegionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTaxNexusRegionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaxNexusRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaxNexusRegionOrderBy>>;
  where?: Maybe<DemiplaneTaxNexusRegionBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTaxNexusRegionByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneTransactionReceiptArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTransactionReceiptAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** query root */
export type QueryRootDemiplaneTransactionReceiptByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUploadArgs = {
  distinct_on?: Maybe<Array<DemiplaneUploadSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUploadOrderBy>>;
  where?: Maybe<DemiplaneUploadBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUploadAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUploadSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUploadOrderBy>>;
  where?: Maybe<DemiplaneUploadBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUploadByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUrapArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUrapAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUrapByPkArgs = {
  adventuringPlatformId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUrgtArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUrgtAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUrgtByPkArgs = {
  gameTypeId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserActivityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserActivityOrderBy>>;
  where?: Maybe<DemiplaneUserActivityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserActivityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserActivityOrderBy>>;
  where?: Maybe<DemiplaneUserActivityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserActivityByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserAdventureArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserAdventureAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserAdventureByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserAdventureRankingArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserAdventureRankingAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserAdventureRankingByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserAdventureRoleArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserAdventureRoleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserAdventureRoleByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserEmailNotificationPrefArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserEmailNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserEmailNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserEmailNotificationPrefBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserEmailNotificationPrefAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserEmailNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserEmailNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserEmailNotificationPrefBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserEmailNotificationPrefByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserInvitationArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserInvitationAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserInvitationByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserMetadataArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserMetadataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserMetadataOrderBy>>;
  where?: Maybe<DemiplaneUserMetadataBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserMetadataAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserMetadataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserMetadataOrderBy>>;
  where?: Maybe<DemiplaneUserMetadataBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserMetadataByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserPushNotificationPrefArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserPushNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserPushNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserPushNotificationPrefBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserPushNotificationPrefAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserPushNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserPushNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserPushNotificationPrefBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserPushNotificationPrefByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerAvailabilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerGamesByPkArgs = {
  game_type_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAvailabilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterAvailabilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterGamesByPkArgs = {
  game_type_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserRoleArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRoleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserRoleByPkArgs = {
  roleId: Scalars['bigint'];
  userId: Scalars['bigint'];
};


/** query root */
export type QueryRootDemiplaneUserVisibilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserVisibilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserVisibilityOrderBy>>;
  where?: Maybe<DemiplaneUserVisibilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserVisibilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserVisibilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserVisibilityOrderBy>>;
  where?: Maybe<DemiplaneUserVisibilityBoolExp>;
};


/** query root */
export type QueryRootDemiplaneUserVisibilityByPkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type QueryRootEmailExistsArgs = {
  email: Scalars['String'];
};


/** query root */
export type QueryRootFavoriteDiceRollsArgs = {
  adventureId: Scalars['String'];
};


/** query root */
export type QueryRootFeaturedGmRecommendationReviewsArgs = {
  userId: Scalars['bigint'];
};


/** query root */
export type QueryRootGetAdventureByLinkCodeArgs = {
  code: Scalars['String'];
};


/** query root */
export type QueryRootGetAdventureRecruitsArgs = {
  adventureId: Scalars['String'];
  displayCount: Scalars['Float'];
  excludeRecruited: Scalars['Boolean'];
  recruitForGameMaster: Scalars['Boolean'];
};


/** query root */
export type QueryRootGetQuestRecruitSummaryArgs = {
  questId: Scalars['String'];
};


/** query root */
export type QueryRootHowUserPlaysArgs = {
  userId: Scalars['bigint'];
};


/** query root */
export type QueryRootJoinAdventuresAdsArgs = {
  adUserId: Scalars['Int'];
  resultLimit?: Maybe<Scalars['Int']>;
};


/** query root */
export type QueryRootJournalNoteArgs = {
  adventureId: Scalars['String'];
  id: Scalars['String'];
  isShared: Scalars['Boolean'];
};


/** query root */
export type QueryRootNotificationHistoryArgs = {
  limit: Scalars['Float'];
};


/** query root */
export type QueryRootOtherAdventuresAdsArgs = {
  resultLimit?: Maybe<Scalars['Int']>;
};


/** query root */
export type QueryRootPaymentsByUserSessionArgs = {
  sessionId: Scalars['String'];
  userId: Scalars['String'];
};


/** query root */
export type QueryRootRecruitAdventurersArgs = {
  displayCount: Scalars['Int'];
  excludeRecruited: Scalars['Boolean'];
  portalId: Scalars['String'];
};


/** query root */
export type QueryRootRecruitGameMastersArgs = {
  displayCount: Scalars['Int'];
  excludeRecruited: Scalars['Boolean'];
  portalId: Scalars['String'];
};


/** query root */
export type QueryRootRegionsByCountryAbbreviationArgs = {
  countryCode: Scalars['String'];
};


/** query root */
export type QueryRootSearchAdDetailArgs = {
  adId: Scalars['String'];
};


/** query root */
export type QueryRootSearchAdDetailMmArgs = {
  adId: Scalars['Int'];
};


/** query root */
export type QueryRootSearchAdsArgs = {
  adventuringPlatformIds: Array<Maybe<Scalars['String']>>;
  availability: Array<Maybe<AvailabilityInput>>;
  frequencyOneTime: Scalars['Boolean'];
  frequencyRecurring: Scalars['Boolean'];
  gameTypeIds: Array<Maybe<Scalars['String']>>;
  newPlayer: Scalars['Boolean'];
  noCost: Scalars['Boolean'];
  quickMatch: Scalars['Boolean'];
  recordCount: Scalars['Float'];
};


/** query root */
export type QueryRootSearchAdsMmArgs = {
  adCreator?: Maybe<Array<Scalars['String']>>;
  adventuringPlatformIds: Array<Scalars['Int']>;
  availability: Array<AvailabilityInputMm>;
  costMax?: Maybe<Scalars['Int']>;
  frequencyOneTime: Scalars['Boolean'];
  frequencyRecurring: Scalars['Boolean'];
  gameMaster?: Maybe<Array<Scalars['String']>>;
  gameTypeIds: Array<Scalars['Int']>;
  newPlayer: Scalars['Boolean'];
  noCost: Scalars['Boolean'];
  numSeatsOpenMax?: Maybe<Scalars['Int']>;
  numSeatsOpenMin?: Maybe<Scalars['Int']>;
  quickMatch: Scalars['Boolean'];
  recordCount: Scalars['Int'];
  sortBy?: Maybe<Array<Scalars['String']>>;
};


/** query root */
export type QueryRootSessionArgs = {
  id: Scalars['String'];
};


/** query root */
export type QueryRootSnippetArgs = {
  name: Scalars['String'];
};


/** query root */
export type QueryRootStripeUserBalanceTransactionsArgs = {
  createdAfter?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};


/** query root */
export type QueryRootTransactionDetailArgs = {
  episodeId: Scalars['String'];
};


/** query root */
export type QueryRootTransactionHistoryArgs = {
  sortBy: Scalars['String'];
};


/** query root */
export type QueryRootUploadArgs = {
  adventurePlayerId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};


/** query root */
export type QueryRootUserAdventureRatingsArgs = {
  userId: Scalars['bigint'];
};


/** query root */
export type QueryRootUserPlayTimeArgs = {
  userId: Scalars['String'];
};


/** query root */
export type QueryRootUserProfileArgs = {
  userId: Scalars['String'];
};


/** query root */
export type QueryRootUserRatingsAndAwardsArgs = {
  userId: Scalars['String'];
};


/** query root */
export type QueryRootUserRecruitmentByUserIdArgs = {
  userId: Scalars['String'];
};


/** query root */
export type QueryRootUserSocialProfilesArgs = {
  userId: Scalars['bigint'];
};


/** query root */
export type QueryRootUserTreasureTroveArgs = {
  userId: Scalars['bigint'];
};


/** query root */
export type QueryRootUsernameExistsArgs = {
  username: Scalars['String'];
};


/** query root */
export type QueryRootValidateUserAddressArgs = {
  userAddress: UserAddressInput;
};

/** subscription root */
export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** perform the action: "aboutUser" */
  aboutUser?: Maybe<AboutUserOutput>;
  /** perform the action: "adDetail" */
  adDetail?: Maybe<AdDetailOutput>;
  adventurePlayerRemoved: AdventureNotificationType;
  /** fetch data from the table: "demiplane.ad" */
  demiplane_ad: Array<DemiplaneAd>;
  /** fetch aggregated fields from the table: "demiplane.ad" */
  demiplane_ad_aggregate: DemiplaneAdAggregate;
  /** fetch data from the table: "demiplane.ad" using primary key columns */
  demiplane_ad_by_pk?: Maybe<DemiplaneAd>;
  /** fetch data from the table: "demiplane.ad_link" */
  demiplane_ad_link: Array<DemiplaneAdLink>;
  /** fetch aggregated fields from the table: "demiplane.ad_link" */
  demiplane_ad_link_aggregate: DemiplaneAdLinkAggregate;
  /** fetch data from the table: "demiplane.ad_link" using primary key columns */
  demiplane_ad_link_by_pk?: Maybe<DemiplaneAdLink>;
  /** fetch data from the table: "demiplane.ad_match_criteria" */
  demiplane_ad_match_criteria: Array<DemiplaneAdMatchCriteria>;
  /** fetch aggregated fields from the table: "demiplane.ad_match_criteria" */
  demiplane_ad_match_criteria_aggregate: DemiplaneAdMatchCriteriaAggregate;
  /** fetch data from the table: "demiplane.ad_match_criteria" using primary key columns */
  demiplane_ad_match_criteria_by_pk?: Maybe<DemiplaneAdMatchCriteria>;
  /** fetch data from the table: "demiplane.ad_match_criteria_time" */
  demiplane_ad_match_criteria_time: Array<DemiplaneAdMatchCriteriaTime>;
  /** fetch aggregated fields from the table: "demiplane.ad_match_criteria_time" */
  demiplane_ad_match_criteria_time_aggregate: DemiplaneAdMatchCriteriaTimeAggregate;
  /** fetch data from the table: "demiplane.ad_match_criteria_time" using primary key columns */
  demiplane_ad_match_criteria_time_by_pk?: Maybe<DemiplaneAdMatchCriteriaTime>;
  /** fetch data from the table: "demiplane.ad_request" */
  demiplane_ad_request: Array<DemiplaneAdRequest>;
  /** fetch aggregated fields from the table: "demiplane.ad_request" */
  demiplane_ad_request_aggregate: DemiplaneAdRequestAggregate;
  /** fetch data from the table: "demiplane.ad_request" using primary key columns */
  demiplane_ad_request_by_pk?: Maybe<DemiplaneAdRequest>;
  /** fetch data from the table: "demiplane.ad_time" */
  demiplane_ad_time: Array<DemiplaneAdTime>;
  /** fetch aggregated fields from the table: "demiplane.ad_time" */
  demiplane_ad_time_aggregate: DemiplaneAdTimeAggregate;
  /** fetch data from the table: "demiplane.ad_time" using primary key columns */
  demiplane_ad_time_by_pk?: Maybe<DemiplaneAdTime>;
  /** fetch data from the table: "demiplane.adventure" */
  demiplane_adventure: Array<DemiplaneAdventure>;
  /** fetch aggregated fields from the table: "demiplane.adventure" */
  demiplane_adventure_aggregate: DemiplaneAdventureAggregate;
  /** fetch data from the table: "demiplane.adventure" using primary key columns */
  demiplane_adventure_by_pk?: Maybe<DemiplaneAdventure>;
  /** fetch data from the table: "demiplane.adventure_connection" */
  demiplane_adventure_connection: Array<DemiplaneAdventureConnection>;
  /** fetch aggregated fields from the table: "demiplane.adventure_connection" */
  demiplane_adventure_connection_aggregate: DemiplaneAdventureConnectionAggregate;
  /** fetch data from the table: "demiplane.adventure_connection" using primary key columns */
  demiplane_adventure_connection_by_pk?: Maybe<DemiplaneAdventureConnection>;
  /** fetch data from the table: "demiplane.adventure_demiplane_tool" */
  demiplane_adventure_demiplane_tool: Array<DemiplaneAdventureDemiplaneTool>;
  /** fetch aggregated fields from the table: "demiplane.adventure_demiplane_tool" */
  demiplane_adventure_demiplane_tool_aggregate: DemiplaneAdventureDemiplaneToolAggregate;
  /** fetch data from the table: "demiplane.adventure_demiplane_tool" using primary key columns */
  demiplane_adventure_demiplane_tool_by_pk?: Maybe<DemiplaneAdventureDemiplaneTool>;
  /** fetch data from the table: "demiplane.adventure_link" */
  demiplane_adventure_link: Array<DemiplaneAdventureLink>;
  /** fetch aggregated fields from the table: "demiplane.adventure_link" */
  demiplane_adventure_link_aggregate: DemiplaneAdventureLinkAggregate;
  /** fetch data from the table: "demiplane.adventure_link" using primary key columns */
  demiplane_adventure_link_by_pk?: Maybe<DemiplaneAdventureLink>;
  /** fetch data from the table: "demiplane.adventure_player" */
  demiplane_adventure_player: Array<DemiplaneAdventurePlayer>;
  /** fetch aggregated fields from the table: "demiplane.adventure_player" */
  demiplane_adventure_player_aggregate: DemiplaneAdventurePlayerAggregate;
  /** fetch data from the table: "demiplane.adventure_player" using primary key columns */
  demiplane_adventure_player_by_pk?: Maybe<DemiplaneAdventurePlayer>;
  /** fetch data from the table: "demiplane.adventure_todo" */
  demiplane_adventure_todo: Array<DemiplaneAdventureTodo>;
  /** fetch aggregated fields from the table: "demiplane.adventure_todo" */
  demiplane_adventure_todo_aggregate: DemiplaneAdventureTodoAggregate;
  /** fetch data from the table: "demiplane.adventure_todo" using primary key columns */
  demiplane_adventure_todo_by_pk?: Maybe<DemiplaneAdventureTodo>;
  /** fetch data from the table: "demiplane.adventuring_platform" */
  demiplane_adventuring_platform: Array<DemiplaneAdventuringPlatform>;
  /** fetch aggregated fields from the table: "demiplane.adventuring_platform" */
  demiplane_adventuring_platform_aggregate: DemiplaneAdventuringPlatformAggregate;
  /** fetch data from the table: "demiplane.adventuring_platform" using primary key columns */
  demiplane_adventuring_platform_by_pk?: Maybe<DemiplaneAdventuringPlatform>;
  /** fetch data from the table: "demiplane.amc_adventuring_platforms" */
  demiplane_amc_adventuring_platforms: Array<DemiplaneAmcAdventuringPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.amc_adventuring_platforms" */
  demiplane_amc_adventuring_platforms_aggregate: DemiplaneAmcAdventuringPlatformsAggregate;
  /** fetch data from the table: "demiplane.amc_adventuring_platforms" using primary key columns */
  demiplane_amc_adventuring_platforms_by_pk?: Maybe<DemiplaneAmcAdventuringPlatforms>;
  /** fetch data from the table: "demiplane.amc_game_types" */
  demiplane_amc_game_types: Array<DemiplaneAmcGameTypes>;
  /** fetch aggregated fields from the table: "demiplane.amc_game_types" */
  demiplane_amc_game_types_aggregate: DemiplaneAmcGameTypesAggregate;
  /** fetch data from the table: "demiplane.amc_game_types" using primary key columns */
  demiplane_amc_game_types_by_pk?: Maybe<DemiplaneAmcGameTypes>;
  /** fetch data from the table: "demiplane.app_version" */
  demiplane_app_version: Array<DemiplaneAppVersion>;
  /** fetch aggregated fields from the table: "demiplane.app_version" */
  demiplane_app_version_aggregate: DemiplaneAppVersionAggregate;
  /** fetch data from the table: "demiplane.app_version" using primary key columns */
  demiplane_app_version_by_pk?: Maybe<DemiplaneAppVersion>;
  /** fetch data from the table: "demiplane.attachment" */
  demiplane_attachment: Array<DemiplaneAttachment>;
  /** fetch aggregated fields from the table: "demiplane.attachment" */
  demiplane_attachment_aggregate: DemiplaneAttachmentAggregate;
  /** fetch data from the table: "demiplane.attachment" using primary key columns */
  demiplane_attachment_by_pk?: Maybe<DemiplaneAttachment>;
  /** fetch data from the table: "demiplane.award_type" */
  demiplane_award_type: Array<DemiplaneAwardType>;
  /** fetch aggregated fields from the table: "demiplane.award_type" */
  demiplane_award_type_aggregate: DemiplaneAwardTypeAggregate;
  /** fetch data from the table: "demiplane.award_type" using primary key columns */
  demiplane_award_type_by_pk?: Maybe<DemiplaneAwardType>;
  /** fetch data from the table: "demiplane.connection_status" */
  demiplane_connection_status: Array<DemiplaneConnectionStatus>;
  /** fetch aggregated fields from the table: "demiplane.connection_status" */
  demiplane_connection_status_aggregate: DemiplaneConnectionStatusAggregate;
  /** fetch data from the table: "demiplane.connection_status" using primary key columns */
  demiplane_connection_status_by_pk?: Maybe<DemiplaneConnectionStatus>;
  /** fetch data from the table: "demiplane.connection_type" */
  demiplane_connection_type: Array<DemiplaneConnectionType>;
  /** fetch aggregated fields from the table: "demiplane.connection_type" */
  demiplane_connection_type_aggregate: DemiplaneConnectionTypeAggregate;
  /** fetch data from the table: "demiplane.connection_type" using primary key columns */
  demiplane_connection_type_by_pk?: Maybe<DemiplaneConnectionType>;
  /** fetch data from the table: "demiplane.contact_type" */
  demiplane_contact_type: Array<DemiplaneContactType>;
  /** fetch aggregated fields from the table: "demiplane.contact_type" */
  demiplane_contact_type_aggregate: DemiplaneContactTypeAggregate;
  /** fetch data from the table: "demiplane.contact_type" using primary key columns */
  demiplane_contact_type_by_pk?: Maybe<DemiplaneContactType>;
  /** fetch data from the table: "demiplane.country" */
  demiplane_country: Array<DemiplaneCountry>;
  /** fetch aggregated fields from the table: "demiplane.country" */
  demiplane_country_aggregate: DemiplaneCountryAggregate;
  /** fetch data from the table: "demiplane.country" using primary key columns */
  demiplane_country_by_pk?: Maybe<DemiplaneCountry>;
  /** fetch data from the table: "demiplane.credit_card" */
  demiplane_credit_card: Array<DemiplaneCreditCard>;
  /** fetch aggregated fields from the table: "demiplane.credit_card" */
  demiplane_credit_card_aggregate: DemiplaneCreditCardAggregate;
  /** fetch data from the table: "demiplane.credit_card" using primary key columns */
  demiplane_credit_card_by_pk?: Maybe<DemiplaneCreditCard>;
  /** fetch data from the table: "demiplane.demiplane_tool" */
  demiplane_demiplane_tool: Array<DemiplaneDemiplaneTool>;
  /** fetch aggregated fields from the table: "demiplane.demiplane_tool" */
  demiplane_demiplane_tool_aggregate: DemiplaneDemiplaneToolAggregate;
  /** fetch data from the table: "demiplane.demiplane_tool" using primary key columns */
  demiplane_demiplane_tool_by_pk?: Maybe<DemiplaneDemiplaneTool>;
  /** fetch data from the table: "demiplane.episode_attendance" */
  demiplane_episode_attendance: Array<DemiplaneEpisodeAttendance>;
  /** fetch aggregated fields from the table: "demiplane.episode_attendance" */
  demiplane_episode_attendance_aggregate: DemiplaneEpisodeAttendanceAggregate;
  /** fetch data from the table: "demiplane.episode_attendance" using primary key columns */
  demiplane_episode_attendance_by_pk?: Maybe<DemiplaneEpisodeAttendance>;
  /** fetch data from the table: "demiplane.event" */
  demiplane_event: Array<DemiplaneEvent>;
  /** fetch aggregated fields from the table: "demiplane.event" */
  demiplane_event_aggregate: DemiplaneEventAggregate;
  /** fetch data from the table: "demiplane.event" using primary key columns */
  demiplane_event_by_pk?: Maybe<DemiplaneEvent>;
  /** fetch data from the table: "demiplane.event_type" */
  demiplane_event_type: Array<DemiplaneEventType>;
  /** fetch aggregated fields from the table: "demiplane.event_type" */
  demiplane_event_type_aggregate: DemiplaneEventTypeAggregate;
  /** fetch data from the table: "demiplane.event_type" using primary key columns */
  demiplane_event_type_by_pk?: Maybe<DemiplaneEventType>;
  /** fetch data from the table: "demiplane.favorite_adventuring_platform" */
  demiplane_favorite_adventuring_platform: Array<DemiplaneFavoriteAdventuringPlatform>;
  /** fetch aggregated fields from the table: "demiplane.favorite_adventuring_platform" */
  demiplane_favorite_adventuring_platform_aggregate: DemiplaneFavoriteAdventuringPlatformAggregate;
  /** fetch data from the table: "demiplane.favorite_adventuring_platform" using primary key columns */
  demiplane_favorite_adventuring_platform_by_pk?: Maybe<DemiplaneFavoriteAdventuringPlatform>;
  /** fetch data from the table: "demiplane.favorite_dice_roll" */
  demiplane_favorite_dice_roll: Array<DemiplaneFavoriteDiceRoll>;
  /** fetch aggregated fields from the table: "demiplane.favorite_dice_roll" */
  demiplane_favorite_dice_roll_aggregate: DemiplaneFavoriteDiceRollAggregate;
  /** fetch data from the table: "demiplane.favorite_dice_roll" using primary key columns */
  demiplane_favorite_dice_roll_by_pk?: Maybe<DemiplaneFavoriteDiceRoll>;
  /** fetch data from the table: "demiplane.favorite_game" */
  demiplane_favorite_game: Array<DemiplaneFavoriteGame>;
  /** fetch aggregated fields from the table: "demiplane.favorite_game" */
  demiplane_favorite_game_aggregate: DemiplaneFavoriteGameAggregate;
  /** fetch data from the table: "demiplane.favorite_game" using primary key columns */
  demiplane_favorite_game_by_pk?: Maybe<DemiplaneFavoriteGame>;
  /** fetch data from the table: "demiplane.game_type" */
  demiplane_game_type: Array<DemiplaneGameType>;
  /** fetch aggregated fields from the table: "demiplane.game_type" */
  demiplane_game_type_aggregate: DemiplaneGameTypeAggregate;
  /** fetch data from the table: "demiplane.game_type" using primary key columns */
  demiplane_game_type_by_pk?: Maybe<DemiplaneGameType>;
  /** fetch data from the table: "demiplane.gm_rating" */
  demiplane_gm_rating: Array<DemiplaneGmRating>;
  /** fetch aggregated fields from the table: "demiplane.gm_rating" */
  demiplane_gm_rating_aggregate: DemiplaneGmRatingAggregate;
  /** fetch data from the table: "demiplane.gm_rating" using primary key columns */
  demiplane_gm_rating_by_pk?: Maybe<DemiplaneGmRating>;
  /** fetch data from the table: "demiplane.gm_recommendation" */
  demiplane_gm_recommendation: Array<DemiplaneGmRecommendation>;
  /** fetch aggregated fields from the table: "demiplane.gm_recommendation" */
  demiplane_gm_recommendation_aggregate: DemiplaneGmRecommendationAggregate;
  /** fetch data from the table: "demiplane.gm_recommendation" using primary key columns */
  demiplane_gm_recommendation_by_pk?: Maybe<DemiplaneGmRecommendation>;
  /** fetch data from the table: "demiplane.invitation" */
  demiplane_invitation: Array<DemiplaneInvitation>;
  /** fetch aggregated fields from the table: "demiplane.invitation" */
  demiplane_invitation_aggregate: DemiplaneInvitationAggregate;
  /** fetch data from the table: "demiplane.invitation" using primary key columns */
  demiplane_invitation_by_pk?: Maybe<DemiplaneInvitation>;
  /** fetch data from the table: "demiplane.journal" */
  demiplane_journal: Array<DemiplaneJournal>;
  /** fetch aggregated fields from the table: "demiplane.journal" */
  demiplane_journal_aggregate: DemiplaneJournalAggregate;
  /** fetch data from the table: "demiplane.journal" using primary key columns */
  demiplane_journal_by_pk?: Maybe<DemiplaneJournal>;
  /** fetch data from the table: "demiplane.journal_note" */
  demiplane_journal_note: Array<DemiplaneJournalNote>;
  /** fetch aggregated fields from the table: "demiplane.journal_note" */
  demiplane_journal_note_aggregate: DemiplaneJournalNoteAggregate;
  /** fetch data from the table: "demiplane.journal_note" using primary key columns */
  demiplane_journal_note_by_pk?: Maybe<DemiplaneJournalNote>;
  /** fetch data from the table: "demiplane.journal_note_share" */
  demiplane_journal_note_share: Array<DemiplaneJournalNoteShare>;
  /** fetch aggregated fields from the table: "demiplane.journal_note_share" */
  demiplane_journal_note_share_aggregate: DemiplaneJournalNoteShareAggregate;
  /** fetch data from the table: "demiplane.journal_note_share" using primary key columns */
  demiplane_journal_note_share_by_pk?: Maybe<DemiplaneJournalNoteShare>;
  /** fetch data from the table: "demiplane.journal_super_event" */
  demiplane_journal_super_event: Array<DemiplaneJournalSuperEvent>;
  /** fetch aggregated fields from the table: "demiplane.journal_super_event" */
  demiplane_journal_super_event_aggregate: DemiplaneJournalSuperEventAggregate;
  /** fetch data from the table: "demiplane.journal_super_event" using primary key columns */
  demiplane_journal_super_event_by_pk?: Maybe<DemiplaneJournalSuperEvent>;
  /** fetch data from the table: "demiplane.journal_super_event_read_status" */
  demiplane_journal_super_event_read_status: Array<DemiplaneJournalSuperEventReadStatus>;
  /** fetch aggregated fields from the table: "demiplane.journal_super_event_read_status" */
  demiplane_journal_super_event_read_status_aggregate: DemiplaneJournalSuperEventReadStatusAggregate;
  /** fetch data from the table: "demiplane.journal_super_event_read_status" using primary key columns */
  demiplane_journal_super_event_read_status_by_pk?: Maybe<DemiplaneJournalSuperEventReadStatus>;
  /** fetch data from the table: "demiplane.journal_title" */
  demiplane_journal_title: Array<DemiplaneJournalTitle>;
  /** fetch aggregated fields from the table: "demiplane.journal_title" */
  demiplane_journal_title_aggregate: DemiplaneJournalTitleAggregate;
  /** fetch data from the table: "demiplane.journal_title" using primary key columns */
  demiplane_journal_title_by_pk?: Maybe<DemiplaneJournalTitle>;
  /** fetch data from the table: "demiplane.matchmaking_token" */
  demiplane_matchmaking_token: Array<DemiplaneMatchmakingToken>;
  /** fetch aggregated fields from the table: "demiplane.matchmaking_token" */
  demiplane_matchmaking_token_aggregate: DemiplaneMatchmakingTokenAggregate;
  /** fetch data from the table: "demiplane.matchmaking_token" using primary key columns */
  demiplane_matchmaking_token_by_pk?: Maybe<DemiplaneMatchmakingToken>;
  /** fetch data from the table: "demiplane.migrations" */
  demiplane_migrations: Array<DemiplaneMigrations>;
  /** fetch aggregated fields from the table: "demiplane.migrations" */
  demiplane_migrations_aggregate: DemiplaneMigrationsAggregate;
  /** fetch data from the table: "demiplane.migrations" using primary key columns */
  demiplane_migrations_by_pk?: Maybe<DemiplaneMigrations>;
  /** fetch data from the table: "demiplane.notification_history" */
  demiplane_notification_history: Array<DemiplaneNotificationHistory>;
  /** fetch aggregated fields from the table: "demiplane.notification_history" */
  demiplane_notification_history_aggregate: DemiplaneNotificationHistoryAggregate;
  /** fetch data from the table: "demiplane.notification_history" using primary key columns */
  demiplane_notification_history_by_pk?: Maybe<DemiplaneNotificationHistory>;
  /** fetch data from the table: "demiplane.payment" */
  demiplane_payment: Array<DemiplanePayment>;
  /** fetch aggregated fields from the table: "demiplane.payment" */
  demiplane_payment_aggregate: DemiplanePaymentAggregate;
  /** fetch data from the table: "demiplane.payment" using primary key columns */
  demiplane_payment_by_pk?: Maybe<DemiplanePayment>;
  /** fetch data from the table: "demiplane.player_award" */
  demiplane_player_award: Array<DemiplanePlayerAward>;
  /** fetch aggregated fields from the table: "demiplane.player_award" */
  demiplane_player_award_aggregate: DemiplanePlayerAwardAggregate;
  /** fetch data from the table: "demiplane.player_award" using primary key columns */
  demiplane_player_award_by_pk?: Maybe<DemiplanePlayerAward>;
  /** fetch data from the table: "demiplane.player_rating" */
  demiplane_player_rating: Array<DemiplanePlayerRating>;
  /** fetch aggregated fields from the table: "demiplane.player_rating" */
  demiplane_player_rating_aggregate: DemiplanePlayerRatingAggregate;
  /** fetch data from the table: "demiplane.player_rating" using primary key columns */
  demiplane_player_rating_by_pk?: Maybe<DemiplanePlayerRating>;
  /** fetch data from the table: "demiplane.pronoun" */
  demiplane_pronoun: Array<DemiplanePronoun>;
  /** fetch aggregated fields from the table: "demiplane.pronoun" */
  demiplane_pronoun_aggregate: DemiplanePronounAggregate;
  /** fetch data from the table: "demiplane.pronoun" using primary key columns */
  demiplane_pronoun_by_pk?: Maybe<DemiplanePronoun>;
  /** fetch data from the table: "demiplane.region" */
  demiplane_region: Array<DemiplaneRegion>;
  /** fetch aggregated fields from the table: "demiplane.region" */
  demiplane_region_aggregate: DemiplaneRegionAggregate;
  /** fetch data from the table: "demiplane.region" using primary key columns */
  demiplane_region_by_pk?: Maybe<DemiplaneRegion>;
  /** fetch data from the table: "demiplane.role" */
  demiplane_role: Array<DemiplaneRole>;
  /** fetch aggregated fields from the table: "demiplane.role" */
  demiplane_role_aggregate: DemiplaneRoleAggregate;
  /** fetch data from the table: "demiplane.role" using primary key columns */
  demiplane_role_by_pk?: Maybe<DemiplaneRole>;
  /** fetch data from the table: "demiplane.session" */
  demiplane_session: Array<DemiplaneSession>;
  /** fetch aggregated fields from the table: "demiplane.session" */
  demiplane_session_aggregate: DemiplaneSessionAggregate;
  /** fetch data from the table: "demiplane.session" using primary key columns */
  demiplane_session_by_pk?: Maybe<DemiplaneSession>;
  /** fetch data from the table: "demiplane.session_player" */
  demiplane_session_player: Array<DemiplaneSessionPlayer>;
  /** fetch aggregated fields from the table: "demiplane.session_player" */
  demiplane_session_player_aggregate: DemiplaneSessionPlayerAggregate;
  /** fetch data from the table: "demiplane.session_player" using primary key columns */
  demiplane_session_player_by_pk?: Maybe<DemiplaneSessionPlayer>;
  /** fetch data from the table: "demiplane.snippet" */
  demiplane_snippet: Array<DemiplaneSnippet>;
  /** fetch aggregated fields from the table: "demiplane.snippet" */
  demiplane_snippet_aggregate: DemiplaneSnippetAggregate;
  /** fetch data from the table: "demiplane.snippet" using primary key columns */
  demiplane_snippet_by_pk?: Maybe<DemiplaneSnippet>;
  /** fetch data from the table: "demiplane.task" */
  demiplane_task: Array<DemiplaneTask>;
  /** fetch aggregated fields from the table: "demiplane.task" */
  demiplane_task_aggregate: DemiplaneTaskAggregate;
  /** fetch data from the table: "demiplane.task" using primary key columns */
  demiplane_task_by_pk?: Maybe<DemiplaneTask>;
  /** fetch data from the table: "demiplane.task_read_status" */
  demiplane_task_read_status: Array<DemiplaneTaskReadStatus>;
  /** fetch aggregated fields from the table: "demiplane.task_read_status" */
  demiplane_task_read_status_aggregate: DemiplaneTaskReadStatusAggregate;
  /** fetch data from the table: "demiplane.task_read_status" using primary key columns */
  demiplane_task_read_status_by_pk?: Maybe<DemiplaneTaskReadStatus>;
  /** fetch data from the table: "demiplane.tax_nexus_region" */
  demiplane_tax_nexus_region: Array<DemiplaneTaxNexusRegion>;
  /** fetch aggregated fields from the table: "demiplane.tax_nexus_region" */
  demiplane_tax_nexus_region_aggregate: DemiplaneTaxNexusRegionAggregate;
  /** fetch data from the table: "demiplane.tax_nexus_region" using primary key columns */
  demiplane_tax_nexus_region_by_pk?: Maybe<DemiplaneTaxNexusRegion>;
  /** fetch data from the table: "demiplane.transaction_receipt" */
  demiplane_transaction_receipt: Array<DemiplaneTransactionReceipt>;
  /** fetch aggregated fields from the table: "demiplane.transaction_receipt" */
  demiplane_transaction_receipt_aggregate: DemiplaneTransactionReceiptAggregate;
  /** fetch data from the table: "demiplane.transaction_receipt" using primary key columns */
  demiplane_transaction_receipt_by_pk?: Maybe<DemiplaneTransactionReceipt>;
  /** fetch data from the table: "demiplane.upload" */
  demiplane_upload: Array<DemiplaneUpload>;
  /** fetch aggregated fields from the table: "demiplane.upload" */
  demiplane_upload_aggregate: DemiplaneUploadAggregate;
  /** fetch data from the table: "demiplane.upload" using primary key columns */
  demiplane_upload_by_pk?: Maybe<DemiplaneUpload>;
  /** fetch data from the table: "demiplane.urap" */
  demiplane_urap: Array<DemiplaneUrap>;
  /** fetch aggregated fields from the table: "demiplane.urap" */
  demiplane_urap_aggregate: DemiplaneUrapAggregate;
  /** fetch data from the table: "demiplane.urap" using primary key columns */
  demiplane_urap_by_pk?: Maybe<DemiplaneUrap>;
  /** fetch data from the table: "demiplane.urgt" */
  demiplane_urgt: Array<DemiplaneUrgt>;
  /** fetch aggregated fields from the table: "demiplane.urgt" */
  demiplane_urgt_aggregate: DemiplaneUrgtAggregate;
  /** fetch data from the table: "demiplane.urgt" using primary key columns */
  demiplane_urgt_by_pk?: Maybe<DemiplaneUrgt>;
  /** fetch data from the table: "demiplane.user" */
  demiplane_user: Array<DemiplaneUser>;
  /** fetch data from the table: "demiplane.user_activity" */
  demiplane_user_activity: Array<DemiplaneUserActivity>;
  /** fetch aggregated fields from the table: "demiplane.user_activity" */
  demiplane_user_activity_aggregate: DemiplaneUserActivityAggregate;
  /** fetch data from the table: "demiplane.user_activity" using primary key columns */
  demiplane_user_activity_by_pk?: Maybe<DemiplaneUserActivity>;
  /** fetch data from the table: "demiplane.user_adventure" */
  demiplane_user_adventure: Array<DemiplaneUserAdventure>;
  /** fetch aggregated fields from the table: "demiplane.user_adventure" */
  demiplane_user_adventure_aggregate: DemiplaneUserAdventureAggregate;
  /** fetch data from the table: "demiplane.user_adventure" using primary key columns */
  demiplane_user_adventure_by_pk?: Maybe<DemiplaneUserAdventure>;
  /** fetch data from the table: "demiplane.user_adventure_ranking" */
  demiplane_user_adventure_ranking: Array<DemiplaneUserAdventureRanking>;
  /** fetch aggregated fields from the table: "demiplane.user_adventure_ranking" */
  demiplane_user_adventure_ranking_aggregate: DemiplaneUserAdventureRankingAggregate;
  /** fetch data from the table: "demiplane.user_adventure_ranking" using primary key columns */
  demiplane_user_adventure_ranking_by_pk?: Maybe<DemiplaneUserAdventureRanking>;
  /** fetch data from the table: "demiplane.user_adventure_role" */
  demiplane_user_adventure_role: Array<DemiplaneUserAdventureRole>;
  /** fetch aggregated fields from the table: "demiplane.user_adventure_role" */
  demiplane_user_adventure_role_aggregate: DemiplaneUserAdventureRoleAggregate;
  /** fetch data from the table: "demiplane.user_adventure_role" using primary key columns */
  demiplane_user_adventure_role_by_pk?: Maybe<DemiplaneUserAdventureRole>;
  /** fetch aggregated fields from the table: "demiplane.user" */
  demiplane_user_aggregate: DemiplaneUserAggregate;
  /** fetch data from the table: "demiplane.user" using primary key columns */
  demiplane_user_by_pk?: Maybe<DemiplaneUser>;
  /** fetch data from the table: "demiplane.user_email_notification_pref" */
  demiplane_user_email_notification_pref: Array<DemiplaneUserEmailNotificationPref>;
  /** fetch aggregated fields from the table: "demiplane.user_email_notification_pref" */
  demiplane_user_email_notification_pref_aggregate: DemiplaneUserEmailNotificationPrefAggregate;
  /** fetch data from the table: "demiplane.user_email_notification_pref" using primary key columns */
  demiplane_user_email_notification_pref_by_pk?: Maybe<DemiplaneUserEmailNotificationPref>;
  /** fetch data from the table: "demiplane.user_invitation" */
  demiplane_user_invitation: Array<DemiplaneUserInvitation>;
  /** fetch aggregated fields from the table: "demiplane.user_invitation" */
  demiplane_user_invitation_aggregate: DemiplaneUserInvitationAggregate;
  /** fetch data from the table: "demiplane.user_invitation" using primary key columns */
  demiplane_user_invitation_by_pk?: Maybe<DemiplaneUserInvitation>;
  /** fetch data from the table: "demiplane.user_metadata" */
  demiplane_user_metadata: Array<DemiplaneUserMetadata>;
  /** fetch aggregated fields from the table: "demiplane.user_metadata" */
  demiplane_user_metadata_aggregate: DemiplaneUserMetadataAggregate;
  /** fetch data from the table: "demiplane.user_metadata" using primary key columns */
  demiplane_user_metadata_by_pk?: Maybe<DemiplaneUserMetadata>;
  /** fetch data from the table: "demiplane.user_push_notification_pref" */
  demiplane_user_push_notification_pref: Array<DemiplaneUserPushNotificationPref>;
  /** fetch aggregated fields from the table: "demiplane.user_push_notification_pref" */
  demiplane_user_push_notification_pref_aggregate: DemiplaneUserPushNotificationPrefAggregate;
  /** fetch data from the table: "demiplane.user_push_notification_pref" using primary key columns */
  demiplane_user_push_notification_pref_by_pk?: Maybe<DemiplaneUserPushNotificationPref>;
  /** fetch data from the table: "demiplane.user_recruitment" */
  demiplane_user_recruitment: Array<DemiplaneUserRecruitment>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer" */
  demiplane_user_recruitment_adventurer: Array<DemiplaneUserRecruitmentAdventurer>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer" */
  demiplane_user_recruitment_adventurer_aggregate: DemiplaneUserRecruitmentAdventurerAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_availability" */
  demiplane_user_recruitment_adventurer_availability: Array<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer_availability" */
  demiplane_user_recruitment_adventurer_availability_aggregate: DemiplaneUserRecruitmentAdventurerAvailabilityAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_availability" using primary key columns */
  demiplane_user_recruitment_adventurer_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerAvailability>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer" using primary key columns */
  demiplane_user_recruitment_adventurer_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurer>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_games" */
  demiplane_user_recruitment_adventurer_games: Array<DemiplaneUserRecruitmentAdventurerGames>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer_games" */
  demiplane_user_recruitment_adventurer_games_aggregate: DemiplaneUserRecruitmentAdventurerGamesAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_games" using primary key columns */
  demiplane_user_recruitment_adventurer_games_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerGames>;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_platforms" */
  demiplane_user_recruitment_adventurer_platforms: Array<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_adventurer_platforms" */
  demiplane_user_recruitment_adventurer_platforms_aggregate: DemiplaneUserRecruitmentAdventurerPlatformsAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_adventurer_platforms" using primary key columns */
  demiplane_user_recruitment_adventurer_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentAdventurerPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment" */
  demiplane_user_recruitment_aggregate: DemiplaneUserRecruitmentAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_availability" */
  demiplane_user_recruitment_availability: Array<DemiplaneUserRecruitmentAvailability>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_availability" */
  demiplane_user_recruitment_availability_aggregate: DemiplaneUserRecruitmentAvailabilityAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_availability" using primary key columns */
  demiplane_user_recruitment_availability_by_pk?: Maybe<DemiplaneUserRecruitmentAvailability>;
  /** fetch data from the table: "demiplane.user_recruitment" using primary key columns */
  demiplane_user_recruitment_by_pk?: Maybe<DemiplaneUserRecruitment>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master" */
  demiplane_user_recruitment_game_master: Array<DemiplaneUserRecruitmentGameMaster>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master" */
  demiplane_user_recruitment_game_master_aggregate: DemiplaneUserRecruitmentGameMasterAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_availability" */
  demiplane_user_recruitment_game_master_availability: Array<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master_availability" */
  demiplane_user_recruitment_game_master_availability_aggregate: DemiplaneUserRecruitmentGameMasterAvailabilityAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_availability" using primary key columns */
  demiplane_user_recruitment_game_master_availability_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterAvailability>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master" using primary key columns */
  demiplane_user_recruitment_game_master_by_pk?: Maybe<DemiplaneUserRecruitmentGameMaster>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_games" */
  demiplane_user_recruitment_game_master_games: Array<DemiplaneUserRecruitmentGameMasterGames>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master_games" */
  demiplane_user_recruitment_game_master_games_aggregate: DemiplaneUserRecruitmentGameMasterGamesAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_games" using primary key columns */
  demiplane_user_recruitment_game_master_games_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterGames>;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_platforms" */
  demiplane_user_recruitment_game_master_platforms: Array<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** fetch aggregated fields from the table: "demiplane.user_recruitment_game_master_platforms" */
  demiplane_user_recruitment_game_master_platforms_aggregate: DemiplaneUserRecruitmentGameMasterPlatformsAggregate;
  /** fetch data from the table: "demiplane.user_recruitment_game_master_platforms" using primary key columns */
  demiplane_user_recruitment_game_master_platforms_by_pk?: Maybe<DemiplaneUserRecruitmentGameMasterPlatforms>;
  /** fetch data from the table: "demiplane.user_role" */
  demiplane_user_role: Array<DemiplaneUserRole>;
  /** fetch aggregated fields from the table: "demiplane.user_role" */
  demiplane_user_role_aggregate: DemiplaneUserRoleAggregate;
  /** fetch data from the table: "demiplane.user_role" using primary key columns */
  demiplane_user_role_by_pk?: Maybe<DemiplaneUserRole>;
  /** fetch data from the table: "demiplane.user_visibility" */
  demiplane_user_visibility: Array<DemiplaneUserVisibility>;
  /** fetch aggregated fields from the table: "demiplane.user_visibility" */
  demiplane_user_visibility_aggregate: DemiplaneUserVisibilityAggregate;
  /** fetch data from the table: "demiplane.user_visibility" using primary key columns */
  demiplane_user_visibility_by_pk?: Maybe<DemiplaneUserVisibility>;
  disableWhisper: AdventurePlayerNotificationType;
  enableWhisper: AdventurePlayerNotificationType;
  /** perform the action: "featuredGmRecommendationReviews" */
  featuredGmRecommendationReviews?: Maybe<FeaturedGmRecommendationReviewsOutput>;
  /** perform the action: "howUserPlays" */
  howUserPlays?: Maybe<HowUserPlaysOutput>;
  /** perform the action: "joinAdventuresAds" */
  joinAdventuresAds?: Maybe<JoinAdventuresAdsOutput>;
  /** perform the action: "otherAdventuresAds" */
  otherAdventuresAds?: Maybe<OtherAdventuresAdsOutput>;
  /** perform the action: "recruitAdventurers" */
  recruitAdventurers?: Maybe<RecruitAdventurersOutput>;
  /** perform the action: "recruitGameMasters" */
  recruitGameMasters?: Maybe<RecruitGameMastersOutput>;
  /** perform the action: "searchAdDetailMM" */
  searchAdDetailMM?: Maybe<SearchAdDetailMmOutput>;
  /** perform the action: "searchAdsMM" */
  searchAdsMM?: Maybe<SearchAdsMmOutput>;
  sessionCancelled: SessionNotificationType;
  sessionEnded: SessionNotificationType;
  sessionGameplayNotification: SessionNotificationType;
  sessionJoined: SessionNotificationType;
  sessionLeft: SessionPlayerNotificationType;
  sessionOpened: SessionNotificationType;
  sessionRescheduled: SessionNotificationType;
  sessionScheduled: SessionNotificationType;
  sessionStarted: SessionNotificationType;
  /** perform the action: "transactionDetail" */
  transactionDetail?: Maybe<TransactionDetailOutput>;
  /** perform the action: "transactionHistory" */
  transactionHistory?: Maybe<TransactionHistoryOutput>;
  /** perform the action: "userAdventureRatings" */
  userAdventureRatings?: Maybe<UserAdventureRatingsOutput>;
  /** perform the action: "userSocialProfiles" */
  userSocialProfiles?: Maybe<UserSocialProfilesOutput>;
  /** perform the action: "userTreasureTrove" */
  userTreasureTrove?: Maybe<UserTreasureTroveOutput>;
};


/** subscription root */
export type SubscriptionRootAboutUserArgs = {
  userId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootAdDetailArgs = {
  adId: Scalars['Int'];
};


/** subscription root */
export type SubscriptionRootAdventurePlayerRemovedArgs = {
  adventurePlayerIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdOrderBy>>;
  where?: Maybe<DemiplaneAdBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdLinkArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdLinkOrderBy>>;
  where?: Maybe<DemiplaneAdLinkBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdLinkAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdLinkOrderBy>>;
  where?: Maybe<DemiplaneAdLinkBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdLinkByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdMatchCriteriaArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdMatchCriteriaAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdMatchCriteriaByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdMatchCriteriaTimeArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaTimeOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdMatchCriteriaTimeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdMatchCriteriaTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdMatchCriteriaTimeOrderBy>>;
  where?: Maybe<DemiplaneAdMatchCriteriaTimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdMatchCriteriaTimeByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdRequestArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdRequestAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdRequestSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdRequestOrderBy>>;
  where?: Maybe<DemiplaneAdRequestBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdRequestByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdTimeArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdTimeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdTimeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdTimeOrderBy>>;
  where?: Maybe<DemiplaneAdTimeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdTimeByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureOrderBy>>;
  where?: Maybe<DemiplaneAdventureBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureConnectionArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureConnectionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureConnectionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureConnectionOrderBy>>;
  where?: Maybe<DemiplaneAdventureConnectionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureConnectionByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureDemiplaneToolArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneAdventureDemiplaneToolBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureDemiplaneToolAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneAdventureDemiplaneToolBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureDemiplaneToolByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureLinkArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureLinkOrderBy>>;
  where?: Maybe<DemiplaneAdventureLinkBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureLinkAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureLinkOrderBy>>;
  where?: Maybe<DemiplaneAdventureLinkBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureLinkByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventurePlayerArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventurePlayerAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventurePlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventurePlayerOrderBy>>;
  where?: Maybe<DemiplaneAdventurePlayerBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventurePlayerByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureTodoArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureTodoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureTodoOrderBy>>;
  where?: Maybe<DemiplaneAdventureTodoBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureTodoAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventureTodoSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventureTodoOrderBy>>;
  where?: Maybe<DemiplaneAdventureTodoBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventureTodoByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventuringPlatformArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventuringPlatformAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneAdventuringPlatformBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAdventuringPlatformByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAmcAdventuringPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAmcAdventuringPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcAdventuringPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcAdventuringPlatformsOrderBy>>;
  where?: Maybe<DemiplaneAmcAdventuringPlatformsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAmcAdventuringPlatformsByPkArgs = {
  adMatchCriteriaId: Scalars['bigint'];
  adventuringPlatformId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAmcGameTypesArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAmcGameTypesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAmcGameTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAmcGameTypesOrderBy>>;
  where?: Maybe<DemiplaneAmcGameTypesBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAmcGameTypesByPkArgs = {
  adMatchCriteriaId: Scalars['bigint'];
  gameTypeId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAppVersionArgs = {
  distinct_on?: Maybe<Array<DemiplaneAppVersionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAppVersionOrderBy>>;
  where?: Maybe<DemiplaneAppVersionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAppVersionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAppVersionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAppVersionOrderBy>>;
  where?: Maybe<DemiplaneAppVersionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAppVersionByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAttachmentArgs = {
  distinct_on?: Maybe<Array<DemiplaneAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAttachmentOrderBy>>;
  where?: Maybe<DemiplaneAttachmentBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAttachmentAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAttachmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAttachmentOrderBy>>;
  where?: Maybe<DemiplaneAttachmentBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAttachmentByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneAwardTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneAwardTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAwardTypeOrderBy>>;
  where?: Maybe<DemiplaneAwardTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAwardTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneAwardTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneAwardTypeOrderBy>>;
  where?: Maybe<DemiplaneAwardTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneAwardTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneConnectionStatusArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionStatusOrderBy>>;
  where?: Maybe<DemiplaneConnectionStatusBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneConnectionStatusAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionStatusOrderBy>>;
  where?: Maybe<DemiplaneConnectionStatusBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneConnectionStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneConnectionTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionTypeOrderBy>>;
  where?: Maybe<DemiplaneConnectionTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneConnectionTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneConnectionTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneConnectionTypeOrderBy>>;
  where?: Maybe<DemiplaneConnectionTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneConnectionTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneContactTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneContactTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneContactTypeOrderBy>>;
  where?: Maybe<DemiplaneContactTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneContactTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneContactTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneContactTypeOrderBy>>;
  where?: Maybe<DemiplaneContactTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneContactTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneCountryArgs = {
  distinct_on?: Maybe<Array<DemiplaneCountrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCountryOrderBy>>;
  where?: Maybe<DemiplaneCountryBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneCountryAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneCountrySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCountryOrderBy>>;
  where?: Maybe<DemiplaneCountryBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneCountryByPkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type SubscriptionRootDemiplaneCreditCardArgs = {
  distinct_on?: Maybe<Array<DemiplaneCreditCardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCreditCardOrderBy>>;
  where?: Maybe<DemiplaneCreditCardBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneCreditCardAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneCreditCardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneCreditCardOrderBy>>;
  where?: Maybe<DemiplaneCreditCardBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneCreditCardByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneDemiplaneToolArgs = {
  distinct_on?: Maybe<Array<DemiplaneDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneDemiplaneToolBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneDemiplaneToolAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneDemiplaneToolSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneDemiplaneToolOrderBy>>;
  where?: Maybe<DemiplaneDemiplaneToolBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneDemiplaneToolByPkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type SubscriptionRootDemiplaneEpisodeAttendanceArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneEpisodeAttendanceAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEpisodeAttendanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEpisodeAttendanceOrderBy>>;
  where?: Maybe<DemiplaneEpisodeAttendanceBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneEpisodeAttendanceByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneEventArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneEventAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventOrderBy>>;
  where?: Maybe<DemiplaneEventBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneEventByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneEventTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventTypeOrderBy>>;
  where?: Maybe<DemiplaneEventTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneEventTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneEventTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneEventTypeOrderBy>>;
  where?: Maybe<DemiplaneEventTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneEventTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteAdventuringPlatformArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteAdventuringPlatformAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteAdventuringPlatformOrderBy>>;
  where?: Maybe<DemiplaneFavoriteAdventuringPlatformBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteAdventuringPlatformByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteDiceRollArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteDiceRollSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteDiceRollOrderBy>>;
  where?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteDiceRollAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteDiceRollSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteDiceRollOrderBy>>;
  where?: Maybe<DemiplaneFavoriteDiceRollBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteDiceRollByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteGameArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteGameAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneFavoriteGameSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneFavoriteGameOrderBy>>;
  where?: Maybe<DemiplaneFavoriteGameBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneFavoriteGameByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneGameTypeArgs = {
  distinct_on?: Maybe<Array<DemiplaneGameTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGameTypeOrderBy>>;
  where?: Maybe<DemiplaneGameTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneGameTypeAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGameTypeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGameTypeOrderBy>>;
  where?: Maybe<DemiplaneGameTypeBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneGameTypeByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneGmRatingArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneGmRatingAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRatingOrderBy>>;
  where?: Maybe<DemiplaneGmRatingBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneGmRatingByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneGmRecommendationArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneGmRecommendationAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneGmRecommendationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneGmRecommendationOrderBy>>;
  where?: Maybe<DemiplaneGmRecommendationBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneGmRecommendationByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneInvitationArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneInvitationAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneInvitationOrderBy>>;
  where?: Maybe<DemiplaneInvitationBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneInvitationByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalOrderBy>>;
  where?: Maybe<DemiplaneJournalBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalOrderBy>>;
  where?: Maybe<DemiplaneJournalBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalNoteArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalNoteAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalNoteByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalNoteShareArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalNoteShareAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalNoteShareSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalNoteShareOrderBy>>;
  where?: Maybe<DemiplaneJournalNoteShareBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalNoteShareByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalSuperEventArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalSuperEventAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalSuperEventByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalSuperEventReadStatusArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalSuperEventReadStatusAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalSuperEventReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalSuperEventReadStatusOrderBy>>;
  where?: Maybe<DemiplaneJournalSuperEventReadStatusBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalSuperEventReadStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalTitleArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalTitleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneJournalTitleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneJournalTitleOrderBy>>;
  where?: Maybe<DemiplaneJournalTitleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneJournalTitleByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneMatchmakingTokenArgs = {
  distinct_on?: Maybe<Array<DemiplaneMatchmakingTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMatchmakingTokenOrderBy>>;
  where?: Maybe<DemiplaneMatchmakingTokenBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneMatchmakingTokenAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneMatchmakingTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMatchmakingTokenOrderBy>>;
  where?: Maybe<DemiplaneMatchmakingTokenBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneMatchmakingTokenByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneMigrationsArgs = {
  distinct_on?: Maybe<Array<DemiplaneMigrationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMigrationsOrderBy>>;
  where?: Maybe<DemiplaneMigrationsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneMigrationsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneMigrationsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneMigrationsOrderBy>>;
  where?: Maybe<DemiplaneMigrationsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneMigrationsByPkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type SubscriptionRootDemiplaneNotificationHistoryArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneNotificationHistoryAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneNotificationHistorySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneNotificationHistoryOrderBy>>;
  where?: Maybe<DemiplaneNotificationHistoryBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneNotificationHistoryByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplanePaymentArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePaymentAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePaymentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePaymentOrderBy>>;
  where?: Maybe<DemiplanePaymentBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePaymentByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplanePlayerAwardArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePlayerAwardAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerAwardSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerAwardOrderBy>>;
  where?: Maybe<DemiplanePlayerAwardBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePlayerAwardByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplanePlayerRatingArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePlayerRatingAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePlayerRatingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePlayerRatingOrderBy>>;
  where?: Maybe<DemiplanePlayerRatingBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePlayerRatingByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplanePronounArgs = {
  distinct_on?: Maybe<Array<DemiplanePronounSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePronounOrderBy>>;
  where?: Maybe<DemiplanePronounBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePronounAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplanePronounSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplanePronounOrderBy>>;
  where?: Maybe<DemiplanePronounBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplanePronounByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneRegionArgs = {
  distinct_on?: Maybe<Array<DemiplaneRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRegionOrderBy>>;
  where?: Maybe<DemiplaneRegionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneRegionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRegionOrderBy>>;
  where?: Maybe<DemiplaneRegionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneRegionByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneRoleArgs = {
  distinct_on?: Maybe<Array<DemiplaneRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRoleOrderBy>>;
  where?: Maybe<DemiplaneRoleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneRoleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneRoleOrderBy>>;
  where?: Maybe<DemiplaneRoleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneRoleByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneSessionArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionOrderBy>>;
  where?: Maybe<DemiplaneSessionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneSessionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionOrderBy>>;
  where?: Maybe<DemiplaneSessionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneSessionByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneSessionPlayerArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneSessionPlayerAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSessionPlayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSessionPlayerOrderBy>>;
  where?: Maybe<DemiplaneSessionPlayerBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneSessionPlayerByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneSnippetArgs = {
  distinct_on?: Maybe<Array<DemiplaneSnippetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSnippetOrderBy>>;
  where?: Maybe<DemiplaneSnippetBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneSnippetAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneSnippetSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneSnippetOrderBy>>;
  where?: Maybe<DemiplaneSnippetBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneSnippetByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneTaskArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTaskAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskOrderBy>>;
  where?: Maybe<DemiplaneTaskBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTaskByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneTaskReadStatusArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTaskReadStatusAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaskReadStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaskReadStatusOrderBy>>;
  where?: Maybe<DemiplaneTaskReadStatusBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTaskReadStatusByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneTaxNexusRegionArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaxNexusRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaxNexusRegionOrderBy>>;
  where?: Maybe<DemiplaneTaxNexusRegionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTaxNexusRegionAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTaxNexusRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTaxNexusRegionOrderBy>>;
  where?: Maybe<DemiplaneTaxNexusRegionBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTaxNexusRegionByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneTransactionReceiptArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTransactionReceiptAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneTransactionReceiptSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneTransactionReceiptOrderBy>>;
  where?: Maybe<DemiplaneTransactionReceiptBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneTransactionReceiptByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUploadArgs = {
  distinct_on?: Maybe<Array<DemiplaneUploadSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUploadOrderBy>>;
  where?: Maybe<DemiplaneUploadBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUploadAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUploadSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUploadOrderBy>>;
  where?: Maybe<DemiplaneUploadBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUploadByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUrapArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUrapAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrapSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrapOrderBy>>;
  where?: Maybe<DemiplaneUrapBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUrapByPkArgs = {
  adventuringPlatformId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUrgtArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUrgtAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUrgtSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUrgtOrderBy>>;
  where?: Maybe<DemiplaneUrgtBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUrgtByPkArgs = {
  gameTypeId: Scalars['bigint'];
  userRecruitmentId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserActivityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserActivityOrderBy>>;
  where?: Maybe<DemiplaneUserActivityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserActivityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserActivitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserActivityOrderBy>>;
  where?: Maybe<DemiplaneUserActivityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserActivityByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureRankingArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureRankingAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRankingSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRankingOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRankingBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureRankingByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureRoleArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureRoleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserAdventureRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserAdventureRoleOrderBy>>;
  where?: Maybe<DemiplaneUserAdventureRoleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAdventureRoleByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserOrderBy>>;
  where?: Maybe<DemiplaneUserBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserEmailNotificationPrefArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserEmailNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserEmailNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserEmailNotificationPrefBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserEmailNotificationPrefAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserEmailNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserEmailNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserEmailNotificationPrefBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserEmailNotificationPrefByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserInvitationArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserInvitationAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserInvitationOrderBy>>;
  where?: Maybe<DemiplaneUserInvitationBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserInvitationByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserMetadataArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserMetadataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserMetadataOrderBy>>;
  where?: Maybe<DemiplaneUserMetadataBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserMetadataAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserMetadataSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserMetadataOrderBy>>;
  where?: Maybe<DemiplaneUserMetadataBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserMetadataByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserPushNotificationPrefArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserPushNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserPushNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserPushNotificationPrefBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserPushNotificationPrefAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserPushNotificationPrefSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserPushNotificationPrefOrderBy>>;
  where?: Maybe<DemiplaneUserPushNotificationPrefBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserPushNotificationPrefByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerAvailabilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerGamesBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerGamesByPkArgs = {
  game_type_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAdventurerPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAdventurerPlatformsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_adventurer_id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAvailabilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterAvailabilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterAvailabilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterGamesArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterGamesAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterGamesOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterGamesBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterGamesByPkArgs = {
  game_type_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterPlatformsArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterPlatformsAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRecruitmentGameMasterPlatformsOrderBy>>;
  where?: Maybe<DemiplaneUserRecruitmentGameMasterPlatformsBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs = {
  adventuring_platform_id: Scalars['bigint'];
  user_recruitment_game_master_id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRoleArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRoleAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserRoleSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserRoleOrderBy>>;
  where?: Maybe<DemiplaneUserRoleBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserRoleByPkArgs = {
  roleId: Scalars['bigint'];
  userId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDemiplaneUserVisibilityArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserVisibilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserVisibilityOrderBy>>;
  where?: Maybe<DemiplaneUserVisibilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserVisibilityAggregateArgs = {
  distinct_on?: Maybe<Array<DemiplaneUserVisibilitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<DemiplaneUserVisibilityOrderBy>>;
  where?: Maybe<DemiplaneUserVisibilityBoolExp>;
};


/** subscription root */
export type SubscriptionRootDemiplaneUserVisibilityByPkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootDisableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootEnableWhisperArgs = {
  adventurePlayerId: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootFeaturedGmRecommendationReviewsArgs = {
  userId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootHowUserPlaysArgs = {
  userId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootJoinAdventuresAdsArgs = {
  adUserId: Scalars['Int'];
  resultLimit?: Maybe<Scalars['Int']>;
};


/** subscription root */
export type SubscriptionRootOtherAdventuresAdsArgs = {
  resultLimit?: Maybe<Scalars['Int']>;
};


/** subscription root */
export type SubscriptionRootRecruitAdventurersArgs = {
  displayCount: Scalars['Int'];
  excludeRecruited: Scalars['Boolean'];
  portalId: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootRecruitGameMastersArgs = {
  displayCount: Scalars['Int'];
  excludeRecruited: Scalars['Boolean'];
  portalId: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootSearchAdDetailMmArgs = {
  adId: Scalars['Int'];
};


/** subscription root */
export type SubscriptionRootSearchAdsMmArgs = {
  adCreator?: Maybe<Array<Scalars['String']>>;
  adventuringPlatformIds: Array<Scalars['Int']>;
  availability: Array<AvailabilityInputMm>;
  costMax?: Maybe<Scalars['Int']>;
  frequencyOneTime: Scalars['Boolean'];
  frequencyRecurring: Scalars['Boolean'];
  gameMaster?: Maybe<Array<Scalars['String']>>;
  gameTypeIds: Array<Scalars['Int']>;
  newPlayer: Scalars['Boolean'];
  noCost: Scalars['Boolean'];
  numSeatsOpenMax?: Maybe<Scalars['Int']>;
  numSeatsOpenMin?: Maybe<Scalars['Int']>;
  quickMatch: Scalars['Boolean'];
  recordCount: Scalars['Int'];
  sortBy?: Maybe<Array<Scalars['String']>>;
};


/** subscription root */
export type SubscriptionRootSessionCancelledArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionEndedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionGameplayNotificationArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionJoinedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionLeftArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionOpenedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionRescheduledArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionScheduledArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootSessionStartedArgs = {
  adventureIds: Array<Maybe<Scalars['ID']>>;
};


/** subscription root */
export type SubscriptionRootTransactionDetailArgs = {
  episodeId: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootTransactionHistoryArgs = {
  sortBy: Scalars['String'];
};


/** subscription root */
export type SubscriptionRootUserAdventureRatingsArgs = {
  userId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootUserSocialProfilesArgs = {
  userId: Scalars['bigint'];
};


/** subscription root */
export type SubscriptionRootUserTreasureTroveArgs = {
  userId: Scalars['bigint'];
};


/** expression to compare columns of type time. All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: Maybe<Scalars['time']>;
  _gt?: Maybe<Scalars['time']>;
  _gte?: Maybe<Scalars['time']>;
  _in?: Maybe<Array<Scalars['time']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['time']>;
  _lte?: Maybe<Scalars['time']>;
  _neq?: Maybe<Scalars['time']>;
  _nin?: Maybe<Array<Scalars['time']>>;
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


export type UpdateGameAvailabilityAdTimeResetMutationVariables = Exact<{
  adTimesToDelete?: Maybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  adventureId: Scalars['bigint'];
  matchMakingStatusGameAvail: Scalars['Boolean'];
  matchMakingEnabled: Scalars['Boolean'];
}>;


export type UpdateGameAvailabilityAdTimeResetMutation = (
  { __typename?: 'mutation_root' }
  & { delete_demiplane_ad_time?: Maybe<(
    { __typename?: 'demiplane_ad_time_mutation_response' }
    & Pick<DemiplaneAdTimeMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'demiplane_ad_time' }
      & { adventure?: Maybe<(
        { __typename?: 'demiplane_adventure' }
        & Pick<DemiplaneAdventure, 'id'>
        & { ad_times: Array<(
          { __typename?: 'demiplane_ad_time' }
          & Pick<DemiplaneAdTime, 'id'>
        )> }
      )> }
    )> }
  )>, update_demiplane_adventure_by_pk?: Maybe<(
    { __typename?: 'demiplane_adventure' }
    & Pick<DemiplaneAdventure, 'id' | 'frequency' | 'match_making_enabled' | 'match_making_status_adv_type' | 'match_making_status_game_avail' | 'updated'>
  )> }
);

export type UpdateGameAvailabilityAdTimeUpdateMutationVariables = Exact<{
  adTimes: Array<DemiplaneAdTimeInsertInput> | DemiplaneAdTimeInsertInput;
  adTimesToDelete?: Maybe<Array<Scalars['bigint']> | Scalars['bigint']>;
  adventureId: Scalars['bigint'];
  matchMakingStatusGameAvail: Scalars['Boolean'];
  matchMakingEnabled: Scalars['Boolean'];
}>;


export type UpdateGameAvailabilityAdTimeUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_demiplane_ad_time?: Maybe<(
    { __typename?: 'demiplane_ad_time_mutation_response' }
    & Pick<DemiplaneAdTimeMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'demiplane_ad_time' }
      & { adventure?: Maybe<(
        { __typename?: 'demiplane_adventure' }
        & Pick<DemiplaneAdventure, 'id'>
        & { ad_times: Array<(
          { __typename?: 'demiplane_ad_time' }
          & Pick<DemiplaneAdTime, 'id'>
        )> }
      )> }
    )> }
  )>, delete_demiplane_ad_time?: Maybe<(
    { __typename?: 'demiplane_ad_time_mutation_response' }
    & Pick<DemiplaneAdTimeMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'demiplane_ad_time' }
      & { adventure?: Maybe<(
        { __typename?: 'demiplane_adventure' }
        & Pick<DemiplaneAdventure, 'id'>
        & { ad_times: Array<(
          { __typename?: 'demiplane_ad_time' }
          & Pick<DemiplaneAdTime, 'id'>
        )> }
      )> }
    )> }
  )>, update_demiplane_adventure_by_pk?: Maybe<(
    { __typename?: 'demiplane_adventure' }
    & Pick<DemiplaneAdventure, 'id' | 'frequency' | 'match_making_enabled' | 'match_making_status_adv_type' | 'match_making_status_game_avail' | 'updated'>
  )> }
);

export type GameAvailabilityAdTimeFieldsFragment = (
  { __typename?: 'demiplane_ad_time' }
  & Pick<DemiplaneAdTime, 'id' | 'dow' | 'start_time' | 'end_time'>
  & { ad?: Maybe<(
    { __typename?: 'demiplane_ad' }
    & Pick<DemiplaneAd, 'id'>
  )> }
);

export type GameAvailabilityAdventureFieldsFragment = (
  { __typename?: 'demiplane_adventure' }
  & Pick<DemiplaneAdventure, 'id' | 'frequency' | 'game_cost' | 'match_making_enabled' | 'match_making_status_adv_type' | 'match_making_status_game_avail'>
  & { ad_times: Array<(
    { __typename?: 'demiplane_ad_time' }
    & GameAvailabilityAdTimeFieldsFragment
  )>, sessions: Array<(
    { __typename?: 'demiplane_session' }
    & GameAvailabilitySessionFieldsFragment
  )> }
);

export type UpdateGameAvailabilitySessionResetMutationVariables = Exact<{
  adventureId: Scalars['bigint'];
}>;


export type UpdateGameAvailabilitySessionResetMutation = (
  { __typename?: 'mutation_root' }
  & { update_demiplane_session?: Maybe<(
    { __typename?: 'demiplane_session_mutation_response' }
    & { returning: Array<(
      { __typename?: 'demiplane_session' }
      & Pick<DemiplaneSession, 'cancelled' | 'cancelled_date' | 'completed' | 'id' | 'updated'>
      & { adventure: (
        { __typename?: 'demiplane_adventure' }
        & Pick<DemiplaneAdventure, 'id'>
      ) }
    )> }
  )> }
);

export type UpdateGameAvailabilitySessionUpdateMutationVariables = Exact<{
  adventureId: Scalars['bigint'];
  scheduledDate: Scalars['timestamptz'];
  sessionCost: Scalars['Int'];
}>;


export type UpdateGameAvailabilitySessionUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_demiplane_session_one?: Maybe<(
    { __typename?: 'demiplane_session' }
    & Pick<DemiplaneSession, 'id' | 'cancelled' | 'completed' | 'cost' | 'end_date' | 'live' | 'open' | 'open_date' | 'scheduled_date' | 'start_date'>
    & { adventure: (
      { __typename?: 'demiplane_adventure' }
      & Pick<DemiplaneAdventure, 'id'>
    ) }
  )>, update_demiplane_adventure_by_pk?: Maybe<(
    { __typename?: 'demiplane_adventure' }
    & Pick<DemiplaneAdventure, 'id' | 'frequency' | 'match_making_enabled' | 'match_making_status_game_avail' | 'updated'>
  )> }
);

export type GameAvailabilitySessionFieldsFragment = (
  { __typename?: 'demiplane_session' }
  & Pick<DemiplaneSession, 'scheduled_date'>
);

export type GameAvailabilityQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type GameAvailabilityQuery = (
  { __typename?: 'query_root' }
  & { demiplane_adventure_by_pk?: Maybe<(
    { __typename?: 'demiplane_adventure' }
    & GameAvailabilityAdventureFieldsFragment
  )> }
);

export type GetAdventuringPlatformsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdventuringPlatformsQuery = (
  { __typename?: 'query_root' }
  & { demiplane_adventuring_platform: Array<(
    { __typename?: 'demiplane_adventuring_platform' }
    & Pick<DemiplaneAdventuringPlatform, 'active' | 'created' | 'name' | 'id' | 'updated'>
  )> }
);

export type AuthMmMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AuthMmMutation = (
  { __typename?: 'mutation_root' }
  & { authMM?: Maybe<(
    { __typename?: 'authMMOutput' }
    & Pick<AuthMmOutput, 'message' | 'success' | 'session'>
  )> }
);

export type GetGameTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGameTypesQuery = (
  { __typename?: 'query_root' }
  & { demiplane_game_type: Array<(
    { __typename?: 'demiplane_game_type' }
    & Pick<DemiplaneGameType, 'id' | 'code' | 'created' | 'name' | 'updated' | 'active'>
  )> }
);

export type SearchAdDetailMmQueryVariables = Exact<{
  adId: Scalars['Int'];
}>;


export type SearchAdDetailMmQuery = (
  { __typename?: 'query_root' }
  & { searchAdDetailMM?: Maybe<(
    { __typename?: 'SearchAdDetailMMOutput' }
    & Pick<SearchAdDetailMmOutput, 'message' | 'success' | 'result'>
  )> }
);

export type SearchAdsMmQueryVariables = Exact<{
  adventuringPlatformIds: Array<Scalars['Int']> | Scalars['Int'];
  availability: Array<AvailabilityInputMm> | AvailabilityInputMm;
  frequencyOneTime: Scalars['Boolean'];
  frequencyRecurring: Scalars['Boolean'];
  gameTypeIds: Array<Scalars['Int']> | Scalars['Int'];
  newPlayer: Scalars['Boolean'];
  noCost: Scalars['Boolean'];
  quickMatch: Scalars['Boolean'];
  recordCount: Scalars['Int'];
  costMax?: Maybe<Scalars['Int']>;
  adCreator?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  numSeatsOpenMax?: Maybe<Scalars['Int']>;
  numSeatsOpenMin?: Maybe<Scalars['Int']>;
  gameMaster?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sortBy?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SearchAdsMmQuery = (
  { __typename?: 'query_root' }
  & { searchAdsMM?: Maybe<(
    { __typename?: 'SearchAdsMMOutput' }
    & Pick<SearchAdsMmOutput, 'message' | 'success' | 'result'>
  )> }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AboutUserOutput: ResolverTypeWrapper<AboutUserOutput>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AdDetailOutput: ResolverTypeWrapper<AdDetailOutput>;
  AdLinkType: ResolverTypeWrapper<AdLinkType>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  AdMatchCriteriaTimeType: ResolverTypeWrapper<AdMatchCriteriaTimeType>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  AdMatchCriteriaType: ResolverTypeWrapper<AdMatchCriteriaType>;
  AdRequestScoredType: ResolverTypeWrapper<AdRequestScoredType>;
  AdRequestType: ResolverTypeWrapper<AdRequestType>;
  AdResultType: ResolverTypeWrapper<AdResultType>;
  AdTimeHasuraType: ResolverTypeWrapper<AdTimeHasuraType>;
  AdTimeInput: AdTimeInput;
  AdTimeType: ResolverTypeWrapper<AdTimeType>;
  AdType: ResolverTypeWrapper<AdType>;
  AddFavoriteGameOutput: ResolverTypeWrapper<AddFavoriteGameOutput>;
  AddFavoritePlatformOutput: ResolverTypeWrapper<AddFavoritePlatformOutput>;
  AddNewPortalV2Output: ResolverTypeWrapper<AddNewPortalV2Output>;
  AdventureConnectionType: ResolverTypeWrapper<AdventureConnectionType>;
  AdventureLinkType: ResolverTypeWrapper<AdventureLinkType>;
  AdventureMutationType: ResolverTypeWrapper<AdventureMutationType>;
  AdventureNotificationType: ResolverTypeWrapper<AdventureNotificationType>;
  AdventurePlayerMutationType: ResolverTypeWrapper<AdventurePlayerMutationType>;
  AdventurePlayerNotificationType: ResolverTypeWrapper<AdventurePlayerNotificationType>;
  AdventurePlayerType: ResolverTypeWrapper<AdventurePlayerType>;
  AdventureTodoType: ResolverTypeWrapper<AdventureTodoType>;
  AdventureType: ResolverTypeWrapper<AdventureType>;
  AdventuringPlatformType: ResolverTypeWrapper<AdventuringPlatformType>;
  AllJournalsItemType: ResolverTypeWrapper<Omit<AllJournalsItemType, 'item'> & { item: ResolversTypes['ItemUnionType'] }>;
  AllJournalsJournalType: ResolverTypeWrapper<AllJournalsJournalType>;
  AppVersionType: ResolverTypeWrapper<AppVersionType>;
  AttachmentType: ResolverTypeWrapper<AttachmentType>;
  AvailabilityInput: AvailabilityInput;
  AvailabilityInputMM: AvailabilityInputMm;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  AwardypeType: ResolverTypeWrapper<AwardypeType>;
  Boolean_comparison_exp: BooleanComparisonExp;
  ChatTokenType: ResolverTypeWrapper<ChatTokenType>;
  ConnectionStatusType: ResolverTypeWrapper<ConnectionStatusType>;
  ConnectionTypeType: ResolverTypeWrapper<ConnectionTypeType>;
  ContactTypeType: ResolverTypeWrapper<ContactTypeType>;
  ConversationParticipantsType: ResolverTypeWrapper<ConversationParticipantsType>;
  CountryType: ResolverTypeWrapper<CountryType>;
  CreditCardInput: CreditCardInput;
  CreditCardType: ResolverTypeWrapper<CreditCardType>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  EarningsType: ResolverTypeWrapper<EarningsType>;
  EjectGameMasterV2Output: ResolverTypeWrapper<EjectGameMasterV2Output>;
  EndEpisodeOutput: ResolverTypeWrapper<EndEpisodeOutput>;
  EndEpisodeV2Output: ResolverTypeWrapper<EndEpisodeV2Output>;
  EpisoderAttendanceType: ResolverTypeWrapper<EpisoderAttendanceType>;
  FavoriteDiceRollType: ResolverTypeWrapper<FavoriteDiceRollType>;
  FeaturedGmRecommendationReviewsOutput: ResolverTypeWrapper<FeaturedGmRecommendationReviewsOutput>;
  GameTypeType: ResolverTypeWrapper<GameTypeType>;
  GmRatingType: ResolverTypeWrapper<GmRatingType>;
  HandleParticipantLeavesWhisperOutput: ResolverTypeWrapper<HandleParticipantLeavesWhisperOutput>;
  HowUserPlaysOutput: ResolverTypeWrapper<HowUserPlaysOutput>;
  ImmediatelyOpenEpisodeV2Output: ResolverTypeWrapper<ImmediatelyOpenEpisodeV2Output>;
  Int_comparison_exp: IntComparisonExp;
  InvitationType: ResolverTypeWrapper<InvitationType>;
  ItemUnionType: ResolversTypes['JournalNoteType'] | ResolversTypes['JournalSuperEventType'];
  JoinAdventuresAdsOutput: ResolverTypeWrapper<JoinAdventuresAdsOutput>;
  JoinAdventuresAdsResult: ResolverTypeWrapper<JoinAdventuresAdsResult>;
  JoinSessionInput: JoinSessionInput;
  JournalNoteShareInput: JournalNoteShareInput;
  JournalNoteShareReturnType: ResolverTypeWrapper<JournalNoteShareReturnType>;
  JournalNoteShareType: ResolverTypeWrapper<JournalNoteShareType>;
  JournalNoteType: ResolverTypeWrapper<JournalNoteType>;
  JournalNoteUpdateInput: JournalNoteUpdateInput;
  JournalSuperEventDeleteInput: JournalSuperEventDeleteInput;
  JournalSuperEventDeleteType: ResolverTypeWrapper<JournalSuperEventDeleteType>;
  JournalSuperEventInput: JournalSuperEventInput;
  JournalSuperEventReadStatusType: ResolverTypeWrapper<JournalSuperEventReadStatusType>;
  JournalSuperEventType: ResolverTypeWrapper<JournalSuperEventType>;
  JournalSuperEventUpdateInput: JournalSuperEventUpdateInput;
  JournalTitleType: ResolverTypeWrapper<JournalTitleType>;
  JournalType: ResolverTypeWrapper<JournalType>;
  JournalUpdateInput: JournalUpdateInput;
  LeaveEpisodeV2Output: ResolverTypeWrapper<LeaveEpisodeV2Output>;
  LeaveSessionInput: LeaveSessionInput;
  Mutation: ResolverTypeWrapper<Mutation>;
  MyAdventuresType: ResolverTypeWrapper<MyAdventuresType>;
  NotificationHistoryType: ResolverTypeWrapper<NotificationHistoryType>;
  OpenEpisodeV2Output: ResolverTypeWrapper<OpenEpisodeV2Output>;
  OtherAdventuresAdsOutput: ResolverTypeWrapper<OtherAdventuresAdsOutput>;
  OtherAdventuresAdsResult: ResolverTypeWrapper<OtherAdventuresAdsResult>;
  ParticipantLeavesWhisperOutput: ResolverTypeWrapper<ParticipantLeavesWhisperOutput>;
  PaymentType: ResolverTypeWrapper<PaymentType>;
  PlayerAwardType: ResolverTypeWrapper<PlayerAwardType>;
  PlayerRatingInput: PlayerRatingInput;
  PlayerRatingType: ResolverTypeWrapper<PlayerRatingType>;
  PromoteGameMasterV2Output: ResolverTypeWrapper<PromoteGameMasterV2Output>;
  PronounType: ResolverTypeWrapper<PronounType>;
  Query: ResolverTypeWrapper<Query>;
  RecruitAdventurerOutput: ResolverTypeWrapper<RecruitAdventurerOutput>;
  RecruitAdventurersOutput: ResolverTypeWrapper<RecruitAdventurersOutput>;
  RecruitGameMasterOutput: ResolverTypeWrapper<RecruitGameMasterOutput>;
  RecruitGameMastersOutput: ResolverTypeWrapper<RecruitGameMastersOutput>;
  RecruitSummaryType: ResolverTypeWrapper<RecruitSummaryType>;
  RecruitType: ResolverTypeWrapper<RecruitType>;
  RegionType: ResolverTypeWrapper<RegionType>;
  RemoveAdventurerV2Output: ResolverTypeWrapper<RemoveAdventurerV2Output>;
  ReorderFavoriteGamesOutput: ResolverTypeWrapper<ReorderFavoriteGamesOutput>;
  ReorderFavoritePlatformsOutput: ResolverTypeWrapper<ReorderFavoritePlatformsOutput>;
  RoleType: ResolverTypeWrapper<RoleType>;
  SearchAdDetailMMOutput: ResolverTypeWrapper<SearchAdDetailMmOutput>;
  SearchAdResultDetailLeaderType: ResolverTypeWrapper<SearchAdResultDetailLeaderType>;
  SearchAdResultDetailType: ResolverTypeWrapper<SearchAdResultDetailType>;
  SearchAdResultType: ResolverTypeWrapper<SearchAdResultType>;
  SearchAdsMMOutput: ResolverTypeWrapper<SearchAdsMmOutput>;
  SearchAdsMMResult: ResolverTypeWrapper<SearchAdsMmResult>;
  SessionCancelInput: SessionCancelInput;
  SessionGameplayNotificationInput: SessionGameplayNotificationInput;
  SessionHistoryTipType: ResolverTypeWrapper<SessionHistoryTipType>;
  SessionHistoryType: ResolverTypeWrapper<SessionHistoryType>;
  SessionImmediateOpenInput: SessionImmediateOpenInput;
  SessionMutationType: ResolverTypeWrapper<SessionMutationType>;
  SessionNotificationType: ResolverTypeWrapper<SessionNotificationType>;
  SessionPlayerActiveType: ResolverTypeWrapper<SessionPlayerActiveType>;
  SessionPlayerMutationType: ResolverTypeWrapper<SessionPlayerMutationType>;
  SessionPlayerNotificationType: ResolverTypeWrapper<SessionPlayerNotificationType>;
  SessionPlayerType: ResolverTypeWrapper<SessionPlayerType>;
  SessionRescheduleInput: SessionRescheduleInput;
  SessionScheduleInput: SessionScheduleInput;
  SessionStartInput: SessionStartInput;
  SessionType: ResolverTypeWrapper<SessionType>;
  SnippetType: ResolverTypeWrapper<SnippetType>;
  StartEpisodeV2Output: ResolverTypeWrapper<StartEpisodeV2Output>;
  StatusType: ResolverTypeWrapper<StatusType>;
  String_comparison_exp: StringComparisonExp;
  StripeBalanceTransactionType: ResolverTypeWrapper<StripeBalanceTransactionType>;
  StripeBalanceType: ResolverTypeWrapper<StripeBalanceType>;
  StripeLoginLinkType: ResolverTypeWrapper<StripeLoginLinkType>;
  StripeSourceTypesType: ResolverTypeWrapper<StripeSourceTypesType>;
  StripeUserBalanceType: ResolverTypeWrapper<StripeUserBalanceType>;
  SubmitFileV2Output: ResolverTypeWrapper<SubmitFileV2Output>;
  Subscription: ResolverTypeWrapper<Subscription>;
  TConversation: ResolverTypeWrapper<TConversation>;
  TaskCreateInput: TaskCreateInput;
  TaskReadStatusType: ResolverTypeWrapper<TaskReadStatusType>;
  TaskType: ResolverTypeWrapper<TaskType>;
  TipInput: TipInput;
  TransactionDetailOutput: ResolverTypeWrapper<TransactionDetailOutput>;
  TransactionHistoryOutput: ResolverTypeWrapper<TransactionHistoryOutput>;
  TriggerDisableWhisperV2Output: ResolverTypeWrapper<TriggerDisableWhisperV2Output>;
  TriggerEnableWhisperV2Output: ResolverTypeWrapper<TriggerEnableWhisperV2Output>;
  UpdateBillingAddressOutput: ResolverTypeWrapper<UpdateBillingAddressOutput>;
  UpdateUploadV2Output: ResolverTypeWrapper<UpdateUploadV2Output>;
  UpdateUserInformationV2Output: ResolverTypeWrapper<UpdateUserInformationV2Output>;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  UploadType: ResolverTypeWrapper<UploadType>;
  UserAddressInput: UserAddressInput;
  UserAddressType: ResolverTypeWrapper<UserAddressType>;
  UserAdventureRatingsOutput: ResolverTypeWrapper<UserAdventureRatingsOutput>;
  UserAdventureRoleType: ResolverTypeWrapper<UserAdventureRoleType>;
  UserConversationsType: ResolverTypeWrapper<UserConversationsType>;
  UserEmailNotificationPrefType: ResolverTypeWrapper<UserEmailNotificationPrefType>;
  UserInformationInput: UserInformationInput;
  UserInput: UserInput;
  UserInvitationType: ResolverTypeWrapper<UserInvitationType>;
  UserMetadataInput: UserMetadataInput;
  UserMetadataType: ResolverTypeWrapper<UserMetadataType>;
  UserPlayTimeType: ResolverTypeWrapper<UserPlayTimeType>;
  UserPreferenceInput: UserPreferenceInput;
  UserProfileType: ResolverTypeWrapper<UserProfileType>;
  UserPushNotificationPrefType: ResolverTypeWrapper<UserPushNotificationPrefType>;
  UserRatingsAndAwardsType: ResolverTypeWrapper<UserRatingsAndAwardsType>;
  UserRecruitmentAvailabilityType: ResolverTypeWrapper<UserRecruitmentAvailabilityType>;
  UserRecruitmentInput: UserRecruitmentInput;
  UserRecruitmentType: ResolverTypeWrapper<UserRecruitmentType>;
  UserSocialProfilesOutput: ResolverTypeWrapper<UserSocialProfilesOutput>;
  UserSocialProfilesResult: ResolverTypeWrapper<UserSocialProfilesResult>;
  UserTreasureTroveOutput: ResolverTypeWrapper<UserTreasureTroveOutput>;
  UserType: ResolverTypeWrapper<UserType>;
  authMMOutput: ResolverTypeWrapper<AuthMmOutput>;
  authMMTokenOutput: ResolverTypeWrapper<AuthMmTokenOutput>;
  bigint: ResolverTypeWrapper<Scalars['bigint']>;
  bigint_comparison_exp: BigintComparisonExp;
  date: ResolverTypeWrapper<Scalars['date']>;
  date_comparison_exp: DateComparisonExp;
  demiplane_ad: ResolverTypeWrapper<DemiplaneAd>;
  demiplane_ad_aggregate: ResolverTypeWrapper<DemiplaneAdAggregate>;
  demiplane_ad_aggregate_fields: ResolverTypeWrapper<DemiplaneAdAggregateFields>;
  demiplane_ad_aggregate_order_by: DemiplaneAdAggregateOrderBy;
  demiplane_ad_arr_rel_insert_input: DemiplaneAdArrRelInsertInput;
  demiplane_ad_avg_fields: ResolverTypeWrapper<DemiplaneAdAvgFields>;
  demiplane_ad_avg_order_by: DemiplaneAdAvgOrderBy;
  demiplane_ad_bool_exp: DemiplaneAdBoolExp;
  demiplane_ad_constraint: DemiplaneAdConstraint;
  demiplane_ad_inc_input: DemiplaneAdIncInput;
  demiplane_ad_insert_input: DemiplaneAdInsertInput;
  demiplane_ad_link: ResolverTypeWrapper<DemiplaneAdLink>;
  demiplane_ad_link_aggregate: ResolverTypeWrapper<DemiplaneAdLinkAggregate>;
  demiplane_ad_link_aggregate_fields: ResolverTypeWrapper<DemiplaneAdLinkAggregateFields>;
  demiplane_ad_link_aggregate_order_by: DemiplaneAdLinkAggregateOrderBy;
  demiplane_ad_link_arr_rel_insert_input: DemiplaneAdLinkArrRelInsertInput;
  demiplane_ad_link_avg_fields: ResolverTypeWrapper<DemiplaneAdLinkAvgFields>;
  demiplane_ad_link_avg_order_by: DemiplaneAdLinkAvgOrderBy;
  demiplane_ad_link_bool_exp: DemiplaneAdLinkBoolExp;
  demiplane_ad_link_constraint: DemiplaneAdLinkConstraint;
  demiplane_ad_link_inc_input: DemiplaneAdLinkIncInput;
  demiplane_ad_link_insert_input: DemiplaneAdLinkInsertInput;
  demiplane_ad_link_max_fields: ResolverTypeWrapper<DemiplaneAdLinkMaxFields>;
  demiplane_ad_link_max_order_by: DemiplaneAdLinkMaxOrderBy;
  demiplane_ad_link_min_fields: ResolverTypeWrapper<DemiplaneAdLinkMinFields>;
  demiplane_ad_link_min_order_by: DemiplaneAdLinkMinOrderBy;
  demiplane_ad_link_mutation_response: ResolverTypeWrapper<DemiplaneAdLinkMutationResponse>;
  demiplane_ad_link_obj_rel_insert_input: DemiplaneAdLinkObjRelInsertInput;
  demiplane_ad_link_on_conflict: DemiplaneAdLinkOnConflict;
  demiplane_ad_link_order_by: DemiplaneAdLinkOrderBy;
  demiplane_ad_link_pk_columns_input: DemiplaneAdLinkPkColumnsInput;
  demiplane_ad_link_select_column: DemiplaneAdLinkSelectColumn;
  demiplane_ad_link_set_input: DemiplaneAdLinkSetInput;
  demiplane_ad_link_stddev_fields: ResolverTypeWrapper<DemiplaneAdLinkStddevFields>;
  demiplane_ad_link_stddev_order_by: DemiplaneAdLinkStddevOrderBy;
  demiplane_ad_link_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdLinkStddevPopFields>;
  demiplane_ad_link_stddev_pop_order_by: DemiplaneAdLinkStddevPopOrderBy;
  demiplane_ad_link_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdLinkStddevSampFields>;
  demiplane_ad_link_stddev_samp_order_by: DemiplaneAdLinkStddevSampOrderBy;
  demiplane_ad_link_sum_fields: ResolverTypeWrapper<DemiplaneAdLinkSumFields>;
  demiplane_ad_link_sum_order_by: DemiplaneAdLinkSumOrderBy;
  demiplane_ad_link_update_column: DemiplaneAdLinkUpdateColumn;
  demiplane_ad_link_var_pop_fields: ResolverTypeWrapper<DemiplaneAdLinkVarPopFields>;
  demiplane_ad_link_var_pop_order_by: DemiplaneAdLinkVarPopOrderBy;
  demiplane_ad_link_var_samp_fields: ResolverTypeWrapper<DemiplaneAdLinkVarSampFields>;
  demiplane_ad_link_var_samp_order_by: DemiplaneAdLinkVarSampOrderBy;
  demiplane_ad_link_variance_fields: ResolverTypeWrapper<DemiplaneAdLinkVarianceFields>;
  demiplane_ad_link_variance_order_by: DemiplaneAdLinkVarianceOrderBy;
  demiplane_ad_match_criteria: ResolverTypeWrapper<DemiplaneAdMatchCriteria>;
  demiplane_ad_match_criteria_aggregate: ResolverTypeWrapper<DemiplaneAdMatchCriteriaAggregate>;
  demiplane_ad_match_criteria_aggregate_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaAggregateFields>;
  demiplane_ad_match_criteria_aggregate_order_by: DemiplaneAdMatchCriteriaAggregateOrderBy;
  demiplane_ad_match_criteria_arr_rel_insert_input: DemiplaneAdMatchCriteriaArrRelInsertInput;
  demiplane_ad_match_criteria_avg_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaAvgFields>;
  demiplane_ad_match_criteria_avg_order_by: DemiplaneAdMatchCriteriaAvgOrderBy;
  demiplane_ad_match_criteria_bool_exp: DemiplaneAdMatchCriteriaBoolExp;
  demiplane_ad_match_criteria_constraint: DemiplaneAdMatchCriteriaConstraint;
  demiplane_ad_match_criteria_inc_input: DemiplaneAdMatchCriteriaIncInput;
  demiplane_ad_match_criteria_insert_input: DemiplaneAdMatchCriteriaInsertInput;
  demiplane_ad_match_criteria_max_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaMaxFields>;
  demiplane_ad_match_criteria_max_order_by: DemiplaneAdMatchCriteriaMaxOrderBy;
  demiplane_ad_match_criteria_min_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaMinFields>;
  demiplane_ad_match_criteria_min_order_by: DemiplaneAdMatchCriteriaMinOrderBy;
  demiplane_ad_match_criteria_mutation_response: ResolverTypeWrapper<DemiplaneAdMatchCriteriaMutationResponse>;
  demiplane_ad_match_criteria_obj_rel_insert_input: DemiplaneAdMatchCriteriaObjRelInsertInput;
  demiplane_ad_match_criteria_on_conflict: DemiplaneAdMatchCriteriaOnConflict;
  demiplane_ad_match_criteria_order_by: DemiplaneAdMatchCriteriaOrderBy;
  demiplane_ad_match_criteria_pk_columns_input: DemiplaneAdMatchCriteriaPkColumnsInput;
  demiplane_ad_match_criteria_select_column: DemiplaneAdMatchCriteriaSelectColumn;
  demiplane_ad_match_criteria_set_input: DemiplaneAdMatchCriteriaSetInput;
  demiplane_ad_match_criteria_stddev_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaStddevFields>;
  demiplane_ad_match_criteria_stddev_order_by: DemiplaneAdMatchCriteriaStddevOrderBy;
  demiplane_ad_match_criteria_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaStddevPopFields>;
  demiplane_ad_match_criteria_stddev_pop_order_by: DemiplaneAdMatchCriteriaStddevPopOrderBy;
  demiplane_ad_match_criteria_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaStddevSampFields>;
  demiplane_ad_match_criteria_stddev_samp_order_by: DemiplaneAdMatchCriteriaStddevSampOrderBy;
  demiplane_ad_match_criteria_sum_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaSumFields>;
  demiplane_ad_match_criteria_sum_order_by: DemiplaneAdMatchCriteriaSumOrderBy;
  demiplane_ad_match_criteria_time: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTime>;
  demiplane_ad_match_criteria_time_aggregate: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeAggregate>;
  demiplane_ad_match_criteria_time_aggregate_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeAggregateFields>;
  demiplane_ad_match_criteria_time_aggregate_order_by: DemiplaneAdMatchCriteriaTimeAggregateOrderBy;
  demiplane_ad_match_criteria_time_arr_rel_insert_input: DemiplaneAdMatchCriteriaTimeArrRelInsertInput;
  demiplane_ad_match_criteria_time_avg_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeAvgFields>;
  demiplane_ad_match_criteria_time_avg_order_by: DemiplaneAdMatchCriteriaTimeAvgOrderBy;
  demiplane_ad_match_criteria_time_bool_exp: DemiplaneAdMatchCriteriaTimeBoolExp;
  demiplane_ad_match_criteria_time_constraint: DemiplaneAdMatchCriteriaTimeConstraint;
  demiplane_ad_match_criteria_time_inc_input: DemiplaneAdMatchCriteriaTimeIncInput;
  demiplane_ad_match_criteria_time_insert_input: DemiplaneAdMatchCriteriaTimeInsertInput;
  demiplane_ad_match_criteria_time_max_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeMaxFields>;
  demiplane_ad_match_criteria_time_max_order_by: DemiplaneAdMatchCriteriaTimeMaxOrderBy;
  demiplane_ad_match_criteria_time_min_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeMinFields>;
  demiplane_ad_match_criteria_time_min_order_by: DemiplaneAdMatchCriteriaTimeMinOrderBy;
  demiplane_ad_match_criteria_time_mutation_response: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeMutationResponse>;
  demiplane_ad_match_criteria_time_obj_rel_insert_input: DemiplaneAdMatchCriteriaTimeObjRelInsertInput;
  demiplane_ad_match_criteria_time_on_conflict: DemiplaneAdMatchCriteriaTimeOnConflict;
  demiplane_ad_match_criteria_time_order_by: DemiplaneAdMatchCriteriaTimeOrderBy;
  demiplane_ad_match_criteria_time_pk_columns_input: DemiplaneAdMatchCriteriaTimePkColumnsInput;
  demiplane_ad_match_criteria_time_select_column: DemiplaneAdMatchCriteriaTimeSelectColumn;
  demiplane_ad_match_criteria_time_set_input: DemiplaneAdMatchCriteriaTimeSetInput;
  demiplane_ad_match_criteria_time_stddev_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeStddevFields>;
  demiplane_ad_match_criteria_time_stddev_order_by: DemiplaneAdMatchCriteriaTimeStddevOrderBy;
  demiplane_ad_match_criteria_time_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeStddevPopFields>;
  demiplane_ad_match_criteria_time_stddev_pop_order_by: DemiplaneAdMatchCriteriaTimeStddevPopOrderBy;
  demiplane_ad_match_criteria_time_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeStddevSampFields>;
  demiplane_ad_match_criteria_time_stddev_samp_order_by: DemiplaneAdMatchCriteriaTimeStddevSampOrderBy;
  demiplane_ad_match_criteria_time_sum_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeSumFields>;
  demiplane_ad_match_criteria_time_sum_order_by: DemiplaneAdMatchCriteriaTimeSumOrderBy;
  demiplane_ad_match_criteria_time_update_column: DemiplaneAdMatchCriteriaTimeUpdateColumn;
  demiplane_ad_match_criteria_time_var_pop_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeVarPopFields>;
  demiplane_ad_match_criteria_time_var_pop_order_by: DemiplaneAdMatchCriteriaTimeVarPopOrderBy;
  demiplane_ad_match_criteria_time_var_samp_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeVarSampFields>;
  demiplane_ad_match_criteria_time_var_samp_order_by: DemiplaneAdMatchCriteriaTimeVarSampOrderBy;
  demiplane_ad_match_criteria_time_variance_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaTimeVarianceFields>;
  demiplane_ad_match_criteria_time_variance_order_by: DemiplaneAdMatchCriteriaTimeVarianceOrderBy;
  demiplane_ad_match_criteria_update_column: DemiplaneAdMatchCriteriaUpdateColumn;
  demiplane_ad_match_criteria_var_pop_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaVarPopFields>;
  demiplane_ad_match_criteria_var_pop_order_by: DemiplaneAdMatchCriteriaVarPopOrderBy;
  demiplane_ad_match_criteria_var_samp_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaVarSampFields>;
  demiplane_ad_match_criteria_var_samp_order_by: DemiplaneAdMatchCriteriaVarSampOrderBy;
  demiplane_ad_match_criteria_variance_fields: ResolverTypeWrapper<DemiplaneAdMatchCriteriaVarianceFields>;
  demiplane_ad_match_criteria_variance_order_by: DemiplaneAdMatchCriteriaVarianceOrderBy;
  demiplane_ad_max_fields: ResolverTypeWrapper<DemiplaneAdMaxFields>;
  demiplane_ad_max_order_by: DemiplaneAdMaxOrderBy;
  demiplane_ad_min_fields: ResolverTypeWrapper<DemiplaneAdMinFields>;
  demiplane_ad_min_order_by: DemiplaneAdMinOrderBy;
  demiplane_ad_mutation_response: ResolverTypeWrapper<DemiplaneAdMutationResponse>;
  demiplane_ad_obj_rel_insert_input: DemiplaneAdObjRelInsertInput;
  demiplane_ad_on_conflict: DemiplaneAdOnConflict;
  demiplane_ad_order_by: DemiplaneAdOrderBy;
  demiplane_ad_pk_columns_input: DemiplaneAdPkColumnsInput;
  demiplane_ad_request: ResolverTypeWrapper<DemiplaneAdRequest>;
  demiplane_ad_request_aggregate: ResolverTypeWrapper<DemiplaneAdRequestAggregate>;
  demiplane_ad_request_aggregate_fields: ResolverTypeWrapper<DemiplaneAdRequestAggregateFields>;
  demiplane_ad_request_aggregate_order_by: DemiplaneAdRequestAggregateOrderBy;
  demiplane_ad_request_arr_rel_insert_input: DemiplaneAdRequestArrRelInsertInput;
  demiplane_ad_request_avg_fields: ResolverTypeWrapper<DemiplaneAdRequestAvgFields>;
  demiplane_ad_request_avg_order_by: DemiplaneAdRequestAvgOrderBy;
  demiplane_ad_request_bool_exp: DemiplaneAdRequestBoolExp;
  demiplane_ad_request_constraint: DemiplaneAdRequestConstraint;
  demiplane_ad_request_inc_input: DemiplaneAdRequestIncInput;
  demiplane_ad_request_insert_input: DemiplaneAdRequestInsertInput;
  demiplane_ad_request_max_fields: ResolverTypeWrapper<DemiplaneAdRequestMaxFields>;
  demiplane_ad_request_max_order_by: DemiplaneAdRequestMaxOrderBy;
  demiplane_ad_request_min_fields: ResolverTypeWrapper<DemiplaneAdRequestMinFields>;
  demiplane_ad_request_min_order_by: DemiplaneAdRequestMinOrderBy;
  demiplane_ad_request_mutation_response: ResolverTypeWrapper<DemiplaneAdRequestMutationResponse>;
  demiplane_ad_request_obj_rel_insert_input: DemiplaneAdRequestObjRelInsertInput;
  demiplane_ad_request_on_conflict: DemiplaneAdRequestOnConflict;
  demiplane_ad_request_order_by: DemiplaneAdRequestOrderBy;
  demiplane_ad_request_pk_columns_input: DemiplaneAdRequestPkColumnsInput;
  demiplane_ad_request_select_column: DemiplaneAdRequestSelectColumn;
  demiplane_ad_request_set_input: DemiplaneAdRequestSetInput;
  demiplane_ad_request_stddev_fields: ResolverTypeWrapper<DemiplaneAdRequestStddevFields>;
  demiplane_ad_request_stddev_order_by: DemiplaneAdRequestStddevOrderBy;
  demiplane_ad_request_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdRequestStddevPopFields>;
  demiplane_ad_request_stddev_pop_order_by: DemiplaneAdRequestStddevPopOrderBy;
  demiplane_ad_request_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdRequestStddevSampFields>;
  demiplane_ad_request_stddev_samp_order_by: DemiplaneAdRequestStddevSampOrderBy;
  demiplane_ad_request_sum_fields: ResolverTypeWrapper<DemiplaneAdRequestSumFields>;
  demiplane_ad_request_sum_order_by: DemiplaneAdRequestSumOrderBy;
  demiplane_ad_request_update_column: DemiplaneAdRequestUpdateColumn;
  demiplane_ad_request_var_pop_fields: ResolverTypeWrapper<DemiplaneAdRequestVarPopFields>;
  demiplane_ad_request_var_pop_order_by: DemiplaneAdRequestVarPopOrderBy;
  demiplane_ad_request_var_samp_fields: ResolverTypeWrapper<DemiplaneAdRequestVarSampFields>;
  demiplane_ad_request_var_samp_order_by: DemiplaneAdRequestVarSampOrderBy;
  demiplane_ad_request_variance_fields: ResolverTypeWrapper<DemiplaneAdRequestVarianceFields>;
  demiplane_ad_request_variance_order_by: DemiplaneAdRequestVarianceOrderBy;
  demiplane_ad_select_column: DemiplaneAdSelectColumn;
  demiplane_ad_set_input: DemiplaneAdSetInput;
  demiplane_ad_stddev_fields: ResolverTypeWrapper<DemiplaneAdStddevFields>;
  demiplane_ad_stddev_order_by: DemiplaneAdStddevOrderBy;
  demiplane_ad_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdStddevPopFields>;
  demiplane_ad_stddev_pop_order_by: DemiplaneAdStddevPopOrderBy;
  demiplane_ad_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdStddevSampFields>;
  demiplane_ad_stddev_samp_order_by: DemiplaneAdStddevSampOrderBy;
  demiplane_ad_sum_fields: ResolverTypeWrapper<DemiplaneAdSumFields>;
  demiplane_ad_sum_order_by: DemiplaneAdSumOrderBy;
  demiplane_ad_time: ResolverTypeWrapper<DemiplaneAdTime>;
  demiplane_ad_time_aggregate: ResolverTypeWrapper<DemiplaneAdTimeAggregate>;
  demiplane_ad_time_aggregate_fields: ResolverTypeWrapper<DemiplaneAdTimeAggregateFields>;
  demiplane_ad_time_aggregate_order_by: DemiplaneAdTimeAggregateOrderBy;
  demiplane_ad_time_arr_rel_insert_input: DemiplaneAdTimeArrRelInsertInput;
  demiplane_ad_time_avg_fields: ResolverTypeWrapper<DemiplaneAdTimeAvgFields>;
  demiplane_ad_time_avg_order_by: DemiplaneAdTimeAvgOrderBy;
  demiplane_ad_time_bool_exp: DemiplaneAdTimeBoolExp;
  demiplane_ad_time_constraint: DemiplaneAdTimeConstraint;
  demiplane_ad_time_inc_input: DemiplaneAdTimeIncInput;
  demiplane_ad_time_insert_input: DemiplaneAdTimeInsertInput;
  demiplane_ad_time_max_fields: ResolverTypeWrapper<DemiplaneAdTimeMaxFields>;
  demiplane_ad_time_max_order_by: DemiplaneAdTimeMaxOrderBy;
  demiplane_ad_time_min_fields: ResolverTypeWrapper<DemiplaneAdTimeMinFields>;
  demiplane_ad_time_min_order_by: DemiplaneAdTimeMinOrderBy;
  demiplane_ad_time_mutation_response: ResolverTypeWrapper<DemiplaneAdTimeMutationResponse>;
  demiplane_ad_time_obj_rel_insert_input: DemiplaneAdTimeObjRelInsertInput;
  demiplane_ad_time_on_conflict: DemiplaneAdTimeOnConflict;
  demiplane_ad_time_order_by: DemiplaneAdTimeOrderBy;
  demiplane_ad_time_pk_columns_input: DemiplaneAdTimePkColumnsInput;
  demiplane_ad_time_select_column: DemiplaneAdTimeSelectColumn;
  demiplane_ad_time_set_input: DemiplaneAdTimeSetInput;
  demiplane_ad_time_stddev_fields: ResolverTypeWrapper<DemiplaneAdTimeStddevFields>;
  demiplane_ad_time_stddev_order_by: DemiplaneAdTimeStddevOrderBy;
  demiplane_ad_time_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdTimeStddevPopFields>;
  demiplane_ad_time_stddev_pop_order_by: DemiplaneAdTimeStddevPopOrderBy;
  demiplane_ad_time_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdTimeStddevSampFields>;
  demiplane_ad_time_stddev_samp_order_by: DemiplaneAdTimeStddevSampOrderBy;
  demiplane_ad_time_sum_fields: ResolverTypeWrapper<DemiplaneAdTimeSumFields>;
  demiplane_ad_time_sum_order_by: DemiplaneAdTimeSumOrderBy;
  demiplane_ad_time_update_column: DemiplaneAdTimeUpdateColumn;
  demiplane_ad_time_var_pop_fields: ResolverTypeWrapper<DemiplaneAdTimeVarPopFields>;
  demiplane_ad_time_var_pop_order_by: DemiplaneAdTimeVarPopOrderBy;
  demiplane_ad_time_var_samp_fields: ResolverTypeWrapper<DemiplaneAdTimeVarSampFields>;
  demiplane_ad_time_var_samp_order_by: DemiplaneAdTimeVarSampOrderBy;
  demiplane_ad_time_variance_fields: ResolverTypeWrapper<DemiplaneAdTimeVarianceFields>;
  demiplane_ad_time_variance_order_by: DemiplaneAdTimeVarianceOrderBy;
  demiplane_ad_update_column: DemiplaneAdUpdateColumn;
  demiplane_ad_var_pop_fields: ResolverTypeWrapper<DemiplaneAdVarPopFields>;
  demiplane_ad_var_pop_order_by: DemiplaneAdVarPopOrderBy;
  demiplane_ad_var_samp_fields: ResolverTypeWrapper<DemiplaneAdVarSampFields>;
  demiplane_ad_var_samp_order_by: DemiplaneAdVarSampOrderBy;
  demiplane_ad_variance_fields: ResolverTypeWrapper<DemiplaneAdVarianceFields>;
  demiplane_ad_variance_order_by: DemiplaneAdVarianceOrderBy;
  demiplane_adventure: ResolverTypeWrapper<DemiplaneAdventure>;
  demiplane_adventure_aggregate: ResolverTypeWrapper<DemiplaneAdventureAggregate>;
  demiplane_adventure_aggregate_fields: ResolverTypeWrapper<DemiplaneAdventureAggregateFields>;
  demiplane_adventure_aggregate_order_by: DemiplaneAdventureAggregateOrderBy;
  demiplane_adventure_arr_rel_insert_input: DemiplaneAdventureArrRelInsertInput;
  demiplane_adventure_avg_fields: ResolverTypeWrapper<DemiplaneAdventureAvgFields>;
  demiplane_adventure_avg_order_by: DemiplaneAdventureAvgOrderBy;
  demiplane_adventure_bool_exp: DemiplaneAdventureBoolExp;
  demiplane_adventure_connection: ResolverTypeWrapper<DemiplaneAdventureConnection>;
  demiplane_adventure_connection_aggregate: ResolverTypeWrapper<DemiplaneAdventureConnectionAggregate>;
  demiplane_adventure_connection_aggregate_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionAggregateFields>;
  demiplane_adventure_connection_aggregate_order_by: DemiplaneAdventureConnectionAggregateOrderBy;
  demiplane_adventure_connection_arr_rel_insert_input: DemiplaneAdventureConnectionArrRelInsertInput;
  demiplane_adventure_connection_avg_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionAvgFields>;
  demiplane_adventure_connection_avg_order_by: DemiplaneAdventureConnectionAvgOrderBy;
  demiplane_adventure_connection_bool_exp: DemiplaneAdventureConnectionBoolExp;
  demiplane_adventure_connection_constraint: DemiplaneAdventureConnectionConstraint;
  demiplane_adventure_connection_inc_input: DemiplaneAdventureConnectionIncInput;
  demiplane_adventure_connection_insert_input: DemiplaneAdventureConnectionInsertInput;
  demiplane_adventure_connection_max_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionMaxFields>;
  demiplane_adventure_connection_max_order_by: DemiplaneAdventureConnectionMaxOrderBy;
  demiplane_adventure_connection_min_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionMinFields>;
  demiplane_adventure_connection_min_order_by: DemiplaneAdventureConnectionMinOrderBy;
  demiplane_adventure_connection_mutation_response: ResolverTypeWrapper<DemiplaneAdventureConnectionMutationResponse>;
  demiplane_adventure_connection_obj_rel_insert_input: DemiplaneAdventureConnectionObjRelInsertInput;
  demiplane_adventure_connection_on_conflict: DemiplaneAdventureConnectionOnConflict;
  demiplane_adventure_connection_order_by: DemiplaneAdventureConnectionOrderBy;
  demiplane_adventure_connection_pk_columns_input: DemiplaneAdventureConnectionPkColumnsInput;
  demiplane_adventure_connection_select_column: DemiplaneAdventureConnectionSelectColumn;
  demiplane_adventure_connection_set_input: DemiplaneAdventureConnectionSetInput;
  demiplane_adventure_connection_stddev_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionStddevFields>;
  demiplane_adventure_connection_stddev_order_by: DemiplaneAdventureConnectionStddevOrderBy;
  demiplane_adventure_connection_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionStddevPopFields>;
  demiplane_adventure_connection_stddev_pop_order_by: DemiplaneAdventureConnectionStddevPopOrderBy;
  demiplane_adventure_connection_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionStddevSampFields>;
  demiplane_adventure_connection_stddev_samp_order_by: DemiplaneAdventureConnectionStddevSampOrderBy;
  demiplane_adventure_connection_sum_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionSumFields>;
  demiplane_adventure_connection_sum_order_by: DemiplaneAdventureConnectionSumOrderBy;
  demiplane_adventure_connection_update_column: DemiplaneAdventureConnectionUpdateColumn;
  demiplane_adventure_connection_var_pop_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionVarPopFields>;
  demiplane_adventure_connection_var_pop_order_by: DemiplaneAdventureConnectionVarPopOrderBy;
  demiplane_adventure_connection_var_samp_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionVarSampFields>;
  demiplane_adventure_connection_var_samp_order_by: DemiplaneAdventureConnectionVarSampOrderBy;
  demiplane_adventure_connection_variance_fields: ResolverTypeWrapper<DemiplaneAdventureConnectionVarianceFields>;
  demiplane_adventure_connection_variance_order_by: DemiplaneAdventureConnectionVarianceOrderBy;
  demiplane_adventure_constraint: DemiplaneAdventureConstraint;
  demiplane_adventure_demiplane_tool: ResolverTypeWrapper<DemiplaneAdventureDemiplaneTool>;
  demiplane_adventure_demiplane_tool_aggregate: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolAggregate>;
  demiplane_adventure_demiplane_tool_aggregate_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolAggregateFields>;
  demiplane_adventure_demiplane_tool_aggregate_order_by: DemiplaneAdventureDemiplaneToolAggregateOrderBy;
  demiplane_adventure_demiplane_tool_arr_rel_insert_input: DemiplaneAdventureDemiplaneToolArrRelInsertInput;
  demiplane_adventure_demiplane_tool_avg_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolAvgFields>;
  demiplane_adventure_demiplane_tool_avg_order_by: DemiplaneAdventureDemiplaneToolAvgOrderBy;
  demiplane_adventure_demiplane_tool_bool_exp: DemiplaneAdventureDemiplaneToolBoolExp;
  demiplane_adventure_demiplane_tool_constraint: DemiplaneAdventureDemiplaneToolConstraint;
  demiplane_adventure_demiplane_tool_inc_input: DemiplaneAdventureDemiplaneToolIncInput;
  demiplane_adventure_demiplane_tool_insert_input: DemiplaneAdventureDemiplaneToolInsertInput;
  demiplane_adventure_demiplane_tool_max_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolMaxFields>;
  demiplane_adventure_demiplane_tool_max_order_by: DemiplaneAdventureDemiplaneToolMaxOrderBy;
  demiplane_adventure_demiplane_tool_min_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolMinFields>;
  demiplane_adventure_demiplane_tool_min_order_by: DemiplaneAdventureDemiplaneToolMinOrderBy;
  demiplane_adventure_demiplane_tool_mutation_response: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolMutationResponse>;
  demiplane_adventure_demiplane_tool_obj_rel_insert_input: DemiplaneAdventureDemiplaneToolObjRelInsertInput;
  demiplane_adventure_demiplane_tool_on_conflict: DemiplaneAdventureDemiplaneToolOnConflict;
  demiplane_adventure_demiplane_tool_order_by: DemiplaneAdventureDemiplaneToolOrderBy;
  demiplane_adventure_demiplane_tool_pk_columns_input: DemiplaneAdventureDemiplaneToolPkColumnsInput;
  demiplane_adventure_demiplane_tool_select_column: DemiplaneAdventureDemiplaneToolSelectColumn;
  demiplane_adventure_demiplane_tool_set_input: DemiplaneAdventureDemiplaneToolSetInput;
  demiplane_adventure_demiplane_tool_stddev_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolStddevFields>;
  demiplane_adventure_demiplane_tool_stddev_order_by: DemiplaneAdventureDemiplaneToolStddevOrderBy;
  demiplane_adventure_demiplane_tool_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolStddevPopFields>;
  demiplane_adventure_demiplane_tool_stddev_pop_order_by: DemiplaneAdventureDemiplaneToolStddevPopOrderBy;
  demiplane_adventure_demiplane_tool_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolStddevSampFields>;
  demiplane_adventure_demiplane_tool_stddev_samp_order_by: DemiplaneAdventureDemiplaneToolStddevSampOrderBy;
  demiplane_adventure_demiplane_tool_sum_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolSumFields>;
  demiplane_adventure_demiplane_tool_sum_order_by: DemiplaneAdventureDemiplaneToolSumOrderBy;
  demiplane_adventure_demiplane_tool_update_column: DemiplaneAdventureDemiplaneToolUpdateColumn;
  demiplane_adventure_demiplane_tool_var_pop_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolVarPopFields>;
  demiplane_adventure_demiplane_tool_var_pop_order_by: DemiplaneAdventureDemiplaneToolVarPopOrderBy;
  demiplane_adventure_demiplane_tool_var_samp_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolVarSampFields>;
  demiplane_adventure_demiplane_tool_var_samp_order_by: DemiplaneAdventureDemiplaneToolVarSampOrderBy;
  demiplane_adventure_demiplane_tool_variance_fields: ResolverTypeWrapper<DemiplaneAdventureDemiplaneToolVarianceFields>;
  demiplane_adventure_demiplane_tool_variance_order_by: DemiplaneAdventureDemiplaneToolVarianceOrderBy;
  demiplane_adventure_inc_input: DemiplaneAdventureIncInput;
  demiplane_adventure_insert_input: DemiplaneAdventureInsertInput;
  demiplane_adventure_link: ResolverTypeWrapper<DemiplaneAdventureLink>;
  demiplane_adventure_link_aggregate: ResolverTypeWrapper<DemiplaneAdventureLinkAggregate>;
  demiplane_adventure_link_aggregate_fields: ResolverTypeWrapper<DemiplaneAdventureLinkAggregateFields>;
  demiplane_adventure_link_aggregate_order_by: DemiplaneAdventureLinkAggregateOrderBy;
  demiplane_adventure_link_arr_rel_insert_input: DemiplaneAdventureLinkArrRelInsertInput;
  demiplane_adventure_link_avg_fields: ResolverTypeWrapper<DemiplaneAdventureLinkAvgFields>;
  demiplane_adventure_link_avg_order_by: DemiplaneAdventureLinkAvgOrderBy;
  demiplane_adventure_link_bool_exp: DemiplaneAdventureLinkBoolExp;
  demiplane_adventure_link_constraint: DemiplaneAdventureLinkConstraint;
  demiplane_adventure_link_inc_input: DemiplaneAdventureLinkIncInput;
  demiplane_adventure_link_insert_input: DemiplaneAdventureLinkInsertInput;
  demiplane_adventure_link_max_fields: ResolverTypeWrapper<DemiplaneAdventureLinkMaxFields>;
  demiplane_adventure_link_max_order_by: DemiplaneAdventureLinkMaxOrderBy;
  demiplane_adventure_link_min_fields: ResolverTypeWrapper<DemiplaneAdventureLinkMinFields>;
  demiplane_adventure_link_min_order_by: DemiplaneAdventureLinkMinOrderBy;
  demiplane_adventure_link_mutation_response: ResolverTypeWrapper<DemiplaneAdventureLinkMutationResponse>;
  demiplane_adventure_link_obj_rel_insert_input: DemiplaneAdventureLinkObjRelInsertInput;
  demiplane_adventure_link_on_conflict: DemiplaneAdventureLinkOnConflict;
  demiplane_adventure_link_order_by: DemiplaneAdventureLinkOrderBy;
  demiplane_adventure_link_pk_columns_input: DemiplaneAdventureLinkPkColumnsInput;
  demiplane_adventure_link_select_column: DemiplaneAdventureLinkSelectColumn;
  demiplane_adventure_link_set_input: DemiplaneAdventureLinkSetInput;
  demiplane_adventure_link_stddev_fields: ResolverTypeWrapper<DemiplaneAdventureLinkStddevFields>;
  demiplane_adventure_link_stddev_order_by: DemiplaneAdventureLinkStddevOrderBy;
  demiplane_adventure_link_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdventureLinkStddevPopFields>;
  demiplane_adventure_link_stddev_pop_order_by: DemiplaneAdventureLinkStddevPopOrderBy;
  demiplane_adventure_link_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdventureLinkStddevSampFields>;
  demiplane_adventure_link_stddev_samp_order_by: DemiplaneAdventureLinkStddevSampOrderBy;
  demiplane_adventure_link_sum_fields: ResolverTypeWrapper<DemiplaneAdventureLinkSumFields>;
  demiplane_adventure_link_sum_order_by: DemiplaneAdventureLinkSumOrderBy;
  demiplane_adventure_link_update_column: DemiplaneAdventureLinkUpdateColumn;
  demiplane_adventure_link_var_pop_fields: ResolverTypeWrapper<DemiplaneAdventureLinkVarPopFields>;
  demiplane_adventure_link_var_pop_order_by: DemiplaneAdventureLinkVarPopOrderBy;
  demiplane_adventure_link_var_samp_fields: ResolverTypeWrapper<DemiplaneAdventureLinkVarSampFields>;
  demiplane_adventure_link_var_samp_order_by: DemiplaneAdventureLinkVarSampOrderBy;
  demiplane_adventure_link_variance_fields: ResolverTypeWrapper<DemiplaneAdventureLinkVarianceFields>;
  demiplane_adventure_link_variance_order_by: DemiplaneAdventureLinkVarianceOrderBy;
  demiplane_adventure_max_fields: ResolverTypeWrapper<DemiplaneAdventureMaxFields>;
  demiplane_adventure_max_order_by: DemiplaneAdventureMaxOrderBy;
  demiplane_adventure_min_fields: ResolverTypeWrapper<DemiplaneAdventureMinFields>;
  demiplane_adventure_min_order_by: DemiplaneAdventureMinOrderBy;
  demiplane_adventure_mutation_response: ResolverTypeWrapper<DemiplaneAdventureMutationResponse>;
  demiplane_adventure_obj_rel_insert_input: DemiplaneAdventureObjRelInsertInput;
  demiplane_adventure_on_conflict: DemiplaneAdventureOnConflict;
  demiplane_adventure_order_by: DemiplaneAdventureOrderBy;
  demiplane_adventure_pk_columns_input: DemiplaneAdventurePkColumnsInput;
  demiplane_adventure_player: ResolverTypeWrapper<DemiplaneAdventurePlayer>;
  demiplane_adventure_player_aggregate: ResolverTypeWrapper<DemiplaneAdventurePlayerAggregate>;
  demiplane_adventure_player_aggregate_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerAggregateFields>;
  demiplane_adventure_player_aggregate_order_by: DemiplaneAdventurePlayerAggregateOrderBy;
  demiplane_adventure_player_arr_rel_insert_input: DemiplaneAdventurePlayerArrRelInsertInput;
  demiplane_adventure_player_avg_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerAvgFields>;
  demiplane_adventure_player_avg_order_by: DemiplaneAdventurePlayerAvgOrderBy;
  demiplane_adventure_player_bool_exp: DemiplaneAdventurePlayerBoolExp;
  demiplane_adventure_player_constraint: DemiplaneAdventurePlayerConstraint;
  demiplane_adventure_player_inc_input: DemiplaneAdventurePlayerIncInput;
  demiplane_adventure_player_insert_input: DemiplaneAdventurePlayerInsertInput;
  demiplane_adventure_player_max_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerMaxFields>;
  demiplane_adventure_player_max_order_by: DemiplaneAdventurePlayerMaxOrderBy;
  demiplane_adventure_player_min_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerMinFields>;
  demiplane_adventure_player_min_order_by: DemiplaneAdventurePlayerMinOrderBy;
  demiplane_adventure_player_mutation_response: ResolverTypeWrapper<DemiplaneAdventurePlayerMutationResponse>;
  demiplane_adventure_player_obj_rel_insert_input: DemiplaneAdventurePlayerObjRelInsertInput;
  demiplane_adventure_player_on_conflict: DemiplaneAdventurePlayerOnConflict;
  demiplane_adventure_player_order_by: DemiplaneAdventurePlayerOrderBy;
  demiplane_adventure_player_pk_columns_input: DemiplaneAdventurePlayerPkColumnsInput;
  demiplane_adventure_player_select_column: DemiplaneAdventurePlayerSelectColumn;
  demiplane_adventure_player_set_input: DemiplaneAdventurePlayerSetInput;
  demiplane_adventure_player_stddev_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerStddevFields>;
  demiplane_adventure_player_stddev_order_by: DemiplaneAdventurePlayerStddevOrderBy;
  demiplane_adventure_player_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerStddevPopFields>;
  demiplane_adventure_player_stddev_pop_order_by: DemiplaneAdventurePlayerStddevPopOrderBy;
  demiplane_adventure_player_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerStddevSampFields>;
  demiplane_adventure_player_stddev_samp_order_by: DemiplaneAdventurePlayerStddevSampOrderBy;
  demiplane_adventure_player_sum_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerSumFields>;
  demiplane_adventure_player_sum_order_by: DemiplaneAdventurePlayerSumOrderBy;
  demiplane_adventure_player_update_column: DemiplaneAdventurePlayerUpdateColumn;
  demiplane_adventure_player_var_pop_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerVarPopFields>;
  demiplane_adventure_player_var_pop_order_by: DemiplaneAdventurePlayerVarPopOrderBy;
  demiplane_adventure_player_var_samp_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerVarSampFields>;
  demiplane_adventure_player_var_samp_order_by: DemiplaneAdventurePlayerVarSampOrderBy;
  demiplane_adventure_player_variance_fields: ResolverTypeWrapper<DemiplaneAdventurePlayerVarianceFields>;
  demiplane_adventure_player_variance_order_by: DemiplaneAdventurePlayerVarianceOrderBy;
  demiplane_adventure_select_column: DemiplaneAdventureSelectColumn;
  demiplane_adventure_set_input: DemiplaneAdventureSetInput;
  demiplane_adventure_stddev_fields: ResolverTypeWrapper<DemiplaneAdventureStddevFields>;
  demiplane_adventure_stddev_order_by: DemiplaneAdventureStddevOrderBy;
  demiplane_adventure_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdventureStddevPopFields>;
  demiplane_adventure_stddev_pop_order_by: DemiplaneAdventureStddevPopOrderBy;
  demiplane_adventure_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdventureStddevSampFields>;
  demiplane_adventure_stddev_samp_order_by: DemiplaneAdventureStddevSampOrderBy;
  demiplane_adventure_sum_fields: ResolverTypeWrapper<DemiplaneAdventureSumFields>;
  demiplane_adventure_sum_order_by: DemiplaneAdventureSumOrderBy;
  demiplane_adventure_todo: ResolverTypeWrapper<DemiplaneAdventureTodo>;
  demiplane_adventure_todo_aggregate: ResolverTypeWrapper<DemiplaneAdventureTodoAggregate>;
  demiplane_adventure_todo_aggregate_fields: ResolverTypeWrapper<DemiplaneAdventureTodoAggregateFields>;
  demiplane_adventure_todo_aggregate_order_by: DemiplaneAdventureTodoAggregateOrderBy;
  demiplane_adventure_todo_arr_rel_insert_input: DemiplaneAdventureTodoArrRelInsertInput;
  demiplane_adventure_todo_avg_fields: ResolverTypeWrapper<DemiplaneAdventureTodoAvgFields>;
  demiplane_adventure_todo_avg_order_by: DemiplaneAdventureTodoAvgOrderBy;
  demiplane_adventure_todo_bool_exp: DemiplaneAdventureTodoBoolExp;
  demiplane_adventure_todo_constraint: DemiplaneAdventureTodoConstraint;
  demiplane_adventure_todo_inc_input: DemiplaneAdventureTodoIncInput;
  demiplane_adventure_todo_insert_input: DemiplaneAdventureTodoInsertInput;
  demiplane_adventure_todo_max_fields: ResolverTypeWrapper<DemiplaneAdventureTodoMaxFields>;
  demiplane_adventure_todo_max_order_by: DemiplaneAdventureTodoMaxOrderBy;
  demiplane_adventure_todo_min_fields: ResolverTypeWrapper<DemiplaneAdventureTodoMinFields>;
  demiplane_adventure_todo_min_order_by: DemiplaneAdventureTodoMinOrderBy;
  demiplane_adventure_todo_mutation_response: ResolverTypeWrapper<DemiplaneAdventureTodoMutationResponse>;
  demiplane_adventure_todo_obj_rel_insert_input: DemiplaneAdventureTodoObjRelInsertInput;
  demiplane_adventure_todo_on_conflict: DemiplaneAdventureTodoOnConflict;
  demiplane_adventure_todo_order_by: DemiplaneAdventureTodoOrderBy;
  demiplane_adventure_todo_pk_columns_input: DemiplaneAdventureTodoPkColumnsInput;
  demiplane_adventure_todo_select_column: DemiplaneAdventureTodoSelectColumn;
  demiplane_adventure_todo_set_input: DemiplaneAdventureTodoSetInput;
  demiplane_adventure_todo_stddev_fields: ResolverTypeWrapper<DemiplaneAdventureTodoStddevFields>;
  demiplane_adventure_todo_stddev_order_by: DemiplaneAdventureTodoStddevOrderBy;
  demiplane_adventure_todo_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdventureTodoStddevPopFields>;
  demiplane_adventure_todo_stddev_pop_order_by: DemiplaneAdventureTodoStddevPopOrderBy;
  demiplane_adventure_todo_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdventureTodoStddevSampFields>;
  demiplane_adventure_todo_stddev_samp_order_by: DemiplaneAdventureTodoStddevSampOrderBy;
  demiplane_adventure_todo_sum_fields: ResolverTypeWrapper<DemiplaneAdventureTodoSumFields>;
  demiplane_adventure_todo_sum_order_by: DemiplaneAdventureTodoSumOrderBy;
  demiplane_adventure_todo_update_column: DemiplaneAdventureTodoUpdateColumn;
  demiplane_adventure_todo_var_pop_fields: ResolverTypeWrapper<DemiplaneAdventureTodoVarPopFields>;
  demiplane_adventure_todo_var_pop_order_by: DemiplaneAdventureTodoVarPopOrderBy;
  demiplane_adventure_todo_var_samp_fields: ResolverTypeWrapper<DemiplaneAdventureTodoVarSampFields>;
  demiplane_adventure_todo_var_samp_order_by: DemiplaneAdventureTodoVarSampOrderBy;
  demiplane_adventure_todo_variance_fields: ResolverTypeWrapper<DemiplaneAdventureTodoVarianceFields>;
  demiplane_adventure_todo_variance_order_by: DemiplaneAdventureTodoVarianceOrderBy;
  demiplane_adventure_update_column: DemiplaneAdventureUpdateColumn;
  demiplane_adventure_var_pop_fields: ResolverTypeWrapper<DemiplaneAdventureVarPopFields>;
  demiplane_adventure_var_pop_order_by: DemiplaneAdventureVarPopOrderBy;
  demiplane_adventure_var_samp_fields: ResolverTypeWrapper<DemiplaneAdventureVarSampFields>;
  demiplane_adventure_var_samp_order_by: DemiplaneAdventureVarSampOrderBy;
  demiplane_adventure_variance_fields: ResolverTypeWrapper<DemiplaneAdventureVarianceFields>;
  demiplane_adventure_variance_order_by: DemiplaneAdventureVarianceOrderBy;
  demiplane_adventuring_platform: ResolverTypeWrapper<DemiplaneAdventuringPlatform>;
  demiplane_adventuring_platform_aggregate: ResolverTypeWrapper<DemiplaneAdventuringPlatformAggregate>;
  demiplane_adventuring_platform_aggregate_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformAggregateFields>;
  demiplane_adventuring_platform_aggregate_order_by: DemiplaneAdventuringPlatformAggregateOrderBy;
  demiplane_adventuring_platform_arr_rel_insert_input: DemiplaneAdventuringPlatformArrRelInsertInput;
  demiplane_adventuring_platform_avg_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformAvgFields>;
  demiplane_adventuring_platform_avg_order_by: DemiplaneAdventuringPlatformAvgOrderBy;
  demiplane_adventuring_platform_bool_exp: DemiplaneAdventuringPlatformBoolExp;
  demiplane_adventuring_platform_constraint: DemiplaneAdventuringPlatformConstraint;
  demiplane_adventuring_platform_inc_input: DemiplaneAdventuringPlatformIncInput;
  demiplane_adventuring_platform_insert_input: DemiplaneAdventuringPlatformInsertInput;
  demiplane_adventuring_platform_max_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformMaxFields>;
  demiplane_adventuring_platform_max_order_by: DemiplaneAdventuringPlatformMaxOrderBy;
  demiplane_adventuring_platform_min_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformMinFields>;
  demiplane_adventuring_platform_min_order_by: DemiplaneAdventuringPlatformMinOrderBy;
  demiplane_adventuring_platform_mutation_response: ResolverTypeWrapper<DemiplaneAdventuringPlatformMutationResponse>;
  demiplane_adventuring_platform_obj_rel_insert_input: DemiplaneAdventuringPlatformObjRelInsertInput;
  demiplane_adventuring_platform_on_conflict: DemiplaneAdventuringPlatformOnConflict;
  demiplane_adventuring_platform_order_by: DemiplaneAdventuringPlatformOrderBy;
  demiplane_adventuring_platform_pk_columns_input: DemiplaneAdventuringPlatformPkColumnsInput;
  demiplane_adventuring_platform_select_column: DemiplaneAdventuringPlatformSelectColumn;
  demiplane_adventuring_platform_set_input: DemiplaneAdventuringPlatformSetInput;
  demiplane_adventuring_platform_stddev_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformStddevFields>;
  demiplane_adventuring_platform_stddev_order_by: DemiplaneAdventuringPlatformStddevOrderBy;
  demiplane_adventuring_platform_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformStddevPopFields>;
  demiplane_adventuring_platform_stddev_pop_order_by: DemiplaneAdventuringPlatformStddevPopOrderBy;
  demiplane_adventuring_platform_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformStddevSampFields>;
  demiplane_adventuring_platform_stddev_samp_order_by: DemiplaneAdventuringPlatformStddevSampOrderBy;
  demiplane_adventuring_platform_sum_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformSumFields>;
  demiplane_adventuring_platform_sum_order_by: DemiplaneAdventuringPlatformSumOrderBy;
  demiplane_adventuring_platform_update_column: DemiplaneAdventuringPlatformUpdateColumn;
  demiplane_adventuring_platform_var_pop_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformVarPopFields>;
  demiplane_adventuring_platform_var_pop_order_by: DemiplaneAdventuringPlatformVarPopOrderBy;
  demiplane_adventuring_platform_var_samp_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformVarSampFields>;
  demiplane_adventuring_platform_var_samp_order_by: DemiplaneAdventuringPlatformVarSampOrderBy;
  demiplane_adventuring_platform_variance_fields: ResolverTypeWrapper<DemiplaneAdventuringPlatformVarianceFields>;
  demiplane_adventuring_platform_variance_order_by: DemiplaneAdventuringPlatformVarianceOrderBy;
  demiplane_amc_adventuring_platforms: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatforms>;
  demiplane_amc_adventuring_platforms_aggregate: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsAggregate>;
  demiplane_amc_adventuring_platforms_aggregate_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsAggregateFields>;
  demiplane_amc_adventuring_platforms_aggregate_order_by: DemiplaneAmcAdventuringPlatformsAggregateOrderBy;
  demiplane_amc_adventuring_platforms_arr_rel_insert_input: DemiplaneAmcAdventuringPlatformsArrRelInsertInput;
  demiplane_amc_adventuring_platforms_avg_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsAvgFields>;
  demiplane_amc_adventuring_platforms_avg_order_by: DemiplaneAmcAdventuringPlatformsAvgOrderBy;
  demiplane_amc_adventuring_platforms_bool_exp: DemiplaneAmcAdventuringPlatformsBoolExp;
  demiplane_amc_adventuring_platforms_constraint: DemiplaneAmcAdventuringPlatformsConstraint;
  demiplane_amc_adventuring_platforms_inc_input: DemiplaneAmcAdventuringPlatformsIncInput;
  demiplane_amc_adventuring_platforms_insert_input: DemiplaneAmcAdventuringPlatformsInsertInput;
  demiplane_amc_adventuring_platforms_max_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsMaxFields>;
  demiplane_amc_adventuring_platforms_max_order_by: DemiplaneAmcAdventuringPlatformsMaxOrderBy;
  demiplane_amc_adventuring_platforms_min_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsMinFields>;
  demiplane_amc_adventuring_platforms_min_order_by: DemiplaneAmcAdventuringPlatformsMinOrderBy;
  demiplane_amc_adventuring_platforms_mutation_response: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsMutationResponse>;
  demiplane_amc_adventuring_platforms_obj_rel_insert_input: DemiplaneAmcAdventuringPlatformsObjRelInsertInput;
  demiplane_amc_adventuring_platforms_on_conflict: DemiplaneAmcAdventuringPlatformsOnConflict;
  demiplane_amc_adventuring_platforms_order_by: DemiplaneAmcAdventuringPlatformsOrderBy;
  demiplane_amc_adventuring_platforms_pk_columns_input: DemiplaneAmcAdventuringPlatformsPkColumnsInput;
  demiplane_amc_adventuring_platforms_select_column: DemiplaneAmcAdventuringPlatformsSelectColumn;
  demiplane_amc_adventuring_platforms_set_input: DemiplaneAmcAdventuringPlatformsSetInput;
  demiplane_amc_adventuring_platforms_stddev_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsStddevFields>;
  demiplane_amc_adventuring_platforms_stddev_order_by: DemiplaneAmcAdventuringPlatformsStddevOrderBy;
  demiplane_amc_adventuring_platforms_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsStddevPopFields>;
  demiplane_amc_adventuring_platforms_stddev_pop_order_by: DemiplaneAmcAdventuringPlatformsStddevPopOrderBy;
  demiplane_amc_adventuring_platforms_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsStddevSampFields>;
  demiplane_amc_adventuring_platforms_stddev_samp_order_by: DemiplaneAmcAdventuringPlatformsStddevSampOrderBy;
  demiplane_amc_adventuring_platforms_sum_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsSumFields>;
  demiplane_amc_adventuring_platforms_sum_order_by: DemiplaneAmcAdventuringPlatformsSumOrderBy;
  demiplane_amc_adventuring_platforms_update_column: DemiplaneAmcAdventuringPlatformsUpdateColumn;
  demiplane_amc_adventuring_platforms_var_pop_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsVarPopFields>;
  demiplane_amc_adventuring_platforms_var_pop_order_by: DemiplaneAmcAdventuringPlatformsVarPopOrderBy;
  demiplane_amc_adventuring_platforms_var_samp_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsVarSampFields>;
  demiplane_amc_adventuring_platforms_var_samp_order_by: DemiplaneAmcAdventuringPlatformsVarSampOrderBy;
  demiplane_amc_adventuring_platforms_variance_fields: ResolverTypeWrapper<DemiplaneAmcAdventuringPlatformsVarianceFields>;
  demiplane_amc_adventuring_platforms_variance_order_by: DemiplaneAmcAdventuringPlatformsVarianceOrderBy;
  demiplane_amc_game_types: ResolverTypeWrapper<DemiplaneAmcGameTypes>;
  demiplane_amc_game_types_aggregate: ResolverTypeWrapper<DemiplaneAmcGameTypesAggregate>;
  demiplane_amc_game_types_aggregate_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesAggregateFields>;
  demiplane_amc_game_types_aggregate_order_by: DemiplaneAmcGameTypesAggregateOrderBy;
  demiplane_amc_game_types_arr_rel_insert_input: DemiplaneAmcGameTypesArrRelInsertInput;
  demiplane_amc_game_types_avg_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesAvgFields>;
  demiplane_amc_game_types_avg_order_by: DemiplaneAmcGameTypesAvgOrderBy;
  demiplane_amc_game_types_bool_exp: DemiplaneAmcGameTypesBoolExp;
  demiplane_amc_game_types_constraint: DemiplaneAmcGameTypesConstraint;
  demiplane_amc_game_types_inc_input: DemiplaneAmcGameTypesIncInput;
  demiplane_amc_game_types_insert_input: DemiplaneAmcGameTypesInsertInput;
  demiplane_amc_game_types_max_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesMaxFields>;
  demiplane_amc_game_types_max_order_by: DemiplaneAmcGameTypesMaxOrderBy;
  demiplane_amc_game_types_min_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesMinFields>;
  demiplane_amc_game_types_min_order_by: DemiplaneAmcGameTypesMinOrderBy;
  demiplane_amc_game_types_mutation_response: ResolverTypeWrapper<DemiplaneAmcGameTypesMutationResponse>;
  demiplane_amc_game_types_obj_rel_insert_input: DemiplaneAmcGameTypesObjRelInsertInput;
  demiplane_amc_game_types_on_conflict: DemiplaneAmcGameTypesOnConflict;
  demiplane_amc_game_types_order_by: DemiplaneAmcGameTypesOrderBy;
  demiplane_amc_game_types_pk_columns_input: DemiplaneAmcGameTypesPkColumnsInput;
  demiplane_amc_game_types_select_column: DemiplaneAmcGameTypesSelectColumn;
  demiplane_amc_game_types_set_input: DemiplaneAmcGameTypesSetInput;
  demiplane_amc_game_types_stddev_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesStddevFields>;
  demiplane_amc_game_types_stddev_order_by: DemiplaneAmcGameTypesStddevOrderBy;
  demiplane_amc_game_types_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesStddevPopFields>;
  demiplane_amc_game_types_stddev_pop_order_by: DemiplaneAmcGameTypesStddevPopOrderBy;
  demiplane_amc_game_types_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesStddevSampFields>;
  demiplane_amc_game_types_stddev_samp_order_by: DemiplaneAmcGameTypesStddevSampOrderBy;
  demiplane_amc_game_types_sum_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesSumFields>;
  demiplane_amc_game_types_sum_order_by: DemiplaneAmcGameTypesSumOrderBy;
  demiplane_amc_game_types_update_column: DemiplaneAmcGameTypesUpdateColumn;
  demiplane_amc_game_types_var_pop_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesVarPopFields>;
  demiplane_amc_game_types_var_pop_order_by: DemiplaneAmcGameTypesVarPopOrderBy;
  demiplane_amc_game_types_var_samp_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesVarSampFields>;
  demiplane_amc_game_types_var_samp_order_by: DemiplaneAmcGameTypesVarSampOrderBy;
  demiplane_amc_game_types_variance_fields: ResolverTypeWrapper<DemiplaneAmcGameTypesVarianceFields>;
  demiplane_amc_game_types_variance_order_by: DemiplaneAmcGameTypesVarianceOrderBy;
  demiplane_app_version: ResolverTypeWrapper<DemiplaneAppVersion>;
  demiplane_app_version_aggregate: ResolverTypeWrapper<DemiplaneAppVersionAggregate>;
  demiplane_app_version_aggregate_fields: ResolverTypeWrapper<DemiplaneAppVersionAggregateFields>;
  demiplane_app_version_aggregate_order_by: DemiplaneAppVersionAggregateOrderBy;
  demiplane_app_version_arr_rel_insert_input: DemiplaneAppVersionArrRelInsertInput;
  demiplane_app_version_avg_fields: ResolverTypeWrapper<DemiplaneAppVersionAvgFields>;
  demiplane_app_version_avg_order_by: DemiplaneAppVersionAvgOrderBy;
  demiplane_app_version_bool_exp: DemiplaneAppVersionBoolExp;
  demiplane_app_version_constraint: DemiplaneAppVersionConstraint;
  demiplane_app_version_inc_input: DemiplaneAppVersionIncInput;
  demiplane_app_version_insert_input: DemiplaneAppVersionInsertInput;
  demiplane_app_version_max_fields: ResolverTypeWrapper<DemiplaneAppVersionMaxFields>;
  demiplane_app_version_max_order_by: DemiplaneAppVersionMaxOrderBy;
  demiplane_app_version_min_fields: ResolverTypeWrapper<DemiplaneAppVersionMinFields>;
  demiplane_app_version_min_order_by: DemiplaneAppVersionMinOrderBy;
  demiplane_app_version_mutation_response: ResolverTypeWrapper<DemiplaneAppVersionMutationResponse>;
  demiplane_app_version_obj_rel_insert_input: DemiplaneAppVersionObjRelInsertInput;
  demiplane_app_version_on_conflict: DemiplaneAppVersionOnConflict;
  demiplane_app_version_order_by: DemiplaneAppVersionOrderBy;
  demiplane_app_version_pk_columns_input: DemiplaneAppVersionPkColumnsInput;
  demiplane_app_version_select_column: DemiplaneAppVersionSelectColumn;
  demiplane_app_version_set_input: DemiplaneAppVersionSetInput;
  demiplane_app_version_stddev_fields: ResolverTypeWrapper<DemiplaneAppVersionStddevFields>;
  demiplane_app_version_stddev_order_by: DemiplaneAppVersionStddevOrderBy;
  demiplane_app_version_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAppVersionStddevPopFields>;
  demiplane_app_version_stddev_pop_order_by: DemiplaneAppVersionStddevPopOrderBy;
  demiplane_app_version_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAppVersionStddevSampFields>;
  demiplane_app_version_stddev_samp_order_by: DemiplaneAppVersionStddevSampOrderBy;
  demiplane_app_version_sum_fields: ResolverTypeWrapper<DemiplaneAppVersionSumFields>;
  demiplane_app_version_sum_order_by: DemiplaneAppVersionSumOrderBy;
  demiplane_app_version_update_column: DemiplaneAppVersionUpdateColumn;
  demiplane_app_version_var_pop_fields: ResolverTypeWrapper<DemiplaneAppVersionVarPopFields>;
  demiplane_app_version_var_pop_order_by: DemiplaneAppVersionVarPopOrderBy;
  demiplane_app_version_var_samp_fields: ResolverTypeWrapper<DemiplaneAppVersionVarSampFields>;
  demiplane_app_version_var_samp_order_by: DemiplaneAppVersionVarSampOrderBy;
  demiplane_app_version_variance_fields: ResolverTypeWrapper<DemiplaneAppVersionVarianceFields>;
  demiplane_app_version_variance_order_by: DemiplaneAppVersionVarianceOrderBy;
  demiplane_attachment: ResolverTypeWrapper<DemiplaneAttachment>;
  demiplane_attachment_aggregate: ResolverTypeWrapper<DemiplaneAttachmentAggregate>;
  demiplane_attachment_aggregate_fields: ResolverTypeWrapper<DemiplaneAttachmentAggregateFields>;
  demiplane_attachment_aggregate_order_by: DemiplaneAttachmentAggregateOrderBy;
  demiplane_attachment_arr_rel_insert_input: DemiplaneAttachmentArrRelInsertInput;
  demiplane_attachment_avg_fields: ResolverTypeWrapper<DemiplaneAttachmentAvgFields>;
  demiplane_attachment_avg_order_by: DemiplaneAttachmentAvgOrderBy;
  demiplane_attachment_bool_exp: DemiplaneAttachmentBoolExp;
  demiplane_attachment_constraint: DemiplaneAttachmentConstraint;
  demiplane_attachment_inc_input: DemiplaneAttachmentIncInput;
  demiplane_attachment_insert_input: DemiplaneAttachmentInsertInput;
  demiplane_attachment_max_fields: ResolverTypeWrapper<DemiplaneAttachmentMaxFields>;
  demiplane_attachment_max_order_by: DemiplaneAttachmentMaxOrderBy;
  demiplane_attachment_min_fields: ResolverTypeWrapper<DemiplaneAttachmentMinFields>;
  demiplane_attachment_min_order_by: DemiplaneAttachmentMinOrderBy;
  demiplane_attachment_mutation_response: ResolverTypeWrapper<DemiplaneAttachmentMutationResponse>;
  demiplane_attachment_obj_rel_insert_input: DemiplaneAttachmentObjRelInsertInput;
  demiplane_attachment_on_conflict: DemiplaneAttachmentOnConflict;
  demiplane_attachment_order_by: DemiplaneAttachmentOrderBy;
  demiplane_attachment_pk_columns_input: DemiplaneAttachmentPkColumnsInput;
  demiplane_attachment_select_column: DemiplaneAttachmentSelectColumn;
  demiplane_attachment_set_input: DemiplaneAttachmentSetInput;
  demiplane_attachment_stddev_fields: ResolverTypeWrapper<DemiplaneAttachmentStddevFields>;
  demiplane_attachment_stddev_order_by: DemiplaneAttachmentStddevOrderBy;
  demiplane_attachment_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAttachmentStddevPopFields>;
  demiplane_attachment_stddev_pop_order_by: DemiplaneAttachmentStddevPopOrderBy;
  demiplane_attachment_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAttachmentStddevSampFields>;
  demiplane_attachment_stddev_samp_order_by: DemiplaneAttachmentStddevSampOrderBy;
  demiplane_attachment_sum_fields: ResolverTypeWrapper<DemiplaneAttachmentSumFields>;
  demiplane_attachment_sum_order_by: DemiplaneAttachmentSumOrderBy;
  demiplane_attachment_update_column: DemiplaneAttachmentUpdateColumn;
  demiplane_attachment_var_pop_fields: ResolverTypeWrapper<DemiplaneAttachmentVarPopFields>;
  demiplane_attachment_var_pop_order_by: DemiplaneAttachmentVarPopOrderBy;
  demiplane_attachment_var_samp_fields: ResolverTypeWrapper<DemiplaneAttachmentVarSampFields>;
  demiplane_attachment_var_samp_order_by: DemiplaneAttachmentVarSampOrderBy;
  demiplane_attachment_variance_fields: ResolverTypeWrapper<DemiplaneAttachmentVarianceFields>;
  demiplane_attachment_variance_order_by: DemiplaneAttachmentVarianceOrderBy;
  demiplane_award_type: ResolverTypeWrapper<DemiplaneAwardType>;
  demiplane_award_type_aggregate: ResolverTypeWrapper<DemiplaneAwardTypeAggregate>;
  demiplane_award_type_aggregate_fields: ResolverTypeWrapper<DemiplaneAwardTypeAggregateFields>;
  demiplane_award_type_aggregate_order_by: DemiplaneAwardTypeAggregateOrderBy;
  demiplane_award_type_arr_rel_insert_input: DemiplaneAwardTypeArrRelInsertInput;
  demiplane_award_type_avg_fields: ResolverTypeWrapper<DemiplaneAwardTypeAvgFields>;
  demiplane_award_type_avg_order_by: DemiplaneAwardTypeAvgOrderBy;
  demiplane_award_type_bool_exp: DemiplaneAwardTypeBoolExp;
  demiplane_award_type_constraint: DemiplaneAwardTypeConstraint;
  demiplane_award_type_inc_input: DemiplaneAwardTypeIncInput;
  demiplane_award_type_insert_input: DemiplaneAwardTypeInsertInput;
  demiplane_award_type_max_fields: ResolverTypeWrapper<DemiplaneAwardTypeMaxFields>;
  demiplane_award_type_max_order_by: DemiplaneAwardTypeMaxOrderBy;
  demiplane_award_type_min_fields: ResolverTypeWrapper<DemiplaneAwardTypeMinFields>;
  demiplane_award_type_min_order_by: DemiplaneAwardTypeMinOrderBy;
  demiplane_award_type_mutation_response: ResolverTypeWrapper<DemiplaneAwardTypeMutationResponse>;
  demiplane_award_type_obj_rel_insert_input: DemiplaneAwardTypeObjRelInsertInput;
  demiplane_award_type_on_conflict: DemiplaneAwardTypeOnConflict;
  demiplane_award_type_order_by: DemiplaneAwardTypeOrderBy;
  demiplane_award_type_pk_columns_input: DemiplaneAwardTypePkColumnsInput;
  demiplane_award_type_select_column: DemiplaneAwardTypeSelectColumn;
  demiplane_award_type_set_input: DemiplaneAwardTypeSetInput;
  demiplane_award_type_stddev_fields: ResolverTypeWrapper<DemiplaneAwardTypeStddevFields>;
  demiplane_award_type_stddev_order_by: DemiplaneAwardTypeStddevOrderBy;
  demiplane_award_type_stddev_pop_fields: ResolverTypeWrapper<DemiplaneAwardTypeStddevPopFields>;
  demiplane_award_type_stddev_pop_order_by: DemiplaneAwardTypeStddevPopOrderBy;
  demiplane_award_type_stddev_samp_fields: ResolverTypeWrapper<DemiplaneAwardTypeStddevSampFields>;
  demiplane_award_type_stddev_samp_order_by: DemiplaneAwardTypeStddevSampOrderBy;
  demiplane_award_type_sum_fields: ResolverTypeWrapper<DemiplaneAwardTypeSumFields>;
  demiplane_award_type_sum_order_by: DemiplaneAwardTypeSumOrderBy;
  demiplane_award_type_update_column: DemiplaneAwardTypeUpdateColumn;
  demiplane_award_type_var_pop_fields: ResolverTypeWrapper<DemiplaneAwardTypeVarPopFields>;
  demiplane_award_type_var_pop_order_by: DemiplaneAwardTypeVarPopOrderBy;
  demiplane_award_type_var_samp_fields: ResolverTypeWrapper<DemiplaneAwardTypeVarSampFields>;
  demiplane_award_type_var_samp_order_by: DemiplaneAwardTypeVarSampOrderBy;
  demiplane_award_type_variance_fields: ResolverTypeWrapper<DemiplaneAwardTypeVarianceFields>;
  demiplane_award_type_variance_order_by: DemiplaneAwardTypeVarianceOrderBy;
  demiplane_connection_status: ResolverTypeWrapper<DemiplaneConnectionStatus>;
  demiplane_connection_status_aggregate: ResolverTypeWrapper<DemiplaneConnectionStatusAggregate>;
  demiplane_connection_status_aggregate_fields: ResolverTypeWrapper<DemiplaneConnectionStatusAggregateFields>;
  demiplane_connection_status_aggregate_order_by: DemiplaneConnectionStatusAggregateOrderBy;
  demiplane_connection_status_arr_rel_insert_input: DemiplaneConnectionStatusArrRelInsertInput;
  demiplane_connection_status_avg_fields: ResolverTypeWrapper<DemiplaneConnectionStatusAvgFields>;
  demiplane_connection_status_avg_order_by: DemiplaneConnectionStatusAvgOrderBy;
  demiplane_connection_status_bool_exp: DemiplaneConnectionStatusBoolExp;
  demiplane_connection_status_constraint: DemiplaneConnectionStatusConstraint;
  demiplane_connection_status_inc_input: DemiplaneConnectionStatusIncInput;
  demiplane_connection_status_insert_input: DemiplaneConnectionStatusInsertInput;
  demiplane_connection_status_max_fields: ResolverTypeWrapper<DemiplaneConnectionStatusMaxFields>;
  demiplane_connection_status_max_order_by: DemiplaneConnectionStatusMaxOrderBy;
  demiplane_connection_status_min_fields: ResolverTypeWrapper<DemiplaneConnectionStatusMinFields>;
  demiplane_connection_status_min_order_by: DemiplaneConnectionStatusMinOrderBy;
  demiplane_connection_status_mutation_response: ResolverTypeWrapper<DemiplaneConnectionStatusMutationResponse>;
  demiplane_connection_status_obj_rel_insert_input: DemiplaneConnectionStatusObjRelInsertInput;
  demiplane_connection_status_on_conflict: DemiplaneConnectionStatusOnConflict;
  demiplane_connection_status_order_by: DemiplaneConnectionStatusOrderBy;
  demiplane_connection_status_pk_columns_input: DemiplaneConnectionStatusPkColumnsInput;
  demiplane_connection_status_select_column: DemiplaneConnectionStatusSelectColumn;
  demiplane_connection_status_set_input: DemiplaneConnectionStatusSetInput;
  demiplane_connection_status_stddev_fields: ResolverTypeWrapper<DemiplaneConnectionStatusStddevFields>;
  demiplane_connection_status_stddev_order_by: DemiplaneConnectionStatusStddevOrderBy;
  demiplane_connection_status_stddev_pop_fields: ResolverTypeWrapper<DemiplaneConnectionStatusStddevPopFields>;
  demiplane_connection_status_stddev_pop_order_by: DemiplaneConnectionStatusStddevPopOrderBy;
  demiplane_connection_status_stddev_samp_fields: ResolverTypeWrapper<DemiplaneConnectionStatusStddevSampFields>;
  demiplane_connection_status_stddev_samp_order_by: DemiplaneConnectionStatusStddevSampOrderBy;
  demiplane_connection_status_sum_fields: ResolverTypeWrapper<DemiplaneConnectionStatusSumFields>;
  demiplane_connection_status_sum_order_by: DemiplaneConnectionStatusSumOrderBy;
  demiplane_connection_status_update_column: DemiplaneConnectionStatusUpdateColumn;
  demiplane_connection_status_var_pop_fields: ResolverTypeWrapper<DemiplaneConnectionStatusVarPopFields>;
  demiplane_connection_status_var_pop_order_by: DemiplaneConnectionStatusVarPopOrderBy;
  demiplane_connection_status_var_samp_fields: ResolverTypeWrapper<DemiplaneConnectionStatusVarSampFields>;
  demiplane_connection_status_var_samp_order_by: DemiplaneConnectionStatusVarSampOrderBy;
  demiplane_connection_status_variance_fields: ResolverTypeWrapper<DemiplaneConnectionStatusVarianceFields>;
  demiplane_connection_status_variance_order_by: DemiplaneConnectionStatusVarianceOrderBy;
  demiplane_connection_type: ResolverTypeWrapper<DemiplaneConnectionType>;
  demiplane_connection_type_aggregate: ResolverTypeWrapper<DemiplaneConnectionTypeAggregate>;
  demiplane_connection_type_aggregate_fields: ResolverTypeWrapper<DemiplaneConnectionTypeAggregateFields>;
  demiplane_connection_type_aggregate_order_by: DemiplaneConnectionTypeAggregateOrderBy;
  demiplane_connection_type_arr_rel_insert_input: DemiplaneConnectionTypeArrRelInsertInput;
  demiplane_connection_type_avg_fields: ResolverTypeWrapper<DemiplaneConnectionTypeAvgFields>;
  demiplane_connection_type_avg_order_by: DemiplaneConnectionTypeAvgOrderBy;
  demiplane_connection_type_bool_exp: DemiplaneConnectionTypeBoolExp;
  demiplane_connection_type_constraint: DemiplaneConnectionTypeConstraint;
  demiplane_connection_type_inc_input: DemiplaneConnectionTypeIncInput;
  demiplane_connection_type_insert_input: DemiplaneConnectionTypeInsertInput;
  demiplane_connection_type_max_fields: ResolverTypeWrapper<DemiplaneConnectionTypeMaxFields>;
  demiplane_connection_type_max_order_by: DemiplaneConnectionTypeMaxOrderBy;
  demiplane_connection_type_min_fields: ResolverTypeWrapper<DemiplaneConnectionTypeMinFields>;
  demiplane_connection_type_min_order_by: DemiplaneConnectionTypeMinOrderBy;
  demiplane_connection_type_mutation_response: ResolverTypeWrapper<DemiplaneConnectionTypeMutationResponse>;
  demiplane_connection_type_obj_rel_insert_input: DemiplaneConnectionTypeObjRelInsertInput;
  demiplane_connection_type_on_conflict: DemiplaneConnectionTypeOnConflict;
  demiplane_connection_type_order_by: DemiplaneConnectionTypeOrderBy;
  demiplane_connection_type_pk_columns_input: DemiplaneConnectionTypePkColumnsInput;
  demiplane_connection_type_select_column: DemiplaneConnectionTypeSelectColumn;
  demiplane_connection_type_set_input: DemiplaneConnectionTypeSetInput;
  demiplane_connection_type_stddev_fields: ResolverTypeWrapper<DemiplaneConnectionTypeStddevFields>;
  demiplane_connection_type_stddev_order_by: DemiplaneConnectionTypeStddevOrderBy;
  demiplane_connection_type_stddev_pop_fields: ResolverTypeWrapper<DemiplaneConnectionTypeStddevPopFields>;
  demiplane_connection_type_stddev_pop_order_by: DemiplaneConnectionTypeStddevPopOrderBy;
  demiplane_connection_type_stddev_samp_fields: ResolverTypeWrapper<DemiplaneConnectionTypeStddevSampFields>;
  demiplane_connection_type_stddev_samp_order_by: DemiplaneConnectionTypeStddevSampOrderBy;
  demiplane_connection_type_sum_fields: ResolverTypeWrapper<DemiplaneConnectionTypeSumFields>;
  demiplane_connection_type_sum_order_by: DemiplaneConnectionTypeSumOrderBy;
  demiplane_connection_type_update_column: DemiplaneConnectionTypeUpdateColumn;
  demiplane_connection_type_var_pop_fields: ResolverTypeWrapper<DemiplaneConnectionTypeVarPopFields>;
  demiplane_connection_type_var_pop_order_by: DemiplaneConnectionTypeVarPopOrderBy;
  demiplane_connection_type_var_samp_fields: ResolverTypeWrapper<DemiplaneConnectionTypeVarSampFields>;
  demiplane_connection_type_var_samp_order_by: DemiplaneConnectionTypeVarSampOrderBy;
  demiplane_connection_type_variance_fields: ResolverTypeWrapper<DemiplaneConnectionTypeVarianceFields>;
  demiplane_connection_type_variance_order_by: DemiplaneConnectionTypeVarianceOrderBy;
  demiplane_contact_type: ResolverTypeWrapper<DemiplaneContactType>;
  demiplane_contact_type_aggregate: ResolverTypeWrapper<DemiplaneContactTypeAggregate>;
  demiplane_contact_type_aggregate_fields: ResolverTypeWrapper<DemiplaneContactTypeAggregateFields>;
  demiplane_contact_type_aggregate_order_by: DemiplaneContactTypeAggregateOrderBy;
  demiplane_contact_type_arr_rel_insert_input: DemiplaneContactTypeArrRelInsertInput;
  demiplane_contact_type_avg_fields: ResolverTypeWrapper<DemiplaneContactTypeAvgFields>;
  demiplane_contact_type_avg_order_by: DemiplaneContactTypeAvgOrderBy;
  demiplane_contact_type_bool_exp: DemiplaneContactTypeBoolExp;
  demiplane_contact_type_constraint: DemiplaneContactTypeConstraint;
  demiplane_contact_type_inc_input: DemiplaneContactTypeIncInput;
  demiplane_contact_type_insert_input: DemiplaneContactTypeInsertInput;
  demiplane_contact_type_max_fields: ResolverTypeWrapper<DemiplaneContactTypeMaxFields>;
  demiplane_contact_type_max_order_by: DemiplaneContactTypeMaxOrderBy;
  demiplane_contact_type_min_fields: ResolverTypeWrapper<DemiplaneContactTypeMinFields>;
  demiplane_contact_type_min_order_by: DemiplaneContactTypeMinOrderBy;
  demiplane_contact_type_mutation_response: ResolverTypeWrapper<DemiplaneContactTypeMutationResponse>;
  demiplane_contact_type_obj_rel_insert_input: DemiplaneContactTypeObjRelInsertInput;
  demiplane_contact_type_on_conflict: DemiplaneContactTypeOnConflict;
  demiplane_contact_type_order_by: DemiplaneContactTypeOrderBy;
  demiplane_contact_type_pk_columns_input: DemiplaneContactTypePkColumnsInput;
  demiplane_contact_type_select_column: DemiplaneContactTypeSelectColumn;
  demiplane_contact_type_set_input: DemiplaneContactTypeSetInput;
  demiplane_contact_type_stddev_fields: ResolverTypeWrapper<DemiplaneContactTypeStddevFields>;
  demiplane_contact_type_stddev_order_by: DemiplaneContactTypeStddevOrderBy;
  demiplane_contact_type_stddev_pop_fields: ResolverTypeWrapper<DemiplaneContactTypeStddevPopFields>;
  demiplane_contact_type_stddev_pop_order_by: DemiplaneContactTypeStddevPopOrderBy;
  demiplane_contact_type_stddev_samp_fields: ResolverTypeWrapper<DemiplaneContactTypeStddevSampFields>;
  demiplane_contact_type_stddev_samp_order_by: DemiplaneContactTypeStddevSampOrderBy;
  demiplane_contact_type_sum_fields: ResolverTypeWrapper<DemiplaneContactTypeSumFields>;
  demiplane_contact_type_sum_order_by: DemiplaneContactTypeSumOrderBy;
  demiplane_contact_type_update_column: DemiplaneContactTypeUpdateColumn;
  demiplane_contact_type_var_pop_fields: ResolverTypeWrapper<DemiplaneContactTypeVarPopFields>;
  demiplane_contact_type_var_pop_order_by: DemiplaneContactTypeVarPopOrderBy;
  demiplane_contact_type_var_samp_fields: ResolverTypeWrapper<DemiplaneContactTypeVarSampFields>;
  demiplane_contact_type_var_samp_order_by: DemiplaneContactTypeVarSampOrderBy;
  demiplane_contact_type_variance_fields: ResolverTypeWrapper<DemiplaneContactTypeVarianceFields>;
  demiplane_contact_type_variance_order_by: DemiplaneContactTypeVarianceOrderBy;
  demiplane_country: ResolverTypeWrapper<DemiplaneCountry>;
  demiplane_country_aggregate: ResolverTypeWrapper<DemiplaneCountryAggregate>;
  demiplane_country_aggregate_fields: ResolverTypeWrapper<DemiplaneCountryAggregateFields>;
  demiplane_country_aggregate_order_by: DemiplaneCountryAggregateOrderBy;
  demiplane_country_arr_rel_insert_input: DemiplaneCountryArrRelInsertInput;
  demiplane_country_avg_fields: ResolverTypeWrapper<DemiplaneCountryAvgFields>;
  demiplane_country_avg_order_by: DemiplaneCountryAvgOrderBy;
  demiplane_country_bool_exp: DemiplaneCountryBoolExp;
  demiplane_country_constraint: DemiplaneCountryConstraint;
  demiplane_country_inc_input: DemiplaneCountryIncInput;
  demiplane_country_insert_input: DemiplaneCountryInsertInput;
  demiplane_country_max_fields: ResolverTypeWrapper<DemiplaneCountryMaxFields>;
  demiplane_country_max_order_by: DemiplaneCountryMaxOrderBy;
  demiplane_country_min_fields: ResolverTypeWrapper<DemiplaneCountryMinFields>;
  demiplane_country_min_order_by: DemiplaneCountryMinOrderBy;
  demiplane_country_mutation_response: ResolverTypeWrapper<DemiplaneCountryMutationResponse>;
  demiplane_country_obj_rel_insert_input: DemiplaneCountryObjRelInsertInput;
  demiplane_country_on_conflict: DemiplaneCountryOnConflict;
  demiplane_country_order_by: DemiplaneCountryOrderBy;
  demiplane_country_pk_columns_input: DemiplaneCountryPkColumnsInput;
  demiplane_country_select_column: DemiplaneCountrySelectColumn;
  demiplane_country_set_input: DemiplaneCountrySetInput;
  demiplane_country_stddev_fields: ResolverTypeWrapper<DemiplaneCountryStddevFields>;
  demiplane_country_stddev_order_by: DemiplaneCountryStddevOrderBy;
  demiplane_country_stddev_pop_fields: ResolverTypeWrapper<DemiplaneCountryStddevPopFields>;
  demiplane_country_stddev_pop_order_by: DemiplaneCountryStddevPopOrderBy;
  demiplane_country_stddev_samp_fields: ResolverTypeWrapper<DemiplaneCountryStddevSampFields>;
  demiplane_country_stddev_samp_order_by: DemiplaneCountryStddevSampOrderBy;
  demiplane_country_sum_fields: ResolverTypeWrapper<DemiplaneCountrySumFields>;
  demiplane_country_sum_order_by: DemiplaneCountrySumOrderBy;
  demiplane_country_update_column: DemiplaneCountryUpdateColumn;
  demiplane_country_var_pop_fields: ResolverTypeWrapper<DemiplaneCountryVarPopFields>;
  demiplane_country_var_pop_order_by: DemiplaneCountryVarPopOrderBy;
  demiplane_country_var_samp_fields: ResolverTypeWrapper<DemiplaneCountryVarSampFields>;
  demiplane_country_var_samp_order_by: DemiplaneCountryVarSampOrderBy;
  demiplane_country_variance_fields: ResolverTypeWrapper<DemiplaneCountryVarianceFields>;
  demiplane_country_variance_order_by: DemiplaneCountryVarianceOrderBy;
  demiplane_credit_card: ResolverTypeWrapper<DemiplaneCreditCard>;
  demiplane_credit_card_aggregate: ResolverTypeWrapper<DemiplaneCreditCardAggregate>;
  demiplane_credit_card_aggregate_fields: ResolverTypeWrapper<DemiplaneCreditCardAggregateFields>;
  demiplane_credit_card_aggregate_order_by: DemiplaneCreditCardAggregateOrderBy;
  demiplane_credit_card_arr_rel_insert_input: DemiplaneCreditCardArrRelInsertInput;
  demiplane_credit_card_avg_fields: ResolverTypeWrapper<DemiplaneCreditCardAvgFields>;
  demiplane_credit_card_avg_order_by: DemiplaneCreditCardAvgOrderBy;
  demiplane_credit_card_bool_exp: DemiplaneCreditCardBoolExp;
  demiplane_credit_card_constraint: DemiplaneCreditCardConstraint;
  demiplane_credit_card_inc_input: DemiplaneCreditCardIncInput;
  demiplane_credit_card_insert_input: DemiplaneCreditCardInsertInput;
  demiplane_credit_card_max_fields: ResolverTypeWrapper<DemiplaneCreditCardMaxFields>;
  demiplane_credit_card_max_order_by: DemiplaneCreditCardMaxOrderBy;
  demiplane_credit_card_min_fields: ResolverTypeWrapper<DemiplaneCreditCardMinFields>;
  demiplane_credit_card_min_order_by: DemiplaneCreditCardMinOrderBy;
  demiplane_credit_card_mutation_response: ResolverTypeWrapper<DemiplaneCreditCardMutationResponse>;
  demiplane_credit_card_obj_rel_insert_input: DemiplaneCreditCardObjRelInsertInput;
  demiplane_credit_card_on_conflict: DemiplaneCreditCardOnConflict;
  demiplane_credit_card_order_by: DemiplaneCreditCardOrderBy;
  demiplane_credit_card_pk_columns_input: DemiplaneCreditCardPkColumnsInput;
  demiplane_credit_card_select_column: DemiplaneCreditCardSelectColumn;
  demiplane_credit_card_set_input: DemiplaneCreditCardSetInput;
  demiplane_credit_card_stddev_fields: ResolverTypeWrapper<DemiplaneCreditCardStddevFields>;
  demiplane_credit_card_stddev_order_by: DemiplaneCreditCardStddevOrderBy;
  demiplane_credit_card_stddev_pop_fields: ResolverTypeWrapper<DemiplaneCreditCardStddevPopFields>;
  demiplane_credit_card_stddev_pop_order_by: DemiplaneCreditCardStddevPopOrderBy;
  demiplane_credit_card_stddev_samp_fields: ResolverTypeWrapper<DemiplaneCreditCardStddevSampFields>;
  demiplane_credit_card_stddev_samp_order_by: DemiplaneCreditCardStddevSampOrderBy;
  demiplane_credit_card_sum_fields: ResolverTypeWrapper<DemiplaneCreditCardSumFields>;
  demiplane_credit_card_sum_order_by: DemiplaneCreditCardSumOrderBy;
  demiplane_credit_card_update_column: DemiplaneCreditCardUpdateColumn;
  demiplane_credit_card_var_pop_fields: ResolverTypeWrapper<DemiplaneCreditCardVarPopFields>;
  demiplane_credit_card_var_pop_order_by: DemiplaneCreditCardVarPopOrderBy;
  demiplane_credit_card_var_samp_fields: ResolverTypeWrapper<DemiplaneCreditCardVarSampFields>;
  demiplane_credit_card_var_samp_order_by: DemiplaneCreditCardVarSampOrderBy;
  demiplane_credit_card_variance_fields: ResolverTypeWrapper<DemiplaneCreditCardVarianceFields>;
  demiplane_credit_card_variance_order_by: DemiplaneCreditCardVarianceOrderBy;
  demiplane_demiplane_tool: ResolverTypeWrapper<DemiplaneDemiplaneTool>;
  demiplane_demiplane_tool_aggregate: ResolverTypeWrapper<DemiplaneDemiplaneToolAggregate>;
  demiplane_demiplane_tool_aggregate_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolAggregateFields>;
  demiplane_demiplane_tool_aggregate_order_by: DemiplaneDemiplaneToolAggregateOrderBy;
  demiplane_demiplane_tool_arr_rel_insert_input: DemiplaneDemiplaneToolArrRelInsertInput;
  demiplane_demiplane_tool_avg_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolAvgFields>;
  demiplane_demiplane_tool_avg_order_by: DemiplaneDemiplaneToolAvgOrderBy;
  demiplane_demiplane_tool_bool_exp: DemiplaneDemiplaneToolBoolExp;
  demiplane_demiplane_tool_constraint: DemiplaneDemiplaneToolConstraint;
  demiplane_demiplane_tool_inc_input: DemiplaneDemiplaneToolIncInput;
  demiplane_demiplane_tool_insert_input: DemiplaneDemiplaneToolInsertInput;
  demiplane_demiplane_tool_max_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolMaxFields>;
  demiplane_demiplane_tool_max_order_by: DemiplaneDemiplaneToolMaxOrderBy;
  demiplane_demiplane_tool_min_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolMinFields>;
  demiplane_demiplane_tool_min_order_by: DemiplaneDemiplaneToolMinOrderBy;
  demiplane_demiplane_tool_mutation_response: ResolverTypeWrapper<DemiplaneDemiplaneToolMutationResponse>;
  demiplane_demiplane_tool_obj_rel_insert_input: DemiplaneDemiplaneToolObjRelInsertInput;
  demiplane_demiplane_tool_on_conflict: DemiplaneDemiplaneToolOnConflict;
  demiplane_demiplane_tool_order_by: DemiplaneDemiplaneToolOrderBy;
  demiplane_demiplane_tool_pk_columns_input: DemiplaneDemiplaneToolPkColumnsInput;
  demiplane_demiplane_tool_select_column: DemiplaneDemiplaneToolSelectColumn;
  demiplane_demiplane_tool_set_input: DemiplaneDemiplaneToolSetInput;
  demiplane_demiplane_tool_stddev_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolStddevFields>;
  demiplane_demiplane_tool_stddev_order_by: DemiplaneDemiplaneToolStddevOrderBy;
  demiplane_demiplane_tool_stddev_pop_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolStddevPopFields>;
  demiplane_demiplane_tool_stddev_pop_order_by: DemiplaneDemiplaneToolStddevPopOrderBy;
  demiplane_demiplane_tool_stddev_samp_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolStddevSampFields>;
  demiplane_demiplane_tool_stddev_samp_order_by: DemiplaneDemiplaneToolStddevSampOrderBy;
  demiplane_demiplane_tool_sum_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolSumFields>;
  demiplane_demiplane_tool_sum_order_by: DemiplaneDemiplaneToolSumOrderBy;
  demiplane_demiplane_tool_update_column: DemiplaneDemiplaneToolUpdateColumn;
  demiplane_demiplane_tool_var_pop_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolVarPopFields>;
  demiplane_demiplane_tool_var_pop_order_by: DemiplaneDemiplaneToolVarPopOrderBy;
  demiplane_demiplane_tool_var_samp_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolVarSampFields>;
  demiplane_demiplane_tool_var_samp_order_by: DemiplaneDemiplaneToolVarSampOrderBy;
  demiplane_demiplane_tool_variance_fields: ResolverTypeWrapper<DemiplaneDemiplaneToolVarianceFields>;
  demiplane_demiplane_tool_variance_order_by: DemiplaneDemiplaneToolVarianceOrderBy;
  demiplane_episode_attendance: ResolverTypeWrapper<DemiplaneEpisodeAttendance>;
  demiplane_episode_attendance_aggregate: ResolverTypeWrapper<DemiplaneEpisodeAttendanceAggregate>;
  demiplane_episode_attendance_aggregate_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceAggregateFields>;
  demiplane_episode_attendance_aggregate_order_by: DemiplaneEpisodeAttendanceAggregateOrderBy;
  demiplane_episode_attendance_arr_rel_insert_input: DemiplaneEpisodeAttendanceArrRelInsertInput;
  demiplane_episode_attendance_avg_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceAvgFields>;
  demiplane_episode_attendance_avg_order_by: DemiplaneEpisodeAttendanceAvgOrderBy;
  demiplane_episode_attendance_bool_exp: DemiplaneEpisodeAttendanceBoolExp;
  demiplane_episode_attendance_constraint: DemiplaneEpisodeAttendanceConstraint;
  demiplane_episode_attendance_inc_input: DemiplaneEpisodeAttendanceIncInput;
  demiplane_episode_attendance_insert_input: DemiplaneEpisodeAttendanceInsertInput;
  demiplane_episode_attendance_max_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceMaxFields>;
  demiplane_episode_attendance_max_order_by: DemiplaneEpisodeAttendanceMaxOrderBy;
  demiplane_episode_attendance_min_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceMinFields>;
  demiplane_episode_attendance_min_order_by: DemiplaneEpisodeAttendanceMinOrderBy;
  demiplane_episode_attendance_mutation_response: ResolverTypeWrapper<DemiplaneEpisodeAttendanceMutationResponse>;
  demiplane_episode_attendance_obj_rel_insert_input: DemiplaneEpisodeAttendanceObjRelInsertInput;
  demiplane_episode_attendance_on_conflict: DemiplaneEpisodeAttendanceOnConflict;
  demiplane_episode_attendance_order_by: DemiplaneEpisodeAttendanceOrderBy;
  demiplane_episode_attendance_pk_columns_input: DemiplaneEpisodeAttendancePkColumnsInput;
  demiplane_episode_attendance_select_column: DemiplaneEpisodeAttendanceSelectColumn;
  demiplane_episode_attendance_set_input: DemiplaneEpisodeAttendanceSetInput;
  demiplane_episode_attendance_stddev_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceStddevFields>;
  demiplane_episode_attendance_stddev_order_by: DemiplaneEpisodeAttendanceStddevOrderBy;
  demiplane_episode_attendance_stddev_pop_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceStddevPopFields>;
  demiplane_episode_attendance_stddev_pop_order_by: DemiplaneEpisodeAttendanceStddevPopOrderBy;
  demiplane_episode_attendance_stddev_samp_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceStddevSampFields>;
  demiplane_episode_attendance_stddev_samp_order_by: DemiplaneEpisodeAttendanceStddevSampOrderBy;
  demiplane_episode_attendance_sum_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceSumFields>;
  demiplane_episode_attendance_sum_order_by: DemiplaneEpisodeAttendanceSumOrderBy;
  demiplane_episode_attendance_update_column: DemiplaneEpisodeAttendanceUpdateColumn;
  demiplane_episode_attendance_var_pop_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceVarPopFields>;
  demiplane_episode_attendance_var_pop_order_by: DemiplaneEpisodeAttendanceVarPopOrderBy;
  demiplane_episode_attendance_var_samp_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceVarSampFields>;
  demiplane_episode_attendance_var_samp_order_by: DemiplaneEpisodeAttendanceVarSampOrderBy;
  demiplane_episode_attendance_variance_fields: ResolverTypeWrapper<DemiplaneEpisodeAttendanceVarianceFields>;
  demiplane_episode_attendance_variance_order_by: DemiplaneEpisodeAttendanceVarianceOrderBy;
  demiplane_event: ResolverTypeWrapper<DemiplaneEvent>;
  demiplane_event_aggregate: ResolverTypeWrapper<DemiplaneEventAggregate>;
  demiplane_event_aggregate_fields: ResolverTypeWrapper<DemiplaneEventAggregateFields>;
  demiplane_event_aggregate_order_by: DemiplaneEventAggregateOrderBy;
  demiplane_event_arr_rel_insert_input: DemiplaneEventArrRelInsertInput;
  demiplane_event_avg_fields: ResolverTypeWrapper<DemiplaneEventAvgFields>;
  demiplane_event_avg_order_by: DemiplaneEventAvgOrderBy;
  demiplane_event_bool_exp: DemiplaneEventBoolExp;
  demiplane_event_constraint: DemiplaneEventConstraint;
  demiplane_event_inc_input: DemiplaneEventIncInput;
  demiplane_event_insert_input: DemiplaneEventInsertInput;
  demiplane_event_max_fields: ResolverTypeWrapper<DemiplaneEventMaxFields>;
  demiplane_event_max_order_by: DemiplaneEventMaxOrderBy;
  demiplane_event_min_fields: ResolverTypeWrapper<DemiplaneEventMinFields>;
  demiplane_event_min_order_by: DemiplaneEventMinOrderBy;
  demiplane_event_mutation_response: ResolverTypeWrapper<DemiplaneEventMutationResponse>;
  demiplane_event_obj_rel_insert_input: DemiplaneEventObjRelInsertInput;
  demiplane_event_on_conflict: DemiplaneEventOnConflict;
  demiplane_event_order_by: DemiplaneEventOrderBy;
  demiplane_event_pk_columns_input: DemiplaneEventPkColumnsInput;
  demiplane_event_select_column: DemiplaneEventSelectColumn;
  demiplane_event_set_input: DemiplaneEventSetInput;
  demiplane_event_stddev_fields: ResolverTypeWrapper<DemiplaneEventStddevFields>;
  demiplane_event_stddev_order_by: DemiplaneEventStddevOrderBy;
  demiplane_event_stddev_pop_fields: ResolverTypeWrapper<DemiplaneEventStddevPopFields>;
  demiplane_event_stddev_pop_order_by: DemiplaneEventStddevPopOrderBy;
  demiplane_event_stddev_samp_fields: ResolverTypeWrapper<DemiplaneEventStddevSampFields>;
  demiplane_event_stddev_samp_order_by: DemiplaneEventStddevSampOrderBy;
  demiplane_event_sum_fields: ResolverTypeWrapper<DemiplaneEventSumFields>;
  demiplane_event_sum_order_by: DemiplaneEventSumOrderBy;
  demiplane_event_type: ResolverTypeWrapper<DemiplaneEventType>;
  demiplane_event_type_aggregate: ResolverTypeWrapper<DemiplaneEventTypeAggregate>;
  demiplane_event_type_aggregate_fields: ResolverTypeWrapper<DemiplaneEventTypeAggregateFields>;
  demiplane_event_type_aggregate_order_by: DemiplaneEventTypeAggregateOrderBy;
  demiplane_event_type_arr_rel_insert_input: DemiplaneEventTypeArrRelInsertInput;
  demiplane_event_type_avg_fields: ResolverTypeWrapper<DemiplaneEventTypeAvgFields>;
  demiplane_event_type_avg_order_by: DemiplaneEventTypeAvgOrderBy;
  demiplane_event_type_bool_exp: DemiplaneEventTypeBoolExp;
  demiplane_event_type_constraint: DemiplaneEventTypeConstraint;
  demiplane_event_type_inc_input: DemiplaneEventTypeIncInput;
  demiplane_event_type_insert_input: DemiplaneEventTypeInsertInput;
  demiplane_event_type_max_fields: ResolverTypeWrapper<DemiplaneEventTypeMaxFields>;
  demiplane_event_type_max_order_by: DemiplaneEventTypeMaxOrderBy;
  demiplane_event_type_min_fields: ResolverTypeWrapper<DemiplaneEventTypeMinFields>;
  demiplane_event_type_min_order_by: DemiplaneEventTypeMinOrderBy;
  demiplane_event_type_mutation_response: ResolverTypeWrapper<DemiplaneEventTypeMutationResponse>;
  demiplane_event_type_obj_rel_insert_input: DemiplaneEventTypeObjRelInsertInput;
  demiplane_event_type_on_conflict: DemiplaneEventTypeOnConflict;
  demiplane_event_type_order_by: DemiplaneEventTypeOrderBy;
  demiplane_event_type_pk_columns_input: DemiplaneEventTypePkColumnsInput;
  demiplane_event_type_select_column: DemiplaneEventTypeSelectColumn;
  demiplane_event_type_set_input: DemiplaneEventTypeSetInput;
  demiplane_event_type_stddev_fields: ResolverTypeWrapper<DemiplaneEventTypeStddevFields>;
  demiplane_event_type_stddev_order_by: DemiplaneEventTypeStddevOrderBy;
  demiplane_event_type_stddev_pop_fields: ResolverTypeWrapper<DemiplaneEventTypeStddevPopFields>;
  demiplane_event_type_stddev_pop_order_by: DemiplaneEventTypeStddevPopOrderBy;
  demiplane_event_type_stddev_samp_fields: ResolverTypeWrapper<DemiplaneEventTypeStddevSampFields>;
  demiplane_event_type_stddev_samp_order_by: DemiplaneEventTypeStddevSampOrderBy;
  demiplane_event_type_sum_fields: ResolverTypeWrapper<DemiplaneEventTypeSumFields>;
  demiplane_event_type_sum_order_by: DemiplaneEventTypeSumOrderBy;
  demiplane_event_type_update_column: DemiplaneEventTypeUpdateColumn;
  demiplane_event_type_var_pop_fields: ResolverTypeWrapper<DemiplaneEventTypeVarPopFields>;
  demiplane_event_type_var_pop_order_by: DemiplaneEventTypeVarPopOrderBy;
  demiplane_event_type_var_samp_fields: ResolverTypeWrapper<DemiplaneEventTypeVarSampFields>;
  demiplane_event_type_var_samp_order_by: DemiplaneEventTypeVarSampOrderBy;
  demiplane_event_type_variance_fields: ResolverTypeWrapper<DemiplaneEventTypeVarianceFields>;
  demiplane_event_type_variance_order_by: DemiplaneEventTypeVarianceOrderBy;
  demiplane_event_update_column: DemiplaneEventUpdateColumn;
  demiplane_event_var_pop_fields: ResolverTypeWrapper<DemiplaneEventVarPopFields>;
  demiplane_event_var_pop_order_by: DemiplaneEventVarPopOrderBy;
  demiplane_event_var_samp_fields: ResolverTypeWrapper<DemiplaneEventVarSampFields>;
  demiplane_event_var_samp_order_by: DemiplaneEventVarSampOrderBy;
  demiplane_event_variance_fields: ResolverTypeWrapper<DemiplaneEventVarianceFields>;
  demiplane_event_variance_order_by: DemiplaneEventVarianceOrderBy;
  demiplane_favorite_adventuring_platform: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatform>;
  demiplane_favorite_adventuring_platform_aggregate: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformAggregate>;
  demiplane_favorite_adventuring_platform_aggregate_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformAggregateFields>;
  demiplane_favorite_adventuring_platform_aggregate_order_by: DemiplaneFavoriteAdventuringPlatformAggregateOrderBy;
  demiplane_favorite_adventuring_platform_arr_rel_insert_input: DemiplaneFavoriteAdventuringPlatformArrRelInsertInput;
  demiplane_favorite_adventuring_platform_avg_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformAvgFields>;
  demiplane_favorite_adventuring_platform_avg_order_by: DemiplaneFavoriteAdventuringPlatformAvgOrderBy;
  demiplane_favorite_adventuring_platform_bool_exp: DemiplaneFavoriteAdventuringPlatformBoolExp;
  demiplane_favorite_adventuring_platform_constraint: DemiplaneFavoriteAdventuringPlatformConstraint;
  demiplane_favorite_adventuring_platform_inc_input: DemiplaneFavoriteAdventuringPlatformIncInput;
  demiplane_favorite_adventuring_platform_insert_input: DemiplaneFavoriteAdventuringPlatformInsertInput;
  demiplane_favorite_adventuring_platform_max_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformMaxFields>;
  demiplane_favorite_adventuring_platform_max_order_by: DemiplaneFavoriteAdventuringPlatformMaxOrderBy;
  demiplane_favorite_adventuring_platform_min_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformMinFields>;
  demiplane_favorite_adventuring_platform_min_order_by: DemiplaneFavoriteAdventuringPlatformMinOrderBy;
  demiplane_favorite_adventuring_platform_mutation_response: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformMutationResponse>;
  demiplane_favorite_adventuring_platform_obj_rel_insert_input: DemiplaneFavoriteAdventuringPlatformObjRelInsertInput;
  demiplane_favorite_adventuring_platform_on_conflict: DemiplaneFavoriteAdventuringPlatformOnConflict;
  demiplane_favorite_adventuring_platform_order_by: DemiplaneFavoriteAdventuringPlatformOrderBy;
  demiplane_favorite_adventuring_platform_pk_columns_input: DemiplaneFavoriteAdventuringPlatformPkColumnsInput;
  demiplane_favorite_adventuring_platform_select_column: DemiplaneFavoriteAdventuringPlatformSelectColumn;
  demiplane_favorite_adventuring_platform_set_input: DemiplaneFavoriteAdventuringPlatformSetInput;
  demiplane_favorite_adventuring_platform_stddev_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformStddevFields>;
  demiplane_favorite_adventuring_platform_stddev_order_by: DemiplaneFavoriteAdventuringPlatformStddevOrderBy;
  demiplane_favorite_adventuring_platform_stddev_pop_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformStddevPopFields>;
  demiplane_favorite_adventuring_platform_stddev_pop_order_by: DemiplaneFavoriteAdventuringPlatformStddevPopOrderBy;
  demiplane_favorite_adventuring_platform_stddev_samp_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformStddevSampFields>;
  demiplane_favorite_adventuring_platform_stddev_samp_order_by: DemiplaneFavoriteAdventuringPlatformStddevSampOrderBy;
  demiplane_favorite_adventuring_platform_sum_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformSumFields>;
  demiplane_favorite_adventuring_platform_sum_order_by: DemiplaneFavoriteAdventuringPlatformSumOrderBy;
  demiplane_favorite_adventuring_platform_update_column: DemiplaneFavoriteAdventuringPlatformUpdateColumn;
  demiplane_favorite_adventuring_platform_var_pop_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformVarPopFields>;
  demiplane_favorite_adventuring_platform_var_pop_order_by: DemiplaneFavoriteAdventuringPlatformVarPopOrderBy;
  demiplane_favorite_adventuring_platform_var_samp_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformVarSampFields>;
  demiplane_favorite_adventuring_platform_var_samp_order_by: DemiplaneFavoriteAdventuringPlatformVarSampOrderBy;
  demiplane_favorite_adventuring_platform_variance_fields: ResolverTypeWrapper<DemiplaneFavoriteAdventuringPlatformVarianceFields>;
  demiplane_favorite_adventuring_platform_variance_order_by: DemiplaneFavoriteAdventuringPlatformVarianceOrderBy;
  demiplane_favorite_dice_roll: ResolverTypeWrapper<DemiplaneFavoriteDiceRoll>;
  demiplane_favorite_dice_roll_aggregate: ResolverTypeWrapper<DemiplaneFavoriteDiceRollAggregate>;
  demiplane_favorite_dice_roll_aggregate_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollAggregateFields>;
  demiplane_favorite_dice_roll_aggregate_order_by: DemiplaneFavoriteDiceRollAggregateOrderBy;
  demiplane_favorite_dice_roll_arr_rel_insert_input: DemiplaneFavoriteDiceRollArrRelInsertInput;
  demiplane_favorite_dice_roll_avg_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollAvgFields>;
  demiplane_favorite_dice_roll_avg_order_by: DemiplaneFavoriteDiceRollAvgOrderBy;
  demiplane_favorite_dice_roll_bool_exp: DemiplaneFavoriteDiceRollBoolExp;
  demiplane_favorite_dice_roll_constraint: DemiplaneFavoriteDiceRollConstraint;
  demiplane_favorite_dice_roll_inc_input: DemiplaneFavoriteDiceRollIncInput;
  demiplane_favorite_dice_roll_insert_input: DemiplaneFavoriteDiceRollInsertInput;
  demiplane_favorite_dice_roll_max_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollMaxFields>;
  demiplane_favorite_dice_roll_max_order_by: DemiplaneFavoriteDiceRollMaxOrderBy;
  demiplane_favorite_dice_roll_min_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollMinFields>;
  demiplane_favorite_dice_roll_min_order_by: DemiplaneFavoriteDiceRollMinOrderBy;
  demiplane_favorite_dice_roll_mutation_response: ResolverTypeWrapper<DemiplaneFavoriteDiceRollMutationResponse>;
  demiplane_favorite_dice_roll_obj_rel_insert_input: DemiplaneFavoriteDiceRollObjRelInsertInput;
  demiplane_favorite_dice_roll_on_conflict: DemiplaneFavoriteDiceRollOnConflict;
  demiplane_favorite_dice_roll_order_by: DemiplaneFavoriteDiceRollOrderBy;
  demiplane_favorite_dice_roll_pk_columns_input: DemiplaneFavoriteDiceRollPkColumnsInput;
  demiplane_favorite_dice_roll_select_column: DemiplaneFavoriteDiceRollSelectColumn;
  demiplane_favorite_dice_roll_set_input: DemiplaneFavoriteDiceRollSetInput;
  demiplane_favorite_dice_roll_stddev_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollStddevFields>;
  demiplane_favorite_dice_roll_stddev_order_by: DemiplaneFavoriteDiceRollStddevOrderBy;
  demiplane_favorite_dice_roll_stddev_pop_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollStddevPopFields>;
  demiplane_favorite_dice_roll_stddev_pop_order_by: DemiplaneFavoriteDiceRollStddevPopOrderBy;
  demiplane_favorite_dice_roll_stddev_samp_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollStddevSampFields>;
  demiplane_favorite_dice_roll_stddev_samp_order_by: DemiplaneFavoriteDiceRollStddevSampOrderBy;
  demiplane_favorite_dice_roll_sum_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollSumFields>;
  demiplane_favorite_dice_roll_sum_order_by: DemiplaneFavoriteDiceRollSumOrderBy;
  demiplane_favorite_dice_roll_update_column: DemiplaneFavoriteDiceRollUpdateColumn;
  demiplane_favorite_dice_roll_var_pop_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollVarPopFields>;
  demiplane_favorite_dice_roll_var_pop_order_by: DemiplaneFavoriteDiceRollVarPopOrderBy;
  demiplane_favorite_dice_roll_var_samp_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollVarSampFields>;
  demiplane_favorite_dice_roll_var_samp_order_by: DemiplaneFavoriteDiceRollVarSampOrderBy;
  demiplane_favorite_dice_roll_variance_fields: ResolverTypeWrapper<DemiplaneFavoriteDiceRollVarianceFields>;
  demiplane_favorite_dice_roll_variance_order_by: DemiplaneFavoriteDiceRollVarianceOrderBy;
  demiplane_favorite_game: ResolverTypeWrapper<DemiplaneFavoriteGame>;
  demiplane_favorite_game_aggregate: ResolverTypeWrapper<DemiplaneFavoriteGameAggregate>;
  demiplane_favorite_game_aggregate_fields: ResolverTypeWrapper<DemiplaneFavoriteGameAggregateFields>;
  demiplane_favorite_game_aggregate_order_by: DemiplaneFavoriteGameAggregateOrderBy;
  demiplane_favorite_game_arr_rel_insert_input: DemiplaneFavoriteGameArrRelInsertInput;
  demiplane_favorite_game_avg_fields: ResolverTypeWrapper<DemiplaneFavoriteGameAvgFields>;
  demiplane_favorite_game_avg_order_by: DemiplaneFavoriteGameAvgOrderBy;
  demiplane_favorite_game_bool_exp: DemiplaneFavoriteGameBoolExp;
  demiplane_favorite_game_constraint: DemiplaneFavoriteGameConstraint;
  demiplane_favorite_game_inc_input: DemiplaneFavoriteGameIncInput;
  demiplane_favorite_game_insert_input: DemiplaneFavoriteGameInsertInput;
  demiplane_favorite_game_max_fields: ResolverTypeWrapper<DemiplaneFavoriteGameMaxFields>;
  demiplane_favorite_game_max_order_by: DemiplaneFavoriteGameMaxOrderBy;
  demiplane_favorite_game_min_fields: ResolverTypeWrapper<DemiplaneFavoriteGameMinFields>;
  demiplane_favorite_game_min_order_by: DemiplaneFavoriteGameMinOrderBy;
  demiplane_favorite_game_mutation_response: ResolverTypeWrapper<DemiplaneFavoriteGameMutationResponse>;
  demiplane_favorite_game_obj_rel_insert_input: DemiplaneFavoriteGameObjRelInsertInput;
  demiplane_favorite_game_on_conflict: DemiplaneFavoriteGameOnConflict;
  demiplane_favorite_game_order_by: DemiplaneFavoriteGameOrderBy;
  demiplane_favorite_game_pk_columns_input: DemiplaneFavoriteGamePkColumnsInput;
  demiplane_favorite_game_select_column: DemiplaneFavoriteGameSelectColumn;
  demiplane_favorite_game_set_input: DemiplaneFavoriteGameSetInput;
  demiplane_favorite_game_stddev_fields: ResolverTypeWrapper<DemiplaneFavoriteGameStddevFields>;
  demiplane_favorite_game_stddev_order_by: DemiplaneFavoriteGameStddevOrderBy;
  demiplane_favorite_game_stddev_pop_fields: ResolverTypeWrapper<DemiplaneFavoriteGameStddevPopFields>;
  demiplane_favorite_game_stddev_pop_order_by: DemiplaneFavoriteGameStddevPopOrderBy;
  demiplane_favorite_game_stddev_samp_fields: ResolverTypeWrapper<DemiplaneFavoriteGameStddevSampFields>;
  demiplane_favorite_game_stddev_samp_order_by: DemiplaneFavoriteGameStddevSampOrderBy;
  demiplane_favorite_game_sum_fields: ResolverTypeWrapper<DemiplaneFavoriteGameSumFields>;
  demiplane_favorite_game_sum_order_by: DemiplaneFavoriteGameSumOrderBy;
  demiplane_favorite_game_update_column: DemiplaneFavoriteGameUpdateColumn;
  demiplane_favorite_game_var_pop_fields: ResolverTypeWrapper<DemiplaneFavoriteGameVarPopFields>;
  demiplane_favorite_game_var_pop_order_by: DemiplaneFavoriteGameVarPopOrderBy;
  demiplane_favorite_game_var_samp_fields: ResolverTypeWrapper<DemiplaneFavoriteGameVarSampFields>;
  demiplane_favorite_game_var_samp_order_by: DemiplaneFavoriteGameVarSampOrderBy;
  demiplane_favorite_game_variance_fields: ResolverTypeWrapper<DemiplaneFavoriteGameVarianceFields>;
  demiplane_favorite_game_variance_order_by: DemiplaneFavoriteGameVarianceOrderBy;
  demiplane_game_type: ResolverTypeWrapper<DemiplaneGameType>;
  demiplane_game_type_aggregate: ResolverTypeWrapper<DemiplaneGameTypeAggregate>;
  demiplane_game_type_aggregate_fields: ResolverTypeWrapper<DemiplaneGameTypeAggregateFields>;
  demiplane_game_type_aggregate_order_by: DemiplaneGameTypeAggregateOrderBy;
  demiplane_game_type_arr_rel_insert_input: DemiplaneGameTypeArrRelInsertInput;
  demiplane_game_type_avg_fields: ResolverTypeWrapper<DemiplaneGameTypeAvgFields>;
  demiplane_game_type_avg_order_by: DemiplaneGameTypeAvgOrderBy;
  demiplane_game_type_bool_exp: DemiplaneGameTypeBoolExp;
  demiplane_game_type_constraint: DemiplaneGameTypeConstraint;
  demiplane_game_type_inc_input: DemiplaneGameTypeIncInput;
  demiplane_game_type_insert_input: DemiplaneGameTypeInsertInput;
  demiplane_game_type_max_fields: ResolverTypeWrapper<DemiplaneGameTypeMaxFields>;
  demiplane_game_type_max_order_by: DemiplaneGameTypeMaxOrderBy;
  demiplane_game_type_min_fields: ResolverTypeWrapper<DemiplaneGameTypeMinFields>;
  demiplane_game_type_min_order_by: DemiplaneGameTypeMinOrderBy;
  demiplane_game_type_mutation_response: ResolverTypeWrapper<DemiplaneGameTypeMutationResponse>;
  demiplane_game_type_obj_rel_insert_input: DemiplaneGameTypeObjRelInsertInput;
  demiplane_game_type_on_conflict: DemiplaneGameTypeOnConflict;
  demiplane_game_type_order_by: DemiplaneGameTypeOrderBy;
  demiplane_game_type_pk_columns_input: DemiplaneGameTypePkColumnsInput;
  demiplane_game_type_select_column: DemiplaneGameTypeSelectColumn;
  demiplane_game_type_set_input: DemiplaneGameTypeSetInput;
  demiplane_game_type_stddev_fields: ResolverTypeWrapper<DemiplaneGameTypeStddevFields>;
  demiplane_game_type_stddev_order_by: DemiplaneGameTypeStddevOrderBy;
  demiplane_game_type_stddev_pop_fields: ResolverTypeWrapper<DemiplaneGameTypeStddevPopFields>;
  demiplane_game_type_stddev_pop_order_by: DemiplaneGameTypeStddevPopOrderBy;
  demiplane_game_type_stddev_samp_fields: ResolverTypeWrapper<DemiplaneGameTypeStddevSampFields>;
  demiplane_game_type_stddev_samp_order_by: DemiplaneGameTypeStddevSampOrderBy;
  demiplane_game_type_sum_fields: ResolverTypeWrapper<DemiplaneGameTypeSumFields>;
  demiplane_game_type_sum_order_by: DemiplaneGameTypeSumOrderBy;
  demiplane_game_type_update_column: DemiplaneGameTypeUpdateColumn;
  demiplane_game_type_var_pop_fields: ResolverTypeWrapper<DemiplaneGameTypeVarPopFields>;
  demiplane_game_type_var_pop_order_by: DemiplaneGameTypeVarPopOrderBy;
  demiplane_game_type_var_samp_fields: ResolverTypeWrapper<DemiplaneGameTypeVarSampFields>;
  demiplane_game_type_var_samp_order_by: DemiplaneGameTypeVarSampOrderBy;
  demiplane_game_type_variance_fields: ResolverTypeWrapper<DemiplaneGameTypeVarianceFields>;
  demiplane_game_type_variance_order_by: DemiplaneGameTypeVarianceOrderBy;
  demiplane_gm_rating: ResolverTypeWrapper<DemiplaneGmRating>;
  demiplane_gm_rating_aggregate: ResolverTypeWrapper<DemiplaneGmRatingAggregate>;
  demiplane_gm_rating_aggregate_fields: ResolverTypeWrapper<DemiplaneGmRatingAggregateFields>;
  demiplane_gm_rating_aggregate_order_by: DemiplaneGmRatingAggregateOrderBy;
  demiplane_gm_rating_arr_rel_insert_input: DemiplaneGmRatingArrRelInsertInput;
  demiplane_gm_rating_avg_fields: ResolverTypeWrapper<DemiplaneGmRatingAvgFields>;
  demiplane_gm_rating_avg_order_by: DemiplaneGmRatingAvgOrderBy;
  demiplane_gm_rating_bool_exp: DemiplaneGmRatingBoolExp;
  demiplane_gm_rating_constraint: DemiplaneGmRatingConstraint;
  demiplane_gm_rating_inc_input: DemiplaneGmRatingIncInput;
  demiplane_gm_rating_insert_input: DemiplaneGmRatingInsertInput;
  demiplane_gm_rating_max_fields: ResolverTypeWrapper<DemiplaneGmRatingMaxFields>;
  demiplane_gm_rating_max_order_by: DemiplaneGmRatingMaxOrderBy;
  demiplane_gm_rating_min_fields: ResolverTypeWrapper<DemiplaneGmRatingMinFields>;
  demiplane_gm_rating_min_order_by: DemiplaneGmRatingMinOrderBy;
  demiplane_gm_rating_mutation_response: ResolverTypeWrapper<DemiplaneGmRatingMutationResponse>;
  demiplane_gm_rating_obj_rel_insert_input: DemiplaneGmRatingObjRelInsertInput;
  demiplane_gm_rating_on_conflict: DemiplaneGmRatingOnConflict;
  demiplane_gm_rating_order_by: DemiplaneGmRatingOrderBy;
  demiplane_gm_rating_pk_columns_input: DemiplaneGmRatingPkColumnsInput;
  demiplane_gm_rating_select_column: DemiplaneGmRatingSelectColumn;
  demiplane_gm_rating_set_input: DemiplaneGmRatingSetInput;
  demiplane_gm_rating_stddev_fields: ResolverTypeWrapper<DemiplaneGmRatingStddevFields>;
  demiplane_gm_rating_stddev_order_by: DemiplaneGmRatingStddevOrderBy;
  demiplane_gm_rating_stddev_pop_fields: ResolverTypeWrapper<DemiplaneGmRatingStddevPopFields>;
  demiplane_gm_rating_stddev_pop_order_by: DemiplaneGmRatingStddevPopOrderBy;
  demiplane_gm_rating_stddev_samp_fields: ResolverTypeWrapper<DemiplaneGmRatingStddevSampFields>;
  demiplane_gm_rating_stddev_samp_order_by: DemiplaneGmRatingStddevSampOrderBy;
  demiplane_gm_rating_sum_fields: ResolverTypeWrapper<DemiplaneGmRatingSumFields>;
  demiplane_gm_rating_sum_order_by: DemiplaneGmRatingSumOrderBy;
  demiplane_gm_rating_update_column: DemiplaneGmRatingUpdateColumn;
  demiplane_gm_rating_var_pop_fields: ResolverTypeWrapper<DemiplaneGmRatingVarPopFields>;
  demiplane_gm_rating_var_pop_order_by: DemiplaneGmRatingVarPopOrderBy;
  demiplane_gm_rating_var_samp_fields: ResolverTypeWrapper<DemiplaneGmRatingVarSampFields>;
  demiplane_gm_rating_var_samp_order_by: DemiplaneGmRatingVarSampOrderBy;
  demiplane_gm_rating_variance_fields: ResolverTypeWrapper<DemiplaneGmRatingVarianceFields>;
  demiplane_gm_rating_variance_order_by: DemiplaneGmRatingVarianceOrderBy;
  demiplane_gm_recommendation: ResolverTypeWrapper<DemiplaneGmRecommendation>;
  demiplane_gm_recommendation_aggregate: ResolverTypeWrapper<DemiplaneGmRecommendationAggregate>;
  demiplane_gm_recommendation_aggregate_fields: ResolverTypeWrapper<DemiplaneGmRecommendationAggregateFields>;
  demiplane_gm_recommendation_aggregate_order_by: DemiplaneGmRecommendationAggregateOrderBy;
  demiplane_gm_recommendation_arr_rel_insert_input: DemiplaneGmRecommendationArrRelInsertInput;
  demiplane_gm_recommendation_avg_fields: ResolverTypeWrapper<DemiplaneGmRecommendationAvgFields>;
  demiplane_gm_recommendation_avg_order_by: DemiplaneGmRecommendationAvgOrderBy;
  demiplane_gm_recommendation_bool_exp: DemiplaneGmRecommendationBoolExp;
  demiplane_gm_recommendation_constraint: DemiplaneGmRecommendationConstraint;
  demiplane_gm_recommendation_inc_input: DemiplaneGmRecommendationIncInput;
  demiplane_gm_recommendation_insert_input: DemiplaneGmRecommendationInsertInput;
  demiplane_gm_recommendation_max_fields: ResolverTypeWrapper<DemiplaneGmRecommendationMaxFields>;
  demiplane_gm_recommendation_max_order_by: DemiplaneGmRecommendationMaxOrderBy;
  demiplane_gm_recommendation_min_fields: ResolverTypeWrapper<DemiplaneGmRecommendationMinFields>;
  demiplane_gm_recommendation_min_order_by: DemiplaneGmRecommendationMinOrderBy;
  demiplane_gm_recommendation_mutation_response: ResolverTypeWrapper<DemiplaneGmRecommendationMutationResponse>;
  demiplane_gm_recommendation_obj_rel_insert_input: DemiplaneGmRecommendationObjRelInsertInput;
  demiplane_gm_recommendation_on_conflict: DemiplaneGmRecommendationOnConflict;
  demiplane_gm_recommendation_order_by: DemiplaneGmRecommendationOrderBy;
  demiplane_gm_recommendation_pk_columns_input: DemiplaneGmRecommendationPkColumnsInput;
  demiplane_gm_recommendation_select_column: DemiplaneGmRecommendationSelectColumn;
  demiplane_gm_recommendation_set_input: DemiplaneGmRecommendationSetInput;
  demiplane_gm_recommendation_stddev_fields: ResolverTypeWrapper<DemiplaneGmRecommendationStddevFields>;
  demiplane_gm_recommendation_stddev_order_by: DemiplaneGmRecommendationStddevOrderBy;
  demiplane_gm_recommendation_stddev_pop_fields: ResolverTypeWrapper<DemiplaneGmRecommendationStddevPopFields>;
  demiplane_gm_recommendation_stddev_pop_order_by: DemiplaneGmRecommendationStddevPopOrderBy;
  demiplane_gm_recommendation_stddev_samp_fields: ResolverTypeWrapper<DemiplaneGmRecommendationStddevSampFields>;
  demiplane_gm_recommendation_stddev_samp_order_by: DemiplaneGmRecommendationStddevSampOrderBy;
  demiplane_gm_recommendation_sum_fields: ResolverTypeWrapper<DemiplaneGmRecommendationSumFields>;
  demiplane_gm_recommendation_sum_order_by: DemiplaneGmRecommendationSumOrderBy;
  demiplane_gm_recommendation_update_column: DemiplaneGmRecommendationUpdateColumn;
  demiplane_gm_recommendation_var_pop_fields: ResolverTypeWrapper<DemiplaneGmRecommendationVarPopFields>;
  demiplane_gm_recommendation_var_pop_order_by: DemiplaneGmRecommendationVarPopOrderBy;
  demiplane_gm_recommendation_var_samp_fields: ResolverTypeWrapper<DemiplaneGmRecommendationVarSampFields>;
  demiplane_gm_recommendation_var_samp_order_by: DemiplaneGmRecommendationVarSampOrderBy;
  demiplane_gm_recommendation_variance_fields: ResolverTypeWrapper<DemiplaneGmRecommendationVarianceFields>;
  demiplane_gm_recommendation_variance_order_by: DemiplaneGmRecommendationVarianceOrderBy;
  demiplane_invitation: ResolverTypeWrapper<DemiplaneInvitation>;
  demiplane_invitation_aggregate: ResolverTypeWrapper<DemiplaneInvitationAggregate>;
  demiplane_invitation_aggregate_fields: ResolverTypeWrapper<DemiplaneInvitationAggregateFields>;
  demiplane_invitation_aggregate_order_by: DemiplaneInvitationAggregateOrderBy;
  demiplane_invitation_arr_rel_insert_input: DemiplaneInvitationArrRelInsertInput;
  demiplane_invitation_avg_fields: ResolverTypeWrapper<DemiplaneInvitationAvgFields>;
  demiplane_invitation_avg_order_by: DemiplaneInvitationAvgOrderBy;
  demiplane_invitation_bool_exp: DemiplaneInvitationBoolExp;
  demiplane_invitation_constraint: DemiplaneInvitationConstraint;
  demiplane_invitation_inc_input: DemiplaneInvitationIncInput;
  demiplane_invitation_insert_input: DemiplaneInvitationInsertInput;
  demiplane_invitation_max_fields: ResolverTypeWrapper<DemiplaneInvitationMaxFields>;
  demiplane_invitation_max_order_by: DemiplaneInvitationMaxOrderBy;
  demiplane_invitation_min_fields: ResolverTypeWrapper<DemiplaneInvitationMinFields>;
  demiplane_invitation_min_order_by: DemiplaneInvitationMinOrderBy;
  demiplane_invitation_mutation_response: ResolverTypeWrapper<DemiplaneInvitationMutationResponse>;
  demiplane_invitation_obj_rel_insert_input: DemiplaneInvitationObjRelInsertInput;
  demiplane_invitation_on_conflict: DemiplaneInvitationOnConflict;
  demiplane_invitation_order_by: DemiplaneInvitationOrderBy;
  demiplane_invitation_pk_columns_input: DemiplaneInvitationPkColumnsInput;
  demiplane_invitation_select_column: DemiplaneInvitationSelectColumn;
  demiplane_invitation_set_input: DemiplaneInvitationSetInput;
  demiplane_invitation_stddev_fields: ResolverTypeWrapper<DemiplaneInvitationStddevFields>;
  demiplane_invitation_stddev_order_by: DemiplaneInvitationStddevOrderBy;
  demiplane_invitation_stddev_pop_fields: ResolverTypeWrapper<DemiplaneInvitationStddevPopFields>;
  demiplane_invitation_stddev_pop_order_by: DemiplaneInvitationStddevPopOrderBy;
  demiplane_invitation_stddev_samp_fields: ResolverTypeWrapper<DemiplaneInvitationStddevSampFields>;
  demiplane_invitation_stddev_samp_order_by: DemiplaneInvitationStddevSampOrderBy;
  demiplane_invitation_sum_fields: ResolverTypeWrapper<DemiplaneInvitationSumFields>;
  demiplane_invitation_sum_order_by: DemiplaneInvitationSumOrderBy;
  demiplane_invitation_update_column: DemiplaneInvitationUpdateColumn;
  demiplane_invitation_var_pop_fields: ResolverTypeWrapper<DemiplaneInvitationVarPopFields>;
  demiplane_invitation_var_pop_order_by: DemiplaneInvitationVarPopOrderBy;
  demiplane_invitation_var_samp_fields: ResolverTypeWrapper<DemiplaneInvitationVarSampFields>;
  demiplane_invitation_var_samp_order_by: DemiplaneInvitationVarSampOrderBy;
  demiplane_invitation_variance_fields: ResolverTypeWrapper<DemiplaneInvitationVarianceFields>;
  demiplane_invitation_variance_order_by: DemiplaneInvitationVarianceOrderBy;
  demiplane_journal: ResolverTypeWrapper<DemiplaneJournal>;
  demiplane_journal_aggregate: ResolverTypeWrapper<DemiplaneJournalAggregate>;
  demiplane_journal_aggregate_fields: ResolverTypeWrapper<DemiplaneJournalAggregateFields>;
  demiplane_journal_aggregate_order_by: DemiplaneJournalAggregateOrderBy;
  demiplane_journal_arr_rel_insert_input: DemiplaneJournalArrRelInsertInput;
  demiplane_journal_avg_fields: ResolverTypeWrapper<DemiplaneJournalAvgFields>;
  demiplane_journal_avg_order_by: DemiplaneJournalAvgOrderBy;
  demiplane_journal_bool_exp: DemiplaneJournalBoolExp;
  demiplane_journal_constraint: DemiplaneJournalConstraint;
  demiplane_journal_inc_input: DemiplaneJournalIncInput;
  demiplane_journal_insert_input: DemiplaneJournalInsertInput;
  demiplane_journal_max_fields: ResolverTypeWrapper<DemiplaneJournalMaxFields>;
  demiplane_journal_max_order_by: DemiplaneJournalMaxOrderBy;
  demiplane_journal_min_fields: ResolverTypeWrapper<DemiplaneJournalMinFields>;
  demiplane_journal_min_order_by: DemiplaneJournalMinOrderBy;
  demiplane_journal_mutation_response: ResolverTypeWrapper<DemiplaneJournalMutationResponse>;
  demiplane_journal_note: ResolverTypeWrapper<DemiplaneJournalNote>;
  demiplane_journal_note_aggregate: ResolverTypeWrapper<DemiplaneJournalNoteAggregate>;
  demiplane_journal_note_aggregate_fields: ResolverTypeWrapper<DemiplaneJournalNoteAggregateFields>;
  demiplane_journal_note_aggregate_order_by: DemiplaneJournalNoteAggregateOrderBy;
  demiplane_journal_note_append_input: DemiplaneJournalNoteAppendInput;
  demiplane_journal_note_arr_rel_insert_input: DemiplaneJournalNoteArrRelInsertInput;
  demiplane_journal_note_avg_fields: ResolverTypeWrapper<DemiplaneJournalNoteAvgFields>;
  demiplane_journal_note_avg_order_by: DemiplaneJournalNoteAvgOrderBy;
  demiplane_journal_note_bool_exp: DemiplaneJournalNoteBoolExp;
  demiplane_journal_note_constraint: DemiplaneJournalNoteConstraint;
  demiplane_journal_note_delete_at_path_input: DemiplaneJournalNoteDeleteAtPathInput;
  demiplane_journal_note_delete_elem_input: DemiplaneJournalNoteDeleteElemInput;
  demiplane_journal_note_delete_key_input: DemiplaneJournalNoteDeleteKeyInput;
  demiplane_journal_note_inc_input: DemiplaneJournalNoteIncInput;
  demiplane_journal_note_insert_input: DemiplaneJournalNoteInsertInput;
  demiplane_journal_note_max_fields: ResolverTypeWrapper<DemiplaneJournalNoteMaxFields>;
  demiplane_journal_note_max_order_by: DemiplaneJournalNoteMaxOrderBy;
  demiplane_journal_note_min_fields: ResolverTypeWrapper<DemiplaneJournalNoteMinFields>;
  demiplane_journal_note_min_order_by: DemiplaneJournalNoteMinOrderBy;
  demiplane_journal_note_mutation_response: ResolverTypeWrapper<DemiplaneJournalNoteMutationResponse>;
  demiplane_journal_note_obj_rel_insert_input: DemiplaneJournalNoteObjRelInsertInput;
  demiplane_journal_note_on_conflict: DemiplaneJournalNoteOnConflict;
  demiplane_journal_note_order_by: DemiplaneJournalNoteOrderBy;
  demiplane_journal_note_pk_columns_input: DemiplaneJournalNotePkColumnsInput;
  demiplane_journal_note_prepend_input: DemiplaneJournalNotePrependInput;
  demiplane_journal_note_select_column: DemiplaneJournalNoteSelectColumn;
  demiplane_journal_note_set_input: DemiplaneJournalNoteSetInput;
  demiplane_journal_note_share: ResolverTypeWrapper<DemiplaneJournalNoteShare>;
  demiplane_journal_note_share_aggregate: ResolverTypeWrapper<DemiplaneJournalNoteShareAggregate>;
  demiplane_journal_note_share_aggregate_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareAggregateFields>;
  demiplane_journal_note_share_aggregate_order_by: DemiplaneJournalNoteShareAggregateOrderBy;
  demiplane_journal_note_share_arr_rel_insert_input: DemiplaneJournalNoteShareArrRelInsertInput;
  demiplane_journal_note_share_avg_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareAvgFields>;
  demiplane_journal_note_share_avg_order_by: DemiplaneJournalNoteShareAvgOrderBy;
  demiplane_journal_note_share_bool_exp: DemiplaneJournalNoteShareBoolExp;
  demiplane_journal_note_share_constraint: DemiplaneJournalNoteShareConstraint;
  demiplane_journal_note_share_inc_input: DemiplaneJournalNoteShareIncInput;
  demiplane_journal_note_share_insert_input: DemiplaneJournalNoteShareInsertInput;
  demiplane_journal_note_share_max_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareMaxFields>;
  demiplane_journal_note_share_max_order_by: DemiplaneJournalNoteShareMaxOrderBy;
  demiplane_journal_note_share_min_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareMinFields>;
  demiplane_journal_note_share_min_order_by: DemiplaneJournalNoteShareMinOrderBy;
  demiplane_journal_note_share_mutation_response: ResolverTypeWrapper<DemiplaneJournalNoteShareMutationResponse>;
  demiplane_journal_note_share_obj_rel_insert_input: DemiplaneJournalNoteShareObjRelInsertInput;
  demiplane_journal_note_share_on_conflict: DemiplaneJournalNoteShareOnConflict;
  demiplane_journal_note_share_order_by: DemiplaneJournalNoteShareOrderBy;
  demiplane_journal_note_share_pk_columns_input: DemiplaneJournalNoteSharePkColumnsInput;
  demiplane_journal_note_share_select_column: DemiplaneJournalNoteShareSelectColumn;
  demiplane_journal_note_share_set_input: DemiplaneJournalNoteShareSetInput;
  demiplane_journal_note_share_stddev_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareStddevFields>;
  demiplane_journal_note_share_stddev_order_by: DemiplaneJournalNoteShareStddevOrderBy;
  demiplane_journal_note_share_stddev_pop_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareStddevPopFields>;
  demiplane_journal_note_share_stddev_pop_order_by: DemiplaneJournalNoteShareStddevPopOrderBy;
  demiplane_journal_note_share_stddev_samp_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareStddevSampFields>;
  demiplane_journal_note_share_stddev_samp_order_by: DemiplaneJournalNoteShareStddevSampOrderBy;
  demiplane_journal_note_share_sum_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareSumFields>;
  demiplane_journal_note_share_sum_order_by: DemiplaneJournalNoteShareSumOrderBy;
  demiplane_journal_note_share_update_column: DemiplaneJournalNoteShareUpdateColumn;
  demiplane_journal_note_share_var_pop_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareVarPopFields>;
  demiplane_journal_note_share_var_pop_order_by: DemiplaneJournalNoteShareVarPopOrderBy;
  demiplane_journal_note_share_var_samp_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareVarSampFields>;
  demiplane_journal_note_share_var_samp_order_by: DemiplaneJournalNoteShareVarSampOrderBy;
  demiplane_journal_note_share_variance_fields: ResolverTypeWrapper<DemiplaneJournalNoteShareVarianceFields>;
  demiplane_journal_note_share_variance_order_by: DemiplaneJournalNoteShareVarianceOrderBy;
  demiplane_journal_note_stddev_fields: ResolverTypeWrapper<DemiplaneJournalNoteStddevFields>;
  demiplane_journal_note_stddev_order_by: DemiplaneJournalNoteStddevOrderBy;
  demiplane_journal_note_stddev_pop_fields: ResolverTypeWrapper<DemiplaneJournalNoteStddevPopFields>;
  demiplane_journal_note_stddev_pop_order_by: DemiplaneJournalNoteStddevPopOrderBy;
  demiplane_journal_note_stddev_samp_fields: ResolverTypeWrapper<DemiplaneJournalNoteStddevSampFields>;
  demiplane_journal_note_stddev_samp_order_by: DemiplaneJournalNoteStddevSampOrderBy;
  demiplane_journal_note_sum_fields: ResolverTypeWrapper<DemiplaneJournalNoteSumFields>;
  demiplane_journal_note_sum_order_by: DemiplaneJournalNoteSumOrderBy;
  demiplane_journal_note_update_column: DemiplaneJournalNoteUpdateColumn;
  demiplane_journal_note_var_pop_fields: ResolverTypeWrapper<DemiplaneJournalNoteVarPopFields>;
  demiplane_journal_note_var_pop_order_by: DemiplaneJournalNoteVarPopOrderBy;
  demiplane_journal_note_var_samp_fields: ResolverTypeWrapper<DemiplaneJournalNoteVarSampFields>;
  demiplane_journal_note_var_samp_order_by: DemiplaneJournalNoteVarSampOrderBy;
  demiplane_journal_note_variance_fields: ResolverTypeWrapper<DemiplaneJournalNoteVarianceFields>;
  demiplane_journal_note_variance_order_by: DemiplaneJournalNoteVarianceOrderBy;
  demiplane_journal_obj_rel_insert_input: DemiplaneJournalObjRelInsertInput;
  demiplane_journal_on_conflict: DemiplaneJournalOnConflict;
  demiplane_journal_order_by: DemiplaneJournalOrderBy;
  demiplane_journal_pk_columns_input: DemiplaneJournalPkColumnsInput;
  demiplane_journal_select_column: DemiplaneJournalSelectColumn;
  demiplane_journal_set_input: DemiplaneJournalSetInput;
  demiplane_journal_stddev_fields: ResolverTypeWrapper<DemiplaneJournalStddevFields>;
  demiplane_journal_stddev_order_by: DemiplaneJournalStddevOrderBy;
  demiplane_journal_stddev_pop_fields: ResolverTypeWrapper<DemiplaneJournalStddevPopFields>;
  demiplane_journal_stddev_pop_order_by: DemiplaneJournalStddevPopOrderBy;
  demiplane_journal_stddev_samp_fields: ResolverTypeWrapper<DemiplaneJournalStddevSampFields>;
  demiplane_journal_stddev_samp_order_by: DemiplaneJournalStddevSampOrderBy;
  demiplane_journal_sum_fields: ResolverTypeWrapper<DemiplaneJournalSumFields>;
  demiplane_journal_sum_order_by: DemiplaneJournalSumOrderBy;
  demiplane_journal_super_event: ResolverTypeWrapper<DemiplaneJournalSuperEvent>;
  demiplane_journal_super_event_aggregate: ResolverTypeWrapper<DemiplaneJournalSuperEventAggregate>;
  demiplane_journal_super_event_aggregate_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventAggregateFields>;
  demiplane_journal_super_event_aggregate_order_by: DemiplaneJournalSuperEventAggregateOrderBy;
  demiplane_journal_super_event_arr_rel_insert_input: DemiplaneJournalSuperEventArrRelInsertInput;
  demiplane_journal_super_event_avg_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventAvgFields>;
  demiplane_journal_super_event_avg_order_by: DemiplaneJournalSuperEventAvgOrderBy;
  demiplane_journal_super_event_bool_exp: DemiplaneJournalSuperEventBoolExp;
  demiplane_journal_super_event_constraint: DemiplaneJournalSuperEventConstraint;
  demiplane_journal_super_event_inc_input: DemiplaneJournalSuperEventIncInput;
  demiplane_journal_super_event_insert_input: DemiplaneJournalSuperEventInsertInput;
  demiplane_journal_super_event_max_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventMaxFields>;
  demiplane_journal_super_event_max_order_by: DemiplaneJournalSuperEventMaxOrderBy;
  demiplane_journal_super_event_min_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventMinFields>;
  demiplane_journal_super_event_min_order_by: DemiplaneJournalSuperEventMinOrderBy;
  demiplane_journal_super_event_mutation_response: ResolverTypeWrapper<DemiplaneJournalSuperEventMutationResponse>;
  demiplane_journal_super_event_obj_rel_insert_input: DemiplaneJournalSuperEventObjRelInsertInput;
  demiplane_journal_super_event_on_conflict: DemiplaneJournalSuperEventOnConflict;
  demiplane_journal_super_event_order_by: DemiplaneJournalSuperEventOrderBy;
  demiplane_journal_super_event_pk_columns_input: DemiplaneJournalSuperEventPkColumnsInput;
  demiplane_journal_super_event_read_status: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatus>;
  demiplane_journal_super_event_read_status_aggregate: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusAggregate>;
  demiplane_journal_super_event_read_status_aggregate_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusAggregateFields>;
  demiplane_journal_super_event_read_status_aggregate_order_by: DemiplaneJournalSuperEventReadStatusAggregateOrderBy;
  demiplane_journal_super_event_read_status_arr_rel_insert_input: DemiplaneJournalSuperEventReadStatusArrRelInsertInput;
  demiplane_journal_super_event_read_status_avg_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusAvgFields>;
  demiplane_journal_super_event_read_status_avg_order_by: DemiplaneJournalSuperEventReadStatusAvgOrderBy;
  demiplane_journal_super_event_read_status_bool_exp: DemiplaneJournalSuperEventReadStatusBoolExp;
  demiplane_journal_super_event_read_status_constraint: DemiplaneJournalSuperEventReadStatusConstraint;
  demiplane_journal_super_event_read_status_inc_input: DemiplaneJournalSuperEventReadStatusIncInput;
  demiplane_journal_super_event_read_status_insert_input: DemiplaneJournalSuperEventReadStatusInsertInput;
  demiplane_journal_super_event_read_status_max_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusMaxFields>;
  demiplane_journal_super_event_read_status_max_order_by: DemiplaneJournalSuperEventReadStatusMaxOrderBy;
  demiplane_journal_super_event_read_status_min_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusMinFields>;
  demiplane_journal_super_event_read_status_min_order_by: DemiplaneJournalSuperEventReadStatusMinOrderBy;
  demiplane_journal_super_event_read_status_mutation_response: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusMutationResponse>;
  demiplane_journal_super_event_read_status_obj_rel_insert_input: DemiplaneJournalSuperEventReadStatusObjRelInsertInput;
  demiplane_journal_super_event_read_status_on_conflict: DemiplaneJournalSuperEventReadStatusOnConflict;
  demiplane_journal_super_event_read_status_order_by: DemiplaneJournalSuperEventReadStatusOrderBy;
  demiplane_journal_super_event_read_status_pk_columns_input: DemiplaneJournalSuperEventReadStatusPkColumnsInput;
  demiplane_journal_super_event_read_status_select_column: DemiplaneJournalSuperEventReadStatusSelectColumn;
  demiplane_journal_super_event_read_status_set_input: DemiplaneJournalSuperEventReadStatusSetInput;
  demiplane_journal_super_event_read_status_stddev_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusStddevFields>;
  demiplane_journal_super_event_read_status_stddev_order_by: DemiplaneJournalSuperEventReadStatusStddevOrderBy;
  demiplane_journal_super_event_read_status_stddev_pop_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusStddevPopFields>;
  demiplane_journal_super_event_read_status_stddev_pop_order_by: DemiplaneJournalSuperEventReadStatusStddevPopOrderBy;
  demiplane_journal_super_event_read_status_stddev_samp_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusStddevSampFields>;
  demiplane_journal_super_event_read_status_stddev_samp_order_by: DemiplaneJournalSuperEventReadStatusStddevSampOrderBy;
  demiplane_journal_super_event_read_status_sum_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusSumFields>;
  demiplane_journal_super_event_read_status_sum_order_by: DemiplaneJournalSuperEventReadStatusSumOrderBy;
  demiplane_journal_super_event_read_status_update_column: DemiplaneJournalSuperEventReadStatusUpdateColumn;
  demiplane_journal_super_event_read_status_var_pop_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusVarPopFields>;
  demiplane_journal_super_event_read_status_var_pop_order_by: DemiplaneJournalSuperEventReadStatusVarPopOrderBy;
  demiplane_journal_super_event_read_status_var_samp_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusVarSampFields>;
  demiplane_journal_super_event_read_status_var_samp_order_by: DemiplaneJournalSuperEventReadStatusVarSampOrderBy;
  demiplane_journal_super_event_read_status_variance_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventReadStatusVarianceFields>;
  demiplane_journal_super_event_read_status_variance_order_by: DemiplaneJournalSuperEventReadStatusVarianceOrderBy;
  demiplane_journal_super_event_select_column: DemiplaneJournalSuperEventSelectColumn;
  demiplane_journal_super_event_set_input: DemiplaneJournalSuperEventSetInput;
  demiplane_journal_super_event_stddev_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventStddevFields>;
  demiplane_journal_super_event_stddev_order_by: DemiplaneJournalSuperEventStddevOrderBy;
  demiplane_journal_super_event_stddev_pop_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventStddevPopFields>;
  demiplane_journal_super_event_stddev_pop_order_by: DemiplaneJournalSuperEventStddevPopOrderBy;
  demiplane_journal_super_event_stddev_samp_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventStddevSampFields>;
  demiplane_journal_super_event_stddev_samp_order_by: DemiplaneJournalSuperEventStddevSampOrderBy;
  demiplane_journal_super_event_sum_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventSumFields>;
  demiplane_journal_super_event_sum_order_by: DemiplaneJournalSuperEventSumOrderBy;
  demiplane_journal_super_event_update_column: DemiplaneJournalSuperEventUpdateColumn;
  demiplane_journal_super_event_var_pop_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventVarPopFields>;
  demiplane_journal_super_event_var_pop_order_by: DemiplaneJournalSuperEventVarPopOrderBy;
  demiplane_journal_super_event_var_samp_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventVarSampFields>;
  demiplane_journal_super_event_var_samp_order_by: DemiplaneJournalSuperEventVarSampOrderBy;
  demiplane_journal_super_event_variance_fields: ResolverTypeWrapper<DemiplaneJournalSuperEventVarianceFields>;
  demiplane_journal_super_event_variance_order_by: DemiplaneJournalSuperEventVarianceOrderBy;
  demiplane_journal_title: ResolverTypeWrapper<DemiplaneJournalTitle>;
  demiplane_journal_title_aggregate: ResolverTypeWrapper<DemiplaneJournalTitleAggregate>;
  demiplane_journal_title_aggregate_fields: ResolverTypeWrapper<DemiplaneJournalTitleAggregateFields>;
  demiplane_journal_title_aggregate_order_by: DemiplaneJournalTitleAggregateOrderBy;
  demiplane_journal_title_arr_rel_insert_input: DemiplaneJournalTitleArrRelInsertInput;
  demiplane_journal_title_avg_fields: ResolverTypeWrapper<DemiplaneJournalTitleAvgFields>;
  demiplane_journal_title_avg_order_by: DemiplaneJournalTitleAvgOrderBy;
  demiplane_journal_title_bool_exp: DemiplaneJournalTitleBoolExp;
  demiplane_journal_title_constraint: DemiplaneJournalTitleConstraint;
  demiplane_journal_title_inc_input: DemiplaneJournalTitleIncInput;
  demiplane_journal_title_insert_input: DemiplaneJournalTitleInsertInput;
  demiplane_journal_title_max_fields: ResolverTypeWrapper<DemiplaneJournalTitleMaxFields>;
  demiplane_journal_title_max_order_by: DemiplaneJournalTitleMaxOrderBy;
  demiplane_journal_title_min_fields: ResolverTypeWrapper<DemiplaneJournalTitleMinFields>;
  demiplane_journal_title_min_order_by: DemiplaneJournalTitleMinOrderBy;
  demiplane_journal_title_mutation_response: ResolverTypeWrapper<DemiplaneJournalTitleMutationResponse>;
  demiplane_journal_title_obj_rel_insert_input: DemiplaneJournalTitleObjRelInsertInput;
  demiplane_journal_title_on_conflict: DemiplaneJournalTitleOnConflict;
  demiplane_journal_title_order_by: DemiplaneJournalTitleOrderBy;
  demiplane_journal_title_pk_columns_input: DemiplaneJournalTitlePkColumnsInput;
  demiplane_journal_title_select_column: DemiplaneJournalTitleSelectColumn;
  demiplane_journal_title_set_input: DemiplaneJournalTitleSetInput;
  demiplane_journal_title_stddev_fields: ResolverTypeWrapper<DemiplaneJournalTitleStddevFields>;
  demiplane_journal_title_stddev_order_by: DemiplaneJournalTitleStddevOrderBy;
  demiplane_journal_title_stddev_pop_fields: ResolverTypeWrapper<DemiplaneJournalTitleStddevPopFields>;
  demiplane_journal_title_stddev_pop_order_by: DemiplaneJournalTitleStddevPopOrderBy;
  demiplane_journal_title_stddev_samp_fields: ResolverTypeWrapper<DemiplaneJournalTitleStddevSampFields>;
  demiplane_journal_title_stddev_samp_order_by: DemiplaneJournalTitleStddevSampOrderBy;
  demiplane_journal_title_sum_fields: ResolverTypeWrapper<DemiplaneJournalTitleSumFields>;
  demiplane_journal_title_sum_order_by: DemiplaneJournalTitleSumOrderBy;
  demiplane_journal_title_update_column: DemiplaneJournalTitleUpdateColumn;
  demiplane_journal_title_var_pop_fields: ResolverTypeWrapper<DemiplaneJournalTitleVarPopFields>;
  demiplane_journal_title_var_pop_order_by: DemiplaneJournalTitleVarPopOrderBy;
  demiplane_journal_title_var_samp_fields: ResolverTypeWrapper<DemiplaneJournalTitleVarSampFields>;
  demiplane_journal_title_var_samp_order_by: DemiplaneJournalTitleVarSampOrderBy;
  demiplane_journal_title_variance_fields: ResolverTypeWrapper<DemiplaneJournalTitleVarianceFields>;
  demiplane_journal_title_variance_order_by: DemiplaneJournalTitleVarianceOrderBy;
  demiplane_journal_update_column: DemiplaneJournalUpdateColumn;
  demiplane_journal_var_pop_fields: ResolverTypeWrapper<DemiplaneJournalVarPopFields>;
  demiplane_journal_var_pop_order_by: DemiplaneJournalVarPopOrderBy;
  demiplane_journal_var_samp_fields: ResolverTypeWrapper<DemiplaneJournalVarSampFields>;
  demiplane_journal_var_samp_order_by: DemiplaneJournalVarSampOrderBy;
  demiplane_journal_variance_fields: ResolverTypeWrapper<DemiplaneJournalVarianceFields>;
  demiplane_journal_variance_order_by: DemiplaneJournalVarianceOrderBy;
  demiplane_matchmaking_token: ResolverTypeWrapper<DemiplaneMatchmakingToken>;
  demiplane_matchmaking_token_aggregate: ResolverTypeWrapper<DemiplaneMatchmakingTokenAggregate>;
  demiplane_matchmaking_token_aggregate_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenAggregateFields>;
  demiplane_matchmaking_token_aggregate_order_by: DemiplaneMatchmakingTokenAggregateOrderBy;
  demiplane_matchmaking_token_arr_rel_insert_input: DemiplaneMatchmakingTokenArrRelInsertInput;
  demiplane_matchmaking_token_avg_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenAvgFields>;
  demiplane_matchmaking_token_avg_order_by: DemiplaneMatchmakingTokenAvgOrderBy;
  demiplane_matchmaking_token_bool_exp: DemiplaneMatchmakingTokenBoolExp;
  demiplane_matchmaking_token_constraint: DemiplaneMatchmakingTokenConstraint;
  demiplane_matchmaking_token_inc_input: DemiplaneMatchmakingTokenIncInput;
  demiplane_matchmaking_token_insert_input: DemiplaneMatchmakingTokenInsertInput;
  demiplane_matchmaking_token_max_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenMaxFields>;
  demiplane_matchmaking_token_max_order_by: DemiplaneMatchmakingTokenMaxOrderBy;
  demiplane_matchmaking_token_min_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenMinFields>;
  demiplane_matchmaking_token_min_order_by: DemiplaneMatchmakingTokenMinOrderBy;
  demiplane_matchmaking_token_mutation_response: ResolverTypeWrapper<DemiplaneMatchmakingTokenMutationResponse>;
  demiplane_matchmaking_token_obj_rel_insert_input: DemiplaneMatchmakingTokenObjRelInsertInput;
  demiplane_matchmaking_token_on_conflict: DemiplaneMatchmakingTokenOnConflict;
  demiplane_matchmaking_token_order_by: DemiplaneMatchmakingTokenOrderBy;
  demiplane_matchmaking_token_pk_columns_input: DemiplaneMatchmakingTokenPkColumnsInput;
  demiplane_matchmaking_token_select_column: DemiplaneMatchmakingTokenSelectColumn;
  demiplane_matchmaking_token_set_input: DemiplaneMatchmakingTokenSetInput;
  demiplane_matchmaking_token_stddev_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenStddevFields>;
  demiplane_matchmaking_token_stddev_order_by: DemiplaneMatchmakingTokenStddevOrderBy;
  demiplane_matchmaking_token_stddev_pop_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenStddevPopFields>;
  demiplane_matchmaking_token_stddev_pop_order_by: DemiplaneMatchmakingTokenStddevPopOrderBy;
  demiplane_matchmaking_token_stddev_samp_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenStddevSampFields>;
  demiplane_matchmaking_token_stddev_samp_order_by: DemiplaneMatchmakingTokenStddevSampOrderBy;
  demiplane_matchmaking_token_sum_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenSumFields>;
  demiplane_matchmaking_token_sum_order_by: DemiplaneMatchmakingTokenSumOrderBy;
  demiplane_matchmaking_token_update_column: DemiplaneMatchmakingTokenUpdateColumn;
  demiplane_matchmaking_token_var_pop_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenVarPopFields>;
  demiplane_matchmaking_token_var_pop_order_by: DemiplaneMatchmakingTokenVarPopOrderBy;
  demiplane_matchmaking_token_var_samp_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenVarSampFields>;
  demiplane_matchmaking_token_var_samp_order_by: DemiplaneMatchmakingTokenVarSampOrderBy;
  demiplane_matchmaking_token_variance_fields: ResolverTypeWrapper<DemiplaneMatchmakingTokenVarianceFields>;
  demiplane_matchmaking_token_variance_order_by: DemiplaneMatchmakingTokenVarianceOrderBy;
  demiplane_migrations: ResolverTypeWrapper<DemiplaneMigrations>;
  demiplane_migrations_aggregate: ResolverTypeWrapper<DemiplaneMigrationsAggregate>;
  demiplane_migrations_aggregate_fields: ResolverTypeWrapper<DemiplaneMigrationsAggregateFields>;
  demiplane_migrations_aggregate_order_by: DemiplaneMigrationsAggregateOrderBy;
  demiplane_migrations_arr_rel_insert_input: DemiplaneMigrationsArrRelInsertInput;
  demiplane_migrations_avg_fields: ResolverTypeWrapper<DemiplaneMigrationsAvgFields>;
  demiplane_migrations_avg_order_by: DemiplaneMigrationsAvgOrderBy;
  demiplane_migrations_bool_exp: DemiplaneMigrationsBoolExp;
  demiplane_migrations_constraint: DemiplaneMigrationsConstraint;
  demiplane_migrations_inc_input: DemiplaneMigrationsIncInput;
  demiplane_migrations_insert_input: DemiplaneMigrationsInsertInput;
  demiplane_migrations_max_fields: ResolverTypeWrapper<DemiplaneMigrationsMaxFields>;
  demiplane_migrations_max_order_by: DemiplaneMigrationsMaxOrderBy;
  demiplane_migrations_min_fields: ResolverTypeWrapper<DemiplaneMigrationsMinFields>;
  demiplane_migrations_min_order_by: DemiplaneMigrationsMinOrderBy;
  demiplane_migrations_mutation_response: ResolverTypeWrapper<DemiplaneMigrationsMutationResponse>;
  demiplane_migrations_obj_rel_insert_input: DemiplaneMigrationsObjRelInsertInput;
  demiplane_migrations_on_conflict: DemiplaneMigrationsOnConflict;
  demiplane_migrations_order_by: DemiplaneMigrationsOrderBy;
  demiplane_migrations_pk_columns_input: DemiplaneMigrationsPkColumnsInput;
  demiplane_migrations_select_column: DemiplaneMigrationsSelectColumn;
  demiplane_migrations_set_input: DemiplaneMigrationsSetInput;
  demiplane_migrations_stddev_fields: ResolverTypeWrapper<DemiplaneMigrationsStddevFields>;
  demiplane_migrations_stddev_order_by: DemiplaneMigrationsStddevOrderBy;
  demiplane_migrations_stddev_pop_fields: ResolverTypeWrapper<DemiplaneMigrationsStddevPopFields>;
  demiplane_migrations_stddev_pop_order_by: DemiplaneMigrationsStddevPopOrderBy;
  demiplane_migrations_stddev_samp_fields: ResolverTypeWrapper<DemiplaneMigrationsStddevSampFields>;
  demiplane_migrations_stddev_samp_order_by: DemiplaneMigrationsStddevSampOrderBy;
  demiplane_migrations_sum_fields: ResolverTypeWrapper<DemiplaneMigrationsSumFields>;
  demiplane_migrations_sum_order_by: DemiplaneMigrationsSumOrderBy;
  demiplane_migrations_update_column: DemiplaneMigrationsUpdateColumn;
  demiplane_migrations_var_pop_fields: ResolverTypeWrapper<DemiplaneMigrationsVarPopFields>;
  demiplane_migrations_var_pop_order_by: DemiplaneMigrationsVarPopOrderBy;
  demiplane_migrations_var_samp_fields: ResolverTypeWrapper<DemiplaneMigrationsVarSampFields>;
  demiplane_migrations_var_samp_order_by: DemiplaneMigrationsVarSampOrderBy;
  demiplane_migrations_variance_fields: ResolverTypeWrapper<DemiplaneMigrationsVarianceFields>;
  demiplane_migrations_variance_order_by: DemiplaneMigrationsVarianceOrderBy;
  demiplane_notification_history: ResolverTypeWrapper<DemiplaneNotificationHistory>;
  demiplane_notification_history_aggregate: ResolverTypeWrapper<DemiplaneNotificationHistoryAggregate>;
  demiplane_notification_history_aggregate_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryAggregateFields>;
  demiplane_notification_history_aggregate_order_by: DemiplaneNotificationHistoryAggregateOrderBy;
  demiplane_notification_history_arr_rel_insert_input: DemiplaneNotificationHistoryArrRelInsertInput;
  demiplane_notification_history_avg_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryAvgFields>;
  demiplane_notification_history_avg_order_by: DemiplaneNotificationHistoryAvgOrderBy;
  demiplane_notification_history_bool_exp: DemiplaneNotificationHistoryBoolExp;
  demiplane_notification_history_constraint: DemiplaneNotificationHistoryConstraint;
  demiplane_notification_history_inc_input: DemiplaneNotificationHistoryIncInput;
  demiplane_notification_history_insert_input: DemiplaneNotificationHistoryInsertInput;
  demiplane_notification_history_max_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryMaxFields>;
  demiplane_notification_history_max_order_by: DemiplaneNotificationHistoryMaxOrderBy;
  demiplane_notification_history_min_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryMinFields>;
  demiplane_notification_history_min_order_by: DemiplaneNotificationHistoryMinOrderBy;
  demiplane_notification_history_mutation_response: ResolverTypeWrapper<DemiplaneNotificationHistoryMutationResponse>;
  demiplane_notification_history_obj_rel_insert_input: DemiplaneNotificationHistoryObjRelInsertInput;
  demiplane_notification_history_on_conflict: DemiplaneNotificationHistoryOnConflict;
  demiplane_notification_history_order_by: DemiplaneNotificationHistoryOrderBy;
  demiplane_notification_history_pk_columns_input: DemiplaneNotificationHistoryPkColumnsInput;
  demiplane_notification_history_select_column: DemiplaneNotificationHistorySelectColumn;
  demiplane_notification_history_set_input: DemiplaneNotificationHistorySetInput;
  demiplane_notification_history_stddev_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryStddevFields>;
  demiplane_notification_history_stddev_order_by: DemiplaneNotificationHistoryStddevOrderBy;
  demiplane_notification_history_stddev_pop_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryStddevPopFields>;
  demiplane_notification_history_stddev_pop_order_by: DemiplaneNotificationHistoryStddevPopOrderBy;
  demiplane_notification_history_stddev_samp_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryStddevSampFields>;
  demiplane_notification_history_stddev_samp_order_by: DemiplaneNotificationHistoryStddevSampOrderBy;
  demiplane_notification_history_sum_fields: ResolverTypeWrapper<DemiplaneNotificationHistorySumFields>;
  demiplane_notification_history_sum_order_by: DemiplaneNotificationHistorySumOrderBy;
  demiplane_notification_history_update_column: DemiplaneNotificationHistoryUpdateColumn;
  demiplane_notification_history_var_pop_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryVarPopFields>;
  demiplane_notification_history_var_pop_order_by: DemiplaneNotificationHistoryVarPopOrderBy;
  demiplane_notification_history_var_samp_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryVarSampFields>;
  demiplane_notification_history_var_samp_order_by: DemiplaneNotificationHistoryVarSampOrderBy;
  demiplane_notification_history_variance_fields: ResolverTypeWrapper<DemiplaneNotificationHistoryVarianceFields>;
  demiplane_notification_history_variance_order_by: DemiplaneNotificationHistoryVarianceOrderBy;
  demiplane_payment: ResolverTypeWrapper<DemiplanePayment>;
  demiplane_payment_aggregate: ResolverTypeWrapper<DemiplanePaymentAggregate>;
  demiplane_payment_aggregate_fields: ResolverTypeWrapper<DemiplanePaymentAggregateFields>;
  demiplane_payment_aggregate_order_by: DemiplanePaymentAggregateOrderBy;
  demiplane_payment_arr_rel_insert_input: DemiplanePaymentArrRelInsertInput;
  demiplane_payment_avg_fields: ResolverTypeWrapper<DemiplanePaymentAvgFields>;
  demiplane_payment_avg_order_by: DemiplanePaymentAvgOrderBy;
  demiplane_payment_bool_exp: DemiplanePaymentBoolExp;
  demiplane_payment_constraint: DemiplanePaymentConstraint;
  demiplane_payment_inc_input: DemiplanePaymentIncInput;
  demiplane_payment_insert_input: DemiplanePaymentInsertInput;
  demiplane_payment_max_fields: ResolverTypeWrapper<DemiplanePaymentMaxFields>;
  demiplane_payment_max_order_by: DemiplanePaymentMaxOrderBy;
  demiplane_payment_min_fields: ResolverTypeWrapper<DemiplanePaymentMinFields>;
  demiplane_payment_min_order_by: DemiplanePaymentMinOrderBy;
  demiplane_payment_mutation_response: ResolverTypeWrapper<DemiplanePaymentMutationResponse>;
  demiplane_payment_obj_rel_insert_input: DemiplanePaymentObjRelInsertInput;
  demiplane_payment_on_conflict: DemiplanePaymentOnConflict;
  demiplane_payment_order_by: DemiplanePaymentOrderBy;
  demiplane_payment_pk_columns_input: DemiplanePaymentPkColumnsInput;
  demiplane_payment_select_column: DemiplanePaymentSelectColumn;
  demiplane_payment_set_input: DemiplanePaymentSetInput;
  demiplane_payment_stddev_fields: ResolverTypeWrapper<DemiplanePaymentStddevFields>;
  demiplane_payment_stddev_order_by: DemiplanePaymentStddevOrderBy;
  demiplane_payment_stddev_pop_fields: ResolverTypeWrapper<DemiplanePaymentStddevPopFields>;
  demiplane_payment_stddev_pop_order_by: DemiplanePaymentStddevPopOrderBy;
  demiplane_payment_stddev_samp_fields: ResolverTypeWrapper<DemiplanePaymentStddevSampFields>;
  demiplane_payment_stddev_samp_order_by: DemiplanePaymentStddevSampOrderBy;
  demiplane_payment_sum_fields: ResolverTypeWrapper<DemiplanePaymentSumFields>;
  demiplane_payment_sum_order_by: DemiplanePaymentSumOrderBy;
  demiplane_payment_update_column: DemiplanePaymentUpdateColumn;
  demiplane_payment_var_pop_fields: ResolverTypeWrapper<DemiplanePaymentVarPopFields>;
  demiplane_payment_var_pop_order_by: DemiplanePaymentVarPopOrderBy;
  demiplane_payment_var_samp_fields: ResolverTypeWrapper<DemiplanePaymentVarSampFields>;
  demiplane_payment_var_samp_order_by: DemiplanePaymentVarSampOrderBy;
  demiplane_payment_variance_fields: ResolverTypeWrapper<DemiplanePaymentVarianceFields>;
  demiplane_payment_variance_order_by: DemiplanePaymentVarianceOrderBy;
  demiplane_player_award: ResolverTypeWrapper<DemiplanePlayerAward>;
  demiplane_player_award_aggregate: ResolverTypeWrapper<DemiplanePlayerAwardAggregate>;
  demiplane_player_award_aggregate_fields: ResolverTypeWrapper<DemiplanePlayerAwardAggregateFields>;
  demiplane_player_award_aggregate_order_by: DemiplanePlayerAwardAggregateOrderBy;
  demiplane_player_award_arr_rel_insert_input: DemiplanePlayerAwardArrRelInsertInput;
  demiplane_player_award_avg_fields: ResolverTypeWrapper<DemiplanePlayerAwardAvgFields>;
  demiplane_player_award_avg_order_by: DemiplanePlayerAwardAvgOrderBy;
  demiplane_player_award_bool_exp: DemiplanePlayerAwardBoolExp;
  demiplane_player_award_constraint: DemiplanePlayerAwardConstraint;
  demiplane_player_award_inc_input: DemiplanePlayerAwardIncInput;
  demiplane_player_award_insert_input: DemiplanePlayerAwardInsertInput;
  demiplane_player_award_max_fields: ResolverTypeWrapper<DemiplanePlayerAwardMaxFields>;
  demiplane_player_award_max_order_by: DemiplanePlayerAwardMaxOrderBy;
  demiplane_player_award_min_fields: ResolverTypeWrapper<DemiplanePlayerAwardMinFields>;
  demiplane_player_award_min_order_by: DemiplanePlayerAwardMinOrderBy;
  demiplane_player_award_mutation_response: ResolverTypeWrapper<DemiplanePlayerAwardMutationResponse>;
  demiplane_player_award_obj_rel_insert_input: DemiplanePlayerAwardObjRelInsertInput;
  demiplane_player_award_on_conflict: DemiplanePlayerAwardOnConflict;
  demiplane_player_award_order_by: DemiplanePlayerAwardOrderBy;
  demiplane_player_award_pk_columns_input: DemiplanePlayerAwardPkColumnsInput;
  demiplane_player_award_select_column: DemiplanePlayerAwardSelectColumn;
  demiplane_player_award_set_input: DemiplanePlayerAwardSetInput;
  demiplane_player_award_stddev_fields: ResolverTypeWrapper<DemiplanePlayerAwardStddevFields>;
  demiplane_player_award_stddev_order_by: DemiplanePlayerAwardStddevOrderBy;
  demiplane_player_award_stddev_pop_fields: ResolverTypeWrapper<DemiplanePlayerAwardStddevPopFields>;
  demiplane_player_award_stddev_pop_order_by: DemiplanePlayerAwardStddevPopOrderBy;
  demiplane_player_award_stddev_samp_fields: ResolverTypeWrapper<DemiplanePlayerAwardStddevSampFields>;
  demiplane_player_award_stddev_samp_order_by: DemiplanePlayerAwardStddevSampOrderBy;
  demiplane_player_award_sum_fields: ResolverTypeWrapper<DemiplanePlayerAwardSumFields>;
  demiplane_player_award_sum_order_by: DemiplanePlayerAwardSumOrderBy;
  demiplane_player_award_update_column: DemiplanePlayerAwardUpdateColumn;
  demiplane_player_award_var_pop_fields: ResolverTypeWrapper<DemiplanePlayerAwardVarPopFields>;
  demiplane_player_award_var_pop_order_by: DemiplanePlayerAwardVarPopOrderBy;
  demiplane_player_award_var_samp_fields: ResolverTypeWrapper<DemiplanePlayerAwardVarSampFields>;
  demiplane_player_award_var_samp_order_by: DemiplanePlayerAwardVarSampOrderBy;
  demiplane_player_award_variance_fields: ResolverTypeWrapper<DemiplanePlayerAwardVarianceFields>;
  demiplane_player_award_variance_order_by: DemiplanePlayerAwardVarianceOrderBy;
  demiplane_player_rating: ResolverTypeWrapper<DemiplanePlayerRating>;
  demiplane_player_rating_aggregate: ResolverTypeWrapper<DemiplanePlayerRatingAggregate>;
  demiplane_player_rating_aggregate_fields: ResolverTypeWrapper<DemiplanePlayerRatingAggregateFields>;
  demiplane_player_rating_aggregate_order_by: DemiplanePlayerRatingAggregateOrderBy;
  demiplane_player_rating_arr_rel_insert_input: DemiplanePlayerRatingArrRelInsertInput;
  demiplane_player_rating_avg_fields: ResolverTypeWrapper<DemiplanePlayerRatingAvgFields>;
  demiplane_player_rating_avg_order_by: DemiplanePlayerRatingAvgOrderBy;
  demiplane_player_rating_bool_exp: DemiplanePlayerRatingBoolExp;
  demiplane_player_rating_constraint: DemiplanePlayerRatingConstraint;
  demiplane_player_rating_inc_input: DemiplanePlayerRatingIncInput;
  demiplane_player_rating_insert_input: DemiplanePlayerRatingInsertInput;
  demiplane_player_rating_max_fields: ResolverTypeWrapper<DemiplanePlayerRatingMaxFields>;
  demiplane_player_rating_max_order_by: DemiplanePlayerRatingMaxOrderBy;
  demiplane_player_rating_min_fields: ResolverTypeWrapper<DemiplanePlayerRatingMinFields>;
  demiplane_player_rating_min_order_by: DemiplanePlayerRatingMinOrderBy;
  demiplane_player_rating_mutation_response: ResolverTypeWrapper<DemiplanePlayerRatingMutationResponse>;
  demiplane_player_rating_obj_rel_insert_input: DemiplanePlayerRatingObjRelInsertInput;
  demiplane_player_rating_on_conflict: DemiplanePlayerRatingOnConflict;
  demiplane_player_rating_order_by: DemiplanePlayerRatingOrderBy;
  demiplane_player_rating_pk_columns_input: DemiplanePlayerRatingPkColumnsInput;
  demiplane_player_rating_select_column: DemiplanePlayerRatingSelectColumn;
  demiplane_player_rating_set_input: DemiplanePlayerRatingSetInput;
  demiplane_player_rating_stddev_fields: ResolverTypeWrapper<DemiplanePlayerRatingStddevFields>;
  demiplane_player_rating_stddev_order_by: DemiplanePlayerRatingStddevOrderBy;
  demiplane_player_rating_stddev_pop_fields: ResolverTypeWrapper<DemiplanePlayerRatingStddevPopFields>;
  demiplane_player_rating_stddev_pop_order_by: DemiplanePlayerRatingStddevPopOrderBy;
  demiplane_player_rating_stddev_samp_fields: ResolverTypeWrapper<DemiplanePlayerRatingStddevSampFields>;
  demiplane_player_rating_stddev_samp_order_by: DemiplanePlayerRatingStddevSampOrderBy;
  demiplane_player_rating_sum_fields: ResolverTypeWrapper<DemiplanePlayerRatingSumFields>;
  demiplane_player_rating_sum_order_by: DemiplanePlayerRatingSumOrderBy;
  demiplane_player_rating_update_column: DemiplanePlayerRatingUpdateColumn;
  demiplane_player_rating_var_pop_fields: ResolverTypeWrapper<DemiplanePlayerRatingVarPopFields>;
  demiplane_player_rating_var_pop_order_by: DemiplanePlayerRatingVarPopOrderBy;
  demiplane_player_rating_var_samp_fields: ResolverTypeWrapper<DemiplanePlayerRatingVarSampFields>;
  demiplane_player_rating_var_samp_order_by: DemiplanePlayerRatingVarSampOrderBy;
  demiplane_player_rating_variance_fields: ResolverTypeWrapper<DemiplanePlayerRatingVarianceFields>;
  demiplane_player_rating_variance_order_by: DemiplanePlayerRatingVarianceOrderBy;
  demiplane_pronoun: ResolverTypeWrapper<DemiplanePronoun>;
  demiplane_pronoun_aggregate: ResolverTypeWrapper<DemiplanePronounAggregate>;
  demiplane_pronoun_aggregate_fields: ResolverTypeWrapper<DemiplanePronounAggregateFields>;
  demiplane_pronoun_aggregate_order_by: DemiplanePronounAggregateOrderBy;
  demiplane_pronoun_arr_rel_insert_input: DemiplanePronounArrRelInsertInput;
  demiplane_pronoun_avg_fields: ResolverTypeWrapper<DemiplanePronounAvgFields>;
  demiplane_pronoun_avg_order_by: DemiplanePronounAvgOrderBy;
  demiplane_pronoun_bool_exp: DemiplanePronounBoolExp;
  demiplane_pronoun_constraint: DemiplanePronounConstraint;
  demiplane_pronoun_inc_input: DemiplanePronounIncInput;
  demiplane_pronoun_insert_input: DemiplanePronounInsertInput;
  demiplane_pronoun_max_fields: ResolverTypeWrapper<DemiplanePronounMaxFields>;
  demiplane_pronoun_max_order_by: DemiplanePronounMaxOrderBy;
  demiplane_pronoun_min_fields: ResolverTypeWrapper<DemiplanePronounMinFields>;
  demiplane_pronoun_min_order_by: DemiplanePronounMinOrderBy;
  demiplane_pronoun_mutation_response: ResolverTypeWrapper<DemiplanePronounMutationResponse>;
  demiplane_pronoun_obj_rel_insert_input: DemiplanePronounObjRelInsertInput;
  demiplane_pronoun_on_conflict: DemiplanePronounOnConflict;
  demiplane_pronoun_order_by: DemiplanePronounOrderBy;
  demiplane_pronoun_pk_columns_input: DemiplanePronounPkColumnsInput;
  demiplane_pronoun_select_column: DemiplanePronounSelectColumn;
  demiplane_pronoun_set_input: DemiplanePronounSetInput;
  demiplane_pronoun_stddev_fields: ResolverTypeWrapper<DemiplanePronounStddevFields>;
  demiplane_pronoun_stddev_order_by: DemiplanePronounStddevOrderBy;
  demiplane_pronoun_stddev_pop_fields: ResolverTypeWrapper<DemiplanePronounStddevPopFields>;
  demiplane_pronoun_stddev_pop_order_by: DemiplanePronounStddevPopOrderBy;
  demiplane_pronoun_stddev_samp_fields: ResolverTypeWrapper<DemiplanePronounStddevSampFields>;
  demiplane_pronoun_stddev_samp_order_by: DemiplanePronounStddevSampOrderBy;
  demiplane_pronoun_sum_fields: ResolverTypeWrapper<DemiplanePronounSumFields>;
  demiplane_pronoun_sum_order_by: DemiplanePronounSumOrderBy;
  demiplane_pronoun_update_column: DemiplanePronounUpdateColumn;
  demiplane_pronoun_var_pop_fields: ResolverTypeWrapper<DemiplanePronounVarPopFields>;
  demiplane_pronoun_var_pop_order_by: DemiplanePronounVarPopOrderBy;
  demiplane_pronoun_var_samp_fields: ResolverTypeWrapper<DemiplanePronounVarSampFields>;
  demiplane_pronoun_var_samp_order_by: DemiplanePronounVarSampOrderBy;
  demiplane_pronoun_variance_fields: ResolverTypeWrapper<DemiplanePronounVarianceFields>;
  demiplane_pronoun_variance_order_by: DemiplanePronounVarianceOrderBy;
  demiplane_region: ResolverTypeWrapper<DemiplaneRegion>;
  demiplane_region_aggregate: ResolverTypeWrapper<DemiplaneRegionAggregate>;
  demiplane_region_aggregate_fields: ResolverTypeWrapper<DemiplaneRegionAggregateFields>;
  demiplane_region_aggregate_order_by: DemiplaneRegionAggregateOrderBy;
  demiplane_region_arr_rel_insert_input: DemiplaneRegionArrRelInsertInput;
  demiplane_region_avg_fields: ResolverTypeWrapper<DemiplaneRegionAvgFields>;
  demiplane_region_avg_order_by: DemiplaneRegionAvgOrderBy;
  demiplane_region_bool_exp: DemiplaneRegionBoolExp;
  demiplane_region_constraint: DemiplaneRegionConstraint;
  demiplane_region_inc_input: DemiplaneRegionIncInput;
  demiplane_region_insert_input: DemiplaneRegionInsertInput;
  demiplane_region_max_fields: ResolverTypeWrapper<DemiplaneRegionMaxFields>;
  demiplane_region_max_order_by: DemiplaneRegionMaxOrderBy;
  demiplane_region_min_fields: ResolverTypeWrapper<DemiplaneRegionMinFields>;
  demiplane_region_min_order_by: DemiplaneRegionMinOrderBy;
  demiplane_region_mutation_response: ResolverTypeWrapper<DemiplaneRegionMutationResponse>;
  demiplane_region_obj_rel_insert_input: DemiplaneRegionObjRelInsertInput;
  demiplane_region_on_conflict: DemiplaneRegionOnConflict;
  demiplane_region_order_by: DemiplaneRegionOrderBy;
  demiplane_region_pk_columns_input: DemiplaneRegionPkColumnsInput;
  demiplane_region_select_column: DemiplaneRegionSelectColumn;
  demiplane_region_set_input: DemiplaneRegionSetInput;
  demiplane_region_stddev_fields: ResolverTypeWrapper<DemiplaneRegionStddevFields>;
  demiplane_region_stddev_order_by: DemiplaneRegionStddevOrderBy;
  demiplane_region_stddev_pop_fields: ResolverTypeWrapper<DemiplaneRegionStddevPopFields>;
  demiplane_region_stddev_pop_order_by: DemiplaneRegionStddevPopOrderBy;
  demiplane_region_stddev_samp_fields: ResolverTypeWrapper<DemiplaneRegionStddevSampFields>;
  demiplane_region_stddev_samp_order_by: DemiplaneRegionStddevSampOrderBy;
  demiplane_region_sum_fields: ResolverTypeWrapper<DemiplaneRegionSumFields>;
  demiplane_region_sum_order_by: DemiplaneRegionSumOrderBy;
  demiplane_region_update_column: DemiplaneRegionUpdateColumn;
  demiplane_region_var_pop_fields: ResolverTypeWrapper<DemiplaneRegionVarPopFields>;
  demiplane_region_var_pop_order_by: DemiplaneRegionVarPopOrderBy;
  demiplane_region_var_samp_fields: ResolverTypeWrapper<DemiplaneRegionVarSampFields>;
  demiplane_region_var_samp_order_by: DemiplaneRegionVarSampOrderBy;
  demiplane_region_variance_fields: ResolverTypeWrapper<DemiplaneRegionVarianceFields>;
  demiplane_region_variance_order_by: DemiplaneRegionVarianceOrderBy;
  demiplane_role: ResolverTypeWrapper<DemiplaneRole>;
  demiplane_role_aggregate: ResolverTypeWrapper<DemiplaneRoleAggregate>;
  demiplane_role_aggregate_fields: ResolverTypeWrapper<DemiplaneRoleAggregateFields>;
  demiplane_role_aggregate_order_by: DemiplaneRoleAggregateOrderBy;
  demiplane_role_arr_rel_insert_input: DemiplaneRoleArrRelInsertInput;
  demiplane_role_avg_fields: ResolverTypeWrapper<DemiplaneRoleAvgFields>;
  demiplane_role_avg_order_by: DemiplaneRoleAvgOrderBy;
  demiplane_role_bool_exp: DemiplaneRoleBoolExp;
  demiplane_role_constraint: DemiplaneRoleConstraint;
  demiplane_role_inc_input: DemiplaneRoleIncInput;
  demiplane_role_insert_input: DemiplaneRoleInsertInput;
  demiplane_role_max_fields: ResolverTypeWrapper<DemiplaneRoleMaxFields>;
  demiplane_role_max_order_by: DemiplaneRoleMaxOrderBy;
  demiplane_role_min_fields: ResolverTypeWrapper<DemiplaneRoleMinFields>;
  demiplane_role_min_order_by: DemiplaneRoleMinOrderBy;
  demiplane_role_mutation_response: ResolverTypeWrapper<DemiplaneRoleMutationResponse>;
  demiplane_role_obj_rel_insert_input: DemiplaneRoleObjRelInsertInput;
  demiplane_role_on_conflict: DemiplaneRoleOnConflict;
  demiplane_role_order_by: DemiplaneRoleOrderBy;
  demiplane_role_pk_columns_input: DemiplaneRolePkColumnsInput;
  demiplane_role_select_column: DemiplaneRoleSelectColumn;
  demiplane_role_set_input: DemiplaneRoleSetInput;
  demiplane_role_stddev_fields: ResolverTypeWrapper<DemiplaneRoleStddevFields>;
  demiplane_role_stddev_order_by: DemiplaneRoleStddevOrderBy;
  demiplane_role_stddev_pop_fields: ResolverTypeWrapper<DemiplaneRoleStddevPopFields>;
  demiplane_role_stddev_pop_order_by: DemiplaneRoleStddevPopOrderBy;
  demiplane_role_stddev_samp_fields: ResolverTypeWrapper<DemiplaneRoleStddevSampFields>;
  demiplane_role_stddev_samp_order_by: DemiplaneRoleStddevSampOrderBy;
  demiplane_role_sum_fields: ResolverTypeWrapper<DemiplaneRoleSumFields>;
  demiplane_role_sum_order_by: DemiplaneRoleSumOrderBy;
  demiplane_role_update_column: DemiplaneRoleUpdateColumn;
  demiplane_role_var_pop_fields: ResolverTypeWrapper<DemiplaneRoleVarPopFields>;
  demiplane_role_var_pop_order_by: DemiplaneRoleVarPopOrderBy;
  demiplane_role_var_samp_fields: ResolverTypeWrapper<DemiplaneRoleVarSampFields>;
  demiplane_role_var_samp_order_by: DemiplaneRoleVarSampOrderBy;
  demiplane_role_variance_fields: ResolverTypeWrapper<DemiplaneRoleVarianceFields>;
  demiplane_role_variance_order_by: DemiplaneRoleVarianceOrderBy;
  demiplane_session: ResolverTypeWrapper<DemiplaneSession>;
  demiplane_session_aggregate: ResolverTypeWrapper<DemiplaneSessionAggregate>;
  demiplane_session_aggregate_fields: ResolverTypeWrapper<DemiplaneSessionAggregateFields>;
  demiplane_session_aggregate_order_by: DemiplaneSessionAggregateOrderBy;
  demiplane_session_arr_rel_insert_input: DemiplaneSessionArrRelInsertInput;
  demiplane_session_avg_fields: ResolverTypeWrapper<DemiplaneSessionAvgFields>;
  demiplane_session_avg_order_by: DemiplaneSessionAvgOrderBy;
  demiplane_session_bool_exp: DemiplaneSessionBoolExp;
  demiplane_session_constraint: DemiplaneSessionConstraint;
  demiplane_session_inc_input: DemiplaneSessionIncInput;
  demiplane_session_insert_input: DemiplaneSessionInsertInput;
  demiplane_session_max_fields: ResolverTypeWrapper<DemiplaneSessionMaxFields>;
  demiplane_session_max_order_by: DemiplaneSessionMaxOrderBy;
  demiplane_session_min_fields: ResolverTypeWrapper<DemiplaneSessionMinFields>;
  demiplane_session_min_order_by: DemiplaneSessionMinOrderBy;
  demiplane_session_mutation_response: ResolverTypeWrapper<DemiplaneSessionMutationResponse>;
  demiplane_session_obj_rel_insert_input: DemiplaneSessionObjRelInsertInput;
  demiplane_session_on_conflict: DemiplaneSessionOnConflict;
  demiplane_session_order_by: DemiplaneSessionOrderBy;
  demiplane_session_pk_columns_input: DemiplaneSessionPkColumnsInput;
  demiplane_session_player: ResolverTypeWrapper<DemiplaneSessionPlayer>;
  demiplane_session_player_aggregate: ResolverTypeWrapper<DemiplaneSessionPlayerAggregate>;
  demiplane_session_player_aggregate_fields: ResolverTypeWrapper<DemiplaneSessionPlayerAggregateFields>;
  demiplane_session_player_aggregate_order_by: DemiplaneSessionPlayerAggregateOrderBy;
  demiplane_session_player_arr_rel_insert_input: DemiplaneSessionPlayerArrRelInsertInput;
  demiplane_session_player_avg_fields: ResolverTypeWrapper<DemiplaneSessionPlayerAvgFields>;
  demiplane_session_player_avg_order_by: DemiplaneSessionPlayerAvgOrderBy;
  demiplane_session_player_bool_exp: DemiplaneSessionPlayerBoolExp;
  demiplane_session_player_constraint: DemiplaneSessionPlayerConstraint;
  demiplane_session_player_inc_input: DemiplaneSessionPlayerIncInput;
  demiplane_session_player_insert_input: DemiplaneSessionPlayerInsertInput;
  demiplane_session_player_max_fields: ResolverTypeWrapper<DemiplaneSessionPlayerMaxFields>;
  demiplane_session_player_max_order_by: DemiplaneSessionPlayerMaxOrderBy;
  demiplane_session_player_min_fields: ResolverTypeWrapper<DemiplaneSessionPlayerMinFields>;
  demiplane_session_player_min_order_by: DemiplaneSessionPlayerMinOrderBy;
  demiplane_session_player_mutation_response: ResolverTypeWrapper<DemiplaneSessionPlayerMutationResponse>;
  demiplane_session_player_obj_rel_insert_input: DemiplaneSessionPlayerObjRelInsertInput;
  demiplane_session_player_on_conflict: DemiplaneSessionPlayerOnConflict;
  demiplane_session_player_order_by: DemiplaneSessionPlayerOrderBy;
  demiplane_session_player_pk_columns_input: DemiplaneSessionPlayerPkColumnsInput;
  demiplane_session_player_select_column: DemiplaneSessionPlayerSelectColumn;
  demiplane_session_player_set_input: DemiplaneSessionPlayerSetInput;
  demiplane_session_player_stddev_fields: ResolverTypeWrapper<DemiplaneSessionPlayerStddevFields>;
  demiplane_session_player_stddev_order_by: DemiplaneSessionPlayerStddevOrderBy;
  demiplane_session_player_stddev_pop_fields: ResolverTypeWrapper<DemiplaneSessionPlayerStddevPopFields>;
  demiplane_session_player_stddev_pop_order_by: DemiplaneSessionPlayerStddevPopOrderBy;
  demiplane_session_player_stddev_samp_fields: ResolverTypeWrapper<DemiplaneSessionPlayerStddevSampFields>;
  demiplane_session_player_stddev_samp_order_by: DemiplaneSessionPlayerStddevSampOrderBy;
  demiplane_session_player_sum_fields: ResolverTypeWrapper<DemiplaneSessionPlayerSumFields>;
  demiplane_session_player_sum_order_by: DemiplaneSessionPlayerSumOrderBy;
  demiplane_session_player_update_column: DemiplaneSessionPlayerUpdateColumn;
  demiplane_session_player_var_pop_fields: ResolverTypeWrapper<DemiplaneSessionPlayerVarPopFields>;
  demiplane_session_player_var_pop_order_by: DemiplaneSessionPlayerVarPopOrderBy;
  demiplane_session_player_var_samp_fields: ResolverTypeWrapper<DemiplaneSessionPlayerVarSampFields>;
  demiplane_session_player_var_samp_order_by: DemiplaneSessionPlayerVarSampOrderBy;
  demiplane_session_player_variance_fields: ResolverTypeWrapper<DemiplaneSessionPlayerVarianceFields>;
  demiplane_session_player_variance_order_by: DemiplaneSessionPlayerVarianceOrderBy;
  demiplane_session_select_column: DemiplaneSessionSelectColumn;
  demiplane_session_set_input: DemiplaneSessionSetInput;
  demiplane_session_stddev_fields: ResolverTypeWrapper<DemiplaneSessionStddevFields>;
  demiplane_session_stddev_order_by: DemiplaneSessionStddevOrderBy;
  demiplane_session_stddev_pop_fields: ResolverTypeWrapper<DemiplaneSessionStddevPopFields>;
  demiplane_session_stddev_pop_order_by: DemiplaneSessionStddevPopOrderBy;
  demiplane_session_stddev_samp_fields: ResolverTypeWrapper<DemiplaneSessionStddevSampFields>;
  demiplane_session_stddev_samp_order_by: DemiplaneSessionStddevSampOrderBy;
  demiplane_session_sum_fields: ResolverTypeWrapper<DemiplaneSessionSumFields>;
  demiplane_session_sum_order_by: DemiplaneSessionSumOrderBy;
  demiplane_session_update_column: DemiplaneSessionUpdateColumn;
  demiplane_session_var_pop_fields: ResolverTypeWrapper<DemiplaneSessionVarPopFields>;
  demiplane_session_var_pop_order_by: DemiplaneSessionVarPopOrderBy;
  demiplane_session_var_samp_fields: ResolverTypeWrapper<DemiplaneSessionVarSampFields>;
  demiplane_session_var_samp_order_by: DemiplaneSessionVarSampOrderBy;
  demiplane_session_variance_fields: ResolverTypeWrapper<DemiplaneSessionVarianceFields>;
  demiplane_session_variance_order_by: DemiplaneSessionVarianceOrderBy;
  demiplane_snippet: ResolverTypeWrapper<DemiplaneSnippet>;
  demiplane_snippet_aggregate: ResolverTypeWrapper<DemiplaneSnippetAggregate>;
  demiplane_snippet_aggregate_fields: ResolverTypeWrapper<DemiplaneSnippetAggregateFields>;
  demiplane_snippet_aggregate_order_by: DemiplaneSnippetAggregateOrderBy;
  demiplane_snippet_arr_rel_insert_input: DemiplaneSnippetArrRelInsertInput;
  demiplane_snippet_avg_fields: ResolverTypeWrapper<DemiplaneSnippetAvgFields>;
  demiplane_snippet_avg_order_by: DemiplaneSnippetAvgOrderBy;
  demiplane_snippet_bool_exp: DemiplaneSnippetBoolExp;
  demiplane_snippet_constraint: DemiplaneSnippetConstraint;
  demiplane_snippet_inc_input: DemiplaneSnippetIncInput;
  demiplane_snippet_insert_input: DemiplaneSnippetInsertInput;
  demiplane_snippet_max_fields: ResolverTypeWrapper<DemiplaneSnippetMaxFields>;
  demiplane_snippet_max_order_by: DemiplaneSnippetMaxOrderBy;
  demiplane_snippet_min_fields: ResolverTypeWrapper<DemiplaneSnippetMinFields>;
  demiplane_snippet_min_order_by: DemiplaneSnippetMinOrderBy;
  demiplane_snippet_mutation_response: ResolverTypeWrapper<DemiplaneSnippetMutationResponse>;
  demiplane_snippet_obj_rel_insert_input: DemiplaneSnippetObjRelInsertInput;
  demiplane_snippet_on_conflict: DemiplaneSnippetOnConflict;
  demiplane_snippet_order_by: DemiplaneSnippetOrderBy;
  demiplane_snippet_pk_columns_input: DemiplaneSnippetPkColumnsInput;
  demiplane_snippet_select_column: DemiplaneSnippetSelectColumn;
  demiplane_snippet_set_input: DemiplaneSnippetSetInput;
  demiplane_snippet_stddev_fields: ResolverTypeWrapper<DemiplaneSnippetStddevFields>;
  demiplane_snippet_stddev_order_by: DemiplaneSnippetStddevOrderBy;
  demiplane_snippet_stddev_pop_fields: ResolverTypeWrapper<DemiplaneSnippetStddevPopFields>;
  demiplane_snippet_stddev_pop_order_by: DemiplaneSnippetStddevPopOrderBy;
  demiplane_snippet_stddev_samp_fields: ResolverTypeWrapper<DemiplaneSnippetStddevSampFields>;
  demiplane_snippet_stddev_samp_order_by: DemiplaneSnippetStddevSampOrderBy;
  demiplane_snippet_sum_fields: ResolverTypeWrapper<DemiplaneSnippetSumFields>;
  demiplane_snippet_sum_order_by: DemiplaneSnippetSumOrderBy;
  demiplane_snippet_update_column: DemiplaneSnippetUpdateColumn;
  demiplane_snippet_var_pop_fields: ResolverTypeWrapper<DemiplaneSnippetVarPopFields>;
  demiplane_snippet_var_pop_order_by: DemiplaneSnippetVarPopOrderBy;
  demiplane_snippet_var_samp_fields: ResolverTypeWrapper<DemiplaneSnippetVarSampFields>;
  demiplane_snippet_var_samp_order_by: DemiplaneSnippetVarSampOrderBy;
  demiplane_snippet_variance_fields: ResolverTypeWrapper<DemiplaneSnippetVarianceFields>;
  demiplane_snippet_variance_order_by: DemiplaneSnippetVarianceOrderBy;
  demiplane_task: ResolverTypeWrapper<DemiplaneTask>;
  demiplane_task_aggregate: ResolverTypeWrapper<DemiplaneTaskAggregate>;
  demiplane_task_aggregate_fields: ResolverTypeWrapper<DemiplaneTaskAggregateFields>;
  demiplane_task_aggregate_order_by: DemiplaneTaskAggregateOrderBy;
  demiplane_task_arr_rel_insert_input: DemiplaneTaskArrRelInsertInput;
  demiplane_task_avg_fields: ResolverTypeWrapper<DemiplaneTaskAvgFields>;
  demiplane_task_avg_order_by: DemiplaneTaskAvgOrderBy;
  demiplane_task_bool_exp: DemiplaneTaskBoolExp;
  demiplane_task_constraint: DemiplaneTaskConstraint;
  demiplane_task_inc_input: DemiplaneTaskIncInput;
  demiplane_task_insert_input: DemiplaneTaskInsertInput;
  demiplane_task_max_fields: ResolverTypeWrapper<DemiplaneTaskMaxFields>;
  demiplane_task_max_order_by: DemiplaneTaskMaxOrderBy;
  demiplane_task_min_fields: ResolverTypeWrapper<DemiplaneTaskMinFields>;
  demiplane_task_min_order_by: DemiplaneTaskMinOrderBy;
  demiplane_task_mutation_response: ResolverTypeWrapper<DemiplaneTaskMutationResponse>;
  demiplane_task_obj_rel_insert_input: DemiplaneTaskObjRelInsertInput;
  demiplane_task_on_conflict: DemiplaneTaskOnConflict;
  demiplane_task_order_by: DemiplaneTaskOrderBy;
  demiplane_task_pk_columns_input: DemiplaneTaskPkColumnsInput;
  demiplane_task_read_status: ResolverTypeWrapper<DemiplaneTaskReadStatus>;
  demiplane_task_read_status_aggregate: ResolverTypeWrapper<DemiplaneTaskReadStatusAggregate>;
  demiplane_task_read_status_aggregate_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusAggregateFields>;
  demiplane_task_read_status_aggregate_order_by: DemiplaneTaskReadStatusAggregateOrderBy;
  demiplane_task_read_status_arr_rel_insert_input: DemiplaneTaskReadStatusArrRelInsertInput;
  demiplane_task_read_status_avg_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusAvgFields>;
  demiplane_task_read_status_avg_order_by: DemiplaneTaskReadStatusAvgOrderBy;
  demiplane_task_read_status_bool_exp: DemiplaneTaskReadStatusBoolExp;
  demiplane_task_read_status_constraint: DemiplaneTaskReadStatusConstraint;
  demiplane_task_read_status_inc_input: DemiplaneTaskReadStatusIncInput;
  demiplane_task_read_status_insert_input: DemiplaneTaskReadStatusInsertInput;
  demiplane_task_read_status_max_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusMaxFields>;
  demiplane_task_read_status_max_order_by: DemiplaneTaskReadStatusMaxOrderBy;
  demiplane_task_read_status_min_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusMinFields>;
  demiplane_task_read_status_min_order_by: DemiplaneTaskReadStatusMinOrderBy;
  demiplane_task_read_status_mutation_response: ResolverTypeWrapper<DemiplaneTaskReadStatusMutationResponse>;
  demiplane_task_read_status_obj_rel_insert_input: DemiplaneTaskReadStatusObjRelInsertInput;
  demiplane_task_read_status_on_conflict: DemiplaneTaskReadStatusOnConflict;
  demiplane_task_read_status_order_by: DemiplaneTaskReadStatusOrderBy;
  demiplane_task_read_status_pk_columns_input: DemiplaneTaskReadStatusPkColumnsInput;
  demiplane_task_read_status_select_column: DemiplaneTaskReadStatusSelectColumn;
  demiplane_task_read_status_set_input: DemiplaneTaskReadStatusSetInput;
  demiplane_task_read_status_stddev_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusStddevFields>;
  demiplane_task_read_status_stddev_order_by: DemiplaneTaskReadStatusStddevOrderBy;
  demiplane_task_read_status_stddev_pop_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusStddevPopFields>;
  demiplane_task_read_status_stddev_pop_order_by: DemiplaneTaskReadStatusStddevPopOrderBy;
  demiplane_task_read_status_stddev_samp_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusStddevSampFields>;
  demiplane_task_read_status_stddev_samp_order_by: DemiplaneTaskReadStatusStddevSampOrderBy;
  demiplane_task_read_status_sum_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusSumFields>;
  demiplane_task_read_status_sum_order_by: DemiplaneTaskReadStatusSumOrderBy;
  demiplane_task_read_status_update_column: DemiplaneTaskReadStatusUpdateColumn;
  demiplane_task_read_status_var_pop_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusVarPopFields>;
  demiplane_task_read_status_var_pop_order_by: DemiplaneTaskReadStatusVarPopOrderBy;
  demiplane_task_read_status_var_samp_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusVarSampFields>;
  demiplane_task_read_status_var_samp_order_by: DemiplaneTaskReadStatusVarSampOrderBy;
  demiplane_task_read_status_variance_fields: ResolverTypeWrapper<DemiplaneTaskReadStatusVarianceFields>;
  demiplane_task_read_status_variance_order_by: DemiplaneTaskReadStatusVarianceOrderBy;
  demiplane_task_select_column: DemiplaneTaskSelectColumn;
  demiplane_task_set_input: DemiplaneTaskSetInput;
  demiplane_task_stddev_fields: ResolverTypeWrapper<DemiplaneTaskStddevFields>;
  demiplane_task_stddev_order_by: DemiplaneTaskStddevOrderBy;
  demiplane_task_stddev_pop_fields: ResolverTypeWrapper<DemiplaneTaskStddevPopFields>;
  demiplane_task_stddev_pop_order_by: DemiplaneTaskStddevPopOrderBy;
  demiplane_task_stddev_samp_fields: ResolverTypeWrapper<DemiplaneTaskStddevSampFields>;
  demiplane_task_stddev_samp_order_by: DemiplaneTaskStddevSampOrderBy;
  demiplane_task_sum_fields: ResolverTypeWrapper<DemiplaneTaskSumFields>;
  demiplane_task_sum_order_by: DemiplaneTaskSumOrderBy;
  demiplane_task_update_column: DemiplaneTaskUpdateColumn;
  demiplane_task_var_pop_fields: ResolverTypeWrapper<DemiplaneTaskVarPopFields>;
  demiplane_task_var_pop_order_by: DemiplaneTaskVarPopOrderBy;
  demiplane_task_var_samp_fields: ResolverTypeWrapper<DemiplaneTaskVarSampFields>;
  demiplane_task_var_samp_order_by: DemiplaneTaskVarSampOrderBy;
  demiplane_task_variance_fields: ResolverTypeWrapper<DemiplaneTaskVarianceFields>;
  demiplane_task_variance_order_by: DemiplaneTaskVarianceOrderBy;
  demiplane_tax_nexus_region: ResolverTypeWrapper<DemiplaneTaxNexusRegion>;
  demiplane_tax_nexus_region_aggregate: ResolverTypeWrapper<DemiplaneTaxNexusRegionAggregate>;
  demiplane_tax_nexus_region_aggregate_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionAggregateFields>;
  demiplane_tax_nexus_region_aggregate_order_by: DemiplaneTaxNexusRegionAggregateOrderBy;
  demiplane_tax_nexus_region_arr_rel_insert_input: DemiplaneTaxNexusRegionArrRelInsertInput;
  demiplane_tax_nexus_region_avg_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionAvgFields>;
  demiplane_tax_nexus_region_avg_order_by: DemiplaneTaxNexusRegionAvgOrderBy;
  demiplane_tax_nexus_region_bool_exp: DemiplaneTaxNexusRegionBoolExp;
  demiplane_tax_nexus_region_constraint: DemiplaneTaxNexusRegionConstraint;
  demiplane_tax_nexus_region_inc_input: DemiplaneTaxNexusRegionIncInput;
  demiplane_tax_nexus_region_insert_input: DemiplaneTaxNexusRegionInsertInput;
  demiplane_tax_nexus_region_max_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionMaxFields>;
  demiplane_tax_nexus_region_max_order_by: DemiplaneTaxNexusRegionMaxOrderBy;
  demiplane_tax_nexus_region_min_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionMinFields>;
  demiplane_tax_nexus_region_min_order_by: DemiplaneTaxNexusRegionMinOrderBy;
  demiplane_tax_nexus_region_mutation_response: ResolverTypeWrapper<DemiplaneTaxNexusRegionMutationResponse>;
  demiplane_tax_nexus_region_obj_rel_insert_input: DemiplaneTaxNexusRegionObjRelInsertInput;
  demiplane_tax_nexus_region_on_conflict: DemiplaneTaxNexusRegionOnConflict;
  demiplane_tax_nexus_region_order_by: DemiplaneTaxNexusRegionOrderBy;
  demiplane_tax_nexus_region_pk_columns_input: DemiplaneTaxNexusRegionPkColumnsInput;
  demiplane_tax_nexus_region_select_column: DemiplaneTaxNexusRegionSelectColumn;
  demiplane_tax_nexus_region_set_input: DemiplaneTaxNexusRegionSetInput;
  demiplane_tax_nexus_region_stddev_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionStddevFields>;
  demiplane_tax_nexus_region_stddev_order_by: DemiplaneTaxNexusRegionStddevOrderBy;
  demiplane_tax_nexus_region_stddev_pop_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionStddevPopFields>;
  demiplane_tax_nexus_region_stddev_pop_order_by: DemiplaneTaxNexusRegionStddevPopOrderBy;
  demiplane_tax_nexus_region_stddev_samp_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionStddevSampFields>;
  demiplane_tax_nexus_region_stddev_samp_order_by: DemiplaneTaxNexusRegionStddevSampOrderBy;
  demiplane_tax_nexus_region_sum_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionSumFields>;
  demiplane_tax_nexus_region_sum_order_by: DemiplaneTaxNexusRegionSumOrderBy;
  demiplane_tax_nexus_region_update_column: DemiplaneTaxNexusRegionUpdateColumn;
  demiplane_tax_nexus_region_var_pop_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionVarPopFields>;
  demiplane_tax_nexus_region_var_pop_order_by: DemiplaneTaxNexusRegionVarPopOrderBy;
  demiplane_tax_nexus_region_var_samp_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionVarSampFields>;
  demiplane_tax_nexus_region_var_samp_order_by: DemiplaneTaxNexusRegionVarSampOrderBy;
  demiplane_tax_nexus_region_variance_fields: ResolverTypeWrapper<DemiplaneTaxNexusRegionVarianceFields>;
  demiplane_tax_nexus_region_variance_order_by: DemiplaneTaxNexusRegionVarianceOrderBy;
  demiplane_transaction_receipt: ResolverTypeWrapper<DemiplaneTransactionReceipt>;
  demiplane_transaction_receipt_aggregate: ResolverTypeWrapper<DemiplaneTransactionReceiptAggregate>;
  demiplane_transaction_receipt_aggregate_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptAggregateFields>;
  demiplane_transaction_receipt_aggregate_order_by: DemiplaneTransactionReceiptAggregateOrderBy;
  demiplane_transaction_receipt_arr_rel_insert_input: DemiplaneTransactionReceiptArrRelInsertInput;
  demiplane_transaction_receipt_avg_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptAvgFields>;
  demiplane_transaction_receipt_avg_order_by: DemiplaneTransactionReceiptAvgOrderBy;
  demiplane_transaction_receipt_bool_exp: DemiplaneTransactionReceiptBoolExp;
  demiplane_transaction_receipt_constraint: DemiplaneTransactionReceiptConstraint;
  demiplane_transaction_receipt_inc_input: DemiplaneTransactionReceiptIncInput;
  demiplane_transaction_receipt_insert_input: DemiplaneTransactionReceiptInsertInput;
  demiplane_transaction_receipt_max_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptMaxFields>;
  demiplane_transaction_receipt_max_order_by: DemiplaneTransactionReceiptMaxOrderBy;
  demiplane_transaction_receipt_min_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptMinFields>;
  demiplane_transaction_receipt_min_order_by: DemiplaneTransactionReceiptMinOrderBy;
  demiplane_transaction_receipt_mutation_response: ResolverTypeWrapper<DemiplaneTransactionReceiptMutationResponse>;
  demiplane_transaction_receipt_obj_rel_insert_input: DemiplaneTransactionReceiptObjRelInsertInput;
  demiplane_transaction_receipt_on_conflict: DemiplaneTransactionReceiptOnConflict;
  demiplane_transaction_receipt_order_by: DemiplaneTransactionReceiptOrderBy;
  demiplane_transaction_receipt_pk_columns_input: DemiplaneTransactionReceiptPkColumnsInput;
  demiplane_transaction_receipt_select_column: DemiplaneTransactionReceiptSelectColumn;
  demiplane_transaction_receipt_set_input: DemiplaneTransactionReceiptSetInput;
  demiplane_transaction_receipt_stddev_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptStddevFields>;
  demiplane_transaction_receipt_stddev_order_by: DemiplaneTransactionReceiptStddevOrderBy;
  demiplane_transaction_receipt_stddev_pop_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptStddevPopFields>;
  demiplane_transaction_receipt_stddev_pop_order_by: DemiplaneTransactionReceiptStddevPopOrderBy;
  demiplane_transaction_receipt_stddev_samp_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptStddevSampFields>;
  demiplane_transaction_receipt_stddev_samp_order_by: DemiplaneTransactionReceiptStddevSampOrderBy;
  demiplane_transaction_receipt_sum_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptSumFields>;
  demiplane_transaction_receipt_sum_order_by: DemiplaneTransactionReceiptSumOrderBy;
  demiplane_transaction_receipt_update_column: DemiplaneTransactionReceiptUpdateColumn;
  demiplane_transaction_receipt_var_pop_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptVarPopFields>;
  demiplane_transaction_receipt_var_pop_order_by: DemiplaneTransactionReceiptVarPopOrderBy;
  demiplane_transaction_receipt_var_samp_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptVarSampFields>;
  demiplane_transaction_receipt_var_samp_order_by: DemiplaneTransactionReceiptVarSampOrderBy;
  demiplane_transaction_receipt_variance_fields: ResolverTypeWrapper<DemiplaneTransactionReceiptVarianceFields>;
  demiplane_transaction_receipt_variance_order_by: DemiplaneTransactionReceiptVarianceOrderBy;
  demiplane_upload: ResolverTypeWrapper<DemiplaneUpload>;
  demiplane_upload_aggregate: ResolverTypeWrapper<DemiplaneUploadAggregate>;
  demiplane_upload_aggregate_fields: ResolverTypeWrapper<DemiplaneUploadAggregateFields>;
  demiplane_upload_aggregate_order_by: DemiplaneUploadAggregateOrderBy;
  demiplane_upload_arr_rel_insert_input: DemiplaneUploadArrRelInsertInput;
  demiplane_upload_avg_fields: ResolverTypeWrapper<DemiplaneUploadAvgFields>;
  demiplane_upload_avg_order_by: DemiplaneUploadAvgOrderBy;
  demiplane_upload_bool_exp: DemiplaneUploadBoolExp;
  demiplane_upload_constraint: DemiplaneUploadConstraint;
  demiplane_upload_inc_input: DemiplaneUploadIncInput;
  demiplane_upload_insert_input: DemiplaneUploadInsertInput;
  demiplane_upload_max_fields: ResolverTypeWrapper<DemiplaneUploadMaxFields>;
  demiplane_upload_max_order_by: DemiplaneUploadMaxOrderBy;
  demiplane_upload_min_fields: ResolverTypeWrapper<DemiplaneUploadMinFields>;
  demiplane_upload_min_order_by: DemiplaneUploadMinOrderBy;
  demiplane_upload_mutation_response: ResolverTypeWrapper<DemiplaneUploadMutationResponse>;
  demiplane_upload_obj_rel_insert_input: DemiplaneUploadObjRelInsertInput;
  demiplane_upload_on_conflict: DemiplaneUploadOnConflict;
  demiplane_upload_order_by: DemiplaneUploadOrderBy;
  demiplane_upload_pk_columns_input: DemiplaneUploadPkColumnsInput;
  demiplane_upload_select_column: DemiplaneUploadSelectColumn;
  demiplane_upload_set_input: DemiplaneUploadSetInput;
  demiplane_upload_stddev_fields: ResolverTypeWrapper<DemiplaneUploadStddevFields>;
  demiplane_upload_stddev_order_by: DemiplaneUploadStddevOrderBy;
  demiplane_upload_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUploadStddevPopFields>;
  demiplane_upload_stddev_pop_order_by: DemiplaneUploadStddevPopOrderBy;
  demiplane_upload_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUploadStddevSampFields>;
  demiplane_upload_stddev_samp_order_by: DemiplaneUploadStddevSampOrderBy;
  demiplane_upload_sum_fields: ResolverTypeWrapper<DemiplaneUploadSumFields>;
  demiplane_upload_sum_order_by: DemiplaneUploadSumOrderBy;
  demiplane_upload_update_column: DemiplaneUploadUpdateColumn;
  demiplane_upload_var_pop_fields: ResolverTypeWrapper<DemiplaneUploadVarPopFields>;
  demiplane_upload_var_pop_order_by: DemiplaneUploadVarPopOrderBy;
  demiplane_upload_var_samp_fields: ResolverTypeWrapper<DemiplaneUploadVarSampFields>;
  demiplane_upload_var_samp_order_by: DemiplaneUploadVarSampOrderBy;
  demiplane_upload_variance_fields: ResolverTypeWrapper<DemiplaneUploadVarianceFields>;
  demiplane_upload_variance_order_by: DemiplaneUploadVarianceOrderBy;
  demiplane_urap: ResolverTypeWrapper<DemiplaneUrap>;
  demiplane_urap_aggregate: ResolverTypeWrapper<DemiplaneUrapAggregate>;
  demiplane_urap_aggregate_fields: ResolverTypeWrapper<DemiplaneUrapAggregateFields>;
  demiplane_urap_aggregate_order_by: DemiplaneUrapAggregateOrderBy;
  demiplane_urap_arr_rel_insert_input: DemiplaneUrapArrRelInsertInput;
  demiplane_urap_avg_fields: ResolverTypeWrapper<DemiplaneUrapAvgFields>;
  demiplane_urap_avg_order_by: DemiplaneUrapAvgOrderBy;
  demiplane_urap_bool_exp: DemiplaneUrapBoolExp;
  demiplane_urap_constraint: DemiplaneUrapConstraint;
  demiplane_urap_inc_input: DemiplaneUrapIncInput;
  demiplane_urap_insert_input: DemiplaneUrapInsertInput;
  demiplane_urap_max_fields: ResolverTypeWrapper<DemiplaneUrapMaxFields>;
  demiplane_urap_max_order_by: DemiplaneUrapMaxOrderBy;
  demiplane_urap_min_fields: ResolverTypeWrapper<DemiplaneUrapMinFields>;
  demiplane_urap_min_order_by: DemiplaneUrapMinOrderBy;
  demiplane_urap_mutation_response: ResolverTypeWrapper<DemiplaneUrapMutationResponse>;
  demiplane_urap_obj_rel_insert_input: DemiplaneUrapObjRelInsertInput;
  demiplane_urap_on_conflict: DemiplaneUrapOnConflict;
  demiplane_urap_order_by: DemiplaneUrapOrderBy;
  demiplane_urap_pk_columns_input: DemiplaneUrapPkColumnsInput;
  demiplane_urap_select_column: DemiplaneUrapSelectColumn;
  demiplane_urap_set_input: DemiplaneUrapSetInput;
  demiplane_urap_stddev_fields: ResolverTypeWrapper<DemiplaneUrapStddevFields>;
  demiplane_urap_stddev_order_by: DemiplaneUrapStddevOrderBy;
  demiplane_urap_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUrapStddevPopFields>;
  demiplane_urap_stddev_pop_order_by: DemiplaneUrapStddevPopOrderBy;
  demiplane_urap_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUrapStddevSampFields>;
  demiplane_urap_stddev_samp_order_by: DemiplaneUrapStddevSampOrderBy;
  demiplane_urap_sum_fields: ResolverTypeWrapper<DemiplaneUrapSumFields>;
  demiplane_urap_sum_order_by: DemiplaneUrapSumOrderBy;
  demiplane_urap_update_column: DemiplaneUrapUpdateColumn;
  demiplane_urap_var_pop_fields: ResolverTypeWrapper<DemiplaneUrapVarPopFields>;
  demiplane_urap_var_pop_order_by: DemiplaneUrapVarPopOrderBy;
  demiplane_urap_var_samp_fields: ResolverTypeWrapper<DemiplaneUrapVarSampFields>;
  demiplane_urap_var_samp_order_by: DemiplaneUrapVarSampOrderBy;
  demiplane_urap_variance_fields: ResolverTypeWrapper<DemiplaneUrapVarianceFields>;
  demiplane_urap_variance_order_by: DemiplaneUrapVarianceOrderBy;
  demiplane_urgt: ResolverTypeWrapper<DemiplaneUrgt>;
  demiplane_urgt_aggregate: ResolverTypeWrapper<DemiplaneUrgtAggregate>;
  demiplane_urgt_aggregate_fields: ResolverTypeWrapper<DemiplaneUrgtAggregateFields>;
  demiplane_urgt_aggregate_order_by: DemiplaneUrgtAggregateOrderBy;
  demiplane_urgt_arr_rel_insert_input: DemiplaneUrgtArrRelInsertInput;
  demiplane_urgt_avg_fields: ResolverTypeWrapper<DemiplaneUrgtAvgFields>;
  demiplane_urgt_avg_order_by: DemiplaneUrgtAvgOrderBy;
  demiplane_urgt_bool_exp: DemiplaneUrgtBoolExp;
  demiplane_urgt_constraint: DemiplaneUrgtConstraint;
  demiplane_urgt_inc_input: DemiplaneUrgtIncInput;
  demiplane_urgt_insert_input: DemiplaneUrgtInsertInput;
  demiplane_urgt_max_fields: ResolverTypeWrapper<DemiplaneUrgtMaxFields>;
  demiplane_urgt_max_order_by: DemiplaneUrgtMaxOrderBy;
  demiplane_urgt_min_fields: ResolverTypeWrapper<DemiplaneUrgtMinFields>;
  demiplane_urgt_min_order_by: DemiplaneUrgtMinOrderBy;
  demiplane_urgt_mutation_response: ResolverTypeWrapper<DemiplaneUrgtMutationResponse>;
  demiplane_urgt_obj_rel_insert_input: DemiplaneUrgtObjRelInsertInput;
  demiplane_urgt_on_conflict: DemiplaneUrgtOnConflict;
  demiplane_urgt_order_by: DemiplaneUrgtOrderBy;
  demiplane_urgt_pk_columns_input: DemiplaneUrgtPkColumnsInput;
  demiplane_urgt_select_column: DemiplaneUrgtSelectColumn;
  demiplane_urgt_set_input: DemiplaneUrgtSetInput;
  demiplane_urgt_stddev_fields: ResolverTypeWrapper<DemiplaneUrgtStddevFields>;
  demiplane_urgt_stddev_order_by: DemiplaneUrgtStddevOrderBy;
  demiplane_urgt_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUrgtStddevPopFields>;
  demiplane_urgt_stddev_pop_order_by: DemiplaneUrgtStddevPopOrderBy;
  demiplane_urgt_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUrgtStddevSampFields>;
  demiplane_urgt_stddev_samp_order_by: DemiplaneUrgtStddevSampOrderBy;
  demiplane_urgt_sum_fields: ResolverTypeWrapper<DemiplaneUrgtSumFields>;
  demiplane_urgt_sum_order_by: DemiplaneUrgtSumOrderBy;
  demiplane_urgt_update_column: DemiplaneUrgtUpdateColumn;
  demiplane_urgt_var_pop_fields: ResolverTypeWrapper<DemiplaneUrgtVarPopFields>;
  demiplane_urgt_var_pop_order_by: DemiplaneUrgtVarPopOrderBy;
  demiplane_urgt_var_samp_fields: ResolverTypeWrapper<DemiplaneUrgtVarSampFields>;
  demiplane_urgt_var_samp_order_by: DemiplaneUrgtVarSampOrderBy;
  demiplane_urgt_variance_fields: ResolverTypeWrapper<DemiplaneUrgtVarianceFields>;
  demiplane_urgt_variance_order_by: DemiplaneUrgtVarianceOrderBy;
  demiplane_user: ResolverTypeWrapper<DemiplaneUser>;
  demiplane_user_activity: ResolverTypeWrapper<DemiplaneUserActivity>;
  demiplane_user_activity_aggregate: ResolverTypeWrapper<DemiplaneUserActivityAggregate>;
  demiplane_user_activity_aggregate_fields: ResolverTypeWrapper<DemiplaneUserActivityAggregateFields>;
  demiplane_user_activity_aggregate_order_by: DemiplaneUserActivityAggregateOrderBy;
  demiplane_user_activity_arr_rel_insert_input: DemiplaneUserActivityArrRelInsertInput;
  demiplane_user_activity_avg_fields: ResolverTypeWrapper<DemiplaneUserActivityAvgFields>;
  demiplane_user_activity_avg_order_by: DemiplaneUserActivityAvgOrderBy;
  demiplane_user_activity_bool_exp: DemiplaneUserActivityBoolExp;
  demiplane_user_activity_constraint: DemiplaneUserActivityConstraint;
  demiplane_user_activity_inc_input: DemiplaneUserActivityIncInput;
  demiplane_user_activity_insert_input: DemiplaneUserActivityInsertInput;
  demiplane_user_activity_max_fields: ResolverTypeWrapper<DemiplaneUserActivityMaxFields>;
  demiplane_user_activity_max_order_by: DemiplaneUserActivityMaxOrderBy;
  demiplane_user_activity_min_fields: ResolverTypeWrapper<DemiplaneUserActivityMinFields>;
  demiplane_user_activity_min_order_by: DemiplaneUserActivityMinOrderBy;
  demiplane_user_activity_mutation_response: ResolverTypeWrapper<DemiplaneUserActivityMutationResponse>;
  demiplane_user_activity_obj_rel_insert_input: DemiplaneUserActivityObjRelInsertInput;
  demiplane_user_activity_on_conflict: DemiplaneUserActivityOnConflict;
  demiplane_user_activity_order_by: DemiplaneUserActivityOrderBy;
  demiplane_user_activity_pk_columns_input: DemiplaneUserActivityPkColumnsInput;
  demiplane_user_activity_select_column: DemiplaneUserActivitySelectColumn;
  demiplane_user_activity_set_input: DemiplaneUserActivitySetInput;
  demiplane_user_activity_stddev_fields: ResolverTypeWrapper<DemiplaneUserActivityStddevFields>;
  demiplane_user_activity_stddev_order_by: DemiplaneUserActivityStddevOrderBy;
  demiplane_user_activity_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserActivityStddevPopFields>;
  demiplane_user_activity_stddev_pop_order_by: DemiplaneUserActivityStddevPopOrderBy;
  demiplane_user_activity_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserActivityStddevSampFields>;
  demiplane_user_activity_stddev_samp_order_by: DemiplaneUserActivityStddevSampOrderBy;
  demiplane_user_activity_sum_fields: ResolverTypeWrapper<DemiplaneUserActivitySumFields>;
  demiplane_user_activity_sum_order_by: DemiplaneUserActivitySumOrderBy;
  demiplane_user_activity_update_column: DemiplaneUserActivityUpdateColumn;
  demiplane_user_activity_var_pop_fields: ResolverTypeWrapper<DemiplaneUserActivityVarPopFields>;
  demiplane_user_activity_var_pop_order_by: DemiplaneUserActivityVarPopOrderBy;
  demiplane_user_activity_var_samp_fields: ResolverTypeWrapper<DemiplaneUserActivityVarSampFields>;
  demiplane_user_activity_var_samp_order_by: DemiplaneUserActivityVarSampOrderBy;
  demiplane_user_activity_variance_fields: ResolverTypeWrapper<DemiplaneUserActivityVarianceFields>;
  demiplane_user_activity_variance_order_by: DemiplaneUserActivityVarianceOrderBy;
  demiplane_user_adventure: ResolverTypeWrapper<DemiplaneUserAdventure>;
  demiplane_user_adventure_aggregate: ResolverTypeWrapper<DemiplaneUserAdventureAggregate>;
  demiplane_user_adventure_aggregate_fields: ResolverTypeWrapper<DemiplaneUserAdventureAggregateFields>;
  demiplane_user_adventure_aggregate_order_by: DemiplaneUserAdventureAggregateOrderBy;
  demiplane_user_adventure_arr_rel_insert_input: DemiplaneUserAdventureArrRelInsertInput;
  demiplane_user_adventure_avg_fields: ResolverTypeWrapper<DemiplaneUserAdventureAvgFields>;
  demiplane_user_adventure_avg_order_by: DemiplaneUserAdventureAvgOrderBy;
  demiplane_user_adventure_bool_exp: DemiplaneUserAdventureBoolExp;
  demiplane_user_adventure_constraint: DemiplaneUserAdventureConstraint;
  demiplane_user_adventure_inc_input: DemiplaneUserAdventureIncInput;
  demiplane_user_adventure_insert_input: DemiplaneUserAdventureInsertInput;
  demiplane_user_adventure_max_fields: ResolverTypeWrapper<DemiplaneUserAdventureMaxFields>;
  demiplane_user_adventure_max_order_by: DemiplaneUserAdventureMaxOrderBy;
  demiplane_user_adventure_min_fields: ResolverTypeWrapper<DemiplaneUserAdventureMinFields>;
  demiplane_user_adventure_min_order_by: DemiplaneUserAdventureMinOrderBy;
  demiplane_user_adventure_mutation_response: ResolverTypeWrapper<DemiplaneUserAdventureMutationResponse>;
  demiplane_user_adventure_obj_rel_insert_input: DemiplaneUserAdventureObjRelInsertInput;
  demiplane_user_adventure_on_conflict: DemiplaneUserAdventureOnConflict;
  demiplane_user_adventure_order_by: DemiplaneUserAdventureOrderBy;
  demiplane_user_adventure_pk_columns_input: DemiplaneUserAdventurePkColumnsInput;
  demiplane_user_adventure_ranking: ResolverTypeWrapper<DemiplaneUserAdventureRanking>;
  demiplane_user_adventure_ranking_aggregate: ResolverTypeWrapper<DemiplaneUserAdventureRankingAggregate>;
  demiplane_user_adventure_ranking_aggregate_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingAggregateFields>;
  demiplane_user_adventure_ranking_aggregate_order_by: DemiplaneUserAdventureRankingAggregateOrderBy;
  demiplane_user_adventure_ranking_arr_rel_insert_input: DemiplaneUserAdventureRankingArrRelInsertInput;
  demiplane_user_adventure_ranking_avg_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingAvgFields>;
  demiplane_user_adventure_ranking_avg_order_by: DemiplaneUserAdventureRankingAvgOrderBy;
  demiplane_user_adventure_ranking_bool_exp: DemiplaneUserAdventureRankingBoolExp;
  demiplane_user_adventure_ranking_constraint: DemiplaneUserAdventureRankingConstraint;
  demiplane_user_adventure_ranking_inc_input: DemiplaneUserAdventureRankingIncInput;
  demiplane_user_adventure_ranking_insert_input: DemiplaneUserAdventureRankingInsertInput;
  demiplane_user_adventure_ranking_max_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingMaxFields>;
  demiplane_user_adventure_ranking_max_order_by: DemiplaneUserAdventureRankingMaxOrderBy;
  demiplane_user_adventure_ranking_min_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingMinFields>;
  demiplane_user_adventure_ranking_min_order_by: DemiplaneUserAdventureRankingMinOrderBy;
  demiplane_user_adventure_ranking_mutation_response: ResolverTypeWrapper<DemiplaneUserAdventureRankingMutationResponse>;
  demiplane_user_adventure_ranking_obj_rel_insert_input: DemiplaneUserAdventureRankingObjRelInsertInput;
  demiplane_user_adventure_ranking_on_conflict: DemiplaneUserAdventureRankingOnConflict;
  demiplane_user_adventure_ranking_order_by: DemiplaneUserAdventureRankingOrderBy;
  demiplane_user_adventure_ranking_pk_columns_input: DemiplaneUserAdventureRankingPkColumnsInput;
  demiplane_user_adventure_ranking_select_column: DemiplaneUserAdventureRankingSelectColumn;
  demiplane_user_adventure_ranking_set_input: DemiplaneUserAdventureRankingSetInput;
  demiplane_user_adventure_ranking_stddev_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingStddevFields>;
  demiplane_user_adventure_ranking_stddev_order_by: DemiplaneUserAdventureRankingStddevOrderBy;
  demiplane_user_adventure_ranking_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingStddevPopFields>;
  demiplane_user_adventure_ranking_stddev_pop_order_by: DemiplaneUserAdventureRankingStddevPopOrderBy;
  demiplane_user_adventure_ranking_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingStddevSampFields>;
  demiplane_user_adventure_ranking_stddev_samp_order_by: DemiplaneUserAdventureRankingStddevSampOrderBy;
  demiplane_user_adventure_ranking_sum_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingSumFields>;
  demiplane_user_adventure_ranking_sum_order_by: DemiplaneUserAdventureRankingSumOrderBy;
  demiplane_user_adventure_ranking_update_column: DemiplaneUserAdventureRankingUpdateColumn;
  demiplane_user_adventure_ranking_var_pop_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingVarPopFields>;
  demiplane_user_adventure_ranking_var_pop_order_by: DemiplaneUserAdventureRankingVarPopOrderBy;
  demiplane_user_adventure_ranking_var_samp_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingVarSampFields>;
  demiplane_user_adventure_ranking_var_samp_order_by: DemiplaneUserAdventureRankingVarSampOrderBy;
  demiplane_user_adventure_ranking_variance_fields: ResolverTypeWrapper<DemiplaneUserAdventureRankingVarianceFields>;
  demiplane_user_adventure_ranking_variance_order_by: DemiplaneUserAdventureRankingVarianceOrderBy;
  demiplane_user_adventure_role: ResolverTypeWrapper<DemiplaneUserAdventureRole>;
  demiplane_user_adventure_role_aggregate: ResolverTypeWrapper<DemiplaneUserAdventureRoleAggregate>;
  demiplane_user_adventure_role_aggregate_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleAggregateFields>;
  demiplane_user_adventure_role_aggregate_order_by: DemiplaneUserAdventureRoleAggregateOrderBy;
  demiplane_user_adventure_role_arr_rel_insert_input: DemiplaneUserAdventureRoleArrRelInsertInput;
  demiplane_user_adventure_role_avg_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleAvgFields>;
  demiplane_user_adventure_role_avg_order_by: DemiplaneUserAdventureRoleAvgOrderBy;
  demiplane_user_adventure_role_bool_exp: DemiplaneUserAdventureRoleBoolExp;
  demiplane_user_adventure_role_constraint: DemiplaneUserAdventureRoleConstraint;
  demiplane_user_adventure_role_inc_input: DemiplaneUserAdventureRoleIncInput;
  demiplane_user_adventure_role_insert_input: DemiplaneUserAdventureRoleInsertInput;
  demiplane_user_adventure_role_max_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleMaxFields>;
  demiplane_user_adventure_role_max_order_by: DemiplaneUserAdventureRoleMaxOrderBy;
  demiplane_user_adventure_role_min_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleMinFields>;
  demiplane_user_adventure_role_min_order_by: DemiplaneUserAdventureRoleMinOrderBy;
  demiplane_user_adventure_role_mutation_response: ResolverTypeWrapper<DemiplaneUserAdventureRoleMutationResponse>;
  demiplane_user_adventure_role_obj_rel_insert_input: DemiplaneUserAdventureRoleObjRelInsertInput;
  demiplane_user_adventure_role_on_conflict: DemiplaneUserAdventureRoleOnConflict;
  demiplane_user_adventure_role_order_by: DemiplaneUserAdventureRoleOrderBy;
  demiplane_user_adventure_role_pk_columns_input: DemiplaneUserAdventureRolePkColumnsInput;
  demiplane_user_adventure_role_select_column: DemiplaneUserAdventureRoleSelectColumn;
  demiplane_user_adventure_role_set_input: DemiplaneUserAdventureRoleSetInput;
  demiplane_user_adventure_role_stddev_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleStddevFields>;
  demiplane_user_adventure_role_stddev_order_by: DemiplaneUserAdventureRoleStddevOrderBy;
  demiplane_user_adventure_role_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleStddevPopFields>;
  demiplane_user_adventure_role_stddev_pop_order_by: DemiplaneUserAdventureRoleStddevPopOrderBy;
  demiplane_user_adventure_role_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleStddevSampFields>;
  demiplane_user_adventure_role_stddev_samp_order_by: DemiplaneUserAdventureRoleStddevSampOrderBy;
  demiplane_user_adventure_role_sum_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleSumFields>;
  demiplane_user_adventure_role_sum_order_by: DemiplaneUserAdventureRoleSumOrderBy;
  demiplane_user_adventure_role_update_column: DemiplaneUserAdventureRoleUpdateColumn;
  demiplane_user_adventure_role_var_pop_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleVarPopFields>;
  demiplane_user_adventure_role_var_pop_order_by: DemiplaneUserAdventureRoleVarPopOrderBy;
  demiplane_user_adventure_role_var_samp_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleVarSampFields>;
  demiplane_user_adventure_role_var_samp_order_by: DemiplaneUserAdventureRoleVarSampOrderBy;
  demiplane_user_adventure_role_variance_fields: ResolverTypeWrapper<DemiplaneUserAdventureRoleVarianceFields>;
  demiplane_user_adventure_role_variance_order_by: DemiplaneUserAdventureRoleVarianceOrderBy;
  demiplane_user_adventure_select_column: DemiplaneUserAdventureSelectColumn;
  demiplane_user_adventure_set_input: DemiplaneUserAdventureSetInput;
  demiplane_user_adventure_stddev_fields: ResolverTypeWrapper<DemiplaneUserAdventureStddevFields>;
  demiplane_user_adventure_stddev_order_by: DemiplaneUserAdventureStddevOrderBy;
  demiplane_user_adventure_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserAdventureStddevPopFields>;
  demiplane_user_adventure_stddev_pop_order_by: DemiplaneUserAdventureStddevPopOrderBy;
  demiplane_user_adventure_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserAdventureStddevSampFields>;
  demiplane_user_adventure_stddev_samp_order_by: DemiplaneUserAdventureStddevSampOrderBy;
  demiplane_user_adventure_sum_fields: ResolverTypeWrapper<DemiplaneUserAdventureSumFields>;
  demiplane_user_adventure_sum_order_by: DemiplaneUserAdventureSumOrderBy;
  demiplane_user_adventure_update_column: DemiplaneUserAdventureUpdateColumn;
  demiplane_user_adventure_var_pop_fields: ResolverTypeWrapper<DemiplaneUserAdventureVarPopFields>;
  demiplane_user_adventure_var_pop_order_by: DemiplaneUserAdventureVarPopOrderBy;
  demiplane_user_adventure_var_samp_fields: ResolverTypeWrapper<DemiplaneUserAdventureVarSampFields>;
  demiplane_user_adventure_var_samp_order_by: DemiplaneUserAdventureVarSampOrderBy;
  demiplane_user_adventure_variance_fields: ResolverTypeWrapper<DemiplaneUserAdventureVarianceFields>;
  demiplane_user_adventure_variance_order_by: DemiplaneUserAdventureVarianceOrderBy;
  demiplane_user_aggregate: ResolverTypeWrapper<DemiplaneUserAggregate>;
  demiplane_user_aggregate_fields: ResolverTypeWrapper<DemiplaneUserAggregateFields>;
  demiplane_user_aggregate_order_by: DemiplaneUserAggregateOrderBy;
  demiplane_user_arr_rel_insert_input: DemiplaneUserArrRelInsertInput;
  demiplane_user_avg_fields: ResolverTypeWrapper<DemiplaneUserAvgFields>;
  demiplane_user_avg_order_by: DemiplaneUserAvgOrderBy;
  demiplane_user_bool_exp: DemiplaneUserBoolExp;
  demiplane_user_constraint: DemiplaneUserConstraint;
  demiplane_user_email_notification_pref: ResolverTypeWrapper<DemiplaneUserEmailNotificationPref>;
  demiplane_user_email_notification_pref_aggregate: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefAggregate>;
  demiplane_user_email_notification_pref_aggregate_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefAggregateFields>;
  demiplane_user_email_notification_pref_aggregate_order_by: DemiplaneUserEmailNotificationPrefAggregateOrderBy;
  demiplane_user_email_notification_pref_arr_rel_insert_input: DemiplaneUserEmailNotificationPrefArrRelInsertInput;
  demiplane_user_email_notification_pref_avg_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefAvgFields>;
  demiplane_user_email_notification_pref_avg_order_by: DemiplaneUserEmailNotificationPrefAvgOrderBy;
  demiplane_user_email_notification_pref_bool_exp: DemiplaneUserEmailNotificationPrefBoolExp;
  demiplane_user_email_notification_pref_constraint: DemiplaneUserEmailNotificationPrefConstraint;
  demiplane_user_email_notification_pref_inc_input: DemiplaneUserEmailNotificationPrefIncInput;
  demiplane_user_email_notification_pref_insert_input: DemiplaneUserEmailNotificationPrefInsertInput;
  demiplane_user_email_notification_pref_max_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefMaxFields>;
  demiplane_user_email_notification_pref_max_order_by: DemiplaneUserEmailNotificationPrefMaxOrderBy;
  demiplane_user_email_notification_pref_min_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefMinFields>;
  demiplane_user_email_notification_pref_min_order_by: DemiplaneUserEmailNotificationPrefMinOrderBy;
  demiplane_user_email_notification_pref_mutation_response: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefMutationResponse>;
  demiplane_user_email_notification_pref_obj_rel_insert_input: DemiplaneUserEmailNotificationPrefObjRelInsertInput;
  demiplane_user_email_notification_pref_on_conflict: DemiplaneUserEmailNotificationPrefOnConflict;
  demiplane_user_email_notification_pref_order_by: DemiplaneUserEmailNotificationPrefOrderBy;
  demiplane_user_email_notification_pref_pk_columns_input: DemiplaneUserEmailNotificationPrefPkColumnsInput;
  demiplane_user_email_notification_pref_select_column: DemiplaneUserEmailNotificationPrefSelectColumn;
  demiplane_user_email_notification_pref_set_input: DemiplaneUserEmailNotificationPrefSetInput;
  demiplane_user_email_notification_pref_stddev_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefStddevFields>;
  demiplane_user_email_notification_pref_stddev_order_by: DemiplaneUserEmailNotificationPrefStddevOrderBy;
  demiplane_user_email_notification_pref_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefStddevPopFields>;
  demiplane_user_email_notification_pref_stddev_pop_order_by: DemiplaneUserEmailNotificationPrefStddevPopOrderBy;
  demiplane_user_email_notification_pref_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefStddevSampFields>;
  demiplane_user_email_notification_pref_stddev_samp_order_by: DemiplaneUserEmailNotificationPrefStddevSampOrderBy;
  demiplane_user_email_notification_pref_sum_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefSumFields>;
  demiplane_user_email_notification_pref_sum_order_by: DemiplaneUserEmailNotificationPrefSumOrderBy;
  demiplane_user_email_notification_pref_update_column: DemiplaneUserEmailNotificationPrefUpdateColumn;
  demiplane_user_email_notification_pref_var_pop_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefVarPopFields>;
  demiplane_user_email_notification_pref_var_pop_order_by: DemiplaneUserEmailNotificationPrefVarPopOrderBy;
  demiplane_user_email_notification_pref_var_samp_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefVarSampFields>;
  demiplane_user_email_notification_pref_var_samp_order_by: DemiplaneUserEmailNotificationPrefVarSampOrderBy;
  demiplane_user_email_notification_pref_variance_fields: ResolverTypeWrapper<DemiplaneUserEmailNotificationPrefVarianceFields>;
  demiplane_user_email_notification_pref_variance_order_by: DemiplaneUserEmailNotificationPrefVarianceOrderBy;
  demiplane_user_inc_input: DemiplaneUserIncInput;
  demiplane_user_insert_input: DemiplaneUserInsertInput;
  demiplane_user_invitation: ResolverTypeWrapper<DemiplaneUserInvitation>;
  demiplane_user_invitation_aggregate: ResolverTypeWrapper<DemiplaneUserInvitationAggregate>;
  demiplane_user_invitation_aggregate_fields: ResolverTypeWrapper<DemiplaneUserInvitationAggregateFields>;
  demiplane_user_invitation_aggregate_order_by: DemiplaneUserInvitationAggregateOrderBy;
  demiplane_user_invitation_arr_rel_insert_input: DemiplaneUserInvitationArrRelInsertInput;
  demiplane_user_invitation_avg_fields: ResolverTypeWrapper<DemiplaneUserInvitationAvgFields>;
  demiplane_user_invitation_avg_order_by: DemiplaneUserInvitationAvgOrderBy;
  demiplane_user_invitation_bool_exp: DemiplaneUserInvitationBoolExp;
  demiplane_user_invitation_constraint: DemiplaneUserInvitationConstraint;
  demiplane_user_invitation_inc_input: DemiplaneUserInvitationIncInput;
  demiplane_user_invitation_insert_input: DemiplaneUserInvitationInsertInput;
  demiplane_user_invitation_max_fields: ResolverTypeWrapper<DemiplaneUserInvitationMaxFields>;
  demiplane_user_invitation_max_order_by: DemiplaneUserInvitationMaxOrderBy;
  demiplane_user_invitation_min_fields: ResolverTypeWrapper<DemiplaneUserInvitationMinFields>;
  demiplane_user_invitation_min_order_by: DemiplaneUserInvitationMinOrderBy;
  demiplane_user_invitation_mutation_response: ResolverTypeWrapper<DemiplaneUserInvitationMutationResponse>;
  demiplane_user_invitation_obj_rel_insert_input: DemiplaneUserInvitationObjRelInsertInput;
  demiplane_user_invitation_on_conflict: DemiplaneUserInvitationOnConflict;
  demiplane_user_invitation_order_by: DemiplaneUserInvitationOrderBy;
  demiplane_user_invitation_pk_columns_input: DemiplaneUserInvitationPkColumnsInput;
  demiplane_user_invitation_select_column: DemiplaneUserInvitationSelectColumn;
  demiplane_user_invitation_set_input: DemiplaneUserInvitationSetInput;
  demiplane_user_invitation_stddev_fields: ResolverTypeWrapper<DemiplaneUserInvitationStddevFields>;
  demiplane_user_invitation_stddev_order_by: DemiplaneUserInvitationStddevOrderBy;
  demiplane_user_invitation_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserInvitationStddevPopFields>;
  demiplane_user_invitation_stddev_pop_order_by: DemiplaneUserInvitationStddevPopOrderBy;
  demiplane_user_invitation_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserInvitationStddevSampFields>;
  demiplane_user_invitation_stddev_samp_order_by: DemiplaneUserInvitationStddevSampOrderBy;
  demiplane_user_invitation_sum_fields: ResolverTypeWrapper<DemiplaneUserInvitationSumFields>;
  demiplane_user_invitation_sum_order_by: DemiplaneUserInvitationSumOrderBy;
  demiplane_user_invitation_update_column: DemiplaneUserInvitationUpdateColumn;
  demiplane_user_invitation_var_pop_fields: ResolverTypeWrapper<DemiplaneUserInvitationVarPopFields>;
  demiplane_user_invitation_var_pop_order_by: DemiplaneUserInvitationVarPopOrderBy;
  demiplane_user_invitation_var_samp_fields: ResolverTypeWrapper<DemiplaneUserInvitationVarSampFields>;
  demiplane_user_invitation_var_samp_order_by: DemiplaneUserInvitationVarSampOrderBy;
  demiplane_user_invitation_variance_fields: ResolverTypeWrapper<DemiplaneUserInvitationVarianceFields>;
  demiplane_user_invitation_variance_order_by: DemiplaneUserInvitationVarianceOrderBy;
  demiplane_user_max_fields: ResolverTypeWrapper<DemiplaneUserMaxFields>;
  demiplane_user_max_order_by: DemiplaneUserMaxOrderBy;
  demiplane_user_metadata: ResolverTypeWrapper<DemiplaneUserMetadata>;
  demiplane_user_metadata_aggregate: ResolverTypeWrapper<DemiplaneUserMetadataAggregate>;
  demiplane_user_metadata_aggregate_fields: ResolverTypeWrapper<DemiplaneUserMetadataAggregateFields>;
  demiplane_user_metadata_aggregate_order_by: DemiplaneUserMetadataAggregateOrderBy;
  demiplane_user_metadata_arr_rel_insert_input: DemiplaneUserMetadataArrRelInsertInput;
  demiplane_user_metadata_avg_fields: ResolverTypeWrapper<DemiplaneUserMetadataAvgFields>;
  demiplane_user_metadata_avg_order_by: DemiplaneUserMetadataAvgOrderBy;
  demiplane_user_metadata_bool_exp: DemiplaneUserMetadataBoolExp;
  demiplane_user_metadata_constraint: DemiplaneUserMetadataConstraint;
  demiplane_user_metadata_inc_input: DemiplaneUserMetadataIncInput;
  demiplane_user_metadata_insert_input: DemiplaneUserMetadataInsertInput;
  demiplane_user_metadata_max_fields: ResolverTypeWrapper<DemiplaneUserMetadataMaxFields>;
  demiplane_user_metadata_max_order_by: DemiplaneUserMetadataMaxOrderBy;
  demiplane_user_metadata_min_fields: ResolverTypeWrapper<DemiplaneUserMetadataMinFields>;
  demiplane_user_metadata_min_order_by: DemiplaneUserMetadataMinOrderBy;
  demiplane_user_metadata_mutation_response: ResolverTypeWrapper<DemiplaneUserMetadataMutationResponse>;
  demiplane_user_metadata_obj_rel_insert_input: DemiplaneUserMetadataObjRelInsertInput;
  demiplane_user_metadata_on_conflict: DemiplaneUserMetadataOnConflict;
  demiplane_user_metadata_order_by: DemiplaneUserMetadataOrderBy;
  demiplane_user_metadata_pk_columns_input: DemiplaneUserMetadataPkColumnsInput;
  demiplane_user_metadata_select_column: DemiplaneUserMetadataSelectColumn;
  demiplane_user_metadata_set_input: DemiplaneUserMetadataSetInput;
  demiplane_user_metadata_stddev_fields: ResolverTypeWrapper<DemiplaneUserMetadataStddevFields>;
  demiplane_user_metadata_stddev_order_by: DemiplaneUserMetadataStddevOrderBy;
  demiplane_user_metadata_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserMetadataStddevPopFields>;
  demiplane_user_metadata_stddev_pop_order_by: DemiplaneUserMetadataStddevPopOrderBy;
  demiplane_user_metadata_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserMetadataStddevSampFields>;
  demiplane_user_metadata_stddev_samp_order_by: DemiplaneUserMetadataStddevSampOrderBy;
  demiplane_user_metadata_sum_fields: ResolverTypeWrapper<DemiplaneUserMetadataSumFields>;
  demiplane_user_metadata_sum_order_by: DemiplaneUserMetadataSumOrderBy;
  demiplane_user_metadata_update_column: DemiplaneUserMetadataUpdateColumn;
  demiplane_user_metadata_var_pop_fields: ResolverTypeWrapper<DemiplaneUserMetadataVarPopFields>;
  demiplane_user_metadata_var_pop_order_by: DemiplaneUserMetadataVarPopOrderBy;
  demiplane_user_metadata_var_samp_fields: ResolverTypeWrapper<DemiplaneUserMetadataVarSampFields>;
  demiplane_user_metadata_var_samp_order_by: DemiplaneUserMetadataVarSampOrderBy;
  demiplane_user_metadata_variance_fields: ResolverTypeWrapper<DemiplaneUserMetadataVarianceFields>;
  demiplane_user_metadata_variance_order_by: DemiplaneUserMetadataVarianceOrderBy;
  demiplane_user_min_fields: ResolverTypeWrapper<DemiplaneUserMinFields>;
  demiplane_user_min_order_by: DemiplaneUserMinOrderBy;
  demiplane_user_mutation_response: ResolverTypeWrapper<DemiplaneUserMutationResponse>;
  demiplane_user_obj_rel_insert_input: DemiplaneUserObjRelInsertInput;
  demiplane_user_on_conflict: DemiplaneUserOnConflict;
  demiplane_user_order_by: DemiplaneUserOrderBy;
  demiplane_user_pk_columns_input: DemiplaneUserPkColumnsInput;
  demiplane_user_push_notification_pref: ResolverTypeWrapper<DemiplaneUserPushNotificationPref>;
  demiplane_user_push_notification_pref_aggregate: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefAggregate>;
  demiplane_user_push_notification_pref_aggregate_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefAggregateFields>;
  demiplane_user_push_notification_pref_aggregate_order_by: DemiplaneUserPushNotificationPrefAggregateOrderBy;
  demiplane_user_push_notification_pref_arr_rel_insert_input: DemiplaneUserPushNotificationPrefArrRelInsertInput;
  demiplane_user_push_notification_pref_avg_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefAvgFields>;
  demiplane_user_push_notification_pref_avg_order_by: DemiplaneUserPushNotificationPrefAvgOrderBy;
  demiplane_user_push_notification_pref_bool_exp: DemiplaneUserPushNotificationPrefBoolExp;
  demiplane_user_push_notification_pref_constraint: DemiplaneUserPushNotificationPrefConstraint;
  demiplane_user_push_notification_pref_inc_input: DemiplaneUserPushNotificationPrefIncInput;
  demiplane_user_push_notification_pref_insert_input: DemiplaneUserPushNotificationPrefInsertInput;
  demiplane_user_push_notification_pref_max_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefMaxFields>;
  demiplane_user_push_notification_pref_max_order_by: DemiplaneUserPushNotificationPrefMaxOrderBy;
  demiplane_user_push_notification_pref_min_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefMinFields>;
  demiplane_user_push_notification_pref_min_order_by: DemiplaneUserPushNotificationPrefMinOrderBy;
  demiplane_user_push_notification_pref_mutation_response: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefMutationResponse>;
  demiplane_user_push_notification_pref_obj_rel_insert_input: DemiplaneUserPushNotificationPrefObjRelInsertInput;
  demiplane_user_push_notification_pref_on_conflict: DemiplaneUserPushNotificationPrefOnConflict;
  demiplane_user_push_notification_pref_order_by: DemiplaneUserPushNotificationPrefOrderBy;
  demiplane_user_push_notification_pref_pk_columns_input: DemiplaneUserPushNotificationPrefPkColumnsInput;
  demiplane_user_push_notification_pref_select_column: DemiplaneUserPushNotificationPrefSelectColumn;
  demiplane_user_push_notification_pref_set_input: DemiplaneUserPushNotificationPrefSetInput;
  demiplane_user_push_notification_pref_stddev_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefStddevFields>;
  demiplane_user_push_notification_pref_stddev_order_by: DemiplaneUserPushNotificationPrefStddevOrderBy;
  demiplane_user_push_notification_pref_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefStddevPopFields>;
  demiplane_user_push_notification_pref_stddev_pop_order_by: DemiplaneUserPushNotificationPrefStddevPopOrderBy;
  demiplane_user_push_notification_pref_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefStddevSampFields>;
  demiplane_user_push_notification_pref_stddev_samp_order_by: DemiplaneUserPushNotificationPrefStddevSampOrderBy;
  demiplane_user_push_notification_pref_sum_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefSumFields>;
  demiplane_user_push_notification_pref_sum_order_by: DemiplaneUserPushNotificationPrefSumOrderBy;
  demiplane_user_push_notification_pref_update_column: DemiplaneUserPushNotificationPrefUpdateColumn;
  demiplane_user_push_notification_pref_var_pop_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefVarPopFields>;
  demiplane_user_push_notification_pref_var_pop_order_by: DemiplaneUserPushNotificationPrefVarPopOrderBy;
  demiplane_user_push_notification_pref_var_samp_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefVarSampFields>;
  demiplane_user_push_notification_pref_var_samp_order_by: DemiplaneUserPushNotificationPrefVarSampOrderBy;
  demiplane_user_push_notification_pref_variance_fields: ResolverTypeWrapper<DemiplaneUserPushNotificationPrefVarianceFields>;
  demiplane_user_push_notification_pref_variance_order_by: DemiplaneUserPushNotificationPrefVarianceOrderBy;
  demiplane_user_recruitment: ResolverTypeWrapper<DemiplaneUserRecruitment>;
  demiplane_user_recruitment_adventurer: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurer>;
  demiplane_user_recruitment_adventurer_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAggregate>;
  demiplane_user_recruitment_adventurer_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAggregateFields>;
  demiplane_user_recruitment_adventurer_aggregate_order_by: DemiplaneUserRecruitmentAdventurerAggregateOrderBy;
  demiplane_user_recruitment_adventurer_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerArrRelInsertInput;
  demiplane_user_recruitment_adventurer_availability: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailability>;
  demiplane_user_recruitment_adventurer_availability_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityAggregate>;
  demiplane_user_recruitment_adventurer_availability_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFields>;
  demiplane_user_recruitment_adventurer_availability_aggregate_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityAggregateOrderBy;
  demiplane_user_recruitment_adventurer_availability_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerAvailabilityArrRelInsertInput;
  demiplane_user_recruitment_adventurer_availability_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityAvgFields>;
  demiplane_user_recruitment_adventurer_availability_avg_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityAvgOrderBy;
  demiplane_user_recruitment_adventurer_availability_bool_exp: DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp;
  demiplane_user_recruitment_adventurer_availability_constraint: DemiplaneUserRecruitmentAdventurerAvailabilityConstraint;
  demiplane_user_recruitment_adventurer_availability_inc_input: DemiplaneUserRecruitmentAdventurerAvailabilityIncInput;
  demiplane_user_recruitment_adventurer_availability_insert_input: DemiplaneUserRecruitmentAdventurerAvailabilityInsertInput;
  demiplane_user_recruitment_adventurer_availability_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityMaxFields>;
  demiplane_user_recruitment_adventurer_availability_max_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityMaxOrderBy;
  demiplane_user_recruitment_adventurer_availability_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityMinFields>;
  demiplane_user_recruitment_adventurer_availability_min_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityMinOrderBy;
  demiplane_user_recruitment_adventurer_availability_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponse>;
  demiplane_user_recruitment_adventurer_availability_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerAvailabilityObjRelInsertInput;
  demiplane_user_recruitment_adventurer_availability_on_conflict: DemiplaneUserRecruitmentAdventurerAvailabilityOnConflict;
  demiplane_user_recruitment_adventurer_availability_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy;
  demiplane_user_recruitment_adventurer_availability_pk_columns_input: DemiplaneUserRecruitmentAdventurerAvailabilityPkColumnsInput;
  demiplane_user_recruitment_adventurer_availability_select_column: DemiplaneUserRecruitmentAdventurerAvailabilitySelectColumn;
  demiplane_user_recruitment_adventurer_availability_set_input: DemiplaneUserRecruitmentAdventurerAvailabilitySetInput;
  demiplane_user_recruitment_adventurer_availability_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityStddevFields>;
  demiplane_user_recruitment_adventurer_availability_stddev_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityStddevOrderBy;
  demiplane_user_recruitment_adventurer_availability_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopFields>;
  demiplane_user_recruitment_adventurer_availability_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_availability_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampFields>;
  demiplane_user_recruitment_adventurer_availability_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_availability_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilitySumFields>;
  demiplane_user_recruitment_adventurer_availability_sum_order_by: DemiplaneUserRecruitmentAdventurerAvailabilitySumOrderBy;
  demiplane_user_recruitment_adventurer_availability_update_column: DemiplaneUserRecruitmentAdventurerAvailabilityUpdateColumn;
  demiplane_user_recruitment_adventurer_availability_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityVarPopFields>;
  demiplane_user_recruitment_adventurer_availability_var_pop_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityVarPopOrderBy;
  demiplane_user_recruitment_adventurer_availability_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityVarSampFields>;
  demiplane_user_recruitment_adventurer_availability_var_samp_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityVarSampOrderBy;
  demiplane_user_recruitment_adventurer_availability_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvailabilityVarianceFields>;
  demiplane_user_recruitment_adventurer_availability_variance_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityVarianceOrderBy;
  demiplane_user_recruitment_adventurer_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerAvgFields>;
  demiplane_user_recruitment_adventurer_avg_order_by: DemiplaneUserRecruitmentAdventurerAvgOrderBy;
  demiplane_user_recruitment_adventurer_bool_exp: DemiplaneUserRecruitmentAdventurerBoolExp;
  demiplane_user_recruitment_adventurer_constraint: DemiplaneUserRecruitmentAdventurerConstraint;
  demiplane_user_recruitment_adventurer_games: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGames>;
  demiplane_user_recruitment_adventurer_games_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesAggregate>;
  demiplane_user_recruitment_adventurer_games_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesAggregateFields>;
  demiplane_user_recruitment_adventurer_games_aggregate_order_by: DemiplaneUserRecruitmentAdventurerGamesAggregateOrderBy;
  demiplane_user_recruitment_adventurer_games_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerGamesArrRelInsertInput;
  demiplane_user_recruitment_adventurer_games_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesAvgFields>;
  demiplane_user_recruitment_adventurer_games_avg_order_by: DemiplaneUserRecruitmentAdventurerGamesAvgOrderBy;
  demiplane_user_recruitment_adventurer_games_bool_exp: DemiplaneUserRecruitmentAdventurerGamesBoolExp;
  demiplane_user_recruitment_adventurer_games_constraint: DemiplaneUserRecruitmentAdventurerGamesConstraint;
  demiplane_user_recruitment_adventurer_games_inc_input: DemiplaneUserRecruitmentAdventurerGamesIncInput;
  demiplane_user_recruitment_adventurer_games_insert_input: DemiplaneUserRecruitmentAdventurerGamesInsertInput;
  demiplane_user_recruitment_adventurer_games_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesMaxFields>;
  demiplane_user_recruitment_adventurer_games_max_order_by: DemiplaneUserRecruitmentAdventurerGamesMaxOrderBy;
  demiplane_user_recruitment_adventurer_games_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesMinFields>;
  demiplane_user_recruitment_adventurer_games_min_order_by: DemiplaneUserRecruitmentAdventurerGamesMinOrderBy;
  demiplane_user_recruitment_adventurer_games_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesMutationResponse>;
  demiplane_user_recruitment_adventurer_games_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerGamesObjRelInsertInput;
  demiplane_user_recruitment_adventurer_games_on_conflict: DemiplaneUserRecruitmentAdventurerGamesOnConflict;
  demiplane_user_recruitment_adventurer_games_order_by: DemiplaneUserRecruitmentAdventurerGamesOrderBy;
  demiplane_user_recruitment_adventurer_games_pk_columns_input: DemiplaneUserRecruitmentAdventurerGamesPkColumnsInput;
  demiplane_user_recruitment_adventurer_games_select_column: DemiplaneUserRecruitmentAdventurerGamesSelectColumn;
  demiplane_user_recruitment_adventurer_games_set_input: DemiplaneUserRecruitmentAdventurerGamesSetInput;
  demiplane_user_recruitment_adventurer_games_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesStddevFields>;
  demiplane_user_recruitment_adventurer_games_stddev_order_by: DemiplaneUserRecruitmentAdventurerGamesStddevOrderBy;
  demiplane_user_recruitment_adventurer_games_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesStddevPopFields>;
  demiplane_user_recruitment_adventurer_games_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerGamesStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_games_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesStddevSampFields>;
  demiplane_user_recruitment_adventurer_games_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerGamesStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_games_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesSumFields>;
  demiplane_user_recruitment_adventurer_games_sum_order_by: DemiplaneUserRecruitmentAdventurerGamesSumOrderBy;
  demiplane_user_recruitment_adventurer_games_update_column: DemiplaneUserRecruitmentAdventurerGamesUpdateColumn;
  demiplane_user_recruitment_adventurer_games_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesVarPopFields>;
  demiplane_user_recruitment_adventurer_games_var_pop_order_by: DemiplaneUserRecruitmentAdventurerGamesVarPopOrderBy;
  demiplane_user_recruitment_adventurer_games_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesVarSampFields>;
  demiplane_user_recruitment_adventurer_games_var_samp_order_by: DemiplaneUserRecruitmentAdventurerGamesVarSampOrderBy;
  demiplane_user_recruitment_adventurer_games_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerGamesVarianceFields>;
  demiplane_user_recruitment_adventurer_games_variance_order_by: DemiplaneUserRecruitmentAdventurerGamesVarianceOrderBy;
  demiplane_user_recruitment_adventurer_inc_input: DemiplaneUserRecruitmentAdventurerIncInput;
  demiplane_user_recruitment_adventurer_insert_input: DemiplaneUserRecruitmentAdventurerInsertInput;
  demiplane_user_recruitment_adventurer_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerMaxFields>;
  demiplane_user_recruitment_adventurer_max_order_by: DemiplaneUserRecruitmentAdventurerMaxOrderBy;
  demiplane_user_recruitment_adventurer_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerMinFields>;
  demiplane_user_recruitment_adventurer_min_order_by: DemiplaneUserRecruitmentAdventurerMinOrderBy;
  demiplane_user_recruitment_adventurer_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerMutationResponse>;
  demiplane_user_recruitment_adventurer_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerObjRelInsertInput;
  demiplane_user_recruitment_adventurer_on_conflict: DemiplaneUserRecruitmentAdventurerOnConflict;
  demiplane_user_recruitment_adventurer_order_by: DemiplaneUserRecruitmentAdventurerOrderBy;
  demiplane_user_recruitment_adventurer_pk_columns_input: DemiplaneUserRecruitmentAdventurerPkColumnsInput;
  demiplane_user_recruitment_adventurer_platforms: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatforms>;
  demiplane_user_recruitment_adventurer_platforms_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsAggregate>;
  demiplane_user_recruitment_adventurer_platforms_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsAggregateFields>;
  demiplane_user_recruitment_adventurer_platforms_aggregate_order_by: DemiplaneUserRecruitmentAdventurerPlatformsAggregateOrderBy;
  demiplane_user_recruitment_adventurer_platforms_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerPlatformsArrRelInsertInput;
  demiplane_user_recruitment_adventurer_platforms_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsAvgFields>;
  demiplane_user_recruitment_adventurer_platforms_avg_order_by: DemiplaneUserRecruitmentAdventurerPlatformsAvgOrderBy;
  demiplane_user_recruitment_adventurer_platforms_bool_exp: DemiplaneUserRecruitmentAdventurerPlatformsBoolExp;
  demiplane_user_recruitment_adventurer_platforms_constraint: DemiplaneUserRecruitmentAdventurerPlatformsConstraint;
  demiplane_user_recruitment_adventurer_platforms_inc_input: DemiplaneUserRecruitmentAdventurerPlatformsIncInput;
  demiplane_user_recruitment_adventurer_platforms_insert_input: DemiplaneUserRecruitmentAdventurerPlatformsInsertInput;
  demiplane_user_recruitment_adventurer_platforms_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsMaxFields>;
  demiplane_user_recruitment_adventurer_platforms_max_order_by: DemiplaneUserRecruitmentAdventurerPlatformsMaxOrderBy;
  demiplane_user_recruitment_adventurer_platforms_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsMinFields>;
  demiplane_user_recruitment_adventurer_platforms_min_order_by: DemiplaneUserRecruitmentAdventurerPlatformsMinOrderBy;
  demiplane_user_recruitment_adventurer_platforms_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsMutationResponse>;
  demiplane_user_recruitment_adventurer_platforms_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerPlatformsObjRelInsertInput;
  demiplane_user_recruitment_adventurer_platforms_on_conflict: DemiplaneUserRecruitmentAdventurerPlatformsOnConflict;
  demiplane_user_recruitment_adventurer_platforms_order_by: DemiplaneUserRecruitmentAdventurerPlatformsOrderBy;
  demiplane_user_recruitment_adventurer_platforms_pk_columns_input: DemiplaneUserRecruitmentAdventurerPlatformsPkColumnsInput;
  demiplane_user_recruitment_adventurer_platforms_select_column: DemiplaneUserRecruitmentAdventurerPlatformsSelectColumn;
  demiplane_user_recruitment_adventurer_platforms_set_input: DemiplaneUserRecruitmentAdventurerPlatformsSetInput;
  demiplane_user_recruitment_adventurer_platforms_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsStddevFields>;
  demiplane_user_recruitment_adventurer_platforms_stddev_order_by: DemiplaneUserRecruitmentAdventurerPlatformsStddevOrderBy;
  demiplane_user_recruitment_adventurer_platforms_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsStddevPopFields>;
  demiplane_user_recruitment_adventurer_platforms_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerPlatformsStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_platforms_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsStddevSampFields>;
  demiplane_user_recruitment_adventurer_platforms_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerPlatformsStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_platforms_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsSumFields>;
  demiplane_user_recruitment_adventurer_platforms_sum_order_by: DemiplaneUserRecruitmentAdventurerPlatformsSumOrderBy;
  demiplane_user_recruitment_adventurer_platforms_update_column: DemiplaneUserRecruitmentAdventurerPlatformsUpdateColumn;
  demiplane_user_recruitment_adventurer_platforms_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsVarPopFields>;
  demiplane_user_recruitment_adventurer_platforms_var_pop_order_by: DemiplaneUserRecruitmentAdventurerPlatformsVarPopOrderBy;
  demiplane_user_recruitment_adventurer_platforms_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsVarSampFields>;
  demiplane_user_recruitment_adventurer_platforms_var_samp_order_by: DemiplaneUserRecruitmentAdventurerPlatformsVarSampOrderBy;
  demiplane_user_recruitment_adventurer_platforms_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerPlatformsVarianceFields>;
  demiplane_user_recruitment_adventurer_platforms_variance_order_by: DemiplaneUserRecruitmentAdventurerPlatformsVarianceOrderBy;
  demiplane_user_recruitment_adventurer_select_column: DemiplaneUserRecruitmentAdventurerSelectColumn;
  demiplane_user_recruitment_adventurer_set_input: DemiplaneUserRecruitmentAdventurerSetInput;
  demiplane_user_recruitment_adventurer_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerStddevFields>;
  demiplane_user_recruitment_adventurer_stddev_order_by: DemiplaneUserRecruitmentAdventurerStddevOrderBy;
  demiplane_user_recruitment_adventurer_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerStddevPopFields>;
  demiplane_user_recruitment_adventurer_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerStddevSampFields>;
  demiplane_user_recruitment_adventurer_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerSumFields>;
  demiplane_user_recruitment_adventurer_sum_order_by: DemiplaneUserRecruitmentAdventurerSumOrderBy;
  demiplane_user_recruitment_adventurer_update_column: DemiplaneUserRecruitmentAdventurerUpdateColumn;
  demiplane_user_recruitment_adventurer_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerVarPopFields>;
  demiplane_user_recruitment_adventurer_var_pop_order_by: DemiplaneUserRecruitmentAdventurerVarPopOrderBy;
  demiplane_user_recruitment_adventurer_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerVarSampFields>;
  demiplane_user_recruitment_adventurer_var_samp_order_by: DemiplaneUserRecruitmentAdventurerVarSampOrderBy;
  demiplane_user_recruitment_adventurer_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAdventurerVarianceFields>;
  demiplane_user_recruitment_adventurer_variance_order_by: DemiplaneUserRecruitmentAdventurerVarianceOrderBy;
  demiplane_user_recruitment_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentAggregate>;
  demiplane_user_recruitment_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAggregateFields>;
  demiplane_user_recruitment_aggregate_order_by: DemiplaneUserRecruitmentAggregateOrderBy;
  demiplane_user_recruitment_arr_rel_insert_input: DemiplaneUserRecruitmentArrRelInsertInput;
  demiplane_user_recruitment_availability: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailability>;
  demiplane_user_recruitment_availability_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityAggregate>;
  demiplane_user_recruitment_availability_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityAggregateFields>;
  demiplane_user_recruitment_availability_aggregate_order_by: DemiplaneUserRecruitmentAvailabilityAggregateOrderBy;
  demiplane_user_recruitment_availability_arr_rel_insert_input: DemiplaneUserRecruitmentAvailabilityArrRelInsertInput;
  demiplane_user_recruitment_availability_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityAvgFields>;
  demiplane_user_recruitment_availability_avg_order_by: DemiplaneUserRecruitmentAvailabilityAvgOrderBy;
  demiplane_user_recruitment_availability_bool_exp: DemiplaneUserRecruitmentAvailabilityBoolExp;
  demiplane_user_recruitment_availability_constraint: DemiplaneUserRecruitmentAvailabilityConstraint;
  demiplane_user_recruitment_availability_inc_input: DemiplaneUserRecruitmentAvailabilityIncInput;
  demiplane_user_recruitment_availability_insert_input: DemiplaneUserRecruitmentAvailabilityInsertInput;
  demiplane_user_recruitment_availability_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityMaxFields>;
  demiplane_user_recruitment_availability_max_order_by: DemiplaneUserRecruitmentAvailabilityMaxOrderBy;
  demiplane_user_recruitment_availability_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityMinFields>;
  demiplane_user_recruitment_availability_min_order_by: DemiplaneUserRecruitmentAvailabilityMinOrderBy;
  demiplane_user_recruitment_availability_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityMutationResponse>;
  demiplane_user_recruitment_availability_obj_rel_insert_input: DemiplaneUserRecruitmentAvailabilityObjRelInsertInput;
  demiplane_user_recruitment_availability_on_conflict: DemiplaneUserRecruitmentAvailabilityOnConflict;
  demiplane_user_recruitment_availability_order_by: DemiplaneUserRecruitmentAvailabilityOrderBy;
  demiplane_user_recruitment_availability_pk_columns_input: DemiplaneUserRecruitmentAvailabilityPkColumnsInput;
  demiplane_user_recruitment_availability_select_column: DemiplaneUserRecruitmentAvailabilitySelectColumn;
  demiplane_user_recruitment_availability_set_input: DemiplaneUserRecruitmentAvailabilitySetInput;
  demiplane_user_recruitment_availability_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityStddevFields>;
  demiplane_user_recruitment_availability_stddev_order_by: DemiplaneUserRecruitmentAvailabilityStddevOrderBy;
  demiplane_user_recruitment_availability_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityStddevPopFields>;
  demiplane_user_recruitment_availability_stddev_pop_order_by: DemiplaneUserRecruitmentAvailabilityStddevPopOrderBy;
  demiplane_user_recruitment_availability_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityStddevSampFields>;
  demiplane_user_recruitment_availability_stddev_samp_order_by: DemiplaneUserRecruitmentAvailabilityStddevSampOrderBy;
  demiplane_user_recruitment_availability_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilitySumFields>;
  demiplane_user_recruitment_availability_sum_order_by: DemiplaneUserRecruitmentAvailabilitySumOrderBy;
  demiplane_user_recruitment_availability_update_column: DemiplaneUserRecruitmentAvailabilityUpdateColumn;
  demiplane_user_recruitment_availability_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityVarPopFields>;
  demiplane_user_recruitment_availability_var_pop_order_by: DemiplaneUserRecruitmentAvailabilityVarPopOrderBy;
  demiplane_user_recruitment_availability_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityVarSampFields>;
  demiplane_user_recruitment_availability_var_samp_order_by: DemiplaneUserRecruitmentAvailabilityVarSampOrderBy;
  demiplane_user_recruitment_availability_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvailabilityVarianceFields>;
  demiplane_user_recruitment_availability_variance_order_by: DemiplaneUserRecruitmentAvailabilityVarianceOrderBy;
  demiplane_user_recruitment_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentAvgFields>;
  demiplane_user_recruitment_avg_order_by: DemiplaneUserRecruitmentAvgOrderBy;
  demiplane_user_recruitment_bool_exp: DemiplaneUserRecruitmentBoolExp;
  demiplane_user_recruitment_constraint: DemiplaneUserRecruitmentConstraint;
  demiplane_user_recruitment_game_master: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMaster>;
  demiplane_user_recruitment_game_master_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAggregate>;
  demiplane_user_recruitment_game_master_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAggregateFields>;
  demiplane_user_recruitment_game_master_aggregate_order_by: DemiplaneUserRecruitmentGameMasterAggregateOrderBy;
  demiplane_user_recruitment_game_master_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterArrRelInsertInput;
  demiplane_user_recruitment_game_master_availability: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailability>;
  demiplane_user_recruitment_game_master_availability_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityAggregate>;
  demiplane_user_recruitment_game_master_availability_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFields>;
  demiplane_user_recruitment_game_master_availability_aggregate_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityAggregateOrderBy;
  demiplane_user_recruitment_game_master_availability_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterAvailabilityArrRelInsertInput;
  demiplane_user_recruitment_game_master_availability_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityAvgFields>;
  demiplane_user_recruitment_game_master_availability_avg_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityAvgOrderBy;
  demiplane_user_recruitment_game_master_availability_bool_exp: DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp;
  demiplane_user_recruitment_game_master_availability_constraint: DemiplaneUserRecruitmentGameMasterAvailabilityConstraint;
  demiplane_user_recruitment_game_master_availability_inc_input: DemiplaneUserRecruitmentGameMasterAvailabilityIncInput;
  demiplane_user_recruitment_game_master_availability_insert_input: DemiplaneUserRecruitmentGameMasterAvailabilityInsertInput;
  demiplane_user_recruitment_game_master_availability_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityMaxFields>;
  demiplane_user_recruitment_game_master_availability_max_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityMaxOrderBy;
  demiplane_user_recruitment_game_master_availability_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityMinFields>;
  demiplane_user_recruitment_game_master_availability_min_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityMinOrderBy;
  demiplane_user_recruitment_game_master_availability_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponse>;
  demiplane_user_recruitment_game_master_availability_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterAvailabilityObjRelInsertInput;
  demiplane_user_recruitment_game_master_availability_on_conflict: DemiplaneUserRecruitmentGameMasterAvailabilityOnConflict;
  demiplane_user_recruitment_game_master_availability_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy;
  demiplane_user_recruitment_game_master_availability_pk_columns_input: DemiplaneUserRecruitmentGameMasterAvailabilityPkColumnsInput;
  demiplane_user_recruitment_game_master_availability_select_column: DemiplaneUserRecruitmentGameMasterAvailabilitySelectColumn;
  demiplane_user_recruitment_game_master_availability_set_input: DemiplaneUserRecruitmentGameMasterAvailabilitySetInput;
  demiplane_user_recruitment_game_master_availability_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityStddevFields>;
  demiplane_user_recruitment_game_master_availability_stddev_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityStddevOrderBy;
  demiplane_user_recruitment_game_master_availability_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopFields>;
  demiplane_user_recruitment_game_master_availability_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopOrderBy;
  demiplane_user_recruitment_game_master_availability_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampFields>;
  demiplane_user_recruitment_game_master_availability_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampOrderBy;
  demiplane_user_recruitment_game_master_availability_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilitySumFields>;
  demiplane_user_recruitment_game_master_availability_sum_order_by: DemiplaneUserRecruitmentGameMasterAvailabilitySumOrderBy;
  demiplane_user_recruitment_game_master_availability_update_column: DemiplaneUserRecruitmentGameMasterAvailabilityUpdateColumn;
  demiplane_user_recruitment_game_master_availability_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityVarPopFields>;
  demiplane_user_recruitment_game_master_availability_var_pop_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityVarPopOrderBy;
  demiplane_user_recruitment_game_master_availability_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityVarSampFields>;
  demiplane_user_recruitment_game_master_availability_var_samp_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityVarSampOrderBy;
  demiplane_user_recruitment_game_master_availability_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvailabilityVarianceFields>;
  demiplane_user_recruitment_game_master_availability_variance_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityVarianceOrderBy;
  demiplane_user_recruitment_game_master_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterAvgFields>;
  demiplane_user_recruitment_game_master_avg_order_by: DemiplaneUserRecruitmentGameMasterAvgOrderBy;
  demiplane_user_recruitment_game_master_bool_exp: DemiplaneUserRecruitmentGameMasterBoolExp;
  demiplane_user_recruitment_game_master_constraint: DemiplaneUserRecruitmentGameMasterConstraint;
  demiplane_user_recruitment_game_master_games: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGames>;
  demiplane_user_recruitment_game_master_games_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesAggregate>;
  demiplane_user_recruitment_game_master_games_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesAggregateFields>;
  demiplane_user_recruitment_game_master_games_aggregate_order_by: DemiplaneUserRecruitmentGameMasterGamesAggregateOrderBy;
  demiplane_user_recruitment_game_master_games_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterGamesArrRelInsertInput;
  demiplane_user_recruitment_game_master_games_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesAvgFields>;
  demiplane_user_recruitment_game_master_games_avg_order_by: DemiplaneUserRecruitmentGameMasterGamesAvgOrderBy;
  demiplane_user_recruitment_game_master_games_bool_exp: DemiplaneUserRecruitmentGameMasterGamesBoolExp;
  demiplane_user_recruitment_game_master_games_constraint: DemiplaneUserRecruitmentGameMasterGamesConstraint;
  demiplane_user_recruitment_game_master_games_inc_input: DemiplaneUserRecruitmentGameMasterGamesIncInput;
  demiplane_user_recruitment_game_master_games_insert_input: DemiplaneUserRecruitmentGameMasterGamesInsertInput;
  demiplane_user_recruitment_game_master_games_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesMaxFields>;
  demiplane_user_recruitment_game_master_games_max_order_by: DemiplaneUserRecruitmentGameMasterGamesMaxOrderBy;
  demiplane_user_recruitment_game_master_games_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesMinFields>;
  demiplane_user_recruitment_game_master_games_min_order_by: DemiplaneUserRecruitmentGameMasterGamesMinOrderBy;
  demiplane_user_recruitment_game_master_games_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesMutationResponse>;
  demiplane_user_recruitment_game_master_games_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterGamesObjRelInsertInput;
  demiplane_user_recruitment_game_master_games_on_conflict: DemiplaneUserRecruitmentGameMasterGamesOnConflict;
  demiplane_user_recruitment_game_master_games_order_by: DemiplaneUserRecruitmentGameMasterGamesOrderBy;
  demiplane_user_recruitment_game_master_games_pk_columns_input: DemiplaneUserRecruitmentGameMasterGamesPkColumnsInput;
  demiplane_user_recruitment_game_master_games_select_column: DemiplaneUserRecruitmentGameMasterGamesSelectColumn;
  demiplane_user_recruitment_game_master_games_set_input: DemiplaneUserRecruitmentGameMasterGamesSetInput;
  demiplane_user_recruitment_game_master_games_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesStddevFields>;
  demiplane_user_recruitment_game_master_games_stddev_order_by: DemiplaneUserRecruitmentGameMasterGamesStddevOrderBy;
  demiplane_user_recruitment_game_master_games_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesStddevPopFields>;
  demiplane_user_recruitment_game_master_games_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterGamesStddevPopOrderBy;
  demiplane_user_recruitment_game_master_games_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesStddevSampFields>;
  demiplane_user_recruitment_game_master_games_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterGamesStddevSampOrderBy;
  demiplane_user_recruitment_game_master_games_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesSumFields>;
  demiplane_user_recruitment_game_master_games_sum_order_by: DemiplaneUserRecruitmentGameMasterGamesSumOrderBy;
  demiplane_user_recruitment_game_master_games_update_column: DemiplaneUserRecruitmentGameMasterGamesUpdateColumn;
  demiplane_user_recruitment_game_master_games_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesVarPopFields>;
  demiplane_user_recruitment_game_master_games_var_pop_order_by: DemiplaneUserRecruitmentGameMasterGamesVarPopOrderBy;
  demiplane_user_recruitment_game_master_games_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesVarSampFields>;
  demiplane_user_recruitment_game_master_games_var_samp_order_by: DemiplaneUserRecruitmentGameMasterGamesVarSampOrderBy;
  demiplane_user_recruitment_game_master_games_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterGamesVarianceFields>;
  demiplane_user_recruitment_game_master_games_variance_order_by: DemiplaneUserRecruitmentGameMasterGamesVarianceOrderBy;
  demiplane_user_recruitment_game_master_inc_input: DemiplaneUserRecruitmentGameMasterIncInput;
  demiplane_user_recruitment_game_master_insert_input: DemiplaneUserRecruitmentGameMasterInsertInput;
  demiplane_user_recruitment_game_master_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterMaxFields>;
  demiplane_user_recruitment_game_master_max_order_by: DemiplaneUserRecruitmentGameMasterMaxOrderBy;
  demiplane_user_recruitment_game_master_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterMinFields>;
  demiplane_user_recruitment_game_master_min_order_by: DemiplaneUserRecruitmentGameMasterMinOrderBy;
  demiplane_user_recruitment_game_master_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterMutationResponse>;
  demiplane_user_recruitment_game_master_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterObjRelInsertInput;
  demiplane_user_recruitment_game_master_on_conflict: DemiplaneUserRecruitmentGameMasterOnConflict;
  demiplane_user_recruitment_game_master_order_by: DemiplaneUserRecruitmentGameMasterOrderBy;
  demiplane_user_recruitment_game_master_pk_columns_input: DemiplaneUserRecruitmentGameMasterPkColumnsInput;
  demiplane_user_recruitment_game_master_platforms: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatforms>;
  demiplane_user_recruitment_game_master_platforms_aggregate: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsAggregate>;
  demiplane_user_recruitment_game_master_platforms_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsAggregateFields>;
  demiplane_user_recruitment_game_master_platforms_aggregate_order_by: DemiplaneUserRecruitmentGameMasterPlatformsAggregateOrderBy;
  demiplane_user_recruitment_game_master_platforms_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterPlatformsArrRelInsertInput;
  demiplane_user_recruitment_game_master_platforms_avg_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsAvgFields>;
  demiplane_user_recruitment_game_master_platforms_avg_order_by: DemiplaneUserRecruitmentGameMasterPlatformsAvgOrderBy;
  demiplane_user_recruitment_game_master_platforms_bool_exp: DemiplaneUserRecruitmentGameMasterPlatformsBoolExp;
  demiplane_user_recruitment_game_master_platforms_constraint: DemiplaneUserRecruitmentGameMasterPlatformsConstraint;
  demiplane_user_recruitment_game_master_platforms_inc_input: DemiplaneUserRecruitmentGameMasterPlatformsIncInput;
  demiplane_user_recruitment_game_master_platforms_insert_input: DemiplaneUserRecruitmentGameMasterPlatformsInsertInput;
  demiplane_user_recruitment_game_master_platforms_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsMaxFields>;
  demiplane_user_recruitment_game_master_platforms_max_order_by: DemiplaneUserRecruitmentGameMasterPlatformsMaxOrderBy;
  demiplane_user_recruitment_game_master_platforms_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsMinFields>;
  demiplane_user_recruitment_game_master_platforms_min_order_by: DemiplaneUserRecruitmentGameMasterPlatformsMinOrderBy;
  demiplane_user_recruitment_game_master_platforms_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsMutationResponse>;
  demiplane_user_recruitment_game_master_platforms_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterPlatformsObjRelInsertInput;
  demiplane_user_recruitment_game_master_platforms_on_conflict: DemiplaneUserRecruitmentGameMasterPlatformsOnConflict;
  demiplane_user_recruitment_game_master_platforms_order_by: DemiplaneUserRecruitmentGameMasterPlatformsOrderBy;
  demiplane_user_recruitment_game_master_platforms_pk_columns_input: DemiplaneUserRecruitmentGameMasterPlatformsPkColumnsInput;
  demiplane_user_recruitment_game_master_platforms_select_column: DemiplaneUserRecruitmentGameMasterPlatformsSelectColumn;
  demiplane_user_recruitment_game_master_platforms_set_input: DemiplaneUserRecruitmentGameMasterPlatformsSetInput;
  demiplane_user_recruitment_game_master_platforms_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsStddevFields>;
  demiplane_user_recruitment_game_master_platforms_stddev_order_by: DemiplaneUserRecruitmentGameMasterPlatformsStddevOrderBy;
  demiplane_user_recruitment_game_master_platforms_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsStddevPopFields>;
  demiplane_user_recruitment_game_master_platforms_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterPlatformsStddevPopOrderBy;
  demiplane_user_recruitment_game_master_platforms_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsStddevSampFields>;
  demiplane_user_recruitment_game_master_platforms_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterPlatformsStddevSampOrderBy;
  demiplane_user_recruitment_game_master_platforms_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsSumFields>;
  demiplane_user_recruitment_game_master_platforms_sum_order_by: DemiplaneUserRecruitmentGameMasterPlatformsSumOrderBy;
  demiplane_user_recruitment_game_master_platforms_update_column: DemiplaneUserRecruitmentGameMasterPlatformsUpdateColumn;
  demiplane_user_recruitment_game_master_platforms_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsVarPopFields>;
  demiplane_user_recruitment_game_master_platforms_var_pop_order_by: DemiplaneUserRecruitmentGameMasterPlatformsVarPopOrderBy;
  demiplane_user_recruitment_game_master_platforms_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsVarSampFields>;
  demiplane_user_recruitment_game_master_platforms_var_samp_order_by: DemiplaneUserRecruitmentGameMasterPlatformsVarSampOrderBy;
  demiplane_user_recruitment_game_master_platforms_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterPlatformsVarianceFields>;
  demiplane_user_recruitment_game_master_platforms_variance_order_by: DemiplaneUserRecruitmentGameMasterPlatformsVarianceOrderBy;
  demiplane_user_recruitment_game_master_select_column: DemiplaneUserRecruitmentGameMasterSelectColumn;
  demiplane_user_recruitment_game_master_set_input: DemiplaneUserRecruitmentGameMasterSetInput;
  demiplane_user_recruitment_game_master_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterStddevFields>;
  demiplane_user_recruitment_game_master_stddev_order_by: DemiplaneUserRecruitmentGameMasterStddevOrderBy;
  demiplane_user_recruitment_game_master_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterStddevPopFields>;
  demiplane_user_recruitment_game_master_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterStddevPopOrderBy;
  demiplane_user_recruitment_game_master_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterStddevSampFields>;
  demiplane_user_recruitment_game_master_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterStddevSampOrderBy;
  demiplane_user_recruitment_game_master_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterSumFields>;
  demiplane_user_recruitment_game_master_sum_order_by: DemiplaneUserRecruitmentGameMasterSumOrderBy;
  demiplane_user_recruitment_game_master_update_column: DemiplaneUserRecruitmentGameMasterUpdateColumn;
  demiplane_user_recruitment_game_master_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterVarPopFields>;
  demiplane_user_recruitment_game_master_var_pop_order_by: DemiplaneUserRecruitmentGameMasterVarPopOrderBy;
  demiplane_user_recruitment_game_master_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterVarSampFields>;
  demiplane_user_recruitment_game_master_var_samp_order_by: DemiplaneUserRecruitmentGameMasterVarSampOrderBy;
  demiplane_user_recruitment_game_master_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentGameMasterVarianceFields>;
  demiplane_user_recruitment_game_master_variance_order_by: DemiplaneUserRecruitmentGameMasterVarianceOrderBy;
  demiplane_user_recruitment_inc_input: DemiplaneUserRecruitmentIncInput;
  demiplane_user_recruitment_insert_input: DemiplaneUserRecruitmentInsertInput;
  demiplane_user_recruitment_max_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentMaxFields>;
  demiplane_user_recruitment_max_order_by: DemiplaneUserRecruitmentMaxOrderBy;
  demiplane_user_recruitment_min_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentMinFields>;
  demiplane_user_recruitment_min_order_by: DemiplaneUserRecruitmentMinOrderBy;
  demiplane_user_recruitment_mutation_response: ResolverTypeWrapper<DemiplaneUserRecruitmentMutationResponse>;
  demiplane_user_recruitment_obj_rel_insert_input: DemiplaneUserRecruitmentObjRelInsertInput;
  demiplane_user_recruitment_on_conflict: DemiplaneUserRecruitmentOnConflict;
  demiplane_user_recruitment_order_by: DemiplaneUserRecruitmentOrderBy;
  demiplane_user_recruitment_pk_columns_input: DemiplaneUserRecruitmentPkColumnsInput;
  demiplane_user_recruitment_select_column: DemiplaneUserRecruitmentSelectColumn;
  demiplane_user_recruitment_set_input: DemiplaneUserRecruitmentSetInput;
  demiplane_user_recruitment_stddev_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentStddevFields>;
  demiplane_user_recruitment_stddev_order_by: DemiplaneUserRecruitmentStddevOrderBy;
  demiplane_user_recruitment_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentStddevPopFields>;
  demiplane_user_recruitment_stddev_pop_order_by: DemiplaneUserRecruitmentStddevPopOrderBy;
  demiplane_user_recruitment_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentStddevSampFields>;
  demiplane_user_recruitment_stddev_samp_order_by: DemiplaneUserRecruitmentStddevSampOrderBy;
  demiplane_user_recruitment_sum_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentSumFields>;
  demiplane_user_recruitment_sum_order_by: DemiplaneUserRecruitmentSumOrderBy;
  demiplane_user_recruitment_update_column: DemiplaneUserRecruitmentUpdateColumn;
  demiplane_user_recruitment_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentVarPopFields>;
  demiplane_user_recruitment_var_pop_order_by: DemiplaneUserRecruitmentVarPopOrderBy;
  demiplane_user_recruitment_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentVarSampFields>;
  demiplane_user_recruitment_var_samp_order_by: DemiplaneUserRecruitmentVarSampOrderBy;
  demiplane_user_recruitment_variance_fields: ResolverTypeWrapper<DemiplaneUserRecruitmentVarianceFields>;
  demiplane_user_recruitment_variance_order_by: DemiplaneUserRecruitmentVarianceOrderBy;
  demiplane_user_role: ResolverTypeWrapper<DemiplaneUserRole>;
  demiplane_user_role_aggregate: ResolverTypeWrapper<DemiplaneUserRoleAggregate>;
  demiplane_user_role_aggregate_fields: ResolverTypeWrapper<DemiplaneUserRoleAggregateFields>;
  demiplane_user_role_aggregate_order_by: DemiplaneUserRoleAggregateOrderBy;
  demiplane_user_role_arr_rel_insert_input: DemiplaneUserRoleArrRelInsertInput;
  demiplane_user_role_avg_fields: ResolverTypeWrapper<DemiplaneUserRoleAvgFields>;
  demiplane_user_role_avg_order_by: DemiplaneUserRoleAvgOrderBy;
  demiplane_user_role_bool_exp: DemiplaneUserRoleBoolExp;
  demiplane_user_role_constraint: DemiplaneUserRoleConstraint;
  demiplane_user_role_inc_input: DemiplaneUserRoleIncInput;
  demiplane_user_role_insert_input: DemiplaneUserRoleInsertInput;
  demiplane_user_role_max_fields: ResolverTypeWrapper<DemiplaneUserRoleMaxFields>;
  demiplane_user_role_max_order_by: DemiplaneUserRoleMaxOrderBy;
  demiplane_user_role_min_fields: ResolverTypeWrapper<DemiplaneUserRoleMinFields>;
  demiplane_user_role_min_order_by: DemiplaneUserRoleMinOrderBy;
  demiplane_user_role_mutation_response: ResolverTypeWrapper<DemiplaneUserRoleMutationResponse>;
  demiplane_user_role_obj_rel_insert_input: DemiplaneUserRoleObjRelInsertInput;
  demiplane_user_role_on_conflict: DemiplaneUserRoleOnConflict;
  demiplane_user_role_order_by: DemiplaneUserRoleOrderBy;
  demiplane_user_role_pk_columns_input: DemiplaneUserRolePkColumnsInput;
  demiplane_user_role_select_column: DemiplaneUserRoleSelectColumn;
  demiplane_user_role_set_input: DemiplaneUserRoleSetInput;
  demiplane_user_role_stddev_fields: ResolverTypeWrapper<DemiplaneUserRoleStddevFields>;
  demiplane_user_role_stddev_order_by: DemiplaneUserRoleStddevOrderBy;
  demiplane_user_role_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserRoleStddevPopFields>;
  demiplane_user_role_stddev_pop_order_by: DemiplaneUserRoleStddevPopOrderBy;
  demiplane_user_role_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserRoleStddevSampFields>;
  demiplane_user_role_stddev_samp_order_by: DemiplaneUserRoleStddevSampOrderBy;
  demiplane_user_role_sum_fields: ResolverTypeWrapper<DemiplaneUserRoleSumFields>;
  demiplane_user_role_sum_order_by: DemiplaneUserRoleSumOrderBy;
  demiplane_user_role_update_column: DemiplaneUserRoleUpdateColumn;
  demiplane_user_role_var_pop_fields: ResolverTypeWrapper<DemiplaneUserRoleVarPopFields>;
  demiplane_user_role_var_pop_order_by: DemiplaneUserRoleVarPopOrderBy;
  demiplane_user_role_var_samp_fields: ResolverTypeWrapper<DemiplaneUserRoleVarSampFields>;
  demiplane_user_role_var_samp_order_by: DemiplaneUserRoleVarSampOrderBy;
  demiplane_user_role_variance_fields: ResolverTypeWrapper<DemiplaneUserRoleVarianceFields>;
  demiplane_user_role_variance_order_by: DemiplaneUserRoleVarianceOrderBy;
  demiplane_user_select_column: DemiplaneUserSelectColumn;
  demiplane_user_set_input: DemiplaneUserSetInput;
  demiplane_user_stddev_fields: ResolverTypeWrapper<DemiplaneUserStddevFields>;
  demiplane_user_stddev_order_by: DemiplaneUserStddevOrderBy;
  demiplane_user_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserStddevPopFields>;
  demiplane_user_stddev_pop_order_by: DemiplaneUserStddevPopOrderBy;
  demiplane_user_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserStddevSampFields>;
  demiplane_user_stddev_samp_order_by: DemiplaneUserStddevSampOrderBy;
  demiplane_user_sum_fields: ResolverTypeWrapper<DemiplaneUserSumFields>;
  demiplane_user_sum_order_by: DemiplaneUserSumOrderBy;
  demiplane_user_update_column: DemiplaneUserUpdateColumn;
  demiplane_user_var_pop_fields: ResolverTypeWrapper<DemiplaneUserVarPopFields>;
  demiplane_user_var_pop_order_by: DemiplaneUserVarPopOrderBy;
  demiplane_user_var_samp_fields: ResolverTypeWrapper<DemiplaneUserVarSampFields>;
  demiplane_user_var_samp_order_by: DemiplaneUserVarSampOrderBy;
  demiplane_user_variance_fields: ResolverTypeWrapper<DemiplaneUserVarianceFields>;
  demiplane_user_variance_order_by: DemiplaneUserVarianceOrderBy;
  demiplane_user_visibility: ResolverTypeWrapper<DemiplaneUserVisibility>;
  demiplane_user_visibility_aggregate: ResolverTypeWrapper<DemiplaneUserVisibilityAggregate>;
  demiplane_user_visibility_aggregate_fields: ResolverTypeWrapper<DemiplaneUserVisibilityAggregateFields>;
  demiplane_user_visibility_aggregate_order_by: DemiplaneUserVisibilityAggregateOrderBy;
  demiplane_user_visibility_arr_rel_insert_input: DemiplaneUserVisibilityArrRelInsertInput;
  demiplane_user_visibility_avg_fields: ResolverTypeWrapper<DemiplaneUserVisibilityAvgFields>;
  demiplane_user_visibility_avg_order_by: DemiplaneUserVisibilityAvgOrderBy;
  demiplane_user_visibility_bool_exp: DemiplaneUserVisibilityBoolExp;
  demiplane_user_visibility_constraint: DemiplaneUserVisibilityConstraint;
  demiplane_user_visibility_inc_input: DemiplaneUserVisibilityIncInput;
  demiplane_user_visibility_insert_input: DemiplaneUserVisibilityInsertInput;
  demiplane_user_visibility_max_fields: ResolverTypeWrapper<DemiplaneUserVisibilityMaxFields>;
  demiplane_user_visibility_max_order_by: DemiplaneUserVisibilityMaxOrderBy;
  demiplane_user_visibility_min_fields: ResolverTypeWrapper<DemiplaneUserVisibilityMinFields>;
  demiplane_user_visibility_min_order_by: DemiplaneUserVisibilityMinOrderBy;
  demiplane_user_visibility_mutation_response: ResolverTypeWrapper<DemiplaneUserVisibilityMutationResponse>;
  demiplane_user_visibility_obj_rel_insert_input: DemiplaneUserVisibilityObjRelInsertInput;
  demiplane_user_visibility_on_conflict: DemiplaneUserVisibilityOnConflict;
  demiplane_user_visibility_order_by: DemiplaneUserVisibilityOrderBy;
  demiplane_user_visibility_pk_columns_input: DemiplaneUserVisibilityPkColumnsInput;
  demiplane_user_visibility_select_column: DemiplaneUserVisibilitySelectColumn;
  demiplane_user_visibility_set_input: DemiplaneUserVisibilitySetInput;
  demiplane_user_visibility_stddev_fields: ResolverTypeWrapper<DemiplaneUserVisibilityStddevFields>;
  demiplane_user_visibility_stddev_order_by: DemiplaneUserVisibilityStddevOrderBy;
  demiplane_user_visibility_stddev_pop_fields: ResolverTypeWrapper<DemiplaneUserVisibilityStddevPopFields>;
  demiplane_user_visibility_stddev_pop_order_by: DemiplaneUserVisibilityStddevPopOrderBy;
  demiplane_user_visibility_stddev_samp_fields: ResolverTypeWrapper<DemiplaneUserVisibilityStddevSampFields>;
  demiplane_user_visibility_stddev_samp_order_by: DemiplaneUserVisibilityStddevSampOrderBy;
  demiplane_user_visibility_sum_fields: ResolverTypeWrapper<DemiplaneUserVisibilitySumFields>;
  demiplane_user_visibility_sum_order_by: DemiplaneUserVisibilitySumOrderBy;
  demiplane_user_visibility_update_column: DemiplaneUserVisibilityUpdateColumn;
  demiplane_user_visibility_var_pop_fields: ResolverTypeWrapper<DemiplaneUserVisibilityVarPopFields>;
  demiplane_user_visibility_var_pop_order_by: DemiplaneUserVisibilityVarPopOrderBy;
  demiplane_user_visibility_var_samp_fields: ResolverTypeWrapper<DemiplaneUserVisibilityVarSampFields>;
  demiplane_user_visibility_var_samp_order_by: DemiplaneUserVisibilityVarSampOrderBy;
  demiplane_user_visibility_variance_fields: ResolverTypeWrapper<DemiplaneUserVisibilityVarianceFields>;
  demiplane_user_visibility_variance_order_by: DemiplaneUserVisibilityVarianceOrderBy;
  json: ResolverTypeWrapper<Scalars['json']>;
  json_comparison_exp: JsonComparisonExp;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  jsonb_comparison_exp: JsonbComparisonExp;
  mutation_root: ResolverTypeWrapper<{}>;
  numeric: ResolverTypeWrapper<Scalars['numeric']>;
  numeric_comparison_exp: NumericComparisonExp;
  order_by: OrderBy;
  query_root: ResolverTypeWrapper<{}>;
  subscription_root: ResolverTypeWrapper<{}>;
  time: ResolverTypeWrapper<Scalars['time']>;
  time_comparison_exp: TimeComparisonExp;
  timestamp: ResolverTypeWrapper<Scalars['timestamp']>;
  timestamp_comparison_exp: TimestampComparisonExp;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  timestamptz_comparison_exp: TimestamptzComparisonExp;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AboutUserOutput: AboutUserOutput;
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  AdDetailOutput: AdDetailOutput;
  AdLinkType: AdLinkType;
  ID: Scalars['ID'];
  AdMatchCriteriaTimeType: AdMatchCriteriaTimeType;
  Float: Scalars['Float'];
  AdMatchCriteriaType: AdMatchCriteriaType;
  AdRequestScoredType: AdRequestScoredType;
  AdRequestType: AdRequestType;
  AdResultType: AdResultType;
  AdTimeHasuraType: AdTimeHasuraType;
  AdTimeInput: AdTimeInput;
  AdTimeType: AdTimeType;
  AdType: AdType;
  AddFavoriteGameOutput: AddFavoriteGameOutput;
  AddFavoritePlatformOutput: AddFavoritePlatformOutput;
  AddNewPortalV2Output: AddNewPortalV2Output;
  AdventureConnectionType: AdventureConnectionType;
  AdventureLinkType: AdventureLinkType;
  AdventureMutationType: AdventureMutationType;
  AdventureNotificationType: AdventureNotificationType;
  AdventurePlayerMutationType: AdventurePlayerMutationType;
  AdventurePlayerNotificationType: AdventurePlayerNotificationType;
  AdventurePlayerType: AdventurePlayerType;
  AdventureTodoType: AdventureTodoType;
  AdventureType: AdventureType;
  AdventuringPlatformType: AdventuringPlatformType;
  AllJournalsItemType: Omit<AllJournalsItemType, 'item'> & { item: ResolversParentTypes['ItemUnionType'] };
  AllJournalsJournalType: AllJournalsJournalType;
  AppVersionType: AppVersionType;
  AttachmentType: AttachmentType;
  AvailabilityInput: AvailabilityInput;
  AvailabilityInputMM: AvailabilityInputMm;
  Int: Scalars['Int'];
  AwardypeType: AwardypeType;
  Boolean_comparison_exp: BooleanComparisonExp;
  ChatTokenType: ChatTokenType;
  ConnectionStatusType: ConnectionStatusType;
  ConnectionTypeType: ConnectionTypeType;
  ContactTypeType: ContactTypeType;
  ConversationParticipantsType: ConversationParticipantsType;
  CountryType: CountryType;
  CreditCardInput: CreditCardInput;
  CreditCardType: CreditCardType;
  DateTime: Scalars['DateTime'];
  EarningsType: EarningsType;
  EjectGameMasterV2Output: EjectGameMasterV2Output;
  EndEpisodeOutput: EndEpisodeOutput;
  EndEpisodeV2Output: EndEpisodeV2Output;
  EpisoderAttendanceType: EpisoderAttendanceType;
  FavoriteDiceRollType: FavoriteDiceRollType;
  FeaturedGmRecommendationReviewsOutput: FeaturedGmRecommendationReviewsOutput;
  GameTypeType: GameTypeType;
  GmRatingType: GmRatingType;
  HandleParticipantLeavesWhisperOutput: HandleParticipantLeavesWhisperOutput;
  HowUserPlaysOutput: HowUserPlaysOutput;
  ImmediatelyOpenEpisodeV2Output: ImmediatelyOpenEpisodeV2Output;
  Int_comparison_exp: IntComparisonExp;
  InvitationType: InvitationType;
  ItemUnionType: ResolversParentTypes['JournalNoteType'] | ResolversParentTypes['JournalSuperEventType'];
  JoinAdventuresAdsOutput: JoinAdventuresAdsOutput;
  JoinAdventuresAdsResult: JoinAdventuresAdsResult;
  JoinSessionInput: JoinSessionInput;
  JournalNoteShareInput: JournalNoteShareInput;
  JournalNoteShareReturnType: JournalNoteShareReturnType;
  JournalNoteShareType: JournalNoteShareType;
  JournalNoteType: JournalNoteType;
  JournalNoteUpdateInput: JournalNoteUpdateInput;
  JournalSuperEventDeleteInput: JournalSuperEventDeleteInput;
  JournalSuperEventDeleteType: JournalSuperEventDeleteType;
  JournalSuperEventInput: JournalSuperEventInput;
  JournalSuperEventReadStatusType: JournalSuperEventReadStatusType;
  JournalSuperEventType: JournalSuperEventType;
  JournalSuperEventUpdateInput: JournalSuperEventUpdateInput;
  JournalTitleType: JournalTitleType;
  JournalType: JournalType;
  JournalUpdateInput: JournalUpdateInput;
  LeaveEpisodeV2Output: LeaveEpisodeV2Output;
  LeaveSessionInput: LeaveSessionInput;
  Mutation: Mutation;
  MyAdventuresType: MyAdventuresType;
  NotificationHistoryType: NotificationHistoryType;
  OpenEpisodeV2Output: OpenEpisodeV2Output;
  OtherAdventuresAdsOutput: OtherAdventuresAdsOutput;
  OtherAdventuresAdsResult: OtherAdventuresAdsResult;
  ParticipantLeavesWhisperOutput: ParticipantLeavesWhisperOutput;
  PaymentType: PaymentType;
  PlayerAwardType: PlayerAwardType;
  PlayerRatingInput: PlayerRatingInput;
  PlayerRatingType: PlayerRatingType;
  PromoteGameMasterV2Output: PromoteGameMasterV2Output;
  PronounType: PronounType;
  Query: Query;
  RecruitAdventurerOutput: RecruitAdventurerOutput;
  RecruitAdventurersOutput: RecruitAdventurersOutput;
  RecruitGameMasterOutput: RecruitGameMasterOutput;
  RecruitGameMastersOutput: RecruitGameMastersOutput;
  RecruitSummaryType: RecruitSummaryType;
  RecruitType: RecruitType;
  RegionType: RegionType;
  RemoveAdventurerV2Output: RemoveAdventurerV2Output;
  ReorderFavoriteGamesOutput: ReorderFavoriteGamesOutput;
  ReorderFavoritePlatformsOutput: ReorderFavoritePlatformsOutput;
  RoleType: RoleType;
  SearchAdDetailMMOutput: SearchAdDetailMmOutput;
  SearchAdResultDetailLeaderType: SearchAdResultDetailLeaderType;
  SearchAdResultDetailType: SearchAdResultDetailType;
  SearchAdResultType: SearchAdResultType;
  SearchAdsMMOutput: SearchAdsMmOutput;
  SearchAdsMMResult: SearchAdsMmResult;
  SessionCancelInput: SessionCancelInput;
  SessionGameplayNotificationInput: SessionGameplayNotificationInput;
  SessionHistoryTipType: SessionHistoryTipType;
  SessionHistoryType: SessionHistoryType;
  SessionImmediateOpenInput: SessionImmediateOpenInput;
  SessionMutationType: SessionMutationType;
  SessionNotificationType: SessionNotificationType;
  SessionPlayerActiveType: SessionPlayerActiveType;
  SessionPlayerMutationType: SessionPlayerMutationType;
  SessionPlayerNotificationType: SessionPlayerNotificationType;
  SessionPlayerType: SessionPlayerType;
  SessionRescheduleInput: SessionRescheduleInput;
  SessionScheduleInput: SessionScheduleInput;
  SessionStartInput: SessionStartInput;
  SessionType: SessionType;
  SnippetType: SnippetType;
  StartEpisodeV2Output: StartEpisodeV2Output;
  StatusType: StatusType;
  String_comparison_exp: StringComparisonExp;
  StripeBalanceTransactionType: StripeBalanceTransactionType;
  StripeBalanceType: StripeBalanceType;
  StripeLoginLinkType: StripeLoginLinkType;
  StripeSourceTypesType: StripeSourceTypesType;
  StripeUserBalanceType: StripeUserBalanceType;
  SubmitFileV2Output: SubmitFileV2Output;
  Subscription: Subscription;
  TConversation: TConversation;
  TaskCreateInput: TaskCreateInput;
  TaskReadStatusType: TaskReadStatusType;
  TaskType: TaskType;
  TipInput: TipInput;
  TransactionDetailOutput: TransactionDetailOutput;
  TransactionHistoryOutput: TransactionHistoryOutput;
  TriggerDisableWhisperV2Output: TriggerDisableWhisperV2Output;
  TriggerEnableWhisperV2Output: TriggerEnableWhisperV2Output;
  UpdateBillingAddressOutput: UpdateBillingAddressOutput;
  UpdateUploadV2Output: UpdateUploadV2Output;
  UpdateUserInformationV2Output: UpdateUserInformationV2Output;
  Upload: Scalars['Upload'];
  UploadType: UploadType;
  UserAddressInput: UserAddressInput;
  UserAddressType: UserAddressType;
  UserAdventureRatingsOutput: UserAdventureRatingsOutput;
  UserAdventureRoleType: UserAdventureRoleType;
  UserConversationsType: UserConversationsType;
  UserEmailNotificationPrefType: UserEmailNotificationPrefType;
  UserInformationInput: UserInformationInput;
  UserInput: UserInput;
  UserInvitationType: UserInvitationType;
  UserMetadataInput: UserMetadataInput;
  UserMetadataType: UserMetadataType;
  UserPlayTimeType: UserPlayTimeType;
  UserPreferenceInput: UserPreferenceInput;
  UserProfileType: UserProfileType;
  UserPushNotificationPrefType: UserPushNotificationPrefType;
  UserRatingsAndAwardsType: UserRatingsAndAwardsType;
  UserRecruitmentAvailabilityType: UserRecruitmentAvailabilityType;
  UserRecruitmentInput: UserRecruitmentInput;
  UserRecruitmentType: UserRecruitmentType;
  UserSocialProfilesOutput: UserSocialProfilesOutput;
  UserSocialProfilesResult: UserSocialProfilesResult;
  UserTreasureTroveOutput: UserTreasureTroveOutput;
  UserType: UserType;
  authMMOutput: AuthMmOutput;
  authMMTokenOutput: AuthMmTokenOutput;
  bigint: Scalars['bigint'];
  bigint_comparison_exp: BigintComparisonExp;
  date: Scalars['date'];
  date_comparison_exp: DateComparisonExp;
  demiplane_ad: DemiplaneAd;
  demiplane_ad_aggregate: DemiplaneAdAggregate;
  demiplane_ad_aggregate_fields: DemiplaneAdAggregateFields;
  demiplane_ad_aggregate_order_by: DemiplaneAdAggregateOrderBy;
  demiplane_ad_arr_rel_insert_input: DemiplaneAdArrRelInsertInput;
  demiplane_ad_avg_fields: DemiplaneAdAvgFields;
  demiplane_ad_avg_order_by: DemiplaneAdAvgOrderBy;
  demiplane_ad_bool_exp: DemiplaneAdBoolExp;
  demiplane_ad_inc_input: DemiplaneAdIncInput;
  demiplane_ad_insert_input: DemiplaneAdInsertInput;
  demiplane_ad_link: DemiplaneAdLink;
  demiplane_ad_link_aggregate: DemiplaneAdLinkAggregate;
  demiplane_ad_link_aggregate_fields: DemiplaneAdLinkAggregateFields;
  demiplane_ad_link_aggregate_order_by: DemiplaneAdLinkAggregateOrderBy;
  demiplane_ad_link_arr_rel_insert_input: DemiplaneAdLinkArrRelInsertInput;
  demiplane_ad_link_avg_fields: DemiplaneAdLinkAvgFields;
  demiplane_ad_link_avg_order_by: DemiplaneAdLinkAvgOrderBy;
  demiplane_ad_link_bool_exp: DemiplaneAdLinkBoolExp;
  demiplane_ad_link_inc_input: DemiplaneAdLinkIncInput;
  demiplane_ad_link_insert_input: DemiplaneAdLinkInsertInput;
  demiplane_ad_link_max_fields: DemiplaneAdLinkMaxFields;
  demiplane_ad_link_max_order_by: DemiplaneAdLinkMaxOrderBy;
  demiplane_ad_link_min_fields: DemiplaneAdLinkMinFields;
  demiplane_ad_link_min_order_by: DemiplaneAdLinkMinOrderBy;
  demiplane_ad_link_mutation_response: DemiplaneAdLinkMutationResponse;
  demiplane_ad_link_obj_rel_insert_input: DemiplaneAdLinkObjRelInsertInput;
  demiplane_ad_link_on_conflict: DemiplaneAdLinkOnConflict;
  demiplane_ad_link_order_by: DemiplaneAdLinkOrderBy;
  demiplane_ad_link_pk_columns_input: DemiplaneAdLinkPkColumnsInput;
  demiplane_ad_link_set_input: DemiplaneAdLinkSetInput;
  demiplane_ad_link_stddev_fields: DemiplaneAdLinkStddevFields;
  demiplane_ad_link_stddev_order_by: DemiplaneAdLinkStddevOrderBy;
  demiplane_ad_link_stddev_pop_fields: DemiplaneAdLinkStddevPopFields;
  demiplane_ad_link_stddev_pop_order_by: DemiplaneAdLinkStddevPopOrderBy;
  demiplane_ad_link_stddev_samp_fields: DemiplaneAdLinkStddevSampFields;
  demiplane_ad_link_stddev_samp_order_by: DemiplaneAdLinkStddevSampOrderBy;
  demiplane_ad_link_sum_fields: DemiplaneAdLinkSumFields;
  demiplane_ad_link_sum_order_by: DemiplaneAdLinkSumOrderBy;
  demiplane_ad_link_var_pop_fields: DemiplaneAdLinkVarPopFields;
  demiplane_ad_link_var_pop_order_by: DemiplaneAdLinkVarPopOrderBy;
  demiplane_ad_link_var_samp_fields: DemiplaneAdLinkVarSampFields;
  demiplane_ad_link_var_samp_order_by: DemiplaneAdLinkVarSampOrderBy;
  demiplane_ad_link_variance_fields: DemiplaneAdLinkVarianceFields;
  demiplane_ad_link_variance_order_by: DemiplaneAdLinkVarianceOrderBy;
  demiplane_ad_match_criteria: DemiplaneAdMatchCriteria;
  demiplane_ad_match_criteria_aggregate: DemiplaneAdMatchCriteriaAggregate;
  demiplane_ad_match_criteria_aggregate_fields: DemiplaneAdMatchCriteriaAggregateFields;
  demiplane_ad_match_criteria_aggregate_order_by: DemiplaneAdMatchCriteriaAggregateOrderBy;
  demiplane_ad_match_criteria_arr_rel_insert_input: DemiplaneAdMatchCriteriaArrRelInsertInput;
  demiplane_ad_match_criteria_avg_fields: DemiplaneAdMatchCriteriaAvgFields;
  demiplane_ad_match_criteria_avg_order_by: DemiplaneAdMatchCriteriaAvgOrderBy;
  demiplane_ad_match_criteria_bool_exp: DemiplaneAdMatchCriteriaBoolExp;
  demiplane_ad_match_criteria_inc_input: DemiplaneAdMatchCriteriaIncInput;
  demiplane_ad_match_criteria_insert_input: DemiplaneAdMatchCriteriaInsertInput;
  demiplane_ad_match_criteria_max_fields: DemiplaneAdMatchCriteriaMaxFields;
  demiplane_ad_match_criteria_max_order_by: DemiplaneAdMatchCriteriaMaxOrderBy;
  demiplane_ad_match_criteria_min_fields: DemiplaneAdMatchCriteriaMinFields;
  demiplane_ad_match_criteria_min_order_by: DemiplaneAdMatchCriteriaMinOrderBy;
  demiplane_ad_match_criteria_mutation_response: DemiplaneAdMatchCriteriaMutationResponse;
  demiplane_ad_match_criteria_obj_rel_insert_input: DemiplaneAdMatchCriteriaObjRelInsertInput;
  demiplane_ad_match_criteria_on_conflict: DemiplaneAdMatchCriteriaOnConflict;
  demiplane_ad_match_criteria_order_by: DemiplaneAdMatchCriteriaOrderBy;
  demiplane_ad_match_criteria_pk_columns_input: DemiplaneAdMatchCriteriaPkColumnsInput;
  demiplane_ad_match_criteria_set_input: DemiplaneAdMatchCriteriaSetInput;
  demiplane_ad_match_criteria_stddev_fields: DemiplaneAdMatchCriteriaStddevFields;
  demiplane_ad_match_criteria_stddev_order_by: DemiplaneAdMatchCriteriaStddevOrderBy;
  demiplane_ad_match_criteria_stddev_pop_fields: DemiplaneAdMatchCriteriaStddevPopFields;
  demiplane_ad_match_criteria_stddev_pop_order_by: DemiplaneAdMatchCriteriaStddevPopOrderBy;
  demiplane_ad_match_criteria_stddev_samp_fields: DemiplaneAdMatchCriteriaStddevSampFields;
  demiplane_ad_match_criteria_stddev_samp_order_by: DemiplaneAdMatchCriteriaStddevSampOrderBy;
  demiplane_ad_match_criteria_sum_fields: DemiplaneAdMatchCriteriaSumFields;
  demiplane_ad_match_criteria_sum_order_by: DemiplaneAdMatchCriteriaSumOrderBy;
  demiplane_ad_match_criteria_time: DemiplaneAdMatchCriteriaTime;
  demiplane_ad_match_criteria_time_aggregate: DemiplaneAdMatchCriteriaTimeAggregate;
  demiplane_ad_match_criteria_time_aggregate_fields: DemiplaneAdMatchCriteriaTimeAggregateFields;
  demiplane_ad_match_criteria_time_aggregate_order_by: DemiplaneAdMatchCriteriaTimeAggregateOrderBy;
  demiplane_ad_match_criteria_time_arr_rel_insert_input: DemiplaneAdMatchCriteriaTimeArrRelInsertInput;
  demiplane_ad_match_criteria_time_avg_fields: DemiplaneAdMatchCriteriaTimeAvgFields;
  demiplane_ad_match_criteria_time_avg_order_by: DemiplaneAdMatchCriteriaTimeAvgOrderBy;
  demiplane_ad_match_criteria_time_bool_exp: DemiplaneAdMatchCriteriaTimeBoolExp;
  demiplane_ad_match_criteria_time_inc_input: DemiplaneAdMatchCriteriaTimeIncInput;
  demiplane_ad_match_criteria_time_insert_input: DemiplaneAdMatchCriteriaTimeInsertInput;
  demiplane_ad_match_criteria_time_max_fields: DemiplaneAdMatchCriteriaTimeMaxFields;
  demiplane_ad_match_criteria_time_max_order_by: DemiplaneAdMatchCriteriaTimeMaxOrderBy;
  demiplane_ad_match_criteria_time_min_fields: DemiplaneAdMatchCriteriaTimeMinFields;
  demiplane_ad_match_criteria_time_min_order_by: DemiplaneAdMatchCriteriaTimeMinOrderBy;
  demiplane_ad_match_criteria_time_mutation_response: DemiplaneAdMatchCriteriaTimeMutationResponse;
  demiplane_ad_match_criteria_time_obj_rel_insert_input: DemiplaneAdMatchCriteriaTimeObjRelInsertInput;
  demiplane_ad_match_criteria_time_on_conflict: DemiplaneAdMatchCriteriaTimeOnConflict;
  demiplane_ad_match_criteria_time_order_by: DemiplaneAdMatchCriteriaTimeOrderBy;
  demiplane_ad_match_criteria_time_pk_columns_input: DemiplaneAdMatchCriteriaTimePkColumnsInput;
  demiplane_ad_match_criteria_time_set_input: DemiplaneAdMatchCriteriaTimeSetInput;
  demiplane_ad_match_criteria_time_stddev_fields: DemiplaneAdMatchCriteriaTimeStddevFields;
  demiplane_ad_match_criteria_time_stddev_order_by: DemiplaneAdMatchCriteriaTimeStddevOrderBy;
  demiplane_ad_match_criteria_time_stddev_pop_fields: DemiplaneAdMatchCriteriaTimeStddevPopFields;
  demiplane_ad_match_criteria_time_stddev_pop_order_by: DemiplaneAdMatchCriteriaTimeStddevPopOrderBy;
  demiplane_ad_match_criteria_time_stddev_samp_fields: DemiplaneAdMatchCriteriaTimeStddevSampFields;
  demiplane_ad_match_criteria_time_stddev_samp_order_by: DemiplaneAdMatchCriteriaTimeStddevSampOrderBy;
  demiplane_ad_match_criteria_time_sum_fields: DemiplaneAdMatchCriteriaTimeSumFields;
  demiplane_ad_match_criteria_time_sum_order_by: DemiplaneAdMatchCriteriaTimeSumOrderBy;
  demiplane_ad_match_criteria_time_var_pop_fields: DemiplaneAdMatchCriteriaTimeVarPopFields;
  demiplane_ad_match_criteria_time_var_pop_order_by: DemiplaneAdMatchCriteriaTimeVarPopOrderBy;
  demiplane_ad_match_criteria_time_var_samp_fields: DemiplaneAdMatchCriteriaTimeVarSampFields;
  demiplane_ad_match_criteria_time_var_samp_order_by: DemiplaneAdMatchCriteriaTimeVarSampOrderBy;
  demiplane_ad_match_criteria_time_variance_fields: DemiplaneAdMatchCriteriaTimeVarianceFields;
  demiplane_ad_match_criteria_time_variance_order_by: DemiplaneAdMatchCriteriaTimeVarianceOrderBy;
  demiplane_ad_match_criteria_var_pop_fields: DemiplaneAdMatchCriteriaVarPopFields;
  demiplane_ad_match_criteria_var_pop_order_by: DemiplaneAdMatchCriteriaVarPopOrderBy;
  demiplane_ad_match_criteria_var_samp_fields: DemiplaneAdMatchCriteriaVarSampFields;
  demiplane_ad_match_criteria_var_samp_order_by: DemiplaneAdMatchCriteriaVarSampOrderBy;
  demiplane_ad_match_criteria_variance_fields: DemiplaneAdMatchCriteriaVarianceFields;
  demiplane_ad_match_criteria_variance_order_by: DemiplaneAdMatchCriteriaVarianceOrderBy;
  demiplane_ad_max_fields: DemiplaneAdMaxFields;
  demiplane_ad_max_order_by: DemiplaneAdMaxOrderBy;
  demiplane_ad_min_fields: DemiplaneAdMinFields;
  demiplane_ad_min_order_by: DemiplaneAdMinOrderBy;
  demiplane_ad_mutation_response: DemiplaneAdMutationResponse;
  demiplane_ad_obj_rel_insert_input: DemiplaneAdObjRelInsertInput;
  demiplane_ad_on_conflict: DemiplaneAdOnConflict;
  demiplane_ad_order_by: DemiplaneAdOrderBy;
  demiplane_ad_pk_columns_input: DemiplaneAdPkColumnsInput;
  demiplane_ad_request: DemiplaneAdRequest;
  demiplane_ad_request_aggregate: DemiplaneAdRequestAggregate;
  demiplane_ad_request_aggregate_fields: DemiplaneAdRequestAggregateFields;
  demiplane_ad_request_aggregate_order_by: DemiplaneAdRequestAggregateOrderBy;
  demiplane_ad_request_arr_rel_insert_input: DemiplaneAdRequestArrRelInsertInput;
  demiplane_ad_request_avg_fields: DemiplaneAdRequestAvgFields;
  demiplane_ad_request_avg_order_by: DemiplaneAdRequestAvgOrderBy;
  demiplane_ad_request_bool_exp: DemiplaneAdRequestBoolExp;
  demiplane_ad_request_inc_input: DemiplaneAdRequestIncInput;
  demiplane_ad_request_insert_input: DemiplaneAdRequestInsertInput;
  demiplane_ad_request_max_fields: DemiplaneAdRequestMaxFields;
  demiplane_ad_request_max_order_by: DemiplaneAdRequestMaxOrderBy;
  demiplane_ad_request_min_fields: DemiplaneAdRequestMinFields;
  demiplane_ad_request_min_order_by: DemiplaneAdRequestMinOrderBy;
  demiplane_ad_request_mutation_response: DemiplaneAdRequestMutationResponse;
  demiplane_ad_request_obj_rel_insert_input: DemiplaneAdRequestObjRelInsertInput;
  demiplane_ad_request_on_conflict: DemiplaneAdRequestOnConflict;
  demiplane_ad_request_order_by: DemiplaneAdRequestOrderBy;
  demiplane_ad_request_pk_columns_input: DemiplaneAdRequestPkColumnsInput;
  demiplane_ad_request_set_input: DemiplaneAdRequestSetInput;
  demiplane_ad_request_stddev_fields: DemiplaneAdRequestStddevFields;
  demiplane_ad_request_stddev_order_by: DemiplaneAdRequestStddevOrderBy;
  demiplane_ad_request_stddev_pop_fields: DemiplaneAdRequestStddevPopFields;
  demiplane_ad_request_stddev_pop_order_by: DemiplaneAdRequestStddevPopOrderBy;
  demiplane_ad_request_stddev_samp_fields: DemiplaneAdRequestStddevSampFields;
  demiplane_ad_request_stddev_samp_order_by: DemiplaneAdRequestStddevSampOrderBy;
  demiplane_ad_request_sum_fields: DemiplaneAdRequestSumFields;
  demiplane_ad_request_sum_order_by: DemiplaneAdRequestSumOrderBy;
  demiplane_ad_request_var_pop_fields: DemiplaneAdRequestVarPopFields;
  demiplane_ad_request_var_pop_order_by: DemiplaneAdRequestVarPopOrderBy;
  demiplane_ad_request_var_samp_fields: DemiplaneAdRequestVarSampFields;
  demiplane_ad_request_var_samp_order_by: DemiplaneAdRequestVarSampOrderBy;
  demiplane_ad_request_variance_fields: DemiplaneAdRequestVarianceFields;
  demiplane_ad_request_variance_order_by: DemiplaneAdRequestVarianceOrderBy;
  demiplane_ad_set_input: DemiplaneAdSetInput;
  demiplane_ad_stddev_fields: DemiplaneAdStddevFields;
  demiplane_ad_stddev_order_by: DemiplaneAdStddevOrderBy;
  demiplane_ad_stddev_pop_fields: DemiplaneAdStddevPopFields;
  demiplane_ad_stddev_pop_order_by: DemiplaneAdStddevPopOrderBy;
  demiplane_ad_stddev_samp_fields: DemiplaneAdStddevSampFields;
  demiplane_ad_stddev_samp_order_by: DemiplaneAdStddevSampOrderBy;
  demiplane_ad_sum_fields: DemiplaneAdSumFields;
  demiplane_ad_sum_order_by: DemiplaneAdSumOrderBy;
  demiplane_ad_time: DemiplaneAdTime;
  demiplane_ad_time_aggregate: DemiplaneAdTimeAggregate;
  demiplane_ad_time_aggregate_fields: DemiplaneAdTimeAggregateFields;
  demiplane_ad_time_aggregate_order_by: DemiplaneAdTimeAggregateOrderBy;
  demiplane_ad_time_arr_rel_insert_input: DemiplaneAdTimeArrRelInsertInput;
  demiplane_ad_time_avg_fields: DemiplaneAdTimeAvgFields;
  demiplane_ad_time_avg_order_by: DemiplaneAdTimeAvgOrderBy;
  demiplane_ad_time_bool_exp: DemiplaneAdTimeBoolExp;
  demiplane_ad_time_inc_input: DemiplaneAdTimeIncInput;
  demiplane_ad_time_insert_input: DemiplaneAdTimeInsertInput;
  demiplane_ad_time_max_fields: DemiplaneAdTimeMaxFields;
  demiplane_ad_time_max_order_by: DemiplaneAdTimeMaxOrderBy;
  demiplane_ad_time_min_fields: DemiplaneAdTimeMinFields;
  demiplane_ad_time_min_order_by: DemiplaneAdTimeMinOrderBy;
  demiplane_ad_time_mutation_response: DemiplaneAdTimeMutationResponse;
  demiplane_ad_time_obj_rel_insert_input: DemiplaneAdTimeObjRelInsertInput;
  demiplane_ad_time_on_conflict: DemiplaneAdTimeOnConflict;
  demiplane_ad_time_order_by: DemiplaneAdTimeOrderBy;
  demiplane_ad_time_pk_columns_input: DemiplaneAdTimePkColumnsInput;
  demiplane_ad_time_set_input: DemiplaneAdTimeSetInput;
  demiplane_ad_time_stddev_fields: DemiplaneAdTimeStddevFields;
  demiplane_ad_time_stddev_order_by: DemiplaneAdTimeStddevOrderBy;
  demiplane_ad_time_stddev_pop_fields: DemiplaneAdTimeStddevPopFields;
  demiplane_ad_time_stddev_pop_order_by: DemiplaneAdTimeStddevPopOrderBy;
  demiplane_ad_time_stddev_samp_fields: DemiplaneAdTimeStddevSampFields;
  demiplane_ad_time_stddev_samp_order_by: DemiplaneAdTimeStddevSampOrderBy;
  demiplane_ad_time_sum_fields: DemiplaneAdTimeSumFields;
  demiplane_ad_time_sum_order_by: DemiplaneAdTimeSumOrderBy;
  demiplane_ad_time_var_pop_fields: DemiplaneAdTimeVarPopFields;
  demiplane_ad_time_var_pop_order_by: DemiplaneAdTimeVarPopOrderBy;
  demiplane_ad_time_var_samp_fields: DemiplaneAdTimeVarSampFields;
  demiplane_ad_time_var_samp_order_by: DemiplaneAdTimeVarSampOrderBy;
  demiplane_ad_time_variance_fields: DemiplaneAdTimeVarianceFields;
  demiplane_ad_time_variance_order_by: DemiplaneAdTimeVarianceOrderBy;
  demiplane_ad_var_pop_fields: DemiplaneAdVarPopFields;
  demiplane_ad_var_pop_order_by: DemiplaneAdVarPopOrderBy;
  demiplane_ad_var_samp_fields: DemiplaneAdVarSampFields;
  demiplane_ad_var_samp_order_by: DemiplaneAdVarSampOrderBy;
  demiplane_ad_variance_fields: DemiplaneAdVarianceFields;
  demiplane_ad_variance_order_by: DemiplaneAdVarianceOrderBy;
  demiplane_adventure: DemiplaneAdventure;
  demiplane_adventure_aggregate: DemiplaneAdventureAggregate;
  demiplane_adventure_aggregate_fields: DemiplaneAdventureAggregateFields;
  demiplane_adventure_aggregate_order_by: DemiplaneAdventureAggregateOrderBy;
  demiplane_adventure_arr_rel_insert_input: DemiplaneAdventureArrRelInsertInput;
  demiplane_adventure_avg_fields: DemiplaneAdventureAvgFields;
  demiplane_adventure_avg_order_by: DemiplaneAdventureAvgOrderBy;
  demiplane_adventure_bool_exp: DemiplaneAdventureBoolExp;
  demiplane_adventure_connection: DemiplaneAdventureConnection;
  demiplane_adventure_connection_aggregate: DemiplaneAdventureConnectionAggregate;
  demiplane_adventure_connection_aggregate_fields: DemiplaneAdventureConnectionAggregateFields;
  demiplane_adventure_connection_aggregate_order_by: DemiplaneAdventureConnectionAggregateOrderBy;
  demiplane_adventure_connection_arr_rel_insert_input: DemiplaneAdventureConnectionArrRelInsertInput;
  demiplane_adventure_connection_avg_fields: DemiplaneAdventureConnectionAvgFields;
  demiplane_adventure_connection_avg_order_by: DemiplaneAdventureConnectionAvgOrderBy;
  demiplane_adventure_connection_bool_exp: DemiplaneAdventureConnectionBoolExp;
  demiplane_adventure_connection_inc_input: DemiplaneAdventureConnectionIncInput;
  demiplane_adventure_connection_insert_input: DemiplaneAdventureConnectionInsertInput;
  demiplane_adventure_connection_max_fields: DemiplaneAdventureConnectionMaxFields;
  demiplane_adventure_connection_max_order_by: DemiplaneAdventureConnectionMaxOrderBy;
  demiplane_adventure_connection_min_fields: DemiplaneAdventureConnectionMinFields;
  demiplane_adventure_connection_min_order_by: DemiplaneAdventureConnectionMinOrderBy;
  demiplane_adventure_connection_mutation_response: DemiplaneAdventureConnectionMutationResponse;
  demiplane_adventure_connection_obj_rel_insert_input: DemiplaneAdventureConnectionObjRelInsertInput;
  demiplane_adventure_connection_on_conflict: DemiplaneAdventureConnectionOnConflict;
  demiplane_adventure_connection_order_by: DemiplaneAdventureConnectionOrderBy;
  demiplane_adventure_connection_pk_columns_input: DemiplaneAdventureConnectionPkColumnsInput;
  demiplane_adventure_connection_set_input: DemiplaneAdventureConnectionSetInput;
  demiplane_adventure_connection_stddev_fields: DemiplaneAdventureConnectionStddevFields;
  demiplane_adventure_connection_stddev_order_by: DemiplaneAdventureConnectionStddevOrderBy;
  demiplane_adventure_connection_stddev_pop_fields: DemiplaneAdventureConnectionStddevPopFields;
  demiplane_adventure_connection_stddev_pop_order_by: DemiplaneAdventureConnectionStddevPopOrderBy;
  demiplane_adventure_connection_stddev_samp_fields: DemiplaneAdventureConnectionStddevSampFields;
  demiplane_adventure_connection_stddev_samp_order_by: DemiplaneAdventureConnectionStddevSampOrderBy;
  demiplane_adventure_connection_sum_fields: DemiplaneAdventureConnectionSumFields;
  demiplane_adventure_connection_sum_order_by: DemiplaneAdventureConnectionSumOrderBy;
  demiplane_adventure_connection_var_pop_fields: DemiplaneAdventureConnectionVarPopFields;
  demiplane_adventure_connection_var_pop_order_by: DemiplaneAdventureConnectionVarPopOrderBy;
  demiplane_adventure_connection_var_samp_fields: DemiplaneAdventureConnectionVarSampFields;
  demiplane_adventure_connection_var_samp_order_by: DemiplaneAdventureConnectionVarSampOrderBy;
  demiplane_adventure_connection_variance_fields: DemiplaneAdventureConnectionVarianceFields;
  demiplane_adventure_connection_variance_order_by: DemiplaneAdventureConnectionVarianceOrderBy;
  demiplane_adventure_demiplane_tool: DemiplaneAdventureDemiplaneTool;
  demiplane_adventure_demiplane_tool_aggregate: DemiplaneAdventureDemiplaneToolAggregate;
  demiplane_adventure_demiplane_tool_aggregate_fields: DemiplaneAdventureDemiplaneToolAggregateFields;
  demiplane_adventure_demiplane_tool_aggregate_order_by: DemiplaneAdventureDemiplaneToolAggregateOrderBy;
  demiplane_adventure_demiplane_tool_arr_rel_insert_input: DemiplaneAdventureDemiplaneToolArrRelInsertInput;
  demiplane_adventure_demiplane_tool_avg_fields: DemiplaneAdventureDemiplaneToolAvgFields;
  demiplane_adventure_demiplane_tool_avg_order_by: DemiplaneAdventureDemiplaneToolAvgOrderBy;
  demiplane_adventure_demiplane_tool_bool_exp: DemiplaneAdventureDemiplaneToolBoolExp;
  demiplane_adventure_demiplane_tool_inc_input: DemiplaneAdventureDemiplaneToolIncInput;
  demiplane_adventure_demiplane_tool_insert_input: DemiplaneAdventureDemiplaneToolInsertInput;
  demiplane_adventure_demiplane_tool_max_fields: DemiplaneAdventureDemiplaneToolMaxFields;
  demiplane_adventure_demiplane_tool_max_order_by: DemiplaneAdventureDemiplaneToolMaxOrderBy;
  demiplane_adventure_demiplane_tool_min_fields: DemiplaneAdventureDemiplaneToolMinFields;
  demiplane_adventure_demiplane_tool_min_order_by: DemiplaneAdventureDemiplaneToolMinOrderBy;
  demiplane_adventure_demiplane_tool_mutation_response: DemiplaneAdventureDemiplaneToolMutationResponse;
  demiplane_adventure_demiplane_tool_obj_rel_insert_input: DemiplaneAdventureDemiplaneToolObjRelInsertInput;
  demiplane_adventure_demiplane_tool_on_conflict: DemiplaneAdventureDemiplaneToolOnConflict;
  demiplane_adventure_demiplane_tool_order_by: DemiplaneAdventureDemiplaneToolOrderBy;
  demiplane_adventure_demiplane_tool_pk_columns_input: DemiplaneAdventureDemiplaneToolPkColumnsInput;
  demiplane_adventure_demiplane_tool_set_input: DemiplaneAdventureDemiplaneToolSetInput;
  demiplane_adventure_demiplane_tool_stddev_fields: DemiplaneAdventureDemiplaneToolStddevFields;
  demiplane_adventure_demiplane_tool_stddev_order_by: DemiplaneAdventureDemiplaneToolStddevOrderBy;
  demiplane_adventure_demiplane_tool_stddev_pop_fields: DemiplaneAdventureDemiplaneToolStddevPopFields;
  demiplane_adventure_demiplane_tool_stddev_pop_order_by: DemiplaneAdventureDemiplaneToolStddevPopOrderBy;
  demiplane_adventure_demiplane_tool_stddev_samp_fields: DemiplaneAdventureDemiplaneToolStddevSampFields;
  demiplane_adventure_demiplane_tool_stddev_samp_order_by: DemiplaneAdventureDemiplaneToolStddevSampOrderBy;
  demiplane_adventure_demiplane_tool_sum_fields: DemiplaneAdventureDemiplaneToolSumFields;
  demiplane_adventure_demiplane_tool_sum_order_by: DemiplaneAdventureDemiplaneToolSumOrderBy;
  demiplane_adventure_demiplane_tool_var_pop_fields: DemiplaneAdventureDemiplaneToolVarPopFields;
  demiplane_adventure_demiplane_tool_var_pop_order_by: DemiplaneAdventureDemiplaneToolVarPopOrderBy;
  demiplane_adventure_demiplane_tool_var_samp_fields: DemiplaneAdventureDemiplaneToolVarSampFields;
  demiplane_adventure_demiplane_tool_var_samp_order_by: DemiplaneAdventureDemiplaneToolVarSampOrderBy;
  demiplane_adventure_demiplane_tool_variance_fields: DemiplaneAdventureDemiplaneToolVarianceFields;
  demiplane_adventure_demiplane_tool_variance_order_by: DemiplaneAdventureDemiplaneToolVarianceOrderBy;
  demiplane_adventure_inc_input: DemiplaneAdventureIncInput;
  demiplane_adventure_insert_input: DemiplaneAdventureInsertInput;
  demiplane_adventure_link: DemiplaneAdventureLink;
  demiplane_adventure_link_aggregate: DemiplaneAdventureLinkAggregate;
  demiplane_adventure_link_aggregate_fields: DemiplaneAdventureLinkAggregateFields;
  demiplane_adventure_link_aggregate_order_by: DemiplaneAdventureLinkAggregateOrderBy;
  demiplane_adventure_link_arr_rel_insert_input: DemiplaneAdventureLinkArrRelInsertInput;
  demiplane_adventure_link_avg_fields: DemiplaneAdventureLinkAvgFields;
  demiplane_adventure_link_avg_order_by: DemiplaneAdventureLinkAvgOrderBy;
  demiplane_adventure_link_bool_exp: DemiplaneAdventureLinkBoolExp;
  demiplane_adventure_link_inc_input: DemiplaneAdventureLinkIncInput;
  demiplane_adventure_link_insert_input: DemiplaneAdventureLinkInsertInput;
  demiplane_adventure_link_max_fields: DemiplaneAdventureLinkMaxFields;
  demiplane_adventure_link_max_order_by: DemiplaneAdventureLinkMaxOrderBy;
  demiplane_adventure_link_min_fields: DemiplaneAdventureLinkMinFields;
  demiplane_adventure_link_min_order_by: DemiplaneAdventureLinkMinOrderBy;
  demiplane_adventure_link_mutation_response: DemiplaneAdventureLinkMutationResponse;
  demiplane_adventure_link_obj_rel_insert_input: DemiplaneAdventureLinkObjRelInsertInput;
  demiplane_adventure_link_on_conflict: DemiplaneAdventureLinkOnConflict;
  demiplane_adventure_link_order_by: DemiplaneAdventureLinkOrderBy;
  demiplane_adventure_link_pk_columns_input: DemiplaneAdventureLinkPkColumnsInput;
  demiplane_adventure_link_set_input: DemiplaneAdventureLinkSetInput;
  demiplane_adventure_link_stddev_fields: DemiplaneAdventureLinkStddevFields;
  demiplane_adventure_link_stddev_order_by: DemiplaneAdventureLinkStddevOrderBy;
  demiplane_adventure_link_stddev_pop_fields: DemiplaneAdventureLinkStddevPopFields;
  demiplane_adventure_link_stddev_pop_order_by: DemiplaneAdventureLinkStddevPopOrderBy;
  demiplane_adventure_link_stddev_samp_fields: DemiplaneAdventureLinkStddevSampFields;
  demiplane_adventure_link_stddev_samp_order_by: DemiplaneAdventureLinkStddevSampOrderBy;
  demiplane_adventure_link_sum_fields: DemiplaneAdventureLinkSumFields;
  demiplane_adventure_link_sum_order_by: DemiplaneAdventureLinkSumOrderBy;
  demiplane_adventure_link_var_pop_fields: DemiplaneAdventureLinkVarPopFields;
  demiplane_adventure_link_var_pop_order_by: DemiplaneAdventureLinkVarPopOrderBy;
  demiplane_adventure_link_var_samp_fields: DemiplaneAdventureLinkVarSampFields;
  demiplane_adventure_link_var_samp_order_by: DemiplaneAdventureLinkVarSampOrderBy;
  demiplane_adventure_link_variance_fields: DemiplaneAdventureLinkVarianceFields;
  demiplane_adventure_link_variance_order_by: DemiplaneAdventureLinkVarianceOrderBy;
  demiplane_adventure_max_fields: DemiplaneAdventureMaxFields;
  demiplane_adventure_max_order_by: DemiplaneAdventureMaxOrderBy;
  demiplane_adventure_min_fields: DemiplaneAdventureMinFields;
  demiplane_adventure_min_order_by: DemiplaneAdventureMinOrderBy;
  demiplane_adventure_mutation_response: DemiplaneAdventureMutationResponse;
  demiplane_adventure_obj_rel_insert_input: DemiplaneAdventureObjRelInsertInput;
  demiplane_adventure_on_conflict: DemiplaneAdventureOnConflict;
  demiplane_adventure_order_by: DemiplaneAdventureOrderBy;
  demiplane_adventure_pk_columns_input: DemiplaneAdventurePkColumnsInput;
  demiplane_adventure_player: DemiplaneAdventurePlayer;
  demiplane_adventure_player_aggregate: DemiplaneAdventurePlayerAggregate;
  demiplane_adventure_player_aggregate_fields: DemiplaneAdventurePlayerAggregateFields;
  demiplane_adventure_player_aggregate_order_by: DemiplaneAdventurePlayerAggregateOrderBy;
  demiplane_adventure_player_arr_rel_insert_input: DemiplaneAdventurePlayerArrRelInsertInput;
  demiplane_adventure_player_avg_fields: DemiplaneAdventurePlayerAvgFields;
  demiplane_adventure_player_avg_order_by: DemiplaneAdventurePlayerAvgOrderBy;
  demiplane_adventure_player_bool_exp: DemiplaneAdventurePlayerBoolExp;
  demiplane_adventure_player_inc_input: DemiplaneAdventurePlayerIncInput;
  demiplane_adventure_player_insert_input: DemiplaneAdventurePlayerInsertInput;
  demiplane_adventure_player_max_fields: DemiplaneAdventurePlayerMaxFields;
  demiplane_adventure_player_max_order_by: DemiplaneAdventurePlayerMaxOrderBy;
  demiplane_adventure_player_min_fields: DemiplaneAdventurePlayerMinFields;
  demiplane_adventure_player_min_order_by: DemiplaneAdventurePlayerMinOrderBy;
  demiplane_adventure_player_mutation_response: DemiplaneAdventurePlayerMutationResponse;
  demiplane_adventure_player_obj_rel_insert_input: DemiplaneAdventurePlayerObjRelInsertInput;
  demiplane_adventure_player_on_conflict: DemiplaneAdventurePlayerOnConflict;
  demiplane_adventure_player_order_by: DemiplaneAdventurePlayerOrderBy;
  demiplane_adventure_player_pk_columns_input: DemiplaneAdventurePlayerPkColumnsInput;
  demiplane_adventure_player_set_input: DemiplaneAdventurePlayerSetInput;
  demiplane_adventure_player_stddev_fields: DemiplaneAdventurePlayerStddevFields;
  demiplane_adventure_player_stddev_order_by: DemiplaneAdventurePlayerStddevOrderBy;
  demiplane_adventure_player_stddev_pop_fields: DemiplaneAdventurePlayerStddevPopFields;
  demiplane_adventure_player_stddev_pop_order_by: DemiplaneAdventurePlayerStddevPopOrderBy;
  demiplane_adventure_player_stddev_samp_fields: DemiplaneAdventurePlayerStddevSampFields;
  demiplane_adventure_player_stddev_samp_order_by: DemiplaneAdventurePlayerStddevSampOrderBy;
  demiplane_adventure_player_sum_fields: DemiplaneAdventurePlayerSumFields;
  demiplane_adventure_player_sum_order_by: DemiplaneAdventurePlayerSumOrderBy;
  demiplane_adventure_player_var_pop_fields: DemiplaneAdventurePlayerVarPopFields;
  demiplane_adventure_player_var_pop_order_by: DemiplaneAdventurePlayerVarPopOrderBy;
  demiplane_adventure_player_var_samp_fields: DemiplaneAdventurePlayerVarSampFields;
  demiplane_adventure_player_var_samp_order_by: DemiplaneAdventurePlayerVarSampOrderBy;
  demiplane_adventure_player_variance_fields: DemiplaneAdventurePlayerVarianceFields;
  demiplane_adventure_player_variance_order_by: DemiplaneAdventurePlayerVarianceOrderBy;
  demiplane_adventure_set_input: DemiplaneAdventureSetInput;
  demiplane_adventure_stddev_fields: DemiplaneAdventureStddevFields;
  demiplane_adventure_stddev_order_by: DemiplaneAdventureStddevOrderBy;
  demiplane_adventure_stddev_pop_fields: DemiplaneAdventureStddevPopFields;
  demiplane_adventure_stddev_pop_order_by: DemiplaneAdventureStddevPopOrderBy;
  demiplane_adventure_stddev_samp_fields: DemiplaneAdventureStddevSampFields;
  demiplane_adventure_stddev_samp_order_by: DemiplaneAdventureStddevSampOrderBy;
  demiplane_adventure_sum_fields: DemiplaneAdventureSumFields;
  demiplane_adventure_sum_order_by: DemiplaneAdventureSumOrderBy;
  demiplane_adventure_todo: DemiplaneAdventureTodo;
  demiplane_adventure_todo_aggregate: DemiplaneAdventureTodoAggregate;
  demiplane_adventure_todo_aggregate_fields: DemiplaneAdventureTodoAggregateFields;
  demiplane_adventure_todo_aggregate_order_by: DemiplaneAdventureTodoAggregateOrderBy;
  demiplane_adventure_todo_arr_rel_insert_input: DemiplaneAdventureTodoArrRelInsertInput;
  demiplane_adventure_todo_avg_fields: DemiplaneAdventureTodoAvgFields;
  demiplane_adventure_todo_avg_order_by: DemiplaneAdventureTodoAvgOrderBy;
  demiplane_adventure_todo_bool_exp: DemiplaneAdventureTodoBoolExp;
  demiplane_adventure_todo_inc_input: DemiplaneAdventureTodoIncInput;
  demiplane_adventure_todo_insert_input: DemiplaneAdventureTodoInsertInput;
  demiplane_adventure_todo_max_fields: DemiplaneAdventureTodoMaxFields;
  demiplane_adventure_todo_max_order_by: DemiplaneAdventureTodoMaxOrderBy;
  demiplane_adventure_todo_min_fields: DemiplaneAdventureTodoMinFields;
  demiplane_adventure_todo_min_order_by: DemiplaneAdventureTodoMinOrderBy;
  demiplane_adventure_todo_mutation_response: DemiplaneAdventureTodoMutationResponse;
  demiplane_adventure_todo_obj_rel_insert_input: DemiplaneAdventureTodoObjRelInsertInput;
  demiplane_adventure_todo_on_conflict: DemiplaneAdventureTodoOnConflict;
  demiplane_adventure_todo_order_by: DemiplaneAdventureTodoOrderBy;
  demiplane_adventure_todo_pk_columns_input: DemiplaneAdventureTodoPkColumnsInput;
  demiplane_adventure_todo_set_input: DemiplaneAdventureTodoSetInput;
  demiplane_adventure_todo_stddev_fields: DemiplaneAdventureTodoStddevFields;
  demiplane_adventure_todo_stddev_order_by: DemiplaneAdventureTodoStddevOrderBy;
  demiplane_adventure_todo_stddev_pop_fields: DemiplaneAdventureTodoStddevPopFields;
  demiplane_adventure_todo_stddev_pop_order_by: DemiplaneAdventureTodoStddevPopOrderBy;
  demiplane_adventure_todo_stddev_samp_fields: DemiplaneAdventureTodoStddevSampFields;
  demiplane_adventure_todo_stddev_samp_order_by: DemiplaneAdventureTodoStddevSampOrderBy;
  demiplane_adventure_todo_sum_fields: DemiplaneAdventureTodoSumFields;
  demiplane_adventure_todo_sum_order_by: DemiplaneAdventureTodoSumOrderBy;
  demiplane_adventure_todo_var_pop_fields: DemiplaneAdventureTodoVarPopFields;
  demiplane_adventure_todo_var_pop_order_by: DemiplaneAdventureTodoVarPopOrderBy;
  demiplane_adventure_todo_var_samp_fields: DemiplaneAdventureTodoVarSampFields;
  demiplane_adventure_todo_var_samp_order_by: DemiplaneAdventureTodoVarSampOrderBy;
  demiplane_adventure_todo_variance_fields: DemiplaneAdventureTodoVarianceFields;
  demiplane_adventure_todo_variance_order_by: DemiplaneAdventureTodoVarianceOrderBy;
  demiplane_adventure_var_pop_fields: DemiplaneAdventureVarPopFields;
  demiplane_adventure_var_pop_order_by: DemiplaneAdventureVarPopOrderBy;
  demiplane_adventure_var_samp_fields: DemiplaneAdventureVarSampFields;
  demiplane_adventure_var_samp_order_by: DemiplaneAdventureVarSampOrderBy;
  demiplane_adventure_variance_fields: DemiplaneAdventureVarianceFields;
  demiplane_adventure_variance_order_by: DemiplaneAdventureVarianceOrderBy;
  demiplane_adventuring_platform: DemiplaneAdventuringPlatform;
  demiplane_adventuring_platform_aggregate: DemiplaneAdventuringPlatformAggregate;
  demiplane_adventuring_platform_aggregate_fields: DemiplaneAdventuringPlatformAggregateFields;
  demiplane_adventuring_platform_aggregate_order_by: DemiplaneAdventuringPlatformAggregateOrderBy;
  demiplane_adventuring_platform_arr_rel_insert_input: DemiplaneAdventuringPlatformArrRelInsertInput;
  demiplane_adventuring_platform_avg_fields: DemiplaneAdventuringPlatformAvgFields;
  demiplane_adventuring_platform_avg_order_by: DemiplaneAdventuringPlatformAvgOrderBy;
  demiplane_adventuring_platform_bool_exp: DemiplaneAdventuringPlatformBoolExp;
  demiplane_adventuring_platform_inc_input: DemiplaneAdventuringPlatformIncInput;
  demiplane_adventuring_platform_insert_input: DemiplaneAdventuringPlatformInsertInput;
  demiplane_adventuring_platform_max_fields: DemiplaneAdventuringPlatformMaxFields;
  demiplane_adventuring_platform_max_order_by: DemiplaneAdventuringPlatformMaxOrderBy;
  demiplane_adventuring_platform_min_fields: DemiplaneAdventuringPlatformMinFields;
  demiplane_adventuring_platform_min_order_by: DemiplaneAdventuringPlatformMinOrderBy;
  demiplane_adventuring_platform_mutation_response: DemiplaneAdventuringPlatformMutationResponse;
  demiplane_adventuring_platform_obj_rel_insert_input: DemiplaneAdventuringPlatformObjRelInsertInput;
  demiplane_adventuring_platform_on_conflict: DemiplaneAdventuringPlatformOnConflict;
  demiplane_adventuring_platform_order_by: DemiplaneAdventuringPlatformOrderBy;
  demiplane_adventuring_platform_pk_columns_input: DemiplaneAdventuringPlatformPkColumnsInput;
  demiplane_adventuring_platform_set_input: DemiplaneAdventuringPlatformSetInput;
  demiplane_adventuring_platform_stddev_fields: DemiplaneAdventuringPlatformStddevFields;
  demiplane_adventuring_platform_stddev_order_by: DemiplaneAdventuringPlatformStddevOrderBy;
  demiplane_adventuring_platform_stddev_pop_fields: DemiplaneAdventuringPlatformStddevPopFields;
  demiplane_adventuring_platform_stddev_pop_order_by: DemiplaneAdventuringPlatformStddevPopOrderBy;
  demiplane_adventuring_platform_stddev_samp_fields: DemiplaneAdventuringPlatformStddevSampFields;
  demiplane_adventuring_platform_stddev_samp_order_by: DemiplaneAdventuringPlatformStddevSampOrderBy;
  demiplane_adventuring_platform_sum_fields: DemiplaneAdventuringPlatformSumFields;
  demiplane_adventuring_platform_sum_order_by: DemiplaneAdventuringPlatformSumOrderBy;
  demiplane_adventuring_platform_var_pop_fields: DemiplaneAdventuringPlatformVarPopFields;
  demiplane_adventuring_platform_var_pop_order_by: DemiplaneAdventuringPlatformVarPopOrderBy;
  demiplane_adventuring_platform_var_samp_fields: DemiplaneAdventuringPlatformVarSampFields;
  demiplane_adventuring_platform_var_samp_order_by: DemiplaneAdventuringPlatformVarSampOrderBy;
  demiplane_adventuring_platform_variance_fields: DemiplaneAdventuringPlatformVarianceFields;
  demiplane_adventuring_platform_variance_order_by: DemiplaneAdventuringPlatformVarianceOrderBy;
  demiplane_amc_adventuring_platforms: DemiplaneAmcAdventuringPlatforms;
  demiplane_amc_adventuring_platforms_aggregate: DemiplaneAmcAdventuringPlatformsAggregate;
  demiplane_amc_adventuring_platforms_aggregate_fields: DemiplaneAmcAdventuringPlatformsAggregateFields;
  demiplane_amc_adventuring_platforms_aggregate_order_by: DemiplaneAmcAdventuringPlatformsAggregateOrderBy;
  demiplane_amc_adventuring_platforms_arr_rel_insert_input: DemiplaneAmcAdventuringPlatformsArrRelInsertInput;
  demiplane_amc_adventuring_platforms_avg_fields: DemiplaneAmcAdventuringPlatformsAvgFields;
  demiplane_amc_adventuring_platforms_avg_order_by: DemiplaneAmcAdventuringPlatformsAvgOrderBy;
  demiplane_amc_adventuring_platforms_bool_exp: DemiplaneAmcAdventuringPlatformsBoolExp;
  demiplane_amc_adventuring_platforms_inc_input: DemiplaneAmcAdventuringPlatformsIncInput;
  demiplane_amc_adventuring_platforms_insert_input: DemiplaneAmcAdventuringPlatformsInsertInput;
  demiplane_amc_adventuring_platforms_max_fields: DemiplaneAmcAdventuringPlatformsMaxFields;
  demiplane_amc_adventuring_platforms_max_order_by: DemiplaneAmcAdventuringPlatformsMaxOrderBy;
  demiplane_amc_adventuring_platforms_min_fields: DemiplaneAmcAdventuringPlatformsMinFields;
  demiplane_amc_adventuring_platforms_min_order_by: DemiplaneAmcAdventuringPlatformsMinOrderBy;
  demiplane_amc_adventuring_platforms_mutation_response: DemiplaneAmcAdventuringPlatformsMutationResponse;
  demiplane_amc_adventuring_platforms_obj_rel_insert_input: DemiplaneAmcAdventuringPlatformsObjRelInsertInput;
  demiplane_amc_adventuring_platforms_on_conflict: DemiplaneAmcAdventuringPlatformsOnConflict;
  demiplane_amc_adventuring_platforms_order_by: DemiplaneAmcAdventuringPlatformsOrderBy;
  demiplane_amc_adventuring_platforms_pk_columns_input: DemiplaneAmcAdventuringPlatformsPkColumnsInput;
  demiplane_amc_adventuring_platforms_set_input: DemiplaneAmcAdventuringPlatformsSetInput;
  demiplane_amc_adventuring_platforms_stddev_fields: DemiplaneAmcAdventuringPlatformsStddevFields;
  demiplane_amc_adventuring_platforms_stddev_order_by: DemiplaneAmcAdventuringPlatformsStddevOrderBy;
  demiplane_amc_adventuring_platforms_stddev_pop_fields: DemiplaneAmcAdventuringPlatformsStddevPopFields;
  demiplane_amc_adventuring_platforms_stddev_pop_order_by: DemiplaneAmcAdventuringPlatformsStddevPopOrderBy;
  demiplane_amc_adventuring_platforms_stddev_samp_fields: DemiplaneAmcAdventuringPlatformsStddevSampFields;
  demiplane_amc_adventuring_platforms_stddev_samp_order_by: DemiplaneAmcAdventuringPlatformsStddevSampOrderBy;
  demiplane_amc_adventuring_platforms_sum_fields: DemiplaneAmcAdventuringPlatformsSumFields;
  demiplane_amc_adventuring_platforms_sum_order_by: DemiplaneAmcAdventuringPlatformsSumOrderBy;
  demiplane_amc_adventuring_platforms_var_pop_fields: DemiplaneAmcAdventuringPlatformsVarPopFields;
  demiplane_amc_adventuring_platforms_var_pop_order_by: DemiplaneAmcAdventuringPlatformsVarPopOrderBy;
  demiplane_amc_adventuring_platforms_var_samp_fields: DemiplaneAmcAdventuringPlatformsVarSampFields;
  demiplane_amc_adventuring_platforms_var_samp_order_by: DemiplaneAmcAdventuringPlatformsVarSampOrderBy;
  demiplane_amc_adventuring_platforms_variance_fields: DemiplaneAmcAdventuringPlatformsVarianceFields;
  demiplane_amc_adventuring_platforms_variance_order_by: DemiplaneAmcAdventuringPlatformsVarianceOrderBy;
  demiplane_amc_game_types: DemiplaneAmcGameTypes;
  demiplane_amc_game_types_aggregate: DemiplaneAmcGameTypesAggregate;
  demiplane_amc_game_types_aggregate_fields: DemiplaneAmcGameTypesAggregateFields;
  demiplane_amc_game_types_aggregate_order_by: DemiplaneAmcGameTypesAggregateOrderBy;
  demiplane_amc_game_types_arr_rel_insert_input: DemiplaneAmcGameTypesArrRelInsertInput;
  demiplane_amc_game_types_avg_fields: DemiplaneAmcGameTypesAvgFields;
  demiplane_amc_game_types_avg_order_by: DemiplaneAmcGameTypesAvgOrderBy;
  demiplane_amc_game_types_bool_exp: DemiplaneAmcGameTypesBoolExp;
  demiplane_amc_game_types_inc_input: DemiplaneAmcGameTypesIncInput;
  demiplane_amc_game_types_insert_input: DemiplaneAmcGameTypesInsertInput;
  demiplane_amc_game_types_max_fields: DemiplaneAmcGameTypesMaxFields;
  demiplane_amc_game_types_max_order_by: DemiplaneAmcGameTypesMaxOrderBy;
  demiplane_amc_game_types_min_fields: DemiplaneAmcGameTypesMinFields;
  demiplane_amc_game_types_min_order_by: DemiplaneAmcGameTypesMinOrderBy;
  demiplane_amc_game_types_mutation_response: DemiplaneAmcGameTypesMutationResponse;
  demiplane_amc_game_types_obj_rel_insert_input: DemiplaneAmcGameTypesObjRelInsertInput;
  demiplane_amc_game_types_on_conflict: DemiplaneAmcGameTypesOnConflict;
  demiplane_amc_game_types_order_by: DemiplaneAmcGameTypesOrderBy;
  demiplane_amc_game_types_pk_columns_input: DemiplaneAmcGameTypesPkColumnsInput;
  demiplane_amc_game_types_set_input: DemiplaneAmcGameTypesSetInput;
  demiplane_amc_game_types_stddev_fields: DemiplaneAmcGameTypesStddevFields;
  demiplane_amc_game_types_stddev_order_by: DemiplaneAmcGameTypesStddevOrderBy;
  demiplane_amc_game_types_stddev_pop_fields: DemiplaneAmcGameTypesStddevPopFields;
  demiplane_amc_game_types_stddev_pop_order_by: DemiplaneAmcGameTypesStddevPopOrderBy;
  demiplane_amc_game_types_stddev_samp_fields: DemiplaneAmcGameTypesStddevSampFields;
  demiplane_amc_game_types_stddev_samp_order_by: DemiplaneAmcGameTypesStddevSampOrderBy;
  demiplane_amc_game_types_sum_fields: DemiplaneAmcGameTypesSumFields;
  demiplane_amc_game_types_sum_order_by: DemiplaneAmcGameTypesSumOrderBy;
  demiplane_amc_game_types_var_pop_fields: DemiplaneAmcGameTypesVarPopFields;
  demiplane_amc_game_types_var_pop_order_by: DemiplaneAmcGameTypesVarPopOrderBy;
  demiplane_amc_game_types_var_samp_fields: DemiplaneAmcGameTypesVarSampFields;
  demiplane_amc_game_types_var_samp_order_by: DemiplaneAmcGameTypesVarSampOrderBy;
  demiplane_amc_game_types_variance_fields: DemiplaneAmcGameTypesVarianceFields;
  demiplane_amc_game_types_variance_order_by: DemiplaneAmcGameTypesVarianceOrderBy;
  demiplane_app_version: DemiplaneAppVersion;
  demiplane_app_version_aggregate: DemiplaneAppVersionAggregate;
  demiplane_app_version_aggregate_fields: DemiplaneAppVersionAggregateFields;
  demiplane_app_version_aggregate_order_by: DemiplaneAppVersionAggregateOrderBy;
  demiplane_app_version_arr_rel_insert_input: DemiplaneAppVersionArrRelInsertInput;
  demiplane_app_version_avg_fields: DemiplaneAppVersionAvgFields;
  demiplane_app_version_avg_order_by: DemiplaneAppVersionAvgOrderBy;
  demiplane_app_version_bool_exp: DemiplaneAppVersionBoolExp;
  demiplane_app_version_inc_input: DemiplaneAppVersionIncInput;
  demiplane_app_version_insert_input: DemiplaneAppVersionInsertInput;
  demiplane_app_version_max_fields: DemiplaneAppVersionMaxFields;
  demiplane_app_version_max_order_by: DemiplaneAppVersionMaxOrderBy;
  demiplane_app_version_min_fields: DemiplaneAppVersionMinFields;
  demiplane_app_version_min_order_by: DemiplaneAppVersionMinOrderBy;
  demiplane_app_version_mutation_response: DemiplaneAppVersionMutationResponse;
  demiplane_app_version_obj_rel_insert_input: DemiplaneAppVersionObjRelInsertInput;
  demiplane_app_version_on_conflict: DemiplaneAppVersionOnConflict;
  demiplane_app_version_order_by: DemiplaneAppVersionOrderBy;
  demiplane_app_version_pk_columns_input: DemiplaneAppVersionPkColumnsInput;
  demiplane_app_version_set_input: DemiplaneAppVersionSetInput;
  demiplane_app_version_stddev_fields: DemiplaneAppVersionStddevFields;
  demiplane_app_version_stddev_order_by: DemiplaneAppVersionStddevOrderBy;
  demiplane_app_version_stddev_pop_fields: DemiplaneAppVersionStddevPopFields;
  demiplane_app_version_stddev_pop_order_by: DemiplaneAppVersionStddevPopOrderBy;
  demiplane_app_version_stddev_samp_fields: DemiplaneAppVersionStddevSampFields;
  demiplane_app_version_stddev_samp_order_by: DemiplaneAppVersionStddevSampOrderBy;
  demiplane_app_version_sum_fields: DemiplaneAppVersionSumFields;
  demiplane_app_version_sum_order_by: DemiplaneAppVersionSumOrderBy;
  demiplane_app_version_var_pop_fields: DemiplaneAppVersionVarPopFields;
  demiplane_app_version_var_pop_order_by: DemiplaneAppVersionVarPopOrderBy;
  demiplane_app_version_var_samp_fields: DemiplaneAppVersionVarSampFields;
  demiplane_app_version_var_samp_order_by: DemiplaneAppVersionVarSampOrderBy;
  demiplane_app_version_variance_fields: DemiplaneAppVersionVarianceFields;
  demiplane_app_version_variance_order_by: DemiplaneAppVersionVarianceOrderBy;
  demiplane_attachment: DemiplaneAttachment;
  demiplane_attachment_aggregate: DemiplaneAttachmentAggregate;
  demiplane_attachment_aggregate_fields: DemiplaneAttachmentAggregateFields;
  demiplane_attachment_aggregate_order_by: DemiplaneAttachmentAggregateOrderBy;
  demiplane_attachment_arr_rel_insert_input: DemiplaneAttachmentArrRelInsertInput;
  demiplane_attachment_avg_fields: DemiplaneAttachmentAvgFields;
  demiplane_attachment_avg_order_by: DemiplaneAttachmentAvgOrderBy;
  demiplane_attachment_bool_exp: DemiplaneAttachmentBoolExp;
  demiplane_attachment_inc_input: DemiplaneAttachmentIncInput;
  demiplane_attachment_insert_input: DemiplaneAttachmentInsertInput;
  demiplane_attachment_max_fields: DemiplaneAttachmentMaxFields;
  demiplane_attachment_max_order_by: DemiplaneAttachmentMaxOrderBy;
  demiplane_attachment_min_fields: DemiplaneAttachmentMinFields;
  demiplane_attachment_min_order_by: DemiplaneAttachmentMinOrderBy;
  demiplane_attachment_mutation_response: DemiplaneAttachmentMutationResponse;
  demiplane_attachment_obj_rel_insert_input: DemiplaneAttachmentObjRelInsertInput;
  demiplane_attachment_on_conflict: DemiplaneAttachmentOnConflict;
  demiplane_attachment_order_by: DemiplaneAttachmentOrderBy;
  demiplane_attachment_pk_columns_input: DemiplaneAttachmentPkColumnsInput;
  demiplane_attachment_set_input: DemiplaneAttachmentSetInput;
  demiplane_attachment_stddev_fields: DemiplaneAttachmentStddevFields;
  demiplane_attachment_stddev_order_by: DemiplaneAttachmentStddevOrderBy;
  demiplane_attachment_stddev_pop_fields: DemiplaneAttachmentStddevPopFields;
  demiplane_attachment_stddev_pop_order_by: DemiplaneAttachmentStddevPopOrderBy;
  demiplane_attachment_stddev_samp_fields: DemiplaneAttachmentStddevSampFields;
  demiplane_attachment_stddev_samp_order_by: DemiplaneAttachmentStddevSampOrderBy;
  demiplane_attachment_sum_fields: DemiplaneAttachmentSumFields;
  demiplane_attachment_sum_order_by: DemiplaneAttachmentSumOrderBy;
  demiplane_attachment_var_pop_fields: DemiplaneAttachmentVarPopFields;
  demiplane_attachment_var_pop_order_by: DemiplaneAttachmentVarPopOrderBy;
  demiplane_attachment_var_samp_fields: DemiplaneAttachmentVarSampFields;
  demiplane_attachment_var_samp_order_by: DemiplaneAttachmentVarSampOrderBy;
  demiplane_attachment_variance_fields: DemiplaneAttachmentVarianceFields;
  demiplane_attachment_variance_order_by: DemiplaneAttachmentVarianceOrderBy;
  demiplane_award_type: DemiplaneAwardType;
  demiplane_award_type_aggregate: DemiplaneAwardTypeAggregate;
  demiplane_award_type_aggregate_fields: DemiplaneAwardTypeAggregateFields;
  demiplane_award_type_aggregate_order_by: DemiplaneAwardTypeAggregateOrderBy;
  demiplane_award_type_arr_rel_insert_input: DemiplaneAwardTypeArrRelInsertInput;
  demiplane_award_type_avg_fields: DemiplaneAwardTypeAvgFields;
  demiplane_award_type_avg_order_by: DemiplaneAwardTypeAvgOrderBy;
  demiplane_award_type_bool_exp: DemiplaneAwardTypeBoolExp;
  demiplane_award_type_inc_input: DemiplaneAwardTypeIncInput;
  demiplane_award_type_insert_input: DemiplaneAwardTypeInsertInput;
  demiplane_award_type_max_fields: DemiplaneAwardTypeMaxFields;
  demiplane_award_type_max_order_by: DemiplaneAwardTypeMaxOrderBy;
  demiplane_award_type_min_fields: DemiplaneAwardTypeMinFields;
  demiplane_award_type_min_order_by: DemiplaneAwardTypeMinOrderBy;
  demiplane_award_type_mutation_response: DemiplaneAwardTypeMutationResponse;
  demiplane_award_type_obj_rel_insert_input: DemiplaneAwardTypeObjRelInsertInput;
  demiplane_award_type_on_conflict: DemiplaneAwardTypeOnConflict;
  demiplane_award_type_order_by: DemiplaneAwardTypeOrderBy;
  demiplane_award_type_pk_columns_input: DemiplaneAwardTypePkColumnsInput;
  demiplane_award_type_set_input: DemiplaneAwardTypeSetInput;
  demiplane_award_type_stddev_fields: DemiplaneAwardTypeStddevFields;
  demiplane_award_type_stddev_order_by: DemiplaneAwardTypeStddevOrderBy;
  demiplane_award_type_stddev_pop_fields: DemiplaneAwardTypeStddevPopFields;
  demiplane_award_type_stddev_pop_order_by: DemiplaneAwardTypeStddevPopOrderBy;
  demiplane_award_type_stddev_samp_fields: DemiplaneAwardTypeStddevSampFields;
  demiplane_award_type_stddev_samp_order_by: DemiplaneAwardTypeStddevSampOrderBy;
  demiplane_award_type_sum_fields: DemiplaneAwardTypeSumFields;
  demiplane_award_type_sum_order_by: DemiplaneAwardTypeSumOrderBy;
  demiplane_award_type_var_pop_fields: DemiplaneAwardTypeVarPopFields;
  demiplane_award_type_var_pop_order_by: DemiplaneAwardTypeVarPopOrderBy;
  demiplane_award_type_var_samp_fields: DemiplaneAwardTypeVarSampFields;
  demiplane_award_type_var_samp_order_by: DemiplaneAwardTypeVarSampOrderBy;
  demiplane_award_type_variance_fields: DemiplaneAwardTypeVarianceFields;
  demiplane_award_type_variance_order_by: DemiplaneAwardTypeVarianceOrderBy;
  demiplane_connection_status: DemiplaneConnectionStatus;
  demiplane_connection_status_aggregate: DemiplaneConnectionStatusAggregate;
  demiplane_connection_status_aggregate_fields: DemiplaneConnectionStatusAggregateFields;
  demiplane_connection_status_aggregate_order_by: DemiplaneConnectionStatusAggregateOrderBy;
  demiplane_connection_status_arr_rel_insert_input: DemiplaneConnectionStatusArrRelInsertInput;
  demiplane_connection_status_avg_fields: DemiplaneConnectionStatusAvgFields;
  demiplane_connection_status_avg_order_by: DemiplaneConnectionStatusAvgOrderBy;
  demiplane_connection_status_bool_exp: DemiplaneConnectionStatusBoolExp;
  demiplane_connection_status_inc_input: DemiplaneConnectionStatusIncInput;
  demiplane_connection_status_insert_input: DemiplaneConnectionStatusInsertInput;
  demiplane_connection_status_max_fields: DemiplaneConnectionStatusMaxFields;
  demiplane_connection_status_max_order_by: DemiplaneConnectionStatusMaxOrderBy;
  demiplane_connection_status_min_fields: DemiplaneConnectionStatusMinFields;
  demiplane_connection_status_min_order_by: DemiplaneConnectionStatusMinOrderBy;
  demiplane_connection_status_mutation_response: DemiplaneConnectionStatusMutationResponse;
  demiplane_connection_status_obj_rel_insert_input: DemiplaneConnectionStatusObjRelInsertInput;
  demiplane_connection_status_on_conflict: DemiplaneConnectionStatusOnConflict;
  demiplane_connection_status_order_by: DemiplaneConnectionStatusOrderBy;
  demiplane_connection_status_pk_columns_input: DemiplaneConnectionStatusPkColumnsInput;
  demiplane_connection_status_set_input: DemiplaneConnectionStatusSetInput;
  demiplane_connection_status_stddev_fields: DemiplaneConnectionStatusStddevFields;
  demiplane_connection_status_stddev_order_by: DemiplaneConnectionStatusStddevOrderBy;
  demiplane_connection_status_stddev_pop_fields: DemiplaneConnectionStatusStddevPopFields;
  demiplane_connection_status_stddev_pop_order_by: DemiplaneConnectionStatusStddevPopOrderBy;
  demiplane_connection_status_stddev_samp_fields: DemiplaneConnectionStatusStddevSampFields;
  demiplane_connection_status_stddev_samp_order_by: DemiplaneConnectionStatusStddevSampOrderBy;
  demiplane_connection_status_sum_fields: DemiplaneConnectionStatusSumFields;
  demiplane_connection_status_sum_order_by: DemiplaneConnectionStatusSumOrderBy;
  demiplane_connection_status_var_pop_fields: DemiplaneConnectionStatusVarPopFields;
  demiplane_connection_status_var_pop_order_by: DemiplaneConnectionStatusVarPopOrderBy;
  demiplane_connection_status_var_samp_fields: DemiplaneConnectionStatusVarSampFields;
  demiplane_connection_status_var_samp_order_by: DemiplaneConnectionStatusVarSampOrderBy;
  demiplane_connection_status_variance_fields: DemiplaneConnectionStatusVarianceFields;
  demiplane_connection_status_variance_order_by: DemiplaneConnectionStatusVarianceOrderBy;
  demiplane_connection_type: DemiplaneConnectionType;
  demiplane_connection_type_aggregate: DemiplaneConnectionTypeAggregate;
  demiplane_connection_type_aggregate_fields: DemiplaneConnectionTypeAggregateFields;
  demiplane_connection_type_aggregate_order_by: DemiplaneConnectionTypeAggregateOrderBy;
  demiplane_connection_type_arr_rel_insert_input: DemiplaneConnectionTypeArrRelInsertInput;
  demiplane_connection_type_avg_fields: DemiplaneConnectionTypeAvgFields;
  demiplane_connection_type_avg_order_by: DemiplaneConnectionTypeAvgOrderBy;
  demiplane_connection_type_bool_exp: DemiplaneConnectionTypeBoolExp;
  demiplane_connection_type_inc_input: DemiplaneConnectionTypeIncInput;
  demiplane_connection_type_insert_input: DemiplaneConnectionTypeInsertInput;
  demiplane_connection_type_max_fields: DemiplaneConnectionTypeMaxFields;
  demiplane_connection_type_max_order_by: DemiplaneConnectionTypeMaxOrderBy;
  demiplane_connection_type_min_fields: DemiplaneConnectionTypeMinFields;
  demiplane_connection_type_min_order_by: DemiplaneConnectionTypeMinOrderBy;
  demiplane_connection_type_mutation_response: DemiplaneConnectionTypeMutationResponse;
  demiplane_connection_type_obj_rel_insert_input: DemiplaneConnectionTypeObjRelInsertInput;
  demiplane_connection_type_on_conflict: DemiplaneConnectionTypeOnConflict;
  demiplane_connection_type_order_by: DemiplaneConnectionTypeOrderBy;
  demiplane_connection_type_pk_columns_input: DemiplaneConnectionTypePkColumnsInput;
  demiplane_connection_type_set_input: DemiplaneConnectionTypeSetInput;
  demiplane_connection_type_stddev_fields: DemiplaneConnectionTypeStddevFields;
  demiplane_connection_type_stddev_order_by: DemiplaneConnectionTypeStddevOrderBy;
  demiplane_connection_type_stddev_pop_fields: DemiplaneConnectionTypeStddevPopFields;
  demiplane_connection_type_stddev_pop_order_by: DemiplaneConnectionTypeStddevPopOrderBy;
  demiplane_connection_type_stddev_samp_fields: DemiplaneConnectionTypeStddevSampFields;
  demiplane_connection_type_stddev_samp_order_by: DemiplaneConnectionTypeStddevSampOrderBy;
  demiplane_connection_type_sum_fields: DemiplaneConnectionTypeSumFields;
  demiplane_connection_type_sum_order_by: DemiplaneConnectionTypeSumOrderBy;
  demiplane_connection_type_var_pop_fields: DemiplaneConnectionTypeVarPopFields;
  demiplane_connection_type_var_pop_order_by: DemiplaneConnectionTypeVarPopOrderBy;
  demiplane_connection_type_var_samp_fields: DemiplaneConnectionTypeVarSampFields;
  demiplane_connection_type_var_samp_order_by: DemiplaneConnectionTypeVarSampOrderBy;
  demiplane_connection_type_variance_fields: DemiplaneConnectionTypeVarianceFields;
  demiplane_connection_type_variance_order_by: DemiplaneConnectionTypeVarianceOrderBy;
  demiplane_contact_type: DemiplaneContactType;
  demiplane_contact_type_aggregate: DemiplaneContactTypeAggregate;
  demiplane_contact_type_aggregate_fields: DemiplaneContactTypeAggregateFields;
  demiplane_contact_type_aggregate_order_by: DemiplaneContactTypeAggregateOrderBy;
  demiplane_contact_type_arr_rel_insert_input: DemiplaneContactTypeArrRelInsertInput;
  demiplane_contact_type_avg_fields: DemiplaneContactTypeAvgFields;
  demiplane_contact_type_avg_order_by: DemiplaneContactTypeAvgOrderBy;
  demiplane_contact_type_bool_exp: DemiplaneContactTypeBoolExp;
  demiplane_contact_type_inc_input: DemiplaneContactTypeIncInput;
  demiplane_contact_type_insert_input: DemiplaneContactTypeInsertInput;
  demiplane_contact_type_max_fields: DemiplaneContactTypeMaxFields;
  demiplane_contact_type_max_order_by: DemiplaneContactTypeMaxOrderBy;
  demiplane_contact_type_min_fields: DemiplaneContactTypeMinFields;
  demiplane_contact_type_min_order_by: DemiplaneContactTypeMinOrderBy;
  demiplane_contact_type_mutation_response: DemiplaneContactTypeMutationResponse;
  demiplane_contact_type_obj_rel_insert_input: DemiplaneContactTypeObjRelInsertInput;
  demiplane_contact_type_on_conflict: DemiplaneContactTypeOnConflict;
  demiplane_contact_type_order_by: DemiplaneContactTypeOrderBy;
  demiplane_contact_type_pk_columns_input: DemiplaneContactTypePkColumnsInput;
  demiplane_contact_type_set_input: DemiplaneContactTypeSetInput;
  demiplane_contact_type_stddev_fields: DemiplaneContactTypeStddevFields;
  demiplane_contact_type_stddev_order_by: DemiplaneContactTypeStddevOrderBy;
  demiplane_contact_type_stddev_pop_fields: DemiplaneContactTypeStddevPopFields;
  demiplane_contact_type_stddev_pop_order_by: DemiplaneContactTypeStddevPopOrderBy;
  demiplane_contact_type_stddev_samp_fields: DemiplaneContactTypeStddevSampFields;
  demiplane_contact_type_stddev_samp_order_by: DemiplaneContactTypeStddevSampOrderBy;
  demiplane_contact_type_sum_fields: DemiplaneContactTypeSumFields;
  demiplane_contact_type_sum_order_by: DemiplaneContactTypeSumOrderBy;
  demiplane_contact_type_var_pop_fields: DemiplaneContactTypeVarPopFields;
  demiplane_contact_type_var_pop_order_by: DemiplaneContactTypeVarPopOrderBy;
  demiplane_contact_type_var_samp_fields: DemiplaneContactTypeVarSampFields;
  demiplane_contact_type_var_samp_order_by: DemiplaneContactTypeVarSampOrderBy;
  demiplane_contact_type_variance_fields: DemiplaneContactTypeVarianceFields;
  demiplane_contact_type_variance_order_by: DemiplaneContactTypeVarianceOrderBy;
  demiplane_country: DemiplaneCountry;
  demiplane_country_aggregate: DemiplaneCountryAggregate;
  demiplane_country_aggregate_fields: DemiplaneCountryAggregateFields;
  demiplane_country_aggregate_order_by: DemiplaneCountryAggregateOrderBy;
  demiplane_country_arr_rel_insert_input: DemiplaneCountryArrRelInsertInput;
  demiplane_country_avg_fields: DemiplaneCountryAvgFields;
  demiplane_country_avg_order_by: DemiplaneCountryAvgOrderBy;
  demiplane_country_bool_exp: DemiplaneCountryBoolExp;
  demiplane_country_inc_input: DemiplaneCountryIncInput;
  demiplane_country_insert_input: DemiplaneCountryInsertInput;
  demiplane_country_max_fields: DemiplaneCountryMaxFields;
  demiplane_country_max_order_by: DemiplaneCountryMaxOrderBy;
  demiplane_country_min_fields: DemiplaneCountryMinFields;
  demiplane_country_min_order_by: DemiplaneCountryMinOrderBy;
  demiplane_country_mutation_response: DemiplaneCountryMutationResponse;
  demiplane_country_obj_rel_insert_input: DemiplaneCountryObjRelInsertInput;
  demiplane_country_on_conflict: DemiplaneCountryOnConflict;
  demiplane_country_order_by: DemiplaneCountryOrderBy;
  demiplane_country_pk_columns_input: DemiplaneCountryPkColumnsInput;
  demiplane_country_set_input: DemiplaneCountrySetInput;
  demiplane_country_stddev_fields: DemiplaneCountryStddevFields;
  demiplane_country_stddev_order_by: DemiplaneCountryStddevOrderBy;
  demiplane_country_stddev_pop_fields: DemiplaneCountryStddevPopFields;
  demiplane_country_stddev_pop_order_by: DemiplaneCountryStddevPopOrderBy;
  demiplane_country_stddev_samp_fields: DemiplaneCountryStddevSampFields;
  demiplane_country_stddev_samp_order_by: DemiplaneCountryStddevSampOrderBy;
  demiplane_country_sum_fields: DemiplaneCountrySumFields;
  demiplane_country_sum_order_by: DemiplaneCountrySumOrderBy;
  demiplane_country_var_pop_fields: DemiplaneCountryVarPopFields;
  demiplane_country_var_pop_order_by: DemiplaneCountryVarPopOrderBy;
  demiplane_country_var_samp_fields: DemiplaneCountryVarSampFields;
  demiplane_country_var_samp_order_by: DemiplaneCountryVarSampOrderBy;
  demiplane_country_variance_fields: DemiplaneCountryVarianceFields;
  demiplane_country_variance_order_by: DemiplaneCountryVarianceOrderBy;
  demiplane_credit_card: DemiplaneCreditCard;
  demiplane_credit_card_aggregate: DemiplaneCreditCardAggregate;
  demiplane_credit_card_aggregate_fields: DemiplaneCreditCardAggregateFields;
  demiplane_credit_card_aggregate_order_by: DemiplaneCreditCardAggregateOrderBy;
  demiplane_credit_card_arr_rel_insert_input: DemiplaneCreditCardArrRelInsertInput;
  demiplane_credit_card_avg_fields: DemiplaneCreditCardAvgFields;
  demiplane_credit_card_avg_order_by: DemiplaneCreditCardAvgOrderBy;
  demiplane_credit_card_bool_exp: DemiplaneCreditCardBoolExp;
  demiplane_credit_card_inc_input: DemiplaneCreditCardIncInput;
  demiplane_credit_card_insert_input: DemiplaneCreditCardInsertInput;
  demiplane_credit_card_max_fields: DemiplaneCreditCardMaxFields;
  demiplane_credit_card_max_order_by: DemiplaneCreditCardMaxOrderBy;
  demiplane_credit_card_min_fields: DemiplaneCreditCardMinFields;
  demiplane_credit_card_min_order_by: DemiplaneCreditCardMinOrderBy;
  demiplane_credit_card_mutation_response: DemiplaneCreditCardMutationResponse;
  demiplane_credit_card_obj_rel_insert_input: DemiplaneCreditCardObjRelInsertInput;
  demiplane_credit_card_on_conflict: DemiplaneCreditCardOnConflict;
  demiplane_credit_card_order_by: DemiplaneCreditCardOrderBy;
  demiplane_credit_card_pk_columns_input: DemiplaneCreditCardPkColumnsInput;
  demiplane_credit_card_set_input: DemiplaneCreditCardSetInput;
  demiplane_credit_card_stddev_fields: DemiplaneCreditCardStddevFields;
  demiplane_credit_card_stddev_order_by: DemiplaneCreditCardStddevOrderBy;
  demiplane_credit_card_stddev_pop_fields: DemiplaneCreditCardStddevPopFields;
  demiplane_credit_card_stddev_pop_order_by: DemiplaneCreditCardStddevPopOrderBy;
  demiplane_credit_card_stddev_samp_fields: DemiplaneCreditCardStddevSampFields;
  demiplane_credit_card_stddev_samp_order_by: DemiplaneCreditCardStddevSampOrderBy;
  demiplane_credit_card_sum_fields: DemiplaneCreditCardSumFields;
  demiplane_credit_card_sum_order_by: DemiplaneCreditCardSumOrderBy;
  demiplane_credit_card_var_pop_fields: DemiplaneCreditCardVarPopFields;
  demiplane_credit_card_var_pop_order_by: DemiplaneCreditCardVarPopOrderBy;
  demiplane_credit_card_var_samp_fields: DemiplaneCreditCardVarSampFields;
  demiplane_credit_card_var_samp_order_by: DemiplaneCreditCardVarSampOrderBy;
  demiplane_credit_card_variance_fields: DemiplaneCreditCardVarianceFields;
  demiplane_credit_card_variance_order_by: DemiplaneCreditCardVarianceOrderBy;
  demiplane_demiplane_tool: DemiplaneDemiplaneTool;
  demiplane_demiplane_tool_aggregate: DemiplaneDemiplaneToolAggregate;
  demiplane_demiplane_tool_aggregate_fields: DemiplaneDemiplaneToolAggregateFields;
  demiplane_demiplane_tool_aggregate_order_by: DemiplaneDemiplaneToolAggregateOrderBy;
  demiplane_demiplane_tool_arr_rel_insert_input: DemiplaneDemiplaneToolArrRelInsertInput;
  demiplane_demiplane_tool_avg_fields: DemiplaneDemiplaneToolAvgFields;
  demiplane_demiplane_tool_avg_order_by: DemiplaneDemiplaneToolAvgOrderBy;
  demiplane_demiplane_tool_bool_exp: DemiplaneDemiplaneToolBoolExp;
  demiplane_demiplane_tool_inc_input: DemiplaneDemiplaneToolIncInput;
  demiplane_demiplane_tool_insert_input: DemiplaneDemiplaneToolInsertInput;
  demiplane_demiplane_tool_max_fields: DemiplaneDemiplaneToolMaxFields;
  demiplane_demiplane_tool_max_order_by: DemiplaneDemiplaneToolMaxOrderBy;
  demiplane_demiplane_tool_min_fields: DemiplaneDemiplaneToolMinFields;
  demiplane_demiplane_tool_min_order_by: DemiplaneDemiplaneToolMinOrderBy;
  demiplane_demiplane_tool_mutation_response: DemiplaneDemiplaneToolMutationResponse;
  demiplane_demiplane_tool_obj_rel_insert_input: DemiplaneDemiplaneToolObjRelInsertInput;
  demiplane_demiplane_tool_on_conflict: DemiplaneDemiplaneToolOnConflict;
  demiplane_demiplane_tool_order_by: DemiplaneDemiplaneToolOrderBy;
  demiplane_demiplane_tool_pk_columns_input: DemiplaneDemiplaneToolPkColumnsInput;
  demiplane_demiplane_tool_set_input: DemiplaneDemiplaneToolSetInput;
  demiplane_demiplane_tool_stddev_fields: DemiplaneDemiplaneToolStddevFields;
  demiplane_demiplane_tool_stddev_order_by: DemiplaneDemiplaneToolStddevOrderBy;
  demiplane_demiplane_tool_stddev_pop_fields: DemiplaneDemiplaneToolStddevPopFields;
  demiplane_demiplane_tool_stddev_pop_order_by: DemiplaneDemiplaneToolStddevPopOrderBy;
  demiplane_demiplane_tool_stddev_samp_fields: DemiplaneDemiplaneToolStddevSampFields;
  demiplane_demiplane_tool_stddev_samp_order_by: DemiplaneDemiplaneToolStddevSampOrderBy;
  demiplane_demiplane_tool_sum_fields: DemiplaneDemiplaneToolSumFields;
  demiplane_demiplane_tool_sum_order_by: DemiplaneDemiplaneToolSumOrderBy;
  demiplane_demiplane_tool_var_pop_fields: DemiplaneDemiplaneToolVarPopFields;
  demiplane_demiplane_tool_var_pop_order_by: DemiplaneDemiplaneToolVarPopOrderBy;
  demiplane_demiplane_tool_var_samp_fields: DemiplaneDemiplaneToolVarSampFields;
  demiplane_demiplane_tool_var_samp_order_by: DemiplaneDemiplaneToolVarSampOrderBy;
  demiplane_demiplane_tool_variance_fields: DemiplaneDemiplaneToolVarianceFields;
  demiplane_demiplane_tool_variance_order_by: DemiplaneDemiplaneToolVarianceOrderBy;
  demiplane_episode_attendance: DemiplaneEpisodeAttendance;
  demiplane_episode_attendance_aggregate: DemiplaneEpisodeAttendanceAggregate;
  demiplane_episode_attendance_aggregate_fields: DemiplaneEpisodeAttendanceAggregateFields;
  demiplane_episode_attendance_aggregate_order_by: DemiplaneEpisodeAttendanceAggregateOrderBy;
  demiplane_episode_attendance_arr_rel_insert_input: DemiplaneEpisodeAttendanceArrRelInsertInput;
  demiplane_episode_attendance_avg_fields: DemiplaneEpisodeAttendanceAvgFields;
  demiplane_episode_attendance_avg_order_by: DemiplaneEpisodeAttendanceAvgOrderBy;
  demiplane_episode_attendance_bool_exp: DemiplaneEpisodeAttendanceBoolExp;
  demiplane_episode_attendance_inc_input: DemiplaneEpisodeAttendanceIncInput;
  demiplane_episode_attendance_insert_input: DemiplaneEpisodeAttendanceInsertInput;
  demiplane_episode_attendance_max_fields: DemiplaneEpisodeAttendanceMaxFields;
  demiplane_episode_attendance_max_order_by: DemiplaneEpisodeAttendanceMaxOrderBy;
  demiplane_episode_attendance_min_fields: DemiplaneEpisodeAttendanceMinFields;
  demiplane_episode_attendance_min_order_by: DemiplaneEpisodeAttendanceMinOrderBy;
  demiplane_episode_attendance_mutation_response: DemiplaneEpisodeAttendanceMutationResponse;
  demiplane_episode_attendance_obj_rel_insert_input: DemiplaneEpisodeAttendanceObjRelInsertInput;
  demiplane_episode_attendance_on_conflict: DemiplaneEpisodeAttendanceOnConflict;
  demiplane_episode_attendance_order_by: DemiplaneEpisodeAttendanceOrderBy;
  demiplane_episode_attendance_pk_columns_input: DemiplaneEpisodeAttendancePkColumnsInput;
  demiplane_episode_attendance_set_input: DemiplaneEpisodeAttendanceSetInput;
  demiplane_episode_attendance_stddev_fields: DemiplaneEpisodeAttendanceStddevFields;
  demiplane_episode_attendance_stddev_order_by: DemiplaneEpisodeAttendanceStddevOrderBy;
  demiplane_episode_attendance_stddev_pop_fields: DemiplaneEpisodeAttendanceStddevPopFields;
  demiplane_episode_attendance_stddev_pop_order_by: DemiplaneEpisodeAttendanceStddevPopOrderBy;
  demiplane_episode_attendance_stddev_samp_fields: DemiplaneEpisodeAttendanceStddevSampFields;
  demiplane_episode_attendance_stddev_samp_order_by: DemiplaneEpisodeAttendanceStddevSampOrderBy;
  demiplane_episode_attendance_sum_fields: DemiplaneEpisodeAttendanceSumFields;
  demiplane_episode_attendance_sum_order_by: DemiplaneEpisodeAttendanceSumOrderBy;
  demiplane_episode_attendance_var_pop_fields: DemiplaneEpisodeAttendanceVarPopFields;
  demiplane_episode_attendance_var_pop_order_by: DemiplaneEpisodeAttendanceVarPopOrderBy;
  demiplane_episode_attendance_var_samp_fields: DemiplaneEpisodeAttendanceVarSampFields;
  demiplane_episode_attendance_var_samp_order_by: DemiplaneEpisodeAttendanceVarSampOrderBy;
  demiplane_episode_attendance_variance_fields: DemiplaneEpisodeAttendanceVarianceFields;
  demiplane_episode_attendance_variance_order_by: DemiplaneEpisodeAttendanceVarianceOrderBy;
  demiplane_event: DemiplaneEvent;
  demiplane_event_aggregate: DemiplaneEventAggregate;
  demiplane_event_aggregate_fields: DemiplaneEventAggregateFields;
  demiplane_event_aggregate_order_by: DemiplaneEventAggregateOrderBy;
  demiplane_event_arr_rel_insert_input: DemiplaneEventArrRelInsertInput;
  demiplane_event_avg_fields: DemiplaneEventAvgFields;
  demiplane_event_avg_order_by: DemiplaneEventAvgOrderBy;
  demiplane_event_bool_exp: DemiplaneEventBoolExp;
  demiplane_event_inc_input: DemiplaneEventIncInput;
  demiplane_event_insert_input: DemiplaneEventInsertInput;
  demiplane_event_max_fields: DemiplaneEventMaxFields;
  demiplane_event_max_order_by: DemiplaneEventMaxOrderBy;
  demiplane_event_min_fields: DemiplaneEventMinFields;
  demiplane_event_min_order_by: DemiplaneEventMinOrderBy;
  demiplane_event_mutation_response: DemiplaneEventMutationResponse;
  demiplane_event_obj_rel_insert_input: DemiplaneEventObjRelInsertInput;
  demiplane_event_on_conflict: DemiplaneEventOnConflict;
  demiplane_event_order_by: DemiplaneEventOrderBy;
  demiplane_event_pk_columns_input: DemiplaneEventPkColumnsInput;
  demiplane_event_set_input: DemiplaneEventSetInput;
  demiplane_event_stddev_fields: DemiplaneEventStddevFields;
  demiplane_event_stddev_order_by: DemiplaneEventStddevOrderBy;
  demiplane_event_stddev_pop_fields: DemiplaneEventStddevPopFields;
  demiplane_event_stddev_pop_order_by: DemiplaneEventStddevPopOrderBy;
  demiplane_event_stddev_samp_fields: DemiplaneEventStddevSampFields;
  demiplane_event_stddev_samp_order_by: DemiplaneEventStddevSampOrderBy;
  demiplane_event_sum_fields: DemiplaneEventSumFields;
  demiplane_event_sum_order_by: DemiplaneEventSumOrderBy;
  demiplane_event_type: DemiplaneEventType;
  demiplane_event_type_aggregate: DemiplaneEventTypeAggregate;
  demiplane_event_type_aggregate_fields: DemiplaneEventTypeAggregateFields;
  demiplane_event_type_aggregate_order_by: DemiplaneEventTypeAggregateOrderBy;
  demiplane_event_type_arr_rel_insert_input: DemiplaneEventTypeArrRelInsertInput;
  demiplane_event_type_avg_fields: DemiplaneEventTypeAvgFields;
  demiplane_event_type_avg_order_by: DemiplaneEventTypeAvgOrderBy;
  demiplane_event_type_bool_exp: DemiplaneEventTypeBoolExp;
  demiplane_event_type_inc_input: DemiplaneEventTypeIncInput;
  demiplane_event_type_insert_input: DemiplaneEventTypeInsertInput;
  demiplane_event_type_max_fields: DemiplaneEventTypeMaxFields;
  demiplane_event_type_max_order_by: DemiplaneEventTypeMaxOrderBy;
  demiplane_event_type_min_fields: DemiplaneEventTypeMinFields;
  demiplane_event_type_min_order_by: DemiplaneEventTypeMinOrderBy;
  demiplane_event_type_mutation_response: DemiplaneEventTypeMutationResponse;
  demiplane_event_type_obj_rel_insert_input: DemiplaneEventTypeObjRelInsertInput;
  demiplane_event_type_on_conflict: DemiplaneEventTypeOnConflict;
  demiplane_event_type_order_by: DemiplaneEventTypeOrderBy;
  demiplane_event_type_pk_columns_input: DemiplaneEventTypePkColumnsInput;
  demiplane_event_type_set_input: DemiplaneEventTypeSetInput;
  demiplane_event_type_stddev_fields: DemiplaneEventTypeStddevFields;
  demiplane_event_type_stddev_order_by: DemiplaneEventTypeStddevOrderBy;
  demiplane_event_type_stddev_pop_fields: DemiplaneEventTypeStddevPopFields;
  demiplane_event_type_stddev_pop_order_by: DemiplaneEventTypeStddevPopOrderBy;
  demiplane_event_type_stddev_samp_fields: DemiplaneEventTypeStddevSampFields;
  demiplane_event_type_stddev_samp_order_by: DemiplaneEventTypeStddevSampOrderBy;
  demiplane_event_type_sum_fields: DemiplaneEventTypeSumFields;
  demiplane_event_type_sum_order_by: DemiplaneEventTypeSumOrderBy;
  demiplane_event_type_var_pop_fields: DemiplaneEventTypeVarPopFields;
  demiplane_event_type_var_pop_order_by: DemiplaneEventTypeVarPopOrderBy;
  demiplane_event_type_var_samp_fields: DemiplaneEventTypeVarSampFields;
  demiplane_event_type_var_samp_order_by: DemiplaneEventTypeVarSampOrderBy;
  demiplane_event_type_variance_fields: DemiplaneEventTypeVarianceFields;
  demiplane_event_type_variance_order_by: DemiplaneEventTypeVarianceOrderBy;
  demiplane_event_var_pop_fields: DemiplaneEventVarPopFields;
  demiplane_event_var_pop_order_by: DemiplaneEventVarPopOrderBy;
  demiplane_event_var_samp_fields: DemiplaneEventVarSampFields;
  demiplane_event_var_samp_order_by: DemiplaneEventVarSampOrderBy;
  demiplane_event_variance_fields: DemiplaneEventVarianceFields;
  demiplane_event_variance_order_by: DemiplaneEventVarianceOrderBy;
  demiplane_favorite_adventuring_platform: DemiplaneFavoriteAdventuringPlatform;
  demiplane_favorite_adventuring_platform_aggregate: DemiplaneFavoriteAdventuringPlatformAggregate;
  demiplane_favorite_adventuring_platform_aggregate_fields: DemiplaneFavoriteAdventuringPlatformAggregateFields;
  demiplane_favorite_adventuring_platform_aggregate_order_by: DemiplaneFavoriteAdventuringPlatformAggregateOrderBy;
  demiplane_favorite_adventuring_platform_arr_rel_insert_input: DemiplaneFavoriteAdventuringPlatformArrRelInsertInput;
  demiplane_favorite_adventuring_platform_avg_fields: DemiplaneFavoriteAdventuringPlatformAvgFields;
  demiplane_favorite_adventuring_platform_avg_order_by: DemiplaneFavoriteAdventuringPlatformAvgOrderBy;
  demiplane_favorite_adventuring_platform_bool_exp: DemiplaneFavoriteAdventuringPlatformBoolExp;
  demiplane_favorite_adventuring_platform_inc_input: DemiplaneFavoriteAdventuringPlatformIncInput;
  demiplane_favorite_adventuring_platform_insert_input: DemiplaneFavoriteAdventuringPlatformInsertInput;
  demiplane_favorite_adventuring_platform_max_fields: DemiplaneFavoriteAdventuringPlatformMaxFields;
  demiplane_favorite_adventuring_platform_max_order_by: DemiplaneFavoriteAdventuringPlatformMaxOrderBy;
  demiplane_favorite_adventuring_platform_min_fields: DemiplaneFavoriteAdventuringPlatformMinFields;
  demiplane_favorite_adventuring_platform_min_order_by: DemiplaneFavoriteAdventuringPlatformMinOrderBy;
  demiplane_favorite_adventuring_platform_mutation_response: DemiplaneFavoriteAdventuringPlatformMutationResponse;
  demiplane_favorite_adventuring_platform_obj_rel_insert_input: DemiplaneFavoriteAdventuringPlatformObjRelInsertInput;
  demiplane_favorite_adventuring_platform_on_conflict: DemiplaneFavoriteAdventuringPlatformOnConflict;
  demiplane_favorite_adventuring_platform_order_by: DemiplaneFavoriteAdventuringPlatformOrderBy;
  demiplane_favorite_adventuring_platform_pk_columns_input: DemiplaneFavoriteAdventuringPlatformPkColumnsInput;
  demiplane_favorite_adventuring_platform_set_input: DemiplaneFavoriteAdventuringPlatformSetInput;
  demiplane_favorite_adventuring_platform_stddev_fields: DemiplaneFavoriteAdventuringPlatformStddevFields;
  demiplane_favorite_adventuring_platform_stddev_order_by: DemiplaneFavoriteAdventuringPlatformStddevOrderBy;
  demiplane_favorite_adventuring_platform_stddev_pop_fields: DemiplaneFavoriteAdventuringPlatformStddevPopFields;
  demiplane_favorite_adventuring_platform_stddev_pop_order_by: DemiplaneFavoriteAdventuringPlatformStddevPopOrderBy;
  demiplane_favorite_adventuring_platform_stddev_samp_fields: DemiplaneFavoriteAdventuringPlatformStddevSampFields;
  demiplane_favorite_adventuring_platform_stddev_samp_order_by: DemiplaneFavoriteAdventuringPlatformStddevSampOrderBy;
  demiplane_favorite_adventuring_platform_sum_fields: DemiplaneFavoriteAdventuringPlatformSumFields;
  demiplane_favorite_adventuring_platform_sum_order_by: DemiplaneFavoriteAdventuringPlatformSumOrderBy;
  demiplane_favorite_adventuring_platform_var_pop_fields: DemiplaneFavoriteAdventuringPlatformVarPopFields;
  demiplane_favorite_adventuring_platform_var_pop_order_by: DemiplaneFavoriteAdventuringPlatformVarPopOrderBy;
  demiplane_favorite_adventuring_platform_var_samp_fields: DemiplaneFavoriteAdventuringPlatformVarSampFields;
  demiplane_favorite_adventuring_platform_var_samp_order_by: DemiplaneFavoriteAdventuringPlatformVarSampOrderBy;
  demiplane_favorite_adventuring_platform_variance_fields: DemiplaneFavoriteAdventuringPlatformVarianceFields;
  demiplane_favorite_adventuring_platform_variance_order_by: DemiplaneFavoriteAdventuringPlatformVarianceOrderBy;
  demiplane_favorite_dice_roll: DemiplaneFavoriteDiceRoll;
  demiplane_favorite_dice_roll_aggregate: DemiplaneFavoriteDiceRollAggregate;
  demiplane_favorite_dice_roll_aggregate_fields: DemiplaneFavoriteDiceRollAggregateFields;
  demiplane_favorite_dice_roll_aggregate_order_by: DemiplaneFavoriteDiceRollAggregateOrderBy;
  demiplane_favorite_dice_roll_arr_rel_insert_input: DemiplaneFavoriteDiceRollArrRelInsertInput;
  demiplane_favorite_dice_roll_avg_fields: DemiplaneFavoriteDiceRollAvgFields;
  demiplane_favorite_dice_roll_avg_order_by: DemiplaneFavoriteDiceRollAvgOrderBy;
  demiplane_favorite_dice_roll_bool_exp: DemiplaneFavoriteDiceRollBoolExp;
  demiplane_favorite_dice_roll_inc_input: DemiplaneFavoriteDiceRollIncInput;
  demiplane_favorite_dice_roll_insert_input: DemiplaneFavoriteDiceRollInsertInput;
  demiplane_favorite_dice_roll_max_fields: DemiplaneFavoriteDiceRollMaxFields;
  demiplane_favorite_dice_roll_max_order_by: DemiplaneFavoriteDiceRollMaxOrderBy;
  demiplane_favorite_dice_roll_min_fields: DemiplaneFavoriteDiceRollMinFields;
  demiplane_favorite_dice_roll_min_order_by: DemiplaneFavoriteDiceRollMinOrderBy;
  demiplane_favorite_dice_roll_mutation_response: DemiplaneFavoriteDiceRollMutationResponse;
  demiplane_favorite_dice_roll_obj_rel_insert_input: DemiplaneFavoriteDiceRollObjRelInsertInput;
  demiplane_favorite_dice_roll_on_conflict: DemiplaneFavoriteDiceRollOnConflict;
  demiplane_favorite_dice_roll_order_by: DemiplaneFavoriteDiceRollOrderBy;
  demiplane_favorite_dice_roll_pk_columns_input: DemiplaneFavoriteDiceRollPkColumnsInput;
  demiplane_favorite_dice_roll_set_input: DemiplaneFavoriteDiceRollSetInput;
  demiplane_favorite_dice_roll_stddev_fields: DemiplaneFavoriteDiceRollStddevFields;
  demiplane_favorite_dice_roll_stddev_order_by: DemiplaneFavoriteDiceRollStddevOrderBy;
  demiplane_favorite_dice_roll_stddev_pop_fields: DemiplaneFavoriteDiceRollStddevPopFields;
  demiplane_favorite_dice_roll_stddev_pop_order_by: DemiplaneFavoriteDiceRollStddevPopOrderBy;
  demiplane_favorite_dice_roll_stddev_samp_fields: DemiplaneFavoriteDiceRollStddevSampFields;
  demiplane_favorite_dice_roll_stddev_samp_order_by: DemiplaneFavoriteDiceRollStddevSampOrderBy;
  demiplane_favorite_dice_roll_sum_fields: DemiplaneFavoriteDiceRollSumFields;
  demiplane_favorite_dice_roll_sum_order_by: DemiplaneFavoriteDiceRollSumOrderBy;
  demiplane_favorite_dice_roll_var_pop_fields: DemiplaneFavoriteDiceRollVarPopFields;
  demiplane_favorite_dice_roll_var_pop_order_by: DemiplaneFavoriteDiceRollVarPopOrderBy;
  demiplane_favorite_dice_roll_var_samp_fields: DemiplaneFavoriteDiceRollVarSampFields;
  demiplane_favorite_dice_roll_var_samp_order_by: DemiplaneFavoriteDiceRollVarSampOrderBy;
  demiplane_favorite_dice_roll_variance_fields: DemiplaneFavoriteDiceRollVarianceFields;
  demiplane_favorite_dice_roll_variance_order_by: DemiplaneFavoriteDiceRollVarianceOrderBy;
  demiplane_favorite_game: DemiplaneFavoriteGame;
  demiplane_favorite_game_aggregate: DemiplaneFavoriteGameAggregate;
  demiplane_favorite_game_aggregate_fields: DemiplaneFavoriteGameAggregateFields;
  demiplane_favorite_game_aggregate_order_by: DemiplaneFavoriteGameAggregateOrderBy;
  demiplane_favorite_game_arr_rel_insert_input: DemiplaneFavoriteGameArrRelInsertInput;
  demiplane_favorite_game_avg_fields: DemiplaneFavoriteGameAvgFields;
  demiplane_favorite_game_avg_order_by: DemiplaneFavoriteGameAvgOrderBy;
  demiplane_favorite_game_bool_exp: DemiplaneFavoriteGameBoolExp;
  demiplane_favorite_game_inc_input: DemiplaneFavoriteGameIncInput;
  demiplane_favorite_game_insert_input: DemiplaneFavoriteGameInsertInput;
  demiplane_favorite_game_max_fields: DemiplaneFavoriteGameMaxFields;
  demiplane_favorite_game_max_order_by: DemiplaneFavoriteGameMaxOrderBy;
  demiplane_favorite_game_min_fields: DemiplaneFavoriteGameMinFields;
  demiplane_favorite_game_min_order_by: DemiplaneFavoriteGameMinOrderBy;
  demiplane_favorite_game_mutation_response: DemiplaneFavoriteGameMutationResponse;
  demiplane_favorite_game_obj_rel_insert_input: DemiplaneFavoriteGameObjRelInsertInput;
  demiplane_favorite_game_on_conflict: DemiplaneFavoriteGameOnConflict;
  demiplane_favorite_game_order_by: DemiplaneFavoriteGameOrderBy;
  demiplane_favorite_game_pk_columns_input: DemiplaneFavoriteGamePkColumnsInput;
  demiplane_favorite_game_set_input: DemiplaneFavoriteGameSetInput;
  demiplane_favorite_game_stddev_fields: DemiplaneFavoriteGameStddevFields;
  demiplane_favorite_game_stddev_order_by: DemiplaneFavoriteGameStddevOrderBy;
  demiplane_favorite_game_stddev_pop_fields: DemiplaneFavoriteGameStddevPopFields;
  demiplane_favorite_game_stddev_pop_order_by: DemiplaneFavoriteGameStddevPopOrderBy;
  demiplane_favorite_game_stddev_samp_fields: DemiplaneFavoriteGameStddevSampFields;
  demiplane_favorite_game_stddev_samp_order_by: DemiplaneFavoriteGameStddevSampOrderBy;
  demiplane_favorite_game_sum_fields: DemiplaneFavoriteGameSumFields;
  demiplane_favorite_game_sum_order_by: DemiplaneFavoriteGameSumOrderBy;
  demiplane_favorite_game_var_pop_fields: DemiplaneFavoriteGameVarPopFields;
  demiplane_favorite_game_var_pop_order_by: DemiplaneFavoriteGameVarPopOrderBy;
  demiplane_favorite_game_var_samp_fields: DemiplaneFavoriteGameVarSampFields;
  demiplane_favorite_game_var_samp_order_by: DemiplaneFavoriteGameVarSampOrderBy;
  demiplane_favorite_game_variance_fields: DemiplaneFavoriteGameVarianceFields;
  demiplane_favorite_game_variance_order_by: DemiplaneFavoriteGameVarianceOrderBy;
  demiplane_game_type: DemiplaneGameType;
  demiplane_game_type_aggregate: DemiplaneGameTypeAggregate;
  demiplane_game_type_aggregate_fields: DemiplaneGameTypeAggregateFields;
  demiplane_game_type_aggregate_order_by: DemiplaneGameTypeAggregateOrderBy;
  demiplane_game_type_arr_rel_insert_input: DemiplaneGameTypeArrRelInsertInput;
  demiplane_game_type_avg_fields: DemiplaneGameTypeAvgFields;
  demiplane_game_type_avg_order_by: DemiplaneGameTypeAvgOrderBy;
  demiplane_game_type_bool_exp: DemiplaneGameTypeBoolExp;
  demiplane_game_type_inc_input: DemiplaneGameTypeIncInput;
  demiplane_game_type_insert_input: DemiplaneGameTypeInsertInput;
  demiplane_game_type_max_fields: DemiplaneGameTypeMaxFields;
  demiplane_game_type_max_order_by: DemiplaneGameTypeMaxOrderBy;
  demiplane_game_type_min_fields: DemiplaneGameTypeMinFields;
  demiplane_game_type_min_order_by: DemiplaneGameTypeMinOrderBy;
  demiplane_game_type_mutation_response: DemiplaneGameTypeMutationResponse;
  demiplane_game_type_obj_rel_insert_input: DemiplaneGameTypeObjRelInsertInput;
  demiplane_game_type_on_conflict: DemiplaneGameTypeOnConflict;
  demiplane_game_type_order_by: DemiplaneGameTypeOrderBy;
  demiplane_game_type_pk_columns_input: DemiplaneGameTypePkColumnsInput;
  demiplane_game_type_set_input: DemiplaneGameTypeSetInput;
  demiplane_game_type_stddev_fields: DemiplaneGameTypeStddevFields;
  demiplane_game_type_stddev_order_by: DemiplaneGameTypeStddevOrderBy;
  demiplane_game_type_stddev_pop_fields: DemiplaneGameTypeStddevPopFields;
  demiplane_game_type_stddev_pop_order_by: DemiplaneGameTypeStddevPopOrderBy;
  demiplane_game_type_stddev_samp_fields: DemiplaneGameTypeStddevSampFields;
  demiplane_game_type_stddev_samp_order_by: DemiplaneGameTypeStddevSampOrderBy;
  demiplane_game_type_sum_fields: DemiplaneGameTypeSumFields;
  demiplane_game_type_sum_order_by: DemiplaneGameTypeSumOrderBy;
  demiplane_game_type_var_pop_fields: DemiplaneGameTypeVarPopFields;
  demiplane_game_type_var_pop_order_by: DemiplaneGameTypeVarPopOrderBy;
  demiplane_game_type_var_samp_fields: DemiplaneGameTypeVarSampFields;
  demiplane_game_type_var_samp_order_by: DemiplaneGameTypeVarSampOrderBy;
  demiplane_game_type_variance_fields: DemiplaneGameTypeVarianceFields;
  demiplane_game_type_variance_order_by: DemiplaneGameTypeVarianceOrderBy;
  demiplane_gm_rating: DemiplaneGmRating;
  demiplane_gm_rating_aggregate: DemiplaneGmRatingAggregate;
  demiplane_gm_rating_aggregate_fields: DemiplaneGmRatingAggregateFields;
  demiplane_gm_rating_aggregate_order_by: DemiplaneGmRatingAggregateOrderBy;
  demiplane_gm_rating_arr_rel_insert_input: DemiplaneGmRatingArrRelInsertInput;
  demiplane_gm_rating_avg_fields: DemiplaneGmRatingAvgFields;
  demiplane_gm_rating_avg_order_by: DemiplaneGmRatingAvgOrderBy;
  demiplane_gm_rating_bool_exp: DemiplaneGmRatingBoolExp;
  demiplane_gm_rating_inc_input: DemiplaneGmRatingIncInput;
  demiplane_gm_rating_insert_input: DemiplaneGmRatingInsertInput;
  demiplane_gm_rating_max_fields: DemiplaneGmRatingMaxFields;
  demiplane_gm_rating_max_order_by: DemiplaneGmRatingMaxOrderBy;
  demiplane_gm_rating_min_fields: DemiplaneGmRatingMinFields;
  demiplane_gm_rating_min_order_by: DemiplaneGmRatingMinOrderBy;
  demiplane_gm_rating_mutation_response: DemiplaneGmRatingMutationResponse;
  demiplane_gm_rating_obj_rel_insert_input: DemiplaneGmRatingObjRelInsertInput;
  demiplane_gm_rating_on_conflict: DemiplaneGmRatingOnConflict;
  demiplane_gm_rating_order_by: DemiplaneGmRatingOrderBy;
  demiplane_gm_rating_pk_columns_input: DemiplaneGmRatingPkColumnsInput;
  demiplane_gm_rating_set_input: DemiplaneGmRatingSetInput;
  demiplane_gm_rating_stddev_fields: DemiplaneGmRatingStddevFields;
  demiplane_gm_rating_stddev_order_by: DemiplaneGmRatingStddevOrderBy;
  demiplane_gm_rating_stddev_pop_fields: DemiplaneGmRatingStddevPopFields;
  demiplane_gm_rating_stddev_pop_order_by: DemiplaneGmRatingStddevPopOrderBy;
  demiplane_gm_rating_stddev_samp_fields: DemiplaneGmRatingStddevSampFields;
  demiplane_gm_rating_stddev_samp_order_by: DemiplaneGmRatingStddevSampOrderBy;
  demiplane_gm_rating_sum_fields: DemiplaneGmRatingSumFields;
  demiplane_gm_rating_sum_order_by: DemiplaneGmRatingSumOrderBy;
  demiplane_gm_rating_var_pop_fields: DemiplaneGmRatingVarPopFields;
  demiplane_gm_rating_var_pop_order_by: DemiplaneGmRatingVarPopOrderBy;
  demiplane_gm_rating_var_samp_fields: DemiplaneGmRatingVarSampFields;
  demiplane_gm_rating_var_samp_order_by: DemiplaneGmRatingVarSampOrderBy;
  demiplane_gm_rating_variance_fields: DemiplaneGmRatingVarianceFields;
  demiplane_gm_rating_variance_order_by: DemiplaneGmRatingVarianceOrderBy;
  demiplane_gm_recommendation: DemiplaneGmRecommendation;
  demiplane_gm_recommendation_aggregate: DemiplaneGmRecommendationAggregate;
  demiplane_gm_recommendation_aggregate_fields: DemiplaneGmRecommendationAggregateFields;
  demiplane_gm_recommendation_aggregate_order_by: DemiplaneGmRecommendationAggregateOrderBy;
  demiplane_gm_recommendation_arr_rel_insert_input: DemiplaneGmRecommendationArrRelInsertInput;
  demiplane_gm_recommendation_avg_fields: DemiplaneGmRecommendationAvgFields;
  demiplane_gm_recommendation_avg_order_by: DemiplaneGmRecommendationAvgOrderBy;
  demiplane_gm_recommendation_bool_exp: DemiplaneGmRecommendationBoolExp;
  demiplane_gm_recommendation_inc_input: DemiplaneGmRecommendationIncInput;
  demiplane_gm_recommendation_insert_input: DemiplaneGmRecommendationInsertInput;
  demiplane_gm_recommendation_max_fields: DemiplaneGmRecommendationMaxFields;
  demiplane_gm_recommendation_max_order_by: DemiplaneGmRecommendationMaxOrderBy;
  demiplane_gm_recommendation_min_fields: DemiplaneGmRecommendationMinFields;
  demiplane_gm_recommendation_min_order_by: DemiplaneGmRecommendationMinOrderBy;
  demiplane_gm_recommendation_mutation_response: DemiplaneGmRecommendationMutationResponse;
  demiplane_gm_recommendation_obj_rel_insert_input: DemiplaneGmRecommendationObjRelInsertInput;
  demiplane_gm_recommendation_on_conflict: DemiplaneGmRecommendationOnConflict;
  demiplane_gm_recommendation_order_by: DemiplaneGmRecommendationOrderBy;
  demiplane_gm_recommendation_pk_columns_input: DemiplaneGmRecommendationPkColumnsInput;
  demiplane_gm_recommendation_set_input: DemiplaneGmRecommendationSetInput;
  demiplane_gm_recommendation_stddev_fields: DemiplaneGmRecommendationStddevFields;
  demiplane_gm_recommendation_stddev_order_by: DemiplaneGmRecommendationStddevOrderBy;
  demiplane_gm_recommendation_stddev_pop_fields: DemiplaneGmRecommendationStddevPopFields;
  demiplane_gm_recommendation_stddev_pop_order_by: DemiplaneGmRecommendationStddevPopOrderBy;
  demiplane_gm_recommendation_stddev_samp_fields: DemiplaneGmRecommendationStddevSampFields;
  demiplane_gm_recommendation_stddev_samp_order_by: DemiplaneGmRecommendationStddevSampOrderBy;
  demiplane_gm_recommendation_sum_fields: DemiplaneGmRecommendationSumFields;
  demiplane_gm_recommendation_sum_order_by: DemiplaneGmRecommendationSumOrderBy;
  demiplane_gm_recommendation_var_pop_fields: DemiplaneGmRecommendationVarPopFields;
  demiplane_gm_recommendation_var_pop_order_by: DemiplaneGmRecommendationVarPopOrderBy;
  demiplane_gm_recommendation_var_samp_fields: DemiplaneGmRecommendationVarSampFields;
  demiplane_gm_recommendation_var_samp_order_by: DemiplaneGmRecommendationVarSampOrderBy;
  demiplane_gm_recommendation_variance_fields: DemiplaneGmRecommendationVarianceFields;
  demiplane_gm_recommendation_variance_order_by: DemiplaneGmRecommendationVarianceOrderBy;
  demiplane_invitation: DemiplaneInvitation;
  demiplane_invitation_aggregate: DemiplaneInvitationAggregate;
  demiplane_invitation_aggregate_fields: DemiplaneInvitationAggregateFields;
  demiplane_invitation_aggregate_order_by: DemiplaneInvitationAggregateOrderBy;
  demiplane_invitation_arr_rel_insert_input: DemiplaneInvitationArrRelInsertInput;
  demiplane_invitation_avg_fields: DemiplaneInvitationAvgFields;
  demiplane_invitation_avg_order_by: DemiplaneInvitationAvgOrderBy;
  demiplane_invitation_bool_exp: DemiplaneInvitationBoolExp;
  demiplane_invitation_inc_input: DemiplaneInvitationIncInput;
  demiplane_invitation_insert_input: DemiplaneInvitationInsertInput;
  demiplane_invitation_max_fields: DemiplaneInvitationMaxFields;
  demiplane_invitation_max_order_by: DemiplaneInvitationMaxOrderBy;
  demiplane_invitation_min_fields: DemiplaneInvitationMinFields;
  demiplane_invitation_min_order_by: DemiplaneInvitationMinOrderBy;
  demiplane_invitation_mutation_response: DemiplaneInvitationMutationResponse;
  demiplane_invitation_obj_rel_insert_input: DemiplaneInvitationObjRelInsertInput;
  demiplane_invitation_on_conflict: DemiplaneInvitationOnConflict;
  demiplane_invitation_order_by: DemiplaneInvitationOrderBy;
  demiplane_invitation_pk_columns_input: DemiplaneInvitationPkColumnsInput;
  demiplane_invitation_set_input: DemiplaneInvitationSetInput;
  demiplane_invitation_stddev_fields: DemiplaneInvitationStddevFields;
  demiplane_invitation_stddev_order_by: DemiplaneInvitationStddevOrderBy;
  demiplane_invitation_stddev_pop_fields: DemiplaneInvitationStddevPopFields;
  demiplane_invitation_stddev_pop_order_by: DemiplaneInvitationStddevPopOrderBy;
  demiplane_invitation_stddev_samp_fields: DemiplaneInvitationStddevSampFields;
  demiplane_invitation_stddev_samp_order_by: DemiplaneInvitationStddevSampOrderBy;
  demiplane_invitation_sum_fields: DemiplaneInvitationSumFields;
  demiplane_invitation_sum_order_by: DemiplaneInvitationSumOrderBy;
  demiplane_invitation_var_pop_fields: DemiplaneInvitationVarPopFields;
  demiplane_invitation_var_pop_order_by: DemiplaneInvitationVarPopOrderBy;
  demiplane_invitation_var_samp_fields: DemiplaneInvitationVarSampFields;
  demiplane_invitation_var_samp_order_by: DemiplaneInvitationVarSampOrderBy;
  demiplane_invitation_variance_fields: DemiplaneInvitationVarianceFields;
  demiplane_invitation_variance_order_by: DemiplaneInvitationVarianceOrderBy;
  demiplane_journal: DemiplaneJournal;
  demiplane_journal_aggregate: DemiplaneJournalAggregate;
  demiplane_journal_aggregate_fields: DemiplaneJournalAggregateFields;
  demiplane_journal_aggregate_order_by: DemiplaneJournalAggregateOrderBy;
  demiplane_journal_arr_rel_insert_input: DemiplaneJournalArrRelInsertInput;
  demiplane_journal_avg_fields: DemiplaneJournalAvgFields;
  demiplane_journal_avg_order_by: DemiplaneJournalAvgOrderBy;
  demiplane_journal_bool_exp: DemiplaneJournalBoolExp;
  demiplane_journal_inc_input: DemiplaneJournalIncInput;
  demiplane_journal_insert_input: DemiplaneJournalInsertInput;
  demiplane_journal_max_fields: DemiplaneJournalMaxFields;
  demiplane_journal_max_order_by: DemiplaneJournalMaxOrderBy;
  demiplane_journal_min_fields: DemiplaneJournalMinFields;
  demiplane_journal_min_order_by: DemiplaneJournalMinOrderBy;
  demiplane_journal_mutation_response: DemiplaneJournalMutationResponse;
  demiplane_journal_note: DemiplaneJournalNote;
  demiplane_journal_note_aggregate: DemiplaneJournalNoteAggregate;
  demiplane_journal_note_aggregate_fields: DemiplaneJournalNoteAggregateFields;
  demiplane_journal_note_aggregate_order_by: DemiplaneJournalNoteAggregateOrderBy;
  demiplane_journal_note_append_input: DemiplaneJournalNoteAppendInput;
  demiplane_journal_note_arr_rel_insert_input: DemiplaneJournalNoteArrRelInsertInput;
  demiplane_journal_note_avg_fields: DemiplaneJournalNoteAvgFields;
  demiplane_journal_note_avg_order_by: DemiplaneJournalNoteAvgOrderBy;
  demiplane_journal_note_bool_exp: DemiplaneJournalNoteBoolExp;
  demiplane_journal_note_delete_at_path_input: DemiplaneJournalNoteDeleteAtPathInput;
  demiplane_journal_note_delete_elem_input: DemiplaneJournalNoteDeleteElemInput;
  demiplane_journal_note_delete_key_input: DemiplaneJournalNoteDeleteKeyInput;
  demiplane_journal_note_inc_input: DemiplaneJournalNoteIncInput;
  demiplane_journal_note_insert_input: DemiplaneJournalNoteInsertInput;
  demiplane_journal_note_max_fields: DemiplaneJournalNoteMaxFields;
  demiplane_journal_note_max_order_by: DemiplaneJournalNoteMaxOrderBy;
  demiplane_journal_note_min_fields: DemiplaneJournalNoteMinFields;
  demiplane_journal_note_min_order_by: DemiplaneJournalNoteMinOrderBy;
  demiplane_journal_note_mutation_response: DemiplaneJournalNoteMutationResponse;
  demiplane_journal_note_obj_rel_insert_input: DemiplaneJournalNoteObjRelInsertInput;
  demiplane_journal_note_on_conflict: DemiplaneJournalNoteOnConflict;
  demiplane_journal_note_order_by: DemiplaneJournalNoteOrderBy;
  demiplane_journal_note_pk_columns_input: DemiplaneJournalNotePkColumnsInput;
  demiplane_journal_note_prepend_input: DemiplaneJournalNotePrependInput;
  demiplane_journal_note_set_input: DemiplaneJournalNoteSetInput;
  demiplane_journal_note_share: DemiplaneJournalNoteShare;
  demiplane_journal_note_share_aggregate: DemiplaneJournalNoteShareAggregate;
  demiplane_journal_note_share_aggregate_fields: DemiplaneJournalNoteShareAggregateFields;
  demiplane_journal_note_share_aggregate_order_by: DemiplaneJournalNoteShareAggregateOrderBy;
  demiplane_journal_note_share_arr_rel_insert_input: DemiplaneJournalNoteShareArrRelInsertInput;
  demiplane_journal_note_share_avg_fields: DemiplaneJournalNoteShareAvgFields;
  demiplane_journal_note_share_avg_order_by: DemiplaneJournalNoteShareAvgOrderBy;
  demiplane_journal_note_share_bool_exp: DemiplaneJournalNoteShareBoolExp;
  demiplane_journal_note_share_inc_input: DemiplaneJournalNoteShareIncInput;
  demiplane_journal_note_share_insert_input: DemiplaneJournalNoteShareInsertInput;
  demiplane_journal_note_share_max_fields: DemiplaneJournalNoteShareMaxFields;
  demiplane_journal_note_share_max_order_by: DemiplaneJournalNoteShareMaxOrderBy;
  demiplane_journal_note_share_min_fields: DemiplaneJournalNoteShareMinFields;
  demiplane_journal_note_share_min_order_by: DemiplaneJournalNoteShareMinOrderBy;
  demiplane_journal_note_share_mutation_response: DemiplaneJournalNoteShareMutationResponse;
  demiplane_journal_note_share_obj_rel_insert_input: DemiplaneJournalNoteShareObjRelInsertInput;
  demiplane_journal_note_share_on_conflict: DemiplaneJournalNoteShareOnConflict;
  demiplane_journal_note_share_order_by: DemiplaneJournalNoteShareOrderBy;
  demiplane_journal_note_share_pk_columns_input: DemiplaneJournalNoteSharePkColumnsInput;
  demiplane_journal_note_share_set_input: DemiplaneJournalNoteShareSetInput;
  demiplane_journal_note_share_stddev_fields: DemiplaneJournalNoteShareStddevFields;
  demiplane_journal_note_share_stddev_order_by: DemiplaneJournalNoteShareStddevOrderBy;
  demiplane_journal_note_share_stddev_pop_fields: DemiplaneJournalNoteShareStddevPopFields;
  demiplane_journal_note_share_stddev_pop_order_by: DemiplaneJournalNoteShareStddevPopOrderBy;
  demiplane_journal_note_share_stddev_samp_fields: DemiplaneJournalNoteShareStddevSampFields;
  demiplane_journal_note_share_stddev_samp_order_by: DemiplaneJournalNoteShareStddevSampOrderBy;
  demiplane_journal_note_share_sum_fields: DemiplaneJournalNoteShareSumFields;
  demiplane_journal_note_share_sum_order_by: DemiplaneJournalNoteShareSumOrderBy;
  demiplane_journal_note_share_var_pop_fields: DemiplaneJournalNoteShareVarPopFields;
  demiplane_journal_note_share_var_pop_order_by: DemiplaneJournalNoteShareVarPopOrderBy;
  demiplane_journal_note_share_var_samp_fields: DemiplaneJournalNoteShareVarSampFields;
  demiplane_journal_note_share_var_samp_order_by: DemiplaneJournalNoteShareVarSampOrderBy;
  demiplane_journal_note_share_variance_fields: DemiplaneJournalNoteShareVarianceFields;
  demiplane_journal_note_share_variance_order_by: DemiplaneJournalNoteShareVarianceOrderBy;
  demiplane_journal_note_stddev_fields: DemiplaneJournalNoteStddevFields;
  demiplane_journal_note_stddev_order_by: DemiplaneJournalNoteStddevOrderBy;
  demiplane_journal_note_stddev_pop_fields: DemiplaneJournalNoteStddevPopFields;
  demiplane_journal_note_stddev_pop_order_by: DemiplaneJournalNoteStddevPopOrderBy;
  demiplane_journal_note_stddev_samp_fields: DemiplaneJournalNoteStddevSampFields;
  demiplane_journal_note_stddev_samp_order_by: DemiplaneJournalNoteStddevSampOrderBy;
  demiplane_journal_note_sum_fields: DemiplaneJournalNoteSumFields;
  demiplane_journal_note_sum_order_by: DemiplaneJournalNoteSumOrderBy;
  demiplane_journal_note_var_pop_fields: DemiplaneJournalNoteVarPopFields;
  demiplane_journal_note_var_pop_order_by: DemiplaneJournalNoteVarPopOrderBy;
  demiplane_journal_note_var_samp_fields: DemiplaneJournalNoteVarSampFields;
  demiplane_journal_note_var_samp_order_by: DemiplaneJournalNoteVarSampOrderBy;
  demiplane_journal_note_variance_fields: DemiplaneJournalNoteVarianceFields;
  demiplane_journal_note_variance_order_by: DemiplaneJournalNoteVarianceOrderBy;
  demiplane_journal_obj_rel_insert_input: DemiplaneJournalObjRelInsertInput;
  demiplane_journal_on_conflict: DemiplaneJournalOnConflict;
  demiplane_journal_order_by: DemiplaneJournalOrderBy;
  demiplane_journal_pk_columns_input: DemiplaneJournalPkColumnsInput;
  demiplane_journal_set_input: DemiplaneJournalSetInput;
  demiplane_journal_stddev_fields: DemiplaneJournalStddevFields;
  demiplane_journal_stddev_order_by: DemiplaneJournalStddevOrderBy;
  demiplane_journal_stddev_pop_fields: DemiplaneJournalStddevPopFields;
  demiplane_journal_stddev_pop_order_by: DemiplaneJournalStddevPopOrderBy;
  demiplane_journal_stddev_samp_fields: DemiplaneJournalStddevSampFields;
  demiplane_journal_stddev_samp_order_by: DemiplaneJournalStddevSampOrderBy;
  demiplane_journal_sum_fields: DemiplaneJournalSumFields;
  demiplane_journal_sum_order_by: DemiplaneJournalSumOrderBy;
  demiplane_journal_super_event: DemiplaneJournalSuperEvent;
  demiplane_journal_super_event_aggregate: DemiplaneJournalSuperEventAggregate;
  demiplane_journal_super_event_aggregate_fields: DemiplaneJournalSuperEventAggregateFields;
  demiplane_journal_super_event_aggregate_order_by: DemiplaneJournalSuperEventAggregateOrderBy;
  demiplane_journal_super_event_arr_rel_insert_input: DemiplaneJournalSuperEventArrRelInsertInput;
  demiplane_journal_super_event_avg_fields: DemiplaneJournalSuperEventAvgFields;
  demiplane_journal_super_event_avg_order_by: DemiplaneJournalSuperEventAvgOrderBy;
  demiplane_journal_super_event_bool_exp: DemiplaneJournalSuperEventBoolExp;
  demiplane_journal_super_event_inc_input: DemiplaneJournalSuperEventIncInput;
  demiplane_journal_super_event_insert_input: DemiplaneJournalSuperEventInsertInput;
  demiplane_journal_super_event_max_fields: DemiplaneJournalSuperEventMaxFields;
  demiplane_journal_super_event_max_order_by: DemiplaneJournalSuperEventMaxOrderBy;
  demiplane_journal_super_event_min_fields: DemiplaneJournalSuperEventMinFields;
  demiplane_journal_super_event_min_order_by: DemiplaneJournalSuperEventMinOrderBy;
  demiplane_journal_super_event_mutation_response: DemiplaneJournalSuperEventMutationResponse;
  demiplane_journal_super_event_obj_rel_insert_input: DemiplaneJournalSuperEventObjRelInsertInput;
  demiplane_journal_super_event_on_conflict: DemiplaneJournalSuperEventOnConflict;
  demiplane_journal_super_event_order_by: DemiplaneJournalSuperEventOrderBy;
  demiplane_journal_super_event_pk_columns_input: DemiplaneJournalSuperEventPkColumnsInput;
  demiplane_journal_super_event_read_status: DemiplaneJournalSuperEventReadStatus;
  demiplane_journal_super_event_read_status_aggregate: DemiplaneJournalSuperEventReadStatusAggregate;
  demiplane_journal_super_event_read_status_aggregate_fields: DemiplaneJournalSuperEventReadStatusAggregateFields;
  demiplane_journal_super_event_read_status_aggregate_order_by: DemiplaneJournalSuperEventReadStatusAggregateOrderBy;
  demiplane_journal_super_event_read_status_arr_rel_insert_input: DemiplaneJournalSuperEventReadStatusArrRelInsertInput;
  demiplane_journal_super_event_read_status_avg_fields: DemiplaneJournalSuperEventReadStatusAvgFields;
  demiplane_journal_super_event_read_status_avg_order_by: DemiplaneJournalSuperEventReadStatusAvgOrderBy;
  demiplane_journal_super_event_read_status_bool_exp: DemiplaneJournalSuperEventReadStatusBoolExp;
  demiplane_journal_super_event_read_status_inc_input: DemiplaneJournalSuperEventReadStatusIncInput;
  demiplane_journal_super_event_read_status_insert_input: DemiplaneJournalSuperEventReadStatusInsertInput;
  demiplane_journal_super_event_read_status_max_fields: DemiplaneJournalSuperEventReadStatusMaxFields;
  demiplane_journal_super_event_read_status_max_order_by: DemiplaneJournalSuperEventReadStatusMaxOrderBy;
  demiplane_journal_super_event_read_status_min_fields: DemiplaneJournalSuperEventReadStatusMinFields;
  demiplane_journal_super_event_read_status_min_order_by: DemiplaneJournalSuperEventReadStatusMinOrderBy;
  demiplane_journal_super_event_read_status_mutation_response: DemiplaneJournalSuperEventReadStatusMutationResponse;
  demiplane_journal_super_event_read_status_obj_rel_insert_input: DemiplaneJournalSuperEventReadStatusObjRelInsertInput;
  demiplane_journal_super_event_read_status_on_conflict: DemiplaneJournalSuperEventReadStatusOnConflict;
  demiplane_journal_super_event_read_status_order_by: DemiplaneJournalSuperEventReadStatusOrderBy;
  demiplane_journal_super_event_read_status_pk_columns_input: DemiplaneJournalSuperEventReadStatusPkColumnsInput;
  demiplane_journal_super_event_read_status_set_input: DemiplaneJournalSuperEventReadStatusSetInput;
  demiplane_journal_super_event_read_status_stddev_fields: DemiplaneJournalSuperEventReadStatusStddevFields;
  demiplane_journal_super_event_read_status_stddev_order_by: DemiplaneJournalSuperEventReadStatusStddevOrderBy;
  demiplane_journal_super_event_read_status_stddev_pop_fields: DemiplaneJournalSuperEventReadStatusStddevPopFields;
  demiplane_journal_super_event_read_status_stddev_pop_order_by: DemiplaneJournalSuperEventReadStatusStddevPopOrderBy;
  demiplane_journal_super_event_read_status_stddev_samp_fields: DemiplaneJournalSuperEventReadStatusStddevSampFields;
  demiplane_journal_super_event_read_status_stddev_samp_order_by: DemiplaneJournalSuperEventReadStatusStddevSampOrderBy;
  demiplane_journal_super_event_read_status_sum_fields: DemiplaneJournalSuperEventReadStatusSumFields;
  demiplane_journal_super_event_read_status_sum_order_by: DemiplaneJournalSuperEventReadStatusSumOrderBy;
  demiplane_journal_super_event_read_status_var_pop_fields: DemiplaneJournalSuperEventReadStatusVarPopFields;
  demiplane_journal_super_event_read_status_var_pop_order_by: DemiplaneJournalSuperEventReadStatusVarPopOrderBy;
  demiplane_journal_super_event_read_status_var_samp_fields: DemiplaneJournalSuperEventReadStatusVarSampFields;
  demiplane_journal_super_event_read_status_var_samp_order_by: DemiplaneJournalSuperEventReadStatusVarSampOrderBy;
  demiplane_journal_super_event_read_status_variance_fields: DemiplaneJournalSuperEventReadStatusVarianceFields;
  demiplane_journal_super_event_read_status_variance_order_by: DemiplaneJournalSuperEventReadStatusVarianceOrderBy;
  demiplane_journal_super_event_set_input: DemiplaneJournalSuperEventSetInput;
  demiplane_journal_super_event_stddev_fields: DemiplaneJournalSuperEventStddevFields;
  demiplane_journal_super_event_stddev_order_by: DemiplaneJournalSuperEventStddevOrderBy;
  demiplane_journal_super_event_stddev_pop_fields: DemiplaneJournalSuperEventStddevPopFields;
  demiplane_journal_super_event_stddev_pop_order_by: DemiplaneJournalSuperEventStddevPopOrderBy;
  demiplane_journal_super_event_stddev_samp_fields: DemiplaneJournalSuperEventStddevSampFields;
  demiplane_journal_super_event_stddev_samp_order_by: DemiplaneJournalSuperEventStddevSampOrderBy;
  demiplane_journal_super_event_sum_fields: DemiplaneJournalSuperEventSumFields;
  demiplane_journal_super_event_sum_order_by: DemiplaneJournalSuperEventSumOrderBy;
  demiplane_journal_super_event_var_pop_fields: DemiplaneJournalSuperEventVarPopFields;
  demiplane_journal_super_event_var_pop_order_by: DemiplaneJournalSuperEventVarPopOrderBy;
  demiplane_journal_super_event_var_samp_fields: DemiplaneJournalSuperEventVarSampFields;
  demiplane_journal_super_event_var_samp_order_by: DemiplaneJournalSuperEventVarSampOrderBy;
  demiplane_journal_super_event_variance_fields: DemiplaneJournalSuperEventVarianceFields;
  demiplane_journal_super_event_variance_order_by: DemiplaneJournalSuperEventVarianceOrderBy;
  demiplane_journal_title: DemiplaneJournalTitle;
  demiplane_journal_title_aggregate: DemiplaneJournalTitleAggregate;
  demiplane_journal_title_aggregate_fields: DemiplaneJournalTitleAggregateFields;
  demiplane_journal_title_aggregate_order_by: DemiplaneJournalTitleAggregateOrderBy;
  demiplane_journal_title_arr_rel_insert_input: DemiplaneJournalTitleArrRelInsertInput;
  demiplane_journal_title_avg_fields: DemiplaneJournalTitleAvgFields;
  demiplane_journal_title_avg_order_by: DemiplaneJournalTitleAvgOrderBy;
  demiplane_journal_title_bool_exp: DemiplaneJournalTitleBoolExp;
  demiplane_journal_title_inc_input: DemiplaneJournalTitleIncInput;
  demiplane_journal_title_insert_input: DemiplaneJournalTitleInsertInput;
  demiplane_journal_title_max_fields: DemiplaneJournalTitleMaxFields;
  demiplane_journal_title_max_order_by: DemiplaneJournalTitleMaxOrderBy;
  demiplane_journal_title_min_fields: DemiplaneJournalTitleMinFields;
  demiplane_journal_title_min_order_by: DemiplaneJournalTitleMinOrderBy;
  demiplane_journal_title_mutation_response: DemiplaneJournalTitleMutationResponse;
  demiplane_journal_title_obj_rel_insert_input: DemiplaneJournalTitleObjRelInsertInput;
  demiplane_journal_title_on_conflict: DemiplaneJournalTitleOnConflict;
  demiplane_journal_title_order_by: DemiplaneJournalTitleOrderBy;
  demiplane_journal_title_pk_columns_input: DemiplaneJournalTitlePkColumnsInput;
  demiplane_journal_title_set_input: DemiplaneJournalTitleSetInput;
  demiplane_journal_title_stddev_fields: DemiplaneJournalTitleStddevFields;
  demiplane_journal_title_stddev_order_by: DemiplaneJournalTitleStddevOrderBy;
  demiplane_journal_title_stddev_pop_fields: DemiplaneJournalTitleStddevPopFields;
  demiplane_journal_title_stddev_pop_order_by: DemiplaneJournalTitleStddevPopOrderBy;
  demiplane_journal_title_stddev_samp_fields: DemiplaneJournalTitleStddevSampFields;
  demiplane_journal_title_stddev_samp_order_by: DemiplaneJournalTitleStddevSampOrderBy;
  demiplane_journal_title_sum_fields: DemiplaneJournalTitleSumFields;
  demiplane_journal_title_sum_order_by: DemiplaneJournalTitleSumOrderBy;
  demiplane_journal_title_var_pop_fields: DemiplaneJournalTitleVarPopFields;
  demiplane_journal_title_var_pop_order_by: DemiplaneJournalTitleVarPopOrderBy;
  demiplane_journal_title_var_samp_fields: DemiplaneJournalTitleVarSampFields;
  demiplane_journal_title_var_samp_order_by: DemiplaneJournalTitleVarSampOrderBy;
  demiplane_journal_title_variance_fields: DemiplaneJournalTitleVarianceFields;
  demiplane_journal_title_variance_order_by: DemiplaneJournalTitleVarianceOrderBy;
  demiplane_journal_var_pop_fields: DemiplaneJournalVarPopFields;
  demiplane_journal_var_pop_order_by: DemiplaneJournalVarPopOrderBy;
  demiplane_journal_var_samp_fields: DemiplaneJournalVarSampFields;
  demiplane_journal_var_samp_order_by: DemiplaneJournalVarSampOrderBy;
  demiplane_journal_variance_fields: DemiplaneJournalVarianceFields;
  demiplane_journal_variance_order_by: DemiplaneJournalVarianceOrderBy;
  demiplane_matchmaking_token: DemiplaneMatchmakingToken;
  demiplane_matchmaking_token_aggregate: DemiplaneMatchmakingTokenAggregate;
  demiplane_matchmaking_token_aggregate_fields: DemiplaneMatchmakingTokenAggregateFields;
  demiplane_matchmaking_token_aggregate_order_by: DemiplaneMatchmakingTokenAggregateOrderBy;
  demiplane_matchmaking_token_arr_rel_insert_input: DemiplaneMatchmakingTokenArrRelInsertInput;
  demiplane_matchmaking_token_avg_fields: DemiplaneMatchmakingTokenAvgFields;
  demiplane_matchmaking_token_avg_order_by: DemiplaneMatchmakingTokenAvgOrderBy;
  demiplane_matchmaking_token_bool_exp: DemiplaneMatchmakingTokenBoolExp;
  demiplane_matchmaking_token_inc_input: DemiplaneMatchmakingTokenIncInput;
  demiplane_matchmaking_token_insert_input: DemiplaneMatchmakingTokenInsertInput;
  demiplane_matchmaking_token_max_fields: DemiplaneMatchmakingTokenMaxFields;
  demiplane_matchmaking_token_max_order_by: DemiplaneMatchmakingTokenMaxOrderBy;
  demiplane_matchmaking_token_min_fields: DemiplaneMatchmakingTokenMinFields;
  demiplane_matchmaking_token_min_order_by: DemiplaneMatchmakingTokenMinOrderBy;
  demiplane_matchmaking_token_mutation_response: DemiplaneMatchmakingTokenMutationResponse;
  demiplane_matchmaking_token_obj_rel_insert_input: DemiplaneMatchmakingTokenObjRelInsertInput;
  demiplane_matchmaking_token_on_conflict: DemiplaneMatchmakingTokenOnConflict;
  demiplane_matchmaking_token_order_by: DemiplaneMatchmakingTokenOrderBy;
  demiplane_matchmaking_token_pk_columns_input: DemiplaneMatchmakingTokenPkColumnsInput;
  demiplane_matchmaking_token_set_input: DemiplaneMatchmakingTokenSetInput;
  demiplane_matchmaking_token_stddev_fields: DemiplaneMatchmakingTokenStddevFields;
  demiplane_matchmaking_token_stddev_order_by: DemiplaneMatchmakingTokenStddevOrderBy;
  demiplane_matchmaking_token_stddev_pop_fields: DemiplaneMatchmakingTokenStddevPopFields;
  demiplane_matchmaking_token_stddev_pop_order_by: DemiplaneMatchmakingTokenStddevPopOrderBy;
  demiplane_matchmaking_token_stddev_samp_fields: DemiplaneMatchmakingTokenStddevSampFields;
  demiplane_matchmaking_token_stddev_samp_order_by: DemiplaneMatchmakingTokenStddevSampOrderBy;
  demiplane_matchmaking_token_sum_fields: DemiplaneMatchmakingTokenSumFields;
  demiplane_matchmaking_token_sum_order_by: DemiplaneMatchmakingTokenSumOrderBy;
  demiplane_matchmaking_token_var_pop_fields: DemiplaneMatchmakingTokenVarPopFields;
  demiplane_matchmaking_token_var_pop_order_by: DemiplaneMatchmakingTokenVarPopOrderBy;
  demiplane_matchmaking_token_var_samp_fields: DemiplaneMatchmakingTokenVarSampFields;
  demiplane_matchmaking_token_var_samp_order_by: DemiplaneMatchmakingTokenVarSampOrderBy;
  demiplane_matchmaking_token_variance_fields: DemiplaneMatchmakingTokenVarianceFields;
  demiplane_matchmaking_token_variance_order_by: DemiplaneMatchmakingTokenVarianceOrderBy;
  demiplane_migrations: DemiplaneMigrations;
  demiplane_migrations_aggregate: DemiplaneMigrationsAggregate;
  demiplane_migrations_aggregate_fields: DemiplaneMigrationsAggregateFields;
  demiplane_migrations_aggregate_order_by: DemiplaneMigrationsAggregateOrderBy;
  demiplane_migrations_arr_rel_insert_input: DemiplaneMigrationsArrRelInsertInput;
  demiplane_migrations_avg_fields: DemiplaneMigrationsAvgFields;
  demiplane_migrations_avg_order_by: DemiplaneMigrationsAvgOrderBy;
  demiplane_migrations_bool_exp: DemiplaneMigrationsBoolExp;
  demiplane_migrations_inc_input: DemiplaneMigrationsIncInput;
  demiplane_migrations_insert_input: DemiplaneMigrationsInsertInput;
  demiplane_migrations_max_fields: DemiplaneMigrationsMaxFields;
  demiplane_migrations_max_order_by: DemiplaneMigrationsMaxOrderBy;
  demiplane_migrations_min_fields: DemiplaneMigrationsMinFields;
  demiplane_migrations_min_order_by: DemiplaneMigrationsMinOrderBy;
  demiplane_migrations_mutation_response: DemiplaneMigrationsMutationResponse;
  demiplane_migrations_obj_rel_insert_input: DemiplaneMigrationsObjRelInsertInput;
  demiplane_migrations_on_conflict: DemiplaneMigrationsOnConflict;
  demiplane_migrations_order_by: DemiplaneMigrationsOrderBy;
  demiplane_migrations_pk_columns_input: DemiplaneMigrationsPkColumnsInput;
  demiplane_migrations_set_input: DemiplaneMigrationsSetInput;
  demiplane_migrations_stddev_fields: DemiplaneMigrationsStddevFields;
  demiplane_migrations_stddev_order_by: DemiplaneMigrationsStddevOrderBy;
  demiplane_migrations_stddev_pop_fields: DemiplaneMigrationsStddevPopFields;
  demiplane_migrations_stddev_pop_order_by: DemiplaneMigrationsStddevPopOrderBy;
  demiplane_migrations_stddev_samp_fields: DemiplaneMigrationsStddevSampFields;
  demiplane_migrations_stddev_samp_order_by: DemiplaneMigrationsStddevSampOrderBy;
  demiplane_migrations_sum_fields: DemiplaneMigrationsSumFields;
  demiplane_migrations_sum_order_by: DemiplaneMigrationsSumOrderBy;
  demiplane_migrations_var_pop_fields: DemiplaneMigrationsVarPopFields;
  demiplane_migrations_var_pop_order_by: DemiplaneMigrationsVarPopOrderBy;
  demiplane_migrations_var_samp_fields: DemiplaneMigrationsVarSampFields;
  demiplane_migrations_var_samp_order_by: DemiplaneMigrationsVarSampOrderBy;
  demiplane_migrations_variance_fields: DemiplaneMigrationsVarianceFields;
  demiplane_migrations_variance_order_by: DemiplaneMigrationsVarianceOrderBy;
  demiplane_notification_history: DemiplaneNotificationHistory;
  demiplane_notification_history_aggregate: DemiplaneNotificationHistoryAggregate;
  demiplane_notification_history_aggregate_fields: DemiplaneNotificationHistoryAggregateFields;
  demiplane_notification_history_aggregate_order_by: DemiplaneNotificationHistoryAggregateOrderBy;
  demiplane_notification_history_arr_rel_insert_input: DemiplaneNotificationHistoryArrRelInsertInput;
  demiplane_notification_history_avg_fields: DemiplaneNotificationHistoryAvgFields;
  demiplane_notification_history_avg_order_by: DemiplaneNotificationHistoryAvgOrderBy;
  demiplane_notification_history_bool_exp: DemiplaneNotificationHistoryBoolExp;
  demiplane_notification_history_inc_input: DemiplaneNotificationHistoryIncInput;
  demiplane_notification_history_insert_input: DemiplaneNotificationHistoryInsertInput;
  demiplane_notification_history_max_fields: DemiplaneNotificationHistoryMaxFields;
  demiplane_notification_history_max_order_by: DemiplaneNotificationHistoryMaxOrderBy;
  demiplane_notification_history_min_fields: DemiplaneNotificationHistoryMinFields;
  demiplane_notification_history_min_order_by: DemiplaneNotificationHistoryMinOrderBy;
  demiplane_notification_history_mutation_response: DemiplaneNotificationHistoryMutationResponse;
  demiplane_notification_history_obj_rel_insert_input: DemiplaneNotificationHistoryObjRelInsertInput;
  demiplane_notification_history_on_conflict: DemiplaneNotificationHistoryOnConflict;
  demiplane_notification_history_order_by: DemiplaneNotificationHistoryOrderBy;
  demiplane_notification_history_pk_columns_input: DemiplaneNotificationHistoryPkColumnsInput;
  demiplane_notification_history_set_input: DemiplaneNotificationHistorySetInput;
  demiplane_notification_history_stddev_fields: DemiplaneNotificationHistoryStddevFields;
  demiplane_notification_history_stddev_order_by: DemiplaneNotificationHistoryStddevOrderBy;
  demiplane_notification_history_stddev_pop_fields: DemiplaneNotificationHistoryStddevPopFields;
  demiplane_notification_history_stddev_pop_order_by: DemiplaneNotificationHistoryStddevPopOrderBy;
  demiplane_notification_history_stddev_samp_fields: DemiplaneNotificationHistoryStddevSampFields;
  demiplane_notification_history_stddev_samp_order_by: DemiplaneNotificationHistoryStddevSampOrderBy;
  demiplane_notification_history_sum_fields: DemiplaneNotificationHistorySumFields;
  demiplane_notification_history_sum_order_by: DemiplaneNotificationHistorySumOrderBy;
  demiplane_notification_history_var_pop_fields: DemiplaneNotificationHistoryVarPopFields;
  demiplane_notification_history_var_pop_order_by: DemiplaneNotificationHistoryVarPopOrderBy;
  demiplane_notification_history_var_samp_fields: DemiplaneNotificationHistoryVarSampFields;
  demiplane_notification_history_var_samp_order_by: DemiplaneNotificationHistoryVarSampOrderBy;
  demiplane_notification_history_variance_fields: DemiplaneNotificationHistoryVarianceFields;
  demiplane_notification_history_variance_order_by: DemiplaneNotificationHistoryVarianceOrderBy;
  demiplane_payment: DemiplanePayment;
  demiplane_payment_aggregate: DemiplanePaymentAggregate;
  demiplane_payment_aggregate_fields: DemiplanePaymentAggregateFields;
  demiplane_payment_aggregate_order_by: DemiplanePaymentAggregateOrderBy;
  demiplane_payment_arr_rel_insert_input: DemiplanePaymentArrRelInsertInput;
  demiplane_payment_avg_fields: DemiplanePaymentAvgFields;
  demiplane_payment_avg_order_by: DemiplanePaymentAvgOrderBy;
  demiplane_payment_bool_exp: DemiplanePaymentBoolExp;
  demiplane_payment_inc_input: DemiplanePaymentIncInput;
  demiplane_payment_insert_input: DemiplanePaymentInsertInput;
  demiplane_payment_max_fields: DemiplanePaymentMaxFields;
  demiplane_payment_max_order_by: DemiplanePaymentMaxOrderBy;
  demiplane_payment_min_fields: DemiplanePaymentMinFields;
  demiplane_payment_min_order_by: DemiplanePaymentMinOrderBy;
  demiplane_payment_mutation_response: DemiplanePaymentMutationResponse;
  demiplane_payment_obj_rel_insert_input: DemiplanePaymentObjRelInsertInput;
  demiplane_payment_on_conflict: DemiplanePaymentOnConflict;
  demiplane_payment_order_by: DemiplanePaymentOrderBy;
  demiplane_payment_pk_columns_input: DemiplanePaymentPkColumnsInput;
  demiplane_payment_set_input: DemiplanePaymentSetInput;
  demiplane_payment_stddev_fields: DemiplanePaymentStddevFields;
  demiplane_payment_stddev_order_by: DemiplanePaymentStddevOrderBy;
  demiplane_payment_stddev_pop_fields: DemiplanePaymentStddevPopFields;
  demiplane_payment_stddev_pop_order_by: DemiplanePaymentStddevPopOrderBy;
  demiplane_payment_stddev_samp_fields: DemiplanePaymentStddevSampFields;
  demiplane_payment_stddev_samp_order_by: DemiplanePaymentStddevSampOrderBy;
  demiplane_payment_sum_fields: DemiplanePaymentSumFields;
  demiplane_payment_sum_order_by: DemiplanePaymentSumOrderBy;
  demiplane_payment_var_pop_fields: DemiplanePaymentVarPopFields;
  demiplane_payment_var_pop_order_by: DemiplanePaymentVarPopOrderBy;
  demiplane_payment_var_samp_fields: DemiplanePaymentVarSampFields;
  demiplane_payment_var_samp_order_by: DemiplanePaymentVarSampOrderBy;
  demiplane_payment_variance_fields: DemiplanePaymentVarianceFields;
  demiplane_payment_variance_order_by: DemiplanePaymentVarianceOrderBy;
  demiplane_player_award: DemiplanePlayerAward;
  demiplane_player_award_aggregate: DemiplanePlayerAwardAggregate;
  demiplane_player_award_aggregate_fields: DemiplanePlayerAwardAggregateFields;
  demiplane_player_award_aggregate_order_by: DemiplanePlayerAwardAggregateOrderBy;
  demiplane_player_award_arr_rel_insert_input: DemiplanePlayerAwardArrRelInsertInput;
  demiplane_player_award_avg_fields: DemiplanePlayerAwardAvgFields;
  demiplane_player_award_avg_order_by: DemiplanePlayerAwardAvgOrderBy;
  demiplane_player_award_bool_exp: DemiplanePlayerAwardBoolExp;
  demiplane_player_award_inc_input: DemiplanePlayerAwardIncInput;
  demiplane_player_award_insert_input: DemiplanePlayerAwardInsertInput;
  demiplane_player_award_max_fields: DemiplanePlayerAwardMaxFields;
  demiplane_player_award_max_order_by: DemiplanePlayerAwardMaxOrderBy;
  demiplane_player_award_min_fields: DemiplanePlayerAwardMinFields;
  demiplane_player_award_min_order_by: DemiplanePlayerAwardMinOrderBy;
  demiplane_player_award_mutation_response: DemiplanePlayerAwardMutationResponse;
  demiplane_player_award_obj_rel_insert_input: DemiplanePlayerAwardObjRelInsertInput;
  demiplane_player_award_on_conflict: DemiplanePlayerAwardOnConflict;
  demiplane_player_award_order_by: DemiplanePlayerAwardOrderBy;
  demiplane_player_award_pk_columns_input: DemiplanePlayerAwardPkColumnsInput;
  demiplane_player_award_set_input: DemiplanePlayerAwardSetInput;
  demiplane_player_award_stddev_fields: DemiplanePlayerAwardStddevFields;
  demiplane_player_award_stddev_order_by: DemiplanePlayerAwardStddevOrderBy;
  demiplane_player_award_stddev_pop_fields: DemiplanePlayerAwardStddevPopFields;
  demiplane_player_award_stddev_pop_order_by: DemiplanePlayerAwardStddevPopOrderBy;
  demiplane_player_award_stddev_samp_fields: DemiplanePlayerAwardStddevSampFields;
  demiplane_player_award_stddev_samp_order_by: DemiplanePlayerAwardStddevSampOrderBy;
  demiplane_player_award_sum_fields: DemiplanePlayerAwardSumFields;
  demiplane_player_award_sum_order_by: DemiplanePlayerAwardSumOrderBy;
  demiplane_player_award_var_pop_fields: DemiplanePlayerAwardVarPopFields;
  demiplane_player_award_var_pop_order_by: DemiplanePlayerAwardVarPopOrderBy;
  demiplane_player_award_var_samp_fields: DemiplanePlayerAwardVarSampFields;
  demiplane_player_award_var_samp_order_by: DemiplanePlayerAwardVarSampOrderBy;
  demiplane_player_award_variance_fields: DemiplanePlayerAwardVarianceFields;
  demiplane_player_award_variance_order_by: DemiplanePlayerAwardVarianceOrderBy;
  demiplane_player_rating: DemiplanePlayerRating;
  demiplane_player_rating_aggregate: DemiplanePlayerRatingAggregate;
  demiplane_player_rating_aggregate_fields: DemiplanePlayerRatingAggregateFields;
  demiplane_player_rating_aggregate_order_by: DemiplanePlayerRatingAggregateOrderBy;
  demiplane_player_rating_arr_rel_insert_input: DemiplanePlayerRatingArrRelInsertInput;
  demiplane_player_rating_avg_fields: DemiplanePlayerRatingAvgFields;
  demiplane_player_rating_avg_order_by: DemiplanePlayerRatingAvgOrderBy;
  demiplane_player_rating_bool_exp: DemiplanePlayerRatingBoolExp;
  demiplane_player_rating_inc_input: DemiplanePlayerRatingIncInput;
  demiplane_player_rating_insert_input: DemiplanePlayerRatingInsertInput;
  demiplane_player_rating_max_fields: DemiplanePlayerRatingMaxFields;
  demiplane_player_rating_max_order_by: DemiplanePlayerRatingMaxOrderBy;
  demiplane_player_rating_min_fields: DemiplanePlayerRatingMinFields;
  demiplane_player_rating_min_order_by: DemiplanePlayerRatingMinOrderBy;
  demiplane_player_rating_mutation_response: DemiplanePlayerRatingMutationResponse;
  demiplane_player_rating_obj_rel_insert_input: DemiplanePlayerRatingObjRelInsertInput;
  demiplane_player_rating_on_conflict: DemiplanePlayerRatingOnConflict;
  demiplane_player_rating_order_by: DemiplanePlayerRatingOrderBy;
  demiplane_player_rating_pk_columns_input: DemiplanePlayerRatingPkColumnsInput;
  demiplane_player_rating_set_input: DemiplanePlayerRatingSetInput;
  demiplane_player_rating_stddev_fields: DemiplanePlayerRatingStddevFields;
  demiplane_player_rating_stddev_order_by: DemiplanePlayerRatingStddevOrderBy;
  demiplane_player_rating_stddev_pop_fields: DemiplanePlayerRatingStddevPopFields;
  demiplane_player_rating_stddev_pop_order_by: DemiplanePlayerRatingStddevPopOrderBy;
  demiplane_player_rating_stddev_samp_fields: DemiplanePlayerRatingStddevSampFields;
  demiplane_player_rating_stddev_samp_order_by: DemiplanePlayerRatingStddevSampOrderBy;
  demiplane_player_rating_sum_fields: DemiplanePlayerRatingSumFields;
  demiplane_player_rating_sum_order_by: DemiplanePlayerRatingSumOrderBy;
  demiplane_player_rating_var_pop_fields: DemiplanePlayerRatingVarPopFields;
  demiplane_player_rating_var_pop_order_by: DemiplanePlayerRatingVarPopOrderBy;
  demiplane_player_rating_var_samp_fields: DemiplanePlayerRatingVarSampFields;
  demiplane_player_rating_var_samp_order_by: DemiplanePlayerRatingVarSampOrderBy;
  demiplane_player_rating_variance_fields: DemiplanePlayerRatingVarianceFields;
  demiplane_player_rating_variance_order_by: DemiplanePlayerRatingVarianceOrderBy;
  demiplane_pronoun: DemiplanePronoun;
  demiplane_pronoun_aggregate: DemiplanePronounAggregate;
  demiplane_pronoun_aggregate_fields: DemiplanePronounAggregateFields;
  demiplane_pronoun_aggregate_order_by: DemiplanePronounAggregateOrderBy;
  demiplane_pronoun_arr_rel_insert_input: DemiplanePronounArrRelInsertInput;
  demiplane_pronoun_avg_fields: DemiplanePronounAvgFields;
  demiplane_pronoun_avg_order_by: DemiplanePronounAvgOrderBy;
  demiplane_pronoun_bool_exp: DemiplanePronounBoolExp;
  demiplane_pronoun_inc_input: DemiplanePronounIncInput;
  demiplane_pronoun_insert_input: DemiplanePronounInsertInput;
  demiplane_pronoun_max_fields: DemiplanePronounMaxFields;
  demiplane_pronoun_max_order_by: DemiplanePronounMaxOrderBy;
  demiplane_pronoun_min_fields: DemiplanePronounMinFields;
  demiplane_pronoun_min_order_by: DemiplanePronounMinOrderBy;
  demiplane_pronoun_mutation_response: DemiplanePronounMutationResponse;
  demiplane_pronoun_obj_rel_insert_input: DemiplanePronounObjRelInsertInput;
  demiplane_pronoun_on_conflict: DemiplanePronounOnConflict;
  demiplane_pronoun_order_by: DemiplanePronounOrderBy;
  demiplane_pronoun_pk_columns_input: DemiplanePronounPkColumnsInput;
  demiplane_pronoun_set_input: DemiplanePronounSetInput;
  demiplane_pronoun_stddev_fields: DemiplanePronounStddevFields;
  demiplane_pronoun_stddev_order_by: DemiplanePronounStddevOrderBy;
  demiplane_pronoun_stddev_pop_fields: DemiplanePronounStddevPopFields;
  demiplane_pronoun_stddev_pop_order_by: DemiplanePronounStddevPopOrderBy;
  demiplane_pronoun_stddev_samp_fields: DemiplanePronounStddevSampFields;
  demiplane_pronoun_stddev_samp_order_by: DemiplanePronounStddevSampOrderBy;
  demiplane_pronoun_sum_fields: DemiplanePronounSumFields;
  demiplane_pronoun_sum_order_by: DemiplanePronounSumOrderBy;
  demiplane_pronoun_var_pop_fields: DemiplanePronounVarPopFields;
  demiplane_pronoun_var_pop_order_by: DemiplanePronounVarPopOrderBy;
  demiplane_pronoun_var_samp_fields: DemiplanePronounVarSampFields;
  demiplane_pronoun_var_samp_order_by: DemiplanePronounVarSampOrderBy;
  demiplane_pronoun_variance_fields: DemiplanePronounVarianceFields;
  demiplane_pronoun_variance_order_by: DemiplanePronounVarianceOrderBy;
  demiplane_region: DemiplaneRegion;
  demiplane_region_aggregate: DemiplaneRegionAggregate;
  demiplane_region_aggregate_fields: DemiplaneRegionAggregateFields;
  demiplane_region_aggregate_order_by: DemiplaneRegionAggregateOrderBy;
  demiplane_region_arr_rel_insert_input: DemiplaneRegionArrRelInsertInput;
  demiplane_region_avg_fields: DemiplaneRegionAvgFields;
  demiplane_region_avg_order_by: DemiplaneRegionAvgOrderBy;
  demiplane_region_bool_exp: DemiplaneRegionBoolExp;
  demiplane_region_inc_input: DemiplaneRegionIncInput;
  demiplane_region_insert_input: DemiplaneRegionInsertInput;
  demiplane_region_max_fields: DemiplaneRegionMaxFields;
  demiplane_region_max_order_by: DemiplaneRegionMaxOrderBy;
  demiplane_region_min_fields: DemiplaneRegionMinFields;
  demiplane_region_min_order_by: DemiplaneRegionMinOrderBy;
  demiplane_region_mutation_response: DemiplaneRegionMutationResponse;
  demiplane_region_obj_rel_insert_input: DemiplaneRegionObjRelInsertInput;
  demiplane_region_on_conflict: DemiplaneRegionOnConflict;
  demiplane_region_order_by: DemiplaneRegionOrderBy;
  demiplane_region_pk_columns_input: DemiplaneRegionPkColumnsInput;
  demiplane_region_set_input: DemiplaneRegionSetInput;
  demiplane_region_stddev_fields: DemiplaneRegionStddevFields;
  demiplane_region_stddev_order_by: DemiplaneRegionStddevOrderBy;
  demiplane_region_stddev_pop_fields: DemiplaneRegionStddevPopFields;
  demiplane_region_stddev_pop_order_by: DemiplaneRegionStddevPopOrderBy;
  demiplane_region_stddev_samp_fields: DemiplaneRegionStddevSampFields;
  demiplane_region_stddev_samp_order_by: DemiplaneRegionStddevSampOrderBy;
  demiplane_region_sum_fields: DemiplaneRegionSumFields;
  demiplane_region_sum_order_by: DemiplaneRegionSumOrderBy;
  demiplane_region_var_pop_fields: DemiplaneRegionVarPopFields;
  demiplane_region_var_pop_order_by: DemiplaneRegionVarPopOrderBy;
  demiplane_region_var_samp_fields: DemiplaneRegionVarSampFields;
  demiplane_region_var_samp_order_by: DemiplaneRegionVarSampOrderBy;
  demiplane_region_variance_fields: DemiplaneRegionVarianceFields;
  demiplane_region_variance_order_by: DemiplaneRegionVarianceOrderBy;
  demiplane_role: DemiplaneRole;
  demiplane_role_aggregate: DemiplaneRoleAggregate;
  demiplane_role_aggregate_fields: DemiplaneRoleAggregateFields;
  demiplane_role_aggregate_order_by: DemiplaneRoleAggregateOrderBy;
  demiplane_role_arr_rel_insert_input: DemiplaneRoleArrRelInsertInput;
  demiplane_role_avg_fields: DemiplaneRoleAvgFields;
  demiplane_role_avg_order_by: DemiplaneRoleAvgOrderBy;
  demiplane_role_bool_exp: DemiplaneRoleBoolExp;
  demiplane_role_inc_input: DemiplaneRoleIncInput;
  demiplane_role_insert_input: DemiplaneRoleInsertInput;
  demiplane_role_max_fields: DemiplaneRoleMaxFields;
  demiplane_role_max_order_by: DemiplaneRoleMaxOrderBy;
  demiplane_role_min_fields: DemiplaneRoleMinFields;
  demiplane_role_min_order_by: DemiplaneRoleMinOrderBy;
  demiplane_role_mutation_response: DemiplaneRoleMutationResponse;
  demiplane_role_obj_rel_insert_input: DemiplaneRoleObjRelInsertInput;
  demiplane_role_on_conflict: DemiplaneRoleOnConflict;
  demiplane_role_order_by: DemiplaneRoleOrderBy;
  demiplane_role_pk_columns_input: DemiplaneRolePkColumnsInput;
  demiplane_role_set_input: DemiplaneRoleSetInput;
  demiplane_role_stddev_fields: DemiplaneRoleStddevFields;
  demiplane_role_stddev_order_by: DemiplaneRoleStddevOrderBy;
  demiplane_role_stddev_pop_fields: DemiplaneRoleStddevPopFields;
  demiplane_role_stddev_pop_order_by: DemiplaneRoleStddevPopOrderBy;
  demiplane_role_stddev_samp_fields: DemiplaneRoleStddevSampFields;
  demiplane_role_stddev_samp_order_by: DemiplaneRoleStddevSampOrderBy;
  demiplane_role_sum_fields: DemiplaneRoleSumFields;
  demiplane_role_sum_order_by: DemiplaneRoleSumOrderBy;
  demiplane_role_var_pop_fields: DemiplaneRoleVarPopFields;
  demiplane_role_var_pop_order_by: DemiplaneRoleVarPopOrderBy;
  demiplane_role_var_samp_fields: DemiplaneRoleVarSampFields;
  demiplane_role_var_samp_order_by: DemiplaneRoleVarSampOrderBy;
  demiplane_role_variance_fields: DemiplaneRoleVarianceFields;
  demiplane_role_variance_order_by: DemiplaneRoleVarianceOrderBy;
  demiplane_session: DemiplaneSession;
  demiplane_session_aggregate: DemiplaneSessionAggregate;
  demiplane_session_aggregate_fields: DemiplaneSessionAggregateFields;
  demiplane_session_aggregate_order_by: DemiplaneSessionAggregateOrderBy;
  demiplane_session_arr_rel_insert_input: DemiplaneSessionArrRelInsertInput;
  demiplane_session_avg_fields: DemiplaneSessionAvgFields;
  demiplane_session_avg_order_by: DemiplaneSessionAvgOrderBy;
  demiplane_session_bool_exp: DemiplaneSessionBoolExp;
  demiplane_session_inc_input: DemiplaneSessionIncInput;
  demiplane_session_insert_input: DemiplaneSessionInsertInput;
  demiplane_session_max_fields: DemiplaneSessionMaxFields;
  demiplane_session_max_order_by: DemiplaneSessionMaxOrderBy;
  demiplane_session_min_fields: DemiplaneSessionMinFields;
  demiplane_session_min_order_by: DemiplaneSessionMinOrderBy;
  demiplane_session_mutation_response: DemiplaneSessionMutationResponse;
  demiplane_session_obj_rel_insert_input: DemiplaneSessionObjRelInsertInput;
  demiplane_session_on_conflict: DemiplaneSessionOnConflict;
  demiplane_session_order_by: DemiplaneSessionOrderBy;
  demiplane_session_pk_columns_input: DemiplaneSessionPkColumnsInput;
  demiplane_session_player: DemiplaneSessionPlayer;
  demiplane_session_player_aggregate: DemiplaneSessionPlayerAggregate;
  demiplane_session_player_aggregate_fields: DemiplaneSessionPlayerAggregateFields;
  demiplane_session_player_aggregate_order_by: DemiplaneSessionPlayerAggregateOrderBy;
  demiplane_session_player_arr_rel_insert_input: DemiplaneSessionPlayerArrRelInsertInput;
  demiplane_session_player_avg_fields: DemiplaneSessionPlayerAvgFields;
  demiplane_session_player_avg_order_by: DemiplaneSessionPlayerAvgOrderBy;
  demiplane_session_player_bool_exp: DemiplaneSessionPlayerBoolExp;
  demiplane_session_player_inc_input: DemiplaneSessionPlayerIncInput;
  demiplane_session_player_insert_input: DemiplaneSessionPlayerInsertInput;
  demiplane_session_player_max_fields: DemiplaneSessionPlayerMaxFields;
  demiplane_session_player_max_order_by: DemiplaneSessionPlayerMaxOrderBy;
  demiplane_session_player_min_fields: DemiplaneSessionPlayerMinFields;
  demiplane_session_player_min_order_by: DemiplaneSessionPlayerMinOrderBy;
  demiplane_session_player_mutation_response: DemiplaneSessionPlayerMutationResponse;
  demiplane_session_player_obj_rel_insert_input: DemiplaneSessionPlayerObjRelInsertInput;
  demiplane_session_player_on_conflict: DemiplaneSessionPlayerOnConflict;
  demiplane_session_player_order_by: DemiplaneSessionPlayerOrderBy;
  demiplane_session_player_pk_columns_input: DemiplaneSessionPlayerPkColumnsInput;
  demiplane_session_player_set_input: DemiplaneSessionPlayerSetInput;
  demiplane_session_player_stddev_fields: DemiplaneSessionPlayerStddevFields;
  demiplane_session_player_stddev_order_by: DemiplaneSessionPlayerStddevOrderBy;
  demiplane_session_player_stddev_pop_fields: DemiplaneSessionPlayerStddevPopFields;
  demiplane_session_player_stddev_pop_order_by: DemiplaneSessionPlayerStddevPopOrderBy;
  demiplane_session_player_stddev_samp_fields: DemiplaneSessionPlayerStddevSampFields;
  demiplane_session_player_stddev_samp_order_by: DemiplaneSessionPlayerStddevSampOrderBy;
  demiplane_session_player_sum_fields: DemiplaneSessionPlayerSumFields;
  demiplane_session_player_sum_order_by: DemiplaneSessionPlayerSumOrderBy;
  demiplane_session_player_var_pop_fields: DemiplaneSessionPlayerVarPopFields;
  demiplane_session_player_var_pop_order_by: DemiplaneSessionPlayerVarPopOrderBy;
  demiplane_session_player_var_samp_fields: DemiplaneSessionPlayerVarSampFields;
  demiplane_session_player_var_samp_order_by: DemiplaneSessionPlayerVarSampOrderBy;
  demiplane_session_player_variance_fields: DemiplaneSessionPlayerVarianceFields;
  demiplane_session_player_variance_order_by: DemiplaneSessionPlayerVarianceOrderBy;
  demiplane_session_set_input: DemiplaneSessionSetInput;
  demiplane_session_stddev_fields: DemiplaneSessionStddevFields;
  demiplane_session_stddev_order_by: DemiplaneSessionStddevOrderBy;
  demiplane_session_stddev_pop_fields: DemiplaneSessionStddevPopFields;
  demiplane_session_stddev_pop_order_by: DemiplaneSessionStddevPopOrderBy;
  demiplane_session_stddev_samp_fields: DemiplaneSessionStddevSampFields;
  demiplane_session_stddev_samp_order_by: DemiplaneSessionStddevSampOrderBy;
  demiplane_session_sum_fields: DemiplaneSessionSumFields;
  demiplane_session_sum_order_by: DemiplaneSessionSumOrderBy;
  demiplane_session_var_pop_fields: DemiplaneSessionVarPopFields;
  demiplane_session_var_pop_order_by: DemiplaneSessionVarPopOrderBy;
  demiplane_session_var_samp_fields: DemiplaneSessionVarSampFields;
  demiplane_session_var_samp_order_by: DemiplaneSessionVarSampOrderBy;
  demiplane_session_variance_fields: DemiplaneSessionVarianceFields;
  demiplane_session_variance_order_by: DemiplaneSessionVarianceOrderBy;
  demiplane_snippet: DemiplaneSnippet;
  demiplane_snippet_aggregate: DemiplaneSnippetAggregate;
  demiplane_snippet_aggregate_fields: DemiplaneSnippetAggregateFields;
  demiplane_snippet_aggregate_order_by: DemiplaneSnippetAggregateOrderBy;
  demiplane_snippet_arr_rel_insert_input: DemiplaneSnippetArrRelInsertInput;
  demiplane_snippet_avg_fields: DemiplaneSnippetAvgFields;
  demiplane_snippet_avg_order_by: DemiplaneSnippetAvgOrderBy;
  demiplane_snippet_bool_exp: DemiplaneSnippetBoolExp;
  demiplane_snippet_inc_input: DemiplaneSnippetIncInput;
  demiplane_snippet_insert_input: DemiplaneSnippetInsertInput;
  demiplane_snippet_max_fields: DemiplaneSnippetMaxFields;
  demiplane_snippet_max_order_by: DemiplaneSnippetMaxOrderBy;
  demiplane_snippet_min_fields: DemiplaneSnippetMinFields;
  demiplane_snippet_min_order_by: DemiplaneSnippetMinOrderBy;
  demiplane_snippet_mutation_response: DemiplaneSnippetMutationResponse;
  demiplane_snippet_obj_rel_insert_input: DemiplaneSnippetObjRelInsertInput;
  demiplane_snippet_on_conflict: DemiplaneSnippetOnConflict;
  demiplane_snippet_order_by: DemiplaneSnippetOrderBy;
  demiplane_snippet_pk_columns_input: DemiplaneSnippetPkColumnsInput;
  demiplane_snippet_set_input: DemiplaneSnippetSetInput;
  demiplane_snippet_stddev_fields: DemiplaneSnippetStddevFields;
  demiplane_snippet_stddev_order_by: DemiplaneSnippetStddevOrderBy;
  demiplane_snippet_stddev_pop_fields: DemiplaneSnippetStddevPopFields;
  demiplane_snippet_stddev_pop_order_by: DemiplaneSnippetStddevPopOrderBy;
  demiplane_snippet_stddev_samp_fields: DemiplaneSnippetStddevSampFields;
  demiplane_snippet_stddev_samp_order_by: DemiplaneSnippetStddevSampOrderBy;
  demiplane_snippet_sum_fields: DemiplaneSnippetSumFields;
  demiplane_snippet_sum_order_by: DemiplaneSnippetSumOrderBy;
  demiplane_snippet_var_pop_fields: DemiplaneSnippetVarPopFields;
  demiplane_snippet_var_pop_order_by: DemiplaneSnippetVarPopOrderBy;
  demiplane_snippet_var_samp_fields: DemiplaneSnippetVarSampFields;
  demiplane_snippet_var_samp_order_by: DemiplaneSnippetVarSampOrderBy;
  demiplane_snippet_variance_fields: DemiplaneSnippetVarianceFields;
  demiplane_snippet_variance_order_by: DemiplaneSnippetVarianceOrderBy;
  demiplane_task: DemiplaneTask;
  demiplane_task_aggregate: DemiplaneTaskAggregate;
  demiplane_task_aggregate_fields: DemiplaneTaskAggregateFields;
  demiplane_task_aggregate_order_by: DemiplaneTaskAggregateOrderBy;
  demiplane_task_arr_rel_insert_input: DemiplaneTaskArrRelInsertInput;
  demiplane_task_avg_fields: DemiplaneTaskAvgFields;
  demiplane_task_avg_order_by: DemiplaneTaskAvgOrderBy;
  demiplane_task_bool_exp: DemiplaneTaskBoolExp;
  demiplane_task_inc_input: DemiplaneTaskIncInput;
  demiplane_task_insert_input: DemiplaneTaskInsertInput;
  demiplane_task_max_fields: DemiplaneTaskMaxFields;
  demiplane_task_max_order_by: DemiplaneTaskMaxOrderBy;
  demiplane_task_min_fields: DemiplaneTaskMinFields;
  demiplane_task_min_order_by: DemiplaneTaskMinOrderBy;
  demiplane_task_mutation_response: DemiplaneTaskMutationResponse;
  demiplane_task_obj_rel_insert_input: DemiplaneTaskObjRelInsertInput;
  demiplane_task_on_conflict: DemiplaneTaskOnConflict;
  demiplane_task_order_by: DemiplaneTaskOrderBy;
  demiplane_task_pk_columns_input: DemiplaneTaskPkColumnsInput;
  demiplane_task_read_status: DemiplaneTaskReadStatus;
  demiplane_task_read_status_aggregate: DemiplaneTaskReadStatusAggregate;
  demiplane_task_read_status_aggregate_fields: DemiplaneTaskReadStatusAggregateFields;
  demiplane_task_read_status_aggregate_order_by: DemiplaneTaskReadStatusAggregateOrderBy;
  demiplane_task_read_status_arr_rel_insert_input: DemiplaneTaskReadStatusArrRelInsertInput;
  demiplane_task_read_status_avg_fields: DemiplaneTaskReadStatusAvgFields;
  demiplane_task_read_status_avg_order_by: DemiplaneTaskReadStatusAvgOrderBy;
  demiplane_task_read_status_bool_exp: DemiplaneTaskReadStatusBoolExp;
  demiplane_task_read_status_inc_input: DemiplaneTaskReadStatusIncInput;
  demiplane_task_read_status_insert_input: DemiplaneTaskReadStatusInsertInput;
  demiplane_task_read_status_max_fields: DemiplaneTaskReadStatusMaxFields;
  demiplane_task_read_status_max_order_by: DemiplaneTaskReadStatusMaxOrderBy;
  demiplane_task_read_status_min_fields: DemiplaneTaskReadStatusMinFields;
  demiplane_task_read_status_min_order_by: DemiplaneTaskReadStatusMinOrderBy;
  demiplane_task_read_status_mutation_response: DemiplaneTaskReadStatusMutationResponse;
  demiplane_task_read_status_obj_rel_insert_input: DemiplaneTaskReadStatusObjRelInsertInput;
  demiplane_task_read_status_on_conflict: DemiplaneTaskReadStatusOnConflict;
  demiplane_task_read_status_order_by: DemiplaneTaskReadStatusOrderBy;
  demiplane_task_read_status_pk_columns_input: DemiplaneTaskReadStatusPkColumnsInput;
  demiplane_task_read_status_set_input: DemiplaneTaskReadStatusSetInput;
  demiplane_task_read_status_stddev_fields: DemiplaneTaskReadStatusStddevFields;
  demiplane_task_read_status_stddev_order_by: DemiplaneTaskReadStatusStddevOrderBy;
  demiplane_task_read_status_stddev_pop_fields: DemiplaneTaskReadStatusStddevPopFields;
  demiplane_task_read_status_stddev_pop_order_by: DemiplaneTaskReadStatusStddevPopOrderBy;
  demiplane_task_read_status_stddev_samp_fields: DemiplaneTaskReadStatusStddevSampFields;
  demiplane_task_read_status_stddev_samp_order_by: DemiplaneTaskReadStatusStddevSampOrderBy;
  demiplane_task_read_status_sum_fields: DemiplaneTaskReadStatusSumFields;
  demiplane_task_read_status_sum_order_by: DemiplaneTaskReadStatusSumOrderBy;
  demiplane_task_read_status_var_pop_fields: DemiplaneTaskReadStatusVarPopFields;
  demiplane_task_read_status_var_pop_order_by: DemiplaneTaskReadStatusVarPopOrderBy;
  demiplane_task_read_status_var_samp_fields: DemiplaneTaskReadStatusVarSampFields;
  demiplane_task_read_status_var_samp_order_by: DemiplaneTaskReadStatusVarSampOrderBy;
  demiplane_task_read_status_variance_fields: DemiplaneTaskReadStatusVarianceFields;
  demiplane_task_read_status_variance_order_by: DemiplaneTaskReadStatusVarianceOrderBy;
  demiplane_task_set_input: DemiplaneTaskSetInput;
  demiplane_task_stddev_fields: DemiplaneTaskStddevFields;
  demiplane_task_stddev_order_by: DemiplaneTaskStddevOrderBy;
  demiplane_task_stddev_pop_fields: DemiplaneTaskStddevPopFields;
  demiplane_task_stddev_pop_order_by: DemiplaneTaskStddevPopOrderBy;
  demiplane_task_stddev_samp_fields: DemiplaneTaskStddevSampFields;
  demiplane_task_stddev_samp_order_by: DemiplaneTaskStddevSampOrderBy;
  demiplane_task_sum_fields: DemiplaneTaskSumFields;
  demiplane_task_sum_order_by: DemiplaneTaskSumOrderBy;
  demiplane_task_var_pop_fields: DemiplaneTaskVarPopFields;
  demiplane_task_var_pop_order_by: DemiplaneTaskVarPopOrderBy;
  demiplane_task_var_samp_fields: DemiplaneTaskVarSampFields;
  demiplane_task_var_samp_order_by: DemiplaneTaskVarSampOrderBy;
  demiplane_task_variance_fields: DemiplaneTaskVarianceFields;
  demiplane_task_variance_order_by: DemiplaneTaskVarianceOrderBy;
  demiplane_tax_nexus_region: DemiplaneTaxNexusRegion;
  demiplane_tax_nexus_region_aggregate: DemiplaneTaxNexusRegionAggregate;
  demiplane_tax_nexus_region_aggregate_fields: DemiplaneTaxNexusRegionAggregateFields;
  demiplane_tax_nexus_region_aggregate_order_by: DemiplaneTaxNexusRegionAggregateOrderBy;
  demiplane_tax_nexus_region_arr_rel_insert_input: DemiplaneTaxNexusRegionArrRelInsertInput;
  demiplane_tax_nexus_region_avg_fields: DemiplaneTaxNexusRegionAvgFields;
  demiplane_tax_nexus_region_avg_order_by: DemiplaneTaxNexusRegionAvgOrderBy;
  demiplane_tax_nexus_region_bool_exp: DemiplaneTaxNexusRegionBoolExp;
  demiplane_tax_nexus_region_inc_input: DemiplaneTaxNexusRegionIncInput;
  demiplane_tax_nexus_region_insert_input: DemiplaneTaxNexusRegionInsertInput;
  demiplane_tax_nexus_region_max_fields: DemiplaneTaxNexusRegionMaxFields;
  demiplane_tax_nexus_region_max_order_by: DemiplaneTaxNexusRegionMaxOrderBy;
  demiplane_tax_nexus_region_min_fields: DemiplaneTaxNexusRegionMinFields;
  demiplane_tax_nexus_region_min_order_by: DemiplaneTaxNexusRegionMinOrderBy;
  demiplane_tax_nexus_region_mutation_response: DemiplaneTaxNexusRegionMutationResponse;
  demiplane_tax_nexus_region_obj_rel_insert_input: DemiplaneTaxNexusRegionObjRelInsertInput;
  demiplane_tax_nexus_region_on_conflict: DemiplaneTaxNexusRegionOnConflict;
  demiplane_tax_nexus_region_order_by: DemiplaneTaxNexusRegionOrderBy;
  demiplane_tax_nexus_region_pk_columns_input: DemiplaneTaxNexusRegionPkColumnsInput;
  demiplane_tax_nexus_region_set_input: DemiplaneTaxNexusRegionSetInput;
  demiplane_tax_nexus_region_stddev_fields: DemiplaneTaxNexusRegionStddevFields;
  demiplane_tax_nexus_region_stddev_order_by: DemiplaneTaxNexusRegionStddevOrderBy;
  demiplane_tax_nexus_region_stddev_pop_fields: DemiplaneTaxNexusRegionStddevPopFields;
  demiplane_tax_nexus_region_stddev_pop_order_by: DemiplaneTaxNexusRegionStddevPopOrderBy;
  demiplane_tax_nexus_region_stddev_samp_fields: DemiplaneTaxNexusRegionStddevSampFields;
  demiplane_tax_nexus_region_stddev_samp_order_by: DemiplaneTaxNexusRegionStddevSampOrderBy;
  demiplane_tax_nexus_region_sum_fields: DemiplaneTaxNexusRegionSumFields;
  demiplane_tax_nexus_region_sum_order_by: DemiplaneTaxNexusRegionSumOrderBy;
  demiplane_tax_nexus_region_var_pop_fields: DemiplaneTaxNexusRegionVarPopFields;
  demiplane_tax_nexus_region_var_pop_order_by: DemiplaneTaxNexusRegionVarPopOrderBy;
  demiplane_tax_nexus_region_var_samp_fields: DemiplaneTaxNexusRegionVarSampFields;
  demiplane_tax_nexus_region_var_samp_order_by: DemiplaneTaxNexusRegionVarSampOrderBy;
  demiplane_tax_nexus_region_variance_fields: DemiplaneTaxNexusRegionVarianceFields;
  demiplane_tax_nexus_region_variance_order_by: DemiplaneTaxNexusRegionVarianceOrderBy;
  demiplane_transaction_receipt: DemiplaneTransactionReceipt;
  demiplane_transaction_receipt_aggregate: DemiplaneTransactionReceiptAggregate;
  demiplane_transaction_receipt_aggregate_fields: DemiplaneTransactionReceiptAggregateFields;
  demiplane_transaction_receipt_aggregate_order_by: DemiplaneTransactionReceiptAggregateOrderBy;
  demiplane_transaction_receipt_arr_rel_insert_input: DemiplaneTransactionReceiptArrRelInsertInput;
  demiplane_transaction_receipt_avg_fields: DemiplaneTransactionReceiptAvgFields;
  demiplane_transaction_receipt_avg_order_by: DemiplaneTransactionReceiptAvgOrderBy;
  demiplane_transaction_receipt_bool_exp: DemiplaneTransactionReceiptBoolExp;
  demiplane_transaction_receipt_inc_input: DemiplaneTransactionReceiptIncInput;
  demiplane_transaction_receipt_insert_input: DemiplaneTransactionReceiptInsertInput;
  demiplane_transaction_receipt_max_fields: DemiplaneTransactionReceiptMaxFields;
  demiplane_transaction_receipt_max_order_by: DemiplaneTransactionReceiptMaxOrderBy;
  demiplane_transaction_receipt_min_fields: DemiplaneTransactionReceiptMinFields;
  demiplane_transaction_receipt_min_order_by: DemiplaneTransactionReceiptMinOrderBy;
  demiplane_transaction_receipt_mutation_response: DemiplaneTransactionReceiptMutationResponse;
  demiplane_transaction_receipt_obj_rel_insert_input: DemiplaneTransactionReceiptObjRelInsertInput;
  demiplane_transaction_receipt_on_conflict: DemiplaneTransactionReceiptOnConflict;
  demiplane_transaction_receipt_order_by: DemiplaneTransactionReceiptOrderBy;
  demiplane_transaction_receipt_pk_columns_input: DemiplaneTransactionReceiptPkColumnsInput;
  demiplane_transaction_receipt_set_input: DemiplaneTransactionReceiptSetInput;
  demiplane_transaction_receipt_stddev_fields: DemiplaneTransactionReceiptStddevFields;
  demiplane_transaction_receipt_stddev_order_by: DemiplaneTransactionReceiptStddevOrderBy;
  demiplane_transaction_receipt_stddev_pop_fields: DemiplaneTransactionReceiptStddevPopFields;
  demiplane_transaction_receipt_stddev_pop_order_by: DemiplaneTransactionReceiptStddevPopOrderBy;
  demiplane_transaction_receipt_stddev_samp_fields: DemiplaneTransactionReceiptStddevSampFields;
  demiplane_transaction_receipt_stddev_samp_order_by: DemiplaneTransactionReceiptStddevSampOrderBy;
  demiplane_transaction_receipt_sum_fields: DemiplaneTransactionReceiptSumFields;
  demiplane_transaction_receipt_sum_order_by: DemiplaneTransactionReceiptSumOrderBy;
  demiplane_transaction_receipt_var_pop_fields: DemiplaneTransactionReceiptVarPopFields;
  demiplane_transaction_receipt_var_pop_order_by: DemiplaneTransactionReceiptVarPopOrderBy;
  demiplane_transaction_receipt_var_samp_fields: DemiplaneTransactionReceiptVarSampFields;
  demiplane_transaction_receipt_var_samp_order_by: DemiplaneTransactionReceiptVarSampOrderBy;
  demiplane_transaction_receipt_variance_fields: DemiplaneTransactionReceiptVarianceFields;
  demiplane_transaction_receipt_variance_order_by: DemiplaneTransactionReceiptVarianceOrderBy;
  demiplane_upload: DemiplaneUpload;
  demiplane_upload_aggregate: DemiplaneUploadAggregate;
  demiplane_upload_aggregate_fields: DemiplaneUploadAggregateFields;
  demiplane_upload_aggregate_order_by: DemiplaneUploadAggregateOrderBy;
  demiplane_upload_arr_rel_insert_input: DemiplaneUploadArrRelInsertInput;
  demiplane_upload_avg_fields: DemiplaneUploadAvgFields;
  demiplane_upload_avg_order_by: DemiplaneUploadAvgOrderBy;
  demiplane_upload_bool_exp: DemiplaneUploadBoolExp;
  demiplane_upload_inc_input: DemiplaneUploadIncInput;
  demiplane_upload_insert_input: DemiplaneUploadInsertInput;
  demiplane_upload_max_fields: DemiplaneUploadMaxFields;
  demiplane_upload_max_order_by: DemiplaneUploadMaxOrderBy;
  demiplane_upload_min_fields: DemiplaneUploadMinFields;
  demiplane_upload_min_order_by: DemiplaneUploadMinOrderBy;
  demiplane_upload_mutation_response: DemiplaneUploadMutationResponse;
  demiplane_upload_obj_rel_insert_input: DemiplaneUploadObjRelInsertInput;
  demiplane_upload_on_conflict: DemiplaneUploadOnConflict;
  demiplane_upload_order_by: DemiplaneUploadOrderBy;
  demiplane_upload_pk_columns_input: DemiplaneUploadPkColumnsInput;
  demiplane_upload_set_input: DemiplaneUploadSetInput;
  demiplane_upload_stddev_fields: DemiplaneUploadStddevFields;
  demiplane_upload_stddev_order_by: DemiplaneUploadStddevOrderBy;
  demiplane_upload_stddev_pop_fields: DemiplaneUploadStddevPopFields;
  demiplane_upload_stddev_pop_order_by: DemiplaneUploadStddevPopOrderBy;
  demiplane_upload_stddev_samp_fields: DemiplaneUploadStddevSampFields;
  demiplane_upload_stddev_samp_order_by: DemiplaneUploadStddevSampOrderBy;
  demiplane_upload_sum_fields: DemiplaneUploadSumFields;
  demiplane_upload_sum_order_by: DemiplaneUploadSumOrderBy;
  demiplane_upload_var_pop_fields: DemiplaneUploadVarPopFields;
  demiplane_upload_var_pop_order_by: DemiplaneUploadVarPopOrderBy;
  demiplane_upload_var_samp_fields: DemiplaneUploadVarSampFields;
  demiplane_upload_var_samp_order_by: DemiplaneUploadVarSampOrderBy;
  demiplane_upload_variance_fields: DemiplaneUploadVarianceFields;
  demiplane_upload_variance_order_by: DemiplaneUploadVarianceOrderBy;
  demiplane_urap: DemiplaneUrap;
  demiplane_urap_aggregate: DemiplaneUrapAggregate;
  demiplane_urap_aggregate_fields: DemiplaneUrapAggregateFields;
  demiplane_urap_aggregate_order_by: DemiplaneUrapAggregateOrderBy;
  demiplane_urap_arr_rel_insert_input: DemiplaneUrapArrRelInsertInput;
  demiplane_urap_avg_fields: DemiplaneUrapAvgFields;
  demiplane_urap_avg_order_by: DemiplaneUrapAvgOrderBy;
  demiplane_urap_bool_exp: DemiplaneUrapBoolExp;
  demiplane_urap_inc_input: DemiplaneUrapIncInput;
  demiplane_urap_insert_input: DemiplaneUrapInsertInput;
  demiplane_urap_max_fields: DemiplaneUrapMaxFields;
  demiplane_urap_max_order_by: DemiplaneUrapMaxOrderBy;
  demiplane_urap_min_fields: DemiplaneUrapMinFields;
  demiplane_urap_min_order_by: DemiplaneUrapMinOrderBy;
  demiplane_urap_mutation_response: DemiplaneUrapMutationResponse;
  demiplane_urap_obj_rel_insert_input: DemiplaneUrapObjRelInsertInput;
  demiplane_urap_on_conflict: DemiplaneUrapOnConflict;
  demiplane_urap_order_by: DemiplaneUrapOrderBy;
  demiplane_urap_pk_columns_input: DemiplaneUrapPkColumnsInput;
  demiplane_urap_set_input: DemiplaneUrapSetInput;
  demiplane_urap_stddev_fields: DemiplaneUrapStddevFields;
  demiplane_urap_stddev_order_by: DemiplaneUrapStddevOrderBy;
  demiplane_urap_stddev_pop_fields: DemiplaneUrapStddevPopFields;
  demiplane_urap_stddev_pop_order_by: DemiplaneUrapStddevPopOrderBy;
  demiplane_urap_stddev_samp_fields: DemiplaneUrapStddevSampFields;
  demiplane_urap_stddev_samp_order_by: DemiplaneUrapStddevSampOrderBy;
  demiplane_urap_sum_fields: DemiplaneUrapSumFields;
  demiplane_urap_sum_order_by: DemiplaneUrapSumOrderBy;
  demiplane_urap_var_pop_fields: DemiplaneUrapVarPopFields;
  demiplane_urap_var_pop_order_by: DemiplaneUrapVarPopOrderBy;
  demiplane_urap_var_samp_fields: DemiplaneUrapVarSampFields;
  demiplane_urap_var_samp_order_by: DemiplaneUrapVarSampOrderBy;
  demiplane_urap_variance_fields: DemiplaneUrapVarianceFields;
  demiplane_urap_variance_order_by: DemiplaneUrapVarianceOrderBy;
  demiplane_urgt: DemiplaneUrgt;
  demiplane_urgt_aggregate: DemiplaneUrgtAggregate;
  demiplane_urgt_aggregate_fields: DemiplaneUrgtAggregateFields;
  demiplane_urgt_aggregate_order_by: DemiplaneUrgtAggregateOrderBy;
  demiplane_urgt_arr_rel_insert_input: DemiplaneUrgtArrRelInsertInput;
  demiplane_urgt_avg_fields: DemiplaneUrgtAvgFields;
  demiplane_urgt_avg_order_by: DemiplaneUrgtAvgOrderBy;
  demiplane_urgt_bool_exp: DemiplaneUrgtBoolExp;
  demiplane_urgt_inc_input: DemiplaneUrgtIncInput;
  demiplane_urgt_insert_input: DemiplaneUrgtInsertInput;
  demiplane_urgt_max_fields: DemiplaneUrgtMaxFields;
  demiplane_urgt_max_order_by: DemiplaneUrgtMaxOrderBy;
  demiplane_urgt_min_fields: DemiplaneUrgtMinFields;
  demiplane_urgt_min_order_by: DemiplaneUrgtMinOrderBy;
  demiplane_urgt_mutation_response: DemiplaneUrgtMutationResponse;
  demiplane_urgt_obj_rel_insert_input: DemiplaneUrgtObjRelInsertInput;
  demiplane_urgt_on_conflict: DemiplaneUrgtOnConflict;
  demiplane_urgt_order_by: DemiplaneUrgtOrderBy;
  demiplane_urgt_pk_columns_input: DemiplaneUrgtPkColumnsInput;
  demiplane_urgt_set_input: DemiplaneUrgtSetInput;
  demiplane_urgt_stddev_fields: DemiplaneUrgtStddevFields;
  demiplane_urgt_stddev_order_by: DemiplaneUrgtStddevOrderBy;
  demiplane_urgt_stddev_pop_fields: DemiplaneUrgtStddevPopFields;
  demiplane_urgt_stddev_pop_order_by: DemiplaneUrgtStddevPopOrderBy;
  demiplane_urgt_stddev_samp_fields: DemiplaneUrgtStddevSampFields;
  demiplane_urgt_stddev_samp_order_by: DemiplaneUrgtStddevSampOrderBy;
  demiplane_urgt_sum_fields: DemiplaneUrgtSumFields;
  demiplane_urgt_sum_order_by: DemiplaneUrgtSumOrderBy;
  demiplane_urgt_var_pop_fields: DemiplaneUrgtVarPopFields;
  demiplane_urgt_var_pop_order_by: DemiplaneUrgtVarPopOrderBy;
  demiplane_urgt_var_samp_fields: DemiplaneUrgtVarSampFields;
  demiplane_urgt_var_samp_order_by: DemiplaneUrgtVarSampOrderBy;
  demiplane_urgt_variance_fields: DemiplaneUrgtVarianceFields;
  demiplane_urgt_variance_order_by: DemiplaneUrgtVarianceOrderBy;
  demiplane_user: DemiplaneUser;
  demiplane_user_activity: DemiplaneUserActivity;
  demiplane_user_activity_aggregate: DemiplaneUserActivityAggregate;
  demiplane_user_activity_aggregate_fields: DemiplaneUserActivityAggregateFields;
  demiplane_user_activity_aggregate_order_by: DemiplaneUserActivityAggregateOrderBy;
  demiplane_user_activity_arr_rel_insert_input: DemiplaneUserActivityArrRelInsertInput;
  demiplane_user_activity_avg_fields: DemiplaneUserActivityAvgFields;
  demiplane_user_activity_avg_order_by: DemiplaneUserActivityAvgOrderBy;
  demiplane_user_activity_bool_exp: DemiplaneUserActivityBoolExp;
  demiplane_user_activity_inc_input: DemiplaneUserActivityIncInput;
  demiplane_user_activity_insert_input: DemiplaneUserActivityInsertInput;
  demiplane_user_activity_max_fields: DemiplaneUserActivityMaxFields;
  demiplane_user_activity_max_order_by: DemiplaneUserActivityMaxOrderBy;
  demiplane_user_activity_min_fields: DemiplaneUserActivityMinFields;
  demiplane_user_activity_min_order_by: DemiplaneUserActivityMinOrderBy;
  demiplane_user_activity_mutation_response: DemiplaneUserActivityMutationResponse;
  demiplane_user_activity_obj_rel_insert_input: DemiplaneUserActivityObjRelInsertInput;
  demiplane_user_activity_on_conflict: DemiplaneUserActivityOnConflict;
  demiplane_user_activity_order_by: DemiplaneUserActivityOrderBy;
  demiplane_user_activity_pk_columns_input: DemiplaneUserActivityPkColumnsInput;
  demiplane_user_activity_set_input: DemiplaneUserActivitySetInput;
  demiplane_user_activity_stddev_fields: DemiplaneUserActivityStddevFields;
  demiplane_user_activity_stddev_order_by: DemiplaneUserActivityStddevOrderBy;
  demiplane_user_activity_stddev_pop_fields: DemiplaneUserActivityStddevPopFields;
  demiplane_user_activity_stddev_pop_order_by: DemiplaneUserActivityStddevPopOrderBy;
  demiplane_user_activity_stddev_samp_fields: DemiplaneUserActivityStddevSampFields;
  demiplane_user_activity_stddev_samp_order_by: DemiplaneUserActivityStddevSampOrderBy;
  demiplane_user_activity_sum_fields: DemiplaneUserActivitySumFields;
  demiplane_user_activity_sum_order_by: DemiplaneUserActivitySumOrderBy;
  demiplane_user_activity_var_pop_fields: DemiplaneUserActivityVarPopFields;
  demiplane_user_activity_var_pop_order_by: DemiplaneUserActivityVarPopOrderBy;
  demiplane_user_activity_var_samp_fields: DemiplaneUserActivityVarSampFields;
  demiplane_user_activity_var_samp_order_by: DemiplaneUserActivityVarSampOrderBy;
  demiplane_user_activity_variance_fields: DemiplaneUserActivityVarianceFields;
  demiplane_user_activity_variance_order_by: DemiplaneUserActivityVarianceOrderBy;
  demiplane_user_adventure: DemiplaneUserAdventure;
  demiplane_user_adventure_aggregate: DemiplaneUserAdventureAggregate;
  demiplane_user_adventure_aggregate_fields: DemiplaneUserAdventureAggregateFields;
  demiplane_user_adventure_aggregate_order_by: DemiplaneUserAdventureAggregateOrderBy;
  demiplane_user_adventure_arr_rel_insert_input: DemiplaneUserAdventureArrRelInsertInput;
  demiplane_user_adventure_avg_fields: DemiplaneUserAdventureAvgFields;
  demiplane_user_adventure_avg_order_by: DemiplaneUserAdventureAvgOrderBy;
  demiplane_user_adventure_bool_exp: DemiplaneUserAdventureBoolExp;
  demiplane_user_adventure_inc_input: DemiplaneUserAdventureIncInput;
  demiplane_user_adventure_insert_input: DemiplaneUserAdventureInsertInput;
  demiplane_user_adventure_max_fields: DemiplaneUserAdventureMaxFields;
  demiplane_user_adventure_max_order_by: DemiplaneUserAdventureMaxOrderBy;
  demiplane_user_adventure_min_fields: DemiplaneUserAdventureMinFields;
  demiplane_user_adventure_min_order_by: DemiplaneUserAdventureMinOrderBy;
  demiplane_user_adventure_mutation_response: DemiplaneUserAdventureMutationResponse;
  demiplane_user_adventure_obj_rel_insert_input: DemiplaneUserAdventureObjRelInsertInput;
  demiplane_user_adventure_on_conflict: DemiplaneUserAdventureOnConflict;
  demiplane_user_adventure_order_by: DemiplaneUserAdventureOrderBy;
  demiplane_user_adventure_pk_columns_input: DemiplaneUserAdventurePkColumnsInput;
  demiplane_user_adventure_ranking: DemiplaneUserAdventureRanking;
  demiplane_user_adventure_ranking_aggregate: DemiplaneUserAdventureRankingAggregate;
  demiplane_user_adventure_ranking_aggregate_fields: DemiplaneUserAdventureRankingAggregateFields;
  demiplane_user_adventure_ranking_aggregate_order_by: DemiplaneUserAdventureRankingAggregateOrderBy;
  demiplane_user_adventure_ranking_arr_rel_insert_input: DemiplaneUserAdventureRankingArrRelInsertInput;
  demiplane_user_adventure_ranking_avg_fields: DemiplaneUserAdventureRankingAvgFields;
  demiplane_user_adventure_ranking_avg_order_by: DemiplaneUserAdventureRankingAvgOrderBy;
  demiplane_user_adventure_ranking_bool_exp: DemiplaneUserAdventureRankingBoolExp;
  demiplane_user_adventure_ranking_inc_input: DemiplaneUserAdventureRankingIncInput;
  demiplane_user_adventure_ranking_insert_input: DemiplaneUserAdventureRankingInsertInput;
  demiplane_user_adventure_ranking_max_fields: DemiplaneUserAdventureRankingMaxFields;
  demiplane_user_adventure_ranking_max_order_by: DemiplaneUserAdventureRankingMaxOrderBy;
  demiplane_user_adventure_ranking_min_fields: DemiplaneUserAdventureRankingMinFields;
  demiplane_user_adventure_ranking_min_order_by: DemiplaneUserAdventureRankingMinOrderBy;
  demiplane_user_adventure_ranking_mutation_response: DemiplaneUserAdventureRankingMutationResponse;
  demiplane_user_adventure_ranking_obj_rel_insert_input: DemiplaneUserAdventureRankingObjRelInsertInput;
  demiplane_user_adventure_ranking_on_conflict: DemiplaneUserAdventureRankingOnConflict;
  demiplane_user_adventure_ranking_order_by: DemiplaneUserAdventureRankingOrderBy;
  demiplane_user_adventure_ranking_pk_columns_input: DemiplaneUserAdventureRankingPkColumnsInput;
  demiplane_user_adventure_ranking_set_input: DemiplaneUserAdventureRankingSetInput;
  demiplane_user_adventure_ranking_stddev_fields: DemiplaneUserAdventureRankingStddevFields;
  demiplane_user_adventure_ranking_stddev_order_by: DemiplaneUserAdventureRankingStddevOrderBy;
  demiplane_user_adventure_ranking_stddev_pop_fields: DemiplaneUserAdventureRankingStddevPopFields;
  demiplane_user_adventure_ranking_stddev_pop_order_by: DemiplaneUserAdventureRankingStddevPopOrderBy;
  demiplane_user_adventure_ranking_stddev_samp_fields: DemiplaneUserAdventureRankingStddevSampFields;
  demiplane_user_adventure_ranking_stddev_samp_order_by: DemiplaneUserAdventureRankingStddevSampOrderBy;
  demiplane_user_adventure_ranking_sum_fields: DemiplaneUserAdventureRankingSumFields;
  demiplane_user_adventure_ranking_sum_order_by: DemiplaneUserAdventureRankingSumOrderBy;
  demiplane_user_adventure_ranking_var_pop_fields: DemiplaneUserAdventureRankingVarPopFields;
  demiplane_user_adventure_ranking_var_pop_order_by: DemiplaneUserAdventureRankingVarPopOrderBy;
  demiplane_user_adventure_ranking_var_samp_fields: DemiplaneUserAdventureRankingVarSampFields;
  demiplane_user_adventure_ranking_var_samp_order_by: DemiplaneUserAdventureRankingVarSampOrderBy;
  demiplane_user_adventure_ranking_variance_fields: DemiplaneUserAdventureRankingVarianceFields;
  demiplane_user_adventure_ranking_variance_order_by: DemiplaneUserAdventureRankingVarianceOrderBy;
  demiplane_user_adventure_role: DemiplaneUserAdventureRole;
  demiplane_user_adventure_role_aggregate: DemiplaneUserAdventureRoleAggregate;
  demiplane_user_adventure_role_aggregate_fields: DemiplaneUserAdventureRoleAggregateFields;
  demiplane_user_adventure_role_aggregate_order_by: DemiplaneUserAdventureRoleAggregateOrderBy;
  demiplane_user_adventure_role_arr_rel_insert_input: DemiplaneUserAdventureRoleArrRelInsertInput;
  demiplane_user_adventure_role_avg_fields: DemiplaneUserAdventureRoleAvgFields;
  demiplane_user_adventure_role_avg_order_by: DemiplaneUserAdventureRoleAvgOrderBy;
  demiplane_user_adventure_role_bool_exp: DemiplaneUserAdventureRoleBoolExp;
  demiplane_user_adventure_role_inc_input: DemiplaneUserAdventureRoleIncInput;
  demiplane_user_adventure_role_insert_input: DemiplaneUserAdventureRoleInsertInput;
  demiplane_user_adventure_role_max_fields: DemiplaneUserAdventureRoleMaxFields;
  demiplane_user_adventure_role_max_order_by: DemiplaneUserAdventureRoleMaxOrderBy;
  demiplane_user_adventure_role_min_fields: DemiplaneUserAdventureRoleMinFields;
  demiplane_user_adventure_role_min_order_by: DemiplaneUserAdventureRoleMinOrderBy;
  demiplane_user_adventure_role_mutation_response: DemiplaneUserAdventureRoleMutationResponse;
  demiplane_user_adventure_role_obj_rel_insert_input: DemiplaneUserAdventureRoleObjRelInsertInput;
  demiplane_user_adventure_role_on_conflict: DemiplaneUserAdventureRoleOnConflict;
  demiplane_user_adventure_role_order_by: DemiplaneUserAdventureRoleOrderBy;
  demiplane_user_adventure_role_pk_columns_input: DemiplaneUserAdventureRolePkColumnsInput;
  demiplane_user_adventure_role_set_input: DemiplaneUserAdventureRoleSetInput;
  demiplane_user_adventure_role_stddev_fields: DemiplaneUserAdventureRoleStddevFields;
  demiplane_user_adventure_role_stddev_order_by: DemiplaneUserAdventureRoleStddevOrderBy;
  demiplane_user_adventure_role_stddev_pop_fields: DemiplaneUserAdventureRoleStddevPopFields;
  demiplane_user_adventure_role_stddev_pop_order_by: DemiplaneUserAdventureRoleStddevPopOrderBy;
  demiplane_user_adventure_role_stddev_samp_fields: DemiplaneUserAdventureRoleStddevSampFields;
  demiplane_user_adventure_role_stddev_samp_order_by: DemiplaneUserAdventureRoleStddevSampOrderBy;
  demiplane_user_adventure_role_sum_fields: DemiplaneUserAdventureRoleSumFields;
  demiplane_user_adventure_role_sum_order_by: DemiplaneUserAdventureRoleSumOrderBy;
  demiplane_user_adventure_role_var_pop_fields: DemiplaneUserAdventureRoleVarPopFields;
  demiplane_user_adventure_role_var_pop_order_by: DemiplaneUserAdventureRoleVarPopOrderBy;
  demiplane_user_adventure_role_var_samp_fields: DemiplaneUserAdventureRoleVarSampFields;
  demiplane_user_adventure_role_var_samp_order_by: DemiplaneUserAdventureRoleVarSampOrderBy;
  demiplane_user_adventure_role_variance_fields: DemiplaneUserAdventureRoleVarianceFields;
  demiplane_user_adventure_role_variance_order_by: DemiplaneUserAdventureRoleVarianceOrderBy;
  demiplane_user_adventure_set_input: DemiplaneUserAdventureSetInput;
  demiplane_user_adventure_stddev_fields: DemiplaneUserAdventureStddevFields;
  demiplane_user_adventure_stddev_order_by: DemiplaneUserAdventureStddevOrderBy;
  demiplane_user_adventure_stddev_pop_fields: DemiplaneUserAdventureStddevPopFields;
  demiplane_user_adventure_stddev_pop_order_by: DemiplaneUserAdventureStddevPopOrderBy;
  demiplane_user_adventure_stddev_samp_fields: DemiplaneUserAdventureStddevSampFields;
  demiplane_user_adventure_stddev_samp_order_by: DemiplaneUserAdventureStddevSampOrderBy;
  demiplane_user_adventure_sum_fields: DemiplaneUserAdventureSumFields;
  demiplane_user_adventure_sum_order_by: DemiplaneUserAdventureSumOrderBy;
  demiplane_user_adventure_var_pop_fields: DemiplaneUserAdventureVarPopFields;
  demiplane_user_adventure_var_pop_order_by: DemiplaneUserAdventureVarPopOrderBy;
  demiplane_user_adventure_var_samp_fields: DemiplaneUserAdventureVarSampFields;
  demiplane_user_adventure_var_samp_order_by: DemiplaneUserAdventureVarSampOrderBy;
  demiplane_user_adventure_variance_fields: DemiplaneUserAdventureVarianceFields;
  demiplane_user_adventure_variance_order_by: DemiplaneUserAdventureVarianceOrderBy;
  demiplane_user_aggregate: DemiplaneUserAggregate;
  demiplane_user_aggregate_fields: DemiplaneUserAggregateFields;
  demiplane_user_aggregate_order_by: DemiplaneUserAggregateOrderBy;
  demiplane_user_arr_rel_insert_input: DemiplaneUserArrRelInsertInput;
  demiplane_user_avg_fields: DemiplaneUserAvgFields;
  demiplane_user_avg_order_by: DemiplaneUserAvgOrderBy;
  demiplane_user_bool_exp: DemiplaneUserBoolExp;
  demiplane_user_email_notification_pref: DemiplaneUserEmailNotificationPref;
  demiplane_user_email_notification_pref_aggregate: DemiplaneUserEmailNotificationPrefAggregate;
  demiplane_user_email_notification_pref_aggregate_fields: DemiplaneUserEmailNotificationPrefAggregateFields;
  demiplane_user_email_notification_pref_aggregate_order_by: DemiplaneUserEmailNotificationPrefAggregateOrderBy;
  demiplane_user_email_notification_pref_arr_rel_insert_input: DemiplaneUserEmailNotificationPrefArrRelInsertInput;
  demiplane_user_email_notification_pref_avg_fields: DemiplaneUserEmailNotificationPrefAvgFields;
  demiplane_user_email_notification_pref_avg_order_by: DemiplaneUserEmailNotificationPrefAvgOrderBy;
  demiplane_user_email_notification_pref_bool_exp: DemiplaneUserEmailNotificationPrefBoolExp;
  demiplane_user_email_notification_pref_inc_input: DemiplaneUserEmailNotificationPrefIncInput;
  demiplane_user_email_notification_pref_insert_input: DemiplaneUserEmailNotificationPrefInsertInput;
  demiplane_user_email_notification_pref_max_fields: DemiplaneUserEmailNotificationPrefMaxFields;
  demiplane_user_email_notification_pref_max_order_by: DemiplaneUserEmailNotificationPrefMaxOrderBy;
  demiplane_user_email_notification_pref_min_fields: DemiplaneUserEmailNotificationPrefMinFields;
  demiplane_user_email_notification_pref_min_order_by: DemiplaneUserEmailNotificationPrefMinOrderBy;
  demiplane_user_email_notification_pref_mutation_response: DemiplaneUserEmailNotificationPrefMutationResponse;
  demiplane_user_email_notification_pref_obj_rel_insert_input: DemiplaneUserEmailNotificationPrefObjRelInsertInput;
  demiplane_user_email_notification_pref_on_conflict: DemiplaneUserEmailNotificationPrefOnConflict;
  demiplane_user_email_notification_pref_order_by: DemiplaneUserEmailNotificationPrefOrderBy;
  demiplane_user_email_notification_pref_pk_columns_input: DemiplaneUserEmailNotificationPrefPkColumnsInput;
  demiplane_user_email_notification_pref_set_input: DemiplaneUserEmailNotificationPrefSetInput;
  demiplane_user_email_notification_pref_stddev_fields: DemiplaneUserEmailNotificationPrefStddevFields;
  demiplane_user_email_notification_pref_stddev_order_by: DemiplaneUserEmailNotificationPrefStddevOrderBy;
  demiplane_user_email_notification_pref_stddev_pop_fields: DemiplaneUserEmailNotificationPrefStddevPopFields;
  demiplane_user_email_notification_pref_stddev_pop_order_by: DemiplaneUserEmailNotificationPrefStddevPopOrderBy;
  demiplane_user_email_notification_pref_stddev_samp_fields: DemiplaneUserEmailNotificationPrefStddevSampFields;
  demiplane_user_email_notification_pref_stddev_samp_order_by: DemiplaneUserEmailNotificationPrefStddevSampOrderBy;
  demiplane_user_email_notification_pref_sum_fields: DemiplaneUserEmailNotificationPrefSumFields;
  demiplane_user_email_notification_pref_sum_order_by: DemiplaneUserEmailNotificationPrefSumOrderBy;
  demiplane_user_email_notification_pref_var_pop_fields: DemiplaneUserEmailNotificationPrefVarPopFields;
  demiplane_user_email_notification_pref_var_pop_order_by: DemiplaneUserEmailNotificationPrefVarPopOrderBy;
  demiplane_user_email_notification_pref_var_samp_fields: DemiplaneUserEmailNotificationPrefVarSampFields;
  demiplane_user_email_notification_pref_var_samp_order_by: DemiplaneUserEmailNotificationPrefVarSampOrderBy;
  demiplane_user_email_notification_pref_variance_fields: DemiplaneUserEmailNotificationPrefVarianceFields;
  demiplane_user_email_notification_pref_variance_order_by: DemiplaneUserEmailNotificationPrefVarianceOrderBy;
  demiplane_user_inc_input: DemiplaneUserIncInput;
  demiplane_user_insert_input: DemiplaneUserInsertInput;
  demiplane_user_invitation: DemiplaneUserInvitation;
  demiplane_user_invitation_aggregate: DemiplaneUserInvitationAggregate;
  demiplane_user_invitation_aggregate_fields: DemiplaneUserInvitationAggregateFields;
  demiplane_user_invitation_aggregate_order_by: DemiplaneUserInvitationAggregateOrderBy;
  demiplane_user_invitation_arr_rel_insert_input: DemiplaneUserInvitationArrRelInsertInput;
  demiplane_user_invitation_avg_fields: DemiplaneUserInvitationAvgFields;
  demiplane_user_invitation_avg_order_by: DemiplaneUserInvitationAvgOrderBy;
  demiplane_user_invitation_bool_exp: DemiplaneUserInvitationBoolExp;
  demiplane_user_invitation_inc_input: DemiplaneUserInvitationIncInput;
  demiplane_user_invitation_insert_input: DemiplaneUserInvitationInsertInput;
  demiplane_user_invitation_max_fields: DemiplaneUserInvitationMaxFields;
  demiplane_user_invitation_max_order_by: DemiplaneUserInvitationMaxOrderBy;
  demiplane_user_invitation_min_fields: DemiplaneUserInvitationMinFields;
  demiplane_user_invitation_min_order_by: DemiplaneUserInvitationMinOrderBy;
  demiplane_user_invitation_mutation_response: DemiplaneUserInvitationMutationResponse;
  demiplane_user_invitation_obj_rel_insert_input: DemiplaneUserInvitationObjRelInsertInput;
  demiplane_user_invitation_on_conflict: DemiplaneUserInvitationOnConflict;
  demiplane_user_invitation_order_by: DemiplaneUserInvitationOrderBy;
  demiplane_user_invitation_pk_columns_input: DemiplaneUserInvitationPkColumnsInput;
  demiplane_user_invitation_set_input: DemiplaneUserInvitationSetInput;
  demiplane_user_invitation_stddev_fields: DemiplaneUserInvitationStddevFields;
  demiplane_user_invitation_stddev_order_by: DemiplaneUserInvitationStddevOrderBy;
  demiplane_user_invitation_stddev_pop_fields: DemiplaneUserInvitationStddevPopFields;
  demiplane_user_invitation_stddev_pop_order_by: DemiplaneUserInvitationStddevPopOrderBy;
  demiplane_user_invitation_stddev_samp_fields: DemiplaneUserInvitationStddevSampFields;
  demiplane_user_invitation_stddev_samp_order_by: DemiplaneUserInvitationStddevSampOrderBy;
  demiplane_user_invitation_sum_fields: DemiplaneUserInvitationSumFields;
  demiplane_user_invitation_sum_order_by: DemiplaneUserInvitationSumOrderBy;
  demiplane_user_invitation_var_pop_fields: DemiplaneUserInvitationVarPopFields;
  demiplane_user_invitation_var_pop_order_by: DemiplaneUserInvitationVarPopOrderBy;
  demiplane_user_invitation_var_samp_fields: DemiplaneUserInvitationVarSampFields;
  demiplane_user_invitation_var_samp_order_by: DemiplaneUserInvitationVarSampOrderBy;
  demiplane_user_invitation_variance_fields: DemiplaneUserInvitationVarianceFields;
  demiplane_user_invitation_variance_order_by: DemiplaneUserInvitationVarianceOrderBy;
  demiplane_user_max_fields: DemiplaneUserMaxFields;
  demiplane_user_max_order_by: DemiplaneUserMaxOrderBy;
  demiplane_user_metadata: DemiplaneUserMetadata;
  demiplane_user_metadata_aggregate: DemiplaneUserMetadataAggregate;
  demiplane_user_metadata_aggregate_fields: DemiplaneUserMetadataAggregateFields;
  demiplane_user_metadata_aggregate_order_by: DemiplaneUserMetadataAggregateOrderBy;
  demiplane_user_metadata_arr_rel_insert_input: DemiplaneUserMetadataArrRelInsertInput;
  demiplane_user_metadata_avg_fields: DemiplaneUserMetadataAvgFields;
  demiplane_user_metadata_avg_order_by: DemiplaneUserMetadataAvgOrderBy;
  demiplane_user_metadata_bool_exp: DemiplaneUserMetadataBoolExp;
  demiplane_user_metadata_inc_input: DemiplaneUserMetadataIncInput;
  demiplane_user_metadata_insert_input: DemiplaneUserMetadataInsertInput;
  demiplane_user_metadata_max_fields: DemiplaneUserMetadataMaxFields;
  demiplane_user_metadata_max_order_by: DemiplaneUserMetadataMaxOrderBy;
  demiplane_user_metadata_min_fields: DemiplaneUserMetadataMinFields;
  demiplane_user_metadata_min_order_by: DemiplaneUserMetadataMinOrderBy;
  demiplane_user_metadata_mutation_response: DemiplaneUserMetadataMutationResponse;
  demiplane_user_metadata_obj_rel_insert_input: DemiplaneUserMetadataObjRelInsertInput;
  demiplane_user_metadata_on_conflict: DemiplaneUserMetadataOnConflict;
  demiplane_user_metadata_order_by: DemiplaneUserMetadataOrderBy;
  demiplane_user_metadata_pk_columns_input: DemiplaneUserMetadataPkColumnsInput;
  demiplane_user_metadata_set_input: DemiplaneUserMetadataSetInput;
  demiplane_user_metadata_stddev_fields: DemiplaneUserMetadataStddevFields;
  demiplane_user_metadata_stddev_order_by: DemiplaneUserMetadataStddevOrderBy;
  demiplane_user_metadata_stddev_pop_fields: DemiplaneUserMetadataStddevPopFields;
  demiplane_user_metadata_stddev_pop_order_by: DemiplaneUserMetadataStddevPopOrderBy;
  demiplane_user_metadata_stddev_samp_fields: DemiplaneUserMetadataStddevSampFields;
  demiplane_user_metadata_stddev_samp_order_by: DemiplaneUserMetadataStddevSampOrderBy;
  demiplane_user_metadata_sum_fields: DemiplaneUserMetadataSumFields;
  demiplane_user_metadata_sum_order_by: DemiplaneUserMetadataSumOrderBy;
  demiplane_user_metadata_var_pop_fields: DemiplaneUserMetadataVarPopFields;
  demiplane_user_metadata_var_pop_order_by: DemiplaneUserMetadataVarPopOrderBy;
  demiplane_user_metadata_var_samp_fields: DemiplaneUserMetadataVarSampFields;
  demiplane_user_metadata_var_samp_order_by: DemiplaneUserMetadataVarSampOrderBy;
  demiplane_user_metadata_variance_fields: DemiplaneUserMetadataVarianceFields;
  demiplane_user_metadata_variance_order_by: DemiplaneUserMetadataVarianceOrderBy;
  demiplane_user_min_fields: DemiplaneUserMinFields;
  demiplane_user_min_order_by: DemiplaneUserMinOrderBy;
  demiplane_user_mutation_response: DemiplaneUserMutationResponse;
  demiplane_user_obj_rel_insert_input: DemiplaneUserObjRelInsertInput;
  demiplane_user_on_conflict: DemiplaneUserOnConflict;
  demiplane_user_order_by: DemiplaneUserOrderBy;
  demiplane_user_pk_columns_input: DemiplaneUserPkColumnsInput;
  demiplane_user_push_notification_pref: DemiplaneUserPushNotificationPref;
  demiplane_user_push_notification_pref_aggregate: DemiplaneUserPushNotificationPrefAggregate;
  demiplane_user_push_notification_pref_aggregate_fields: DemiplaneUserPushNotificationPrefAggregateFields;
  demiplane_user_push_notification_pref_aggregate_order_by: DemiplaneUserPushNotificationPrefAggregateOrderBy;
  demiplane_user_push_notification_pref_arr_rel_insert_input: DemiplaneUserPushNotificationPrefArrRelInsertInput;
  demiplane_user_push_notification_pref_avg_fields: DemiplaneUserPushNotificationPrefAvgFields;
  demiplane_user_push_notification_pref_avg_order_by: DemiplaneUserPushNotificationPrefAvgOrderBy;
  demiplane_user_push_notification_pref_bool_exp: DemiplaneUserPushNotificationPrefBoolExp;
  demiplane_user_push_notification_pref_inc_input: DemiplaneUserPushNotificationPrefIncInput;
  demiplane_user_push_notification_pref_insert_input: DemiplaneUserPushNotificationPrefInsertInput;
  demiplane_user_push_notification_pref_max_fields: DemiplaneUserPushNotificationPrefMaxFields;
  demiplane_user_push_notification_pref_max_order_by: DemiplaneUserPushNotificationPrefMaxOrderBy;
  demiplane_user_push_notification_pref_min_fields: DemiplaneUserPushNotificationPrefMinFields;
  demiplane_user_push_notification_pref_min_order_by: DemiplaneUserPushNotificationPrefMinOrderBy;
  demiplane_user_push_notification_pref_mutation_response: DemiplaneUserPushNotificationPrefMutationResponse;
  demiplane_user_push_notification_pref_obj_rel_insert_input: DemiplaneUserPushNotificationPrefObjRelInsertInput;
  demiplane_user_push_notification_pref_on_conflict: DemiplaneUserPushNotificationPrefOnConflict;
  demiplane_user_push_notification_pref_order_by: DemiplaneUserPushNotificationPrefOrderBy;
  demiplane_user_push_notification_pref_pk_columns_input: DemiplaneUserPushNotificationPrefPkColumnsInput;
  demiplane_user_push_notification_pref_set_input: DemiplaneUserPushNotificationPrefSetInput;
  demiplane_user_push_notification_pref_stddev_fields: DemiplaneUserPushNotificationPrefStddevFields;
  demiplane_user_push_notification_pref_stddev_order_by: DemiplaneUserPushNotificationPrefStddevOrderBy;
  demiplane_user_push_notification_pref_stddev_pop_fields: DemiplaneUserPushNotificationPrefStddevPopFields;
  demiplane_user_push_notification_pref_stddev_pop_order_by: DemiplaneUserPushNotificationPrefStddevPopOrderBy;
  demiplane_user_push_notification_pref_stddev_samp_fields: DemiplaneUserPushNotificationPrefStddevSampFields;
  demiplane_user_push_notification_pref_stddev_samp_order_by: DemiplaneUserPushNotificationPrefStddevSampOrderBy;
  demiplane_user_push_notification_pref_sum_fields: DemiplaneUserPushNotificationPrefSumFields;
  demiplane_user_push_notification_pref_sum_order_by: DemiplaneUserPushNotificationPrefSumOrderBy;
  demiplane_user_push_notification_pref_var_pop_fields: DemiplaneUserPushNotificationPrefVarPopFields;
  demiplane_user_push_notification_pref_var_pop_order_by: DemiplaneUserPushNotificationPrefVarPopOrderBy;
  demiplane_user_push_notification_pref_var_samp_fields: DemiplaneUserPushNotificationPrefVarSampFields;
  demiplane_user_push_notification_pref_var_samp_order_by: DemiplaneUserPushNotificationPrefVarSampOrderBy;
  demiplane_user_push_notification_pref_variance_fields: DemiplaneUserPushNotificationPrefVarianceFields;
  demiplane_user_push_notification_pref_variance_order_by: DemiplaneUserPushNotificationPrefVarianceOrderBy;
  demiplane_user_recruitment: DemiplaneUserRecruitment;
  demiplane_user_recruitment_adventurer: DemiplaneUserRecruitmentAdventurer;
  demiplane_user_recruitment_adventurer_aggregate: DemiplaneUserRecruitmentAdventurerAggregate;
  demiplane_user_recruitment_adventurer_aggregate_fields: DemiplaneUserRecruitmentAdventurerAggregateFields;
  demiplane_user_recruitment_adventurer_aggregate_order_by: DemiplaneUserRecruitmentAdventurerAggregateOrderBy;
  demiplane_user_recruitment_adventurer_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerArrRelInsertInput;
  demiplane_user_recruitment_adventurer_availability: DemiplaneUserRecruitmentAdventurerAvailability;
  demiplane_user_recruitment_adventurer_availability_aggregate: DemiplaneUserRecruitmentAdventurerAvailabilityAggregate;
  demiplane_user_recruitment_adventurer_availability_aggregate_fields: DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFields;
  demiplane_user_recruitment_adventurer_availability_aggregate_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityAggregateOrderBy;
  demiplane_user_recruitment_adventurer_availability_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerAvailabilityArrRelInsertInput;
  demiplane_user_recruitment_adventurer_availability_avg_fields: DemiplaneUserRecruitmentAdventurerAvailabilityAvgFields;
  demiplane_user_recruitment_adventurer_availability_avg_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityAvgOrderBy;
  demiplane_user_recruitment_adventurer_availability_bool_exp: DemiplaneUserRecruitmentAdventurerAvailabilityBoolExp;
  demiplane_user_recruitment_adventurer_availability_inc_input: DemiplaneUserRecruitmentAdventurerAvailabilityIncInput;
  demiplane_user_recruitment_adventurer_availability_insert_input: DemiplaneUserRecruitmentAdventurerAvailabilityInsertInput;
  demiplane_user_recruitment_adventurer_availability_max_fields: DemiplaneUserRecruitmentAdventurerAvailabilityMaxFields;
  demiplane_user_recruitment_adventurer_availability_max_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityMaxOrderBy;
  demiplane_user_recruitment_adventurer_availability_min_fields: DemiplaneUserRecruitmentAdventurerAvailabilityMinFields;
  demiplane_user_recruitment_adventurer_availability_min_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityMinOrderBy;
  demiplane_user_recruitment_adventurer_availability_mutation_response: DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponse;
  demiplane_user_recruitment_adventurer_availability_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerAvailabilityObjRelInsertInput;
  demiplane_user_recruitment_adventurer_availability_on_conflict: DemiplaneUserRecruitmentAdventurerAvailabilityOnConflict;
  demiplane_user_recruitment_adventurer_availability_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityOrderBy;
  demiplane_user_recruitment_adventurer_availability_pk_columns_input: DemiplaneUserRecruitmentAdventurerAvailabilityPkColumnsInput;
  demiplane_user_recruitment_adventurer_availability_set_input: DemiplaneUserRecruitmentAdventurerAvailabilitySetInput;
  demiplane_user_recruitment_adventurer_availability_stddev_fields: DemiplaneUserRecruitmentAdventurerAvailabilityStddevFields;
  demiplane_user_recruitment_adventurer_availability_stddev_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityStddevOrderBy;
  demiplane_user_recruitment_adventurer_availability_stddev_pop_fields: DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopFields;
  demiplane_user_recruitment_adventurer_availability_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_availability_stddev_samp_fields: DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampFields;
  demiplane_user_recruitment_adventurer_availability_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_availability_sum_fields: DemiplaneUserRecruitmentAdventurerAvailabilitySumFields;
  demiplane_user_recruitment_adventurer_availability_sum_order_by: DemiplaneUserRecruitmentAdventurerAvailabilitySumOrderBy;
  demiplane_user_recruitment_adventurer_availability_var_pop_fields: DemiplaneUserRecruitmentAdventurerAvailabilityVarPopFields;
  demiplane_user_recruitment_adventurer_availability_var_pop_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityVarPopOrderBy;
  demiplane_user_recruitment_adventurer_availability_var_samp_fields: DemiplaneUserRecruitmentAdventurerAvailabilityVarSampFields;
  demiplane_user_recruitment_adventurer_availability_var_samp_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityVarSampOrderBy;
  demiplane_user_recruitment_adventurer_availability_variance_fields: DemiplaneUserRecruitmentAdventurerAvailabilityVarianceFields;
  demiplane_user_recruitment_adventurer_availability_variance_order_by: DemiplaneUserRecruitmentAdventurerAvailabilityVarianceOrderBy;
  demiplane_user_recruitment_adventurer_avg_fields: DemiplaneUserRecruitmentAdventurerAvgFields;
  demiplane_user_recruitment_adventurer_avg_order_by: DemiplaneUserRecruitmentAdventurerAvgOrderBy;
  demiplane_user_recruitment_adventurer_bool_exp: DemiplaneUserRecruitmentAdventurerBoolExp;
  demiplane_user_recruitment_adventurer_games: DemiplaneUserRecruitmentAdventurerGames;
  demiplane_user_recruitment_adventurer_games_aggregate: DemiplaneUserRecruitmentAdventurerGamesAggregate;
  demiplane_user_recruitment_adventurer_games_aggregate_fields: DemiplaneUserRecruitmentAdventurerGamesAggregateFields;
  demiplane_user_recruitment_adventurer_games_aggregate_order_by: DemiplaneUserRecruitmentAdventurerGamesAggregateOrderBy;
  demiplane_user_recruitment_adventurer_games_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerGamesArrRelInsertInput;
  demiplane_user_recruitment_adventurer_games_avg_fields: DemiplaneUserRecruitmentAdventurerGamesAvgFields;
  demiplane_user_recruitment_adventurer_games_avg_order_by: DemiplaneUserRecruitmentAdventurerGamesAvgOrderBy;
  demiplane_user_recruitment_adventurer_games_bool_exp: DemiplaneUserRecruitmentAdventurerGamesBoolExp;
  demiplane_user_recruitment_adventurer_games_inc_input: DemiplaneUserRecruitmentAdventurerGamesIncInput;
  demiplane_user_recruitment_adventurer_games_insert_input: DemiplaneUserRecruitmentAdventurerGamesInsertInput;
  demiplane_user_recruitment_adventurer_games_max_fields: DemiplaneUserRecruitmentAdventurerGamesMaxFields;
  demiplane_user_recruitment_adventurer_games_max_order_by: DemiplaneUserRecruitmentAdventurerGamesMaxOrderBy;
  demiplane_user_recruitment_adventurer_games_min_fields: DemiplaneUserRecruitmentAdventurerGamesMinFields;
  demiplane_user_recruitment_adventurer_games_min_order_by: DemiplaneUserRecruitmentAdventurerGamesMinOrderBy;
  demiplane_user_recruitment_adventurer_games_mutation_response: DemiplaneUserRecruitmentAdventurerGamesMutationResponse;
  demiplane_user_recruitment_adventurer_games_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerGamesObjRelInsertInput;
  demiplane_user_recruitment_adventurer_games_on_conflict: DemiplaneUserRecruitmentAdventurerGamesOnConflict;
  demiplane_user_recruitment_adventurer_games_order_by: DemiplaneUserRecruitmentAdventurerGamesOrderBy;
  demiplane_user_recruitment_adventurer_games_pk_columns_input: DemiplaneUserRecruitmentAdventurerGamesPkColumnsInput;
  demiplane_user_recruitment_adventurer_games_set_input: DemiplaneUserRecruitmentAdventurerGamesSetInput;
  demiplane_user_recruitment_adventurer_games_stddev_fields: DemiplaneUserRecruitmentAdventurerGamesStddevFields;
  demiplane_user_recruitment_adventurer_games_stddev_order_by: DemiplaneUserRecruitmentAdventurerGamesStddevOrderBy;
  demiplane_user_recruitment_adventurer_games_stddev_pop_fields: DemiplaneUserRecruitmentAdventurerGamesStddevPopFields;
  demiplane_user_recruitment_adventurer_games_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerGamesStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_games_stddev_samp_fields: DemiplaneUserRecruitmentAdventurerGamesStddevSampFields;
  demiplane_user_recruitment_adventurer_games_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerGamesStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_games_sum_fields: DemiplaneUserRecruitmentAdventurerGamesSumFields;
  demiplane_user_recruitment_adventurer_games_sum_order_by: DemiplaneUserRecruitmentAdventurerGamesSumOrderBy;
  demiplane_user_recruitment_adventurer_games_var_pop_fields: DemiplaneUserRecruitmentAdventurerGamesVarPopFields;
  demiplane_user_recruitment_adventurer_games_var_pop_order_by: DemiplaneUserRecruitmentAdventurerGamesVarPopOrderBy;
  demiplane_user_recruitment_adventurer_games_var_samp_fields: DemiplaneUserRecruitmentAdventurerGamesVarSampFields;
  demiplane_user_recruitment_adventurer_games_var_samp_order_by: DemiplaneUserRecruitmentAdventurerGamesVarSampOrderBy;
  demiplane_user_recruitment_adventurer_games_variance_fields: DemiplaneUserRecruitmentAdventurerGamesVarianceFields;
  demiplane_user_recruitment_adventurer_games_variance_order_by: DemiplaneUserRecruitmentAdventurerGamesVarianceOrderBy;
  demiplane_user_recruitment_adventurer_inc_input: DemiplaneUserRecruitmentAdventurerIncInput;
  demiplane_user_recruitment_adventurer_insert_input: DemiplaneUserRecruitmentAdventurerInsertInput;
  demiplane_user_recruitment_adventurer_max_fields: DemiplaneUserRecruitmentAdventurerMaxFields;
  demiplane_user_recruitment_adventurer_max_order_by: DemiplaneUserRecruitmentAdventurerMaxOrderBy;
  demiplane_user_recruitment_adventurer_min_fields: DemiplaneUserRecruitmentAdventurerMinFields;
  demiplane_user_recruitment_adventurer_min_order_by: DemiplaneUserRecruitmentAdventurerMinOrderBy;
  demiplane_user_recruitment_adventurer_mutation_response: DemiplaneUserRecruitmentAdventurerMutationResponse;
  demiplane_user_recruitment_adventurer_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerObjRelInsertInput;
  demiplane_user_recruitment_adventurer_on_conflict: DemiplaneUserRecruitmentAdventurerOnConflict;
  demiplane_user_recruitment_adventurer_order_by: DemiplaneUserRecruitmentAdventurerOrderBy;
  demiplane_user_recruitment_adventurer_pk_columns_input: DemiplaneUserRecruitmentAdventurerPkColumnsInput;
  demiplane_user_recruitment_adventurer_platforms: DemiplaneUserRecruitmentAdventurerPlatforms;
  demiplane_user_recruitment_adventurer_platforms_aggregate: DemiplaneUserRecruitmentAdventurerPlatformsAggregate;
  demiplane_user_recruitment_adventurer_platforms_aggregate_fields: DemiplaneUserRecruitmentAdventurerPlatformsAggregateFields;
  demiplane_user_recruitment_adventurer_platforms_aggregate_order_by: DemiplaneUserRecruitmentAdventurerPlatformsAggregateOrderBy;
  demiplane_user_recruitment_adventurer_platforms_arr_rel_insert_input: DemiplaneUserRecruitmentAdventurerPlatformsArrRelInsertInput;
  demiplane_user_recruitment_adventurer_platforms_avg_fields: DemiplaneUserRecruitmentAdventurerPlatformsAvgFields;
  demiplane_user_recruitment_adventurer_platforms_avg_order_by: DemiplaneUserRecruitmentAdventurerPlatformsAvgOrderBy;
  demiplane_user_recruitment_adventurer_platforms_bool_exp: DemiplaneUserRecruitmentAdventurerPlatformsBoolExp;
  demiplane_user_recruitment_adventurer_platforms_inc_input: DemiplaneUserRecruitmentAdventurerPlatformsIncInput;
  demiplane_user_recruitment_adventurer_platforms_insert_input: DemiplaneUserRecruitmentAdventurerPlatformsInsertInput;
  demiplane_user_recruitment_adventurer_platforms_max_fields: DemiplaneUserRecruitmentAdventurerPlatformsMaxFields;
  demiplane_user_recruitment_adventurer_platforms_max_order_by: DemiplaneUserRecruitmentAdventurerPlatformsMaxOrderBy;
  demiplane_user_recruitment_adventurer_platforms_min_fields: DemiplaneUserRecruitmentAdventurerPlatformsMinFields;
  demiplane_user_recruitment_adventurer_platforms_min_order_by: DemiplaneUserRecruitmentAdventurerPlatformsMinOrderBy;
  demiplane_user_recruitment_adventurer_platforms_mutation_response: DemiplaneUserRecruitmentAdventurerPlatformsMutationResponse;
  demiplane_user_recruitment_adventurer_platforms_obj_rel_insert_input: DemiplaneUserRecruitmentAdventurerPlatformsObjRelInsertInput;
  demiplane_user_recruitment_adventurer_platforms_on_conflict: DemiplaneUserRecruitmentAdventurerPlatformsOnConflict;
  demiplane_user_recruitment_adventurer_platforms_order_by: DemiplaneUserRecruitmentAdventurerPlatformsOrderBy;
  demiplane_user_recruitment_adventurer_platforms_pk_columns_input: DemiplaneUserRecruitmentAdventurerPlatformsPkColumnsInput;
  demiplane_user_recruitment_adventurer_platforms_set_input: DemiplaneUserRecruitmentAdventurerPlatformsSetInput;
  demiplane_user_recruitment_adventurer_platforms_stddev_fields: DemiplaneUserRecruitmentAdventurerPlatformsStddevFields;
  demiplane_user_recruitment_adventurer_platforms_stddev_order_by: DemiplaneUserRecruitmentAdventurerPlatformsStddevOrderBy;
  demiplane_user_recruitment_adventurer_platforms_stddev_pop_fields: DemiplaneUserRecruitmentAdventurerPlatformsStddevPopFields;
  demiplane_user_recruitment_adventurer_platforms_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerPlatformsStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_platforms_stddev_samp_fields: DemiplaneUserRecruitmentAdventurerPlatformsStddevSampFields;
  demiplane_user_recruitment_adventurer_platforms_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerPlatformsStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_platforms_sum_fields: DemiplaneUserRecruitmentAdventurerPlatformsSumFields;
  demiplane_user_recruitment_adventurer_platforms_sum_order_by: DemiplaneUserRecruitmentAdventurerPlatformsSumOrderBy;
  demiplane_user_recruitment_adventurer_platforms_var_pop_fields: DemiplaneUserRecruitmentAdventurerPlatformsVarPopFields;
  demiplane_user_recruitment_adventurer_platforms_var_pop_order_by: DemiplaneUserRecruitmentAdventurerPlatformsVarPopOrderBy;
  demiplane_user_recruitment_adventurer_platforms_var_samp_fields: DemiplaneUserRecruitmentAdventurerPlatformsVarSampFields;
  demiplane_user_recruitment_adventurer_platforms_var_samp_order_by: DemiplaneUserRecruitmentAdventurerPlatformsVarSampOrderBy;
  demiplane_user_recruitment_adventurer_platforms_variance_fields: DemiplaneUserRecruitmentAdventurerPlatformsVarianceFields;
  demiplane_user_recruitment_adventurer_platforms_variance_order_by: DemiplaneUserRecruitmentAdventurerPlatformsVarianceOrderBy;
  demiplane_user_recruitment_adventurer_set_input: DemiplaneUserRecruitmentAdventurerSetInput;
  demiplane_user_recruitment_adventurer_stddev_fields: DemiplaneUserRecruitmentAdventurerStddevFields;
  demiplane_user_recruitment_adventurer_stddev_order_by: DemiplaneUserRecruitmentAdventurerStddevOrderBy;
  demiplane_user_recruitment_adventurer_stddev_pop_fields: DemiplaneUserRecruitmentAdventurerStddevPopFields;
  demiplane_user_recruitment_adventurer_stddev_pop_order_by: DemiplaneUserRecruitmentAdventurerStddevPopOrderBy;
  demiplane_user_recruitment_adventurer_stddev_samp_fields: DemiplaneUserRecruitmentAdventurerStddevSampFields;
  demiplane_user_recruitment_adventurer_stddev_samp_order_by: DemiplaneUserRecruitmentAdventurerStddevSampOrderBy;
  demiplane_user_recruitment_adventurer_sum_fields: DemiplaneUserRecruitmentAdventurerSumFields;
  demiplane_user_recruitment_adventurer_sum_order_by: DemiplaneUserRecruitmentAdventurerSumOrderBy;
  demiplane_user_recruitment_adventurer_var_pop_fields: DemiplaneUserRecruitmentAdventurerVarPopFields;
  demiplane_user_recruitment_adventurer_var_pop_order_by: DemiplaneUserRecruitmentAdventurerVarPopOrderBy;
  demiplane_user_recruitment_adventurer_var_samp_fields: DemiplaneUserRecruitmentAdventurerVarSampFields;
  demiplane_user_recruitment_adventurer_var_samp_order_by: DemiplaneUserRecruitmentAdventurerVarSampOrderBy;
  demiplane_user_recruitment_adventurer_variance_fields: DemiplaneUserRecruitmentAdventurerVarianceFields;
  demiplane_user_recruitment_adventurer_variance_order_by: DemiplaneUserRecruitmentAdventurerVarianceOrderBy;
  demiplane_user_recruitment_aggregate: DemiplaneUserRecruitmentAggregate;
  demiplane_user_recruitment_aggregate_fields: DemiplaneUserRecruitmentAggregateFields;
  demiplane_user_recruitment_aggregate_order_by: DemiplaneUserRecruitmentAggregateOrderBy;
  demiplane_user_recruitment_arr_rel_insert_input: DemiplaneUserRecruitmentArrRelInsertInput;
  demiplane_user_recruitment_availability: DemiplaneUserRecruitmentAvailability;
  demiplane_user_recruitment_availability_aggregate: DemiplaneUserRecruitmentAvailabilityAggregate;
  demiplane_user_recruitment_availability_aggregate_fields: DemiplaneUserRecruitmentAvailabilityAggregateFields;
  demiplane_user_recruitment_availability_aggregate_order_by: DemiplaneUserRecruitmentAvailabilityAggregateOrderBy;
  demiplane_user_recruitment_availability_arr_rel_insert_input: DemiplaneUserRecruitmentAvailabilityArrRelInsertInput;
  demiplane_user_recruitment_availability_avg_fields: DemiplaneUserRecruitmentAvailabilityAvgFields;
  demiplane_user_recruitment_availability_avg_order_by: DemiplaneUserRecruitmentAvailabilityAvgOrderBy;
  demiplane_user_recruitment_availability_bool_exp: DemiplaneUserRecruitmentAvailabilityBoolExp;
  demiplane_user_recruitment_availability_inc_input: DemiplaneUserRecruitmentAvailabilityIncInput;
  demiplane_user_recruitment_availability_insert_input: DemiplaneUserRecruitmentAvailabilityInsertInput;
  demiplane_user_recruitment_availability_max_fields: DemiplaneUserRecruitmentAvailabilityMaxFields;
  demiplane_user_recruitment_availability_max_order_by: DemiplaneUserRecruitmentAvailabilityMaxOrderBy;
  demiplane_user_recruitment_availability_min_fields: DemiplaneUserRecruitmentAvailabilityMinFields;
  demiplane_user_recruitment_availability_min_order_by: DemiplaneUserRecruitmentAvailabilityMinOrderBy;
  demiplane_user_recruitment_availability_mutation_response: DemiplaneUserRecruitmentAvailabilityMutationResponse;
  demiplane_user_recruitment_availability_obj_rel_insert_input: DemiplaneUserRecruitmentAvailabilityObjRelInsertInput;
  demiplane_user_recruitment_availability_on_conflict: DemiplaneUserRecruitmentAvailabilityOnConflict;
  demiplane_user_recruitment_availability_order_by: DemiplaneUserRecruitmentAvailabilityOrderBy;
  demiplane_user_recruitment_availability_pk_columns_input: DemiplaneUserRecruitmentAvailabilityPkColumnsInput;
  demiplane_user_recruitment_availability_set_input: DemiplaneUserRecruitmentAvailabilitySetInput;
  demiplane_user_recruitment_availability_stddev_fields: DemiplaneUserRecruitmentAvailabilityStddevFields;
  demiplane_user_recruitment_availability_stddev_order_by: DemiplaneUserRecruitmentAvailabilityStddevOrderBy;
  demiplane_user_recruitment_availability_stddev_pop_fields: DemiplaneUserRecruitmentAvailabilityStddevPopFields;
  demiplane_user_recruitment_availability_stddev_pop_order_by: DemiplaneUserRecruitmentAvailabilityStddevPopOrderBy;
  demiplane_user_recruitment_availability_stddev_samp_fields: DemiplaneUserRecruitmentAvailabilityStddevSampFields;
  demiplane_user_recruitment_availability_stddev_samp_order_by: DemiplaneUserRecruitmentAvailabilityStddevSampOrderBy;
  demiplane_user_recruitment_availability_sum_fields: DemiplaneUserRecruitmentAvailabilitySumFields;
  demiplane_user_recruitment_availability_sum_order_by: DemiplaneUserRecruitmentAvailabilitySumOrderBy;
  demiplane_user_recruitment_availability_var_pop_fields: DemiplaneUserRecruitmentAvailabilityVarPopFields;
  demiplane_user_recruitment_availability_var_pop_order_by: DemiplaneUserRecruitmentAvailabilityVarPopOrderBy;
  demiplane_user_recruitment_availability_var_samp_fields: DemiplaneUserRecruitmentAvailabilityVarSampFields;
  demiplane_user_recruitment_availability_var_samp_order_by: DemiplaneUserRecruitmentAvailabilityVarSampOrderBy;
  demiplane_user_recruitment_availability_variance_fields: DemiplaneUserRecruitmentAvailabilityVarianceFields;
  demiplane_user_recruitment_availability_variance_order_by: DemiplaneUserRecruitmentAvailabilityVarianceOrderBy;
  demiplane_user_recruitment_avg_fields: DemiplaneUserRecruitmentAvgFields;
  demiplane_user_recruitment_avg_order_by: DemiplaneUserRecruitmentAvgOrderBy;
  demiplane_user_recruitment_bool_exp: DemiplaneUserRecruitmentBoolExp;
  demiplane_user_recruitment_game_master: DemiplaneUserRecruitmentGameMaster;
  demiplane_user_recruitment_game_master_aggregate: DemiplaneUserRecruitmentGameMasterAggregate;
  demiplane_user_recruitment_game_master_aggregate_fields: DemiplaneUserRecruitmentGameMasterAggregateFields;
  demiplane_user_recruitment_game_master_aggregate_order_by: DemiplaneUserRecruitmentGameMasterAggregateOrderBy;
  demiplane_user_recruitment_game_master_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterArrRelInsertInput;
  demiplane_user_recruitment_game_master_availability: DemiplaneUserRecruitmentGameMasterAvailability;
  demiplane_user_recruitment_game_master_availability_aggregate: DemiplaneUserRecruitmentGameMasterAvailabilityAggregate;
  demiplane_user_recruitment_game_master_availability_aggregate_fields: DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFields;
  demiplane_user_recruitment_game_master_availability_aggregate_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityAggregateOrderBy;
  demiplane_user_recruitment_game_master_availability_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterAvailabilityArrRelInsertInput;
  demiplane_user_recruitment_game_master_availability_avg_fields: DemiplaneUserRecruitmentGameMasterAvailabilityAvgFields;
  demiplane_user_recruitment_game_master_availability_avg_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityAvgOrderBy;
  demiplane_user_recruitment_game_master_availability_bool_exp: DemiplaneUserRecruitmentGameMasterAvailabilityBoolExp;
  demiplane_user_recruitment_game_master_availability_inc_input: DemiplaneUserRecruitmentGameMasterAvailabilityIncInput;
  demiplane_user_recruitment_game_master_availability_insert_input: DemiplaneUserRecruitmentGameMasterAvailabilityInsertInput;
  demiplane_user_recruitment_game_master_availability_max_fields: DemiplaneUserRecruitmentGameMasterAvailabilityMaxFields;
  demiplane_user_recruitment_game_master_availability_max_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityMaxOrderBy;
  demiplane_user_recruitment_game_master_availability_min_fields: DemiplaneUserRecruitmentGameMasterAvailabilityMinFields;
  demiplane_user_recruitment_game_master_availability_min_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityMinOrderBy;
  demiplane_user_recruitment_game_master_availability_mutation_response: DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponse;
  demiplane_user_recruitment_game_master_availability_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterAvailabilityObjRelInsertInput;
  demiplane_user_recruitment_game_master_availability_on_conflict: DemiplaneUserRecruitmentGameMasterAvailabilityOnConflict;
  demiplane_user_recruitment_game_master_availability_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityOrderBy;
  demiplane_user_recruitment_game_master_availability_pk_columns_input: DemiplaneUserRecruitmentGameMasterAvailabilityPkColumnsInput;
  demiplane_user_recruitment_game_master_availability_set_input: DemiplaneUserRecruitmentGameMasterAvailabilitySetInput;
  demiplane_user_recruitment_game_master_availability_stddev_fields: DemiplaneUserRecruitmentGameMasterAvailabilityStddevFields;
  demiplane_user_recruitment_game_master_availability_stddev_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityStddevOrderBy;
  demiplane_user_recruitment_game_master_availability_stddev_pop_fields: DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopFields;
  demiplane_user_recruitment_game_master_availability_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopOrderBy;
  demiplane_user_recruitment_game_master_availability_stddev_samp_fields: DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampFields;
  demiplane_user_recruitment_game_master_availability_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampOrderBy;
  demiplane_user_recruitment_game_master_availability_sum_fields: DemiplaneUserRecruitmentGameMasterAvailabilitySumFields;
  demiplane_user_recruitment_game_master_availability_sum_order_by: DemiplaneUserRecruitmentGameMasterAvailabilitySumOrderBy;
  demiplane_user_recruitment_game_master_availability_var_pop_fields: DemiplaneUserRecruitmentGameMasterAvailabilityVarPopFields;
  demiplane_user_recruitment_game_master_availability_var_pop_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityVarPopOrderBy;
  demiplane_user_recruitment_game_master_availability_var_samp_fields: DemiplaneUserRecruitmentGameMasterAvailabilityVarSampFields;
  demiplane_user_recruitment_game_master_availability_var_samp_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityVarSampOrderBy;
  demiplane_user_recruitment_game_master_availability_variance_fields: DemiplaneUserRecruitmentGameMasterAvailabilityVarianceFields;
  demiplane_user_recruitment_game_master_availability_variance_order_by: DemiplaneUserRecruitmentGameMasterAvailabilityVarianceOrderBy;
  demiplane_user_recruitment_game_master_avg_fields: DemiplaneUserRecruitmentGameMasterAvgFields;
  demiplane_user_recruitment_game_master_avg_order_by: DemiplaneUserRecruitmentGameMasterAvgOrderBy;
  demiplane_user_recruitment_game_master_bool_exp: DemiplaneUserRecruitmentGameMasterBoolExp;
  demiplane_user_recruitment_game_master_games: DemiplaneUserRecruitmentGameMasterGames;
  demiplane_user_recruitment_game_master_games_aggregate: DemiplaneUserRecruitmentGameMasterGamesAggregate;
  demiplane_user_recruitment_game_master_games_aggregate_fields: DemiplaneUserRecruitmentGameMasterGamesAggregateFields;
  demiplane_user_recruitment_game_master_games_aggregate_order_by: DemiplaneUserRecruitmentGameMasterGamesAggregateOrderBy;
  demiplane_user_recruitment_game_master_games_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterGamesArrRelInsertInput;
  demiplane_user_recruitment_game_master_games_avg_fields: DemiplaneUserRecruitmentGameMasterGamesAvgFields;
  demiplane_user_recruitment_game_master_games_avg_order_by: DemiplaneUserRecruitmentGameMasterGamesAvgOrderBy;
  demiplane_user_recruitment_game_master_games_bool_exp: DemiplaneUserRecruitmentGameMasterGamesBoolExp;
  demiplane_user_recruitment_game_master_games_inc_input: DemiplaneUserRecruitmentGameMasterGamesIncInput;
  demiplane_user_recruitment_game_master_games_insert_input: DemiplaneUserRecruitmentGameMasterGamesInsertInput;
  demiplane_user_recruitment_game_master_games_max_fields: DemiplaneUserRecruitmentGameMasterGamesMaxFields;
  demiplane_user_recruitment_game_master_games_max_order_by: DemiplaneUserRecruitmentGameMasterGamesMaxOrderBy;
  demiplane_user_recruitment_game_master_games_min_fields: DemiplaneUserRecruitmentGameMasterGamesMinFields;
  demiplane_user_recruitment_game_master_games_min_order_by: DemiplaneUserRecruitmentGameMasterGamesMinOrderBy;
  demiplane_user_recruitment_game_master_games_mutation_response: DemiplaneUserRecruitmentGameMasterGamesMutationResponse;
  demiplane_user_recruitment_game_master_games_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterGamesObjRelInsertInput;
  demiplane_user_recruitment_game_master_games_on_conflict: DemiplaneUserRecruitmentGameMasterGamesOnConflict;
  demiplane_user_recruitment_game_master_games_order_by: DemiplaneUserRecruitmentGameMasterGamesOrderBy;
  demiplane_user_recruitment_game_master_games_pk_columns_input: DemiplaneUserRecruitmentGameMasterGamesPkColumnsInput;
  demiplane_user_recruitment_game_master_games_set_input: DemiplaneUserRecruitmentGameMasterGamesSetInput;
  demiplane_user_recruitment_game_master_games_stddev_fields: DemiplaneUserRecruitmentGameMasterGamesStddevFields;
  demiplane_user_recruitment_game_master_games_stddev_order_by: DemiplaneUserRecruitmentGameMasterGamesStddevOrderBy;
  demiplane_user_recruitment_game_master_games_stddev_pop_fields: DemiplaneUserRecruitmentGameMasterGamesStddevPopFields;
  demiplane_user_recruitment_game_master_games_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterGamesStddevPopOrderBy;
  demiplane_user_recruitment_game_master_games_stddev_samp_fields: DemiplaneUserRecruitmentGameMasterGamesStddevSampFields;
  demiplane_user_recruitment_game_master_games_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterGamesStddevSampOrderBy;
  demiplane_user_recruitment_game_master_games_sum_fields: DemiplaneUserRecruitmentGameMasterGamesSumFields;
  demiplane_user_recruitment_game_master_games_sum_order_by: DemiplaneUserRecruitmentGameMasterGamesSumOrderBy;
  demiplane_user_recruitment_game_master_games_var_pop_fields: DemiplaneUserRecruitmentGameMasterGamesVarPopFields;
  demiplane_user_recruitment_game_master_games_var_pop_order_by: DemiplaneUserRecruitmentGameMasterGamesVarPopOrderBy;
  demiplane_user_recruitment_game_master_games_var_samp_fields: DemiplaneUserRecruitmentGameMasterGamesVarSampFields;
  demiplane_user_recruitment_game_master_games_var_samp_order_by: DemiplaneUserRecruitmentGameMasterGamesVarSampOrderBy;
  demiplane_user_recruitment_game_master_games_variance_fields: DemiplaneUserRecruitmentGameMasterGamesVarianceFields;
  demiplane_user_recruitment_game_master_games_variance_order_by: DemiplaneUserRecruitmentGameMasterGamesVarianceOrderBy;
  demiplane_user_recruitment_game_master_inc_input: DemiplaneUserRecruitmentGameMasterIncInput;
  demiplane_user_recruitment_game_master_insert_input: DemiplaneUserRecruitmentGameMasterInsertInput;
  demiplane_user_recruitment_game_master_max_fields: DemiplaneUserRecruitmentGameMasterMaxFields;
  demiplane_user_recruitment_game_master_max_order_by: DemiplaneUserRecruitmentGameMasterMaxOrderBy;
  demiplane_user_recruitment_game_master_min_fields: DemiplaneUserRecruitmentGameMasterMinFields;
  demiplane_user_recruitment_game_master_min_order_by: DemiplaneUserRecruitmentGameMasterMinOrderBy;
  demiplane_user_recruitment_game_master_mutation_response: DemiplaneUserRecruitmentGameMasterMutationResponse;
  demiplane_user_recruitment_game_master_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterObjRelInsertInput;
  demiplane_user_recruitment_game_master_on_conflict: DemiplaneUserRecruitmentGameMasterOnConflict;
  demiplane_user_recruitment_game_master_order_by: DemiplaneUserRecruitmentGameMasterOrderBy;
  demiplane_user_recruitment_game_master_pk_columns_input: DemiplaneUserRecruitmentGameMasterPkColumnsInput;
  demiplane_user_recruitment_game_master_platforms: DemiplaneUserRecruitmentGameMasterPlatforms;
  demiplane_user_recruitment_game_master_platforms_aggregate: DemiplaneUserRecruitmentGameMasterPlatformsAggregate;
  demiplane_user_recruitment_game_master_platforms_aggregate_fields: DemiplaneUserRecruitmentGameMasterPlatformsAggregateFields;
  demiplane_user_recruitment_game_master_platforms_aggregate_order_by: DemiplaneUserRecruitmentGameMasterPlatformsAggregateOrderBy;
  demiplane_user_recruitment_game_master_platforms_arr_rel_insert_input: DemiplaneUserRecruitmentGameMasterPlatformsArrRelInsertInput;
  demiplane_user_recruitment_game_master_platforms_avg_fields: DemiplaneUserRecruitmentGameMasterPlatformsAvgFields;
  demiplane_user_recruitment_game_master_platforms_avg_order_by: DemiplaneUserRecruitmentGameMasterPlatformsAvgOrderBy;
  demiplane_user_recruitment_game_master_platforms_bool_exp: DemiplaneUserRecruitmentGameMasterPlatformsBoolExp;
  demiplane_user_recruitment_game_master_platforms_inc_input: DemiplaneUserRecruitmentGameMasterPlatformsIncInput;
  demiplane_user_recruitment_game_master_platforms_insert_input: DemiplaneUserRecruitmentGameMasterPlatformsInsertInput;
  demiplane_user_recruitment_game_master_platforms_max_fields: DemiplaneUserRecruitmentGameMasterPlatformsMaxFields;
  demiplane_user_recruitment_game_master_platforms_max_order_by: DemiplaneUserRecruitmentGameMasterPlatformsMaxOrderBy;
  demiplane_user_recruitment_game_master_platforms_min_fields: DemiplaneUserRecruitmentGameMasterPlatformsMinFields;
  demiplane_user_recruitment_game_master_platforms_min_order_by: DemiplaneUserRecruitmentGameMasterPlatformsMinOrderBy;
  demiplane_user_recruitment_game_master_platforms_mutation_response: DemiplaneUserRecruitmentGameMasterPlatformsMutationResponse;
  demiplane_user_recruitment_game_master_platforms_obj_rel_insert_input: DemiplaneUserRecruitmentGameMasterPlatformsObjRelInsertInput;
  demiplane_user_recruitment_game_master_platforms_on_conflict: DemiplaneUserRecruitmentGameMasterPlatformsOnConflict;
  demiplane_user_recruitment_game_master_platforms_order_by: DemiplaneUserRecruitmentGameMasterPlatformsOrderBy;
  demiplane_user_recruitment_game_master_platforms_pk_columns_input: DemiplaneUserRecruitmentGameMasterPlatformsPkColumnsInput;
  demiplane_user_recruitment_game_master_platforms_set_input: DemiplaneUserRecruitmentGameMasterPlatformsSetInput;
  demiplane_user_recruitment_game_master_platforms_stddev_fields: DemiplaneUserRecruitmentGameMasterPlatformsStddevFields;
  demiplane_user_recruitment_game_master_platforms_stddev_order_by: DemiplaneUserRecruitmentGameMasterPlatformsStddevOrderBy;
  demiplane_user_recruitment_game_master_platforms_stddev_pop_fields: DemiplaneUserRecruitmentGameMasterPlatformsStddevPopFields;
  demiplane_user_recruitment_game_master_platforms_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterPlatformsStddevPopOrderBy;
  demiplane_user_recruitment_game_master_platforms_stddev_samp_fields: DemiplaneUserRecruitmentGameMasterPlatformsStddevSampFields;
  demiplane_user_recruitment_game_master_platforms_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterPlatformsStddevSampOrderBy;
  demiplane_user_recruitment_game_master_platforms_sum_fields: DemiplaneUserRecruitmentGameMasterPlatformsSumFields;
  demiplane_user_recruitment_game_master_platforms_sum_order_by: DemiplaneUserRecruitmentGameMasterPlatformsSumOrderBy;
  demiplane_user_recruitment_game_master_platforms_var_pop_fields: DemiplaneUserRecruitmentGameMasterPlatformsVarPopFields;
  demiplane_user_recruitment_game_master_platforms_var_pop_order_by: DemiplaneUserRecruitmentGameMasterPlatformsVarPopOrderBy;
  demiplane_user_recruitment_game_master_platforms_var_samp_fields: DemiplaneUserRecruitmentGameMasterPlatformsVarSampFields;
  demiplane_user_recruitment_game_master_platforms_var_samp_order_by: DemiplaneUserRecruitmentGameMasterPlatformsVarSampOrderBy;
  demiplane_user_recruitment_game_master_platforms_variance_fields: DemiplaneUserRecruitmentGameMasterPlatformsVarianceFields;
  demiplane_user_recruitment_game_master_platforms_variance_order_by: DemiplaneUserRecruitmentGameMasterPlatformsVarianceOrderBy;
  demiplane_user_recruitment_game_master_set_input: DemiplaneUserRecruitmentGameMasterSetInput;
  demiplane_user_recruitment_game_master_stddev_fields: DemiplaneUserRecruitmentGameMasterStddevFields;
  demiplane_user_recruitment_game_master_stddev_order_by: DemiplaneUserRecruitmentGameMasterStddevOrderBy;
  demiplane_user_recruitment_game_master_stddev_pop_fields: DemiplaneUserRecruitmentGameMasterStddevPopFields;
  demiplane_user_recruitment_game_master_stddev_pop_order_by: DemiplaneUserRecruitmentGameMasterStddevPopOrderBy;
  demiplane_user_recruitment_game_master_stddev_samp_fields: DemiplaneUserRecruitmentGameMasterStddevSampFields;
  demiplane_user_recruitment_game_master_stddev_samp_order_by: DemiplaneUserRecruitmentGameMasterStddevSampOrderBy;
  demiplane_user_recruitment_game_master_sum_fields: DemiplaneUserRecruitmentGameMasterSumFields;
  demiplane_user_recruitment_game_master_sum_order_by: DemiplaneUserRecruitmentGameMasterSumOrderBy;
  demiplane_user_recruitment_game_master_var_pop_fields: DemiplaneUserRecruitmentGameMasterVarPopFields;
  demiplane_user_recruitment_game_master_var_pop_order_by: DemiplaneUserRecruitmentGameMasterVarPopOrderBy;
  demiplane_user_recruitment_game_master_var_samp_fields: DemiplaneUserRecruitmentGameMasterVarSampFields;
  demiplane_user_recruitment_game_master_var_samp_order_by: DemiplaneUserRecruitmentGameMasterVarSampOrderBy;
  demiplane_user_recruitment_game_master_variance_fields: DemiplaneUserRecruitmentGameMasterVarianceFields;
  demiplane_user_recruitment_game_master_variance_order_by: DemiplaneUserRecruitmentGameMasterVarianceOrderBy;
  demiplane_user_recruitment_inc_input: DemiplaneUserRecruitmentIncInput;
  demiplane_user_recruitment_insert_input: DemiplaneUserRecruitmentInsertInput;
  demiplane_user_recruitment_max_fields: DemiplaneUserRecruitmentMaxFields;
  demiplane_user_recruitment_max_order_by: DemiplaneUserRecruitmentMaxOrderBy;
  demiplane_user_recruitment_min_fields: DemiplaneUserRecruitmentMinFields;
  demiplane_user_recruitment_min_order_by: DemiplaneUserRecruitmentMinOrderBy;
  demiplane_user_recruitment_mutation_response: DemiplaneUserRecruitmentMutationResponse;
  demiplane_user_recruitment_obj_rel_insert_input: DemiplaneUserRecruitmentObjRelInsertInput;
  demiplane_user_recruitment_on_conflict: DemiplaneUserRecruitmentOnConflict;
  demiplane_user_recruitment_order_by: DemiplaneUserRecruitmentOrderBy;
  demiplane_user_recruitment_pk_columns_input: DemiplaneUserRecruitmentPkColumnsInput;
  demiplane_user_recruitment_set_input: DemiplaneUserRecruitmentSetInput;
  demiplane_user_recruitment_stddev_fields: DemiplaneUserRecruitmentStddevFields;
  demiplane_user_recruitment_stddev_order_by: DemiplaneUserRecruitmentStddevOrderBy;
  demiplane_user_recruitment_stddev_pop_fields: DemiplaneUserRecruitmentStddevPopFields;
  demiplane_user_recruitment_stddev_pop_order_by: DemiplaneUserRecruitmentStddevPopOrderBy;
  demiplane_user_recruitment_stddev_samp_fields: DemiplaneUserRecruitmentStddevSampFields;
  demiplane_user_recruitment_stddev_samp_order_by: DemiplaneUserRecruitmentStddevSampOrderBy;
  demiplane_user_recruitment_sum_fields: DemiplaneUserRecruitmentSumFields;
  demiplane_user_recruitment_sum_order_by: DemiplaneUserRecruitmentSumOrderBy;
  demiplane_user_recruitment_var_pop_fields: DemiplaneUserRecruitmentVarPopFields;
  demiplane_user_recruitment_var_pop_order_by: DemiplaneUserRecruitmentVarPopOrderBy;
  demiplane_user_recruitment_var_samp_fields: DemiplaneUserRecruitmentVarSampFields;
  demiplane_user_recruitment_var_samp_order_by: DemiplaneUserRecruitmentVarSampOrderBy;
  demiplane_user_recruitment_variance_fields: DemiplaneUserRecruitmentVarianceFields;
  demiplane_user_recruitment_variance_order_by: DemiplaneUserRecruitmentVarianceOrderBy;
  demiplane_user_role: DemiplaneUserRole;
  demiplane_user_role_aggregate: DemiplaneUserRoleAggregate;
  demiplane_user_role_aggregate_fields: DemiplaneUserRoleAggregateFields;
  demiplane_user_role_aggregate_order_by: DemiplaneUserRoleAggregateOrderBy;
  demiplane_user_role_arr_rel_insert_input: DemiplaneUserRoleArrRelInsertInput;
  demiplane_user_role_avg_fields: DemiplaneUserRoleAvgFields;
  demiplane_user_role_avg_order_by: DemiplaneUserRoleAvgOrderBy;
  demiplane_user_role_bool_exp: DemiplaneUserRoleBoolExp;
  demiplane_user_role_inc_input: DemiplaneUserRoleIncInput;
  demiplane_user_role_insert_input: DemiplaneUserRoleInsertInput;
  demiplane_user_role_max_fields: DemiplaneUserRoleMaxFields;
  demiplane_user_role_max_order_by: DemiplaneUserRoleMaxOrderBy;
  demiplane_user_role_min_fields: DemiplaneUserRoleMinFields;
  demiplane_user_role_min_order_by: DemiplaneUserRoleMinOrderBy;
  demiplane_user_role_mutation_response: DemiplaneUserRoleMutationResponse;
  demiplane_user_role_obj_rel_insert_input: DemiplaneUserRoleObjRelInsertInput;
  demiplane_user_role_on_conflict: DemiplaneUserRoleOnConflict;
  demiplane_user_role_order_by: DemiplaneUserRoleOrderBy;
  demiplane_user_role_pk_columns_input: DemiplaneUserRolePkColumnsInput;
  demiplane_user_role_set_input: DemiplaneUserRoleSetInput;
  demiplane_user_role_stddev_fields: DemiplaneUserRoleStddevFields;
  demiplane_user_role_stddev_order_by: DemiplaneUserRoleStddevOrderBy;
  demiplane_user_role_stddev_pop_fields: DemiplaneUserRoleStddevPopFields;
  demiplane_user_role_stddev_pop_order_by: DemiplaneUserRoleStddevPopOrderBy;
  demiplane_user_role_stddev_samp_fields: DemiplaneUserRoleStddevSampFields;
  demiplane_user_role_stddev_samp_order_by: DemiplaneUserRoleStddevSampOrderBy;
  demiplane_user_role_sum_fields: DemiplaneUserRoleSumFields;
  demiplane_user_role_sum_order_by: DemiplaneUserRoleSumOrderBy;
  demiplane_user_role_var_pop_fields: DemiplaneUserRoleVarPopFields;
  demiplane_user_role_var_pop_order_by: DemiplaneUserRoleVarPopOrderBy;
  demiplane_user_role_var_samp_fields: DemiplaneUserRoleVarSampFields;
  demiplane_user_role_var_samp_order_by: DemiplaneUserRoleVarSampOrderBy;
  demiplane_user_role_variance_fields: DemiplaneUserRoleVarianceFields;
  demiplane_user_role_variance_order_by: DemiplaneUserRoleVarianceOrderBy;
  demiplane_user_set_input: DemiplaneUserSetInput;
  demiplane_user_stddev_fields: DemiplaneUserStddevFields;
  demiplane_user_stddev_order_by: DemiplaneUserStddevOrderBy;
  demiplane_user_stddev_pop_fields: DemiplaneUserStddevPopFields;
  demiplane_user_stddev_pop_order_by: DemiplaneUserStddevPopOrderBy;
  demiplane_user_stddev_samp_fields: DemiplaneUserStddevSampFields;
  demiplane_user_stddev_samp_order_by: DemiplaneUserStddevSampOrderBy;
  demiplane_user_sum_fields: DemiplaneUserSumFields;
  demiplane_user_sum_order_by: DemiplaneUserSumOrderBy;
  demiplane_user_var_pop_fields: DemiplaneUserVarPopFields;
  demiplane_user_var_pop_order_by: DemiplaneUserVarPopOrderBy;
  demiplane_user_var_samp_fields: DemiplaneUserVarSampFields;
  demiplane_user_var_samp_order_by: DemiplaneUserVarSampOrderBy;
  demiplane_user_variance_fields: DemiplaneUserVarianceFields;
  demiplane_user_variance_order_by: DemiplaneUserVarianceOrderBy;
  demiplane_user_visibility: DemiplaneUserVisibility;
  demiplane_user_visibility_aggregate: DemiplaneUserVisibilityAggregate;
  demiplane_user_visibility_aggregate_fields: DemiplaneUserVisibilityAggregateFields;
  demiplane_user_visibility_aggregate_order_by: DemiplaneUserVisibilityAggregateOrderBy;
  demiplane_user_visibility_arr_rel_insert_input: DemiplaneUserVisibilityArrRelInsertInput;
  demiplane_user_visibility_avg_fields: DemiplaneUserVisibilityAvgFields;
  demiplane_user_visibility_avg_order_by: DemiplaneUserVisibilityAvgOrderBy;
  demiplane_user_visibility_bool_exp: DemiplaneUserVisibilityBoolExp;
  demiplane_user_visibility_inc_input: DemiplaneUserVisibilityIncInput;
  demiplane_user_visibility_insert_input: DemiplaneUserVisibilityInsertInput;
  demiplane_user_visibility_max_fields: DemiplaneUserVisibilityMaxFields;
  demiplane_user_visibility_max_order_by: DemiplaneUserVisibilityMaxOrderBy;
  demiplane_user_visibility_min_fields: DemiplaneUserVisibilityMinFields;
  demiplane_user_visibility_min_order_by: DemiplaneUserVisibilityMinOrderBy;
  demiplane_user_visibility_mutation_response: DemiplaneUserVisibilityMutationResponse;
  demiplane_user_visibility_obj_rel_insert_input: DemiplaneUserVisibilityObjRelInsertInput;
  demiplane_user_visibility_on_conflict: DemiplaneUserVisibilityOnConflict;
  demiplane_user_visibility_order_by: DemiplaneUserVisibilityOrderBy;
  demiplane_user_visibility_pk_columns_input: DemiplaneUserVisibilityPkColumnsInput;
  demiplane_user_visibility_set_input: DemiplaneUserVisibilitySetInput;
  demiplane_user_visibility_stddev_fields: DemiplaneUserVisibilityStddevFields;
  demiplane_user_visibility_stddev_order_by: DemiplaneUserVisibilityStddevOrderBy;
  demiplane_user_visibility_stddev_pop_fields: DemiplaneUserVisibilityStddevPopFields;
  demiplane_user_visibility_stddev_pop_order_by: DemiplaneUserVisibilityStddevPopOrderBy;
  demiplane_user_visibility_stddev_samp_fields: DemiplaneUserVisibilityStddevSampFields;
  demiplane_user_visibility_stddev_samp_order_by: DemiplaneUserVisibilityStddevSampOrderBy;
  demiplane_user_visibility_sum_fields: DemiplaneUserVisibilitySumFields;
  demiplane_user_visibility_sum_order_by: DemiplaneUserVisibilitySumOrderBy;
  demiplane_user_visibility_var_pop_fields: DemiplaneUserVisibilityVarPopFields;
  demiplane_user_visibility_var_pop_order_by: DemiplaneUserVisibilityVarPopOrderBy;
  demiplane_user_visibility_var_samp_fields: DemiplaneUserVisibilityVarSampFields;
  demiplane_user_visibility_var_samp_order_by: DemiplaneUserVisibilityVarSampOrderBy;
  demiplane_user_visibility_variance_fields: DemiplaneUserVisibilityVarianceFields;
  demiplane_user_visibility_variance_order_by: DemiplaneUserVisibilityVarianceOrderBy;
  json: Scalars['json'];
  json_comparison_exp: JsonComparisonExp;
  jsonb: Scalars['jsonb'];
  jsonb_comparison_exp: JsonbComparisonExp;
  mutation_root: {};
  numeric: Scalars['numeric'];
  numeric_comparison_exp: NumericComparisonExp;
  query_root: {};
  subscription_root: {};
  time: Scalars['time'];
  time_comparison_exp: TimeComparisonExp;
  timestamp: Scalars['timestamp'];
  timestamp_comparison_exp: TimestampComparisonExp;
  timestamptz: Scalars['timestamptz'];
  timestamptz_comparison_exp: TimestamptzComparisonExp;
  uuid: Scalars['uuid'];
};

export type AboutUserOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AboutUserOutput'] = ResolversParentTypes['AboutUserOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdDetailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdDetailOutput'] = ResolversParentTypes['AdDetailOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdLinkTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdLinkType'] = ResolversParentTypes['AdLinkType']> = {
  ad?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdMatchCriteriaTimeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdMatchCriteriaTimeType'] = ResolversParentTypes['AdMatchCriteriaTimeType']> = {
  adMatchCriteria?: Resolver<ResolversTypes['AdMatchCriteriaType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdMatchCriteriaTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdMatchCriteriaType'] = ResolversParentTypes['AdMatchCriteriaType']> = {
  adventuringPlatforms?: Resolver<Array<ResolversTypes['AdventuringPlatformType']>, ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameTypes?: Resolver<Array<ResolversTypes['GameTypeType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imminent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isGM?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newPlayer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  noCost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  paidOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playerStory?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  puzzlesLogic?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  resultCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rolePlaying?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  socialIntrigue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  strictRules?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  times?: Resolver<Array<ResolversTypes['AdMatchCriteriaTimeType']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdRequestScoredTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdRequestScoredType'] = ResolversParentTypes['AdRequestScoredType']> = {
  acceptedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ad?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  closed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  declinedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  matchCriteria?: Resolver<Maybe<ResolversTypes['AdMatchCriteriaType']>, ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdRequestTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdRequestType'] = ResolversParentTypes['AdRequestType']> = {
  acceptedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ad?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  closed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  declinedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  matchCriteria?: Resolver<Maybe<ResolversTypes['AdMatchCriteriaType']>, ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdResultTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdResultType'] = ResolversParentTypes['AdResultType']> = {
  adRequests?: Resolver<Array<ResolversTypes['AdRequestScoredType']>, ParentType, ContextType>;
  adventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adventuringPlatform?: Resolver<ResolversTypes['AdventuringPlatformType'], ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  demicard?: Resolver<Maybe<ResolversTypes['AttachmentType']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameType?: Resolver<ResolversTypes['GameTypeType'], ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imminent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  imminentLaunchDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invitations?: Resolver<Array<ResolversTypes['InvitationType']>, ParentType, ContextType>;
  invites?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['AdLinkType']>, ParentType, ContextType>;
  matchCriteriaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newPlayer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationHistory?: Resolver<Maybe<Array<ResolversTypes['NotificationHistoryType']>>, ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerStory?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  puzzlesLogic?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rolePlaying?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  searchEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  socialIntrigue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  strictRules?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  times?: Resolver<Maybe<Array<ResolversTypes['AdTimeType']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTimeHasuraTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdTimeHasuraType'] = ResolversParentTypes['AdTimeHasuraType']> = {
  ad?: Resolver<Maybe<ResolversTypes['AdType']>, ParentType, ContextType>;
  adventure?: Resolver<Maybe<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  end_time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  start_time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTimeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdTimeType'] = ResolversParentTypes['AdTimeType']> = {
  ad?: Resolver<Maybe<ResolversTypes['AdType']>, ParentType, ContextType>;
  adventure?: Resolver<Maybe<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdType'] = ResolversParentTypes['AdType']> = {
  adRequests?: Resolver<Array<ResolversTypes['AdRequestType']>, ParentType, ContextType>;
  adventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adventuringPlatform?: Resolver<ResolversTypes['AdventuringPlatformType'], ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  demicard?: Resolver<Maybe<ResolversTypes['AttachmentType']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameType?: Resolver<ResolversTypes['GameTypeType'], ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imminent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  imminentLaunchDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invitations?: Resolver<Array<ResolversTypes['InvitationType']>, ParentType, ContextType>;
  invites?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['AdLinkType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newPlayer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationHistory?: Resolver<Maybe<Array<ResolversTypes['NotificationHistoryType']>>, ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerStory?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  puzzlesLogic?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rolePlaying?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  searchEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  socialIntrigue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  strictRules?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  times?: Resolver<Maybe<Array<ResolversTypes['AdTimeType']>>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddFavoriteGameOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddFavoriteGameOutput'] = ResolversParentTypes['AddFavoriteGameOutput']> = {
  favoriteGameId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddFavoritePlatformOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddFavoritePlatformOutput'] = ResolversParentTypes['AddFavoritePlatformOutput']> = {
  favoritePlatformId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddNewPortalV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddNewPortalV2Output'] = ResolversParentTypes['AddNewPortalV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  updatedUser?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventureConnectionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventureConnectionType'] = ResolversParentTypes['AdventureConnectionType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  connectionStatus?: Resolver<Maybe<ResolversTypes['ConnectionStatusType']>, ParentType, ContextType>;
  connectionStatusDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  connectionType?: Resolver<Maybe<ResolversTypes['ConnectionTypeType']>, ParentType, ContextType>;
  connectionTypeDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  contactType?: Resolver<ResolversTypes['ContactTypeType'], ParentType, ContextType>;
  contactTypeDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  contactor?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  requestee?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  requesteeApproval?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requesteeEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requesteeRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestor?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  requestorApproval?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventureLinkTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventureLinkType'] = ResolversParentTypes['AdventureLinkType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventureMutationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventureMutationType'] = ResolversParentTypes['AdventureMutationType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventureNotificationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventureNotificationType'] = ResolversParentTypes['AdventureNotificationType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventurePlayerMutationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventurePlayerMutationType'] = ResolversParentTypes['AdventurePlayerMutationType']> = {
  adventurePlayerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventurePlayerNotificationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventurePlayerNotificationType'] = ResolversParentTypes['AdventurePlayerNotificationType']> = {
  adventurePlayerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventurePlayerTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventurePlayerType'] = ResolversParentTypes['AdventurePlayerType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  episodeAttendances?: Resolver<Array<Maybe<ResolversTypes['EpisoderAttendanceType']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inVideo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inVoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inWhisper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isGm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['UploadType']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventureTodoTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventureTodoType'] = ResolversParentTypes['AdventureTodoType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  taskMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taskOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventureTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventureType'] = ResolversParentTypes['AdventureType']> = {
  acceptedIncremenetalAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adventurePlayers?: Resolver<Array<ResolversTypes['AdventurePlayerType']>, ParentType, ContextType>;
  adventureTodos?: Resolver<Array<ResolversTypes['AdventureTodoType']>, ParentType, ContextType>;
  adventuringPlatform?: Resolver<Maybe<ResolversTypes['AdventuringPlatformType']>, ParentType, ContextType>;
  autoJoin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  autoJoinThreshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availability?: Resolver<Array<Maybe<ResolversTypes['AdTimeType']>>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  connections?: Resolver<Array<Maybe<ResolversTypes['AdventureConnectionType']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  currentAd?: Resolver<Maybe<ResolversTypes['AdType']>, ParentType, ContextType>;
  currentSession?: Resolver<Maybe<ResolversTypes['SessionType']>, ParentType, ContextType>;
  defaultAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  demicard?: Resolver<Maybe<ResolversTypes['AttachmentType']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  episodeFrequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gameLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameMasterDetails?: Resolver<Maybe<ResolversTypes['SearchAdResultDetailLeaderType']>, ParentType, ContextType>;
  gameType?: Resolver<Maybe<ResolversTypes['GameTypeType']>, ParentType, ContextType>;
  hasGm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasLeaderViewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitations?: Resolver<Array<ResolversTypes['InvitationType']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAdventurePlayer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAdventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isGm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isInvited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLeader?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRallying?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRecruited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isUserActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  journals?: Resolver<Array<Maybe<ResolversTypes['AllJournalsJournalType']>>, ParentType, ContextType>;
  leaderDetails?: Resolver<Maybe<ResolversTypes['SearchAdResultDetailLeaderType']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['AdventureLinkType']>, ParentType, ContextType>;
  matchMakingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matchMakingStatusAdvDetails?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matchMakingStatusAdvImage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matchMakingStatusAdvType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matchMakingStatusGameAvail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matchMakingStatusPartyMgmt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matchMakingStatusThemeScores?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newPlayerFriendly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  openIncremenetalAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paidFlag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playerStory?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  previousSession?: Resolver<Maybe<ResolversTypes['SessionType']>, ParentType, ContextType>;
  puzzlesLogic?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ratingSession?: Resolver<Maybe<ResolversTypes['SessionType']>, ParentType, ContextType>;
  requestedIncremenetalAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rolePlaying?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sessions?: Resolver<Array<ResolversTypes['SessionType']>, ParentType, ContextType>;
  socialIntrigue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  strictRules?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tasks?: Resolver<Array<Maybe<ResolversTypes['TaskType']>>, ParentType, ContextType>;
  totalActiveAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  unreadChatCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  unreadConnectionCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  unreadJournalCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  unreadTaskCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userAdventureRoles?: Resolver<Array<Maybe<ResolversTypes['UserAdventureRoleType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdventuringPlatformTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdventuringPlatformType'] = ResolversParentTypes['AdventuringPlatformType']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ads?: Resolver<Array<ResolversTypes['AdType']>, ParentType, ContextType>;
  adventures?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllJournalsItemTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllJournalsItemType'] = ResolversParentTypes['AllJournalsItemType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isNote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isShared?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSuperEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item?: Resolver<ResolversTypes['ItemUnionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllJournalsJournalTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllJournalsJournalType'] = ResolversParentTypes['AllJournalsJournalType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['AllJournalsItemType']>, ParentType, ContextType>;
  journal?: Resolver<ResolversTypes['JournalType'], ParentType, ContextType>;
  unread?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppVersionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppVersionType'] = ResolversParentTypes['AppVersionType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttachmentType'] = ResolversParentTypes['AttachmentType']> = {
  ads?: Resolver<Maybe<Array<ResolversTypes['AdType']>>, ParentType, ContextType>;
  adventures?: Resolver<Maybe<Array<ResolversTypes['AdventureType']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwardypeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AwardypeType'] = ResolversParentTypes['AwardypeType']> = {
  awardName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatTokenTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatTokenType'] = ResolversParentTypes['ChatTokenType']> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionStatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectionStatusType'] = ResolversParentTypes['ConnectionStatusType']> = {
  connections?: Resolver<Maybe<Array<ResolversTypes['AdventureConnectionType']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionTypeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectionTypeType'] = ResolversParentTypes['ConnectionTypeType']> = {
  connections?: Resolver<Maybe<Array<ResolversTypes['AdventureConnectionType']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactTypeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactTypeType'] = ResolversParentTypes['ContactTypeType']> = {
  connections?: Resolver<Maybe<Array<ResolversTypes['AdventureConnectionType']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationParticipantsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationParticipantsType'] = ResolversParentTypes['ConversationParticipantsType']> = {
  fullParty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  participants?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryType'] = ResolversParentTypes['CountryType']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  code2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code3?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  regions?: Resolver<Array<ResolversTypes['RegionType']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditCardTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditCardType'] = ResolversParentTypes['CreditCardType']> = {
  cardName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cardType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expirationMonth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  expirationYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastDigits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeCardId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type EarningsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EarningsType'] = ResolversParentTypes['EarningsType']> = {
  currentMonthEarnings?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currentMonthEpisodeCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lifetimeEarnings?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lifetimeEpisodeCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EjectGameMasterV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EjectGameMasterV2Output'] = ResolversParentTypes['EjectGameMasterV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EndEpisodeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EndEpisodeOutput'] = ResolversParentTypes['EndEpisodeOutput']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EndEpisodeV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EndEpisodeV2Output'] = ResolversParentTypes['EndEpisodeV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EpisoderAttendanceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EpisoderAttendanceType'] = ResolversParentTypes['EpisoderAttendanceType']> = {
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  joining?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  missing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FavoriteDiceRollTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FavoriteDiceRollType'] = ResolversParentTypes['FavoriteDiceRollType']> = {
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  diceRoll?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  rollName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedGmRecommendationReviewsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeaturedGmRecommendationReviewsOutput'] = ResolversParentTypes['FeaturedGmRecommendationReviewsOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameTypeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameTypeType'] = ResolversParentTypes['GameTypeType']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventures?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmRatingTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GmRatingType'] = ResolversParentTypes['GmRatingType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  rated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ratedPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ratingPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HandleParticipantLeavesWhisperOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HandleParticipantLeavesWhisperOutput'] = ResolversParentTypes['HandleParticipantLeavesWhisperOutput']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HowUserPlaysOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['HowUserPlaysOutput'] = ResolversParentTypes['HowUserPlaysOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImmediatelyOpenEpisodeV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImmediatelyOpenEpisodeV2Output'] = ResolversParentTypes['ImmediatelyOpenEpisodeV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationType'] = ResolversParentTypes['InvitationType']> = {
  acceptedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  acceptedFlag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ad?: Resolver<Maybe<ResolversTypes['AdType']>, ParentType, ContextType>;
  adventure?: Resolver<Maybe<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  declinedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  declinedFlag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recruitedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  recruitedFlag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemUnionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemUnionType'] = ResolversParentTypes['ItemUnionType']> = {
  __resolveType: TypeResolveFn<'JournalNoteType' | 'JournalSuperEventType', ParentType, ContextType>;
};

export type JoinAdventuresAdsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JoinAdventuresAdsOutput'] = ResolversParentTypes['JoinAdventuresAdsOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JoinAdventuresAdsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['JoinAdventuresAdsResult'] = ResolversParentTypes['JoinAdventuresAdsResult']> = {
  acceptedAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adventureFeatured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adventureRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adventureShareLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adventuringPlatformName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowFriday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowMonday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowSaturday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowSunday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowThursday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowTuesday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowWednesday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  matchScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quickMatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quickMatchDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  requestedAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalNoteShareReturnTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalNoteShareReturnType'] = ResolversParentTypes['JournalNoteShareReturnType']> = {
  journalId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  noteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  shares?: Resolver<Array<Maybe<ResolversTypes['JournalNoteShareType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalNoteShareTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalNoteShareType'] = ResolversParentTypes['JournalNoteShareType']> = {
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<ResolversTypes['JournalNoteType'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalNoteTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalNoteType'] = ResolversParentTypes['JournalNoteType']> = {
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  contentDelta?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType>;
  contentHtml?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contentPlain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  journal?: Resolver<ResolversTypes['JournalType'], ParentType, ContextType>;
  shares?: Resolver<Array<ResolversTypes['JournalNoteShareType']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalSuperEventDeleteTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalSuperEventDeleteType'] = ResolversParentTypes['JournalSuperEventDeleteType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  journalId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalSuperEventReadStatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalSuperEventReadStatusType'] = ResolversParentTypes['JournalSuperEventReadStatusType']> = {
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  journalSuperEvent?: Resolver<ResolversTypes['JournalSuperEventType'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalSuperEventTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalSuperEventType'] = ResolversParentTypes['JournalSuperEventType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  journal?: Resolver<ResolversTypes['JournalType'], ParentType, ContextType>;
  readStatuses?: Resolver<Array<Maybe<ResolversTypes['JournalSuperEventReadStatusType']>>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalTitleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalTitleType'] = ResolversParentTypes['JournalTitleType']> = {
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  journal?: Resolver<ResolversTypes['JournalType'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JournalTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JournalType'] = ResolversParentTypes['JournalType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notes?: Resolver<Array<ResolversTypes['JournalNoteType']>, ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  superEvents?: Resolver<Array<ResolversTypes['JournalSuperEventType']>, ParentType, ContextType>;
  titles?: Resolver<Array<ResolversTypes['JournalTitleType']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaveEpisodeV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['LeaveEpisodeV2Output'] = ResolversParentTypes['LeaveEpisodeV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptInvite?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationAcceptInviteArgs, 'connectionId'>>;
  acceptRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationAcceptRecruitArgs, 'connectionId'>>;
  acceptRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationAcceptRequestArgs, 'connectionId'>>;
  addAdventureSlim?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationAddAdventureSlimArgs, 'adventureCost' | 'adventureName' | 'gameTypeCode' | 'invites' | 'isPaid'>>;
  addCreditCard?: Resolver<ResolversTypes['CreditCardType'], ParentType, ContextType, RequireFields<MutationAddCreditCardArgs, 'creditCard'>>;
  addJournalSuperEvent?: Resolver<ResolversTypes['JournalSuperEventType'], ParentType, ContextType, RequireFields<MutationAddJournalSuperEventArgs, 'journalSuperEvent'>>;
  addUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationAddUserArgs, 'user'>>;
  attendEpisode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAttendEpisodeArgs, 'sessionId'>>;
  calculateFeesWithTip?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType, RequireFields<MutationCalculateFeesWithTipArgs, 'tip'>>;
  cancelPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelPaymentArgs, 'sessionId'>>;
  cancelSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationCancelSessionArgs, 'session'>>;
  cantMakeEpisode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCantMakeEpisodeArgs, 'sessionId'>>;
  capturePayment?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType, RequireFields<MutationCapturePaymentArgs, 'sessionId'>>;
  captureTipPayment?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType, RequireFields<MutationCaptureTipPaymentArgs, 'tip'>>;
  completeRatingFlow?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType, RequireFields<MutationCompleteRatingFlowArgs, 'sessionId'>>;
  completeTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationCompleteTaskArgs, 'id'>>;
  contactGameMaster?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationContactGameMasterArgs, 'adventureId'>>;
  createFavoriteDiceRoll?: Resolver<ResolversTypes['FavoriteDiceRollType'], ParentType, ContextType, RequireFields<MutationCreateFavoriteDiceRollArgs, 'adventureId' | 'diceRoll' | 'rollName'>>;
  createTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationCreateTaskArgs, 'task'>>;
  createUpload?: Resolver<ResolversTypes['UploadType'], ParentType, ContextType, RequireFields<MutationCreateUploadArgs, 'file' | 'kind' | 'mimeType'>>;
  declineInvite?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationDeclineInviteArgs, 'connectionId'>>;
  declineRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationDeclineRecruitArgs, 'connectionId'>>;
  declineRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationDeclineRequestArgs, 'connectionId'>>;
  declineTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationDeclineTaskArgs, 'id'>>;
  deleteAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAdventureArgs, 'adventureId'>>;
  deleteFavoriteDiceRoll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFavoriteDiceRollArgs, 'adventureId' | 'favoriteDiceRollId'>>;
  deleteJournalSuperEvent?: Resolver<ResolversTypes['JournalSuperEventDeleteType'], ParentType, ContextType, RequireFields<MutationDeleteJournalSuperEventArgs, 'journalSuperEvent'>>;
  deleteUpload?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUploadArgs, never>>;
  disableAdventureMatchmaking?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationDisableAdventureMatchmakingArgs, 'adventureId'>>;
  ejectAdventureGameMaster?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationEjectAdventureGameMasterArgs, 'adventureId' | 'userId'>>;
  enableAdventureMatchmaking?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationEnableAdventureMatchmakingArgs, 'adventureId' | 'adventurerCount' | 'autoJoin' | 'threshold'>>;
  gameplayNotification?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationGameplayNotificationArgs, 'session'>>;
  immediatelyOpenSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationImmediatelyOpenSessionArgs, 'session'>>;
  insertPlayerAward?: Resolver<ResolversTypes['PlayerAwardType'], ParentType, ContextType, RequireFields<MutationInsertPlayerAwardArgs, 'awardType' | 'sessionId' | 'sessionPlayerId'>>;
  inviteAdventurers?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationInviteAdventurersArgs, 'adventureId' | 'invites'>>;
  joinAdventureRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationJoinAdventureRecruitArgs, 'connectionId'>>;
  joinAdventureRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationJoinAdventureRequestArgs, 'connectionId'>>;
  joinSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationJoinSessionArgs, 'session'>>;
  leaveAdventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationLeaveAdventureArgs, 'adventureId'>>;
  leaveRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationLeaveRecruitArgs, 'connectionId'>>;
  leaveRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationLeaveRequestArgs, 'connectionId'>>;
  leaveSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationLeaveSessionArgs, 'session'>>;
  openSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationOpenSessionArgs, 'adventureId'>>;
  preauthorizePayment?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType, RequireFields<MutationPreauthorizePaymentArgs, 'sessionId'>>;
  precalculateFees?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType, RequireFields<MutationPrecalculateFeesArgs, 'sessionId'>>;
  promoteAdventureGameMaster?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationPromoteAdventureGameMasterArgs, 'adventureId' | 'userId'>>;
  readAdventureConnections?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReadAdventureConnectionsArgs, 'adventureConnnectionIds' | 'adventureId'>>;
  readNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReadNotificationArgs, 'notificationId'>>;
  readNotificationByUrl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReadNotificationByUrlArgs, 'url'>>;
  readSharedNote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReadSharedNoteArgs, 'adventureId' | 'noteId'>>;
  readSuperEvents?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReadSuperEventsArgs, 'adventureId' | 'superEventIds'>>;
  readTasks?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReadTasksArgs, 'adventureId' | 'taskIds'>>;
  readUnreadNotifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  recruitAdventure?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRecruitAdventureArgs, 'adventureId' | 'forGm' | 'recruitId'>>;
  removeAdventurer?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRemoveAdventurerArgs, 'adventureId' | 'userId'>>;
  reorderFavoriteDiceRolls?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReorderFavoriteDiceRollsArgs, 'adventureId' | 'rollIds'>>;
  reorderTasks?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReorderTasksArgs, 'taskIds'>>;
  rescheduleSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRescheduleSessionArgs, 'session'>>;
  scheduleSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationScheduleSessionArgs, 'session'>>;
  sendAccountCreateEmail?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  sendFeedbackEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendFeedbackEmailArgs, 'adventureId' | 'feedback' | 'gmRating' | 'userId'>>;
  sendTipEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendTipEmailArgs, 'adventureId' | 'userId'>>;
  setEpisodeFrequency?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetEpisodeFrequencyArgs, 'adventureId' | 'episodeFrequency'>>;
  shareJournalNote?: Resolver<ResolversTypes['JournalNoteShareReturnType'], ParentType, ContextType, RequireFields<MutationShareJournalNoteArgs, 'journalNote'>>;
  specialRequest?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationSpecialRequestArgs, 'adventureId'>>;
  startSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationStartSessionArgs, 'session'>>;
  submitFile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSubmitFileArgs, 'chatHash' | 'file'>>;
  submitGiphy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSubmitGiphyArgs, 'chatHash' | 'url'>>;
  submitMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSubmitMessageArgs, 'chatHash' | 'message'>>;
  submitSystemMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSubmitSystemMessageArgs, 'chatHash' | 'message'>>;
  toggleVoiceVideoStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationToggleVoiceVideoStatusArgs, 'adventurePlayerId' | 'inVideo' | 'inVoice'>>;
  toggleWhisperStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationToggleWhisperStatusArgs, 'adventurePlayerId'>>;
  triggerDisableWhisper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationTriggerDisableWhisperArgs, 'adventurePlayerId'>>;
  triggerEnableWhisper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationTriggerEnableWhisperArgs, 'adventurePlayerId'>>;
  updateAdventureCoverImage?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationUpdateAdventureCoverImageArgs, 'adventureId' | 'demicardId'>>;
  updateAdventureDetails?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationUpdateAdventureDetailsArgs, 'adventureId' | 'adventuringPlatformId' | 'description' | 'gameLinkUrl' | 'newPlayerFriendly'>>;
  updateAdventureFrequency?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationUpdateAdventureFrequencyArgs, 'adventureId' | 'frequency'>>;
  updateAdventureGameAvailability?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationUpdateAdventureGameAvailabilityArgs, 'adventureId'>>;
  updateAdventureMatchMakingStatus?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationUpdateAdventureMatchMakingStatusArgs, 'adventureId'>>;
  updateAdventurePlayerName?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType, RequireFields<MutationUpdateAdventurePlayerNameArgs, 'adventurePlayerId' | 'playerName'>>;
  updateAdventureThemeScores?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationUpdateAdventureThemeScoresArgs, 'adventureId' | 'combat' | 'playerStory' | 'puzzlesLogic' | 'rolePlaying' | 'socialIntrigue' | 'strictRules'>>;
  updateFavoriteDiceRoll?: Resolver<ResolversTypes['FavoriteDiceRollType'], ParentType, ContextType, RequireFields<MutationUpdateFavoriteDiceRollArgs, 'adventureId' | 'favoriteDiceRollId' | 'newDiceRoll' | 'newRollName'>>;
  updateFirstLogin?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationUpdateFirstLoginArgs, 'firstLogin'>>;
  updateGameMasterBio?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationUpdateGameMasterBioArgs, 'gameMasterBio'>>;
  updateGmRating?: Resolver<ResolversTypes['GmRatingType'], ParentType, ContextType, RequireFields<MutationUpdateGmRatingArgs, 'rating' | 'sessionId' | 'sessionPlayerId'>>;
  updateJournal?: Resolver<ResolversTypes['JournalTitleType'], ParentType, ContextType, RequireFields<MutationUpdateJournalArgs, 'journal'>>;
  updateJournalNote?: Resolver<ResolversTypes['JournalNoteType'], ParentType, ContextType, RequireFields<MutationUpdateJournalNoteArgs, 'journalNote'>>;
  updateJournalSuperEvent?: Resolver<ResolversTypes['JournalSuperEventType'], ParentType, ContextType, RequireFields<MutationUpdateJournalSuperEventArgs, 'journalSuperEvent'>>;
  updateLeaderHasViewed?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationUpdateLeaderHasViewedArgs, 'adventureId' | 'isAdventurer' | 'isGm'>>;
  updatePlayerRatings?: Resolver<Array<ResolversTypes['PlayerRatingType']>, ParentType, ContextType, RequireFields<MutationUpdatePlayerRatingsArgs, 'ratings' | 'sessionId'>>;
  updatePreferences?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationUpdatePreferencesArgs, 'userPreferences'>>;
  updateTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationUpdateTaskArgs, 'id' | 'title'>>;
  updateUpload?: Resolver<ResolversTypes['UploadType'], ParentType, ContextType, RequireFields<MutationUpdateUploadArgs, 'file' | 'kind' | 'mimeType' | 'uploadId'>>;
  updateUserInformation?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationUpdateUserInformationArgs, 'userInformation'>>;
  updateUserMetadata?: Resolver<ResolversTypes['UserMetadataType'], ParentType, ContextType, RequireFields<MutationUpdateUserMetadataArgs, 'userMetadata'>>;
  updateUserRecruitment?: Resolver<ResolversTypes['UserRecruitmentType'], ParentType, ContextType, RequireFields<MutationUpdateUserRecruitmentArgs, 'userRecruitment'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyAdventuresTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MyAdventuresType'] = ResolversParentTypes['MyAdventuresType']> = {
  actives?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  defaults?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationHistoryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationHistoryType'] = ResolversParentTypes['NotificationHistoryType']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  heading?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  osId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  submitor?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<Array<ResolversTypes['UserType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpenEpisodeV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpenEpisodeV2Output'] = ResolversParentTypes['OpenEpisodeV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OtherAdventuresAdsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['OtherAdventuresAdsOutput'] = ResolversParentTypes['OtherAdventuresAdsOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OtherAdventuresAdsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['OtherAdventuresAdsResult'] = ResolversParentTypes['OtherAdventuresAdsResult']> = {
  acceptedAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adventureShareLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adventuringPlatformName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowFriday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowMonday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowSaturday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowSunday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowThursday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowTuesday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowWednesday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  matchScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quickMatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quickMatchDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  requestedAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantLeavesWhisperOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipantLeavesWhisperOutput'] = ResolversParentTypes['ParticipantLeavesWhisperOutput']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentType'] = ResolversParentTypes['PaymentType']> = {
  authorized?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  captured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  chargeAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  refunded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  shouldCapture?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  stripeChargeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tipCharge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerAwardTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlayerAwardType'] = ResolversParentTypes['PlayerAwardType']> = {
  awardType?: Resolver<ResolversTypes['AwardypeType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  votedPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  votingPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerRatingTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlayerRatingType'] = ResolversParentTypes['PlayerRatingType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  rated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ratedPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ratingPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoteGameMasterV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoteGameMasterV2Output'] = ResolversParentTypes['PromoteGameMasterV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PronounTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PronounType'] = ResolversParentTypes['PronounType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<QueryAdventureArgs, 'id'>>;
  adventureActives?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  adventureDetails?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<QueryAdventureDetailsArgs, 'adventureId'>>;
  adventureHosts?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  adventuringPlatforms?: Resolver<Array<ResolversTypes['AdventuringPlatformType']>, ParentType, ContextType>;
  almostFullAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  appVersion?: Resolver<ResolversTypes['AppVersionType'], ParentType, ContextType>;
  chatToken?: Resolver<ResolversTypes['ChatTokenType'], ParentType, ContextType>;
  conversationParticipants?: Resolver<ResolversTypes['ConversationParticipantsType'], ParentType, ContextType, RequireFields<QueryConversationParticipantsArgs, 'chatHash'>>;
  creditCards?: Resolver<Array<ResolversTypes['CreditCardType']>, ParentType, ContextType>;
  currentUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  demicards?: Resolver<Array<ResolversTypes['AttachmentType']>, ParentType, ContextType>;
  earnings?: Resolver<ResolversTypes['EarningsType'], ParentType, ContextType>;
  emailExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryEmailExistsArgs, 'email'>>;
  favoriteDiceRolls?: Resolver<Array<ResolversTypes['FavoriteDiceRollType']>, ParentType, ContextType, RequireFields<QueryFavoriteDiceRollsArgs, 'adventureId'>>;
  freeAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  gameTypes?: Resolver<Array<ResolversTypes['GameTypeType']>, ParentType, ContextType>;
  getAdventureByLinkCode?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<QueryGetAdventureByLinkCodeArgs, 'code'>>;
  getAdventureRecruits?: Resolver<Array<ResolversTypes['RecruitType']>, ParentType, ContextType, RequireFields<QueryGetAdventureRecruitsArgs, 'adventureId' | 'displayCount' | 'excludeRecruited' | 'recruitForGameMaster'>>;
  getQuestRecruitSummary?: Resolver<ResolversTypes['RecruitSummaryType'], ParentType, ContextType, RequireFields<QueryGetQuestRecruitSummaryArgs, 'questId'>>;
  journalNote?: Resolver<ResolversTypes['JournalNoteType'], ParentType, ContextType, RequireFields<QueryJournalNoteArgs, 'adventureId' | 'id' | 'isShared'>>;
  myAdventures?: Resolver<ResolversTypes['MyAdventuresType'], ParentType, ContextType>;
  newestAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  notificationHistory?: Resolver<Array<ResolversTypes['NotificationHistoryType']>, ParentType, ContextType, RequireFields<QueryNotificationHistoryArgs, 'limit'>>;
  paymentAdventureHosts?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  paymentsByUserSession?: Resolver<Array<ResolversTypes['PaymentType']>, ParentType, ContextType, RequireFields<QueryPaymentsByUserSessionArgs, 'sessionId' | 'userId'>>;
  playingSoonAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  regionsByCountryAbbreviation?: Resolver<Array<ResolversTypes['RegionType']>, ParentType, ContextType, RequireFields<QueryRegionsByCountryAbbreviationArgs, 'countryCode'>>;
  searchAdDetail?: Resolver<ResolversTypes['SearchAdResultDetailType'], ParentType, ContextType, RequireFields<QuerySearchAdDetailArgs, 'adId'>>;
  searchAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType, RequireFields<QuerySearchAdsArgs, 'adventuringPlatformIds' | 'availability' | 'frequencyOneTime' | 'frequencyRecurring' | 'gameTypeIds' | 'newPlayer' | 'noCost' | 'quickMatch' | 'recordCount'>>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType, RequireFields<QuerySessionArgs, 'id'>>;
  sessionHistories?: Resolver<Array<ResolversTypes['SessionHistoryType']>, ParentType, ContextType>;
  snippet?: Resolver<ResolversTypes['SnippetType'], ParentType, ContextType, RequireFields<QuerySnippetArgs, 'name'>>;
  stripeConnectUrl?: Resolver<ResolversTypes['StripeLoginLinkType'], ParentType, ContextType>;
  stripeUserBalance?: Resolver<ResolversTypes['StripeUserBalanceType'], ParentType, ContextType>;
  stripeUserBalanceTransactions?: Resolver<Array<ResolversTypes['StripeBalanceTransactionType']>, ParentType, ContextType, RequireFields<QueryStripeUserBalanceTransactionsArgs, never>>;
  suggestedAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  systemStatus?: Resolver<ResolversTypes['StatusType'], ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['UploadType']>, ParentType, ContextType, RequireFields<QueryUploadArgs, 'userId'>>;
  userAdventurePlayers?: Resolver<Array<ResolversTypes['AdventurePlayerType']>, ParentType, ContextType>;
  userConversations?: Resolver<ResolversTypes['UserConversationsType'], ParentType, ContextType>;
  userEmailNotificationPref?: Resolver<ResolversTypes['UserEmailNotificationPrefType'], ParentType, ContextType>;
  userPlayTime?: Resolver<ResolversTypes['UserPlayTimeType'], ParentType, ContextType, RequireFields<QueryUserPlayTimeArgs, 'userId'>>;
  userProfile?: Resolver<ResolversTypes['UserProfileType'], ParentType, ContextType, RequireFields<QueryUserProfileArgs, 'userId'>>;
  userPushNotificationPref?: Resolver<ResolversTypes['UserPushNotificationPrefType'], ParentType, ContextType>;
  userRatingsAndAwards?: Resolver<ResolversTypes['UserRatingsAndAwardsType'], ParentType, ContextType, RequireFields<QueryUserRatingsAndAwardsArgs, 'userId'>>;
  userRecruitmentByUserId?: Resolver<Maybe<ResolversTypes['UserRecruitmentType']>, ParentType, ContextType, RequireFields<QueryUserRecruitmentByUserIdArgs, 'userId'>>;
  usernameExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryUsernameExistsArgs, 'username'>>;
  validateUserAddress?: Resolver<ResolversTypes['UserAddressType'], ParentType, ContextType, RequireFields<QueryValidateUserAddressArgs, 'userAddress'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecruitAdventurerOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecruitAdventurerOutput'] = ResolversParentTypes['RecruitAdventurerOutput']> = {
  hours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  votes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecruitAdventurersOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecruitAdventurersOutput'] = ResolversParentTypes['RecruitAdventurersOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recruitAdventurers?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecruitGameMasterOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecruitGameMasterOutput'] = ResolversParentTypes['RecruitGameMasterOutput']> = {
  hours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  votes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecruitGameMastersOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecruitGameMastersOutput'] = ResolversParentTypes['RecruitGameMastersOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recruitGameMasters?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecruitSummaryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecruitSummaryType'] = ResolversParentTypes['RecruitSummaryType']> = {
  playerAdventuringPlatformCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  playerGameTypeCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  questId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecruitTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecruitType'] = ResolversParentTypes['RecruitType']> = {
  hours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['UploadType']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  votes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegionType'] = ResolversParentTypes['RegionType']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  code2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryType']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['UserType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveAdventurerV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RemoveAdventurerV2Output'] = ResolversParentTypes['RemoveAdventurerV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReorderFavoriteGamesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReorderFavoriteGamesOutput'] = ResolversParentTypes['ReorderFavoriteGamesOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReorderFavoritePlatformsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReorderFavoritePlatformsOutput'] = ResolversParentTypes['ReorderFavoritePlatformsOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoleType'] = ResolversParentTypes['RoleType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  roleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userAdventureRoles?: Resolver<Maybe<Array<ResolversTypes['UserAdventureRoleType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchAdDetailMmOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchAdDetailMMOutput'] = ResolversParentTypes['SearchAdDetailMMOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchAdResultDetailLeaderTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchAdResultDetailLeaderType'] = ResolversParentTypes['SearchAdResultDetailLeaderType']> = {
  adventuringHours?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adventuringThumbsUp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bio?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostingHours?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hostingThumbsUp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mvpCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['UploadType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchAdResultDetailTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchAdResultDetailType'] = ResolversParentTypes['SearchAdResultDetailType']> = {
  acceptedAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adventureId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adventureShareLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adventuringPlatformName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dowFriday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowMonday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowSaturday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowSunday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowThursday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowTuesday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowWednesday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  gameTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leader?: Resolver<ResolversTypes['SearchAdResultDetailLeaderType'], ParentType, ContextType>;
  matchScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  playerStory?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  puzzlesLogic?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quickMatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quickMatchDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  requestedAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rolePlaying?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  socialIntrigue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  strictRules?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  times?: Resolver<Maybe<Array<ResolversTypes['AdTimeHasuraType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchAdResultTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchAdResultType'] = ResolversParentTypes['SearchAdResultType']> = {
  acceptedAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adventuringPlatformName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dowFriday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowMonday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowSaturday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowSunday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowThursday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowTuesday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dowWednesday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  matchScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quickMatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quickMatchDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  requestedAdventurerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchAdsMmOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchAdsMMOutput'] = ResolversParentTypes['SearchAdsMMOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchAdsMmResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchAdsMMResult'] = ResolversParentTypes['SearchAdsMMResult']> = {
  acceptedAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adventureShareLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adventuringPlatformName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowFriday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowMonday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowSaturday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowSunday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowThursday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowTuesday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dowWednesday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameMaster?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  matchScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quickMatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quickMatchDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  requestedAdventurerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionHistoryTipTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionHistoryTipType'] = ResolversParentTypes['SessionHistoryTipType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tipAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionHistoryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionHistoryType'] = ResolversParentTypes['SessionHistoryType']> = {
  adventureName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  demicardImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  gmRatings?: Resolver<Maybe<Array<ResolversTypes['GmRatingType']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  playerRatings?: Resolver<Maybe<Array<ResolversTypes['PlayerRatingType']>>, ParentType, ContextType>;
  sessionAdventurerCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sessionPaymentFeesCharged?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sessionPaymentTips?: Resolver<Maybe<Array<ResolversTypes['SessionHistoryTipType']>>, ParentType, ContextType>;
  sessionPaymentTipsCharged?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sessionPaymentTotalCharged?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  wasGm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionMutationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionMutationType'] = ResolversParentTypes['SessionMutationType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionNotificationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionNotificationType'] = ResolversParentTypes['SessionNotificationType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionPlayerActiveTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionPlayerActiveType'] = ResolversParentTypes['SessionPlayerActiveType']> = {
  adventurePlayerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  playerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  realName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sessionPlayerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionPlayerMutationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionPlayerMutationType'] = ResolversParentTypes['SessionPlayerMutationType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  sessionPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionPlayerNotificationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionPlayerNotificationType'] = ResolversParentTypes['SessionPlayerNotificationType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  sessionPlayer?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionPlayerTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionPlayerType'] = ResolversParentTypes['SessionPlayerType']> = {
  combinedTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedRatingFlow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gmFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gmRatings?: Resolver<Array<Maybe<ResolversTypes['GmRatingType']>>, ParentType, ContextType>;
  gmsRated?: Resolver<Array<Maybe<ResolversTypes['GmRatingType']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isGm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  joinedSession?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  leftDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  leftSession?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playerAwards?: Resolver<Array<Maybe<ResolversTypes['PlayerAwardType']>>, ParentType, ContextType>;
  playerRatings?: Resolver<Array<Maybe<ResolversTypes['PlayerRatingType']>>, ParentType, ContextType>;
  playersAwarded?: Resolver<Array<Maybe<ResolversTypes['PlayerAwardType']>>, ParentType, ContextType>;
  playersRated?: Resolver<Array<Maybe<ResolversTypes['PlayerRatingType']>>, ParentType, ContextType>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType>;
  taxAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tipAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transactionFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionType'] = ResolversParentTypes['SessionType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  adventurerJoinedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurerTotalCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cancelled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cancelledDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  episodeAttendances?: Resolver<Array<Maybe<ResolversTypes['EpisoderAttendanceType']>>, ParentType, ContextType>;
  gmJoinedCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gmLookup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gmTotalCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  live?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  open?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  openDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  payments?: Resolver<Maybe<Array<ResolversTypes['PaymentType']>>, ParentType, ContextType>;
  scheduledDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  sessionPlayers?: Resolver<Array<ResolversTypes['SessionPlayerType']>, ParentType, ContextType>;
  sessionPlayersActive?: Resolver<Maybe<Array<ResolversTypes['SessionPlayerActiveType']>>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SnippetTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SnippetType'] = ResolversParentTypes['SnippetType']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartEpisodeV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['StartEpisodeV2Output'] = ResolversParentTypes['StartEpisodeV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatusType'] = ResolversParentTypes['StatusType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeBalanceTransactionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeBalanceTransactionType'] = ResolversParentTypes['StripeBalanceTransactionType']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  available_on?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  net?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  object?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reporting_category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeBalanceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeBalanceType'] = ResolversParentTypes['StripeBalanceType']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source_types?: Resolver<Maybe<ResolversTypes['StripeSourceTypesType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeLoginLinkTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeLoginLinkType'] = ResolversParentTypes['StripeLoginLinkType']> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeSourceTypesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeSourceTypesType'] = ResolversParentTypes['StripeSourceTypesType']> = {
  card?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeUserBalanceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeUserBalanceType'] = ResolversParentTypes['StripeUserBalanceType']> = {
  available?: Resolver<Maybe<Array<ResolversTypes['StripeBalanceType']>>, ParentType, ContextType>;
  pending?: Resolver<Maybe<Array<ResolversTypes['StripeBalanceType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitFileV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmitFileV2Output'] = ResolversParentTypes['SubmitFileV2Output']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  adventurePlayerRemoved?: Resolver<ResolversTypes['AdventureNotificationType'], ParentType, ContextType, RequireFields<SubscriptionAdventurePlayerRemovedArgs, 'adventurePlayerIds'>>;
  disableWhisper?: Resolver<ResolversTypes['AdventurePlayerNotificationType'], ParentType, ContextType, RequireFields<SubscriptionDisableWhisperArgs, 'adventurePlayerId'>>;
  enableWhisper?: Resolver<ResolversTypes['AdventurePlayerNotificationType'], ParentType, ContextType, RequireFields<SubscriptionEnableWhisperArgs, 'adventurePlayerId'>>;
  sessionCancelled?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionCancelledArgs, 'adventureIds'>>;
  sessionEnded?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionEndedArgs, 'adventureIds'>>;
  sessionGameplayNotification?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionGameplayNotificationArgs, 'adventureIds'>>;
  sessionJoined?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionJoinedArgs, 'adventureIds'>>;
  sessionLeft?: Resolver<ResolversTypes['SessionPlayerNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionLeftArgs, 'adventureIds'>>;
  sessionOpened?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionOpenedArgs, 'adventureIds'>>;
  sessionRescheduled?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionRescheduledArgs, 'adventureIds'>>;
  sessionScheduled?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionScheduledArgs, 'adventureIds'>>;
  sessionStarted?: Resolver<ResolversTypes['SessionNotificationType'], ParentType, ContextType, RequireFields<SubscriptionSessionStartedArgs, 'adventureIds'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TConversationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TConversation'] = ResolversParentTypes['TConversation']> = {
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participants?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topicId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  welcomeMessages?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskReadStatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskReadStatusType'] = ResolversParentTypes['TaskReadStatusType']> = {
  adventurePlayer?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskType'] = ResolversParentTypes['TaskType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  completedBy?: Resolver<Maybe<ResolversTypes['AdventurePlayerType']>, ParentType, ContextType>;
  completedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType>;
  declined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  declinedBy?: Resolver<Maybe<ResolversTypes['AdventurePlayerType']>, ParentType, ContextType>;
  declinedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  readStatuses?: Resolver<Array<Maybe<ResolversTypes['TaskReadStatusType']>>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  super?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionDetailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionDetailOutput'] = ResolversParentTypes['TransactionDetailOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  transactionDetail?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionHistoryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionHistoryOutput'] = ResolversParentTypes['TransactionHistoryOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  transactionHistory?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerDisableWhisperV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerDisableWhisperV2Output'] = ResolversParentTypes['TriggerDisableWhisperV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerEnableWhisperV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerEnableWhisperV2Output'] = ResolversParentTypes['TriggerEnableWhisperV2Output']> = {
  adventureId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAdventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBillingAddressOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateBillingAddressOutput'] = ResolversParentTypes['UpdateBillingAddressOutput']> = {
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipcode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipcodePlus4Code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUploadV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUploadV2Output'] = ResolversParentTypes['UpdateUploadV2Output']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedUpload?: Resolver<Maybe<ResolversTypes['demiplane_upload']>, ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserInformationV2OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUserInformationV2Output'] = ResolversParentTypes['UpdateUserInformationV2Output']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedUser?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UploadTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadType'] = ResolversParentTypes['UploadType']> = {
  adventurePlayer?: Resolver<Maybe<ResolversTypes['AdventurePlayerType']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAddressTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAddressType'] = ResolversParentTypes['UserAddressType']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcodePlus4Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAdventureRatingsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAdventureRatingsOutput'] = ResolversParentTypes['UserAdventureRatingsOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAdventureRoleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAdventureRoleType'] = ResolversParentTypes['UserAdventureRoleType']> = {
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['RoleType'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserConversationsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConversationsType'] = ResolversParentTypes['UserConversationsType']> = {
  conversations?: Resolver<Array<Maybe<ResolversTypes['TConversation']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEmailNotificationPrefTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserEmailNotificationPrefType'] = ResolversParentTypes['UserEmailNotificationPrefType']> = {
  adventureRallying?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventureRescheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventureScheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventurerLeavesAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  apLaunchesAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdFirstQuest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedToQuestOrParty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  postAdventureReminder?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  questApCreatorLaunchesAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  questLaunchesAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestToJoinAcceptDecline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestToJoinReceived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  unreadNotifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  weeklyJournalAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  weeklyTaskAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInvitationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInvitationType'] = ResolversParentTypes['UserInvitationType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitation?: Resolver<ResolversTypes['InvitationType'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMetadataTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMetadataType'] = ResolversParentTypes['UserMetadataType']> = {
  acceptedTerms?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  utmCampaign?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utmMedium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utmSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  viewedActiveGmMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewedActivePlayerMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewedAddToHomeScreen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewedMatchedGmMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewedMatchedPlayerMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPlayTimeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPlayTimeType'] = ResolversParentTypes['UserPlayTimeType']> = {
  gmPlayTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  playerPlayTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalPlayTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfileType'] = ResolversParentTypes['UserProfileType']> = {
  adventuringHours?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adventuringThumbsUp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameMasterBio?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostingHours?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hostingThumbsUp?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mvpCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['UploadType']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPushNotificationPrefTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPushNotificationPrefType'] = ResolversParentTypes['UserPushNotificationPrefType']> = {
  adventureIsRallying?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventureReminders?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventurerRemovedFromAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventurerRequestsToJoin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  gmAcceptsAdventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gmDeniesAdventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gmRecruitsAdventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gmRequestsToHost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedToAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invitedToAdventuringParty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invitedToQuest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newChatAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newJournalAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  partyLeaderAcceptsGM?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  partyLeaderDeniesGM?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  userConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userJoinsPortal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRatingsAndAwardsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRatingsAndAwardsType'] = ResolversParentTypes['UserRatingsAndAwardsType']> = {
  gmRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mvpAwards?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  playerRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRecruitmentAvailabilityTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRecruitmentAvailabilityType'] = ResolversParentTypes['UserRecruitmentAvailabilityType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userRecruitment?: Resolver<ResolversTypes['UserRecruitmentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRecruitmentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRecruitmentType'] = ResolversParentTypes['UserRecruitmentType']> = {
  adventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventuringPlatforms?: Resolver<Array<ResolversTypes['AdventuringPlatformType']>, ParentType, ContextType>;
  availabilities?: Resolver<Array<ResolversTypes['UserRecruitmentAvailabilityType']>, ParentType, ContextType>;
  availabilityStartOfWeek?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  gameMaster?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gameTypes?: Resolver<Array<ResolversTypes['GameTypeType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxMatchScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minMatchScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  noCost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSocialProfilesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSocialProfilesOutput'] = ResolversParentTypes['UserSocialProfilesOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSocialProfilesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSocialProfilesResult'] = ResolversParentTypes['UserSocialProfilesResult']> = {
  facebook_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitch_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTreasureTroveOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTreasureTroveOutput'] = ResolversParentTypes['UserTreasureTroveOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserType'] = ResolversParentTypes['UserType']> = {
  accessLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adventurePlayers?: Resolver<Maybe<Array<ResolversTypes['AdventurePlayerType']>>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognitoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  contactorConnections?: Resolver<Maybe<Array<ResolversTypes['AdventureConnectionType']>>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryType'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creditCards?: Resolver<Maybe<Array<ResolversTypes['CreditCardType']>>, ParentType, ContextType>;
  dob?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  experience?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  featureLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameMasterBio?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactiveMonthAlertedEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inactiveTwoMonthAlertedEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inactiveWeekAlertedEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastAccess?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newUserSecondEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newUserThirdEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationHistory?: Resolver<Maybe<Array<ResolversTypes['NotificationHistoryType']>>, ParentType, ContextType>;
  notificationSubmissionHistory?: Resolver<Maybe<Array<ResolversTypes['NotificationHistoryType']>>, ParentType, ContextType>;
  payments?: Resolver<Maybe<Array<ResolversTypes['PaymentType']>>, ParentType, ContextType>;
  playerStory?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pronoun?: Resolver<Maybe<ResolversTypes['PronounType']>, ParentType, ContextType>;
  puzzlesLogic?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  recruitmentSetOrAlerted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['RegionType'], ParentType, ContextType>;
  requesteeConnections?: Resolver<Maybe<Array<ResolversTypes['AdventureConnectionType']>>, ParentType, ContextType>;
  requestorConnections?: Resolver<Maybe<Array<ResolversTypes['AdventureConnectionType']>>, ParentType, ContextType>;
  rolePlaying?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['RoleType']>>, ParentType, ContextType>;
  sessionPlayers?: Resolver<Maybe<Array<ResolversTypes['SessionPlayerType']>>, ParentType, ContextType>;
  socialIntrigue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  strictRules?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stripeConnectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeZone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tutorialSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['UploadType']>, ParentType, ContextType>;
  userAdventureRoles?: Resolver<Maybe<Array<ResolversTypes['UserAdventureRoleType']>>, ParentType, ContextType>;
  userEmailNotificationPref?: Resolver<Maybe<ResolversTypes['UserEmailNotificationPrefType']>, ParentType, ContextType>;
  userInvitations?: Resolver<Maybe<Array<ResolversTypes['UserInvitationType']>>, ParentType, ContextType>;
  userMetadata?: Resolver<ResolversTypes['UserMetadataType'], ParentType, ContextType>;
  userPushNotificationPref?: Resolver<Maybe<ResolversTypes['UserPushNotificationPrefType']>, ParentType, ContextType>;
  userRecruitment?: Resolver<Maybe<ResolversTypes['UserRecruitmentType']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcodePlus4Code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthMmOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['authMMOutput'] = ResolversParentTypes['authMMOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  session?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthMmTokenOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['authMMTokenOutput'] = ResolversParentTypes['authMMTokenOutput']> = {
  jwt?: Resolver<ResolversTypes['json'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['date'], any> {
  name: 'date';
}

export type DemiplaneAdResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad'] = ResolversParentTypes['demiplane_ad']> = {
  ad_link?: Resolver<Maybe<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType>;
  ad_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ad_requests?: Resolver<Array<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<DemiplaneAdAdRequestsArgs, never>>;
  ad_requests_aggregate?: Resolver<ResolversTypes['demiplane_ad_request_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdAdRequestsAggregateArgs, never>>;
  ad_times?: Resolver<Array<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType, RequireFields<DemiplaneAdAdTimesArgs, never>>;
  ad_times_aggregate?: Resolver<ResolversTypes['demiplane_ad_time_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdAdTimesAggregateArgs, never>>;
  adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventurer_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adventuring_platform?: Resolver<ResolversTypes['demiplane_adventuring_platform'], ParentType, ContextType>;
  adventuring_platform_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  attachment?: Resolver<Maybe<ResolversTypes['demiplane_attachment']>, ParentType, ContextType>;
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['time']>, ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  friday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  game_type?: Resolver<ResolversTypes['demiplane_game_type'], ParentType, ContextType>;
  game_type_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  gm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  imminent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  imminent_launch_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  invitations?: Resolver<Array<ResolversTypes['demiplane_invitation']>, ParentType, ContextType, RequireFields<DemiplaneAdInvitationsArgs, never>>;
  invitations_aggregate?: Resolver<ResolversTypes['demiplane_invitation_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdInvitationsAggregateArgs, never>>;
  invites?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  monday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  new_player?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notification_histories?: Resolver<Array<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<DemiplaneAdNotificationHistoriesArgs, never>>;
  notification_histories_aggregate?: Resolver<ResolversTypes['demiplane_notification_history_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdNotificationHistoriesAggregateArgs, never>>;
  outcome?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player_story?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  puzzles_logic?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  role_playing?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  saturday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  search_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  social_intrigue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['time']>, ParentType, ContextType>;
  strict_rules?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sunday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  thursday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tuesday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  wednesday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_aggregate'] = ResolversParentTypes['demiplane_ad_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_ad_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_aggregate_fields'] = ResolversParentTypes['demiplane_ad_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_ad_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_ad_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_ad_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_ad_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_ad_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_ad_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_avg_fields'] = ResolversParentTypes['demiplane_ad_avg_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link'] = ResolversParentTypes['demiplane_ad_link']> = {
  ad?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_aggregate'] = ResolversParentTypes['demiplane_ad_link_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_aggregate_fields'] = ResolversParentTypes['demiplane_ad_link_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdLinkAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_avg_fields'] = ResolversParentTypes['demiplane_ad_link_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_max_fields'] = ResolversParentTypes['demiplane_ad_link_max_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_min_fields'] = ResolversParentTypes['demiplane_ad_link_min_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_mutation_response'] = ResolversParentTypes['demiplane_ad_link_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_stddev_fields'] = ResolversParentTypes['demiplane_ad_link_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_stddev_pop_fields'] = ResolversParentTypes['demiplane_ad_link_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_stddev_samp_fields'] = ResolversParentTypes['demiplane_ad_link_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_sum_fields'] = ResolversParentTypes['demiplane_ad_link_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_var_pop_fields'] = ResolversParentTypes['demiplane_ad_link_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_var_samp_fields'] = ResolversParentTypes['demiplane_ad_link_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdLinkVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_link_variance_fields'] = ResolversParentTypes['demiplane_ad_link_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria'] = ResolversParentTypes['demiplane_ad_match_criteria']> = {
  ad_match_criteria_times?: Resolver<Array<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAdMatchCriteriaTimesArgs, never>>;
  ad_match_criteria_times_aggregate?: Resolver<ResolversTypes['demiplane_ad_match_criteria_time_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAdMatchCriteriaTimesAggregateArgs, never>>;
  ad_requests?: Resolver<Array<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAdRequestsArgs, never>>;
  ad_requests_aggregate?: Resolver<ResolversTypes['demiplane_ad_request_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAdRequestsAggregateArgs, never>>;
  amc_adventuring_platforms?: Resolver<Array<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAmcAdventuringPlatformsArgs, never>>;
  amc_adventuring_platforms_aggregate?: Resolver<ResolversTypes['demiplane_amc_adventuring_platforms_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAmcAdventuringPlatformsAggregateArgs, never>>;
  amc_game_types?: Resolver<Array<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAmcGameTypesArgs, never>>;
  amc_game_types_aggregate?: Resolver<ResolversTypes['demiplane_amc_game_types_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAmcGameTypesAggregateArgs, never>>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['time']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  friday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  imminent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  monday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  new_player?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  no_cost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  paid_only?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  saturday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['time']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sunday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  thursday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tuesday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  wednesday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_aggregate'] = ResolversParentTypes['demiplane_ad_match_criteria_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_aggregate_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_avg_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_avg_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_max_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_max_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_min_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_min_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_mutation_response'] = ResolversParentTypes['demiplane_ad_match_criteria_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_stddev_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_stddev_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_stddev_pop_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_stddev_pop_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_stddev_samp_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_stddev_samp_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_sum_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_sum_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time'] = ResolversParentTypes['demiplane_ad_match_criteria_time']> = {
  ad_match_criteria_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  ad_match_criterium?: Resolver<ResolversTypes['demiplane_ad_match_criteria'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  end_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  start_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_aggregate'] = ResolversParentTypes['demiplane_ad_match_criteria_time_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_aggregate_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdMatchCriteriaTimeAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_avg_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_avg_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_max_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_max_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_min_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_min_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_mutation_response'] = ResolversParentTypes['demiplane_ad_match_criteria_time_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_stddev_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_stddev_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_stddev_pop_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_stddev_pop_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_stddev_samp_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_stddev_samp_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_sum_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_sum_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_var_pop_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_var_pop_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_var_samp_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_var_samp_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaTimeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_time_variance_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_time_variance_fields']> = {
  ad_match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_var_pop_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_var_pop_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_var_samp_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_var_samp_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMatchCriteriaVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_match_criteria_variance_fields'] = ResolversParentTypes['demiplane_ad_match_criteria_variance_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_max_fields'] = ResolversParentTypes['demiplane_ad_max_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  imminent_launch_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  invites?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_min_fields'] = ResolversParentTypes['demiplane_ad_min_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  imminent_launch_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  invites?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_mutation_response'] = ResolversParentTypes['demiplane_ad_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request'] = ResolversParentTypes['demiplane_ad_request']> = {
  accepted_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ad?: Resolver<ResolversTypes['demiplane_ad'], ParentType, ContextType>;
  ad_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  ad_match_criterium?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType>;
  closed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  invited_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requested_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_aggregate'] = ResolversParentTypes['demiplane_ad_request_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_aggregate_fields'] = ResolversParentTypes['demiplane_ad_request_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdRequestAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_avg_fields'] = ResolversParentTypes['demiplane_ad_request_avg_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_max_fields'] = ResolversParentTypes['demiplane_ad_request_max_fields']> = {
  accepted_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  invited_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requested_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_min_fields'] = ResolversParentTypes['demiplane_ad_request_min_fields']> = {
  accepted_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  invited_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requested_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_mutation_response'] = ResolversParentTypes['demiplane_ad_request_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_stddev_fields'] = ResolversParentTypes['demiplane_ad_request_stddev_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_stddev_pop_fields'] = ResolversParentTypes['demiplane_ad_request_stddev_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_stddev_samp_fields'] = ResolversParentTypes['demiplane_ad_request_stddev_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_sum_fields'] = ResolversParentTypes['demiplane_ad_request_sum_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_var_pop_fields'] = ResolversParentTypes['demiplane_ad_request_var_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_var_samp_fields'] = ResolversParentTypes['demiplane_ad_request_var_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdRequestVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_request_variance_fields'] = ResolversParentTypes['demiplane_ad_request_variance_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  match_criteria_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_stddev_fields'] = ResolversParentTypes['demiplane_ad_stddev_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_stddev_pop_fields'] = ResolversParentTypes['demiplane_ad_stddev_pop_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_stddev_samp_fields'] = ResolversParentTypes['demiplane_ad_stddev_samp_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_sum_fields'] = ResolversParentTypes['demiplane_ad_sum_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time'] = ResolversParentTypes['demiplane_ad_time']> = {
  ad?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType>;
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  end_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  start_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_aggregate'] = ResolversParentTypes['demiplane_ad_time_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_aggregate_fields'] = ResolversParentTypes['demiplane_ad_time_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdTimeAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_avg_fields'] = ResolversParentTypes['demiplane_ad_time_avg_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_max_fields'] = ResolversParentTypes['demiplane_ad_time_max_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_min_fields'] = ResolversParentTypes['demiplane_ad_time_min_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_mutation_response'] = ResolversParentTypes['demiplane_ad_time_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_stddev_fields'] = ResolversParentTypes['demiplane_ad_time_stddev_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_stddev_pop_fields'] = ResolversParentTypes['demiplane_ad_time_stddev_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_stddev_samp_fields'] = ResolversParentTypes['demiplane_ad_time_stddev_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_sum_fields'] = ResolversParentTypes['demiplane_ad_time_sum_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_var_pop_fields'] = ResolversParentTypes['demiplane_ad_time_var_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_var_samp_fields'] = ResolversParentTypes['demiplane_ad_time_var_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdTimeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_time_variance_fields'] = ResolversParentTypes['demiplane_ad_time_variance_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_var_pop_fields'] = ResolversParentTypes['demiplane_ad_var_pop_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_var_samp_fields'] = ResolversParentTypes['demiplane_ad_var_samp_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_ad_variance_fields'] = ResolversParentTypes['demiplane_ad_variance_fields']> = {
  ad_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventurer_count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure'] = ResolversParentTypes['demiplane_adventure']> = {
  accepted_inc_adv_cnt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ad_times?: Resolver<Array<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType, RequireFields<DemiplaneAdventureAdTimesArgs, never>>;
  ad_times_aggregate?: Resolver<ResolversTypes['demiplane_ad_time_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureAdTimesAggregateArgs, never>>;
  ads?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<DemiplaneAdventureAdsArgs, never>>;
  ads_aggregate?: Resolver<ResolversTypes['demiplane_ad_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureAdsAggregateArgs, never>>;
  adventure_connections?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<DemiplaneAdventureAdventureConnectionsArgs, never>>;
  adventure_connections_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureAdventureConnectionsAggregateArgs, never>>;
  adventure_link?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_players?: Resolver<Array<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType, RequireFields<DemiplaneAdventureAdventurePlayersArgs, never>>;
  adventure_players_aggregate?: Resolver<ResolversTypes['demiplane_adventure_player_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureAdventurePlayersAggregateArgs, never>>;
  adventure_todos?: Resolver<Array<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType, RequireFields<DemiplaneAdventureAdventureTodosArgs, never>>;
  adventure_todos_aggregate?: Resolver<ResolversTypes['demiplane_adventure_todo_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureAdventureTodosAggregateArgs, never>>;
  adventuring_platform?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  attachment?: Resolver<Maybe<ResolversTypes['demiplane_attachment']>, ParentType, ContextType>;
  auto_join?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  default_adventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_cost?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  game_link_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_type?: Resolver<Maybe<ResolversTypes['demiplane_game_type']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  gm_recommendations?: Resolver<Array<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<DemiplaneAdventureGmRecommendationsArgs, never>>;
  gm_recommendations_aggregate?: Resolver<ResolversTypes['demiplane_gm_recommendation_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureGmRecommendationsAggregateArgs, never>>;
  has_leader_viewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  invitations?: Resolver<Array<ResolversTypes['demiplane_invitation']>, ParentType, ContextType, RequireFields<DemiplaneAdventureInvitationsArgs, never>>;
  invitations_aggregate?: Resolver<ResolversTypes['demiplane_invitation_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureInvitationsAggregateArgs, never>>;
  match_making_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match_making_status_adv_details?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match_making_status_adv_image?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match_making_status_adv_type?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match_making_status_game_avail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match_making_status_party_mgmt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match_making_status_theme_scores?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  new_player_friendly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notification_histories?: Resolver<Array<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<DemiplaneAdventureNotificationHistoriesArgs, never>>;
  notification_histories_aggregate?: Resolver<ResolversTypes['demiplane_notification_history_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureNotificationHistoriesAggregateArgs, never>>;
  open_inc_adv_cnt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  paid_flag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  player_story?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  puzzles_logic?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  role_playing?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sessions?: Resolver<Array<ResolversTypes['demiplane_session']>, ParentType, ContextType, RequireFields<DemiplaneAdventureSessionsArgs, never>>;
  sessions_aggregate?: Resolver<ResolversTypes['demiplane_session_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureSessionsAggregateArgs, never>>;
  social_intrigue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  strict_rules?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tasks?: Resolver<Array<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<DemiplaneAdventureTasksArgs, never>>;
  tasks_aggregate?: Resolver<ResolversTypes['demiplane_task_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureTasksAggregateArgs, never>>;
  total_act_adv_cnt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_adventure_rankings?: Resolver<Array<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType, RequireFields<DemiplaneAdventureUserAdventureRankingsArgs, never>>;
  user_adventure_rankings_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_ranking_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureUserAdventureRankingsAggregateArgs, never>>;
  user_adventure_roles?: Resolver<Array<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<DemiplaneAdventureUserAdventureRolesArgs, never>>;
  user_adventure_roles_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_role_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureUserAdventureRolesAggregateArgs, never>>;
  user_adventures?: Resolver<Array<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType, RequireFields<DemiplaneAdventureUserAdventuresArgs, never>>;
  user_adventures_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventureUserAdventuresAggregateArgs, never>>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_aggregate'] = ResolversParentTypes['demiplane_adventure_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_adventure_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_aggregate_fields'] = ResolversParentTypes['demiplane_adventure_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_adventure_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdventureAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_adventure_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_adventure_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_adventure_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_adventure_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_adventure_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_avg_fields'] = ResolversParentTypes['demiplane_adventure_avg_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection'] = ResolversParentTypes['demiplane_adventure_connection']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  connection_status?: Resolver<Maybe<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType>;
  connection_status_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  connection_type?: Resolver<Maybe<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType>;
  connection_type_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  contact_type?: Resolver<ResolversTypes['demiplane_contact_type'], ParentType, ContextType>;
  contact_type_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  contact_type_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  contactor?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  requestee?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  requestee_approval?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestee_email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  requestee_read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestor?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  requestor_approval?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_aggregate'] = ResolversParentTypes['demiplane_adventure_connection_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_aggregate_fields'] = ResolversParentTypes['demiplane_adventure_connection_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdventureConnectionAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_avg_fields'] = ResolversParentTypes['demiplane_adventure_connection_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_max_fields'] = ResolversParentTypes['demiplane_adventure_connection_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  connection_status_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  connection_type_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  contact_type_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  requestee_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_min_fields'] = ResolversParentTypes['demiplane_adventure_connection_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  connection_status_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  connection_type_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  contact_type_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  requestee_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_mutation_response'] = ResolversParentTypes['demiplane_adventure_connection_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_stddev_fields'] = ResolversParentTypes['demiplane_adventure_connection_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_stddev_pop_fields'] = ResolversParentTypes['demiplane_adventure_connection_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_stddev_samp_fields'] = ResolversParentTypes['demiplane_adventure_connection_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_sum_fields'] = ResolversParentTypes['demiplane_adventure_connection_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_var_pop_fields'] = ResolversParentTypes['demiplane_adventure_connection_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_var_samp_fields'] = ResolversParentTypes['demiplane_adventure_connection_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureConnectionVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_connection_variance_fields'] = ResolversParentTypes['demiplane_adventure_connection_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_status_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  connection_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contact_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestee_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool'] = ResolversParentTypes['demiplane_adventure_demiplane_tool']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  demiplane_tool?: Resolver<ResolversTypes['demiplane_demiplane_tool'], ParentType, ContextType>;
  demiplane_tool_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  sort_order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_aggregate'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_adventure_demiplane_tool']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_aggregate_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdventureDemiplaneToolAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_avg_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_max_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_min_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_mutation_response'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_adventure_demiplane_tool']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_stddev_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_stddev_pop_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_stddev_samp_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_sum_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_var_pop_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_var_samp_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureDemiplaneToolVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_demiplane_tool_variance_fields'] = ResolversParentTypes['demiplane_adventure_demiplane_tool_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demiplane_tool_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link'] = ResolversParentTypes['demiplane_adventure_link']> = {
  adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_aggregate'] = ResolversParentTypes['demiplane_adventure_link_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_aggregate_fields'] = ResolversParentTypes['demiplane_adventure_link_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdventureLinkAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_avg_fields'] = ResolversParentTypes['demiplane_adventure_link_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_max_fields'] = ResolversParentTypes['demiplane_adventure_link_max_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_min_fields'] = ResolversParentTypes['demiplane_adventure_link_min_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_mutation_response'] = ResolversParentTypes['demiplane_adventure_link_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_stddev_fields'] = ResolversParentTypes['demiplane_adventure_link_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_stddev_pop_fields'] = ResolversParentTypes['demiplane_adventure_link_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_stddev_samp_fields'] = ResolversParentTypes['demiplane_adventure_link_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_sum_fields'] = ResolversParentTypes['demiplane_adventure_link_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_var_pop_fields'] = ResolversParentTypes['demiplane_adventure_link_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_var_samp_fields'] = ResolversParentTypes['demiplane_adventure_link_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureLinkVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_link_variance_fields'] = ResolversParentTypes['demiplane_adventure_link_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_max_fields'] = ResolversParentTypes['demiplane_adventure_max_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  game_link_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_min_fields'] = ResolversParentTypes['demiplane_adventure_min_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  game_link_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_mutation_response'] = ResolversParentTypes['demiplane_adventure_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player'] = ResolversParentTypes['demiplane_adventure_player']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  episode_attendances?: Resolver<Array<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerEpisodeAttendancesArgs, never>>;
  episode_attendances_aggregate?: Resolver<ResolversTypes['demiplane_episode_attendance_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerEpisodeAttendancesAggregateArgs, never>>;
  favorite_dice_rolls?: Resolver<Array<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerFavoriteDiceRollsArgs, never>>;
  favorite_dice_rolls_aggregate?: Resolver<ResolversTypes['demiplane_favorite_dice_roll_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerFavoriteDiceRollsAggregateArgs, never>>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  in_video?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  in_voice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  in_whisper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_gm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  journal_note_shares?: Resolver<Array<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalNoteSharesArgs, never>>;
  journal_note_shares_aggregate?: Resolver<ResolversTypes['demiplane_journal_note_share_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalNoteSharesAggregateArgs, never>>;
  journal_notes?: Resolver<Array<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalNotesArgs, never>>;
  journal_notes_aggregate?: Resolver<ResolversTypes['demiplane_journal_note_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalNotesAggregateArgs, never>>;
  journal_super_event_read_statuses?: Resolver<Array<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalSuperEventReadStatusesArgs, never>>;
  journal_super_event_read_statuses_aggregate?: Resolver<ResolversTypes['demiplane_journal_super_event_read_status_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalSuperEventReadStatusesAggregateArgs, never>>;
  journal_titles?: Resolver<Array<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalTitlesArgs, never>>;
  journal_titles_aggregate?: Resolver<ResolversTypes['demiplane_journal_title_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerJournalTitlesAggregateArgs, never>>;
  player_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  portal_participant_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task_read_statuses?: Resolver<Array<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTaskReadStatusesArgs, never>>;
  task_read_statuses_aggregate?: Resolver<ResolversTypes['demiplane_task_read_status_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTaskReadStatusesAggregateArgs, never>>;
  tasks?: Resolver<Array<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTasksArgs, never>>;
  tasksByCompletedBy?: Resolver<Array<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTasksByCompletedByArgs, never>>;
  tasksByCompletedBy_aggregate?: Resolver<ResolversTypes['demiplane_task_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTasksByCompletedByAggregateArgs, never>>;
  tasksByDeclinedBy?: Resolver<Array<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTasksByDeclinedByArgs, never>>;
  tasksByDeclinedBy_aggregate?: Resolver<ResolversTypes['demiplane_task_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTasksByDeclinedByAggregateArgs, never>>;
  tasks_aggregate?: Resolver<ResolversTypes['demiplane_task_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerTasksAggregateArgs, never>>;
  triggered_disable_whisper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['demiplane_upload']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  whisper_participant_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_aggregate'] = ResolversParentTypes['demiplane_adventure_player_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_aggregate_fields'] = ResolversParentTypes['demiplane_adventure_player_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdventurePlayerAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_avg_fields'] = ResolversParentTypes['demiplane_adventure_player_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_max_fields'] = ResolversParentTypes['demiplane_adventure_player_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  portal_participant_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  whisper_participant_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_min_fields'] = ResolversParentTypes['demiplane_adventure_player_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  portal_participant_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  whisper_participant_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_mutation_response'] = ResolversParentTypes['demiplane_adventure_player_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_stddev_fields'] = ResolversParentTypes['demiplane_adventure_player_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_stddev_pop_fields'] = ResolversParentTypes['demiplane_adventure_player_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_stddev_samp_fields'] = ResolversParentTypes['demiplane_adventure_player_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_sum_fields'] = ResolversParentTypes['demiplane_adventure_player_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_var_pop_fields'] = ResolversParentTypes['demiplane_adventure_player_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_var_samp_fields'] = ResolversParentTypes['demiplane_adventure_player_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventurePlayerVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_player_variance_fields'] = ResolversParentTypes['demiplane_adventure_player_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_stddev_fields'] = ResolversParentTypes['demiplane_adventure_stddev_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_stddev_pop_fields'] = ResolversParentTypes['demiplane_adventure_stddev_pop_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_stddev_samp_fields'] = ResolversParentTypes['demiplane_adventure_stddev_samp_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_sum_fields'] = ResolversParentTypes['demiplane_adventure_sum_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo'] = ResolversParentTypes['demiplane_adventure_todo']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  task_message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  task_open?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_aggregate'] = ResolversParentTypes['demiplane_adventure_todo_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_aggregate_fields'] = ResolversParentTypes['demiplane_adventure_todo_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdventureTodoAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_avg_fields'] = ResolversParentTypes['demiplane_adventure_todo_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_max_fields'] = ResolversParentTypes['demiplane_adventure_todo_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  task_message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_min_fields'] = ResolversParentTypes['demiplane_adventure_todo_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  task_message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_mutation_response'] = ResolversParentTypes['demiplane_adventure_todo_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_stddev_fields'] = ResolversParentTypes['demiplane_adventure_todo_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_stddev_pop_fields'] = ResolversParentTypes['demiplane_adventure_todo_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_stddev_samp_fields'] = ResolversParentTypes['demiplane_adventure_todo_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_sum_fields'] = ResolversParentTypes['demiplane_adventure_todo_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_var_pop_fields'] = ResolversParentTypes['demiplane_adventure_todo_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_var_samp_fields'] = ResolversParentTypes['demiplane_adventure_todo_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureTodoVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_todo_variance_fields'] = ResolversParentTypes['demiplane_adventure_todo_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_var_pop_fields'] = ResolversParentTypes['demiplane_adventure_var_pop_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_var_samp_fields'] = ResolversParentTypes['demiplane_adventure_var_samp_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventureVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventure_variance_fields'] = ResolversParentTypes['demiplane_adventure_variance_fields']> = {
  accepted_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_link_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  auto_join_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  demicard_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  episode_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  open_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requested_inc_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_act_adv_cnt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform'] = ResolversParentTypes['demiplane_adventuring_platform']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ads?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformAdsArgs, never>>;
  ads_aggregate?: Resolver<ResolversTypes['demiplane_ad_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformAdsAggregateArgs, never>>;
  adventures?: Resolver<Array<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformAdventuresArgs, never>>;
  adventures_aggregate?: Resolver<ResolversTypes['demiplane_adventure_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformAdventuresAggregateArgs, never>>;
  amc_adventuring_platforms?: Resolver<Array<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformAmcAdventuringPlatformsArgs, never>>;
  amc_adventuring_platforms_aggregate?: Resolver<ResolversTypes['demiplane_amc_adventuring_platforms_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformAmcAdventuringPlatformsAggregateArgs, never>>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  favorite_adventuring_platforms?: Resolver<Array<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformFavoriteAdventuringPlatformsArgs, never>>;
  favorite_adventuring_platforms_aggregate?: Resolver<ResolversTypes['demiplane_favorite_adventuring_platform_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformFavoriteAdventuringPlatformsAggregateArgs, never>>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  uraps?: Resolver<Array<ResolversTypes['demiplane_urap']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformUrapsArgs, never>>;
  uraps_aggregate?: Resolver<ResolversTypes['demiplane_urap_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformUrapsAggregateArgs, never>>;
  user_recruitment_adventurer_platforms?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformUserRecruitmentAdventurerPlatformsArgs, never>>;
  user_recruitment_adventurer_platforms_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformUserRecruitmentAdventurerPlatformsAggregateArgs, never>>;
  user_recruitment_game_master_platforms?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformUserRecruitmentGameMasterPlatformsArgs, never>>;
  user_recruitment_game_master_platforms_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_platforms_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformUserRecruitmentGameMasterPlatformsAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_aggregate'] = ResolversParentTypes['demiplane_adventuring_platform_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_aggregate_fields'] = ResolversParentTypes['demiplane_adventuring_platform_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAdventuringPlatformAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_avg_fields'] = ResolversParentTypes['demiplane_adventuring_platform_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_max_fields'] = ResolversParentTypes['demiplane_adventuring_platform_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_min_fields'] = ResolversParentTypes['demiplane_adventuring_platform_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_mutation_response'] = ResolversParentTypes['demiplane_adventuring_platform_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_stddev_fields'] = ResolversParentTypes['demiplane_adventuring_platform_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_stddev_pop_fields'] = ResolversParentTypes['demiplane_adventuring_platform_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_stddev_samp_fields'] = ResolversParentTypes['demiplane_adventuring_platform_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_sum_fields'] = ResolversParentTypes['demiplane_adventuring_platform_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_var_pop_fields'] = ResolversParentTypes['demiplane_adventuring_platform_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_var_samp_fields'] = ResolversParentTypes['demiplane_adventuring_platform_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAdventuringPlatformVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_adventuring_platform_variance_fields'] = ResolversParentTypes['demiplane_adventuring_platform_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms'] = ResolversParentTypes['demiplane_amc_adventuring_platforms']> = {
  adMatchCriteriaId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  ad_match_criterium?: Resolver<ResolversTypes['demiplane_ad_match_criteria'], ParentType, ContextType>;
  adventuringPlatformId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  adventuring_platform?: Resolver<ResolversTypes['demiplane_adventuring_platform'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_aggregate'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_aggregate_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAmcAdventuringPlatformsAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_avg_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_avg_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_max_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_max_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_min_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_min_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_mutation_response'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_stddev_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_stddev_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_stddev_pop_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_stddev_pop_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_stddev_samp_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_stddev_samp_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_sum_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_sum_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_var_pop_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_var_pop_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_var_samp_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_var_samp_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcAdventuringPlatformsVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_adventuring_platforms_variance_fields'] = ResolversParentTypes['demiplane_amc_adventuring_platforms_variance_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types'] = ResolversParentTypes['demiplane_amc_game_types']> = {
  adMatchCriteriaId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  ad_match_criterium?: Resolver<ResolversTypes['demiplane_ad_match_criteria'], ParentType, ContextType>;
  gameTypeId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  game_type?: Resolver<ResolversTypes['demiplane_game_type'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_aggregate'] = ResolversParentTypes['demiplane_amc_game_types_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_aggregate_fields'] = ResolversParentTypes['demiplane_amc_game_types_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAmcGameTypesAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_avg_fields'] = ResolversParentTypes['demiplane_amc_game_types_avg_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_max_fields'] = ResolversParentTypes['demiplane_amc_game_types_max_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_min_fields'] = ResolversParentTypes['demiplane_amc_game_types_min_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_mutation_response'] = ResolversParentTypes['demiplane_amc_game_types_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_stddev_fields'] = ResolversParentTypes['demiplane_amc_game_types_stddev_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_stddev_pop_fields'] = ResolversParentTypes['demiplane_amc_game_types_stddev_pop_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_stddev_samp_fields'] = ResolversParentTypes['demiplane_amc_game_types_stddev_samp_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_sum_fields'] = ResolversParentTypes['demiplane_amc_game_types_sum_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_var_pop_fields'] = ResolversParentTypes['demiplane_amc_game_types_var_pop_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_var_samp_fields'] = ResolversParentTypes['demiplane_amc_game_types_var_samp_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAmcGameTypesVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_amc_game_types_variance_fields'] = ResolversParentTypes['demiplane_amc_game_types_variance_fields']> = {
  adMatchCriteriaId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version'] = ResolversParentTypes['demiplane_app_version']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_aggregate'] = ResolversParentTypes['demiplane_app_version_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_app_version_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_app_version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_aggregate_fields'] = ResolversParentTypes['demiplane_app_version_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_app_version_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAppVersionAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_app_version_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_app_version_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_app_version_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_app_version_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_app_version_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_app_version_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_app_version_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_app_version_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_app_version_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_avg_fields'] = ResolversParentTypes['demiplane_app_version_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_max_fields'] = ResolversParentTypes['demiplane_app_version_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_min_fields'] = ResolversParentTypes['demiplane_app_version_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_mutation_response'] = ResolversParentTypes['demiplane_app_version_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_app_version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_stddev_fields'] = ResolversParentTypes['demiplane_app_version_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_stddev_pop_fields'] = ResolversParentTypes['demiplane_app_version_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_stddev_samp_fields'] = ResolversParentTypes['demiplane_app_version_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_sum_fields'] = ResolversParentTypes['demiplane_app_version_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_var_pop_fields'] = ResolversParentTypes['demiplane_app_version_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_var_samp_fields'] = ResolversParentTypes['demiplane_app_version_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAppVersionVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_app_version_variance_fields'] = ResolversParentTypes['demiplane_app_version_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment'] = ResolversParentTypes['demiplane_attachment']> = {
  ads?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<DemiplaneAttachmentAdsArgs, never>>;
  ads_aggregate?: Resolver<ResolversTypes['demiplane_ad_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAttachmentAdsAggregateArgs, never>>;
  adventures?: Resolver<Array<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<DemiplaneAttachmentAdventuresArgs, never>>;
  adventures_aggregate?: Resolver<ResolversTypes['demiplane_adventure_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAttachmentAdventuresAggregateArgs, never>>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mime_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_aggregate'] = ResolversParentTypes['demiplane_attachment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_attachment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_attachment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_aggregate_fields'] = ResolversParentTypes['demiplane_attachment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_attachment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAttachmentAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_attachment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_attachment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_attachment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_attachment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_attachment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_attachment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_attachment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_attachment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_attachment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_avg_fields'] = ResolversParentTypes['demiplane_attachment_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_max_fields'] = ResolversParentTypes['demiplane_attachment_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mime_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_min_fields'] = ResolversParentTypes['demiplane_attachment_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mime_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_mutation_response'] = ResolversParentTypes['demiplane_attachment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_attachment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_stddev_fields'] = ResolversParentTypes['demiplane_attachment_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_stddev_pop_fields'] = ResolversParentTypes['demiplane_attachment_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_stddev_samp_fields'] = ResolversParentTypes['demiplane_attachment_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_sum_fields'] = ResolversParentTypes['demiplane_attachment_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_var_pop_fields'] = ResolversParentTypes['demiplane_attachment_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_var_samp_fields'] = ResolversParentTypes['demiplane_attachment_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAttachmentVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_attachment_variance_fields'] = ResolversParentTypes['demiplane_attachment_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type'] = ResolversParentTypes['demiplane_award_type']> = {
  award_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  player_awards?: Resolver<Array<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<DemiplaneAwardTypePlayerAwardsArgs, never>>;
  player_awards_aggregate?: Resolver<ResolversTypes['demiplane_player_award_aggregate'], ParentType, ContextType, RequireFields<DemiplaneAwardTypePlayerAwardsAggregateArgs, never>>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_aggregate'] = ResolversParentTypes['demiplane_award_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_award_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_award_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_aggregate_fields'] = ResolversParentTypes['demiplane_award_type_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_award_type_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneAwardTypeAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_award_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_award_type_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_award_type_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_award_type_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_award_type_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_award_type_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_award_type_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_award_type_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_award_type_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_avg_fields'] = ResolversParentTypes['demiplane_award_type_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_max_fields'] = ResolversParentTypes['demiplane_award_type_max_fields']> = {
  award_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_min_fields'] = ResolversParentTypes['demiplane_award_type_min_fields']> = {
  award_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_mutation_response'] = ResolversParentTypes['demiplane_award_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_award_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_stddev_fields'] = ResolversParentTypes['demiplane_award_type_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_stddev_pop_fields'] = ResolversParentTypes['demiplane_award_type_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_stddev_samp_fields'] = ResolversParentTypes['demiplane_award_type_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_sum_fields'] = ResolversParentTypes['demiplane_award_type_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_var_pop_fields'] = ResolversParentTypes['demiplane_award_type_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_var_samp_fields'] = ResolversParentTypes['demiplane_award_type_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneAwardTypeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_award_type_variance_fields'] = ResolversParentTypes['demiplane_award_type_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status'] = ResolversParentTypes['demiplane_connection_status']> = {
  adventure_connections?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<DemiplaneConnectionStatusAdventureConnectionsArgs, never>>;
  adventure_connections_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<DemiplaneConnectionStatusAdventureConnectionsAggregateArgs, never>>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_aggregate'] = ResolversParentTypes['demiplane_connection_status_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_aggregate_fields'] = ResolversParentTypes['demiplane_connection_status_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneConnectionStatusAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_avg_fields'] = ResolversParentTypes['demiplane_connection_status_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_max_fields'] = ResolversParentTypes['demiplane_connection_status_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_min_fields'] = ResolversParentTypes['demiplane_connection_status_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_mutation_response'] = ResolversParentTypes['demiplane_connection_status_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_stddev_fields'] = ResolversParentTypes['demiplane_connection_status_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_stddev_pop_fields'] = ResolversParentTypes['demiplane_connection_status_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_stddev_samp_fields'] = ResolversParentTypes['demiplane_connection_status_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_sum_fields'] = ResolversParentTypes['demiplane_connection_status_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_var_pop_fields'] = ResolversParentTypes['demiplane_connection_status_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_var_samp_fields'] = ResolversParentTypes['demiplane_connection_status_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionStatusVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_status_variance_fields'] = ResolversParentTypes['demiplane_connection_status_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type'] = ResolversParentTypes['demiplane_connection_type']> = {
  adventure_connections?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<DemiplaneConnectionTypeAdventureConnectionsArgs, never>>;
  adventure_connections_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<DemiplaneConnectionTypeAdventureConnectionsAggregateArgs, never>>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_aggregate'] = ResolversParentTypes['demiplane_connection_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_aggregate_fields'] = ResolversParentTypes['demiplane_connection_type_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneConnectionTypeAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_avg_fields'] = ResolversParentTypes['demiplane_connection_type_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_max_fields'] = ResolversParentTypes['demiplane_connection_type_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_min_fields'] = ResolversParentTypes['demiplane_connection_type_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_mutation_response'] = ResolversParentTypes['demiplane_connection_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_stddev_fields'] = ResolversParentTypes['demiplane_connection_type_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_stddev_pop_fields'] = ResolversParentTypes['demiplane_connection_type_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_stddev_samp_fields'] = ResolversParentTypes['demiplane_connection_type_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_sum_fields'] = ResolversParentTypes['demiplane_connection_type_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_var_pop_fields'] = ResolversParentTypes['demiplane_connection_type_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_var_samp_fields'] = ResolversParentTypes['demiplane_connection_type_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneConnectionTypeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_connection_type_variance_fields'] = ResolversParentTypes['demiplane_connection_type_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type'] = ResolversParentTypes['demiplane_contact_type']> = {
  adventure_connections?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<DemiplaneContactTypeAdventureConnectionsArgs, never>>;
  adventure_connections_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<DemiplaneContactTypeAdventureConnectionsAggregateArgs, never>>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_aggregate'] = ResolversParentTypes['demiplane_contact_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_contact_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_aggregate_fields'] = ResolversParentTypes['demiplane_contact_type_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneContactTypeAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_avg_fields'] = ResolversParentTypes['demiplane_contact_type_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_max_fields'] = ResolversParentTypes['demiplane_contact_type_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_min_fields'] = ResolversParentTypes['demiplane_contact_type_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_mutation_response'] = ResolversParentTypes['demiplane_contact_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_contact_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_stddev_fields'] = ResolversParentTypes['demiplane_contact_type_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_stddev_pop_fields'] = ResolversParentTypes['demiplane_contact_type_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_stddev_samp_fields'] = ResolversParentTypes['demiplane_contact_type_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_sum_fields'] = ResolversParentTypes['demiplane_contact_type_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_var_pop_fields'] = ResolversParentTypes['demiplane_contact_type_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_var_samp_fields'] = ResolversParentTypes['demiplane_contact_type_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneContactTypeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_contact_type_variance_fields'] = ResolversParentTypes['demiplane_contact_type_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country'] = ResolversParentTypes['demiplane_country']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  code2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code3?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  regions?: Resolver<Array<ResolversTypes['demiplane_region']>, ParentType, ContextType, RequireFields<DemiplaneCountryRegionsArgs, never>>;
  regions_aggregate?: Resolver<ResolversTypes['demiplane_region_aggregate'], ParentType, ContextType, RequireFields<DemiplaneCountryRegionsAggregateArgs, never>>;
  transaction_receipts?: Resolver<Array<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<DemiplaneCountryTransactionReceiptsArgs, never>>;
  transaction_receipts_aggregate?: Resolver<ResolversTypes['demiplane_transaction_receipt_aggregate'], ParentType, ContextType, RequireFields<DemiplaneCountryTransactionReceiptsAggregateArgs, never>>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<DemiplaneCountryUsersArgs, never>>;
  users_aggregate?: Resolver<ResolversTypes['demiplane_user_aggregate'], ParentType, ContextType, RequireFields<DemiplaneCountryUsersAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_aggregate'] = ResolversParentTypes['demiplane_country_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_country_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_country']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_aggregate_fields'] = ResolversParentTypes['demiplane_country_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_country_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneCountryAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_country_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_country_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_country_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_country_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_country_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_country_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_country_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_country_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_country_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_avg_fields'] = ResolversParentTypes['demiplane_country_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_max_fields'] = ResolversParentTypes['demiplane_country_max_fields']> = {
  code2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_min_fields'] = ResolversParentTypes['demiplane_country_min_fields']> = {
  code2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_mutation_response'] = ResolversParentTypes['demiplane_country_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_country']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_stddev_fields'] = ResolversParentTypes['demiplane_country_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_stddev_pop_fields'] = ResolversParentTypes['demiplane_country_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_stddev_samp_fields'] = ResolversParentTypes['demiplane_country_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountrySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_sum_fields'] = ResolversParentTypes['demiplane_country_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_var_pop_fields'] = ResolversParentTypes['demiplane_country_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_var_samp_fields'] = ResolversParentTypes['demiplane_country_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCountryVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_country_variance_fields'] = ResolversParentTypes['demiplane_country_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card'] = ResolversParentTypes['demiplane_credit_card']> = {
  card_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  card_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  exp_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  last_digits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_card_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_aggregate'] = ResolversParentTypes['demiplane_credit_card_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_aggregate_fields'] = ResolversParentTypes['demiplane_credit_card_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneCreditCardAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_avg_fields'] = ResolversParentTypes['demiplane_credit_card_avg_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_max_fields'] = ResolversParentTypes['demiplane_credit_card_max_fields']> = {
  card_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  card_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  exp_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  last_digits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_card_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_min_fields'] = ResolversParentTypes['demiplane_credit_card_min_fields']> = {
  card_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  card_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  exp_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  last_digits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_card_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_mutation_response'] = ResolversParentTypes['demiplane_credit_card_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_stddev_fields'] = ResolversParentTypes['demiplane_credit_card_stddev_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_stddev_pop_fields'] = ResolversParentTypes['demiplane_credit_card_stddev_pop_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_stddev_samp_fields'] = ResolversParentTypes['demiplane_credit_card_stddev_samp_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_sum_fields'] = ResolversParentTypes['demiplane_credit_card_sum_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_var_pop_fields'] = ResolversParentTypes['demiplane_credit_card_var_pop_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_var_samp_fields'] = ResolversParentTypes['demiplane_credit_card_var_samp_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneCreditCardVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_credit_card_variance_fields'] = ResolversParentTypes['demiplane_credit_card_variance_fields']> = {
  exp_month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  exp_year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool'] = ResolversParentTypes['demiplane_demiplane_tool']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_aggregate'] = ResolversParentTypes['demiplane_demiplane_tool_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_demiplane_tool']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_aggregate_fields'] = ResolversParentTypes['demiplane_demiplane_tool_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneDemiplaneToolAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_avg_fields'] = ResolversParentTypes['demiplane_demiplane_tool_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_max_fields'] = ResolversParentTypes['demiplane_demiplane_tool_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_min_fields'] = ResolversParentTypes['demiplane_demiplane_tool_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_mutation_response'] = ResolversParentTypes['demiplane_demiplane_tool_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_demiplane_tool']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_stddev_fields'] = ResolversParentTypes['demiplane_demiplane_tool_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_stddev_pop_fields'] = ResolversParentTypes['demiplane_demiplane_tool_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_stddev_samp_fields'] = ResolversParentTypes['demiplane_demiplane_tool_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_sum_fields'] = ResolversParentTypes['demiplane_demiplane_tool_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_var_pop_fields'] = ResolversParentTypes['demiplane_demiplane_tool_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_var_samp_fields'] = ResolversParentTypes['demiplane_demiplane_tool_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneDemiplaneToolVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_demiplane_tool_variance_fields'] = ResolversParentTypes['demiplane_demiplane_tool_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance'] = ResolversParentTypes['demiplane_episode_attendance']> = {
  adventure_player?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  joining?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  missing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['demiplane_session'], ParentType, ContextType>;
  session_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_aggregate'] = ResolversParentTypes['demiplane_episode_attendance_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_aggregate_fields'] = ResolversParentTypes['demiplane_episode_attendance_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneEpisodeAttendanceAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_avg_fields'] = ResolversParentTypes['demiplane_episode_attendance_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_max_fields'] = ResolversParentTypes['demiplane_episode_attendance_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_min_fields'] = ResolversParentTypes['demiplane_episode_attendance_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_mutation_response'] = ResolversParentTypes['demiplane_episode_attendance_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_stddev_fields'] = ResolversParentTypes['demiplane_episode_attendance_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_stddev_pop_fields'] = ResolversParentTypes['demiplane_episode_attendance_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_stddev_samp_fields'] = ResolversParentTypes['demiplane_episode_attendance_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_sum_fields'] = ResolversParentTypes['demiplane_episode_attendance_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_var_pop_fields'] = ResolversParentTypes['demiplane_episode_attendance_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_var_samp_fields'] = ResolversParentTypes['demiplane_episode_attendance_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEpisodeAttendanceVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_episode_attendance_variance_fields'] = ResolversParentTypes['demiplane_episode_attendance_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event'] = ResolversParentTypes['demiplane_event']> = {
  ack?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, RequireFields<DemiplaneEventDataArgs, never>>;
  event_type?: Resolver<ResolversTypes['demiplane_event_type'], ParentType, ContextType>;
  event_type_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_aggregate'] = ResolversParentTypes['demiplane_event_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_event_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_event']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_aggregate_fields'] = ResolversParentTypes['demiplane_event_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_event_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneEventAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_event_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_event_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_event_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_event_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_event_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_event_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_event_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_event_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_event_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_avg_fields'] = ResolversParentTypes['demiplane_event_avg_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_max_fields'] = ResolversParentTypes['demiplane_event_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  event_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_min_fields'] = ResolversParentTypes['demiplane_event_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  event_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_mutation_response'] = ResolversParentTypes['demiplane_event_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_event']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_stddev_fields'] = ResolversParentTypes['demiplane_event_stddev_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_stddev_pop_fields'] = ResolversParentTypes['demiplane_event_stddev_pop_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_stddev_samp_fields'] = ResolversParentTypes['demiplane_event_stddev_samp_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_sum_fields'] = ResolversParentTypes['demiplane_event_sum_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type'] = ResolversParentTypes['demiplane_event_type']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['demiplane_event']>, ParentType, ContextType, RequireFields<DemiplaneEventTypeEventsArgs, never>>;
  events_aggregate?: Resolver<ResolversTypes['demiplane_event_aggregate'], ParentType, ContextType, RequireFields<DemiplaneEventTypeEventsAggregateArgs, never>>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_aggregate'] = ResolversParentTypes['demiplane_event_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_event_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_event_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_aggregate_fields'] = ResolversParentTypes['demiplane_event_type_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_event_type_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneEventTypeAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_event_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_event_type_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_event_type_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_event_type_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_event_type_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_event_type_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_event_type_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_event_type_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_event_type_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_avg_fields'] = ResolversParentTypes['demiplane_event_type_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_max_fields'] = ResolversParentTypes['demiplane_event_type_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_min_fields'] = ResolversParentTypes['demiplane_event_type_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_mutation_response'] = ResolversParentTypes['demiplane_event_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_event_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_stddev_fields'] = ResolversParentTypes['demiplane_event_type_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_stddev_pop_fields'] = ResolversParentTypes['demiplane_event_type_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_stddev_samp_fields'] = ResolversParentTypes['demiplane_event_type_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_sum_fields'] = ResolversParentTypes['demiplane_event_type_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_var_pop_fields'] = ResolversParentTypes['demiplane_event_type_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_var_samp_fields'] = ResolversParentTypes['demiplane_event_type_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventTypeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_type_variance_fields'] = ResolversParentTypes['demiplane_event_type_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_var_pop_fields'] = ResolversParentTypes['demiplane_event_var_pop_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_var_samp_fields'] = ResolversParentTypes['demiplane_event_var_samp_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneEventVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_event_variance_fields'] = ResolversParentTypes['demiplane_event_variance_fields']> = {
  event_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform'] = ResolversParentTypes['demiplane_favorite_adventuring_platform']> = {
  adventuring_platform?: Resolver<ResolversTypes['demiplane_adventuring_platform'], ParentType, ContextType>;
  adventuring_platform_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  sort_order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_aggregate'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_aggregate_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneFavoriteAdventuringPlatformAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_avg_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_avg_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_max_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_max_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_min_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_min_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_mutation_response'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_stddev_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_stddev_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_stddev_pop_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_stddev_pop_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_stddev_samp_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_stddev_samp_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_sum_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_sum_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_var_pop_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_var_pop_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_var_samp_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_var_samp_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteAdventuringPlatformVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_adventuring_platform_variance_fields'] = ResolversParentTypes['demiplane_favorite_adventuring_platform_variance_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll'] = ResolversParentTypes['demiplane_favorite_dice_roll']> = {
  adventure_player?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dice_roll?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  roll_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sort_order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_aggregate'] = ResolversParentTypes['demiplane_favorite_dice_roll_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_aggregate_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneFavoriteDiceRollAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_avg_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_max_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dice_roll?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  roll_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_min_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dice_roll?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  roll_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_mutation_response'] = ResolversParentTypes['demiplane_favorite_dice_roll_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_stddev_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_stddev_pop_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_stddev_samp_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_sum_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_var_pop_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_var_samp_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteDiceRollVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_dice_roll_variance_fields'] = ResolversParentTypes['demiplane_favorite_dice_roll_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game'] = ResolversParentTypes['demiplane_favorite_game']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  game_type?: Resolver<ResolversTypes['demiplane_game_type'], ParentType, ContextType>;
  game_type_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  sort_order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_aggregate'] = ResolversParentTypes['demiplane_favorite_game_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_aggregate_fields'] = ResolversParentTypes['demiplane_favorite_game_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneFavoriteGameAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_avg_fields'] = ResolversParentTypes['demiplane_favorite_game_avg_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_max_fields'] = ResolversParentTypes['demiplane_favorite_game_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_min_fields'] = ResolversParentTypes['demiplane_favorite_game_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_mutation_response'] = ResolversParentTypes['demiplane_favorite_game_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_stddev_fields'] = ResolversParentTypes['demiplane_favorite_game_stddev_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_stddev_pop_fields'] = ResolversParentTypes['demiplane_favorite_game_stddev_pop_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_stddev_samp_fields'] = ResolversParentTypes['demiplane_favorite_game_stddev_samp_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_sum_fields'] = ResolversParentTypes['demiplane_favorite_game_sum_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_var_pop_fields'] = ResolversParentTypes['demiplane_favorite_game_var_pop_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_var_samp_fields'] = ResolversParentTypes['demiplane_favorite_game_var_samp_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneFavoriteGameVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_favorite_game_variance_fields'] = ResolversParentTypes['demiplane_favorite_game_variance_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type'] = ResolversParentTypes['demiplane_game_type']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ads?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeAdsArgs, never>>;
  ads_aggregate?: Resolver<ResolversTypes['demiplane_ad_aggregate'], ParentType, ContextType, RequireFields<DemiplaneGameTypeAdsAggregateArgs, never>>;
  adventures?: Resolver<Array<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeAdventuresArgs, never>>;
  adventures_aggregate?: Resolver<ResolversTypes['demiplane_adventure_aggregate'], ParentType, ContextType, RequireFields<DemiplaneGameTypeAdventuresAggregateArgs, never>>;
  amc_game_types?: Resolver<Array<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeAmcGameTypesArgs, never>>;
  amc_game_types_aggregate?: Resolver<ResolversTypes['demiplane_amc_game_types_aggregate'], ParentType, ContextType, RequireFields<DemiplaneGameTypeAmcGameTypesAggregateArgs, never>>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  favorite_games?: Resolver<Array<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeFavoriteGamesArgs, never>>;
  favorite_games_aggregate?: Resolver<ResolversTypes['demiplane_favorite_game_aggregate'], ParentType, ContextType, RequireFields<DemiplaneGameTypeFavoriteGamesAggregateArgs, never>>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  urgts?: Resolver<Array<ResolversTypes['demiplane_urgt']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeUrgtsArgs, never>>;
  urgts_aggregate?: Resolver<ResolversTypes['demiplane_urgt_aggregate'], ParentType, ContextType, RequireFields<DemiplaneGameTypeUrgtsAggregateArgs, never>>;
  user_recruitment_adventurer_games?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeUserRecruitmentAdventurerGamesArgs, never>>;
  user_recruitment_adventurer_games_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_games_aggregate'], ParentType, ContextType, RequireFields<DemiplaneGameTypeUserRecruitmentAdventurerGamesAggregateArgs, never>>;
  user_recruitment_game_master_games?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeUserRecruitmentGameMasterGamesArgs, never>>;
  user_recruitment_game_master_games_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_games_aggregate'], ParentType, ContextType, RequireFields<DemiplaneGameTypeUserRecruitmentGameMasterGamesAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_aggregate'] = ResolversParentTypes['demiplane_game_type_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_game_type_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_game_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_aggregate_fields'] = ResolversParentTypes['demiplane_game_type_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_game_type_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneGameTypeAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_game_type_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_game_type_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_game_type_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_game_type_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_game_type_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_game_type_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_game_type_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_game_type_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_game_type_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_avg_fields'] = ResolversParentTypes['demiplane_game_type_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_max_fields'] = ResolversParentTypes['demiplane_game_type_max_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_min_fields'] = ResolversParentTypes['demiplane_game_type_min_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_mutation_response'] = ResolversParentTypes['demiplane_game_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_game_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_stddev_fields'] = ResolversParentTypes['demiplane_game_type_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_stddev_pop_fields'] = ResolversParentTypes['demiplane_game_type_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_stddev_samp_fields'] = ResolversParentTypes['demiplane_game_type_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_sum_fields'] = ResolversParentTypes['demiplane_game_type_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_var_pop_fields'] = ResolversParentTypes['demiplane_game_type_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_var_samp_fields'] = ResolversParentTypes['demiplane_game_type_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGameTypeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_game_type_variance_fields'] = ResolversParentTypes['demiplane_game_type_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating'] = ResolversParentTypes['demiplane_gm_rating']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  rated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rated_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  rating_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['demiplane_session'], ParentType, ContextType>;
  sessionPlayerByRatedPlayerId?: Resolver<ResolversTypes['demiplane_session_player'], ParentType, ContextType>;
  session_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  session_player?: Resolver<ResolversTypes['demiplane_session_player'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_aggregate'] = ResolversParentTypes['demiplane_gm_rating_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_aggregate_fields'] = ResolversParentTypes['demiplane_gm_rating_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneGmRatingAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_avg_fields'] = ResolversParentTypes['demiplane_gm_rating_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_max_fields'] = ResolversParentTypes['demiplane_gm_rating_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_min_fields'] = ResolversParentTypes['demiplane_gm_rating_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_mutation_response'] = ResolversParentTypes['demiplane_gm_rating_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_stddev_fields'] = ResolversParentTypes['demiplane_gm_rating_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_stddev_pop_fields'] = ResolversParentTypes['demiplane_gm_rating_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_stddev_samp_fields'] = ResolversParentTypes['demiplane_gm_rating_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_sum_fields'] = ResolversParentTypes['demiplane_gm_rating_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_var_pop_fields'] = ResolversParentTypes['demiplane_gm_rating_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_var_samp_fields'] = ResolversParentTypes['demiplane_gm_rating_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRatingVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_rating_variance_fields'] = ResolversParentTypes['demiplane_gm_rating_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation'] = ResolversParentTypes['demiplane_gm_recommendation']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  displayed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gm?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  gm_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  intro?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recommendation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recommending_user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  recommending_user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  tipped?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_aggregate'] = ResolversParentTypes['demiplane_gm_recommendation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_aggregate_fields'] = ResolversParentTypes['demiplane_gm_recommendation_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneGmRecommendationAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_avg_fields'] = ResolversParentTypes['demiplane_gm_recommendation_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_max_fields'] = ResolversParentTypes['demiplane_gm_recommendation_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  intro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommendation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_min_fields'] = ResolversParentTypes['demiplane_gm_recommendation_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  intro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommendation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_mutation_response'] = ResolversParentTypes['demiplane_gm_recommendation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_stddev_fields'] = ResolversParentTypes['demiplane_gm_recommendation_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_stddev_pop_fields'] = ResolversParentTypes['demiplane_gm_recommendation_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_stddev_samp_fields'] = ResolversParentTypes['demiplane_gm_recommendation_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_sum_fields'] = ResolversParentTypes['demiplane_gm_recommendation_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_var_pop_fields'] = ResolversParentTypes['demiplane_gm_recommendation_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_var_samp_fields'] = ResolversParentTypes['demiplane_gm_recommendation_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneGmRecommendationVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_gm_recommendation_variance_fields'] = ResolversParentTypes['demiplane_gm_recommendation_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recommending_user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation'] = ResolversParentTypes['demiplane_invitation']> = {
  accepted_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  accepted_flag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ad?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType>;
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  declined_flag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  recruited_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  recruited_flag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user_invitations?: Resolver<Array<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType, RequireFields<DemiplaneInvitationUserInvitationsArgs, never>>;
  user_invitations_aggregate?: Resolver<ResolversTypes['demiplane_user_invitation_aggregate'], ParentType, ContextType, RequireFields<DemiplaneInvitationUserInvitationsAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_aggregate'] = ResolversParentTypes['demiplane_invitation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_invitation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_invitation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_aggregate_fields'] = ResolversParentTypes['demiplane_invitation_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_invitation_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneInvitationAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_invitation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_invitation_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_invitation_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_invitation_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_invitation_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_invitation_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_invitation_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_invitation_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_invitation_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_avg_fields'] = ResolversParentTypes['demiplane_invitation_avg_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_max_fields'] = ResolversParentTypes['demiplane_invitation_max_fields']> = {
  accepted_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  recruited_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_min_fields'] = ResolversParentTypes['demiplane_invitation_min_fields']> = {
  accepted_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  recruited_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_mutation_response'] = ResolversParentTypes['demiplane_invitation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_invitation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_stddev_fields'] = ResolversParentTypes['demiplane_invitation_stddev_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_stddev_pop_fields'] = ResolversParentTypes['demiplane_invitation_stddev_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_stddev_samp_fields'] = ResolversParentTypes['demiplane_invitation_stddev_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_sum_fields'] = ResolversParentTypes['demiplane_invitation_sum_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_var_pop_fields'] = ResolversParentTypes['demiplane_invitation_var_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_var_samp_fields'] = ResolversParentTypes['demiplane_invitation_var_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneInvitationVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_invitation_variance_fields'] = ResolversParentTypes['demiplane_invitation_variance_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal'] = ResolversParentTypes['demiplane_journal']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal_notes?: Resolver<Array<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType, RequireFields<DemiplaneJournalJournalNotesArgs, never>>;
  journal_notes_aggregate?: Resolver<ResolversTypes['demiplane_journal_note_aggregate'], ParentType, ContextType, RequireFields<DemiplaneJournalJournalNotesAggregateArgs, never>>;
  journal_super_events?: Resolver<Array<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType, RequireFields<DemiplaneJournalJournalSuperEventsArgs, never>>;
  journal_super_events_aggregate?: Resolver<ResolversTypes['demiplane_journal_super_event_aggregate'], ParentType, ContextType, RequireFields<DemiplaneJournalJournalSuperEventsAggregateArgs, never>>;
  journal_titles?: Resolver<Array<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType, RequireFields<DemiplaneJournalJournalTitlesArgs, never>>;
  journal_titles_aggregate?: Resolver<ResolversTypes['demiplane_journal_title_aggregate'], ParentType, ContextType, RequireFields<DemiplaneJournalJournalTitlesAggregateArgs, never>>;
  session?: Resolver<ResolversTypes['demiplane_session'], ParentType, ContextType>;
  session_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_aggregate'] = ResolversParentTypes['demiplane_journal_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_journal_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_journal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_aggregate_fields'] = ResolversParentTypes['demiplane_journal_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_journal_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneJournalAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_journal_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_journal_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_journal_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_journal_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_journal_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_avg_fields'] = ResolversParentTypes['demiplane_journal_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_max_fields'] = ResolversParentTypes['demiplane_journal_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_min_fields'] = ResolversParentTypes['demiplane_journal_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_mutation_response'] = ResolversParentTypes['demiplane_journal_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_journal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note'] = ResolversParentTypes['demiplane_journal_note']> = {
  adventure_player?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  content_delta?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, RequireFields<DemiplaneJournalNoteContentDeltaArgs, never>>;
  content_html?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content_plain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal?: Resolver<ResolversTypes['demiplane_journal'], ParentType, ContextType>;
  journal_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal_note_shares?: Resolver<Array<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType, RequireFields<DemiplaneJournalNoteJournalNoteSharesArgs, never>>;
  journal_note_shares_aggregate?: Resolver<ResolversTypes['demiplane_journal_note_share_aggregate'], ParentType, ContextType, RequireFields<DemiplaneJournalNoteJournalNoteSharesAggregateArgs, never>>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_aggregate'] = ResolversParentTypes['demiplane_journal_note_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_aggregate_fields'] = ResolversParentTypes['demiplane_journal_note_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneJournalNoteAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_avg_fields'] = ResolversParentTypes['demiplane_journal_note_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_max_fields'] = ResolversParentTypes['demiplane_journal_note_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  content_html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content_plain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_min_fields'] = ResolversParentTypes['demiplane_journal_note_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  content_html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content_plain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_mutation_response'] = ResolversParentTypes['demiplane_journal_note_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share'] = ResolversParentTypes['demiplane_journal_note_share']> = {
  adventure_player?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal_note?: Resolver<ResolversTypes['demiplane_journal_note'], ParentType, ContextType>;
  journal_note_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_aggregate'] = ResolversParentTypes['demiplane_journal_note_share_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_aggregate_fields'] = ResolversParentTypes['demiplane_journal_note_share_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneJournalNoteShareAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_avg_fields'] = ResolversParentTypes['demiplane_journal_note_share_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_max_fields'] = ResolversParentTypes['demiplane_journal_note_share_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_min_fields'] = ResolversParentTypes['demiplane_journal_note_share_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_mutation_response'] = ResolversParentTypes['demiplane_journal_note_share_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_stddev_fields'] = ResolversParentTypes['demiplane_journal_note_share_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_stddev_pop_fields'] = ResolversParentTypes['demiplane_journal_note_share_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_stddev_samp_fields'] = ResolversParentTypes['demiplane_journal_note_share_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_sum_fields'] = ResolversParentTypes['demiplane_journal_note_share_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_var_pop_fields'] = ResolversParentTypes['demiplane_journal_note_share_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_var_samp_fields'] = ResolversParentTypes['demiplane_journal_note_share_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteShareVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_share_variance_fields'] = ResolversParentTypes['demiplane_journal_note_share_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_note_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_stddev_fields'] = ResolversParentTypes['demiplane_journal_note_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_stddev_pop_fields'] = ResolversParentTypes['demiplane_journal_note_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_stddev_samp_fields'] = ResolversParentTypes['demiplane_journal_note_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_sum_fields'] = ResolversParentTypes['demiplane_journal_note_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_var_pop_fields'] = ResolversParentTypes['demiplane_journal_note_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_var_samp_fields'] = ResolversParentTypes['demiplane_journal_note_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalNoteVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_note_variance_fields'] = ResolversParentTypes['demiplane_journal_note_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_stddev_fields'] = ResolversParentTypes['demiplane_journal_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_stddev_pop_fields'] = ResolversParentTypes['demiplane_journal_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_stddev_samp_fields'] = ResolversParentTypes['demiplane_journal_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_sum_fields'] = ResolversParentTypes['demiplane_journal_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event'] = ResolversParentTypes['demiplane_journal_super_event']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal?: Resolver<ResolversTypes['demiplane_journal'], ParentType, ContextType>;
  journal_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal_super_event_read_statuses?: Resolver<Array<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType, RequireFields<DemiplaneJournalSuperEventJournalSuperEventReadStatusesArgs, never>>;
  journal_super_event_read_statuses_aggregate?: Resolver<ResolversTypes['demiplane_journal_super_event_read_status_aggregate'], ParentType, ContextType, RequireFields<DemiplaneJournalSuperEventJournalSuperEventReadStatusesAggregateArgs, never>>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_aggregate'] = ResolversParentTypes['demiplane_journal_super_event_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_aggregate_fields'] = ResolversParentTypes['demiplane_journal_super_event_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneJournalSuperEventAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_avg_fields'] = ResolversParentTypes['demiplane_journal_super_event_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_max_fields'] = ResolversParentTypes['demiplane_journal_super_event_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_min_fields'] = ResolversParentTypes['demiplane_journal_super_event_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_mutation_response'] = ResolversParentTypes['demiplane_journal_super_event_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status'] = ResolversParentTypes['demiplane_journal_super_event_read_status']> = {
  adventure_player?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal_super_event?: Resolver<ResolversTypes['demiplane_journal_super_event'], ParentType, ContextType>;
  journal_super_event_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_aggregate'] = ResolversParentTypes['demiplane_journal_super_event_read_status_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_aggregate_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneJournalSuperEventReadStatusAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_avg_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_max_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_min_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_mutation_response'] = ResolversParentTypes['demiplane_journal_super_event_read_status_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_stddev_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_stddev_pop_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_stddev_samp_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_sum_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_var_pop_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_var_samp_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventReadStatusVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_read_status_variance_fields'] = ResolversParentTypes['demiplane_journal_super_event_read_status_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_super_event_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_stddev_fields'] = ResolversParentTypes['demiplane_journal_super_event_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_stddev_pop_fields'] = ResolversParentTypes['demiplane_journal_super_event_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_stddev_samp_fields'] = ResolversParentTypes['demiplane_journal_super_event_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_sum_fields'] = ResolversParentTypes['demiplane_journal_super_event_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_var_pop_fields'] = ResolversParentTypes['demiplane_journal_super_event_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_var_samp_fields'] = ResolversParentTypes['demiplane_journal_super_event_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalSuperEventVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_super_event_variance_fields'] = ResolversParentTypes['demiplane_journal_super_event_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title'] = ResolversParentTypes['demiplane_journal_title']> = {
  adventure_player?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journal?: Resolver<ResolversTypes['demiplane_journal'], ParentType, ContextType>;
  journal_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_aggregate'] = ResolversParentTypes['demiplane_journal_title_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_aggregate_fields'] = ResolversParentTypes['demiplane_journal_title_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneJournalTitleAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_avg_fields'] = ResolversParentTypes['demiplane_journal_title_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_max_fields'] = ResolversParentTypes['demiplane_journal_title_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_min_fields'] = ResolversParentTypes['demiplane_journal_title_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_mutation_response'] = ResolversParentTypes['demiplane_journal_title_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_stddev_fields'] = ResolversParentTypes['demiplane_journal_title_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_stddev_pop_fields'] = ResolversParentTypes['demiplane_journal_title_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_stddev_samp_fields'] = ResolversParentTypes['demiplane_journal_title_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_sum_fields'] = ResolversParentTypes['demiplane_journal_title_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_var_pop_fields'] = ResolversParentTypes['demiplane_journal_title_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_var_samp_fields'] = ResolversParentTypes['demiplane_journal_title_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalTitleVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_title_variance_fields'] = ResolversParentTypes['demiplane_journal_title_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  journal_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_var_pop_fields'] = ResolversParentTypes['demiplane_journal_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_var_samp_fields'] = ResolversParentTypes['demiplane_journal_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneJournalVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_journal_variance_fields'] = ResolversParentTypes['demiplane_journal_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token'] = ResolversParentTypes['demiplane_matchmaking_token']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_aggregate'] = ResolversParentTypes['demiplane_matchmaking_token_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_matchmaking_token']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_aggregate_fields'] = ResolversParentTypes['demiplane_matchmaking_token_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneMatchmakingTokenAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_avg_fields'] = ResolversParentTypes['demiplane_matchmaking_token_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_max_fields'] = ResolversParentTypes['demiplane_matchmaking_token_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_min_fields'] = ResolversParentTypes['demiplane_matchmaking_token_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_mutation_response'] = ResolversParentTypes['demiplane_matchmaking_token_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_matchmaking_token']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_stddev_fields'] = ResolversParentTypes['demiplane_matchmaking_token_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_stddev_pop_fields'] = ResolversParentTypes['demiplane_matchmaking_token_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_stddev_samp_fields'] = ResolversParentTypes['demiplane_matchmaking_token_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_sum_fields'] = ResolversParentTypes['demiplane_matchmaking_token_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_var_pop_fields'] = ResolversParentTypes['demiplane_matchmaking_token_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_var_samp_fields'] = ResolversParentTypes['demiplane_matchmaking_token_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMatchmakingTokenVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_matchmaking_token_variance_fields'] = ResolversParentTypes['demiplane_matchmaking_token_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations'] = ResolversParentTypes['demiplane_migrations']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_aggregate'] = ResolversParentTypes['demiplane_migrations_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_migrations_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_migrations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_aggregate_fields'] = ResolversParentTypes['demiplane_migrations_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_migrations_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneMigrationsAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_migrations_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_migrations_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_migrations_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_migrations_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_migrations_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_migrations_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_migrations_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_migrations_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_migrations_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_avg_fields'] = ResolversParentTypes['demiplane_migrations_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_max_fields'] = ResolversParentTypes['demiplane_migrations_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_min_fields'] = ResolversParentTypes['demiplane_migrations_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_mutation_response'] = ResolversParentTypes['demiplane_migrations_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_migrations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_stddev_fields'] = ResolversParentTypes['demiplane_migrations_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_stddev_pop_fields'] = ResolversParentTypes['demiplane_migrations_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_stddev_samp_fields'] = ResolversParentTypes['demiplane_migrations_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_sum_fields'] = ResolversParentTypes['demiplane_migrations_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_var_pop_fields'] = ResolversParentTypes['demiplane_migrations_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_var_samp_fields'] = ResolversParentTypes['demiplane_migrations_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneMigrationsVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_migrations_variance_fields'] = ResolversParentTypes['demiplane_migrations_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history'] = ResolversParentTypes['demiplane_notification_history']> = {
  ad?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType>;
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  heading?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  os_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  userBySubmitorId?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_aggregate'] = ResolversParentTypes['demiplane_notification_history_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_aggregate_fields'] = ResolversParentTypes['demiplane_notification_history_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneNotificationHistoryAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_avg_fields'] = ResolversParentTypes['demiplane_notification_history_avg_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_max_fields'] = ResolversParentTypes['demiplane_notification_history_max_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  heading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  os_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_min_fields'] = ResolversParentTypes['demiplane_notification_history_min_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  heading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  os_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_mutation_response'] = ResolversParentTypes['demiplane_notification_history_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_stddev_fields'] = ResolversParentTypes['demiplane_notification_history_stddev_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_stddev_pop_fields'] = ResolversParentTypes['demiplane_notification_history_stddev_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_stddev_samp_fields'] = ResolversParentTypes['demiplane_notification_history_stddev_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistorySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_sum_fields'] = ResolversParentTypes['demiplane_notification_history_sum_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_var_pop_fields'] = ResolversParentTypes['demiplane_notification_history_var_pop_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_var_samp_fields'] = ResolversParentTypes['demiplane_notification_history_var_samp_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneNotificationHistoryVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_notification_history_variance_fields'] = ResolversParentTypes['demiplane_notification_history_variance_fields']> = {
  ad_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  submitor_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment'] = ResolversParentTypes['demiplane_payment']> = {
  authorized?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  captured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  charge_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  refunded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['demiplane_session'], ParentType, ContextType>;
  session_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  should_capture?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  stripe_charge_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tip_charge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  transaction_receipt?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_aggregate'] = ResolversParentTypes['demiplane_payment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_payment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_payment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_aggregate_fields'] = ResolversParentTypes['demiplane_payment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_payment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplanePaymentAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_payment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_payment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_payment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_payment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_payment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_payment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_payment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_payment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_payment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_avg_fields'] = ResolversParentTypes['demiplane_payment_avg_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_max_fields'] = ResolversParentTypes['demiplane_payment_max_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  stripe_charge_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_min_fields'] = ResolversParentTypes['demiplane_payment_min_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  stripe_charge_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_mutation_response'] = ResolversParentTypes['demiplane_payment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_payment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_stddev_fields'] = ResolversParentTypes['demiplane_payment_stddev_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_stddev_pop_fields'] = ResolversParentTypes['demiplane_payment_stddev_pop_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_stddev_samp_fields'] = ResolversParentTypes['demiplane_payment_stddev_samp_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_sum_fields'] = ResolversParentTypes['demiplane_payment_sum_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_var_pop_fields'] = ResolversParentTypes['demiplane_payment_var_pop_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_var_samp_fields'] = ResolversParentTypes['demiplane_payment_var_samp_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePaymentVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_payment_variance_fields'] = ResolversParentTypes['demiplane_payment_variance_fields']> = {
  charge_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award'] = ResolversParentTypes['demiplane_player_award']> = {
  award_type?: Resolver<ResolversTypes['demiplane_award_type'], ParentType, ContextType>;
  award_type_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['demiplane_session'], ParentType, ContextType>;
  sessionPlayerByVotedPlayerId?: Resolver<ResolversTypes['demiplane_session_player'], ParentType, ContextType>;
  session_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  session_player?: Resolver<ResolversTypes['demiplane_session_player'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  voted_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  voting_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_aggregate'] = ResolversParentTypes['demiplane_player_award_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_player_award_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_player_award']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_aggregate_fields'] = ResolversParentTypes['demiplane_player_award_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_player_award_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplanePlayerAwardAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_player_award_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_player_award_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_player_award_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_player_award_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_player_award_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_player_award_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_player_award_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_player_award_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_player_award_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_avg_fields'] = ResolversParentTypes['demiplane_player_award_avg_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_max_fields'] = ResolversParentTypes['demiplane_player_award_max_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_min_fields'] = ResolversParentTypes['demiplane_player_award_min_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_mutation_response'] = ResolversParentTypes['demiplane_player_award_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_player_award']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_stddev_fields'] = ResolversParentTypes['demiplane_player_award_stddev_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_stddev_pop_fields'] = ResolversParentTypes['demiplane_player_award_stddev_pop_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_stddev_samp_fields'] = ResolversParentTypes['demiplane_player_award_stddev_samp_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_sum_fields'] = ResolversParentTypes['demiplane_player_award_sum_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_var_pop_fields'] = ResolversParentTypes['demiplane_player_award_var_pop_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_var_samp_fields'] = ResolversParentTypes['demiplane_player_award_var_samp_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerAwardVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_award_variance_fields'] = ResolversParentTypes['demiplane_player_award_variance_fields']> = {
  award_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voted_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voting_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating'] = ResolversParentTypes['demiplane_player_rating']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  rated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rated_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  rating_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  session?: Resolver<ResolversTypes['demiplane_session'], ParentType, ContextType>;
  sessionPlayerByRatedPlayerId?: Resolver<ResolversTypes['demiplane_session_player'], ParentType, ContextType>;
  session_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  session_player?: Resolver<ResolversTypes['demiplane_session_player'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_aggregate'] = ResolversParentTypes['demiplane_player_rating_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_aggregate_fields'] = ResolversParentTypes['demiplane_player_rating_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplanePlayerRatingAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_avg_fields'] = ResolversParentTypes['demiplane_player_rating_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_max_fields'] = ResolversParentTypes['demiplane_player_rating_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_min_fields'] = ResolversParentTypes['demiplane_player_rating_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_mutation_response'] = ResolversParentTypes['demiplane_player_rating_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_stddev_fields'] = ResolversParentTypes['demiplane_player_rating_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_stddev_pop_fields'] = ResolversParentTypes['demiplane_player_rating_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_stddev_samp_fields'] = ResolversParentTypes['demiplane_player_rating_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_sum_fields'] = ResolversParentTypes['demiplane_player_rating_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_var_pop_fields'] = ResolversParentTypes['demiplane_player_rating_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_var_samp_fields'] = ResolversParentTypes['demiplane_player_rating_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePlayerRatingVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_player_rating_variance_fields'] = ResolversParentTypes['demiplane_player_rating_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rated_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rating_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun'] = ResolversParentTypes['demiplane_pronoun']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sort_order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<DemiplanePronounUsersArgs, never>>;
  users_aggregate?: Resolver<ResolversTypes['demiplane_user_aggregate'], ParentType, ContextType, RequireFields<DemiplanePronounUsersAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_aggregate'] = ResolversParentTypes['demiplane_pronoun_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_aggregate_fields'] = ResolversParentTypes['demiplane_pronoun_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplanePronounAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_avg_fields'] = ResolversParentTypes['demiplane_pronoun_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_max_fields'] = ResolversParentTypes['demiplane_pronoun_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_min_fields'] = ResolversParentTypes['demiplane_pronoun_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_mutation_response'] = ResolversParentTypes['demiplane_pronoun_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_stddev_fields'] = ResolversParentTypes['demiplane_pronoun_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_stddev_pop_fields'] = ResolversParentTypes['demiplane_pronoun_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_stddev_samp_fields'] = ResolversParentTypes['demiplane_pronoun_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_sum_fields'] = ResolversParentTypes['demiplane_pronoun_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_var_pop_fields'] = ResolversParentTypes['demiplane_pronoun_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_var_samp_fields'] = ResolversParentTypes['demiplane_pronoun_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplanePronounVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_pronoun_variance_fields'] = ResolversParentTypes['demiplane_pronoun_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region'] = ResolversParentTypes['demiplane_region']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  code2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['demiplane_country']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transaction_receipts?: Resolver<Array<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<DemiplaneRegionTransactionReceiptsArgs, never>>;
  transaction_receipts_aggregate?: Resolver<ResolversTypes['demiplane_transaction_receipt_aggregate'], ParentType, ContextType, RequireFields<DemiplaneRegionTransactionReceiptsAggregateArgs, never>>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<DemiplaneRegionUsersArgs, never>>;
  users_aggregate?: Resolver<ResolversTypes['demiplane_user_aggregate'], ParentType, ContextType, RequireFields<DemiplaneRegionUsersAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_aggregate'] = ResolversParentTypes['demiplane_region_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_region_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_region']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_aggregate_fields'] = ResolversParentTypes['demiplane_region_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_region_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneRegionAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_region_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_region_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_region_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_region_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_region_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_region_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_region_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_region_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_region_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_avg_fields'] = ResolversParentTypes['demiplane_region_avg_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_max_fields'] = ResolversParentTypes['demiplane_region_max_fields']> = {
  code2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_min_fields'] = ResolversParentTypes['demiplane_region_min_fields']> = {
  code2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_mutation_response'] = ResolversParentTypes['demiplane_region_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_region']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_stddev_fields'] = ResolversParentTypes['demiplane_region_stddev_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_stddev_pop_fields'] = ResolversParentTypes['demiplane_region_stddev_pop_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_stddev_samp_fields'] = ResolversParentTypes['demiplane_region_stddev_samp_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_sum_fields'] = ResolversParentTypes['demiplane_region_sum_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_var_pop_fields'] = ResolversParentTypes['demiplane_region_var_pop_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_var_samp_fields'] = ResolversParentTypes['demiplane_region_var_samp_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRegionVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_region_variance_fields'] = ResolversParentTypes['demiplane_region_variance_fields']> = {
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role'] = ResolversParentTypes['demiplane_role']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  role_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_adventure_roles?: Resolver<Array<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<DemiplaneRoleUserAdventureRolesArgs, never>>;
  user_adventure_roles_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_role_aggregate'], ParentType, ContextType, RequireFields<DemiplaneRoleUserAdventureRolesAggregateArgs, never>>;
  user_roles?: Resolver<Array<ResolversTypes['demiplane_user_role']>, ParentType, ContextType, RequireFields<DemiplaneRoleUserRolesArgs, never>>;
  user_roles_aggregate?: Resolver<ResolversTypes['demiplane_user_role_aggregate'], ParentType, ContextType, RequireFields<DemiplaneRoleUserRolesAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_aggregate'] = ResolversParentTypes['demiplane_role_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_role_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_aggregate_fields'] = ResolversParentTypes['demiplane_role_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_role_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneRoleAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_role_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_role_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_role_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_role_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_role_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_role_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_role_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_role_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_role_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_avg_fields'] = ResolversParentTypes['demiplane_role_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_max_fields'] = ResolversParentTypes['demiplane_role_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_min_fields'] = ResolversParentTypes['demiplane_role_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_mutation_response'] = ResolversParentTypes['demiplane_role_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_stddev_fields'] = ResolversParentTypes['demiplane_role_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_stddev_pop_fields'] = ResolversParentTypes['demiplane_role_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_stddev_samp_fields'] = ResolversParentTypes['demiplane_role_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_sum_fields'] = ResolversParentTypes['demiplane_role_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_var_pop_fields'] = ResolversParentTypes['demiplane_role_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_var_samp_fields'] = ResolversParentTypes['demiplane_role_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneRoleVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_role_variance_fields'] = ResolversParentTypes['demiplane_role_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session'] = ResolversParentTypes['demiplane_session']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  cancelled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cancelled_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  episode_attendances?: Resolver<Array<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType, RequireFields<DemiplaneSessionEpisodeAttendancesArgs, never>>;
  episode_attendances_aggregate?: Resolver<ResolversTypes['demiplane_episode_attendance_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionEpisodeAttendancesAggregateArgs, never>>;
  gm_ratings?: Resolver<Array<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<DemiplaneSessionGmRatingsArgs, never>>;
  gm_ratings_aggregate?: Resolver<ResolversTypes['demiplane_gm_rating_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionGmRatingsAggregateArgs, never>>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journals?: Resolver<Array<ResolversTypes['demiplane_journal']>, ParentType, ContextType, RequireFields<DemiplaneSessionJournalsArgs, never>>;
  journals_aggregate?: Resolver<ResolversTypes['demiplane_journal_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionJournalsAggregateArgs, never>>;
  live?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  open?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  open_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['demiplane_payment']>, ParentType, ContextType, RequireFields<DemiplaneSessionPaymentsArgs, never>>;
  payments_aggregate?: Resolver<ResolversTypes['demiplane_payment_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPaymentsAggregateArgs, never>>;
  player_awards?: Resolver<Array<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerAwardsArgs, never>>;
  player_awards_aggregate?: Resolver<ResolversTypes['demiplane_player_award_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerAwardsAggregateArgs, never>>;
  player_ratings?: Resolver<Array<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerRatingsArgs, never>>;
  player_ratings_aggregate?: Resolver<ResolversTypes['demiplane_player_rating_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerRatingsAggregateArgs, never>>;
  scheduled_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  session_players?: Resolver<Array<ResolversTypes['demiplane_session_player']>, ParentType, ContextType, RequireFields<DemiplaneSessionSessionPlayersArgs, never>>;
  session_players_aggregate?: Resolver<ResolversTypes['demiplane_session_player_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionSessionPlayersAggregateArgs, never>>;
  start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_aggregate'] = ResolversParentTypes['demiplane_session_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_session_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_session']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_aggregate_fields'] = ResolversParentTypes['demiplane_session_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_session_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneSessionAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_session_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_session_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_session_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_session_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_session_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_session_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_session_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_session_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_session_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_avg_fields'] = ResolversParentTypes['demiplane_session_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_max_fields'] = ResolversParentTypes['demiplane_session_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cancelled_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  open_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  scheduled_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_min_fields'] = ResolversParentTypes['demiplane_session_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cancelled_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  open_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  scheduled_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_mutation_response'] = ResolversParentTypes['demiplane_session_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_session']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player'] = ResolversParentTypes['demiplane_session_player']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  completed_rating_flow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  gmRatingsByRatedPlayerId?: Resolver<Array<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerGmRatingsByRatedPlayerIdArgs, never>>;
  gmRatingsByRatedPlayerId_aggregate?: Resolver<ResolversTypes['demiplane_gm_rating_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerGmRatingsByRatedPlayerIdAggregateArgs, never>>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gm_ratings?: Resolver<Array<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerGmRatingsArgs, never>>;
  gm_ratings_aggregate?: Resolver<ResolversTypes['demiplane_gm_rating_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerGmRatingsAggregateArgs, never>>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  is_gm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  join_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  joined_session?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  left_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  left_session?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['demiplane_payment']>, ParentType, ContextType>;
  playerAwardsByVotedPlayerId?: Resolver<Array<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerAwardsByVotedPlayerIdArgs, never>>;
  playerAwardsByVotedPlayerId_aggregate?: Resolver<ResolversTypes['demiplane_player_award_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerAwardsByVotedPlayerIdAggregateArgs, never>>;
  playerRatingsByRatedPlayerId?: Resolver<Array<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerRatingsByRatedPlayerIdArgs, never>>;
  playerRatingsByRatedPlayerId_aggregate?: Resolver<ResolversTypes['demiplane_player_rating_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerRatingsByRatedPlayerIdAggregateArgs, never>>;
  player_awards?: Resolver<Array<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerAwardsArgs, never>>;
  player_awards_aggregate?: Resolver<ResolversTypes['demiplane_player_award_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerAwardsAggregateArgs, never>>;
  player_ratings?: Resolver<Array<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerRatingsArgs, never>>;
  player_ratings_aggregate?: Resolver<ResolversTypes['demiplane_player_rating_aggregate'], ParentType, ContextType, RequireFields<DemiplaneSessionPlayerPlayerRatingsAggregateArgs, never>>;
  session?: Resolver<ResolversTypes['demiplane_session'], ParentType, ContextType>;
  session_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_aggregate'] = ResolversParentTypes['demiplane_session_player_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_session_player_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_session_player']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_aggregate_fields'] = ResolversParentTypes['demiplane_session_player_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_session_player_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneSessionPlayerAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_session_player_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_session_player_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_session_player_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_session_player_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_session_player_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_session_player_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_session_player_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_session_player_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_session_player_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_avg_fields'] = ResolversParentTypes['demiplane_session_player_avg_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_max_fields'] = ResolversParentTypes['demiplane_session_player_max_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  join_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  left_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_min_fields'] = ResolversParentTypes['demiplane_session_player_min_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  join_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  left_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_mutation_response'] = ResolversParentTypes['demiplane_session_player_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_session_player']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_stddev_fields'] = ResolversParentTypes['demiplane_session_player_stddev_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_stddev_pop_fields'] = ResolversParentTypes['demiplane_session_player_stddev_pop_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_stddev_samp_fields'] = ResolversParentTypes['demiplane_session_player_stddev_samp_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_sum_fields'] = ResolversParentTypes['demiplane_session_player_sum_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_var_pop_fields'] = ResolversParentTypes['demiplane_session_player_var_pop_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_var_samp_fields'] = ResolversParentTypes['demiplane_session_player_var_samp_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionPlayerVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_player_variance_fields'] = ResolversParentTypes['demiplane_session_player_variance_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_stddev_fields'] = ResolversParentTypes['demiplane_session_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_stddev_pop_fields'] = ResolversParentTypes['demiplane_session_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_stddev_samp_fields'] = ResolversParentTypes['demiplane_session_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_sum_fields'] = ResolversParentTypes['demiplane_session_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_var_pop_fields'] = ResolversParentTypes['demiplane_session_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_var_samp_fields'] = ResolversParentTypes['demiplane_session_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSessionVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_session_variance_fields'] = ResolversParentTypes['demiplane_session_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet'] = ResolversParentTypes['demiplane_snippet']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_aggregate'] = ResolversParentTypes['demiplane_snippet_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_snippet_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_snippet']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_aggregate_fields'] = ResolversParentTypes['demiplane_snippet_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_snippet_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneSnippetAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_snippet_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_snippet_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_snippet_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_snippet_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_snippet_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_snippet_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_snippet_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_snippet_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_snippet_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_avg_fields'] = ResolversParentTypes['demiplane_snippet_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_max_fields'] = ResolversParentTypes['demiplane_snippet_max_fields']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_min_fields'] = ResolversParentTypes['demiplane_snippet_min_fields']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_mutation_response'] = ResolversParentTypes['demiplane_snippet_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_snippet']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_stddev_fields'] = ResolversParentTypes['demiplane_snippet_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_stddev_pop_fields'] = ResolversParentTypes['demiplane_snippet_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_stddev_samp_fields'] = ResolversParentTypes['demiplane_snippet_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_sum_fields'] = ResolversParentTypes['demiplane_snippet_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_var_pop_fields'] = ResolversParentTypes['demiplane_snippet_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_var_samp_fields'] = ResolversParentTypes['demiplane_snippet_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneSnippetVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_snippet_variance_fields'] = ResolversParentTypes['demiplane_snippet_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task'] = ResolversParentTypes['demiplane_task']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventurePlayerByCompletedBy?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType>;
  adventurePlayerByCreatedBy?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  adventure_player?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType>;
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  completed_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  declined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  sort_order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  super?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  task_read_statuses?: Resolver<Array<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType, RequireFields<DemiplaneTaskTaskReadStatusesArgs, never>>;
  task_read_statuses_aggregate?: Resolver<ResolversTypes['demiplane_task_read_status_aggregate'], ParentType, ContextType, RequireFields<DemiplaneTaskTaskReadStatusesAggregateArgs, never>>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_aggregate'] = ResolversParentTypes['demiplane_task_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_task_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_task']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_aggregate_fields'] = ResolversParentTypes['demiplane_task_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_task_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneTaskAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_task_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_task_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_task_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_task_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_task_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_task_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_task_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_task_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_task_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_avg_fields'] = ResolversParentTypes['demiplane_task_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_max_fields'] = ResolversParentTypes['demiplane_task_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  completed_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_min_fields'] = ResolversParentTypes['demiplane_task_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  completed_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  declined_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_mutation_response'] = ResolversParentTypes['demiplane_task_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_task']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status'] = ResolversParentTypes['demiplane_task_read_status']> = {
  adventure_player?: Resolver<ResolversTypes['demiplane_adventure_player'], ParentType, ContextType>;
  adventure_player_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  task?: Resolver<ResolversTypes['demiplane_task'], ParentType, ContextType>;
  task_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_aggregate'] = ResolversParentTypes['demiplane_task_read_status_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_aggregate_fields'] = ResolversParentTypes['demiplane_task_read_status_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneTaskReadStatusAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_avg_fields'] = ResolversParentTypes['demiplane_task_read_status_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_max_fields'] = ResolversParentTypes['demiplane_task_read_status_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_min_fields'] = ResolversParentTypes['demiplane_task_read_status_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_mutation_response'] = ResolversParentTypes['demiplane_task_read_status_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_stddev_fields'] = ResolversParentTypes['demiplane_task_read_status_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_stddev_pop_fields'] = ResolversParentTypes['demiplane_task_read_status_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_stddev_samp_fields'] = ResolversParentTypes['demiplane_task_read_status_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_sum_fields'] = ResolversParentTypes['demiplane_task_read_status_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_var_pop_fields'] = ResolversParentTypes['demiplane_task_read_status_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_var_samp_fields'] = ResolversParentTypes['demiplane_task_read_status_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskReadStatusVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_read_status_variance_fields'] = ResolversParentTypes['demiplane_task_read_status_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  task_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_stddev_fields'] = ResolversParentTypes['demiplane_task_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_stddev_pop_fields'] = ResolversParentTypes['demiplane_task_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_stddev_samp_fields'] = ResolversParentTypes['demiplane_task_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_sum_fields'] = ResolversParentTypes['demiplane_task_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_var_pop_fields'] = ResolversParentTypes['demiplane_task_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_var_samp_fields'] = ResolversParentTypes['demiplane_task_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaskVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_task_variance_fields'] = ResolversParentTypes['demiplane_task_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completed_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declined_by?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region'] = ResolversParentTypes['demiplane_tax_nexus_region']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_aggregate'] = ResolversParentTypes['demiplane_tax_nexus_region_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_tax_nexus_region']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_aggregate_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneTaxNexusRegionAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_avg_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_max_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_max_fields']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_min_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_min_fields']> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_mutation_response'] = ResolversParentTypes['demiplane_tax_nexus_region_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_tax_nexus_region']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_stddev_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_stddev_pop_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_stddev_samp_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_sum_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_var_pop_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_var_samp_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTaxNexusRegionVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_tax_nexus_region_variance_fields'] = ResolversParentTypes['demiplane_tax_nexus_region_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt'] = ResolversParentTypes['demiplane_transaction_receipt']> = {
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  combined_tax_rate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['demiplane_country'], ParentType, ContextType>;
  country_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gm_fee?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  payment?: Resolver<ResolversTypes['demiplane_payment'], ParentType, ContextType>;
  payment_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['demiplane_region'], ParentType, ContextType>;
  region_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  session_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  tax_amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tip_amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total_amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transaction_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  transaction_fee?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  zipcode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipcode_plus_4_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_aggregate'] = ResolversParentTypes['demiplane_transaction_receipt_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_aggregate_fields'] = ResolversParentTypes['demiplane_transaction_receipt_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneTransactionReceiptAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_avg_fields'] = ResolversParentTypes['demiplane_transaction_receipt_avg_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_max_fields'] = ResolversParentTypes['demiplane_transaction_receipt_max_fields']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transaction_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode_plus_4_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_min_fields'] = ResolversParentTypes['demiplane_transaction_receipt_min_fields']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  session_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transaction_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode_plus_4_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_mutation_response'] = ResolversParentTypes['demiplane_transaction_receipt_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_stddev_fields'] = ResolversParentTypes['demiplane_transaction_receipt_stddev_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_stddev_pop_fields'] = ResolversParentTypes['demiplane_transaction_receipt_stddev_pop_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_stddev_samp_fields'] = ResolversParentTypes['demiplane_transaction_receipt_stddev_samp_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_sum_fields'] = ResolversParentTypes['demiplane_transaction_receipt_sum_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_var_pop_fields'] = ResolversParentTypes['demiplane_transaction_receipt_var_pop_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_var_samp_fields'] = ResolversParentTypes['demiplane_transaction_receipt_var_samp_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneTransactionReceiptVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_transaction_receipt_variance_fields'] = ResolversParentTypes['demiplane_transaction_receipt_variance_fields']> = {
  combined_tax_rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gm_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tip_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total_amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transaction_fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload'] = ResolversParentTypes['demiplane_upload']> = {
  adventure_player?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType>;
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mime_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_aggregate'] = ResolversParentTypes['demiplane_upload_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_upload_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_upload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_aggregate_fields'] = ResolversParentTypes['demiplane_upload_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_upload_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUploadAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_upload_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_upload_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_upload_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_upload_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_upload_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_upload_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_upload_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_upload_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_upload_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_avg_fields'] = ResolversParentTypes['demiplane_upload_avg_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_max_fields'] = ResolversParentTypes['demiplane_upload_max_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mime_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_min_fields'] = ResolversParentTypes['demiplane_upload_min_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mime_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_mutation_response'] = ResolversParentTypes['demiplane_upload_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_upload']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_stddev_fields'] = ResolversParentTypes['demiplane_upload_stddev_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_stddev_pop_fields'] = ResolversParentTypes['demiplane_upload_stddev_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_stddev_samp_fields'] = ResolversParentTypes['demiplane_upload_stddev_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_sum_fields'] = ResolversParentTypes['demiplane_upload_sum_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_var_pop_fields'] = ResolversParentTypes['demiplane_upload_var_pop_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_var_samp_fields'] = ResolversParentTypes['demiplane_upload_var_samp_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUploadVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_upload_variance_fields'] = ResolversParentTypes['demiplane_upload_variance_fields']> = {
  adventure_player_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap'] = ResolversParentTypes['demiplane_urap']> = {
  adventuringPlatformId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  adventuring_platform?: Resolver<ResolversTypes['demiplane_adventuring_platform'], ParentType, ContextType>;
  userRecruitmentId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user_recruitment?: Resolver<ResolversTypes['demiplane_user_recruitment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_aggregate'] = ResolversParentTypes['demiplane_urap_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_urap_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_urap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_aggregate_fields'] = ResolversParentTypes['demiplane_urap_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_urap_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUrapAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_urap_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_urap_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_urap_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_urap_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_urap_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_urap_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_urap_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_urap_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_urap_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_avg_fields'] = ResolversParentTypes['demiplane_urap_avg_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_max_fields'] = ResolversParentTypes['demiplane_urap_max_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_min_fields'] = ResolversParentTypes['demiplane_urap_min_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_mutation_response'] = ResolversParentTypes['demiplane_urap_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_urap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_stddev_fields'] = ResolversParentTypes['demiplane_urap_stddev_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_stddev_pop_fields'] = ResolversParentTypes['demiplane_urap_stddev_pop_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_stddev_samp_fields'] = ResolversParentTypes['demiplane_urap_stddev_samp_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_sum_fields'] = ResolversParentTypes['demiplane_urap_sum_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_var_pop_fields'] = ResolversParentTypes['demiplane_urap_var_pop_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_var_samp_fields'] = ResolversParentTypes['demiplane_urap_var_samp_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrapVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urap_variance_fields'] = ResolversParentTypes['demiplane_urap_variance_fields']> = {
  adventuringPlatformId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt'] = ResolversParentTypes['demiplane_urgt']> = {
  gameTypeId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  game_type?: Resolver<ResolversTypes['demiplane_game_type'], ParentType, ContextType>;
  userRecruitmentId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user_recruitment?: Resolver<ResolversTypes['demiplane_user_recruitment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_aggregate'] = ResolversParentTypes['demiplane_urgt_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_urgt_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_urgt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_aggregate_fields'] = ResolversParentTypes['demiplane_urgt_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_urgt_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUrgtAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_urgt_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_urgt_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_urgt_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_urgt_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_urgt_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_urgt_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_urgt_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_urgt_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_urgt_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_avg_fields'] = ResolversParentTypes['demiplane_urgt_avg_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_max_fields'] = ResolversParentTypes['demiplane_urgt_max_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_min_fields'] = ResolversParentTypes['demiplane_urgt_min_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_mutation_response'] = ResolversParentTypes['demiplane_urgt_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_urgt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_stddev_fields'] = ResolversParentTypes['demiplane_urgt_stddev_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_stddev_pop_fields'] = ResolversParentTypes['demiplane_urgt_stddev_pop_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_stddev_samp_fields'] = ResolversParentTypes['demiplane_urgt_stddev_samp_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_sum_fields'] = ResolversParentTypes['demiplane_urgt_sum_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_var_pop_fields'] = ResolversParentTypes['demiplane_urgt_var_pop_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_var_samp_fields'] = ResolversParentTypes['demiplane_urgt_var_samp_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUrgtVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_urgt_variance_fields'] = ResolversParentTypes['demiplane_urgt_variance_fields']> = {
  gameTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userRecruitmentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user'] = ResolversParentTypes['demiplane_user']> = {
  access_level?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accessibility_friendly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ad_requests?: Resolver<Array<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<DemiplaneUserAdRequestsArgs, never>>;
  ad_requests_aggregate?: Resolver<ResolversTypes['demiplane_ad_request_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserAdRequestsAggregateArgs, never>>;
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ads?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<DemiplaneUserAdsArgs, never>>;
  ads_aggregate?: Resolver<ResolversTypes['demiplane_ad_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserAdsAggregateArgs, never>>;
  adventureConnectionsByContactorId?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventureConnectionsByContactorIdArgs, never>>;
  adventureConnectionsByContactorId_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserAdventureConnectionsByContactorIdAggregateArgs, never>>;
  adventureConnectionsByRequestorId?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventureConnectionsByRequestorIdArgs, never>>;
  adventureConnectionsByRequestorId_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserAdventureConnectionsByRequestorIdAggregateArgs, never>>;
  adventure_connections?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventureConnectionsArgs, never>>;
  adventure_connections_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserAdventureConnectionsAggregateArgs, never>>;
  adventure_players?: Resolver<Array<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventurePlayersArgs, never>>;
  adventure_players_aggregate?: Resolver<ResolversTypes['demiplane_adventure_player_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserAdventurePlayersAggregateArgs, never>>;
  adventures?: Resolver<Array<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventuresArgs, never>>;
  adventures_aggregate?: Resolver<ResolversTypes['demiplane_adventure_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserAdventuresAggregateArgs, never>>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognito_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  combat?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['demiplane_country'], ParentType, ContextType>;
  country_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_cards?: Resolver<Array<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType, RequireFields<DemiplaneUserCreditCardsArgs, never>>;
  credit_cards_aggregate?: Resolver<ResolversTypes['demiplane_credit_card_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserCreditCardsAggregateArgs, never>>;
  dob?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['demiplane_event']>, ParentType, ContextType, RequireFields<DemiplaneUserEventsArgs, never>>;
  events_aggregate?: Resolver<ResolversTypes['demiplane_event_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserEventsAggregateArgs, never>>;
  experience?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  facebook_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favorite_adventuring_platforms?: Resolver<Array<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType, RequireFields<DemiplaneUserFavoriteAdventuringPlatformsArgs, never>>;
  favorite_adventuring_platforms_aggregate?: Resolver<ResolversTypes['demiplane_favorite_adventuring_platform_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserFavoriteAdventuringPlatformsAggregateArgs, never>>;
  favorite_games?: Resolver<Array<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType, RequireFields<DemiplaneUserFavoriteGamesArgs, never>>;
  favorite_games_aggregate?: Resolver<ResolversTypes['demiplane_favorite_game_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserFavoriteGamesAggregateArgs, never>>;
  feature_level?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  first_login?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  game_master_bio?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gmRecommendationsByGmId?: Resolver<Array<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<DemiplaneUserGmRecommendationsByGmIdArgs, never>>;
  gmRecommendationsByGmId_aggregate?: Resolver<ResolversTypes['demiplane_gm_recommendation_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserGmRecommendationsByGmIdAggregateArgs, never>>;
  gm_recommendations?: Resolver<Array<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<DemiplaneUserGmRecommendationsArgs, never>>;
  gm_recommendations_aggregate?: Resolver<ResolversTypes['demiplane_gm_recommendation_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserGmRecommendationsAggregateArgs, never>>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  inactive_month_alerted_email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inactive_two_month_alerted_email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inactive_week_alerted_email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  instagram_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_access?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lgbtqia_friendly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mind_theater_combat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  new_user_second_email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  new_user_third_email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationHistoriesBySubmitorId?: Resolver<Array<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<DemiplaneUserNotificationHistoriesBySubmitorIdArgs, never>>;
  notificationHistoriesBySubmitorId_aggregate?: Resolver<ResolversTypes['demiplane_notification_history_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserNotificationHistoriesBySubmitorIdAggregateArgs, never>>;
  notification_histories?: Resolver<Array<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<DemiplaneUserNotificationHistoriesArgs, never>>;
  notification_histories_aggregate?: Resolver<ResolversTypes['demiplane_notification_history_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserNotificationHistoriesAggregateArgs, never>>;
  payments?: Resolver<Array<ResolversTypes['demiplane_payment']>, ParentType, ContextType, RequireFields<DemiplaneUserPaymentsArgs, never>>;
  payments_aggregate?: Resolver<ResolversTypes['demiplane_payment_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserPaymentsAggregateArgs, never>>;
  player_story?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pronoun?: Resolver<Maybe<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recruitment_set_or_alerted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['demiplane_region'], ParentType, ContextType>;
  region_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  role_playing?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  session_players?: Resolver<Array<ResolversTypes['demiplane_session_player']>, ParentType, ContextType, RequireFields<DemiplaneUserSessionPlayersArgs, never>>;
  session_players_aggregate?: Resolver<ResolversTypes['demiplane_session_player_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserSessionPlayersAggregateArgs, never>>;
  social_intrigue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  strict_rules?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stripe_connect_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tactical_grid_combat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  time_zone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transaction_receipts?: Resolver<Array<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<DemiplaneUserTransactionReceiptsArgs, never>>;
  transaction_receipts_aggregate?: Resolver<ResolversTypes['demiplane_transaction_receipt_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserTransactionReceiptsAggregateArgs, never>>;
  tutorial_seen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  twitch_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  upload?: Resolver<Maybe<ResolversTypes['demiplane_upload']>, ParentType, ContextType>;
  user_activities?: Resolver<Array<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType, RequireFields<DemiplaneUserUserActivitiesArgs, never>>;
  user_activities_aggregate?: Resolver<ResolversTypes['demiplane_user_activity_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserUserActivitiesAggregateArgs, never>>;
  user_adventure_rankings?: Resolver<Array<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType, RequireFields<DemiplaneUserUserAdventureRankingsArgs, never>>;
  user_adventure_rankings_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_ranking_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserUserAdventureRankingsAggregateArgs, never>>;
  user_adventure_roles?: Resolver<Array<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<DemiplaneUserUserAdventureRolesArgs, never>>;
  user_adventure_roles_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_role_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserUserAdventureRolesAggregateArgs, never>>;
  user_adventures?: Resolver<Array<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType, RequireFields<DemiplaneUserUserAdventuresArgs, never>>;
  user_adventures_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserUserAdventuresAggregateArgs, never>>;
  user_email_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType>;
  user_invitations?: Resolver<Array<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType, RequireFields<DemiplaneUserUserInvitationsArgs, never>>;
  user_invitations_aggregate?: Resolver<ResolversTypes['demiplane_user_invitation_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserUserInvitationsAggregateArgs, never>>;
  user_metadatum?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType>;
  user_push_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType>;
  user_recruitment?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType>;
  user_recruitment_adventurer?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType>;
  user_recruitment_game_master?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType>;
  user_roles?: Resolver<Array<ResolversTypes['demiplane_user_role']>, ParentType, ContextType, RequireFields<DemiplaneUserUserRolesArgs, never>>;
  user_roles_aggregate?: Resolver<ResolversTypes['demiplane_user_role_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserUserRolesAggregateArgs, never>>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uses_video_webcam?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uses_voice_mic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode_plus_4_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity'] = ResolversParentTypes['demiplane_user_activity']> = {
  access_date?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  ip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_agent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_aggregate'] = ResolversParentTypes['demiplane_user_activity_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_aggregate_fields'] = ResolversParentTypes['demiplane_user_activity_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserActivityAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_avg_fields'] = ResolversParentTypes['demiplane_user_activity_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_max_fields'] = ResolversParentTypes['demiplane_user_activity_max_fields']> = {
  access_date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_agent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_min_fields'] = ResolversParentTypes['demiplane_user_activity_min_fields']> = {
  access_date?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_agent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_mutation_response'] = ResolversParentTypes['demiplane_user_activity_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_stddev_fields'] = ResolversParentTypes['demiplane_user_activity_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_activity_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_activity_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivitySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_sum_fields'] = ResolversParentTypes['demiplane_user_activity_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_var_pop_fields'] = ResolversParentTypes['demiplane_user_activity_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_var_samp_fields'] = ResolversParentTypes['demiplane_user_activity_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserActivityVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_activity_variance_fields'] = ResolversParentTypes['demiplane_user_activity_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure'] = ResolversParentTypes['demiplane_user_adventure']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_aggregate'] = ResolversParentTypes['demiplane_user_adventure_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_aggregate_fields'] = ResolversParentTypes['demiplane_user_adventure_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventureAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_avg_fields'] = ResolversParentTypes['demiplane_user_adventure_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_max_fields'] = ResolversParentTypes['demiplane_user_adventure_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_min_fields'] = ResolversParentTypes['demiplane_user_adventure_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_mutation_response'] = ResolversParentTypes['demiplane_user_adventure_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking'] = ResolversParentTypes['demiplane_user_adventure_ranking']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  featured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  sort_order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_aggregate'] = ResolversParentTypes['demiplane_user_adventure_ranking_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_aggregate_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventureRankingAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_avg_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_max_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_min_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_mutation_response'] = ResolversParentTypes['demiplane_user_adventure_ranking_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_stddev_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_sum_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_var_pop_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_var_samp_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRankingVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_ranking_variance_fields'] = ResolversParentTypes['demiplane_user_adventure_ranking_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort_order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role'] = ResolversParentTypes['demiplane_user_adventure_role']> = {
  adventure?: Resolver<ResolversTypes['demiplane_adventure'], ParentType, ContextType>;
  adventure_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['demiplane_role'], ParentType, ContextType>;
  role_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_aggregate'] = ResolversParentTypes['demiplane_user_adventure_role_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_aggregate_fields'] = ResolversParentTypes['demiplane_user_adventure_role_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserAdventureRoleAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_avg_fields'] = ResolversParentTypes['demiplane_user_adventure_role_avg_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_max_fields'] = ResolversParentTypes['demiplane_user_adventure_role_max_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_min_fields'] = ResolversParentTypes['demiplane_user_adventure_role_min_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_mutation_response'] = ResolversParentTypes['demiplane_user_adventure_role_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_stddev_fields'] = ResolversParentTypes['demiplane_user_adventure_role_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_adventure_role_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_adventure_role_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_sum_fields'] = ResolversParentTypes['demiplane_user_adventure_role_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_var_pop_fields'] = ResolversParentTypes['demiplane_user_adventure_role_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_var_samp_fields'] = ResolversParentTypes['demiplane_user_adventure_role_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureRoleVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_role_variance_fields'] = ResolversParentTypes['demiplane_user_adventure_role_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_stddev_fields'] = ResolversParentTypes['demiplane_user_adventure_stddev_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_adventure_stddev_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_adventure_stddev_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_sum_fields'] = ResolversParentTypes['demiplane_user_adventure_sum_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_var_pop_fields'] = ResolversParentTypes['demiplane_user_adventure_var_pop_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_var_samp_fields'] = ResolversParentTypes['demiplane_user_adventure_var_samp_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAdventureVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_adventure_variance_fields'] = ResolversParentTypes['demiplane_user_adventure_variance_fields']> = {
  adventure_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_aggregate'] = ResolversParentTypes['demiplane_user_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_aggregate_fields'] = ResolversParentTypes['demiplane_user_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_avg_fields'] = ResolversParentTypes['demiplane_user_avg_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref'] = ResolversParentTypes['demiplane_user_email_notification_pref']> = {
  adventure_rallying?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventure_rescheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventure_scheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventurer_leaves_adventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ap_launches_adventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_first_quest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  invited_to_quest_or_party?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  post_adventure_reminder?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quest_ap_creator_launches_adventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quest_launches_adventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  request_to_join_accept_decline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  request_to_join_received?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  unread_notifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  weekly_journal_alert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  weekly_task_alert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_aggregate'] = ResolversParentTypes['demiplane_user_email_notification_pref_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_aggregate_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserEmailNotificationPrefAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_avg_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_max_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_min_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_mutation_response'] = ResolversParentTypes['demiplane_user_email_notification_pref_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_stddev_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_sum_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_var_pop_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_var_samp_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserEmailNotificationPrefVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_email_notification_pref_variance_fields'] = ResolversParentTypes['demiplane_user_email_notification_pref_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation'] = ResolversParentTypes['demiplane_user_invitation']> = {
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  invitation?: Resolver<ResolversTypes['demiplane_invitation'], ParentType, ContextType>;
  invitation_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_aggregate'] = ResolversParentTypes['demiplane_user_invitation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_aggregate_fields'] = ResolversParentTypes['demiplane_user_invitation_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserInvitationAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_avg_fields'] = ResolversParentTypes['demiplane_user_invitation_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_max_fields'] = ResolversParentTypes['demiplane_user_invitation_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_min_fields'] = ResolversParentTypes['demiplane_user_invitation_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_mutation_response'] = ResolversParentTypes['demiplane_user_invitation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_stddev_fields'] = ResolversParentTypes['demiplane_user_invitation_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_invitation_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_invitation_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_sum_fields'] = ResolversParentTypes['demiplane_user_invitation_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_var_pop_fields'] = ResolversParentTypes['demiplane_user_invitation_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_var_samp_fields'] = ResolversParentTypes['demiplane_user_invitation_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserInvitationVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_invitation_variance_fields'] = ResolversParentTypes['demiplane_user_invitation_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invitation_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_max_fields'] = ResolversParentTypes['demiplane_user_max_fields']> = {
  access_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognito_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  facebook_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feature_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_master_bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  instagram_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_access?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stripe_connect_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time_zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitch_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode_plus_4_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata'] = ResolversParentTypes['demiplane_user_metadata']> = {
  accepted_terms?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  utm_campaign?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utm_medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utm_source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  viewed_active_gm_message?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewed_active_player_message?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewed_add_to_home_screen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewed_matched_gm_message?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewed_matched_player_message?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_aggregate'] = ResolversParentTypes['demiplane_user_metadata_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_aggregate_fields'] = ResolversParentTypes['demiplane_user_metadata_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserMetadataAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_avg_fields'] = ResolversParentTypes['demiplane_user_metadata_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_max_fields'] = ResolversParentTypes['demiplane_user_metadata_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  utm_campaign?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utm_medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utm_source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_min_fields'] = ResolversParentTypes['demiplane_user_metadata_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  utm_campaign?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utm_medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utm_source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_mutation_response'] = ResolversParentTypes['demiplane_user_metadata_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_stddev_fields'] = ResolversParentTypes['demiplane_user_metadata_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_metadata_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_metadata_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_sum_fields'] = ResolversParentTypes['demiplane_user_metadata_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_var_pop_fields'] = ResolversParentTypes['demiplane_user_metadata_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_var_samp_fields'] = ResolversParentTypes['demiplane_user_metadata_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMetadataVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_metadata_variance_fields'] = ResolversParentTypes['demiplane_user_metadata_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_min_fields'] = ResolversParentTypes['demiplane_user_min_fields']> = {
  access_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognito_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  facebook_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feature_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  game_master_bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  instagram_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_access?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stripe_connect_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripe_customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time_zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitch_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter_profile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode_plus_4_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_mutation_response'] = ResolversParentTypes['demiplane_user_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref'] = ResolversParentTypes['demiplane_user_push_notification_pref']> = {
  adventure_is_rallying?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventure_reminders?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventurer_removed_from_adventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adventurer_requests_to_join?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  gm_accepts_adventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gm_denies_adventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gm_recruits_adventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gm_requests_to_host?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  invited_to_adventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invited_to_adventuring_party?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  invited_to_quest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  new_chat_alert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  new_journal_alert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  party_leader_accepts_gm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  party_leader_denies_gm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_connected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_joins_portal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_aggregate'] = ResolversParentTypes['demiplane_user_push_notification_pref_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_aggregate_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserPushNotificationPrefAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_avg_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_max_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_min_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_mutation_response'] = ResolversParentTypes['demiplane_user_push_notification_pref_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_stddev_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_sum_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_var_pop_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_var_samp_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserPushNotificationPrefVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_push_notification_pref_variance_fields'] = ResolversParentTypes['demiplane_user_push_notification_pref_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment'] = ResolversParentTypes['demiplane_user_recruitment']> = {
  adventurer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  game_master?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  max_match_score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  min_match_score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  no_cost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  uraps?: Resolver<Array<ResolversTypes['demiplane_urap']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentUrapsArgs, never>>;
  uraps_aggregate?: Resolver<ResolversTypes['demiplane_urap_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentUrapsAggregateArgs, never>>;
  urgts?: Resolver<Array<ResolversTypes['demiplane_urgt']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentUrgtsArgs, never>>;
  urgts_aggregate?: Resolver<ResolversTypes['demiplane_urgt_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentUrgtsAggregateArgs, never>>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_availabilities?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentUserRecruitmentAvailabilitiesArgs, never>>;
  user_recruitment_availabilities_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_availability_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentUserRecruitmentAvailabilitiesAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer'] = ResolversParentTypes['demiplane_user_recruitment_adventurer']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  no_cost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user_recruitment_adventurer_availabilities?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerAvailabilitiesArgs, never>>;
  user_recruitment_adventurer_availabilities_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_availability_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerAvailabilitiesAggregateArgs, never>>;
  user_recruitment_adventurer_games?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerGamesArgs, never>>;
  user_recruitment_adventurer_games_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_games_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerGamesAggregateArgs, never>>;
  user_recruitment_adventurer_platforms?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerPlatformsArgs, never>>;
  user_recruitment_adventurer_platforms_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerUserRecruitmentAdventurerPlatformsAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  end_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  start_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_adventurer?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer'], ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_avg_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_stddev_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_stddev_pop_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_stddev_samp_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilitySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_sum_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_var_pop_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_var_samp_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvailabilityVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_availability_variance_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games']> = {
  game_type?: Resolver<ResolversTypes['demiplane_game_type'], ParentType, ContextType>;
  game_type_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user_recruitment_adventurer?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer'], ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerGamesAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_avg_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_max_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_min_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_stddev_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_stddev_pop_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_stddev_samp_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_sum_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_var_pop_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_var_samp_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerGamesVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_games_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_games_variance_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_max_fields']> = {
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_min_fields']> = {
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms']> = {
  adventuring_platform?: Resolver<ResolversTypes['demiplane_adventuring_platform'], ParentType, ContextType>;
  adventuring_platform_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user_recruitment_adventurer?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer'], ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAdventurerPlatformsAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_avg_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_max_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_min_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_stddev_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_stddev_pop_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_stddev_samp_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_sum_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_var_pop_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_var_samp_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerPlatformsVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_platforms_variance_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_adventurer_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAdventurerVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_adventurer_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_adventurer_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability'] = ResolversParentTypes['demiplane_user_recruitment_availability']> = {
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  end_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  start_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user_recruitment?: Resolver<ResolversTypes['demiplane_user_recruitment'], ParentType, ContextType>;
  user_recruitment_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_availability_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentAvailabilityAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_avg_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_availability_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_stddev_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_stddev_pop_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_stddev_samp_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilitySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_sum_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_var_pop_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_var_samp_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvailabilityVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_availability_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_availability_variance_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master'] = ResolversParentTypes['demiplane_user_recruitment_game_master']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paid_only?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  public_profile?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_availabilities?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterAvailabilitiesArgs, never>>;
  user_recruitment_game_master_availabilities_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_availability_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterAvailabilitiesAggregateArgs, never>>;
  user_recruitment_game_master_games?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterGamesArgs, never>>;
  user_recruitment_game_master_games_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_games_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterGamesAggregateArgs, never>>;
  user_recruitment_game_master_platforms?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterPlatformsArgs, never>>;
  user_recruitment_game_master_platforms_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_platforms_aggregate'], ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterUserRecruitmentGameMasterPlatformsAggregateArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_game_master_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  end_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  start_time?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_game_master?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master'], ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_avg_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_stddev_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_stddev_pop_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_stddev_samp_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilitySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_sum_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_var_pop_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_var_samp_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvailabilityVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_availability_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_availability_variance_fields']> = {
  dow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games']> = {
  game_type?: Resolver<ResolversTypes['demiplane_game_type'], ParentType, ContextType>;
  game_type_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user_recruitment_game_master?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master'], ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterGamesAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_avg_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_max_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_min_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_stddev_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_stddev_pop_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_stddev_samp_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_sum_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_var_pop_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_var_samp_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterGamesVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_games_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_games_variance_fields']> = {
  game_type_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_max_fields']> = {
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_min_fields']> = {
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_game_master_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms']> = {
  adventuring_platform?: Resolver<ResolversTypes['demiplane_adventuring_platform'], ParentType, ContextType>;
  adventuring_platform_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user_recruitment_game_master?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master'], ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_aggregate'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_aggregate_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRecruitmentGameMasterPlatformsAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_avg_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_avg_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_max_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_min_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_stddev_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_stddev_pop_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_stddev_samp_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_sum_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_var_pop_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_var_samp_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterPlatformsVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_platforms_variance_fields']> = {
  adventuring_platform_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_recruitment_game_master_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentGameMasterVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_game_master_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_game_master_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score_threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_max_fields'] = ResolversParentTypes['demiplane_user_recruitment_max_fields']> = {
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_min_fields'] = ResolversParentTypes['demiplane_user_recruitment_min_fields']> = {
  availability_start_of_week?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_mutation_response'] = ResolversParentTypes['demiplane_user_recruitment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_stddev_fields'] = ResolversParentTypes['demiplane_user_recruitment_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_sum_fields'] = ResolversParentTypes['demiplane_user_recruitment_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_var_pop_fields'] = ResolversParentTypes['demiplane_user_recruitment_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_var_samp_fields'] = ResolversParentTypes['demiplane_user_recruitment_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRecruitmentVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_recruitment_variance_fields'] = ResolversParentTypes['demiplane_user_recruitment_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  max_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min_match_score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role'] = ResolversParentTypes['demiplane_user_role']> = {
  role?: Resolver<ResolversTypes['demiplane_role'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_aggregate'] = ResolversParentTypes['demiplane_user_role_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_role_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_aggregate_fields'] = ResolversParentTypes['demiplane_user_role_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_role_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserRoleAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_role_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_role_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_role_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_role_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_role_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_role_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_role_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_role_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_role_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_avg_fields'] = ResolversParentTypes['demiplane_user_role_avg_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_max_fields'] = ResolversParentTypes['demiplane_user_role_max_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_min_fields'] = ResolversParentTypes['demiplane_user_role_min_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_mutation_response'] = ResolversParentTypes['demiplane_user_role_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_stddev_fields'] = ResolversParentTypes['demiplane_user_role_stddev_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_role_stddev_pop_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_role_stddev_samp_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_sum_fields'] = ResolversParentTypes['demiplane_user_role_sum_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_var_pop_fields'] = ResolversParentTypes['demiplane_user_role_var_pop_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_var_samp_fields'] = ResolversParentTypes['demiplane_user_role_var_samp_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserRoleVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_role_variance_fields'] = ResolversParentTypes['demiplane_user_role_variance_fields']> = {
  roleId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_stddev_fields'] = ResolversParentTypes['demiplane_user_stddev_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_stddev_pop_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_stddev_samp_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_sum_fields'] = ResolversParentTypes['demiplane_user_sum_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_var_pop_fields'] = ResolversParentTypes['demiplane_user_var_pop_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_var_samp_fields'] = ResolversParentTypes['demiplane_user_var_samp_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_variance_fields'] = ResolversParentTypes['demiplane_user_variance_fields']> = {
  combat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  player_story?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pronoun_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  puzzles_logic?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  role_playing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  social_intrigue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  strict_rules?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility'] = ResolversParentTypes['demiplane_user_visibility']> = {
  adventure_ratings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  facebook_profile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  how_i_play?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  instagram_profile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  my_favorite_games?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  my_favorite_platforms?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  my_pronouns?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  my_recommendations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  my_treasure_trove?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  twitch_profile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  twitter_profile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['demiplane_user'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_aggregate'] = ResolversParentTypes['demiplane_user_visibility_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['demiplane_user_visibility']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_aggregate_fields'] = ResolversParentTypes['demiplane_user_visibility_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<DemiplaneUserVisibilityAggregateFieldsCountArgs, never>>;
  max?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_avg_fields'] = ResolversParentTypes['demiplane_user_visibility_avg_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_max_fields'] = ResolversParentTypes['demiplane_user_visibility_max_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_min_fields'] = ResolversParentTypes['demiplane_user_visibility_min_fields']> = {
  created?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_mutation_response'] = ResolversParentTypes['demiplane_user_visibility_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['demiplane_user_visibility']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_stddev_fields'] = ResolversParentTypes['demiplane_user_visibility_stddev_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_stddev_pop_fields'] = ResolversParentTypes['demiplane_user_visibility_stddev_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_stddev_samp_fields'] = ResolversParentTypes['demiplane_user_visibility_stddev_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilitySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_sum_fields'] = ResolversParentTypes['demiplane_user_visibility_sum_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_var_pop_fields'] = ResolversParentTypes['demiplane_user_visibility_var_pop_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_var_samp_fields'] = ResolversParentTypes['demiplane_user_visibility_var_samp_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DemiplaneUserVisibilityVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['demiplane_user_visibility_variance_fields'] = ResolversParentTypes['demiplane_user_visibility_variance_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  user_id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['json'], any> {
  name: 'json';
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type MutationRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']> = {
  acceptInvite?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootAcceptInviteArgs, 'connectionId'>>;
  acceptRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootAcceptRecruitArgs, 'connectionId'>>;
  acceptRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootAcceptRequestArgs, 'connectionId'>>;
  addAdventureSlim?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootAddAdventureSlimArgs, 'adventureCost' | 'adventureName' | 'gameTypeCode' | 'invites' | 'isPaid'>>;
  addCreditCard?: Resolver<ResolversTypes['CreditCardType'], ParentType, ContextType, RequireFields<MutationRootAddCreditCardArgs, 'creditCard'>>;
  addFavoriteGame?: Resolver<Maybe<ResolversTypes['AddFavoriteGameOutput']>, ParentType, ContextType, RequireFields<MutationRootAddFavoriteGameArgs, 'gameTypeId' | 'userId'>>;
  addFavoritePlatform?: Resolver<Maybe<ResolversTypes['AddFavoritePlatformOutput']>, ParentType, ContextType, RequireFields<MutationRootAddFavoritePlatformArgs, 'adventuringPlatformId' | 'userId'>>;
  addJournalSuperEvent?: Resolver<ResolversTypes['JournalSuperEventType'], ParentType, ContextType, RequireFields<MutationRootAddJournalSuperEventArgs, 'journalSuperEvent'>>;
  addNewPortalV2?: Resolver<Maybe<ResolversTypes['AddNewPortalV2Output']>, ParentType, ContextType, RequireFields<MutationRootAddNewPortalV2Args, 'gameTypeCode' | 'invites' | 'isPaid' | 'portalCost' | 'portalName'>>;
  addUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationRootAddUserArgs, 'user'>>;
  attendEpisode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootAttendEpisodeArgs, 'sessionId'>>;
  authMM?: Resolver<Maybe<ResolversTypes['authMMOutput']>, ParentType, ContextType, RequireFields<MutationRootAuthMmArgs, 'token'>>;
  calculateFeesWithTip?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType, RequireFields<MutationRootCalculateFeesWithTipArgs, 'tip'>>;
  cancelPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootCancelPaymentArgs, 'sessionId'>>;
  cancelSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootCancelSessionArgs, 'session'>>;
  cantMakeEpisode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootCantMakeEpisodeArgs, 'sessionId'>>;
  capturePayment?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType, RequireFields<MutationRootCapturePaymentArgs, 'sessionId'>>;
  captureTipPayment?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType, RequireFields<MutationRootCaptureTipPaymentArgs, 'tip'>>;
  completeRatingFlow?: Resolver<ResolversTypes['SessionPlayerType'], ParentType, ContextType, RequireFields<MutationRootCompleteRatingFlowArgs, 'sessionId'>>;
  completeTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationRootCompleteTaskArgs, 'id'>>;
  contactGameMaster?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootContactGameMasterArgs, 'adventureId'>>;
  createFavoriteDiceRoll?: Resolver<ResolversTypes['FavoriteDiceRollType'], ParentType, ContextType, RequireFields<MutationRootCreateFavoriteDiceRollArgs, 'adventureId' | 'diceRoll' | 'rollName'>>;
  createTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationRootCreateTaskArgs, 'task'>>;
  createUpload?: Resolver<ResolversTypes['UploadType'], ParentType, ContextType, RequireFields<MutationRootCreateUploadArgs, 'file' | 'kind' | 'mimeType'>>;
  declineInvite?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootDeclineInviteArgs, 'connectionId'>>;
  declineRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootDeclineRecruitArgs, 'connectionId'>>;
  declineRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootDeclineRequestArgs, 'connectionId'>>;
  declineTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationRootDeclineTaskArgs, 'id'>>;
  deleteAdventure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDeleteAdventureArgs, 'adventureId'>>;
  deleteFavoriteDiceRoll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDeleteFavoriteDiceRollArgs, 'adventureId' | 'favoriteDiceRollId'>>;
  deleteJournalSuperEvent?: Resolver<ResolversTypes['JournalSuperEventDeleteType'], ParentType, ContextType, RequireFields<MutationRootDeleteJournalSuperEventArgs, 'journalSuperEvent'>>;
  deleteUpload?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDeleteUploadArgs, never>>;
  delete_demiplane_ad?: Resolver<Maybe<ResolversTypes['demiplane_ad_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdArgs, 'where'>>;
  delete_demiplane_ad_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdByPkArgs, 'id'>>;
  delete_demiplane_ad_link?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdLinkArgs, 'where'>>;
  delete_demiplane_ad_link_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdLinkByPkArgs, 'id'>>;
  delete_demiplane_ad_match_criteria?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdMatchCriteriaArgs, 'where'>>;
  delete_demiplane_ad_match_criteria_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdMatchCriteriaByPkArgs, 'id'>>;
  delete_demiplane_ad_match_criteria_time?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdMatchCriteriaTimeArgs, 'where'>>;
  delete_demiplane_ad_match_criteria_time_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdMatchCriteriaTimeByPkArgs, 'id'>>;
  delete_demiplane_ad_request?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdRequestArgs, 'where'>>;
  delete_demiplane_ad_request_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdRequestByPkArgs, 'id'>>;
  delete_demiplane_ad_time?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdTimeArgs, 'where'>>;
  delete_demiplane_ad_time_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdTimeByPkArgs, 'id'>>;
  delete_demiplane_adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureArgs, 'where'>>;
  delete_demiplane_adventure_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureByPkArgs, 'id'>>;
  delete_demiplane_adventure_connection?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureConnectionArgs, 'where'>>;
  delete_demiplane_adventure_connection_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureConnectionByPkArgs, 'id'>>;
  delete_demiplane_adventure_demiplane_tool?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureDemiplaneToolArgs, 'where'>>;
  delete_demiplane_adventure_demiplane_tool_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureDemiplaneToolByPkArgs, 'id'>>;
  delete_demiplane_adventure_link?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureLinkArgs, 'where'>>;
  delete_demiplane_adventure_link_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureLinkByPkArgs, 'id'>>;
  delete_demiplane_adventure_player?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventurePlayerArgs, 'where'>>;
  delete_demiplane_adventure_player_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventurePlayerByPkArgs, 'id'>>;
  delete_demiplane_adventure_todo?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureTodoArgs, 'where'>>;
  delete_demiplane_adventure_todo_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventureTodoByPkArgs, 'id'>>;
  delete_demiplane_adventuring_platform?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventuringPlatformArgs, 'where'>>;
  delete_demiplane_adventuring_platform_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAdventuringPlatformByPkArgs, 'id'>>;
  delete_demiplane_amc_adventuring_platforms?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAmcAdventuringPlatformsArgs, 'where'>>;
  delete_demiplane_amc_adventuring_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAmcAdventuringPlatformsByPkArgs, 'adMatchCriteriaId' | 'adventuringPlatformId'>>;
  delete_demiplane_amc_game_types?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAmcGameTypesArgs, 'where'>>;
  delete_demiplane_amc_game_types_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAmcGameTypesByPkArgs, 'adMatchCriteriaId' | 'gameTypeId'>>;
  delete_demiplane_app_version?: Resolver<Maybe<ResolversTypes['demiplane_app_version_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAppVersionArgs, 'where'>>;
  delete_demiplane_app_version_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_app_version']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAppVersionByPkArgs, 'id'>>;
  delete_demiplane_attachment?: Resolver<Maybe<ResolversTypes['demiplane_attachment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAttachmentArgs, 'where'>>;
  delete_demiplane_attachment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_attachment']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAttachmentByPkArgs, 'id'>>;
  delete_demiplane_award_type?: Resolver<Maybe<ResolversTypes['demiplane_award_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAwardTypeArgs, 'where'>>;
  delete_demiplane_award_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_award_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneAwardTypeByPkArgs, 'id'>>;
  delete_demiplane_connection_status?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneConnectionStatusArgs, 'where'>>;
  delete_demiplane_connection_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneConnectionStatusByPkArgs, 'id'>>;
  delete_demiplane_connection_type?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneConnectionTypeArgs, 'where'>>;
  delete_demiplane_connection_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneConnectionTypeByPkArgs, 'id'>>;
  delete_demiplane_contact_type?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneContactTypeArgs, 'where'>>;
  delete_demiplane_contact_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_contact_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneContactTypeByPkArgs, 'id'>>;
  delete_demiplane_country?: Resolver<Maybe<ResolversTypes['demiplane_country_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneCountryArgs, 'where'>>;
  delete_demiplane_country_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_country']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneCountryByPkArgs, 'id'>>;
  delete_demiplane_credit_card?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneCreditCardArgs, 'where'>>;
  delete_demiplane_credit_card_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneCreditCardByPkArgs, 'id'>>;
  delete_demiplane_demiplane_tool?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneDemiplaneToolArgs, 'where'>>;
  delete_demiplane_demiplane_tool_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneDemiplaneToolByPkArgs, 'id'>>;
  delete_demiplane_episode_attendance?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneEpisodeAttendanceArgs, 'where'>>;
  delete_demiplane_episode_attendance_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneEpisodeAttendanceByPkArgs, 'id'>>;
  delete_demiplane_event?: Resolver<Maybe<ResolversTypes['demiplane_event_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneEventArgs, 'where'>>;
  delete_demiplane_event_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_event']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneEventByPkArgs, 'id'>>;
  delete_demiplane_event_type?: Resolver<Maybe<ResolversTypes['demiplane_event_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneEventTypeArgs, 'where'>>;
  delete_demiplane_event_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_event_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneEventTypeByPkArgs, 'id'>>;
  delete_demiplane_favorite_adventuring_platform?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneFavoriteAdventuringPlatformArgs, 'where'>>;
  delete_demiplane_favorite_adventuring_platform_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneFavoriteAdventuringPlatformByPkArgs, 'id'>>;
  delete_demiplane_favorite_dice_roll?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneFavoriteDiceRollArgs, 'where'>>;
  delete_demiplane_favorite_dice_roll_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneFavoriteDiceRollByPkArgs, 'id'>>;
  delete_demiplane_favorite_game?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneFavoriteGameArgs, 'where'>>;
  delete_demiplane_favorite_game_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneFavoriteGameByPkArgs, 'id'>>;
  delete_demiplane_game_type?: Resolver<Maybe<ResolversTypes['demiplane_game_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneGameTypeArgs, 'where'>>;
  delete_demiplane_game_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_game_type']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneGameTypeByPkArgs, 'id'>>;
  delete_demiplane_gm_rating?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneGmRatingArgs, 'where'>>;
  delete_demiplane_gm_rating_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneGmRatingByPkArgs, 'id'>>;
  delete_demiplane_gm_recommendation?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneGmRecommendationArgs, 'where'>>;
  delete_demiplane_gm_recommendation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneGmRecommendationByPkArgs, 'id'>>;
  delete_demiplane_invitation?: Resolver<Maybe<ResolversTypes['demiplane_invitation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneInvitationArgs, 'where'>>;
  delete_demiplane_invitation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_invitation']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneInvitationByPkArgs, 'id'>>;
  delete_demiplane_journal?: Resolver<Maybe<ResolversTypes['demiplane_journal_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalArgs, 'where'>>;
  delete_demiplane_journal_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalByPkArgs, 'id'>>;
  delete_demiplane_journal_note?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalNoteArgs, 'where'>>;
  delete_demiplane_journal_note_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalNoteByPkArgs, 'id'>>;
  delete_demiplane_journal_note_share?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalNoteShareArgs, 'where'>>;
  delete_demiplane_journal_note_share_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalNoteShareByPkArgs, 'id'>>;
  delete_demiplane_journal_super_event?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalSuperEventArgs, 'where'>>;
  delete_demiplane_journal_super_event_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalSuperEventByPkArgs, 'id'>>;
  delete_demiplane_journal_super_event_read_status?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalSuperEventReadStatusArgs, 'where'>>;
  delete_demiplane_journal_super_event_read_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalSuperEventReadStatusByPkArgs, 'id'>>;
  delete_demiplane_journal_title?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalTitleArgs, 'where'>>;
  delete_demiplane_journal_title_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneJournalTitleByPkArgs, 'id'>>;
  delete_demiplane_matchmaking_token?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneMatchmakingTokenArgs, 'where'>>;
  delete_demiplane_matchmaking_token_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneMatchmakingTokenByPkArgs, 'id'>>;
  delete_demiplane_migrations?: Resolver<Maybe<ResolversTypes['demiplane_migrations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneMigrationsArgs, 'where'>>;
  delete_demiplane_migrations_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_migrations']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneMigrationsByPkArgs, 'id'>>;
  delete_demiplane_notification_history?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneNotificationHistoryArgs, 'where'>>;
  delete_demiplane_notification_history_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneNotificationHistoryByPkArgs, 'id'>>;
  delete_demiplane_payment?: Resolver<Maybe<ResolversTypes['demiplane_payment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePaymentArgs, 'where'>>;
  delete_demiplane_payment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_payment']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePaymentByPkArgs, 'id'>>;
  delete_demiplane_player_award?: Resolver<Maybe<ResolversTypes['demiplane_player_award_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePlayerAwardArgs, 'where'>>;
  delete_demiplane_player_award_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePlayerAwardByPkArgs, 'id'>>;
  delete_demiplane_player_rating?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePlayerRatingArgs, 'where'>>;
  delete_demiplane_player_rating_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePlayerRatingByPkArgs, 'id'>>;
  delete_demiplane_pronoun?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePronounArgs, 'where'>>;
  delete_demiplane_pronoun_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplanePronounByPkArgs, 'id'>>;
  delete_demiplane_region?: Resolver<Maybe<ResolversTypes['demiplane_region_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneRegionArgs, 'where'>>;
  delete_demiplane_region_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_region']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneRegionByPkArgs, 'id'>>;
  delete_demiplane_role?: Resolver<Maybe<ResolversTypes['demiplane_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneRoleArgs, 'where'>>;
  delete_demiplane_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_role']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneRoleByPkArgs, 'id'>>;
  delete_demiplane_session?: Resolver<Maybe<ResolversTypes['demiplane_session_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneSessionArgs, 'where'>>;
  delete_demiplane_session_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_session']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneSessionByPkArgs, 'id'>>;
  delete_demiplane_session_player?: Resolver<Maybe<ResolversTypes['demiplane_session_player_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneSessionPlayerArgs, 'where'>>;
  delete_demiplane_session_player_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_session_player']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneSessionPlayerByPkArgs, 'id'>>;
  delete_demiplane_snippet?: Resolver<Maybe<ResolversTypes['demiplane_snippet_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneSnippetArgs, 'where'>>;
  delete_demiplane_snippet_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_snippet']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneSnippetByPkArgs, 'id'>>;
  delete_demiplane_task?: Resolver<Maybe<ResolversTypes['demiplane_task_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTaskArgs, 'where'>>;
  delete_demiplane_task_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTaskByPkArgs, 'id'>>;
  delete_demiplane_task_read_status?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTaskReadStatusArgs, 'where'>>;
  delete_demiplane_task_read_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTaskReadStatusByPkArgs, 'id'>>;
  delete_demiplane_tax_nexus_region?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTaxNexusRegionArgs, 'where'>>;
  delete_demiplane_tax_nexus_region_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTaxNexusRegionByPkArgs, 'id'>>;
  delete_demiplane_transaction_receipt?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTransactionReceiptArgs, 'where'>>;
  delete_demiplane_transaction_receipt_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneTransactionReceiptByPkArgs, 'id'>>;
  delete_demiplane_upload?: Resolver<Maybe<ResolversTypes['demiplane_upload_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUploadArgs, 'where'>>;
  delete_demiplane_upload_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_upload']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUploadByPkArgs, 'id'>>;
  delete_demiplane_urap?: Resolver<Maybe<ResolversTypes['demiplane_urap_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUrapArgs, 'where'>>;
  delete_demiplane_urap_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_urap']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUrapByPkArgs, 'adventuringPlatformId' | 'userRecruitmentId'>>;
  delete_demiplane_urgt?: Resolver<Maybe<ResolversTypes['demiplane_urgt_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUrgtArgs, 'where'>>;
  delete_demiplane_urgt_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_urgt']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUrgtByPkArgs, 'gameTypeId' | 'userRecruitmentId'>>;
  delete_demiplane_user?: Resolver<Maybe<ResolversTypes['demiplane_user_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserArgs, 'where'>>;
  delete_demiplane_user_activity?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserActivityArgs, 'where'>>;
  delete_demiplane_user_activity_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserActivityByPkArgs, 'id'>>;
  delete_demiplane_user_adventure?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserAdventureArgs, 'where'>>;
  delete_demiplane_user_adventure_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserAdventureByPkArgs, 'id'>>;
  delete_demiplane_user_adventure_ranking?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserAdventureRankingArgs, 'where'>>;
  delete_demiplane_user_adventure_ranking_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserAdventureRankingByPkArgs, 'id'>>;
  delete_demiplane_user_adventure_role?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserAdventureRoleArgs, 'where'>>;
  delete_demiplane_user_adventure_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserAdventureRoleByPkArgs, 'id'>>;
  delete_demiplane_user_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserByPkArgs, 'id'>>;
  delete_demiplane_user_email_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserEmailNotificationPrefArgs, 'where'>>;
  delete_demiplane_user_email_notification_pref_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserEmailNotificationPrefByPkArgs, 'id'>>;
  delete_demiplane_user_invitation?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserInvitationArgs, 'where'>>;
  delete_demiplane_user_invitation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserInvitationByPkArgs, 'id'>>;
  delete_demiplane_user_metadata?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserMetadataArgs, 'where'>>;
  delete_demiplane_user_metadata_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserMetadataByPkArgs, 'id'>>;
  delete_demiplane_user_push_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserPushNotificationPrefArgs, 'where'>>;
  delete_demiplane_user_push_notification_pref_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserPushNotificationPrefByPkArgs, 'id'>>;
  delete_demiplane_user_recruitment?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentArgs, 'where'>>;
  delete_demiplane_user_recruitment_adventurer?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerArgs, 'where'>>;
  delete_demiplane_user_recruitment_adventurer_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerAvailabilityArgs, 'where'>>;
  delete_demiplane_user_recruitment_adventurer_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs, 'id'>>;
  delete_demiplane_user_recruitment_adventurer_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerByPkArgs, 'id'>>;
  delete_demiplane_user_recruitment_adventurer_games?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerGamesArgs, 'where'>>;
  delete_demiplane_user_recruitment_adventurer_games_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerGamesByPkArgs, 'game_type_id' | 'user_recruitment_adventurer_id'>>;
  delete_demiplane_user_recruitment_adventurer_platforms?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerPlatformsArgs, 'where'>>;
  delete_demiplane_user_recruitment_adventurer_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs, 'adventuring_platform_id' | 'user_recruitment_adventurer_id'>>;
  delete_demiplane_user_recruitment_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAvailabilityArgs, 'where'>>;
  delete_demiplane_user_recruitment_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentAvailabilityByPkArgs, 'id'>>;
  delete_demiplane_user_recruitment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentByPkArgs, 'id'>>;
  delete_demiplane_user_recruitment_game_master?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterArgs, 'where'>>;
  delete_demiplane_user_recruitment_game_master_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterAvailabilityArgs, 'where'>>;
  delete_demiplane_user_recruitment_game_master_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs, 'id'>>;
  delete_demiplane_user_recruitment_game_master_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterByPkArgs, 'id'>>;
  delete_demiplane_user_recruitment_game_master_games?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterGamesArgs, 'where'>>;
  delete_demiplane_user_recruitment_game_master_games_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterGamesByPkArgs, 'game_type_id' | 'user_recruitment_game_master_id'>>;
  delete_demiplane_user_recruitment_game_master_platforms?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterPlatformsArgs, 'where'>>;
  delete_demiplane_user_recruitment_game_master_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs, 'adventuring_platform_id' | 'user_recruitment_game_master_id'>>;
  delete_demiplane_user_role?: Resolver<Maybe<ResolversTypes['demiplane_user_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRoleArgs, 'where'>>;
  delete_demiplane_user_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_role']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserRoleByPkArgs, 'roleId' | 'userId'>>;
  delete_demiplane_user_visibility?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserVisibilityArgs, 'where'>>;
  delete_demiplane_user_visibility_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility']>, ParentType, ContextType, RequireFields<MutationRootDeleteDemiplaneUserVisibilityByPkArgs, 'id'>>;
  disableAdventureMatchmaking?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootDisableAdventureMatchmakingArgs, 'adventureId'>>;
  ejectAdventureGameMaster?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootEjectAdventureGameMasterArgs, 'adventureId' | 'userId'>>;
  ejectGameMasterV2?: Resolver<Maybe<ResolversTypes['EjectGameMasterV2Output']>, ParentType, ContextType, RequireFields<MutationRootEjectGameMasterV2Args, 'adventureId' | 'gameMasterToEjectUserId'>>;
  enableAdventureMatchmaking?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootEnableAdventureMatchmakingArgs, 'adventureId' | 'adventurerCount' | 'autoJoin' | 'threshold'>>;
  endEpisode?: Resolver<Maybe<ResolversTypes['EndEpisodeOutput']>, ParentType, ContextType, RequireFields<MutationRootEndEpisodeArgs, 'portalId'>>;
  gameplayNotification?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootGameplayNotificationArgs, 'session'>>;
  immediatelyOpenEpisodeV2?: Resolver<Maybe<ResolversTypes['ImmediatelyOpenEpisodeV2Output']>, ParentType, ContextType, RequireFields<MutationRootImmediatelyOpenEpisodeV2Args, 'portalId'>>;
  immediatelyOpenSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootImmediatelyOpenSessionArgs, 'session'>>;
  insertPlayerAward?: Resolver<ResolversTypes['PlayerAwardType'], ParentType, ContextType, RequireFields<MutationRootInsertPlayerAwardArgs, 'awardType' | 'sessionId' | 'sessionPlayerId'>>;
  insert_demiplane_ad?: Resolver<Maybe<ResolversTypes['demiplane_ad_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdArgs, 'objects'>>;
  insert_demiplane_ad_link?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdLinkArgs, 'objects'>>;
  insert_demiplane_ad_link_one?: Resolver<Maybe<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdLinkOneArgs, 'object'>>;
  insert_demiplane_ad_match_criteria?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdMatchCriteriaArgs, 'objects'>>;
  insert_demiplane_ad_match_criteria_one?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdMatchCriteriaOneArgs, 'object'>>;
  insert_demiplane_ad_match_criteria_time?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdMatchCriteriaTimeArgs, 'objects'>>;
  insert_demiplane_ad_match_criteria_time_one?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdMatchCriteriaTimeOneArgs, 'object'>>;
  insert_demiplane_ad_one?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdOneArgs, 'object'>>;
  insert_demiplane_ad_request?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdRequestArgs, 'objects'>>;
  insert_demiplane_ad_request_one?: Resolver<Maybe<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdRequestOneArgs, 'object'>>;
  insert_demiplane_ad_time?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdTimeArgs, 'objects'>>;
  insert_demiplane_ad_time_one?: Resolver<Maybe<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdTimeOneArgs, 'object'>>;
  insert_demiplane_adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureArgs, 'objects'>>;
  insert_demiplane_adventure_connection?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureConnectionArgs, 'objects'>>;
  insert_demiplane_adventure_connection_one?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureConnectionOneArgs, 'object'>>;
  insert_demiplane_adventure_demiplane_tool?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureDemiplaneToolArgs, 'objects'>>;
  insert_demiplane_adventure_demiplane_tool_one?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureDemiplaneToolOneArgs, 'object'>>;
  insert_demiplane_adventure_link?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureLinkArgs, 'objects'>>;
  insert_demiplane_adventure_link_one?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureLinkOneArgs, 'object'>>;
  insert_demiplane_adventure_one?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureOneArgs, 'object'>>;
  insert_demiplane_adventure_player?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventurePlayerArgs, 'objects'>>;
  insert_demiplane_adventure_player_one?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventurePlayerOneArgs, 'object'>>;
  insert_demiplane_adventure_todo?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureTodoArgs, 'objects'>>;
  insert_demiplane_adventure_todo_one?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventureTodoOneArgs, 'object'>>;
  insert_demiplane_adventuring_platform?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventuringPlatformArgs, 'objects'>>;
  insert_demiplane_adventuring_platform_one?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAdventuringPlatformOneArgs, 'object'>>;
  insert_demiplane_amc_adventuring_platforms?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAmcAdventuringPlatformsArgs, 'objects'>>;
  insert_demiplane_amc_adventuring_platforms_one?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAmcAdventuringPlatformsOneArgs, 'object'>>;
  insert_demiplane_amc_game_types?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAmcGameTypesArgs, 'objects'>>;
  insert_demiplane_amc_game_types_one?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAmcGameTypesOneArgs, 'object'>>;
  insert_demiplane_app_version?: Resolver<Maybe<ResolversTypes['demiplane_app_version_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAppVersionArgs, 'objects'>>;
  insert_demiplane_app_version_one?: Resolver<Maybe<ResolversTypes['demiplane_app_version']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAppVersionOneArgs, 'object'>>;
  insert_demiplane_attachment?: Resolver<Maybe<ResolversTypes['demiplane_attachment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAttachmentArgs, 'objects'>>;
  insert_demiplane_attachment_one?: Resolver<Maybe<ResolversTypes['demiplane_attachment']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAttachmentOneArgs, 'object'>>;
  insert_demiplane_award_type?: Resolver<Maybe<ResolversTypes['demiplane_award_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAwardTypeArgs, 'objects'>>;
  insert_demiplane_award_type_one?: Resolver<Maybe<ResolversTypes['demiplane_award_type']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneAwardTypeOneArgs, 'object'>>;
  insert_demiplane_connection_status?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneConnectionStatusArgs, 'objects'>>;
  insert_demiplane_connection_status_one?: Resolver<Maybe<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneConnectionStatusOneArgs, 'object'>>;
  insert_demiplane_connection_type?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneConnectionTypeArgs, 'objects'>>;
  insert_demiplane_connection_type_one?: Resolver<Maybe<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneConnectionTypeOneArgs, 'object'>>;
  insert_demiplane_contact_type?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneContactTypeArgs, 'objects'>>;
  insert_demiplane_contact_type_one?: Resolver<Maybe<ResolversTypes['demiplane_contact_type']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneContactTypeOneArgs, 'object'>>;
  insert_demiplane_country?: Resolver<Maybe<ResolversTypes['demiplane_country_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneCountryArgs, 'objects'>>;
  insert_demiplane_country_one?: Resolver<Maybe<ResolversTypes['demiplane_country']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneCountryOneArgs, 'object'>>;
  insert_demiplane_credit_card?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneCreditCardArgs, 'objects'>>;
  insert_demiplane_credit_card_one?: Resolver<Maybe<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneCreditCardOneArgs, 'object'>>;
  insert_demiplane_demiplane_tool?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneDemiplaneToolArgs, 'objects'>>;
  insert_demiplane_demiplane_tool_one?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneDemiplaneToolOneArgs, 'object'>>;
  insert_demiplane_episode_attendance?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneEpisodeAttendanceArgs, 'objects'>>;
  insert_demiplane_episode_attendance_one?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneEpisodeAttendanceOneArgs, 'object'>>;
  insert_demiplane_event?: Resolver<Maybe<ResolversTypes['demiplane_event_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneEventArgs, 'objects'>>;
  insert_demiplane_event_one?: Resolver<Maybe<ResolversTypes['demiplane_event']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneEventOneArgs, 'object'>>;
  insert_demiplane_event_type?: Resolver<Maybe<ResolversTypes['demiplane_event_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneEventTypeArgs, 'objects'>>;
  insert_demiplane_event_type_one?: Resolver<Maybe<ResolversTypes['demiplane_event_type']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneEventTypeOneArgs, 'object'>>;
  insert_demiplane_favorite_adventuring_platform?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneFavoriteAdventuringPlatformArgs, 'objects'>>;
  insert_demiplane_favorite_adventuring_platform_one?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneFavoriteAdventuringPlatformOneArgs, 'object'>>;
  insert_demiplane_favorite_dice_roll?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneFavoriteDiceRollArgs, 'objects'>>;
  insert_demiplane_favorite_dice_roll_one?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneFavoriteDiceRollOneArgs, 'object'>>;
  insert_demiplane_favorite_game?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneFavoriteGameArgs, 'objects'>>;
  insert_demiplane_favorite_game_one?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneFavoriteGameOneArgs, 'object'>>;
  insert_demiplane_game_type?: Resolver<Maybe<ResolversTypes['demiplane_game_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneGameTypeArgs, 'objects'>>;
  insert_demiplane_game_type_one?: Resolver<Maybe<ResolversTypes['demiplane_game_type']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneGameTypeOneArgs, 'object'>>;
  insert_demiplane_gm_rating?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneGmRatingArgs, 'objects'>>;
  insert_demiplane_gm_rating_one?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneGmRatingOneArgs, 'object'>>;
  insert_demiplane_gm_recommendation?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneGmRecommendationArgs, 'objects'>>;
  insert_demiplane_gm_recommendation_one?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneGmRecommendationOneArgs, 'object'>>;
  insert_demiplane_invitation?: Resolver<Maybe<ResolversTypes['demiplane_invitation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneInvitationArgs, 'objects'>>;
  insert_demiplane_invitation_one?: Resolver<Maybe<ResolversTypes['demiplane_invitation']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneInvitationOneArgs, 'object'>>;
  insert_demiplane_journal?: Resolver<Maybe<ResolversTypes['demiplane_journal_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalArgs, 'objects'>>;
  insert_demiplane_journal_note?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalNoteArgs, 'objects'>>;
  insert_demiplane_journal_note_one?: Resolver<Maybe<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalNoteOneArgs, 'object'>>;
  insert_demiplane_journal_note_share?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalNoteShareArgs, 'objects'>>;
  insert_demiplane_journal_note_share_one?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalNoteShareOneArgs, 'object'>>;
  insert_demiplane_journal_one?: Resolver<Maybe<ResolversTypes['demiplane_journal']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalOneArgs, 'object'>>;
  insert_demiplane_journal_super_event?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalSuperEventArgs, 'objects'>>;
  insert_demiplane_journal_super_event_one?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalSuperEventOneArgs, 'object'>>;
  insert_demiplane_journal_super_event_read_status?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalSuperEventReadStatusArgs, 'objects'>>;
  insert_demiplane_journal_super_event_read_status_one?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalSuperEventReadStatusOneArgs, 'object'>>;
  insert_demiplane_journal_title?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalTitleArgs, 'objects'>>;
  insert_demiplane_journal_title_one?: Resolver<Maybe<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneJournalTitleOneArgs, 'object'>>;
  insert_demiplane_matchmaking_token?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneMatchmakingTokenArgs, 'objects'>>;
  insert_demiplane_matchmaking_token_one?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneMatchmakingTokenOneArgs, 'object'>>;
  insert_demiplane_migrations?: Resolver<Maybe<ResolversTypes['demiplane_migrations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneMigrationsArgs, 'objects'>>;
  insert_demiplane_migrations_one?: Resolver<Maybe<ResolversTypes['demiplane_migrations']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneMigrationsOneArgs, 'object'>>;
  insert_demiplane_notification_history?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneNotificationHistoryArgs, 'objects'>>;
  insert_demiplane_notification_history_one?: Resolver<Maybe<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneNotificationHistoryOneArgs, 'object'>>;
  insert_demiplane_payment?: Resolver<Maybe<ResolversTypes['demiplane_payment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePaymentArgs, 'objects'>>;
  insert_demiplane_payment_one?: Resolver<Maybe<ResolversTypes['demiplane_payment']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePaymentOneArgs, 'object'>>;
  insert_demiplane_player_award?: Resolver<Maybe<ResolversTypes['demiplane_player_award_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePlayerAwardArgs, 'objects'>>;
  insert_demiplane_player_award_one?: Resolver<Maybe<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePlayerAwardOneArgs, 'object'>>;
  insert_demiplane_player_rating?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePlayerRatingArgs, 'objects'>>;
  insert_demiplane_player_rating_one?: Resolver<Maybe<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePlayerRatingOneArgs, 'object'>>;
  insert_demiplane_pronoun?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePronounArgs, 'objects'>>;
  insert_demiplane_pronoun_one?: Resolver<Maybe<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplanePronounOneArgs, 'object'>>;
  insert_demiplane_region?: Resolver<Maybe<ResolversTypes['demiplane_region_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneRegionArgs, 'objects'>>;
  insert_demiplane_region_one?: Resolver<Maybe<ResolversTypes['demiplane_region']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneRegionOneArgs, 'object'>>;
  insert_demiplane_role?: Resolver<Maybe<ResolversTypes['demiplane_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneRoleArgs, 'objects'>>;
  insert_demiplane_role_one?: Resolver<Maybe<ResolversTypes['demiplane_role']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneRoleOneArgs, 'object'>>;
  insert_demiplane_session?: Resolver<Maybe<ResolversTypes['demiplane_session_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneSessionArgs, 'objects'>>;
  insert_demiplane_session_one?: Resolver<Maybe<ResolversTypes['demiplane_session']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneSessionOneArgs, 'object'>>;
  insert_demiplane_session_player?: Resolver<Maybe<ResolversTypes['demiplane_session_player_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneSessionPlayerArgs, 'objects'>>;
  insert_demiplane_session_player_one?: Resolver<Maybe<ResolversTypes['demiplane_session_player']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneSessionPlayerOneArgs, 'object'>>;
  insert_demiplane_snippet?: Resolver<Maybe<ResolversTypes['demiplane_snippet_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneSnippetArgs, 'objects'>>;
  insert_demiplane_snippet_one?: Resolver<Maybe<ResolversTypes['demiplane_snippet']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneSnippetOneArgs, 'object'>>;
  insert_demiplane_task?: Resolver<Maybe<ResolversTypes['demiplane_task_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTaskArgs, 'objects'>>;
  insert_demiplane_task_one?: Resolver<Maybe<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTaskOneArgs, 'object'>>;
  insert_demiplane_task_read_status?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTaskReadStatusArgs, 'objects'>>;
  insert_demiplane_task_read_status_one?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTaskReadStatusOneArgs, 'object'>>;
  insert_demiplane_tax_nexus_region?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTaxNexusRegionArgs, 'objects'>>;
  insert_demiplane_tax_nexus_region_one?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTaxNexusRegionOneArgs, 'object'>>;
  insert_demiplane_transaction_receipt?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTransactionReceiptArgs, 'objects'>>;
  insert_demiplane_transaction_receipt_one?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneTransactionReceiptOneArgs, 'object'>>;
  insert_demiplane_upload?: Resolver<Maybe<ResolversTypes['demiplane_upload_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUploadArgs, 'objects'>>;
  insert_demiplane_upload_one?: Resolver<Maybe<ResolversTypes['demiplane_upload']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUploadOneArgs, 'object'>>;
  insert_demiplane_urap?: Resolver<Maybe<ResolversTypes['demiplane_urap_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUrapArgs, 'objects'>>;
  insert_demiplane_urap_one?: Resolver<Maybe<ResolversTypes['demiplane_urap']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUrapOneArgs, 'object'>>;
  insert_demiplane_urgt?: Resolver<Maybe<ResolversTypes['demiplane_urgt_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUrgtArgs, 'objects'>>;
  insert_demiplane_urgt_one?: Resolver<Maybe<ResolversTypes['demiplane_urgt']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUrgtOneArgs, 'object'>>;
  insert_demiplane_user?: Resolver<Maybe<ResolversTypes['demiplane_user_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserArgs, 'objects'>>;
  insert_demiplane_user_activity?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserActivityArgs, 'objects'>>;
  insert_demiplane_user_activity_one?: Resolver<Maybe<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserActivityOneArgs, 'object'>>;
  insert_demiplane_user_adventure?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserAdventureArgs, 'objects'>>;
  insert_demiplane_user_adventure_one?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserAdventureOneArgs, 'object'>>;
  insert_demiplane_user_adventure_ranking?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserAdventureRankingArgs, 'objects'>>;
  insert_demiplane_user_adventure_ranking_one?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserAdventureRankingOneArgs, 'object'>>;
  insert_demiplane_user_adventure_role?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserAdventureRoleArgs, 'objects'>>;
  insert_demiplane_user_adventure_role_one?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserAdventureRoleOneArgs, 'object'>>;
  insert_demiplane_user_email_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserEmailNotificationPrefArgs, 'objects'>>;
  insert_demiplane_user_email_notification_pref_one?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserEmailNotificationPrefOneArgs, 'object'>>;
  insert_demiplane_user_invitation?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserInvitationArgs, 'objects'>>;
  insert_demiplane_user_invitation_one?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserInvitationOneArgs, 'object'>>;
  insert_demiplane_user_metadata?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserMetadataArgs, 'objects'>>;
  insert_demiplane_user_metadata_one?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserMetadataOneArgs, 'object'>>;
  insert_demiplane_user_one?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserOneArgs, 'object'>>;
  insert_demiplane_user_push_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserPushNotificationPrefArgs, 'objects'>>;
  insert_demiplane_user_push_notification_pref_one?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserPushNotificationPrefOneArgs, 'object'>>;
  insert_demiplane_user_recruitment?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentArgs, 'objects'>>;
  insert_demiplane_user_recruitment_adventurer?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerArgs, 'objects'>>;
  insert_demiplane_user_recruitment_adventurer_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerAvailabilityArgs, 'objects'>>;
  insert_demiplane_user_recruitment_adventurer_availability_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerAvailabilityOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_adventurer_games?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerGamesArgs, 'objects'>>;
  insert_demiplane_user_recruitment_adventurer_games_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerGamesOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_adventurer_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_adventurer_platforms?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerPlatformsArgs, 'objects'>>;
  insert_demiplane_user_recruitment_adventurer_platforms_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAdventurerPlatformsOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAvailabilityArgs, 'objects'>>;
  insert_demiplane_user_recruitment_availability_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentAvailabilityOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_game_master?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterArgs, 'objects'>>;
  insert_demiplane_user_recruitment_game_master_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterAvailabilityArgs, 'objects'>>;
  insert_demiplane_user_recruitment_game_master_availability_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterAvailabilityOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_game_master_games?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterGamesArgs, 'objects'>>;
  insert_demiplane_user_recruitment_game_master_games_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterGamesOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_game_master_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_game_master_platforms?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterPlatformsArgs, 'objects'>>;
  insert_demiplane_user_recruitment_game_master_platforms_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentGameMasterPlatformsOneArgs, 'object'>>;
  insert_demiplane_user_recruitment_one?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRecruitmentOneArgs, 'object'>>;
  insert_demiplane_user_role?: Resolver<Maybe<ResolversTypes['demiplane_user_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRoleArgs, 'objects'>>;
  insert_demiplane_user_role_one?: Resolver<Maybe<ResolversTypes['demiplane_user_role']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserRoleOneArgs, 'object'>>;
  insert_demiplane_user_visibility?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserVisibilityArgs, 'objects'>>;
  insert_demiplane_user_visibility_one?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility']>, ParentType, ContextType, RequireFields<MutationRootInsertDemiplaneUserVisibilityOneArgs, 'object'>>;
  inviteAdventurers?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootInviteAdventurersArgs, 'adventureId' | 'invites'>>;
  joinAdventureRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootJoinAdventureRecruitArgs, 'connectionId'>>;
  joinAdventureRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootJoinAdventureRequestArgs, 'connectionId'>>;
  joinSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootJoinSessionArgs, 'session'>>;
  leaveAdventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootLeaveAdventureArgs, 'adventureId'>>;
  leaveEpisodeV2?: Resolver<Maybe<ResolversTypes['LeaveEpisodeV2Output']>, ParentType, ContextType, RequireFields<MutationRootLeaveEpisodeV2Args, 'id'>>;
  leaveRecruit?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootLeaveRecruitArgs, 'connectionId'>>;
  leaveRequest?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootLeaveRequestArgs, 'connectionId'>>;
  leaveSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootLeaveSessionArgs, 'session'>>;
  openEpisodeV2?: Resolver<Maybe<ResolversTypes['OpenEpisodeV2Output']>, ParentType, ContextType, RequireFields<MutationRootOpenEpisodeV2Args, 'portalId'>>;
  openSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootOpenSessionArgs, 'adventureId'>>;
  participantLeavesWhisper?: Resolver<Maybe<ResolversTypes['ParticipantLeavesWhisperOutput']>, ParentType, ContextType, RequireFields<MutationRootParticipantLeavesWhisperArgs, 'portalId' | 'whisperParticipantId'>>;
  preauthorizePayment?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType, RequireFields<MutationRootPreauthorizePaymentArgs, 'sessionId'>>;
  precalculateFees?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType, RequireFields<MutationRootPrecalculateFeesArgs, 'sessionId'>>;
  promoteAdventureGameMaster?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootPromoteAdventureGameMasterArgs, 'adventureId' | 'userId'>>;
  promoteGameMasterV2?: Resolver<Maybe<ResolversTypes['PromoteGameMasterV2Output']>, ParentType, ContextType, RequireFields<MutationRootPromoteGameMasterV2Args, 'adventureId' | 'adventurerToPromoteUserId'>>;
  readAdventureConnections?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReadAdventureConnectionsArgs, 'adventureConnnectionIds' | 'adventureId'>>;
  readNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReadNotificationArgs, 'notificationId'>>;
  readNotificationByUrl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReadNotificationByUrlArgs, 'url'>>;
  readSharedNote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReadSharedNoteArgs, 'adventureId' | 'noteId'>>;
  readSuperEvents?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReadSuperEventsArgs, 'adventureId' | 'superEventIds'>>;
  readTasks?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReadTasksArgs, 'adventureId' | 'taskIds'>>;
  readUnreadNotifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  recruitAdventure?: Resolver<ResolversTypes['AdventureConnectionType'], ParentType, ContextType, RequireFields<MutationRootRecruitAdventureArgs, 'adventureId' | 'forGm' | 'recruitId'>>;
  removeAdventurer?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootRemoveAdventurerArgs, 'adventureId' | 'userId'>>;
  removeAdventurerV2?: Resolver<Maybe<ResolversTypes['RemoveAdventurerV2Output']>, ParentType, ContextType, RequireFields<MutationRootRemoveAdventurerV2Args, 'adventureId' | 'adventurerToRemoveUserId'>>;
  reorderFavoriteDiceRolls?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReorderFavoriteDiceRollsArgs, 'adventureId' | 'rollIds'>>;
  reorderFavoriteGames?: Resolver<Maybe<ResolversTypes['ReorderFavoriteGamesOutput']>, ParentType, ContextType, RequireFields<MutationRootReorderFavoriteGamesArgs, 'gameTypeIds' | 'userId'>>;
  reorderFavoritePlatforms?: Resolver<Maybe<ResolversTypes['ReorderFavoritePlatformsOutput']>, ParentType, ContextType, RequireFields<MutationRootReorderFavoritePlatformsArgs, 'adventuringPlatformIds' | 'userId'>>;
  reorderTasks?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootReorderTasksArgs, 'taskIds'>>;
  rescheduleSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootRescheduleSessionArgs, 'session'>>;
  scheduleSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootScheduleSessionArgs, 'session'>>;
  sendAccountCreateEmail?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  sendFeedbackEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSendFeedbackEmailArgs, 'adventureId' | 'feedback' | 'gmRating' | 'userId'>>;
  sendTipEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSendTipEmailArgs, 'adventureId' | 'userId'>>;
  setEpisodeFrequency?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSetEpisodeFrequencyArgs, 'adventureId' | 'episodeFrequency'>>;
  shareJournalNote?: Resolver<ResolversTypes['JournalNoteShareReturnType'], ParentType, ContextType, RequireFields<MutationRootShareJournalNoteArgs, 'journalNote'>>;
  specialRequest?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootSpecialRequestArgs, 'adventureId'>>;
  startEpisodeV2?: Resolver<Maybe<ResolversTypes['StartEpisodeV2Output']>, ParentType, ContextType, RequireFields<MutationRootStartEpisodeV2Args, 'portalId'>>;
  startSession?: Resolver<ResolversTypes['SessionMutationType'], ParentType, ContextType, RequireFields<MutationRootStartSessionArgs, 'session'>>;
  submitFile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSubmitFileArgs, 'chatHash' | 'file'>>;
  submitFileV2?: Resolver<Maybe<ResolversTypes['SubmitFileV2Output']>, ParentType, ContextType, RequireFields<MutationRootSubmitFileV2Args, 'base64str' | 'chatHash' | 'name' | 'type'>>;
  submitGiphy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSubmitGiphyArgs, 'chatHash' | 'url'>>;
  submitMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSubmitMessageArgs, 'chatHash' | 'message'>>;
  submitSystemMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSubmitSystemMessageArgs, 'chatHash' | 'message'>>;
  toggleVoiceVideoStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootToggleVoiceVideoStatusArgs, 'adventurePlayerId' | 'inVideo' | 'inVoice'>>;
  toggleWhisperStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootToggleWhisperStatusArgs, 'adventurePlayerId'>>;
  triggerDisableWhisper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootTriggerDisableWhisperArgs, 'adventurePlayerId'>>;
  triggerDisableWhisperV2?: Resolver<Maybe<ResolversTypes['TriggerDisableWhisperV2Output']>, ParentType, ContextType, RequireFields<MutationRootTriggerDisableWhisperV2Args, 'adventurePlayerId'>>;
  triggerEnableWhisper?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootTriggerEnableWhisperArgs, 'adventurePlayerId'>>;
  triggerEnableWhisperV2?: Resolver<Maybe<ResolversTypes['TriggerEnableWhisperV2Output']>, ParentType, ContextType, RequireFields<MutationRootTriggerEnableWhisperV2Args, 'adventurePlayerId'>>;
  updateAdventureCoverImage?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootUpdateAdventureCoverImageArgs, 'adventureId' | 'demicardId'>>;
  updateAdventureDetails?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootUpdateAdventureDetailsArgs, 'adventureId' | 'adventuringPlatformId' | 'description' | 'gameLinkUrl' | 'newPlayerFriendly'>>;
  updateAdventureFrequency?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootUpdateAdventureFrequencyArgs, 'adventureId' | 'frequency'>>;
  updateAdventureGameAvailability?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootUpdateAdventureGameAvailabilityArgs, 'adventureId'>>;
  updateAdventureMatchMakingStatus?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootUpdateAdventureMatchMakingStatusArgs, 'adventureId'>>;
  updateAdventurePlayerName?: Resolver<ResolversTypes['AdventurePlayerType'], ParentType, ContextType, RequireFields<MutationRootUpdateAdventurePlayerNameArgs, 'adventurePlayerId' | 'playerName'>>;
  updateAdventureThemeScores?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootUpdateAdventureThemeScoresArgs, 'adventureId' | 'combat' | 'playerStory' | 'puzzlesLogic' | 'rolePlaying' | 'socialIntrigue' | 'strictRules'>>;
  updateBillingAddress?: Resolver<Maybe<ResolversTypes['UpdateBillingAddressOutput']>, ParentType, ContextType, RequireFields<MutationRootUpdateBillingAddressArgs, 'address1' | 'city' | 'firstName' | 'lastName' | 'regionId' | 'zipcode'>>;
  updateFavoriteDiceRoll?: Resolver<ResolversTypes['FavoriteDiceRollType'], ParentType, ContextType, RequireFields<MutationRootUpdateFavoriteDiceRollArgs, 'adventureId' | 'favoriteDiceRollId' | 'newDiceRoll' | 'newRollName'>>;
  updateFirstLogin?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationRootUpdateFirstLoginArgs, 'firstLogin'>>;
  updateGameMasterBio?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationRootUpdateGameMasterBioArgs, 'gameMasterBio'>>;
  updateGmRating?: Resolver<ResolversTypes['GmRatingType'], ParentType, ContextType, RequireFields<MutationRootUpdateGmRatingArgs, 'rating' | 'sessionId' | 'sessionPlayerId'>>;
  updateJournal?: Resolver<ResolversTypes['JournalTitleType'], ParentType, ContextType, RequireFields<MutationRootUpdateJournalArgs, 'journal'>>;
  updateJournalNote?: Resolver<ResolversTypes['JournalNoteType'], ParentType, ContextType, RequireFields<MutationRootUpdateJournalNoteArgs, 'journalNote'>>;
  updateJournalSuperEvent?: Resolver<ResolversTypes['JournalSuperEventType'], ParentType, ContextType, RequireFields<MutationRootUpdateJournalSuperEventArgs, 'journalSuperEvent'>>;
  updateLeaderHasViewed?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<MutationRootUpdateLeaderHasViewedArgs, 'adventureId' | 'isAdventurer' | 'isGm'>>;
  updatePlayerRatings?: Resolver<Array<ResolversTypes['PlayerRatingType']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlayerRatingsArgs, 'ratings' | 'sessionId'>>;
  updatePreferences?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationRootUpdatePreferencesArgs, 'userPreferences'>>;
  updateTask?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType, RequireFields<MutationRootUpdateTaskArgs, 'id' | 'title'>>;
  updateUpload?: Resolver<ResolversTypes['UploadType'], ParentType, ContextType, RequireFields<MutationRootUpdateUploadArgs, 'file' | 'kind' | 'mimeType' | 'uploadId'>>;
  updateUploadV2?: Resolver<Maybe<ResolversTypes['UpdateUploadV2Output']>, ParentType, ContextType, RequireFields<MutationRootUpdateUploadV2Args, 'file' | 'kind' | 'mimeType' | 'uploadId'>>;
  updateUserInformation?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<MutationRootUpdateUserInformationArgs, 'userInformation'>>;
  updateUserInformationV2?: Resolver<Maybe<ResolversTypes['UpdateUserInformationV2Output']>, ParentType, ContextType, RequireFields<MutationRootUpdateUserInformationV2Args, 'address1' | 'city' | 'dob' | 'firstName' | 'lastName' | 'regionId' | 'zipcode'>>;
  updateUserMetadata?: Resolver<ResolversTypes['UserMetadataType'], ParentType, ContextType, RequireFields<MutationRootUpdateUserMetadataArgs, 'userMetadata'>>;
  updateUserRecruitment?: Resolver<ResolversTypes['UserRecruitmentType'], ParentType, ContextType, RequireFields<MutationRootUpdateUserRecruitmentArgs, 'userRecruitment'>>;
  update_demiplane_ad?: Resolver<Maybe<ResolversTypes['demiplane_ad_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdArgs, 'where'>>;
  update_demiplane_ad_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdByPkArgs, 'pk_columns'>>;
  update_demiplane_ad_link?: Resolver<Maybe<ResolversTypes['demiplane_ad_link_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdLinkArgs, 'where'>>;
  update_demiplane_ad_link_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdLinkByPkArgs, 'pk_columns'>>;
  update_demiplane_ad_match_criteria?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdMatchCriteriaArgs, 'where'>>;
  update_demiplane_ad_match_criteria_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdMatchCriteriaByPkArgs, 'pk_columns'>>;
  update_demiplane_ad_match_criteria_time?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdMatchCriteriaTimeArgs, 'where'>>;
  update_demiplane_ad_match_criteria_time_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdMatchCriteriaTimeByPkArgs, 'pk_columns'>>;
  update_demiplane_ad_request?: Resolver<Maybe<ResolversTypes['demiplane_ad_request_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdRequestArgs, 'where'>>;
  update_demiplane_ad_request_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdRequestByPkArgs, 'pk_columns'>>;
  update_demiplane_ad_time?: Resolver<Maybe<ResolversTypes['demiplane_ad_time_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdTimeArgs, 'where'>>;
  update_demiplane_ad_time_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdTimeByPkArgs, 'pk_columns'>>;
  update_demiplane_adventure?: Resolver<Maybe<ResolversTypes['demiplane_adventure_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureArgs, 'where'>>;
  update_demiplane_adventure_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureByPkArgs, 'pk_columns'>>;
  update_demiplane_adventure_connection?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureConnectionArgs, 'where'>>;
  update_demiplane_adventure_connection_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureConnectionByPkArgs, 'pk_columns'>>;
  update_demiplane_adventure_demiplane_tool?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureDemiplaneToolArgs, 'where'>>;
  update_demiplane_adventure_demiplane_tool_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureDemiplaneToolByPkArgs, 'pk_columns'>>;
  update_demiplane_adventure_link?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureLinkArgs, 'where'>>;
  update_demiplane_adventure_link_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureLinkByPkArgs, 'pk_columns'>>;
  update_demiplane_adventure_player?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventurePlayerArgs, 'where'>>;
  update_demiplane_adventure_player_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventurePlayerByPkArgs, 'pk_columns'>>;
  update_demiplane_adventure_todo?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureTodoArgs, 'where'>>;
  update_demiplane_adventure_todo_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventureTodoByPkArgs, 'pk_columns'>>;
  update_demiplane_adventuring_platform?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventuringPlatformArgs, 'where'>>;
  update_demiplane_adventuring_platform_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAdventuringPlatformByPkArgs, 'pk_columns'>>;
  update_demiplane_amc_adventuring_platforms?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAmcAdventuringPlatformsArgs, 'where'>>;
  update_demiplane_amc_adventuring_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAmcAdventuringPlatformsByPkArgs, 'pk_columns'>>;
  update_demiplane_amc_game_types?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAmcGameTypesArgs, 'where'>>;
  update_demiplane_amc_game_types_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAmcGameTypesByPkArgs, 'pk_columns'>>;
  update_demiplane_app_version?: Resolver<Maybe<ResolversTypes['demiplane_app_version_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAppVersionArgs, 'where'>>;
  update_demiplane_app_version_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_app_version']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAppVersionByPkArgs, 'pk_columns'>>;
  update_demiplane_attachment?: Resolver<Maybe<ResolversTypes['demiplane_attachment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAttachmentArgs, 'where'>>;
  update_demiplane_attachment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_attachment']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAttachmentByPkArgs, 'pk_columns'>>;
  update_demiplane_award_type?: Resolver<Maybe<ResolversTypes['demiplane_award_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAwardTypeArgs, 'where'>>;
  update_demiplane_award_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_award_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneAwardTypeByPkArgs, 'pk_columns'>>;
  update_demiplane_connection_status?: Resolver<Maybe<ResolversTypes['demiplane_connection_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneConnectionStatusArgs, 'where'>>;
  update_demiplane_connection_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneConnectionStatusByPkArgs, 'pk_columns'>>;
  update_demiplane_connection_type?: Resolver<Maybe<ResolversTypes['demiplane_connection_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneConnectionTypeArgs, 'where'>>;
  update_demiplane_connection_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneConnectionTypeByPkArgs, 'pk_columns'>>;
  update_demiplane_contact_type?: Resolver<Maybe<ResolversTypes['demiplane_contact_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneContactTypeArgs, 'where'>>;
  update_demiplane_contact_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_contact_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneContactTypeByPkArgs, 'pk_columns'>>;
  update_demiplane_country?: Resolver<Maybe<ResolversTypes['demiplane_country_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneCountryArgs, 'where'>>;
  update_demiplane_country_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_country']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneCountryByPkArgs, 'pk_columns'>>;
  update_demiplane_credit_card?: Resolver<Maybe<ResolversTypes['demiplane_credit_card_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneCreditCardArgs, 'where'>>;
  update_demiplane_credit_card_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneCreditCardByPkArgs, 'pk_columns'>>;
  update_demiplane_demiplane_tool?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneDemiplaneToolArgs, 'where'>>;
  update_demiplane_demiplane_tool_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneDemiplaneToolByPkArgs, 'pk_columns'>>;
  update_demiplane_episode_attendance?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneEpisodeAttendanceArgs, 'where'>>;
  update_demiplane_episode_attendance_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneEpisodeAttendanceByPkArgs, 'pk_columns'>>;
  update_demiplane_event?: Resolver<Maybe<ResolversTypes['demiplane_event_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneEventArgs, 'where'>>;
  update_demiplane_event_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_event']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneEventByPkArgs, 'pk_columns'>>;
  update_demiplane_event_type?: Resolver<Maybe<ResolversTypes['demiplane_event_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneEventTypeArgs, 'where'>>;
  update_demiplane_event_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_event_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneEventTypeByPkArgs, 'pk_columns'>>;
  update_demiplane_favorite_adventuring_platform?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneFavoriteAdventuringPlatformArgs, 'where'>>;
  update_demiplane_favorite_adventuring_platform_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneFavoriteAdventuringPlatformByPkArgs, 'pk_columns'>>;
  update_demiplane_favorite_dice_roll?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneFavoriteDiceRollArgs, 'where'>>;
  update_demiplane_favorite_dice_roll_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneFavoriteDiceRollByPkArgs, 'pk_columns'>>;
  update_demiplane_favorite_game?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneFavoriteGameArgs, 'where'>>;
  update_demiplane_favorite_game_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneFavoriteGameByPkArgs, 'pk_columns'>>;
  update_demiplane_game_type?: Resolver<Maybe<ResolversTypes['demiplane_game_type_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneGameTypeArgs, 'where'>>;
  update_demiplane_game_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_game_type']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneGameTypeByPkArgs, 'pk_columns'>>;
  update_demiplane_gm_rating?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneGmRatingArgs, 'where'>>;
  update_demiplane_gm_rating_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneGmRatingByPkArgs, 'pk_columns'>>;
  update_demiplane_gm_recommendation?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneGmRecommendationArgs, 'where'>>;
  update_demiplane_gm_recommendation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneGmRecommendationByPkArgs, 'pk_columns'>>;
  update_demiplane_invitation?: Resolver<Maybe<ResolversTypes['demiplane_invitation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneInvitationArgs, 'where'>>;
  update_demiplane_invitation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_invitation']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneInvitationByPkArgs, 'pk_columns'>>;
  update_demiplane_journal?: Resolver<Maybe<ResolversTypes['demiplane_journal_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalArgs, 'where'>>;
  update_demiplane_journal_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalByPkArgs, 'pk_columns'>>;
  update_demiplane_journal_note?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalNoteArgs, 'where'>>;
  update_demiplane_journal_note_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalNoteByPkArgs, 'pk_columns'>>;
  update_demiplane_journal_note_share?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalNoteShareArgs, 'where'>>;
  update_demiplane_journal_note_share_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalNoteShareByPkArgs, 'pk_columns'>>;
  update_demiplane_journal_super_event?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalSuperEventArgs, 'where'>>;
  update_demiplane_journal_super_event_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalSuperEventByPkArgs, 'pk_columns'>>;
  update_demiplane_journal_super_event_read_status?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalSuperEventReadStatusArgs, 'where'>>;
  update_demiplane_journal_super_event_read_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalSuperEventReadStatusByPkArgs, 'pk_columns'>>;
  update_demiplane_journal_title?: Resolver<Maybe<ResolversTypes['demiplane_journal_title_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalTitleArgs, 'where'>>;
  update_demiplane_journal_title_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneJournalTitleByPkArgs, 'pk_columns'>>;
  update_demiplane_matchmaking_token?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneMatchmakingTokenArgs, 'where'>>;
  update_demiplane_matchmaking_token_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneMatchmakingTokenByPkArgs, 'pk_columns'>>;
  update_demiplane_migrations?: Resolver<Maybe<ResolversTypes['demiplane_migrations_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneMigrationsArgs, 'where'>>;
  update_demiplane_migrations_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_migrations']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneMigrationsByPkArgs, 'pk_columns'>>;
  update_demiplane_notification_history?: Resolver<Maybe<ResolversTypes['demiplane_notification_history_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneNotificationHistoryArgs, 'where'>>;
  update_demiplane_notification_history_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneNotificationHistoryByPkArgs, 'pk_columns'>>;
  update_demiplane_payment?: Resolver<Maybe<ResolversTypes['demiplane_payment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePaymentArgs, 'where'>>;
  update_demiplane_payment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_payment']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePaymentByPkArgs, 'pk_columns'>>;
  update_demiplane_player_award?: Resolver<Maybe<ResolversTypes['demiplane_player_award_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePlayerAwardArgs, 'where'>>;
  update_demiplane_player_award_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePlayerAwardByPkArgs, 'pk_columns'>>;
  update_demiplane_player_rating?: Resolver<Maybe<ResolversTypes['demiplane_player_rating_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePlayerRatingArgs, 'where'>>;
  update_demiplane_player_rating_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePlayerRatingByPkArgs, 'pk_columns'>>;
  update_demiplane_pronoun?: Resolver<Maybe<ResolversTypes['demiplane_pronoun_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePronounArgs, 'where'>>;
  update_demiplane_pronoun_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplanePronounByPkArgs, 'pk_columns'>>;
  update_demiplane_region?: Resolver<Maybe<ResolversTypes['demiplane_region_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneRegionArgs, 'where'>>;
  update_demiplane_region_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_region']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneRegionByPkArgs, 'pk_columns'>>;
  update_demiplane_role?: Resolver<Maybe<ResolversTypes['demiplane_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneRoleArgs, 'where'>>;
  update_demiplane_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_role']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneRoleByPkArgs, 'pk_columns'>>;
  update_demiplane_session?: Resolver<Maybe<ResolversTypes['demiplane_session_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneSessionArgs, 'where'>>;
  update_demiplane_session_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_session']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneSessionByPkArgs, 'pk_columns'>>;
  update_demiplane_session_player?: Resolver<Maybe<ResolversTypes['demiplane_session_player_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneSessionPlayerArgs, 'where'>>;
  update_demiplane_session_player_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_session_player']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneSessionPlayerByPkArgs, 'pk_columns'>>;
  update_demiplane_snippet?: Resolver<Maybe<ResolversTypes['demiplane_snippet_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneSnippetArgs, 'where'>>;
  update_demiplane_snippet_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_snippet']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneSnippetByPkArgs, 'pk_columns'>>;
  update_demiplane_task?: Resolver<Maybe<ResolversTypes['demiplane_task_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTaskArgs, 'where'>>;
  update_demiplane_task_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTaskByPkArgs, 'pk_columns'>>;
  update_demiplane_task_read_status?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTaskReadStatusArgs, 'where'>>;
  update_demiplane_task_read_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTaskReadStatusByPkArgs, 'pk_columns'>>;
  update_demiplane_tax_nexus_region?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTaxNexusRegionArgs, 'where'>>;
  update_demiplane_tax_nexus_region_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTaxNexusRegionByPkArgs, 'pk_columns'>>;
  update_demiplane_transaction_receipt?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTransactionReceiptArgs, 'where'>>;
  update_demiplane_transaction_receipt_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneTransactionReceiptByPkArgs, 'pk_columns'>>;
  update_demiplane_upload?: Resolver<Maybe<ResolversTypes['demiplane_upload_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUploadArgs, 'where'>>;
  update_demiplane_upload_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_upload']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUploadByPkArgs, 'pk_columns'>>;
  update_demiplane_urap?: Resolver<Maybe<ResolversTypes['demiplane_urap_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUrapArgs, 'where'>>;
  update_demiplane_urap_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_urap']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUrapByPkArgs, 'pk_columns'>>;
  update_demiplane_urgt?: Resolver<Maybe<ResolversTypes['demiplane_urgt_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUrgtArgs, 'where'>>;
  update_demiplane_urgt_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_urgt']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUrgtByPkArgs, 'pk_columns'>>;
  update_demiplane_user?: Resolver<Maybe<ResolversTypes['demiplane_user_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserArgs, 'where'>>;
  update_demiplane_user_activity?: Resolver<Maybe<ResolversTypes['demiplane_user_activity_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserActivityArgs, 'where'>>;
  update_demiplane_user_activity_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserActivityByPkArgs, 'pk_columns'>>;
  update_demiplane_user_adventure?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserAdventureArgs, 'where'>>;
  update_demiplane_user_adventure_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserAdventureByPkArgs, 'pk_columns'>>;
  update_demiplane_user_adventure_ranking?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserAdventureRankingArgs, 'where'>>;
  update_demiplane_user_adventure_ranking_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserAdventureRankingByPkArgs, 'pk_columns'>>;
  update_demiplane_user_adventure_role?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserAdventureRoleArgs, 'where'>>;
  update_demiplane_user_adventure_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserAdventureRoleByPkArgs, 'pk_columns'>>;
  update_demiplane_user_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserByPkArgs, 'pk_columns'>>;
  update_demiplane_user_email_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserEmailNotificationPrefArgs, 'where'>>;
  update_demiplane_user_email_notification_pref_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserEmailNotificationPrefByPkArgs, 'pk_columns'>>;
  update_demiplane_user_invitation?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserInvitationArgs, 'where'>>;
  update_demiplane_user_invitation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserInvitationByPkArgs, 'pk_columns'>>;
  update_demiplane_user_metadata?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserMetadataArgs, 'where'>>;
  update_demiplane_user_metadata_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserMetadataByPkArgs, 'pk_columns'>>;
  update_demiplane_user_push_notification_pref?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserPushNotificationPrefArgs, 'where'>>;
  update_demiplane_user_push_notification_pref_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserPushNotificationPrefByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentArgs, 'where'>>;
  update_demiplane_user_recruitment_adventurer?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerArgs, 'where'>>;
  update_demiplane_user_recruitment_adventurer_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerAvailabilityArgs, 'where'>>;
  update_demiplane_user_recruitment_adventurer_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_adventurer_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_adventurer_games?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerGamesArgs, 'where'>>;
  update_demiplane_user_recruitment_adventurer_games_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerGamesByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_adventurer_platforms?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerPlatformsArgs, 'where'>>;
  update_demiplane_user_recruitment_adventurer_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAvailabilityArgs, 'where'>>;
  update_demiplane_user_recruitment_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentAvailabilityByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_game_master?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterArgs, 'where'>>;
  update_demiplane_user_recruitment_game_master_availability?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterAvailabilityArgs, 'where'>>;
  update_demiplane_user_recruitment_game_master_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_game_master_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_game_master_games?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterGamesArgs, 'where'>>;
  update_demiplane_user_recruitment_game_master_games_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterGamesByPkArgs, 'pk_columns'>>;
  update_demiplane_user_recruitment_game_master_platforms?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterPlatformsArgs, 'where'>>;
  update_demiplane_user_recruitment_game_master_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs, 'pk_columns'>>;
  update_demiplane_user_role?: Resolver<Maybe<ResolversTypes['demiplane_user_role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRoleArgs, 'where'>>;
  update_demiplane_user_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_role']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserRoleByPkArgs, 'pk_columns'>>;
  update_demiplane_user_visibility?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserVisibilityArgs, 'where'>>;
  update_demiplane_user_visibility_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility']>, ParentType, ContextType, RequireFields<MutationRootUpdateDemiplaneUserVisibilityByPkArgs, 'pk_columns'>>;
};

export interface NumericScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type QueryRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']> = {
  aboutUser?: Resolver<Maybe<ResolversTypes['AboutUserOutput']>, ParentType, ContextType, RequireFields<QueryRootAboutUserArgs, 'userId'>>;
  adDetail?: Resolver<Maybe<ResolversTypes['AdDetailOutput']>, ParentType, ContextType, RequireFields<QueryRootAdDetailArgs, 'adId'>>;
  adventure?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<QueryRootAdventureArgs, 'id'>>;
  adventureActives?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  adventureDetails?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<QueryRootAdventureDetailsArgs, 'adventureId'>>;
  adventureHosts?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  adventuringPlatforms?: Resolver<Array<ResolversTypes['AdventuringPlatformType']>, ParentType, ContextType>;
  almostFullAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  appVersion?: Resolver<ResolversTypes['AppVersionType'], ParentType, ContextType>;
  chatToken?: Resolver<ResolversTypes['ChatTokenType'], ParentType, ContextType>;
  conversationParticipants?: Resolver<ResolversTypes['ConversationParticipantsType'], ParentType, ContextType, RequireFields<QueryRootConversationParticipantsArgs, 'chatHash'>>;
  creditCards?: Resolver<Array<ResolversTypes['CreditCardType']>, ParentType, ContextType>;
  currentUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  demicards?: Resolver<Array<ResolversTypes['AttachmentType']>, ParentType, ContextType>;
  demiplane_ad?: Resolver<Array<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdArgs, never>>;
  demiplane_ad_aggregate?: Resolver<ResolversTypes['demiplane_ad_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdAggregateArgs, never>>;
  demiplane_ad_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdByPkArgs, 'id'>>;
  demiplane_ad_link?: Resolver<Array<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdLinkArgs, never>>;
  demiplane_ad_link_aggregate?: Resolver<ResolversTypes['demiplane_ad_link_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdLinkAggregateArgs, never>>;
  demiplane_ad_link_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_link']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdLinkByPkArgs, 'id'>>;
  demiplane_ad_match_criteria?: Resolver<Array<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdMatchCriteriaArgs, never>>;
  demiplane_ad_match_criteria_aggregate?: Resolver<ResolversTypes['demiplane_ad_match_criteria_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdMatchCriteriaAggregateArgs, never>>;
  demiplane_ad_match_criteria_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdMatchCriteriaByPkArgs, 'id'>>;
  demiplane_ad_match_criteria_time?: Resolver<Array<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdMatchCriteriaTimeArgs, never>>;
  demiplane_ad_match_criteria_time_aggregate?: Resolver<ResolversTypes['demiplane_ad_match_criteria_time_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdMatchCriteriaTimeAggregateArgs, never>>;
  demiplane_ad_match_criteria_time_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdMatchCriteriaTimeByPkArgs, 'id'>>;
  demiplane_ad_request?: Resolver<Array<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdRequestArgs, never>>;
  demiplane_ad_request_aggregate?: Resolver<ResolversTypes['demiplane_ad_request_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdRequestAggregateArgs, never>>;
  demiplane_ad_request_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_request']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdRequestByPkArgs, 'id'>>;
  demiplane_ad_time?: Resolver<Array<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdTimeArgs, never>>;
  demiplane_ad_time_aggregate?: Resolver<ResolversTypes['demiplane_ad_time_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdTimeAggregateArgs, never>>;
  demiplane_ad_time_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_ad_time']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdTimeByPkArgs, 'id'>>;
  demiplane_adventure?: Resolver<Array<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureArgs, never>>;
  demiplane_adventure_aggregate?: Resolver<ResolversTypes['demiplane_adventure_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureAggregateArgs, never>>;
  demiplane_adventure_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureByPkArgs, 'id'>>;
  demiplane_adventure_connection?: Resolver<Array<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureConnectionArgs, never>>;
  demiplane_adventure_connection_aggregate?: Resolver<ResolversTypes['demiplane_adventure_connection_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureConnectionAggregateArgs, never>>;
  demiplane_adventure_connection_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_connection']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureConnectionByPkArgs, 'id'>>;
  demiplane_adventure_demiplane_tool?: Resolver<Array<ResolversTypes['demiplane_adventure_demiplane_tool']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureDemiplaneToolArgs, never>>;
  demiplane_adventure_demiplane_tool_aggregate?: Resolver<ResolversTypes['demiplane_adventure_demiplane_tool_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureDemiplaneToolAggregateArgs, never>>;
  demiplane_adventure_demiplane_tool_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureDemiplaneToolByPkArgs, 'id'>>;
  demiplane_adventure_link?: Resolver<Array<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureLinkArgs, never>>;
  demiplane_adventure_link_aggregate?: Resolver<ResolversTypes['demiplane_adventure_link_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureLinkAggregateArgs, never>>;
  demiplane_adventure_link_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_link']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureLinkByPkArgs, 'id'>>;
  demiplane_adventure_player?: Resolver<Array<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventurePlayerArgs, never>>;
  demiplane_adventure_player_aggregate?: Resolver<ResolversTypes['demiplane_adventure_player_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventurePlayerAggregateArgs, never>>;
  demiplane_adventure_player_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_player']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventurePlayerByPkArgs, 'id'>>;
  demiplane_adventure_todo?: Resolver<Array<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureTodoArgs, never>>;
  demiplane_adventure_todo_aggregate?: Resolver<ResolversTypes['demiplane_adventure_todo_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureTodoAggregateArgs, never>>;
  demiplane_adventure_todo_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventure_todo']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventureTodoByPkArgs, 'id'>>;
  demiplane_adventuring_platform?: Resolver<Array<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventuringPlatformArgs, never>>;
  demiplane_adventuring_platform_aggregate?: Resolver<ResolversTypes['demiplane_adventuring_platform_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventuringPlatformAggregateArgs, never>>;
  demiplane_adventuring_platform_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_adventuring_platform']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAdventuringPlatformByPkArgs, 'id'>>;
  demiplane_amc_adventuring_platforms?: Resolver<Array<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAmcAdventuringPlatformsArgs, never>>;
  demiplane_amc_adventuring_platforms_aggregate?: Resolver<ResolversTypes['demiplane_amc_adventuring_platforms_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAmcAdventuringPlatformsAggregateArgs, never>>;
  demiplane_amc_adventuring_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAmcAdventuringPlatformsByPkArgs, 'adMatchCriteriaId' | 'adventuringPlatformId'>>;
  demiplane_amc_game_types?: Resolver<Array<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAmcGameTypesArgs, never>>;
  demiplane_amc_game_types_aggregate?: Resolver<ResolversTypes['demiplane_amc_game_types_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAmcGameTypesAggregateArgs, never>>;
  demiplane_amc_game_types_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_amc_game_types']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAmcGameTypesByPkArgs, 'adMatchCriteriaId' | 'gameTypeId'>>;
  demiplane_app_version?: Resolver<Array<ResolversTypes['demiplane_app_version']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAppVersionArgs, never>>;
  demiplane_app_version_aggregate?: Resolver<ResolversTypes['demiplane_app_version_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAppVersionAggregateArgs, never>>;
  demiplane_app_version_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_app_version']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAppVersionByPkArgs, 'id'>>;
  demiplane_attachment?: Resolver<Array<ResolversTypes['demiplane_attachment']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAttachmentArgs, never>>;
  demiplane_attachment_aggregate?: Resolver<ResolversTypes['demiplane_attachment_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAttachmentAggregateArgs, never>>;
  demiplane_attachment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_attachment']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAttachmentByPkArgs, 'id'>>;
  demiplane_award_type?: Resolver<Array<ResolversTypes['demiplane_award_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAwardTypeArgs, never>>;
  demiplane_award_type_aggregate?: Resolver<ResolversTypes['demiplane_award_type_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneAwardTypeAggregateArgs, never>>;
  demiplane_award_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_award_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneAwardTypeByPkArgs, 'id'>>;
  demiplane_connection_status?: Resolver<Array<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneConnectionStatusArgs, never>>;
  demiplane_connection_status_aggregate?: Resolver<ResolversTypes['demiplane_connection_status_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneConnectionStatusAggregateArgs, never>>;
  demiplane_connection_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_connection_status']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneConnectionStatusByPkArgs, 'id'>>;
  demiplane_connection_type?: Resolver<Array<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneConnectionTypeArgs, never>>;
  demiplane_connection_type_aggregate?: Resolver<ResolversTypes['demiplane_connection_type_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneConnectionTypeAggregateArgs, never>>;
  demiplane_connection_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_connection_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneConnectionTypeByPkArgs, 'id'>>;
  demiplane_contact_type?: Resolver<Array<ResolversTypes['demiplane_contact_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneContactTypeArgs, never>>;
  demiplane_contact_type_aggregate?: Resolver<ResolversTypes['demiplane_contact_type_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneContactTypeAggregateArgs, never>>;
  demiplane_contact_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_contact_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneContactTypeByPkArgs, 'id'>>;
  demiplane_country?: Resolver<Array<ResolversTypes['demiplane_country']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneCountryArgs, never>>;
  demiplane_country_aggregate?: Resolver<ResolversTypes['demiplane_country_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneCountryAggregateArgs, never>>;
  demiplane_country_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_country']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneCountryByPkArgs, 'id'>>;
  demiplane_credit_card?: Resolver<Array<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneCreditCardArgs, never>>;
  demiplane_credit_card_aggregate?: Resolver<ResolversTypes['demiplane_credit_card_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneCreditCardAggregateArgs, never>>;
  demiplane_credit_card_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_credit_card']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneCreditCardByPkArgs, 'id'>>;
  demiplane_demiplane_tool?: Resolver<Array<ResolversTypes['demiplane_demiplane_tool']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneDemiplaneToolArgs, never>>;
  demiplane_demiplane_tool_aggregate?: Resolver<ResolversTypes['demiplane_demiplane_tool_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneDemiplaneToolAggregateArgs, never>>;
  demiplane_demiplane_tool_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_demiplane_tool']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneDemiplaneToolByPkArgs, 'id'>>;
  demiplane_episode_attendance?: Resolver<Array<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneEpisodeAttendanceArgs, never>>;
  demiplane_episode_attendance_aggregate?: Resolver<ResolversTypes['demiplane_episode_attendance_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneEpisodeAttendanceAggregateArgs, never>>;
  demiplane_episode_attendance_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_episode_attendance']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneEpisodeAttendanceByPkArgs, 'id'>>;
  demiplane_event?: Resolver<Array<ResolversTypes['demiplane_event']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneEventArgs, never>>;
  demiplane_event_aggregate?: Resolver<ResolversTypes['demiplane_event_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneEventAggregateArgs, never>>;
  demiplane_event_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_event']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneEventByPkArgs, 'id'>>;
  demiplane_event_type?: Resolver<Array<ResolversTypes['demiplane_event_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneEventTypeArgs, never>>;
  demiplane_event_type_aggregate?: Resolver<ResolversTypes['demiplane_event_type_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneEventTypeAggregateArgs, never>>;
  demiplane_event_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_event_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneEventTypeByPkArgs, 'id'>>;
  demiplane_favorite_adventuring_platform?: Resolver<Array<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteAdventuringPlatformArgs, never>>;
  demiplane_favorite_adventuring_platform_aggregate?: Resolver<ResolversTypes['demiplane_favorite_adventuring_platform_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteAdventuringPlatformAggregateArgs, never>>;
  demiplane_favorite_adventuring_platform_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteAdventuringPlatformByPkArgs, 'id'>>;
  demiplane_favorite_dice_roll?: Resolver<Array<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteDiceRollArgs, never>>;
  demiplane_favorite_dice_roll_aggregate?: Resolver<ResolversTypes['demiplane_favorite_dice_roll_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteDiceRollAggregateArgs, never>>;
  demiplane_favorite_dice_roll_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteDiceRollByPkArgs, 'id'>>;
  demiplane_favorite_game?: Resolver<Array<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteGameArgs, never>>;
  demiplane_favorite_game_aggregate?: Resolver<ResolversTypes['demiplane_favorite_game_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteGameAggregateArgs, never>>;
  demiplane_favorite_game_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_favorite_game']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneFavoriteGameByPkArgs, 'id'>>;
  demiplane_game_type?: Resolver<Array<ResolversTypes['demiplane_game_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneGameTypeArgs, never>>;
  demiplane_game_type_aggregate?: Resolver<ResolversTypes['demiplane_game_type_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneGameTypeAggregateArgs, never>>;
  demiplane_game_type_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_game_type']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneGameTypeByPkArgs, 'id'>>;
  demiplane_gm_rating?: Resolver<Array<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneGmRatingArgs, never>>;
  demiplane_gm_rating_aggregate?: Resolver<ResolversTypes['demiplane_gm_rating_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneGmRatingAggregateArgs, never>>;
  demiplane_gm_rating_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_gm_rating']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneGmRatingByPkArgs, 'id'>>;
  demiplane_gm_recommendation?: Resolver<Array<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneGmRecommendationArgs, never>>;
  demiplane_gm_recommendation_aggregate?: Resolver<ResolversTypes['demiplane_gm_recommendation_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneGmRecommendationAggregateArgs, never>>;
  demiplane_gm_recommendation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_gm_recommendation']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneGmRecommendationByPkArgs, 'id'>>;
  demiplane_invitation?: Resolver<Array<ResolversTypes['demiplane_invitation']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneInvitationArgs, never>>;
  demiplane_invitation_aggregate?: Resolver<ResolversTypes['demiplane_invitation_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneInvitationAggregateArgs, never>>;
  demiplane_invitation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_invitation']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneInvitationByPkArgs, 'id'>>;
  demiplane_journal?: Resolver<Array<ResolversTypes['demiplane_journal']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalArgs, never>>;
  demiplane_journal_aggregate?: Resolver<ResolversTypes['demiplane_journal_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalAggregateArgs, never>>;
  demiplane_journal_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalByPkArgs, 'id'>>;
  demiplane_journal_note?: Resolver<Array<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalNoteArgs, never>>;
  demiplane_journal_note_aggregate?: Resolver<ResolversTypes['demiplane_journal_note_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalNoteAggregateArgs, never>>;
  demiplane_journal_note_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_note']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalNoteByPkArgs, 'id'>>;
  demiplane_journal_note_share?: Resolver<Array<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalNoteShareArgs, never>>;
  demiplane_journal_note_share_aggregate?: Resolver<ResolversTypes['demiplane_journal_note_share_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalNoteShareAggregateArgs, never>>;
  demiplane_journal_note_share_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_note_share']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalNoteShareByPkArgs, 'id'>>;
  demiplane_journal_super_event?: Resolver<Array<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalSuperEventArgs, never>>;
  demiplane_journal_super_event_aggregate?: Resolver<ResolversTypes['demiplane_journal_super_event_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalSuperEventAggregateArgs, never>>;
  demiplane_journal_super_event_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalSuperEventByPkArgs, 'id'>>;
  demiplane_journal_super_event_read_status?: Resolver<Array<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalSuperEventReadStatusArgs, never>>;
  demiplane_journal_super_event_read_status_aggregate?: Resolver<ResolversTypes['demiplane_journal_super_event_read_status_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalSuperEventReadStatusAggregateArgs, never>>;
  demiplane_journal_super_event_read_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalSuperEventReadStatusByPkArgs, 'id'>>;
  demiplane_journal_title?: Resolver<Array<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalTitleArgs, never>>;
  demiplane_journal_title_aggregate?: Resolver<ResolversTypes['demiplane_journal_title_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalTitleAggregateArgs, never>>;
  demiplane_journal_title_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_journal_title']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneJournalTitleByPkArgs, 'id'>>;
  demiplane_matchmaking_token?: Resolver<Array<ResolversTypes['demiplane_matchmaking_token']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneMatchmakingTokenArgs, never>>;
  demiplane_matchmaking_token_aggregate?: Resolver<ResolversTypes['demiplane_matchmaking_token_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneMatchmakingTokenAggregateArgs, never>>;
  demiplane_matchmaking_token_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_matchmaking_token']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneMatchmakingTokenByPkArgs, 'id'>>;
  demiplane_migrations?: Resolver<Array<ResolversTypes['demiplane_migrations']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneMigrationsArgs, never>>;
  demiplane_migrations_aggregate?: Resolver<ResolversTypes['demiplane_migrations_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneMigrationsAggregateArgs, never>>;
  demiplane_migrations_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_migrations']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneMigrationsByPkArgs, 'id'>>;
  demiplane_notification_history?: Resolver<Array<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneNotificationHistoryArgs, never>>;
  demiplane_notification_history_aggregate?: Resolver<ResolversTypes['demiplane_notification_history_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneNotificationHistoryAggregateArgs, never>>;
  demiplane_notification_history_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_notification_history']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneNotificationHistoryByPkArgs, 'id'>>;
  demiplane_payment?: Resolver<Array<ResolversTypes['demiplane_payment']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePaymentArgs, never>>;
  demiplane_payment_aggregate?: Resolver<ResolversTypes['demiplane_payment_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplanePaymentAggregateArgs, never>>;
  demiplane_payment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_payment']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePaymentByPkArgs, 'id'>>;
  demiplane_player_award?: Resolver<Array<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePlayerAwardArgs, never>>;
  demiplane_player_award_aggregate?: Resolver<ResolversTypes['demiplane_player_award_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplanePlayerAwardAggregateArgs, never>>;
  demiplane_player_award_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_player_award']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePlayerAwardByPkArgs, 'id'>>;
  demiplane_player_rating?: Resolver<Array<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePlayerRatingArgs, never>>;
  demiplane_player_rating_aggregate?: Resolver<ResolversTypes['demiplane_player_rating_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplanePlayerRatingAggregateArgs, never>>;
  demiplane_player_rating_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_player_rating']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePlayerRatingByPkArgs, 'id'>>;
  demiplane_pronoun?: Resolver<Array<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePronounArgs, never>>;
  demiplane_pronoun_aggregate?: Resolver<ResolversTypes['demiplane_pronoun_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplanePronounAggregateArgs, never>>;
  demiplane_pronoun_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_pronoun']>, ParentType, ContextType, RequireFields<QueryRootDemiplanePronounByPkArgs, 'id'>>;
  demiplane_region?: Resolver<Array<ResolversTypes['demiplane_region']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneRegionArgs, never>>;
  demiplane_region_aggregate?: Resolver<ResolversTypes['demiplane_region_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneRegionAggregateArgs, never>>;
  demiplane_region_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_region']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneRegionByPkArgs, 'id'>>;
  demiplane_role?: Resolver<Array<ResolversTypes['demiplane_role']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneRoleArgs, never>>;
  demiplane_role_aggregate?: Resolver<ResolversTypes['demiplane_role_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneRoleAggregateArgs, never>>;
  demiplane_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_role']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneRoleByPkArgs, 'id'>>;
  demiplane_session?: Resolver<Array<ResolversTypes['demiplane_session']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneSessionArgs, never>>;
  demiplane_session_aggregate?: Resolver<ResolversTypes['demiplane_session_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneSessionAggregateArgs, never>>;
  demiplane_session_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_session']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneSessionByPkArgs, 'id'>>;
  demiplane_session_player?: Resolver<Array<ResolversTypes['demiplane_session_player']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneSessionPlayerArgs, never>>;
  demiplane_session_player_aggregate?: Resolver<ResolversTypes['demiplane_session_player_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneSessionPlayerAggregateArgs, never>>;
  demiplane_session_player_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_session_player']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneSessionPlayerByPkArgs, 'id'>>;
  demiplane_snippet?: Resolver<Array<ResolversTypes['demiplane_snippet']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneSnippetArgs, never>>;
  demiplane_snippet_aggregate?: Resolver<ResolversTypes['demiplane_snippet_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneSnippetAggregateArgs, never>>;
  demiplane_snippet_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_snippet']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneSnippetByPkArgs, 'id'>>;
  demiplane_task?: Resolver<Array<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTaskArgs, never>>;
  demiplane_task_aggregate?: Resolver<ResolversTypes['demiplane_task_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneTaskAggregateArgs, never>>;
  demiplane_task_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_task']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTaskByPkArgs, 'id'>>;
  demiplane_task_read_status?: Resolver<Array<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTaskReadStatusArgs, never>>;
  demiplane_task_read_status_aggregate?: Resolver<ResolversTypes['demiplane_task_read_status_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneTaskReadStatusAggregateArgs, never>>;
  demiplane_task_read_status_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_task_read_status']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTaskReadStatusByPkArgs, 'id'>>;
  demiplane_tax_nexus_region?: Resolver<Array<ResolversTypes['demiplane_tax_nexus_region']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTaxNexusRegionArgs, never>>;
  demiplane_tax_nexus_region_aggregate?: Resolver<ResolversTypes['demiplane_tax_nexus_region_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneTaxNexusRegionAggregateArgs, never>>;
  demiplane_tax_nexus_region_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_tax_nexus_region']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTaxNexusRegionByPkArgs, 'id'>>;
  demiplane_transaction_receipt?: Resolver<Array<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTransactionReceiptArgs, never>>;
  demiplane_transaction_receipt_aggregate?: Resolver<ResolversTypes['demiplane_transaction_receipt_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneTransactionReceiptAggregateArgs, never>>;
  demiplane_transaction_receipt_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_transaction_receipt']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneTransactionReceiptByPkArgs, 'id'>>;
  demiplane_upload?: Resolver<Array<ResolversTypes['demiplane_upload']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUploadArgs, never>>;
  demiplane_upload_aggregate?: Resolver<ResolversTypes['demiplane_upload_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUploadAggregateArgs, never>>;
  demiplane_upload_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_upload']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUploadByPkArgs, 'id'>>;
  demiplane_urap?: Resolver<Array<ResolversTypes['demiplane_urap']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUrapArgs, never>>;
  demiplane_urap_aggregate?: Resolver<ResolversTypes['demiplane_urap_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUrapAggregateArgs, never>>;
  demiplane_urap_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_urap']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUrapByPkArgs, 'adventuringPlatformId' | 'userRecruitmentId'>>;
  demiplane_urgt?: Resolver<Array<ResolversTypes['demiplane_urgt']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUrgtArgs, never>>;
  demiplane_urgt_aggregate?: Resolver<ResolversTypes['demiplane_urgt_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUrgtAggregateArgs, never>>;
  demiplane_urgt_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_urgt']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUrgtByPkArgs, 'gameTypeId' | 'userRecruitmentId'>>;
  demiplane_user?: Resolver<Array<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserArgs, never>>;
  demiplane_user_activity?: Resolver<Array<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserActivityArgs, never>>;
  demiplane_user_activity_aggregate?: Resolver<ResolversTypes['demiplane_user_activity_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserActivityAggregateArgs, never>>;
  demiplane_user_activity_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_activity']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserActivityByPkArgs, 'id'>>;
  demiplane_user_adventure?: Resolver<Array<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureArgs, never>>;
  demiplane_user_adventure_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureAggregateArgs, never>>;
  demiplane_user_adventure_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureByPkArgs, 'id'>>;
  demiplane_user_adventure_ranking?: Resolver<Array<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureRankingArgs, never>>;
  demiplane_user_adventure_ranking_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_ranking_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureRankingAggregateArgs, never>>;
  demiplane_user_adventure_ranking_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureRankingByPkArgs, 'id'>>;
  demiplane_user_adventure_role?: Resolver<Array<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureRoleArgs, never>>;
  demiplane_user_adventure_role_aggregate?: Resolver<ResolversTypes['demiplane_user_adventure_role_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureRoleAggregateArgs, never>>;
  demiplane_user_adventure_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_adventure_role']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAdventureRoleByPkArgs, 'id'>>;
  demiplane_user_aggregate?: Resolver<ResolversTypes['demiplane_user_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserAggregateArgs, never>>;
  demiplane_user_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserByPkArgs, 'id'>>;
  demiplane_user_email_notification_pref?: Resolver<Array<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserEmailNotificationPrefArgs, never>>;
  demiplane_user_email_notification_pref_aggregate?: Resolver<ResolversTypes['demiplane_user_email_notification_pref_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserEmailNotificationPrefAggregateArgs, never>>;
  demiplane_user_email_notification_pref_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserEmailNotificationPrefByPkArgs, 'id'>>;
  demiplane_user_invitation?: Resolver<Array<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserInvitationArgs, never>>;
  demiplane_user_invitation_aggregate?: Resolver<ResolversTypes['demiplane_user_invitation_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserInvitationAggregateArgs, never>>;
  demiplane_user_invitation_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_invitation']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserInvitationByPkArgs, 'id'>>;
  demiplane_user_metadata?: Resolver<Array<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserMetadataArgs, never>>;
  demiplane_user_metadata_aggregate?: Resolver<ResolversTypes['demiplane_user_metadata_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserMetadataAggregateArgs, never>>;
  demiplane_user_metadata_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_metadata']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserMetadataByPkArgs, 'id'>>;
  demiplane_user_push_notification_pref?: Resolver<Array<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserPushNotificationPrefArgs, never>>;
  demiplane_user_push_notification_pref_aggregate?: Resolver<ResolversTypes['demiplane_user_push_notification_pref_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserPushNotificationPrefAggregateArgs, never>>;
  demiplane_user_push_notification_pref_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserPushNotificationPrefByPkArgs, 'id'>>;
  demiplane_user_recruitment?: Resolver<Array<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentArgs, never>>;
  demiplane_user_recruitment_adventurer?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerArgs, never>>;
  demiplane_user_recruitment_adventurer_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_availability?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerAvailabilityArgs, never>>;
  demiplane_user_recruitment_adventurer_availability_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_availability_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerAvailabilityAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs, 'id'>>;
  demiplane_user_recruitment_adventurer_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerByPkArgs, 'id'>>;
  demiplane_user_recruitment_adventurer_games?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerGamesArgs, never>>;
  demiplane_user_recruitment_adventurer_games_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_games_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerGamesAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_games_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerGamesByPkArgs, 'game_type_id' | 'user_recruitment_adventurer_id'>>;
  demiplane_user_recruitment_adventurer_platforms?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerPlatformsArgs, never>>;
  demiplane_user_recruitment_adventurer_platforms_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerPlatformsAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs, 'adventuring_platform_id' | 'user_recruitment_adventurer_id'>>;
  demiplane_user_recruitment_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAggregateArgs, never>>;
  demiplane_user_recruitment_availability?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAvailabilityArgs, never>>;
  demiplane_user_recruitment_availability_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_availability_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAvailabilityAggregateArgs, never>>;
  demiplane_user_recruitment_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentAvailabilityByPkArgs, 'id'>>;
  demiplane_user_recruitment_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentByPkArgs, 'id'>>;
  demiplane_user_recruitment_game_master?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterArgs, never>>;
  demiplane_user_recruitment_game_master_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_availability?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterAvailabilityArgs, never>>;
  demiplane_user_recruitment_game_master_availability_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_availability_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterAvailabilityAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_availability_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs, 'id'>>;
  demiplane_user_recruitment_game_master_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterByPkArgs, 'id'>>;
  demiplane_user_recruitment_game_master_games?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterGamesArgs, never>>;
  demiplane_user_recruitment_game_master_games_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_games_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterGamesAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_games_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterGamesByPkArgs, 'game_type_id' | 'user_recruitment_game_master_id'>>;
  demiplane_user_recruitment_game_master_platforms?: Resolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterPlatformsArgs, never>>;
  demiplane_user_recruitment_game_master_platforms_aggregate?: Resolver<ResolversTypes['demiplane_user_recruitment_game_master_platforms_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterPlatformsAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_platforms_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs, 'adventuring_platform_id' | 'user_recruitment_game_master_id'>>;
  demiplane_user_role?: Resolver<Array<ResolversTypes['demiplane_user_role']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRoleArgs, never>>;
  demiplane_user_role_aggregate?: Resolver<ResolversTypes['demiplane_user_role_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRoleAggregateArgs, never>>;
  demiplane_user_role_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_role']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserRoleByPkArgs, 'roleId' | 'userId'>>;
  demiplane_user_visibility?: Resolver<Array<ResolversTypes['demiplane_user_visibility']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserVisibilityArgs, never>>;
  demiplane_user_visibility_aggregate?: Resolver<ResolversTypes['demiplane_user_visibility_aggregate'], ParentType, ContextType, RequireFields<QueryRootDemiplaneUserVisibilityAggregateArgs, never>>;
  demiplane_user_visibility_by_pk?: Resolver<Maybe<ResolversTypes['demiplane_user_visibility']>, ParentType, ContextType, RequireFields<QueryRootDemiplaneUserVisibilityByPkArgs, 'id'>>;
  earnings?: Resolver<ResolversTypes['EarningsType'], ParentType, ContextType>;
  emailExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryRootEmailExistsArgs, 'email'>>;
  favoriteDiceRolls?: Resolver<Array<ResolversTypes['FavoriteDiceRollType']>, ParentType, ContextType, RequireFields<QueryRootFavoriteDiceRollsArgs, 'adventureId'>>;
  featuredGmRecommendationReviews?: Resolver<Maybe<ResolversTypes['FeaturedGmRecommendationReviewsOutput']>, ParentType, ContextType, RequireFields<QueryRootFeaturedGmRecommendationReviewsArgs, 'userId'>>;
  freeAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  gameTypes?: Resolver<Array<ResolversTypes['GameTypeType']>, ParentType, ContextType>;
  getAdventureByLinkCode?: Resolver<ResolversTypes['AdventureType'], ParentType, ContextType, RequireFields<QueryRootGetAdventureByLinkCodeArgs, 'code'>>;
  getAdventureRecruits?: Resolver<Array<ResolversTypes['RecruitType']>, ParentType, ContextType, RequireFields<QueryRootGetAdventureRecruitsArgs, 'adventureId' | 'displayCount' | 'excludeRecruited' | 'recruitForGameMaster'>>;
  getQuestRecruitSummary?: Resolver<ResolversTypes['RecruitSummaryType'], ParentType, ContextType, RequireFields<QueryRootGetQuestRecruitSummaryArgs, 'questId'>>;
  howUserPlays?: Resolver<Maybe<ResolversTypes['HowUserPlaysOutput']>, ParentType, ContextType, RequireFields<QueryRootHowUserPlaysArgs, 'userId'>>;
  joinAdventuresAds?: Resolver<Maybe<ResolversTypes['JoinAdventuresAdsOutput']>, ParentType, ContextType, RequireFields<QueryRootJoinAdventuresAdsArgs, 'adUserId'>>;
  journalNote?: Resolver<ResolversTypes['JournalNoteType'], ParentType, ContextType, RequireFields<QueryRootJournalNoteArgs, 'adventureId' | 'id' | 'isShared'>>;
  myAdventures?: Resolver<ResolversTypes['MyAdventuresType'], ParentType, ContextType>;
  newestAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  notificationHistory?: Resolver<Array<ResolversTypes['NotificationHistoryType']>, ParentType, ContextType, RequireFields<QueryRootNotificationHistoryArgs, 'limit'>>;
  otherAdventuresAds?: Resolver<Maybe<ResolversTypes['OtherAdventuresAdsOutput']>, ParentType, ContextType, RequireFields<QueryRootOtherAdventuresAdsArgs, never>>;
  paymentAdventureHosts?: Resolver<Array<ResolversTypes['AdventureType']>, ParentType, ContextType>;
  paymentsByUserSession?: Resolver<Array<ResolversTypes['PaymentType']>, ParentType, ContextType, RequireFields<QueryRootPaymentsByUserSessionArgs, 'sessionId' | 'userId'>>;
  playingSoonAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  recruitAdventurers?: Resolver<Maybe<ResolversTypes['RecruitAdventurersOutput']>, ParentType, ContextType, RequireFields<QueryRootRecruitAdventurersArgs, 'displayCount' | 'excludeRecruited' | 'portalId'>>;
  recruitGameMasters?: Resolver<Maybe<ResolversTypes['RecruitGameMastersOutput']>, ParentType, ContextType, RequireFields<QueryRootRecruitGameMastersArgs, 'displayCount' | 'excludeRecruited' | 'portalId'>>;
  regionsByCountryAbbreviation?: Resolver<Array<ResolversTypes['RegionType']>, ParentType, ContextType, RequireFields<QueryRootRegionsByCountryAbbreviationArgs, 'countryCode'>>;
  searchAdDetail?: Resolver<ResolversTypes['SearchAdResultDetailType'], ParentType, ContextType, RequireFields<QueryRootSearchAdDetailArgs, 'adId'>>;
  searchAdDetailMM?: Resolver<Maybe<ResolversTypes['SearchAdDetailMMOutput']>, ParentType, ContextType, RequireFields<QueryRootSearchAdDetailMmArgs, 'adId'>>;
  searchAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType, RequireFields<QueryRootSearchAdsArgs, 'adventuringPlatformIds' | 'availability' | 'frequencyOneTime' | 'frequencyRecurring' | 'gameTypeIds' | 'newPlayer' | 'noCost' | 'quickMatch' | 'recordCount'>>;
  searchAdsMM?: Resolver<Maybe<ResolversTypes['SearchAdsMMOutput']>, ParentType, ContextType, RequireFields<QueryRootSearchAdsMmArgs, 'adventuringPlatformIds' | 'availability' | 'frequencyOneTime' | 'frequencyRecurring' | 'gameTypeIds' | 'newPlayer' | 'noCost' | 'quickMatch' | 'recordCount'>>;
  session?: Resolver<ResolversTypes['SessionType'], ParentType, ContextType, RequireFields<QueryRootSessionArgs, 'id'>>;
  sessionHistories?: Resolver<Array<ResolversTypes['SessionHistoryType']>, ParentType, ContextType>;
  snippet?: Resolver<ResolversTypes['SnippetType'], ParentType, ContextType, RequireFields<QueryRootSnippetArgs, 'name'>>;
  stripeConnectUrl?: Resolver<ResolversTypes['StripeLoginLinkType'], ParentType, ContextType>;
  stripeUserBalance?: Resolver<ResolversTypes['StripeUserBalanceType'], ParentType, ContextType>;
  stripeUserBalanceTransactions?: Resolver<Array<ResolversTypes['StripeBalanceTransactionType']>, ParentType, ContextType, RequireFields<QueryRootStripeUserBalanceTransactionsArgs, never>>;
  suggestedAds?: Resolver<Array<ResolversTypes['SearchAdResultType']>, ParentType, ContextType>;
  systemStatus?: Resolver<ResolversTypes['StatusType'], ParentType, ContextType>;
  transactionDetail?: Resolver<Maybe<ResolversTypes['TransactionDetailOutput']>, ParentType, ContextType, RequireFields<QueryRootTransactionDetailArgs, 'episodeId'>>;
  transactionHistory?: Resolver<Maybe<ResolversTypes['TransactionHistoryOutput']>, ParentType, ContextType, RequireFields<QueryRootTransactionHistoryArgs, 'sortBy'>>;
  upload?: Resolver<Maybe<ResolversTypes['UploadType']>, ParentType, ContextType, RequireFields<QueryRootUploadArgs, 'userId'>>;
  userAdventurePlayers?: Resolver<Array<ResolversTypes['AdventurePlayerType']>, ParentType, ContextType>;
  userAdventureRatings?: Resolver<Maybe<ResolversTypes['UserAdventureRatingsOutput']>, ParentType, ContextType, RequireFields<QueryRootUserAdventureRatingsArgs, 'userId'>>;
  userConversations?: Resolver<ResolversTypes['UserConversationsType'], ParentType, ContextType>;
  userEmailNotificationPref?: Resolver<ResolversTypes['UserEmailNotificationPrefType'], ParentType, ContextType>;
  userPlayTime?: Resolver<ResolversTypes['UserPlayTimeType'], ParentType, ContextType, RequireFields<QueryRootUserPlayTimeArgs, 'userId'>>;
  userProfile?: Resolver<ResolversTypes['UserProfileType'], ParentType, ContextType, RequireFields<QueryRootUserProfileArgs, 'userId'>>;
  userPushNotificationPref?: Resolver<ResolversTypes['UserPushNotificationPrefType'], ParentType, ContextType>;
  userRatingsAndAwards?: Resolver<ResolversTypes['UserRatingsAndAwardsType'], ParentType, ContextType, RequireFields<QueryRootUserRatingsAndAwardsArgs, 'userId'>>;
  userRecruitmentByUserId?: Resolver<Maybe<ResolversTypes['UserRecruitmentType']>, ParentType, ContextType, RequireFields<QueryRootUserRecruitmentByUserIdArgs, 'userId'>>;
  userSocialProfiles?: Resolver<Maybe<ResolversTypes['UserSocialProfilesOutput']>, ParentType, ContextType, RequireFields<QueryRootUserSocialProfilesArgs, 'userId'>>;
  userTreasureTrove?: Resolver<Maybe<ResolversTypes['UserTreasureTroveOutput']>, ParentType, ContextType, RequireFields<QueryRootUserTreasureTroveArgs, 'userId'>>;
  usernameExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryRootUsernameExistsArgs, 'username'>>;
  validateUserAddress?: Resolver<ResolversTypes['UserAddressType'], ParentType, ContextType, RequireFields<QueryRootValidateUserAddressArgs, 'userAddress'>>;
};

export type SubscriptionRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']> = {
  aboutUser?: SubscriptionResolver<Maybe<ResolversTypes['AboutUserOutput']>, "aboutUser", ParentType, ContextType, RequireFields<SubscriptionRootAboutUserArgs, 'userId'>>;
  adDetail?: SubscriptionResolver<Maybe<ResolversTypes['AdDetailOutput']>, "adDetail", ParentType, ContextType, RequireFields<SubscriptionRootAdDetailArgs, 'adId'>>;
  adventurePlayerRemoved?: SubscriptionResolver<ResolversTypes['AdventureNotificationType'], "adventurePlayerRemoved", ParentType, ContextType, RequireFields<SubscriptionRootAdventurePlayerRemovedArgs, 'adventurePlayerIds'>>;
  demiplane_ad?: SubscriptionResolver<Array<ResolversTypes['demiplane_ad']>, "demiplane_ad", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdArgs, never>>;
  demiplane_ad_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_ad_aggregate'], "demiplane_ad_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdAggregateArgs, never>>;
  demiplane_ad_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_ad']>, "demiplane_ad_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdByPkArgs, 'id'>>;
  demiplane_ad_link?: SubscriptionResolver<Array<ResolversTypes['demiplane_ad_link']>, "demiplane_ad_link", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdLinkArgs, never>>;
  demiplane_ad_link_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_ad_link_aggregate'], "demiplane_ad_link_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdLinkAggregateArgs, never>>;
  demiplane_ad_link_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_ad_link']>, "demiplane_ad_link_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdLinkByPkArgs, 'id'>>;
  demiplane_ad_match_criteria?: SubscriptionResolver<Array<ResolversTypes['demiplane_ad_match_criteria']>, "demiplane_ad_match_criteria", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdMatchCriteriaArgs, never>>;
  demiplane_ad_match_criteria_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_ad_match_criteria_aggregate'], "demiplane_ad_match_criteria_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdMatchCriteriaAggregateArgs, never>>;
  demiplane_ad_match_criteria_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_ad_match_criteria']>, "demiplane_ad_match_criteria_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdMatchCriteriaByPkArgs, 'id'>>;
  demiplane_ad_match_criteria_time?: SubscriptionResolver<Array<ResolversTypes['demiplane_ad_match_criteria_time']>, "demiplane_ad_match_criteria_time", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdMatchCriteriaTimeArgs, never>>;
  demiplane_ad_match_criteria_time_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_ad_match_criteria_time_aggregate'], "demiplane_ad_match_criteria_time_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdMatchCriteriaTimeAggregateArgs, never>>;
  demiplane_ad_match_criteria_time_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_ad_match_criteria_time']>, "demiplane_ad_match_criteria_time_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdMatchCriteriaTimeByPkArgs, 'id'>>;
  demiplane_ad_request?: SubscriptionResolver<Array<ResolversTypes['demiplane_ad_request']>, "demiplane_ad_request", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdRequestArgs, never>>;
  demiplane_ad_request_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_ad_request_aggregate'], "demiplane_ad_request_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdRequestAggregateArgs, never>>;
  demiplane_ad_request_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_ad_request']>, "demiplane_ad_request_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdRequestByPkArgs, 'id'>>;
  demiplane_ad_time?: SubscriptionResolver<Array<ResolversTypes['demiplane_ad_time']>, "demiplane_ad_time", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdTimeArgs, never>>;
  demiplane_ad_time_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_ad_time_aggregate'], "demiplane_ad_time_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdTimeAggregateArgs, never>>;
  demiplane_ad_time_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_ad_time']>, "demiplane_ad_time_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdTimeByPkArgs, 'id'>>;
  demiplane_adventure?: SubscriptionResolver<Array<ResolversTypes['demiplane_adventure']>, "demiplane_adventure", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureArgs, never>>;
  demiplane_adventure_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_adventure_aggregate'], "demiplane_adventure_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureAggregateArgs, never>>;
  demiplane_adventure_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_adventure']>, "demiplane_adventure_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureByPkArgs, 'id'>>;
  demiplane_adventure_connection?: SubscriptionResolver<Array<ResolversTypes['demiplane_adventure_connection']>, "demiplane_adventure_connection", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureConnectionArgs, never>>;
  demiplane_adventure_connection_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_adventure_connection_aggregate'], "demiplane_adventure_connection_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureConnectionAggregateArgs, never>>;
  demiplane_adventure_connection_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_adventure_connection']>, "demiplane_adventure_connection_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureConnectionByPkArgs, 'id'>>;
  demiplane_adventure_demiplane_tool?: SubscriptionResolver<Array<ResolversTypes['demiplane_adventure_demiplane_tool']>, "demiplane_adventure_demiplane_tool", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureDemiplaneToolArgs, never>>;
  demiplane_adventure_demiplane_tool_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_adventure_demiplane_tool_aggregate'], "demiplane_adventure_demiplane_tool_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureDemiplaneToolAggregateArgs, never>>;
  demiplane_adventure_demiplane_tool_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_adventure_demiplane_tool']>, "demiplane_adventure_demiplane_tool_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureDemiplaneToolByPkArgs, 'id'>>;
  demiplane_adventure_link?: SubscriptionResolver<Array<ResolversTypes['demiplane_adventure_link']>, "demiplane_adventure_link", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureLinkArgs, never>>;
  demiplane_adventure_link_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_adventure_link_aggregate'], "demiplane_adventure_link_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureLinkAggregateArgs, never>>;
  demiplane_adventure_link_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_adventure_link']>, "demiplane_adventure_link_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureLinkByPkArgs, 'id'>>;
  demiplane_adventure_player?: SubscriptionResolver<Array<ResolversTypes['demiplane_adventure_player']>, "demiplane_adventure_player", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventurePlayerArgs, never>>;
  demiplane_adventure_player_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_adventure_player_aggregate'], "demiplane_adventure_player_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventurePlayerAggregateArgs, never>>;
  demiplane_adventure_player_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_adventure_player']>, "demiplane_adventure_player_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventurePlayerByPkArgs, 'id'>>;
  demiplane_adventure_todo?: SubscriptionResolver<Array<ResolversTypes['demiplane_adventure_todo']>, "demiplane_adventure_todo", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureTodoArgs, never>>;
  demiplane_adventure_todo_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_adventure_todo_aggregate'], "demiplane_adventure_todo_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureTodoAggregateArgs, never>>;
  demiplane_adventure_todo_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_adventure_todo']>, "demiplane_adventure_todo_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventureTodoByPkArgs, 'id'>>;
  demiplane_adventuring_platform?: SubscriptionResolver<Array<ResolversTypes['demiplane_adventuring_platform']>, "demiplane_adventuring_platform", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventuringPlatformArgs, never>>;
  demiplane_adventuring_platform_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_adventuring_platform_aggregate'], "demiplane_adventuring_platform_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventuringPlatformAggregateArgs, never>>;
  demiplane_adventuring_platform_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_adventuring_platform']>, "demiplane_adventuring_platform_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAdventuringPlatformByPkArgs, 'id'>>;
  demiplane_amc_adventuring_platforms?: SubscriptionResolver<Array<ResolversTypes['demiplane_amc_adventuring_platforms']>, "demiplane_amc_adventuring_platforms", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAmcAdventuringPlatformsArgs, never>>;
  demiplane_amc_adventuring_platforms_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_amc_adventuring_platforms_aggregate'], "demiplane_amc_adventuring_platforms_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAmcAdventuringPlatformsAggregateArgs, never>>;
  demiplane_amc_adventuring_platforms_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_amc_adventuring_platforms']>, "demiplane_amc_adventuring_platforms_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAmcAdventuringPlatformsByPkArgs, 'adMatchCriteriaId' | 'adventuringPlatformId'>>;
  demiplane_amc_game_types?: SubscriptionResolver<Array<ResolversTypes['demiplane_amc_game_types']>, "demiplane_amc_game_types", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAmcGameTypesArgs, never>>;
  demiplane_amc_game_types_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_amc_game_types_aggregate'], "demiplane_amc_game_types_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAmcGameTypesAggregateArgs, never>>;
  demiplane_amc_game_types_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_amc_game_types']>, "demiplane_amc_game_types_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAmcGameTypesByPkArgs, 'adMatchCriteriaId' | 'gameTypeId'>>;
  demiplane_app_version?: SubscriptionResolver<Array<ResolversTypes['demiplane_app_version']>, "demiplane_app_version", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAppVersionArgs, never>>;
  demiplane_app_version_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_app_version_aggregate'], "demiplane_app_version_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAppVersionAggregateArgs, never>>;
  demiplane_app_version_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_app_version']>, "demiplane_app_version_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAppVersionByPkArgs, 'id'>>;
  demiplane_attachment?: SubscriptionResolver<Array<ResolversTypes['demiplane_attachment']>, "demiplane_attachment", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAttachmentArgs, never>>;
  demiplane_attachment_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_attachment_aggregate'], "demiplane_attachment_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAttachmentAggregateArgs, never>>;
  demiplane_attachment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_attachment']>, "demiplane_attachment_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAttachmentByPkArgs, 'id'>>;
  demiplane_award_type?: SubscriptionResolver<Array<ResolversTypes['demiplane_award_type']>, "demiplane_award_type", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAwardTypeArgs, never>>;
  demiplane_award_type_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_award_type_aggregate'], "demiplane_award_type_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAwardTypeAggregateArgs, never>>;
  demiplane_award_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_award_type']>, "demiplane_award_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneAwardTypeByPkArgs, 'id'>>;
  demiplane_connection_status?: SubscriptionResolver<Array<ResolversTypes['demiplane_connection_status']>, "demiplane_connection_status", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneConnectionStatusArgs, never>>;
  demiplane_connection_status_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_connection_status_aggregate'], "demiplane_connection_status_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneConnectionStatusAggregateArgs, never>>;
  demiplane_connection_status_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_connection_status']>, "demiplane_connection_status_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneConnectionStatusByPkArgs, 'id'>>;
  demiplane_connection_type?: SubscriptionResolver<Array<ResolversTypes['demiplane_connection_type']>, "demiplane_connection_type", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneConnectionTypeArgs, never>>;
  demiplane_connection_type_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_connection_type_aggregate'], "demiplane_connection_type_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneConnectionTypeAggregateArgs, never>>;
  demiplane_connection_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_connection_type']>, "demiplane_connection_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneConnectionTypeByPkArgs, 'id'>>;
  demiplane_contact_type?: SubscriptionResolver<Array<ResolversTypes['demiplane_contact_type']>, "demiplane_contact_type", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneContactTypeArgs, never>>;
  demiplane_contact_type_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_contact_type_aggregate'], "demiplane_contact_type_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneContactTypeAggregateArgs, never>>;
  demiplane_contact_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_contact_type']>, "demiplane_contact_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneContactTypeByPkArgs, 'id'>>;
  demiplane_country?: SubscriptionResolver<Array<ResolversTypes['demiplane_country']>, "demiplane_country", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneCountryArgs, never>>;
  demiplane_country_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_country_aggregate'], "demiplane_country_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneCountryAggregateArgs, never>>;
  demiplane_country_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_country']>, "demiplane_country_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneCountryByPkArgs, 'id'>>;
  demiplane_credit_card?: SubscriptionResolver<Array<ResolversTypes['demiplane_credit_card']>, "demiplane_credit_card", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneCreditCardArgs, never>>;
  demiplane_credit_card_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_credit_card_aggregate'], "demiplane_credit_card_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneCreditCardAggregateArgs, never>>;
  demiplane_credit_card_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_credit_card']>, "demiplane_credit_card_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneCreditCardByPkArgs, 'id'>>;
  demiplane_demiplane_tool?: SubscriptionResolver<Array<ResolversTypes['demiplane_demiplane_tool']>, "demiplane_demiplane_tool", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneDemiplaneToolArgs, never>>;
  demiplane_demiplane_tool_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_demiplane_tool_aggregate'], "demiplane_demiplane_tool_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneDemiplaneToolAggregateArgs, never>>;
  demiplane_demiplane_tool_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_demiplane_tool']>, "demiplane_demiplane_tool_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneDemiplaneToolByPkArgs, 'id'>>;
  demiplane_episode_attendance?: SubscriptionResolver<Array<ResolversTypes['demiplane_episode_attendance']>, "demiplane_episode_attendance", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEpisodeAttendanceArgs, never>>;
  demiplane_episode_attendance_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_episode_attendance_aggregate'], "demiplane_episode_attendance_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEpisodeAttendanceAggregateArgs, never>>;
  demiplane_episode_attendance_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_episode_attendance']>, "demiplane_episode_attendance_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEpisodeAttendanceByPkArgs, 'id'>>;
  demiplane_event?: SubscriptionResolver<Array<ResolversTypes['demiplane_event']>, "demiplane_event", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEventArgs, never>>;
  demiplane_event_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_event_aggregate'], "demiplane_event_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEventAggregateArgs, never>>;
  demiplane_event_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_event']>, "demiplane_event_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEventByPkArgs, 'id'>>;
  demiplane_event_type?: SubscriptionResolver<Array<ResolversTypes['demiplane_event_type']>, "demiplane_event_type", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEventTypeArgs, never>>;
  demiplane_event_type_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_event_type_aggregate'], "demiplane_event_type_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEventTypeAggregateArgs, never>>;
  demiplane_event_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_event_type']>, "demiplane_event_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneEventTypeByPkArgs, 'id'>>;
  demiplane_favorite_adventuring_platform?: SubscriptionResolver<Array<ResolversTypes['demiplane_favorite_adventuring_platform']>, "demiplane_favorite_adventuring_platform", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteAdventuringPlatformArgs, never>>;
  demiplane_favorite_adventuring_platform_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_favorite_adventuring_platform_aggregate'], "demiplane_favorite_adventuring_platform_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteAdventuringPlatformAggregateArgs, never>>;
  demiplane_favorite_adventuring_platform_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_favorite_adventuring_platform']>, "demiplane_favorite_adventuring_platform_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteAdventuringPlatformByPkArgs, 'id'>>;
  demiplane_favorite_dice_roll?: SubscriptionResolver<Array<ResolversTypes['demiplane_favorite_dice_roll']>, "demiplane_favorite_dice_roll", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteDiceRollArgs, never>>;
  demiplane_favorite_dice_roll_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_favorite_dice_roll_aggregate'], "demiplane_favorite_dice_roll_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteDiceRollAggregateArgs, never>>;
  demiplane_favorite_dice_roll_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_favorite_dice_roll']>, "demiplane_favorite_dice_roll_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteDiceRollByPkArgs, 'id'>>;
  demiplane_favorite_game?: SubscriptionResolver<Array<ResolversTypes['demiplane_favorite_game']>, "demiplane_favorite_game", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteGameArgs, never>>;
  demiplane_favorite_game_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_favorite_game_aggregate'], "demiplane_favorite_game_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteGameAggregateArgs, never>>;
  demiplane_favorite_game_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_favorite_game']>, "demiplane_favorite_game_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneFavoriteGameByPkArgs, 'id'>>;
  demiplane_game_type?: SubscriptionResolver<Array<ResolversTypes['demiplane_game_type']>, "demiplane_game_type", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGameTypeArgs, never>>;
  demiplane_game_type_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_game_type_aggregate'], "demiplane_game_type_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGameTypeAggregateArgs, never>>;
  demiplane_game_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_game_type']>, "demiplane_game_type_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGameTypeByPkArgs, 'id'>>;
  demiplane_gm_rating?: SubscriptionResolver<Array<ResolversTypes['demiplane_gm_rating']>, "demiplane_gm_rating", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGmRatingArgs, never>>;
  demiplane_gm_rating_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_gm_rating_aggregate'], "demiplane_gm_rating_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGmRatingAggregateArgs, never>>;
  demiplane_gm_rating_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_gm_rating']>, "demiplane_gm_rating_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGmRatingByPkArgs, 'id'>>;
  demiplane_gm_recommendation?: SubscriptionResolver<Array<ResolversTypes['demiplane_gm_recommendation']>, "demiplane_gm_recommendation", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGmRecommendationArgs, never>>;
  demiplane_gm_recommendation_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_gm_recommendation_aggregate'], "demiplane_gm_recommendation_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGmRecommendationAggregateArgs, never>>;
  demiplane_gm_recommendation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_gm_recommendation']>, "demiplane_gm_recommendation_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneGmRecommendationByPkArgs, 'id'>>;
  demiplane_invitation?: SubscriptionResolver<Array<ResolversTypes['demiplane_invitation']>, "demiplane_invitation", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneInvitationArgs, never>>;
  demiplane_invitation_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_invitation_aggregate'], "demiplane_invitation_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneInvitationAggregateArgs, never>>;
  demiplane_invitation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_invitation']>, "demiplane_invitation_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneInvitationByPkArgs, 'id'>>;
  demiplane_journal?: SubscriptionResolver<Array<ResolversTypes['demiplane_journal']>, "demiplane_journal", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalArgs, never>>;
  demiplane_journal_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_journal_aggregate'], "demiplane_journal_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalAggregateArgs, never>>;
  demiplane_journal_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_journal']>, "demiplane_journal_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalByPkArgs, 'id'>>;
  demiplane_journal_note?: SubscriptionResolver<Array<ResolversTypes['demiplane_journal_note']>, "demiplane_journal_note", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalNoteArgs, never>>;
  demiplane_journal_note_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_journal_note_aggregate'], "demiplane_journal_note_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalNoteAggregateArgs, never>>;
  demiplane_journal_note_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_journal_note']>, "demiplane_journal_note_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalNoteByPkArgs, 'id'>>;
  demiplane_journal_note_share?: SubscriptionResolver<Array<ResolversTypes['demiplane_journal_note_share']>, "demiplane_journal_note_share", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalNoteShareArgs, never>>;
  demiplane_journal_note_share_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_journal_note_share_aggregate'], "demiplane_journal_note_share_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalNoteShareAggregateArgs, never>>;
  demiplane_journal_note_share_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_journal_note_share']>, "demiplane_journal_note_share_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalNoteShareByPkArgs, 'id'>>;
  demiplane_journal_super_event?: SubscriptionResolver<Array<ResolversTypes['demiplane_journal_super_event']>, "demiplane_journal_super_event", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalSuperEventArgs, never>>;
  demiplane_journal_super_event_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_journal_super_event_aggregate'], "demiplane_journal_super_event_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalSuperEventAggregateArgs, never>>;
  demiplane_journal_super_event_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_journal_super_event']>, "demiplane_journal_super_event_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalSuperEventByPkArgs, 'id'>>;
  demiplane_journal_super_event_read_status?: SubscriptionResolver<Array<ResolversTypes['demiplane_journal_super_event_read_status']>, "demiplane_journal_super_event_read_status", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalSuperEventReadStatusArgs, never>>;
  demiplane_journal_super_event_read_status_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_journal_super_event_read_status_aggregate'], "demiplane_journal_super_event_read_status_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalSuperEventReadStatusAggregateArgs, never>>;
  demiplane_journal_super_event_read_status_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_journal_super_event_read_status']>, "demiplane_journal_super_event_read_status_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalSuperEventReadStatusByPkArgs, 'id'>>;
  demiplane_journal_title?: SubscriptionResolver<Array<ResolversTypes['demiplane_journal_title']>, "demiplane_journal_title", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalTitleArgs, never>>;
  demiplane_journal_title_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_journal_title_aggregate'], "demiplane_journal_title_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalTitleAggregateArgs, never>>;
  demiplane_journal_title_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_journal_title']>, "demiplane_journal_title_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneJournalTitleByPkArgs, 'id'>>;
  demiplane_matchmaking_token?: SubscriptionResolver<Array<ResolversTypes['demiplane_matchmaking_token']>, "demiplane_matchmaking_token", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneMatchmakingTokenArgs, never>>;
  demiplane_matchmaking_token_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_matchmaking_token_aggregate'], "demiplane_matchmaking_token_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneMatchmakingTokenAggregateArgs, never>>;
  demiplane_matchmaking_token_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_matchmaking_token']>, "demiplane_matchmaking_token_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneMatchmakingTokenByPkArgs, 'id'>>;
  demiplane_migrations?: SubscriptionResolver<Array<ResolversTypes['demiplane_migrations']>, "demiplane_migrations", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneMigrationsArgs, never>>;
  demiplane_migrations_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_migrations_aggregate'], "demiplane_migrations_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneMigrationsAggregateArgs, never>>;
  demiplane_migrations_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_migrations']>, "demiplane_migrations_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneMigrationsByPkArgs, 'id'>>;
  demiplane_notification_history?: SubscriptionResolver<Array<ResolversTypes['demiplane_notification_history']>, "demiplane_notification_history", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneNotificationHistoryArgs, never>>;
  demiplane_notification_history_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_notification_history_aggregate'], "demiplane_notification_history_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneNotificationHistoryAggregateArgs, never>>;
  demiplane_notification_history_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_notification_history']>, "demiplane_notification_history_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneNotificationHistoryByPkArgs, 'id'>>;
  demiplane_payment?: SubscriptionResolver<Array<ResolversTypes['demiplane_payment']>, "demiplane_payment", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePaymentArgs, never>>;
  demiplane_payment_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_payment_aggregate'], "demiplane_payment_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePaymentAggregateArgs, never>>;
  demiplane_payment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_payment']>, "demiplane_payment_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePaymentByPkArgs, 'id'>>;
  demiplane_player_award?: SubscriptionResolver<Array<ResolversTypes['demiplane_player_award']>, "demiplane_player_award", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePlayerAwardArgs, never>>;
  demiplane_player_award_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_player_award_aggregate'], "demiplane_player_award_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePlayerAwardAggregateArgs, never>>;
  demiplane_player_award_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_player_award']>, "demiplane_player_award_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePlayerAwardByPkArgs, 'id'>>;
  demiplane_player_rating?: SubscriptionResolver<Array<ResolversTypes['demiplane_player_rating']>, "demiplane_player_rating", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePlayerRatingArgs, never>>;
  demiplane_player_rating_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_player_rating_aggregate'], "demiplane_player_rating_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePlayerRatingAggregateArgs, never>>;
  demiplane_player_rating_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_player_rating']>, "demiplane_player_rating_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePlayerRatingByPkArgs, 'id'>>;
  demiplane_pronoun?: SubscriptionResolver<Array<ResolversTypes['demiplane_pronoun']>, "demiplane_pronoun", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePronounArgs, never>>;
  demiplane_pronoun_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_pronoun_aggregate'], "demiplane_pronoun_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePronounAggregateArgs, never>>;
  demiplane_pronoun_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_pronoun']>, "demiplane_pronoun_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplanePronounByPkArgs, 'id'>>;
  demiplane_region?: SubscriptionResolver<Array<ResolversTypes['demiplane_region']>, "demiplane_region", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneRegionArgs, never>>;
  demiplane_region_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_region_aggregate'], "demiplane_region_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneRegionAggregateArgs, never>>;
  demiplane_region_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_region']>, "demiplane_region_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneRegionByPkArgs, 'id'>>;
  demiplane_role?: SubscriptionResolver<Array<ResolversTypes['demiplane_role']>, "demiplane_role", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneRoleArgs, never>>;
  demiplane_role_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_role_aggregate'], "demiplane_role_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneRoleAggregateArgs, never>>;
  demiplane_role_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_role']>, "demiplane_role_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneRoleByPkArgs, 'id'>>;
  demiplane_session?: SubscriptionResolver<Array<ResolversTypes['demiplane_session']>, "demiplane_session", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSessionArgs, never>>;
  demiplane_session_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_session_aggregate'], "demiplane_session_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSessionAggregateArgs, never>>;
  demiplane_session_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_session']>, "demiplane_session_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSessionByPkArgs, 'id'>>;
  demiplane_session_player?: SubscriptionResolver<Array<ResolversTypes['demiplane_session_player']>, "demiplane_session_player", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSessionPlayerArgs, never>>;
  demiplane_session_player_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_session_player_aggregate'], "demiplane_session_player_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSessionPlayerAggregateArgs, never>>;
  demiplane_session_player_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_session_player']>, "demiplane_session_player_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSessionPlayerByPkArgs, 'id'>>;
  demiplane_snippet?: SubscriptionResolver<Array<ResolversTypes['demiplane_snippet']>, "demiplane_snippet", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSnippetArgs, never>>;
  demiplane_snippet_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_snippet_aggregate'], "demiplane_snippet_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSnippetAggregateArgs, never>>;
  demiplane_snippet_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_snippet']>, "demiplane_snippet_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneSnippetByPkArgs, 'id'>>;
  demiplane_task?: SubscriptionResolver<Array<ResolversTypes['demiplane_task']>, "demiplane_task", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaskArgs, never>>;
  demiplane_task_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_task_aggregate'], "demiplane_task_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaskAggregateArgs, never>>;
  demiplane_task_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_task']>, "demiplane_task_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaskByPkArgs, 'id'>>;
  demiplane_task_read_status?: SubscriptionResolver<Array<ResolversTypes['demiplane_task_read_status']>, "demiplane_task_read_status", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaskReadStatusArgs, never>>;
  demiplane_task_read_status_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_task_read_status_aggregate'], "demiplane_task_read_status_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaskReadStatusAggregateArgs, never>>;
  demiplane_task_read_status_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_task_read_status']>, "demiplane_task_read_status_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaskReadStatusByPkArgs, 'id'>>;
  demiplane_tax_nexus_region?: SubscriptionResolver<Array<ResolversTypes['demiplane_tax_nexus_region']>, "demiplane_tax_nexus_region", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaxNexusRegionArgs, never>>;
  demiplane_tax_nexus_region_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_tax_nexus_region_aggregate'], "demiplane_tax_nexus_region_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaxNexusRegionAggregateArgs, never>>;
  demiplane_tax_nexus_region_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_tax_nexus_region']>, "demiplane_tax_nexus_region_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTaxNexusRegionByPkArgs, 'id'>>;
  demiplane_transaction_receipt?: SubscriptionResolver<Array<ResolversTypes['demiplane_transaction_receipt']>, "demiplane_transaction_receipt", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTransactionReceiptArgs, never>>;
  demiplane_transaction_receipt_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_transaction_receipt_aggregate'], "demiplane_transaction_receipt_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTransactionReceiptAggregateArgs, never>>;
  demiplane_transaction_receipt_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_transaction_receipt']>, "demiplane_transaction_receipt_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneTransactionReceiptByPkArgs, 'id'>>;
  demiplane_upload?: SubscriptionResolver<Array<ResolversTypes['demiplane_upload']>, "demiplane_upload", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUploadArgs, never>>;
  demiplane_upload_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_upload_aggregate'], "demiplane_upload_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUploadAggregateArgs, never>>;
  demiplane_upload_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_upload']>, "demiplane_upload_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUploadByPkArgs, 'id'>>;
  demiplane_urap?: SubscriptionResolver<Array<ResolversTypes['demiplane_urap']>, "demiplane_urap", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUrapArgs, never>>;
  demiplane_urap_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_urap_aggregate'], "demiplane_urap_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUrapAggregateArgs, never>>;
  demiplane_urap_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_urap']>, "demiplane_urap_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUrapByPkArgs, 'adventuringPlatformId' | 'userRecruitmentId'>>;
  demiplane_urgt?: SubscriptionResolver<Array<ResolversTypes['demiplane_urgt']>, "demiplane_urgt", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUrgtArgs, never>>;
  demiplane_urgt_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_urgt_aggregate'], "demiplane_urgt_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUrgtAggregateArgs, never>>;
  demiplane_urgt_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_urgt']>, "demiplane_urgt_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUrgtByPkArgs, 'gameTypeId' | 'userRecruitmentId'>>;
  demiplane_user?: SubscriptionResolver<Array<ResolversTypes['demiplane_user']>, "demiplane_user", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserArgs, never>>;
  demiplane_user_activity?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_activity']>, "demiplane_user_activity", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserActivityArgs, never>>;
  demiplane_user_activity_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_activity_aggregate'], "demiplane_user_activity_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserActivityAggregateArgs, never>>;
  demiplane_user_activity_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_activity']>, "demiplane_user_activity_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserActivityByPkArgs, 'id'>>;
  demiplane_user_adventure?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_adventure']>, "demiplane_user_adventure", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureArgs, never>>;
  demiplane_user_adventure_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_adventure_aggregate'], "demiplane_user_adventure_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureAggregateArgs, never>>;
  demiplane_user_adventure_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_adventure']>, "demiplane_user_adventure_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureByPkArgs, 'id'>>;
  demiplane_user_adventure_ranking?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_adventure_ranking']>, "demiplane_user_adventure_ranking", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureRankingArgs, never>>;
  demiplane_user_adventure_ranking_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_adventure_ranking_aggregate'], "demiplane_user_adventure_ranking_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureRankingAggregateArgs, never>>;
  demiplane_user_adventure_ranking_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_adventure_ranking']>, "demiplane_user_adventure_ranking_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureRankingByPkArgs, 'id'>>;
  demiplane_user_adventure_role?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_adventure_role']>, "demiplane_user_adventure_role", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureRoleArgs, never>>;
  demiplane_user_adventure_role_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_adventure_role_aggregate'], "demiplane_user_adventure_role_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureRoleAggregateArgs, never>>;
  demiplane_user_adventure_role_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_adventure_role']>, "demiplane_user_adventure_role_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAdventureRoleByPkArgs, 'id'>>;
  demiplane_user_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_aggregate'], "demiplane_user_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserAggregateArgs, never>>;
  demiplane_user_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user']>, "demiplane_user_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserByPkArgs, 'id'>>;
  demiplane_user_email_notification_pref?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_email_notification_pref']>, "demiplane_user_email_notification_pref", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserEmailNotificationPrefArgs, never>>;
  demiplane_user_email_notification_pref_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_email_notification_pref_aggregate'], "demiplane_user_email_notification_pref_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserEmailNotificationPrefAggregateArgs, never>>;
  demiplane_user_email_notification_pref_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_email_notification_pref']>, "demiplane_user_email_notification_pref_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserEmailNotificationPrefByPkArgs, 'id'>>;
  demiplane_user_invitation?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_invitation']>, "demiplane_user_invitation", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserInvitationArgs, never>>;
  demiplane_user_invitation_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_invitation_aggregate'], "demiplane_user_invitation_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserInvitationAggregateArgs, never>>;
  demiplane_user_invitation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_invitation']>, "demiplane_user_invitation_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserInvitationByPkArgs, 'id'>>;
  demiplane_user_metadata?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_metadata']>, "demiplane_user_metadata", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserMetadataArgs, never>>;
  demiplane_user_metadata_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_metadata_aggregate'], "demiplane_user_metadata_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserMetadataAggregateArgs, never>>;
  demiplane_user_metadata_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_metadata']>, "demiplane_user_metadata_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserMetadataByPkArgs, 'id'>>;
  demiplane_user_push_notification_pref?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_push_notification_pref']>, "demiplane_user_push_notification_pref", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserPushNotificationPrefArgs, never>>;
  demiplane_user_push_notification_pref_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_push_notification_pref_aggregate'], "demiplane_user_push_notification_pref_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserPushNotificationPrefAggregateArgs, never>>;
  demiplane_user_push_notification_pref_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_push_notification_pref']>, "demiplane_user_push_notification_pref_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserPushNotificationPrefByPkArgs, 'id'>>;
  demiplane_user_recruitment?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment']>, "demiplane_user_recruitment", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentArgs, never>>;
  demiplane_user_recruitment_adventurer?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer']>, "demiplane_user_recruitment_adventurer", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerArgs, never>>;
  demiplane_user_recruitment_adventurer_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_adventurer_aggregate'], "demiplane_user_recruitment_adventurer_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_availability?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, "demiplane_user_recruitment_adventurer_availability", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerAvailabilityArgs, never>>;
  demiplane_user_recruitment_adventurer_availability_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_adventurer_availability_aggregate'], "demiplane_user_recruitment_adventurer_availability_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerAvailabilityAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_availability_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_availability']>, "demiplane_user_recruitment_adventurer_availability_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerAvailabilityByPkArgs, 'id'>>;
  demiplane_user_recruitment_adventurer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer']>, "demiplane_user_recruitment_adventurer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerByPkArgs, 'id'>>;
  demiplane_user_recruitment_adventurer_games?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, "demiplane_user_recruitment_adventurer_games", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerGamesArgs, never>>;
  demiplane_user_recruitment_adventurer_games_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_adventurer_games_aggregate'], "demiplane_user_recruitment_adventurer_games_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerGamesAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_games_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_games']>, "demiplane_user_recruitment_adventurer_games_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerGamesByPkArgs, 'game_type_id' | 'user_recruitment_adventurer_id'>>;
  demiplane_user_recruitment_adventurer_platforms?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, "demiplane_user_recruitment_adventurer_platforms", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerPlatformsArgs, never>>;
  demiplane_user_recruitment_adventurer_platforms_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_adventurer_platforms_aggregate'], "demiplane_user_recruitment_adventurer_platforms_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerPlatformsAggregateArgs, never>>;
  demiplane_user_recruitment_adventurer_platforms_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_adventurer_platforms']>, "demiplane_user_recruitment_adventurer_platforms_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAdventurerPlatformsByPkArgs, 'adventuring_platform_id' | 'user_recruitment_adventurer_id'>>;
  demiplane_user_recruitment_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_aggregate'], "demiplane_user_recruitment_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAggregateArgs, never>>;
  demiplane_user_recruitment_availability?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_availability']>, "demiplane_user_recruitment_availability", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAvailabilityArgs, never>>;
  demiplane_user_recruitment_availability_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_availability_aggregate'], "demiplane_user_recruitment_availability_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAvailabilityAggregateArgs, never>>;
  demiplane_user_recruitment_availability_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_availability']>, "demiplane_user_recruitment_availability_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentAvailabilityByPkArgs, 'id'>>;
  demiplane_user_recruitment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment']>, "demiplane_user_recruitment_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentByPkArgs, 'id'>>;
  demiplane_user_recruitment_game_master?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_game_master']>, "demiplane_user_recruitment_game_master", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterArgs, never>>;
  demiplane_user_recruitment_game_master_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_game_master_aggregate'], "demiplane_user_recruitment_game_master_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_availability?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, "demiplane_user_recruitment_game_master_availability", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterAvailabilityArgs, never>>;
  demiplane_user_recruitment_game_master_availability_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_game_master_availability_aggregate'], "demiplane_user_recruitment_game_master_availability_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterAvailabilityAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_availability_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_availability']>, "demiplane_user_recruitment_game_master_availability_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterAvailabilityByPkArgs, 'id'>>;
  demiplane_user_recruitment_game_master_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master']>, "demiplane_user_recruitment_game_master_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterByPkArgs, 'id'>>;
  demiplane_user_recruitment_game_master_games?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_games']>, "demiplane_user_recruitment_game_master_games", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterGamesArgs, never>>;
  demiplane_user_recruitment_game_master_games_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_game_master_games_aggregate'], "demiplane_user_recruitment_game_master_games_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterGamesAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_games_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_games']>, "demiplane_user_recruitment_game_master_games_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterGamesByPkArgs, 'game_type_id' | 'user_recruitment_game_master_id'>>;
  demiplane_user_recruitment_game_master_platforms?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, "demiplane_user_recruitment_game_master_platforms", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterPlatformsArgs, never>>;
  demiplane_user_recruitment_game_master_platforms_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_recruitment_game_master_platforms_aggregate'], "demiplane_user_recruitment_game_master_platforms_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterPlatformsAggregateArgs, never>>;
  demiplane_user_recruitment_game_master_platforms_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_recruitment_game_master_platforms']>, "demiplane_user_recruitment_game_master_platforms_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRecruitmentGameMasterPlatformsByPkArgs, 'adventuring_platform_id' | 'user_recruitment_game_master_id'>>;
  demiplane_user_role?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_role']>, "demiplane_user_role", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRoleArgs, never>>;
  demiplane_user_role_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_role_aggregate'], "demiplane_user_role_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRoleAggregateArgs, never>>;
  demiplane_user_role_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_role']>, "demiplane_user_role_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserRoleByPkArgs, 'roleId' | 'userId'>>;
  demiplane_user_visibility?: SubscriptionResolver<Array<ResolversTypes['demiplane_user_visibility']>, "demiplane_user_visibility", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserVisibilityArgs, never>>;
  demiplane_user_visibility_aggregate?: SubscriptionResolver<ResolversTypes['demiplane_user_visibility_aggregate'], "demiplane_user_visibility_aggregate", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserVisibilityAggregateArgs, never>>;
  demiplane_user_visibility_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['demiplane_user_visibility']>, "demiplane_user_visibility_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDemiplaneUserVisibilityByPkArgs, 'id'>>;
  disableWhisper?: SubscriptionResolver<ResolversTypes['AdventurePlayerNotificationType'], "disableWhisper", ParentType, ContextType, RequireFields<SubscriptionRootDisableWhisperArgs, 'adventurePlayerId'>>;
  enableWhisper?: SubscriptionResolver<ResolversTypes['AdventurePlayerNotificationType'], "enableWhisper", ParentType, ContextType, RequireFields<SubscriptionRootEnableWhisperArgs, 'adventurePlayerId'>>;
  featuredGmRecommendationReviews?: SubscriptionResolver<Maybe<ResolversTypes['FeaturedGmRecommendationReviewsOutput']>, "featuredGmRecommendationReviews", ParentType, ContextType, RequireFields<SubscriptionRootFeaturedGmRecommendationReviewsArgs, 'userId'>>;
  howUserPlays?: SubscriptionResolver<Maybe<ResolversTypes['HowUserPlaysOutput']>, "howUserPlays", ParentType, ContextType, RequireFields<SubscriptionRootHowUserPlaysArgs, 'userId'>>;
  joinAdventuresAds?: SubscriptionResolver<Maybe<ResolversTypes['JoinAdventuresAdsOutput']>, "joinAdventuresAds", ParentType, ContextType, RequireFields<SubscriptionRootJoinAdventuresAdsArgs, 'adUserId'>>;
  otherAdventuresAds?: SubscriptionResolver<Maybe<ResolversTypes['OtherAdventuresAdsOutput']>, "otherAdventuresAds", ParentType, ContextType, RequireFields<SubscriptionRootOtherAdventuresAdsArgs, never>>;
  recruitAdventurers?: SubscriptionResolver<Maybe<ResolversTypes['RecruitAdventurersOutput']>, "recruitAdventurers", ParentType, ContextType, RequireFields<SubscriptionRootRecruitAdventurersArgs, 'displayCount' | 'excludeRecruited' | 'portalId'>>;
  recruitGameMasters?: SubscriptionResolver<Maybe<ResolversTypes['RecruitGameMastersOutput']>, "recruitGameMasters", ParentType, ContextType, RequireFields<SubscriptionRootRecruitGameMastersArgs, 'displayCount' | 'excludeRecruited' | 'portalId'>>;
  searchAdDetailMM?: SubscriptionResolver<Maybe<ResolversTypes['SearchAdDetailMMOutput']>, "searchAdDetailMM", ParentType, ContextType, RequireFields<SubscriptionRootSearchAdDetailMmArgs, 'adId'>>;
  searchAdsMM?: SubscriptionResolver<Maybe<ResolversTypes['SearchAdsMMOutput']>, "searchAdsMM", ParentType, ContextType, RequireFields<SubscriptionRootSearchAdsMmArgs, 'adventuringPlatformIds' | 'availability' | 'frequencyOneTime' | 'frequencyRecurring' | 'gameTypeIds' | 'newPlayer' | 'noCost' | 'quickMatch' | 'recordCount'>>;
  sessionCancelled?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionCancelled", ParentType, ContextType, RequireFields<SubscriptionRootSessionCancelledArgs, 'adventureIds'>>;
  sessionEnded?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionEnded", ParentType, ContextType, RequireFields<SubscriptionRootSessionEndedArgs, 'adventureIds'>>;
  sessionGameplayNotification?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionGameplayNotification", ParentType, ContextType, RequireFields<SubscriptionRootSessionGameplayNotificationArgs, 'adventureIds'>>;
  sessionJoined?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionJoined", ParentType, ContextType, RequireFields<SubscriptionRootSessionJoinedArgs, 'adventureIds'>>;
  sessionLeft?: SubscriptionResolver<ResolversTypes['SessionPlayerNotificationType'], "sessionLeft", ParentType, ContextType, RequireFields<SubscriptionRootSessionLeftArgs, 'adventureIds'>>;
  sessionOpened?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionOpened", ParentType, ContextType, RequireFields<SubscriptionRootSessionOpenedArgs, 'adventureIds'>>;
  sessionRescheduled?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionRescheduled", ParentType, ContextType, RequireFields<SubscriptionRootSessionRescheduledArgs, 'adventureIds'>>;
  sessionScheduled?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionScheduled", ParentType, ContextType, RequireFields<SubscriptionRootSessionScheduledArgs, 'adventureIds'>>;
  sessionStarted?: SubscriptionResolver<ResolversTypes['SessionNotificationType'], "sessionStarted", ParentType, ContextType, RequireFields<SubscriptionRootSessionStartedArgs, 'adventureIds'>>;
  transactionDetail?: SubscriptionResolver<Maybe<ResolversTypes['TransactionDetailOutput']>, "transactionDetail", ParentType, ContextType, RequireFields<SubscriptionRootTransactionDetailArgs, 'episodeId'>>;
  transactionHistory?: SubscriptionResolver<Maybe<ResolversTypes['TransactionHistoryOutput']>, "transactionHistory", ParentType, ContextType, RequireFields<SubscriptionRootTransactionHistoryArgs, 'sortBy'>>;
  userAdventureRatings?: SubscriptionResolver<Maybe<ResolversTypes['UserAdventureRatingsOutput']>, "userAdventureRatings", ParentType, ContextType, RequireFields<SubscriptionRootUserAdventureRatingsArgs, 'userId'>>;
  userSocialProfiles?: SubscriptionResolver<Maybe<ResolversTypes['UserSocialProfilesOutput']>, "userSocialProfiles", ParentType, ContextType, RequireFields<SubscriptionRootUserSocialProfilesArgs, 'userId'>>;
  userTreasureTrove?: SubscriptionResolver<Maybe<ResolversTypes['UserTreasureTroveOutput']>, "userTreasureTrove", ParentType, ContextType, RequireFields<SubscriptionRootUserTreasureTroveArgs, 'userId'>>;
};

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['time'], any> {
  name: 'time';
}

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamp'], any> {
  name: 'timestamp';
}

export interface TimestamptzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type Resolvers<ContextType = any> = {
  AboutUserOutput?: AboutUserOutputResolvers<ContextType>;
  AdDetailOutput?: AdDetailOutputResolvers<ContextType>;
  AdLinkType?: AdLinkTypeResolvers<ContextType>;
  AdMatchCriteriaTimeType?: AdMatchCriteriaTimeTypeResolvers<ContextType>;
  AdMatchCriteriaType?: AdMatchCriteriaTypeResolvers<ContextType>;
  AdRequestScoredType?: AdRequestScoredTypeResolvers<ContextType>;
  AdRequestType?: AdRequestTypeResolvers<ContextType>;
  AdResultType?: AdResultTypeResolvers<ContextType>;
  AdTimeHasuraType?: AdTimeHasuraTypeResolvers<ContextType>;
  AdTimeType?: AdTimeTypeResolvers<ContextType>;
  AdType?: AdTypeResolvers<ContextType>;
  AddFavoriteGameOutput?: AddFavoriteGameOutputResolvers<ContextType>;
  AddFavoritePlatformOutput?: AddFavoritePlatformOutputResolvers<ContextType>;
  AddNewPortalV2Output?: AddNewPortalV2OutputResolvers<ContextType>;
  AdventureConnectionType?: AdventureConnectionTypeResolvers<ContextType>;
  AdventureLinkType?: AdventureLinkTypeResolvers<ContextType>;
  AdventureMutationType?: AdventureMutationTypeResolvers<ContextType>;
  AdventureNotificationType?: AdventureNotificationTypeResolvers<ContextType>;
  AdventurePlayerMutationType?: AdventurePlayerMutationTypeResolvers<ContextType>;
  AdventurePlayerNotificationType?: AdventurePlayerNotificationTypeResolvers<ContextType>;
  AdventurePlayerType?: AdventurePlayerTypeResolvers<ContextType>;
  AdventureTodoType?: AdventureTodoTypeResolvers<ContextType>;
  AdventureType?: AdventureTypeResolvers<ContextType>;
  AdventuringPlatformType?: AdventuringPlatformTypeResolvers<ContextType>;
  AllJournalsItemType?: AllJournalsItemTypeResolvers<ContextType>;
  AllJournalsJournalType?: AllJournalsJournalTypeResolvers<ContextType>;
  AppVersionType?: AppVersionTypeResolvers<ContextType>;
  AttachmentType?: AttachmentTypeResolvers<ContextType>;
  AwardypeType?: AwardypeTypeResolvers<ContextType>;
  ChatTokenType?: ChatTokenTypeResolvers<ContextType>;
  ConnectionStatusType?: ConnectionStatusTypeResolvers<ContextType>;
  ConnectionTypeType?: ConnectionTypeTypeResolvers<ContextType>;
  ContactTypeType?: ContactTypeTypeResolvers<ContextType>;
  ConversationParticipantsType?: ConversationParticipantsTypeResolvers<ContextType>;
  CountryType?: CountryTypeResolvers<ContextType>;
  CreditCardType?: CreditCardTypeResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  EarningsType?: EarningsTypeResolvers<ContextType>;
  EjectGameMasterV2Output?: EjectGameMasterV2OutputResolvers<ContextType>;
  EndEpisodeOutput?: EndEpisodeOutputResolvers<ContextType>;
  EndEpisodeV2Output?: EndEpisodeV2OutputResolvers<ContextType>;
  EpisoderAttendanceType?: EpisoderAttendanceTypeResolvers<ContextType>;
  FavoriteDiceRollType?: FavoriteDiceRollTypeResolvers<ContextType>;
  FeaturedGmRecommendationReviewsOutput?: FeaturedGmRecommendationReviewsOutputResolvers<ContextType>;
  GameTypeType?: GameTypeTypeResolvers<ContextType>;
  GmRatingType?: GmRatingTypeResolvers<ContextType>;
  HandleParticipantLeavesWhisperOutput?: HandleParticipantLeavesWhisperOutputResolvers<ContextType>;
  HowUserPlaysOutput?: HowUserPlaysOutputResolvers<ContextType>;
  ImmediatelyOpenEpisodeV2Output?: ImmediatelyOpenEpisodeV2OutputResolvers<ContextType>;
  InvitationType?: InvitationTypeResolvers<ContextType>;
  ItemUnionType?: ItemUnionTypeResolvers<ContextType>;
  JoinAdventuresAdsOutput?: JoinAdventuresAdsOutputResolvers<ContextType>;
  JoinAdventuresAdsResult?: JoinAdventuresAdsResultResolvers<ContextType>;
  JournalNoteShareReturnType?: JournalNoteShareReturnTypeResolvers<ContextType>;
  JournalNoteShareType?: JournalNoteShareTypeResolvers<ContextType>;
  JournalNoteType?: JournalNoteTypeResolvers<ContextType>;
  JournalSuperEventDeleteType?: JournalSuperEventDeleteTypeResolvers<ContextType>;
  JournalSuperEventReadStatusType?: JournalSuperEventReadStatusTypeResolvers<ContextType>;
  JournalSuperEventType?: JournalSuperEventTypeResolvers<ContextType>;
  JournalTitleType?: JournalTitleTypeResolvers<ContextType>;
  JournalType?: JournalTypeResolvers<ContextType>;
  LeaveEpisodeV2Output?: LeaveEpisodeV2OutputResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MyAdventuresType?: MyAdventuresTypeResolvers<ContextType>;
  NotificationHistoryType?: NotificationHistoryTypeResolvers<ContextType>;
  OpenEpisodeV2Output?: OpenEpisodeV2OutputResolvers<ContextType>;
  OtherAdventuresAdsOutput?: OtherAdventuresAdsOutputResolvers<ContextType>;
  OtherAdventuresAdsResult?: OtherAdventuresAdsResultResolvers<ContextType>;
  ParticipantLeavesWhisperOutput?: ParticipantLeavesWhisperOutputResolvers<ContextType>;
  PaymentType?: PaymentTypeResolvers<ContextType>;
  PlayerAwardType?: PlayerAwardTypeResolvers<ContextType>;
  PlayerRatingType?: PlayerRatingTypeResolvers<ContextType>;
  PromoteGameMasterV2Output?: PromoteGameMasterV2OutputResolvers<ContextType>;
  PronounType?: PronounTypeResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RecruitAdventurerOutput?: RecruitAdventurerOutputResolvers<ContextType>;
  RecruitAdventurersOutput?: RecruitAdventurersOutputResolvers<ContextType>;
  RecruitGameMasterOutput?: RecruitGameMasterOutputResolvers<ContextType>;
  RecruitGameMastersOutput?: RecruitGameMastersOutputResolvers<ContextType>;
  RecruitSummaryType?: RecruitSummaryTypeResolvers<ContextType>;
  RecruitType?: RecruitTypeResolvers<ContextType>;
  RegionType?: RegionTypeResolvers<ContextType>;
  RemoveAdventurerV2Output?: RemoveAdventurerV2OutputResolvers<ContextType>;
  ReorderFavoriteGamesOutput?: ReorderFavoriteGamesOutputResolvers<ContextType>;
  ReorderFavoritePlatformsOutput?: ReorderFavoritePlatformsOutputResolvers<ContextType>;
  RoleType?: RoleTypeResolvers<ContextType>;
  SearchAdDetailMMOutput?: SearchAdDetailMmOutputResolvers<ContextType>;
  SearchAdResultDetailLeaderType?: SearchAdResultDetailLeaderTypeResolvers<ContextType>;
  SearchAdResultDetailType?: SearchAdResultDetailTypeResolvers<ContextType>;
  SearchAdResultType?: SearchAdResultTypeResolvers<ContextType>;
  SearchAdsMMOutput?: SearchAdsMmOutputResolvers<ContextType>;
  SearchAdsMMResult?: SearchAdsMmResultResolvers<ContextType>;
  SessionHistoryTipType?: SessionHistoryTipTypeResolvers<ContextType>;
  SessionHistoryType?: SessionHistoryTypeResolvers<ContextType>;
  SessionMutationType?: SessionMutationTypeResolvers<ContextType>;
  SessionNotificationType?: SessionNotificationTypeResolvers<ContextType>;
  SessionPlayerActiveType?: SessionPlayerActiveTypeResolvers<ContextType>;
  SessionPlayerMutationType?: SessionPlayerMutationTypeResolvers<ContextType>;
  SessionPlayerNotificationType?: SessionPlayerNotificationTypeResolvers<ContextType>;
  SessionPlayerType?: SessionPlayerTypeResolvers<ContextType>;
  SessionType?: SessionTypeResolvers<ContextType>;
  SnippetType?: SnippetTypeResolvers<ContextType>;
  StartEpisodeV2Output?: StartEpisodeV2OutputResolvers<ContextType>;
  StatusType?: StatusTypeResolvers<ContextType>;
  StripeBalanceTransactionType?: StripeBalanceTransactionTypeResolvers<ContextType>;
  StripeBalanceType?: StripeBalanceTypeResolvers<ContextType>;
  StripeLoginLinkType?: StripeLoginLinkTypeResolvers<ContextType>;
  StripeSourceTypesType?: StripeSourceTypesTypeResolvers<ContextType>;
  StripeUserBalanceType?: StripeUserBalanceTypeResolvers<ContextType>;
  SubmitFileV2Output?: SubmitFileV2OutputResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  TConversation?: TConversationResolvers<ContextType>;
  TaskReadStatusType?: TaskReadStatusTypeResolvers<ContextType>;
  TaskType?: TaskTypeResolvers<ContextType>;
  TransactionDetailOutput?: TransactionDetailOutputResolvers<ContextType>;
  TransactionHistoryOutput?: TransactionHistoryOutputResolvers<ContextType>;
  TriggerDisableWhisperV2Output?: TriggerDisableWhisperV2OutputResolvers<ContextType>;
  TriggerEnableWhisperV2Output?: TriggerEnableWhisperV2OutputResolvers<ContextType>;
  UpdateBillingAddressOutput?: UpdateBillingAddressOutputResolvers<ContextType>;
  UpdateUploadV2Output?: UpdateUploadV2OutputResolvers<ContextType>;
  UpdateUserInformationV2Output?: UpdateUserInformationV2OutputResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UploadType?: UploadTypeResolvers<ContextType>;
  UserAddressType?: UserAddressTypeResolvers<ContextType>;
  UserAdventureRatingsOutput?: UserAdventureRatingsOutputResolvers<ContextType>;
  UserAdventureRoleType?: UserAdventureRoleTypeResolvers<ContextType>;
  UserConversationsType?: UserConversationsTypeResolvers<ContextType>;
  UserEmailNotificationPrefType?: UserEmailNotificationPrefTypeResolvers<ContextType>;
  UserInvitationType?: UserInvitationTypeResolvers<ContextType>;
  UserMetadataType?: UserMetadataTypeResolvers<ContextType>;
  UserPlayTimeType?: UserPlayTimeTypeResolvers<ContextType>;
  UserProfileType?: UserProfileTypeResolvers<ContextType>;
  UserPushNotificationPrefType?: UserPushNotificationPrefTypeResolvers<ContextType>;
  UserRatingsAndAwardsType?: UserRatingsAndAwardsTypeResolvers<ContextType>;
  UserRecruitmentAvailabilityType?: UserRecruitmentAvailabilityTypeResolvers<ContextType>;
  UserRecruitmentType?: UserRecruitmentTypeResolvers<ContextType>;
  UserSocialProfilesOutput?: UserSocialProfilesOutputResolvers<ContextType>;
  UserSocialProfilesResult?: UserSocialProfilesResultResolvers<ContextType>;
  UserTreasureTroveOutput?: UserTreasureTroveOutputResolvers<ContextType>;
  UserType?: UserTypeResolvers<ContextType>;
  authMMOutput?: AuthMmOutputResolvers<ContextType>;
  authMMTokenOutput?: AuthMmTokenOutputResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  date?: GraphQLScalarType;
  demiplane_ad?: DemiplaneAdResolvers<ContextType>;
  demiplane_ad_aggregate?: DemiplaneAdAggregateResolvers<ContextType>;
  demiplane_ad_aggregate_fields?: DemiplaneAdAggregateFieldsResolvers<ContextType>;
  demiplane_ad_avg_fields?: DemiplaneAdAvgFieldsResolvers<ContextType>;
  demiplane_ad_link?: DemiplaneAdLinkResolvers<ContextType>;
  demiplane_ad_link_aggregate?: DemiplaneAdLinkAggregateResolvers<ContextType>;
  demiplane_ad_link_aggregate_fields?: DemiplaneAdLinkAggregateFieldsResolvers<ContextType>;
  demiplane_ad_link_avg_fields?: DemiplaneAdLinkAvgFieldsResolvers<ContextType>;
  demiplane_ad_link_max_fields?: DemiplaneAdLinkMaxFieldsResolvers<ContextType>;
  demiplane_ad_link_min_fields?: DemiplaneAdLinkMinFieldsResolvers<ContextType>;
  demiplane_ad_link_mutation_response?: DemiplaneAdLinkMutationResponseResolvers<ContextType>;
  demiplane_ad_link_stddev_fields?: DemiplaneAdLinkStddevFieldsResolvers<ContextType>;
  demiplane_ad_link_stddev_pop_fields?: DemiplaneAdLinkStddevPopFieldsResolvers<ContextType>;
  demiplane_ad_link_stddev_samp_fields?: DemiplaneAdLinkStddevSampFieldsResolvers<ContextType>;
  demiplane_ad_link_sum_fields?: DemiplaneAdLinkSumFieldsResolvers<ContextType>;
  demiplane_ad_link_var_pop_fields?: DemiplaneAdLinkVarPopFieldsResolvers<ContextType>;
  demiplane_ad_link_var_samp_fields?: DemiplaneAdLinkVarSampFieldsResolvers<ContextType>;
  demiplane_ad_link_variance_fields?: DemiplaneAdLinkVarianceFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria?: DemiplaneAdMatchCriteriaResolvers<ContextType>;
  demiplane_ad_match_criteria_aggregate?: DemiplaneAdMatchCriteriaAggregateResolvers<ContextType>;
  demiplane_ad_match_criteria_aggregate_fields?: DemiplaneAdMatchCriteriaAggregateFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_avg_fields?: DemiplaneAdMatchCriteriaAvgFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_max_fields?: DemiplaneAdMatchCriteriaMaxFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_min_fields?: DemiplaneAdMatchCriteriaMinFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_mutation_response?: DemiplaneAdMatchCriteriaMutationResponseResolvers<ContextType>;
  demiplane_ad_match_criteria_stddev_fields?: DemiplaneAdMatchCriteriaStddevFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_stddev_pop_fields?: DemiplaneAdMatchCriteriaStddevPopFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_stddev_samp_fields?: DemiplaneAdMatchCriteriaStddevSampFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_sum_fields?: DemiplaneAdMatchCriteriaSumFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time?: DemiplaneAdMatchCriteriaTimeResolvers<ContextType>;
  demiplane_ad_match_criteria_time_aggregate?: DemiplaneAdMatchCriteriaTimeAggregateResolvers<ContextType>;
  demiplane_ad_match_criteria_time_aggregate_fields?: DemiplaneAdMatchCriteriaTimeAggregateFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_avg_fields?: DemiplaneAdMatchCriteriaTimeAvgFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_max_fields?: DemiplaneAdMatchCriteriaTimeMaxFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_min_fields?: DemiplaneAdMatchCriteriaTimeMinFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_mutation_response?: DemiplaneAdMatchCriteriaTimeMutationResponseResolvers<ContextType>;
  demiplane_ad_match_criteria_time_stddev_fields?: DemiplaneAdMatchCriteriaTimeStddevFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_stddev_pop_fields?: DemiplaneAdMatchCriteriaTimeStddevPopFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_stddev_samp_fields?: DemiplaneAdMatchCriteriaTimeStddevSampFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_sum_fields?: DemiplaneAdMatchCriteriaTimeSumFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_var_pop_fields?: DemiplaneAdMatchCriteriaTimeVarPopFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_var_samp_fields?: DemiplaneAdMatchCriteriaTimeVarSampFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_time_variance_fields?: DemiplaneAdMatchCriteriaTimeVarianceFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_var_pop_fields?: DemiplaneAdMatchCriteriaVarPopFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_var_samp_fields?: DemiplaneAdMatchCriteriaVarSampFieldsResolvers<ContextType>;
  demiplane_ad_match_criteria_variance_fields?: DemiplaneAdMatchCriteriaVarianceFieldsResolvers<ContextType>;
  demiplane_ad_max_fields?: DemiplaneAdMaxFieldsResolvers<ContextType>;
  demiplane_ad_min_fields?: DemiplaneAdMinFieldsResolvers<ContextType>;
  demiplane_ad_mutation_response?: DemiplaneAdMutationResponseResolvers<ContextType>;
  demiplane_ad_request?: DemiplaneAdRequestResolvers<ContextType>;
  demiplane_ad_request_aggregate?: DemiplaneAdRequestAggregateResolvers<ContextType>;
  demiplane_ad_request_aggregate_fields?: DemiplaneAdRequestAggregateFieldsResolvers<ContextType>;
  demiplane_ad_request_avg_fields?: DemiplaneAdRequestAvgFieldsResolvers<ContextType>;
  demiplane_ad_request_max_fields?: DemiplaneAdRequestMaxFieldsResolvers<ContextType>;
  demiplane_ad_request_min_fields?: DemiplaneAdRequestMinFieldsResolvers<ContextType>;
  demiplane_ad_request_mutation_response?: DemiplaneAdRequestMutationResponseResolvers<ContextType>;
  demiplane_ad_request_stddev_fields?: DemiplaneAdRequestStddevFieldsResolvers<ContextType>;
  demiplane_ad_request_stddev_pop_fields?: DemiplaneAdRequestStddevPopFieldsResolvers<ContextType>;
  demiplane_ad_request_stddev_samp_fields?: DemiplaneAdRequestStddevSampFieldsResolvers<ContextType>;
  demiplane_ad_request_sum_fields?: DemiplaneAdRequestSumFieldsResolvers<ContextType>;
  demiplane_ad_request_var_pop_fields?: DemiplaneAdRequestVarPopFieldsResolvers<ContextType>;
  demiplane_ad_request_var_samp_fields?: DemiplaneAdRequestVarSampFieldsResolvers<ContextType>;
  demiplane_ad_request_variance_fields?: DemiplaneAdRequestVarianceFieldsResolvers<ContextType>;
  demiplane_ad_stddev_fields?: DemiplaneAdStddevFieldsResolvers<ContextType>;
  demiplane_ad_stddev_pop_fields?: DemiplaneAdStddevPopFieldsResolvers<ContextType>;
  demiplane_ad_stddev_samp_fields?: DemiplaneAdStddevSampFieldsResolvers<ContextType>;
  demiplane_ad_sum_fields?: DemiplaneAdSumFieldsResolvers<ContextType>;
  demiplane_ad_time?: DemiplaneAdTimeResolvers<ContextType>;
  demiplane_ad_time_aggregate?: DemiplaneAdTimeAggregateResolvers<ContextType>;
  demiplane_ad_time_aggregate_fields?: DemiplaneAdTimeAggregateFieldsResolvers<ContextType>;
  demiplane_ad_time_avg_fields?: DemiplaneAdTimeAvgFieldsResolvers<ContextType>;
  demiplane_ad_time_max_fields?: DemiplaneAdTimeMaxFieldsResolvers<ContextType>;
  demiplane_ad_time_min_fields?: DemiplaneAdTimeMinFieldsResolvers<ContextType>;
  demiplane_ad_time_mutation_response?: DemiplaneAdTimeMutationResponseResolvers<ContextType>;
  demiplane_ad_time_stddev_fields?: DemiplaneAdTimeStddevFieldsResolvers<ContextType>;
  demiplane_ad_time_stddev_pop_fields?: DemiplaneAdTimeStddevPopFieldsResolvers<ContextType>;
  demiplane_ad_time_stddev_samp_fields?: DemiplaneAdTimeStddevSampFieldsResolvers<ContextType>;
  demiplane_ad_time_sum_fields?: DemiplaneAdTimeSumFieldsResolvers<ContextType>;
  demiplane_ad_time_var_pop_fields?: DemiplaneAdTimeVarPopFieldsResolvers<ContextType>;
  demiplane_ad_time_var_samp_fields?: DemiplaneAdTimeVarSampFieldsResolvers<ContextType>;
  demiplane_ad_time_variance_fields?: DemiplaneAdTimeVarianceFieldsResolvers<ContextType>;
  demiplane_ad_var_pop_fields?: DemiplaneAdVarPopFieldsResolvers<ContextType>;
  demiplane_ad_var_samp_fields?: DemiplaneAdVarSampFieldsResolvers<ContextType>;
  demiplane_ad_variance_fields?: DemiplaneAdVarianceFieldsResolvers<ContextType>;
  demiplane_adventure?: DemiplaneAdventureResolvers<ContextType>;
  demiplane_adventure_aggregate?: DemiplaneAdventureAggregateResolvers<ContextType>;
  demiplane_adventure_aggregate_fields?: DemiplaneAdventureAggregateFieldsResolvers<ContextType>;
  demiplane_adventure_avg_fields?: DemiplaneAdventureAvgFieldsResolvers<ContextType>;
  demiplane_adventure_connection?: DemiplaneAdventureConnectionResolvers<ContextType>;
  demiplane_adventure_connection_aggregate?: DemiplaneAdventureConnectionAggregateResolvers<ContextType>;
  demiplane_adventure_connection_aggregate_fields?: DemiplaneAdventureConnectionAggregateFieldsResolvers<ContextType>;
  demiplane_adventure_connection_avg_fields?: DemiplaneAdventureConnectionAvgFieldsResolvers<ContextType>;
  demiplane_adventure_connection_max_fields?: DemiplaneAdventureConnectionMaxFieldsResolvers<ContextType>;
  demiplane_adventure_connection_min_fields?: DemiplaneAdventureConnectionMinFieldsResolvers<ContextType>;
  demiplane_adventure_connection_mutation_response?: DemiplaneAdventureConnectionMutationResponseResolvers<ContextType>;
  demiplane_adventure_connection_stddev_fields?: DemiplaneAdventureConnectionStddevFieldsResolvers<ContextType>;
  demiplane_adventure_connection_stddev_pop_fields?: DemiplaneAdventureConnectionStddevPopFieldsResolvers<ContextType>;
  demiplane_adventure_connection_stddev_samp_fields?: DemiplaneAdventureConnectionStddevSampFieldsResolvers<ContextType>;
  demiplane_adventure_connection_sum_fields?: DemiplaneAdventureConnectionSumFieldsResolvers<ContextType>;
  demiplane_adventure_connection_var_pop_fields?: DemiplaneAdventureConnectionVarPopFieldsResolvers<ContextType>;
  demiplane_adventure_connection_var_samp_fields?: DemiplaneAdventureConnectionVarSampFieldsResolvers<ContextType>;
  demiplane_adventure_connection_variance_fields?: DemiplaneAdventureConnectionVarianceFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool?: DemiplaneAdventureDemiplaneToolResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_aggregate?: DemiplaneAdventureDemiplaneToolAggregateResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_aggregate_fields?: DemiplaneAdventureDemiplaneToolAggregateFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_avg_fields?: DemiplaneAdventureDemiplaneToolAvgFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_max_fields?: DemiplaneAdventureDemiplaneToolMaxFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_min_fields?: DemiplaneAdventureDemiplaneToolMinFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_mutation_response?: DemiplaneAdventureDemiplaneToolMutationResponseResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_stddev_fields?: DemiplaneAdventureDemiplaneToolStddevFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_stddev_pop_fields?: DemiplaneAdventureDemiplaneToolStddevPopFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_stddev_samp_fields?: DemiplaneAdventureDemiplaneToolStddevSampFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_sum_fields?: DemiplaneAdventureDemiplaneToolSumFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_var_pop_fields?: DemiplaneAdventureDemiplaneToolVarPopFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_var_samp_fields?: DemiplaneAdventureDemiplaneToolVarSampFieldsResolvers<ContextType>;
  demiplane_adventure_demiplane_tool_variance_fields?: DemiplaneAdventureDemiplaneToolVarianceFieldsResolvers<ContextType>;
  demiplane_adventure_link?: DemiplaneAdventureLinkResolvers<ContextType>;
  demiplane_adventure_link_aggregate?: DemiplaneAdventureLinkAggregateResolvers<ContextType>;
  demiplane_adventure_link_aggregate_fields?: DemiplaneAdventureLinkAggregateFieldsResolvers<ContextType>;
  demiplane_adventure_link_avg_fields?: DemiplaneAdventureLinkAvgFieldsResolvers<ContextType>;
  demiplane_adventure_link_max_fields?: DemiplaneAdventureLinkMaxFieldsResolvers<ContextType>;
  demiplane_adventure_link_min_fields?: DemiplaneAdventureLinkMinFieldsResolvers<ContextType>;
  demiplane_adventure_link_mutation_response?: DemiplaneAdventureLinkMutationResponseResolvers<ContextType>;
  demiplane_adventure_link_stddev_fields?: DemiplaneAdventureLinkStddevFieldsResolvers<ContextType>;
  demiplane_adventure_link_stddev_pop_fields?: DemiplaneAdventureLinkStddevPopFieldsResolvers<ContextType>;
  demiplane_adventure_link_stddev_samp_fields?: DemiplaneAdventureLinkStddevSampFieldsResolvers<ContextType>;
  demiplane_adventure_link_sum_fields?: DemiplaneAdventureLinkSumFieldsResolvers<ContextType>;
  demiplane_adventure_link_var_pop_fields?: DemiplaneAdventureLinkVarPopFieldsResolvers<ContextType>;
  demiplane_adventure_link_var_samp_fields?: DemiplaneAdventureLinkVarSampFieldsResolvers<ContextType>;
  demiplane_adventure_link_variance_fields?: DemiplaneAdventureLinkVarianceFieldsResolvers<ContextType>;
  demiplane_adventure_max_fields?: DemiplaneAdventureMaxFieldsResolvers<ContextType>;
  demiplane_adventure_min_fields?: DemiplaneAdventureMinFieldsResolvers<ContextType>;
  demiplane_adventure_mutation_response?: DemiplaneAdventureMutationResponseResolvers<ContextType>;
  demiplane_adventure_player?: DemiplaneAdventurePlayerResolvers<ContextType>;
  demiplane_adventure_player_aggregate?: DemiplaneAdventurePlayerAggregateResolvers<ContextType>;
  demiplane_adventure_player_aggregate_fields?: DemiplaneAdventurePlayerAggregateFieldsResolvers<ContextType>;
  demiplane_adventure_player_avg_fields?: DemiplaneAdventurePlayerAvgFieldsResolvers<ContextType>;
  demiplane_adventure_player_max_fields?: DemiplaneAdventurePlayerMaxFieldsResolvers<ContextType>;
  demiplane_adventure_player_min_fields?: DemiplaneAdventurePlayerMinFieldsResolvers<ContextType>;
  demiplane_adventure_player_mutation_response?: DemiplaneAdventurePlayerMutationResponseResolvers<ContextType>;
  demiplane_adventure_player_stddev_fields?: DemiplaneAdventurePlayerStddevFieldsResolvers<ContextType>;
  demiplane_adventure_player_stddev_pop_fields?: DemiplaneAdventurePlayerStddevPopFieldsResolvers<ContextType>;
  demiplane_adventure_player_stddev_samp_fields?: DemiplaneAdventurePlayerStddevSampFieldsResolvers<ContextType>;
  demiplane_adventure_player_sum_fields?: DemiplaneAdventurePlayerSumFieldsResolvers<ContextType>;
  demiplane_adventure_player_var_pop_fields?: DemiplaneAdventurePlayerVarPopFieldsResolvers<ContextType>;
  demiplane_adventure_player_var_samp_fields?: DemiplaneAdventurePlayerVarSampFieldsResolvers<ContextType>;
  demiplane_adventure_player_variance_fields?: DemiplaneAdventurePlayerVarianceFieldsResolvers<ContextType>;
  demiplane_adventure_stddev_fields?: DemiplaneAdventureStddevFieldsResolvers<ContextType>;
  demiplane_adventure_stddev_pop_fields?: DemiplaneAdventureStddevPopFieldsResolvers<ContextType>;
  demiplane_adventure_stddev_samp_fields?: DemiplaneAdventureStddevSampFieldsResolvers<ContextType>;
  demiplane_adventure_sum_fields?: DemiplaneAdventureSumFieldsResolvers<ContextType>;
  demiplane_adventure_todo?: DemiplaneAdventureTodoResolvers<ContextType>;
  demiplane_adventure_todo_aggregate?: DemiplaneAdventureTodoAggregateResolvers<ContextType>;
  demiplane_adventure_todo_aggregate_fields?: DemiplaneAdventureTodoAggregateFieldsResolvers<ContextType>;
  demiplane_adventure_todo_avg_fields?: DemiplaneAdventureTodoAvgFieldsResolvers<ContextType>;
  demiplane_adventure_todo_max_fields?: DemiplaneAdventureTodoMaxFieldsResolvers<ContextType>;
  demiplane_adventure_todo_min_fields?: DemiplaneAdventureTodoMinFieldsResolvers<ContextType>;
  demiplane_adventure_todo_mutation_response?: DemiplaneAdventureTodoMutationResponseResolvers<ContextType>;
  demiplane_adventure_todo_stddev_fields?: DemiplaneAdventureTodoStddevFieldsResolvers<ContextType>;
  demiplane_adventure_todo_stddev_pop_fields?: DemiplaneAdventureTodoStddevPopFieldsResolvers<ContextType>;
  demiplane_adventure_todo_stddev_samp_fields?: DemiplaneAdventureTodoStddevSampFieldsResolvers<ContextType>;
  demiplane_adventure_todo_sum_fields?: DemiplaneAdventureTodoSumFieldsResolvers<ContextType>;
  demiplane_adventure_todo_var_pop_fields?: DemiplaneAdventureTodoVarPopFieldsResolvers<ContextType>;
  demiplane_adventure_todo_var_samp_fields?: DemiplaneAdventureTodoVarSampFieldsResolvers<ContextType>;
  demiplane_adventure_todo_variance_fields?: DemiplaneAdventureTodoVarianceFieldsResolvers<ContextType>;
  demiplane_adventure_var_pop_fields?: DemiplaneAdventureVarPopFieldsResolvers<ContextType>;
  demiplane_adventure_var_samp_fields?: DemiplaneAdventureVarSampFieldsResolvers<ContextType>;
  demiplane_adventure_variance_fields?: DemiplaneAdventureVarianceFieldsResolvers<ContextType>;
  demiplane_adventuring_platform?: DemiplaneAdventuringPlatformResolvers<ContextType>;
  demiplane_adventuring_platform_aggregate?: DemiplaneAdventuringPlatformAggregateResolvers<ContextType>;
  demiplane_adventuring_platform_aggregate_fields?: DemiplaneAdventuringPlatformAggregateFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_avg_fields?: DemiplaneAdventuringPlatformAvgFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_max_fields?: DemiplaneAdventuringPlatformMaxFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_min_fields?: DemiplaneAdventuringPlatformMinFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_mutation_response?: DemiplaneAdventuringPlatformMutationResponseResolvers<ContextType>;
  demiplane_adventuring_platform_stddev_fields?: DemiplaneAdventuringPlatformStddevFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_stddev_pop_fields?: DemiplaneAdventuringPlatformStddevPopFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_stddev_samp_fields?: DemiplaneAdventuringPlatformStddevSampFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_sum_fields?: DemiplaneAdventuringPlatformSumFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_var_pop_fields?: DemiplaneAdventuringPlatformVarPopFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_var_samp_fields?: DemiplaneAdventuringPlatformVarSampFieldsResolvers<ContextType>;
  demiplane_adventuring_platform_variance_fields?: DemiplaneAdventuringPlatformVarianceFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms?: DemiplaneAmcAdventuringPlatformsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_aggregate?: DemiplaneAmcAdventuringPlatformsAggregateResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_aggregate_fields?: DemiplaneAmcAdventuringPlatformsAggregateFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_avg_fields?: DemiplaneAmcAdventuringPlatformsAvgFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_max_fields?: DemiplaneAmcAdventuringPlatformsMaxFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_min_fields?: DemiplaneAmcAdventuringPlatformsMinFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_mutation_response?: DemiplaneAmcAdventuringPlatformsMutationResponseResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_stddev_fields?: DemiplaneAmcAdventuringPlatformsStddevFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_stddev_pop_fields?: DemiplaneAmcAdventuringPlatformsStddevPopFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_stddev_samp_fields?: DemiplaneAmcAdventuringPlatformsStddevSampFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_sum_fields?: DemiplaneAmcAdventuringPlatformsSumFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_var_pop_fields?: DemiplaneAmcAdventuringPlatformsVarPopFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_var_samp_fields?: DemiplaneAmcAdventuringPlatformsVarSampFieldsResolvers<ContextType>;
  demiplane_amc_adventuring_platforms_variance_fields?: DemiplaneAmcAdventuringPlatformsVarianceFieldsResolvers<ContextType>;
  demiplane_amc_game_types?: DemiplaneAmcGameTypesResolvers<ContextType>;
  demiplane_amc_game_types_aggregate?: DemiplaneAmcGameTypesAggregateResolvers<ContextType>;
  demiplane_amc_game_types_aggregate_fields?: DemiplaneAmcGameTypesAggregateFieldsResolvers<ContextType>;
  demiplane_amc_game_types_avg_fields?: DemiplaneAmcGameTypesAvgFieldsResolvers<ContextType>;
  demiplane_amc_game_types_max_fields?: DemiplaneAmcGameTypesMaxFieldsResolvers<ContextType>;
  demiplane_amc_game_types_min_fields?: DemiplaneAmcGameTypesMinFieldsResolvers<ContextType>;
  demiplane_amc_game_types_mutation_response?: DemiplaneAmcGameTypesMutationResponseResolvers<ContextType>;
  demiplane_amc_game_types_stddev_fields?: DemiplaneAmcGameTypesStddevFieldsResolvers<ContextType>;
  demiplane_amc_game_types_stddev_pop_fields?: DemiplaneAmcGameTypesStddevPopFieldsResolvers<ContextType>;
  demiplane_amc_game_types_stddev_samp_fields?: DemiplaneAmcGameTypesStddevSampFieldsResolvers<ContextType>;
  demiplane_amc_game_types_sum_fields?: DemiplaneAmcGameTypesSumFieldsResolvers<ContextType>;
  demiplane_amc_game_types_var_pop_fields?: DemiplaneAmcGameTypesVarPopFieldsResolvers<ContextType>;
  demiplane_amc_game_types_var_samp_fields?: DemiplaneAmcGameTypesVarSampFieldsResolvers<ContextType>;
  demiplane_amc_game_types_variance_fields?: DemiplaneAmcGameTypesVarianceFieldsResolvers<ContextType>;
  demiplane_app_version?: DemiplaneAppVersionResolvers<ContextType>;
  demiplane_app_version_aggregate?: DemiplaneAppVersionAggregateResolvers<ContextType>;
  demiplane_app_version_aggregate_fields?: DemiplaneAppVersionAggregateFieldsResolvers<ContextType>;
  demiplane_app_version_avg_fields?: DemiplaneAppVersionAvgFieldsResolvers<ContextType>;
  demiplane_app_version_max_fields?: DemiplaneAppVersionMaxFieldsResolvers<ContextType>;
  demiplane_app_version_min_fields?: DemiplaneAppVersionMinFieldsResolvers<ContextType>;
  demiplane_app_version_mutation_response?: DemiplaneAppVersionMutationResponseResolvers<ContextType>;
  demiplane_app_version_stddev_fields?: DemiplaneAppVersionStddevFieldsResolvers<ContextType>;
  demiplane_app_version_stddev_pop_fields?: DemiplaneAppVersionStddevPopFieldsResolvers<ContextType>;
  demiplane_app_version_stddev_samp_fields?: DemiplaneAppVersionStddevSampFieldsResolvers<ContextType>;
  demiplane_app_version_sum_fields?: DemiplaneAppVersionSumFieldsResolvers<ContextType>;
  demiplane_app_version_var_pop_fields?: DemiplaneAppVersionVarPopFieldsResolvers<ContextType>;
  demiplane_app_version_var_samp_fields?: DemiplaneAppVersionVarSampFieldsResolvers<ContextType>;
  demiplane_app_version_variance_fields?: DemiplaneAppVersionVarianceFieldsResolvers<ContextType>;
  demiplane_attachment?: DemiplaneAttachmentResolvers<ContextType>;
  demiplane_attachment_aggregate?: DemiplaneAttachmentAggregateResolvers<ContextType>;
  demiplane_attachment_aggregate_fields?: DemiplaneAttachmentAggregateFieldsResolvers<ContextType>;
  demiplane_attachment_avg_fields?: DemiplaneAttachmentAvgFieldsResolvers<ContextType>;
  demiplane_attachment_max_fields?: DemiplaneAttachmentMaxFieldsResolvers<ContextType>;
  demiplane_attachment_min_fields?: DemiplaneAttachmentMinFieldsResolvers<ContextType>;
  demiplane_attachment_mutation_response?: DemiplaneAttachmentMutationResponseResolvers<ContextType>;
  demiplane_attachment_stddev_fields?: DemiplaneAttachmentStddevFieldsResolvers<ContextType>;
  demiplane_attachment_stddev_pop_fields?: DemiplaneAttachmentStddevPopFieldsResolvers<ContextType>;
  demiplane_attachment_stddev_samp_fields?: DemiplaneAttachmentStddevSampFieldsResolvers<ContextType>;
  demiplane_attachment_sum_fields?: DemiplaneAttachmentSumFieldsResolvers<ContextType>;
  demiplane_attachment_var_pop_fields?: DemiplaneAttachmentVarPopFieldsResolvers<ContextType>;
  demiplane_attachment_var_samp_fields?: DemiplaneAttachmentVarSampFieldsResolvers<ContextType>;
  demiplane_attachment_variance_fields?: DemiplaneAttachmentVarianceFieldsResolvers<ContextType>;
  demiplane_award_type?: DemiplaneAwardTypeResolvers<ContextType>;
  demiplane_award_type_aggregate?: DemiplaneAwardTypeAggregateResolvers<ContextType>;
  demiplane_award_type_aggregate_fields?: DemiplaneAwardTypeAggregateFieldsResolvers<ContextType>;
  demiplane_award_type_avg_fields?: DemiplaneAwardTypeAvgFieldsResolvers<ContextType>;
  demiplane_award_type_max_fields?: DemiplaneAwardTypeMaxFieldsResolvers<ContextType>;
  demiplane_award_type_min_fields?: DemiplaneAwardTypeMinFieldsResolvers<ContextType>;
  demiplane_award_type_mutation_response?: DemiplaneAwardTypeMutationResponseResolvers<ContextType>;
  demiplane_award_type_stddev_fields?: DemiplaneAwardTypeStddevFieldsResolvers<ContextType>;
  demiplane_award_type_stddev_pop_fields?: DemiplaneAwardTypeStddevPopFieldsResolvers<ContextType>;
  demiplane_award_type_stddev_samp_fields?: DemiplaneAwardTypeStddevSampFieldsResolvers<ContextType>;
  demiplane_award_type_sum_fields?: DemiplaneAwardTypeSumFieldsResolvers<ContextType>;
  demiplane_award_type_var_pop_fields?: DemiplaneAwardTypeVarPopFieldsResolvers<ContextType>;
  demiplane_award_type_var_samp_fields?: DemiplaneAwardTypeVarSampFieldsResolvers<ContextType>;
  demiplane_award_type_variance_fields?: DemiplaneAwardTypeVarianceFieldsResolvers<ContextType>;
  demiplane_connection_status?: DemiplaneConnectionStatusResolvers<ContextType>;
  demiplane_connection_status_aggregate?: DemiplaneConnectionStatusAggregateResolvers<ContextType>;
  demiplane_connection_status_aggregate_fields?: DemiplaneConnectionStatusAggregateFieldsResolvers<ContextType>;
  demiplane_connection_status_avg_fields?: DemiplaneConnectionStatusAvgFieldsResolvers<ContextType>;
  demiplane_connection_status_max_fields?: DemiplaneConnectionStatusMaxFieldsResolvers<ContextType>;
  demiplane_connection_status_min_fields?: DemiplaneConnectionStatusMinFieldsResolvers<ContextType>;
  demiplane_connection_status_mutation_response?: DemiplaneConnectionStatusMutationResponseResolvers<ContextType>;
  demiplane_connection_status_stddev_fields?: DemiplaneConnectionStatusStddevFieldsResolvers<ContextType>;
  demiplane_connection_status_stddev_pop_fields?: DemiplaneConnectionStatusStddevPopFieldsResolvers<ContextType>;
  demiplane_connection_status_stddev_samp_fields?: DemiplaneConnectionStatusStddevSampFieldsResolvers<ContextType>;
  demiplane_connection_status_sum_fields?: DemiplaneConnectionStatusSumFieldsResolvers<ContextType>;
  demiplane_connection_status_var_pop_fields?: DemiplaneConnectionStatusVarPopFieldsResolvers<ContextType>;
  demiplane_connection_status_var_samp_fields?: DemiplaneConnectionStatusVarSampFieldsResolvers<ContextType>;
  demiplane_connection_status_variance_fields?: DemiplaneConnectionStatusVarianceFieldsResolvers<ContextType>;
  demiplane_connection_type?: DemiplaneConnectionTypeResolvers<ContextType>;
  demiplane_connection_type_aggregate?: DemiplaneConnectionTypeAggregateResolvers<ContextType>;
  demiplane_connection_type_aggregate_fields?: DemiplaneConnectionTypeAggregateFieldsResolvers<ContextType>;
  demiplane_connection_type_avg_fields?: DemiplaneConnectionTypeAvgFieldsResolvers<ContextType>;
  demiplane_connection_type_max_fields?: DemiplaneConnectionTypeMaxFieldsResolvers<ContextType>;
  demiplane_connection_type_min_fields?: DemiplaneConnectionTypeMinFieldsResolvers<ContextType>;
  demiplane_connection_type_mutation_response?: DemiplaneConnectionTypeMutationResponseResolvers<ContextType>;
  demiplane_connection_type_stddev_fields?: DemiplaneConnectionTypeStddevFieldsResolvers<ContextType>;
  demiplane_connection_type_stddev_pop_fields?: DemiplaneConnectionTypeStddevPopFieldsResolvers<ContextType>;
  demiplane_connection_type_stddev_samp_fields?: DemiplaneConnectionTypeStddevSampFieldsResolvers<ContextType>;
  demiplane_connection_type_sum_fields?: DemiplaneConnectionTypeSumFieldsResolvers<ContextType>;
  demiplane_connection_type_var_pop_fields?: DemiplaneConnectionTypeVarPopFieldsResolvers<ContextType>;
  demiplane_connection_type_var_samp_fields?: DemiplaneConnectionTypeVarSampFieldsResolvers<ContextType>;
  demiplane_connection_type_variance_fields?: DemiplaneConnectionTypeVarianceFieldsResolvers<ContextType>;
  demiplane_contact_type?: DemiplaneContactTypeResolvers<ContextType>;
  demiplane_contact_type_aggregate?: DemiplaneContactTypeAggregateResolvers<ContextType>;
  demiplane_contact_type_aggregate_fields?: DemiplaneContactTypeAggregateFieldsResolvers<ContextType>;
  demiplane_contact_type_avg_fields?: DemiplaneContactTypeAvgFieldsResolvers<ContextType>;
  demiplane_contact_type_max_fields?: DemiplaneContactTypeMaxFieldsResolvers<ContextType>;
  demiplane_contact_type_min_fields?: DemiplaneContactTypeMinFieldsResolvers<ContextType>;
  demiplane_contact_type_mutation_response?: DemiplaneContactTypeMutationResponseResolvers<ContextType>;
  demiplane_contact_type_stddev_fields?: DemiplaneContactTypeStddevFieldsResolvers<ContextType>;
  demiplane_contact_type_stddev_pop_fields?: DemiplaneContactTypeStddevPopFieldsResolvers<ContextType>;
  demiplane_contact_type_stddev_samp_fields?: DemiplaneContactTypeStddevSampFieldsResolvers<ContextType>;
  demiplane_contact_type_sum_fields?: DemiplaneContactTypeSumFieldsResolvers<ContextType>;
  demiplane_contact_type_var_pop_fields?: DemiplaneContactTypeVarPopFieldsResolvers<ContextType>;
  demiplane_contact_type_var_samp_fields?: DemiplaneContactTypeVarSampFieldsResolvers<ContextType>;
  demiplane_contact_type_variance_fields?: DemiplaneContactTypeVarianceFieldsResolvers<ContextType>;
  demiplane_country?: DemiplaneCountryResolvers<ContextType>;
  demiplane_country_aggregate?: DemiplaneCountryAggregateResolvers<ContextType>;
  demiplane_country_aggregate_fields?: DemiplaneCountryAggregateFieldsResolvers<ContextType>;
  demiplane_country_avg_fields?: DemiplaneCountryAvgFieldsResolvers<ContextType>;
  demiplane_country_max_fields?: DemiplaneCountryMaxFieldsResolvers<ContextType>;
  demiplane_country_min_fields?: DemiplaneCountryMinFieldsResolvers<ContextType>;
  demiplane_country_mutation_response?: DemiplaneCountryMutationResponseResolvers<ContextType>;
  demiplane_country_stddev_fields?: DemiplaneCountryStddevFieldsResolvers<ContextType>;
  demiplane_country_stddev_pop_fields?: DemiplaneCountryStddevPopFieldsResolvers<ContextType>;
  demiplane_country_stddev_samp_fields?: DemiplaneCountryStddevSampFieldsResolvers<ContextType>;
  demiplane_country_sum_fields?: DemiplaneCountrySumFieldsResolvers<ContextType>;
  demiplane_country_var_pop_fields?: DemiplaneCountryVarPopFieldsResolvers<ContextType>;
  demiplane_country_var_samp_fields?: DemiplaneCountryVarSampFieldsResolvers<ContextType>;
  demiplane_country_variance_fields?: DemiplaneCountryVarianceFieldsResolvers<ContextType>;
  demiplane_credit_card?: DemiplaneCreditCardResolvers<ContextType>;
  demiplane_credit_card_aggregate?: DemiplaneCreditCardAggregateResolvers<ContextType>;
  demiplane_credit_card_aggregate_fields?: DemiplaneCreditCardAggregateFieldsResolvers<ContextType>;
  demiplane_credit_card_avg_fields?: DemiplaneCreditCardAvgFieldsResolvers<ContextType>;
  demiplane_credit_card_max_fields?: DemiplaneCreditCardMaxFieldsResolvers<ContextType>;
  demiplane_credit_card_min_fields?: DemiplaneCreditCardMinFieldsResolvers<ContextType>;
  demiplane_credit_card_mutation_response?: DemiplaneCreditCardMutationResponseResolvers<ContextType>;
  demiplane_credit_card_stddev_fields?: DemiplaneCreditCardStddevFieldsResolvers<ContextType>;
  demiplane_credit_card_stddev_pop_fields?: DemiplaneCreditCardStddevPopFieldsResolvers<ContextType>;
  demiplane_credit_card_stddev_samp_fields?: DemiplaneCreditCardStddevSampFieldsResolvers<ContextType>;
  demiplane_credit_card_sum_fields?: DemiplaneCreditCardSumFieldsResolvers<ContextType>;
  demiplane_credit_card_var_pop_fields?: DemiplaneCreditCardVarPopFieldsResolvers<ContextType>;
  demiplane_credit_card_var_samp_fields?: DemiplaneCreditCardVarSampFieldsResolvers<ContextType>;
  demiplane_credit_card_variance_fields?: DemiplaneCreditCardVarianceFieldsResolvers<ContextType>;
  demiplane_demiplane_tool?: DemiplaneDemiplaneToolResolvers<ContextType>;
  demiplane_demiplane_tool_aggregate?: DemiplaneDemiplaneToolAggregateResolvers<ContextType>;
  demiplane_demiplane_tool_aggregate_fields?: DemiplaneDemiplaneToolAggregateFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_avg_fields?: DemiplaneDemiplaneToolAvgFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_max_fields?: DemiplaneDemiplaneToolMaxFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_min_fields?: DemiplaneDemiplaneToolMinFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_mutation_response?: DemiplaneDemiplaneToolMutationResponseResolvers<ContextType>;
  demiplane_demiplane_tool_stddev_fields?: DemiplaneDemiplaneToolStddevFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_stddev_pop_fields?: DemiplaneDemiplaneToolStddevPopFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_stddev_samp_fields?: DemiplaneDemiplaneToolStddevSampFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_sum_fields?: DemiplaneDemiplaneToolSumFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_var_pop_fields?: DemiplaneDemiplaneToolVarPopFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_var_samp_fields?: DemiplaneDemiplaneToolVarSampFieldsResolvers<ContextType>;
  demiplane_demiplane_tool_variance_fields?: DemiplaneDemiplaneToolVarianceFieldsResolvers<ContextType>;
  demiplane_episode_attendance?: DemiplaneEpisodeAttendanceResolvers<ContextType>;
  demiplane_episode_attendance_aggregate?: DemiplaneEpisodeAttendanceAggregateResolvers<ContextType>;
  demiplane_episode_attendance_aggregate_fields?: DemiplaneEpisodeAttendanceAggregateFieldsResolvers<ContextType>;
  demiplane_episode_attendance_avg_fields?: DemiplaneEpisodeAttendanceAvgFieldsResolvers<ContextType>;
  demiplane_episode_attendance_max_fields?: DemiplaneEpisodeAttendanceMaxFieldsResolvers<ContextType>;
  demiplane_episode_attendance_min_fields?: DemiplaneEpisodeAttendanceMinFieldsResolvers<ContextType>;
  demiplane_episode_attendance_mutation_response?: DemiplaneEpisodeAttendanceMutationResponseResolvers<ContextType>;
  demiplane_episode_attendance_stddev_fields?: DemiplaneEpisodeAttendanceStddevFieldsResolvers<ContextType>;
  demiplane_episode_attendance_stddev_pop_fields?: DemiplaneEpisodeAttendanceStddevPopFieldsResolvers<ContextType>;
  demiplane_episode_attendance_stddev_samp_fields?: DemiplaneEpisodeAttendanceStddevSampFieldsResolvers<ContextType>;
  demiplane_episode_attendance_sum_fields?: DemiplaneEpisodeAttendanceSumFieldsResolvers<ContextType>;
  demiplane_episode_attendance_var_pop_fields?: DemiplaneEpisodeAttendanceVarPopFieldsResolvers<ContextType>;
  demiplane_episode_attendance_var_samp_fields?: DemiplaneEpisodeAttendanceVarSampFieldsResolvers<ContextType>;
  demiplane_episode_attendance_variance_fields?: DemiplaneEpisodeAttendanceVarianceFieldsResolvers<ContextType>;
  demiplane_event?: DemiplaneEventResolvers<ContextType>;
  demiplane_event_aggregate?: DemiplaneEventAggregateResolvers<ContextType>;
  demiplane_event_aggregate_fields?: DemiplaneEventAggregateFieldsResolvers<ContextType>;
  demiplane_event_avg_fields?: DemiplaneEventAvgFieldsResolvers<ContextType>;
  demiplane_event_max_fields?: DemiplaneEventMaxFieldsResolvers<ContextType>;
  demiplane_event_min_fields?: DemiplaneEventMinFieldsResolvers<ContextType>;
  demiplane_event_mutation_response?: DemiplaneEventMutationResponseResolvers<ContextType>;
  demiplane_event_stddev_fields?: DemiplaneEventStddevFieldsResolvers<ContextType>;
  demiplane_event_stddev_pop_fields?: DemiplaneEventStddevPopFieldsResolvers<ContextType>;
  demiplane_event_stddev_samp_fields?: DemiplaneEventStddevSampFieldsResolvers<ContextType>;
  demiplane_event_sum_fields?: DemiplaneEventSumFieldsResolvers<ContextType>;
  demiplane_event_type?: DemiplaneEventTypeResolvers<ContextType>;
  demiplane_event_type_aggregate?: DemiplaneEventTypeAggregateResolvers<ContextType>;
  demiplane_event_type_aggregate_fields?: DemiplaneEventTypeAggregateFieldsResolvers<ContextType>;
  demiplane_event_type_avg_fields?: DemiplaneEventTypeAvgFieldsResolvers<ContextType>;
  demiplane_event_type_max_fields?: DemiplaneEventTypeMaxFieldsResolvers<ContextType>;
  demiplane_event_type_min_fields?: DemiplaneEventTypeMinFieldsResolvers<ContextType>;
  demiplane_event_type_mutation_response?: DemiplaneEventTypeMutationResponseResolvers<ContextType>;
  demiplane_event_type_stddev_fields?: DemiplaneEventTypeStddevFieldsResolvers<ContextType>;
  demiplane_event_type_stddev_pop_fields?: DemiplaneEventTypeStddevPopFieldsResolvers<ContextType>;
  demiplane_event_type_stddev_samp_fields?: DemiplaneEventTypeStddevSampFieldsResolvers<ContextType>;
  demiplane_event_type_sum_fields?: DemiplaneEventTypeSumFieldsResolvers<ContextType>;
  demiplane_event_type_var_pop_fields?: DemiplaneEventTypeVarPopFieldsResolvers<ContextType>;
  demiplane_event_type_var_samp_fields?: DemiplaneEventTypeVarSampFieldsResolvers<ContextType>;
  demiplane_event_type_variance_fields?: DemiplaneEventTypeVarianceFieldsResolvers<ContextType>;
  demiplane_event_var_pop_fields?: DemiplaneEventVarPopFieldsResolvers<ContextType>;
  demiplane_event_var_samp_fields?: DemiplaneEventVarSampFieldsResolvers<ContextType>;
  demiplane_event_variance_fields?: DemiplaneEventVarianceFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform?: DemiplaneFavoriteAdventuringPlatformResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_aggregate?: DemiplaneFavoriteAdventuringPlatformAggregateResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_aggregate_fields?: DemiplaneFavoriteAdventuringPlatformAggregateFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_avg_fields?: DemiplaneFavoriteAdventuringPlatformAvgFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_max_fields?: DemiplaneFavoriteAdventuringPlatformMaxFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_min_fields?: DemiplaneFavoriteAdventuringPlatformMinFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_mutation_response?: DemiplaneFavoriteAdventuringPlatformMutationResponseResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_stddev_fields?: DemiplaneFavoriteAdventuringPlatformStddevFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_stddev_pop_fields?: DemiplaneFavoriteAdventuringPlatformStddevPopFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_stddev_samp_fields?: DemiplaneFavoriteAdventuringPlatformStddevSampFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_sum_fields?: DemiplaneFavoriteAdventuringPlatformSumFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_var_pop_fields?: DemiplaneFavoriteAdventuringPlatformVarPopFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_var_samp_fields?: DemiplaneFavoriteAdventuringPlatformVarSampFieldsResolvers<ContextType>;
  demiplane_favorite_adventuring_platform_variance_fields?: DemiplaneFavoriteAdventuringPlatformVarianceFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll?: DemiplaneFavoriteDiceRollResolvers<ContextType>;
  demiplane_favorite_dice_roll_aggregate?: DemiplaneFavoriteDiceRollAggregateResolvers<ContextType>;
  demiplane_favorite_dice_roll_aggregate_fields?: DemiplaneFavoriteDiceRollAggregateFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_avg_fields?: DemiplaneFavoriteDiceRollAvgFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_max_fields?: DemiplaneFavoriteDiceRollMaxFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_min_fields?: DemiplaneFavoriteDiceRollMinFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_mutation_response?: DemiplaneFavoriteDiceRollMutationResponseResolvers<ContextType>;
  demiplane_favorite_dice_roll_stddev_fields?: DemiplaneFavoriteDiceRollStddevFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_stddev_pop_fields?: DemiplaneFavoriteDiceRollStddevPopFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_stddev_samp_fields?: DemiplaneFavoriteDiceRollStddevSampFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_sum_fields?: DemiplaneFavoriteDiceRollSumFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_var_pop_fields?: DemiplaneFavoriteDiceRollVarPopFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_var_samp_fields?: DemiplaneFavoriteDiceRollVarSampFieldsResolvers<ContextType>;
  demiplane_favorite_dice_roll_variance_fields?: DemiplaneFavoriteDiceRollVarianceFieldsResolvers<ContextType>;
  demiplane_favorite_game?: DemiplaneFavoriteGameResolvers<ContextType>;
  demiplane_favorite_game_aggregate?: DemiplaneFavoriteGameAggregateResolvers<ContextType>;
  demiplane_favorite_game_aggregate_fields?: DemiplaneFavoriteGameAggregateFieldsResolvers<ContextType>;
  demiplane_favorite_game_avg_fields?: DemiplaneFavoriteGameAvgFieldsResolvers<ContextType>;
  demiplane_favorite_game_max_fields?: DemiplaneFavoriteGameMaxFieldsResolvers<ContextType>;
  demiplane_favorite_game_min_fields?: DemiplaneFavoriteGameMinFieldsResolvers<ContextType>;
  demiplane_favorite_game_mutation_response?: DemiplaneFavoriteGameMutationResponseResolvers<ContextType>;
  demiplane_favorite_game_stddev_fields?: DemiplaneFavoriteGameStddevFieldsResolvers<ContextType>;
  demiplane_favorite_game_stddev_pop_fields?: DemiplaneFavoriteGameStddevPopFieldsResolvers<ContextType>;
  demiplane_favorite_game_stddev_samp_fields?: DemiplaneFavoriteGameStddevSampFieldsResolvers<ContextType>;
  demiplane_favorite_game_sum_fields?: DemiplaneFavoriteGameSumFieldsResolvers<ContextType>;
  demiplane_favorite_game_var_pop_fields?: DemiplaneFavoriteGameVarPopFieldsResolvers<ContextType>;
  demiplane_favorite_game_var_samp_fields?: DemiplaneFavoriteGameVarSampFieldsResolvers<ContextType>;
  demiplane_favorite_game_variance_fields?: DemiplaneFavoriteGameVarianceFieldsResolvers<ContextType>;
  demiplane_game_type?: DemiplaneGameTypeResolvers<ContextType>;
  demiplane_game_type_aggregate?: DemiplaneGameTypeAggregateResolvers<ContextType>;
  demiplane_game_type_aggregate_fields?: DemiplaneGameTypeAggregateFieldsResolvers<ContextType>;
  demiplane_game_type_avg_fields?: DemiplaneGameTypeAvgFieldsResolvers<ContextType>;
  demiplane_game_type_max_fields?: DemiplaneGameTypeMaxFieldsResolvers<ContextType>;
  demiplane_game_type_min_fields?: DemiplaneGameTypeMinFieldsResolvers<ContextType>;
  demiplane_game_type_mutation_response?: DemiplaneGameTypeMutationResponseResolvers<ContextType>;
  demiplane_game_type_stddev_fields?: DemiplaneGameTypeStddevFieldsResolvers<ContextType>;
  demiplane_game_type_stddev_pop_fields?: DemiplaneGameTypeStddevPopFieldsResolvers<ContextType>;
  demiplane_game_type_stddev_samp_fields?: DemiplaneGameTypeStddevSampFieldsResolvers<ContextType>;
  demiplane_game_type_sum_fields?: DemiplaneGameTypeSumFieldsResolvers<ContextType>;
  demiplane_game_type_var_pop_fields?: DemiplaneGameTypeVarPopFieldsResolvers<ContextType>;
  demiplane_game_type_var_samp_fields?: DemiplaneGameTypeVarSampFieldsResolvers<ContextType>;
  demiplane_game_type_variance_fields?: DemiplaneGameTypeVarianceFieldsResolvers<ContextType>;
  demiplane_gm_rating?: DemiplaneGmRatingResolvers<ContextType>;
  demiplane_gm_rating_aggregate?: DemiplaneGmRatingAggregateResolvers<ContextType>;
  demiplane_gm_rating_aggregate_fields?: DemiplaneGmRatingAggregateFieldsResolvers<ContextType>;
  demiplane_gm_rating_avg_fields?: DemiplaneGmRatingAvgFieldsResolvers<ContextType>;
  demiplane_gm_rating_max_fields?: DemiplaneGmRatingMaxFieldsResolvers<ContextType>;
  demiplane_gm_rating_min_fields?: DemiplaneGmRatingMinFieldsResolvers<ContextType>;
  demiplane_gm_rating_mutation_response?: DemiplaneGmRatingMutationResponseResolvers<ContextType>;
  demiplane_gm_rating_stddev_fields?: DemiplaneGmRatingStddevFieldsResolvers<ContextType>;
  demiplane_gm_rating_stddev_pop_fields?: DemiplaneGmRatingStddevPopFieldsResolvers<ContextType>;
  demiplane_gm_rating_stddev_samp_fields?: DemiplaneGmRatingStddevSampFieldsResolvers<ContextType>;
  demiplane_gm_rating_sum_fields?: DemiplaneGmRatingSumFieldsResolvers<ContextType>;
  demiplane_gm_rating_var_pop_fields?: DemiplaneGmRatingVarPopFieldsResolvers<ContextType>;
  demiplane_gm_rating_var_samp_fields?: DemiplaneGmRatingVarSampFieldsResolvers<ContextType>;
  demiplane_gm_rating_variance_fields?: DemiplaneGmRatingVarianceFieldsResolvers<ContextType>;
  demiplane_gm_recommendation?: DemiplaneGmRecommendationResolvers<ContextType>;
  demiplane_gm_recommendation_aggregate?: DemiplaneGmRecommendationAggregateResolvers<ContextType>;
  demiplane_gm_recommendation_aggregate_fields?: DemiplaneGmRecommendationAggregateFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_avg_fields?: DemiplaneGmRecommendationAvgFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_max_fields?: DemiplaneGmRecommendationMaxFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_min_fields?: DemiplaneGmRecommendationMinFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_mutation_response?: DemiplaneGmRecommendationMutationResponseResolvers<ContextType>;
  demiplane_gm_recommendation_stddev_fields?: DemiplaneGmRecommendationStddevFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_stddev_pop_fields?: DemiplaneGmRecommendationStddevPopFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_stddev_samp_fields?: DemiplaneGmRecommendationStddevSampFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_sum_fields?: DemiplaneGmRecommendationSumFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_var_pop_fields?: DemiplaneGmRecommendationVarPopFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_var_samp_fields?: DemiplaneGmRecommendationVarSampFieldsResolvers<ContextType>;
  demiplane_gm_recommendation_variance_fields?: DemiplaneGmRecommendationVarianceFieldsResolvers<ContextType>;
  demiplane_invitation?: DemiplaneInvitationResolvers<ContextType>;
  demiplane_invitation_aggregate?: DemiplaneInvitationAggregateResolvers<ContextType>;
  demiplane_invitation_aggregate_fields?: DemiplaneInvitationAggregateFieldsResolvers<ContextType>;
  demiplane_invitation_avg_fields?: DemiplaneInvitationAvgFieldsResolvers<ContextType>;
  demiplane_invitation_max_fields?: DemiplaneInvitationMaxFieldsResolvers<ContextType>;
  demiplane_invitation_min_fields?: DemiplaneInvitationMinFieldsResolvers<ContextType>;
  demiplane_invitation_mutation_response?: DemiplaneInvitationMutationResponseResolvers<ContextType>;
  demiplane_invitation_stddev_fields?: DemiplaneInvitationStddevFieldsResolvers<ContextType>;
  demiplane_invitation_stddev_pop_fields?: DemiplaneInvitationStddevPopFieldsResolvers<ContextType>;
  demiplane_invitation_stddev_samp_fields?: DemiplaneInvitationStddevSampFieldsResolvers<ContextType>;
  demiplane_invitation_sum_fields?: DemiplaneInvitationSumFieldsResolvers<ContextType>;
  demiplane_invitation_var_pop_fields?: DemiplaneInvitationVarPopFieldsResolvers<ContextType>;
  demiplane_invitation_var_samp_fields?: DemiplaneInvitationVarSampFieldsResolvers<ContextType>;
  demiplane_invitation_variance_fields?: DemiplaneInvitationVarianceFieldsResolvers<ContextType>;
  demiplane_journal?: DemiplaneJournalResolvers<ContextType>;
  demiplane_journal_aggregate?: DemiplaneJournalAggregateResolvers<ContextType>;
  demiplane_journal_aggregate_fields?: DemiplaneJournalAggregateFieldsResolvers<ContextType>;
  demiplane_journal_avg_fields?: DemiplaneJournalAvgFieldsResolvers<ContextType>;
  demiplane_journal_max_fields?: DemiplaneJournalMaxFieldsResolvers<ContextType>;
  demiplane_journal_min_fields?: DemiplaneJournalMinFieldsResolvers<ContextType>;
  demiplane_journal_mutation_response?: DemiplaneJournalMutationResponseResolvers<ContextType>;
  demiplane_journal_note?: DemiplaneJournalNoteResolvers<ContextType>;
  demiplane_journal_note_aggregate?: DemiplaneJournalNoteAggregateResolvers<ContextType>;
  demiplane_journal_note_aggregate_fields?: DemiplaneJournalNoteAggregateFieldsResolvers<ContextType>;
  demiplane_journal_note_avg_fields?: DemiplaneJournalNoteAvgFieldsResolvers<ContextType>;
  demiplane_journal_note_max_fields?: DemiplaneJournalNoteMaxFieldsResolvers<ContextType>;
  demiplane_journal_note_min_fields?: DemiplaneJournalNoteMinFieldsResolvers<ContextType>;
  demiplane_journal_note_mutation_response?: DemiplaneJournalNoteMutationResponseResolvers<ContextType>;
  demiplane_journal_note_share?: DemiplaneJournalNoteShareResolvers<ContextType>;
  demiplane_journal_note_share_aggregate?: DemiplaneJournalNoteShareAggregateResolvers<ContextType>;
  demiplane_journal_note_share_aggregate_fields?: DemiplaneJournalNoteShareAggregateFieldsResolvers<ContextType>;
  demiplane_journal_note_share_avg_fields?: DemiplaneJournalNoteShareAvgFieldsResolvers<ContextType>;
  demiplane_journal_note_share_max_fields?: DemiplaneJournalNoteShareMaxFieldsResolvers<ContextType>;
  demiplane_journal_note_share_min_fields?: DemiplaneJournalNoteShareMinFieldsResolvers<ContextType>;
  demiplane_journal_note_share_mutation_response?: DemiplaneJournalNoteShareMutationResponseResolvers<ContextType>;
  demiplane_journal_note_share_stddev_fields?: DemiplaneJournalNoteShareStddevFieldsResolvers<ContextType>;
  demiplane_journal_note_share_stddev_pop_fields?: DemiplaneJournalNoteShareStddevPopFieldsResolvers<ContextType>;
  demiplane_journal_note_share_stddev_samp_fields?: DemiplaneJournalNoteShareStddevSampFieldsResolvers<ContextType>;
  demiplane_journal_note_share_sum_fields?: DemiplaneJournalNoteShareSumFieldsResolvers<ContextType>;
  demiplane_journal_note_share_var_pop_fields?: DemiplaneJournalNoteShareVarPopFieldsResolvers<ContextType>;
  demiplane_journal_note_share_var_samp_fields?: DemiplaneJournalNoteShareVarSampFieldsResolvers<ContextType>;
  demiplane_journal_note_share_variance_fields?: DemiplaneJournalNoteShareVarianceFieldsResolvers<ContextType>;
  demiplane_journal_note_stddev_fields?: DemiplaneJournalNoteStddevFieldsResolvers<ContextType>;
  demiplane_journal_note_stddev_pop_fields?: DemiplaneJournalNoteStddevPopFieldsResolvers<ContextType>;
  demiplane_journal_note_stddev_samp_fields?: DemiplaneJournalNoteStddevSampFieldsResolvers<ContextType>;
  demiplane_journal_note_sum_fields?: DemiplaneJournalNoteSumFieldsResolvers<ContextType>;
  demiplane_journal_note_var_pop_fields?: DemiplaneJournalNoteVarPopFieldsResolvers<ContextType>;
  demiplane_journal_note_var_samp_fields?: DemiplaneJournalNoteVarSampFieldsResolvers<ContextType>;
  demiplane_journal_note_variance_fields?: DemiplaneJournalNoteVarianceFieldsResolvers<ContextType>;
  demiplane_journal_stddev_fields?: DemiplaneJournalStddevFieldsResolvers<ContextType>;
  demiplane_journal_stddev_pop_fields?: DemiplaneJournalStddevPopFieldsResolvers<ContextType>;
  demiplane_journal_stddev_samp_fields?: DemiplaneJournalStddevSampFieldsResolvers<ContextType>;
  demiplane_journal_sum_fields?: DemiplaneJournalSumFieldsResolvers<ContextType>;
  demiplane_journal_super_event?: DemiplaneJournalSuperEventResolvers<ContextType>;
  demiplane_journal_super_event_aggregate?: DemiplaneJournalSuperEventAggregateResolvers<ContextType>;
  demiplane_journal_super_event_aggregate_fields?: DemiplaneJournalSuperEventAggregateFieldsResolvers<ContextType>;
  demiplane_journal_super_event_avg_fields?: DemiplaneJournalSuperEventAvgFieldsResolvers<ContextType>;
  demiplane_journal_super_event_max_fields?: DemiplaneJournalSuperEventMaxFieldsResolvers<ContextType>;
  demiplane_journal_super_event_min_fields?: DemiplaneJournalSuperEventMinFieldsResolvers<ContextType>;
  demiplane_journal_super_event_mutation_response?: DemiplaneJournalSuperEventMutationResponseResolvers<ContextType>;
  demiplane_journal_super_event_read_status?: DemiplaneJournalSuperEventReadStatusResolvers<ContextType>;
  demiplane_journal_super_event_read_status_aggregate?: DemiplaneJournalSuperEventReadStatusAggregateResolvers<ContextType>;
  demiplane_journal_super_event_read_status_aggregate_fields?: DemiplaneJournalSuperEventReadStatusAggregateFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_avg_fields?: DemiplaneJournalSuperEventReadStatusAvgFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_max_fields?: DemiplaneJournalSuperEventReadStatusMaxFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_min_fields?: DemiplaneJournalSuperEventReadStatusMinFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_mutation_response?: DemiplaneJournalSuperEventReadStatusMutationResponseResolvers<ContextType>;
  demiplane_journal_super_event_read_status_stddev_fields?: DemiplaneJournalSuperEventReadStatusStddevFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_stddev_pop_fields?: DemiplaneJournalSuperEventReadStatusStddevPopFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_stddev_samp_fields?: DemiplaneJournalSuperEventReadStatusStddevSampFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_sum_fields?: DemiplaneJournalSuperEventReadStatusSumFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_var_pop_fields?: DemiplaneJournalSuperEventReadStatusVarPopFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_var_samp_fields?: DemiplaneJournalSuperEventReadStatusVarSampFieldsResolvers<ContextType>;
  demiplane_journal_super_event_read_status_variance_fields?: DemiplaneJournalSuperEventReadStatusVarianceFieldsResolvers<ContextType>;
  demiplane_journal_super_event_stddev_fields?: DemiplaneJournalSuperEventStddevFieldsResolvers<ContextType>;
  demiplane_journal_super_event_stddev_pop_fields?: DemiplaneJournalSuperEventStddevPopFieldsResolvers<ContextType>;
  demiplane_journal_super_event_stddev_samp_fields?: DemiplaneJournalSuperEventStddevSampFieldsResolvers<ContextType>;
  demiplane_journal_super_event_sum_fields?: DemiplaneJournalSuperEventSumFieldsResolvers<ContextType>;
  demiplane_journal_super_event_var_pop_fields?: DemiplaneJournalSuperEventVarPopFieldsResolvers<ContextType>;
  demiplane_journal_super_event_var_samp_fields?: DemiplaneJournalSuperEventVarSampFieldsResolvers<ContextType>;
  demiplane_journal_super_event_variance_fields?: DemiplaneJournalSuperEventVarianceFieldsResolvers<ContextType>;
  demiplane_journal_title?: DemiplaneJournalTitleResolvers<ContextType>;
  demiplane_journal_title_aggregate?: DemiplaneJournalTitleAggregateResolvers<ContextType>;
  demiplane_journal_title_aggregate_fields?: DemiplaneJournalTitleAggregateFieldsResolvers<ContextType>;
  demiplane_journal_title_avg_fields?: DemiplaneJournalTitleAvgFieldsResolvers<ContextType>;
  demiplane_journal_title_max_fields?: DemiplaneJournalTitleMaxFieldsResolvers<ContextType>;
  demiplane_journal_title_min_fields?: DemiplaneJournalTitleMinFieldsResolvers<ContextType>;
  demiplane_journal_title_mutation_response?: DemiplaneJournalTitleMutationResponseResolvers<ContextType>;
  demiplane_journal_title_stddev_fields?: DemiplaneJournalTitleStddevFieldsResolvers<ContextType>;
  demiplane_journal_title_stddev_pop_fields?: DemiplaneJournalTitleStddevPopFieldsResolvers<ContextType>;
  demiplane_journal_title_stddev_samp_fields?: DemiplaneJournalTitleStddevSampFieldsResolvers<ContextType>;
  demiplane_journal_title_sum_fields?: DemiplaneJournalTitleSumFieldsResolvers<ContextType>;
  demiplane_journal_title_var_pop_fields?: DemiplaneJournalTitleVarPopFieldsResolvers<ContextType>;
  demiplane_journal_title_var_samp_fields?: DemiplaneJournalTitleVarSampFieldsResolvers<ContextType>;
  demiplane_journal_title_variance_fields?: DemiplaneJournalTitleVarianceFieldsResolvers<ContextType>;
  demiplane_journal_var_pop_fields?: DemiplaneJournalVarPopFieldsResolvers<ContextType>;
  demiplane_journal_var_samp_fields?: DemiplaneJournalVarSampFieldsResolvers<ContextType>;
  demiplane_journal_variance_fields?: DemiplaneJournalVarianceFieldsResolvers<ContextType>;
  demiplane_matchmaking_token?: DemiplaneMatchmakingTokenResolvers<ContextType>;
  demiplane_matchmaking_token_aggregate?: DemiplaneMatchmakingTokenAggregateResolvers<ContextType>;
  demiplane_matchmaking_token_aggregate_fields?: DemiplaneMatchmakingTokenAggregateFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_avg_fields?: DemiplaneMatchmakingTokenAvgFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_max_fields?: DemiplaneMatchmakingTokenMaxFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_min_fields?: DemiplaneMatchmakingTokenMinFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_mutation_response?: DemiplaneMatchmakingTokenMutationResponseResolvers<ContextType>;
  demiplane_matchmaking_token_stddev_fields?: DemiplaneMatchmakingTokenStddevFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_stddev_pop_fields?: DemiplaneMatchmakingTokenStddevPopFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_stddev_samp_fields?: DemiplaneMatchmakingTokenStddevSampFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_sum_fields?: DemiplaneMatchmakingTokenSumFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_var_pop_fields?: DemiplaneMatchmakingTokenVarPopFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_var_samp_fields?: DemiplaneMatchmakingTokenVarSampFieldsResolvers<ContextType>;
  demiplane_matchmaking_token_variance_fields?: DemiplaneMatchmakingTokenVarianceFieldsResolvers<ContextType>;
  demiplane_migrations?: DemiplaneMigrationsResolvers<ContextType>;
  demiplane_migrations_aggregate?: DemiplaneMigrationsAggregateResolvers<ContextType>;
  demiplane_migrations_aggregate_fields?: DemiplaneMigrationsAggregateFieldsResolvers<ContextType>;
  demiplane_migrations_avg_fields?: DemiplaneMigrationsAvgFieldsResolvers<ContextType>;
  demiplane_migrations_max_fields?: DemiplaneMigrationsMaxFieldsResolvers<ContextType>;
  demiplane_migrations_min_fields?: DemiplaneMigrationsMinFieldsResolvers<ContextType>;
  demiplane_migrations_mutation_response?: DemiplaneMigrationsMutationResponseResolvers<ContextType>;
  demiplane_migrations_stddev_fields?: DemiplaneMigrationsStddevFieldsResolvers<ContextType>;
  demiplane_migrations_stddev_pop_fields?: DemiplaneMigrationsStddevPopFieldsResolvers<ContextType>;
  demiplane_migrations_stddev_samp_fields?: DemiplaneMigrationsStddevSampFieldsResolvers<ContextType>;
  demiplane_migrations_sum_fields?: DemiplaneMigrationsSumFieldsResolvers<ContextType>;
  demiplane_migrations_var_pop_fields?: DemiplaneMigrationsVarPopFieldsResolvers<ContextType>;
  demiplane_migrations_var_samp_fields?: DemiplaneMigrationsVarSampFieldsResolvers<ContextType>;
  demiplane_migrations_variance_fields?: DemiplaneMigrationsVarianceFieldsResolvers<ContextType>;
  demiplane_notification_history?: DemiplaneNotificationHistoryResolvers<ContextType>;
  demiplane_notification_history_aggregate?: DemiplaneNotificationHistoryAggregateResolvers<ContextType>;
  demiplane_notification_history_aggregate_fields?: DemiplaneNotificationHistoryAggregateFieldsResolvers<ContextType>;
  demiplane_notification_history_avg_fields?: DemiplaneNotificationHistoryAvgFieldsResolvers<ContextType>;
  demiplane_notification_history_max_fields?: DemiplaneNotificationHistoryMaxFieldsResolvers<ContextType>;
  demiplane_notification_history_min_fields?: DemiplaneNotificationHistoryMinFieldsResolvers<ContextType>;
  demiplane_notification_history_mutation_response?: DemiplaneNotificationHistoryMutationResponseResolvers<ContextType>;
  demiplane_notification_history_stddev_fields?: DemiplaneNotificationHistoryStddevFieldsResolvers<ContextType>;
  demiplane_notification_history_stddev_pop_fields?: DemiplaneNotificationHistoryStddevPopFieldsResolvers<ContextType>;
  demiplane_notification_history_stddev_samp_fields?: DemiplaneNotificationHistoryStddevSampFieldsResolvers<ContextType>;
  demiplane_notification_history_sum_fields?: DemiplaneNotificationHistorySumFieldsResolvers<ContextType>;
  demiplane_notification_history_var_pop_fields?: DemiplaneNotificationHistoryVarPopFieldsResolvers<ContextType>;
  demiplane_notification_history_var_samp_fields?: DemiplaneNotificationHistoryVarSampFieldsResolvers<ContextType>;
  demiplane_notification_history_variance_fields?: DemiplaneNotificationHistoryVarianceFieldsResolvers<ContextType>;
  demiplane_payment?: DemiplanePaymentResolvers<ContextType>;
  demiplane_payment_aggregate?: DemiplanePaymentAggregateResolvers<ContextType>;
  demiplane_payment_aggregate_fields?: DemiplanePaymentAggregateFieldsResolvers<ContextType>;
  demiplane_payment_avg_fields?: DemiplanePaymentAvgFieldsResolvers<ContextType>;
  demiplane_payment_max_fields?: DemiplanePaymentMaxFieldsResolvers<ContextType>;
  demiplane_payment_min_fields?: DemiplanePaymentMinFieldsResolvers<ContextType>;
  demiplane_payment_mutation_response?: DemiplanePaymentMutationResponseResolvers<ContextType>;
  demiplane_payment_stddev_fields?: DemiplanePaymentStddevFieldsResolvers<ContextType>;
  demiplane_payment_stddev_pop_fields?: DemiplanePaymentStddevPopFieldsResolvers<ContextType>;
  demiplane_payment_stddev_samp_fields?: DemiplanePaymentStddevSampFieldsResolvers<ContextType>;
  demiplane_payment_sum_fields?: DemiplanePaymentSumFieldsResolvers<ContextType>;
  demiplane_payment_var_pop_fields?: DemiplanePaymentVarPopFieldsResolvers<ContextType>;
  demiplane_payment_var_samp_fields?: DemiplanePaymentVarSampFieldsResolvers<ContextType>;
  demiplane_payment_variance_fields?: DemiplanePaymentVarianceFieldsResolvers<ContextType>;
  demiplane_player_award?: DemiplanePlayerAwardResolvers<ContextType>;
  demiplane_player_award_aggregate?: DemiplanePlayerAwardAggregateResolvers<ContextType>;
  demiplane_player_award_aggregate_fields?: DemiplanePlayerAwardAggregateFieldsResolvers<ContextType>;
  demiplane_player_award_avg_fields?: DemiplanePlayerAwardAvgFieldsResolvers<ContextType>;
  demiplane_player_award_max_fields?: DemiplanePlayerAwardMaxFieldsResolvers<ContextType>;
  demiplane_player_award_min_fields?: DemiplanePlayerAwardMinFieldsResolvers<ContextType>;
  demiplane_player_award_mutation_response?: DemiplanePlayerAwardMutationResponseResolvers<ContextType>;
  demiplane_player_award_stddev_fields?: DemiplanePlayerAwardStddevFieldsResolvers<ContextType>;
  demiplane_player_award_stddev_pop_fields?: DemiplanePlayerAwardStddevPopFieldsResolvers<ContextType>;
  demiplane_player_award_stddev_samp_fields?: DemiplanePlayerAwardStddevSampFieldsResolvers<ContextType>;
  demiplane_player_award_sum_fields?: DemiplanePlayerAwardSumFieldsResolvers<ContextType>;
  demiplane_player_award_var_pop_fields?: DemiplanePlayerAwardVarPopFieldsResolvers<ContextType>;
  demiplane_player_award_var_samp_fields?: DemiplanePlayerAwardVarSampFieldsResolvers<ContextType>;
  demiplane_player_award_variance_fields?: DemiplanePlayerAwardVarianceFieldsResolvers<ContextType>;
  demiplane_player_rating?: DemiplanePlayerRatingResolvers<ContextType>;
  demiplane_player_rating_aggregate?: DemiplanePlayerRatingAggregateResolvers<ContextType>;
  demiplane_player_rating_aggregate_fields?: DemiplanePlayerRatingAggregateFieldsResolvers<ContextType>;
  demiplane_player_rating_avg_fields?: DemiplanePlayerRatingAvgFieldsResolvers<ContextType>;
  demiplane_player_rating_max_fields?: DemiplanePlayerRatingMaxFieldsResolvers<ContextType>;
  demiplane_player_rating_min_fields?: DemiplanePlayerRatingMinFieldsResolvers<ContextType>;
  demiplane_player_rating_mutation_response?: DemiplanePlayerRatingMutationResponseResolvers<ContextType>;
  demiplane_player_rating_stddev_fields?: DemiplanePlayerRatingStddevFieldsResolvers<ContextType>;
  demiplane_player_rating_stddev_pop_fields?: DemiplanePlayerRatingStddevPopFieldsResolvers<ContextType>;
  demiplane_player_rating_stddev_samp_fields?: DemiplanePlayerRatingStddevSampFieldsResolvers<ContextType>;
  demiplane_player_rating_sum_fields?: DemiplanePlayerRatingSumFieldsResolvers<ContextType>;
  demiplane_player_rating_var_pop_fields?: DemiplanePlayerRatingVarPopFieldsResolvers<ContextType>;
  demiplane_player_rating_var_samp_fields?: DemiplanePlayerRatingVarSampFieldsResolvers<ContextType>;
  demiplane_player_rating_variance_fields?: DemiplanePlayerRatingVarianceFieldsResolvers<ContextType>;
  demiplane_pronoun?: DemiplanePronounResolvers<ContextType>;
  demiplane_pronoun_aggregate?: DemiplanePronounAggregateResolvers<ContextType>;
  demiplane_pronoun_aggregate_fields?: DemiplanePronounAggregateFieldsResolvers<ContextType>;
  demiplane_pronoun_avg_fields?: DemiplanePronounAvgFieldsResolvers<ContextType>;
  demiplane_pronoun_max_fields?: DemiplanePronounMaxFieldsResolvers<ContextType>;
  demiplane_pronoun_min_fields?: DemiplanePronounMinFieldsResolvers<ContextType>;
  demiplane_pronoun_mutation_response?: DemiplanePronounMutationResponseResolvers<ContextType>;
  demiplane_pronoun_stddev_fields?: DemiplanePronounStddevFieldsResolvers<ContextType>;
  demiplane_pronoun_stddev_pop_fields?: DemiplanePronounStddevPopFieldsResolvers<ContextType>;
  demiplane_pronoun_stddev_samp_fields?: DemiplanePronounStddevSampFieldsResolvers<ContextType>;
  demiplane_pronoun_sum_fields?: DemiplanePronounSumFieldsResolvers<ContextType>;
  demiplane_pronoun_var_pop_fields?: DemiplanePronounVarPopFieldsResolvers<ContextType>;
  demiplane_pronoun_var_samp_fields?: DemiplanePronounVarSampFieldsResolvers<ContextType>;
  demiplane_pronoun_variance_fields?: DemiplanePronounVarianceFieldsResolvers<ContextType>;
  demiplane_region?: DemiplaneRegionResolvers<ContextType>;
  demiplane_region_aggregate?: DemiplaneRegionAggregateResolvers<ContextType>;
  demiplane_region_aggregate_fields?: DemiplaneRegionAggregateFieldsResolvers<ContextType>;
  demiplane_region_avg_fields?: DemiplaneRegionAvgFieldsResolvers<ContextType>;
  demiplane_region_max_fields?: DemiplaneRegionMaxFieldsResolvers<ContextType>;
  demiplane_region_min_fields?: DemiplaneRegionMinFieldsResolvers<ContextType>;
  demiplane_region_mutation_response?: DemiplaneRegionMutationResponseResolvers<ContextType>;
  demiplane_region_stddev_fields?: DemiplaneRegionStddevFieldsResolvers<ContextType>;
  demiplane_region_stddev_pop_fields?: DemiplaneRegionStddevPopFieldsResolvers<ContextType>;
  demiplane_region_stddev_samp_fields?: DemiplaneRegionStddevSampFieldsResolvers<ContextType>;
  demiplane_region_sum_fields?: DemiplaneRegionSumFieldsResolvers<ContextType>;
  demiplane_region_var_pop_fields?: DemiplaneRegionVarPopFieldsResolvers<ContextType>;
  demiplane_region_var_samp_fields?: DemiplaneRegionVarSampFieldsResolvers<ContextType>;
  demiplane_region_variance_fields?: DemiplaneRegionVarianceFieldsResolvers<ContextType>;
  demiplane_role?: DemiplaneRoleResolvers<ContextType>;
  demiplane_role_aggregate?: DemiplaneRoleAggregateResolvers<ContextType>;
  demiplane_role_aggregate_fields?: DemiplaneRoleAggregateFieldsResolvers<ContextType>;
  demiplane_role_avg_fields?: DemiplaneRoleAvgFieldsResolvers<ContextType>;
  demiplane_role_max_fields?: DemiplaneRoleMaxFieldsResolvers<ContextType>;
  demiplane_role_min_fields?: DemiplaneRoleMinFieldsResolvers<ContextType>;
  demiplane_role_mutation_response?: DemiplaneRoleMutationResponseResolvers<ContextType>;
  demiplane_role_stddev_fields?: DemiplaneRoleStddevFieldsResolvers<ContextType>;
  demiplane_role_stddev_pop_fields?: DemiplaneRoleStddevPopFieldsResolvers<ContextType>;
  demiplane_role_stddev_samp_fields?: DemiplaneRoleStddevSampFieldsResolvers<ContextType>;
  demiplane_role_sum_fields?: DemiplaneRoleSumFieldsResolvers<ContextType>;
  demiplane_role_var_pop_fields?: DemiplaneRoleVarPopFieldsResolvers<ContextType>;
  demiplane_role_var_samp_fields?: DemiplaneRoleVarSampFieldsResolvers<ContextType>;
  demiplane_role_variance_fields?: DemiplaneRoleVarianceFieldsResolvers<ContextType>;
  demiplane_session?: DemiplaneSessionResolvers<ContextType>;
  demiplane_session_aggregate?: DemiplaneSessionAggregateResolvers<ContextType>;
  demiplane_session_aggregate_fields?: DemiplaneSessionAggregateFieldsResolvers<ContextType>;
  demiplane_session_avg_fields?: DemiplaneSessionAvgFieldsResolvers<ContextType>;
  demiplane_session_max_fields?: DemiplaneSessionMaxFieldsResolvers<ContextType>;
  demiplane_session_min_fields?: DemiplaneSessionMinFieldsResolvers<ContextType>;
  demiplane_session_mutation_response?: DemiplaneSessionMutationResponseResolvers<ContextType>;
  demiplane_session_player?: DemiplaneSessionPlayerResolvers<ContextType>;
  demiplane_session_player_aggregate?: DemiplaneSessionPlayerAggregateResolvers<ContextType>;
  demiplane_session_player_aggregate_fields?: DemiplaneSessionPlayerAggregateFieldsResolvers<ContextType>;
  demiplane_session_player_avg_fields?: DemiplaneSessionPlayerAvgFieldsResolvers<ContextType>;
  demiplane_session_player_max_fields?: DemiplaneSessionPlayerMaxFieldsResolvers<ContextType>;
  demiplane_session_player_min_fields?: DemiplaneSessionPlayerMinFieldsResolvers<ContextType>;
  demiplane_session_player_mutation_response?: DemiplaneSessionPlayerMutationResponseResolvers<ContextType>;
  demiplane_session_player_stddev_fields?: DemiplaneSessionPlayerStddevFieldsResolvers<ContextType>;
  demiplane_session_player_stddev_pop_fields?: DemiplaneSessionPlayerStddevPopFieldsResolvers<ContextType>;
  demiplane_session_player_stddev_samp_fields?: DemiplaneSessionPlayerStddevSampFieldsResolvers<ContextType>;
  demiplane_session_player_sum_fields?: DemiplaneSessionPlayerSumFieldsResolvers<ContextType>;
  demiplane_session_player_var_pop_fields?: DemiplaneSessionPlayerVarPopFieldsResolvers<ContextType>;
  demiplane_session_player_var_samp_fields?: DemiplaneSessionPlayerVarSampFieldsResolvers<ContextType>;
  demiplane_session_player_variance_fields?: DemiplaneSessionPlayerVarianceFieldsResolvers<ContextType>;
  demiplane_session_stddev_fields?: DemiplaneSessionStddevFieldsResolvers<ContextType>;
  demiplane_session_stddev_pop_fields?: DemiplaneSessionStddevPopFieldsResolvers<ContextType>;
  demiplane_session_stddev_samp_fields?: DemiplaneSessionStddevSampFieldsResolvers<ContextType>;
  demiplane_session_sum_fields?: DemiplaneSessionSumFieldsResolvers<ContextType>;
  demiplane_session_var_pop_fields?: DemiplaneSessionVarPopFieldsResolvers<ContextType>;
  demiplane_session_var_samp_fields?: DemiplaneSessionVarSampFieldsResolvers<ContextType>;
  demiplane_session_variance_fields?: DemiplaneSessionVarianceFieldsResolvers<ContextType>;
  demiplane_snippet?: DemiplaneSnippetResolvers<ContextType>;
  demiplane_snippet_aggregate?: DemiplaneSnippetAggregateResolvers<ContextType>;
  demiplane_snippet_aggregate_fields?: DemiplaneSnippetAggregateFieldsResolvers<ContextType>;
  demiplane_snippet_avg_fields?: DemiplaneSnippetAvgFieldsResolvers<ContextType>;
  demiplane_snippet_max_fields?: DemiplaneSnippetMaxFieldsResolvers<ContextType>;
  demiplane_snippet_min_fields?: DemiplaneSnippetMinFieldsResolvers<ContextType>;
  demiplane_snippet_mutation_response?: DemiplaneSnippetMutationResponseResolvers<ContextType>;
  demiplane_snippet_stddev_fields?: DemiplaneSnippetStddevFieldsResolvers<ContextType>;
  demiplane_snippet_stddev_pop_fields?: DemiplaneSnippetStddevPopFieldsResolvers<ContextType>;
  demiplane_snippet_stddev_samp_fields?: DemiplaneSnippetStddevSampFieldsResolvers<ContextType>;
  demiplane_snippet_sum_fields?: DemiplaneSnippetSumFieldsResolvers<ContextType>;
  demiplane_snippet_var_pop_fields?: DemiplaneSnippetVarPopFieldsResolvers<ContextType>;
  demiplane_snippet_var_samp_fields?: DemiplaneSnippetVarSampFieldsResolvers<ContextType>;
  demiplane_snippet_variance_fields?: DemiplaneSnippetVarianceFieldsResolvers<ContextType>;
  demiplane_task?: DemiplaneTaskResolvers<ContextType>;
  demiplane_task_aggregate?: DemiplaneTaskAggregateResolvers<ContextType>;
  demiplane_task_aggregate_fields?: DemiplaneTaskAggregateFieldsResolvers<ContextType>;
  demiplane_task_avg_fields?: DemiplaneTaskAvgFieldsResolvers<ContextType>;
  demiplane_task_max_fields?: DemiplaneTaskMaxFieldsResolvers<ContextType>;
  demiplane_task_min_fields?: DemiplaneTaskMinFieldsResolvers<ContextType>;
  demiplane_task_mutation_response?: DemiplaneTaskMutationResponseResolvers<ContextType>;
  demiplane_task_read_status?: DemiplaneTaskReadStatusResolvers<ContextType>;
  demiplane_task_read_status_aggregate?: DemiplaneTaskReadStatusAggregateResolvers<ContextType>;
  demiplane_task_read_status_aggregate_fields?: DemiplaneTaskReadStatusAggregateFieldsResolvers<ContextType>;
  demiplane_task_read_status_avg_fields?: DemiplaneTaskReadStatusAvgFieldsResolvers<ContextType>;
  demiplane_task_read_status_max_fields?: DemiplaneTaskReadStatusMaxFieldsResolvers<ContextType>;
  demiplane_task_read_status_min_fields?: DemiplaneTaskReadStatusMinFieldsResolvers<ContextType>;
  demiplane_task_read_status_mutation_response?: DemiplaneTaskReadStatusMutationResponseResolvers<ContextType>;
  demiplane_task_read_status_stddev_fields?: DemiplaneTaskReadStatusStddevFieldsResolvers<ContextType>;
  demiplane_task_read_status_stddev_pop_fields?: DemiplaneTaskReadStatusStddevPopFieldsResolvers<ContextType>;
  demiplane_task_read_status_stddev_samp_fields?: DemiplaneTaskReadStatusStddevSampFieldsResolvers<ContextType>;
  demiplane_task_read_status_sum_fields?: DemiplaneTaskReadStatusSumFieldsResolvers<ContextType>;
  demiplane_task_read_status_var_pop_fields?: DemiplaneTaskReadStatusVarPopFieldsResolvers<ContextType>;
  demiplane_task_read_status_var_samp_fields?: DemiplaneTaskReadStatusVarSampFieldsResolvers<ContextType>;
  demiplane_task_read_status_variance_fields?: DemiplaneTaskReadStatusVarianceFieldsResolvers<ContextType>;
  demiplane_task_stddev_fields?: DemiplaneTaskStddevFieldsResolvers<ContextType>;
  demiplane_task_stddev_pop_fields?: DemiplaneTaskStddevPopFieldsResolvers<ContextType>;
  demiplane_task_stddev_samp_fields?: DemiplaneTaskStddevSampFieldsResolvers<ContextType>;
  demiplane_task_sum_fields?: DemiplaneTaskSumFieldsResolvers<ContextType>;
  demiplane_task_var_pop_fields?: DemiplaneTaskVarPopFieldsResolvers<ContextType>;
  demiplane_task_var_samp_fields?: DemiplaneTaskVarSampFieldsResolvers<ContextType>;
  demiplane_task_variance_fields?: DemiplaneTaskVarianceFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region?: DemiplaneTaxNexusRegionResolvers<ContextType>;
  demiplane_tax_nexus_region_aggregate?: DemiplaneTaxNexusRegionAggregateResolvers<ContextType>;
  demiplane_tax_nexus_region_aggregate_fields?: DemiplaneTaxNexusRegionAggregateFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_avg_fields?: DemiplaneTaxNexusRegionAvgFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_max_fields?: DemiplaneTaxNexusRegionMaxFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_min_fields?: DemiplaneTaxNexusRegionMinFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_mutation_response?: DemiplaneTaxNexusRegionMutationResponseResolvers<ContextType>;
  demiplane_tax_nexus_region_stddev_fields?: DemiplaneTaxNexusRegionStddevFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_stddev_pop_fields?: DemiplaneTaxNexusRegionStddevPopFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_stddev_samp_fields?: DemiplaneTaxNexusRegionStddevSampFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_sum_fields?: DemiplaneTaxNexusRegionSumFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_var_pop_fields?: DemiplaneTaxNexusRegionVarPopFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_var_samp_fields?: DemiplaneTaxNexusRegionVarSampFieldsResolvers<ContextType>;
  demiplane_tax_nexus_region_variance_fields?: DemiplaneTaxNexusRegionVarianceFieldsResolvers<ContextType>;
  demiplane_transaction_receipt?: DemiplaneTransactionReceiptResolvers<ContextType>;
  demiplane_transaction_receipt_aggregate?: DemiplaneTransactionReceiptAggregateResolvers<ContextType>;
  demiplane_transaction_receipt_aggregate_fields?: DemiplaneTransactionReceiptAggregateFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_avg_fields?: DemiplaneTransactionReceiptAvgFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_max_fields?: DemiplaneTransactionReceiptMaxFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_min_fields?: DemiplaneTransactionReceiptMinFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_mutation_response?: DemiplaneTransactionReceiptMutationResponseResolvers<ContextType>;
  demiplane_transaction_receipt_stddev_fields?: DemiplaneTransactionReceiptStddevFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_stddev_pop_fields?: DemiplaneTransactionReceiptStddevPopFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_stddev_samp_fields?: DemiplaneTransactionReceiptStddevSampFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_sum_fields?: DemiplaneTransactionReceiptSumFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_var_pop_fields?: DemiplaneTransactionReceiptVarPopFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_var_samp_fields?: DemiplaneTransactionReceiptVarSampFieldsResolvers<ContextType>;
  demiplane_transaction_receipt_variance_fields?: DemiplaneTransactionReceiptVarianceFieldsResolvers<ContextType>;
  demiplane_upload?: DemiplaneUploadResolvers<ContextType>;
  demiplane_upload_aggregate?: DemiplaneUploadAggregateResolvers<ContextType>;
  demiplane_upload_aggregate_fields?: DemiplaneUploadAggregateFieldsResolvers<ContextType>;
  demiplane_upload_avg_fields?: DemiplaneUploadAvgFieldsResolvers<ContextType>;
  demiplane_upload_max_fields?: DemiplaneUploadMaxFieldsResolvers<ContextType>;
  demiplane_upload_min_fields?: DemiplaneUploadMinFieldsResolvers<ContextType>;
  demiplane_upload_mutation_response?: DemiplaneUploadMutationResponseResolvers<ContextType>;
  demiplane_upload_stddev_fields?: DemiplaneUploadStddevFieldsResolvers<ContextType>;
  demiplane_upload_stddev_pop_fields?: DemiplaneUploadStddevPopFieldsResolvers<ContextType>;
  demiplane_upload_stddev_samp_fields?: DemiplaneUploadStddevSampFieldsResolvers<ContextType>;
  demiplane_upload_sum_fields?: DemiplaneUploadSumFieldsResolvers<ContextType>;
  demiplane_upload_var_pop_fields?: DemiplaneUploadVarPopFieldsResolvers<ContextType>;
  demiplane_upload_var_samp_fields?: DemiplaneUploadVarSampFieldsResolvers<ContextType>;
  demiplane_upload_variance_fields?: DemiplaneUploadVarianceFieldsResolvers<ContextType>;
  demiplane_urap?: DemiplaneUrapResolvers<ContextType>;
  demiplane_urap_aggregate?: DemiplaneUrapAggregateResolvers<ContextType>;
  demiplane_urap_aggregate_fields?: DemiplaneUrapAggregateFieldsResolvers<ContextType>;
  demiplane_urap_avg_fields?: DemiplaneUrapAvgFieldsResolvers<ContextType>;
  demiplane_urap_max_fields?: DemiplaneUrapMaxFieldsResolvers<ContextType>;
  demiplane_urap_min_fields?: DemiplaneUrapMinFieldsResolvers<ContextType>;
  demiplane_urap_mutation_response?: DemiplaneUrapMutationResponseResolvers<ContextType>;
  demiplane_urap_stddev_fields?: DemiplaneUrapStddevFieldsResolvers<ContextType>;
  demiplane_urap_stddev_pop_fields?: DemiplaneUrapStddevPopFieldsResolvers<ContextType>;
  demiplane_urap_stddev_samp_fields?: DemiplaneUrapStddevSampFieldsResolvers<ContextType>;
  demiplane_urap_sum_fields?: DemiplaneUrapSumFieldsResolvers<ContextType>;
  demiplane_urap_var_pop_fields?: DemiplaneUrapVarPopFieldsResolvers<ContextType>;
  demiplane_urap_var_samp_fields?: DemiplaneUrapVarSampFieldsResolvers<ContextType>;
  demiplane_urap_variance_fields?: DemiplaneUrapVarianceFieldsResolvers<ContextType>;
  demiplane_urgt?: DemiplaneUrgtResolvers<ContextType>;
  demiplane_urgt_aggregate?: DemiplaneUrgtAggregateResolvers<ContextType>;
  demiplane_urgt_aggregate_fields?: DemiplaneUrgtAggregateFieldsResolvers<ContextType>;
  demiplane_urgt_avg_fields?: DemiplaneUrgtAvgFieldsResolvers<ContextType>;
  demiplane_urgt_max_fields?: DemiplaneUrgtMaxFieldsResolvers<ContextType>;
  demiplane_urgt_min_fields?: DemiplaneUrgtMinFieldsResolvers<ContextType>;
  demiplane_urgt_mutation_response?: DemiplaneUrgtMutationResponseResolvers<ContextType>;
  demiplane_urgt_stddev_fields?: DemiplaneUrgtStddevFieldsResolvers<ContextType>;
  demiplane_urgt_stddev_pop_fields?: DemiplaneUrgtStddevPopFieldsResolvers<ContextType>;
  demiplane_urgt_stddev_samp_fields?: DemiplaneUrgtStddevSampFieldsResolvers<ContextType>;
  demiplane_urgt_sum_fields?: DemiplaneUrgtSumFieldsResolvers<ContextType>;
  demiplane_urgt_var_pop_fields?: DemiplaneUrgtVarPopFieldsResolvers<ContextType>;
  demiplane_urgt_var_samp_fields?: DemiplaneUrgtVarSampFieldsResolvers<ContextType>;
  demiplane_urgt_variance_fields?: DemiplaneUrgtVarianceFieldsResolvers<ContextType>;
  demiplane_user?: DemiplaneUserResolvers<ContextType>;
  demiplane_user_activity?: DemiplaneUserActivityResolvers<ContextType>;
  demiplane_user_activity_aggregate?: DemiplaneUserActivityAggregateResolvers<ContextType>;
  demiplane_user_activity_aggregate_fields?: DemiplaneUserActivityAggregateFieldsResolvers<ContextType>;
  demiplane_user_activity_avg_fields?: DemiplaneUserActivityAvgFieldsResolvers<ContextType>;
  demiplane_user_activity_max_fields?: DemiplaneUserActivityMaxFieldsResolvers<ContextType>;
  demiplane_user_activity_min_fields?: DemiplaneUserActivityMinFieldsResolvers<ContextType>;
  demiplane_user_activity_mutation_response?: DemiplaneUserActivityMutationResponseResolvers<ContextType>;
  demiplane_user_activity_stddev_fields?: DemiplaneUserActivityStddevFieldsResolvers<ContextType>;
  demiplane_user_activity_stddev_pop_fields?: DemiplaneUserActivityStddevPopFieldsResolvers<ContextType>;
  demiplane_user_activity_stddev_samp_fields?: DemiplaneUserActivityStddevSampFieldsResolvers<ContextType>;
  demiplane_user_activity_sum_fields?: DemiplaneUserActivitySumFieldsResolvers<ContextType>;
  demiplane_user_activity_var_pop_fields?: DemiplaneUserActivityVarPopFieldsResolvers<ContextType>;
  demiplane_user_activity_var_samp_fields?: DemiplaneUserActivityVarSampFieldsResolvers<ContextType>;
  demiplane_user_activity_variance_fields?: DemiplaneUserActivityVarianceFieldsResolvers<ContextType>;
  demiplane_user_adventure?: DemiplaneUserAdventureResolvers<ContextType>;
  demiplane_user_adventure_aggregate?: DemiplaneUserAdventureAggregateResolvers<ContextType>;
  demiplane_user_adventure_aggregate_fields?: DemiplaneUserAdventureAggregateFieldsResolvers<ContextType>;
  demiplane_user_adventure_avg_fields?: DemiplaneUserAdventureAvgFieldsResolvers<ContextType>;
  demiplane_user_adventure_max_fields?: DemiplaneUserAdventureMaxFieldsResolvers<ContextType>;
  demiplane_user_adventure_min_fields?: DemiplaneUserAdventureMinFieldsResolvers<ContextType>;
  demiplane_user_adventure_mutation_response?: DemiplaneUserAdventureMutationResponseResolvers<ContextType>;
  demiplane_user_adventure_ranking?: DemiplaneUserAdventureRankingResolvers<ContextType>;
  demiplane_user_adventure_ranking_aggregate?: DemiplaneUserAdventureRankingAggregateResolvers<ContextType>;
  demiplane_user_adventure_ranking_aggregate_fields?: DemiplaneUserAdventureRankingAggregateFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_avg_fields?: DemiplaneUserAdventureRankingAvgFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_max_fields?: DemiplaneUserAdventureRankingMaxFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_min_fields?: DemiplaneUserAdventureRankingMinFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_mutation_response?: DemiplaneUserAdventureRankingMutationResponseResolvers<ContextType>;
  demiplane_user_adventure_ranking_stddev_fields?: DemiplaneUserAdventureRankingStddevFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_stddev_pop_fields?: DemiplaneUserAdventureRankingStddevPopFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_stddev_samp_fields?: DemiplaneUserAdventureRankingStddevSampFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_sum_fields?: DemiplaneUserAdventureRankingSumFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_var_pop_fields?: DemiplaneUserAdventureRankingVarPopFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_var_samp_fields?: DemiplaneUserAdventureRankingVarSampFieldsResolvers<ContextType>;
  demiplane_user_adventure_ranking_variance_fields?: DemiplaneUserAdventureRankingVarianceFieldsResolvers<ContextType>;
  demiplane_user_adventure_role?: DemiplaneUserAdventureRoleResolvers<ContextType>;
  demiplane_user_adventure_role_aggregate?: DemiplaneUserAdventureRoleAggregateResolvers<ContextType>;
  demiplane_user_adventure_role_aggregate_fields?: DemiplaneUserAdventureRoleAggregateFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_avg_fields?: DemiplaneUserAdventureRoleAvgFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_max_fields?: DemiplaneUserAdventureRoleMaxFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_min_fields?: DemiplaneUserAdventureRoleMinFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_mutation_response?: DemiplaneUserAdventureRoleMutationResponseResolvers<ContextType>;
  demiplane_user_adventure_role_stddev_fields?: DemiplaneUserAdventureRoleStddevFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_stddev_pop_fields?: DemiplaneUserAdventureRoleStddevPopFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_stddev_samp_fields?: DemiplaneUserAdventureRoleStddevSampFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_sum_fields?: DemiplaneUserAdventureRoleSumFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_var_pop_fields?: DemiplaneUserAdventureRoleVarPopFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_var_samp_fields?: DemiplaneUserAdventureRoleVarSampFieldsResolvers<ContextType>;
  demiplane_user_adventure_role_variance_fields?: DemiplaneUserAdventureRoleVarianceFieldsResolvers<ContextType>;
  demiplane_user_adventure_stddev_fields?: DemiplaneUserAdventureStddevFieldsResolvers<ContextType>;
  demiplane_user_adventure_stddev_pop_fields?: DemiplaneUserAdventureStddevPopFieldsResolvers<ContextType>;
  demiplane_user_adventure_stddev_samp_fields?: DemiplaneUserAdventureStddevSampFieldsResolvers<ContextType>;
  demiplane_user_adventure_sum_fields?: DemiplaneUserAdventureSumFieldsResolvers<ContextType>;
  demiplane_user_adventure_var_pop_fields?: DemiplaneUserAdventureVarPopFieldsResolvers<ContextType>;
  demiplane_user_adventure_var_samp_fields?: DemiplaneUserAdventureVarSampFieldsResolvers<ContextType>;
  demiplane_user_adventure_variance_fields?: DemiplaneUserAdventureVarianceFieldsResolvers<ContextType>;
  demiplane_user_aggregate?: DemiplaneUserAggregateResolvers<ContextType>;
  demiplane_user_aggregate_fields?: DemiplaneUserAggregateFieldsResolvers<ContextType>;
  demiplane_user_avg_fields?: DemiplaneUserAvgFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref?: DemiplaneUserEmailNotificationPrefResolvers<ContextType>;
  demiplane_user_email_notification_pref_aggregate?: DemiplaneUserEmailNotificationPrefAggregateResolvers<ContextType>;
  demiplane_user_email_notification_pref_aggregate_fields?: DemiplaneUserEmailNotificationPrefAggregateFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_avg_fields?: DemiplaneUserEmailNotificationPrefAvgFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_max_fields?: DemiplaneUserEmailNotificationPrefMaxFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_min_fields?: DemiplaneUserEmailNotificationPrefMinFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_mutation_response?: DemiplaneUserEmailNotificationPrefMutationResponseResolvers<ContextType>;
  demiplane_user_email_notification_pref_stddev_fields?: DemiplaneUserEmailNotificationPrefStddevFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_stddev_pop_fields?: DemiplaneUserEmailNotificationPrefStddevPopFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_stddev_samp_fields?: DemiplaneUserEmailNotificationPrefStddevSampFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_sum_fields?: DemiplaneUserEmailNotificationPrefSumFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_var_pop_fields?: DemiplaneUserEmailNotificationPrefVarPopFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_var_samp_fields?: DemiplaneUserEmailNotificationPrefVarSampFieldsResolvers<ContextType>;
  demiplane_user_email_notification_pref_variance_fields?: DemiplaneUserEmailNotificationPrefVarianceFieldsResolvers<ContextType>;
  demiplane_user_invitation?: DemiplaneUserInvitationResolvers<ContextType>;
  demiplane_user_invitation_aggregate?: DemiplaneUserInvitationAggregateResolvers<ContextType>;
  demiplane_user_invitation_aggregate_fields?: DemiplaneUserInvitationAggregateFieldsResolvers<ContextType>;
  demiplane_user_invitation_avg_fields?: DemiplaneUserInvitationAvgFieldsResolvers<ContextType>;
  demiplane_user_invitation_max_fields?: DemiplaneUserInvitationMaxFieldsResolvers<ContextType>;
  demiplane_user_invitation_min_fields?: DemiplaneUserInvitationMinFieldsResolvers<ContextType>;
  demiplane_user_invitation_mutation_response?: DemiplaneUserInvitationMutationResponseResolvers<ContextType>;
  demiplane_user_invitation_stddev_fields?: DemiplaneUserInvitationStddevFieldsResolvers<ContextType>;
  demiplane_user_invitation_stddev_pop_fields?: DemiplaneUserInvitationStddevPopFieldsResolvers<ContextType>;
  demiplane_user_invitation_stddev_samp_fields?: DemiplaneUserInvitationStddevSampFieldsResolvers<ContextType>;
  demiplane_user_invitation_sum_fields?: DemiplaneUserInvitationSumFieldsResolvers<ContextType>;
  demiplane_user_invitation_var_pop_fields?: DemiplaneUserInvitationVarPopFieldsResolvers<ContextType>;
  demiplane_user_invitation_var_samp_fields?: DemiplaneUserInvitationVarSampFieldsResolvers<ContextType>;
  demiplane_user_invitation_variance_fields?: DemiplaneUserInvitationVarianceFieldsResolvers<ContextType>;
  demiplane_user_max_fields?: DemiplaneUserMaxFieldsResolvers<ContextType>;
  demiplane_user_metadata?: DemiplaneUserMetadataResolvers<ContextType>;
  demiplane_user_metadata_aggregate?: DemiplaneUserMetadataAggregateResolvers<ContextType>;
  demiplane_user_metadata_aggregate_fields?: DemiplaneUserMetadataAggregateFieldsResolvers<ContextType>;
  demiplane_user_metadata_avg_fields?: DemiplaneUserMetadataAvgFieldsResolvers<ContextType>;
  demiplane_user_metadata_max_fields?: DemiplaneUserMetadataMaxFieldsResolvers<ContextType>;
  demiplane_user_metadata_min_fields?: DemiplaneUserMetadataMinFieldsResolvers<ContextType>;
  demiplane_user_metadata_mutation_response?: DemiplaneUserMetadataMutationResponseResolvers<ContextType>;
  demiplane_user_metadata_stddev_fields?: DemiplaneUserMetadataStddevFieldsResolvers<ContextType>;
  demiplane_user_metadata_stddev_pop_fields?: DemiplaneUserMetadataStddevPopFieldsResolvers<ContextType>;
  demiplane_user_metadata_stddev_samp_fields?: DemiplaneUserMetadataStddevSampFieldsResolvers<ContextType>;
  demiplane_user_metadata_sum_fields?: DemiplaneUserMetadataSumFieldsResolvers<ContextType>;
  demiplane_user_metadata_var_pop_fields?: DemiplaneUserMetadataVarPopFieldsResolvers<ContextType>;
  demiplane_user_metadata_var_samp_fields?: DemiplaneUserMetadataVarSampFieldsResolvers<ContextType>;
  demiplane_user_metadata_variance_fields?: DemiplaneUserMetadataVarianceFieldsResolvers<ContextType>;
  demiplane_user_min_fields?: DemiplaneUserMinFieldsResolvers<ContextType>;
  demiplane_user_mutation_response?: DemiplaneUserMutationResponseResolvers<ContextType>;
  demiplane_user_push_notification_pref?: DemiplaneUserPushNotificationPrefResolvers<ContextType>;
  demiplane_user_push_notification_pref_aggregate?: DemiplaneUserPushNotificationPrefAggregateResolvers<ContextType>;
  demiplane_user_push_notification_pref_aggregate_fields?: DemiplaneUserPushNotificationPrefAggregateFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_avg_fields?: DemiplaneUserPushNotificationPrefAvgFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_max_fields?: DemiplaneUserPushNotificationPrefMaxFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_min_fields?: DemiplaneUserPushNotificationPrefMinFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_mutation_response?: DemiplaneUserPushNotificationPrefMutationResponseResolvers<ContextType>;
  demiplane_user_push_notification_pref_stddev_fields?: DemiplaneUserPushNotificationPrefStddevFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_stddev_pop_fields?: DemiplaneUserPushNotificationPrefStddevPopFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_stddev_samp_fields?: DemiplaneUserPushNotificationPrefStddevSampFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_sum_fields?: DemiplaneUserPushNotificationPrefSumFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_var_pop_fields?: DemiplaneUserPushNotificationPrefVarPopFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_var_samp_fields?: DemiplaneUserPushNotificationPrefVarSampFieldsResolvers<ContextType>;
  demiplane_user_push_notification_pref_variance_fields?: DemiplaneUserPushNotificationPrefVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment?: DemiplaneUserRecruitmentResolvers<ContextType>;
  demiplane_user_recruitment_adventurer?: DemiplaneUserRecruitmentAdventurerResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_aggregate?: DemiplaneUserRecruitmentAdventurerAggregateResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_aggregate_fields?: DemiplaneUserRecruitmentAdventurerAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability?: DemiplaneUserRecruitmentAdventurerAvailabilityResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_aggregate?: DemiplaneUserRecruitmentAdventurerAvailabilityAggregateResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_aggregate_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_avg_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_max_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_min_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_mutation_response?: DemiplaneUserRecruitmentAdventurerAvailabilityMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_stddev_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_stddev_pop_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_stddev_samp_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_sum_fields?: DemiplaneUserRecruitmentAdventurerAvailabilitySumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_var_pop_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_var_samp_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_availability_variance_fields?: DemiplaneUserRecruitmentAdventurerAvailabilityVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_avg_fields?: DemiplaneUserRecruitmentAdventurerAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games?: DemiplaneUserRecruitmentAdventurerGamesResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_aggregate?: DemiplaneUserRecruitmentAdventurerGamesAggregateResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_aggregate_fields?: DemiplaneUserRecruitmentAdventurerGamesAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_avg_fields?: DemiplaneUserRecruitmentAdventurerGamesAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_max_fields?: DemiplaneUserRecruitmentAdventurerGamesMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_min_fields?: DemiplaneUserRecruitmentAdventurerGamesMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_mutation_response?: DemiplaneUserRecruitmentAdventurerGamesMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_stddev_fields?: DemiplaneUserRecruitmentAdventurerGamesStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_stddev_pop_fields?: DemiplaneUserRecruitmentAdventurerGamesStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_stddev_samp_fields?: DemiplaneUserRecruitmentAdventurerGamesStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_sum_fields?: DemiplaneUserRecruitmentAdventurerGamesSumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_var_pop_fields?: DemiplaneUserRecruitmentAdventurerGamesVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_var_samp_fields?: DemiplaneUserRecruitmentAdventurerGamesVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_games_variance_fields?: DemiplaneUserRecruitmentAdventurerGamesVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_max_fields?: DemiplaneUserRecruitmentAdventurerMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_min_fields?: DemiplaneUserRecruitmentAdventurerMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_mutation_response?: DemiplaneUserRecruitmentAdventurerMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms?: DemiplaneUserRecruitmentAdventurerPlatformsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_aggregate?: DemiplaneUserRecruitmentAdventurerPlatformsAggregateResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_aggregate_fields?: DemiplaneUserRecruitmentAdventurerPlatformsAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_avg_fields?: DemiplaneUserRecruitmentAdventurerPlatformsAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_max_fields?: DemiplaneUserRecruitmentAdventurerPlatformsMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_min_fields?: DemiplaneUserRecruitmentAdventurerPlatformsMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_mutation_response?: DemiplaneUserRecruitmentAdventurerPlatformsMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_stddev_fields?: DemiplaneUserRecruitmentAdventurerPlatformsStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_stddev_pop_fields?: DemiplaneUserRecruitmentAdventurerPlatformsStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_stddev_samp_fields?: DemiplaneUserRecruitmentAdventurerPlatformsStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_sum_fields?: DemiplaneUserRecruitmentAdventurerPlatformsSumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_var_pop_fields?: DemiplaneUserRecruitmentAdventurerPlatformsVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_var_samp_fields?: DemiplaneUserRecruitmentAdventurerPlatformsVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_platforms_variance_fields?: DemiplaneUserRecruitmentAdventurerPlatformsVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_stddev_fields?: DemiplaneUserRecruitmentAdventurerStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_stddev_pop_fields?: DemiplaneUserRecruitmentAdventurerStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_stddev_samp_fields?: DemiplaneUserRecruitmentAdventurerStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_sum_fields?: DemiplaneUserRecruitmentAdventurerSumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_var_pop_fields?: DemiplaneUserRecruitmentAdventurerVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_var_samp_fields?: DemiplaneUserRecruitmentAdventurerVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_adventurer_variance_fields?: DemiplaneUserRecruitmentAdventurerVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_aggregate?: DemiplaneUserRecruitmentAggregateResolvers<ContextType>;
  demiplane_user_recruitment_aggregate_fields?: DemiplaneUserRecruitmentAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability?: DemiplaneUserRecruitmentAvailabilityResolvers<ContextType>;
  demiplane_user_recruitment_availability_aggregate?: DemiplaneUserRecruitmentAvailabilityAggregateResolvers<ContextType>;
  demiplane_user_recruitment_availability_aggregate_fields?: DemiplaneUserRecruitmentAvailabilityAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_avg_fields?: DemiplaneUserRecruitmentAvailabilityAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_max_fields?: DemiplaneUserRecruitmentAvailabilityMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_min_fields?: DemiplaneUserRecruitmentAvailabilityMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_mutation_response?: DemiplaneUserRecruitmentAvailabilityMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_availability_stddev_fields?: DemiplaneUserRecruitmentAvailabilityStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_stddev_pop_fields?: DemiplaneUserRecruitmentAvailabilityStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_stddev_samp_fields?: DemiplaneUserRecruitmentAvailabilityStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_sum_fields?: DemiplaneUserRecruitmentAvailabilitySumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_var_pop_fields?: DemiplaneUserRecruitmentAvailabilityVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_var_samp_fields?: DemiplaneUserRecruitmentAvailabilityVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_availability_variance_fields?: DemiplaneUserRecruitmentAvailabilityVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_avg_fields?: DemiplaneUserRecruitmentAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master?: DemiplaneUserRecruitmentGameMasterResolvers<ContextType>;
  demiplane_user_recruitment_game_master_aggregate?: DemiplaneUserRecruitmentGameMasterAggregateResolvers<ContextType>;
  demiplane_user_recruitment_game_master_aggregate_fields?: DemiplaneUserRecruitmentGameMasterAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability?: DemiplaneUserRecruitmentGameMasterAvailabilityResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_aggregate?: DemiplaneUserRecruitmentGameMasterAvailabilityAggregateResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_aggregate_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_avg_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_max_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_min_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_mutation_response?: DemiplaneUserRecruitmentGameMasterAvailabilityMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_stddev_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_stddev_pop_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_stddev_samp_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_sum_fields?: DemiplaneUserRecruitmentGameMasterAvailabilitySumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_var_pop_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_var_samp_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_availability_variance_fields?: DemiplaneUserRecruitmentGameMasterAvailabilityVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_avg_fields?: DemiplaneUserRecruitmentGameMasterAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games?: DemiplaneUserRecruitmentGameMasterGamesResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_aggregate?: DemiplaneUserRecruitmentGameMasterGamesAggregateResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_aggregate_fields?: DemiplaneUserRecruitmentGameMasterGamesAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_avg_fields?: DemiplaneUserRecruitmentGameMasterGamesAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_max_fields?: DemiplaneUserRecruitmentGameMasterGamesMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_min_fields?: DemiplaneUserRecruitmentGameMasterGamesMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_mutation_response?: DemiplaneUserRecruitmentGameMasterGamesMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_stddev_fields?: DemiplaneUserRecruitmentGameMasterGamesStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_stddev_pop_fields?: DemiplaneUserRecruitmentGameMasterGamesStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_stddev_samp_fields?: DemiplaneUserRecruitmentGameMasterGamesStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_sum_fields?: DemiplaneUserRecruitmentGameMasterGamesSumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_var_pop_fields?: DemiplaneUserRecruitmentGameMasterGamesVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_var_samp_fields?: DemiplaneUserRecruitmentGameMasterGamesVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_games_variance_fields?: DemiplaneUserRecruitmentGameMasterGamesVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_max_fields?: DemiplaneUserRecruitmentGameMasterMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_min_fields?: DemiplaneUserRecruitmentGameMasterMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_mutation_response?: DemiplaneUserRecruitmentGameMasterMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms?: DemiplaneUserRecruitmentGameMasterPlatformsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_aggregate?: DemiplaneUserRecruitmentGameMasterPlatformsAggregateResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_aggregate_fields?: DemiplaneUserRecruitmentGameMasterPlatformsAggregateFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_avg_fields?: DemiplaneUserRecruitmentGameMasterPlatformsAvgFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_max_fields?: DemiplaneUserRecruitmentGameMasterPlatformsMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_min_fields?: DemiplaneUserRecruitmentGameMasterPlatformsMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_mutation_response?: DemiplaneUserRecruitmentGameMasterPlatformsMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_stddev_fields?: DemiplaneUserRecruitmentGameMasterPlatformsStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_stddev_pop_fields?: DemiplaneUserRecruitmentGameMasterPlatformsStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_stddev_samp_fields?: DemiplaneUserRecruitmentGameMasterPlatformsStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_sum_fields?: DemiplaneUserRecruitmentGameMasterPlatformsSumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_var_pop_fields?: DemiplaneUserRecruitmentGameMasterPlatformsVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_var_samp_fields?: DemiplaneUserRecruitmentGameMasterPlatformsVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_platforms_variance_fields?: DemiplaneUserRecruitmentGameMasterPlatformsVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_stddev_fields?: DemiplaneUserRecruitmentGameMasterStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_stddev_pop_fields?: DemiplaneUserRecruitmentGameMasterStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_stddev_samp_fields?: DemiplaneUserRecruitmentGameMasterStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_sum_fields?: DemiplaneUserRecruitmentGameMasterSumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_var_pop_fields?: DemiplaneUserRecruitmentGameMasterVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_var_samp_fields?: DemiplaneUserRecruitmentGameMasterVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_game_master_variance_fields?: DemiplaneUserRecruitmentGameMasterVarianceFieldsResolvers<ContextType>;
  demiplane_user_recruitment_max_fields?: DemiplaneUserRecruitmentMaxFieldsResolvers<ContextType>;
  demiplane_user_recruitment_min_fields?: DemiplaneUserRecruitmentMinFieldsResolvers<ContextType>;
  demiplane_user_recruitment_mutation_response?: DemiplaneUserRecruitmentMutationResponseResolvers<ContextType>;
  demiplane_user_recruitment_stddev_fields?: DemiplaneUserRecruitmentStddevFieldsResolvers<ContextType>;
  demiplane_user_recruitment_stddev_pop_fields?: DemiplaneUserRecruitmentStddevPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_stddev_samp_fields?: DemiplaneUserRecruitmentStddevSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_sum_fields?: DemiplaneUserRecruitmentSumFieldsResolvers<ContextType>;
  demiplane_user_recruitment_var_pop_fields?: DemiplaneUserRecruitmentVarPopFieldsResolvers<ContextType>;
  demiplane_user_recruitment_var_samp_fields?: DemiplaneUserRecruitmentVarSampFieldsResolvers<ContextType>;
  demiplane_user_recruitment_variance_fields?: DemiplaneUserRecruitmentVarianceFieldsResolvers<ContextType>;
  demiplane_user_role?: DemiplaneUserRoleResolvers<ContextType>;
  demiplane_user_role_aggregate?: DemiplaneUserRoleAggregateResolvers<ContextType>;
  demiplane_user_role_aggregate_fields?: DemiplaneUserRoleAggregateFieldsResolvers<ContextType>;
  demiplane_user_role_avg_fields?: DemiplaneUserRoleAvgFieldsResolvers<ContextType>;
  demiplane_user_role_max_fields?: DemiplaneUserRoleMaxFieldsResolvers<ContextType>;
  demiplane_user_role_min_fields?: DemiplaneUserRoleMinFieldsResolvers<ContextType>;
  demiplane_user_role_mutation_response?: DemiplaneUserRoleMutationResponseResolvers<ContextType>;
  demiplane_user_role_stddev_fields?: DemiplaneUserRoleStddevFieldsResolvers<ContextType>;
  demiplane_user_role_stddev_pop_fields?: DemiplaneUserRoleStddevPopFieldsResolvers<ContextType>;
  demiplane_user_role_stddev_samp_fields?: DemiplaneUserRoleStddevSampFieldsResolvers<ContextType>;
  demiplane_user_role_sum_fields?: DemiplaneUserRoleSumFieldsResolvers<ContextType>;
  demiplane_user_role_var_pop_fields?: DemiplaneUserRoleVarPopFieldsResolvers<ContextType>;
  demiplane_user_role_var_samp_fields?: DemiplaneUserRoleVarSampFieldsResolvers<ContextType>;
  demiplane_user_role_variance_fields?: DemiplaneUserRoleVarianceFieldsResolvers<ContextType>;
  demiplane_user_stddev_fields?: DemiplaneUserStddevFieldsResolvers<ContextType>;
  demiplane_user_stddev_pop_fields?: DemiplaneUserStddevPopFieldsResolvers<ContextType>;
  demiplane_user_stddev_samp_fields?: DemiplaneUserStddevSampFieldsResolvers<ContextType>;
  demiplane_user_sum_fields?: DemiplaneUserSumFieldsResolvers<ContextType>;
  demiplane_user_var_pop_fields?: DemiplaneUserVarPopFieldsResolvers<ContextType>;
  demiplane_user_var_samp_fields?: DemiplaneUserVarSampFieldsResolvers<ContextType>;
  demiplane_user_variance_fields?: DemiplaneUserVarianceFieldsResolvers<ContextType>;
  demiplane_user_visibility?: DemiplaneUserVisibilityResolvers<ContextType>;
  demiplane_user_visibility_aggregate?: DemiplaneUserVisibilityAggregateResolvers<ContextType>;
  demiplane_user_visibility_aggregate_fields?: DemiplaneUserVisibilityAggregateFieldsResolvers<ContextType>;
  demiplane_user_visibility_avg_fields?: DemiplaneUserVisibilityAvgFieldsResolvers<ContextType>;
  demiplane_user_visibility_max_fields?: DemiplaneUserVisibilityMaxFieldsResolvers<ContextType>;
  demiplane_user_visibility_min_fields?: DemiplaneUserVisibilityMinFieldsResolvers<ContextType>;
  demiplane_user_visibility_mutation_response?: DemiplaneUserVisibilityMutationResponseResolvers<ContextType>;
  demiplane_user_visibility_stddev_fields?: DemiplaneUserVisibilityStddevFieldsResolvers<ContextType>;
  demiplane_user_visibility_stddev_pop_fields?: DemiplaneUserVisibilityStddevPopFieldsResolvers<ContextType>;
  demiplane_user_visibility_stddev_samp_fields?: DemiplaneUserVisibilityStddevSampFieldsResolvers<ContextType>;
  demiplane_user_visibility_sum_fields?: DemiplaneUserVisibilitySumFieldsResolvers<ContextType>;
  demiplane_user_visibility_var_pop_fields?: DemiplaneUserVisibilityVarPopFieldsResolvers<ContextType>;
  demiplane_user_visibility_var_samp_fields?: DemiplaneUserVisibilityVarSampFieldsResolvers<ContextType>;
  demiplane_user_visibility_variance_fields?: DemiplaneUserVisibilityVarianceFieldsResolvers<ContextType>;
  json?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  mutation_root?: MutationRootResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  query_root?: QueryRootResolvers<ContextType>;
  subscription_root?: SubscriptionRootResolvers<ContextType>;
  time?: GraphQLScalarType;
  timestamp?: GraphQLScalarType;
  timestamptz?: GraphQLScalarType;
  uuid?: GraphQLScalarType;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export const GameAvailabilityAdTimeFieldsFragmentDoc = gql`
    fragment GameAvailabilityAdTimeFields on demiplane_ad_time {
  id
  dow
  start_time
  end_time
  ad {
    id
  }
}
    `;
export const GameAvailabilitySessionFieldsFragmentDoc = gql`
    fragment GameAvailabilitySessionFields on demiplane_session {
  scheduled_date
}
    `;
export const GameAvailabilityAdventureFieldsFragmentDoc = gql`
    fragment GameAvailabilityAdventureFields on demiplane_adventure {
  id
  frequency
  game_cost
  match_making_enabled
  match_making_status_adv_type
  match_making_status_game_avail
  ad_times {
    ...GameAvailabilityAdTimeFields
  }
  sessions(
    where: {completed: {_eq: false}, cancelled: {_eq: false}, end_date: {_is_null: true}}
    order_by: {created: desc}
  ) {
    ...GameAvailabilitySessionFields
  }
}
    ${GameAvailabilityAdTimeFieldsFragmentDoc}
${GameAvailabilitySessionFieldsFragmentDoc}`;
export const UpdateGameAvailabilityAdTimeResetDocument = gql`
    mutation updateGameAvailabilityAdTimeReset($adTimesToDelete: [bigint!], $adventureId: bigint!, $matchMakingStatusGameAvail: Boolean!, $matchMakingEnabled: Boolean!) {
  delete_demiplane_ad_time(where: {id: {_in: $adTimesToDelete}}) {
    affected_rows
    returning {
      adventure {
        id
        ad_times {
          id
        }
      }
    }
  }
  update_demiplane_adventure_by_pk(
    pk_columns: {id: $adventureId}
    _set: {match_making_status_game_avail: $matchMakingStatusGameAvail, match_making_enabled: $matchMakingEnabled, updated: "now()"}
  ) {
    id
    frequency
    match_making_enabled
    match_making_status_adv_type
    match_making_status_game_avail
    updated
  }
}
    `;

export function useUpdateGameAvailabilityAdTimeResetMutation() {
  return Urql.useMutation<UpdateGameAvailabilityAdTimeResetMutation, UpdateGameAvailabilityAdTimeResetMutationVariables>(UpdateGameAvailabilityAdTimeResetDocument);
};
export const UpdateGameAvailabilityAdTimeUpdateDocument = gql`
    mutation updateGameAvailabilityAdTimeUpdate($adTimes: [demiplane_ad_time_insert_input!]!, $adTimesToDelete: [bigint!], $adventureId: bigint!, $matchMakingStatusGameAvail: Boolean!, $matchMakingEnabled: Boolean!) {
  insert_demiplane_ad_time(
    objects: $adTimes
    on_conflict: {constraint: pk_ad_time_id, update_columns: [start_time, end_time, updated]}
  ) {
    affected_rows
    returning {
      adventure {
        id
        ad_times {
          id
        }
      }
    }
  }
  delete_demiplane_ad_time(where: {id: {_in: $adTimesToDelete}}) {
    affected_rows
    returning {
      adventure {
        id
        ad_times {
          id
        }
      }
    }
  }
  update_demiplane_adventure_by_pk(
    pk_columns: {id: $adventureId}
    _set: {match_making_status_game_avail: $matchMakingStatusGameAvail, match_making_enabled: $matchMakingEnabled, updated: "now()"}
  ) {
    id
    frequency
    match_making_enabled
    match_making_status_adv_type
    match_making_status_game_avail
    updated
  }
}
    `;

export function useUpdateGameAvailabilityAdTimeUpdateMutation() {
  return Urql.useMutation<UpdateGameAvailabilityAdTimeUpdateMutation, UpdateGameAvailabilityAdTimeUpdateMutationVariables>(UpdateGameAvailabilityAdTimeUpdateDocument);
};
export const UpdateGameAvailabilitySessionResetDocument = gql`
    mutation updateGameAvailabilitySessionReset($adventureId: bigint!) {
  update_demiplane_session(
    where: {_and: {adventure_id: {_eq: $adventureId}, completed: {_eq: false}, cancelled: {_eq: false}}}
    _set: {cancelled: true, cancelled_date: "now()", updated: "now()"}
  ) {
    returning {
      adventure {
        id
      }
      cancelled
      cancelled_date
      completed
      id
      updated
    }
  }
}
    `;

export function useUpdateGameAvailabilitySessionResetMutation() {
  return Urql.useMutation<UpdateGameAvailabilitySessionResetMutation, UpdateGameAvailabilitySessionResetMutationVariables>(UpdateGameAvailabilitySessionResetDocument);
};
export const UpdateGameAvailabilitySessionUpdateDocument = gql`
    mutation updateGameAvailabilitySessionUpdate($adventureId: bigint!, $scheduledDate: timestamptz!, $sessionCost: Int!) {
  insert_demiplane_session_one(
    object: {adventure_id: $adventureId, scheduled_date: $scheduledDate, cost: $sessionCost, open: false, live: false, completed: false}
  ) {
    id
    adventure {
      id
    }
    cancelled
    completed
    cost
    end_date
    live
    open
    open_date
    scheduled_date
    start_date
  }
  update_demiplane_adventure_by_pk(
    pk_columns: {id: $adventureId}
    _set: {match_making_status_game_avail: true, updated: "now()"}
  ) {
    id
    frequency
    match_making_enabled
    match_making_status_game_avail
    updated
  }
}
    `;

export function useUpdateGameAvailabilitySessionUpdateMutation() {
  return Urql.useMutation<UpdateGameAvailabilitySessionUpdateMutation, UpdateGameAvailabilitySessionUpdateMutationVariables>(UpdateGameAvailabilitySessionUpdateDocument);
};
export const GameAvailabilityDocument = gql`
    query gameAvailability($id: bigint!) {
  demiplane_adventure_by_pk(id: $id) {
    ...GameAvailabilityAdventureFields
  }
}
    ${GameAvailabilityAdventureFieldsFragmentDoc}`;

export function useGameAvailabilityQuery(options: Omit<Urql.UseQueryArgs<GameAvailabilityQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GameAvailabilityQuery>({ query: GameAvailabilityDocument, ...options });
};
export const GetAdventuringPlatformsDocument = gql`
    query getAdventuringPlatforms {
  demiplane_adventuring_platform(order_by: {name: asc}) {
    active
    created
    name
    id
    updated
  }
}
    `;

export function useGetAdventuringPlatformsQuery(options: Omit<Urql.UseQueryArgs<GetAdventuringPlatformsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAdventuringPlatformsQuery>({ query: GetAdventuringPlatformsDocument, ...options });
};
export const AuthMmDocument = gql`
    mutation authMM($token: String!) {
  authMM(token: $token) {
    message
    success
    session
  }
}
    `;

export function useAuthMmMutation() {
  return Urql.useMutation<AuthMmMutation, AuthMmMutationVariables>(AuthMmDocument);
};
export const GetGameTypesDocument = gql`
    query getGameTypes {
  demiplane_game_type(order_by: {name: asc}) {
    id
    code
    created
    name
    updated
    active
  }
}
    `;

export function useGetGameTypesQuery(options: Omit<Urql.UseQueryArgs<GetGameTypesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetGameTypesQuery>({ query: GetGameTypesDocument, ...options });
};
export const SearchAdDetailMmDocument = gql`
    query searchAdDetailMM($adId: Int!) {
  searchAdDetailMM(adId: $adId) {
    message
    success
    result
  }
}
    `;

export function useSearchAdDetailMmQuery(options: Omit<Urql.UseQueryArgs<SearchAdDetailMmQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SearchAdDetailMmQuery>({ query: SearchAdDetailMmDocument, ...options });
};
export const SearchAdsMmDocument = gql`
    query searchAdsMM($adventuringPlatformIds: [Int!]!, $availability: [AvailabilityInputMM!]!, $frequencyOneTime: Boolean!, $frequencyRecurring: Boolean!, $gameTypeIds: [Int!]!, $newPlayer: Boolean!, $noCost: Boolean!, $quickMatch: Boolean!, $recordCount: Int!, $costMax: Int, $adCreator: [String!], $numSeatsOpenMax: Int, $numSeatsOpenMin: Int, $gameMaster: [String!], $sortBy: [String!]) {
  searchAdsMM(
    adventuringPlatformIds: $adventuringPlatformIds
    availability: $availability
    frequencyOneTime: $frequencyOneTime
    frequencyRecurring: $frequencyRecurring
    gameTypeIds: $gameTypeIds
    newPlayer: $newPlayer
    noCost: $noCost
    quickMatch: $quickMatch
    recordCount: $recordCount
    costMax: $costMax
    adCreator: $adCreator
    numSeatsOpenMax: $numSeatsOpenMax
    numSeatsOpenMin: $numSeatsOpenMin
    gameMaster: $gameMaster
    sortBy: $sortBy
  ) {
    message
    success
    result
  }
}
    `;

export function useSearchAdsMmQuery(options: Omit<Urql.UseQueryArgs<SearchAdsMmQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SearchAdsMmQuery>({ query: SearchAdsMmDocument, ...options });
};