import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    appContainer: {
      height: `${theme.viewHeight}px`,
      background: theme.palette.common.white,
    },
  };
});
