import { createMuiTheme, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    cursor: 'pointer',
  },
  error: {
    fontSize: '0.75rem',
    color: theme.palette.warning.main,
  },
  keyboardIcon: {
    width: '1.5rem',
    height: '1.5rem',

    position: 'absolute',
    top: '1.25rem',
    right: 0,

    pointerEvents: 'none',
  },
  input: {
    textAlign: 'center',
    margin: 'auto',
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: '0.75rem',
    color: '#3276B5',
    cursor: 'pointer',
  },
  inputDisabled: {
    opacity: 0.2,
    cursor: 'default',
  },
}));

export const theme = createMuiTheme({
  viewHeight: window.innerHeight,
  palette: {
    primary: {
      main: '#33a6d7',
      light: '#33a6d7',
      dark: '#3276b5',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#3276B5',
    },
  },
  typography: {
    button: {
      width: 'auto',
      background: 'transparent',
    },
  },
});
