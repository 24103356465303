import { createMuiTheme } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    viewHeight: number;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    viewHeight: number;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gradient: {
      main: PaletteColor['main'];
      light: PaletteColor['light'];
      dark: PaletteColor['dark'];
    };
    customType: string;
  }

  interface PaletteOptions {
    gradient?: {
      main?: PaletteColor['main'];
      light?: PaletteColor['light'];
      dark?: PaletteColor['dark'];
    };
    customType?: string;
  }
}

// $default- font - size: 1.6rem;
export const colors = {
  white: '#fff',
  offWhite: '#FDFDFD',
  lightestGrey: '#bababa',
  lighterGrey: '#F3F3F3',
  lightGrey: '#898989',
  slate: '#7B7B7B', //rgba(0, 0, 0, 0.54)
  mediumLightGrey: '#C4C4C4',
  mediumGrey: '#545454',
  mediumDarkGrey: '#464646',
  darkGrey: '#1f1f1f',
  lightBlack: '#393636',
  black: '#000',
  evenLighterBlue: '#d7eefa',
  lightestBlue: '#A4D5ED',
  lightBlue: '#33a6d7',
  deepBlue: '#3276b5',
  red: '#f00',
  colorGradientBlue:
    'linear-gradient(104.37deg, #33a6d7 7.55%, #3276b5 95.18%)',
  colorGradientLightBlue:
    'linear-gradient(180deg, #98d0e8 0%, #33a6d7 100%, rgba(196, 196, 196, 0) 100%)',
  colorGradientGrey:
    'linear-gradient(104.37deg, #bababa 7.55%, #878787 95.18%)',
  lighterBrightGreen: '#5bf57d',
  brightGreen: '#71D77B',
  lightRed: '#D73333',
  lighterRed: '#d95b5b',
  lightestRed: '#ffa3a3',
  green: '#71D77B',
};

const createTheme = () => {
  const theme = createMuiTheme({
    viewHeight: window.innerHeight,
    palette: {
      primary: {
        main: colors.lightBlue,
        light: colors.lightBlue,
        dark: colors.deepBlue,
      },
      secondary: {
        main: colors.lightGrey,
        light: colors.lightestGrey,
        dark: colors.mediumDarkGrey,
      },
      background: {
        paper: colors.white,
        default: colors.white,
      },
      text: {
        primary: colors.black,
        secondary: colors.white,
      },
      gradient: {
        main: colors.colorGradientBlue,
        light: colors.colorGradientGrey,
        dark: colors.colorGradientLightBlue,
      },
      warning: {
        main: colors.red,
        dark: '#a30000',
        light: '#ff5454',
      },
      success: {
        main: '#04C800',
      },
      customType: 'light',
    },
    typography: {
      button: {
        width: '100%',
        height: '3rem',
        background: colors.deepBlue,
        textTransform: 'none',
        '&:disabled': {
          background: colors.lightGrey,
          color: colors.white,
          width: '100%',
        },
        root: {
          '&:hover': {
            backgroundColor: colors.lightBlue,
            color: colors.white,
          },
        },
      },
      h1: {
        color: colors.black,
        fontFamily: 'Roboto',
        fontWeight: 600,
        fontSize: '1.5rem',
        textAlign: 'center',
      },
      h2: {
        fontWeight: 500,
        fontSize: '1.25rem',
        textAlign: 'center',
        marginBottom: '2rem',
      },
      h3: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        color: colors.black,
        textAlign: 'left',
        marginBottom: '0.5rem',
      },
      h4: {
        fontWeight: 600,
        fontSize: '1rem',
        color: colors.black,
        marginBottom: '1.4375rem',
      },
      h5: {
        fontWeight: 500,
        fontSize: '0.875rem',
        color: colors.black,
        marginBottom: '1rem',
      },
      h6: {
        fontWeight: 600,
        fontSize: '0.625rem',
        color: colors.black,
      },
      caption: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '0.75rem',
        fontSize: '0.75rem',
        color: colors.slate,
      },
      subtitle1: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 'normal',
        fontSize: '1rem',
        lineHeight: '1.125rem',
        color: colors.black,
      },
      subtitle2: {
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        fontWeight: 'normal',
        fontSize: '1rem',
        lineHeight: '1.125rem',
        color: colors.slate,
      },
      body1: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '1.25rem',
        fontSize: '1rem',
        textAlign: 'left',
        marginBottom: '1.25rem',
        color: colors.lightGrey,
      },
      body2: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '1.25rem',
        fontSize: '1rem',
        marginBottom: '1.25rem',
        color: colors.darkGrey,
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color: colors.white,
        },
        root: {
          '&:hover': {
            backgroundColor: colors.lightBlue,
            color: colors.white,
          },
        },
      },
      MuiMenuItem: {
        root: {
          marginBottom: 0,
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '0.9375rem',
          color: colors.slate,
        },
      },
      MuiInputBase: {
        root: {
          marginBottom: 0,
        },
      },
      MuiCheckbox: {
        root: {
          color: colors.lightGrey,
        },
      },
    },
  });

  return theme;
};

export default createTheme;
