import { Grid, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { IAdDisplaySchedule } from '../../types';
import { useStyles } from './styles';

interface IAdventureScheduleProps {
  schedule: IAdDisplaySchedule[];
}

const AdventureScheduleComp = ({
  schedule,
  ...props
}: IAdventureScheduleProps) => {
  const classes = useStyles({ itemCount: schedule.length });
  const scheduleScrollableNodeRef = useRef<any>();
  const [overflowing, setOverflowing] = useState<boolean>(true);
  const [leftVisible, setLeftVisible] = useState<boolean>(false);
  const [rightVisible, setRightVisible] = useState<boolean>(true);

  const [, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let isMounted = true;

    if (!!scheduleScrollableNodeRef.current) {
      if (isMounted) {
        setOverflowing(
          scheduleScrollableNodeRef.current.scrollWidth >
            scheduleScrollableNodeRef.current.clientWidth
        );
      }
    }

    return () => {
      isMounted = false;
    };
  });

  useEffect(() => {
    if (!!scheduleScrollableNodeRef.current) {
      scheduleScrollableNodeRef.current.addEventListener('scroll', (e: any) => {
        if (!!scheduleScrollableNodeRef.current) {
          if (scheduleScrollableNodeRef.current.scrollLeft > 0) {
            setLeftVisible(true);
          } else {
            setLeftVisible(false);
          }

          if (
            scheduleScrollableNodeRef.current.scrollLeft <
            scheduleScrollableNodeRef.current.scrollWidth -
              scheduleScrollableNodeRef.current.clientWidth
          ) {
            setRightVisible(true);
          } else {
            setRightVisible(false);
          }
        }
      });
    }
  });

  const handleLeftScrollClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const current = scheduleScrollableNodeRef.current.scrollLeft;
    const newVal = current - 105;

    scheduleScrollableNodeRef.current.scrollTo({
      left: newVal,
      behavior: 'smooth',
    });
  };

  const handleRightScrollClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const current = scheduleScrollableNodeRef.current.scrollLeft;
    const newVal = current + 105;

    scheduleScrollableNodeRef.current.scrollTo({
      left: newVal,
      behavior: 'smooth',
    });
  };

  const scheduleElems = schedule.map((s) => {
    return (
      <div className={classes.scheduleBox} key={s.day}>
        <div className={classes.scheduleDay}>{s.day}</div>
        <div className={classes.scheduleTime}>
          {`${s.startTime} - ${s.endTime}`}
        </div>
      </div>
    );
  });

  return (
    <Grid container classes={{ root: classes.scheduleContainer }}>
      <Grid item xs={12} classes={{ root: classes.scheduleTitle }}>
        Adventure Start Times
      </Grid>
      <Grid item xs={12} classes={{ root: classes.scheduleSubtitle }}>
        Estimated start times. Chat with the GM for details!
      </Grid>
      <SimpleBar
        className={classes.scrollableSchedule}
        scrollableNodeProps={{ ref: scheduleScrollableNodeRef }}
      >
        <Grid item xs={12} classes={{ root: classes.schedule }}>
          {scheduleElems}
        </Grid>
      </SimpleBar>
      {overflowing && (
        <Grid item xs={12} classes={{ root: classes.scheduleButtonContainer }}>
          {leftVisible && (
            <IconButton
              className={classes.scheduleButtonLeft}
              onClick={handleLeftScrollClick}
            >
              <ChevronLeft />
            </IconButton>
          )}
          {rightVisible && (
            <IconButton
              className={classes.scheduleButtonRight}
              onClick={handleRightScrollClick}
            >
              <ChevronRight />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

// @ts-ignore
AdventureScheduleComp.whyDidYouRender = {
  customName: 'AdventureSchedule',
};

export const AdventureSchedule = AdventureScheduleComp;
