import { gql } from 'urql';

export const GET_GAME_TYPES = gql`
  query getGameTypes {
    gameTypes {
      id
      code
      name
      active
      created
      updated
    }
  }
`;
