import React, { Dispatch, SetStateAction, useState } from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { useStyles } from './styles';
import { DrawerBase } from '@demiplane-dev/demiplane-components';
import { Checkbox, Grid } from '@material-ui/core';
import { Availability } from '../../Availability';

interface IUserAvailabilityDrawerProps {
  open: boolean;
  onClose: (modified: boolean) => void;
  showClose: boolean;
  title: string;
  selectedAvailability: string[];
  setSelectedAvailability: Dispatch<SetStateAction<string[]>>;
  setFrequencyFiltered: Dispatch<SetStateAction<boolean>>;
  frequencyOneTime: boolean;
  setFrequencyOneTime: Dispatch<SetStateAction<boolean>>;
  frequencyRecurring: boolean;
  setFrequencyRecurring: Dispatch<SetStateAction<boolean>>;
  showRecurringFilter: boolean;
  showOneShotFilter: boolean;
}

type TUserAvailabilityDrawerProps = IUserAvailabilityDrawerProps &
  WithSnackbarProps;

const UserAvailabilityDrawerComp = withSnackbar(
  ({
    open,
    onClose,
    showClose,
    title,
    selectedAvailability,
    setSelectedAvailability,
    setFrequencyFiltered,
    frequencyOneTime,
    setFrequencyOneTime,
    frequencyRecurring,
    setFrequencyRecurring,
    showRecurringFilter,
    showOneShotFilter,
  }: TUserAvailabilityDrawerProps) => {
    const classes = useStyles();
    const [updated, setUpdated] = useState(
      selectedAvailability.length > 0 ? true : false
    );

    const handleDrawerClose = async () => {
      onClose(updated);
    };

    const handleSetAvailabilities = (availabilities: string[]) => {
      setSelectedAvailability(availabilities);
      if (availabilities.length) {
        setUpdated(true);
      } else {
        setUpdated(false);
      }
    };

    const handleChangeOneTime = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFrequencyOneTime(event.target.checked);
      if (
        (!event.target.checked && frequencyRecurring) ||
        (event.target.checked && !frequencyRecurring)
      ) {
        setFrequencyFiltered(true);
      } else {
        setFrequencyFiltered(false);
      }
    };

    const handleChangeRecurring = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFrequencyRecurring(event.target.checked);
      if (
        (!event.target.checked && frequencyOneTime) ||
        (event.target.checked && !frequencyOneTime)
      ) {
        setFrequencyFiltered(true);
      } else {
        setFrequencyFiltered(false);
      }
    };

    return (
      <DrawerBase
        open={open}
        onClose={handleDrawerClose}
        showClose={showClose}
        title={title}
        includePadding={true}
      >
        <Grid container justify='center'>
          <Grid item xs={12} className={classes.desc}>
            <b>When would you like to Adventure?</b>
            <br />
            Only Adventures that occur during the below availability will be
            displayed.
          </Grid>
          <Grid item xs={12}>
            <Availability
              availabilities={selectedAvailability}
              handleSetAvailabilities={handleSetAvailabilities}
            />
          </Grid>
          <Grid item xs={12} className={classes.title}>
            Adventure Frequency
          </Grid>
          {showOneShotFilter && (
            <Grid item xs={12} className={classes.row}>
              <Checkbox
                checked={frequencyOneTime}
                onChange={handleChangeOneTime}
                name='onetime'
                color='primary'
              />
              <div className={classes.checkbox}>One-time</div>
            </Grid>
          )}
          {showRecurringFilter && (
            <Grid item xs={12} className={classes.row}>
              <Checkbox
                checked={frequencyRecurring}
                onChange={handleChangeRecurring}
                name='recurring'
                color='primary'
              />
              <div className={classes.checkbox}>Recurring</div>
            </Grid>
          )}
        </Grid>
      </DrawerBase>
    );
  }
);

// @ts-ignore
UserAvailabilityDrawerComp.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'UserAvailabilityDrawer',
};

export const UserAvailabilityDrawer = UserAvailabilityDrawerComp;
