import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  headerLeft: {
    textAlign: 'left',
    paddingTop: '0.5rem',
    paddingLeft: '0.5rem',
    paddingBottom: '0.5rem',
  },
  headerRight: {
    textAlign: 'right',
    paddingTop: '0.5rem',
    paddingRight: '0.5rem',
    paddingBottom: '0.5rem',
  },
  colHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    textAlign: 'center',
    color: '#000000',
  },
  colTime: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    textAlign: 'center',
    color: '#000000',
  },
  colImage: {
    textAlign: 'center',
  },
  dayLabel: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '2.25rem',
    lineHeight: '2.625rem',
    color: colors.mediumGrey,
    margin: 'auto',
  },
  dayContainer: {
    textAlign: 'center',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingLeft: '0.50rem',
    paddingRight: '0.50rem',
  },
}));
