import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../../../../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  columnHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    color: colors.mediumGrey,
    textAlign: 'center',
    margin: 'auto',
  },
  time: {
    textAlign: 'center',
    margin: 'auto',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: '0.75rem',
    color: '#3276B5',
  },
  buttonContainer: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  dowButtonUnselected: {
    height: '2.5rem',
    background: '#FDFDFD',
    border: '1px solid #3276B5',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
  },
  dowButtonSelected: {
    height: '2.5rem',
    background: '#3276B5',
    border: '1px solid #3276B5',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
    '&:hover': {
      opacity: '1.0',
      boxShadow: 'none',
      background: '#3276B5',
    },
  },
  dowButtonLabelUnselected: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '0.75rem',
    color: '#3276B5',
  },
  dowButtonLabelSelected: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '0.75rem',
    color: '#FDFDFD',
  },
}));
