import React from 'react';
import { Grid } from '@material-ui/core';
import { Header } from '..';
import { Footer } from '../Footer';
import { useStyles } from './styles';

interface ILayoutProps {
  children?: any;
  source?: string;
  includeHeader?: boolean;
  includeFooter?: boolean;
  grayscale?: boolean;
  grayscaleTop?: number | string;
  grayscaleBottom?: number | string;
  showFilter: boolean;
  onFilterClick: () => void;
  isFiltered: boolean;
}

const LayoutComp = ({
  children,
  source,
  includeHeader,
  includeFooter,
  grayscale,
  grayscaleTop,
  grayscaleBottom,
  showFilter,
  onFilterClick,
  isFiltered,
}: ILayoutProps) => {
  const classes = useStyles();
  return (
    <>
      {includeHeader && (
        <Grid container style={{ position: 'fixed', top: 0, zIndex: 1 }}>
          <Header
            showFilter={showFilter}
            onFilterClick={onFilterClick}
            isFiltered={isFiltered}
          />
        </Grid>
      )}
      <div className={classes.children}>{children}</div>
      {includeFooter && (
        <div className={classes.footer}>
          <Footer />
        </div>
      )}
    </>
  );
};

// @ts-ignore
LayoutComp.whyDidYouRender = {
  customName: 'Layout',
};

export const Layout = LayoutComp;
