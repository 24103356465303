import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import { DrawerBase } from '@demiplane-dev/demiplane-components';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { Availability, QuickMatch } from './components';
import { IAvailabilityStatus } from '../../../types';
import { addMinutes, formatISO, isAfter, isBefore, parseISO } from 'date-fns';
import { calculateAvailabilityDate } from '../../../utils';
import {
  DemiplaneAdTimeInsertInput,
  useGameAvailabilityQuery,
  useUpdateGameAvailabilityAdTimeUpdateMutation,
  useUpdateGameAvailabilityAdTimeResetMutation,
  useUpdateGameAvailabilitySessionUpdateMutation,
  useUpdateGameAvailabilitySessionResetMutation,
} from '../../../types/graphql';

interface IAdTimeInput {
  dow: number;
  startTime: Date;
  endTime: Date;
}

interface IGameAvailabilityDrawerProps {
  open: boolean;
  onClose: () => void;
  showClose: boolean;
  title: string;
  adventureId: string;
}

const GameAvailabilityDrawerComp = ({
  open,
  onClose,
  showClose,
  title,
  adventureId,
}: IGameAvailabilityDrawerProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [availabilityStatuses, setAvailabilityStatuses] = useState<
    IAvailabilityStatus[]
  >([]);
  const [minSessionScheduleDate] = useState<Date>(addMinutes(new Date(), 61));
  const [
    originalSessionScheduledDate,
    setOriginalSessionScheduledDate,
  ] = useState<Date>(new Date());
  const [
    currentSessionScheduledDate,
    setCurrentSessionScheduledDate,
  ] = useState<Date>(new Date());
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [
    ,
    updateGameAvailabilityAdTimeUpdate,
  ] = useUpdateGameAvailabilityAdTimeUpdateMutation();
  const [
    ,
    updateGameAvailabilityAdTimeReset,
  ] = useUpdateGameAvailabilityAdTimeResetMutation();
  const [
    ,
    updateGameAvailabilitySessionUpdate,
  ] = useUpdateGameAvailabilitySessionUpdateMutation();
  const [
    ,
    updateGameAvailabilitySessionReset,
  ] = useUpdateGameAvailabilitySessionResetMutation();
  const [adventureResult] = useGameAvailabilityQuery({
    variables: { id: adventureId },
    requestPolicy: 'cache-and-network',
  });
  const { data: adventureData, fetching: adventureLoading } = adventureResult;

  // Load Availability
  useEffect(() => {
    if (open && !updated && initialLoad) {
      if (!!adventureData && !!adventureData.demiplane_adventure_by_pk) {
        setAvailabilityLoading(true);
        const adventure = adventureData.demiplane_adventure_by_pk;
        const availability = adventure.ad_times;
        const currentSession = adventure.sessions[0];

        const dailyStatuses: IAvailabilityStatus[] = [];
        [0, 1, 2, 3, 4, 5, 6].map((dow) => {
          let match = false;
          if (!!availability) {
            for (const adTime of availability) {
              if (adTime.dow === dow) {
                match = true;
                dailyStatuses.push({
                  dow: adTime.dow,
                  dowSelected: true,
                  startTime: parseISO(adTime.start_time),
                  endTime: parseISO(adTime.end_time),
                });
              }
            }
          }
          if (!match) {
            const startTime = new Date();
            startTime.setHours(16);
            startTime.setMinutes(30);
            startTime.setSeconds(0);
            startTime.setMilliseconds(0);
            const endTime = new Date();
            endTime.setHours(17);
            endTime.setMinutes(30);
            endTime.setSeconds(0);
            endTime.setMilliseconds(0);
            dailyStatuses.push({
              dow,
              dowSelected: false,
              startTime,
              endTime,
            });
          }
          return match;
        });

        setAvailabilityStatuses(dailyStatuses);

        if (!!currentSession) {
          setOriginalSessionScheduledDate(
            new Date(currentSession.scheduled_date)
          );
          setCurrentSessionScheduledDate(
            new Date(currentSession.scheduled_date)
          );
        } else {
          setOriginalSessionScheduledDate(minSessionScheduleDate);
          setCurrentSessionScheduledDate(minSessionScheduleDate);
        }
        setInitialLoad(false);
        setAvailabilityLoading(false);
      }
    }
  }, [adventureData, initialLoad, minSessionScheduleDate, open, updated]);

  const handleDrawerClose = async () => {
    if (
      updated &&
      !!adventureData &&
      !!adventureData.demiplane_adventure_by_pk &&
      (!!availabilityStatuses || !!currentSessionScheduledDate)
    ) {
      // Convert to AdTime DateTimes
      const statuses: IAdTimeInput[] = [];
      for (const avail of availabilityStatuses) {
        if (avail.dowSelected) {
          const { dow, startTime, endTime } = avail;
          statuses.push({
            dow,
            startTime: calculateAvailabilityDate(startTime, dow),
            endTime: calculateAvailabilityDate(endTime, dow),
          });
        }
      }

      // Determine if start/end date/times are valid
      let isValid = true;
      for (const status of statuses) {
        if (
          isBefore(status.endTime, status.startTime) ||
          isAfter(status.startTime, status.endTime)
        ) {
          isValid = false;
          break;
        }
      }

      if (!isValid) {
        enqueueSnackbar('Earliest and Latest times can not overlap', {
          variant: 'error',
        });
      } else {
        // Valid so perform the correct hasura updates
        setAvailabilityLoading(true);

        // Perform updates
        const adventure = adventureData.demiplane_adventure_by_pk;
        const availability = adventure.ad_times;

        let adId: string | undefined = undefined;
        if (!!availability && availability.length > 0) {
          adId = availability[0].ad?.id;
        }

        let results = undefined;
        const currentDate = new Date().toISOString();
        if (
          (adventure.frequency === 'recurring' ||
            adventure.frequency === 'onetime') &&
          !!statuses &&
          statuses.length > 0
        ) {
          // New AdTime rcds to insert/update
          const adTimes: DemiplaneAdTimeInsertInput[] = [];
          for (const status of statuses) {
            const { dow, startTime, endTime } = status;

            // Figure out if dow already exists
            let id: string | undefined = undefined;
            for (const at of adventure.ad_times) {
              if (dow === at.dow) {
                id = at.id;
                break;
              }
            }

            // Build array of upsert objects
            adTimes.push({
              id,
              adventure_id: adventure.id,
              ad_id: adId,
              dow,
              start_time: startTime.toISOString(),
              end_time: endTime.toISOString(),
              updated: currentDate,
            });
          }

          // Does the status exist current then upsert else delete
          const adTimesToDelete: string[] = [];
          for (const at of adventure.ad_times) {
            // Figure out if the new AdTime to be inserted/updated exists in existing
            let dowExists = false;
            for (const adtm of adTimes) {
              if (at.dow === adtm.dow) {
                dowExists = true;
                break;
              }
            }

            if (!dowExists) {
              adTimesToDelete.push(at.id);
            }
          }

          // Adventure updates to apply
          const matchMakingStatusGameAvail = adTimes.length > 0 ? true : false;
          const matchMakingEnabled =
            adTimes.length > 0 ? adventure.match_making_enabled : false;

          results = await updateGameAvailabilityAdTimeUpdate({
            adTimes,
            adTimesToDelete,
            adventureId: adventure.id,
            matchMakingStatusGameAvail,
            matchMakingEnabled,
          });
        } else {
          // Remove all current AdTimes
          const adTimesToDelete: string[] = [];
          for (const at of adventure.ad_times) {
            adTimesToDelete.push(at.id);
          }

          results = await updateGameAvailabilityAdTimeReset({
            adTimesToDelete,
            adventureId: adventure.id,
            matchMakingStatusGameAvail: false,
            matchMakingEnabled: false,
          });
        }

        const scheduledDate =
          currentSessionScheduledDate !== originalSessionScheduledDate
            ? formatISO(currentSessionScheduledDate)
            : '';
        if (
          adventure.frequency === 'quickmatch' &&
          !!scheduledDate &&
          scheduledDate.length > 0
        ) {
          results = await updateGameAvailabilitySessionUpdate({
            adventureId: adventure.id,
            scheduledDate: new Date(scheduledDate).toISOString(),
            sessionCost: adventure.game_cost,
          });
        } else {
          results = await updateGameAvailabilitySessionReset({
            adventureId: adventure.id,
          });
        }

        if (!!results && !!results.data) {
          enqueueSnackbar('Changes to your settings have been saved', {
            variant: 'success',
          });
          setUpdated(false);
        } else {
          enqueueSnackbar('Unable to update your settings', {
            variant: 'error',
          });
        }
        setAvailabilityLoading(false);
        onClose();
      }
    } else {
      onClose();
    }
  };

  const handleDowSelect = (
    event: React.MouseEvent<any, MouseEvent>,
    dow: number
  ) => {
    const updatedStatuses = availabilityStatuses.slice();
    const updatedDow = updatedStatuses[dow];
    updatedDow.dowSelected = !updatedDow.dowSelected;
    updatedStatuses.splice(dow, 1, updatedDow);
    setAvailabilityStatuses(updatedStatuses);
    setUpdated(true);
  };

  const handleTimeSelectAvailability = (
    date: Date | null,
    timeField: string,
    dow: number
  ) => {
    const updatedStatuses = availabilityStatuses.slice();
    const updatedDow = updatedStatuses[dow];
    if (timeField === 'startTime' && !!date) {
      updatedDow.startTime = date;
    } else if (timeField === 'endTime' && !!date) {
      updatedDow.endTime = date;
    }
    updatedStatuses.splice(dow, 1, updatedDow);
    setAvailabilityStatuses(updatedStatuses);
    setUpdated(true);
  };

  const handleTimeSelectQuickMatch = (date: Date | null) => {
    setCurrentSessionScheduledDate(date ? date : new Date());
    setUpdated(true);
  };

  if (
    adventureLoading ||
    !adventureData ||
    !adventureData.demiplane_adventure_by_pk ||
    initialLoad ||
    availabilityLoading
  ) {
    return (
      <DrawerBase
        open={open}
        onClose={handleDrawerClose}
        showClose={showClose}
        title={title}
        includePadding={true}
      >
        <div className={classes.spinnerContainer}>
          <CircularProgress classes={{ colorPrimary: classes.spinner }} />
        </div>
      </DrawerBase>
    );
  }

  const adventure = adventureData.demiplane_adventure_by_pk;

  return (
    <DrawerBase
      open={open}
      onClose={handleDrawerClose}
      showClose={showClose}
      title={title}
      includePadding={true}
    >
      <Grid container justify='center'>
        <Grid item xs={12}>
          <Typography
            className={classes.sectionHeader}
            style={{ marginTop: '1.25rem' }}
          >
            <b>Define when you play for interested Adventurers.</b>
            <br />
            {adventure.frequency === 'onetime' ||
            adventure.frequency === 'recurring'
              ? 'These times will be displayed to Adventurers who wish to join your Adventure.'
              : 'This date and time will be displayed to Adventurers who wish to join your Quick Match.'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {adventure.frequency === 'onetime' ||
          adventure.frequency === 'recurring' ? (
            <Availability
              availabilityStatuses={availabilityStatuses}
              handleDowSelect={handleDowSelect}
              handleTimeSelect={handleTimeSelectAvailability}
            />
          ) : (
            <QuickMatch
              minSessionScheduleDate={minSessionScheduleDate}
              currentSessionScheduledDate={currentSessionScheduledDate}
              handleTimeSelect={handleTimeSelectQuickMatch}
            />
          )}
        </Grid>
      </Grid>
    </DrawerBase>
  );
};

// @ts-ignore
GameAvailabilityDrawerComp.whyDidYouRender = {
  customName: 'GameAvailabilityDrawer',
};

export const GameAvailabilityDrawer = GameAvailabilityDrawerComp;
