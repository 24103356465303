import React from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { useStyles } from './styles';
import { Button, Grid } from '@material-ui/core';
import { TimePicker } from '..';

interface IItemProps {
  dow: number;
  dowSelected: boolean;
  startTime: Date;
  endTime: Date;
  handleDowSelect: (
    event: React.MouseEvent<any, MouseEvent>,
    dow: number
  ) => void;
  handleTimeSelect: (date: Date | null, timeField: string, dow: number) => void;
}

type TItemProps = IItemProps & WithSnackbarProps;

const ItemComp = withSnackbar(
  ({
    dow,
    dowSelected,
    startTime,
    endTime,
    handleDowSelect,
    handleTimeSelect,
  }: TItemProps) => {
    const classes = useStyles();

    const dowName = (dow: number): string => {
      let name = '';
      if (dow === 0) {
        name = 'Sunday';
      } else if (dow === 1) {
        name = 'Monday';
      } else if (dow === 2) {
        name = 'Tuesday';
      } else if (dow === 3) {
        name = 'Wednesday';
      } else if (dow === 4) {
        name = 'Thursday';
      } else if (dow === 5) {
        name = 'Friday';
      } else if (dow === 6) {
        name = 'Saturday';
      }
      return name;
    };

    return (
      <>
        <Grid item xs={4} className={classes.buttonContainer}>
          <Button
            type='button'
            color='primary'
            onClick={(event) => {
              handleDowSelect(event, dow);
            }}
            className={
              dowSelected
                ? classes.dowButtonSelected
                : classes.dowButtonUnselected
            }
            classes={
              dowSelected
                ? { label: classes.dowButtonLabelSelected }
                : { label: classes.dowButtonLabelUnselected }
            }
          >
            {dowName(dow)}
          </Button>
        </Grid>
        <Grid item xs={4} className={classes.time}>
          <TimePicker
            dateTime={startTime}
            timeField='startTime'
            dow={dow}
            handleTimeChange={handleTimeSelect}
            dowSelected={dowSelected}
          />
        </Grid>
        <Grid item xs={4} className={classes.time}>
          <TimePicker
            dateTime={endTime}
            timeField='endTime'
            dow={dow}
            handleTimeChange={handleTimeSelect}
            dowSelected={dowSelected}
          />
        </Grid>
      </>
    );
  }
);

// @ts-ignore
ItemComp.whyDidYouRender = {
  customName: 'Item',
};

export const Item = ItemComp;
