import { createContext } from 'react';

interface IUrqlClientContext {
  resetClient(): void;
}

const initialState: IUrqlClientContext = {
  resetClient: () => {},
};

export const UrqlClientContext = createContext<IUrqlClientContext>(
  initialState
);
