import { createContext } from 'react';
import { Location } from 'history';

interface ILoadingContext {
  isGlobalLoading: boolean;
}

interface ILoadingActionsContext {
  setGlobalLoading(value: boolean, location: Location, force?: boolean): void;
}

const initialStateLoading: ILoadingContext = {
  isGlobalLoading: false,
};

const initialStateActions: ILoadingActionsContext = {
  setGlobalLoading: () => {},
};

export const LoadingContext = createContext<ILoadingContext>(
  initialStateLoading
);
export const LoadingActionsContext = createContext<ILoadingActionsContext>(
  initialStateActions
);
