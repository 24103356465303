import React from 'react';

interface HomeIconProps {
  height: string;
  width: string;
}

export const HomeIcon = ({ height, width }: HomeIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 15.3284H5.28571V31H13V21.1493H19.3333V31H27.1429V15.3284H31V14.4328L16.4286 1H15.1429L1 14.4328V15.3284Z'
      stroke='#3276B5'
      strokeWidth='2'
      strokeLinejoin='round'
    />
  </svg>
);
