import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../styles/rootTheme';

interface IStyleProps {
  desktop: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  resultsContainerDesktop: {
    paddingBottom: 'calc(1rem + env(safe-area-inset-bottom, 0))',
    paddingLeft: '.375rem',
    paddingRight: '.375rem',
    maxHeight: `calc(${theme.viewHeight}px - 4rem)`,
    minHeight: `calc(${theme.viewHeight}px - 4rem)`,
    height: `calc(${theme.viewHeight}px - 4rem)`,
    overflowX: 'hidden',
    backgroundColor: () =>
      theme.palette.customType === 'dark' ? '#333333' : colors.white,
  },
  resultsContainerMobile: {
    paddingBottom: 'calc(1rem + env(safe-area-inset-bottom, 0))',
    paddingLeft: '.375rem',
    paddingRight: '.375rem',
    maxHeight: `calc(${theme.viewHeight}px - 4rem)`,
    minHeight: `calc(${theme.viewHeight}px - 4rem)`,
    height: `calc(${theme.viewHeight}px - 4rem)`,
    overflowX: 'hidden',
    backgroundColor: () =>
      theme.palette.customType === 'dark' ? '#333333' : colors.white,
  },
  resultsSpacing: {
    paddingTop: '1rem',

    '& > .MuiGrid-item': {
      padding: 0,
    },
  },
  resultContainerVertical: {
    paddingTop: '.625rem',
    paddingBottom: '.625rem',
    minWidth: (props: IStyleProps) => (props.desktop ? '18.3755rem' : '18'),
    paddingLeft: '.625rem',
    paddingRight: '.625rem',
    zIndex: 1,
  },
  resultContainerHorizontal: {
    paddingTop: '.625rem',
    minWidth: (props: IStyleProps) => (props.desktop ? '23.3755rem' : '18rem'),
    paddingLeft: '.625rem',
    paddingRight: '.625rem',
    zIndex: 1,

    '&:first-child': {
      paddingLeft: '1rem',
    },
    '&:last-child': {
      paddingRight: '1rem',
    },
  },
  adCardContainer: {
    boxShadow: '0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
    borderRadius: '0.25rem',
    overflow: 'hidden',
  },
  adTabContainer: {
    borderRadius: '0.25rem',
  },
  noResultContainer: {
    marginTop: '2rem',
  },
  noResultContainerMobile: {
    marginTop: '5rem',
  },
  noResultText: {
    fontFamily: 'Roboto !important',
    fontStyle: 'italic !important',
    fontWeight: 'normal !important' as 'normal',
    fontSize: '.875rem !important',
    lineHeight: '1rem !important',
    textAlign: 'center !important' as 'center',
    color: `${colors.slate} !important`,
    width: '10.25rem !important',
  },
  noResultIconContainer: {
    marginBottom: '1.0625rem',
    width: '2.5rem',
  },
  createButtonContainer: {
    marginTop: '1.25rem',
    height: '1.5rem',
    maxWidth: '9.5625rem',
  },
  createButton: {
    height: '1.5rem !important',
    maxWidth: '9.5625rem !important',

    '&:hover': {
      backgroundColor: `${colors.lightBlue} !important`,
      color: `${colors.white} !important`,
    },
  },
  createButtonLabel: {
    color: `${colors.offWhite} !important`,
    display: 'block !important',
    textAlign: 'center !important' as 'center',
    fontFamily: 'Roboto !important',
    fontStyle: 'normal !important',
    fontSize: '.75rem !important',
    lineHeight: '.875rem !important',
    letterSpacing: '.02em !important',
  },
}));
