import React from 'react';

interface EarlyIconProps {
  height: string;
  width: string;
}

export const EarlyIcon = ({ height, width }: EarlyIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.9839 6.30242C19.2193 6.30242 18.6006 5.68373 18.6006 4.91914V2.15257C18.6006 1.38796 19.2193 0.769287 19.9839 0.769287C20.7485 0.769287 21.3672 1.38798 21.3672 2.15257V4.91914C21.3672 5.28658 21.2213 5.63783 20.962 5.89719C20.7026 6.15656 20.3514 6.30245 19.9839 6.30245L19.9839 6.30242Z'
        fill='black'
      />
      <path
        d='M37.829 21.5185H35.2002C34.4356 21.5185 33.817 20.8998 33.817 20.1352C33.817 19.3706 34.4357 18.752 35.2002 18.752H37.829C38.5937 18.752 39.2123 19.3706 39.2123 20.1352C39.2123 20.8998 38.5936 21.5185 37.829 21.5185Z'
        fill='black'
      />
      <path
        d='M37.4601 21.5185H2.15251C1.3906 21.5185 0.769226 20.8998 0.769226 20.1352C0.769226 19.3706 1.39063 18.752 2.15251 18.752H37.4601C38.2247 18.752 38.8433 19.3706 38.8433 20.1352C38.8433 20.8998 38.2246 21.5185 37.4601 21.5185Z'
        fill='black'
      />
      <path
        d='M30.5853 10.8735C30.0288 10.8708 29.5263 10.5358 29.3129 10.0197C29.1021 9.5037 29.2183 8.91199 29.6128 8.51485L31.5283 6.59935C32.0686 6.05901 32.944 6.05901 33.4843 6.59935C34.0246 7.1397 34.0246 8.01777 33.4843 8.55808L31.5607 10.452C31.3067 10.7168 30.9555 10.8681 30.5853 10.8735L30.5853 10.8735Z'
        fill='black'
      />
      <path
        d='M9.38234 10.8957C9.0149 10.893 8.66365 10.7471 8.40698 10.4877L6.5401 8.62081H6.5374C5.99705 8.08047 5.99705 7.20239 6.5374 6.66208C7.07775 6.12177 7.95582 6.12174 8.49613 6.66208L10.363 8.52896C10.7629 8.92612 10.8817 9.5232 10.6656 10.0419C10.4522 10.5607 9.94422 10.8984 9.38226 10.8957L9.38234 10.8957Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.6658 19.9999C29.6122 16.1363 27.2665 12.6717 23.6905 11.1895C20.0698 9.69007 15.9067 10.5195 13.1374 13.2888C11.3188 15.1074 10.3367 17.5274 10.3015 19.9999H13.0693C13.0856 19.1526 13.2577 18.3008 13.5943 17.4874C14.6642 14.9045 17.1876 13.2186 19.9839 13.2186L19.9836 13.2183C21.818 13.2183 23.5769 13.9478 24.8737 15.2446C26.1387 16.5097 26.8639 18.2143 26.8987 19.9999H29.6658Z'
        fill='black'
      />
    </svg>
  );
};
