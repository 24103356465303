import { createContext } from 'react';

interface IAuthContext {
  authenticate(token: string): Promise<boolean>;
}

const initialState: IAuthContext = {
  authenticate: () => new Promise<boolean>((resolve) => resolve(false)),
};

export const AuthContext = createContext<IAuthContext>(initialState);
