import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from '@demiplane-dev/react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {
  createClient,
  dedupExchange,
  cacheExchange,
  subscriptionExchange,
} from 'urql';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { devtoolsExchange } from '@urql/devtools';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { App, AppProviders } from './components';

const startApp = () => {
  const gaTrackerId: string =
    process.env.REACT_APP_GA_MEASUREMENT_ID || 'UA-000000-01';
  const gaDebug: boolean =
    process.env.REACT_APP_GA_DEBUG?.toLowerCase() === 'true' ? true : false;
  ReactGA.initialize(gaTrackerId, { debug: gaDebug });

  const pixelTrackerId: string =
    process.env.REACT_APP_PIXEL_MEASUREMENT_ID || 'UA-000000-01';
  const pixelDebug: boolean =
    process.env.REACT_APP_PIXEL_DEBUG?.toLowerCase() === 'true' ? true : false;

  const pixelOptions = {
    autoConfig: true,
    debug: pixelDebug,
  };

  ReactPixel.init(pixelTrackerId, undefined, pixelOptions);

  const makeUrqlClient = () => {
    const subscriptionClient = new SubscriptionClient(
      `${process.env.REACT_APP_API_WS_ENDPOINT}`,
      {
        reconnect: true,
        timeout: 30000,
      }
    );

    const client = createClient({
      url: `${process.env.REACT_APP_API_HTTP_ENDPOINT}`,
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        cacheExchange,
        multipartFetchExchange,
        subscriptionExchange({
          forwardSubscription(operation) {
            return subscriptionClient.request(operation);
          },
        }),
      ],
    });

    return client;
  };

  ReactDOM.render(
    <>
      <Router>
        <AppProviders makeUrqlClient={makeUrqlClient}>
          <App />
        </AppProviders>
      </Router>
    </>,
    document.getElementById('root')
  );
};

startApp();
