import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';

import { theme, useStyles } from './styles';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { addDays } from 'date-fns';

interface IDateTimePickerProps {
  dateTime: Date;
  handleTimeChange: (date: Date | null) => void;
  isError?: boolean;
  errorMessage?: string;
}

type TDateTimePickerProps = IDateTimePickerProps & WithSnackbarProps;

const DateTimePickerComp = withSnackbar(
  ({
    dateTime,
    handleTimeChange,
    isError,
    errorMessage,
    enqueueSnackbar,
    closeSnackbar,
    ...props
  }: TDateTimePickerProps) => {
    const classes = useStyles();

    return (
      <div className={classes.container}>
        <ThemeProvider theme={theme}>
          <MuiDateTimePicker
            {...props}
            value={dateTime}
            emptyLabel=''
            disabled={false}
            disablePast
            onChange={handleTimeChange}
            maxDate={addDays(new Date(), 7)}
            fullWidth={true}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input },
            }}
          />
        </ThemeProvider>
        {isError && <div className={classes.error}>{errorMessage}</div>}
      </div>
    );
  }
);

// @ts-ignore
DateTimePickerComp.whyDidYouRender = {
  customName: 'DateTimePicker',
};

export const DateTimePicker = DateTimePickerComp;
