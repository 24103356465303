import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { TimePicker as MuiTimePicker } from '@material-ui/pickers';

import { theme, useStyles } from './styles';
import { WithSnackbarProps, withSnackbar } from 'notistack';

interface ITimePickerProps {
  dateTime: Date;
  timeField: string;
  dow: number;
  handleTimeChange: (date: Date | null, timeField: string, dow: number) => void;
  dowSelected: boolean;
}

type TTimePickerProps = ITimePickerProps & WithSnackbarProps;

const TimePickerComp = withSnackbar(
  ({
    dateTime,
    timeField,
    dow,
    handleTimeChange,
    dowSelected,
    enqueueSnackbar,
    closeSnackbar,
    ...props
  }: TTimePickerProps) => {
    const classes = useStyles();

    const handleChange = (date: Date | null) => {
      handleTimeChange(date, timeField, dow);
    };

    return (
      <div className={classes.container}>
        <ThemeProvider theme={theme}>
          <MuiTimePicker
            value={dateTime}
            emptyLabel=''
            disabled={dowSelected ? false : true}
            onChange={handleChange}
            {...props}
            InputProps={{
              disableUnderline: true,
              classes: dowSelected
                ? { input: classes.input }
                : {
                    input: classes.input,
                    disabled: classes.inputDisabled,
                  },
            }}
          />
        </ThemeProvider>
      </div>
    );
  }
);

// @ts-ignore
TimePickerComp.whyDidYouRender = {
  customName: 'TimePicker',
};

export const TimePicker = TimePickerComp;
