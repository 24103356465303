import React from 'react';

interface ProfileSelectedIconProps {
  height: string;
  width: string;
}

export const ProfileSelectedIcon = ({
  height,
  width,
}: ProfileSelectedIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 36 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z'
      stroke='#3276B5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.9999 20.6472C20.4365 20.6472 22.4117 18.8695 22.4117 16.6766C22.4117 14.4837 20.4365 12.7061 17.9999 12.7061C15.5633 12.7061 13.5881 14.4837 13.5881 16.6766C13.5881 18.8695 15.5633 20.6472 17.9999 20.6472Z'
      fill='#3276B5'
      stroke='#3276B5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.17651 29.4709L9.39664 27.8572C9.53883 26.8378 10.0598 25.9028 10.8628 25.2259C11.6657 24.5491 12.6961 24.1763 13.7625 24.1769H15.0466L17.9817 26.3002L20.9168 24.1769H22.2008C23.2736 24.1677 24.3129 24.5366 25.1235 25.2142C25.9342 25.8919 26.4605 26.8317 26.6034 27.8572L26.8236 29.4709'
      fill='#3276B5'
    />
    <path
      d='M9.17651 29.4709L9.39664 27.8572C9.53883 26.8378 10.0598 25.9028 10.8628 25.2259C11.6657 24.5491 12.6961 24.1763 13.7625 24.1769H15.0466L17.9817 26.3002L20.9168 24.1769H22.2008C23.2736 24.1677 24.3129 24.5366 25.1235 25.2142C25.9342 25.8919 26.4605 26.8317 26.6034 27.8572L26.8236 29.4709'
      stroke='#3276B5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
