import React, { Dispatch, SetStateAction, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { useStyles } from './styles';
import {
  DrawerBase,
  DrawerTitleRow,
} from '@demiplane-dev/demiplane-components';
import { ISelectOption } from '../../../types';
import {
  Button,
  FormControlLabel,
  Grid,
  Link,
  Switch,
} from '@material-ui/core';
import {
  AdventuringPlatformsDrawer,
  GameSystemsDrawer,
  UserAvailabilityDrawer,
} from '../..';

interface IAdventureFiltersDrawerProps {
  open: boolean;
  onClose: (filter: boolean) => void;
  showGameSystemsFilter: boolean;
  gameSystemsFiltered: boolean;
  setGameSystemsFiltered: Dispatch<SetStateAction<boolean>>;
  selectedGameSystems: ISelectOption[];
  setSelectedGameSystems: Dispatch<SetStateAction<ISelectOption[]>>;
  showAdventuringPlatformsFilter: boolean;
  adventuringPlatformsFiltered: boolean;
  setAdventuringPlatformsFiltered: Dispatch<SetStateAction<boolean>>;
  selectedAdventuringPlatforms: ISelectOption[];
  setSelectedAdventuringPlatforms: Dispatch<SetStateAction<ISelectOption[]>>;
  availabilityFiltered: boolean;
  setAvailabilityFiltered: Dispatch<SetStateAction<boolean>>;
  selectedAvailability: string[];
  setSelectedAvailability: Dispatch<SetStateAction<string[]>>;
  frequencyFiltered: boolean;
  setFrequencyFiltered: Dispatch<SetStateAction<boolean>>;
  frequencyOneTime: boolean;
  setFrequencyOneTime: Dispatch<SetStateAction<boolean>>;
  frequencyRecurring: boolean;
  setFrequencyRecurring: Dispatch<SetStateAction<boolean>>;
  onReset: () => void;
  extraFiltersFiltered: boolean;
  setExtraFiltersFiltered: Dispatch<SetStateAction<boolean>>;
  showQuickMatchFilter: boolean;
  quickMatch: boolean;
  setQuickMatch: Dispatch<SetStateAction<boolean>>;
  showNewPlayerFilter: boolean;
  newPlayer: boolean;
  setNewPlayer: Dispatch<SetStateAction<boolean>>;
  showNoCostFilter: boolean;
  noCost: boolean;
  setNoCost: Dispatch<SetStateAction<boolean>>;
  showRecurringFilter: boolean;
  showOneShotFilter: boolean;
}

type TAdventureFiltersDrawerProps = IAdventureFiltersDrawerProps;

const AdventureFiltersDrawerComp = ({
  open,
  onClose,
  showGameSystemsFilter,
  gameSystemsFiltered,
  setGameSystemsFiltered,
  selectedGameSystems,
  setSelectedGameSystems,
  showAdventuringPlatformsFilter,
  adventuringPlatformsFiltered,
  setAdventuringPlatformsFiltered,
  selectedAdventuringPlatforms,
  setSelectedAdventuringPlatforms,
  availabilityFiltered,
  setAvailabilityFiltered,
  selectedAvailability,
  setSelectedAvailability,
  frequencyFiltered,
  setFrequencyFiltered,
  frequencyOneTime,
  setFrequencyOneTime,
  frequencyRecurring,
  setFrequencyRecurring,
  onReset,
  extraFiltersFiltered,
  setExtraFiltersFiltered,
  showQuickMatchFilter,
  quickMatch,
  setQuickMatch,
  showNewPlayerFilter,
  newPlayer,
  setNewPlayer,
  showNoCostFilter,
  noCost,
  setNoCost,
  showRecurringFilter,
  showOneShotFilter,
}: TAdventureFiltersDrawerProps) => {
  const classes = useStyles();

  // Game Systems
  const [openGameSystemsDrawer, setOpenGameSystemsDrawer] = useState(false);
  const handleOpenGameSystemsDrawer = () => {
    setOpenGameSystemsDrawer(true);
  };
  const handleCloseGameSystemsDrawer = (modified: boolean) => {
    setGameSystemsFiltered(modified);
    setOpenGameSystemsDrawer(false);
  };

  // Adventuring Platforms
  const [
    openAdventuringPlatformsDrawer,
    setOpenAdventuringPlatformsDrawer,
  ] = useState(false);
  const handleOpenAdventuringPlatformsDrawer = () => {
    setOpenAdventuringPlatformsDrawer(true);
  };
  const handleCloseAdventuringPlatformsDrawer = (modified: boolean) => {
    setAdventuringPlatformsFiltered(modified);
    setOpenAdventuringPlatformsDrawer(false);
  };

  // Availability & Frequency
  const [openUserAvailabilityDrawer, setOpenUserAvailabilityDrawer] = useState(
    false
  );
  const handleOpenUserAvailabilityDrawer = () => {
    setOpenUserAvailabilityDrawer(true);
  };
  const handleCloseUserAvailabilityDrawer = (modified: boolean) => {
    setAvailabilityFiltered(modified);
    setSelectedAvailability(selectedAvailability);
    setOpenUserAvailabilityDrawer(false);
  };

  const handleChangeQuickMatch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuickMatch(event.target.checked);
    if (event.target.checked) {
      setExtraFiltersFiltered(true);
    } else {
      if (!newPlayer && !noCost) {
        setExtraFiltersFiltered(false);
      }
    }
  };

  const handleChangeNewPlayer = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPlayer(event.target.checked);
    if (event.target.checked) {
      setExtraFiltersFiltered(true);
    } else {
      if (!quickMatch && !noCost) {
        setExtraFiltersFiltered(false);
      }
    }
  };

  const handleChangeNoCost = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoCost(event.target.checked);
    if (event.target.checked) {
      setExtraFiltersFiltered(true);
    } else {
      if (!quickMatch && !newPlayer) {
        setExtraFiltersFiltered(false);
      }
    }
  };

  const isFiltered = () => {
    return (
      gameSystemsFiltered ||
      adventuringPlatformsFiltered ||
      availabilityFiltered ||
      frequencyFiltered ||
      extraFiltersFiltered ||
      quickMatch
    );
  };

  const theme = {
    root: {
      background: 'red',
      color: 'blue',
    },
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <DrawerBase
          open={open}
          onClose={() => onClose(false)}
          showClose={true}
          title={'Adventure Filters'}
          includePadding={true}
        >
          <div>
            {showGameSystemsFilter && (
              <DrawerTitleRow
                titleChecked={'Game Systems'}
                setOpen={handleOpenGameSystemsDrawer}
                isCheck={true}
                isChecked={gameSystemsFiltered}
                includeQuestionMark={false}
                includePadding={false}
              />
            )}
            {showAdventuringPlatformsFilter && (
              <DrawerTitleRow
                titleChecked={'Adventuring Platforms'}
                setOpen={handleOpenAdventuringPlatformsDrawer}
                isCheck={true}
                isChecked={adventuringPlatformsFiltered}
                includeQuestionMark={false}
                includePadding={false}
              />
            )}
            <DrawerTitleRow
              titleChecked={'Availability & Frequency'}
              setOpen={handleOpenUserAvailabilityDrawer}
              isCheck={true}
              isChecked={availabilityFiltered || frequencyFiltered}
              includeQuestionMark={false}
              includePadding={false}
            />

            <div>
              <Grid container justify='center'>
                <Grid item xs={12} className={classes.only}>
                  Only show...
                </Grid>
                {showQuickMatchFilter && (
                  <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={quickMatch}
                          onChange={handleChangeQuickMatch}
                          name='quickMatch'
                          color='primary'
                          size='medium'
                        />
                      }
                      label='Quick Matches'
                      classes={{ label: classes.switchLabel }}
                    />
                  </Grid>
                )}
                {showNewPlayerFilter && (
                  <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={newPlayer}
                          onChange={handleChangeNewPlayer}
                          name='newPlayer'
                          color='primary'
                          size='medium'
                        />
                      }
                      label='New Player Friendly'
                      classes={{ label: classes.switchLabel }}
                    />
                  </Grid>
                )}
                {showNoCostFilter && (
                  <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={noCost}
                          onChange={handleChangeNoCost}
                          name='noCost'
                          color='primary'
                          size='medium'
                        />
                      }
                      label='No Cost Adventures'
                      classes={{ label: classes.switchLabel }}
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            {isFiltered() && (
              <div className={classes.buttonContainer}>
                <Grid container justify='center'>
                  <Grid item xs={1} />
                  <Grid item xs={10} style={{ marginBottom: '1rem' }}>
                    <Button
                      type='button'
                      color='primary'
                      onClick={() => onReset()}
                      key='reset'
                      className={classes.buttonReset}
                      classes={{ label: classes.buttonResetLabel }}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </div>
            )}
            <Grid container justify='center'>
              <div className={classes.logoContainer}>
                <Link href='https://app.demiplane.com' target='_blank'>
                  <div className={classes.logoText}>
                    Find More Adventures on
                  </div>
                  <div className={classes.logo}></div>
                </Link>
              </div>
            </Grid>
          </div>
        </DrawerBase>
      </ThemeProvider>

      {openGameSystemsDrawer && (
        <GameSystemsDrawer
          open={openGameSystemsDrawer}
          onClose={handleCloseGameSystemsDrawer}
          showClose={false}
          title={'Game Systems'}
          selectedGameSystems={selectedGameSystems}
          setSelectedGameSystems={setSelectedGameSystems}
        />
      )}

      {openAdventuringPlatformsDrawer && (
        <AdventuringPlatformsDrawer
          open={openAdventuringPlatformsDrawer}
          onClose={handleCloseAdventuringPlatformsDrawer}
          showClose={false}
          title={'Adventuring Platforms'}
          selectedAdventuringPlatforms={selectedAdventuringPlatforms}
          setSelectedAdventuringPlatforms={setSelectedAdventuringPlatforms}
        />
      )}

      {openUserAvailabilityDrawer && (
        <UserAvailabilityDrawer
          open={openUserAvailabilityDrawer}
          onClose={handleCloseUserAvailabilityDrawer}
          showClose={false}
          title={'Availability & Frequency'}
          selectedAvailability={selectedAvailability}
          setSelectedAvailability={setSelectedAvailability}
          setFrequencyFiltered={setFrequencyFiltered}
          frequencyOneTime={frequencyOneTime}
          setFrequencyOneTime={setFrequencyOneTime}
          frequencyRecurring={frequencyRecurring}
          setFrequencyRecurring={setFrequencyRecurring}
          showRecurringFilter={showRecurringFilter}
          showOneShotFilter={showOneShotFilter}
        />
      )}
    </>
  );
};

// @ts-ignore
AdventureFiltersDrawerComp.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'AdventureFiltersDrawer',
};

export const AdventureFiltersDrawer = AdventureFiltersDrawerComp;
