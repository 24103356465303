import { addDays, parseISO, startOfWeek } from 'date-fns';

export const calculateAvailabilityDate = (
  availabilityDate: Date | string,
  dow: number
): Date => {
  // Parse the passed in Date
  let availDate = new Date();
  if (availabilityDate instanceof Date) {
    availDate = availabilityDate;
  } else {
    availDate = parseISO(availabilityDate);
  }
  const sow = startOfWeek(new Date(), { weekStartsOn: 0 });
  sow.setHours(availDate.getHours());
  sow.setMinutes(availDate.getMinutes());
  sow.setSeconds(availDate.getSeconds());
  sow.setMilliseconds(availDate.getMilliseconds());
  return addDays(sow, dow);
};
