import React from 'react';

interface FilterIconProps {
  height: string;
  width: string;
  viewBox: string;
}

export const FilterIcon = ({ height, width, viewBox }: FilterIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask id='path-1-inside-1' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.49995 7.79487V12.9107L4.49995 16.125V7.79487L0.504761 0.875H13.4951L9.49995 7.79487Z'
      />
    </mask>
    <path
      d='M9.49995 7.79487L8.63393 7.29487L8.49995 7.52692V7.79487H9.49995ZM9.49995 12.9107L10.0407 13.7519L10.5 13.4567V12.9107H9.49995ZM4.49995 16.125H3.49995V17.9567L5.04071 16.9662L4.49995 16.125ZM4.49995 7.79487H5.49995V7.52692L5.36598 7.29487L4.49995 7.79487ZM0.504761 0.875V-0.125H-1.22729L-0.361265 1.375L0.504761 0.875ZM13.4951 0.875L14.3612 1.375L15.2272 -0.125H13.4951V0.875ZM8.49995 7.79487V12.9107H10.5V7.79487H8.49995ZM8.95919 12.0695L3.95919 15.2838L5.04071 16.9662L10.0407 13.7519L8.95919 12.0695ZM5.49995 16.125V7.79487H3.49995V16.125H5.49995ZM5.36598 7.29487L1.37079 0.375L-0.361265 1.375L3.63392 8.29487L5.36598 7.29487ZM0.504761 1.875H13.4951V-0.125H0.504761V1.875ZM12.6291 0.375L8.63393 7.29487L10.366 8.29487L14.3612 1.375L12.6291 0.375Z'
      fill='#828282'
      mask='url(#path-1-inside-1)'
    />
  </svg>
);
