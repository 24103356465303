import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  desc: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: '140%',
    color: '#898989',
    marginTop: '1rem',
  },
  title: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    color: '#000000',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: '0.75rem',
    color: '#000000',
  },
}));
