import React from 'react';

interface EveningIconProps {
  height: string;
  width: string;
}

export const EveningIcon = ({ height, width }: EveningIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40.6736 26.5577C40.3646 26.247 39.901 26.148 39.4913 26.306C37.3888 27.1116 35.1544 27.523 32.9028 27.5178C26.8401 27.5144 21.1668 24.53 17.729 19.5351C14.2915 14.5387 13.531 8.17419 15.6943 2.50883C15.8522 2.09911 15.7533 1.63559 15.4425 1.32652C15.1335 1.01575 14.6699 0.916792 14.2602 1.07305C7.72907 3.57479 2.9095 9.21353 1.45305 16.054C-0.00353193 22.8945 2.10234 30.0074 7.04685 34.9532C11.9931 39.8977 19.106 42.0035 25.946 40.547C32.7865 39.0904 38.4252 34.271 40.927 27.7398C41.0832 27.3301 40.9842 26.8666 40.6735 26.5575L40.6736 26.5577ZM21.6442 38.7782C16.1424 38.7782 10.929 36.3181 7.42903 32.0733C3.93075 27.8285 2.51079 22.2417 3.56097 16.8421C4.6096 11.4412 8.01743 6.79188 12.8526 4.16654C11.1355 11.1803 13.205 18.5817 18.3126 23.6875C23.4184 28.7951 30.8197 30.8644 37.8335 29.1475C36.2415 32.0521 33.8995 34.4773 31.0522 36.1702C28.205 37.8647 24.9566 38.764 21.6441 38.7779L21.6442 38.7782Z'
        fill='black'
        stroke='black'
      />
    </svg>
  );
};
