import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  desktop: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  joinSubSectionTitle: {
    fontSize: (props: IStyleProps) => (props.desktop ? '1.25rem' : '1rem'),
    lineHeight: '100%',
    color: theme.palette.common.black,
    marginTop: '.875rem',
    marginRight: (props: IStyleProps) => (props.desktop ? '1.875rem' : 0),
    marginBottom: '1.125rem',
    position: 'relative',
    fontWeight: 700,
  },
  title: {
    marginLeft: '1.875rem',
  },
  scrollableCards: {
    width: '100%',
    scrollBehavior: 'smooth',
    zIndex: 1,
    paddingBottom: '.5rem',
    paddingRight: (props: IStyleProps) => (props.desktop ? 0 : '1.875rem'),
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: 'fit-content',
  },
  scrollButtonLeft: {
    position: 'absolute',
    top: '50%',
    left: 0,
    border: '1px solid #F3F3F3',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FDFDFD',
    height: '2rem',
    width: '2rem',
    color: '#3276B5',
    zIndex: 1,

    '&:hover': {
      backgroundColor: '#FDFDFD',
    },
  },
  scrollButtonRight: {
    position: 'absolute',
    top: '50%',
    right: (props: IStyleProps) => (props.desktop ? 0 : '1.875rem'),
    border: '1px solid #F3F3F3',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FDFDFD',
    height: '2rem',
    width: '2rem',
    color: '#3276B5',
    zIndex: 1,

    '&:hover': {
      backgroundColor: '#FDFDFD',
    },
  },
}));
