import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    paddingBottom: 'env(safe-area-inset-bottom, 0)',
    height: '3.125rem',
    bottom: 0,
    zIndex: 100,
    boxShadow:
      '0 -0.125rem 0.5rem 0.375rem rgba(0, 0, 0, 0.24), 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12)',
  },
  addToHomeScreenContainer: {
    height: `${theme.viewHeight}px`,
    width: '100vw',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    zIndex: 1001,
    overflow: 'hidden',
  },
  icon: {
    fontSize: '3rem',
    color: '#fff',
  },
  shareArrow: {
    position: 'absolute',
    bottom: '-2.5rem',
    left: '39%',
    color: '#33a6d7',
    fontSize: '5rem',
  },
  homeBadge: {
    backgroundColor: '#FA323E',
    border: '1px solid #3276B5',
    top: '2.1rem',
    right: '.97rem',
  },
  searchBadge: {
    backgroundColor: '#FA323E',
    border: '1px solid #3276B5',
    top: '2.1rem',
    right: '1rem',
  },
  profileBadge: {
    backgroundColor: '#FA323E',
    border: '1px solid #3276B5',
    top: '2.1rem',
    right: '1.1rem',
  },
}));
