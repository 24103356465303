import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { DrawerBase } from '@demiplane-dev/demiplane-components';
import { Grid } from '@material-ui/core';
import { IAdventuringPlatform, ISelectOption } from '../../../types';
import { SelectPanel } from 'react-multi-select-component';
import './styles.css';
import { useGetAdventuringPlatformsQuery } from '../../../types/graphql';
import { parseISO } from 'date-fns';

interface IAdventuringPlatformsDrawerProps {
  open: boolean;
  onClose: (modified: boolean) => void;
  showClose: boolean;
  title: string;
  selectedAdventuringPlatforms: ISelectOption[];
  setSelectedAdventuringPlatforms: Dispatch<SetStateAction<ISelectOption[]>>;
}

type TAdventuringPlatformsDrawerProps = IAdventuringPlatformsDrawerProps &
  WithSnackbarProps;

const AdventuringPlatformsDrawerComp = withSnackbar(
  ({
    open,
    onClose,
    showClose,
    title,
    selectedAdventuringPlatforms,
    setSelectedAdventuringPlatforms,
  }: TAdventuringPlatformsDrawerProps) => {
    const [adventuringPlatforms, setAdventuringPlatforms] = useState<
      IAdventuringPlatform[]
    >([]);
    const [updated, setUpdated] = useState(false);
    const [adventuringPlatformsResult] = useGetAdventuringPlatformsQuery();
    const { data: adventuringPlatformsData } = adventuringPlatformsResult;

    useEffect(() => {
      if (
        !!adventuringPlatformsData &&
        !!adventuringPlatformsData.demiplane_adventuring_platform
      ) {
        setAdventuringPlatforms(
          adventuringPlatformsData.demiplane_adventuring_platform.map(
            (ap): IAdventuringPlatform => {
              return {
                id: ap.id,
                name: ap.name,
                active: ap.active,
                created: parseISO(ap.created),
                updated: parseISO(ap.updated),
              };
            }
          )
        );
      }
    }, [adventuringPlatformsData]);

    useEffect(() => {
      const isUpdated =
        adventuringPlatforms.length !== selectedAdventuringPlatforms.length &&
        selectedAdventuringPlatforms.length !== 0;
      setUpdated(isUpdated);
    }, [adventuringPlatforms.length, selectedAdventuringPlatforms.length]);

    const handleDrawerClose = async () => {
      onClose(updated);
    };

    const handleSelected = (selectedPlatforms: ISelectOption[]): void => {
      setSelectedAdventuringPlatforms(selectedPlatforms);
      if (adventuringPlatforms.length !== selectedPlatforms.length) {
        setUpdated(true);
      } else {
        setUpdated(false);
      }
    };

    const adventuringPlatformOptions: ISelectOption[] = useMemo(() => {
      return adventuringPlatforms.map((p) => {
        return { label: p.name, value: p.id, key: p.id, disabled: false };
      });
    }, [adventuringPlatforms]);

    return (
      <DrawerBase
        open={open}
        onClose={handleDrawerClose}
        showClose={showClose}
        title={title}
        includePadding={true}
      >
        <Grid container justify='center'>
          <Grid item xs={12}>
            <SelectPanel
              options={adventuringPlatformOptions}
              onChange={handleSelected}
              focusSearchOnOpen={true}
              hasSelectAll
              value={selectedAdventuringPlatforms}
            />
          </Grid>
        </Grid>
      </DrawerBase>
    );
  }
);

// @ts-ignore
AdventuringPlatformsDrawerComp.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'AdventuringPlatformsDrawer',
};

export const AdventuringPlatformsDrawer = AdventuringPlatformsDrawerComp;
