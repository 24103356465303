import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../styles/rootTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: '140%',
    color: colors.lightGrey,
    textAlign: 'left',
  },
  subHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: '140%',
    textAlign: 'center',
    color: colors.lightGrey,
    marginTop: '1.25rem',
    marginBottom: '0',
  },
  underline: {
    borderBottom: '0.125rem solid #00BCD4',
    '&:before': {
      borderBottom: 'none',
    },
  },
  deleteButton: {
    position: 'fixed',
    bottom: '4rem',
    width: '15rem',
    background: 'transparent',
    border: '1px solid #D73333',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: colors.lightestGrey,
    },
  },
  deleteButtonLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.02em',
    color: colors.lightRed,
  },
  spinnerContainer: {
    paddingTop: '4rem',
    textAlign: 'center',
    paddingBottom: '4rem',
  },
  spinner: {
    color: theme.palette.primary.main,
  },
}));
