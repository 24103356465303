import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Client, Provider as UrqlProvider } from 'urql';
import { UrqlClientContext } from '../contexts';

interface IUrqlClientProviderProps {
  makeClient: () => Client;
  children: any;
}

const UrqlClientProviderComp = React.memo(
  ({ makeClient, ...props }: IUrqlClientProviderProps) => {
    const [client, setClient] = useState<Client>();

    useEffect(() => {
      let isMounted = true;

      if (isMounted) {
        setClient(makeClient());
      }

      return () => {
        isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetClient = useCallback(() => {
      setClient(makeClient());
    }, [makeClient]);

    const returnObj = useMemo(() => {
      return {
        resetClient,
      };
    }, [resetClient]);

    return (
      <UrqlClientContext.Provider value={returnObj}>
        {!!client && <UrqlProvider value={client} {...props} />}
      </UrqlClientContext.Provider>
    );
  }
);

// @ts-ignore
UrqlClientProviderComp.whyDidYouRender = {
  customName: 'UrqlClientProvider',
};

export const UrqlClientProvider = UrqlClientProviderComp;
