import React from 'react';
import { AppBar, Button, Grid, Link } from '@material-ui/core';
import { useStyles } from './styles';
import { FilterActiveIcon, FilterIcon } from '../../assets/icons';

interface IHeaderProps {
  showFilter: boolean;
  onFilterClick: () => void;
  isFiltered: boolean;
}

const HeaderComp = React.memo(
  ({ showFilter, onFilterClick, isFiltered }: IHeaderProps) => {
    const classes = useStyles();

    return (
      <Grid container className={classes.top}>
        <Grid item xs={12}>
          <AppBar
            elevation={0}
            classes={{
              positionAbsolute: classes.header,
            }}
            position='absolute'
          >
            <div className={classes.logoContainer}>
              <Link
                href='https://app.demiplane.com'
                underline='none'
                target='_blank'
                className={classes.logoLink}
              >
                <div className={classes.logoText}>Powered by</div>
                <div className={classes.logo}></div>
              </Link>
            </div>
            {!!showFilter && (
              <Button
                onClick={() => onFilterClick()}
                className={`${
                  isFiltered ? classes.filterButtonActive : classes.filterButton
                }`}
              >
                {isFiltered ? (
                  <>
                    <FilterActiveIcon
                      width='14'
                      height='17'
                      viewBox='0 0 14 17'
                    />
                    <span className={classes.filterButtonLabelActive}>
                      Filter On
                    </span>
                  </>
                ) : (
                  <>
                    <FilterIcon width='14' height='17' viewBox='0 0 14 17' />
                    <span className={classes.filterButtonLabel}>Filter</span>
                  </>
                )}
              </Button>
            )}
          </AppBar>
        </Grid>
      </Grid>
    );
  }
);

// @ts-ignore
HeaderComp.whyDidYouRender = {
  customName: 'Header',
};

export const Header = HeaderComp;
