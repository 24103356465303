import React from 'react';
import Routes from '../../routes';
import { useStyles } from './styles';
import './style.scss';
import 'react-quill/dist/quill.snow.css';
import {} from '../../gql';

const AppComp = () => {
  const classes = useStyles();

  return (
    <div className={classes.appContainer}>
      <Routes />
    </div>
  );
};

// @ts-ignore
AppComp.whyDidYouRender = {
  customName: 'App',
};

export const App = AppComp;
