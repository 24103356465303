import { add, addHours, format } from 'date-fns';

const incrementDate = (originalDate: Date) => {
  return addHours(originalDate, 8);
};

export const calculateAvailabilityDateStrings = (
  sow: Date,
  availabilityFlags: string[]
): string[] => {
  const availabiltyDates: string[] = [];
  let startDate = sow;
  let endDate = add(sow, {
    years: 0,
    months: 0,
    days: 0,
    hours: 7,
    minutes: 59,
    seconds: 59,
  });
  endDate.setMilliseconds(999);
  if (availabilityFlags.includes('EAR-SUN')) {
    availabiltyDates.push(
      `${0},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('DAY-SUN')) {
    availabiltyDates.push(
      `${0},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EVE-SUN')) {
    availabiltyDates.push(
      `${0},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EAR-MON')) {
    availabiltyDates.push(
      `${1},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('DAY-MON')) {
    availabiltyDates.push(
      `${1},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EVE-MON')) {
    availabiltyDates.push(
      `${1},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EAR-TUE')) {
    availabiltyDates.push(
      `${2},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('DAY-TUE')) {
    availabiltyDates.push(
      `${2},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EVE-TUE')) {
    availabiltyDates.push(
      `${2},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EAR-WED')) {
    availabiltyDates.push(
      `${3},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('DAY-WED')) {
    availabiltyDates.push(
      `${3},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EVE-WED')) {
    availabiltyDates.push(
      `${3},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EAR-THU')) {
    availabiltyDates.push(
      `${4},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('DAY-THU')) {
    availabiltyDates.push(
      `${4},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EVE-THU')) {
    availabiltyDates.push(
      `${4},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EAR-FRI')) {
    availabiltyDates.push(
      `${5},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('DAY-FRI')) {
    availabiltyDates.push(
      `${5},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EVE-FRI')) {
    availabiltyDates.push(
      `${5},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EAR-SAT')) {
    availabiltyDates.push(
      `${6},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('DAY-SAT')) {
    availabiltyDates.push(
      `${6},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }
  startDate = incrementDate(startDate);
  endDate = incrementDate(endDate);
  if (availabilityFlags.includes('EVE-SAT')) {
    availabiltyDates.push(
      `${6},${format(startDate, 'yyyy-MM-dd HH:mm:ss.SSS x')},${format(
        endDate,
        'yyyy-MM-dd HH:mm:ss.SSS x'
      )}`
    );
  }

  return availabiltyDates;
};
