import React, { useState } from 'react';

// import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import { DateTimePicker } from './components';
import { isBefore } from 'date-fns';

interface IQuickMatchProps {
  minSessionScheduleDate: Date;
  currentSessionScheduledDate: Date;
  handleTimeSelect: (date: Date | null) => void;
}

type TQuickMatchProps = IQuickMatchProps;

const QuickMatchComp = ({
  minSessionScheduleDate,
  currentSessionScheduledDate,
  handleTimeSelect,
}: TQuickMatchProps) => {
  // const classes = useStyles();
  const [isError, setIsError] = useState(false);
  const errorMessage = 'Time must be at least one hour from now.';

  const handleTimeSelectVerify = (date: Date | null) => {
    if (!!date && isBefore(date, minSessionScheduleDate)) {
      setIsError(true);
    } else {
      setIsError(false);
      handleTimeSelect(date);
    }
  };

  return (
    <Grid container justify='center'>
      <Grid item xs={12}>
        <DateTimePicker
          dateTime={currentSessionScheduledDate}
          handleTimeChange={handleTimeSelectVerify}
          isError={isError}
          errorMessage={errorMessage}
        />
      </Grid>
    </Grid>
  );
};

// @ts-ignore
QuickMatchComp.whyDidYouRender = {
  customName: 'QuickMatch',
};

export const QuickMatch = QuickMatchComp;
