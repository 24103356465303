import React from 'react';
import { useStyles } from './styles';
import { DayButton } from './components';
import { Grid, Link as MuiLink } from '@material-ui/core';
import { EarlyIcon, DaytimeIcon, EveningIcon } from '../../assets/icons';

interface IAvailabilityProps {
  availabilities: string[];
  handleSetAvailabilities: (availabilities: string[]) => void;
}

type TAvailabilityProps = IAvailabilityProps;

const AvailabilityComp = ({
  availabilities,
  handleSetAvailabilities,
}: TAvailabilityProps) => {
  const classes = useStyles();
  const days = [0, 1, 2, 3, 4, 5, 6];

  const formatDayInitial = (dow: number): string => {
    let dayInitial = '';
    switch (dow) {
      case 0:
        dayInitial = 'Su';
        break;
      case 1:
        dayInitial = 'M';
        break;
      case 2:
        dayInitial = 'Tu';
        break;
      case 3:
        dayInitial = 'W';
        break;
      case 4:
        dayInitial = 'Th';
        break;
      case 5:
        dayInitial = 'F';
        break;
      case 6:
        dayInitial = 'Sa';
        break;
    }
    return dayInitial;
  };

  return (
    <Grid
      container
      justify='space-between'
      style={{ backgroundColor: '#fff', padding: '0.5rem', overflow: 'hidden' }}
    >
      <Grid item xs={6} className={classes.headerLeft}>
        <MuiLink
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleSetAvailabilities([
              'EAR-SUN',
              'DAY-SUN',
              'EVE-SUN',
              'EAR-MON',
              'DAY-MON',
              'EVE-MON',
              'EAR-TUE',
              'DAY-TUE',
              'EVE-TUE',
              'EAR-WED',
              'DAY-WED',
              'EVE-WED',
              'EAR-THU',
              'DAY-THU',
              'EVE-THU',
              'EAR-FRI',
              'DAY-FRI',
              'EVE-FRI',
              'EAR-SAT',
              'DAY-SAT',
              'EVE-SAT',
            ])
          }
        >
          Select All
        </MuiLink>
      </Grid>
      <Grid item xs={6} className={classes.headerRight}>
        <MuiLink
          style={{ cursor: 'pointer' }}
          onClick={() => handleSetAvailabilities([])}
        >
          Clear All
        </MuiLink>
      </Grid>

      <Grid
        container
        justify='space-between'
        style={{ paddingLeft: '0.5rem', paddingRight: '0.75rem' }}
      >
        <Grid item xs={3} />
        <Grid item xs={3} className={classes.colHeader}>
          EARLY AM
        </Grid>
        <Grid item xs={3} className={classes.colHeader}>
          DAYTIME
        </Grid>
        <Grid item xs={3} className={classes.colHeader}>
          EVENING
        </Grid>

        <Grid item xs={3} />
        <Grid item xs={3} className={classes.colTime}>
          12am - 8am
        </Grid>
        <Grid item xs={3} className={classes.colTime}>
          8am - 4pm
        </Grid>
        <Grid item xs={3} className={classes.colTime}>
          4pm - 12am
        </Grid>

        <Grid item xs={3} />
        <Grid
          item
          xs={3}
          className={classes.colImage}
          style={{ paddingTop: '2rem' }}
        >
          <EarlyIcon height='22' width='40' />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.colImage}
          style={{ paddingTop: '0.5rem' }}
        >
          <DaytimeIcon height='45' width='45' />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.colImage}
          style={{ paddingTop: '0.5rem' }}
        >
          <EveningIcon height='42' width='42' />
        </Grid>

        {days.map((dow: number) => {
          return (
            <Grid container justify='space-between' key={dow}>
              <Grid item xs={3} className={classes.dayLabel}>
                {formatDayInitial(dow)}
              </Grid>
              <Grid item xs={3} className={classes.dayContainer}>
                <DayButton
                  dow={dow}
                  range={'EAR'}
                  availabilities={availabilities}
                  key={`EAR-${dow}`}
                  handleSetAvailabilities={handleSetAvailabilities}
                />
              </Grid>
              <Grid item xs={3} className={classes.dayContainer}>
                <DayButton
                  dow={dow}
                  range={'DAY'}
                  availabilities={availabilities}
                  key={`DAY-${dow}`}
                  handleSetAvailabilities={handleSetAvailabilities}
                />
              </Grid>
              <Grid item xs={3} className={classes.dayContainer}>
                <DayButton
                  dow={dow}
                  range={'EVE'}
                  availabilities={availabilities}
                  key={`EVE-${dow}`}
                  handleSetAvailabilities={handleSetAvailabilities}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

// @ts-ignore
AvailabilityComp.whyDidYouRender = {
  customName: 'Availability',
};

export const Availability = AvailabilityComp;
