import React, { useState, useEffect } from 'react';
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Grid,
} from '@material-ui/core';
import { useNavigate, useLocation } from '@demiplane-dev/react-router-dom';
import {
  ProfileIcon,
  SearchIcon,
  HomeIcon,
  HomeSelectedIcon,
  SearchSelectedIcon,
  ProfileSelectedIcon,
} from '../../assets/icons';
import { useStyles } from './styles';

const BottomNavigationComp = React.memo(({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const [bottomNavigationValue, setBottomNavigationValue] = useState('');

  useEffect(() => {
    let isMounted = true;

    const path = location.pathname;

    if (
      path.startsWith('/home') ||
      path.startsWith('/search') ||
      path.startsWith('/profile')
    ) {
      if (isMounted) {
        setBottomNavigationValue(location.pathname.split('/')[1]);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [location.pathname]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setBottomNavigationValue(newValue);

    switch (newValue) {
      case 'home':
        navigate('/home');
        break;
      case 'search':
        navigate('/search');
        break;
      case 'profile':
        navigate('/profile');
        break;
    }
  };

  return (
    <>
      <Grid container style={{ position: 'fixed', bottom: 0, zIndex: 2 }}>
        <MuiBottomNavigation
          value={bottomNavigationValue}
          onChange={handleChange}
          classes={{ root: classes.container }}
          showLabels
        >
          <BottomNavigationAction
            value='home'
            icon={
              bottomNavigationValue === 'home' ? (
                <HomeSelectedIcon height='30' width='30' />
              ) : (
                <HomeIcon height='30' width='30' />
              )
            }
          />
          <BottomNavigationAction
            value='search'
            icon={
              bottomNavigationValue === 'search' ? (
                <SearchSelectedIcon height='30' width='30' />
              ) : (
                <SearchIcon height='30' width='30' />
              )
            }
          />
          <BottomNavigationAction
            value='profile'
            icon={
              bottomNavigationValue === 'profile' ? (
                <ProfileSelectedIcon height='38' width='36' />
              ) : (
                <ProfileIcon height='38' width='36' />
              )
            }
          />
        </MuiBottomNavigation>
      </Grid>
    </>
  );
});

// @ts-ignore
BottomNavigationComp.whyDidYouRender = {
  customName: 'BottomNavigation',
};

export const BottomNavigation = BottomNavigationComp;
