import React, { useEffect, useRef, useState } from 'react';
import { Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import SimpleBar from 'simplebar-react';
import { useStyles } from './styles';

interface IHomeJoinSubSectionProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  loadMoreResults: () => void;
}

const HomeJoinSubSectionComp = React.memo(
  ({
    title,
    children,
    loadMoreResults,
    ...props
  }: IHomeJoinSubSectionProps) => {
    const matchesWidth = useMediaQuery('(min-width:600px)');
    const classes = useStyles({ desktop: matchesWidth });

    const [, setDimensions] = useState({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    const [overflowing, setOverflowing] = useState<boolean>(true);
    const [leftVisible, setLeftVisible] = useState<boolean>(false);
    const [rightVisible, setRightVisible] = useState<boolean>(true);

    const scrollableNodeRef = useRef<HTMLDivElement>();

    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      let isMounted = true;

      if (!!scrollableNodeRef.current) {
        if (isMounted) {
          setOverflowing(
            scrollableNodeRef.current.scrollWidth >
              scrollableNodeRef.current.clientWidth
          );
        }
      }

      return () => {
        isMounted = false;
      };
    });

    useEffect(() => {
      if (!!scrollableNodeRef.current) {
        scrollableNodeRef.current.addEventListener('scroll', (e: any) => {
          if (!!scrollableNodeRef.current) {
            if (scrollableNodeRef.current.scrollLeft > 0) {
              setLeftVisible(true);
            } else {
              setLeftVisible(false);
            }

            if (
              scrollableNodeRef.current.scrollLeft <
              scrollableNodeRef.current.scrollWidth -
                scrollableNodeRef.current.clientWidth
            ) {
              setRightVisible(true);
            } else {
              setRightVisible(false);
            }
            const totalWidth =
              scrollableNodeRef.current.scrollWidth -
              scrollableNodeRef.current.offsetWidth;

            if (scrollableNodeRef.current.scrollLeft >= totalWidth - 150) {
              loadMoreResults();
            }
          }
        });
      }
    });

    const handleLeftScrollClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();

      if (!!scrollableNodeRef.current) {
        const current = scrollableNodeRef.current.scrollLeft;
        const newVal = current - 276;

        scrollableNodeRef.current.scrollTo({
          left: newVal,
          behavior: 'smooth',
        });
      }
    };

    const handleRightScrollClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();

      if (!!scrollableNodeRef.current) {
        const current = scrollableNodeRef.current.scrollLeft;
        const newVal = current + 276;

        scrollableNodeRef.current.scrollTo({
          left: newVal,
          behavior: 'smooth',
        });
      }
    };

    return (
      <Grid item xs={12}>
        <SimpleBar
          className={classes.scrollableCards}
          scrollableNodeProps={{ ref: scrollableNodeRef }}
        >
          <div className={classes.cardsContainer}>{children}</div>
        </SimpleBar>
        {overflowing && (
          <div>
            {leftVisible && (
              <IconButton
                className={classes.scrollButtonLeft}
                onClick={handleLeftScrollClick}
              >
                <ChevronLeft />
              </IconButton>
            )}
            {rightVisible && (
              <IconButton
                className={classes.scrollButtonRight}
                onClick={handleRightScrollClick}
              >
                <ChevronRight />
              </IconButton>
            )}
          </div>
        )}
      </Grid>
    );
  }
);

// @ts-ignore
HomeJoinSubSectionComp.whyDidYouRender = {
  customName: 'HomeJoinSubSection',
};

export const HomeJoinSubSection = HomeJoinSubSectionComp;
