import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../styles/rootTheme';

const logoLight = require('../../assets/logo/demiplane_light.png').default;
const logoDark = require('../../assets/logo/demiplane_dark.png').default;

export const useStyles = makeStyles((theme: Theme) => ({
  top: {
    height: '4rem',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    zIndex: 100,
  },
  header: {
    position: 'absolute',
    top: '0rem',
    height: 'calc(4rem + env(safe-area-inset-top, 0))',
    backgroundColor: () =>
      theme.palette.customType === 'dark' ? '#333333' : colors.white,
    paddingTop: 'env(safe-area-inset-top, 0)',
  },
  logoLink: {
    width: '6.125rem',
  },
  logoContainer: {
    width: '6.125rem',
    height: '2.625rem',
    position: 'relative',
    left: '0.3125rem',
    top: '.725rem',
  },
  logo: {
    position: 'relative',
    height: '1.9775rem',
    width: '6.125rem',
    backgroundImage: () =>
      theme.palette.customType === 'dark'
        ? `url(${logoDark})`
        : `url(${logoLight})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoText: {
    position: 'relative',
    left: '.7rem',
    top: '.2rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.5rem',
    lineHeight: '0.5625rem',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.02em',
    color: () =>
      theme.palette.customType === 'dark' ? colors.white : '#4F4F4F',
    height: '0.5625rem',
    width: '2.6875rem',
  },
  filterButtonActive: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '1rem',
    top: '26.56%',
    bottom: '28.12%',
    width: '5.5rem',
    height: '1.8125rem',
    radius: '0.25rem',
    border: '1px solid #BDBDBD',
    '&:hover': {
      backgroundColor: '#dbdbdb',
    },
    zIndex: 2,
    backgroundColor: colors.deepBlue,
    boxShadow: '0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25) inset',
  },
  filterButtonLabel: {
    height: '0.75rem',
    width: '3rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '0.87875rem',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: () =>
      theme.palette.customType === 'dark' ? colors.white : '#828282',
  },
  filterButtonLabelActive: {
    height: '0.75rem',
    width: '3rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '0.87875rem',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: colors.offWhite,
  },
  filterButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '1rem',
    top: '26.56%',
    bottom: '28.12%',
    width: '5.5rem',
    height: '1.8125rem',
    radius: '0.25rem',
    border: '1px solid #BDBDBD',
    backgroundColor: () =>
      theme.palette.customType === 'dark' ? '#3D3D3D' : colors.offWhite,
    '&:hover': {
      backgroundColor: '#dbdbdb',
    },
    zIndex: 2,
    boxShadow: '0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)',
  },
}));
