import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { useStyles } from './stlyes';

interface IFooterProps {}

const FooterComp = React.memo(({ ...props }: IFooterProps) => {
  const classes = useStyles();

  return (
    <Grid container justify='center'>
      <Link
        href='https://www.demiplane.com/tos'
        target='_blank'
        underline='always'
        className={classes.tos}
      >
        Terms of Use
      </Link>
    </Grid>
  );
});

// @ts-ignore
FooterComp.whyDidYouRender = {
  customName: 'Footer',
};

export const Footer = FooterComp;
