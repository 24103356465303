import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { StylesProvider, ThemeProvider } from '@material-ui/core/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Client } from 'urql';
import {
  AuthProvider,
  LoadingProvider,
  UrqlClientProvider,
} from '../../state/providers';
import createTheme from '../../styles/rootTheme';
import { SnackbarProvider } from 'notistack';

interface IAppProvidersProps {
  makeUrqlClient: () => Client;
  children?: any;
}

const AppProvidersComp = ({ makeUrqlClient, children }: IAppProvidersProps) => {
  const [, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const theme = createTheme();

  return (
    <UrqlClientProvider makeClient={makeUrqlClient}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SnackbarProvider maxSnack={3}>
              <LoadingProvider>
                <AuthProvider>{children}</AuthProvider>
              </LoadingProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StylesProvider>
    </UrqlClientProvider>
  );
};

// @ts-ignore
AppProvidersComp.whyDidYouRender = true;

export const AppProviders = AppProvidersComp;
