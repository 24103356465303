import React from 'react';
import { Navigate, Routes } from '@demiplane-dev/react-router-dom';
import { PrivateRoute } from '../components';
import { MatchMaking } from './matchMaking';

const AppRoutes = React.memo(() => {
  return (
    <Routes>
      <PrivateRoute path='home' element={<MatchMaking />} />
      <Navigate to='home' />
    </Routes>
  );
});

export default AppRoutes;
