import React from 'react';

interface SearchIconProps {
  height: string;
  width: string;
  fill?: string;
}

export const SearchIcon = ({ height, width, fill }: SearchIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.9948 19.0248C23.2739 16.2374 23.9541 12.4709 22.7921 9.06302C21.6311 5.6551 18.794 3.08659 15.2863 2.27145C11.7796 1.45538 8.09906 2.50698 5.55335 5.05388C3.0074 7.59987 1.95584 11.2795 2.77096 14.786C3.58702 18.2938 6.15431 21.1317 9.56319 22.2929C12.9711 23.454 16.7377 22.7737 19.5248 20.4946L25.7797 26.7495C26.193 27.1033 26.8097 27.0798 27.1948 26.6948C27.5799 26.3097 27.6033 25.693 27.2495 25.2796L20.9948 19.0248ZM12.9261 20.7762C10.7135 20.7762 8.59279 19.8976 7.02909 18.3339C5.46441 16.7692 4.58581 14.6485 4.58581 12.4367C4.58581 10.2241 5.46441 8.10334 7.02909 6.53962C8.59279 4.97492 10.7137 4.0963 12.9261 4.0963C15.1378 4.0963 17.2585 4.97492 18.8232 6.53962C20.3869 8.10334 21.2655 10.2243 21.2655 12.4367C21.2655 14.6484 20.3869 16.7691 18.8232 18.3339C17.2585 19.8976 15.1378 20.7762 12.9261 20.7762Z'
      fill={fill ?? '#3276B5'}
    />
  </svg>
);
