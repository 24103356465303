import { makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  itemCount: number;
}

export const useStyles = makeStyles((theme: Theme) => ({
  scheduleContainer: {
    height: '10rem',
  },
  scheduleBox: {
    height: '5.125rem',
    minWidth: (props: IStyleProps) =>
      props.itemCount <= 3
        ? '10.125rem'
        : props.itemCount === 4
        ? '6.1875rem'
        : '4.625rem',
    maxWidth: (props: IStyleProps) =>
      props.itemCount <= 3
        ? '10.125rem'
        : props.itemCount === 4
        ? '6.1875rem'
        : '4.625rem',
    borderRadius: 4,
    backgroundColor: '#F3F3F3',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    padding: '0 .5rem',

    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  scheduleDay: {
    width: '100%',
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '100%',
    textAlign: 'center',
    flexShrink: 0,
    flexWrap: 'nowrap',
  },
  scheduleTime: {
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '100%',
    textAlign: 'center',
    flexShrink: 0,
    flexWrap: 'nowrap',
    color: '#898989',
  },
  scrollableSchedule: {
    width: '100%',
    scrollBehavior: 'smooth',
    zIndex: 1,
  },
  schedule: {
    marginTop: '1rem',
    height: '6rem',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  scheduleButtonLeft: {
    position: 'absolute',
    top: '-1.75rem',
    left: 0,
    border: '1px solid #F3F3F3',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FDFDFD',
    height: '2rem',
    width: '2rem',
    color: '#3276B5',
    zIndex: 1,

    '&:hover': {
      backgroundColor: '#FDFDFD',
    },
  },
  scheduleButtonRight: {
    position: 'absolute',
    top: '-1.75rem',
    right: 0,
    border: '1px solid #F3F3F3',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FDFDFD',
    height: '2rem',
    width: '2rem',
    color: '#3276B5',
    zIndex: 1,

    '&:hover': {
      backgroundColor: '#FDFDFD',
    },
  },
  scheduleButtonContainer: {
    position: 'relative',
  },
  scheduleTitle: {
    fontWeight: 700,
    fontSize: '1.125rem',
    height: '1.125rem',
  },
  scheduleSubtitle: {
    color: '#898989',
    fontSize: '0.875rem',
    minHeight: '0.875rem',
    lineHeight: '0.875rem',
    margin: '0.25rem',
  },
}));
