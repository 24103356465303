import React, { useState, useRef, useCallback } from 'react';
import { LoadingContext, LoadingActionsContext } from '../contexts';
import { Location } from 'history';

const LoadingProviderComp = ({ ...props }) => {
  const [isGlobalLoading, _setGlobalLoading] = useState(true);
  const timeout = useRef<number | undefined>();
  const blockedRoute = useRef('');

  const setGlobalLoading = useCallback(
    (value: boolean, location: Location, force?: boolean) => {
      if (value) {
        if (location!.pathname !== blockedRoute.current || !!force) {
          if (!timeout.current) {
            timeout.current = window.setTimeout(() => {
              _setGlobalLoading(value);
            }, 400);
          }
        }
        blockedRoute.current = '';
      } else {
        if (!!timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        } else {
          blockedRoute.current = location!.pathname;
        }

        _setGlobalLoading(value);
      }
    },
    []
  );

  return (
    <LoadingContext.Provider value={{ isGlobalLoading }}>
      <LoadingActionsContext.Provider value={{ setGlobalLoading }}>
        {props.children}
      </LoadingActionsContext.Provider>
    </LoadingContext.Provider>
  );
};

// @ts-ignore
LoadingProviderComp.whyDidYouRender = {
  customName: 'LoadingProvider',
};

export const LoadingProvider = LoadingProviderComp;
