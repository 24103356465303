import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../styles/rootTheme';

const logoLight = require('../../../assets/logo/demiplane_light.png').default;
const logoDark = require('../../../assets/logo/demiplane_dark.png').default;

export const useStyles = makeStyles((theme: Theme) => ({
  only: {
    paddingTop: '1rem',
    paddingLeft: '1rem',
    paddingBottom: '1rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: colors.mediumGrey,
  },
  switchLabel: {
    margin: 'auto',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: colors.mediumGrey,
  },
  buttonContainer: {
    marginTop: '2.75rem',
  },
  buttonReset: {
    background: 'transparent',
    border: '1px solid #3276b5',
    '&:hover': {
      background: 'transparent',
      color: colors.deepBlue,
    },
  },
  buttonResetLabel: {
    color: colors.deepBlue,
  },
  buttonConfirm: {
    '&:hover': {
      background: colors.lightBlue,
      color: colors.white,
    },
  },
  logoContainer: {
    position: 'absolute',
    bottom: '1.1875rem',
    width: '6.1875rem',
    height: '2.25rem',
  },
  logo: {
    position: 'absolute',
    top: '0.306875rem',
    height: '1.943125rem',
    width: '6.125rem',
    backgroundImage: () =>
      theme.palette.customType === 'dark'
        ? `url(${logoDark})`
        : `url(${logoLight})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoText: {
    position: 'absolute',
    height: '0.5625rem',
    width: '5.8125rem',
    left: '0.275rem',
    top: '0px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.5rem',
    lineHeight: '0.5625rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: () =>
      theme.palette.customType === 'dark' ? colors.white : '#333333',
  },
}));
