import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Route, useLocation } from '@demiplane-dev/react-router-dom';
import { useAuth } from '../../state/hooks';

const PrivateRouteComp = ({ element, ...props }: any) => {
  const location = useLocation();
  const { authenticate } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const queryParams = queryString.parse(location.search);

      if (typeof queryParams.token === 'string') {
        const result = await authenticate(queryParams.token);

        if (!result) {
          // route to unathorized page
          window.location.replace(
            `${process.env.REACT_APP_MM_ENDPOINT}/unauthorized.html`
          );
        }
      } else {
        window.location.replace(
          `${process.env.REACT_APP_MM_ENDPOINT}/unauthorized.html`
        );
      }

      setLoading(false);
    };

    checkAuthentication();
  }, [authenticate, location.search]);

  if (loading) {
    return null;
  }

  return <Route {...props} element={element} />;
};

// @ts-ignore
PrivateRouteComp.whyDidYouRender = {
  customName: 'PrivateRoute',
};

export const PrivateRoute = PrivateRouteComp;
