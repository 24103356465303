import * as Auth from './Auth';
import * as Loading from './Loading';
import * as UrqlClient from './UrqlClient';

export * from './Auth';
export * from './Loading';
export * from './UrqlClient';

const hooks = {
  ...Auth,
  ...Loading,
  ...UrqlClient,
};

export default hooks;
