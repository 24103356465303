import React from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { useStyles } from './styles';
import { Grid, Typography } from '@material-ui/core';
import { IAvailabilityStatus } from '../../../../../types';
import { Item } from './components';

interface IAvailabilityProps {
  availabilityStatuses: IAvailabilityStatus[];
  handleDowSelect: (
    event: React.MouseEvent<any, MouseEvent>,
    dow: number
  ) => void;
  handleTimeSelect: (date: Date | null, timeField: string, dow: number) => void;
}

type TAvailabilityProps = IAvailabilityProps & WithSnackbarProps;

const AvailabilityComp = withSnackbar(
  ({
    availabilityStatuses,
    handleDowSelect,
    handleTimeSelect,
    enqueueSnackbar,
  }: TAvailabilityProps) => {
    const classes = useStyles();

    return (
      <Grid container justify='center'>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography className={classes.columnHeader}>
            Earliest Start Time
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.columnHeader}>
            Latest Start Time
          </Typography>
        </Grid>

        {availabilityStatuses.map((status) => (
          <Item
            key={status.dow}
            dow={status.dow}
            dowSelected={status.dowSelected}
            startTime={status.startTime}
            endTime={status.endTime}
            handleDowSelect={handleDowSelect}
            handleTimeSelect={handleTimeSelect}
          />
        ))}
      </Grid>
    );
  }
);

// @ts-ignore
AvailabilityComp.whyDidYouRender = {
  customName: 'Availability',
};

export const Availability = AvailabilityComp;
