import React from 'react';

interface HomeSelectedIconProps {
  height: string;
  width: string;
}

export const HomeSelectedIcon = ({ height, width }: HomeSelectedIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 14.3284H4.28571V30H12V20.1493H18.3333V30H26.1429V14.3284H30V13.4328L15.4286 0H14.1429L0 13.4328V14.3284Z'
      fill='#3276B5'
    />
  </svg>
);
