import React from 'react';

interface SearchSelectedIconProps {
  height: string;
  width: string;
}

export const SearchSelectedIcon = ({
  height,
  width,
}: SearchSelectedIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.9947 19.0248C23.2738 16.2374 23.954 12.4709 22.792 9.06302C21.631 5.6551 18.7939 3.08659 15.2862 2.27145C11.7795 1.45538 8.09896 2.50698 5.55325 5.05388C3.00729 7.59987 1.95573 11.2795 2.77086 14.786C3.58691 18.2938 6.15421 21.1317 9.56308 22.2929C12.971 23.454 16.7376 22.7737 19.5247 20.4946L25.7795 26.7495C26.1929 27.1033 26.8096 27.0798 27.1947 26.6948C27.5798 26.3097 27.6032 25.693 27.2494 25.2796L20.9947 19.0248ZM12.926 20.7762C10.7134 20.7762 8.59268 19.8976 7.02898 18.3339C5.4643 16.7692 4.5857 14.6485 4.5857 12.4367C4.5857 10.2241 5.4643 8.10334 7.02898 6.53962C8.59268 4.97492 10.7136 4.0963 12.926 4.0963C15.1377 4.0963 17.2583 4.97492 18.823 6.53962C20.3867 8.10334 21.2654 10.2243 21.2654 12.4367C21.2654 14.6484 20.3867 16.7691 18.823 18.3339C17.2584 19.8976 15.1377 20.7762 12.926 20.7762Z'
      fill='#3276B5'
    />
    <path
      d='M22 12.5C22 17.1944 17.9706 21 13 21C8.02944 21 4 17.1944 4 12.5C4 7.80558 8.02944 4 13 4C17.9706 4 22 7.80558 22 12.5Z'
      fill='#3276B5'
    />
    <path
      d='M6.29564 7.44799C8.64217 4.00919 11.7429 3.7863 13 4.10471C6.29572 7.5437 5.73698 14.1345 6.29564 17C5.31792 15.2488 3.94911 10.8868 6.29564 7.44799Z'
      fill='#FDFDFD'
      stroke='#FDFDFD'
      strokeLinejoin='round'
    />
  </svg>
);
