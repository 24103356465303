import React, { useCallback, useMemo } from 'react';
import { AuthContext } from '../contexts';
import { useAuthMmMutation } from '../../types/graphql';

const AuthProviderComp = React.memo(({ ...props }) => {
  const [, authMM] = useAuthMmMutation();

  const authenticate = useCallback(
    async (token: string) => {
      const result = await authMM({
        token,
      });

      return !!result.data?.authMM?.success;
    },
    [authMM]
  );

  const returnObj = useMemo(() => {
    return {
      authenticate,
    };
  }, [authenticate]);

  return <AuthContext.Provider value={returnObj} {...props} />;
});

// @ts-ignore
AuthProviderComp.whyDidYouRender = {
  customName: 'AuthProvider',
};

export const AuthProvider = AuthProviderComp;
