import React from 'react';
import { useStyles } from './styles';

interface IDayButtonProps {
  dow: number;
  range: string;
  availabilities: string[];
  handleSetAvailabilities: (availabilities: string[]) => void;
}

type TDayButtonProps = IDayButtonProps;

const DayButtonComp = ({
  dow,
  range,
  availabilities,
  handleSetAvailabilities,
}: TDayButtonProps) => {
  const classes = useStyles();

  const dayName = () => {
    let dayName = '';
    switch (dow) {
      case 0:
        dayName = 'SUN';
        break;
      case 1:
        dayName = 'MON';
        break;
      case 2:
        dayName = 'TUE';
        break;
      case 3:
        dayName = 'WED';
        break;
      case 4:
        dayName = 'THU';
        break;
      case 5:
        dayName = 'FRI';
        break;
      case 6:
        dayName = 'SAT';
        break;
    }
    return dayName;
  };

  const handleDayOnClick = (): void => {
    const newAvailabilities: string[] = [];
    let availExists = false;
    for (let i = 0; i < availabilities.length; i++) {
      const avail = availabilities[i];
      if (`${range}-${dayName()}` === avail) {
        availExists = true;
      } else {
        newAvailabilities.push(avail);
      }
    }
    if (!availExists) {
      newAvailabilities.push(`${range}-${dayName()}`);
    }
    handleSetAvailabilities(newAvailabilities);
  };

  const isSelected = (): boolean => {
    let availExists = false;
    for (let i = 0; i < availabilities.length; i++) {
      const avail = availabilities[i];
      if (`${range}-${dayName()}` === avail) {
        availExists = true;
      }
    }
    return availExists;
  };

  return (
    <div
      className={isSelected() ? classes.containerSelected : classes.container}
      onClick={handleDayOnClick}
    ></div>
  );
};

// @ts-ignore
DayButtonComp.whyDidYouRender = {
  customName: 'DayButton',
};

export const DayButton = DayButtonComp;
