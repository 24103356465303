import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { AdventureFiltersDrawer, Layout } from '../../components';
import { useLocation } from '@demiplane-dev/react-router-dom';
import { useLoadingActions } from '../../state/hooks';
import {
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ISelectOption, IGameType, IAdventuringPlatform } from '../../types';
import { addDays, endOfWeek, parseISO, startOfWeek } from 'date-fns';
import {
  calculateAvailabilityDateStrings,
  dateLocalToUTC,
  shuffleArray,
} from '../../utils';
import { AdCard, AdTab } from '@demiplane-dev/demiplane-components';
import { Location } from 'history';
import {
  AvailabilityInputMm,
  useSearchAdsMmQuery,
  useGetGameTypesQuery,
  useGetAdventuringPlatformsQuery,
} from '../../types/graphql';
import queryString from 'query-string';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { HomeJoinSubSection } from './components';
import { SearchIcon } from '../../assets/icons';

interface ISearchRoutesStateProps {
  gameSystemsFiltered: boolean;
  selectedGameSystems: ISelectOption[];
  adventuringPlatformsFiltered: boolean;
  selectedAdventuringPlatforms: ISelectOption[];
  availabilityFiltered: boolean;
  selectedAvailability: string[];
  frequencyFiltered: boolean;
  frequencyOneTime: boolean;
  frequencyRecurring: boolean;
  extraFiltersFiltered: boolean;
  quickMatch: boolean;
  newPlayer: boolean;
  noCost: boolean;
  gameTypeIds: number[];
  adventuringPlatformIds: number[];
}

interface IMatchMakingQueryParams {
  newPlayerFriendly?: boolean;
  costMax?: number;
  gameSystems?: number[];
  numSeatsOpenMin?: number;
  numSeatsOpenMax?: number;
  adventuringPlatforms?: number[];
  adCreator?: string;
  gameMaster?: string;
  timeZone?: string;
  sortBy?: string[];
  tabs?: boolean;
  showFilter?: boolean;
  oneShot?: boolean;
  recurring?: boolean;
  quickMatch?: boolean;
}

interface IMatchMakingDefaultConfig {
  newPlayerFriendly: boolean;
  costMax?: number;
  gameSystems: number[];
  numSeatsOpenMin?: number;
  numSeatsOpenMax?: number;
  adventuringPlatforms: number[];
  adCreator?: string;
  gameMaster?: string;
  timeZone?: string;
  sortBy?: string[];
  tabs: boolean;
  showFilter: boolean;
  oneShot: boolean;
  recurring: boolean;
  quickMatch: boolean;
}

const defaultConfig: IMatchMakingDefaultConfig = {
  oneShot: true,
  recurring: true,
  quickMatch: false,
  newPlayerFriendly: false,
  //costMax: 0,
  gameSystems: [],
  //numberOfSeatsOpenMin: 1,
  //numberOfSeatsOpenMax: 12,
  adventuringPlatforms: [],
  //adCreator: 'zerocool',
  //gameMaster: 'zerocool,
  //timeZone: '-6',
  //sortBy: [],
  tabs: false,
  showFilter: true,
};

const PAGE_SIZE = 25;

export const MatchMaking = ({ ...props }) => {
  const matchesScrollHeight = (): boolean => {
    return window.innerHeight >= 350;
  };
  const matchesFilterHeight = (): boolean => {
    return window.innerHeight >= 700;
  };
  const matchesWidth = useMediaQuery('(min-width:600px)');
  //const matchesHeight = useMediaQuery('min-height:700px'); height check doesn't seem to work in iframe
  const classes = useStyles({ desktop: matchesWidth });
  const location = useLocation() as Location<ISearchRoutesStateProps>;
  const originDomain = !!window.location.ancestorOrigins
    ? window.location.ancestorOrigins[0]
    : undefined;
  const [seed] = useState(Math.random());
  const createAdventureLink = `${process.env.REACT_APP_CLIENT_ENDPOINT}/home?open=newportal&utm_medium=iframe&utm_source=${originDomain}`;
  const { setGlobalLoading } = useLoadingActions();
  const [searchLoading, setSearchLoading] = useState(true);
  const sbScrollableNodeRef = useRef<any>();
  const queryParams: IMatchMakingQueryParams = queryString.parse(
    location.search,
    {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'comma',
    }
  );
  if (typeof queryParams.gameSystems === 'number') {
    queryParams.gameSystems = [queryParams.gameSystems];
  }
  if (typeof queryParams.adventuringPlatforms === 'number') {
    queryParams.adventuringPlatforms = [queryParams.adventuringPlatforms];
  }

  const [cards, setCards] = useState<React.ReactNode[]>([]);

  // Filters ##########################################################################
  const [openAdventureFiltersDrawer, setOpenAdventureFiltersDrawer] = useState(
    false
  );

  const [gameTypes, setGameTypes] = useState<IGameType[]>([]);
  const [gameTypeIds, setGameTypeIds] = useState<number[]>(
    queryParams.gameSystems ?? defaultConfig.gameSystems
  );
  const [gameSystemsLoading, setGameSystemsLoading] = useState(true);
  const [gameSystemsFiltered, setGameSystemsFiltered] = useState(false);

  const [selectedGameSystems, setSelectedGameSystems] = useState<
    ISelectOption[]
  >(
    !!queryParams.gameSystems
      ? queryParams.gameSystems.map(
          (gs): ISelectOption => {
            return {
              value: gs.toString(),
              label: '',
            };
          }
        )
      : []
  );

  const [adventuringPlatforms, setAdventuringPlatforms] = useState<
    IAdventuringPlatform[]
  >([]);
  const [adventuringPlatformIds, setAdventuringPlatformIds] = useState<
    number[]
  >(queryParams.adventuringPlatforms ?? defaultConfig.adventuringPlatforms);
  const [
    adventuringPlatformsLoading,
    setAdventuringPlatformsLoading,
  ] = useState(true);
  const [
    adventuringPlatformsFiltered,
    setAdventuringPlatformsFiltered,
  ] = useState(false);

  const [
    selectedAdventuringPlatforms,
    setSelectedAdventuringPlatforms,
  ] = useState<ISelectOption[]>(
    !!queryParams.adventuringPlatforms
      ? queryParams.adventuringPlatforms.map(
          (ap): ISelectOption => {
            return {
              value: ap.toString(),
              label: '',
            };
          }
        )
      : []
  );

  const [availabilityFiltered, setAvailabilityFiltered] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState<string[]>(
    []
  );
  const [availability, setAvailability] = useState<AvailabilityInputMm[]>([]);
  const [frequencyFiltered, setFrequencyFiltered] = useState(false);
  const [frequencyOneTime, setFrequencyOneTime] = useState(
    queryParams.oneShot ?? defaultConfig.oneShot
  );
  const [frequencyRecurring, setFrequencyRecurring] = useState(
    queryParams.recurring ?? defaultConfig.recurring
  );
  const [extraFiltersFiltered, setExtraFiltersFiltered] = useState(false);
  const [quickMatch, setQuickMatch] = useState(
    queryParams.quickMatch ?? defaultConfig.quickMatch
  );
  const [newPlayer, setNewPlayer] = useState(
    queryParams.newPlayerFriendly ?? defaultConfig.newPlayerFriendly
  );
  const [noCost, setNoCost] = useState(
    queryParams.costMax === 0 ? true : false
  );
  const [recordCount, setRecordCount] = useState(PAGE_SIZE);
  const costMax = queryParams.costMax ?? defaultConfig.costMax;
  const tabs = queryParams.tabs ?? defaultConfig.tabs;
  const showFilter = queryParams.showFilter ?? defaultConfig.showFilter;
  const adCreator = queryParams.adCreator ?? defaultConfig.adCreator;
  const numSeatsOpenMax =
    queryParams.numSeatsOpenMax ?? defaultConfig.numSeatsOpenMax;
  const numSeatsOpenMin =
    queryParams.numSeatsOpenMin ?? defaultConfig.numSeatsOpenMin;
  const gameMaster = queryParams.gameMaster ?? defaultConfig.gameMaster;
  const sortBy = queryParams.sortBy ?? defaultConfig.sortBy;

  const [searchAdsMmResult] = useSearchAdsMmQuery({
    variables: {
      recordCount,
      gameTypeIds,
      adventuringPlatformIds,
      frequencyOneTime,
      frequencyRecurring,
      availability,
      quickMatch,
      newPlayer,
      noCost,
      costMax,
      adCreator,
      numSeatsOpenMax,
      numSeatsOpenMin,
      gameMaster,
      sortBy,
    },
    requestPolicy: 'cache-and-network',
  });

  const { data: searchAdsData, fetching: searchAdsLoading } = searchAdsMmResult;

  const [gameTypesResult] = useGetGameTypesQuery();
  const [adventuringPlatformsResult] = useGetAdventuringPlatformsQuery();

  const { data: gameTypesData } = gameTypesResult;
  const { data: adventuringPlatformsData } = adventuringPlatformsResult;

  // ##################################################################################
  const isFiltered = () => {
    return (
      gameSystemsFiltered ||
      adventuringPlatformsFiltered ||
      availabilityFiltered ||
      frequencyFiltered ||
      extraFiltersFiltered
    );
  };

  useEffect(() => {
    // reset record count if a filter has changed, and scroll to top
    setRecordCount(PAGE_SIZE);
    if (!!sbScrollableNodeRef.current) {
      sbScrollableNodeRef.current.scrollTo(0, 0);
    }
  }, [
    gameTypeIds,
    adventuringPlatformIds,
    frequencyOneTime,
    frequencyRecurring,
    availability,
    quickMatch,
    newPlayer,
    noCost,
  ]);

  useEffect(() => {
    if (!!gameTypesData && !!gameTypesData.demiplane_game_type) {
      setGameTypes(
        gameTypesData.demiplane_game_type.map(
          (gt): IGameType => {
            return {
              id: gt.id,
              code: gt.code,
              name: gt.name,
              active: gt.active,
              created: parseISO(gt.created),
              updated: parseISO(gt.updated),
            };
          }
        )
      );
    }
  }, [gameTypesData]);

  useEffect(() => {
    if (
      !!adventuringPlatformsData &&
      !!adventuringPlatformsData.demiplane_adventuring_platform
    ) {
      setAdventuringPlatforms(
        adventuringPlatformsData.demiplane_adventuring_platform.map(
          (ap): IAdventuringPlatform => {
            return {
              id: ap.id,
              name: ap.name,
              active: ap.active,
              created: parseISO(ap.created),
              updated: parseISO(ap.updated),
            };
          }
        )
      );
    }
  }, [adventuringPlatformsData]);

  const handleAdventureFiltersDrawerClose = (filter: boolean) => {
    setOpenAdventureFiltersDrawer(false);
  };

  const handleReset = async () => {
    const optionsGT = gameTypes.map((p) => {
      return { label: p.name, value: p.id, key: p.id, disabled: false };
    });
    const selectedOptionsGT: ISelectOption[] = [];
    for (const option of optionsGT) {
      selectedOptionsGT.push(option);
    }
    setSelectedGameSystems(selectedOptionsGT);
    setGameSystemsFiltered(false);

    // Reset Adventuring Platforms
    const optionsAP = adventuringPlatforms.map((p) => {
      return { label: p.name, value: p.id, key: p.id, disabled: false };
    });
    const selectedOptionsAP: ISelectOption[] = [];
    for (const option of optionsAP) {
      selectedOptionsAP.push(option);
    }
    setSelectedAdventuringPlatforms(selectedOptionsAP);
    setAdventuringPlatformsFiltered(false);

    // Reset Availability
    setSelectedAvailability([]);
    setAvailabilityFiltered(false);

    // Reset Frequency
    setFrequencyOneTime(true);
    setFrequencyRecurring(true);
    setFrequencyFiltered(false);

    // Reset extra filters
    setExtraFiltersFiltered(false);
    setQuickMatch(false);
    setNewPlayer(false);
    setNoCost(false);
  };

  const buildAvailability = (
    currentAvailability: string[]
  ): AvailabilityInputMm[] => {
    const availabilityUtcValidated: AvailabilityInputMm[] = [];

    // Reuse current parsing logic from Recruitment
    if (currentAvailability && currentAvailability.length > 0) {
      // Utilize the same process as recruiting to build an string array of date objects
      let availabilityDates: string[] = [];
      const sow = startOfWeek(new Date(), { weekStartsOn: 0 });
      availabilityDates = calculateAvailabilityDateStrings(
        sow,
        currentAvailability
      );

      // Parse the local timezone string array into an array of date objects
      const availabilityLocal: AvailabilityInputMm[] = [];
      for (const ad of availabilityDates) {
        const av: string[] = ad.split(',');
        availabilityLocal.push({
          dow: parseInt(av[0]),
          startTime: new Date(av[1]).toISOString(),
          endTime: new Date(av[2]).toISOString(),
        });
      }

      // Validate for any crossover of UTC week
      const eow = endOfWeek(new Date(), { weekStartsOn: 0 });
      for (const time of availabilityLocal) {
        // Convert the start/end date/time to their UTC equivalents
        const startTimeUtc = dateLocalToUTC(new Date(time.startTime));
        const startTimeUtcDate = new Date(startTimeUtc.toDateString());
        const endTimeUtc = dateLocalToUTC(new Date(time.endTime));
        const endTimeUtcDate = new Date(endTimeUtc.toDateString());

        // Check the beginning of the week
        if (startTimeUtcDate < sow && endTimeUtcDate < sow) {
          // move record forward
          availabilityUtcValidated.push({
            dow: time.dow,
            startTime: addDays(new Date(time.startTime), 7).toISOString(),
            endTime: addDays(new Date(time.endTime), 7).toISOString(),
          });
        } else if (startTimeUtcDate < sow) {
          // move record forward
          availabilityUtcValidated.push({
            dow: time.dow,
            startTime: addDays(new Date(time.startTime), 7).toISOString(),
            endTime: addDays(new Date(time.endTime), 7).toISOString(),
          });
          // also keep original in case a portion matches
          availabilityUtcValidated.push(time);
        } else {
          availabilityUtcValidated.push(time);
        }

        // Check the end of the week
        if (startTimeUtcDate > eow && endTimeUtcDate > eow) {
          // move record backward
          availabilityUtcValidated.push({
            dow: time.dow,
            startTime: addDays(new Date(time.startTime), -7).toISOString(),
            endTime: addDays(new Date(time.endTime), -7).toISOString(),
          });
        } else if (endTimeUtcDate > eow) {
          // move record backward
          availabilityUtcValidated.push({
            dow: time.dow,
            startTime: addDays(new Date(time.startTime), -7).toISOString(),
            endTime: addDays(new Date(time.endTime), -7).toISOString(),
          });
          // also keep original in case a portion matches
          availabilityUtcValidated.push(time);
        } else {
          availabilityUtcValidated.push(time);
        }
      }
    }

    return availabilityUtcValidated;
  };

  const buildCards = useCallback(
    (searchResults: any): React.ReactNode[] => {
      const updatedCards: React.ReactNode[] = [];

      if (!!searchResults) {
        if (searchResults.length > 0) {
          for (const sr of searchResults) {
            const daysRaw: Date[] = [];
            if (sr.dowSunday) {
              daysRaw.push(
                addDays(
                  startOfWeek(new Date(sr.dowSunday), { weekStartsOn: 0 }),
                  new Date(sr.dowSunday).getDay()
                )
              );
            }
            if (sr.dowMonday) {
              daysRaw.push(
                addDays(
                  startOfWeek(new Date(sr.dowMonday), { weekStartsOn: 0 }),
                  new Date(sr.dowMonday).getDay()
                )
              );
            }
            if (sr.dowTuesday) {
              daysRaw.push(
                addDays(
                  startOfWeek(new Date(sr.dowTuesday), { weekStartsOn: 0 }),
                  new Date(sr.dowTuesday).getDay()
                )
              );
            }
            if (sr.dowWednesday) {
              daysRaw.push(
                addDays(
                  startOfWeek(new Date(sr.dowWednesday), { weekStartsOn: 0 }),
                  new Date(sr.dowWednesday).getDay()
                )
              );
            }
            if (sr.dowThursday) {
              daysRaw.push(
                addDays(
                  startOfWeek(new Date(sr.dowThursday), { weekStartsOn: 0 }),
                  new Date(sr.dowThursday).getDay()
                )
              );
            }
            if (sr.dowFriday) {
              daysRaw.push(
                addDays(
                  startOfWeek(new Date(sr.dowFriday), { weekStartsOn: 0 }),
                  new Date(sr.dowFriday).getDay()
                )
              );
            }
            if (sr.dowSaturday) {
              daysRaw.push(
                addDays(
                  startOfWeek(new Date(sr.dowSaturday), { weekStartsOn: 0 }),
                  new Date(sr.dowSaturday).getDay()
                )
              );
            }
            const adLink = `${process.env.REACT_APP_CLIENT_ENDPOINT}/profile/${sr.gameMaster}?open=ad&utm_medium=iframe&utm_source=${originDomain}&utm_campaign=${sr.adventureId}&id=${sr.id}`;

            updatedCards.push(
              <Grid
                key={sr.id}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                classes={{
                  root: matchesScrollHeight()
                    ? classes.resultContainerVertical
                    : classes.resultContainerHorizontal,
                }}
              >
                {tabs ? (
                  <Link href={adLink} target='_blank'>
                    <AdTab
                      id={sr.id}
                      backgroundImage={sr.image}
                      title={sr.name}
                      gameType={sr.gameTypeName}
                      adventurePlatform={sr.adventuringPlatformName}
                      cost={sr.cost}
                      daysOfWeek={daysRaw}
                      isQuickMatch={sr.quickMatch}
                      quickMatchDate={
                        !!sr.quickMatchDate
                          ? parseISO(sr.quickMatchDate)
                          : undefined
                      }
                      onClick={() => {}}
                      playerCount={sr.acceptedAdventurerCount}
                      playerCapacity={sr.requestedAdventurerCount}
                    />
                  </Link>
                ) : (
                  <div className={classes.adCardContainer}>
                    <Link
                      href={adLink}
                      target='_blank'
                      style={{ height: '15.25rem', display: 'block' }}
                    >
                      <AdCard
                        id={sr.id}
                        backgroundImage={sr.image}
                        title={sr.name}
                        gameType={sr.gameTypeName}
                        adventurePlatform={sr.adventuringPlatformName}
                        cost={sr.cost}
                        daysOfWeek={daysRaw}
                        isQuickMatch={sr.quickMatch}
                        quickMatchDate={
                          !!sr.quickMatchDate
                            ? parseISO(sr.quickMatchDate)
                            : undefined
                        }
                        onClick={() => {}}
                        playerCount={sr.acceptedAdventurerCount}
                        playerCapacity={sr.requestedAdventurerCount}
                      />
                    </Link>
                  </div>
                )}
              </Grid>
            );
          }
        }
      }

      return updatedCards;
    },
    [
      originDomain,
      classes.resultContainerVertical,
      classes.resultContainerHorizontal,
      classes.adCardContainer,
      tabs,
    ]
  );

  const loadMoreResults = () => {
    if (
      !searchAdsLoading &&
      !!searchAdsData &&
      !!searchAdsData.searchAdsMM &&
      searchAdsData.searchAdsMM.result.length === recordCount
    ) {
      setRecordCount(recordCount + PAGE_SIZE);
    }
  };

  // Append elements from array1 into array
  const appendArray = (array: React.ReactNode[], array1: React.ReactNode[]) => {
    let len = array1.length;
    let i = 0;
    while (i < len) {
      array[array.length] = array1[i++];
    }
    return array;
  };

  // Default search
  useEffect(() => {
    let isMounted = true;

    const loadSearchAds = async () => {
      if (isMounted) {
        setSearchLoading(true);
      }

      if (isMounted && !!searchAdsData?.searchAdsMM?.success) {
        const updatedCards = buildCards(searchAdsData.searchAdsMM?.result);

        // Sort each page based on `seed`, and concatenate those sorted arrays into `sortedCards`
        // This is done so we don't reorder the entire array when a new page has been added to the results
        const pages = updatedCards.length / PAGE_SIZE;
        const sortedCards: React.ReactNode[] = [];

        for (let x = 0; x < pages; x++) {
          const startIdx = x * 1 * PAGE_SIZE;
          const endIdx = (x + 1) * PAGE_SIZE;
          appendArray(
            sortedCards,
            shuffleArray(updatedCards.slice(startIdx, endIdx), seed)
          );
        }
        setCards(sortedCards);
      }

      if (isMounted) {
        setSearchLoading(false);
      }
    };
    loadSearchAds();

    return () => {
      isMounted = false;
    };
  }, [buildCards, searchAdsData, seed]);

  // If empty, set selectedGameSystems to ALL
  useEffect(() => {
    let isMounted = true;

    const setupGameSystems = async () => {
      if (isMounted) {
        setGameSystemsLoading(true);
      }

      if (
        isMounted &&
        !openAdventureFiltersDrawer &&
        selectedGameSystems.length === 0
      ) {
        const options = gameTypes.map((p) => {
          return { label: p.name, value: p.id, key: p.id, disabled: false };
        });
        const selectedOptions: ISelectOption[] = [];
        for (const option of options) {
          selectedOptions.push(option);
        }
        setSelectedGameSystems(selectedOptions);
      }

      if (isMounted) {
        setGameSystemsLoading(false);
      }
    };
    setupGameSystems();

    return () => {
      isMounted = false;
    };
  }, [gameTypes, openAdventureFiltersDrawer, selectedGameSystems.length]);

  // If empty, set selectedAdventuringPlatforms to ALL
  useEffect(() => {
    let isMounted = true;

    const setupAdventuringPlatforms = async () => {
      if (isMounted) {
        setAdventuringPlatformsLoading(true);
      }

      if (
        isMounted &&
        !openAdventureFiltersDrawer &&
        selectedAdventuringPlatforms.length === 0
      ) {
        const options = adventuringPlatforms.map((p) => {
          return { label: p.name, value: p.id, key: p.id, disabled: false };
        });
        const selectedOptions: ISelectOption[] = [];
        for (const option of options) {
          selectedOptions.push(option);
        }
        setSelectedAdventuringPlatforms(selectedOptions);
      }

      if (isMounted) {
        setAdventuringPlatformsLoading(false);
      }
    };
    setupAdventuringPlatforms();

    return () => {
      isMounted = false;
    };
  }, [
    adventuringPlatforms,
    openAdventureFiltersDrawer,
    selectedAdventuringPlatforms.length,
  ]);

  useEffect(() => {
    let isMounted = true;

    if (!!location.state) {
      if (isMounted) {
        setGameSystemsFiltered(location.state.gameSystemsFiltered);
      }
      if (isMounted) {
        setSelectedGameSystems(location.state.selectedGameSystems);
      }
      if (isMounted) {
        setAdventuringPlatformsFiltered(
          location.state.adventuringPlatformsFiltered
        );
      }
      if (isMounted) {
        setSelectedAdventuringPlatforms(
          location.state.selectedAdventuringPlatforms
        );
      }
      if (isMounted) {
        setAvailabilityFiltered(location.state.availabilityFiltered);
      }
      if (isMounted) {
        setSelectedAvailability(location.state.selectedAvailability);
      }
      if (isMounted) {
        setFrequencyFiltered(location.state.frequencyFiltered);
      }
      if (isMounted) {
        setFrequencyOneTime(location.state.frequencyOneTime);
      }
      if (isMounted) {
        setFrequencyRecurring(location.state.frequencyRecurring);
      }
      if (isMounted) {
        setExtraFiltersFiltered(location.state.extraFiltersFiltered);
      }
      if (isMounted) {
        setQuickMatch(location.state.quickMatch);
      }
      if (isMounted) {
        setNewPlayer(location.state.newPlayer);
      }
      if (isMounted) {
        setNoCost(location.state.noCost);
      }
      if (isMounted) {
        setGameTypeIds(location.state.gameTypeIds);
      }
      if (isMounted) {
        setAdventuringPlatformIds(location.state.adventuringPlatformIds);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [location.state]);

  // Gloabal loading
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (
        !searchLoading &&
        !gameSystemsLoading &&
        !adventuringPlatformsLoading
      ) {
        setGlobalLoading(false, location);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    location,
    setGlobalLoading,
    searchLoading,
    gameSystemsLoading,
    adventuringPlatformsLoading,
  ]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const _adventuringPlatformIds = [];
      for (const ap of selectedAdventuringPlatforms) {
        _adventuringPlatformIds.push(parseInt(ap.value));
      }
      setAdventuringPlatformIds(_adventuringPlatformIds);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedAdventuringPlatforms]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const _gameTypeIds = [];
      for (const gs of selectedGameSystems) {
        _gameTypeIds.push(parseInt(gs.value));
      }
      setGameTypeIds(_gameTypeIds);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedGameSystems]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setAvailability(buildAvailability(selectedAvailability));
    }
    return () => {
      isMounted = false;
    };
  }, [selectedAvailability]);

  // Infinite scroll
  useEffect(() => {
    if (!!sbScrollableNodeRef.current) {
      sbScrollableNodeRef.current.onscroll = () => {
        const totalHeight =
          sbScrollableNodeRef.current.scrollHeight -
          sbScrollableNodeRef.current.offsetHeight;

        if (sbScrollableNodeRef.current.scrollTop >= totalHeight - 250) {
          loadMoreResults();
        }
      };
    }
  });

  const noAdsFound = () => {
    return (
      !searchAdsLoading &&
      !!searchAdsData?.searchAdsMM?.result &&
      !searchAdsData.searchAdsMM.result.length
    );
  };

  const noAdsFoundComp = () => {
    return (
      <>
        <Grid
          container
          justify='center'
          classes={{
            root: matchesScrollHeight()
              ? classes.noResultContainer
              : classes.noResultContainerMobile,
          }}
        >
          <div className={classes.noResultIconContainer}>
            <SearchIcon height='40' width='40' fill='#545454' />
          </div>
        </Grid>
        <Grid container justify='center'>
          <div>
            <Typography classes={{ body1: classes.noResultText }}>
              There are no Adventures that meet the chosen parameters.
            </Typography>
            <Typography />
            <Typography classes={{ body1: classes.noResultText }}>
              Create an Adventure now on Demiplane!
            </Typography>
          </div>
        </Grid>
        <Grid container justify='center'>
          <div className={classes.createButtonContainer}>
            <Link target='_blank' href={createAdventureLink} underline='none'>
              <Button
                color='primary'
                classes={{
                  root: classes.createButton,
                  label: classes.createButtonLabel,
                }}
              >
                Create Adventure
              </Button>
            </Link>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Layout
        includeHeader={true}
        includeFooter={true}
        showFilter={showFilter && matchesFilterHeight()}
        onFilterClick={() => setOpenAdventureFiltersDrawer(true)}
        isFiltered={isFiltered()}
      >
        {matchesScrollHeight() ? (
          <SimpleBar
            className={
              matchesWidth
                ? classes.resultsContainerDesktop
                : classes.resultsContainerMobile
            }
            scrollableNodeProps={{ ref: sbScrollableNodeRef }}
          >
            <>
              {noAdsFound() ? (
                <>{noAdsFoundComp()}</>
              ) : (
                <Grid
                  container
                  classes={{
                    'spacing-xs-10': classes.resultsSpacing,
                  }}
                >
                  {cards}
                </Grid>
              )}
            </>
          </SimpleBar>
        ) : (
          <>
            {noAdsFound() ? (
              <>{noAdsFoundComp()}</>
            ) : (
              <Grid container>
                <HomeJoinSubSection
                  title=''
                  loadMoreResults={() => loadMoreResults()}
                >
                  {cards}
                </HomeJoinSubSection>
              </Grid>
            )}
          </>
        )}
      </Layout>

      {openAdventureFiltersDrawer && (
        <AdventureFiltersDrawer
          open={openAdventureFiltersDrawer}
          onClose={handleAdventureFiltersDrawerClose}
          showGameSystemsFilter={
            typeof queryParams.gameSystems === 'undefined' ? true : false
          }
          gameSystemsFiltered={gameSystemsFiltered}
          setGameSystemsFiltered={setGameSystemsFiltered}
          selectedGameSystems={selectedGameSystems}
          setSelectedGameSystems={setSelectedGameSystems}
          showAdventuringPlatformsFilter={
            typeof queryParams.adventuringPlatforms === 'undefined'
              ? true
              : false
          }
          adventuringPlatformsFiltered={adventuringPlatformsFiltered}
          setAdventuringPlatformsFiltered={setAdventuringPlatformsFiltered}
          selectedAdventuringPlatforms={selectedAdventuringPlatforms}
          setSelectedAdventuringPlatforms={setSelectedAdventuringPlatforms}
          availabilityFiltered={availabilityFiltered}
          setAvailabilityFiltered={setAvailabilityFiltered}
          selectedAvailability={selectedAvailability}
          setSelectedAvailability={setSelectedAvailability}
          frequencyFiltered={frequencyFiltered}
          setFrequencyFiltered={setFrequencyFiltered}
          frequencyOneTime={frequencyOneTime}
          setFrequencyOneTime={setFrequencyOneTime}
          frequencyRecurring={frequencyRecurring}
          setFrequencyRecurring={setFrequencyRecurring}
          onReset={handleReset}
          extraFiltersFiltered={extraFiltersFiltered}
          setExtraFiltersFiltered={setExtraFiltersFiltered}
          showQuickMatchFilter={
            typeof queryParams.quickMatch === 'undefined' ? true : false
          }
          quickMatch={quickMatch}
          setQuickMatch={setQuickMatch}
          showNewPlayerFilter={
            typeof queryParams.newPlayerFriendly === 'undefined' ? true : false
          }
          newPlayer={newPlayer}
          setNewPlayer={setNewPlayer}
          showNoCostFilter={queryParams.costMax === 0 ? false : true}
          noCost={noCost}
          setNoCost={setNoCost}
          showRecurringFilter={
            typeof queryParams.recurring === 'undefined' ? true : false
          }
          showOneShotFilter={
            typeof queryParams.oneShot === 'undefined' ? true : false
          }
        />
      )}
    </>
  );
};
